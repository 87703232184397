import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"

import { MdClose } from "react-icons/md"

import { settings } from "apollo/query"

import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
function ChangeDialog({
  openDialog,
  closeDialog,
  content,
  change_data,
  usersDatas,
  change_active,
}) {
  const [infos, setinfos] = useState("")
  const [open, setOpen] = useState(openDialog)
  const workList3 = ["기존 담당자 퇴사", "퇴사로 인한 계약 이관", "업무 변경으로 인한 이관"]
  useEffect(() => {
    setOpen(openDialog)
    setstep(1)
    setchangeList({
      id: null,
      department: "",
      name: "",
      reason: "퇴사로 인한 계약 이관",
    })
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const stepActive = () => {
    closeDialog()
    setOpen(false)
  }
  const next_step = (next) => {
    setstep(next)
  }

  useEffect(() => {
    settings_Call()
  }, [])
  const [step, setstep] = useState(1)
  const handleChange = (e, index) => {
    const changeListData = { ...changeList }
    if (index === 1) {
      changeListData.department = e.target.value
    } else if (index === 2) {
      changeListData.id = e.target.value.split(" ")[2]
      changeListData.name = e.target.value
    } else if (index === 3) {
      changeListData.reason = e.target.value
    }
    setchangeList(changeListData)
  }

  const [changeList, setchangeList] = useState({
    id: null,
    department: "",
    name: "",
    reason: "퇴사로 인한 계약 이관",
  })

  const user_filter = () => {
    let users = usersDatas
      .filter((el) => el.department === changeList.department)
      .map((x) => x.name + " " + x.position + " " + x.id)
    return users
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const workListSelects3 = workList3.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const [department_list, setdepartment_list] = useState([])
  const workListSelects = department_list.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const workListSelects2 = user_filter().map((x, index) => {
    return (
      <MenuItem value={x} key={index}>
        {x.split(" ")[0] + " " + x.split(" ")[1]}
      </MenuItem>
    )
  })
  const [change_check_dialog, setchange_check_dialog] = useState(false)
  const [change_check_dialog_Content, setchange_check_dialog_Content] = useState(
    "지정한 이관 대상자에게 일괄 이관하시겠습니까? \n 이관 완료 후 이전 상태로 복구할 수 없습니다."
  )
  const settings_Call = () => {
    settingsActive({
      variables: {
        type: "department",
      },
    }).then((res) => {
      let list = res.data.settings[0].list
      setdepartment_list(list)
    })
  }
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const change_go = () => {
    change_active(changeList)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <SuiBox sx={{ p: 5, width: "480px" }}>
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          edge="start"
          color="inherit"
          onClick={() => handleClose()}
          aria-label="close"
        >
          <MdClose />
        </IconButton>

        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "center" }}>
          {step === 1 ? "이관 신청" : "이관 신청 확인"}
        </SuiTypography>
        <SuiTypography
          fontSize="16px"
          color="subTitle"
          sx={{ textAlign: "center", marginTop: "14px", lineHeight: "20px" }}
        >
          {step === 1
            ? "신청 내용을 확인하고 이관 사유 및 대상자를 선택합니다."
            : `선택한 정보를 확인합니다.`}
          {step === 1 ? "" : <br />}
          {step === 1 ? "" : `변경시 뒤로가기버튼을 눌러 수정해주세요.`}
        </SuiTypography>
        <SuiBox
          sx={{
            border: "1px solid #CDD2D3",
            borderRadius: "10px",
            padding: 2,
            mt: 4,
            mb: 2,
            wordNreak: "keep-all",
            backgroundColor: "#F7F9FB",
          }}
        >
          <SuiTypography fontSize="18px" sx={{ color: "#707070", textAlign: "center" }}>
            신청 내용 :{" "}
            <span>
              {content}
              {/* {first_contract}{" "}
              {ContarctData.length - 1 > 0 ? `외 ${ContarctData.length - 1}건` : ""} */}
            </span>
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" justifyContent="start" sx={{ flexFlow: "wrap" }}>
          <Card
            sx={{
              mt: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              mb: 2,
              backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
              width: "100%",
              borderRadius: "1rem 0.7rem 0.7rem 1rem",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: "60px" }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ ml: 4, whiteSpace: "nowrap", color: "#535353" }}
              >
                이관사유
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }} />
              {step === 1 ? (
                <SuiBox sx={{ width: "100%", height: "auto" }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      changeList.reason === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      value={changeList.reason}
                      onChange={(e) => handleChange(e, 3)}
                      className="change-select-radius"
                    >
                      {workListSelects3}
                    </Select>
                  </FormControl>
                </SuiBox>
              ) : (
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {changeList.reason}
                </SuiTypography>
              )}
            </SuiBox>
          </Card>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                pl: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
                mb: 2,
                borderRadius: "1rem 0.7rem 0.7rem 1rem",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                >
                  이관부서
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                />
                {step === 1 ? (
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.department === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen1(!FiltersOpen1)}
                          />
                        )}
                        open={FiltersOpen1}
                        value={changeList.department}
                        onChange={(e) => handleChange(e, 1)}
                        className="change-select-radius"
                      >
                        {workListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {changeList.department}
                  </SuiTypography>
                )}
              </SuiBox>
            </Card>
            <Card
              sx={{
                pl: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
                borderRadius: "1rem 0.7rem 0.7rem 1rem",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                >
                  이관대상
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                />
                {step === 1 ? (
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.name === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen2(!FiltersOpen2)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen2(!FiltersOpen2)}
                          />
                        )}
                        open={FiltersOpen2}
                        value={changeList.name}
                        onChange={(e) => handleChange(e, 2)}
                        className="change-select-radius"
                      >
                        {workListSelects2}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {changeList.name.split(" ")[0] + " " + changeList.name.split(" ")[1]}
                  </SuiTypography>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </SuiBox>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 0, mt: 5, px: 0 }}
          >
            {step === 2 ? (
              <SuiButton
                color="secondary"
                variant="outlined"
                onClick={() => next_step(1)}
                sx={{
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  width: 100,
                  fontSize: 14,
                }}
              >
                이전
              </SuiButton>
            ) : null}
            <SuiButton
              color="info2"
              onClick={() => (step === 1 ? setstep(2) : change_go())}
              disabled={!changeList.name || !changeList.department || !changeList.reason}
              sx={{
                ml: "auto",
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              {step === 1 ? "다음으로" : "완료"}
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </Dialog>
  )
}

ChangeDialog.defaultProps = {
  openDialog: false,
}

ChangeDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  content: PropTypes.string,
  change_data: PropTypes.array,
  usersDatas: PropTypes.array,
  change_active: PropTypes.func,
}
export default ChangeDialog
