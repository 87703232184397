import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { IoIosCloseCircle } from "react-icons/io"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { FiSearch } from "react-icons/fi"
import GetFileIcon from "components/getFileIcon"
// Quill
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

// Api
import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"

function NoticeDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  editData,
  edit,
  NoticeDataAdd,
  NoticeDataEdit,
  addIndex,
}) {
  const [open, setOpen] = useState(openDialog)
  const [Files, setFiles] = useState([])
  const { myData } = useSelector((state) => state.store)
  const dispatch = useDispatch()
  const InputListsSet = [
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "제목 :",
      maxLength: 100,
      xs: 8,
      changed: false,
    },
    {
      items: ["공지사항", "업데이트", "기타"],
      value: "",
      error_message: "",
      error: false,
      type: "selectBox",
      title: "유형 :",
      maxLength: 100,
      xs: 4,
      makerId: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
    },
    {
      type: "fileList",
      value: "",
      title: "내용 :",
      xs: 12,
      files: [],
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  useEffect(() => {
    setOpen(openDialog)
    if (openDialog) {
      if (edit) {
        let input_set = [...InputLists]
        input_set[0].value = editData.title
        input_set[1].value = { notice: "공지사항", update: "업데이트", etc: "기타" }[editData.type]
        input_set[2].value = editData.content

        let array = []
        if (editData.file) {
          array.push({ file: editData.file })
        }

        setFiles(array)
        setInputLists[input_set]
      } else {
        setFiles([])
        setInputLists(InputListsSet)
      }
    }
  }, [openDialog])

  const [changeInput, setchangeInput] = useState("")
  let arrayFiles = [...Files]
  const imageHandler = () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute(
      "accept",
      ".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
    )
    input.click()
    input.onchange = () => {
      const file = input.files[0]

      if (file.size > 20000000) {
        return alert("200MB 이하의 pdf, excel, ppt, word, png, jpeg 파일만 업로드 가능합니다.")
      }
      arrayFiles[0] = { file: file }
      // arrayFiles.push({ file: file })
      setFiles(arrayFiles)
      setTimeout(() => {
        setchangeInput("on")
      }, 500)
      setTimeout(() => {
        setchangeInput("off")
      }, 1000)
    }
  }
  const DeleteFile = (file) => {
    let array = [...Files]
    setFiles(array.filter((x) => x.file.name !== file.name))
    arrayFiles = array.filter((x) => x.file.name !== file.name)
  }
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [["bold", "italic", "image"]],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: imageHandler,
        },
      },
    }
  }, [])
  const formats = ["bold", "italic", "image"]
  const quillChange = (e) => {
    let array = [...InputLists]
    array[2].value = e
    array[0].changed = true
    setInputLists(array)
  }
  const handleInput = (index, maxLength) => (e) => {
    let array = [...InputLists]
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
      array[0].changed = true
    }
    setInputLists(array)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].value = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const showPdf = (file) => {
    if (file.url) {
      var a = document.createElement("a")
      a.href = process.env.REACT_APP_BACKEND_URL + file.url
      a.target = "_blank"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
    }
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }

  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox>
          <SuiInput
            error={data.error}
            success={data.success}
            helperText={data.error_message}
            type={data.inputType}
            placeholder=""
            value={data.value}
            disabled={data.value === "**********"}
            onChange={handleInput(index, data.maxLength)}
          />
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox sx={{ width: 200, mb: 0.7 }}>
          <FormControl
            fullWidth
            sx={{ minHeight: 30 }}
            className={
              data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => setFiltersOpen1(!FiltersOpen1)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => setFiltersOpen1(!FiltersOpen1)}
                />
              )}
              open={FiltersOpen1}
              value={data.value}
              onChange={handleSelectChange(index)}
              className="setting-border-radius"
            >
              {data.items.map((x, index) => {
                return (
                  <MenuItem value={x} key={index}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <input
            type="text"
            value={changeInput}
            style={{ display: "none" }}
            id="changeInputAct"
            onChange={(e) => setchangeInput(e.target.value)}
          ></input>
          <SuiBox
            justifyContent="center"
            display="flex"
            alignItems="start"
            sx={{ height: "auto", width: "100%" }}
          >
            <ReactQuill
              className="Quill-layout"
              modules={modules}
              theme="snow"
              value={data.value}
              formats={formats}
              onChange={quillChange}
            />
          </SuiBox>
          <SuiBox sx={{ mt: 1 }}>
            {Files.map((file, index) => {
              const fileName = file.file?.name
              const fileType = fileName.split(".")[fileName.split(".").length - 1]
              return (
                <SuiBox
                  key={index}
                  justifyContent="flex-start"
                  display="flex"
                  alignItems="center"
                  sx={{ height: "auto" }}
                >
                  <IconButton
                    sx={{ mr: 1, p: 0, pt: 0.6, width: 18, height: 18 }}
                    onClick={() => DeleteFile(file.file)}
                  >
                    <IoIosCloseCircle />
                  </IconButton>
                  <IconButton sx={{ mr: 1, p: 0 }} className="cursorDefalut">
                    {GetFileIcon(fileType)}
                  </IconButton>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="medium"
                    color="dark"
                    sx={{ pb: 0, cursor: file.file.url ? "pointer" : "default" }}
                    onClick={() => DownloadPdf(file.file)}
                  >
                    {file.file.name}
                  </SuiTypography>
                  {file.file.url ? (
                    <div style={{ marginBottom: "2px", width: "150px" }}>
                      <SuiButton
                        sx={{
                          backgroundColor: "#EEEEEE",
                          ml: "auto",
                          minHeight: 24,
                          height: 24,
                          width: 24,
                          minWidth: 24,
                          p: 0,
                          ml: 2,
                          boxShadow: 0,
                        }}
                        onClick={() => showPdf(file.file)}
                      >
                        <FiSearch size="15" />
                      </SuiButton>
                      {/* <SuiButton
                        color="info"
                        sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                        onClick={() => DownloadPdf(file.file)}
                      >
                        다운로드
                      </SuiButton> */}
                    </div>
                  ) : (
                    ""
                  )}
                </SuiBox>
              )
            })}
          </SuiBox>
        </SuiBox>
      )
    }
  }

  const CUcheck = () => {
    if (!edit) {
      let new_data = {
        name: myData.name,
        position: myData.position,
        department: myData.department,
        created_at: new Date(),
        title: InputLists[0].value,
        type: { 공지사항: "notice", 업데이트: "update", 기타: "etc" }[InputLists[1].value],
        content: InputLists[2].value,
        file: Files[0] ? Files[0].file : null,
        added: addIndex + 1,
        show: true,
      }
      NoticeDataAdd(new_data)
    } else {
      let edit_data = {
        id: editData.id,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        created_at: new Date(),
        title: InputLists[0].value,
        type: { 공지사항: "notice", 업데이트: "update", 기타: "etc" }[InputLists[1].value],
        content: InputLists[2].value,
        file: Files[0] ? Files[0].file : null,
        changed: true,
        added: editData.added,
      }
      NoticeDataEdit(edit_data)
    }
    setOpen(false)
    closeDialog2()
    setTimeout(() => {
      setFiles([])
      setInputLists(InputListsSet)
    }, 500)
  }
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "inherit" }}
      >
        <SuiBox>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="secondary"
            sx={{ pt: index < 2 ? 2 : 0.5, pb: 0.5 }}
          >
            {row.title}
          </SuiTypography>
          <button id="hihi" style={{ display: "none" }}></button>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <SuiBox sx={{ p: 5 }}>
          <SuiBox sx={{ textAlign: "start" }}>
            <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
              {edit ? "공지사항 수정" : "공지사항 추가"}
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          <Divider sx={{ mb: 4, mt: 2 }} />
          <DialogActions sx={{ p: 0 }}>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{ width: 97.38, border: 1, borderColor: "#707070", height: 40 }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              disabled={
                InputLists[0].value === "" ||
                InputLists[1].value === "" ||
                InputLists[2].value === ""
              }
              sx={{ width: 97.38, height: 40 }}
              color="primary"
              onClick={() => CUcheck()}
            >
              {!edit ? "완료" : "수정"}
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </div>
  )
}

NoticeDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: {},
}

NoticeDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.object,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  NoticeDataAdd: PropTypes.func,
  NoticeDataEdit: PropTypes.func,
  addIndex: PropTypes.number,
}
export default NoticeDialog
