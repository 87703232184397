import React from "react"
import PropTypes from "prop-types"
function GetFileIcon(fileType) {
  switch (fileType) {
    case "png": {
      return <img height={24} width={24} src="/images/PNG.png" />
    }
    case "jpg": {
      return <img height={24} width={24} src="/images/JPG.png" />
    }
    case "jpeg": {
      return <img height={24} width={24} src="/images/JPG.png" />
    }
    case "pdf": {
      return <img height={24} width={24} src="/images/PDF.png" />
    }
    case "xls": {
      return <img height={24} width={24} src="/images/XSL.png" />
    }
    case "xlsx": {
      return <img height={24} width={24} src="/images/XSL.png" />
    }
    case "ppt": {
      return <img height={24} width={24} src="/images/PPT.png" />
    }
    case "pptx": {
      return <img height={24} width={24} src="/images/PPT.png" />
    }
    case "doc": {
      return <img height={24} width={24} src="/images/DOC.png" />
    }
    case "docs": {
      return <img height={24} width={24} src="/images/DOC.png" />
    }
    case "docx": {
      return <img height={24} width={24} src="/images/DOC.png" />
    }
    default:
      return <img height={24} width={24} src="/images/PDF.png" />
  }
}

GetFileIcon.defaultProps = {
  fileType: "pdf",
}

GetFileIcon.propTypes = {
  fileType: PropTypes.string,
}

export default GetFileIcon
