import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import GetFileIcon from "components/getFileIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import enLocale from "date-fns/locale/en-US"

import ContractInfoDialog from "../ContractInfoDialog"

import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"

import { FileUploader } from "react-drag-drop-files"

// Redux
import { useSelector, useDispatch } from "react-redux"

// Api
import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"
import { loading_Apply } from "reducers/store"

function DragDrop({ files }) {
  let message = "드래그해서 파일을 업로드 해주세요."
  const [Message, setMessage] = useState(message)
  const handleChange = (file) => {
    files({ name: "" })
    files(file)
    setMessage("업로드 되었습니다.")
  }
  const onTypeError = () => {
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }
  const onSizeError = () => {
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      hoverTitle="pdf, excel, ppt, word, png, jpeg 파일만 업로드 됩니다."
      label="드레그해서 파일을 업로드 해주세요."
      maxSize="200"
      classes="width100 fileDragDropContract"
      types={[
        "pdf",
        "png",
        "jpeg",
        "jpg",
        "ppt",
        "pptx",
        "docs",
        "xlsx",
        "docx",
        "xlsm",
        "xls",
        "xlsb",
        "xltx",
        "xltm",
        "xml",
      ]}
      accept=".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
      onSizeError={onSizeError}
      onTypeError={onTypeError}
    >
      <Card
        sx={{
          p: 2,
          // pt: "20px",
          width: "100%",
          height: 100,
          boxShadow: 0,
          border: 1,
          backgroundColor: "#FAF8FF",
          borderColor: "#EEEEEE",
        }}
      >
        <SuiBox
          sx={{
            mt: 0,
            textAlign: "center",
          }}
        >
          <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
            <BiUpload />
          </IconButton>
          <SuiTypography
            color={
              Message === "200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다."
                ? "error"
                : "secondary"
            }
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {Message}
          </SuiTypography>
        </SuiBox>
      </Card>
    </FileUploader>
  )
}
DragDrop.defaultProps = {}

DragDrop.propTypes = {
  files: PropTypes.func,
}

function ContractRenewalDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  edit,
  editData,
  type,
  ContarctData,
  renewalData,
  renewalEndData,
  ContarctDatas,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [DragDropFile, setDragDropFile] = useState({ name: "" })
  const { myData } = useSelector((state) => state.store)
  const [InfoDialogOpen, setInfoDialogOpen] = useState(false)
  const [InfoData, setInfoData] = useState([])
  const closeDialogInfo = () => {
    setInfoDialogOpen(false)
  }
  const InputListsSet = [
    {
      inputType: "text",
      value: "",
      type: "info",
      title: "연결된 이전 계약 :",
      xs: 6,
    },
    {
      inputType: "text",
      value: "상위 계약+1년",
      items: ["상위 계약+1년", "직접입력"],
      type: "selectBox",
      title: "계약 방식 :",
      xs: 6,
    },
    {
      type: "picUpload",
      title: "계약서 업로드:",
      xs: 6,
      file: {},
      changed: false,
      dropdown1: false,
      dropdown2: false,
      memo: [],
      subContract: [],
      activeStep: 0,
    },
    {
      value1: null,
      value2: null,
      type: "info2",
      title: "계약일:",
      title1: "계약 종료일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    {
      title: "업로드 파일:",
      type: "fileList",
      xs: 6,
    },
    {
      inputType: "text",
      value: "자동갱신",
      items: ["자동갱신", "해지시 종료"],
      type: "selectBox",
      title: "자동 갱신 계약 여부:",
      xs: 6,
    },
  ]
  const InputListsSet2 = [
    {
      inputType: "text",
      value: "",
      type: "info",
      title: "연결된 상위 계약 :",
      xs: 6,
    },
    {
      value: dayjs(new Date()),
      type: "enddatePicker",
      title: "통고일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    {
      type: "picUpload",
      title: "계약서 업로드:",
      xs: 6,
      file: {},
      changed: false,
      dropdown1: false,
      dropdown2: false,
      memo: [],
      subContract: [],
      activeStep: 0,
    },
    {
      value: dayjs(new Date()),
      type: "enddatePicker",
      title: "계약 종료일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    {
      title: "업로드 파일:",
      type: "fileList",
      xs: 6,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  const [InputLists2, setInputLists2] = useState(InputListsSet2)
  const [FirstContractDate, setFirstContractDate] = useState("")
  useEffect(() => {
    setOpen(openDialog)

    if (ContarctData.length > 0) {
      let array = [...InputLists]
      let array2 = [...InputLists2]
      if (type !== "renewal") {
      } else {
        array[1].type = "selectBox"
        array[1].value = "상위 계약+1년"
        array[1].items = ["상위 계약+1년", "직접입력"]
        array[3].type = "info2"
        array[5].type = "selectBox"
        if (ContarctData[3].value === "자동갱신") {
          array[5].value = "해지시 종료"
          array[5].items = ["해지시 종료", "자동갱신"]
        } else {
          array[5].value = "해지시 종료"
          array[5].items = ["해지시 종료", "자동갱신"]
        }
      }
      if (ContarctData[0].ContractType === "갱신 계약") {
        array[0].value = ContarctData[0].ContractType + "_" + (ContarctDatas.length - 1)
      } else {
        array[0].value = ContarctData[0].selectData[1].value
        array2[0].value = ContarctData[0].selectData[1].value
      }
      if (ContarctData[3].value === "자동갱신") {
        array[3].value1 = dayjs(new Date())
        array[3].value2 = null
      } else {
        array[3].value1 = ContarctData[1].value2
        array[3].value2 = ContarctData[1].value2
      }
      setInputLists(array)
      setInputLists2(array2)
      setInfoData(ContarctData)
      if (ContarctData[3].value === "자동갱신") {
        setFirstContractDate(array[3].value1)
      } else {
        setFirstContractDate(array[3].value2)
      }
    }
  }, [openDialog])

  useEffect(() => {
    if (edit) {
      setDragDropFile(editData[0].file)
      InputListsSet[0].file = editData[0].file
      InputListsSet[0].memo = editData[0].memo
      InputListsSet[0].subContract = editData[0].subContract
      InputListsSet[1].value1 = editData[1].value1
      InputListsSet[1].value2 = editData[1].value2
      InputListsSet[3].value = editData[3].value
      setInputLists(JSON.parse(JSON.stringify(InputListsSet)))
    }
  }, [edit])
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const handleClose = () => {
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    closeDialog()
    setOpen(false)
  }
  const handleClose2 = () => {
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    closeDialog2()
    setOpen(false)
  }
  const handleSelectChange = (index, title) => (e) => {
    let array = [...InputLists]
    array[index].value = e.target.value
    array[0].changed = true
    if (e.target.value === "상위 계약+1년") {
      array[5].value = "해지시 종료"
    }

    if (title === "계약 방식 :" && e.target.value === "직접입력") {
      let array = [...InputLists]
      array[3].type = "datePicker"
      array[5].value = "해지시 종료"
      array[5].items = ["해지시 종료", "자동갱신"]
      setInputLists(array)
    } else if (title === "계약 방식 :") {
      let array = [...InputLists]
      array[3].type = "info2"
      array[5].value = "해지시 종료"
      array[5].items = ["해지시 종료", "자동갱신"]
      setInputLists(array)
    }
    setInputLists(array)
  }
  const handleFilterDatas = (index, num) => (e) => {
    let array = [...InputLists]
    if (e) {
      if (e.toString() === "Invalid Date") {
        array[index][`error${num}`] = true
      } else {
        array[index][`error${num}`] = false
      }
    }

    if (num === 1) {
      array[index].value1 = e
    } else if (num === 2) {
      array[index].value2 = e
    }
    array[0].changed = true
    setInputLists(array)
  }
  const handleFilterDatas2 = (index, num) => (e) => {
    let array = [...InputLists2]
    if (e) {
      if (e.toString() === "Invalid Date") {
        array[index][`error${num}`] = true
      } else {
        array[index][`error${num}`] = false
      }
    }

    if (num === 1) {
      array[index].value = e
    } else if (num === 2) {
      array[index].value = e
    }
    array[0].changed = true
    setInputLists2(array)
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: String(myData.id),
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const imgupload = () => {
    document.getElementsByClassName("fileDragDropContract")[0].click()
  }

  const fileText = (data) => {
    if (data.length > 40) {
      let result = data.slice(0, 30) + "..." + data.slice(-7)

      return result
    } else {
      return data
    }
  }
  const fileListShow = () => {
    if (DragDropFile.name) {
      const fileName = DragDropFile.name
      const fileType = fileName.split(".")[fileName.split(".").length - 1]
      return (
        <SuiBox display="flex" alignItems="start" sx={{ height: "auto" }}>
          <IconButton sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }} className="cursorDefalut">
            {/* <GrDocumentPdf className="pdfColor" /> */}
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="dark"
            sx={{ pt: 0.8, pb: 0.3, cursor: DragDropFile.url ? "pointer" : "default" }}
            onClick={() => DownloadPdf(DragDropFile)}
          >
            {fileText(DragDropFile.name)}
          </SuiTypography>
          {DragDropFile.url ? (
            <div style={{ width: "150px" }}>
              <SuiButton
                sx={{
                  backgroundColor: "#EEEEEE",
                  ml: "auto",
                  minHeight: 24,
                  height: 24,
                  width: 24,
                  minWidth: 24,
                  p: 0,
                  ml: 1,
                  boxShadow: 0,
                }}
                onClick={() => showPdf(DragDropFile)}
              >
                <FiSearch size="15" />
              </SuiButton>
              {/* <SuiButton
                color="info"
                sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                onClick={() => DownloadPdf(DragDropFile)}
              >
                다운로드
              </SuiButton> */}
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else {
      return (
        <SuiBox
          justifyContent="center"
          display="flex"
          alignItems="start"
          sx={{ height: "auto", pt: 4 }}
        ></SuiBox>
      )
    }
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const openContract = (type) => {
    if (type === "renewal") {
      setInfoDialogOpen(true)
    }
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "info") {
      return (
        <SuiBox sx={{ width: "100%" }}>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 700,
              pt: 1.8,
              mb: 1,
              textDecoration: type === "renewal" ? "underline" : "",
            }}
            className={type === "renewal" ? "cursor" : ""}
            onClick={() => openContract(type)}
          >
            {data.value}
          </Card>
        </SuiBox>
      )
    } else if (data.type === "info2") {
      return (
        <SuiBox sx={{ width: "100%" }}>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              pt: 1.8,
              mb: 1,
              px: 2,
            }}
          >
            <SuiBox display="flex" width="100%">
              <BiCalendar size="26" />
              <SuiTypography sx={{ ml: "auto", fontSize: 18, fontWeight: 700 }}>
                {dayjs(data.value1).format("YY.MM.DD")}
              </SuiTypography>
            </SuiBox>
          </Card>
          <SuiTypography fontSize="14px" color="info" fontWeight="400" sx={{ pt: 2, pb: 1 }}>
            {data.title1}
          </SuiTypography>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              pt: 1.8,
              mb: 1,
              px: 2,
            }}
          >
            <SuiBox display="flex" width="100%">
              <BiCalendar size="26" />
              <SuiTypography sx={{ ml: "auto", fontSize: 18, fontWeight: 700 }}>
                {dayjs(data.value1).add(1, "years").format("YY.MM.DD")}
              </SuiTypography>
            </SuiBox>
          </Card>
        </SuiBox>
      )
    } else if (data.type === "picUpload") {
      return (
        <SuiBox>
          <SuiBox display="flex" alignItems="center">
            <DragDrop files={setDragDropFile} />
          </SuiBox>
          <SuiBox
            justifyContent="center"
            display="flex"
            alignItems="start"
            sx={{ height: "auto", mt: 2.5 }}
          >
            <SuiButton
              sx={{ height: 30, px: 2 }}
              color="info2"
              size="small"
              onClick={() => imgupload()}
            >
              파일선택
            </SuiButton>
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <SuiBox>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    } else if (data.type === "datePicker") {
      let array = [...InputLists]
      return (
        <SuiBox>
          <SuiBox sx={{ width: "100%", height: 65 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
              <DatePicker
                className="contract-date2"
                inputFormat={"YYYY-MM-DD"}
                value={data.value1}
                minDate={new Date(FirstContractDate)}
                onChange={handleFilterDatas(index, 1)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
          <SuiTypography fontSize="14px" fontWeight="400" color="text" sx={{ pt: 2, pb: 1 }}>
            {data.title1}
          </SuiTypography>
          <SuiBox sx={{ width: "100%", height: 65 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
              <DatePicker
                className="contract-date2"
                inputFormat={"YYYY-MM-DD"}
                value={data.value2}
                minDate={new Date(data.value1)}
                onChange={handleFilterDatas(index, 2)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "enddatePicker") {
      return (
        <SuiBox
          sx={{
            mb: index === 3 ? "86px" : "0px",
          }}
        >
          <SuiBox sx={{ width: "100%", height: 65 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
              <DatePicker
                className="contract-date2"
                inputFormat={"YYYY-MM-DD"}
                value={data.value}
                onChange={handleFilterDatas2(index, 2)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox sx={{ height: 60 }}>
          <FormControl
            fullWidth
            className={
              data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() =>
              data.title === "계약 방식 :"
                ? setFiltersOpen1(!FiltersOpen1)
                : setFiltersOpen2(!FiltersOpen2)
            }
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() =>
                    data.title === "계약 방식 :"
                      ? setFiltersOpen1(!FiltersOpen1)
                      : setFiltersOpen2(!FiltersOpen2)
                  }
                />
              )}
              open={data.title === "계약 방식 :" ? FiltersOpen1 : FiltersOpen2}
              // open={
              //   data.title === "계약 방식 :"
              //     ? FiltersOpen1
              //     : InputLists[1].value === "상위 계약+1년"
              //     ? false
              //     : FiltersOpen2
              // }
              value={data.value}
              onChange={handleSelectChange(index, data.title)}
              className="setting-border-radius"
            >
              {data.items.map((x) => {
                return (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    }
  }
  const backStep = () => {
    closeDialog()
    setOpen(false)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "flex-start" }}
      >
        <SuiBox>
          <SuiTypography
            fontSize="15px"
            color="text"
            sx={{ pt: 2, pb: 1, fontWeight: 400, color: "#668B9E" }}
          >
            {row.title}
          </SuiTypography>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const listsShow2 = InputLists2.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "flex-start" }}
      >
        <SuiBox>
          <SuiTypography
            fontSize="15px"
            color="text"
            sx={{ pt: 2, pb: 1, fontWeight: 400, color: "#668B9E" }}
          >
            {row.title}
          </SuiTypography>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const [wrong_date, setwrong_date] = useState(false)
  const createContractDc = () => {
    if (type === "renewal") {
      if (InputLists[3].error1 || InputLists[3].error2) {
        return setwrong_date(true)
      }
      InputLists[0].file = DragDropFile
      if (edit) {
        if (!DragDropFile.url) {
          InputLists[0].changed = true
        }
      }
      renewalData(InputLists)
    } else {
      if (InputLists2[3].error1 || InputLists2[3].error2) {
        return setwrong_date(true)
      }
      InputLists2[0].file = DragDropFile
      if (edit) {
        if (!DragDropFile.url) {
          InputLists2[0].changed = true
        }
      }
      renewalEndData(InputLists2)
    }
    handleClose2()
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <ContractInfoDialog
        openDialog={InfoDialogOpen}
        closeDialog={closeDialogInfo}
        infoData={InfoData}
      />
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 820 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                {type === "renewal" ? "갱신계약서 정보 등록" : "계약 종료 정보 등록"}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                {type === "renewal"
                  ? "계약 정보를 등록하고, 계약서 파일을 업로드합니다."
                  : "계약 정보를 등록하고, 계약서 파일을 업로드합니다."}
              </SuiTypography>
            </SuiBox>
            <Grid container alignItems="center" sx={{ px: 4 }}>
              {type === "renewal" ? listsShow : listsShow2}
            </Grid>
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ my: 4, px: 4 }}
          >
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                border: 1,
                borderColor: "#707070",
              }}
              onClick={() => backStep()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="primary"
              onClick={() => createContractDc()}
              sx={{ px: 2, py: 0.5, height: 40, minHeight: 40, width: 100 }}
              disabled={
                DragDropFile.name === "" ||
                InputLists[1].value1 === null ||
                InputLists[1].value2 === null
              }
            >
              {edit ? "수정" : "완료"}
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      <SuiDialog
        openDialog={wrong_date}
        closeDialog={() => setwrong_date(false)}
        MainTitle={"정보 입력 오류"}
        Content={"계약일 또는 계약 종료일을 다시 확인해 주세요."}
        CanBtnText={"닫기"}
      />
    </Dialog>
  )
}

ContractRenewalDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: [],
  type: "renewal",
  ContarctDatas: [],
}

ContractRenewalDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.array,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  renewalData: PropTypes.func,
  renewalEndData: PropTypes.func,
  type: PropTypes.string,
  ContarctData: PropTypes.array,
  ContarctDatas: PropTypes.array,
}
export default ContractRenewalDialog
