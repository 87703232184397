import { useState, useEffect } from "react"

// react-router-dom components
import { useNavigate } from "react-router-dom"

import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout"

import { useParams } from "react-router"

import { sendEmails, users } from "apollo/query"

import { useMutation, useQuery } from "@apollo/client"
import { updateUserPassword } from "apollo/mutation"

const Reset = () => {
  sessionStorage.removeItem("joara_token")
  const navigate = useNavigate()
  const [userID, setuserID] = useState(null)
  const [paramsAuthCode, setparamsAuthCode] = useState(useParams().id)
  const [AuthCode, setAuthCode] = useState("")
  const [password, setpassword] = useState("")
  const [password_error, setpassword_error] = useState(false)
  const [password_error_message, setpassword_error_message] = useState("")
  const [re_password, setre_password] = useState("")
  const [re_password_error, setre_password_error] = useState(false)
  const [re_password_error_message, setre_password_error_message] = useState("")
  const [openDialog, setOpenDialog] = useState(false)

  const {} = useQuery(users, {
    variables: {
      email: useParams().email,
      limit: 1,
    },
    onError() {
      navigate("/")
      alert("확인되지 않는 Email 입니다.")
    },
    onCompleted: (data) => {
      if (data.users.length > 0) {
        setuserID(data.users[0].id)
      } else {
        alert("확인되지 않는 Email 입니다.")
      }
    },
  })

  const { data } = useQuery(sendEmails, {
    variables: {
      usermail: useParams().email,
      type: "1",
      limit: 1,
    },
    onError() {
      navigate("/")
      alert("확인되지 않는 Email 입니다.")
    },
  })
  useEffect(() => {
    if (data) {
      if (data.sendEmails.length === 0) {
        navigate("/")
        alert("확인되지 않는 AuthCode 입니다.")
      } else {
        if (paramsAuthCode === data.sendEmails[0].authcode) {
          setAuthCode(data.sendEmails[0].authcode)
        } else {
          navigate("/")
          alert("확인되지 않는 AuthCode 입니다.")
        }
      }
    }
  }, [data])

  const [passwordResetActive] = useMutation(updateUserPassword, {
    variables: {
      id: userID,
      password: password,
    },
    onError() {
      setpassword("")
      setre_password("")
      alert("오류가 발생하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {
      setpassword("")
      setre_password("")
      setOpenDialog(true)
    },
  })
  const handleInput = (index) => (e) => {
    if (index === 1) {
      let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
      setpassword(e.target.value)
      if (!rePassword.test(e.target.value)) {
        setpassword_error(true)
        setpassword_error_message("*특수문자를 포함한 10자리 이상의 비밀번호가 아닙니다.")
      } else {
        setpassword_error(false)
        setpassword_error_message("")
      }
    } else {
      setre_password(e.target.value)
      if (e.target.value === password) {
        setre_password_error(false)
        setre_password_error_message("")
      } else {
        setre_password_error(true)
        setre_password_error_message("*비밀번호가 일치하지 않습니다.")
      }
    }
  }
  const closeDialog = () => {
    setOpenDialog(false)
    gotoLogin()
  }
  const gotoLogin = () => {
    navigate("/")
  }

  const passwordCheck = () => {
    let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
    if (!rePassword.test(password)) {
      setpassword_error(true)
      return setpassword_error_message("*특수문자를 포함한 10자리 이상의 비밀번호가 아닙니다.")
    } else if (password !== re_password) {
      setre_password_error(true)
      return setre_password_error_message("*비밀번호가 일치하지 않습니다.")
    } else {
      passwordResetActive()
    }
  }
  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      p={3}
      path="/reset-password"
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={5}>
          <SuiBox display="flex" justifyContent="center" mb={2}>
            <SuiTypography
              component="label"
              color="dark"
              fontWeight="bold"
              className="letter-0-8px"
            >
              비밀번호 재설정
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center">
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              {useParams().email} 의 비밀번호를 재설정합니다.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox>
          <SuiBox ml={0.5}>
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              새 비밀번호 :
            </SuiTypography>
          </SuiBox>
          <SuiInput
            error={password_error}
            helperText={password_error_message}
            type="password"
            placeholder=""
            value={password}
            onChange={handleInput(1)}
          />
        </SuiBox>
        <SuiBox>
          <SuiBox ml={0.5}>
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              새 비밀번호 확인 :
            </SuiTypography>
          </SuiBox>
          <SuiInput
            error={re_password_error}
            helperText={re_password_error_message}
            type="password"
            placeholder=""
            value={re_password}
            onChange={handleInput(2)}
          />
        </SuiBox>
        <SuiBox mt={3.5} mb={1}>
          <SuiButton
            color="primary"
            fullWidth
            onClick={() => passwordCheck()}
            sx={{
              fontSize: 14,
            }}
          >
            저장
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        MainTitle={"비밀번호 재설정 완료"}
        Content={"새로운 비밀번호가 설정되었습니다. 이제 새로운 비밀번호로 로그인하실 수 있습니다."}
        CanBtnText={"확인"}
      />
    </CoverLayout>
  )
}

export default Reset
