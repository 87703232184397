/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard"

// Soft UI Dashboard React icons
import Document from "examples/Icons/Document"
import SignIn from "layouts/authentication/sign-in"
import ResetPassword from "layouts/authentication/reset-password"
import Reset from "layouts/authentication/reset"
import WorkStatus from "layouts/work-status/"
import WriterManagement from "layouts/writerManagement/"
import DistributorManagement from "layouts/distributorManagement/"
import OutsourcingManagement from "layouts/outsourcingManagement/"
import EventsManagement from "layouts/eventsManagement/eventsManagement"
import MyPage from "layouts/mypage/"
import Notice from "layouts/eventsManagement/notice"
import NoticeComfirm from "layouts/eventsManagement/items/noticeComfirm"

// leftNav 항목별 아이콘
import { BiUserPin, BiBook, BiBuildings, BiCog, BiChevronLeft } from "react-icons/bi"
import { ImClipboard } from "react-icons/im"
import { BiGroup } from "react-icons/bi"
import { BsExclamationCircle } from "react-icons/bs"
import { BiCalendarEvent } from "react-icons/bi"

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/",
    icon: <Document size="18px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    icon: <Document size="18px" />,
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/reset/:email/:id",
    icon: <Document size="18px" />,
    component: <Reset />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "작품 현황",
    key: "WorkStatus",
    route: "/WorkStatus",
    icon: <BiBook size="20px" />,
    arrow: <BiChevronLeft size="25px" />,
    component: <WorkStatus />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "작가 관리",
    key: "WriterManagement",
    route: "/WriterManagement",
    icon: <BiUserPin size="20px" />,
    component: <WriterManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "유통사 관리",
    key: "DistributorManagement",
    route: "/DistributorManagement",
    icon: <BiBuildings size="20px" />,
    component: <DistributorManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "외주계약 관리",
    key: "OutsourcingManagement",
    route: "/OutsourcingManagement",
    icon: <ImClipboard size="20px" />,
    component: <OutsourcingManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "이벤트 관리",
    key: "EventsManagement",
    route: "/EventsManagement",
    icon: <BiCalendarEvent size="20px" />,
    component: <EventsManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "관리자",
    key: "admin",
    route: "/",
    icon: <BiGroup size="18px" color="black" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "설정",
    key: "setting",
    route: "/dashboard/3",
    icon: <BiCog size="20px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "info",
    key: "notice",
    route: "/dashboard/4",
    icon: <BsExclamationCircle size="18px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "마이페이지",
    key: "MyPage",
    route: "/MyPage",
    component: <MyPage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "이벤트 승인/비승인",
    key: "Notice",
    route: "/Notice/:code",
    icon: <ImClipboard size="20px" />,
    component: <Notice />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "이벤트 승인/비승인",
    key: "NoticeComfirm",
    route: "/noticeDetail/:code",
    icon: <ImClipboard size="20px" />,
    component: <NoticeComfirm />,
    noCollapse: true,
  },
]

export default routes
