import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import { useApolloClient } from "@apollo/client"

import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Dialog from "@mui/material/Dialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MobileStepper from "@mui/material/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"
import FileUploadDialog from "../FileUploadDialog"
import ReferrerDialog from "../referrerDialog"
import ReferrerCheck from "../referrerCheck"
import MeetingDialog from "../MeetingDialog"
import MeetingDetailDialog from "../MeetingDetailDialog"
import ContractDialog from "../ContractDialog"
import ContractRenewalDialog from "../ContractRenewalDialog"
import ContractChangeDialog from "../ContractChangeDialog"
import ContractSubDialog from "../ContractSubDialog"
import ContractChangeInfoDialog from "../ContractChangeInfoDialog"
import ContractChangeTitleInfoDialog from "../ContractChangeTitleInfoDialog"
import ContractMangerInfoDialog from "../ContractMangerInfoDialog"
import ContractDeleteDialog from "../ContractdeleteDialog"
import ContractInfoDialog from "../ContractInfoDialog"
import SubContractInfoDialog from "../SubContractInfoDialog"
import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiDialog from "components/SuiDialog"
import DeleteDialog from "../deleteDialog"
import DeleteDenyDialog from "../DeleteDenyDialog"
import ChangeDialog from "../ChangeDialog"
import GetFileIcon from "components/getFileIcon"
import Event from "layouts/eventsManagement/items/event"

import { BiCalendar, BiRecycle, BiImages } from "react-icons/bi"
import { BsFillArrowUpLeftSquareFill, BsFillCircleFill } from "react-icons/bs"
import { AiFillCloseCircle, AiOutlinePlus, AiOutlineUnorderedList } from "react-icons/ai"
import { CgUserAdd } from "react-icons/cg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { FiSearch, FiMoreHorizontal } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { RiDeleteBinLine } from "react-icons/ri"
import { MdClose } from "react-icons/md"
import { FaThLarge, FaTh } from "react-icons/fa"
import { AiOutlineCloseCircle } from "react-icons/ai"
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp"
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp"
import ContractInfosDialog from "../ContractInfosDialog_connect"
import DaumPostcode from "react-daum-postcode"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

// import ApexCharts from "apexcharts"
import ApexCharts from "react-apexcharts"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  updateWriter,
  createAttachment,
  updateAttachment,
  createMeeting,
  updateMeeting,
  createContract,
  updateContract,
  createContractDocument,
  updateContractDocument,
  createRequest,
  updateRequest,
  createRecent,
  createAlert,
  createChangeRequest,
  createWriterSignificant,
  updateWriterSignificant,
  createDonwloadLog,
  createSendEmail,
  updateChangeRequest,
} from "apollo/mutation"
import {
  writer,
  writers,
  changeRequests,
  users,
  me,
  writerContract,
  writerSignificants,
  settings,
  outsourcingHistories,
  companyContractsSearch,
  users_list,
  eventReceviers,
  events,
  contracts,
  search_bookcode_contracts,
  eventEmailNoDel,
  eventResultEmailNoDel,
} from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../../../reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function EnhancedTableHead2(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "계약유형",
    },
    {
      id: "work",
      numeric: false,
      disablePadding: false,
      label: "계약 담당자",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "계약 일자",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "계약 종료일",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "subCount",
      numeric: false,
      disablePadding: false,
      label: "부속계약(수)",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "계약 일자" || headCell.label === "부속계약(수)"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span style={{ cursor: "default" }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead2.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead2.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}
function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "버전",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "유형",
    },
    {
      id: "work",
      numeric: false,
      disablePadding: false,
      label: "내용",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "등록자",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "등록일",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "형식",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "내용" ||
                headCell.label === "등록자" ||
                headCell.label === "형식"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}
function EnhancedTableHead3(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "번호",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "summation",
      numeric: false,
      disablePadding: false,
      label: "누적 이벤트 진행",
    },
    {
      id: "complete",
      numeric: false,
      disablePadding: false,
      label: "이벤트 완료",
    },
    {
      id: "ing",
      numeric: false,
      disablePadding: false,
      label: "진행중인 이벤트",
    },
    {
      id: "expected",
      numeric: false,
      disablePadding: false,
      label: "이벤트 예정",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft: "1px solid #F1F1F5",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead3.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead3.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}

function EnhancedTableHead4(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "번호",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "reward",
      numeric: false,
      disablePadding: false,
      label: "개별 리워드",
    },
    {
      id: "eventTitle",
      numeric: false,
      disablePadding: false,
      label: "이벤트 명",
    },
    {
      id: "flatform",
      numeric: false,
      disablePadding: false,
      label: "플랫폼",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "일정(시작 일 ~ 종료 일)",
    },
    {
      id: "etc",
      numeric: false,
      disablePadding: false,
      label: "상세보기",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft: "1px solid #F1F1F5",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead4.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead4.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}
function TabPanel(props) {
  const handleStepChange = (step, ContractIndex, index) => {
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].activeStep = step
    setContractDatas(array)
  }
  const [ContractInfosDialogOpenBool, setContractInfosDialogOpenBool] = useState(false)
  const [ContractInfosData, setContractInfosData] = useState([])
  const [FilesSorting, setFilesSorting] = useState("최신순")
  const [FilesSortingMetting, setFilesSortingMetting] = useState("최신순")
  const [FilesSortingContract, setFilesSortingContract] = useState("최신순")
  const [SubContractSorting, setSubContractSorting] = useState("최신순")
  const [TeamSelect, setTeamSelect] = useState("콘텐츠사업본부")
  const [addressOpen, setaddressOpen] = useState(false)
  const [referrerIds, setreferrerIds] = useState([])
  const [historySplice, sethistorySplice] = useState(5)
  const [historySplice2, sethistorySplice2] = useState(10)
  const [historySplice3, sethistorySplice3] = useState(10)
  const [historySplice4, sethistorySplice4] = useState(10)
  const [createContractShow, setcreateContractShow] = useState(false)
  const [createShow, setcreateShow] = useState(false)
  const [deleteContractIds, setdeleteContractIds] = useState({})
  const { myData } = useSelector((state) => state.store)
  const [writer_rows, setwriter_rows] = useState([])
  const [, updateState] = useState()
  const {
    children,
    value,
    index,
    inputListsExport,
    referrerIdsExport,
    AttachmentDatasExport,
    MeetingDatasExport,
    editId,
    type,
    edit,
    SignificantsDatasExport,
  } = props
  const [typeData, settypeData] = useState(type)
  const client = useApolloClient()
  useEffect(() => {
    settypeData(type)
  }, [type])
  const addFilesBtn = () => {
    seteditBool(false)
    setFileUploadDialogBool(true)
  }

  useEffect(async () => {
    // 원래 useLazyEffect 였지만 useEffect로 바꿈
    // settings_Call 이후에 다른 로직이 실행되길 원해서 - 전현균
    await settings_Call()
    OutsourcingHistoriesActive()
    CompanyContractsSearchActive()
  }, [])
  useEffect(async () => {
    // 기존 openDialog시 호출했었지만 호출속도관계로 이벤트 현황 탭을 눌렀을때만 호출할 수 있도록 변경
    // - 성시헌
    if (value === 2) {
      setTimeout(async () => {
        await EventReceviersActive(currentYear, currentMonth)
      }, 500)
    }
  }, [value])
  const tooltipIndex = useRef(null)
  const [outsourcingHistories_Datas, setoutsourcingHistories_Datas] = useState([])
  const [companyContract_Datas, setcompanyContract_Datas] = useState([])

  /****바 차트 옵션 기본값
 @author 전현균 
 */
  const chart_data = {
    colors: [
      function ({ value }) {
        if (value === 1) {
          return "#B3E5FC"
        } else if (value === 2) {
          return "#81D4FA"
        } else if (value === 3) {
          return "#4FC3F7"
        } else if (value === 4) {
          return "#03A9F4"
        } else if (value === 5) {
          return "#03A9F4"
        } else if (value === 6) {
          return "#039BE5"
        } else if (value === 7) {
          return "#0288D1"
        } else if (value === 7) {
          return "#0288D1"
        } else if (value < 1) {
          return "#9ba5a7"
        } else {
          return "#0277BD"
        }
      },
    ],
    chart: {
      type: "bar",
      // height: 350,

      background: "#f8f9fb",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        columnWidth: "90%",
        dataLabels: {
          total: {
            formatter: (e) => {
              if (e < 1) {
                return 0
              } else {
                return e
              }
            },
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      show: true,
      colors: ["#f8f9fb"],
      width: 0.5,
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      x: {
        show: false,
      },
      y: {
        formatter: function (value) {
          return Math.floor(value)
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      axisTicks: { show: false },
      categories: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      axisBorder: {
        show: true,
        color: "#9ba5a7",
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      // max: 1,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
  }

  // 도넛차트 호버 작품명 색깔 구분 함수
  function formatLabelText(label, color) {
    const pattern = /([^:\n]+)(?=\s*:)/g // ':'이전 모든 문자열에 색깔을 입히는 정규식
    const formattedLabel = label.replace(pattern, `<span style='color:${color}'>$1</span>`) // ':' 이후 숫자는 색깔을 입히지 않음
    return formattedLabel
  }
  /****도넛 차트 옵션 기본값 
 @author 전현균 
 */
  const chart_data_donut = {
    chart: {
      type: "donut",
    },
    stroke: {
      width: 0,
    },

    labels: [],
    dataLabels: {
      enabled: false,
    },

    colors: [],
    legend: { show: false },

    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "light",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //작품명
        const label = w.config.labels[seriesIndex]
        //작품색깔
        const color = w.config.colors[seriesIndex]
        //작품명 색깔 입히는 함수실행
        const formattedLabel = formatLabelText(label, color)
        return (
          '<div class="arrow_box" style="white-space:pre;">' +
          "<span>" +
          formattedLabel +
          "</span>" +
          "</div>"
        )
      },
      marker: {
        show: true,
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return ""
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
    },
  }

  const [yearChartData, setYearChartData] = useState([]) // 누적 참여현황 데이터 저장
  const [donutchartDataTotal, setDonutChartDataTotal] = useState(0) // 도넛 차트 총 건수

  const [barChartSeries, setBarChartSeries] = useState([]) // 바 차트 아이템
  const [barChartOption, setBarChartOption] = useState(chart_data) // 바 차트 옵션

  const [donutChartSeries, setDonutChartSeries] = useState([]) // 도넛 차트 아이템
  const [donutChartOption, setDonutChartOption] = useState(chart_data_donut) // 도넛 차트 옵션
  const [donutChartLegend, setDonutChartLegend] = useState([]) // 도넛 차트 범례

  const month_list = [
    "전체",
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ]

  const month_value_list = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

  const [chart_month, setChart_month] = useState("전체") // 차트 월
  const [chart_year, setChart_year] = useState(dayjs(new Date()).format("YYYY")) // 차트 년도
  const monthSelects = month_list.map((x) => {
    return (
      <MenuItem value={x} key={x} sx={{ minWidth: 80 }}>
        {x}
      </MenuItem>
    )
  })
  /****도넛 차트 범례 리턴 
 @author 전현균 
 @returns 도넛 차트 범례 리스트
 */
  const donutLegendList = donutChartLegend.map((x, index) => {
    return (
      <SuiBox display="flex" className="legend_item" key={x.index}>
        {x.name === "참여 작품 없음" ? (
          <div className="legend_name_empty">{x.name}</div>
        ) : (
          <>
            <div className="legend_color" style={{ background: x.color }}></div>
            <div className="legend_name">{x.name}</div>
            <div className="legend_count">{x.count}</div>
          </>
        )}
      </SuiBox>
    )
  })

  const [EventDatas, setEventDatas] = useState([])
  const [EventReceiversData, setEventReceiversData] = useState([])
  const [EventReceiversIndividualData, setEventReceiversIndividualData] = useState([])
  const [eventBookCodeId, setEventBookCodeId] = useState("")

  //이벤트 현황 API
  const EventReceviersActive = async (currentYear, currentMonth) => {
    //작가의 계약작품 리스트 호출 API

    await client
      .query({
        query: contracts,
        variables: {
          writer: editId.toString(),
        },
        fetchPolicy: "network-only",
      })
      .then(async (contractRes) => {
        if (contractRes.data.contracts.length > 0) {
          //초기 호출떄 작가의 작품 id를 저장
          setEventBookCodeId(editId.toString())
          // 이벤트 누적 참여 현황 호출 함수(연도별)
          await EventReceiversDataSet(editId.toString(), currentYear, "table,chart")
          // 이벤트 참여작품 호출 함수(연도-월 별)
          await EventReceiversIndividualDataSet(editId.toString(), currentYear, currentMonth)
        } else {
          setEventReceiversIndividualData([])
          setEventReceiversData([])

          // 차트 데이터 세팅
          let chart_option = { ...chart_data }
          chart_option.yaxis.max = 5
          chart_option.tooltip.enabled = false
          chart_option.tooltip.enabledOnSeries = undefined
          setBarChartSeries([
            {
              name: "",
              data: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
            },
          ])
          setBarChartOption(chart_option)
          setYearChartData([])
          donutChartDataSet([], chart_year, chart_month)
          setChart_month("전체")
        }
        dispatch(loading_Apply(false))
      })
  }

  // 이벤트 누적 참여 현황 호출 함수(연도별)
  const EventReceiversDataSet = async (bookCodeId, currentYear, callType) => {
    dispatch(loading_Apply(true))
    if (bookCodeId === "" && eventBookCodeId.length > 0) {
      bookCodeId = eventBookCodeId
    }

    let receiversData = []
    //작가의 계약작품 중 승인된 이벤트가 있는 작품 호출 API
    let startOfYear = dayjs(new Date(currentYear)).startOf("year").format("YYYY-MM-DD")
    let endOfYear = dayjs(new Date(currentYear)).endOf("year").format("YYYY-MM-DD")

    await client
      .query({
        query: eventReceviers,
        variables: {
          writerId: bookCodeId,
          _or: [
            { mailType: "info", access: "access" },
            { mailType: "result", result_status: "완료" },
          ],
        },
        fetchPolicy: "network-only",
      })
      .then(async (receiverRes) => {
        if (receiverRes.data.eventReceviers.length > 0) {
          let receiverData = JSON.parse(JSON.stringify(receiverRes.data.eventReceviers))
          let current_data = []
          // 삭제된 이메일 부분 제거
          for (let i = 0; i < receiverData.length; i++) {
            if (receiverData[i].mailType === "info") {
              await client
                .query({
                  query: eventEmailNoDel,
                  variables: {
                    id: receiverData[i].eventEmailId,
                  },
                  fetchPolicy: "network-only",
                })
                .then((resData) => {
                  if (resData.data.eventEmails.length > 0) {
                    current_data.push(receiverData[i])
                  }
                })
            } else {
              await client
                .query({
                  query: eventResultEmailNoDel,
                  variables: {
                    id: receiverData[i].eventEmailId,
                  },
                  fetchPolicy: "network-only",
                })
                .then((resData) => {
                  if (resData.data.eventResultEmails.length > 0) {
                    current_data.push(receiverData[i])
                  }
                })
            }
          }

          current_data.forEach((data) => {
            data.events = []
          })
          receiversData = current_data
        }
      })
    if (receiversData.length > 0) {
      let originalEventId = receiversData.map((x) => x.eventId)
      let startOfYear = dayjs(new Date(currentYear)).startOf("year").format("YYYY-MM-DD")
      let endOfyear = dayjs(new Date(currentYear)).endOf("year").format("YYYY-MM-DD")
      // 승인된 이벤트가 무엇인지 호출하는 API

      await client
        .query({
          query: events,
          variables: {
            id: originalEventId,
            start: startOfYear,
            start_lte: endOfyear,
          },
          fetchPolicy: "network-only",
        })
        .then((eventRes) => {
          if (eventRes.data.events.length !== 0) {
            let eventsData = eventRes.data.events

            for (let i = 0; i < eventsData.length; i++) {
              const event = eventsData[i]

              const receiversWithEvent = receiversData.filter(
                (receiver) => receiver.eventId === event.id
              )

              for (const receiver of receiversWithEvent) {
                receiver.events.push(event)
              }
            }
          }
        })
      // 동일 작품에 같은 결과보고가 두번이상 걸려있는 경우 제거
      const filteredReceiversData = Array.from(
        new Set(
          receiversData.map((item) =>
            JSON.stringify({ bookCode: item.bookCode, eventId: item.eventId })
          )
        )
      ).map((str) => {
        const { bookCode, eventId } = JSON.parse(str)
        // 처음 발견된 항목의 인덱스를 찾음
        const index = receiversData.findIndex(
          (item) => item.bookCode === bookCode && item.eventId === eventId
        )
        // 해당 인덱스의 객체 반환
        return receiversData[index]
      })

      receiversData = filteredReceiversData

      // 이벤트가 완료인지, 진행중인지, 예정인지 판단
      let li = []

      for (let i = 0; i < receiversData.length; i++) {
        // 이벤트리시버에 같은 이벤트 중복 제거
        receiversData[i].events = receiversData[i].events
          ? receiversData[i].events.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
          : []

        // li에 아무것도 없을경우
        if (li.length === 0) {
          if (receiversData[i].events.length > 0) {
            if (receiversData[i].events[0].status === "cancle") {
              receiversData[i]["cancle"] = 1
            } else if (
              dayjs(receiversData[i].events[0].start).format("YY-MM-DD") >
              dayjs(new Date()).format("YY-MM-DD")
            ) {
              receiversData[i]["expected"] = 1
            } else if (
              dayjs(receiversData[i].events[0].start).format("YY-MM-DD") <=
                dayjs(new Date()).format("YY-MM-DD") &&
              dayjs(receiversData[i].events[0].end).format("YY-MM-DD") >=
                dayjs(new Date()).format("YY-MM-DD")
            ) {
              receiversData[i]["ing"] = 1
            } else if (
              dayjs(receiversData[i].events[0].end).format("YY-MM-DD") <
              dayjs(new Date()).format("YY-MM-DD")
            ) {
              receiversData[i]["complete"] = 1
            }
            li.push(receiversData[i])
          }
        } else {
          if (li.findIndex((x) => x.bookCode === receiversData[i].bookCode) > -1) {
            // 추가되어있는 이벤트리시버에 추가되는 이벤트리시버의 이벤트 추가
            li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)].events = li[
              li.findIndex((x) => x.bookCode === receiversData[i].bookCode)
            ].events.concat(receiversData[i].events)

            // 해당 이벤트리시버의 이벤트에서 이벤트 중복 제거
            li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)].events = li[
              li.findIndex((x) => x.bookCode === receiversData[i].bookCode)
            ].events.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)

            //이벤트가 취소일 경우
            if (receiversData[i].events.length > 0) {
              if (receiversData[i].events[0].status === "cancle") {
                if (li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["cancle"]) {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["cancle"]++
                } else {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["cancle"] = 1
                }
                //이벤트의 날짜가 현재와 비교했을떄 예정일경우
              } else if (
                dayjs(receiversData[i].events[0].start).format("YY-MM-DD") >
                dayjs(new Date()).format("YY-MM-DD")
              ) {
                if (li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["expected"]) {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["expected"]++
                } else {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["expected"] = 1
                }
                //이벤트의 날짜가 현재와 비교했을떄 진행중일경우
              } else if (
                dayjs(receiversData[i].events[0].start).format("YY-MM-DD") <=
                  dayjs(new Date()).format("YY-MM-DD") &&
                dayjs(receiversData[i].events[0].end).format("YY-MM-DD") >=
                  dayjs(new Date()).format("YY-MM-DD")
              ) {
                if (li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["ing"]) {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["ing"]++
                } else {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["ing"] = 1
                }
                //이벤트의 날짜가 현재와 비교했을떄 완료일경우
              } else if (
                dayjs(receiversData[i].events[0].end).format("YY-MM-DD") <
                dayjs(new Date()).format("YY-MM-DD")
              ) {
                if (li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["complete"]) {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["complete"]++
                } else {
                  li[li.findIndex((x) => x.bookCode === receiversData[i].bookCode)]["complete"] = 1
                }
              }
            }
            // 작가가 갖고있는 다른 작품의 이벤트를 비교하기 시작할때
          } else {
            if (receiversData[i].events.length > 0) {
              if (receiversData[i].events[0].status === "cancle") {
                receiversData[i]["cancle"] = 1
              } else if (
                dayjs(receiversData[i].events[0].start).format("YY-MM-DD") >
                dayjs(new Date()).format("YY-MM-DD")
              ) {
                receiversData[i]["expected"] = 1
              } else if (
                dayjs(receiversData[i].events[0].start).format("YY-MM-DD") <=
                  dayjs(new Date()).format("YY-MM-DD") &&
                dayjs(receiversData[i].events[0].end).format("YY-MM-DD") >=
                  dayjs(new Date()).format("YY-MM-DD")
              ) {
                receiversData[i]["ing"] = 1
              } else if (
                dayjs(receiversData[i].events[0].end).format("YY-MM-DD") <
                dayjs(new Date()).format("YY-MM-DD")
              ) {
                receiversData[i]["complete"] = 1
              }
              li.push(receiversData[i])
            }
          }
        }
      }
      if (callType.indexOf("table") > -1) {
        // 테이블(이벤트 누적현황) 경우 use state 세팅
        setEventReceiversData(li)
      }
      if (callType.indexOf("chart") > -1) {
        // 차트(이벤트 현황) 경우

        // 바 차트 데이터 세팅
        let barChart_items = li.map((item) => {
          return { ...item, name: li.title }
        })

        let totalCount = 0 // 총 참여 건수
        let zero_item = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // 0건 있는 월 탐색 배열
        for (let index = 0; index < barChart_items.length; index++) {
          let chart_item = barChart_items[index]

          // 작품이 해당 연도에 참여한 이벤트를 시작일의 월별로 나누어서 배열로 리턴
          let item_count = month_value_list.map((month, index) => {
            let month_dayjs = dayjs(`${currentYear}-${month}-11`)

            let month_count = chart_item.events.filter((event) => {
              let event_dayjs = dayjs(event.start)

              return month_dayjs.isSame(event_dayjs, "month")
            })
            zero_item[index] += month_count.length
            return month_count.length
          })

          // 월별 건수 합산해서 총 참여건수에 추가
          const result = item_count.reduce((sum, num) => sum + num)
          totalCount += result

          // 차트 데이터에 차트 데이터 형식 맞춰서 세팅
          barChart_items[index] = { name: chart_item.title, data: item_count }
        }

        let max_Count = 5 // 가장 참여가 많은 월의 건수
        if (barChart_items.length > 0) {
          let barchart_item_values = barChart_items.map((el) => el.data) // 차트 아이템의 값 부분만 추출
          let barchart_item_sums = barchart_item_values[0].map((x, idx) =>
            // 월 별로 모든 데이터의 합을 배열로 구함
            barchart_item_values.reduce((sum, curr) => sum + curr[idx], 0)
          )

          max_Count = Math.max(...barchart_item_sums) // 월별 모든 합의 최대값
        }

        let zeroMonthYn = zero_item.filter((el) => el === 0) // 0건이 있는 월 확인
        if (zeroMonthYn.length > 0) {
          // 0건이 있는 월 있는 경우
          let zero_data = zero_item.map((el) => {
            // 0건 월의 데이터 세팅
            if (el === 0) {
              el = max_Count * 0.02
            } else {
              el = 0
            }
            return el
          })
          let zero_chart = { name: "", data: zero_data }

          // 바차트 아이템에 추가
          barChart_items.push(zero_chart)
        }

        setYearChartData(li)
        donutChartDataSet(li, currentYear, "전체")
        setChart_month("전체")

        if (totalCount === 0) {
          // 1년간 1건도 없으면 0 처리
          let chart_option = { ...chart_data }
          chart_option.yaxis.max = 5
          chart_option.tooltip.enabled = false
          chart_option.tooltip.enabledOnSeries = undefined

          setBarChartSeries([
            {
              name: "",
              data: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
            },
          ])
          setBarChartOption(chart_option)
        } else {
          // 1건 이상 있는 경우 차트 데이터 세팅
          let chart_option = { ...chart_data }
          chart_option.yaxis.max = max_Count
          chart_option.tooltip.enabled = true

          let enabled_index = barChart_items
            // 툴팁 보여줄 아이템 세팅
            .map((el, index) => {
              if (el.name) {
                return index
              } else {
                return null
              }
            })
            .filter((el) => el !== null)

          chart_option.tooltip.enabledOnSeries = enabled_index
          setBarChartSeries(barChart_items)
          setBarChartOption(chart_option)
        }
      }
    } else {
      if (callType.indexOf("table") > -1) {
        // 테이블(이벤트 누적현황) 경우 use state 세팅
        setEventReceiversIndividualData([])
        setEventReceiversData([])
      }
      if (callType.indexOf("chart") > -1) {
        // 차트(이벤트 현황) 경우
        let chart_option = { ...chart_data }
        chart_option.yaxis.max = 5
        chart_option.tooltip.enabled = false
        chart_option.tooltip.enabledOnSeries = undefined
        setBarChartSeries([
          {
            name: "",
            data: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
          },
        ])
        setBarChartOption(chart_option)
        setYearChartData([])
        donutChartDataSet([], currentYear, "전체")
        setChart_month("전체")
      }
    }

    dispatch(loading_Apply(false))
  }

  //이벤트 더보기를 위한 총갯수 지정
  const [eventCount, setEventCount] = useState(0)
  // 이벤트 참여작품 호출 함수(연도-월 별)
  const EventReceiversIndividualDataSet = async (bookCodeId, currentYear, currentMonth) => {
    dispatch(loading_Apply(true))
    if (bookCodeId.length === 0 && eventBookCodeId.length > 0) {
      bookCodeId = eventBookCodeId
    }
    let receiversIndividualData = []
    let startOfYear = dayjs(new Date(currentYear)).startOf("year").format("YYYY-MM-DD")
    let endOfYear = dayjs(new Date(currentYear)).endOf("year").format("YYYY-MM-DD")

    await client
      .query({
        query: eventReceviers,
        variables: {
          writerId: bookCodeId,
          _or: [
            { mailType: "info", access: "access" },
            { mailType: "result", result_status: "완료" },
          ],
        },
        fetchPolicy: "network-only",
      })
      .then(async (receiverRes) => {
        if (receiverRes.data.eventReceviers.length > 0) {
          let receiverData = JSON.parse(JSON.stringify(receiverRes.data.eventReceviers))
          let current_data = []
          // 삭제된 이메일 부분 제거
          for (let i = 0; i < receiverData.length; i++) {
            if (receiverData[i].mailType === "info") {
              await client
                .query({
                  query: eventEmailNoDel,
                  variables: {
                    id: receiverData[i].eventEmailId,
                  },
                  fetchPolicy: "network-only",
                })
                .then((resData) => {
                  if (resData.data.eventEmails.length > 0) {
                    current_data.push(receiverData[i])
                  }
                })
            } else {
              await client
                .query({
                  query: eventResultEmailNoDel,
                  variables: {
                    id: receiverData[i].eventEmailId,
                  },
                  fetchPolicy: "network-only",
                })
                .then((resData) => {
                  if (resData.data.eventResultEmails.length > 0) {
                    current_data.push(receiverData[i])
                  }
                })
            }
          }

          current_data.forEach((data) => {
            data.events = []
          })
          receiversIndividualData = current_data
        }
      })

    if (receiversIndividualData.length > 0) {
      let originalEventId = receiversIndividualData.map((x) => x.eventId)
      let startOfMonth = dayjs(new Date(`${currentYear}-${currentMonth}`))
        .startOf("month")
        .format("YYYY-MM-DD")
      let endOfMonth = dayjs(new Date(`${currentYear}-${currentMonth}`))
        .endOf("month")
        .format("YYYY-MM-DD")

      await client
        .query({
          query: events,
          variables: {
            id: originalEventId,
            start: startOfMonth,
            start_lte: endOfMonth,
          },
          fetchPolicy: "network-only",
        })
        .then((eventRes) => {
          if (eventRes.data.events.length !== 0) {
            let eventsData = eventRes.data.events
            for (let i = 0; i < eventsData.length; i++) {
              const event = eventsData[i]

              const receiversWithEvent = receiversIndividualData.filter(
                (receiver) => receiver.eventId === event.id
              )

              for (const receiver of receiversWithEvent) {
                receiver.events.push(event)
              }
            }
          }
        })

      // 동일 작품에 같은 결과보고가 두번이상 걸려있는 경우 제거
      const filteredReceiversData = Array.from(
        new Set(
          receiversIndividualData.map((item) =>
            JSON.stringify({ bookCode: item.bookCode, eventId: item.eventId })
          )
        )
      ).map((str) => {
        const { bookCode, eventId } = JSON.parse(str)
        // 처음 발견된 항목의 인덱스를 찾음
        const index = receiversIndividualData.findIndex(
          (item) => item.bookCode === bookCode && item.eventId === eventId
        )
        // 해당 인덱스의 객체 반환
        return receiversIndividualData[index]
      })
      //월별 데이터가 바뀔때마다 event총count 초기화
      setEventCount(0)

      // receiversData에 이벤트의 갯수를 세는 함수
      for (let i = 0; i < filteredReceiversData.length; i++) {
        if (filteredReceiversData[i].events.length > 0) {
          setEventCount((prev) => prev + 1)
        }
      }

      // 작품이 참여한 이벤트들을 전부 알아보기위해 세팅
      setEventReceiversIndividualData(filteredReceiversData)
    } else {
      setEventReceiversIndividualData([])
    }
    setTimeout(() => {
      dispatch(loading_Apply(false))
    }, 1000)
  }

  /****도넛 차트 데이터 변경 로직
 @param {array} data 연간 누적 데이터
 @param {string} currentYear 현재 년도
 @param {string} currentMont 현재 월
 @author 전현균 
 */
  const donutChartDataSet = (data, currentYear, currentMonth) => {
    // 도넛 차트 데이터 세팅
    let donutChart_items = data.map((item) => {
      return { ...item, name: data.title }
    })

    let donut_items = [] // 도넛차트 아이템
    let donut_labels = [] // 도넛차트 라벨
    let donut_colors = [] // 도넛차트 아이템 별 색깔
    let donut_legend = [] // 도넛 차트 범례

    let donut_events = donutChart_items
      // 연간 누적 데이터로 이벤트만 추출
      .map((el) => {
        if (el.events) {
          return el.events
        } else {
          return []
        }
      })
      .flat(1)

    let sttings_data =
      settings_ref && settings_ref.current && settings_ref.current.eventFlatform
        ? settings_ref.current.eventFlatform
        : []

    for (let index = 0; index < sttings_data.length; index++) {
      // 이벤트 플랫폼으로 Loop 해서 도넛 차트 데이터 세팅
      let flatform = sttings_data[index]

      let flatform_item = donut_events.filter((event) => {
        // 날짜가 포함되고, 플랫폼에 해당하는 이벤트 필터
        let event_dayjs = dayjs(event.start)

        let sameYn // 날짜 포함되는지 유무

        // 날짜 포함 확인 로직
        if (currentMonth === "전체") {
          let check_dayjs = dayjs(`${currentYear}-01-01`)
          sameYn = check_dayjs.isSame(event_dayjs, "year")
        } else {
          let check_dayjs = dayjs(`${currentYear}-${currentMonth.replace("월", "")}-01`)
          sameYn = check_dayjs.isSame(event_dayjs, "month")
        }

        // 날짜가 포함되고, 플랫폼에 해당하는 데이터 리턴
        return sameYn && event.flatform === flatform.value
      })
      if (flatform_item.length > 0) {
        let dataArray = data.map((item) => {
          return {
            title: item.title,
            eventId: item.events.map((event) => event.id),
          }
        })
        let includedStrings = []
        // 이미 처리한 flatform_id를 추적하기 위한 배열
        let processedIds = []

        flatform_item.forEach((flatform) => {
          // 이미 처리한 flatform_id인 경우 패스
          if (processedIds.includes(flatform.id)) {
            return
          }

          let foundTitles = []

          dataArray.forEach((dataFilter) => {
            let eventArray = dataFilter.eventId
            if (eventArray.indexOf(flatform.id) > -1) {
              // 현재 flatform_id에 해당하는 title의 중복 수를 확인
              let titleCount = includedStrings.filter((str) =>
                str.includes(dataFilter.title)
              ).length
              let newTitle = `${dataFilter.title} : ${titleCount + 1}`

              if (!foundTitles.includes(dataFilter.title)) {
                // 중복된 title이 없는 경우, 이전 값들 중 해당 title 제거하고 새로운 값 추가
                includedStrings = includedStrings.filter((str) => !str.includes(dataFilter.title))
                includedStrings.push(newTitle)
                foundTitles.push(dataFilter.title)
              } else {
                // 이미 중복된 title이 있는 경우, 이전 값 중 해당 title 제거하고 새로운 값 추가
                let indexToRemove = includedStrings.findIndex((str) =>
                  str.includes(dataFilter.title)
                )
                includedStrings.splice(indexToRemove, 1)
                let newCount = titleCount + 1
                newTitle = `${dataFilter.title} : ${newCount}`
                includedStrings.push(newTitle)
              }
            }
          })

          // 처리한 flatform_id를 추적 배열에 추가
          processedIds.push(flatform.id)
        })

        // 라벨명 정렬(숫자기준으로)
        includedStrings.sort(function (a, b) {
          let numA = parseInt(a.match(/\d+/)[0])
          let numB = parseInt(b.match(/\d+/)[0])
          return numA - numB
        })

        donut_items.push(flatform_item.length)
        donut_labels.push(includedStrings.join("\n"))
        donut_colors.push(flatform.color)
        donut_legend.push({
          name: flatform.value,
          color: flatform.color,
          count: flatform_item.length,
        })
      }
    }
    let totalCount = donut_items.reduce((sum, num) => sum + num, 0) // 총 이벤트 건수
    setDonutChartDataTotal(totalCount)
    if (totalCount === 0) {
      // 총 이벤트 건 수 0 인 경우 세팅
      let chart_option = { ...chart_data_donut }
      chart_option.labels = []
      chart_option.colors = ["#cdd2d3"]
      chart_option.tooltip.enabled = false
      chart_option.states = {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      }

      setDonutChartSeries([1])
      setDonutChartOption(chart_option)
      setDonutChartLegend([{ name: "참여 작품 없음", color: "#fff" }])
    } else {
      // 총 이벤트 건 수 0 이상 경우 세팅
      let chart_option = { ...chart_data_donut }
      chart_option.labels = donut_labels
      chart_option.colors = donut_colors
      chart_option.tooltip.enabled = true
      chart_option.states = {
        hover: {
          filter: {
            type: "lighten",
            value: 0.15,
          },
        },
        active: {
          filter: {
            type: "darken",
            value: 0.35,
          },
        },
      }

      setDonutChartSeries(donut_items)
      setDonutChartOption(chart_option)
      setDonutChartLegend(donut_legend)
    }
  }
  /****explain 차트 월 변경 이벤트
 @param {object} e 이벤트 params
 @author 전현균 
 */
  const change_chart_month = (e) => {
    setChart_month(e.target.value)
    donutChartDataSet(yearChartData, chart_year, e.target.value)
  }
  // 이벤트 참여 작품 상세보기
  const handleDetail = (data) => {
    let event_detail_date
    myEventActive({ variables: { id: data } }).then((res) => {
      event_detail_date = res.data.events[0]
    })
    if (myData.role.name === "bronze") {
      const InfoAlertSet1 = {
        dialog: true,
        title: "권한없음",
        content: "열람권한이 없습니다.",
        CanBtnText: "확인",
      }
      setInfoAlert(InfoAlertSet1)
    } else {
      set_edit_event_id(data)
      set_type_event("detail")
      set_event_dialog(true)
    }
  }
  const [edit_event_id, set_edit_event_id] = useState(null)
  const [type_event, set_type_event] = useState("create")
  const [event_dialog, set_event_dialog] = useState(false)
  const [myEventActive] = useLazyQuery(events, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const closeWorksDialog = async () => {
    await EventReceviersActive(currentYear, currentMonth)
    set_event_dialog(false)
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  //이벤트현황 현재 년도, 월 컨트롤하는곳
  const [currentYear, setCurrentYear] = useState(dayjs(new Date()).format("YYYY"))
  const [currentMonth, setCurrentMonth] = useState(dayjs(new Date()).format("MM"))

  const handleLeftIcon = async (dayType) => {
    if (dayType === "month") {
      const newMonth = dayjs(currentMonth, "MM").subtract(1, "month").format("MM")
      setCurrentMonth(newMonth)
      //월별데이터가 바뀔때 historySplice를 초기화하여 더보기 기능 재활성화
      sethistorySplice4(10)
      await EventReceiversIndividualDataSet("", currentYear, newMonth)
    } else {
      const newYear = dayjs(currentYear, "YYYY").subtract(1, "year").format("YYYY")
      setCurrentYear(newYear)
      await EventReceiversDataSet("", newYear, "table")
      await EventReceiversIndividualDataSet("", newYear, currentMonth)
    }
  }

  const handleRightIcon = async (dayType) => {
    if (dayType === "month") {
      const newMonth = dayjs(currentMonth, "MM").add(1, "month").format("MM")
      setCurrentMonth(newMonth)
      //월별데이터가 바뀔때 historySplice를 초기화하여 더보기 기능 재활성화
      sethistorySplice4(10)
      await EventReceiversIndividualDataSet("", currentYear, newMonth)
    } else {
      const newYear = dayjs(currentYear, "YYYY").add(1, "year").format("YYYY")
      setCurrentYear(newYear)
      await EventReceiversDataSet("", newYear, "table")
      await EventReceiversIndividualDataSet("", newYear, currentMonth)
    }
  }

  const handleYearChart = async (type) => {
    if (type === "add") {
      const newYear = dayjs(chart_year, "YYYY").add(1, "year").format("YYYY")
      setChart_year(newYear)
      await EventReceiversDataSet("", newYear, "chart")
    } else {
      const newYear = dayjs(chart_year, "YYYY").subtract(1, "year").format("YYYY")
      setChart_year(newYear)
      await EventReceiversDataSet("", newYear, "chart")
    }
  }
  const CompanyContractsSearchActive = () => {
    companyContractsSearchActive().then((res) => {
      const data2 = []
      res.data.companyContracts
        .map((x) => x.pieces)
        .forEach((el) => {
          if (el) {
            for (let index = 0; index < el.length; index++) {
              el[index].type = "유통사"
              data2.push(el[index])
            }
          }
        })
      setcompanyContract_Datas(data2)
    })
  }
  const OutsourcingHistoriesActive = () => {
    outsourcingHistoriesActive().then((res) => {
      const data2 = []
      res.data.outsourcingHistories
        .map((x) => x.pieces)
        .forEach((el) => {
          for (let index = 0; index < el.length; index++) {
            el[index].type = "외주계약"
            data2.push(el[index])
          }
        })
      setoutsourcingHistories_Datas(data2)
    })
  }
  const settings_ref = useRef(null)
  const settings_Call = async () => {
    await settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        settings[el.type] = el.list
      })
      settings_ref.current = settings
    })
  }

  const settings_return = (type, items) => {
    let list = []
    if (type) {
      if (settings_ref.current) {
        let slice_index = settings_ref.current[type].findIndex((el) => el === "-")
        if (slice_index > -1) {
          list = settings_ref.current[type].splice(slice_index, 1)
        } else {
          list = settings_ref.current[type]
        }
      } else {
        list = items
      }
    } else {
      list = items
    }
    return list
  }
  const [outsourcingHistoriesActive] = useLazyQuery(outsourcingHistories, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [companyContractsSearchActive] = useLazyQuery(companyContractsSearch, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [contractActive] = useLazyQuery(contracts, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [eventReceviersActive] = useLazyQuery(eventReceviers, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [input_event_content, set_input_event_content] = useState("")
  let InputListsSet = [
    {
      file: null,
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "본명:",
      maxLength: 10,
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "회원 ID:",
      maxLength: 100,
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "조아라 필명:",
      maxLength: 100,
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이북 필명:",
      maxLength: 100,
      xs: 3,
    },
    //4
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "주민등록번호:",
      maxLength: 14,
      xs: 3,
    },
    //5
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "연락처:",
      maxLength: 50,
      xs: 3,
    },
    //6
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "address",
      title: "주소:",
      maxLength: 100,
      xs: 6,
      copy: true,
    },
    //7
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "생일 달:",
      maxLength: 100,
      xs: 1,
    },
    //8
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이메일:",
      maxLength: 100,
      xs: 4,
      copy: true,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "예금주:",
      maxLength: 100,
      xs: 2,
      copy: true,
    },
    {
      items: [
        "KB국민은행",
        "우리은행",
        "신한은행",
        "하나은행",
        "SC제일은행",
        "한국씨티은행",
        "기업은행",
        "케이뱅크",
        "카카오뱅크",
        "토스뱅크",
        "농협",
        "수협",
        "신협",
        "산림조합",
        "상호저축은행",
        "새마을금고",
        "BNK부산은행",
        "BNK경남은행",
        "광주은행",
        "DGB대구은행",
        "전북은행",
        "제주은행",
        "산업은행",
        "우체국은행",
      ],
      itemsValue: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "bankCount",
      setting_type: "bank",
      title: "계좌번호:",
      maxLength: 29,
      xs: 5,
      copy: true,
    },
  ]
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [rowlength, setrowlength] = useState(0)
  const [FilesSortingHistory, setFilesSortingHistory] = useState("넓게보기")
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [Contract_Delete_Dialog, setContract_Delete_Dialog] = useState(false)
  const [Contract_Update_Dialog, setContract_Update_Dialog] = useState(false)
  const [Contract_Delete_Index, setContract_Delete_Index] = useState(null)
  const [Delete_Contarct_Active, setDelete_Contarct_Active] = useState(false)
  const [deleteMettings, setdeleteMettings] = useState(false)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [createRecentActive] = useMutation(createRecent, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [me, writer],
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })

  const [createWriterSignificantActive] = useMutation(createWriterSignificant, {
    onError(err) {
      alert("생성 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateWriterSignificantActive] = useMutation(updateWriterSignificant, {
    onError(err) {
      alert("수정 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [writer],
  })
  const deleteContractBtn = (index) => {
    setContract_Delete_Index(index)
    setContract_Delete_Dialog(true)
  }
  const Delete_All = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      suggestRole: "작가 삭제신청",
      profile: myData.profile,
      requestExplain: requestExplainData,
      title: DetailData.ebookname,

      writerId: DetailData.id,
    }
    if (DetailData.history.length > 0) {
      request.first_history = DetailData.history[DetailData.history.length - 1]
    }
    createRequestActive({
      variables: {
        status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
        content: request,
        type: "Writer",
        writer: DetailData.id,
        requestId: DetailData.id,
        users_permissions_user: myData.id,
      },
    }).then(async () => {
      await client
        .query({
          query: users_list,
          variables: {
            status: "근무",
            role: 1,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          res.data.users
            .filter((x) => x.role.id === 1)
            .forEach((user) => {
              if (user.adminAlertSetting) {
                if (user.adminAlertSetting[2].value) {
                  createAlertActive({
                    variables: {
                      users_permissions_user: String(user.id),
                      title: "작가 삭제 신청",
                      type: "Delete",
                      userid: String(user.id),
                      content: {
                        id: myData.id,
                        profile: myData.profile,
                        position: myData.position,
                        department: myData.department,
                        name: myData.name,
                        profile: myData.profile,
                        DetailData: DetailData,
                        targetName: DetailData.ebookname,
                      },
                    },
                  })
                }
              }
            })

          const editHistory = [...DetailData.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" + DetailData.ebookname + " 작가님 </strong> 정보 삭제를 신청하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          updateWriterActive({
            variables: {
              id: DetailData.id,
              history: editHistory,
            },
          })
          dispatch(loading_Apply(false))
          setDelete_Dialog(false)
        })
    })
  }
  const [ContractDatasBackUp, setContractDatasBackUp] = useState([])
  const Delete_Contract = () => {
    let array = [...ContractDatas]
    if (!Delete_Contarct_Active) {
      setContractDatasBackUp([...ContractDatas])
    }
    array.splice(Contract_Delete_Index, 1)
    setDelete_Contarct_Active(true)
    setContractDatas(array)
    setcreateContractShow(true)
    setContract_Delete_Dialog(false)
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (secondDateObj.getTime() - firstDateObj.getTime() <= 0) {
      return "light2"
    }
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "error"
    } else {
      return "light2"
    }
  }
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const updateContarctDropDown = (index, type, bool, ContractIndex) => {
    let array = [...ContractDatas]
    if (type === 0) {
      array[ContractIndex][8].data[index][0][0].dropdown1 = !bool
    } else if (type === 1) {
      array[ContractIndex][8].data[index][0][0].dropdown2 = !bool
    }
    setContractDatas(array)
  }
  const createContarctDropDown = (index, type, bool) => {
    let array = [...ContractData]
    if (type === 0) {
      array[8].data[index][0].dropdown1 = !bool
    } else if (type === 1) {
      array[8].data[index][0].dropdown2 = !bool
    }
    setContractData(array)
  }
  const ContarctsDropDown = (index, type, bool) => {
    let array = [...ContractDatas]
    array[index][type].dropdown = !bool
    setContractDatas(array)
  }
  const ContarctDropDown = (index, bool) => {
    let array = [...ContractData]
    array[index].dropdown = !bool
    setContractData(array)
  }
  const ContractDataReset = () => {
    // setcreateContractShow(!createContractShow)
    setcreateShow(false)
    setContractData(ContractDataSet)
  }
  const handleInputContracts = (index, type, maxLength) => (e) => {
    let array = [...ContractDatas]
    // 특수문자 입력 제거 삭제 22.05.31 - 이규화
    // var checkSpecial =
    //   /[●◎◇◆□■△▲▽㉾ºª®℡㏘㏘㏂™㏇№㈜㉿♬♪♩♭▼→←↑↓↔〓◁◀▷▶♤♠♡♥♧♣⊙◈▣◐◑＃＆＊＠§※☆★○\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g
    // if (checkSpecial.test(e.target.value)) {
    //   e.target.value = e.target.value.replace(checkSpecial, "")
    // }
    if (maxLength >= e.target.value.length) {
      array[index][type].value = e.target.value
    }
    array[index][1].changed = true

    setContractDatas(array)

    for (var i = 0; i < DetailData.contracts.length; i++) {
      if (DetailData.contracts[i].id == array[index][0].infoData) {
        if (
          DetailData.contracts[i].bookcode === array[index][1].value &&
          DetailData.contracts[i].genre === array[index][2].value &&
          DetailData.contracts[i].joaraStatus === array[index][3].value &&
          DetailData.contracts[i].cover === array[index][4].value &&
          DetailData.contracts[i].ecover === array[index][5].value
        ) {
          setcreateContractShow(false)
        } else {
          setcreateContractShow(true)
        }
      }
    }
  }
  const handleInputContract = (index, maxLength) => (e) => {
    let array = [...ContractData]
    // var checkSpecial =
    //   /[●◎◇◆□■△▲▽㉾ºª®℡㏘㏘㏂™㏇№㈜㉿♬♪♩♭▼→←↑↓↔〓◁◀▷▶♤♠♡♥♧♣⊙◈▣◐◑＃＆＊＠§※☆★○\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g
    // if (checkSpecial.test(e.target.value)) {
    //   e.target.value = e.target.value.replace(checkSpecial, "")
    // }
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
    }
    setContractData(array)
  }
  const editSubContract = (data, row, rowIndex, contract) => {
    setContarctSubData(row)
    setContractSubDialogOpenBool(true)
    setContractSubDialogEdit(true)
    setContractSubDialogEditData(data)
    setContractSubDialogRowIndex(rowIndex)
    setContarctSubParentData(contract)
  }
  const handleInput = (index, maxLength, index2, type) => (e) => {
    if (value === 0) {
      let array = [...InputLists]
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let idNumber =
        /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[*|1-4][\d|*][\d|*][\d|*][\d|*][\d|*][\d|*]/g
      if (index === 7) {
        if (e.target.value.replace(/[^0-9*]/, "").length < 3) {
          array[index].value = e.target.value.replace(/[^0-9*]/, "")
        }
      } else if (maxLength >= e.target.value.length) {
        array[index].value = e.target.value
        if (typeData === "update") {
          array[index].changed = true
        }
        if (index === 4) {
          array[index].value = array[index].value
            .replace(/[^0-9*]/, "")
            .replace(/^(\d{6})([\d|*][\d|*][\d|*][\d|*][\d|*][\d|*][\d|*])$/, `$1-$2`)
          array[index].error = false
          array[index].error_message = ""
          if (array[index].value === "") {
            array[index].error = false
            array[index].error_message = ""
          }
          if (index === 4 && !idNumber.test(array[index].value) && array[index].value !== "") {
            array[index].error = true
            array[index].error_message = "주민등록번호 형식이 아닙니다."
          } else {
            array[index].error = false
            array[index].error_message = ""
          }
        } else if (index === 5) {
          let num_special = /[0-9!~@#$%^&*-_-+=(){}.,/]$/
          let num_test = array[index].value.match(num_special)
          if (!num_test) {
            array[index].value = array[index].value.substring(0, array[index].value.length - 1)
          }
          // array[index].value = array[index].value
          //   .replace(/[^0-9]/, "")
          //   .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
          array[index].error = false
          array[index].error_message = ""
        } else if (index === 8) {
          if (e.target.value === "") {
            array[index].error = false
            array[index].error_message = ""
          }
          if (index === 8 && !reEmail.test(e.target.value) && e.target.value !== "") {
            array[index].error = true
            array[index].error_message = "이메일 형식이 아닙니다."
          } else if (index === 8 && reEmail.test(e.target.value) && e.target.value !== "") {
            array[index].error = false
            array[index].error_message = ""
          }
        } else if (index === 10) {
          array[index].value = array[index].value.replace(/[^0-9][/][-]/, "")
          array[index].error = false
          array[index].error_message = ""

          if (array[index].itemsValue === "") {
            array[index].error = true
            array[index].error_message = "은행을 선택하여 주십시오."
          }
          if (array[index].value === "") {
            array[index].error = false
            array[index].error_message = ""
          }
        } else {
          array[index].error = false
          array[index].error_message = ""
        }
      }

      let reBirth
      if (DetailData.birth && DetailData.birth.length >= 15) {
        reBirth = CryptoJS.AES.decrypt(DetailData.birth, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        reBirth = DetailData.birth
      }
      let reitemValue
      if (DetailData.account && DetailData.account.length >= 44) {
        reitemValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
      } else {
        reitemValue = DetailData.account
      }
      let reValue
      if (DetailData.account && DetailData.account.length >= 44) {
        reValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else {
        reValue = DetailData.account
      }

      let rephone
      if (DetailData.phone && DetailData.phone.length >= 14) {
        rephone = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        rephone = DetailData.phone
      }

      let readdress
      if (DetailData.address && DetailData.address.length >= 44) {
        readdress = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        readdress = DetailData.address
      }

      let reemail
      if (DetailData.email && DetailData.email.length >= 40) {
        reemail = CryptoJS.AES.decrypt(DetailData.email, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        reemail = DetailData.email
      }

      if (reValue == "/" && reitemValue == "/") {
        reValue = ""
        reitemValue = ""
      }
      if (ischeck === false) {
        if (
          array[0].value === DetailData.name &&
          array[1].value === DetailData.userid &&
          array[2].value === DetailData.joaraname &&
          array[3].value === DetailData.ebookname &&
          array[4].value === reBirth &&
          array[5].value === rephone &&
          array[6].value === readdress &&
          array[7].value === DetailData.dal &&
          array[8].value === reemail &&
          array[9].value === DetailData.accountName &&
          array[10].itemsValue === reitemValue &&
          array[10].value === reValue
        ) {
          setcreateContractShow(false)
        } else {
          setcreateContractShow(true)
        }
      }

      setInputLists(array)
    } else if (value === 1) {
      let array = [...ContractDatas]

      if (type === "update") {
        let array = [...ContractDatas]

        if (maxLength >= e.target.value.length) {
          array[index2][index].value = e.target.value
          if (typeData === "update") {
            array[index2][1].changed = true
          }
        }
        setContractDatas(array)
      } else {
        let array = [...ContractData]
        if (maxLength >= e.target.value.length) {
          array[index].value = e.target.value
          if (typeData === "update") {
            array[index].changed = true
          }
        }
        setContractData(array)
      }

      if (createShow === false && array[index2]) {
        for (var i = 0; i < DetailData.contracts.length; i++) {
          if (DetailData.contracts[i].id === array[index2][0].id) {
            if (
              DetailData.contracts[i].bookcode === array[index2][1].value &&
              DetailData.contracts[i].genre === array[index2][2].value &&
              DetailData.contracts[i].joaraStatus === array[index2][3].value &&
              DetailData.contracts[i].cover === array[index2][4].value &&
              DetailData.contracts[i].ecover === array[index2][5].value
            ) {
              setcreateContractShow(false)
            } else {
              setcreateContractShow(true)
            }
          }
        }
      }
    }
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false)
  const [FiltersOpen7, setFiltersOpen7] = useState(false)
  const [FiltersOpen8, setFiltersOpen8] = useState(false)
  const [FiltersOpen9, setFiltersOpen9] = useState(false)
  const [FiltersOpen10, setFiltersOpen10] = useState(false)

  const [FiltersOpenIndex, setFiltersOpenIndex] = useState("")
  const [FiltersOpenIndex1, setFiltersOpenIndex1] = useState("")
  const filterOpenClick = (row, type) => {
    if (type === "subContract") {
      setFiltersOpenIndex(row[0][0].id)
      setFiltersOpen5(!FiltersOpen5)
    } else if (type === "memo") {
      setFiltersOpenIndex1(row[0][0].id)
      setFiltersOpen6(!FiltersOpen6)
    }
  }
  useEffect(() => {
    if (!FiltersOpen5) {
      setFiltersOpenIndex("")
    }
  }, [FiltersOpen5])

  const handleSelectChange = (index, index2, type) => (e) => {
    if (value === 0) {
      let array = [...InputLists]
      if (array[index].type === "bankCount") {
        array[index].itemsValue = e.target.value
        array[index].error_message = ""
        array[index].error = false
        if (typeData === "update") {
          array[index].changed = true
        }
      } else {
        array[index].value = e.target.value
        if (typeData === "update") {
          array[index].changed = true
        }
      }
      let reBirth
      if (DetailData.birth && DetailData.birth.length >= 15) {
        reBirth = CryptoJS.AES.decrypt(DetailData.birth, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        reBirth = DetailData.birth
      }
      let reitemValue
      if (DetailData.account && DetailData.account.length >= 44) {
        reitemValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
      } else {
        reitemValue = DetailData.account
      }
      let reValue
      if (DetailData.account && DetailData.account.length >= 44) {
        reValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else {
        reValue = DetailData.account
      }

      let rephone
      if (DetailData.phone && DetailData.phone.length >= 14) {
        rephone = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        rephone = DetailData.phone
      }

      let readdress
      if (DetailData.address && DetailData.address.length >= 44) {
        readdress = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        readdress = DetailData.address
      }

      let reemail
      if (DetailData.email && DetailData.email.length >= 40) {
        reemail = CryptoJS.AES.decrypt(DetailData.email, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        reemail = DetailData.email
      }

      if (reValue == "/" && reitemValue == "/") {
        reValue = ""
        reitemValue = ""
      }
      if (ischeck === false) {
        if (
          array[0].value === DetailData.name &&
          array[1].value === DetailData.userid &&
          array[2].value === DetailData.joaraname &&
          array[3].value === DetailData.ebookname &&
          array[4].value === reBirth &&
          array[5].value === rephone &&
          array[6].value === readdress &&
          array[8].value === reemail &&
          array[9].value === DetailData.accountName &&
          array[10].itemsValue === reitemValue &&
          array[10].value === reValue
        ) {
          setcreateContractShow(false)
        } else {
          setcreateContractShow(true)
        }
      }

      setInputLists(array)
    } else if (value === 1) {
      let array = [...ContractDatas]
      if (type === "update") {
        array[index2][index].value = e.target.value
        if (typeData === "update") {
          array[index2][1].changed = true
        }
        setContractDatas(array)
      } else {
        if (!array[index2]) {
          let newArray = [...ContractData]
          newArray[index].value = e.target.value
          setContractData(newArray)
        } else {
          array[index].value = e.target.value
          if (typeData === "update") {
            array[index].changed = true
          }
          setContractDatas(array)
        }
      }
      if (index2 !== undefined) {
        for (var i = 0; i < DetailData.contracts.length; i++) {
          if (DetailData.contracts[i].id === array[index2][0].id) {
            if (
              DetailData.contracts[i].bookcode === array[index2][1].value &&
              DetailData.contracts[i].genre === array[index2][2].value &&
              DetailData.contracts[i].joaraStatus === array[index2][3].value &&
              DetailData.contracts[i].cover === array[index2][4].value &&
              DetailData.contracts[i].ecover === array[index2][5].value
            ) {
              setcreateContractShow(false)
            } else {
              setcreateContractShow(true)
            }
          }
        }
      }
    }
  }
  const handleDatePicker = (index, index2) => (e) => {
    if (index2 > -1) {
      let contract_data = [...ContractDatas]
      if (typeData === "update") {
        contract_data[index2][1].changed = true
      }
      contract_data[index2][index].value = dayjs(e).format("YYYY/MM/DD")
      setContractDatas(contract_data)
    } else {
      let contract_data = [...ContractData]
      if (typeData === "update") {
        contract_data[1].changed = true
      }
      contract_data[index].value = dayjs(e).format("YYYY/MM/DD")
      setContractData(contract_data)
    }
    setcreateContractShow(true)
  }
  const handlereferrerIdsDelete = (user) => {
    if (user.id) {
      setreferrerIds([...referrerIds.filter((y) => y.id !== user.id)])
    } else {
      setreferrerIds([...referrerIds.filter((y) => y.department !== user.name)])
    }
    setcreateContractShow(true)
  }
  const InputTypeEl = (data, index, index2, type) => {
    if (typeData === "detail") {
      if (data.type === "monthPicker") {
        return (
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: 1,
                color: "#535353",
              }}
            >
              {data.value}
            </Card>
          </SuiBox>
        )
      } else {
        return (
          <SuiBox sx={{ width: "100%" }}>
            {index > 3 ? (
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {data?.value}
              </Card>
            ) : data.value?.length < 11 ? (
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {data?.value}
              </Card>
            ) : (
              <Tooltip
                arrow={false}
                color="white"
                classes={{ popper: "tooltip-light" }}
                title={
                  <SuiBox>
                    <SuiTypography
                      style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                      verticalAlign="middle"
                    >
                      {data.value}
                    </SuiTypography>
                  </SuiBox>
                }
              >
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {data.value.length > 0 ? data.value.substr(0, 10) + "..." : ""}
                </Card>
              </Tooltip>
            )}
          </SuiBox>
        )
      }
    } else if (typeData === "create" || typeData === "update") {
      if (data.type === "monthPicker") {
        return (
          <SuiBox sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="event_datepicker_contract"
                inputFormat={"YYYY/MM/DD"}
                value={data.value}
                onChange={handleDatePicker(index, index2)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
        )
      } else if (data.type === "textFiled") {
        return (
          <SuiBox className={value === 1 ? "textFiled-Writer" : ""}>
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength, index2, type)}
            />
          </SuiBox>
        )
      } else if (data.type === "address") {
        return (
          <SuiBox>
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength)}
            />
            <Dialog open={addressOpen} maxWidth="lg">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setaddressOpen(false)}
                aria-label="close"
                sx={{ alignSelf: "self-end", padding: "15px 10px 15px" }}
              >
                <MdClose />
              </IconButton>
              <SuiBox sx={{ width: 480 }}>
                <DaumPostcode onComplete={handleComplete} {...props} />
              </SuiBox>
            </Dialog>
          </SuiBox>
        )
      } else if (data.type === "bankCount") {
        return (
          <SuiBox display="flex">
            <SuiBox sx={{ width: 200 }}>
              <FormControl
                sx={{ minHeight: 30, width: "100%" }}
                className={
                  data.itemsValue === ""
                    ? "setting-selectbox select-none-active"
                    : "setting-selectbox"
                }
                onClick={() => setFiltersOpen1(!FiltersOpen1)}
              >
                <InputLabel sx={{ display: data.itemsValue === "" ? "" : "none" }}>
                  은행선택
                </InputLabel>
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    />
                  )}
                  open={FiltersOpen1}
                  value={data.itemsValue}
                  success={data.success}
                  // helperText={data.error_message}
                  error={data.error}
                  onChange={handleSelectChange(index)}
                  className="setting-border-radius-right"
                >
                  {settings_return(data.setting_type, data.items).map((x) => {
                    return (
                      <MenuItem value={x} key={x}>
                        {x}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </SuiBox>
            <SuiInput
              className="backCount-left-input"
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength)}
            />
          </SuiBox>
        )
      } else if (data.type === "selectBox") {
        return (
          <SuiBox>
            <FormControl
              className={
                data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
              }
              sx={{ width: "100%" }}
              onClick={() => setFiltersOpen2(!FiltersOpen2)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen2(!FiltersOpen2)}
                  />
                )}
                // open={FiltersOpen2}
                value={data.value}
                onChange={handleSelectChange(index, index2, type)}
                className="setting-border-radius"
              >
                {settings_return(data.setting_type, data.items).map((x) => {
                  return (
                    <MenuItem value={x} key={x}>
                      {x}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </SuiBox>
        )
      }
    }
  }

  const [InputLists, setInputLists] = useState(InputListsSet)

  const ContractDataSet = [
    { value: "", maxLength: 255, dropdown: false },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "북코드:",
      maxLength: 100,
      xs: 2,
      changed: false,
    },
    {
      type: "selectBox",
      setting_type: "genre",
      value: "로맨스",
      items: [
        "-",
        "로맨스",
        "판타지",
        "로판",
        "현판",
        "무협",
        "퓨전",
        "현대물",
        "게임",
        "미스터리",
        "역사물/대체역사",
        "패러디",
        "스포츠",
        "BL",
        "GL",
        "라이트노벨",
        "SF",
        "팬픽",
        "학원/액션/범죄",
        "남성향 성인",
        "여성향 성인",
      ],
      title: "장르:",
      xs: 2,
    },
    {
      type: "selectBox",
      setting_type: "age",
      value: "등급",
      items: ["-", "19금", "15금", "전연령"],
      title: "등급:",
      xs: 2,
    },
    {
      type: "selectBox",
      setting_type: "sign",
      value: "표지",
      items: ["-", "있음", "없음"],
      title: "표지:",
      xs: 1,
    },
    {
      type: "selectBox",
      setting_type: "offer",
      value: "제공/연재",
      items: ["제공/연재", "제공/완결", "비제공"],
      title: "조아라 연재:",
      xs: 2,
    },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      maxLength: 100,
      xs: 1,
      changed: false,
      title: "화수:",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "monthPicker",
      title: "출간일자:",
      xs: 2,
    },
    {
      data: [],
    },
  ]
  const [ContractData, setContractData] = useState(ContractDataSet)
  const [contract_documents_data, setcontract_documents_data] = useState([])
  const [contract_documents_data_update, setcontract_documents_data_update] = useState([])
  const [contract_documents_data_edit_index, setcontract_documents_data_edit_index] = useState(0)
  const [contract_documents_activeStep, setcontract_documents_activeStep] = useState(0)
  const [contract_documents_data_index, setcontract_documents_data_index] = useState(0)
  useEffect(() => {
    let array = [...ContractData]
    if (contract_documents_data.length > 0) {
      let DocDatas = [...array[8].data]
      DocDatas.push(contract_documents_data)
      array[8].data = DocDatas
    }
    setContractData(array)
  }, [contract_documents_data])
  const [dataShowActive, setdataShowActive] = useState(false)
  const [ContractDatas, setContractDatas] = useState([])
  useEffect(() => {
    let array = [...ContractDatas]
    if (contract_documents_data_update.length > 0) {
      let DocDatas = [...array[contract_documents_data_index][8].data]
      if (!ContractDialogEdit) {
        DocDatas.unshift([contract_documents_data_update])
      } else {
        if (DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].id) {
          contract_documents_data_update[0].id =
            DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].id
        }
        contract_documents_data_update[0].user =
          DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].user
        contract_documents_data_update[0].ContractType =
          DocDatas[contract_documents_data_edit_index][
            contract_documents_activeStep
          ][0].ContractType
        contract_documents_data_update[0].changed = true
        contract_documents_data_update[0].memo =
          DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].memo
        contract_documents_data_update[0].subContract =
          DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].subContract
        contract_documents_data_update[0].managerLog =
          DocDatas[contract_documents_data_edit_index][contract_documents_activeStep][0].managerLog
        DocDatas[contract_documents_data_edit_index][contract_documents_activeStep] =
          contract_documents_data_update

        // 계약서 수정시 부속계약서 수정(상위 계약서랑 동일 일경우)
        let find_index = []
        let first_contract = ""
        if (DocDatas[contract_documents_data_edit_index][0][0].subContract.length === 1) {
          // 최초계약 하나 일경우
          find_index.push(0)
          first_contract = true
        } else {
          // 갱신계약이 있을경우
          first_contract = false
          for (
            let i = 0;
            i < DocDatas[contract_documents_data_edit_index][0][0].subContract.length;
            i++
          ) {
            let element = DocDatas[contract_documents_data_edit_index][0][0].subContract[i]
            if (
              element[0].ContractType.split("_")[1] ===
              String(DocDatas[contract_documents_data_edit_index].length - 1)
            ) {
              find_index.push(i)
            }
          }
        }
        if (!first_contract) {
          for (let i = 0; i < find_index.length; i++) {
            let idx = find_index[i]
            if (
              DocDatas[contract_documents_data_edit_index][0][0].subContract[idx][1].value ===
              "상위 계약과 동일"
            ) {
              DocDatas[contract_documents_data_edit_index][0][0].subContract[idx][1].value1 =
                DocDatas[contract_documents_data_edit_index][0][1].value1
              DocDatas[contract_documents_data_edit_index][0][0].subContract[idx][1].value2 =
                DocDatas[contract_documents_data_edit_index][0][1].value2
            }
          }
        } else if (first_contract) {
          DocDatas[contract_documents_data_edit_index][0][0].subContract[0][1].value1 =
            DocDatas[contract_documents_data_edit_index][0][1].value1
          DocDatas[contract_documents_data_edit_index][0][0].subContract[0][1].value2 =
            DocDatas[contract_documents_data_edit_index][0][1].value2
        }
      }
      // 계약서 수정시 부속계약서 수정 끝

      array[contract_documents_data_index][1].changed = true
      array[contract_documents_data_index][8].data = DocDatas
    }
    setContractDatas(array)
  }, [contract_documents_data_update])
  const handleComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }
    let array = [...InputLists]
    array[6].value = fullAddress
    setInputLists(array)
    setaddressOpen(false)
  }
  const ContractDataShow = ContractData.map((row, index) => {
    if (index > 0 && index !== 8) {
      return (
        <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
          <SuiBox>
            <SuiBox display="flex">
              <SuiTypography
                fontSize="15px"
                color="info"
                sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
              >
                {row.title}
              </SuiTypography>
            </SuiBox>
            {InputTypeEl(row, index)}
          </SuiBox>
        </Grid>
      )
    }
  })
  const InputListShow = InputLists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
        <SuiBox>
          <SuiBox display="flex">
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
              {row.title}
            </SuiTypography>
            {typeData === "detail" ? (
              row.copy ? (
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="info2"
                  className="cursor"
                  sx={{
                    textAlign: "right",
                    pt: 2,
                    ml: "auto",
                    pb: 1,
                    mr: "14px",
                    textDecoration: "underline",
                  }}
                  onClick={() => copyData(row, index)}
                >
                  복사
                </SuiTypography>
              ) : (
                ""
              )
            ) : (
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color="info2"
                className="cursor"
                sx={{
                  textAlign: "right",
                  pt: 2,
                  ml: "auto",
                  pb: 1,
                  textDecoration: "underline",
                  display: row.type === "address" ? "" : "none",
                }}
                onClick={() => {
                  setaddressOpen(true)
                  setcreateContractShow(true)
                }}
              >
                주소 검색
              </SuiTypography>
            )}
          </SuiBox>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)
  const [AnchorElRowData, setAnchorElRowData] = useState([[{}, { created_at: "" }]])
  const [openDelete_Dialog, setopenDelete_Dialog] = useState(false)
  const [openChange_Dialog, setopenChange_Dialog] = useState(false)
  const [openSignificantDelete_Dialog, setopenSignificantDelete_Dialog] = useState(false)
  const [openMeettingDelete_Dialog, setopenMeettingDelete_Dialog] = useState(false)
  const [editBool, seteditBool] = useState(false)
  const [editData, seteditData] = useState([])
  const [editMettingBool, seteditMettingBool] = useState(false)
  const [editMettingData, seteditMettingData] = useState([])
  const [DetailMettingData, setDetailMettingData] = useState({
    data: [
      {
        value: "",
        inputType: "text",
        error_message: "",
        error: false,
        type: "textFiled",
        title: "제목",
        maxLength: 100,
        xs: 8,
        changed: false,
      },
      {
        items: [],
        value: "",
        error_message: "",
        error: false,
        type: "selectBox",
        title: "관련계약",
        maxLength: 100,
        xs: 4,
        name: "",
        position: "",
        department: "",
        created_at: new Date(),
      },
      {
        type: "fileList",
        value: "",
        title: "내용",
        xs: 8,
        files: [],
      },
      {
        inputType: "text",
        value: "",
        value2: "",
        items2: ["전체 공개", "일부 공개"],
        items: ["회의록", "인터뷰", "인수인계", "기타"],
        type: "category",
        title: "",
        xs: 4,
      },
    ],
  })
  const [openContractDelete_Dialog, setopenContractDelete_Dialog] = useState(false)
  const [deleteMettingData, setdeleteMettingData] = useState([])
  const [deleteData, setdeleteData] = useState([])
  const [deleteDatas, setdeleteDatas] = useState([])
  const [DetailData, setDetailData] = useState({
    history: [],
    manager: [],
    requests: [],
    users: [],
    referrer: [],
  })
  const open = Boolean(anchorEl)
  let InputListsSetData = [
    { type: "picUpload", title: "파일 업로드:", xs: 6, file: {}, changed: false },
    {
      items: ["회사소개서", "통장사본", "사업자등록증", "포트폴리오", "신분증", "기타"],
      itemsValue: "회사소개서",
      value: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "유형:",
      maxLength: 30,
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      version: "최신",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "fileList",
      title: "",
      maxLength: 255,
      xs: 6,
    },
    // {
    //   inputType: "text",
    //   value: "",
    //   error_message: "",
    //   error: false,
    //   type: "textArea",
    //   title: "설명:",
    //   maxLength: 30,
    //   xs: 6,
    // },
  ]
  let MeetingsInputListsSet = [
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "제목",
      maxLength: 100,
      xs: 8,
      changed: false,
    },
    {
      items: [],
      value: "",
      error_message: "",
      error: false,
      type: "selectBox",
      title: "관련계약",
      maxLength: 100,
      xs: 4,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
    },
    {
      type: "fileList",
      value: "",
      title: "내용",
      xs: 8,
      files: [],
    },
    {
      inputType: "text",
      value: "",
      value2: "",
      items2: ["전체 공개", "일부 공개"],
      items: ["회의록", "인터뷰", "인수인계", "기타"],
      type: "category",
      title: "",
      xs: 4,
    },
  ]
  let ContractSet = [
    { value: "", maxLength: 255, dropdown: false },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "북코드:",
      maxLength: 100,
      xs: 2,
      changed: false,
    },
    {
      type: "selectBox",
      setting_type: "genre",
      value: "로맨스",
      items: [
        "-",
        "로맨스",
        "판타지",
        "로판",
        "현판",
        "무협",
        "퓨전",
        "현대물",
        "게임",
        "미스터리",
        "역사물/대체역사",
        "패러디",
        "스포츠",
        "BL",
        "GL",
        "라이트노벨",
        "SF",
        "팬픽",
        "학원/액션/범죄",
        "남성향 성인",
        "여성향 성인",
      ],
      title: "장르:",
      xs: 2,
    },
    {
      type: "selectBox",
      setting_type: "age",
      value: "등급",
      items: ["-", "19금", "15금", "전연령"],
      title: "등급:",
      xs: 2,
    },
    {
      type: "selectBox",
      setting_type: "sign",
      value: "표지",
      items: ["-", "있음", "없음"],
      title: "표지:",
      xs: 1,
    },
    {
      type: "selectBox",
      setting_type: "offer",
      value: "제공/연재",
      items: ["제공/연재", "제공/완결", "비제공"],
      title: "조아라 연재:",
      xs: 2,
    },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      maxLength: 100,
      xs: 1,
      changed: false,
      title: "화수:",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "monthPicker",
      title: "출간일자:",
      xs: 2,
    },
    {
      data: [],
    },
  ]
  const [changeRequestsActive] = useLazyQuery(changeRequests, {
    fetchPolicy: "network-only",
    // variables: {
    //   id: editId,
    // },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const { data } = useQuery(writer, {
    fetchPolicy: "network-only",
    variables: {
      id: editId,
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  useEffect(() => {
    dispatch(loading_Apply(true))
    if (data) {
      firstChangeRequestsActive(data.writer.id)
      setDetailData(data.writer)
      let array = [...InputLists]
      array[0].value = data.writer.name
      array[1].value = data.writer.userid
      array[2].value = data.writer.joaraname
      array[3].value = data.writer.ebookname
      array[7].value = data.writer.dal ? Number(data.writer.dal) : ""
      array[9].value = data.writer.accountName
      if (data.writer.birth.length < 15) {
        array[4].value = data.writer.birth
      } else {
        array[4].value = CryptoJS.AES.decrypt(data.writer.birth, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (data.writer.phone.length < 14) {
        array[5].value = data.writer.phone
      } else {
        array[5].value = CryptoJS.AES.decrypt(data.writer.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (data.writer.address.length < 44) {
        array[6].value = data.writer.address
      } else {
        array[6].value = CryptoJS.AES.decrypt(data.writer.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      if (data.writer.email.length < 40) {
        array[8].value = data.writer.email
      } else {
        array[8].value = CryptoJS.AES.decrypt(data.writer.email, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (data.writer.account.length < 44) {
        if (data.writer.account.indexOf("/") >= 0) {
          array[10].itemsValue = data.writer.account.split("/")[0]
          array[10].value = data.writer.account.split("/")[1]
        } else {
          array[10].itemsValue = ""
          array[10].value = ""
        }
      } else {
        if (
          CryptoJS.AES.decrypt(data.writer.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .indexOf("/") >= 0
        ) {
          array[10].itemsValue = CryptoJS.AES.decrypt(data.writer.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .split("/")[0]
          array[10].value = CryptoJS.AES.decrypt(data.writer.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .split("/")[1]
        } else {
          array[10].itemsValue = ""
          array[10].value = ""
        }
      }

      let arrayAttachments = []
      data.writer.attachments.forEach((element) => {
        InputListsSetData[0].file = element.file[0]
        InputListsSetData[1].id = element.id
        InputListsSetData[1].value = element.title
        InputListsSetData[1].itemsValue = element.type
        InputListsSetData[1].name = element.users_permissions_user.name
        InputListsSetData[1].position = element.users_permissions_user.position
        InputListsSetData[1].department = element.users_permissions_user.department
        InputListsSetData[1].created_at = element.created_at
        // InputListsSetData[3].value = element.explain
        arrayAttachments.push({ data: JSON.parse(JSON.stringify(InputListsSetData)) })
      })
      setAttachmentDatas(
        arrayAttachments.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      let arrayMeetings = []
      data.writer.meetings.forEach((element) => {
        MeetingsInputListsSet[0].value = element.title
        MeetingsInputListsSet[1].id = element.id
        MeetingsInputListsSet[1].value = element.contract
        MeetingsInputListsSet[1].itemsValue = element.type
        MeetingsInputListsSet[1].name = element.users_permissions_user.name
        MeetingsInputListsSet[1].position = element.users_permissions_user.position
        MeetingsInputListsSet[1].department = element.users_permissions_user.department
        MeetingsInputListsSet[1].created_at = element.created_at
        MeetingsInputListsSet[1].makerId = element.users_permissions_user.id
        MeetingsInputListsSet[1].contract_document = element.contract_document
        let fileArray = []
        element.file.forEach((element) => {
          fileArray.push({ file: element })
        })
        MeetingsInputListsSet[2].files = fileArray
        MeetingsInputListsSet[2].value = element.content
        MeetingsInputListsSet[3].value = element.category
        MeetingsInputListsSet[3].value2 = element.open ? "전체 공개" : "일부 공개"
        arrayMeetings.push({ data: JSON.parse(JSON.stringify(MeetingsInputListsSet)) })
      })
      setMeetingDatas(
        arrayMeetings.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )

      let arrayContracts = []
      data.writer.contracts.forEach((element) => {
        ContractSet[0].id = element.id
        ContractSet[0].value = element.title
        ContractSet[0].user = element.user
        ContractSet[1].value = element.bookcode
        ContractSet[1].created_at = element.created_at
        ContractSet[2].value = element.genre
        ContractSet[3].value = element.rating ? element.rating : ""
        ContractSet[4].value = element.cover
        ContractSet[5].value = element.joaraStatus
        ContractSet[6].value = element.episodes ? element.episodes : ""
        ContractSet[7].value = element.publicDate ? element.publicDate : ""
        element.contract_documents.forEach((element, index) => {
          element.contractPaper.forEach((paper, paperIndex) => {
            // 전현균 - 오류 무시용 주석 - start
            if (paper[paperIndex]) {
              paper[paperIndex].id = element.id
              paper[paperIndex].memo = element.memo
              paper[paperIndex].subContract = element.subContract
              paper[paperIndex].managerLog = element.managerLog
              paper[paperIndex].user = element.users_permissions_user
              paper[paperIndex].changed = false
              paper[paperIndex].referrer = element.referrer
              paper[paperIndex].oriReferrer = element.referrer
            }
            paper[0].requests = element.requests
            // 전현균 - 오류 무시용 주석 - end
          })
        })
        ContractSet[8].data = element.contract_documents.map((x) => x.contractPaper)
        arrayContracts.push(JSON.parse(JSON.stringify(ContractSet)))
      })
      setContractDatas(
        arrayContracts.sort((a, b) => {
          var dateA = new Date(a[1].created_at).getTime()
          var dateB = new Date(b[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setInputLists(array)
      setdataShowActive(true)
      const x = JSON.parse(JSON.stringify(data.writer.writer_significants))
      setoriginSignificants(x)
      setSignificants(data.writer.writer_significants)
    }

    setTimeout(() => {
      dispatch(loading_Apply(false))
    }, 2000)
  }, [data])
  const [ChangeRequests, setChangeRequests] = useState([])
  const firstChangeRequestsActive = async (id) => {
    await client
      .query({
        query: changeRequests,
        variables: {
          writersId: id,
          status: "신청",
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setChangeRequests(res.data.changeRequests)
      })
      .catch((err) => {})
  }
  const paperSorting2 = (array) => {
    array.sort((a, b) => {
      return Number(a[0][0].id) - Number(b[0][0].id)
    })
    return array
  }
  const FindingFirst = (Datas) => {
    let returnData
    let all_list = []

    for (let i = 0; i < Datas.length; i++) {
      let dep1_list = Datas[i][8].data
      for (let j = 0; j < dep1_list.length; j++) {
        all_list.push(dep1_list[j])
      }
    }
    if (all_list.length > 0) {
      returnData = paperSorting2(all_list)[0][0]
    }

    if (returnData) {
      return returnData
    } else {
      return null
    }
  }
  const Delete_ContractData = () => {
    let array = [...ContractDatas]
    let contract_doc = []
    const setData = array[contract_documents_data_index][8].data
    setData.forEach((element, index) => {
      if (contract_documents_data_edit_index !== index) {
        contract_doc.push(element)
      }
    })
    array[contract_documents_data_index][8].data = contract_doc
    let objectData = { ...deleteContractIds }
    setdeleteContractIds({
      ...objectData,
      [contract_documents_data_index]: array[contract_documents_data_index][8].data
        .filter((x) => x[0][0].id)
        .map((x) => x[0][0].id),
    })
    array[contract_documents_data_index][1].changed = true
    setContractDatas(array)
    setopenContractDelete_Dialog(false)
  }
  const [isupdataCheck, setisupdataCheck] = useState("")
  const [isupdataid, setisupdataid] = useState("")

  const updateTitle = (data) => {
    setisupdataCheck(data.value)

    setisupdataid(data.value + "1")

    setContract_Update_Dialog(true)
  }
  const Update_Contract = () => {
    if (isupdataCheck) {
      let tag = document.getElementById(isupdataCheck)
      tag.className += " buttomcursor"
      tag.disabled = true
    }
    if (isupdataid) {
      let tagid = document.getElementById(isupdataid)
      tagid.className += " disabledcolor"
      tagid.disabled = true
    }
    setcreateContractShow(true)
    setContract_Update_Dialog(false)
  }
  const contract_update = (data) => {
    if (data.id) {
      for (var i = 0; i < DetailData.contracts.length; i++) {
        if (data.id === DetailData.contracts[i].id) {
          if (data.value === DetailData.contracts[i].title) {
            return true
          } else {
            return false
          }
        }
      }
    } else {
      if (data.value === CopyContractData[0].value) {
        return true
      } else {
        return false
      }
    }
  }
  const [deleted_meetings, setdeleted_meetings] = useState([])

  const Delete_MeettingData = () => {
    let array = [...MeetingDatas]
    let deleted_array = [...deleted_meetings]
    if (DetailData.meetings.filter((x) => x.id === deleteMettingData[1].id).length > 0) {
      deleted_array.push(deleteMettingData)
      setdeleted_meetings(deleted_array)
    }
    setMeetingDatas(array.filter((x) => x.data[0].value !== deleteMettingData[0].value))
    setdeleteMettings(true)
    setopenMeettingDelete_Dialog(false)
    setcreateContractShow(true)
  }
  const [deleted_attachment, setdeleted_attachment] = useState([])
  const Delete_AttachmentData = () => {
    let array = [...AttachmentDatas]
    let deleted_array = [...deleted_attachment]
    if (DetailData.attachments.filter((x) => x.id === deleteData[1].id).length > 0) {
      deleted_array.push(deleteData)
      setdeleted_attachment(deleted_array)
    }
    setAttachmentDatas(array.filter((x) => x.data[1].value !== deleteData[1].value))
    const deleteArrays = [...deleteDatas]
    deleteArrays.push(deleteData)
    setdeleteDatas(deleteArrays)
    setopenDelete_Dialog(false)
  }
  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
    if (row) {
      setAnchorElRowData(row)
    }
  }
  const handleMenu = () => {
    setAnchorEl(null)
  }
  const createContractPaperBtn = () => {
    setContractDialogOpenBool(true)
    setContractType("create")
  }
  const [Contract_deleteData, setContract_deleteData] = useState([])
  const [delete_ContractData, setdelete_ContractData] = useState([])

  const deleteRequest = (data) => {}
  const ContractOption = (data, e, index, type, ContractIndex, activeStep, row, contractData) => {
    setContractType(type)
    setcontract_documents_activeStep(activeStep)
    setcontract_documents_data_edit_index(index)
    setcontract_documents_data_index(ContractIndex)
    if (e.target.innerText === "수정") {
      setContractDialogEdit(false)
      setTimeout(() => {
        setContractDialogEdit(true)
      }, 200)
      setContractDialogOpenBool(true)
      setContractDialogEditData(data)
    } else if (e.target.innerText === "삭제신청") {
      setopenContract_deleteDialog(true)
      setContract_deleteData(row)
      setdelete_ContractData(contractData)
      // setopenContractDelete_Dialog(true)
    } else if (e.target.innerText === "계약이관") {
      data.DetailData = DetailData
      data.targetName = DetailData.ebookname
      data.row = row
      setContractChangeDialogOpenBool(true)
      setContarctChangeData(data)
    } else if (e.target.innerText === "갱신계약") {
      setContarctRenewalType("renewal")
      setContractRenewalDialogOpenBool(true)
      console.log(data)
      setContarctRenewalData(data)
    } else if (e.target.innerText === "종료통고") {
      setContarctRenewalType("ContractEnd")
      setContractRenewalDialogOpenBool(true)
      setContarctRenewalData(data)
    }
    setAnchorEl(null)
  }
  const MettingOption = (data, e, index) => {
    setContractList(ContractDatas)
    if (e.target.innerText === "수정") {
      seteditMettingBool(false)
      setTimeout(() => {
        seteditMettingBool(true)
      }, 200)
      setMeetingDialogBool(true)
      seteditMettingData(data)
    } else if (e.target.innerText === "삭제") {
      setdeleteMettingData(data)
      setopenMeettingDelete_Dialog(true)
    }
    setAnchorEl(null)
  }
  const handleClose = (data, e, index) => {
    if (e.target.innerText === "수정") {
      seteditBool(false)
      setTimeout(() => {
        seteditBool(true)
      }, 200)
      seteditData(data)
      setFileUploadDialogBool(true)
    } else if (e.target.innerText === "삭제") {
      setdeleteData(data)
      setopenDelete_Dialog(true)
    }
    setcreateContractShow(true)
    setAnchorEl(null)
  }
  const meetings_close = () => {
    setcreateContractShow(false)
    setopenMeettingDelete_Dialog(false)
  }
  const updateContractDialogopen = (ContractIndex, index) => {
    setContractType("update")
    setcontract_documents_data_index(ContractIndex)
    setcontract_documents_data_edit_index(index)
    setContractDialogOpenBool(true)
    setContractDialogEdit(false)
    setContractDialogEditData([])
  }
  const DetailMeetingShow = (data) => {
    if (data.data.length > 0) {
      if (data.data[3].value2 === "전체 공개") {
        return true
      } else if (myData.role.name === "Authenticated" || myData.role.name === "gold") return true
      else if (data.data[1].makerId === myData.id) return true
      else if (data.data[1].contract_document) {
        if (data.data[1].contract_document.referrer.length === 0) return false
        let ok = 0
        data.data[1].contract_document.referrer.forEach((el, index) => {
          if (el.id === myData.id) {
            ok += 1
          }
          if (index === data.data[1].contract_document.referrer.length - 1) {
            if (ok === 0) {
              return false
            } else {
              return true
            }
          }
        })
      }
    }
  }
  const DetailMeetingOpen = (data) => {
    if (typeData === "detail") {
      setDetailMettingData(data)
      if (data.data.length > 0) {
        if (data.data[3].value2 === "전체 공개") return setMeetingDetailDialogBool(true)
        else if (myData.role.name === "Authenticated" || myData.role.name === "gold")
          return setMeetingDetailDialogBool(true)
        else if (data.data[1].makerId === myData.id) setMeetingDetailDialogBool(true)
        else if (data.data[1].contract_document) {
          if (data.data[1].contract_document.referrer.length === 0) setsSnackState3(true)
          else if (data.data[1].contract_document.referrer.map((x) => x.id).indexOf(myData.id) > -1)
            setMeetingDetailDialogBool(true)
          else setsSnackState3(true)
        } else setsSnackState3(true)
      }
    }
  }
  const ITEM_HEIGHT = 48
  const subText = () => {
    if (value === 0) {
      return "작가의 기본 정보를 등록하고, 확인하실 수 있습니다."
    } else if (value === 1) {
      return "계약 작품 정보와 계약서를 등록하고, 확인하실 수 있습니다."
    } else if (value === 2) {
      return "이벤트 참여 작품 현황과 결과 내역을 확인하실 수 있습니다."
    } else if (value === 3) {
      return "작가 미팅에서 작성한 회의 내용을 등록하고, 확인하실 수 있습니다."
    }
  }
  const fileText = (data) => {
    if (data?.length > 40) {
      let result = data.slice(0, 30) + "..." + data.slice(-7)

      return result
    } else {
      return data
    }
  }
  const [openContractInfoDialog, setopenContractInfoDialog] = useState(false)
  const [openSubContractInfoDialog, setopenSubContractInfoDialog] = useState(false)
  const [openNoContract_dialog, setopenNoContract_dialog] = useState(false)

  const [writerContractActive] = useLazyQuery(writerContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const clickContract2 = (Contractdata) => {
    writerContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: Contractdata.data[1].contract_document.id,
      },
    }).then(({ data }) => {
      if (data.contractDocument.contract === null) {
        dispatch(loading_Apply(false))
        setopenNoContract_dialog(true)
      } else {
        openInfoDialog2(data, Contractdata)
      }
    })
  }
  const [InfoData, setInfoData] = useState([])
  const [SubInfoData, setSubInfoData] = useState([])
  const closeDialogInfo = () => {
    setopenContractInfoDialog(false)
    setopenSubContractInfoDialog(false)
  }
  const openInfoDialog2 = (contractPaper, Contractdata) => {
    const data = Contractdata.data[1].contract_document.contractPaper[0]
    data[4] = contractPaper
    setInfoData(data)
    setopenContractInfoDialog(true)
  }
  const openInfoDialog = (contractPaper) => {
    setInfoData(contractPaper)
    setopenContractInfoDialog(true)
  }
  const clickContract = (row, type, title) => {
    dispatch(loading_Apply(true))
    writerContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: row.contractId,
      },
    }).then(({ data }) => {
      if (data.contractDocument.contract === null) {
        setopenNoContract_dialog(true)
        dispatch(loading_Apply(false))
      } else {
        writerContractActive({
          fetchPolicy: "network-only",
          variables: {
            id: row.contractId,
          },
        }).then(({ data }) => {
          let contractData = { ...JSON.parse(JSON.stringify(data)) }
          dispatch(loading_Apply(false))
          if (type === "renewal") {
            contractData.contractDocument.contractPaper[
              contractData.contractDocument.contractPaper.length - 1 - row.renewalIndex
            ][0].managerLog.unshift(contractData.contractDocument.users_permissions_user)

            setSubInfoData(
              contractData.contractDocument.contractPaper[
                contractData.contractDocument.contractPaper.length - 1 - row.renewalIndex
              ]
            )
            setopenSubContractInfoDialog(true)
          } else {
            if (row.renewalIndex) {
              let array = [
                ...JSON.parse(
                  JSON.stringify(
                    contractData.contractDocument.contractPaper[
                      contractData.contractDocument.contractPaper.length - row.renewalIndex
                    ]
                  )
                ),
              ]
              array[0].ContractType = title
              array[0].managerLog.unshift(contractData.contractDocument.users_permissions_user)

              setSubInfoData(array)
              setopenSubContractInfoDialog(true)
            } else {
              if (row.contractLength) {
                contractData.contractDocument.contractPaper[
                  contractData.contractDocument.contractPaper.length - row.contractLength
                ].push(contractData)

                openInfoDialog(
                  contractData.contractDocument.contractPaper[
                    contractData.contractDocument.contractPaper.length - row.contractLength
                  ]
                )
              } else {
                contractData.contractDocument.contractPaper[0].push(contractData)

                openInfoDialog(contractData.contractDocument.contractPaper[0])
              }
            }
          }
        })
      }
    })
  }
  const clickSubContract = (row) => {
    if (row.subDataSet) {
      let subData = [...JSON.parse(JSON.stringify(row.subDataSet))]
      writerContractActive({
        fetchPolicy: "network-only",
        variables: {
          id: row.contractId,
        },
      }).then(({ data }) => {
        dispatch(loading_Apply(false))
        if (data.contractDocument.contract !== null) {
          subData[0].managerLog.unshift(data.contractDocument.users_permissions_user)
        }

        setSubInfoData(subData)
        setopenSubContractInfoDialog(true)
      })
    } else {
      clickContract(row, "renewal")
    }
  }

  const historyContent = (row) => {
    let strongLength = row.content.match(/<strong[^>]*>([^<]+)<\/strong>/g)
    let firstStrong
    let secondStrong
    let hisotryType
    if (strongLength && strongLength.length > 0) {
      if (row.type.indexOf("contract") > -1) {
        firstStrong = strongLength[0].match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
        if (strongLength[1]) {
          secondStrong = strongLength[1].match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
        }
        if (row.type === "contract") {
          hisotryType = row.content.slice(-10)
        } else {
          let startIndex = row.content.lastIndexOf("</strong>")
          hisotryType = row.content.substring(startIndex + 9)
        }
      }
    }

    return row.type.indexOf("delete") === -1 ? (
      <span>
        <strong
          className="history-content-strong"
          onClick={() => clickContract(row, "", firstStrong)}
        >
          {firstStrong}
        </strong>
        {secondStrong ? "의" : ""}
        {secondStrong ? (
          <strong className="history-content-strong" onClick={() => clickSubContract(row)}>
            {" "}
            {secondStrong}
          </strong>
        ) : (
          ""
        )}
        {hisotryType}
      </span>
    ) : (
      <span>
        <strong>{firstStrong}</strong>
        {secondStrong ? "의" : ""}
        {secondStrong ? (
          <strong
            className="history-content-strong"
            onClick={() => clickContract(row, "", secondStrong)}
          >
            {" "}
            {secondStrong}
          </strong>
        ) : (
          ""
        )}
        {hisotryType}
      </span>
    )
  }
  const [InputValue, setInputValue] = useState("")
  const inputValue = (e) => {
    setInputValue(e.target.value)
  }
  const authCheckFile = (row) => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else if (row[1].id === myData.id) return true
    else if (row[1].contract_document) {
      if (row[1].contract_document.referrer.map((x) => x.id).indexOf(myData.id) > -1) {
        return true
      } else return false
    } else {
      return false
    }
  }

  const [ischeck, setischeck] = useState(false)
  const onClickSignificant = (SignificantsType) => {
    let dt = new Date()
    let profileurl = ""
    if (myData.profile) {
      profileurl = myData.profile.url
    }
    setSignificants([
      ...Significants,
      {
        content: InputValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        writer: DetailData.id ? DetailData.id : null,
        writerId: myData.id,
        updated_at: dt,
        type: SignificantsType,
      },
    ])

    const tag = document.getElementById("significantInputValue")
    tag.value = ""
    setInputValue("")
    setischeck(true)
    setcreateContractShow(true)
  }
  const [openDeleteJudging_Dialog, setopenDeleteJudging_Dialog] = useState(false)
  const [openChangeJudging_Dialog, setopenChangeJudging_Dialog] = useState(false)
  const [DeleteJudging_Content, setDeleteJudging_Content] = useState("")
  const [ChangeJudging_Content, setChangeJudging_Content] = useState("")
  const [open_DenyReasonDialog, setopen_DenyReasonDialog] = useState(false)
  const [denyReason, setdenyReason] = useState("")
  const close_denyReason = () => {
    setopen_DenyReasonDialog(false)
  }
  const handleDelete = (x, row) => {
    const data = [...ContractDatas]
    if (row.length > 0) {
      data.forEach((el, index) => {
        if (el[8].data.length > 0) {
          el[8].data.forEach((el2) => {
            if (row.length > 0) {
              if (row[0][0].id) {
                if (el2[0][0].id === row[0][0].id) {
                  const array = [...row[0][0].referrer]
                  const index = array.map((x) => x.id).indexOf(x.id)
                  array.splice(index, 1)
                  el2[0][0].referrer = array
                  el[1].changed = true
                  el2[0][0].changed = true
                }
              } else {
                if (row[0][1].created_at === el2[0][1].created_at) {
                  const array = [...row[0][0].referrer]
                  const index = array.map((x) => x.id).indexOf(x.id)
                  array.splice(index, 1)
                  el2[0][0].referrer = array
                  el[1].changed = true
                  el2[0][0].changed = true
                }
              }
            }
          })
        }
        if (index === data.length - 1) {
          setContractDatas(
            data.sort((a, b) => {
              var dateA = new Date(a[1].created_at).getTime()
              var dateB = new Date(b[1].created_at).getTime()
              return dateA < dateB ? 1 : -1
            })
          )
          setcreateContractShow(true)
          // setselectContarct([])
        }
      })
    }
  }
  const openChangeDialog = (row) => {
    const date = DetailData.change_requests.filter(
      (x) => x.arrayContent.writersIds.indexOf(row[0][0].id) > -1
    ).created_at
    let content = "현재 계약서 이관 신청 건(" + dayjs(date).format("YY.MM.DD") + ")을 심사중입니다."
    setChangeJudging_Content(content)
    setopenChangeJudging_Dialog(true)
  }
  const openDeleteDialog = (row) => {
    let notRead = row[0][0].requests.filter((e) => {
      return e.read === false
    })
    if (notRead.length === 0) {
      return false
    } else {
      notRead.sort((a, b) => {
        var a = a.id
        var b = b.id
        return a < b ? 1 : -1
      })

      if (notRead[0].status.indexOf("심사중") > -1) {
        let content =
          "현재 계약서 삭제 신청 건(" +
          dayjs(notRead[0].created_at).format("YY.MM.DD") +
          ")을 심사중입니다."
        setDeleteJudging_Content(content)
        setopenDeleteJudging_Dialog(true)
      } else if (notRead[0].status.indexOf("거절") > -1) {
        updateRequestActive({
          variables: {
            id: notRead[0].id,
            read: true,
          },
        }).then(() => {
          let readId = row[0][0].requests.findIndex((e) => e.id === notRead[0].id)
          row[0][0].requests[readId].read = true
          setopen_DenyReasonDialog(true)
          setdenyReason(notRead[0].declining)
        })
      }
    }
  }
  const [MaxHistoryList, setMaxHistoryList] = useState(4)
  const showChangeRequest = (row) => {
    if (DetailData.change_requests.length > 0) {
      if (
        DetailData.change_requests.filter(
          (x) => x.arrayContent.writersIds.indexOf(row[0][0].id) > -1
        ).length > 0
      )
        return true
      else return false
    } else return false
  }
  const showDeleteRequest = (row) => {
    let notRead = row[0][0].requests.filter((e) => {
      return e.read === false
    })
    if (notRead.length === 0) {
      return false
    } else {
      notRead.sort((a, b) => {
        var a = a.id
        var b = b.id
        return a < b ? 1 : -1
      })

      if (notRead[0].status.indexOf("심사중") > -1) {
        return "삭제신청"
      } else if (notRead[0].status.indexOf("거절") > -1) {
        return "승인거절"
      }
    }
  }
  const [openTool, setOpenTool] = useState(false)
  const handleCloseTool = () => {
    setOpenTool(false)
  }

  const handleOpenTool = () => {
    setOpenTool(true)
  }
  const ShowStateIcon = (data, index) => {
    if (data.length > 0) {
      return (
        <div onMouseEnter={() => (tooltipIndex.current = index)}>
          <Tooltip
            open={openTool}
            onClose={handleCloseTool}
            onOpen={handleOpenTool}
            color="white"
            classes={{ popper: "tooltip-light" }}
            arrow={false}
            title={
              index === tooltipIndex.current ? (
                <SuiBox>
                  <SuiTypography
                    style={{ fontSize: "12px", color: "#9AA0A6" }}
                    verticalAlign="middle"
                  >
                    계약 유효일
                  </SuiTypography>
                  <SuiTypography
                    style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}
                    verticalAlign="middle"
                  >
                    {dayjs(new Date()) > dayjs(data[1].value2)
                      ? "계약종료"
                      : betweenDay(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(data[1].value2).format("YYYYMMDD")
                        ) + "일"}
                  </SuiTypography>
                  <SuiTypography
                    style={{ fontSize: "12px", color: "#707070", fontWeight: "normal" }}
                    verticalAlign="middle"
                  >
                    {`${data[3].value === "자동갱신" ? "갱신일" : "종료일"} : ${dayjs(
                      data[1].value2
                    ).format("YY.MM.DD")}`}
                  </SuiTypography>
                </SuiBox>
              ) : (
                ""
              )
            }
          >
            {betweenDayColor(
              dayjs(new Date()).format("YYYYMMDD"),
              dayjs(data[1].value2).format("YYYYMMDD")
            ) ? (
              <IconButton
                color={betweenDayColor(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(data[1].value2).format("YYYYMMDD")
                )}
                sx={{ p: 0, cursor: "default" }}
              >
                {data[3].value === "자동갱신" ? (
                  <BiRecycle size={20} />
                ) : (
                  <BsFillCircleFill size={10}></BsFillCircleFill>
                )}
              </IconButton>
            ) : (
              <div></div>
            )}
          </Tooltip>
        </div>
      )
    } else {
      return "-"
    }
  }
  const downloadCheck2 = (row) => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else if (myData.role.name === "silver") {
      if (row[1].makerId === myData.id) return true
      else if (row[1].contract_document) {
        if (row[1].contract_document.referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
        else return false
      } else {
        return false
      }
    } else if (myData.role.name === "bronze") {
      if (row[1].contract_document) {
        if (row[1].contract_document.referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
        else return false
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const downloadCheck = (row) => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else if (myData.role.name === "silver") {
      if (row[0].user) {
        if (row[0].user.id === myData.id) return true
        else if (row[0].referrer) {
          if (row[0].referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
          else return false
        } else {
          return false
        }
      } else {
        return false
      }
    } else if (myData.role.name === "bronze") {
      if (row[0].referrer) {
        if (row[0].referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
        else return false
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const Content = () => {
    if (value === 0) {
      return (
        <div>
          <FileUploadDialog
            openDialog={FileUploadDialogBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            AttachmentData={setAttachmentData}
            edit={editBool}
            editData={editData}
          />

          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  개인 상세 정보
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <Grid container alignItems="center">
                  {InputListShow}
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>
          <Card sx={{ my: "16px", boxShadow: 0 }}>
            <SuiBox
              pl={5}
              pt={5}
              pb={2}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  첨부 파일
                </SuiTypography>
              </SuiBox>
              <SuiBox display="flex" sx={{ my: 1, pr: 5 }}>
                {typeData !== "update" && typeData !== "create" ? (
                  ""
                ) : (
                  <SuiButton
                    sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32, p: 1 }}
                    color="primary"
                    onClick={() => addFilesBtn()}
                  >
                    <AiOutlinePlus size="20" />
                  </SuiButton>
                )}
                <SuiBox sx={{ width: 100, ml: "auto", mb: "20px" }}>
                  <FormControl
                    sx={{ minHeight: 30, width: "100%" }}
                    className={
                      FilesSorting === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      value={FilesSorting}
                      onChange={(e) => setFilesSorting(e.target.value)}
                      className="setting-border-radius"
                    >
                      <MenuItem value="최신순">최신순</MenuItem>
                      <MenuItem value="오래된 순">오래된 순</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>
              </SuiBox>
              <SuiBox display="flex" sx={{ my: 1, width: "100%", overflowX: "auto", pb: 3 }}>
                {AttachmentDatasShow_type2()}
              </SuiBox>
            </SuiBox>
          </Card>
          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              pr={3}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  특이사항 기록
                </SuiTypography>
              </SuiBox>
              {typeData === "create" || typeData === "update" ? (
                <SuiBox sx={{ mb: 2 }}>
                  <SuiBox display="flex" sx={{ mt: 3, mr: 4 }}>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                      style={{ backgroundColor: "transparent" }}
                      alt="History"
                      src={
                        myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""
                      }
                    />
                    <SuiInput
                      type="textfield"
                      // className="significantInput"
                      id="significantInputValue"
                      sx={{ ml: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      onChange={inputValue}
                    />
                  </SuiBox>

                  <SuiBox sx={{ textAlign: "end", mt: 3, mr: 3.5 }}>
                    <SuiButton
                      color="info2"
                      sx={{
                        width: 100,
                        fontSize: 14,
                      }}
                      disabled={InputValue.length > 0 ? false : true}
                      onClick={() => onClickSignificant("basic")}
                    >
                      등록
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              ) : null}
              {significant()}
            </SuiBox>
          </Card>
          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  최초 계약 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  <Grid item sx={{ pr: 2, width: "248px" }}>
                    <SuiBox display="flex" sx={{ mt: 1 }}>
                      <SuiTypography
                        fontSize="15px"
                        color="info"
                        sx={{ pt: 2, pb: 1, fontWeight: 400 }}
                      >
                        계약일자:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                      <Card
                        sx={{
                          width: "100%",
                          height: 57,
                          backgroundColor: "#F7F9FB",
                          boxShadow: 0,
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: 700,
                          pt: 0.8,
                          color: "#535353",
                        }}
                      >
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            color: "#535353",
                          }}
                        >
                          <IconButton color="text2" sx={{ mb: 0, mx: 1 }}>
                            <BiCalendar />
                          </IconButton>
                          {ContractDatas.length > 0 ? (
                            FindingFirst(ContractDatas) ? (
                              <div style={{ margin: "0 auto" }}>
                                {dayjs(FindingFirst(ContractDatas)[1].value1).format("YYYY.MM.DD")}
                              </div>
                            ) : (
                              <div style={{ margin: "0 auto" }}>자동 입력 사용</div>
                            )
                          ) : (
                            <div style={{ margin: "0 auto" }}>자동 입력 사용</div>
                          )}
                        </SuiBox>
                      </Card>
                    </SuiBox>
                  </Grid>
                  {ContractDatas.length > 0 ? (
                    FindingFirst(ContractDatas) ? (
                      <Grid item sx={{ pr: 2, width: "420px" }}>
                        <SuiBox display="flex" sx={{ mt: 1 }}>
                          <SuiTypography
                            fontSize="15px"
                            fontWeight="bold"
                            color="info"
                            sx={{ pt: 2, pb: 1, fontWeight: 400 }}
                          >
                            계약 정보:
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                          <Card
                            sx={{
                              width: "100%",
                              height: 57,
                              backgroundColor: "#F7F9FB",
                              boxShadow: 0,
                              textAlign: "center",
                              fontSize: 18,
                              fontWeight: 700,
                              pt: 0.8,
                              px: 4,
                            }}
                          >
                            <SuiBox
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: 40, color: "#535353" }}
                            >
                              {FindingFirst(ContractDatas)[0].selectData[1].value.length > 6 ? (
                                <Tooltip
                                  arrow={false}
                                  color="white"
                                  classes={{ popper: "tooltip-light" }}
                                  title={
                                    <SuiTypography
                                      style={{
                                        fontSize: "14px",
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      verticalAlign="middle"
                                    >
                                      <span className="first-contract">
                                        {FindingFirst(ContractDatas)[0].selectData[1].value}
                                      </span>
                                    </SuiTypography>
                                  }
                                >
                                  <span className="first-contract">
                                    {FindingFirst(ContractDatas)[0].selectData[1].value}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className="first-contract">
                                  {FindingFirst(ContractDatas)[0].selectData[1].value}
                                </span>
                              )}
                              <Divider
                                orientation="vertical"
                                sx={{
                                  borderColor: "#EEEEEE",
                                  mx:
                                    FindingFirst(ContractDatas)[0].selectData[1].value.length > 3
                                      ? 1.5
                                      : 3,
                                }}
                              />
                              <div style={{ margin: "0 auto" }}>
                                {dayjs(FindingFirst(ContractDatas)[1].value2) > dayjs(new Date()) &&
                                FindingFirst(ContractDatas)[0].ContractType !== "계약 종료" ? (
                                  dayjs(FindingFirst(ContractDatas)[1].value2).format(
                                    "YYYYMMDD"
                                  ) === "29990101" ? (
                                    ""
                                  ) : (
                                    <strong>
                                      {betweenDay(
                                        dayjs(new Date()).format("YYYYMMDD"),
                                        dayjs(FindingFirst(ContractDatas)[1].value2).format(
                                          "YYYYMMDD"
                                        )
                                      )}
                                    </strong>
                                  )
                                ) : (
                                  <span style={{ minWidth: "68px" }}>계약 종료</span>
                                )}
                                <span
                                  style={{
                                    fontSize: 18,
                                    fontWeight: 400,
                                    minWidth:
                                      dayjs(FindingFirst(ContractDatas)[1].value2) >
                                        dayjs(new Date()) &&
                                      FindingFirst(ContractDatas)[0].ContractType !== "계약 종료"
                                        ? "41px"
                                        : "8px",
                                  }}
                                >
                                  {dayjs(FindingFirst(ContractDatas)[1].value2) >
                                    dayjs(new Date()) &&
                                  FindingFirst(ContractDatas)[0].ContractType !== "계약 종료" ? (
                                    dayjs(FindingFirst(ContractDatas)[1].value2).format(
                                      "YYYYMMDD"
                                    ) === "29990101" ? (
                                      <strong>무기한</strong>
                                    ) : (
                                      "일 남음"
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                                {FindingFirst(ContractDatas)[3].value === "자동갱신" ? (
                                  <IconButton
                                    color={betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(FindingFirst(ContractDatas)[1].value2).format(
                                        "YYYYMMDD"
                                      )
                                    )}
                                    sx={{ pr: 0 }}
                                    className="cursorDefalut"
                                  >
                                    <BiRecycle />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </div>
                            </SuiBox>
                          </Card>
                        </SuiBox>
                      </Grid>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {FindingFirst(ContractDatas) && ContractDatas.length > 0 ? (
                    <Grid item sx={{ width: "335px" }}>
                      <SuiBox display="flex" sx={{ mt: 1 }}>
                        <SuiTypography
                          fontSize="15px"
                          fontWeight="bold"
                          color="info"
                          sx={{ pt: 2, pb: 1, fontWeight: 400 }}
                        >
                          등록자:
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 0.8,
                            px: 3,
                          }}
                        >
                          <SuiBox
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: 40, color: "#535353" }}
                          >
                            <div style={{ margin: "0 auto" }}>
                              {FindingFirst(ContractDatas)[0].user?.department}
                            </div>
                            <Divider
                              orientation="vertical"
                              sx={{
                                borderColor: "#EEEEEE",
                                mx: FindingFirst(ContractDatas)[0].user?.name.length > 3 ? 1.5 : 3,
                              }}
                            />
                            <div style={{ margin: "0 auto" }}>
                              {FindingFirst(ContractDatas)[0].user?.name}{" "}
                              {FindingFirst(ContractDatas)[0].user?.position}
                            </div>
                          </SuiBox>
                        </Card>
                      </SuiBox>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>
          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  담당자 및 계약 정보
                </SuiTypography>
              </SuiBox>
              {ListCard_type2()}
            </SuiBox>
          </Card>

          <Card sx={{ mt: "16px", mb: 12, boxShadow: 0 }}>
            <SuiBox p={5} pr={3} display="flex" flexDirection="column" justifyContent="start">
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography mb="24px" fontSize="18px" fontWeight="bold" color="dark">
                  히스토리
                </SuiTypography>
                <SuiBox className="history-container">
                  {typeData === "create" ? (
                    <SuiTypography fontSize="18px" fontWeight="medium" color="text2">
                      변경 내용 저장시 히스토리에 기록됩니다.
                    </SuiTypography>
                  ) : (
                    DetailData.history.map((row, index) => {
                      return index <= MaxHistoryList ? (
                        <Card
                          key={index}
                          sx={{
                            width: "100%",
                            height: 72,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            p: 2,
                            mb: 2,
                          }}
                        >
                          <SuiBox display="flex" alignItems="center" justifyContent="center">
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                              }}
                              style={{ backgroundColor: "transparent" }}
                              alt="History"
                              src={
                                row.profile ? process.env.REACT_APP_BACKEND_URL + row.profile : ""
                              }
                            />
                            <SuiTypography
                              ml="14px"
                              fontSize="16px"
                              fontWeight="medium"
                              sx={{ color: "#0C65FF" }}
                            >
                              {row.department} {row.name} {row.position}
                            </SuiTypography>
                            <SuiTypography
                              ml="16px"
                              fontSize="16px"
                              fontWeight="medium"
                              sx={{ color: "#535353", width: "60%", textAlign: "left" }}
                            >
                              {row.type && row.type.indexOf("contract") > -1 ? (
                                historyContent(row)
                              ) : (
                                <span dangerouslySetInnerHTML={{ __html: row.content }}></span>
                              )}
                            </SuiTypography>
                            <SuiTypography
                              mr="4px"
                              ml="auto"
                              fontSize="16px"
                              fontWeight="medium"
                              color="text2"
                            >
                              {row.date}
                            </SuiTypography>
                          </SuiBox>
                        </Card>
                      ) : (
                        ""
                      )
                    })
                  )}
                  {MaxHistoryList < DetailData.history.length - 1 ? (
                    <SuiBox
                      sx={{ pl: 3, mt: "40px" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SuiButton
                        color="info2"
                        variant="text"
                        sx={{
                          width: 100,
                          border: 1,
                          borderColor: "#0C65FF",
                          height: 40,
                          mr: 2,
                          fontSize: 14,
                        }}
                        onClick={() => setMaxHistoryList(MaxHistoryList + 5)}
                      >
                        더보기
                      </SuiButton>
                    </SuiBox>
                  ) : (
                    ""
                  )}
                </SuiBox>
              </SuiBox>
            </SuiBox>
            {openContractInfoDialog ? (
              <ContractInfoDialog
                openDialog={openContractInfoDialog}
                closeDialog={closeDialogInfo}
                infoData={InfoData}
              />
            ) : (
              ""
            )}
            {openSubContractInfoDialog ? (
              <SubContractInfoDialog
                openDialog={openSubContractInfoDialog}
                closeDialog={closeDialogInfo}
                infoData={SubInfoData}
              />
            ) : (
              ""
            )}
          </Card>
          <ContractMangerInfoDialog
            openDialog={openMangerDialog}
            closeDialog={closeDialog}
            ContarctData={MangerContractData()}
          />
        </div>
      )
    } else if (value === 1) {
      const all_contracts = (type) => {
        let total = 0
        if (type === "document") {
          DetailData.contracts.forEach((el) => {
            total += el.contract_documents.length
          })
        } else if (type === "ongoing_contract") {
          DetailData.contracts.forEach((el) => {
            let ongoing = false
            el.contract_documents.forEach((contract) => {
              let today = dayjs(new Date()).format("YYYY-MM-DD")

              if (dayjs(contract.contractPaper[0][1].value2).isAfter(today)) {
                ongoing = true
              }
            })
            if (ongoing) {
              total += 1
            }
          })
        } else if (type === "ongoing_document") {
          DetailData.contracts.forEach((el) => {
            el.contract_documents.forEach((contract) => {
              let today = dayjs(new Date()).format("YYYY-MM-DD")

              if (dayjs(contract.contractPaper[0][1].value2).isAfter(today)) {
                total += 1
              }
            })
          })
        }
        if (total > 0) {
          return total
        } else {
          return "-"
        }
      }
      return (
        <div>
          <ReferrerCheck
            openDialog={ReferrerCheckDialog}
            closeDialog={() => setReferrerCheckDialog(false)}
            ContarctData={ReferrerCheckData}
          ></ReferrerCheck>
          <ReferrerDialog
            openDialog={referrerDialog}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            referrerIds={setreferrerIds}
            referrerIdsUpdate={referrerIds}
            department_list={settings_ref.current}
          />
          <ContractChangeInfoDialog
            openDialog={ContractChangeDialogInfoOpenBool}
            closeDialog={closeDialog}
            ContarctData={ContarctChangeInfoData}
          />
          <ContractChangeTitleInfoDialog
            openDialog={ContractChangeTitleDialogInfoOpenBool}
            closeDialog={closeDialog}
            ContarctData={ContarctChangeTitleInfoData}
          />

          <ContractSubDialog
            openDialog={ContractSubDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            edit={ContractSubDialogEdit}
            editData={ContractSubDialogEditData}
            ContarctData={ContarctSubData}
            ContarctSubParentData={ContarctSubParentData}
            subData={setContarctSubDataApply}
          />
          <ContractChangeDialog
            openDialog={ContractChangeDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            ContarctData={ContarctChangeData}
          />
          <ContractDeleteDialog
            openDialog={openContract_deleteDialog}
            closeDialog={closeDialog}
            ContractDeleteData={Contract_deleteData}
            deleteContractData={delete_ContractData}
            DetailData={DetailData}
            deleteRequest={deleteRequest}
            setcreateContractShow={setcreateContractShow}
          />
          <DeleteDenyDialog
            openDialog={open_DenyReasonDialog}
            closeDialog={close_denyReason}
            denyReason={denyReason}
          />

          {ContractDatas.length > 0 ? (
            <ContractRenewalDialog
              openDialog={ContractRenewalDialogOpenBool}
              closeDialog={closeDialog}
              closeDialog2={closeDialog2}
              edit={ContractRenewalDialogEdit}
              editData={ContractRenewalDialogEditData}
              ContarctData={ContarctRenewalData}
              ContarctDatas={
                ContractDatas[contract_documents_data_index][8].data[
                  contract_documents_data_edit_index
                ]
              }
              type={ContarctRenewalType}
              renewalData={setrenewalData}
              renewalEndData={setrenewalEndData}
            />
          ) : (
            ""
          )}
          <ContractDialog
            openDialog={ContractDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            contract_documents_data={setcontract_documents_data}
            contract_documents_data_update={setcontract_documents_data_update}
            edit={ContractDialogEdit}
            editData={ContractDialogEditData}
            type={ContractType}
            contract_documents={ContractDatas[contract_documents_data_index]}
          />
          {typeData === "create" || typeData === "update" ? (
            <SuiButton
              sx={{
                minHeight: 67,
                fontSize: 18,
                fontWeight: 700,
                boxShadow: 3,
                width: "100%",
                mb: "16px",
                mt: "20px",
              }}
              className="outlined-bg-white"
              variant="outlined"
              color="primary"
              onClick={() => addContract()}
            >
              계약 작품 추가하기
            </SuiButton>
          ) : (
            ""
          )}
          {/* 계약 작품 정보 */}
          <Card
            sx={{
              boxShadow: 0,
              px: 4,
              py: 5,
              my: 5,
            }}
          >
            <SuiBox sx={{ textAlign: "start", pl: 1 }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                계약 작품 정보
              </SuiTypography>
            </SuiBox>
            <Grid container alignItems="center">
              <Grid item xs={3} sx={{ pl: 1, pr: 1 }}>
                <SuiBox>
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="15px"
                      color="info"
                      sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                    >
                      등록 작품 수 :
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ width: "100%" }}>
                    <Card
                      sx={{
                        width: "100%",
                        height: 47,
                        backgroundColor: "#F7F9FB",
                        boxShadow: 0,
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        pt: 0.8,
                        mb: 1,
                      }}
                    >
                      {DetailData.contracts && DetailData.contracts.length > 0
                        ? DetailData.contracts.length
                        : "-"}
                    </Card>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={3} sx={{ pl: 1, pr: 1 }}>
                <SuiBox>
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="15px"
                      color="info"
                      sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                    >
                      등록 계약 수 :
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ width: "100%" }}>
                    <Card
                      sx={{
                        width: "100%",
                        height: 47,
                        backgroundColor: "#F7F9FB",
                        boxShadow: 0,
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        pt: 0.8,
                        mb: 1,
                      }}
                    >
                      {DetailData.contracts && DetailData.contracts.length > 0
                        ? all_contracts("document")
                        : "-"}
                    </Card>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={3} sx={{ pl: 1, pr: 1 }}>
                <SuiBox>
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="15px"
                      color="info"
                      sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                    >
                      계약중인 작품 수 :
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ width: "100%" }}>
                    <Card
                      sx={{
                        width: "100%",
                        height: 47,
                        backgroundColor: "#F7F9FB",
                        boxShadow: 0,
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        pt: 0.8,
                        mb: 1,
                      }}
                    >
                      {DetailData.contracts && DetailData.contracts.length > 0
                        ? all_contracts("ongoing_contract")
                        : "-"}
                    </Card>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={3} sx={{ pl: 1, pr: 1 }}>
                <SuiBox>
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="15px"
                      color="info"
                      sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                    >
                      진행중인 계약 수 :
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ width: "100%" }}>
                    <Card
                      sx={{
                        width: "100%",
                        height: 47,
                        backgroundColor: "#F7F9FB",
                        boxShadow: 0,
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        pt: 0.8,
                        mb: 1,
                      }}
                    >
                      {DetailData.contracts && DetailData.contracts.length > 0
                        ? all_contracts("ongoing_document")
                        : "-"}
                    </Card>
                  </SuiBox>
                </SuiBox>
              </Grid>
            </Grid>
          </Card>

          <SuiBox sx={{ width: 100, ml: "auto", mt: 4 }}>
            <FormControl
              sx={{ minHeight: 30, width: "100%" }}
              className={
                FilesSortingContract === ""
                  ? "setting-selectbox select-none-active"
                  : "setting-selectbox"
              }
              onClick={() => setFiltersOpen4(!FiltersOpen4)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen4(!FiltersOpen4)}
                  />
                )}
                open={FiltersOpen4}
                value={FilesSortingContract}
                onChange={(e) => setFilesSortingContract(e.target.value)}
                className="setting-border-radius"
              >
                <MenuItem value="최신순">최신순</MenuItem>
                <MenuItem value="오래된 순">오래된 순</MenuItem>
              </Select>
            </FormControl>
          </SuiBox>
          {createShow && (typeData === "create" || typeData === "update") ? (
            <Card sx={{ mt: 2, boxShadow: 0, px: 4, pt: 1, mb: 4 }}>
              <SuiBox display="flex" alignItems="center">
                <input
                  className="addContract"
                  type="text"
                  placeholder="작품명을 입력해주세요."
                  value={ContractData[0].value}
                  onChange={handleInputContract(0, ContractData[0].maxLength)}
                ></input>
                <SuiBox sx={{ ml: "auto" }}>
                  <SuiButton
                    color="subTitle"
                    variant="text"
                    sx={{ fontSize: "18px", fontWeight: 400 }}
                    onClick={ContractDataReset}
                  >
                    취소
                  </SuiButton>
                  <SuiButton
                    className="only-text-btn"
                    sx={{ fontSize: "18px", fontWeight: 400 }}
                    color="info2"
                    variant="text"
                    disabled={ContractData[0].value === ""}
                    onClick={() => createContractBtn()}
                  >
                    완료
                  </SuiButton>
                  <IconButton onClick={() => ContarctDropDown(0, ContractData[0].dropdown)}>
                    {ContractData[0].dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </SuiBox>
              </SuiBox>
              <Divider sx={{ mb: 4, mt: 1 }} />
              <SuiBox className={ContractData[0].dropdown ? "none-panel panel-1" : "none-panel"}>
                <SuiTypography
                  fontSize="16px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left" }}
                >
                  작품 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  {ContractDataShow}
                </Grid>
              </SuiBox>
              <SuiBox className={ContractData[0].dropdown ? "none-panel panel-2" : "none-panel"}>
                <SuiBox display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <SuiTypography
                    fontSize="16px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left" }}
                  >
                    작품 계약서 ({ContractData[8] ? ContractData[8].data.length : "0"})
                  </SuiTypography>
                  <SuiButton
                    className="only-text-btn"
                    sx={{ fontSize: "16px", ml: "auto" }}
                    color="primary"
                    variant="text"
                    disabled
                  >
                    + 계약서 추가하기
                  </SuiButton>
                </SuiBox>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="text2"
                  sx={{ textAlign: "left" }}
                >
                  아직 등록된 작품 계약 정보가 없습니다.
                </SuiTypography>
              </SuiBox>
            </Card>
          ) : (
            ""
          )}
          {dataShowActive &&
          ContractDatas.length === 0 &&
          typeData !== "create" &&
          typeData !== "update" ? (
            <SuiTypography
              fontSize="18px"
              fontWeight="regular"
              color="text2"
              sx={{ textAlign: "left" }}
            >
              아직 등록된 작품 계약 정보가 없습니다.
            </SuiTypography>
          ) : null}
          {ContractDatas.map((data, ContractIndex) => {
            return (
              <Card
                sx={{
                  mt: 2,
                  boxShadow: 0,
                  px: 4,
                  pt: 1,
                  mb: ContractIndex === ContractDatas.length - 1 ? 12 : 2,
                }}
                key={ContractIndex}
              >
                <SuiBox display="flex" alignItems="center">
                  {typeData === "detail" && (
                    <>
                      <div
                        style={{
                          height: "48px",
                          paddingTop: "10px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        [{data[0].id ? data[0].id.padStart(5, "0") : "00000"}] {data[0].value}
                      </div>

                      <SuiButton
                        color="info"
                        sx={{
                          minHeight: 20,
                          height: 20,
                          ml: 2,
                          mt: 0.5,
                          px: 0,
                          display: changeTitleButton(data[0]) ? "none" : "",
                        }}
                        onClick={() => {
                          changeTitleInfo(data[0])
                        }}
                      >
                        변경이력
                      </SuiButton>
                    </>
                  )}
                  {(typeData === "create" || typeData === "update") && (
                    <input
                      className="addContract"
                      type="text"
                      id={data[0].value + "1"}
                      placeholder="작품명을 입력해주세요."
                      value={data[0].value}
                      readOnly={typeData === "detail"}
                      onChange={handleInputContracts(ContractIndex, 0, data[0].maxLength)}
                    ></input>
                  )}
                  {typeData === "create" || typeData === "update" ? (
                    <SuiBox sx={{ ml: "auto" }}>
                      <SuiButton
                        id={data[0].value}
                        className="only-text-btn"
                        sx={{ fontSize: "18px", fontWeight: 400 }}
                        color="info2"
                        variant="text"
                        disabled={contract_update(data[0]) ? true : false}
                        onClick={() => {
                          updateTitle(data[0])
                        }}
                      >
                        수정
                      </SuiButton>
                      <SuiButton
                        className="only-text-btn"
                        sx={{ fontSize: "18px", fontWeight: 400 }}
                        color="error"
                        variant="text"
                        onClick={() =>
                          data[8].data.length > 0
                            ? setopenCantDelete_Dialog(true)
                            : deleteContractBtn(ContractIndex)
                        }
                      >
                        삭제
                      </SuiButton>
                    </SuiBox>
                  ) : (
                    ""
                  )}
                </SuiBox>
                <Divider sx={{ mb: 4, mt: 1 }} />
                <SuiTypography
                  fontSize="16px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left" }}
                >
                  작품 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  {data.map((row, dataIndex) => {
                    if (dataIndex > 0 && dataIndex !== 8) {
                      return (
                        <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
                          <SuiBox>
                            <SuiBox display="flex">
                              <SuiTypography
                                fontSize="15px"
                                color="info"
                                sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                              >
                                {row.title}
                              </SuiTypography>
                            </SuiBox>
                            {InputTypeEl(row, dataIndex, ContractIndex, "update")}
                          </SuiBox>
                        </Grid>
                      )
                    }
                  })}
                </Grid>
                <SuiBox display="flex" alignItems="center" sx={{ mb: 2, mt: 4 }}>
                  <SuiTypography
                    fontSize="16px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left", cursor: "pointer" }}
                    onClick={() => ContarctsDropDown(ContractIndex, 0, data[0].dropdown)}
                  >
                    작품 계약서 ({data[8].data.length})
                  </SuiTypography>
                  <SuiBox sx={{ ml: "auto" }}>
                    <IconButton
                      onClick={() => ContarctsDropDown(ContractIndex, 0, data[0].dropdown)}
                    >
                      {data[0].dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </SuiBox>
                </SuiBox>
                <SuiBox className={data[0].dropdown ? "none-panel panel-2" : "none-panel"}>
                  <SuiBox display="flex" sx={{ mb: 2 }}>
                    {typeData === "update" ? (
                      <SuiButton
                        sx={{
                          minHeight: 32,
                          height: 32,
                          width: 32,
                          minWidth: 32,
                          p: 1,
                          mr: "auto",
                        }}
                        color="primary"
                        onClick={() => updateContractDialogopen(ContractIndex, index)}
                      >
                        <AiOutlinePlus size="20" />
                      </SuiButton>
                    ) : (
                      ""
                    )}
                  </SuiBox>
                  {data[8].data.length === 0 ? (
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="regular"
                      color="text2"
                      sx={{ textAlign: "left" }}
                    >
                      아직 등록된 작품 계약 정보가 없습니다.
                    </SuiTypography>
                  ) : (
                    ""
                  )}
                  {data[8].data.length > 0 ? (
                    <SuiBox display="flex" justifyContent="end" sx={{ mt: "40px", mb: "20px" }}>
                      <IconButton
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick={() => setFilesSortingHistory("좁게보기")}
                        className={
                          FilesSortingHistory !== "넓게보기" ? "image-icon active" : "image-icon"
                        }
                      >
                        <FaTh />
                      </IconButton>
                      <IconButton
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick={() => setFilesSortingHistory("넓게보기")}
                        className={
                          FilesSortingHistory === "넓게보기" ? "image-icon active" : "image-icon"
                        }
                      >
                        <FaThLarge />
                      </IconButton>
                    </SuiBox>
                  ) : (
                    ""
                  )}
                  <Grid container alignItems="start" sx={{ mb: 4 }}>
                    {data[8].data.map((row, index) => {
                      return (
                        <Grid
                          item
                          xs={FilesSortingHistory !== "넓게보기" ? 3 : 6}
                          sx={{
                            pr: FilesSortingHistory !== "넓게보기" ? 2 : index % 2 === 0 ? 2 : 0,
                            mb: 2,
                          }}
                          key={index}
                        >
                          <Card
                            sx={{
                              boxShadow: "0px 3px 6px transparent",
                              border: "1px solid rgba(0, 0, 0, 0.16)",
                              py: 2,
                              backgroundColor:
                                dayjs(row[0][1].value2) < dayjs(new Date()) ? "#F7F9FB" : "",
                            }}
                          >
                            <SuiBox
                              display="flex"
                              alignItems="center"
                              sx={{
                                px: 2,
                              }}
                            >
                              <SuiTypography
                                fontSize="16px"
                                fontWeight="medium"
                                color="dark"
                                sx={{
                                  py:
                                    row[0][3].value === "자동갱신"
                                      ? dayjs(row[0][1].value2) > dayjs(new Date()) ||
                                        row[0][0].ContractType !== "계약 종료"
                                        ? ""
                                        : "6px"
                                      : "6px",
                                }}
                              >
                                {row[0][0].selectData[1].value}
                              </SuiTypography>
                              <SuiTypography
                                fontSize="16px"
                                fontWeight="medium"
                                sx={{
                                  py:
                                    row[0][3].value === "자동갱신"
                                      ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                        row[0][0].ContractType !== "계약 종료"
                                        ? ""
                                        : "6px"
                                      : "6px",
                                  display: showDeleteRequest(row) ? "" : "none",
                                  ml: 2,
                                  textDecoration: "underline",
                                  color:
                                    showDeleteRequest(row) === "삭제신청" ? "#FF4D60" : "#9AA0A6",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  showDeleteRequest(row) === "삭제신청"
                                    ? open_cancle_alert("delete", row)
                                    : openDeleteDialog(row)
                                }
                              >
                                {showDeleteRequest(row)}
                              </SuiTypography>
                              <SuiTypography
                                fontSize="16px"
                                fontWeight="medium"
                                sx={{
                                  py:
                                    row[0][3].value === "자동갱신"
                                      ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                        row[0][0].ContractType !== "계약 종료"
                                        ? ""
                                        : "6px"
                                      : "6px",
                                  display: showChangeRequest(row) ? "" : "none",
                                  ml: 2,
                                  textDecoration: "underline",
                                  color: "#FF4D60",
                                  cursor: "pointer",
                                }}
                                onClick={() => open_cancle_alert("change", row)}
                              >
                                이관신청
                              </SuiTypography>
                              <SuiBox
                                sx={{
                                  ml: "auto",
                                  py:
                                    row[0][3].value === "자동갱신"
                                      ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                        row[0][0].ContractType !== "계약 종료"
                                        ? ""
                                        : "6px"
                                      : "6px",
                                }}
                              >
                                {FilesSortingHistory !== "넓게보기" ? (
                                  ""
                                ) : (
                                  <SuiTypography fontSize="16px" fontWeight="medium" color="dark">
                                    {dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                                      dayjs(row[0][1].value2).format("YYYY.MM.DD") ===
                                      "2999.01.01" ? (
                                        <span>무기한</span>
                                      ) : (
                                        <strong>
                                          {betweenDay(
                                            dayjs(new Date()).format("YYYYMMDD"),
                                            dayjs(row[0][1].value2).format("YYYYMMDD")
                                          )}
                                        </strong>
                                      )
                                    ) : (
                                      <span>계약 종료</span>
                                    )}

                                    <span style={{ fontSize: 14, fontWeight: 400 }}>
                                      {dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                      dayjs(row[0][1].value2).format("YYYY.MM.DD") !== "2999.01.01"
                                        ? "일 남음"
                                        : ""}
                                    </span>
                                  </SuiTypography>
                                )}
                              </SuiBox>
                              {row[0][3].value === "자동갱신" &&
                              row[0][0].ContractType !== "계약 종료" ? (
                                <IconButton
                                  color={betweenDayColor(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(row[0][1].value2).format("YYYYMMDD")
                                  )}
                                  className="cursorDefalut"
                                  sx={{ pr: 0 }}
                                >
                                  <BiRecycle />
                                </IconButton>
                              ) : row[0][3].value === "-" &&
                                row[0][0].ContractType === "계약 종료" &&
                                dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                                <IconButton
                                  color={betweenDayColor(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(row[0][1].value2).format("YYYYMMDD")
                                  )}
                                  className="cursorDefalut"
                                  sx={{ pr: 0 }}
                                >
                                  <BsFillCircleFill size={10} />
                                </IconButton>
                              ) : row[0][3].value === "해지시 종료" &&
                                dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                                <IconButton
                                  color={betweenDayColor(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(row[0][1].value2).format("YYYYMMDD")
                                  )}
                                  className="cursorDefalut"
                                  sx={{ pr: 0 }}
                                >
                                  <BsFillCircleFill size={10} />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {row[0][3].value !== "자동갱신" &&
                              dayjs(row[0][1].value2) < dayjs(new Date()) ? (
                                <IconButton
                                  sx={{
                                    color: "#b7b7b7",
                                  }}
                                  className="cursorDefalut"
                                >
                                  <BsFillCircleFill size={10} />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {typeData === "update" ? (
                                !showDeleteRequest(row) &&
                                !showChangeRequest(row) &&
                                (authCheckContract(row) || myData.role.name === "Authenticated") ? (
                                  <IconButton
                                    aria-label="more"
                                    id={`long-button`}
                                    aria-controls="long-menu"
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    color="dark"
                                    sx={{
                                      color: !row[0][0].id ? "#CDD2D3" : "",
                                      pr: 0,
                                      // display: row[0][0].ContractType === "계약 종료" ? "none" : "",
                                      cursor: !row[0][0].id ? "default" : "",
                                    }}
                                    onClick={(e) =>
                                      !row[0][0].id ? "" : handleClick(e, index, row)
                                    }
                                  >
                                    <FiMoreHorizontal />
                                  </IconButton>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              <Menu
                                sx={{
                                  display:
                                    index === anchorElIndex &&
                                    row[0][1].created_at === AnchorElRowData[0][1].created_at
                                      ? // &&
                                        // row[0][0].ContractType !== "계약 종료"
                                        ""
                                      : "none",
                                }}
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenu}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                  },
                                }}
                              >
                                {row[row[0][0].activeStep][0].id && authCheckContract(row, "no") ? (
                                  <MenuItem
                                    onClick={(e) =>
                                      ContractOption(
                                        row[row[0][0].activeStep],
                                        e,
                                        index,
                                        "update",
                                        ContractIndex,
                                        row[0][0].activeStep,
                                        row
                                      )
                                    }
                                  >
                                    계약이관
                                  </MenuItem>
                                ) : (
                                  ""
                                )}
                                {row[row[0][0].activeStep][0].id ? (
                                  <MenuItem
                                    onClick={(e) =>
                                      ContractOption(
                                        row[row[0][0].activeStep],
                                        e,
                                        index,
                                        "update",
                                        ContractIndex,
                                        row[0][0].activeStep
                                      )
                                    }
                                    sx={{
                                      backgroundColor: "#ffffff !important",
                                      opacity:
                                        row[0][0].ContractType === "계약 종료"
                                          ? "1 !important"
                                          : "",
                                      color:
                                        row[0][0].ContractType === "계약 종료"
                                          ? "#CED4D7 !important"
                                          : "",
                                    }}
                                    disabled={row[0][0].ContractType === "계약 종료" ? true : false}
                                  >
                                    갱신계약
                                  </MenuItem>
                                ) : (
                                  ""
                                )}
                                {row[row[0][0].activeStep][0].id ? (
                                  <MenuItem
                                    onClick={(e) =>
                                      ContractOption(
                                        row[row[0][0].activeStep],
                                        e,
                                        index,
                                        "update",
                                        ContractIndex,
                                        row[0][0].activeStep
                                      )
                                    }
                                    sx={{
                                      backgroundColor: "#ffffff !important",
                                      opacity:
                                        row[0][0].ContractType === "계약 종료"
                                          ? "1 !important"
                                          : "",
                                      color:
                                        row[0][0].ContractType === "계약 종료"
                                          ? "#CED4D7 !important"
                                          : "",
                                    }}
                                    disabled={row[0][0].ContractType === "계약 종료" ? true : false}
                                  >
                                    종료통고
                                  </MenuItem>
                                ) : (
                                  ""
                                )}
                                {/* <MenuItem
                                  onClick={(e) =>
                                    ContractOption(
                                      row[row[0][0].activeStep],
                                      e,
                                      index,
                                      "update",
                                      ContractIndex,
                                      row[0][0].activeStep
                                    )
                                  }
                                >
                                  수정하기
                                </MenuItem> */}
                                {authCheckContract(row, "no") ? (
                                  <MenuItem
                                    disabled={showDeleteRequest(row) ? true : false}
                                    onClick={(e) =>
                                      ContractOption(
                                        row[row[0][0].activeStep],
                                        e,
                                        index,
                                        "update",
                                        ContractIndex,
                                        row[0][0].activeStep,
                                        row,
                                        data
                                      )
                                    }
                                  >
                                    삭제신청
                                  </MenuItem>
                                ) : (
                                  ""
                                )}
                              </Menu>
                            </SuiBox>
                            <Card sx={{ boxShadow: 0, mx: 2, p: 2, backgroundColor: "#F7F9FB" }}>
                              <SwipeableViews
                                axis="x"
                                index={row[row[0][0].activeStep][0].activeStep}
                                onChangeIndex={(e) => handleStepChange(e, ContractIndex, index)}
                                enableMouseEvents
                              >
                                {row.map((rowData, rowIndex) => {
                                  const fileName = rowData[0].file?.name
                                  const fileType =
                                    fileName.split(".")[fileName.split(".").length - 1]
                                  return (
                                    <SuiBox key={rowIndex}>
                                      <SuiBox display="flex" alignItems="center">
                                        <SuiTypography
                                          fontSize="14px"
                                          fontWeight="medium"
                                          color="dark"
                                          sx={{ textAlign: "left", mb: 1 }}
                                        >
                                          {rowData[0].ContractType
                                            ? rowData[0].ContractType === "계약 종료"
                                              ? rowData[0].ContractType + " 통고서"
                                              : rowData[0].ContractType +
                                                "_" +
                                                (row.length - rowIndex - 1)
                                            : "최초 계약"}
                                        </SuiTypography>
                                        {typeData === "update" && rowIndex === 0 ? (
                                          authCheckContract(row) ? (
                                            <SuiTypography
                                              fontSize="14px"
                                              fontWeight="medium"
                                              color="info2"
                                              sx={{ ml: "auto", mb: 1, mr: 1, cursor: "pointer" }}
                                              onClick={(e) =>
                                                ContractOption(
                                                  row[row[0][0].activeStep],
                                                  e,
                                                  index,
                                                  "update",
                                                  ContractIndex,
                                                  row[0][0].activeStep
                                                )
                                              }
                                            >
                                              수정
                                            </SuiTypography>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </SuiBox>
                                      <table className="contract-table">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                계약일
                                              </SuiTypography>
                                            </td>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                계약 종료일
                                              </SuiTypography>
                                            </td>
                                            {FilesSortingHistory !== "넓게보기" ? (
                                              ""
                                            ) : (
                                              <td>
                                                <SuiTypography
                                                  fontSize="13px"
                                                  fontWeight="medium"
                                                  color="text2"
                                                >
                                                  갱신 / 해지
                                                </SuiTypography>
                                              </td>
                                            )}
                                          </tr>
                                          <tr>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                {rowData[1].value1
                                                  ? dayjs(rowData[1].value1).format("YYYY.MM.DD")
                                                  : "-"}
                                              </SuiTypography>
                                            </td>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                {rowData[1].value2
                                                  ? dayjs(rowData[1].value2).format(
                                                      "YYYY.MM.DD"
                                                    ) === "2999.01.01"
                                                    ? "-"
                                                    : dayjs(rowData[1].value2).format("YYYY.MM.DD")
                                                  : "-"}
                                              </SuiTypography>
                                            </td>
                                            {FilesSortingHistory !== "넓게보기" ? (
                                              ""
                                            ) : (
                                              <td>
                                                <SuiTypography
                                                  fontSize="13px"
                                                  fontWeight="medium"
                                                  color={
                                                    rowData[3].value === "자동갱신"
                                                      ? "success"
                                                      : "error"
                                                  }
                                                >
                                                  {rowData[3].value}
                                                </SuiTypography>
                                              </td>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                      {FilesSortingHistory !== "넓게보기" ? (
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color="info2"
                                          sx={{ textAlign: "left", mt: 2 }}
                                        >
                                          계약 담당: <br />
                                          {row[0][0].user
                                            ? row[0][0].user.department
                                            : myData.department}{" "}
                                          {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                          {row[0][0].user
                                            ? row[0][0].user.position
                                            : myData.position}
                                        </SuiTypography>
                                      ) : (
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color="info2"
                                          sx={{ textAlign: "left", mt: 2 }}
                                        >
                                          계약 담당:{" "}
                                          {row[0][0].user
                                            ? row[0][0].user.department
                                            : myData.department}{" "}
                                          / {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                          {row[0][0].user
                                            ? row[0][0].user.position
                                            : myData.position}
                                          <SuiButton
                                            color="info"
                                            sx={{
                                              minHeight: 20,
                                              height: 20,
                                              ml: 2,
                                              px: 0,
                                              boxShadow: 0,
                                            }}
                                            onClick={() => chagneInfo(rowData)}
                                          >
                                            변경이력
                                          </SuiButton>
                                        </SuiTypography>
                                      )}

                                      <Divider sx={{ my: 2 }} />

                                      {FilesSortingHistory !== "넓게보기" ? (
                                        <SuiBox display="flex" alignItems="center">
                                          <SuiTypography
                                            fontSize="13px"
                                            fontWeight="medium"
                                            color="dark"
                                            sx={{ textAlign: "left" }}
                                          >
                                            계약서 원본
                                          </SuiTypography>
                                          {downloadCheck(rowData) ? (
                                            <SuiButton
                                              sx={{
                                                backgroundColor: "#EEEEEE",
                                                minHeight: 24,
                                                height: 24,
                                                width: 24,
                                                minWidth: 24,
                                                p: 0,
                                                ml: 1,
                                                boxShadow: 0,
                                                display: rowData[0].file.url ? "" : "none",
                                              }}
                                              onClick={() => showPdf(rowData[0].file)}
                                            >
                                              <FiSearch size="15" />
                                            </SuiButton>
                                          ) : (
                                            ""
                                          )}
                                          {typeData === "update" && authCheckContract(row) ? (
                                            <SuiButton
                                              dark
                                              sx={{
                                                backgroundColor: "#0C65FF",
                                                minHeight: 32,
                                                height: 32,
                                                width: 32,
                                                minWidth: 32,
                                                p: 0,
                                                ml: 1,
                                                boxShadow: 0,
                                                ml: "auto",
                                              }}
                                              onClick={() => {
                                                setreferrerDialog(true)
                                                setselectContarct(row)
                                                if (row[0][0].referrer) {
                                                  setreferrerIds(row[0][0].referrer)
                                                }
                                              }}
                                            >
                                              <CgUserAdd size="20" color="white" />
                                            </SuiButton>
                                          ) : (
                                            ""
                                          )}
                                        </SuiBox>
                                      ) : (
                                        <SuiBox>
                                          <SuiTypography
                                            fontSize="13px"
                                            fontWeight="medium"
                                            color="dark"
                                            sx={{ textAlign: "left" }}
                                          >
                                            계약서 원본
                                          </SuiTypography>
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            sx={{ mt: 1, overflowX: "hidden" }}
                                          >
                                            <IconButton
                                              sx={{ mr: 1.5, p: 0 }}
                                              className="cursorDefalut"
                                            >
                                              {GetFileIcon(fileType)}
                                            </IconButton>
                                            <SuiTypography
                                              fontSize="14px"
                                              fontWeight="medium"
                                              color="dark"
                                              sx={{
                                                mr: "4px",
                                                textAlign: "left",
                                                cursor: rowData[0].file.url ? "pointer" : "default",
                                              }}
                                              onClick={() => DownloadPdf(rowData[0].file)}
                                            >
                                              {rowData[0].file
                                                ? fileText(rowData[0].file.name)
                                                : ""}
                                            </SuiTypography>
                                            {downloadCheck(rowData) ? (
                                              <SuiButton
                                                sx={{
                                                  backgroundColor: "#EEEEEE",
                                                  minHeight: 24,
                                                  height: 24,
                                                  width: 24,
                                                  minWidth: 24,
                                                  p: 0,
                                                  ml: 1,
                                                  boxShadow: 0,
                                                  display: rowData[0].file.url ? "" : "none",
                                                }}
                                                onClick={() => showPdf(rowData[0].file)}
                                              >
                                                <FiSearch size="15" />
                                              </SuiButton>
                                            ) : (
                                              ""
                                            )}
                                            {/* {downloadCheck(rowData) ? (
                                              <SuiButton
                                                color="info"
                                                sx={{
                                                  minHeight: 20,
                                                  height: 20,
                                                  ml: 1,
                                                  pt: 1.8,
                                                  px: 0,
                                                  boxShadow: 0,
                                                  display: rowData[0].file.url ? "" : "none",
                                                }}
                                                onClick={() => DownloadPdf(rowData[0].file)}
                                              >
                                                다운로드
                                              </SuiButton>
                                            ) : (
                                              ""
                                            )} */}
                                          </SuiBox>
                                        </SuiBox>
                                      )}
                                    </SuiBox>
                                  )
                                })}
                              </SwipeableViews>
                            </Card>
                            <MobileStepper
                              steps={row.length}
                              position="static"
                              activeStep={row[0][0].activeStep}
                              sx={{
                                background: "transparent",
                                m: "0 auto",
                              }}
                            />
                            {typeData === "update" &&
                            FilesSortingHistory === "넓게보기" &&
                            authCheckContract(row) ? (
                              <SuiBox display="flex" alignItems="center" sx={{ mx: "16px" }}>
                                <SuiButton
                                  className="referrerIcon"
                                  sx={{
                                    backgroundColor: "#0C65FF",
                                    minHeight: 32,
                                    height: 32,
                                    width: 32,
                                    minWidth: 32,
                                    p: 0,
                                    boxShadow: 0,
                                  }}
                                  onClick={() => {
                                    setreferrerDialog(true)
                                    setselectContarct(row)
                                    if (row[0][0].referrer) {
                                      setreferrerIds(row[0][0].referrer)
                                    }
                                  }}
                                >
                                  <CgUserAdd size="20" color="white" />
                                </SuiButton>
                                <Stack direction="row" spacing={1} sx={{ ml: 2, overflow: "auto" }}>
                                  {row[0][0].referrer
                                    ? row[0][0].referrer.map((x, index) => {
                                        return (
                                          <Chip
                                            key={index}
                                            label={x.name}
                                            onDelete={(e) => handleDelete(x, row)}
                                            deleteIcon={<AiFillCloseCircle color="#535353" />}
                                          />
                                        )
                                      })
                                    : ""}
                                </Stack>
                              </SuiBox>
                            ) : FilesSortingHistory === "넓게보기" ? (
                              <SuiButton
                                className="referrerIcon"
                                sx={{
                                  mx: "16px",
                                  backgroundColor: "#0C65FF",
                                  minHeight: 32,
                                  height: 32,
                                  width: 100,
                                  p: 0,
                                  boxShadow: 0,
                                  color: "white !important",
                                  display: row[0][0].referrer
                                    ? row[0][0].referrer.length === 0
                                      ? "none"
                                      : "block"
                                    : "none",
                                }}
                                onClick={() => {
                                  setReferrerCheckDialog(true)
                                  if (row[0][0].referrer) setReferrerCheckData(row[0][0].referrer)
                                  else setReferrerCheckData([])
                                }}
                              >
                                참조자 확인
                              </SuiButton>
                            ) : (
                              ""
                            )}
                            <Divider sx={{ my: 2 }} />
                            <SuiBox display="flex" alignItems="center">
                              <SuiTypography
                                fontSize="18px"
                                fontWeight="medium"
                                color={row[0][0].dropdown1 ? "primary" : "info"}
                                className="cursor"
                                onClick={() =>
                                  updateContarctDropDown(
                                    index,
                                    0,
                                    row[0][0].dropdown1,
                                    ContractIndex
                                  )
                                }
                                sx={{ textAlign: "left", mx: 3 }}
                              >
                                부속계약서 ({row[0][0].subContract.length})
                              </SuiTypography>
                              <IconButton
                                color={row[0][0].dropdown1 ? "primary" : "info"}
                                sx={{ ml: "auto" }}
                                onClick={() =>
                                  updateContarctDropDown(
                                    index,
                                    0,
                                    row[0][0].dropdown1,
                                    ContractIndex
                                  )
                                }
                              >
                                {row[0][0].dropdown1 ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </SuiBox>
                            <Divider
                              sx={{
                                mb: row[0][0].dropdown1 ? 0 : 2,
                                mt: 2,
                              }}
                            />
                            <SuiBox
                              sx={{
                                display: row[0][0].dropdown1 ? "" : "none",
                                p: 2,
                                backgroundColor: "#F1F1F5",
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: "#CED4D7",
                                minHeight: 160,
                              }}
                            >
                              <SuiBox display="flex">
                                {typeData === "update" && row[0][0].id && authCheckContract(row) ? (
                                  <SuiButton
                                    sx={{
                                      minHeight: 32,
                                      height: 32,
                                      width: 32,
                                      minWidth: 32,
                                      p: 1,
                                      mr: "auto",
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      addContractSubDialogBtn(row[0], ContractIndex, index, row)
                                    }
                                  >
                                    <AiOutlinePlus size="20" />
                                  </SuiButton>
                                ) : (
                                  ""
                                )}
                                <SuiBox sx={{ width: 100, ml: "auto" }}>
                                  <FormControl
                                    sx={{ minHeight: 30, width: "100%" }}
                                    className={
                                      row[0][0].subContractSotring === ""
                                        ? "setting-selectbox select-none-active"
                                        : "setting-selectbox"
                                    }
                                    onClick={() => filterOpenClick(row, "subContract")}
                                  >
                                    <Select
                                      IconComponent={() => (
                                        <KeyboardArrowDownIcon
                                          fontSize="small"
                                          color="black"
                                          className="select-icon cursor"
                                          onClick={() => filterOpenClick(row, "subContract")}
                                        />
                                      )}
                                      open={FiltersOpen5 && FiltersOpenIndex === row[0][0].id}
                                      value={row[0][0].subContractSotring}
                                      onChange={(e) =>
                                        SubContractSortingActive(
                                          e.target.value,
                                          ContractIndex,
                                          index
                                        )
                                      }
                                      className="setting-border-radius"
                                    >
                                      <MenuItem value="최신순">최신순</MenuItem>
                                      <MenuItem value="오래된 순">오래된 순</MenuItem>
                                    </Select>
                                  </FormControl>
                                </SuiBox>
                              </SuiBox>
                              {row[0][0].subContract.length === 0 ? (
                                <SuiBox sx={{ mt: 2 }}>
                                  <SuiTypography
                                    fontSize={FilesSortingHistory !== "넓게보기" ? "14px" : "18px"}
                                    fontWeight="medium"
                                    color="text2"
                                    sx={{ textAlign: "left" }}
                                  >
                                    등록된 부속계약서가 없습니다.
                                  </SuiTypography>
                                </SuiBox>
                              ) : (
                                row[0][0].subContract.map((rowData, rowIndex) => {
                                  const fileName = rowData[0].file?.name
                                  const fileType =
                                    fileName?.split(".")[fileName?.split(".").length - 1]
                                  return (
                                    <SuiBox
                                      key={rowIndex}
                                      sx={{
                                        backgroundColor: "#ffffff",
                                        mt: 1,
                                        p: 2,
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      <SuiBox display="flex" alignItems="center">
                                        <SuiTypography
                                          fontSize="14px"
                                          fontWeight="medium"
                                          color="dark"
                                          sx={{ textAlign: "left", mb: 1 }}
                                        >
                                          {rowData[0].ContractType
                                            ? rowData[0].ContractType
                                            : "부속 계약"}
                                        </SuiTypography>
                                        {dayjs(row[0][1].value2) < dayjs(new Date()) ||
                                        row[0][0].ContractType === "계약 종료" ? (
                                          <SuiTypography
                                            fontSize="14px"
                                            fontWeight="medium"
                                            color="text2"
                                            sx={{ ml: "auto", mb: 1, mr: 1 }}
                                          >
                                            계약종료
                                          </SuiTypography>
                                        ) : authCheckContract(row) ? (
                                          rowCheckContract(row, rowData) ? (
                                            <SuiTypography
                                              fontSize="14px"
                                              fontWeight="medium"
                                              color="text2"
                                              sx={{ ml: "auto", mb: 1, mr: 1 }}
                                            >
                                              계약종료
                                            </SuiTypography>
                                          ) : (
                                            <SuiTypography
                                              fontSize="14px"
                                              fontWeight="medium"
                                              color="info2"
                                              sx={{ ml: "auto", mb: 1, mr: 1, cursor: "pointer" }}
                                              onClick={() =>
                                                editSubContract(rowData, row[0], rowIndex, row)
                                              }
                                            >
                                              수정
                                            </SuiTypography>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </SuiBox>
                                      <table className="contract-table">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                계약일
                                              </SuiTypography>
                                            </td>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                계약 종료일
                                              </SuiTypography>
                                            </td>
                                            {FilesSortingHistory !== "넓게보기" ? (
                                              ""
                                            ) : (
                                              <td>
                                                <SuiTypography
                                                  fontSize="13px"
                                                  fontWeight="medium"
                                                  color="text2"
                                                >
                                                  갱신 / 해지
                                                </SuiTypography>
                                              </td>
                                            )}
                                          </tr>
                                          <tr>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                {dayjs(rowData[1].value1).format("YYYY.MM.DD")}
                                              </SuiTypography>
                                            </td>
                                            <td>
                                              <SuiTypography
                                                fontSize="13px"
                                                fontWeight="medium"
                                                color="text2"
                                              >
                                                {dayjs(rowData[1].value2).format("YYYY.MM.DD") ===
                                                "2999.01.01"
                                                  ? "-"
                                                  : dayjs(rowData[1].value2).format("YYYY.MM.DD")}
                                              </SuiTypography>
                                            </td>
                                            {FilesSortingHistory !== "넓게보기" ? (
                                              ""
                                            ) : (
                                              <td>
                                                <SuiTypography
                                                  fontSize="13px"
                                                  fontWeight="medium"
                                                  color={
                                                    rowData[3].value === "자동갱신"
                                                      ? "success"
                                                      : "error"
                                                  }
                                                >
                                                  {rowData[3].value}
                                                </SuiTypography>
                                              </td>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                      {FilesSortingHistory !== "넓게보기" ? (
                                        ""
                                      ) : (
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color="info2"
                                          sx={{ textAlign: "left", mt: 2 }}
                                        >
                                          계약 담당:{" "}
                                          {row[0][0].user
                                            ? row[0][0].user.department
                                            : myData.department}{" "}
                                          / {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                          {row[0][0].user
                                            ? row[0][0].user.position
                                            : myData.position}
                                          {/* <SuiButton
                                        color="info"
                                        sx={{
                                          minHeight: 20,
                                          height: 20,
                                          ml: 2,
                                          px: 0,
                                        }}
                                      >
                                        변경이력
                                      </SuiButton> */}
                                        </SuiTypography>
                                      )}
                                      {FilesSortingHistory !== "넓게보기" ? (
                                        ""
                                      ) : (
                                        <Divider sx={{ my: 2 }} />
                                      )}
                                      {FilesSortingHistory !== "넓게보기" ? (
                                        <SuiBox display="flex" alignItems="center" sx={{ mt: 1 }}>
                                          <SuiTypography
                                            fontSize="13px"
                                            fontWeight="medium"
                                            color="dark"
                                            sx={{ textAlign: "left" }}
                                          >
                                            계약서 원본
                                          </SuiTypography>
                                          {downloadCheck(rowData) ? (
                                            <SuiButton
                                              sx={{
                                                backgroundColor: "#EEEEEE",
                                                minHeight: 24,
                                                height: 24,
                                                width: 24,
                                                minWidth: 24,
                                                p: 0,
                                                ml: 1,
                                                boxShadow: 0,
                                                display: rowData[0].file.url ? "" : "none",
                                              }}
                                              onClick={() => showPdf(rowData[0].file)}
                                            >
                                              <FiSearch size="15" />
                                            </SuiButton>
                                          ) : (
                                            ""
                                          )}
                                        </SuiBox>
                                      ) : (
                                        <SuiBox>
                                          <SuiTypography
                                            fontSize="13px"
                                            fontWeight="medium"
                                            color="dark"
                                            sx={{ textAlign: "left" }}
                                          >
                                            계약서 원본
                                          </SuiTypography>
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            sx={{ mt: 1, overflowX: "hidden" }}
                                          >
                                            <IconButton
                                              sx={{ mr: 1.5, p: 0 }}
                                              className="cursorDefalut"
                                            >
                                              {GetFileIcon(fileType)}
                                            </IconButton>
                                            <SuiTypography
                                              fontSize="14px"
                                              fontWeight="medium"
                                              color="dark"
                                              sx={{
                                                mr: "4px",
                                                textAlign: "left",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => DownloadPdf(rowData[0].file)}
                                            >
                                              {rowData[0].file
                                                ? fileText(rowData[0].file.name)
                                                : ""}
                                            </SuiTypography>
                                            {downloadCheck(rowData) ? (
                                              <SuiButton
                                                sx={{
                                                  backgroundColor: "#EEEEEE",
                                                  minHeight: 24,
                                                  height: 24,
                                                  width: 24,
                                                  minWidth: 24,
                                                  p: 0,
                                                  ml: 1,
                                                  boxShadow: 0,
                                                  display: rowData[0].file.url ? "" : "none",
                                                }}
                                                onClick={() => showPdf(rowData[0].file)}
                                              >
                                                <FiSearch size="15" />
                                              </SuiButton>
                                            ) : (
                                              ""
                                            )}
                                            {/* {downloadCheck(rowData) ? (
                                              <SuiButton
                                                color="info"
                                                sx={{
                                                  minHeight: 20,
                                                  height: 20,
                                                  ml: 1,
                                                  pt: 1.8,
                                                  px: 0,
                                                  boxShadow: 0,
                                                  display: rowData[0].file.url ? "" : "none",
                                                }}
                                                onClick={() => DownloadPdf(rowData[0].file)}
                                              >
                                                다운로드
                                              </SuiButton>
                                            ) : (
                                              ""
                                            )} */}
                                          </SuiBox>
                                        </SuiBox>
                                      )}
                                    </SuiBox>
                                  )
                                })
                              )}
                            </SuiBox>
                            <SuiBox
                              display="flex"
                              alignItems="center"
                              sx={{
                                pt: row[0][0].dropdown1 ? 2 : 0,
                                pb: row[0][0].dropdown2 ? 2 : 0,
                              }}
                            >
                              <SuiTypography
                                fontSize="18px"
                                fontWeight="medium"
                                color={row[0][0].dropdown2 ? "primary" : "info"}
                                className="cursor"
                                onClick={() =>
                                  updateContarctDropDown(
                                    index,
                                    1,
                                    row[0][0].dropdown2,
                                    ContractIndex
                                  )
                                }
                                sx={{ textAlign: "left", mx: 3 }}
                              >
                                메모 ({row[0][0].memo.length})
                              </SuiTypography>
                              <IconButton
                                color={row[0][0].dropdown2 ? "primary" : "info"}
                                sx={{ ml: "auto" }}
                                onClick={() =>
                                  updateContarctDropDown(
                                    index,
                                    1,
                                    row[0][0].dropdown2,
                                    ContractIndex
                                  )
                                }
                              >
                                {row[0][0].dropdown2 ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </SuiBox>
                            <SuiBox
                              sx={{
                                display: row[0][0].dropdown2 ? "" : "none",
                                p: 2,
                                backgroundColor: "#F1F1F5",
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: "#CED4D7",
                                minHeight: 160,
                              }}
                            >
                              <SuiBox display="flex">
                                {typeData === "update" && row[0][0].id && authCheckContract(row) ? (
                                  <SuiButton
                                    sx={{
                                      minHeight: 32,
                                      height: 32,
                                      width: 32,
                                      minWidth: 32,
                                      p: 1,
                                      mr: "auto",
                                    }}
                                    color="primary"
                                    onClick={() => addMemo(ContractIndex, index)}
                                  >
                                    <AiOutlinePlus size="20" />
                                  </SuiButton>
                                ) : (
                                  ""
                                )}
                                <SuiBox sx={{ width: 100, ml: "auto" }}>
                                  <FormControl
                                    sx={{ minHeight: 30, width: "100%" }}
                                    className={
                                      row[0][0].memoSotring === ""
                                        ? "setting-selectbox select-none-active"
                                        : "setting-selectbox"
                                    }
                                    onClick={() => filterOpenClick(row, "memo")}
                                  >
                                    <Select
                                      IconComponent={() => (
                                        <KeyboardArrowDownIcon
                                          fontSize="small"
                                          color="black"
                                          className="select-icon cursor"
                                          onClick={() => filterOpenClick(row, "memo")}
                                        />
                                      )}
                                      open={FiltersOpen6 && FiltersOpenIndex1 === row[0][0].id}
                                      value={row[0][0].memoSotring}
                                      onChange={(e) =>
                                        MemoContractSortingActive(
                                          e.target.value,
                                          ContractIndex,
                                          index
                                        )
                                      }
                                      className="setting-border-radius"
                                    >
                                      <MenuItem value="최신순">최신순</MenuItem>
                                      <MenuItem value="오래된 순">오래된 순</MenuItem>
                                    </Select>
                                  </FormControl>
                                </SuiBox>
                              </SuiBox>
                              {row[0][0].memo.length === 0 ? (
                                <SuiBox sx={{ mt: 1 }}>
                                  <SuiTypography
                                    fontSize={FilesSortingHistory !== "넓게보기" ? "14px" : "18px"}
                                    fontWeight="medium"
                                    color="text2"
                                    sx={{ textAlign: "left" }}
                                  >
                                    등록된 메모가 없습니다.
                                  </SuiTypography>
                                </SuiBox>
                              ) : (
                                row[0][0].memo.map((memoData, memoIndex) => {
                                  return memoData.type === "create" ? (
                                    <SuiBox
                                      key={memoIndex}
                                      sx={{
                                        backgroundColor: "#ffffff",
                                        mt: 1,
                                        py: 2,
                                        borderRadius: "0.5rem",
                                        border: 1,
                                        borderColor: "#CED4D7",
                                      }}
                                    >
                                      <SuiBox display="flex" alignItems="center" sx={{ px: 2 }}>
                                        <SuiTypography
                                          fontSize="18px"
                                          fontWeight="medium"
                                          color="dark"
                                          sx={{ mr: "auto" }}
                                        >
                                          메모하기
                                        </SuiTypography>
                                        <IconButton
                                          sx={{ mr: 1, p: 0 }}
                                          color="error"
                                          onClick={() =>
                                            deleteMemo(ContractIndex, index, memoIndex)
                                          }
                                        >
                                          <RiDeleteBinLine />
                                        </IconButton>
                                        <SuiButton
                                          color={
                                            memoData.title === "" || memoData.content === ""
                                              ? "white"
                                              : "info2"
                                          }
                                          variant="outlined"
                                          sx={{
                                            minHeight: 20,
                                            height: 20,
                                            ml: 1,
                                            px: 0,
                                          }}
                                          disabled={
                                            memoData.title === "" || memoData.content === ""
                                          }
                                          onClick={() => pushMemo(ContractIndex, index, memoIndex)}
                                        >
                                          완료
                                        </SuiButton>
                                      </SuiBox>
                                      <Divider sx={{ my: 1 }} />
                                      <SuiBox sx={{ px: 2 }}>
                                        <SuiInput
                                          type="text"
                                          placeholder="제목"
                                          value={memoData.title}
                                          onChange={(e) =>
                                            memoInput(
                                              ContractIndex,
                                              index,
                                              "title",
                                              e.target.value,
                                              memoIndex
                                            )
                                          }
                                        />
                                      </SuiBox>
                                      <SuiBox sx={{ px: 2 }}>
                                        <TextField
                                          multiline
                                          rows={8}
                                          sx={{ width: "100%" }}
                                          className="Fileupload-textArea"
                                          variant="outlined"
                                          placeholder="내용을 입력해주세요."
                                          value={memoData.content}
                                          onChange={(e) =>
                                            memoInput(
                                              ContractIndex,
                                              index,
                                              "content",
                                              e.target.value,
                                              memoIndex
                                            )
                                          }
                                        />
                                      </SuiBox>
                                    </SuiBox>
                                  ) : (
                                    <SuiBox
                                      key={memoIndex}
                                      sx={{
                                        backgroundColor: "#ffffff",
                                        mt: 1,
                                        py: 2,
                                        borderRadius: "0.5rem",
                                        border: 1,
                                        borderColor: "#CED4D7",
                                      }}
                                    >
                                      <SuiBox display="flex" alignItems="center" sx={{ px: 2 }}>
                                        <SuiBox sx={{ mr: "auto" }}>
                                          <SuiTypography
                                            fontSize="16px"
                                            fontWeight="medium"
                                            color="dark"
                                            sx={{ textAlign: "left" }}
                                          >
                                            {memoData.title}
                                          </SuiTypography>
                                          <SuiTypography
                                            fontSize="14px"
                                            fontWeight="medium"
                                            color="secondary"
                                            sx={{ textAlign: "left" }}
                                          >
                                            {memoData.department} · {memoData.name}&nbsp;
                                            {memoData.position} ·
                                            {dayjs(memoData.created_at).format("YY.MM.DD")}
                                          </SuiTypography>
                                        </SuiBox>
                                        <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                                          {typeData === "update" && authCheckContract(row) ? (
                                            <IconButton
                                              sx={{ p: 0 }}
                                              color="error"
                                              onClick={() =>
                                                deleteMemo(ContractIndex, index, memoIndex)
                                              }
                                            >
                                              <RiDeleteBinLine />
                                            </IconButton>
                                          ) : (
                                            // <IconButton
                                            //   aria-label="more"
                                            //   id={`long-button`}
                                            //   aria-controls="long-menu"
                                            //   aria-expanded={open ? "true" : undefined}
                                            //   aria-haspopup="true"
                                            //   onClick={(e) => handleClick(e, memoIndex)}
                                            // >
                                            //   <FiMoreHorizontal />
                                            // </IconButton>
                                            ""
                                          )}
                                          {/* <Menu
                                            sx={{
                                              display: memoIndex === anchorElIndex ? "" : "none",
                                            }}
                                            id="long-menu"
                                            MenuListProps={{
                                              "aria-labelledby": "long-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleMenu}
                                            PaperProps={{
                                              style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                              },
                                            }}
                                          >
                                            <MenuItem
                                              onClick={() =>
                                                deleteMemo(ContractIndex, index, memoIndex)
                                              }
                                            >
                                              삭제하기
                                            </MenuItem>
                                          </Menu> */}
                                        </div>
                                      </SuiBox>
                                      <Divider sx={{ my: 1 }} />
                                      <SuiBox sx={{ px: 2 }}>
                                        <SuiTypography
                                          fontSize="14px"
                                          fontWeight="medium"
                                          color="text2"
                                          sx={{ textAlign: "left", whiteSpace: "pre-line" }}
                                        >
                                          {memoData.content}
                                        </SuiTypography>
                                      </SuiBox>
                                    </SuiBox>
                                  )
                                })
                              )}
                            </SuiBox>
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>
                </SuiBox>
              </Card>
            )
          })}
        </div>
      )
    } else if (value === 2) {
      return (
        <div>
          {/* 차트 start */}
          <Card
            sx={{
              mt: 5,
              boxShadow: 0,
              px: 4,
              pt: 1.8,
              mb: 1,
              overflow: "unset",
            }}
          >
            <div
              style={{
                height: "48px",
                paddingTop: "10px",
                fontSize: "18px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span>이벤트 현황</span>
              </div>
              <div>
                <ChevronLeftSharpIcon
                  sx={{ mr: 2, cursor: "pointer" }}
                  onClick={() => handleYearChart("subtract")}
                ></ChevronLeftSharpIcon>
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {chart_year}년
                </span>
                <ChevronRightSharpIcon
                  sx={{ ml: 2, cursor: "pointer" }}
                  onClick={() => handleYearChart("add")}
                ></ChevronRightSharpIcon>
              </div>
            </div>
            <Grid container sx={{ margin: "20px 0px 5px 0px" }}>
              <Grid item xs={8}>
                <SuiTypography
                  fontSize="15px"
                  color="info"
                  sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                >
                  월별 이벤트 참여 현황
                </SuiTypography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex" }}>
                <SuiTypography
                  fontSize="15px"
                  color="info"
                  sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 400 }}
                >
                  플랫폼 별 작품 수 현황
                </SuiTypography>

                <FormControl
                  sx={{ minHeight: 30, width: "82px", marginLeft: "auto" }}
                  className={
                    // FilesSortingContract === ""
                    // ?
                    "setting-selectbox select-none-active"
                    // : "setting-selectbox"
                  }
                >
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        color="black"
                        className="select-icon cursor"
                      />
                    )}
                    value={chart_month}
                    onChange={(e) => change_chart_month(e)}
                    className="setting-border-radius"
                    sx={{
                      "& .MuiOutlinedInput-input": { zIndex: 10 },
                    }}
                  >
                    {monthSelects}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} sx={{ pr: 2 }}>
                <ApexCharts
                  options={barChartOption}
                  series={barChartSeries}
                  height="150"
                  type="bar"
                  className="writer_event_chart"
                />
              </Grid>

              <Grid
                item
                xs={4}
                sx={{ height: 150, borderRadius: 5, background: "#f8f9fb", display: "flex" }}
              >
                <SuiBox
                  height={150}
                  width={"50%"}
                  sx={{
                    position: "relative",
                    mt: 1,
                  }}
                >
                  <ApexCharts
                    options={donutChartOption}
                    series={donutChartSeries}
                    height={"100%"}
                    type="donut"
                    className="writer_event_chart"
                  />
                  <SuiBox
                    display="block"
                    alignItems="center"
                    justifyContent="start"
                    sx={{
                      position: "absolute",
                      left: "37%",
                      top: "44%",
                      transform: "translate(0%,-50%)",
                    }}
                  >
                    <SuiTypography
                      sx={{ fontSize: "15px", fontWeight: "bold", lineHeight: 1 }}
                      color="dark"
                    >
                      {donutchartDataTotal}
                    </SuiTypography>
                    <SuiTypography sx={{ fontSize: "13px" }} color="dark">
                      작품 참여
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>

                <SuiBox
                  className="donut_legend"
                  display="block"
                  width="48%"
                  sx={{
                    marginTop: "8px",
                    maxHeight: "90%",
                    overflowX: "auto",
                  }}
                >
                  {donutLegendList}
                </SuiBox>
              </Grid>
            </Grid>
          </Card>
          {/* 차트 end */}

          <Card
            sx={{
              mt: 2,
              boxShadow: 0,
              px: 4,
              pt: 1,
              mb: 2,
            }}
          >
            <div
              style={{
                height: "48px",
                paddingTop: "10px",
                fontSize: "18px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <span>이벤트 누적 참여 현황</span>
              </div>
              <div>
                <ChevronLeftSharpIcon
                  sx={{ mr: 2, cursor: "pointer" }}
                  onClick={() => handleLeftIcon("year")}
                ></ChevronLeftSharpIcon>
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {currentYear}년
                </span>
                <ChevronRightSharpIcon
                  sx={{ ml: 2, cursor: "pointer" }}
                  onClick={() => handleRightIcon("year")}
                ></ChevronRightSharpIcon>
              </div>
            </div>
            <Grid item xs={12}>
              {EventReceiversData.length === 0 ? (
                <TableContainer
                  sx={{
                    border: "1px solid #F1F1F5",
                    borderRadius: 0,
                    boxShadow: 0,
                    mt: 4,
                    mb: 5,
                  }}
                >
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                    <EnhancedTableHead3
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rowlength}
                    />
                  </Table>
                  <SuiBox
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    height="100px"
                    sx={{ py: 3 }}
                  >
                    <SuiTypography sx={{ pl: 3 }} fontSize="18px" color="dark" fontWeight="400">
                      이벤트 참여 작품이 없습니다
                    </SuiTypography>
                  </SuiBox>
                </TableContainer>
              ) : (
                <SuiBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer
                    sx={{
                      border: "1px solid #F1F1F5",
                      borderRadius: 0,
                      boxShadow: 0,
                      mt: 4,
                      mb: 5,
                    }}
                  >
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                      <EnhancedTableHead3
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rowlength}
                      />

                      <TableBody>
                        {stableSort(
                          EventReceiversData.slice(0, historySplice3),
                          getComparator(order, orderBy)
                        ).map((data, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell
                                style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                align="center"
                                className="td-Setting td-fontSetting"
                              >
                                {index + 1}
                              </TableCell>
                              <Tooltip
                                arrow={false}
                                color="white"
                                classes={{ popper: "tooltip-light" }}
                                title={
                                  <SuiTypography
                                    style={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "bold",
                                      width: "100%",
                                    }}
                                    verticalAlign="middle"
                                  >
                                    <span>{data.title}</span>
                                  </SuiTypography>
                                }
                              >
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {data.title}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                align="center"
                                className="td-Setting td-fontSetting"
                              >
                                {(data.complete ? data.complete : 0) +
                                  (data.ing ? data.ing : 0) +
                                  (data.expected ? data.expected : 0)}
                              </TableCell>
                              <TableCell
                                style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                align="center"
                                className="td-Setting td-fontSetting"
                              >
                                {data.complete ? data.complete : 0}
                              </TableCell>
                              <TableCell
                                style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                align="center"
                                className="td-Setting td-fontSetting"
                              >
                                {data.ing ? data.ing : 0}
                              </TableCell>
                              <TableCell
                                style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                align="center"
                                className="td-Setting td-fontSetting"
                              >
                                {data.expected ? data.expected : 0}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {Math.ceil(historySplice3 / 10) < Math.ceil(EventReceiversData.length / 10) ? (
                    <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px", mb: "40px" }}>
                      <SuiButton
                        color="info2"
                        variant="text"
                        sx={{
                          width: 100,
                          border: 1,
                          borderColor: "#0C65FF",
                          height: 40,
                          mr: 2,
                          fontSize: 14,
                        }}
                        onClick={() => {
                          sethistorySplice3(historySplice3 + 10)
                        }}
                      >
                        더보기
                      </SuiButton>
                    </SuiBox>
                  ) : (
                    ""
                  )}
                </SuiBox>
              )}
            </Grid>
          </Card>
          <Card
            sx={{
              mt: 2,
              boxShadow: 0,
              px: 4,
              pt: 1,
              mb: 2,
            }}
          >
            <div
              style={{
                height: "48px",
                paddingTop: "10px",
                fontSize: "18px",
                fontWeight: "700",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div>
                <span>이벤트 참여 작품</span>
              </div>
              <div>
                <ChevronLeftSharpIcon
                  sx={{ mr: 2, cursor: "pointer" }}
                  onClick={() => handleLeftIcon("month")}
                ></ChevronLeftSharpIcon>
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {currentMonth}월
                </span>
                <ChevronRightSharpIcon
                  sx={{ ml: 2, cursor: "pointer" }}
                  onClick={() => handleRightIcon("month")}
                ></ChevronRightSharpIcon>
              </div>
            </div>
            <Grid item xs={12}>
              {EventReceiversIndividualData.length === 0 ||
              EventReceiversIndividualData.filter(
                (receiver) => receiver.events && receiver.events.length > 0
              ).length === 0 ? (
                <TableContainer
                  sx={{
                    border: "1px solid #F1F1F5",
                    borderRadius: 0,
                    boxShadow: 0,
                    mt: 4,
                    mb: 5,
                  }}
                >
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                    <EnhancedTableHead4
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rowlength}
                    />
                  </Table>
                  <SuiBox
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    height="100px"
                    sx={{ py: 3 }}
                  >
                    <SuiTypography sx={{ pl: 3 }} fontSize="18px" color="dark" fontWeight="400">
                      이벤트 참여 작품이 없습니다
                    </SuiTypography>
                  </SuiBox>
                </TableContainer>
              ) : (
                <SuiBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer
                    sx={{
                      border: "1px solid #F1F1F5",
                      borderRadius: 0,
                      boxShadow: 0,
                      mt: 4,
                      mb: 5,
                    }}
                  >
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                      <EnhancedTableHead4
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rowlength}
                      />

                      <TableBody>
                        {stableSort(EventReceiversIndividualData, getComparator(order, orderBy))
                          .filter((receiver) => receiver.events && receiver.events.length > 0)
                          .slice(0, historySplice4)
                          .map((data, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {index + 1}
                                </TableCell>
                                <Tooltip
                                  arrow={false}
                                  color="white"
                                  classes={{ popper: "tooltip-light" }}
                                  title={
                                    <SuiTypography
                                      style={{
                                        fontSize: "14px",
                                        color: "black",
                                        fontWeight: "bold",
                                        width: "100%",
                                      }}
                                      verticalAlign="middle"
                                    >
                                      <span>{data.title}</span>
                                    </SuiTypography>
                                  }
                                >
                                  <TableCell
                                    style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {data.title}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {data.rewarod ? data.rewarod : "-"}
                                </TableCell>
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {data.events[0].title}
                                </TableCell>
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {data.events[0].flatform}
                                </TableCell>
                                <TableCell
                                  style={{ borderRight: "1px solid #F1F1F5", cursor: "default" }}
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                >
                                  {dayjs(data.events[0].start).format("YY-MM-DD") +
                                    " ~ " +
                                    dayjs(data.events[0].end).format("YY-MM-DD")}
                                </TableCell>
                                <TableCell
                                  onClick={() => {
                                    handleDetail(data.events[0].id)
                                  }}
                                  align="center"
                                  id={data.events[0].id}
                                  className="td-Setting td-fontSetting"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <SuiTypography className="event_works_lists">
                                    페이지 이동
                                  </SuiTypography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {eventCount > 10 && historySplice4 < eventCount ? (
                    <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px", mb: "40px" }}>
                      <SuiButton
                        color="info2"
                        variant="text"
                        sx={{
                          width: 100,
                          border: 1,
                          borderColor: "#0C65FF",
                          height: 40,
                          mr: 2,
                          fontSize: 14,
                        }}
                        onClick={() => {
                          sethistorySplice4(historySplice4 + 10)
                        }}
                      >
                        더보기
                      </SuiButton>
                    </SuiBox>
                  ) : (
                    ""
                  )}
                </SuiBox>
              )}
            </Grid>
          </Card>
          <Card sx={{ mt: 1.8, boxShadow: 0, mb: 12 }}>
            <SuiBox
              p={5}
              pr={3}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  특이사항 기록
                </SuiTypography>
              </SuiBox>
              {typeData === "create" || typeData === "update" ? (
                <SuiBox sx={{ mb: 2 }}>
                  <SuiBox display="flex" sx={{ mt: 3, mr: 4 }}>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                      style={{ backgroundColor: "transparent" }}
                      alt="History"
                      src={
                        myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""
                      }
                    />
                    <SuiInput
                      type="textfield"
                      // className="significantInput"
                      id="significantInputValue"
                      sx={{ ml: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      onChange={inputValue}
                    />
                  </SuiBox>

                  <SuiBox sx={{ textAlign: "end", mt: 3, mr: 3.5 }}>
                    <SuiButton
                      color="info2"
                      sx={{
                        width: 100,
                        fontSize: 14,
                      }}
                      disabled={InputValue.length > 0 ? false : true}
                      onClick={() => onClickSignificant("event")}
                    >
                      등록
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              ) : null}
              {significant()}
            </SuiBox>
          </Card>
        </div>
      )
    } else if (value === 3) {
      return (
        <div>
          {openContractInfoDialog ? (
            <ContractInfoDialog
              openDialog={openContractInfoDialog}
              closeDialog={closeDialogInfo}
              infoData={InfoData}
            />
          ) : (
            ""
          )}
          <MeetingDialog
            openDialog={MeetingDialogBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            MeetingData={setMeetingData}
            ContractList={ContractList}
            edit={editMettingBool}
            editData={editMettingData}
          />
          <MeetingDetailDialog
            openDialog={MeetingDetailDialogBool}
            closeDialog={closeDialog}
            detailData={DetailMettingData}
          />
          {typeData === "create" || typeData === "update" ? (
            <SuiButton
              sx={{
                minHeight: 67,
                fontSize: 18,
                fontWeight: 700,
                boxShadow: 3,
                width: "100%",
                mb: "16px",
                mt: "20px",
              }}
              className="outlined-bg-white"
              variant="outlined"
              color="primary"
              onClick={() => addMettingDialog()}
            >
              회의록 추가하기
            </SuiButton>
          ) : (
            ""
          )}
          <SuiBox sx={{ width: 100, ml: "auto" }}>
            <FormControl
              sx={{ minHeight: 30, width: "100%" }}
              className={
                FilesSortingMetting === ""
                  ? "setting-selectbox select-none-active"
                  : "setting-selectbox"
              }
              onClick={() => setFiltersOpen7(!FiltersOpen7)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen7(!FiltersOpen7)}
                  />
                )}
                open={FiltersOpen7}
                value={FilesSortingMetting}
                onChange={(e) => setFilesSortingMetting(e.target.value)}
                className="setting-border-radius"
              >
                <MenuItem value="최신순">최신순</MenuItem>
                <MenuItem value="오래된 순">오래된 순</MenuItem>
              </Select>
            </FormControl>
          </SuiBox>
          <SuiBox sx={{ mt: 4, mb: 12 }}>
            <Grid container>{MeetingDatasShow}</Grid>
          </SuiBox>
        </div>
      )
    }
  }
  const [ReferrerCheckDialog, setReferrerCheckDialog] = useState(false)
  const [ReferrerCheckData, setReferrerCheckData] = useState([])
  const [FileUploadDialogBool, setFileUploadDialogBool] = useState(false)
  const [MeetingDialogBool, setMeetingDialogBool] = useState(false)
  const [MeetingDetailDialogBool, setMeetingDetailDialogBool] = useState(false)
  const [ContractDialogOpenBool, setContractDialogOpenBool] = useState(false)
  const [ContractRenewalDialogOpenBool, setContractRenewalDialogOpenBool] = useState(false)
  const [ContractChangeDialogOpenBool, setContractChangeDialogOpenBool] = useState(false)
  const [ContractChangeDialogInfoOpenBool, setContractChangeDialogInfoOpenBool] = useState(false)
  const [ContractChangeTitleDialogInfoOpenBool, setContractChangeTitleDialogInfoOpenBool] =
    useState(false)
  const [openContract_deleteDialog, setopenContract_deleteDialog] = useState(false)
  const [ContarctChangeData, setContarctChangeData] = useState([{ managerLog: [] }])
  const [ContarctChangeInfoData, setContarctChangeInfoData] = useState([])
  const [ContarctChangeTitleInfoData, setContarctChangeTitleInfoData] = useState([])
  const [ContractSubDialogOpenBool, setContractSubDialogOpenBool] = useState(false)
  const [ContarctRenewalData, setContarctRenewalData] = useState([])
  const [ContarctSubData, setContarctSubData] = useState([])
  const [ContarctSubParentData, setContarctSubParentData] = useState([])
  const [ContarctSubDataApply, setContarctSubDataApply] = useState([])
  const [ContarctSubDataIndexs, setContarctSubDataIndexs] = useState({ index: 0, ContractIndex: 0 })
  const [renewalData, setrenewalData] = useState([])
  const [renewalEndData, setrenewalEndData] = useState([])
  const [ContarctRenewalType, setContarctRenewalType] = useState("renewal")
  const [ContractRenewalDialogEdit, setContractRenewalDialogEdit] = useState(false)
  const [ContractRenewalDialogEditData, setContractRenewalDialogEditData] = useState([])
  const [ContractSubDialogEdit, setContractSubDialogEdit] = useState(false)
  const [ContractSubDialogEditData, setContractSubDialogEditData] = useState([])
  const [ContractSubDialogRowIndex, setContractSubDialogRowIndex] = useState(0)
  const [ContractDialogEdit, setContractDialogEdit] = useState(false)
  const [ContractType, setContractType] = useState("create")
  const [ContractDialogEditData, setContractDialogEditData] = useState([])
  const [AttachmentData, setAttachmentData] = useState([])
  const [AttachmentDatas, setAttachmentDatas] = useState([])
  const [MeetingData, setMeetingData] = useState([])
  const [MeetingDatas, setMeetingDatas] = useState([])
  const [ContractList, setContractList] = useState([])
  const [selectContarct, setselectContarct] = useState([])
  const [referrerDialog, setreferrerDialog] = useState(false)
  const [subContractAction, setsubContractAction] = useState([])
  const [AddRenewal, setAddRenewal] = useState([])
  useEffect(() => {
    if (ContarctSubDataApply.length > 0) {
      let array = [...ContractDatas]
      let SubDataSet = [...JSON.parse(JSON.stringify(ContarctSubDataApply))]
      if (ContarctSubDataApply[0].value.indexOf("갱신 계약") > -1) {
        SubDataSet[0].ContractType = ContarctSubDataApply[0].value + "_부속 계약"
      } else {
        SubDataSet[0].ContractType = "최초 계약_부속 계약"
      }
      SubDataSet[1].value1 = ContarctSubDataApply[3].value1
      SubDataSet[1].value2 = ContarctSubDataApply[3].value2
      SubDataSet[3].value = ContarctSubDataApply[5].value
      SubDataSet[0].file = ContarctSubDataApply[0].file
      SubDataSet[0].managerLog = [
        {
          email: myData.email,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          users_permissions_user: myData.id,
          created_at: new Date(),
        },
      ]
      if (ContractSubDialogEdit) {
        array[ContarctSubDataIndexs.ContractIndex][8].data[
          ContarctSubDataIndexs.index
        ][0][0].subContract[ContractSubDialogRowIndex] = SubDataSet
      } else {
        array[ContarctSubDataIndexs.ContractIndex][8].data[
          ContarctSubDataIndexs.index
        ][0][0].subContract.push(SubDataSet)
      }
      array[ContarctSubDataIndexs.ContractIndex][1].changed = true
      array[ContarctSubDataIndexs.ContractIndex][8].data[
        ContarctSubDataIndexs.index
      ][0][0].changed = true
      setContractDatas(array)

      AddsubContractHistory(ContractSubDialogEdit, ContarctSubDataApply, SubDataSet)
    }
  }, [ContarctSubDataApply])
  useEffect(() => {
    if (ContarctRenewalData.length > 0) {
      let array = [...ContractDatas]
      let RenewalDataSet = [...JSON.parse(JSON.stringify(ContarctRenewalData))]
      RenewalDataSet[0].changed = true
      RenewalDataSet[0].ContractType = "갱신 계약"
      RenewalDataSet[0].ContractTypeValue = renewalData[1].value
      RenewalDataSet[0].file = renewalData[0].file
      RenewalDataSet[0].subContract[0][0].file = ContarctRenewalData[0].subContract[0][0].file
      if (renewalData[1].value === "직접입력") {
        RenewalDataSet[1].value1 = renewalData[3].value1
        RenewalDataSet[1].value2 = renewalData[3].value2
      } else {
        RenewalDataSet[1].value1 = renewalData[3].value1
        RenewalDataSet[1].value2 = dayjs(renewalData[3].value1).add(1, "years")
      }
      RenewalDataSet[3].value = renewalData[5].value
      array[contract_documents_data_index][1].changed = true
      array[contract_documents_data_index][8].data[contract_documents_data_edit_index].unshift(
        RenewalDataSet
      )
      setContractDatas(array)
      AddsetAddRenewal(renewalData)
    }
  }, [renewalData])

  const AddsetAddRenewal = (renewalData) => {
    let array = [...AddRenewal]
    array.push({
      parentContractId: ContractDatas[contract_documents_data_index][0].id,
      profile: myData.profile ? myData.profile.url : null,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      content:
        "<strong>" +
        renewalData[0].value +
        "</strong>의 <strong>갱신 계약</strong>을 등록하였습니다.",
      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      type: "contract",
      renewalIndex:
        ContractDatas[contract_documents_data_index][8].data[contract_documents_data_edit_index]
          .length - 1,
    })
    setAddRenewal(array)
  }
  useEffect(() => {
    if (ContarctRenewalData.length > 0) {
      let array = [...ContractDatas]
      let RenewalDataSet = [...JSON.parse(JSON.stringify(ContarctRenewalData))]
      RenewalDataSet[0].changed = true
      RenewalDataSet[0].ContractType = "계약 종료"
      if (renewalEndData) {
        RenewalDataSet[0].ContractTypeValue = renewalEndData[1].value
        RenewalDataSet[0].file = renewalEndData[0].file
        RenewalDataSet[1].value1 = renewalEndData[1].value
        RenewalDataSet[1].value2 = renewalEndData[3].value
        // RenewalDataSet[3].value = renewalEndData[5].value
      }
      array[contract_documents_data_index][1].changed = true
      array[contract_documents_data_index][8].data[contract_documents_data_edit_index].unshift(
        RenewalDataSet
      )
      setContractDatas(array)
    }
  }, [renewalEndData])

  const AddsubContractHistory = (edit, subData, SubDataSet) => {
    let array = [...subContractAction]
    let content
    if (edit) {
      content =
        "<strong>" +
        subData[0].parentContract +
        "</strong>의 <strong>부속 계약</strong>을 수정하였습니다."
    } else {
      content =
        "<strong>" +
        subData[0].parentContract +
        "</strong>의 <strong>부속 계약</strong>을 등록하였습니다."
    }
    let sameMessage = array.findIndex((element) => element.content === content)
    if (sameMessage !== -1) {
      array.splice(sameMessage, 1, {
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: content,
        subDataSet: SubDataSet,
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        type: "contract",
      })
    } else {
      array.push({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: content,
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        subDataSet: SubDataSet,
        type: "contract",
      })
    }
    setsubContractAction(array)
  }

  const memoInput = (ContractIndex, index, type, e, memoIndex) => {
    let array = [...ContractDatas]
    if (type === "title") {
      array[ContractIndex][8].data[index][0][0].memo[memoIndex].title = e
    } else {
      array[ContractIndex][8].data[index][0][0].memo[memoIndex].content = e
    }
    setContractDatas(array)
  }
  const addMemo = (ContractIndex, index) => {
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].memo.unshift({
      title: "",
      content: "",
      name: myData.name,
      position: myData.position,
      department: myData.department,
      type: "create",
      created_at: new Date(),
    })
    array[ContractIndex][1].changed = true
    setContractDatas(array)
  }
  const pushMemo = (ContractIndex, index, memoIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].memo[memoIndex].type = "detail"
    array[ContractIndex][1].changed = true
    array[ContractIndex][8].data[index][0][0].changed = true
    setContractDatas(array)
    setcreateContractShow(true)
  }
  const deleteMemo = (ContractIndex, index, memoIndex) => {
    setcreateContractShow(true)
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].memo.splice(memoIndex, 1)
    array[ContractIndex][1].changed = true
    array[ContractIndex][8].data[index][0][0].changed = true
    setContractDatas(array)
  }
  const addContractSubDialogBtn = (row, ContractIndex, index, contract) => {
    setContractSubDialogEdit(false)
    setContarctSubData(row)
    setContarctSubDataIndexs({
      index: index,
      ContractIndex: ContractIndex,
    })
    setContractSubDialogOpenBool(true)
    setContarctSubParentData(contract)
  }

  const cancelReset = (value) => {
    if (value === 0) {
      let array = [...InputLists]
      array[0].value = DetailData.name
      array[1].value = DetailData.userid
      array[2].value = DetailData.joaraname
      array[3].value = DetailData.ebookname
      array[9].value = DetailData.accountName
      array[7].value = DetailData.dal
      if (DetailData.birth.length < 15) {
        array[4].value = DetailData.birth
      } else {
        array[4].value = CryptoJS.AES.decrypt(DetailData.birth, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (DetailData.phone.length < 14) {
        array[5].value = DetailData.phone
      } else {
        array[5].value = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (DetailData.address.length < 44) {
        array[6].value = DetailData.address
      } else {
        array[8].value = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      if (DetailData.email.length < 40) {
        array[8].value = DetailData.email
      } else {
        array[8].value = CryptoJS.AES.decrypt(DetailData.email, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }

      if (DetailData.account.length < 44) {
        if (DetailData.account.indexOf("/") >= 0) {
          array[10].itemsValue = DetailData.account.split("/")[0]
          array[10].value = DetailData.account.split("/")[1]
        } else {
          array[10].itemsValue = ""
          array[10].value = ""
        }
      } else {
        if (
          CryptoJS.AES.decrypt(DetailData.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .indexOf("/") >= 0
        ) {
          array[10].itemsValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .split("/")[0]
          array[10].value = CryptoJS.AES.decrypt(DetailData.account, secretKey)
            .toString(CryptoJS.enc.Utf8)
            .replace(/\"/g, "")
            .split("/")[1]
        } else {
          array[10].itemsValue = ""
          array[10].value = ""
        }
      }
      const x = JSON.parse(JSON.stringify(originSignificants))
      setSignificants(x)

      let arrayAttachments = []
      DetailData.attachments.forEach((element) => {
        InputListsSetData[0].file = element.file[0]
        InputListsSetData[1].id = element.id
        InputListsSetData[1].value = element.title
        InputListsSetData[1].itemsValue = element.type
        InputListsSetData[1].name = element.users_permissions_user.name
        InputListsSetData[1].position = element.users_permissions_user.position
        InputListsSetData[1].department = element.users_permissions_user.department
        InputListsSetData[1].created_at = element.created_at
        // InputListsSetData[3].value = element.explain
        arrayAttachments.push({ data: JSON.parse(JSON.stringify(InputListsSetData)) })
      })
      setAttachmentDatas(
        arrayAttachments.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setischeck(false)
    } else if (value === 1) {
      let arrayContracts = []
      DetailData.contracts.forEach((element) => {
        ContractSet[0].id = element.id
        ContractSet[0].value = element.title
        ContractSet[0].user = element.user
        ContractSet[1].value = element.bookcode
        ContractSet[1].created_at = element.created_at
        ContractSet[2].value = element.genre
        ContractSet[3].value = element.rating ? element.rating : ""
        ContractSet[4].value = element.cover
        ContractSet[5].value = element.joaraStatus
        ContractSet[6].value = element.episodes ? element.episodes : ""
        ContractSet[7].value = element.publicDate ? element.publicDate : ""
        element.contract_documents.forEach((element, index) => {
          element.contractPaper.forEach((paper, paperIndex) => {
            if (paper[paperIndex]) {
              paper[paperIndex].id = element.id
              paper[paperIndex].memo = element.memo
              paper[paperIndex].subContract = element.subContract
              paper[paperIndex].managerLog = element.managerLog
              paper[paperIndex].user = element.users_permissions_user
              paper[paperIndex].changed = false
              paper[paperIndex].referrer = element.referrer
            }

            paper[0].requests = element.requests
          })
        })
        ContractSet[8].data = element.contract_documents.map((x) => x.contractPaper)
        arrayContracts.push(JSON.parse(JSON.stringify(ContractSet)))
      })
      setContractDatas(
        arrayContracts.sort((a, b) => {
          var dateA = new Date(a[1].created_at).getTime()
          var dateB = new Date(b[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )

      setsubContractAction([])
      setAddRenewal([])
      setdeleted_attachment([])
    } else if (value === 3) {
      let arrayMeetings = []
      DetailData.meetings.forEach((element) => {
        MeetingsInputListsSet[0].value = element.title
        MeetingsInputListsSet[1].id = element.id
        MeetingsInputListsSet[1].itemsValue = element.type
        MeetingsInputListsSet[1].name = element.users_permissions_user.name
        MeetingsInputListsSet[1].position = element.users_permissions_user.position
        MeetingsInputListsSet[1].department = element.users_permissions_user.department
        MeetingsInputListsSet[1].created_at = element.created_at
        MeetingsInputListsSet[1].makerId = element.users_permissions_user.id
        MeetingsInputListsSet[1].contract_document = element.contract_document
        MeetingsInputListsSet[1].value = element.contract
        let fileArray = []
        element.file.forEach((element) => {
          fileArray.push({ file: element })
        })
        MeetingsInputListsSet[2].files = fileArray
        MeetingsInputListsSet[2].value = element.content
        MeetingsInputListsSet[3].value = element.category
        MeetingsInputListsSet[3].value2 = element.open ? "전체 공개" : "일부 공개"
        arrayMeetings.push({ data: JSON.parse(JSON.stringify(MeetingsInputListsSet)) })
      })

      setMeetingDatas(
        arrayMeetings.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setdeleted_meetings([])
    }
    settypeData("detail")
    setcreateContractShow(false)
    setcreateShow(false)
    setContractData(ContractDataSet)
    dispatch(editStatus_Apply(false))
  }
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    a.rel = "noopener noreferrer"
    document.body.appendChild(a)
    a.click()
  }
  const firstItemCheck = (attachData) => {
    let valueData = AttachmentDatas.map((x) => x.data[1])
    let afterCheck = valueData.findIndex(
      (e) =>
        dayjs(e.created_at).isAfter(dayjs(attachData.data[1].created_at)) &&
        e.itemsValue === attachData.data[1].itemsValue
    )
    if (afterCheck === -1) {
      return true
    } else {
      return false
    }
  }
  const AttachmentDatasShow_type2 = () => {
    return AttachmentDatas.length === 0 ? (
      <TableContainer
        sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0, marginRight: "40px" }}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowlength}
          />
        </Table>
        <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
          <SuiTypography fontSize="18px" color="dark" fontWeight="400">
            등록된 내역이 없습니다.
          </SuiTypography>
        </SuiBox>
      </TableContainer>
    ) : (
      <SuiBox
        sx={{
          width: "100%",
          paddingRight: "40px",
        }}
      >
        <TableContainer
          sx={{
            borderTop: "1px solid #F1F1F5",
            borderRadius: 0,
            boxShadow: 0,
            marginRight: "40px",
          }}
        >
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowlength}
            />
            <TableBody>
              {stableSort(
                AttachmentDatas.slice(0, historySplice),
                getComparator(order, orderBy)
              ).map((data, index) => {
                const fileName = data.data[0].file?.name
                const fileType = fileName.split(".")[fileName.split(".").length - 1]
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ cursor: "pointer" }}
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiTypography
                        className="td-Setting td-fontSetting"
                        sx={{
                          fontWeight: "bold",
                          // backgroundColor: firstItemCheck(data) ? "#FBE8F1" : "#EEEEEE",
                          color: firstItemCheck(data) ? "#E67DAF !important" : "#A4A4A4 !important",
                          // borderRadius: "8px",
                        }}
                      >
                        {firstItemCheck(data) ? "최신" : "구버전"}
                      </SuiTypography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {data.data[1].itemsValue}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{
                        borderRight: "1px solid #F1F1F5",
                        minWidth: "400px",
                        width: "400px",
                      }}
                    >
                      {data.data[1].value}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      {data.data[1].name} {data.data[1].position}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiBox display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                          sx={{ mr: 1.5, p: 0 }}
                          className="cursorDefalut"
                          onClick={() => DownloadPdf(data.data[0].file)}
                        >
                          {GetFileIcon(fileType)}
                        </IconButton>
                        <SuiButton
                          sx={{
                            backgroundColor: "#EEEEEE",
                            minHeight: 24,
                            height: 24,
                            width: 24,
                            minWidth: 24,
                            p: 0,
                            ml: 1,
                            boxShadow: 0,
                            display: data.data[0].file.url ? "" : "none",
                          }}
                          onClick={() => showPdf(data.data[0].file)}
                        >
                          <FiSearch size="15" />
                        </SuiButton>

                        {typeData !== "update" && typeData !== "create" ? (
                          ""
                        ) : (
                          <div>
                            <IconButton
                              aria-label="more"
                              id={`long-button`}
                              aria-controls="long-menu"
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                              sx={{ color: "#000000" }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              sx={{ display: index === anchorElIndex ? "" : "none" }}
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleMenu}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                },
                              }}
                            >
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                수정
                              </MenuItem>
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                삭제
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </SuiBox>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {Math.ceil(historySplice / 5) < Math.ceil(AttachmentDatas.length / 5) ? (
          <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px" }}>
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => {
                sethistorySplice(historySplice + 5)
              }}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }
  const authCheckContract = (row, type) => {
    if (typeData !== "create") {
      if (myData.role.name === "Authenticated") return true
      else if (myData.role.name === "gold") {
        if (row[0][0].user) {
          if (type) {
            if (row[0][0].user.id === String(myData.id)) return true
          } else return true
        } else {
          return false
        }
      } else if (row[0][0].user) {
        if (row[0][0].user.id === String(myData.id)) {
          return true
        } else {
          if (type) return false
          else {
            if (myData.role.name === "silver") {
              if (row[0][0].user.id === String(myData.id)) return true
              else if (row[0][0].referrer) {
                if (row[0][0].referrer.map((x) => x.id).indexOf(String(myData.id)) > -1) return true
                else return false
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else {
        return false
      }
    }
  }

  const rowCheckContract = (rows, row) => {
    if (rows.length === 1) {
      return false
    } else {
      if (rows.length - 1 === Number(row[0].ContractType.split("_")[1])) {
        return false
      } else {
        return true
      }
    }
  }
  const AttachmentDatasShow = AttachmentDatas.map((data, index) => {
    const fileName = data.data[0].file?.name
    const fileType = fileName.split(".")[fileName.split(".").length - 1]
    return (
      <Card
        key={index}
        sx={{
          border: 1,
          borderColor: "#CDD2D3",
          boxShadow: 0,
          minWidth: 491,
          p: 2.5,
          mr: AttachmentDatas.length - 1 === index ? 0 : 2,
          textAlign: "left",
        }}
      >
        <SuiBox display="flex" sx={{ alignItems: "center", mb: "6px" }}>
          <Chip
            label={firstItemCheck(data) ? "최신" : "구버전"}
            sx={{
              backgroundColor: firstItemCheck(data) ? "#FBE8F1" : "#EEEEEE",
              color: firstItemCheck(data) ? "#E67DAF" : "#A4A4A4",
              borderRadius: "8px",
            }}
          />
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{ ml: 1.5, mr: "auto" }}
          >
            {data.data[1].itemsValue}
          </SuiTypography>
          {typeData !== "update" && typeData !== "create" ? (
            ""
          ) : (
            <div>
              <IconButton
                aria-label="more"
                id={`long-button`}
                aria-controls="long-menu"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, index)}
                sx={{ color: "#000000" }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                sx={{ display: index === anchorElIndex ? "" : "none" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                  },
                }}
              >
                <MenuItem onClick={(e) => handleClose(data.data, e, index)}>수정</MenuItem>
                <MenuItem onClick={(e) => handleClose(data.data, e, index)}>삭제</MenuItem>
              </Menu>
            </div>
          )}
        </SuiBox>
        <SuiTypography fontSize="15px" color="subTitle">
          {data.data[1].department} {data.data[1].name} {data.data[1].position} ·
          {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
        </SuiTypography>
        <SuiTypography fontSize="16px" sx={{ py: 2.5, color: "#535353" }}>
          {data.data[1].value}
        </SuiTypography>
        <SuiBox display="flex" alignItems="center">
          <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="medium"
            color="dark"
            sx={{ mr: 0.5, cursor: data.data[0].file.url ? "pointer" : "default" }}
            onClick={() => DownloadPdf(data.data[0].file)}
          >
            {data.data[0].file
              ? data.data[0].file.name.length > 20
                ? data.data[0].file.name.substring(0, 20) + "..."
                : data.data[0].file.name
              : ""}
          </SuiTypography>
          <SuiButton
            sx={{
              backgroundColor: "#EEEEEE",
              minHeight: 24,
              height: 24,
              width: 24,
              minWidth: 24,
              p: 0,
              ml: 1,
              boxShadow: 0,
              display: data.data[0].file.url ? "" : "none",
            }}
            onClick={() => showPdf(data.data[0].file)}
          >
            <FiSearch size="15" />
          </SuiButton>
          {/* <SuiButton
            color="info"
            sx={{
              minHeight: 20,
              height: 20,
              ml: 1,
              pt: 1.8,
              px: 0,
              boxShadow: 0,
              display: data.data[0].file.url ? "" : "none",
            }}
            onClick={() => DownloadPdf(data.data[0].file)}
          >
            다운로드
          </SuiButton> */}
        </SuiBox>
      </Card>
    )
  })
  const MeetingDatasShow = MeetingDatas.map((data, index) => {
    return (
      <Grid item xs={6} key={index}>
        <Card
          className={typeData === "detail" ? "cursor" : ""}
          sx={{
            border: 1,
            borderColor: "#CED4D7",
            boxShadow: 0,
            minWidth: 491,
            p: 2.5,
            mb: 2,
            mr: index % 2 === 0 ? 2 : 0,
            textAlign: "left",
            height: typeData === "detail" ? "215px" : "222px",
          }}
        >
          <SuiBox
            display="flex"
            sx={{ alignItems: "center", marginBottom: "6px" }}
            onClick={() => DetailMeetingOpen(data)}
          >
            <Chip
              label={data.data[3].value}
              sx={{
                backgroundColor:
                  data.data[3].value === "회의록"
                    ? "#FBE8F1"
                    : data.data[3].value === "인터뷰"
                    ? "#E1EFF6"
                    : data.data[3].value === "인수인계"
                    ? "#CBFFE5"
                    : "",
                color:
                  data.data[3].value === "회의록"
                    ? "#E67DAF"
                    : data.data[3].value === "인터뷰"
                    ? "#668B9E"
                    : data.data[3].value === "인수인계"
                    ? "#0E914F"
                    : "",
                borderRadius: "8px",
              }}
            />
            <SuiTypography
              fontSize="18px"
              fontWeight="bold"
              color="dark"
              sx={{ ml: 1.5, mr: "auto" }}
            >
              [{data.data[3].value2}] {data.data[0].value}
            </SuiTypography>
            {typeData !== "update" && typeData !== "create" ? (
              ""
            ) : (
              <div>
                {myData.id === data.data[1].makerId || myData.role.name === "Authenticated" ? (
                  <div>
                    <IconButton
                      aria-label="more"
                      id={`long-button`}
                      aria-controls="long-menu"
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, index)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      sx={{ display: index === anchorElIndex ? "" : "none" }}
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenu}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                        },
                      }}
                    >
                      <MenuItem onClick={(e) => MettingOption(data.data, e, index)}>수정</MenuItem>
                      <MenuItem onClick={(e) => MettingOption(data.data, e, index)}>삭제</MenuItem>
                    </Menu>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </SuiBox>
          <SuiTypography fontSize="15px" color="subTitle" onClick={() => DetailMeetingOpen(data)}>
            {data.data[1].department} {data.data[1].name} {data.data[1].position} ·
            {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
          </SuiTypography>
          {downloadCheck2(data.data) || data.data[3].value2 === "전체 공개" ? (
            <SuiTypography
              fontSize="16px"
              sx={{ py: 2.5, color: "#535353" }}
              onClick={() => DetailMeetingOpen(data)}
            >
              <span
                className="ql-snow textOverFlow"
                dangerouslySetInnerHTML={{ __html: data.data[2].value }}
              ></span>
            </SuiTypography>
          ) : (
            ""
          )}
          {downloadCheck2(data.data) ? (
            <SuiBox display="flex" alignItems="end" sx={{ mt: "10px" }}>
              <SuiTypography
                fontSize="14px"
                color="info2"
                sx={{ ml: 1, textDecoration: "underline", cursor: "pointer" }}
                // onClick={() => openInfoDialog()}
                onClick={() => clickContract2(data)}
              >
                {data.data[1].contract_document ? data.data[1].value : ""}
              </SuiTypography>
            </SuiBox>
          ) : (
            ""
          )}
        </Card>
      </Grid>
    )
  })
  const dispatch = useDispatch()
  const [usersDatas, setusersDatas] = useState([])
  const [originSignificants, setoriginSignificants] = useState([])
  const [Significants, setSignificants] = useState([])

  const [updateWriterActive] = useMutation(updateWriter, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("작가수정 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [updateAttachmentActive] = useMutation(updateAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 수정시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [createAttachmentActive] = useMutation(createAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 생성 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [createMeetingActive] = useMutation(createMeeting, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("회의록 생성시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [updateMeetingActive] = useMutation(updateMeeting, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("회의록 수정시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [createContractActive] = useMutation(createContract, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("계약작품 생성시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [updateContractActive] = useMutation(updateContract, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("계약작품 수정시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [createContractDocumentActive] = useMutation(createContractDocument, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("계약작품 계약서 생성시 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [updateContractDocumentActive] = useMutation(updateContractDocument, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [writer],
  })
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openDeleteInfo_Dialog, setopenDeleteInfo_Dialog] = useState(false)
  const [openCantDelete_Dialog, setopenCantDelete_Dialog] = useState(false)
  const [CopyContractData, setCopyContractData] = useState([])

  const createContractBtn = () => {
    let array = [...ContractDatas]
    let copy = JSON.parse(JSON.stringify(ContractData))
    setCopyContractData(copy)
    array.unshift(ContractData)
    setcreateContractShow(true)
    setContractDatas(array)
    ContractDataReset()
  }
  const updateWriterActiveBtn = async () => {
    if (value === 0) {
      const editHistory = [...DetailData.history]
      dispatch(loading_Apply(true))

      if (UpdateData.length > 0) {
        for (var i = 0; i < UpdateData.length; i++) {
          if (UpdateData[i].id) {
            updateWriterSignificantActive({
              variables: {
                id: UpdateData[i].id,
                content: UpdateData[i].content,
              },
            })
          }
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 수정하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
        setUpdateData([])
      }

      if (DeleteId.length > 0) {
        for (var i = 0; i < DeleteId.length; i++) {
          if (DeleteId[i] !== undefined) {
            updateWriterSignificantActive({
              variables: {
                id: DeleteId[i],
                writer: null,
              },
            })
          }
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 삭제하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
        setDeleteId([])
      }
      let significantAdded = 0
      for (var i = 0; i < Significants.length; i++) {
        if (!Significants[i].id) {
          significantAdded++
          createWriterSignificantActive({
            variables: {
              content: Significants[i].content,
              userName: Significants[i].userName,
              userPosition: Significants[i].userPosition,
              userProfile: Significants[i].userProfile,
              userDepartment: Significants[i].userDepartment,
              writer: Significants[i].writer,
              writerId: Significants[i].writerId,
              type: "basic",
            },
          })
        }
      }

      if (significantAdded > 0) {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 등록하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      let ok = 0
      InputLists.forEach((element) => {
        if (element.title === "이북 필명:" && element.value === "") {
          ok += 1
        }
        // if (element.title === "계좌번호:" && element.itemsValue !== "") {
        //   if (element.value === "") {
        //     ok += 1
        //   }
        // }
        // if (element.title === "계좌번호:" && element.value !== "") {
        //   if (element.itemsValue === "") {
        //     ok += 1
        //   }
        // }

        // if (element.title === "주민등록번호:" && element.value !== "") {
        //   if (element.value.indexOf("-") === -1) {
        //     ok += 1
        //   }
        //   if (element.error) {
        //     ok += 1
        //   }
        // }
        let reEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (element.title === "이메일:" && element.value !== "") {
          if (!reEmail.test(element.value)) {
            ok += 1
          }
        }
      })
      let changeFiled = []
      InputLists.forEach((element) => {
        if (element.changed) {
          changeFiled.push(element.title.replace(":", ""))
        }
      })

      let addAttachmentChanged = false
      let changed_attachment = []
      AttachmentDatas.forEach((element) => {
        if (!element.data[1].id) {
          addAttachmentChanged = true
        } else {
          let idfilterd = DetailData.attachments.filter((el) => {
            return el.id === element.data[1].id
          })
          if (idfilterd[0].type !== element.data[1].itemsValue) {
            changed_attachment.push(idfilterd[0])
          } else if (idfilterd[0].title !== element.data[1].value) {
            changed_attachment.push(idfilterd[0])
          } else if (!element.data[0].file.id) {
            changed_attachment.push(idfilterd[0])
          }
        }
      })

      if (changed_attachment.length > 0) {
        changed_attachment.forEach((el) => {
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>첨부파일 " + el.type + "</strong>을(를) 수정하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
        })
      }

      if (deleted_attachment.length > 0) {
        deleted_attachment.forEach((del) => {
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>첨부파일 " + del[1].itemsValue + "</strong>을(를) 삭제하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
        })
      }

      if (changeFiled.length > 0) {
        let sideText
        if (changeFiled.join(", ").endsWith("일") || changeFiled.join(", ").endsWith("명")) {
          sideText = "을 "
        } else {
          sideText = "를 "
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>" + changeFiled.join(", ") + "</strong>" + sideText + "수정하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      if (addAttachmentChanged) {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>첨부파일</strong>을 등록하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }

      let referCheck = 0
      if (referrerIds.length === DetailData.referrer.length) {
        referrerIds.forEach((newRefer) => {
          let newId = DetailData.referrer.findIndex((refer) => refer.id === newRefer.id)
          if (newId === -1) {
            referCheck++
          }
        })
      } else {
        if (referrerIds.length !== 0) {
          referCheck++
        }
      }
      if (referCheck > 0) {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>참조자</strong>를 등록하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      const editManager = [...DetailData.manager]
      if (ok === 0) {
        dispatch(loading_Apply(true))
        let variablesData = {
          id: DetailData.id,
          name: InputLists[0].value,
          userid: InputLists[1].value,
          joaraname: InputLists[2].value,
          ebookname: InputLists[3].value,
          dal: InputLists[7].value
            ? String(InputLists[7].value)
            : String(
                (InputLists[4].value[2] === "0" ? "" : InputLists[4].value[2]) +
                  InputLists[4].value[3]
              ),
          birth: InputLists[4].value
            ? CryptoJS.AES.encrypt(JSON.stringify(InputLists[4].value), secretKey).toString()
            : "",
          phone: InputLists[5].value
            ? CryptoJS.AES.encrypt(JSON.stringify(InputLists[5].value), secretKey).toString()
            : "",
          address: InputLists[6].value
            ? CryptoJS.AES.encrypt(JSON.stringify(InputLists[6].value), secretKey).toString()
            : "",
          email: InputLists[8].value
            ? CryptoJS.AES.encrypt(JSON.stringify(InputLists[8].value), secretKey).toString()
            : "",
          account:
            InputLists[10].itemsValue && InputLists[10].value
              ? CryptoJS.AES.encrypt(
                  JSON.stringify(InputLists[10].itemsValue + "/" + InputLists[10].value),
                  secretKey
                ).toString()
              : "",
          accountName: InputLists[9].value,
          referrer: referrerIds,
          history: editHistory,
          manager: editManager,
        }
        if (deleteDatas.length > 0) {
          variablesData.attachments = AttachmentDatas.map((x) => x.data[1].id).filter(
            (x) => !deleteDatas.map((x) => x[1].id).includes(x)
          )
        }
        updateWriterActive({
          variables: variablesData,
        }).then((resWirter) => {
          let difference = referrerIds.filter((x) => !DetailData.referrer.includes(x))
          difference.forEach((referrer) => {
            if (referrer.nomalAlertSetting) {
              if (referrer.nomalAlertSetting[3].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(referrer.id),
                    title: "작가 정보 참조자 등록",
                    type: "Writer",
                    userid: String(referrer.id),
                    content: {
                      id: referrer.id,
                      position: referrer.position,
                      department: referrer.department,
                      name: referrer.name,
                      profile: referrer.profile,
                    },
                  },
                })
              }
            }
          })
          DetailData.users.forEach((user) => {
            if (user.id !== myData.id) {
              if (user.nomalAlertSetting) {
                if (user.nomalAlertSetting[0].value) {
                  createAlertActive({
                    variables: {
                      users_permissions_user: String(user.id),
                      title: "작가 정보 수정",
                      type: "Writer",
                      userid: String(user.id),
                      content: {
                        id: myData.id,
                        position: myData.position,
                        department: myData.department,
                        name: myData.name,
                        title: InputLists[0].value,
                        profile: myData.profile,
                        history: editHistory,
                      },
                    },
                  })
                }
              }
            }
          })
          createRecentActive({
            variables: {
              users_permissions_user: myData.id,
              title: "작가 정보 수정",
              type: "Writer",
              srcId: DetailData.id,
              name: DetailData.ebookname,
            },
          })

          if (AttachmentDatas.length > 0) {
            AttachmentDatas.map((x) => x.data).forEach((element, index) => {
              if (element[0].changed) {
                if (element[1].id) {
                  if (element[0].file.url) {
                    updateAttachmentActive({
                      variables: {
                        id: element[1].id,
                        title: element[1].value,
                        type: element[1].itemsValue,
                        // explain: element[3].value,
                        writer: resWirter.data.updateWriter.writer.id,
                        writerId: resWirter.data.updateWriter.writer.id,
                        maker: myData.email,
                        users_permissions_user: myData.id,
                        position: myData.position,
                      },
                    })
                  } else {
                    let data = new FormData()
                    data.append("files", element[0].file)
                    axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
                      updateAttachmentActive({
                        variables: {
                          id: element[1].id,
                          file: [res.data[0].id],
                          title: element[1].value,
                          type: element[1].itemsValue,
                          // explain: element[3].value,
                          writer: resWirter.data.updateWriter.writer.id,
                          writerId: resWirter.data.updateWriter.writer.id,
                          maker: myData.email,
                          users_permissions_user: myData.id,
                          position: myData.position,
                        },
                      })
                    })
                  }
                } else {
                  let data = new FormData()
                  data.append("files", element[0].file)
                  axios
                    .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                    .then((res) => {
                      createAttachmentActive({
                        variables: {
                          file: [res.data[0].id],
                          title: element[1].value,
                          type: element[1].itemsValue,
                          // explain: element[3].value,
                          writer: resWirter.data.updateWriter.writer.id,
                          writerId: resWirter.data.updateWriter.writer.id,
                          maker: myData.email,
                          users_permissions_user: myData.id,
                          position: myData.position,
                        },
                      })
                    })
                    .catch(() => dispatch(loading_Apply(false)))
                }
              }
              if (AttachmentDatas.length - 1 === index) {
                // dispatch(loading_Apply(false))
                snackOpenClick()
                settypeData("detail")
              }
            })
          } else {
            settypeData("detail")
            // dispatch(loading_Apply(false))
            snackOpenClick()
          }
        })
      } else {
        setopenInfo_Dialog(true)
      }

      dispatch(loading_Apply(false))
    } else if (value === 1) {
      dispatch(loading_Apply(true))
      //기존 작품들의 북코드와 동일한지 찾기위한 쿼리문
      let errorCheck = 0
      await client
        .query({
          query: search_bookcode_contracts,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let basicData = res.data.contracts
          //기존 작품들의 북코드 담기
          let array = [...ContractDatas]
          array.reverse().forEach((element) => {
            //북코드의 변경이 있을시
            if (element[1].changed && element[1].value !== "") {
              // 계약작품을 수정할 시에
              if (element[0].id !== undefined) {
                let foundElement = basicData.filter(
                  (code) => String(code.bookcode) === String(element[1].value)
                )
                if (foundElement.length > 0) {
                  if (String(foundElement[0].id) !== String(element[0].id)) {
                    dispatch(loading_Apply(false))
                    element[1].error = true
                    element[1].error_message = "북코드가 이미 존재합니다."
                    errorCheck++
                  }
                }
                // 계약작품을 생성할 시에
              } else {
                let foundElement = basicData.filter(
                  (code) => String(code.bookcode) === String(element[1].value)
                )

                if (foundElement.length > 0) {
                  dispatch(loading_Apply(false))
                  element[1].error = true
                  element[1].error_message = "북코드가 이미 존재합니다."
                  errorCheck++
                }
              }
            }
          })
          setContractDatas(array.reverse())
        })

      // 만일 기존작품의 북코드와 동일한 수정 및 생성사항이 하나 이상이라면 저장 중지
      if (errorCheck > 0) {
        return
      }
      const editHistory = [...DetailData.history]
      dispatch(loading_Apply(true))
      if (Delete_Contarct_Active) {
        let unshiftDelete = ContractDatasBackUp.filter((e) => {
          return ContractDatas.includes(e) === false
        })
        unshiftDelete.forEach((deleted) => {
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>" + deleted[0].value + "</strong> 작품을 삭제하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
        })

        await updateWriterActive({
          variables: {
            id: DetailData.id,
            contracts: ContractDatas.filter((x) => x[0].id).map((x) => x[0].id),
            history: editHistory,
          },
        }).then(() => {
          setDelete_Contarct_Active(false)

          setContractDatasBackUp([])
        })
      } else {
        // dispatch(loading_Apply(false))
        settypeData("detail")
      }
      let contract_reverse = ContractDatas.reverse()
      contract_reverse.forEach(async (element, ContractIndex) => {
        if (element[0].id) {
          if (element[1].changed) {
            // let ok = 0
            let backUpArray = [...DetailData.contracts]
            let changedValue = []
            if (
              element[0].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].title
            ) {
              changedValue.unshift("작품명")
            }
            if (
              element[1].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].bookcode
            ) {
              changedValue.unshift("북코드")
            }
            if (
              element[2].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].genre
            ) {
              changedValue.unshift("장르")
            }
            if (
              element[3].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].rating
            ) {
              changedValue.unshift("등급")
            }
            if (
              element[4].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].cover
            ) {
              changedValue.unshift("표지")
            }
            if (
              element[5].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].joaraStatus
            ) {
              changedValue.unshift("조아라 연재")
            }
            if (
              element[6].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].episodes
            ) {
              changedValue.unshift("화수")
            }
            if (
              element[7].value !==
              backUpArray[backUpArray.findIndex((x) => x.id === element[0].id)].publicDate
            ) {
              changedValue.unshift("출간일자")
            }

            if (changedValue.length > 0) {
              let sideText
              if (
                changedValue.join(", ").endsWith("일") ||
                changedValue.join(", ").endsWith("명")
              ) {
                sideText = "을 "
              } else {
                sideText = "를 "
              }
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                backUpcontent: backUpArray[ContractDatas.length - 1 - ContractIndex].title,
                content:
                  "<strong>" +
                  element[0].value +
                  "</strong> 작품의 <strong>" +
                  (sideText === "작품명"
                    ? backUpArray[ContractDatas.length - 1 - ContractIndex].title
                    : changedValue.join(", ")) +
                  "</strong>" +
                  sideText +
                  "수정하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                contractChnagedId: element[0].id,
              })
              updateWriterActive({
                variables: {
                  id: DetailData.id,
                  history: editHistory,
                },
              })
            }
            let dataset = {
              id: element[0].id,
              title: element[0].value,
              bookcode: element[1].value,
              genre: element[2].value,
              rating: element[3].value,
              cover: element[4].value,
              joaraStatus: element[5].value,
              episodes: element[6].value,
              publicDate: element[7].value,
            }
            if (deleteContractIds[ContractIndex]) {
              dataset.contract_documents = deleteContractIds[ContractIndex]
            }
            await updateContractActive({
              variables: dataset,
            }).then(async (res) => {
              let reverse_list = element[8].data.reverse()
              for (let i = 0; i < reverse_list.length; i++) {
                let data = reverse_list[i]
                for (let j = 0; j < data.length; j++) {
                  let setData = data[j]
                  let addedMemo = setData[0].memo.filter((memos) => memos.type !== "create")
                  setData[0].memo = addedMemo
                  setData[0].dropdown1 = false
                  setData[0].dropdown2 = false
                  if (data[0][0].changed && j === 0) {
                    if (data[0][0].id) {
                      if (!setData[0].file.url) {
                        let fileData = new FormData()
                        fileData.append("files", setData[0].file)
                        await axios
                          .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                          .then(async (res) => {
                            setData[0].file = res.data[0]
                            await updateContractDocumentActive({
                              variables: {
                                id: setData[0].id,
                                memo: addedMemo,
                                subContract: setData[0].subContract,
                                name: myData.name,
                                position: myData.position,
                                department: myData.department,
                                managerLog: [
                                  ...setData[0].managerLog,
                                  {
                                    email: myData.email,
                                    name: myData.name,
                                    position: myData.position,
                                    department: myData.department,
                                    users_permissions_user: myData.id,
                                    created_at: new Date(),
                                  },
                                ],
                                referrer: setData[0].referrer ? setData[0].referrer : [],
                                contractPaper: data,
                              },
                            }).then((newContract) => {
                              if (setData[0].referrer) {
                                setData[0].referrer.forEach((referrer) => {
                                  if (
                                    setData[0].oriReferrer.map((x) => x.id).indexOf(referrer.id) ===
                                    -1
                                  ) {
                                    createAlertActive({
                                      variables: {
                                        users_permissions_user: String(referrer.id),
                                        title: "참조자 등록안내",
                                        type: "Writer",
                                        userid: String(referrer.id),
                                        content: {
                                          id: myData.id,
                                          position: myData.position,
                                          department: myData.department,
                                          name: myData.name,
                                          title: data[0][0].selectData[1].value,
                                          profile: myData.profile,
                                          targetName: DetailData.ebookname,
                                        },
                                      },
                                    })
                                  }
                                })
                              }
                              let usersArray = DetailData.users.map((x) => x.id)
                              if (usersArray.indexOf(myData.id) === -1) {
                                usersArray.push(myData.id)
                              }
                              if (
                                myData.id !== setData[0].user.id &&
                                myData.role.name === "Authenticated"
                              ) {
                                const content = {
                                  request: {
                                    id: myData.id,
                                    department: myData.department,
                                    name: myData.name,
                                    position: myData.position,
                                    profile: myData.profile ? myData.profile.url : null,
                                  },
                                  apply: {
                                    id: myData.id,
                                    department: myData.department,
                                    name: myData.name,
                                    position: myData.position,
                                  },
                                  contractsIds: [],
                                  companiesIds: [],
                                  writersIds: [],
                                  writersContractId: {
                                    id: setData[0].id,
                                    user: setData[0].user.id,
                                    writerId: DetailData.id,
                                    users: usersArray,
                                  },
                                  reject: "",
                                  reason: "Authenticated",
                                  created_at: new Date(),
                                  status: "승인",
                                }
                                const body = {
                                  type: "Writer",
                                  status: "승인",
                                  contractId: setData[0].id,
                                  content: content,
                                  users_permissions_user: myData.id,
                                }
                                axios.post(
                                  process.env.REACT_APP_BACKEND_URL + "/change-requests",
                                  body,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + sessionStorage.getItem("joara_token"),
                                    },
                                  }
                                )
                              }
                              let Renewaled = AddRenewal.filter((renew) => {
                                return renew.parentContractId === element[0].id
                              })
                              if (Renewaled.length > 0) {
                                Renewaled.forEach((renewal) => {
                                  console.log(newContract)
                                  renewal.contractId =
                                    newContract?.data.updateContractDocument.contractDocument.id
                                  editHistory.unshift(renewal)
                                })
                              } else {
                                editHistory.unshift({
                                  profile: myData.profile ? myData.profile.url : null,
                                  name: myData.name,
                                  position: myData.position,
                                  department: myData.department,
                                  content:
                                    "<strong>" +
                                    data[0][0].selectData[1].value +
                                    "</strong>을 수정하였습니다.",
                                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                                  contractId:
                                    newContract.data.updateContractDocument.contractDocument.id,
                                  type: "contract",
                                  contractLength: data.length,
                                })
                              }
                              updateWriterActive({
                                variables: {
                                  id: DetailData.id,
                                  history: editHistory,
                                  users: usersArray,
                                },
                              })
                            })
                          })
                      } else {
                        if (setData[0].subContract.length === 0) {
                          await updateContractDocumentActive({
                            variables: {
                              id: setData[0].id,
                              memo: addedMemo,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              managerLog: [
                                ...setData[0].managerLog,
                                {
                                  email: myData.email,
                                  name: myData.name,
                                  position: myData.position,
                                  department: myData.department,
                                  users_permissions_user: myData.id,
                                  created_at: new Date(),
                                },
                              ],
                              referrer: setData[0].referrer ? setData[0].referrer : [],
                              contractPaper: data,
                              // users_permissions_user: myData.id,
                            },
                          }).then((newContract) => {
                            if (setData[0].referrer) {
                              setData[0].referrer.forEach((referrer) => {
                                if (
                                  setData[0].oriReferrer.map((x) => x.id).indexOf(referrer.id) ===
                                  -1
                                ) {
                                  createAlertActive({
                                    variables: {
                                      users_permissions_user: String(referrer.id),
                                      title: "참조자 등록안내",
                                      type: "Writer",
                                      userid: String(referrer.id),
                                      content: {
                                        id: myData.id,
                                        position: myData.position,
                                        department: myData.department,
                                        name: myData.name,
                                        title: data[0][0].selectData[1].value,
                                        profile: myData.profile,
                                        targetName: DetailData.ebookname,
                                      },
                                    },
                                  })
                                }
                              })
                            }
                            let usersArray = DetailData.users.map((x) => x.id)
                            if (usersArray.indexOf(myData.id) === -1) {
                              usersArray.push(myData.id)
                            }
                            editHistory.unshift({
                              profile: myData.profile ? myData.profile.url : null,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              content:
                                "<strong>" +
                                data[0][0].selectData[1].value +
                                "</strong>을 수정하였습니다.",
                              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                              contractId:
                                newContract.data.updateContractDocument.contractDocument.id,
                              type: "contract",
                              contractLength: data.length,
                            })
                            updateWriterActive({
                              variables: {
                                id: DetailData.id,
                                history: editHistory,
                                users: usersArray,
                              },
                            })
                          })
                        } else {
                          await setData[0].subContract.forEach(
                            async (element, subContractIndex) => {
                              if (!element[0].file.url) {
                                let fileData = new FormData()
                                fileData.append("files", element[0].file)
                                await axios
                                  .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                                  .then(async (res) => {
                                    element[0].file = res.data[0]
                                  })
                              }
                              if (setData[0].subContract.length - 1 === subContractIndex) {
                                updateContractDocumentActive({
                                  variables: {
                                    id: setData[0].id,
                                    memo: addedMemo,
                                    name: myData.name,
                                    position: myData.position,
                                    department: myData.department,
                                    managerLog: [
                                      ...setData[0].managerLog,
                                      {
                                        email: myData.email,
                                        name: myData.name,
                                        position: myData.position,
                                        department: myData.department,
                                        users_permissions_user: myData.id,
                                        created_at: new Date(),
                                      },
                                    ],
                                    referrer: setData[0].referrer ? setData[0].referrer : [],
                                    contractPaper: data,
                                    subContract: setData[0].subContract,
                                    // users_permissions_user: myData.id,
                                  },
                                }).then((newContract) => {
                                  if (setData[0].referrer) {
                                    setData[0].referrer.forEach((referrer) => {
                                      if (
                                        setData[0].oriReferrer
                                          .map((x) => x.id)
                                          .indexOf(referrer.id) === -1
                                      ) {
                                        createAlertActive({
                                          variables: {
                                            users_permissions_user: String(referrer.id),
                                            title: "참조자 등록안내",
                                            type: "Writer",
                                            userid: String(referrer.id),
                                            content: {
                                              id: myData.id,
                                              position: myData.position,
                                              department: myData.department,
                                              name: myData.name,
                                              title: data[0][0].selectData[1].value,
                                              profile: myData.profile,
                                              targetName: DetailData.ebookname,
                                            },
                                          },
                                        })
                                      }
                                    })
                                  }
                                  let usersArray = DetailData.users.map((x) => x.id)
                                  if (usersArray.indexOf(myData.id) === -1) {
                                    usersArray.push(myData.id)
                                  }
                                  if (subContractAction.length > 0) {
                                    subContractAction.forEach((subAction, subIndex) => {
                                      subAction.contractId =
                                        newContract.data.updateContractDocument.contractDocument.id
                                      editHistory.unshift(subAction)
                                      subContractAction.splice(subIndex, 1)
                                    })
                                  } else {
                                    editHistory.unshift({
                                      profile: myData.profile ? myData.profile.url : null,
                                      name: myData.name,
                                      position: myData.position,
                                      department: myData.department,
                                      content:
                                        "<strong>" +
                                        data[0][0].selectData[1].value +
                                        "</strong>을 수정하였습니다.",
                                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                                      contractId:
                                        newContract.data.updateContractDocument.contractDocument.id,
                                      type: "contract",
                                      contractLength: data.length,
                                    })
                                  }
                                  updateWriterActive({
                                    variables: {
                                      id: DetailData.id,
                                      history: editHistory,
                                      users: usersArray,
                                    },
                                  })
                                })
                              }
                            }
                          )
                        }
                      }
                    } else {
                      let fileData = new FormData()
                      fileData.append("files", setData[0].file)
                      await axios
                        .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                        .then((res) => {
                          setData[0].file = res.data[0]
                        })
                      await createContractDocumentActive({
                        variables: {
                          type: "Wirter",
                          status: "공개",
                          memo: [],
                          subContract: [],
                          contract: res.data.updateContract.contract.id,
                          name: myData.name,
                          position: myData.position,
                          department: myData.department,
                          users_permissions_user: myData.id,
                          managerLog: [
                            {
                              email: myData.email,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              users_permissions_user: myData.id,
                              created_at: new Date(),
                            },
                          ],
                          referrer: setData[0].referrer ? setData[0].referrer : [],
                          contractPaper: data,
                        },
                      }).then(async (newContract) => {
                        editHistory.unshift({
                          profile: myData.profile ? myData.profile.url : null,
                          name: myData.name,
                          position: myData.position,
                          department: myData.department,
                          content:
                            "<strong>" +
                            data[0][0].selectData[1].value +
                            "</strong>을 등록하였습니다.",
                          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                          contractId: newContract.data.createContractDocument.contractDocument.id,
                          type: "contract",
                        })
                        let usersArray = DetailData.users.map((x) => x.id)
                        if (usersArray.indexOf(myData.id) === -1) {
                          usersArray.push(myData.id)
                        }
                        if (
                          i === element[8].data.length - 1 &&
                          j === element[8].data[i].length - 1
                        ) {
                          await updateWriterActive({
                            variables: {
                              id: DetailData.id,
                              users: usersArray,
                              history: editHistory,
                            },
                          })
                        }
                      })
                    }
                  } else {
                    let usersArray = DetailData.users.map((x) => x.id)
                    if (usersArray.indexOf(myData.id) === -1) {
                      usersArray.push(myData.id)
                    }
                    if (i === element[8].data.length - 1 && j === element[8].data[i].length - 1) {
                      await updateWriterActive({
                        variables: {
                          id: DetailData.id,
                          users: usersArray,
                          history: editHistory,
                        },
                      })
                    }
                  }
                }
              }
            })
          }
        } else {
          await createContractActive({
            variables: {
              title: element[0].value,
              status: "공개",
              user: myData.id,
              writer: DetailData.id,
              bookcode: element[1].value,
              genre: element[2].value,
              rating: element[3].value,
              cover: element[4].value,
              joaraStatus: element[5].value,
              episodes: element[6].value,
              publicDate: element[7].value,
              name: myData.name,
              position: myData.position,
              department: myData.department,
            },
          }).then(async (res) => {
            let usersArray = DetailData.users.map((x) => x.id)
            if (usersArray.indexOf(myData.id) === -1) {
              usersArray.push(myData.id)
            }
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: "<strong>" + element[0].value + "</strong> 작품을 등록하였습니다.",
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
            })
            if (element[8].data.length === 0) {
              await updateWriterActive({
                variables: {
                  id: DetailData.id,
                  users: usersArray,
                  history: editHistory,
                },
              })
            }
            let reverse_list = element[8].data.reverse()
            for (let i = 0; i < reverse_list.length; i++) {
              let data = reverse_list[i]
              for (let j = 0; j < data.length; j++) {
                let setData = data[j]
                let fileData = new FormData()
                fileData.append("files", setData[0].file)
                await axios
                  .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                  .then((res) => {
                    setData[0].file = res.data[0]
                  })
                await createContractDocumentActive({
                  variables: {
                    type: "Wirter",
                    status: "공개",
                    memo: [],
                    subContract: [],
                    contract: res.data.createContract.contract.id,
                    name: myData.name,
                    position: myData.position,
                    department: myData.department,
                    users_permissions_user: myData.id,
                    managerLog: [
                      {
                        email: myData.email,
                        name: myData.name,
                        position: myData.position,
                        department: myData.department,
                        users_permissions_user: myData.id,
                        created_at: new Date(),
                      },
                    ],
                    referrer: setData[0].referrer ? setData[0].referrer : [],
                    contractPaper: data,
                  },
                }).then((newContract) => {
                  editHistory.unshift({
                    profile: myData.profile ? myData.profile.url : null,
                    name: myData.name,
                    position: myData.position,
                    department: myData.department,
                    content:
                      "<strong>" + data[0][0].selectData[1].value + "</strong>을 등록하였습니다.",
                    date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                    contractId: newContract.data.createContractDocument.contractDocument.id,
                    type: "contract",
                  })
                })
                if (i === element[8].data.length - 1 && j === element[8].data[i].length - 1) {
                  await updateWriterActive({
                    variables: {
                      id: DetailData.id,
                      users: usersArray,
                      history: editHistory,
                    },
                  })
                }
              }
            }
          })
        }
        if (ContractIndex === ContractDatas.length - 1) {
          dispatch(loading_Apply(false))
          snackOpenClick()
          ContractDataReset()
          settypeData("detail")
          DetailData.users.forEach((user) => {
            if (user.id !== myData.id) {
              if (user.nomalAlertSetting) {
                if (user.nomalAlertSetting[0].value) {
                  createAlertActive({
                    variables: {
                      users_permissions_user: String(user.id),
                      title: "작가 계약작품 작성",
                      type: "Writer",
                      userid: String(user.id),
                      content: {
                        id: myData.id,
                        position: myData.position,
                        department: myData.department,
                        name: myData.name,
                        title: InputLists[0].value,
                        profile: myData.profile,
                      },
                    },
                  })
                }
              }
            }
          })
          createRecentActive({
            variables: {
              users_permissions_user: myData.id,
              title: "작가 계약작품 작성",
              type: "Writer",
              srcId: DetailData.id,
              name: DetailData.ebookname,
            },
          })
        }
      })
      setsubContractAction([])
      setAddRenewal([])
    } else if (value === 2) {
      if (UpdateData.length > 0) {
        for (var i = 0; i < UpdateData.length; i++) {
          if (UpdateData[i].id) {
            updateWriterSignificantActive({
              variables: {
                id: UpdateData[i].id,
                content: UpdateData[i].content,
              },
            }).then(() => {
              settypeData("detail")
              // dispatch(loading_Apply(false))
              snackOpenClick()
            })
          }
        }
      }
      if (DeleteId.length > 0) {
        for (var i = 0; i < DeleteId.length; i++) {
          if (DeleteId[i] !== undefined) {
            updateWriterSignificantActive({
              variables: {
                id: DeleteId[i],
                writer: null,
              },
            }).then(() => {
              settypeData("detail")
              // dispatch(loading_Apply(false))
              snackOpenClick()
            })
          }
        }
      }

      let significantAdded = 0
      for (var i = 0; i < Significants.length; i++) {
        if (!Significants[i].id) {
          significantAdded++
          createWriterSignificantActive({
            variables: {
              content: Significants[i].content,
              userName: Significants[i].userName,
              userPosition: Significants[i].userPosition,
              userProfile: Significants[i].userProfile,
              userDepartment: Significants[i].userDepartment,
              writer: Significants[i].writer,
              writerId: Significants[i].writerId,
              type: "event",
            },
          }).then(() => {
            settypeData("detail")
            // dispatch(loading_Apply(false))
            snackOpenClick()
          })
        }
      }
    } else if (value === 3) {
      const editHistory = [...DetailData.history]
      if (MeetingDatas.length > 0) {
        dispatch(loading_Apply(true))
        let docList = []
        ContractList.map((x) => x[8].data).forEach((datas) => {
          datas.forEach((element) => {
            docList.push({ id: element[0][0].id, value: element[0][0].file.name })
          })
        })
        if (deleteMettings) {
          if (deleted_meetings.length > 0) {
            deleted_meetings.forEach((del) => {
              let sideText
              if (del[3].value.endsWith("록")) {
                sideText = "을 "
              } else {
                sideText = "를 "
              }
              if (del[1].contract_document !== null) {
                let z = del[1].contract_document.contractPaper[0][0].selectData[1].value
                editHistory.unshift({
                  profile: myData.profile ? myData.profile.url : null,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  content:
                    "<strong>" + z + "</strong>의 " + del[3].value + sideText + "삭제하였습니다. ",
                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  type: "contract-meeting",
                  contractId: del[1].contract_document.id,
                })
              } else {
                editHistory.unshift({
                  profile: myData.profile ? myData.profile.url : null,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  content: del[3].value + sideText + "삭제하였습니다. ",
                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  type: "meeting",
                  contractId: null,
                })
              }
            })
          }
          updateWriterActive({
            variables: {
              id: DetailData.id,
              meetings: MeetingDatas.map((x) => x.data)
                .map((y) => y[1].id)
                .filter((z) => z !== undefined),
              history: editHistory,
            },
          })
        }
        MeetingDatas.map((x) => x.data).forEach(async (element, index) => {
          const docListIndex = docList.map((x) => x.id).indexOf(element[1].valueId)
          if (element[0].changed) {
            if (element[1].id) {
              if (element[2].files.length > 0) {
                let Ids = []
                element[2].files.forEach(async (file, index) => {
                  if (file.file.url) {
                    Ids.push(file.file.id)
                    if (element[2].files.length - 1 === index) {
                      await updateMeetingActive({
                        variables: {
                          id: element[1].id,
                          file: [...Ids],
                          title: element[0].value,
                          category: element[3].value,
                          contract: element[1].value,
                          content: element[2].value,
                          contract_document: docListIndex > -1 ? docList[docListIndex].id : null,
                          open: element[3].value2 === "전체 공개" ? true : false,
                        },
                      }).then(() => {
                        // dispatch(loading_Apply(false))
                        snackOpenClick()
                        settypeData("detail")

                        let sideText
                        if (element[3].value.endsWith("록")) {
                          sideText = "을 "
                        } else {
                          sideText = "를 "
                        }
                        if (element[1].contract_document !== null) {
                          let z =
                            element[1].contract_document.contractPaper[0][0].selectData[1].value
                          editHistory.unshift({
                            profile: myData.profile ? myData.profile.url : null,
                            name: myData.name,
                            position: myData.position,
                            department: myData.department,
                            content:
                              "<strong>" +
                              z +
                              "</strong>의 " +
                              element[3].value +
                              sideText +
                              "수정하였습니다. ",
                            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                            type: "contract-meeting",
                            contractId: docListIndex > -1 ? docList[docListIndex].id : null,
                          })
                        } else {
                          editHistory.unshift({
                            profile: myData.profile ? myData.profile.url : null,
                            name: myData.name,
                            position: myData.position,
                            department: myData.department,
                            content: element[3].value + sideText + "수정하였습니다. ",
                            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                            type: "meeting",
                            contractId: null,
                          })
                        }
                        updateWriterActive({
                          variables: {
                            id: DetailData.id,
                            history: editHistory,
                          },
                        })
                      })
                    }
                  } else {
                    let data = new FormData()
                    data.append("files", file.file)
                    await axios
                      .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                      .then(async (res) => {
                        Ids.push(res.data[0].id)
                        if (element[2].files.length - 1 === index) {
                          await updateMeetingActive({
                            variables: {
                              id: element[1].id,
                              file: [...Ids],
                              title: element[0].value,
                              category: element[3].value,
                              contract: element[1].value,
                              content: element[2].value,
                              contract_document:
                                docListIndex > -1 ? docList[docListIndex].id : null,
                              open: element[3].value2 === "전체 공개" ? true : false,
                            },
                          }).then(() => {
                            dispatch(loading_Apply(false))
                            snackOpenClick()
                            settypeData("detail")
                            let sideText
                            if (element[3].value.endsWith("록")) {
                              sideText = "을 "
                            } else {
                              sideText = "를 "
                            }
                            if (element[1].contract_document !== null) {
                              let z =
                                element[1].contract_document.contractPaper[0][0].selectData[1].value
                              editHistory.unshift({
                                profile: myData.profile ? myData.profile.url : null,
                                name: myData.name,
                                position: myData.position,
                                department: myData.department,
                                content:
                                  "<strong>" +
                                  z +
                                  "</strong>의 " +
                                  element[3].value +
                                  sideText +
                                  "수정하였습니다. ",
                                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                                type: "contract-meeting",
                                contractId: docListIndex > -1 ? docList[docListIndex].id : null,
                              })
                            } else {
                              editHistory.unshift({
                                profile: myData.profile ? myData.profile.url : null,
                                name: myData.name,
                                position: myData.position,
                                department: myData.department,
                                content: element[3].value + sideText + "수정하였습니다. ",
                                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                                type: "meeting",
                                contractId: null,
                              })
                            }
                            updateWriterActive({
                              variables: {
                                id: DetailData.id,
                                history: editHistory,
                              },
                            })
                          })
                        }
                      })
                  }
                })
              } else {
                await updateMeetingActive({
                  variables: {
                    id: element[1].id,
                    title: element[0].value,
                    category: element[3].value,
                    file: [],
                    type: "Writer",
                    contract: element[1].value,
                    content: element[2].value,
                    contract_document: docListIndex > -1 ? docList[docListIndex].id : null,
                    open: element[3].value2 === "전체 공개" ? true : false,
                  },
                }).then(() => {
                  dispatch(loading_Apply(false))
                  snackOpenClick()
                  settypeData("detail")
                  let sideText
                  if (element[3].value.endsWith("록")) {
                    sideText = "을 "
                  } else {
                    sideText = "를 "
                  }
                  if (element[1].contract_document !== null) {
                    let z = element[1].contract_document.contractPaper[0][0].selectData[1].value
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content:
                        "<strong>" +
                        z +
                        "</strong>의 " +
                        element[3].value +
                        sideText +
                        "수정하였습니다. ",
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "contract-meeting",
                      contractId: docListIndex > -1 ? docList[docListIndex].id : null,
                    })
                  } else {
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content: element[3].value + sideText + "수정하였습니다. ",
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "meeting",
                      contractId: null,
                    })
                  }
                  updateWriterActive({
                    variables: {
                      id: DetailData.id,
                      history: editHistory,
                    },
                  })
                })
              }
            } else {
              if (element[2].files.length > 0) {
                let Ids = []
                element[2].files.forEach(async (file, index) => {
                  let data = new FormData()
                  data.append("files", file.file)
                  await axios
                    .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                    .then((res) => {
                      Ids.push(res.data[0].id)
                      if (element[2].files.length - 1 === index) {
                        createMeetingActive({
                          variables: {
                            file: [...Ids],
                            title: element[0].value,
                            category: element[3].value,
                            type: "Writer",
                            contract: element[1].value,
                            content: element[2].value,
                            writer: DetailData.id,
                            relationId: DetailData.id,
                            maker: myData.email,
                            users_permissions_user: myData.id,
                            position: myData.position,
                            contract_document: docListIndex > -1 ? docList[docListIndex].id : null,
                            status: "공개",
                            open: element[3].value2 === "전체 공개" ? true : false,
                          },
                        }).then((res) => {
                          // dispatch(loading_Apply(false))
                          snackOpenClick()
                          settypeData("detail")
                          let sideText
                          if (element[3].value.endsWith("록")) {
                            sideText = "을 "
                          } else {
                            sideText = "를 "
                          }

                          if (res.data.createMeeting.meeting.contract_document !== null) {
                            let z =
                              res.data.createMeeting.meeting.contract_document.contractPaper[0][0]
                                .selectData[1].value
                            editHistory.unshift({
                              profile: myData.profile ? myData.profile.url : null,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              content:
                                "<strong>" +
                                z +
                                "</strong>의 " +
                                element[3].value +
                                sideText +
                                "등록하였습니다. ",
                              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                              type: "contract-meeting",
                              contractId: docListIndex > -1 ? docList[docListIndex].id : null,
                            })
                          } else {
                            editHistory.unshift({
                              profile: myData.profile ? myData.profile.url : null,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              content: element[3].value + sideText + "등록하였습니다. ",
                              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                              type: "meeting",
                              contractId: null,
                            })
                          }

                          updateWriterActive({
                            variables: {
                              id: DetailData.id,
                              history: editHistory,
                            },
                          })
                        })
                      }
                    })
                })
              } else {
                await createMeetingActive({
                  variables: {
                    title: element[0].value,
                    category: element[3].value,
                    type: "Writer",
                    contract: element[1].value,
                    content: element[2].value,
                    writer: DetailData.id,
                    relationId: DetailData.id,
                    maker: myData.email,
                    users_permissions_user: myData.id,
                    position: myData.position,
                    status: "공개",
                    contract_document: docListIndex > -1 ? docList[docListIndex].id : null,
                    open: element[3].value2 === "전체 공개" ? true : false,
                  },
                }).then((res) => {
                  // dispatch(loading_Apply(false))
                  snackOpenClick()
                  settypeData("detail")
                  let sideText
                  if (element[3].value.endsWith("록")) {
                    sideText = "을 "
                  } else {
                    sideText = "를 "
                  }
                  if (res.data.createMeeting.meeting.contract_document !== null) {
                    let z =
                      res.data.createMeeting.meeting.contract_document.contractPaper[0][0]
                        .selectData[1].value
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content:
                        "<strong>" +
                        z +
                        "</strong>의 " +
                        element[3].value +
                        sideText +
                        "등록하였습니다.",
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "contract-meeting",
                      contractId: docListIndex > -1 ? docList[docListIndex].id : null,
                    })
                  } else {
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content: element[3].value + sideText + "등록하였습니다.",
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "meeting",
                      contractId: null,
                    })
                  }

                  updateWriterActive({
                    variables: {
                      id: DetailData.id,
                      history: editHistory,
                    },
                  })
                })
              }
            }
          } else {
            if (index === MeetingDatas.length - 1) {
              // dispatch(loading_Apply(false))
              snackOpenClick()
              settypeData("detail")
              DetailData.users.forEach((user) => {
                if (user.id !== myData.id) {
                  if (user.nomalAlertSetting) {
                    if (user.nomalAlertSetting[0].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "작가 계약작품 작성",
                          type: "Writer",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            title: InputLists[0].value,
                            profile: myData.profile,
                          },
                        },
                      })
                    }
                  }
                }
              })
              createRecentActive({
                variables: {
                  users_permissions_user: myData.id,
                  title: "작가 회의록 작성",
                  type: "Writer",
                  srcId: DetailData.id,
                  name: DetailData.ebookname,
                },
              })
            }
          }
        })
      } else {
        if (deleted_meetings.length > 0) {
          deleted_meetings.forEach((del) => {
            let sideText
            if (del[3].value.endsWith("록")) {
              sideText = "을 "
            } else {
              sideText = "를 "
            }
            if (del[1].contract_document !== null) {
              let z = del[1].contract_document.contractPaper[0][0].selectData[1].value
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content:
                  "<strong>" + z + "</strong>의 " + del[3].value + sideText + "삭제하였습니다. ",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-meeting",
                contractId: del[1].contract_document.id,
              })
            } else {
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: del[3].value + sideText + "삭제하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "meeting",
                contractId: null,
              })
            }
          })
        }

        updateWriterActive({
          variables: {
            id: DetailData.id,
            meetings: [],
            history: editHistory,
          },
        })

        settypeData("detail")
        // snackOpenClick()
      }
    }
    dispatch(editStatus_Apply(false))
  }
  const authCheck = () => {
    if (typeData !== "create") {
      if (
        myData.role.name === "Authenticated" ||
        myData.role.name === "gold" ||
        myData.role.name === "silver"
      ) {
        return true
      } else if (DetailData.referrer.length > 0) {
        if (DetailData.referrer.map((x) => x.id).indexOf(myData.id) > -1) {
          return true
        } else {
          if (DetailData.manager.length > 0) {
            if (
              DetailData.manager[0].name === myData.name &&
              DetailData.manager[0].department === myData.department
            ) {
              return true
            } else {
              if (DetailData.users.length > 0) {
                if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            }
          } else {
            if (DetailData.users.length > 0) {
              if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else if (DetailData.manager.length > 0) {
        if (
          DetailData.manager[0].name === myData.name &&
          DetailData.manager[0].department === myData.department
        ) {
          return true
        } else {
          if (DetailData.referrer.map((x) => x.id).indexOf(myData.id) > -1) {
            return true
          } else {
            if (DetailData.users.length > 0) {
              if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else {
        if (DetailData.users.length > 0) {
          if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    }
  }

  const deleteSelect = () => {
    if (DetailData.requests.length > 0) {
      if (DetailData.requests[0].status.indexOf("심사중") > -1) {
        setopenDeleteInfo_Dialog(true)
      } else {
        setDelete_Dialog_type("create")
        setDelete_Content(`${DetailData.ebookname}`)
        setDelete_Dialog(true)
        setrequestExplainData("")
      }
    } else {
      setDelete_Dialog_type("create")
      setDelete_Content(`${DetailData.ebookname}`)
      setDelete_Dialog(true)
      setrequestExplainData("")
    }
    setAnchorEl(null)
  }
  const openRequest = (data) => {
    setDelete_Dialog(true)
    if (data.status.indexOf("심사중") > -1) {
      setDelete_Content(`${DetailData.ebookname}`)
      setrequestExplainData(data.content.requestExplain)
      setDelete_Dialog_type("edit")
    } else {
      if (!data.content.read) {
        const content = data.content
        content.read = true
        updateRequestActive({
          variables: {
            id: data.id,
            content: content,
          },
        })
      }
      setDelete_Content(`${DetailData.ebookname}`)
      setrequestExplainData(data.declining)
      setDelete_Dialog_type("delete")
    }
  }

  const [snackState, setsSnackState] = React.useState(false)
  const [snackState2, setsSnackState2] = React.useState(false)
  const [snackState3, setsSnackState3] = React.useState(false)
  const snackOpenClick = () => {
    setsSnackState(true)
  }
  const snackOpenClick2 = () => {
    setsSnackState2(true)
  }
  const copyData = async (row, index) => {
    if (index === 10) {
      await navigator.clipboard.writeText(row.itemsValue + "/" + row.value)
    } else {
      await navigator.clipboard.writeText(row.value)
    }
    snackOpenClick2()
  }
  const startEdit = () => {
    let array = [...InputLists]
    array.forEach((el) => {
      el.error = false
      el.error_message = ""
    })

    settypeData("update")
    dispatch(editStatus_Apply(true))
    setcreateContractShow(false)
    setsubContractAction([])
    setAddRenewal([])
    setdeleted_meetings([])
    setdeleted_attachment([])
  }
  const [chagne_cancel_dailog, setchagne_cancel_dailog] = useState(false)
  const chagne_cancel_dailog_Active = () => {
    dispatch(loading_Apply(true))
    updateChangeRequestActive({
      variables: {
        id: ChangeRequests[0].id,
        status: "취소",
      },
    }).then((res) => {
      setchagne_cancel_dailog(false)
      let data = { ...alert_Dialog }
      data.open = true
      data.title = "이관 신청 취소 완료"
      data.content = `신청이 취소되었습니다.`
      setalert_Dialog(data)
      firstChangeRequestsActive(DetailData.id)
      dispatch(loading_Apply(false))
    })
  }
  const open_change_dialog = async () => {
    setchange_content(DetailData.ebookname)
    dispatch(loading_Apply(true))

    await client
      .query({
        query: users_list,
        variables: {
          status: "근무",
          role: 1,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setusersDatas(res.data.users)
        setopen_ChagneDialog(true)
        dispatch(loading_Apply(false))
      })
  }
  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="1320px"
        maxWidth="1320"
        alignItems="center"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState}
          message="저장되었습니다"
          onClose={() => setsSnackState(false)}
        />
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState2}
          message="복사되었습니다"
          onClose={() => setsSnackState2(false)}
        />
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState3}
          message="참조자 등록 후 회의록 열람이 가능합니다"
          onClose={() => setsSnackState3(false)}
        />
        {typeData === "detail" ? (
          <SuiButton
            color="info2"
            sx={{ ml: "auto", width: 100, position: "absolute", fontSize: 14 }}
            onClick={() => startEdit()}
          >
            수정
          </SuiButton>
        ) : (
          <SuiButton
            color="subTitle"
            variant="text"
            sx={{ width: 100, border: 1, borderColor: "#707070", height: 40, mr: 2, fontSize: 14 }}
            onClick={() => cancelReset(value)}
          >
            취소
          </SuiButton>
        )}
        {typeData === "detail" ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {DetailData.requests.length > 0 &&
            DetailData.requests[0].status.indexOf("회원 취소") === -1 ? (
              <SuiTypography
                onClick={() => {
                  if (
                    DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length === 0
                  )
                    openRequest(DetailData.requests[0])
                  else
                    setdelete_cancle_alert({
                      id: DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1)[0].id,
                      open: true,
                      title: "삭제신청 취소",
                      content: "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?",
                      twoBtn: true,
                      CanBtnText: "취소",
                    })
                }}
                fontSize="16px"
                fontWeight="bold"
                color={DetailData.requests[0].status.indexOf("심사중") > -1 ? "error" : "secondary"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    DetailData.requests[0].content.id === myData.id &&
                    !DetailData.requests[0].content.read
                      ? ""
                      : "none",
                }}
              >
                {DetailData.requests[0].status.replace("심사중", "")}
              </SuiTypography>
            ) : (
              ""
            )}
            {ChangeRequests.length > 0 ? (
              <SuiTypography
                onClick={() => setchagne_cancel_dailog(true)}
                fontSize="16px"
                fontWeight="bold"
                color={"error"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    ChangeRequests[0].arrayContent.users.map((x) => x.id).indexOf(myData.id) > -1 ||
                    ChangeRequests[0].users_permissions_user.id === myData.id
                      ? ""
                      : "none",
                }}
              >
                이관 신청{dayjs(ChangeRequests[0].updated_at).format("YY.MM.DD HH:mm")}
              </SuiTypography>
            ) : (
              ""
            )}
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleClick(e, index)}
              sx={{ color: "#000000" }}
            >
              <FiMoreHorizontal />
            </IconButton>
            <Menu
              sx={{ display: index === anchorElIndex ? "" : "none" }}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              <MenuItem
                disabled={
                  ChangeRequests.length > 0 ||
                  DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length > 0
                }
                onClick={() => open_change_dialog()}
              >
                이관신청
              </MenuItem>
              <MenuItem
                disabled={
                  DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length > 0 ||
                  ChangeRequests.length > 0
                }
                onClick={(e) => deleteSelect()}
              >
                삭제신청
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}
        {value === 0 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateWriterActiveBtn()}
            id="saveEditBtn"
          >
            저장하기
          </SuiButton>
        )}
        {value === 1 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateWriterActiveBtn()}
            id="saveEditBtn"
          >
            저장하기
          </SuiButton>
        )}
        {value === 2 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateWriterActiveBtn()}
            id="saveEditBtn"
          >
            저장하기
          </SuiButton>
        )}
        {value === 3 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateWriterActiveBtn()}
            id="saveEditBtn"
          >
            저장하기
          </SuiButton>
        )}
      </SuiBox>
    )
  }
  useEffect(() => {
    let array = [...AttachmentDatas]
    if (FilesSorting === "최신순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSorting === "오래된 순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSorting])
  useEffect(() => {
    let array = [...MeetingDatas]
    if (FilesSortingMetting === "최신순") {
      setMeetingDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSortingMetting === "오래된 순") {
      setMeetingDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSortingMetting])
  useEffect(() => {
    let array = [...ContractDatas]
    if (FilesSortingContract === "최신순") {
      setContractDatas(
        array.sort((a, b) => {
          var dateA = new Date(a[1].created_at).getTime()
          var dateB = new Date(b[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSortingContract === "오래된 순") {
      setContractDatas(
        array.sort((a, b) => {
          var dateA = new Date(a[1].created_at).getTime()
          var dateB = new Date(b[1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSortingContract])
  const MemoContractSortingActive = (e, ContractIndex, index) => {
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].memoSotring = e
    if (e === "최신순") {
      array[ContractIndex][8].data[index][0][0].memo.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
    } else if (e === "오래된 순") {
      array[ContractIndex][8].data[index][0][0].memo.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
    }
    setContractDatas(array)
  }
  const SubContractSortingActive = (e, ContractIndex, index) => {
    let array = [...ContractDatas]
    array[ContractIndex][8].data[index][0][0].subContractSotring = e
    if (e === "최신순") {
      array[ContractIndex][8].data[index][0][0].subContract.sort((a, b) => {
        var dateA = new Date(a[3].created_at).getTime()
        var dateB = new Date(b[3].created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
    } else if (e === "오래된 순") {
      array[ContractIndex][8].data[index][0][0].subContract.sort((a, b) => {
        var dateA = new Date(a[3].created_at).getTime()
        var dateB = new Date(b[3].created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
    }
    setContractDatas(array)
  }

  useEffect(() => {
    if (AttachmentData.length > 0) {
      let array = [...AttachmentDatas]
      array.unshift({ data: AttachmentData })
      setAttachmentDatas(array)
    }
  }, [AttachmentData])
  useEffect(() => {
    if (MeetingData.length > 0) {
      let array = [...MeetingDatas]
      array.unshift({ data: MeetingData })
      setMeetingDatas(array)
    }
  }, [MeetingData])
  const closeDialog = () => {
    setFileUploadDialogBool(false)
    setreferrerDialog(false)
    setMeetingDialogBool(false)
    setContractRenewalDialogOpenBool(false)
    setMeetingDetailDialogBool(false)
    setContractDialogOpenBool(false)
    setContractSubDialogOpenBool(false)
    setContractChangeDialogOpenBool(false)
    setContractChangeDialogInfoOpenBool(false)
    setContractChangeTitleDialogInfoOpenBool(false)
    setopenContract_deleteDialog(false)
    setopenMangerDialog(false)
  }
  const closeDialog2 = () => {
    setFileUploadDialogBool(false)
    setreferrerDialog(false)
    setMeetingDialogBool(false)
    setContractRenewalDialogOpenBool(false)
    setMeetingDetailDialogBool(false)
    setContractDialogOpenBool(false)
    setContractSubDialogOpenBool(false)
    setContractChangeDialogOpenBool(false)
    setContractChangeDialogInfoOpenBool(false)
    setischeck(true)
    setcreateContractShow(true)
  }
  const setreferrer = () => {
    setreferrerDialog(true)
  }
  const changeTitleButton = (datas) => {
    const editHistory = [...DetailData.history]
    let ContarctData = []
    for (var i = 0; i < DetailData.history.length; i++) {
      if (datas.id === DetailData.history[i].contractChnagedId) {
        ContarctData.push(DetailData.history[i])
      }
    }

    let info = []

    ContarctData.forEach((el, index) => {
      let data = {}
      if (el.content.indexOf("작품명") > -1) {
        const e = el.content.split("<strong>")
        const str = e[1].split("</strong>")
        data.titleName = str[0]
        data.date = el.date
        info.push(data)
      }
    })
    if (info.length > 0) {
      return false
    } else {
      return true
    }
  }
  const changeTitleInfo = (data) => {
    const editHistory = [...DetailData.history]
    let ContarctData = []
    for (var i = 0; i < DetailData.history.length; i++) {
      if (data.id === DetailData.history[i].contractChnagedId) {
        ContarctData.push(DetailData.history[i])
      }
    }
    if (ContarctData) {
      setContarctChangeTitleInfoData(ContarctData)
    } else {
      setContarctChangeTitleInfoData([])
    }
    setContractChangeTitleDialogInfoOpenBool(true)
  }
  const chagneInfo = (rowData) => {
    dispatch(loading_Apply(true))

    changeRequestsActive({
      variables: {
        type: "Writer",
      },
    }).then((res) => {
      let Datas = []
      if (res.data.changeRequests.length > 0) {
        res.data.changeRequests.forEach((element, index) => {
          if (element.contractId === rowData[0].id) {
            Datas.push(element)
          } else if (element.type === "Writer / Company") {
            element.content.writersIds.forEach((writer) => {
              writer.contracts.forEach((contract) => {
                contract.contract_documents.forEach((document) => {
                  if (document.id === rowData[0].id) {
                    Datas.push(element)
                  }
                })
              })
            })
          }
          if (index === res.data.changeRequests.length - 1) {
            setContarctChangeInfoData(Datas)
          }
        })
      } else {
        setContarctChangeInfoData([])
      }
      dispatch(loading_Apply(false))
      setContractChangeDialogInfoOpenBool(true)
    })
  }
  // const chagneTitleInfo = (rowData) => {
  //   dispatch(loading_Apply(true))
  //   changeRequestsActive({
  //     variables: {
  //       type: "Writer",
  //     },
  //   }).then(() => {
  //     changeRequestsActive({
  //       variables: {
  //         type: "Writer",
  //       },
  //     }).then((res) => {
  //       let Datas = []
  //       if (res.data.changeRequests.length > 0) {
  //         res.data.changeRequests.forEach((element, index) => {
  //           if (element.contractId === rowData[0].id) {
  //             Datas.push(element)
  //           } else if (element.type === "Writer / Company") {
  //             element.content.writersIds.forEach((writer) => {
  //               writer.contracts.forEach((contract) => {
  //                 contract.contract_documents.forEach((document) => {
  //                   if (document.id === rowData[0].id) {
  //                     Datas.push(element)
  //                   }
  //                 })
  //               })
  //             })
  //           }
  //           if (index === res.data.changeRequests.length - 1) {
  //             setContarctChangeInfoData(Datas)
  //           }
  //         })
  //       } else {
  //         setContarctChangeInfoData([])
  //       }
  //       dispatch(loading_Apply(false))
  //       setContractChangeDialogInfoOpenBool(true)
  //     })
  //   })
  // }
  const addMettingDialog = () => {
    setContractList(ContractDatas)
    seteditMettingBool(false)
    setMeetingDialogBool(true)
  }
  const addContract = () => {
    setcreateShow(!createShow)
    ContarctDropDown(0, ContractData[0].dropdown)
  }
  const teamList = settings_ref.current
    ? settings_ref.current.department
    : [
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ]
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  let lists = ["최초 정보 등록:", "계약 담당자:", "담당 계약:"]
  const [editTeamSelect, seteditTeamSelect] = useState("")
  const [editMangerSelect, seteditMangerSelect] = useState("")
  const [editMangerContractData, seteditMangerContractData] = useState([])
  if (DetailData.users.length > 0) {
    if (ContractDatas.length > 0) {
      const ContractDatasList = [...ContractDatas]
      let ContractListSet = []
      ContractDatasList.map((x) => x[8].data).forEach((datas) => {
        datas.forEach((element) => {
          if (element[0][0].user) {
            ContractListSet.push({
              value: element[0][0].selectData[1].value,
              value1: element[0][1].value1,
              value2: element[0][1].value2,
              value3: element[0][3].value,
              name: element[0][0].user.name,
              position: element[0][0].user.position,
              department: element[0][0].user.department,
            })
          } else {
            ContractListSet.push({
              name: element[0][1].name,
              position: element[0][1].position,
              department: element[0][1].department,
              value: element[0][0].selectData[1].value,
              value1: element[0][1].value1,
              value2: element[0][1].value2,
              value3: element[0][3].value,
            })
          }
        })
      })
      // lists[2] = "진행 계약 정보: " + ContractListSet.length + "건"
      // 전현균 - 오류 무시용 주석 - start
      DetailData.ContractList = ContractListSet
      // 전현균 - 오류 무시용 주석 - end
    }
  }
  useEffect(() => {
    if (DetailData.ContractList) {
      if (DetailData.ContractList.map((x) => x.department)[0]) {
        async function seteditTeamSelectFnc() {
          const data = await DetailData.ContractList.map((x) => x.department)[0]
          seteditTeamSelect(data)
          const data2 = await DetailData.ContractList.filter((x) => x.department === data).map(
            (x) => x.name
          )[0]
          seteditMangerSelect(data2)
        }
        seteditTeamSelectFnc()
      }
    }
  }, [DetailData])
  const changeTeamSelect = (e) => {
    seteditTeamSelect(e.target.value)
    setTimeout(() => {
      seteditMangerSelect(
        DetailData.ContractList.filter((x) => x.department !== editTeamSelect).map((x) => x.name)[0]
      )
    }, 500)
  }
  const mangaers = () => {
    let test = []

    if (DetailData) {
      DetailData.ContractList.forEach((x) => {
        if (test.findIndex((e) => e.name === x.name) === -1) {
          test.push(x)
        }
      })
    }
    return test
  }
  const MangerContractData = () => {
    let test = []
    if (DetailData.ContractList) {
      DetailData.ContractList.forEach((x) => {
        if (x.name === editMangerSelect) {
          test.push(x)
        }
      })
    }
    return test
  }
  const magerdisabled = () => {
    if (DetailData.ContractList !== undefined) {
      if (!(DetailData.ContractList && !DetailData.ContractList[0])) {
        setFiltersOpen9(!FiltersOpen9)
      }
    }
  }
  const [openMangerDialog, setopenMangerDialog] = useState(false)
  const openMangerInfoDialog = () => {
    setopenMangerDialog(true)
  }
  const listsShow = lists.map((row, index) => {
    return (
      <SuiBox sx={{ textAlign: "start", width: index === 3 ? "15%" : "25%", mr: 2 }} key={row}>
        <SuiTypography
          fontSize="15px"
          fontWeight="bold"
          color="info"
          sx={{ pt: 2, pb: 1, fontWeight: 400, ml: index === 1 ? 2 : 0 }}
          display={
            typeData === "create"
              ? ""
              : index === 3
              ? DetailData.ContractList === undefined
                ? "none"
                : DetailData.ContractList && !DetailData.ContractList[0]
                ? "none"
                : ""
              : ""
          }
        >
          {row}
        </SuiTypography>
        {DetailData.users.length === 0 ? (
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 700,
              pt: 1.8,
              display: edit
                ? "none"
                : row === "담당 계약:" &&
                  typeData !== "create" &&
                  (DetailData.ContractList === undefined || !DetailData.ContractList[0])
                ? "none"
                : "",
              color: "#535353",
            }}
          >
            {/*  {index === 0 ? myData.department : ""} {index === 1 ? myData.name : "" */}
          </Card>
        ) : index === 0 ? (
          <SuiBox sx={{ width: "100%", ml: "auto" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                color: "#535353",
              }}
            >
              {DetailData.manager[0].name} {DetailData.manager[0].position}
            </Card>
          </SuiBox>
        ) : index === 1 ? (
          <SuiBox display="flex">
            <div className="verticalLine"></div>
            <SuiBox sx={{ width: "100%", ml: "auto" }}>
              <FormControl
                sx={{ minHeight: 40, width: "100%" }}
                className={
                  DetailData.ContractList === undefined
                    ? "showData-selectbox setting-selectbox-manger svg-disabled"
                    : DetailData.ContractList && !DetailData.ContractList[0]
                    ? "showData-selectbox setting-selectbox-manger svg-disabled"
                    : "showData-selectbox setting-selectbox-manger "
                }
                // disabled={DetailData.ContractList && !DetailData.ContractList[0]}
                onClick={() => {
                  magerdisabled()
                }}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => magerdisabled()}
                    />
                  )}
                  open={FiltersOpen9}
                  value={editMangerSelect}
                  onChange={(e) => seteditMangerSelect(e.target.value)}
                  className="setting-border-radius"
                >
                  {DetailData.ContractList &&
                    mangaers().map((x, index) => {
                      return (
                        <MenuItem value={x.name} key={index}>
                          {x.name} {x.position}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
        ) : MangerContractData().length > 0 && index === 3 ? (
          <SuiBox sx={{ width: "100%", ml: "auto" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                textAlign: "center",
                boxShadow: 0,
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                color: "#535353",
              }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#0C65FF",
                  textAlign: "center",
                }}
                onClick={() => openMangerInfoDialog()}
              >
                {MangerContractData().length}건
              </SuiTypography>
            </Card>
          </SuiBox>
        ) : (
          ""
        )}
        <SuiBox sx={{ width: "100%", ml: "auto", display: edit && index === 0 ? "" : "none" }}>
          <FormControl
            sx={{ minHeight: 40, width: "100%" }}
            className={
              TeamSelect === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => setFiltersOpen10(!FiltersOpen10)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => setFiltersOpen10(!FiltersOpen10)}
                />
              )}
              open={FiltersOpen10}
              value={TeamSelect}
              onChange={(e) => setTeamSelect(e.target.value)}
              className="setting-border-radius"
            >
              {teamListSelects}
            </Select>
          </FormControl>
        </SuiBox>
        <SuiButton
          sx={{
            minHeight: 32,
            height: 32,
            width: 32,
            minWidth: 32,
            p: 1,
            display: edit && index === 1 ? "" : "none",
          }}
          color="primary"
          onClick={() => addFilesBtn()}
        >
          <AiOutlinePlus size="20" />
        </SuiButton>
      </SuiBox>
    )
  })

  const [test, settest] = useState("")
  const [idx, setidx] = useState("")
  const [stepidx, setstepidx] = useState(0)
  const [updateinputvalue, setupdateinputvalue] = useState("")
  const updateInputValue = (data, e, index) => {
    setupdateinputvalue(e.target.value)
  }
  const eidt_Significant = (content, id, e, index) => {
    setstepidx(1)
    setidx(index)
    setupdateinputvalue(content)
    settest(content)
  }
  const eidt_finish_Significant = (row, id, event, i) => {
    let updateSignificants = Significants
    for (var i = 0; i < updateSignificants.length; i++) {
      if (updateSignificants[i] === row) {
        updateSignificants[i].content = updateinputvalue
        if (row.id) {
          setUpdateData([...UpdateData, row])
        }
      }
    }

    setcreateContractShow(true)
    setstepidx(0)
    setidx("")
    settest("")
  }
  const updateCancle = () => {
    setstepidx(0)
  }
  const [DeleteId, setDeleteId] = useState([])
  const [UpdateData, setUpdateData] = useState([])
  const [DeletecurrentId, setDeletecurrentId] = useState("")
  const [Deleterow, setDeleterow] = useState([])
  const closeSignificant_dialog = () => {
    if (DeletecurrentId) {
      setDeleteId([...DeleteId.filter((y) => y.id === DeletecurrentId)])
    }
    setDeletecurrentId("")
    setopenSignificantDelete_Dialog(false)
  }
  const Delete_SignificantData = () => {
    setSignificants([...Significants.filter((y) => y !== Deleterow)])
    setcreateContractShow(true)
    setopenSignificantDelete_Dialog(false)
  }
  const delete_Significant = (id, row) => {
    setopenSignificantDelete_Dialog(true)

    setDeletecurrentId(id)
    if (DeleteId.indexOf(id) === -1 && id) {
      setDeleteId([...DeleteId, id])
    }
    setDeleterow(row)

    setstepidx(0)
    setidx("")
    settest("")
  }
  const [MaxSignificant, setMaxSignificant] = useState(4)
  const significant = () => {
    let data = [...Significants]
    data.sort(function (a, b) {
      return new Date(b.updated_at) - new Date(a.updated_at)
    })
    return (
      <SuiBox
        // display="flex"
        alignItems="center"
        sx={{
          mt: 2,
        }}
        className="significants-container"
      >
        {data.map((row, index) => {
          if (value === 0 && row.type === "basic") {
            let dt = new Date(row.updated_at)
            return index <= MaxSignificant ? (
              <SuiBox
                key={index}
                display="flex"
                sx={{
                  mb: 3,
                }}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                  style={{ backgroundColor: "transparent" }}
                  src={row.userProfile ? process.env.REACT_APP_BACKEND_URL + row.userProfile : ""}
                />

                <Card
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "start",
                    fontSize: 18,
                    fontWeight: 700,
                    p: 2,
                    ml: 1,
                  }}
                >
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="light"
                      sx={{ color: "#535353", width: "100%" }}
                    >
                      <span style={{ color: "#0C65FF" }}>
                        {row.userDepartment} {row.userName} {row.userPosition}
                      </span>
                      •{" "}
                      {dt.toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </SuiTypography>

                    {(typeData === "create" || typeData === "update") &&
                      (row.writerId === myData.id || myData.role.name === "Authenticated") &&
                      stepidx === 0 && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_Significant(row.content, row.id, event, index)
                            }}
                          >
                            수정
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              delete_Significant(row.id, row)
                            }}
                          >
                            삭제
                          </SuiButton>
                        </>
                      )}
                    {(typeData === "create" || typeData === "update") &&
                      row.writerId === myData.id &&
                      stepidx === 1 &&
                      idx === index && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_finish_Significant(row, row.id, event, index)
                            }}
                          >
                            완료
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              updateCancle()
                            }}
                          >
                            취소
                          </SuiButton>
                        </>
                      )}
                  </SuiBox>
                  {row.writerId === myData.id && stepidx === 1 && idx === index ? (
                    <SuiInput
                      type="textfield"
                      className="significantInput"
                      id={`${index}`}
                      sx={{ mt: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      defaultValue={test}
                      onChange={(e) => updateInputValue(index, e, index)}
                    />
                  ) : (
                    <SuiTypography fontSize="16px" fontWeight="medium" sx={{ color: "#535353" }}>
                      {row.content}
                    </SuiTypography>
                  )}
                </Card>
              </SuiBox>
            ) : (
              ""
            )
          } else if (value === 2 && row.type === "event") {
            let dt = new Date(row.updated_at)
            return index <= MaxSignificant ? (
              <SuiBox
                key={index}
                display="flex"
                sx={{
                  mb: 3,
                }}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                  style={{ backgroundColor: "transparent" }}
                  src={row.userProfile ? process.env.REACT_APP_BACKEND_URL + row.userProfile : ""}
                />

                <Card
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "start",
                    fontSize: 18,
                    fontWeight: 700,
                    p: 2,
                    ml: 1,
                  }}
                >
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="light"
                      sx={{ color: "#535353", width: "100%" }}
                    >
                      <span style={{ color: "#0C65FF" }}>
                        {row.userDepartment} {row.userName} {row.userPosition}
                      </span>
                      •{" "}
                      {dt.toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </SuiTypography>

                    {(typeData === "create" || typeData === "update") &&
                      (row.writerId === myData.id || myData.role.name === "Authenticated") &&
                      stepidx === 0 && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_Significant(row.content, row.id, event, index)
                            }}
                          >
                            수정
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              delete_Significant(row.id, row)
                            }}
                          >
                            삭제
                          </SuiButton>
                        </>
                      )}
                    {(typeData === "create" || typeData === "update") &&
                      row.writerId === myData.id &&
                      stepidx === 1 &&
                      idx === index && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_finish_Significant(row, row.id, event, index)
                            }}
                          >
                            완료
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              updateCancle()
                            }}
                          >
                            취소
                          </SuiButton>
                        </>
                      )}
                  </SuiBox>
                  {row.writerId === myData.id && stepidx === 1 && idx === index ? (
                    <SuiInput
                      type="textfield"
                      className="significantInput"
                      id={`${index}`}
                      sx={{ mt: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      defaultValue={test}
                      onChange={(e) => updateInputValue(index, e, index)}
                    />
                  ) : (
                    <SuiTypography fontSize="16px" fontWeight="medium" sx={{ color: "#535353" }}>
                      {row.content}
                    </SuiTypography>
                  )}
                </Card>
              </SuiBox>
            ) : (
              ""
            )
          }
        })}
        {MaxSignificant < data.length - 1 ? (
          <SuiBox
            sx={{ pl: 3, mt: "40px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => setMaxSignificant(MaxSignificant + 5)}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }
  const referrerData = () => {
    let list = {
      경영지원실: 0,
      콘텐츠사업본부: 0,
      전략기획팀: 0,
      플랫폼사업팀: 0,
      IP사업팀: 0,
      인터랙션디자인팀: 0,
      서비스개발팀: 0,
      서비스운영팀: 0,
    }
    let list2 = {
      경영지원실: 0,
      콘텐츠사업본부: 0,
      전략기획팀: 0,
      플랫폼사업팀: 0,
      IP사업팀: 0,
      인터랙션디자인팀: 0,
      서비스개발팀: 0,
      서비스운영팀: 0,
    }
    if (settings_ref.current) {
      let list3 = {}
      let list4 = {}
      settings_ref.current.department.forEach((el) => {
        list3[el] = 0
        list4[el] = 0
      })
      list = list3
      list2 = list4
    }

    for (var i = 0; i < usersDatas.length; i++) {
      if (list[usersDatas[i].department] > -1 && usersDatas[i].id !== myData.id) {
        list[usersDatas[i].department] =
          (parseInt(list[usersDatas[i].department])
            ? parseInt(list[usersDatas[i].department])
            : 0) + 1
      }
    }

    list[myData.department] =
      (parseInt(list[myData.department]) ? parseInt(list[myData.department]) : 0) + 1
    for (var i = 0; i < referrerIds.length; i++) {
      if (list2[referrerIds[i].department] > -1) {
        list2[referrerIds[i].department] =
          (parseInt(list2[referrerIds[i].department])
            ? parseInt(list2[referrerIds[i].department])
            : 0) + 1
      }
    }

    let Rereferrer = JSON.parse(JSON.stringify(referrerIds))
    for (const key in list) {
      if (list[key] === list2[key] && list2[key]) {
        Rereferrer = Rereferrer.filter((x) => x.department !== key)
        let x = {
          name: key,
        }
        Rereferrer.push(x)
      }
    }
    return Rereferrer
  }
  const contractPerson = () => {
    if (DetailData.manager && DetailData.manager.length > 0) {
      return DetailData.manager[0].name + " " + DetailData.manager[0].position
    } else if (DetailData.users.length > 0) {
      return DetailData.users[0].name + " " + DetailData.users[0].position
    } else {
      return "-"
    }
  }
  const contractCount = () => {
    let count = 0
    ContractDatas.forEach((el, index) => {
      let end = 0
      el[8].data.forEach((el2, index2) => {
        if (dayjs().$d < dayjs(el2[0][1].value2).$d) {
          end += 1
        }
        if (index2 === el[8].data.length - 1) {
          if (end === el[8].data.length) {
            count += 1
          }
        }
      })
    })
    return count
  }
  const contractGenre = () => {
    let count = 0
    ContractDatas.forEach((el, index) => {
      el[8].data.forEach((el2) => {
        count += el2[0][0].subContract.length
        count += el2.length
      })
    })
    return count
  }
  const connectOpen = () => {
    setContractInfosDialogOpenBool(true)
    let array = []
    if (companyContract_Datas) {
      companyContract_Datas.forEach((el) => {
        if (ContractDatas.length > 0) {
          if (
            ContractDatas.filter((x) => x[0].id)
              .map((x) => x[0].id)
              .indexOf(String(el.id)) > -1
          ) {
            array.push(el)
          }
        }
      })
    }
    if (outsourcingHistories_Datas) {
      outsourcingHistories_Datas.forEach((el) => {
        if (ContractDatas.length > 0) {
          if (
            ContractDatas.filter((x) => x[0].id)
              .map((x) => x[0].id)
              .indexOf(String(el.id)) > -1
          ) {
            array.push(el)
          }
        }
      })
    }
    setContractInfosData(array)
  }
  const connectCount = () => {
    let count = 0
    if (outsourcingHistories_Datas) {
      outsourcingHistories_Datas.forEach((el) => {
        if (ContractDatas.length > 0) {
          if (
            ContractDatas.filter((x) => x[0].id)
              .map((x) => x[0].id)
              .indexOf(String(el.id)) > -1
          ) {
            count += 1
          }
        }
      })
    }
    if (companyContract_Datas) {
      companyContract_Datas.forEach((el) => {
        if (ContractDatas.length > 0) {
          if (
            ContractDatas.filter((x) => x[0].id)
              .map((x) => x[0].id)
              .indexOf(String(el.id)) > -1
          ) {
            count += 1
          }
        }
      })
    }
    return count
  }

  const contract_documents_lists = () => {
    let contract_lists = []
    ContractDatas.forEach((el) => {
      el[8].data.forEach((contract) => {
        let content = {
          contrcact_paper: contract,
          contract: el[0],
        }
        contract_lists.push(content)
      })
    })
    contract_lists.sort((a, b) => {
      var dateA = new Date(a.contrcact_paper[0][1].value1).getTime()
      var dateB = new Date(b.contrcact_paper[0][1].value1).getTime()

      var dateC = new Date(a.contrcact_paper[0][1].value2).getTime()
      var dateD = new Date(b.contrcact_paper[0][1].value2).getTime()

      if (dateA < dateB) return 1
      if (dateA > dateB) return -1
      else {
        if (dateC < dateD) return 1
        if (dateC > dateD) return -1
        return 0
      }
    })

    return contract_lists
  }
  const ListCard_type2 = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={3} sx={{ pr: 1.5 }}>
          <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
            작가 담당자:
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: "34px",
                color: "#535353",
              }}
            >
              {contractPerson()}
            </Card>
          </SuiBox>
        </Grid>
        <Grid item xs={3} sx={{ pr: 1.5 }}>
          <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
            계약 작품 수:
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: "34px",
                color: "#535353",
              }}
            >
              {contractCount()}건
            </Card>
          </SuiBox>
        </Grid>
        <Grid item xs={3} sx={{ pr: 1.5 }}>
          <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
            계약 종(유형) 수:
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: "34px",
                color: "#535353",
              }}
            >
              {contractGenre()}건
            </Card>
          </SuiBox>
        </Grid>
        <Grid item xs={3}>
          <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
            연결 계약:
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: "34px",
                cursor: "pointer",
                textDecoration: "underline",
                color: "#0C65FF",
              }}
              onClick={() => connectOpen()}
            >
              {connectCount()}건
            </Card>
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          {ContractDatas.length === 0 ? (
            <TableContainer
              sx={{
                borderTop: "1px solid #F1F1F5",
                borderRadius: 0,
                boxShadow: 0,
              }}
            >
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                <EnhancedTableHead2
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rowlength}
                />
              </Table>
              <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                <SuiTypography fontSize="18px" color="dark" fontWeight="400">
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            </TableContainer>
          ) : (
            <SuiBox
              sx={{
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  borderTop: "1px solid #F1F1F5",
                  borderRadius: 0,
                  boxShadow: 0,
                  marginRight: "40px",
                }}
              >
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                  <EnhancedTableHead2
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rowlength}
                  />
                  <TableBody>
                    {stableSort(
                      contract_documents_lists().slice(0, historySplice2),
                      getComparator(order, orderBy)
                    )
                      .sort((a, b) => {
                        var dateA = a.contract.value
                        var dateB = b.contract.value
                        return dateA < dateB ? 1 : -1
                      })
                      .sort((a, b) => {
                        var dateA = dayjs(a.contrcact_paper[0][1].value1)
                        var dateB = dayjs(b.contrcact_paper[0][1].value1)
                        return dateA < dateB ? 1 : -1
                      })
                      .map((data, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <Tooltip
                              arrow={false}
                              color="white"
                              classes={{ popper: "tooltip-light" }}
                              title={
                                <SuiTypography
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "100%",
                                  }}
                                  verticalAlign="middle"
                                >
                                  <span style={{ cursor: "default" }}>{data.contract.value}</span>
                                </SuiTypography>
                              }
                            >
                              <TableCell
                                align="center"
                                className="td-Setting td-fontSetting"
                                sx={{ cursor: "default" }}
                              >
                                {data.contract.value}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              sx={{ cursor: "default" }}
                            >
                              {data.contrcact_paper[0][0].selectData[1].value}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              style={{ borderRight: "1px solid #F1F1F5" }}
                              sx={{ cursor: "default" }}
                            >
                              {data.contract.user.name} {data.contract.user.position}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              sx={{ cursor: "default" }}
                            >
                              {data.contrcact_paper.length > 0
                                ? dayjs(data.contrcact_paper[0][1].value1).format("YY.MM.DD")
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              sx={{ cursor: "default" }}
                            >
                              {data.contrcact_paper[0][1].value2
                                ? data.contrcact_paper.length > 0
                                  ? dayjs(data.contrcact_paper[0][1].value2).format("YY.MM.DD")
                                  : "-"
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              style={{ borderRight: "1px solid #F1F1F5" }}
                              sx={{ cursor: "default" }}
                            >
                              {data.contrcact_paper.length > 0
                                ? ShowStateIcon(data.contrcact_paper[0], index)
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td-Setting td-fontSetting"
                              sx={{ cursor: "default" }}
                            >
                              {data.contrcact_paper.length > 0
                                ? data.contrcact_paper[0][0].subContract.length
                                : "0"}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {Math.ceil(historySplice2 / 10) <
              Math.ceil(contract_documents_lists().length / 10) ? (
                <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px" }}>
                  <SuiButton
                    color="info2"
                    variant="text"
                    sx={{
                      width: 100,
                      border: 1,
                      borderColor: "#0C65FF",
                      height: 40,
                      mr: 2,
                      fontSize: 14,
                    }}
                    onClick={() => {
                      sethistorySplice2(historySplice2 + 10)
                    }}
                  >
                    더보기
                  </SuiButton>
                </SuiBox>
              ) : (
                ""
              )}
            </SuiBox>
          )}
        </Grid>
      </Grid>
    )
  }
  const ListCard = () => {
    return (
      <SuiBox>
        <SuiBox display="flex">{listsShow}</SuiBox>
        <SuiBox display="flex" sx={{ mt: 1 }}>
          <SuiTypography
            fontSize="15px"
            fontWeight="bold"
            color="info"
            sx={{ pt: 2, pb: 1, fontWeight: 400 }}
          >
            참조자:
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" sx={{ mt: 1 }}>
          {" "}
          {typeData !== "update" && typeData !== "create" ? (
            ""
          ) : (
            <SuiButton
              sx={{
                minHeight: 32,
                height: 32,
                width: 32,
                minWidth: 32,
                p: 1,
                ml: referrerIds.length === 0 ? 0 : 1,
                mr: 1,
              }}
              color="primary"
              onClick={() => setreferrer()}
            >
              <AiOutlinePlus size="20" />
            </SuiButton>
          )}
          <Stack display="block" direction="row" spacing={1} sx={{ textAlign: "left" }}>
            {referrerData().map((row, index) => {
              return typeData !== "update" && typeData !== "create" ? (
                <Chip
                  sx={{
                    backgroundColor: "#F7F9FB",
                    height: 32,
                  }}
                  style={{ margin: "0px 5px 5px 0px" }}
                  key={index}
                  label={row.name}
                />
              ) : (
                <Chip
                  sx={{
                    backgroundColor: "#F7F9FB",
                    height: 32,
                  }}
                  key={index}
                  label={row.name}
                  style={{ margin: "0px 5px 5px 0px" }}
                  onDelete={() => handlereferrerIdsDelete(row)}
                  deleteIcon={<AiOutlineCloseCircle className="blackColor" />}
                />
              )
            })}
          </Stack>
        </SuiBox>
      </SuiBox>
    )
  }
  // dataExport

  useEffect(() => {
    AttachmentDatasExport(AttachmentDatas)
  }, [AttachmentDatas])
  useEffect(() => {
    MeetingDatasExport(MeetingDatas)
  }, [MeetingDatas])
  useEffect(() => {
    SignificantsDatasExport(Significants)
  }, [Significants])

  useEffect(() => {
    const data = [...ContractDatas]
    if (selectContarct.length > 0) {
      data.forEach((el, index) => {
        if (el[8].data.length > 0) {
          el[8].data.forEach((el2) => {
            if (selectContarct.length > 0) {
              if (selectContarct[0][0].id) {
                if (el2[0][0].id === selectContarct[0][0].id) {
                  el2[0][0].referrer = referrerIds
                  el[1].changed = true
                  el2[0][0].changed = true
                }
              } else {
                if (selectContarct[0][1].created_at === el2[0][1].created_at) {
                  el2[0][0].referrer = referrerIds
                  el[1].changed = true
                  el2[0][0].changed = true
                }
              }
            }
          })
        }
        if (index === data.length - 1) {
          setContractDatas(
            data.sort((a, b) => {
              var dateA = new Date(a[1].created_at).getTime()
              var dateB = new Date(b[1].created_at).getTime()
              return dateA < dateB ? 1 : -1
            })
          )
          // setselectContarct([])
        }
      })
    }
  }, [referrerIds])
  useEffect(() => {
    inputListsExport(InputLists)
  }, [InputLists])
  const [alert_Dialog, setalert_Dialog] = useState({
    open: false,
    title: "",
    content: "",
  })
  const alertDialog_close = async () => {
    dispatch(loading_Apply(true))

    await firstChangeRequestsActive(DetailData.id)
    let data = { ...alert_Dialog }
    data.open = false
    setalert_Dialog(data)
    dispatch(loading_Apply(false))
  }
  const [updateChangeRequestActive] = useMutation(updateChangeRequest, {
    onError() {
      alert("이관 신청 취소가 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const [open_ChagneDialog, setopen_ChagneDialog] = useState(false)
  const [change_data, setchange_data] = useState([])
  const [change_content, setchange_content] = useState("")
  const [change_target, setchange_target] = useState("writer")
  const close_ChagneDialog = () => {
    setopen_ChagneDialog(false)
  }
  const change_active = (changeList) => {
    dispatch(loading_Apply(true))
    const content = {
      request: {
        id: myData.id,
        department: myData.department,
        name: myData.name,
        position: myData.position,
        profile: myData.profile ? myData.profile.url : null,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
      },
      companiesIds: [],
      writersIds: [DetailData.id],
      contractsIds: [],
      outsourcingsIds: [],
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
      type: "my_writer",
      name: "",
      users: DetailData.users,
      contract_document: DetailData.contracts,
      targets_name: [DetailData.ebookname],
      targets: [DetailData],
    }
    const body = {
      type: `my_writer Change`,
      arrayContent: content,
      users_permissions_user: myData.id,
      status: "신청",
      writersId: DetailData.id,
    }
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then((res) => {
        usersDatas
          .filter((x) => x.role.id === 1)
          .forEach(async (user, user_idex) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[1].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "담당자 변경 신청",
                    type: "Change",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      title: myData.name,
                      name: myData.name,
                      targetName: DetailData.ebookname,
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "3",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                    },
                  },
                })
              }
            }
            if (user_idex === usersDatas.length - 1) {
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "이관 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 이관이 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
            }
          })
        dispatch(loading_Apply(false))
      })
  }

  const open_cancle_alert = (type, row) => {
    let request = {}
    if (typeData === "detail") {
      // 수정 버튼 클릭 전  - 기존 확인 팝업 생성
      if (type === "change") {
        return openChangeDialog(row)
      } else {
        return openDeleteDialog(row)
      }
    }
    if (type === "change") {
      // 이관 신청 취소하기
      if (DetailData.change_requests.length > 0) {
        if (
          DetailData.change_requests.filter(
            (x) => x.arrayContent.writersIds.indexOf(row[0][0].id) > -1
          ).length > 0
        )
          request = DetailData.change_requests.filter(
            (x) => x.arrayContent.writersIds.indexOf(row[0][0].id) > -1
          )[0]
      }

      // 신청인이 아닐 시 기존의 신청 확인 팝업
      if (
        request.arrayContent.request.id !== myData.id &&
        myData.role.name !== "Authenticated" &&
        myData.role.name !== "gold" &&
        myData.role.name !== "silver"
      ) {
        return openChangeDialog(row)
      }

      let data = { ...change_cancle_alert }
      data.open = true
      data.title = "이관신청 취소"
      data.content = "관리자에게 이관 신청 중입니다. 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.data = request
      data.CanBtnText = "돌아가기"
      setchange_cancle_alert(data)
    } else if (type === "delete") {
      let notRead = row[0][0].requests.filter((e) => {
        return e.read === false
      })
      if (notRead.length === 0) {
        return false
      } else {
        notRead.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })

        if (notRead[0].status.indexOf("심사중") > -1) {
          request = notRead[0]
        }
      }
      // 신청인이 아닐 시 기존의 신청 확인 팝업
      if (
        !request.content ||
        (request.content.id !== myData.id &&
          myData.role.name !== "Authenticated" &&
          myData.role.name !== "gold" &&
          myData.role.name !== "silver")
      ) {
        return openChangeDialog(row)
      }

      let data = { ...delete_cancle_alert }
      data.open = true
      data.title = "삭제신청 취소"
      data.content = "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.CanBtnText = "돌아가기"
      setdelete_cancle_alert(data)
    }
  }
  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
  }
  const delete_cancle_active = () => {
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제신청 취소 완료"
      data.content = "신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"

      setdelete_cancle_alert(data)
      setcreateContractShow(true)

      updateWriterActive({
        variables: {
          id: DetailData.id,
        },
      })
    })
  }
  // 이관신청 취소 alert
  const [change_cancle_alert, setchange_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
    data: {},
  })
  const change_cancle_alert_close = () => {
    let data = { ...change_cancle_alert }
    data.open = false
    setchange_cancle_alert(data)
  }
  const change_cancle_active = () => {
    const content = { ...change_cancle_alert.data.arrayContent }
    content["read"] = true
    content["status"] = `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`
    content["decisionDate"] = new Date()
    const body = {
      arrayContent: content,
      status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
      decisionDate: new Date(),
    }
    dispatch(loading_Apply(true))
    axios
      .put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + change_cancle_alert.id, body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then(() => {
        let data = { ...change_cancle_alert }
        data.title = "이관신청 취소 완료"
        data.content = "신청이 취소되었습니다."
        data.twoBtn = false
        data.CanBtnText = "확인"

        setchange_cancle_alert(data)
        setcreateContractShow(true)
        firstChangeRequestsActive(DetailData.id)

        updateWriterActive({
          variables: {
            id: DetailData.id,
          },
        })

        dispatch(loading_Apply(false))
      })
  }

  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // {...other}
    >
      {value === index && (
        <SuiBox display="flex" justifyContent="center" bgColor="#F1F1F5" minHeight="85vh" px={14}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                {children}
              </SuiTypography>
              <SuiTypography
                color="subTitle"
                sx={{ mt: 2, fontWeight: 500, fontSize: 18, mb: "40px" }}
                variant="h6"
                component="div"
              >
                {subText()}
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
          <SuiDialog
            openDialog={openNoContract_dialog}
            closeDialog={() => setopenNoContract_dialog(false)}
            MainTitle={"삭제된 계약 알림"}
            Content={"담당자의 신청에 의해 이미 삭제된 계약 건입니다."}
            CanBtnText={"확인"}
          />
          <ContractInfosDialog
            openDialog={ContractInfosDialogOpenBool}
            closeDialog={() => setContractInfosDialogOpenBool(false)}
            ContarctData={ContractInfosData}
          />
          <DeleteDialog
            openDialog={Delete_Dialog}
            closeDialog={() => setDelete_Dialog(false)}
            MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
            Content={Delete_Content}
            CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
            CreBtnText={"삭제신청"}
            CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
            AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
            requestExplainData={setrequestExplainData}
            requestExplainShowData={requestExplainData}
            Active={() => Delete_All()}
            type={Delete_Dialog_type}
          />
          <SuiDialog
            openDialog={alert_Dialog.open}
            className={"admin_setting_dialog"}
            MainTitle={alert_Dialog.title}
            Content={alert_Dialog.content}
            CanBtnText={"닫기"}
            CreBtnColor="info2"
            AlertType="oneBtn"
            closeDialog={() => alertDialog_close()}
          />
          <SuiDialog
            openDialog={Contract_Update_Dialog}
            closeDialog={() => setContract_Update_Dialog(false)}
            MainTitle={"작품명 변경"}
            Content={"작품명 변경시 변경내용이 기록됩니다. 작품명을 변경하고 저장하시겠습니다?"}
            CanBtnText={"취소"}
            CreBtnText={"저장하기"}
            CreBtnColor="primary"
            AlertType="twoBtn"
            Active={() => Update_Contract()}
          />
          <SuiDialog
            openDialog={chagne_cancel_dailog}
            closeDialog={() => setchagne_cancel_dailog(false)}
            MainTitle={"이관 신청 취소"}
            Content={`관리자에게 이관 신청 중입니다.
            취소하시겠습니까?`}
            CanBtnText={"돌아가기"}
            CreBtnText={"취소하기"}
            CreBtnColor="primary"
            AlertType="twoBtn"
            Active={() => chagne_cancel_dailog_Active()}
          />
          <SuiDialog
            openDialog={Contract_Delete_Dialog}
            closeDialog={() => setContract_Delete_Dialog(false)}
            MainTitle={"작품 삭제하기"}
            Content={"작품 정보를 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_Contract()}
          />
          <SuiDialog
            openDialog={openMeettingDelete_Dialog}
            closeDialog={() => meetings_close()}
            MainTitle={"회의록 삭제하기"}
            Content={"등록하신 회의록을 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_MeettingData()}
          />
          <SuiDialog
            openDialog={openContractDelete_Dialog}
            closeDialog={() => setopenContractDelete_Dialog(false)}
            MainTitle={"계약서 삭제하기"}
            Content={"등록하신 계약서를 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_ContractData()}
          />
          <SuiDialog
            openDialog={openDelete_Dialog}
            closeDialog={() => setopenDelete_Dialog(false)}
            MainTitle={"첨부 파일 삭제"}
            Content={"첨부파일을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_AttachmentData()}
          />
          <SuiDialog
            openDialog={openSignificantDelete_Dialog}
            closeDialog={() => closeSignificant_dialog()}
            MainTitle={"특이사항 삭제"}
            Content={"특이사항을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_SignificantData()}
          />
          <SuiDialog
            openDialog={openInfo_Dialog}
            closeDialog={() => setopenInfo_Dialog(false)}
            MainTitle={"정보 입력 오류"}
            Content={"올바른 개인 상세 정보를 입력해 주세요."}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openDeleteInfo_Dialog}
            closeDialog={() => setopenDeleteInfo_Dialog(false)}
            MainTitle={"삭제 신청 심사중"}
            Content={"삭제 신청 심사중 입니다. <br>심사가 끝날때 까지 기다려 주세요."}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openCantDelete_Dialog}
            closeDialog={() => setopenCantDelete_Dialog(false)}
            MainTitle={"작품 삭제 불가"}
            Content={"연결된 계약서가 존재하여 작품 정보를 삭제하실 수 없습니다."}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openDeleteJudging_Dialog}
            closeDialog={() => setopenDeleteJudging_Dialog(false)}
            MainTitle={"삭제 신청 심사중"}
            Content={DeleteJudging_Content}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openChangeJudging_Dialog}
            closeDialog={() => setopenChangeJudging_Dialog(false)}
            MainTitle={"이관 신청 심사중"}
            Content={ChangeJudging_Content}
            CanBtnText={"확인"}
          />
          <ChangeDialog
            openDialog={open_ChagneDialog}
            closeDialog={close_ChagneDialog}
            change_data={change_data}
            content={change_content}
            usersDatas={usersDatas}
            change_active={change_active}
          />

          <SuiDialog
            openDialog={delete_cancle_alert.open}
            className={"admin_setting_dialog"}
            MainTitle={delete_cancle_alert.title}
            Content={delete_cancle_alert.content}
            CanBtnText={delete_cancle_alert.CanBtnText}
            AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
            CreBtnColor={"error"}
            CreBtnText={"취소하기"}
            closeDialog={() => delete_cancle_alert_close()}
            Active={() => delete_cancle_active()}
          />

          <SuiDialog
            openDialog={change_cancle_alert.open}
            className={"admin_setting_dialog"}
            MainTitle={change_cancle_alert.title}
            Content={change_cancle_alert.content}
            CanBtnText={change_cancle_alert.CanBtnText}
            AlertType={change_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
            CreBtnColor={"error"}
            CreBtnText={"취소하기"}
            closeDialog={() => change_cancle_alert_close()}
            Active={() => change_cancle_active()}
          />
          <Event
            open={event_dialog}
            closeDialog={closeWorksDialog}
            editId={edit_event_id}
            type={type_event}
          />
        </SuiBox>
      )}
      {authCheck() ? FooterForm() : ""}
    </div>
  )
}
TabPanel.defaultProps = {
  edit: false,
  editId: null,
  type: "create",
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  edit: PropTypes.bool,
  type: PropTypes.string,
  inputListsExport: PropTypes.func,
  referrerIdsExport: PropTypes.func,
  AttachmentDatasExport: PropTypes.func,
  MeetingDatasExport: PropTypes.func,
  inputListsImport: PropTypes.array,
  editId: PropTypes.string,
  SignificantsDatasExport: PropTypes.func,
}
export default TabPanel
