import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import SuiDialog from "components/SuiDialog"

import { MdClose } from "react-icons/md"

import { useMutation, useLazyQuery } from "@apollo/client"
import {
  changeRequests,
  writersChangeList,
  companiesChangeList,
  companyContract,
  writerContract,
  user,
  writer,
  company,
  settings,
  users_list,
} from "apollo/query"
import {
  updateCompany,
  updateWriter,
  updateChangeRequest,
  updateContract,
  updateCompanyContract,
  updateContractDocument,
  createAlert,
} from "apollo/mutation"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

import axios from "axios"

function ContractInfosDialog3({ openDialog, closeDialog, ContarctData, userInfo }) {
  const dispatch = useDispatch()
  const { myData } = useSelector((state) => state.store)
  const [infos, setinfos] = useState("")
  const [first_contract, setfirst_contract] = useState("")
  const [open, setOpen] = useState(openDialog)
  const [users_listData, setusers_listData] = useState([])
  const [users_listActive, {}] = useLazyQuery(users_list, {
    variables: {
      status: "근무",
    },
  })
  useEffect(() => {
    setOpen(openDialog)

    if (ContarctData.length > 0) {
      let info = ""
      ContarctData.forEach((el, index) => {
        el.forEach((el2, index2) => {
          if (index2 === 0) {
            info +=
              el2[0].file.name.split(".")[0] +
              " (" +
              (el2[0].selectData[1].value
                ? el2[0].selectData[1].value
                : el2[0].selectData[0].value) +
              ") " +
              dayjs(el2[1].created_at).format("YYYY.MM.DD") +
              "\n"
            if (index === 0) {
              setfirst_contract(
                el2[0].file.name.split(".")[0] +
                  " (" +
                  (el2[0].selectData[1].value
                    ? el2[0].selectData[1].value
                    : el2[0].selectData[0].value) +
                  ") "
              )
            }
          }
        })
        if (ContarctData.length - 1 === index) {
          setinfos(info)
        }
      })
      settings_Call()
    }
    if (openDialog) {
      users_listActive().then((res) => {
        setusers_listData(res.data.users)
      })
      setstep(0)
      setchangeList({
        id: null,
        department: "",
        name: "",
        reason: "퇴사로 인한 계약 이관",
      })
    }
  }, [openDialog])

  const [department_list, setdepartment_list] = useState([])
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [updateWriterActive] = useMutation(updateWriter, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyActive] = useMutation(updateCompany, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [writerActive] = useLazyQuery(writer, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [companyActive] = useLazyQuery(company, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [updateContractDocumentActive] = useMutation(updateContractDocument, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyContractActive] = useMutation(updateCompanyContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [userActive] = useLazyQuery(user, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const settings_Call = () => {
    settingsActive({
      variables: {
        type: "department",
      },
    }).then((res) => {
      let list = res.data.settings[0].list
      setdepartment_list(list)
    })
  }
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const stepActive = () => {
    closeDialog()
    setOpen(false)
  }
  const next_step = (next) => {
    setstep(next)
  }

  const [step, setstep] = useState(0)

  const [changeList, setchangeList] = useState({
    id: null,
    department: "",
    name: "",
    reason: "퇴사로 인한 계약 이관",
  })
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)

  const [workList2, setworkList2] = useState([])

  const workListSelects = department_list.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const return_usre_list = () => {
    return users_listData
      .filter((y) => y.id !== userInfo.id && y.department === changeList.department)
      .map((x) => x.name + " " + x.position + " " + x.id)
  }
  const workListSelects2 = return_usre_list().map((x, index) => {
    return (
      <MenuItem value={x} key={index}>
        {x.split(" ")[0] + " " + x.split(" ")[1]}
      </MenuItem>
    )
  })

  const workList3 = ["퇴사로 인한 계약 이관", "업무 변경으로 인한 이관"]
  const workListSelects3 = workList3.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const handleChange = (e, index) => {
    const changeListData = { ...changeList }
    if (index === 1) {
      changeListData.department = e.target.value
    } else if (index === 2) {
      changeListData.id = e.target.value.split(" ")[2]
      changeListData.name = e.target.value
    } else if (index === 3) {
      changeListData.reason = e.target.value
    }
    setchangeList(changeListData)
  }

  const handleCloseOut = () => {
    setopenOut_Dialog(false)
  }
  const [done_Dialog_open, setdone_Dialog_open] = useState(false)
  const [change_check_dialog, setchange_check_dialog] = useState(false)
  const [change_check_dialog_Content, setchange_check_dialog_Content] = useState(
    "지정한 이관 대상자에게 일괄 이관하시겠습니까? \n 이관 완료 후 이전 상태로 복구할 수 없습니다."
  )

  const [done_dialog_Content, setdone_dialog_Content] = useState("")

  const change_active = () => {
    let content = `일괄 이관이 완료되었습니다. (${dayjs(new Date()).format(
      "YYYY.MM.DD"
    )}) \n 이관된 담당자에게 알림이 전송됩니다.`
    setdone_dialog_Content(content)
    setdone_Dialog_open(true)
    setchange_check_dialog(false)
  }

  const change_all_active = () => {
    const content = {
      request: {
        id: userInfo.id,
        department: userInfo.department,
        name: userInfo.name,
        position: userInfo.position,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
      },
      companiesIds: [],
      writersIds: [],
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
    }
    userInfo.companies.forEach((element) => {
      content.companiesIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        company_contracts: element.company_contracts.filter(
          (x) => x.users_permissions_user.id === userInfo.id
        ),
      })
    })
    userInfo.writers.forEach((element) => {
      content.writersIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        contracts: element.contracts,
      })
    })

    dispatch(loading_Apply(true))

    content.status = "승인"
    const body = {
      content: content,
      status: "승인",
    }
    if (content.writersContractId) {
      writerActive({
        variables: {
          id: content.writersContractId.writerId,
        },
      }).then(({ data }) => {
        let contractIndex = data.writer.contracts.findIndex(
          (el) => el.contract_documents.findIndex((e) => e.id === content.contractId) !== -1
        )
        let titleIndex = data.writer.contracts[contractIndex].contract_documents.findIndex(
          (e) => e.id === content.contractId
        )

        let historyContent =
          "<strong>" +
          data.writer.contracts[contractIndex].contract_documents[titleIndex].contractPaper[0][0]
            .selectData[1].value +
          "</strong> 담당자가 변경(" +
          content.request.name +
          " " +
          content.request.position +
          " → " +
          content.apply.name +
          " " +
          content.apply.position +
          ")"
        const editHistory = [...data.writer.history]
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: historyContent,
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          type: "contract-change",
          contractId: content.contractId,
        })

        updateWriterActive({
          variables: {
            id: content.writersContractId.writerId,
            history: editHistory,
          },
        })
      })
    }

    if (content.companyContractId) {
      companyActive({
        variables: {
          id: content.companyContractId.companyId,
        },
      }).then(({ data }) => {
        let contractIndex = data.company.company_contracts.findIndex(
          (el) => el.id === content.contractId
        )
        let historyContent =
          "<strong>" +
          data.company.company_contracts[contractIndex].contractPaper[0][0].selectData[0].value +
          "</strong> 담당자가 변경(" +
          content.request.name +
          " " +
          content.request.position +
          " → " +
          content.apply.name +
          " " +
          content.apply.position +
          ")"
        const editHistory = [...data.company.history]
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: historyContent,
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          type: "contract-change",
          contractId: content.contractId,
        })
        updateCompanyActive({
          variables: {
            id: content.companyContractId.companyId,
            history: editHistory,
          },
        })
      })
    }

    if (content.writersIds.length > 0) {
      content.writersIds.forEach(async (element, index) => {
        const userIndex = element.users.indexOf(content.request.id)
        element.users.splice(userIndex, 1)
        element.users.push(content.apply.id)
        await updateWriterActive({
          variables: {
            id: element.id,
            users: element.users,
          },
        })
        element.contracts.forEach((contract) => {
          contract.contract_documents.forEach((contract_doc) => {
            if (contract_doc.users_permissions_user.id === content.request.id) {
              updateContractDocumentActive({
                variables: {
                  id: contract_doc.id,
                  users_permissions_user: content.apply.id,
                },
              })
            }
          })
        })
        if (content.writersIds.length - 1 === index) {
          if (content.companiesIds.length > 0) {
            content.companiesIds.forEach(async (element, index) => {
              const userIndex = element.users.indexOf(content.request.id)
              element.users.splice(userIndex, 1)
              element.users.push(content.apply.id)
              await updateCompanyActive({
                variables: {
                  id: element.id,
                  users: element.users,
                },
              })
              element.company_contracts.forEach((contract) => {
                updateCompanyContractActive({
                  variables: {
                    id: contract.id,
                    users_permissions_user: content.apply.id,
                  },
                })
              })
              if (content.companiesIds.length - 1 === index) {
                change_active()
                dispatch(loading_Apply(false))
              }
            })
          } else {
            change_active()
            dispatch(loading_Apply(false))
          }
        }
      })
    } else if (content.companiesIds.length > 0) {
      content.companiesIds.forEach(async (element, index) => {
        const userIndex = element.users.indexOf(content.request.id)
        element.users.splice(userIndex, 1)
        element.users.push(content.apply.id)
        await updateCompanyActive({
          variables: {
            id: element.id,
            users: element.users,
          },
        })
        element.company_contracts.forEach((contract) => {
          updateCompanyContractActive({
            variables: {
              id: contract.id,
              users_permissions_user: content.apply.id,
            },
          })
        })
        if (content.companiesIds.length - 1 === index) {
          change_active()
          dispatch(loading_Apply(false))
        }
      })
    } else {
      if (content.writersContractId) {
        let usersArray = content.writersContractId.users.map((x) => x.id)
        usersArray.push(content.apply.id)
        updateWriterActive({
          variables: {
            id: content.writersContractId.writerId,
            users: usersArray,
          },
        }).then(() => {
          updateContractDocumentActive({
            variables: {
              id: content.writersContractId.id,
              users_permissions_user: content.apply.id,
            },
          }).then(() => {
            change_active()

            dispatch(loading_Apply(false))
          })
        })
      } else {
        let usersArray = content.companyContractId.users.map((x) => x.id)
        usersArray.push(content.apply.id)
        updateCompanyActive({
          variables: {
            id: content.companyContractId.companyId,
            users: usersArray,
          },
        }).then(() => {
          updateCompanyContractActive({
            variables: {
              id: content.companyContractId.id,
              users_permissions_user: content.apply.id,
            },
          }).then(() => {
            change_active()

            dispatch(loading_Apply(false))
          })
        })
      }
    }
    userActive({
      variables: {
        id: content.request.id,
      },
    }).then(({ data }) => {
      if (data.user.nomalAlertSetting) {
        if (data.user.nomalAlertSetting[2].value) {
          createAlertActive({
            variables: {
              users_permissions_user: String(data.user.id),
              title: "계약 이관 승인",
              type: "change",
              userid: String(data.user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
                content: content,
              },
            },
          })
        }
      }
    })
  }
  const show_lists = () => {
    return (
      <>
        <Grid item xs={12}>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                <span style={{ color: "#0C65FF" }}>
                  {`${userInfo.name + " " + userInfo.position + " "}`}
                </span>
                : 계약 일괄 이관
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                담당 데이터 및 계약 정보를 확인하고 일괄 이관합니다.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              이관 신청 건 :
            </SuiTypography>
          </SuiBox>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              mb: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                {ContarctData.length}건(
                {`작가:${ContarctData.filter((x) => x.dataType === "writer").length}건 · 유통사: ${
                  ContarctData.filter((x) => x.dataType === "company").length
                }건 · 외주계약:${
                  ContarctData.filter((x) => x.dataType === "outsourcing").length
                }건`}
                )
              </SuiTypography>
            </SuiBox>
          </Card>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              계약 정보 :
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              disabled
              multiline
              rows={8}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              value={infos}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2 }}
          >
            <SuiButton
              color="primary"
              onClick={() => next_step(2)}
              sx={{
                ml: "auto",
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              다음으로
            </SuiButton>
          </SuiBox>
        </Grid>
      </>
    )
  }

  const close_all = () => {
    setdone_Dialog_open(false)
    setchange_check_dialog(false)
    closeDialog()
  }
  const next_manager = () => {
    return (
      <SuiBox sx={{}}>
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "center" }}>
          {step === 1 ? "일괄 이관 담당자 지정" : "일괄 이관 처리 확인"}
        </SuiTypography>
        <SuiTypography fontSize="16px" sx={{ textAlign: "center", marginTop: "14px" }}>
          {step === 1
            ? " 이관 정보를 확인하고 이관 대상자를 지정합니다."
            : "마지막으로 이관 정보를 확인합니다."}
        </SuiTypography>
        <SuiBox
          sx={{
            border: "1px solid #CDD2D3",
            borderRadius: "10px",
            padding: 2,
            mt: 4,
            mb: 2,
            backgroundColor: "#F7F9FB",
            wordNreak: "keep-all",
          }}
        >
          <SuiTypography fontSize="18px" sx={{ color: "#707070", textAlign: "center" }}>
            이관 신청 내용 :{" "}
            <span style={{ color: "#0C65FF" }}>
              {first_contract}{" "}
              {ContarctData.length - 1 > 0 ? `외 ${ContarctData.length - 1}건` : ""}
            </span>
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" justifyContent="start" sx={{ flexFlow: "wrap" }}>
          <SuiBox sx={{ width: "100%" }}>
            <Card
              sx={{
                pl: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
                mb: 2,
                borderRadius: "1rem 0.7rem 0.7rem 1rem",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                >
                  담당부서
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                />
                {step === 2 ? (
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.department === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen1(!FiltersOpen1)}
                          />
                        )}
                        open={FiltersOpen1}
                        value={changeList.department}
                        onChange={(e) => handleChange(e, 1)}
                        className="change-select-radius"
                      >
                        {workListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {changeList.department}
                  </SuiTypography>
                )}
              </SuiBox>
            </Card>
            <Card
              sx={{
                pl: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
                borderRadius: "1rem 0.7rem 0.7rem 1rem",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                >
                  담당자
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                {step === 2 ? (
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.name === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen2(!FiltersOpen2)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen2(!FiltersOpen2)}
                          />
                        )}
                        open={FiltersOpen2}
                        value={changeList.name}
                        onChange={(e) => handleChange(e, 2)}
                        className="change-select-radius"
                      >
                        {workListSelects2}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {changeList.name}
                  </SuiTypography>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
          <Card
            sx={{
              mt: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: step === 1 ? "#e9ecef" : "#F7F9FB",
              width: "100%",
              borderRadius: "1rem 0.7rem 0.7rem 1rem",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: "60px" }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ ml: 4, whiteSpace: "nowrap", color: "#535353" }}
              >
                이관사유
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }} />
              {step === 2 ? (
                <SuiBox sx={{ width: "100%", height: "auto" }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      changeList.reason === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      value={changeList.reason}
                      onChange={(e) => handleChange(e, 3)}
                      className="change-select-radius"
                    >
                      {workListSelects3}
                    </Select>
                  </FormControl>
                </SuiBox>
              ) : (
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {changeList.reason}
                </SuiTypography>
              )}
            </SuiBox>
          </Card>
        </SuiBox>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 0, mt: 5, px: 0 }}
          >
            {step === 2 ? (
              <SuiButton
                color="subTitle"
                variant="text"
                onClick={() => next_step(0)}
                className="back-btn"
                sx={{ justifyContent: "left", fontSize: 16 }}
              >
                뒤로
              </SuiButton>
            ) : null}
            <SuiButton
              color="primary"
              onClick={() => (step === 1 ? setstep(2) : setchange_check_dialog(true))}
              disabled={!changeList.name || !changeList.department || !changeList.reason}
              sx={{
                ml: "auto",
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              {step === 1 ? "다음으로" : "완료"}
            </SuiButton>
          </SuiBox>
        </Grid>

        <SuiDialog
          openDialog={change_check_dialog}
          closeDialog={() => setchange_check_dialog(false)}
          MainTitle={"일괄 이관 처리 확인"}
          Content={change_check_dialog_Content}
          Active={() => change_all_active()}
          CanBtnText={"취소"}
          CreBtnText={"확인"}
          AlertType={"twoBtn"}
          CreBtnColor={"info2"}
        />
        <SuiDialog
          openDialog={done_Dialog_open}
          closeDialog={() => close_all()}
          MainTitle={"일괄 이관 완료"}
          Content={done_dialog_Content}
          CanBtnText={"확인"}
        />
      </SuiBox>
    )
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ padding: 5, width: 480, position: "relatve" }}>
        <IconButton
          edge="start"
          color="inherit"
          sx={{ position: "absolute", top: 15, right: 15 }}
          onClick={() => handleClose()}
          aria-label="close"
        >
          <MdClose />
        </IconButton>
        {{ 0: show_lists(), 1: next_manager(), 2: next_manager() }[step]}
      </Grid>
    </Dialog>
  )
}

ContractInfosDialog3.defaultProps = {
  openDialog: false,
}

ContractInfosDialog3.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  ContarctData: PropTypes.array,
  userInfo: PropTypes.object,
}
export default ContractInfosDialog3
