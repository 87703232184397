import React, { forwardRef } from "react"

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// Custom styles for SuiInput
import SuiInputRoot from "components/SuiInput/SuiInputRoot"
import SuiInputWithIconRoot from "components/SuiInput/SuiInputWithIconRoot"
import SuiInputIconBoxRoot from "components/SuiInput/SuiInputIconBoxRoot"
import SuiInputIconRoot from "components/SuiInput/SuiInputIconRoot"

// Mui Import
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context"

// eslint-disable-next-line react/display-name
const SuiInput = forwardRef(
  ({ size, icon, error, success, disabled, helperText, iconActive, ...rest }, ref) => {
    let template
    const [controller] = useSoftUIController()
    const { direction } = controller
    const iconDirection = icon.direction

    if (icon.component && icon.direction === "left") {
      template = (
        <FormControl sx={{ width: "100%" }}>
          <SuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
            <SuiInputIconBoxRoot ownerState={{ size }} onClick={iconActive} className="cursor">
              <SuiInputIconRoot fontSize="small" ownerState={{ size }}>
                {icon.component}
              </SuiInputIconRoot>
            </SuiInputIconBoxRoot>
            <SuiInputRoot
              {...rest}
              ownerState={{ size, error, success, iconDirection, direction, disabled }}
            />
          </SuiInputWithIconRoot>
          <FormHelperText className={success ? "component-success-text" : "component-error-text"}>
            {helperText}
          </FormHelperText>
        </FormControl>
      )
    } else if (icon.component && icon.direction === "right") {
      template = (
        <FormControl sx={{ width: "100%" }}>
          <SuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
            <SuiInputRoot
              {...rest}
              ownerState={{ size, error, success, iconDirection, direction, disabled }}
            />
            <SuiInputIconBoxRoot
              ownerState={{ size }}
              onClick={iconActive}
              className="cursor suiInput_icon"
            >
              <SuiInputIconRoot fontSize="small" ownerState={{ size }}>
                {icon.component}
              </SuiInputIconRoot>
            </SuiInputIconBoxRoot>
          </SuiInputWithIconRoot>
          <FormHelperText className={success ? "component-success-text" : "component-error-text"}>
            {helperText}
          </FormHelperText>
        </FormControl>
      )
    } else {
      template = (
        <FormControl sx={{ width: "100%" }}>
          <SuiInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled }} />
          <FormHelperText className={success ? "component-success-text" : "component-error-text"}>
            {helperText}
          </FormHelperText>
        </FormControl>
      )
    }

    return template
  }
)

// Setting default values for the props of SuiInput
SuiInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
  helperText: "",
}

// Typechecking props for the SuiInput
SuiInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  iconActive: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
}

export default SuiInput
