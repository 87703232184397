import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"

import Divider from "@mui/material/Divider"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"

function SuiDialog({
  openDialog,
  closeDialog,
  Active,
  CreBtnText,
  CanBtnText,
  exitBtnText,
  AlertType,
  MainTitle,
  Content,
  CreBtnColor,
  ActiveOneBtn,
  ActiveLeave,
  className,
}) {
  const [open, setOpen] = useState(openDialog)

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const BtnControll = () => {
    if (AlertType !== "oneBtn") {
      if (AlertType === "threeBtn") {
        return (
          <DialogActions sx={{ p: 0 }}>
            <SuiButton
              variant="text"
              color="text2"
              sx={{ mr: "auto", height: 40, minWidth: 48, width: 35, pl: 0, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              {exitBtnText}
            </SuiButton>{" "}
            <SuiButton
              variant="outlined"
              color="error"
              sx={{ ml: "auto", height: 40, minWidth: 48, width: 105, fontSize: 14 }}
              onClick={() => ActiveLeave()}
            >
              {CanBtnText}
            </SuiButton>
            <SuiButton
              color={CreBtnColor}
              onClick={() => Active()}
              sx={{
                fontSize: 14,
                height: 40,
                width: 105,
              }}
            >
              {CreBtnText}
            </SuiButton>
          </DialogActions>
        )
      } else {
        return (
          <DialogActions sx={{ p: 0 }}>
            <SuiButton
              variant="outlined"
              color="text2"
              sx={{ ml: "auto", height: 40, minWidth: 48, width: 105, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              {CanBtnText}
            </SuiButton>
            <SuiButton
              color={CreBtnColor}
              onClick={() => Active()}
              sx={{
                fontSize: 14,
                height: 40,
                width: 105,
              }}
            >
              {CreBtnText}
            </SuiButton>
          </DialogActions>
        )
      }
    } else {
      return (
        <DialogActions>
          {ActiveOneBtn ? (
            <SuiButton
              color="info2"
              sx={{ width: 105, height: 40, fontSize: 14 }}
              onClick={() => Active()}
            >
              {CanBtnText}
            </SuiButton>
          ) : (
            <SuiButton
              color="info2"
              sx={{ width: 105, height: 40, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              {CanBtnText}
            </SuiButton>
          )}
        </DialogActions>
      )
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" className="alert-dialog-layout">
      <SuiBox sx={{ p: 4 }}>
        <SuiBox sx={{ textAlign: "start" }}>
          <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
            {MainTitle}
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ mt: 1.5, mb: 3.75 }} className={className}>
          <SuiTypography fontSize="16px" fontWeight="medium" color="subTitle">
            <span dangerouslySetInnerHTML={{ __html: Content }}></span>
          </SuiTypography>
        </SuiBox>
        {BtnControll()}
      </SuiBox>
    </Dialog>
  )
}

SuiDialog.defaultProps = {
  openDialog: false,
  ActiveOneBtn: false,
  AlertType: "oneBtn",
  exitBtnText: "취소",
  CanBtnText: "확인",
  CreBtnText: "저장",
  CreBtnColor: "primary",
  MainTitle: "제목을 입력해주세요.",
  Content: "내용을 입력해주세요.",
}

SuiDialog.propTypes = {
  openDialog: PropTypes.bool,
  ActiveOneBtn: PropTypes.bool,
  MainTitle: PropTypes.string,
  Content: PropTypes.string,
  AlertType: PropTypes.string,
  CanBtnText: PropTypes.string,
  CreBtnText: PropTypes.string,
  exitBtnText: PropTypes.string,
  CreBtnColor: PropTypes.string,
  closeDialog: PropTypes.func,
  Active: PropTypes.func,
  ActiveLeave: PropTypes.func,
  className: PropTypes.string,
}

export default SuiDialog
