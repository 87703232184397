import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import SuiDialog from "components/SuiDialog"
import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import ContractInfosDialog from "../ContractInfosDialog"

import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

// API
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { createAlert, createChangeRequest, updateWriter } from "apollo/mutation"
import { requests, users_list, me, writer, settings } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

import axios from "axios"

function ContractChangeDialog({ openDialog, closeDialog, closeDialog2, ContarctData }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [step, setstep] = useState(0)
  const { myData } = useSelector((state) => state.store)
  const [workList2, setworkList2] = useState([])
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [ContractInfosDialogOpenBool, setContractInfosDialogOpenBool] = useState(false)
  const [ContractInfosData, setContractInfosData] = useState([])
  const [departUserList, setDepartUserList] = useState([])
  const client = useApolloClient()
  const [changeList, setchangeList] = useState({
    id: null,
    department: "콘텐츠사업본부",
    name: "",
    reason: "퇴사로 인한 계약 이관",
  })
  const [userAdminActive] = useLazyQuery(users_list, {
    fetchPolicy: "network-only",
    variables: {
      sort: "created_at:desc",
      start: 0,
      role: 1,
      status: "근무",
    },
  })

  const infoActive = () => {
    setContractInfosDialogOpenBool(true)
    setContractInfosData(ContarctData.row)
  }
  const handleChange = (e, index) => {
    const changeListData = { ...changeList }
    if (index === 1) {
      user_filter(e.target.value)
      changeListData.department = e.target.value
      changeListData.id = null
      changeListData.name = ""
    } else if (index === 2) {
      changeListData.id = e.target.value.split(" ")[2]
      changeListData.name = e.target.value
    } else if (index === 3) {
      changeListData.reason = e.target.value
    }
    setchangeList(changeListData)
  }
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  useLayoutEffect(() => {
    settings_Call()
  }, [])
  const settings_ref = useRef([])
  const settings_Call = () => {
    settingsActive({
      variables: {
        type: "department",
      },
    }).then((res) => {
      let list = res.data.settings[0].list
      settings_ref.current = list
    })
  }
  const workListSelects = settings_ref.current.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const workListSelects2 = departUserList.map((x, index) => {
    return (
      <MenuItem value={x} key={index}>
        {x.split(" ")[0] + " " + x.split(" ")[1]}
      </MenuItem>
    )
  })
  const workList3 = ["퇴사로 인한 계약 이관", "업무 변경으로 인한 이관"]
  const workListSelects3 = workList3.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const user_filter = (data) => {
    let users = []
    dispatch(loading_Apply(true))

    client
      .query({
        query: users_list,
        variables: {
          status: "근무",
          department: data,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        users = res.data.users.map((x) => x.name + " " + x.position + " " + x.id)
        setDepartUserList(users)
        dispatch(loading_Apply(false))
      })
  }

  useEffect(() => {
    setOpen(openDialog)
    setstep(0)
    if (openDialog) {
      user_filter("콘텐츠사업본부")
    }
  }, [openDialog, ContarctData])

  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateWriterActive] = useMutation(updateWriter, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("작가수정 오류 발생")
    },
    refetchQueries: [writer],
  })

  const change_data = () => {
    dispatch(loading_Apply(true))
    const content = {
      request: {
        id: myData.id,
        department: myData.department,
        name: myData.name,
        position: myData.position,
        profile: myData.profile ? myData.profile.url : null,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
      },
      companiesIds: [],
      contractsIds: [],
      writersIds: [ContarctData[0].id],
      writersContractId: {
        id: ContarctData[0].id,
        user: ContarctData[0].user.id,
        writerId: ContarctData.DetailData.id,
        users: ContarctData.DetailData.users.filter((x) => x.id !== ContarctData[0].user.id),
      },
      selectData: ContarctData[0].selectData[1].value,
      targetName: ContarctData.targetName,
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
    }
    const body = {
      type: "Writer",
      status: "신청",
      contractId: ContarctData[0].id,
      arrayContent: content,
      users_permissions_user: myData.id,
      writer: ContarctData.DetailData.id,
    }
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("joara_token"),
        },
      })
      .then(() => {
        userAdminActive().then((res) => {
          res.data.users.forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[1].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "작가 계약 이관 신청",
                    type: "Change",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      profile: myData.profile,
                      title: ContarctData.DetailData.ebookname,
                      selectData: ContarctData[0].selectData[1].value,
                      content: content,
                      DetailData: ContarctData.DetailData,
                      targetName: ContarctData.targetName,
                    },
                  },
                })
              }
            }
          })
        })

        const editHistory = [...ContarctData.DetailData.history]
        let historyContent = `${ContarctData[0].selectData[1].value}`

        //  ${ContarctData.row.length > 1 ? "외 " + ContarctData.row.length + "건" : ""}

        let variablesData = {
          id: ContarctData.DetailData.id,
          history: editHistory,
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>" + historyContent + "</strong>의 담당자 이관을 신청하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          type: "contract-change",
          contractId: ContarctData[0].id,
        })

        updateWriterActive({
          variables: variablesData,
        }).then(() => {})

        dispatch(loading_Apply(false))
      })
  }
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }

  const backStep = () => {
    if (step === 0) {
      closeDialog()
      setOpen(false)
    } else {
      setstep(step - 1)
    }
  }
  const stepActive = () => {
    if (step === 2) {
      setopenInfo_Dialog(true)
      change_data()
    } else {
      setstep(step + 1)
    }
  }
  const Active = () => {
    closeDialog2()
    setopenInfo_Dialog(false)
    setOpen(false)
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                계약 이관 신청
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                {step === 0 ? "현재 계약 담당자 정보를 확인하고 다음으로 넘어갑니다." : ""}
                {step === 1 ? "변경하실 계약 담당자 정보를 입력하고 다음으로 넘어갑니다." : ""}
                {step === 2 ? "마지막으로 이관 신청 정보를 확인합니다." : ""}
              </SuiTypography>
            </SuiBox>
            {step === 0 && ContarctData[0].user ? (
              <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap", mx: 4 }}>
                <SuiBox sx={{ width: "100%" }}>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      mb: 2,
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, color: "#535353" }}
                      >
                        담당부서
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ mr: "auto", color: "#535353" }}
                      >
                        {ContarctData[0].user.department}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, color: "#535353" }}
                      >
                        담당자
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ mr: "auto", color: "#535353" }}
                      >
                        {ContarctData[0].user.name} {ContarctData[0].user.position}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </SuiBox>
                <Card
                  sx={{
                    mt: 2,
                    border: 1,
                    borderColor: "#F1F1F5",
                    boxShadow: 0,
                    backgroundColor: "#F7F9FB",
                    width: "100%",
                  }}
                >
                  <SuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", height: 136 }}
                  >
                    <SuiTypography
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ ml: 4, color: "#535353" }}
                    >
                      이관
                      <br />
                      계약
                    </SuiTypography>
                    <Divider
                      orientation="vertical"
                      sx={{ borderColor: "#EEEEEE", ml: 6, mr: "auto" }}
                    />
                    <SuiTypography
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{
                        mr: "auto",
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#0C65FF",
                      }}
                      onClick={() => infoActive()}
                    >
                      {ContarctData[0].selectData[1].value}{" "}
                      {ContarctData.row.length > 1 ? "외" + ContarctData.row.length + "건" : ""}
                    </SuiTypography>
                  </SuiBox>
                </Card>
              </SuiBox>
            ) : (
              ""
            )}
            {step === 1 ? (
              <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap", px: 4 }}>
                <SuiBox sx={{ width: "100%" }}>
                  <Card
                    sx={{
                      pl: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      mb: 2,
                      borderRadius: "1rem 0.7rem 0.7rem 1rem",
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                      >
                        담당부서
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                      />
                      <SuiBox sx={{ width: "100%", height: "auto" }}>
                        <FormControl
                          fullWidth
                          sx={{ minHeight: 30 }}
                          className={
                            changeList.department === ""
                              ? "setting-selectbox select-none-active"
                              : "setting-selectbox"
                          }
                          onClick={() => setFiltersOpen1(!FiltersOpen1)}
                        >
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                fontSize="small"
                                color="black"
                                className="select-icon cursor"
                                onClick={() => setFiltersOpen1(!FiltersOpen1)}
                              />
                            )}
                            open={FiltersOpen1}
                            value={changeList.department}
                            onChange={(e) => handleChange(e, 1)}
                            className="change-select-radius"
                          >
                            {workListSelects}
                          </Select>
                        </FormControl>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                  <Card
                    sx={{
                      pl: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      borderRadius: "1rem 0.7rem 0.7rem 1rem",
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                      >
                        담당자
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                      />
                      <SuiBox sx={{ width: "100%", height: "auto" }}>
                        <FormControl
                          fullWidth
                          sx={{ minHeight: 30 }}
                          className={
                            changeList.name === ""
                              ? "setting-selectbox select-none-active"
                              : "setting-selectbox"
                          }
                          onClick={() => setFiltersOpen2(!FiltersOpen2)}
                        >
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                fontSize="small"
                                color="black"
                                className="select-icon cursor"
                                onClick={() => setFiltersOpen2(!FiltersOpen2)}
                              />
                            )}
                            open={FiltersOpen2}
                            value={changeList.name}
                            onChange={(e) => handleChange(e, 2)}
                            className="change-select-radius"
                          >
                            {workListSelects2}
                          </Select>
                        </FormControl>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </SuiBox>
                <Card
                  sx={{
                    mt: 2,
                    border: 1,
                    borderColor: "#F1F1F5",
                    boxShadow: 0,
                    backgroundColor: "#F7F9FB",
                    width: "100%",
                    borderRadius: "1rem 0.7rem 0.7rem 1rem",
                  }}
                >
                  <SuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", height: "auto" }}
                  >
                    <SuiTypography
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ ml: 4, whiteSpace: "nowrap", color: "#535353" }}
                    >
                      이관사유
                    </SuiTypography>
                    <Divider
                      orientation="vertical"
                      sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                    />
                    <SuiBox sx={{ width: "100%", height: "auto" }}>
                      <FormControl
                        fullWidth
                        sx={{ minHeight: 30 }}
                        className={
                          changeList.reason === ""
                            ? "setting-selectbox select-none-active"
                            : "setting-selectbox"
                        }
                        onClick={() => setFiltersOpen3(!FiltersOpen3)}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              color="black"
                              className="select-icon cursor"
                              onClick={() => setFiltersOpen3(!FiltersOpen3)}
                            />
                          )}
                          open={FiltersOpen3}
                          value={changeList.reason}
                          onChange={(e) => handleChange(e, 3)}
                          className="change-select-radius"
                        >
                          {workListSelects3}
                        </Select>
                      </FormControl>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </SuiBox>
            ) : (
              ""
            )}
            {step === 2 ? (
              <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap", mx: 4 }}>
                <SuiBox sx={{ width: "100%" }}>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      mb: 2,
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, color: "#535353" }}
                      >
                        이관 계약
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        onClick={() => infoActive()}
                        sx={{
                          mr: "auto",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#0C65FF",
                        }}
                      >
                        {ContarctData[0].selectData[1].value}{" "}
                        {ContarctData.row.length > 1 ? "외" + ContarctData.row.length + "건" : ""}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      mb: 2,
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, color: "#535353" }}
                      >
                        담당자
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ mr: "auto", color: "#535353" }}
                      >
                        {ContarctData[0].user.name} {ContarctData[0].user.position} →{" "}
                        {changeList.name.split(" ")[0] + " " + changeList.name.split(" ")[1]}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                      mb: 2,
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 4, color: "#535353" }}
                      >
                        사유
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ mr: "auto", color: "#535353" }}
                      >
                        {changeList.reason}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                  <Card
                    sx={{
                      px: 2,
                      border: 1,
                      borderColor: "#F1F1F5",
                      boxShadow: 0,
                      backgroundColor: "#F7F9FB",
                    }}
                  >
                    <SuiBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: 60 }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ ml: 2, color: "#535353" }}
                      >
                        신청일
                      </SuiTypography>
                      <Divider
                        orientation="vertical"
                        sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                      />
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ mr: "auto", color: "#535353" }}
                      >
                        {dayjs(new Date()).format("YY.MM.DD")}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </SuiBox>
              </SuiBox>
            ) : (
              ""
            )}
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ my: 4, px: 4 }}
          >
            {step > 0 ? (
              <SuiButton
                color="subTitle"
                variant="text"
                onClick={() => backStep()}
                className="back-btn"
                style={{ cursor: "pointer" }}
                sx={{ mr: "auto", fontSize: 16 }}
              >
                뒤로
              </SuiButton>
            ) : (
              ""
            )}

            <SuiButton
              color="primary"
              onClick={() => stepActive()}
              sx={{
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                ml: step === 0 ? "auto" : "",
              }}
              disabled={step === 1 && changeList.name === ""}
            >
              {step === 2 ? "완료" : "다음으로"}
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      <ContractInfosDialog
        openDialog={ContractInfosDialogOpenBool}
        closeDialog={() => setContractInfosDialogOpenBool(false)}
        ContarctData={ContractInfosData}
      />
      <SuiDialog
        openDialog={openInfo_Dialog}
        closeDialog={() => setopenInfo_Dialog(false)}
        MainTitle={"계약 이관 신청 완료"}
        Content={`계약 이관 신청<strong>(${dayjs(new Date()).format(
          "YY.MM.DD"
        )})</strong>이 완료되었습니다. 관리자 승인시 계약 이관이 완료됩니다.`}
        ActiveOneBtn={true}
        Active={() => Active()}
        CanBtnText={"확인"}
        AlertType={"oneBtn"}
        CreBtnColor={"primary"}
      />
    </Dialog>
  )
}

ContractChangeDialog.defaultProps = {
  openDialog: false,
}

ContractChangeDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  ContarctData: PropTypes.array,
}
export default ContractChangeDialog
