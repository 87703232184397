import React, { useState, useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { useApolloClient } from "@apollo/client"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import dayjs from "dayjs"
import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { AiOutlineSearch } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"

import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import {} from "apollo/mutation"

function previewDialog({ openDialog, closeDialog, emailContent }) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const imgPathUrl = process.env.REACT_APP_BACKEND_URL
  const [preview_content, set_preview_content] = useState("")
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [["bold", { color: [] }, "italic", "link", "image"]],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
        },
      },
    }
  }, [])
  const formats = ["bold", "color", "italic", "link", "image", "alt", "width", "height", "style"]
  const quillRef = useRef(null)
  useEffect(() => {
    if (openDialog) {
      let preview_sender
      let body_replace
      if (emailContent.type === "add") {
        preview_sender = emailContent.list[4].data[emailContent.index]
        body_replace = JSON.parse(JSON.stringify(emailContent.list[5].value))
        body_replace = body_replace.replaceAll("발송인 명", emailContent.list[3].value)
        body_replace = body_replace.replaceAll("작가 본명", preview_sender.real_name)
        body_replace = body_replace.replaceAll("e북필명", preview_sender.ebook)
        body_replace = body_replace.replaceAll("작가 조아라필명", preview_sender.joara_name)
        body_replace = body_replace.replaceAll("작품 명", preview_sender.title)

        let replace_str = ""
        if (preview_sender.img && preview_sender.img.length > 0) {
          for (let j = 0; j < preview_sender.img.length; j++) {
            if (preview_sender.img[j].file[2].value) {
              replace_str =
                replace_str +
                `<p><br></p><p>[${preview_sender.img[j].file[1].value}]</p><p><a href="${
                  preview_sender.img[j].file[2].value
                }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + preview_sender.img[j].file[0].url
                }" width="${preview_sender.img[j].file[0].size.value1}px" height="${
                  preview_sender.img[j].file[0].size.value2
                }px"></a></p><p><br></p>`
            } else {
              replace_str =
                replace_str +
                `<p><br></p><p>[${
                  preview_sender.img[j].file[1].value
                }]</p><p><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + preview_sender.img[j].file[0].url
                }" width="${preview_sender.img[j].file[0].size.value1}px" height="${
                  preview_sender.img[j].file[0].size.value2
                }px"></p><p><br></p>`
            }
          }
        }

        body_replace = body_replace.replaceAll(
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
          replace_str
        )
        set_preview_content(body_replace)
      } else {
        preview_sender = emailContent.list.data[4].data[emailContent.index]

        body_replace = JSON.parse(JSON.stringify(emailContent.list.data[5].value))
        body_replace = body_replace.replaceAll("발송인 명", emailContent.list.data[3].value)
        body_replace = body_replace.replaceAll("작가 본명", preview_sender.real_name)
        body_replace = body_replace.replaceAll("이북 필명", preview_sender.ebook)
        body_replace = body_replace.replaceAll("조아라 필명", preview_sender.joara_name)
        body_replace = body_replace.replaceAll("작품 명", preview_sender.title)

        let replace_str = ""
        if (preview_sender.img && preview_sender.img.length > 0) {
          for (let j = 0; j < preview_sender.img.length; j++) {
            if (preview_sender.img[j].file[2].value) {
              replace_str =
                replace_str +
                `<p><br></p><p>[${preview_sender.img[j].file[1].value}]</p><p><a href="${
                  preview_sender.img[j].file[2].value
                }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + preview_sender.img[j].file[0].url
                }" width="${preview_sender.img[j].file[0].size.value1}px" height="${
                  preview_sender.img[j].file[0].size.value2
                }px"></a></p><p><br></p>`
            } else {
              replace_str =
                replace_str +
                `<p><br></p><p>[${
                  preview_sender.img[j].file[1].value
                }]</p><p><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + preview_sender.img[j].file[0].url
                }" width="${preview_sender.img[j].file[0].size.value1}px" height="${
                  preview_sender.img[j].file[0].size.value2
                }px"></p><p><br></p>`
            }
          }
        }
        body_replace = body_replace.replaceAll(
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
          replace_str
        )
        set_preview_content(body_replace)
      }
    }
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
  }
  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ width: 700, p: 2 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              이메일 미리보기
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <div
              style={{
                backgroundColor: "#f7f9fb",
                fontSize: "14px",
                textAlign: "start",
                padding: "12px",
                borderRadius: "15px",
              }}
              dangerouslySetInnerHTML={{ __html: preview_content }}
            ></div>
          </SuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
        <SuiBox display="flex" height="100%" sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}>
          <SuiButton
            color="primary"
            sx={{
              ml: "auto",
              mr: 1,
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              width: 100,
              fontSize: 14,
              mb: 1,
              border: 1,
              borderColor: "#707070",
            }}
            onClick={() => {
              handleClose()
            }}
          >
            확인
          </SuiButton>
        </SuiBox>
      </Grid>
    </Dialog>
  )
}
previewDialog.defaultProps = {
  openDialog: false,
}

previewDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  emailContent: PropTypes.array,
}
export default previewDialog
