import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SvgIcon from "@mui/material/SvgIcon"
import TreeView from "@mui/lab/TreeView"
import { alpha, styled } from "@mui/material/styles"
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem"
import Collapse from "@mui/material/Collapse"
import { useSpring, animated } from "react-spring"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import SuiDialog from "components/SuiDialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import { useQuery, useApolloClient } from "@apollo/client"
import { usersQuery } from "apollo/query"
import { FiSearch } from "react-icons/fi"
import { GrCheckmark } from "react-icons/gr"
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa"
import { AiFillCloseCircle } from "react-icons/ai"
//아이콘
import { MdClose } from "react-icons/md"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../../../reducers/store"

function MinusSquare(props) {
  return (
    <SvgIcon color="info2" fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <FaMinusSquare size="24" />
    </SvgIcon>
  )
}

function PlusSquare(props) {
  return (
    <SvgIcon color="info2" fontSize="inherit" style={{ width: 20, height: 20, p: 0 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <FaPlusSquare size="24" />
    </SvgIcon>
  )
}
function CloseSquare(props) {
  return (
    <SvgIcon
      color="info2"
      className="close"
      fontSize="inherit"
      style={{ width: 18, height: 18 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  )
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  })

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  )
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
}

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px solid #CED4D7`,
  },
}))

function referrerDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  referrerIds,
  referrerIdsUpdate,
  department_list,
}) {
  const dispatch = useDispatch()
  const [usersData, setUsersData] = useState([])
  const [usersFilterData, setUsersFilterData] = useState([])
  const [open, setOpen] = useState(openDialog)
  const [searchName, setSearchName] = useState("")
  const [searchActive, setsearchActive] = useState(false)
  const [expanded, setexpanded] = React.useState([])
  const [referrer, setreferrer] = useState(referrerIdsUpdate)
  const [copyreferrer, setcopyreferrer] = useState([])
  const [referrerCheck, setreferrerCheck] = useState([])
  const { myData } = useSelector((state) => state.store)
  const client = useApolloClient()
  useEffect(() => {
    setOpen(openDialog)
    if (openDialog) {
      if (usersData.length === 0) {
        dispatch(loading_Apply(true))
      }
      client
        .query({
          query: usersQuery,
          variables: {
            sort: "created_at:desc",
            status: "근무",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          dispatch(loading_Apply(false))
          setUsersData(res.data.users)
        })
    }
    let copy = JSON.parse(JSON.stringify(referrer))
    setcopyreferrer(copy)
  }, [openDialog])
  useEffect(() => {
    setreferrer(referrerIdsUpdate)
  }, [referrerIdsUpdate])
  const teamList =
    department_list && department_list.department
      ? department_list.department
      : [
          "경영지원실",
          "콘텐츠사업본부",
          "전략기획팀",
          "플랫폼사업팀",
          "IP사업팀",
          "인터랙션디자인팀",
          "서비스개발팀",
          "서비스운영팀",
        ]

  const handleDelete = (e) => {
    const array = [...referrer]
    const index = array.map((x) => x.id).indexOf(e.id)
    array.splice(index, 1)
    setreferrer(array)
  }
  const handleReferrer = (user, item, item2) => {
    if (myData.name !== user.name) {
      if (referrer.map((x) => x.id).indexOf(user.id) > -1) {
        setreferrer([...referrer.filter((y) => y.id !== user.id)])
        const checktag = document.getElementById(item2)
        if (checktag) {
          checktag.children[0].children[1].children[0].children[0].style.color = ""
          checktag.children[0].children[1].children[0].children[1].style.display = "none"
        }
      } else {
        setreferrer([
          ...referrer,
          {
            id: user.id,
            name: user.name + " " + user.position,
            position: user.position,
            department: user.department,
            profile: user.profile,
            nomalAlertSetting: user.nomalAlertSetting,
            adminAlertSetting: user.adminAlertSetting,
          },
        ])

        function isdepartment(element) {
          if (element.department === item) {
            return true
          }
        }
        const a = usersData.filter(isdepartment)
        const b = referrer.filter(isdepartment)
        if (a.length === b.length + 1) {
          const checktag = document.getElementById(item2)
          checktag.children[0].children[1].children[0].children[0].style.color =
            "rgba(111,79,255,1)"
          checktag.children[0].children[1].children[0].children[1].style.display = "inline"
        }
      }
    }
  }
  const isAll = (item) => {
    function isdepartment(element) {
      if (element.department === item) {
        return true
      }
    }
    const a = usersData.filter(isdepartment)
    const b = referrer.filter(isdepartment)
    if (a.length === b.length && a.length) {
      return true
    } else {
      return false
    }
  }
  const renderLabel = (item) => {
    return (
      <div>
        <span
          className="colorPick"
          onClick={(event) => {
            setreferrer([...referrer])

            function isdepartment(element) {
              if (element.department === item) {
                return true
              }
            }
            const a = usersData.filter(isdepartment)
            const b = referrer.filter(isdepartment)
            const checktag = document.getElementById(item)
            if (a.length === b.length) {
              let refererData = JSON.parse(JSON.stringify(referrer))
              let x = []
              for (var i = 0; i < refererData.length; i++) {
                if (refererData[i].id === myData.id) {
                  x.push(refererData[i])
                } else {
                  if (refererData[i].department !== item) {
                    x.push(refererData[i])
                  }
                }
              }
              setreferrer(x)
              event.target.style.color = ""
              checktag.style.display = "none"
            } else {
              if (myData.department === item) {
                let num
                if (referrer.findIndex((e) => e.id === myData.id) === -1) {
                  num = 1
                } else {
                  num = 0
                }
                if (a.length - num === b.length) {
                  let refererData = JSON.parse(JSON.stringify(referrer))
                  let x = []
                  for (var i = 0; i < refererData.length; i++) {
                    if (refererData[i].id === myData.id) {
                      x.push(refererData[i])
                    } else {
                      if (refererData[i].department !== item) {
                        x.push(refererData[i])
                      }
                    }
                  }
                  setreferrer(x)
                  if (!num) {
                    checktag.style.display = "inline"
                    event.target.style.color = "rgba(111,79,255,1)"
                  }
                } else {
                  let refererData = JSON.parse(JSON.stringify(referrer))

                  for (var i = 0; i < a.length; i++) {
                    if (
                      a[i].department === item &&
                      referrer.findIndex((e) => e.id === a[i].id) === -1
                    ) {
                      if (a[i].id !== myData.id) {
                        refererData.push({
                          id: a[i].id,
                          name: a[i].name + " " + a[i].position,
                          position: a[i].position,
                          department: a[i].department,
                          profile: a[i].profile,
                          nomalAlertSetting: a[i].nomalAlertSetting,
                          adminAlertSetting: a[i].adminAlertSetting,
                        })
                      }
                    }
                  }

                  setreferrer(refererData)
                  if (myData.department !== item) {
                    checktag.style.display = "inline"
                    event.target.style.color = "rgba(111,79,255,1)"
                  }
                  if (!num) {
                    checktag.style.display = "inline"
                    event.target.style.color = "rgba(111,79,255,1)"
                  }
                }
              } else {
                let refererData = JSON.parse(JSON.stringify(referrer))

                for (var i = 0; i < a.length; i++) {
                  if (
                    a[i].department === item &&
                    referrer.findIndex((e) => e.id === a[i].id) === -1
                  ) {
                    if (a[i].id !== myData.id) {
                      refererData.push({
                        id: a[i].id,
                        name: a[i].name + " " + a[i].position,
                        position: a[i].position,
                        department: a[i].department,
                        profile: a[i].profile,
                        nomalAlertSetting: a[i].nomalAlertSetting,
                        adminAlertSetting: a[i].adminAlertSetting,
                      })
                    }
                  }
                }

                setreferrer(refererData)
                if (myData.department !== item) {
                  checktag.style.display = "inline"
                  event.target.style.color = "rgba(111,79,255,1)"
                }
              }
            }
            event.stopPropagation()
            event.preventDefault()
          }}
          style={{
            color: isAll(item) ? "rgba(111,79,255,1)" : "",
          }}
        >
          {item}
        </span>
        <IconButton
          id={item}
          color="primary"
          size="small"
          className="icon-primary"
          sx={{
            pl: 1,
            pt: 0,
            pb: 0,
            display: isAll(item) ? "" : "none",
          }}
        >
          <GrCheckmark size="14" />
        </IconButton>
      </div>
    )
  }

  // referrer
  const TreeItem = teamList.map((x, index) => {
    return (
      <StyledTreeItem
        nodeId={String(index)}
        label={renderLabel(x)}
        key={index}
        id={String(index)}
        className="TreeItem-style"
      >
        {usersData.filter((user) => user.department === x).length > 0
          ? usersData.map((user, userIndex) => {
              if (user.department === x) {
                return (
                  <SuiBox
                    key={userIndex}
                    display="flex"
                    alignItems="center"
                    sx={{ borderLeft: "1px solid #CED4D7" }}
                  >
                    <Divider sx={{ width: 7 }} />
                    <SuiTypography
                      className={myData.name === user.name ? "" : "cursor"}
                      sx={{ py: 0.5 }}
                      color={
                        myData.name === user.name
                          ? "light2"
                          : referrer.map((x) => x.id).indexOf(user.id) > -1
                          ? "primary"
                          : "dark"
                      }
                      style={{ fontSize: "14px", fontWeight: "medium" }}
                      onClick={() => handleReferrer(user, x, String(index))}
                    >
                      {user.name} {user.position}
                    </SuiTypography>
                  </SuiBox>
                )
              }
            })
          : ""}
      </StyledTreeItem>
    )
  })
  const handleClose = (complete) => {
    closeDialog()
    setsearchActive(false)
    setOpen(false)
    if (complete) {
      referrerIds(referrer)
      closeDialog2()
    } else {
      referrerIds(copyreferrer)
    }
  }

  const handleSearchName = (event) => {
    setSearchName(event.target.value)
  }
  const userSearch = () => {
    if (searchName !== "") {
      setsearchActive(true)
      setUsersFilterData(usersData.filter((x) => x.name.indexOf(searchName) > -1))
    } else {
      setsearchActive(false)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchName !== "") {
        userSearch()
      } else {
        setsearchActive(false)
      }
    }
  }
  const handleChange = (event, nodes) => {
    setexpanded(nodes)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Card sx={{ p: 5, width: 480 }}>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox display="flex" alignItems="center" justifyContent="end" sx={{ pt: 1 }}>
              <IconButton
                edge="start"
                color="subTitle"
                size="small"
                onClick={() => handleClose()}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                <MdClose />
              </IconButton>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                참조자 등록{searchActive ? ":검색" : ""}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                이름을 검색하고, 참조자로 등록합니다.
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12}>
            <SuiBox display="flex" fullWidth>
              <SuiBox fullWidth sx={{ mr: 2, width: "100%" }}>
                <SuiInput
                  type="text"
                  placeholder="이름을 입력해주세요."
                  value={searchName}
                  onChange={handleSearchName}
                  onKeyPress={handleKeyPress}
                />
              </SuiBox>
              <SuiButton
                sx={{ height: 40, width: 40, minWidth: 40, p: 1 }}
                color="info2"
                onClick={() => userSearch()}
              >
                <FiSearch size="30" />
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ pt: "12px", overflow: "auto" }}>
              {referrer.map((x, index) => {
                return (
                  <Chip
                    key={index}
                    label={x.name}
                    onDelete={() => handleDelete(x)}
                    deleteIcon={<AiFillCloseCircle color="#535353" />}
                  />
                )
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, display: searchActive ? "" : "none" }}>
            <SuiTypography style={{ color: "#6B6B6B", fontSize: "14px", fontWeight: "medium" }}>
              검색결과 : <strong style={{ color: "#000000" }}>{usersFilterData.length}</strong>명의
              사용자를 찾았습니다.
            </SuiTypography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, display: searchActive ? "" : "none" }}>
            <SuiBox
              height="340px"
              sx={{
                mt: 2,
                overflowY: "auto",
              }}
            >
              {usersFilterData.map((user, index) => {
                return (
                  <SuiBox
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    sx={{ borderTop: 1, borderColor: "#CED4D7" }}
                  >
                    <SuiTypography style={{ fontSize: "14px", fontWeight: "bold" }} color="dark">
                      {user.name} {user.position}
                    </SuiTypography>
                    <SuiBox sx={{ ml: 2 }}>
                      <SuiTypography
                        style={{ fontSize: "14px", fontWeight: "medium" }}
                        color="secondary"
                      >
                        {user.department}
                      </SuiTypography>
                    </SuiBox>
                    <Checkbox
                      sx={{
                        ml: "auto",
                      }}
                      checked={referrer.map((x) => x.id).indexOf(user.id) > -1}
                      onChange={() => {
                        const userList = [...referrerCheck]
                        userList.push(user.id)
                        setreferrerCheck(userList)
                        handleReferrer(user)
                      }}
                      color="primary"
                    />
                  </SuiBox>
                )
              })}
            </SuiBox>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, display: searchActive ? "none" : "" }}>
            <TreeView
              className="TreeView-style"
              // defaultExpanded={[]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              expanded={expanded}
              onNodeToggle={handleChange}
              sx={{ height: 340, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
            >
              {TreeItem}
            </TreeView>
          </Grid>
          <Grid item xs={12}>
            <SuiBox
              display="flex"
              justifyContent="start"
              height="100%"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              {searchActive ? (
                <SuiButton
                  color="text2"
                  variant="text"
                  onClick={() => {
                    setreferrer(referrer.filter((x) => referrerCheck.indexOf(x.id) === -1))
                    setsearchActive(false)
                  }}
                  className="back-btn"
                  sx={{ mr: "auto" }}
                >
                  뒤로
                </SuiButton>
              ) : (
                ""
              )}
              <SuiButton
                color="primary"
                sx={{
                  ml: "auto",
                  fontSize: 14,
                }}
                disabled={referrer.length === 0}
                onClick={() => handleClose("complete")}
              >
                선택완료
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  )
}

referrerDialog.defaultProps = {
  openDialog: false,
}

referrerDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  referrerIds: PropTypes.func,
  referrerIdsUpdate: PropTypes.array,
  department_list: PropTypes.object,
}
export default referrerDialog
