import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { visuallyHidden } from "@mui/utils"
import Pagination from "@mui/material/Pagination"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import InputLabel from "@mui/material/InputLabel"
import FormControlLabel from "@mui/material/FormControlLabel"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import TextField from "@mui/material/TextField"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import enLocale from "date-fns/locale/en-US"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import SuiTypography from "components/SuiTypography"
import PaginationCustom from "components/PaginationCustom"

import dayjs from "dayjs"
import {
  AiFillCaretDown,
  AiOutlineCheck,
  AiFillCheckCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai"
import { BiRecycle } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import { BsFillCircleFill } from "react-icons/bs"

import bullseye from "assets/images/small-logos/bullseye.svg"

// Dialog
import WriterDialog from "../writer"

// API
import { useQuery, useLazyQuery, useApolloClient, useMutation } from "@apollo/client"
import { contractDocuments, contracts, contractsCount, settings } from "apollo/query"
import { createDonwloadLog } from "apollo/mutation"

import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../reducers/store"

function createData(
  id,
  title,
  type,
  bookcode,
  joaraname,
  bookname,
  name,
  agent,
  contract,
  nobless,
  premium,
  mixnobl,
  ebook,
  webtoon,
  video,
  game,
  integrated,
  coversub,
  seoninse,
  created,
  sign,
  ebooksign,
  serialization,
  writer,
  bullseye,
  etc,
  contractStatus,
  ebookStatus,
  rating,
  publicDate,
  user,
  contract_documents
) {
  return {
    id,
    title,
    type,
    bookcode,
    joaraname,
    bookname,
    name,
    agent,
    contract,
    nobless,
    premium,
    mixnobl,
    ebook,
    webtoon,
    video,
    game,
    integrated,
    coversub,
    seoninse,
    created,
    sign,
    ebooksign,
    serialization,
    writer,
    bullseye,
    etc,
    contractStatus,
    ebookStatus,
    rating,
    publicDate,
    user,
    contract_documents,
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "등록번호",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "작품명",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "장르",
  },
  {
    id: "bookcode",
    numeric: false,
    disablePadding: false,
    label: "북코드",
  },
  {
    id: "bookname",
    numeric: false,
    disablePadding: false,
    label: "이북 필명",
  },
  {
    id: "joaraname",
    numeric: false,
    disablePadding: false,
    label: "조아라 필명",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "본명",
  },
  {
    id: "integrated",
    numeric: false,
    disablePadding: false,
    label: "통합",
  },
  {
    id: "ebook",
    numeric: false,
    disablePadding: false,
    label: "이북",
  },
  {
    id: "contract",
    numeric: false,
    disablePadding: false,
    label: "프리미엄",
  },
  {
    id: "mixnobl",
    numeric: false,
    disablePadding: false,
    label: "노블X프리",
  },
  {
    id: "nobless",
    numeric: false,
    disablePadding: false,
    label: "노블레스",
  },
  {
    id: "premium",
    numeric: false,
    disablePadding: false,
    label: "에이전트",
  },
  {
    id: "agent",
    numeric: false,
    disablePadding: false,
    label: "웹툰",
  },
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "등급",
  },
  // {
  //   id: "ebooksign",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "출간 일자",
  // },
  {
    id: "sign",
    numeric: false,
    disablePadding: false,
    label: "표지",
  },
  {
    id: "serialization",
    numeric: false,
    disablePadding: false,
    label: "조아라 연재",
  },
]

const headCellsLong = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "등록번호",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "작품명",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "장르",
  },
  {
    id: "bookcode",
    numeric: false,
    disablePadding: false,
    label: "북코드",
  },
  {
    id: "bookname",
    numeric: false,
    disablePadding: false,
    label: "이북 필명",
  },
  {
    id: "joaraname",
    numeric: false,
    disablePadding: false,
    label: "조아라 필명",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "본명",
  },
  {
    id: "integrated",
    numeric: false,
    disablePadding: false,
    label: "통합",
  },
  {
    id: "ebook",
    numeric: false,
    disablePadding: false,
    label: "이북",
  },
  {
    id: "contract",
    numeric: false,
    disablePadding: false,
    label: "프리미엄",
  },
  {
    id: "mixnobl",
    numeric: false,
    disablePadding: false,
    label: "노블X프리",
  },
  {
    id: "nobless",
    numeric: false,
    disablePadding: false,
    label: "노블레스",
  },
  {
    id: "premium",
    numeric: false,
    disablePadding: false,
    label: "에이전트",
  },
  {
    id: "agent",
    numeric: false,
    disablePadding: false,
    label: "웹툰",
  },
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "등급",
  },
  // {
  //   id: "ebooksign",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "출간 일자",
  // },
  {
    id: "sign",
    numeric: false,
    disablePadding: false,
    label: "표지",
  },
  {
    id: "serialization",
    numeric: false,
    disablePadding: false,
    label: "조아라 연재",
  },
]
const headCellsExcel = [
  {
    id: "No",
    numeric: false,
    disablePadding: false,
    label: "No.",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "등록번호",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "작품명",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "장르",
  },
  {
    id: "bookcode",
    numeric: false,
    disablePadding: false,
    label: "북코드",
  },
  {
    id: "bookname",
    numeric: false,
    disablePadding: false,
    label: "이북 필명",
  },
  {
    id: "joaraname",
    numeric: false,
    disablePadding: false,
    label: "조아라 필명",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "본명",
  },
  {
    id: "integrated",
    numeric: false,
    disablePadding: false,
    label: "통합 ",
  },
  {
    id: "coversub",
    numeric: false,
    disablePadding: false,
    label: "표지",
  },
  {
    id: "seoninse",
    numeric: false,
    disablePadding: false,
    label: "선인세",
  },
  {
    id: "ebook",
    numeric: false,
    disablePadding: false,
    label: "이북",
  },
  {
    id: "contract",
    numeric: false,
    disablePadding: false,
    label: "프리미엄",
  },
  {
    id: "mixnobl",
    numeric: false,
    disablePadding: false,
    label: "노블X프리",
  },
  {
    id: "nobless",
    numeric: false,
    disablePadding: false,
    label: "노블레스",
  },
  {
    id: "agent",
    numeric: false,
    disablePadding: false,
    label: "에이전트 ",
  },
  {
    id: "exclusive",
    numeric: false,
    disablePadding: false,
    label: "전속계약",
  },
  {
    id: "webtoon",
    numeric: false,
    disablePadding: false,
    label: "웹툰 ",
  },
  {
    id: "video",
    numeric: false,
    disablePadding: false,
    label: "영상",
  },
  {
    id: "game",
    numeric: false,
    disablePadding: false,
    label: "게임",
  },
  {
    id: "manufacture",
    numeric: false,
    disablePadding: false,
    label: "제작",
  },
  {
    id: "etc",
    numeric: false,
    disablePadding: false,
    label: "기타",
  },
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "등급",
  },
  {
    id: "ebooksign",
    numeric: false,
    disablePadding: false,
    label: "출간 일자",
  },
  {
    id: "sign",
    numeric: false,
    disablePadding: false,
    label: "표지",
  },
  {
    id: "serialization",
    numeric: false,
    disablePadding: false,
    label: "조아라 연재",
  },
]
function CheckIcon(status) {
  let check

  if (status.status === "check") {
    check = <AiOutlineCheck size={14}></AiOutlineCheck>
  } else {
    check = <></>
  }

  return check
}

function IconCircle(color) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  let colorCircle
  if (color.color !== "") {
    colorCircle = (
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        color="white"
        classes={{ popper: "tooltip-light" }}
        arrow={false}
        title={
          <SuiBox>
            <SuiTypography style={{ fontSize: "12px", color: "#9AA0A6" }} verticalAlign="middle">
              계약 유효일
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}
              verticalAlign="middle"
            >
              {color.day === 0
                ? "계약종료"
                : color.value === "자동갱신"
                ? color.day + "일"
                : color.day + "일"}
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "12px", color: "#707070", fontWeight: "normal" }}
              verticalAlign="middle"
            >
              {`${color.value === "자동갱신" ? "갱신일" : "종료일"} : ${color.end_date}`}
            </SuiTypography>
          </SuiBox>
        }
      >
        {color.color ? (
          <IconButton color={color.color} sx={{ p: 0 }}>
            {color.value === "자동갱신" && !color.ContractType ? (
              <BiRecycle size={20} />
            ) : (
              <BsFillCircleFill size={10}></BsFillCircleFill>
            )}
          </IconButton>
        ) : (
          <div></div>
        )}
      </Tooltip>
    )
  } else {
    colorCircle = <></>
  }

  return colorCircle
}
function Icondown(headCell) {
  let Icondownarray

  if (headCell.staus.label === "장르" || headCell.staus.label === "이북") {
    Icondownarray = <AiFillCaretDown></AiFillCaretDown>
  } else {
    Icondownarray = <></>
  }
  return Icondownarray
}

function DataOfNobless_gray(stat) {
  let returnData
  if (stat.stat !== "") {
    returnData = <span style={{ color: "gray" }}>{stat.stat}</span>
  } else {
    returnData = <></>
  }

  return returnData
}
function DataOfNobless_red(stat) {
  let returnData
  if (stat.stat !== "") {
    returnData = <span style={{ color: "red" }}>{stat.stat}</span>
  } else {
    returnData = <></>
  }
  return returnData
}
function DataOfNobless_orange(stat) {
  let returnData
  if (stat.stat !== "") {
    returnData = <span style={{ color: "orange" }}>{stat.stat}</span>
  } else {
    returnData = <></>
  }
  return returnData
}

function DataOfNobless_green(stat) {
  let returnData
  returnData = <span style={{ color: "green" }}>{stat.stat}</span>
  return returnData
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    changeFilter,
    changeFilter2,
    changeFilter3,
    showchangeFilter,
    excelTable,
  } = props
  const createSortHandler = (property) => (event) => {
    if (property !== "agent" && property !== "premium" && property !== "integrated") {
      onRequestSort(event, property)
    }
  }
  const [genreChipData, setgenreChipData] = useState([])
  const agentList = ["에이전트", "전속계약"]
  const EbookList = ["웹툰", "영상", "게임", "제작", "기타"]
  const integratedList = ["통합", "표지", "선인세"]
  const [ebookVal, setebookVal] = useState("웹툰")
  const [agentVal, setagentVal] = useState("에이전트")
  const [integrated, setintegrated] = useState("통합")
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [headCelldata, setheadCelldata] = useState(headCells)
  if (!localStorage.getItem("miniSidenav")) {
    localStorage.setItem("miniSidenav", "true")
  }

  useEffect(() => {
    if (showchangeFilter) {
      setebookVal(showchangeFilter)
    }
  }, [showchangeFilter])
  useEffect(() => {
    if (excelTable) {
      setheadCelldata(headCellsExcel)
    } else {
      if (localStorage.getItem("miniSidenav") === "true") {
        setheadCelldata(headCells)
      } else if (localStorage.getItem("miniSidenav") === "false") {
        setheadCelldata(headCellsLong)
      }
    }
  }, [localStorage.getItem("miniSidenav")])
  const integratedListSelects = integratedList.map((x) => {
    return (
      <MenuItem sx={{ minWidth: "0px" }} value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const AgentListSelects = agentList.map((x) => {
    return (
      <MenuItem sx={{ minWidth: "0px" }} value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const EbookListSelects = EbookList.map((x) => {
    return (
      <MenuItem sx={{ minWidth: "0px" }} value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false)

  const handleIntegratedChange = (event) => {
    setintegrated(event.target.value)
    changeFilter3(event.target.value)
  }
  const handleAgentListChange = (event) => {
    setagentVal(event.target.value)
    changeFilter2(event.target.value)
  }
  const handleEbookListChange = (event) => {
    setebookVal(event.target.value)
    changeFilter(event.target.value)
  }
  const [openGenreFilter, setopenGenreFilter] = useState(false)
  const openGenreFilterDialog = () => {
    setopenGenreFilter(!openGenreFilter)
  }
  const genreSave = () => {
    setopenGenreFilter(false)
  }
  const genreChip = (x) => {
    let array = [...genreChipData]
    if (array.indexOf(x) === -1) {
      array.push(x)
    } else {
      array.splice(array.indexOf(x), 1)
    }
    setgenreChipData(array)
  }
  const genreChipsList = [
    "로맨스",
    "판타지",
    "로판",
    "현판",
    "무협",
    "퓨전/현대물",
    "게임",
    "미스터리",
    "역사물/대체역사",
    "패러디",
    "스포츠",
    "BL",
    "라이트노벨",
    "SF",
    "팬픽",
    "GL",
  ]
  const genreChipsListSelects = genreChipsList.map((x, index) => {
    return (
      <Grid
        xs={3}
        item
        key={x}
        sx={{
          pr: index === 3 || index === 7 || index === 11 || index === 15 ? 0 : 1.5,
        }}
      >
        <Chip
          sx={{
            height: 40,
            width: "100%",
            p: 0,
            mt: 1,
            border: genreChipData.indexOf(x) > -1 ? "1px solid #5C38FF" : "none",
            backgroundColor: genreChipData.indexOf(x) > -1 ? "#FAF8FF" : "#F1F1F5",
            fontWeight: genreChipData.indexOf(x) > -1 ? 900 : 500,
          }}
          color={genreChipData.indexOf(x) > -1 ? "primary" : "secondary"}
          variant="outlined"
          className="oneSelect-filter-chips"
          label={x}
          onClick={() => genreChip(x)}
        ></Chip>
      </Grid>
    )
  })
  return (
    <TableHead>
      <TableRow>
        {/* 테이블에 전체 열 삭제 작업 완료 20221211 - 박경실 */}
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderRight:
                headCell.label === "본명" ||
                (localStorage.getItem("miniSidenav") === false
                  ? headCell.label === "기타"
                  : headCell.label === "웹툰")
                  ? "1px solid #F1F1F5"
                  : "",
              width:
                headCell.label === "에이전트" ||
                headCell.label === "전속계약" ||
                headCell.label === "노블레스" ||
                headCell.label === "프리미엄" ||
                headCell.label === "노블X프리"
                  ? 90
                  : headCell.label === "이북" ||
                    headCell.label === "웹툰" ||
                    headCell.label === "영상" ||
                    headCell.label === "게임" ||
                    headCell.label === "기타"
                  ? 70
                  : "auto",
              paddingRight: headCell.label === "웹툰" ? "20px" : "8px",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="table-head-sortLabel"
            >
              {
                headCell.label === "웹툰" ? (
                  <SuiBox
                    width="55px"
                    className="setting-head-selectbox"
                    display="flex"
                    alignItems="center"
                  >
                    <FormControl
                      sx={{ fontSize: 29 }}
                      fullWidth
                      onClick={() => setFiltersOpen2(!FiltersOpen2)}
                    >
                      <Select
                        IconComponent={() => (
                          <ArrowDropDownIcon
                            fontSize="medium"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen2(!FiltersOpen2)}
                          />
                        )}
                        open={FiltersOpen2}
                        value={ebookVal}
                        onChange={handleEbookListChange}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        style={{ fontSize: 30 }}
                      >
                        {EbookListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : headCell.label === "에이전트" ? (
                  <SuiBox
                    width="70px"
                    className="setting-head-selectbox"
                    display="flex"
                    alignItems="center"
                  >
                    <FormControl
                      sx={{ fontSize: 29 }}
                      fullWidth
                      onClick={() => setFiltersOpen5(!FiltersOpen5)}
                    >
                      <Select
                        IconComponent={() => (
                          <ArrowDropDownIcon
                            fontSize="medium"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen5(!FiltersOpen5)}
                          />
                        )}
                        open={FiltersOpen5}
                        value={agentVal}
                        onChange={handleAgentListChange}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        style={{ fontSize: 30 }}
                      >
                        {AgentListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : headCell.label === "통합" ? (
                  <SuiBox
                    width="70px"
                    className="setting-head-selectbox"
                    display="flex"
                    alignItems="center"
                  >
                    <FormControl
                      sx={{ fontSize: 29 }}
                      fullWidth
                      onClick={() => setFiltersOpen6(!FiltersOpen6)}
                    >
                      <Select
                        IconComponent={() => (
                          <ArrowDropDownIcon
                            fontSize="medium"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen6(!FiltersOpen6)}
                          />
                        )}
                        open={FiltersOpen6}
                        value={integrated}
                        onChange={handleIntegratedChange}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        style={{ fontSize: 30 }}
                      >
                        {integratedListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                ) : (
                  // headCell.label === "" ? (
                  //   <div>
                  //     <SuiBox
                  //       width="55px"
                  //       className="setting-head-selectbox"
                  //       display="flex"
                  //       alignItems="center"
                  //       color={openGenreFilter ? "primary" : ""}
                  //       onClick={openGenreFilterDialog}
                  //     >
                  //       장르
                  //       <ArrowDropDownIcon
                  //         color={openGenreFilter ? "primary" : ""}
                  //         fontSize="medium"
                  //         className="select-icon cursor"
                  //       />
                  //     </SuiBox>
                  //     <SuiBox
                  //       display="flex"
                  //       alignItems="center"
                  //       sx={{
                  //         display: openGenreFilter ? "" : "none",
                  //         position: "absolute",
                  //         zIndex: 5,
                  //         backgroundColor: "#ffffff",
                  //         p: 2,
                  //         top: "32px",
                  //         left: "-172px",
                  //         width: 432,
                  //         border: 1,
                  //         boxShadow: 2,
                  //         borderColor: "#d2d6da",
                  //         borderRadius: "10px",
                  //       }}
                  //     >
                  //       <Grid container>
                  //         <Grid item xs={12}>
                  //           <SuiBox display="flex" alignItems="center">
                  //             <SuiTypography
                  //               style={{
                  //                 fontSize: "14px",
                  //                 fontWeight: "bold",
                  //                 cursor: "pointer",
                  //               }}
                  //               color="primary"
                  //               onClick={() => setgenreChipData(genreChipsList)}
                  //             >
                  //               전체선택
                  //             </SuiTypography>
                  //             <SuiBox
                  //               ml={0.5}
                  //               pt={0.6}
                  //               component="img"
                  //               src="/reload.png"
                  //               alt="Soft UI Logo"
                  //               height="36px"
                  //               className="cursor"
                  //               onClick={() => setgenreChipData([])}
                  //             />
                  //             <SuiButton
                  //               sx={{
                  //                 width: 10,
                  //                 minWidth: 25,
                  //                 height: 20,
                  //                 ml: "auto",
                  //                 p: 0,
                  //               }}
                  //               variant="text"
                  //               color="info2"
                  //               size="small"
                  //               onClick={() => genreSave()}
                  //             >
                  //               완료
                  //             </SuiButton>
                  //           </SuiBox>
                  //         </Grid>
                  //         <Grid item xs={12} sx={{}}>
                  //           <SuiBox
                  //             display="flex"
                  //             justifyContent="start"
                  //             height="100%"
                  //             alignItems="center"
                  //           >
                  //             <Grid container>{genreChipsListSelects}</Grid>
                  //           </SuiBox>
                  //         </Grid>
                  //       </Grid>
                  //     </SuiBox>
                  //   </div>
                  // ) : (
                  <span>{headCell.label}</span>
                )
                // )
              }

              {/* <Icondown staus={headCell}></Icondown> */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: null,
  excelTable: false,
}
EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  // rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  changeFilter2: PropTypes.func,
  changeFilter3: PropTypes.func,
  showchangeFilter: PropTypes.string,
  excelTable: PropTypes.bool,
}

export default function EnhancedTable() {
  //filter-start
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const client = useApolloClient()
  const [rows, setrows] = useState([])
  const [rowsFilter, setrowsFilter] = useState([])
  const [filterChipData, setfilterChipData] = useState([])
  const [openGenreFilter, setopenGenreFilter] = useState(false)
  const [genreChipData, setgenreChipData] = useState([])
  const [openFilter, setopenFilter] = useState(false)
  const [writerDialog, setwriterDialog] = useState(false)
  const [editId, seteditId] = useState(null)
  const [editData, seteditData] = useState({ writername: "" })
  const [type, settype] = useState("create")
  const [tabActive, settabActive] = useState(0)
  const [changeFilter3, setchangeFilter3] = useState("통합")
  const [changeFilter2, setchangeFilter2] = useState("에이전트")
  const [changeFilter, setchangeFilter] = useState("웹툰")
  const [rows_hide, setrows_hide] = useState([])
  const { myData } = useSelector((state) => state.store)
  const [FiltersOpen10, setFiltersOpen10] = useState(false) // 엑셀 다운로드 형식
  const [eventExcel, seteventExcel] = useState("현재 페이지")
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [InfoAlert_Acess, setInfoAlert_Acess] = useState(InfoAlertSet)
  const closeDialog = () => {
    setwriterDialog(false)
  }
  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
  let orFilterData = {
    department: "", // 담당부서
    title: "", // 작품명
    genre: "", // 장르선택
    bookcode: "", // 북코드 입력
    agent: "", // 에이전트
    contract: "", // 전속계약
    nobless: "", // 노블레스
    premium: "", // 프리미엄
    noblepre: "", // 노블X프리
    ebook: "", // 이북
    cover: false, // (플)표지
    ecover: "", // 이북표지
    contractDateAccessDay: "", // 계약유효일
    contractDate: null, // 최초계약일
    writingnow: false, // 조아라 연재 작품
    ebookname: "", // 이북 필명
    joaraname: "", // 조아라 필명
    name: "", // 작가 본명
    managerDepartment: "", //담당 부서
    managerName: "", // 담당자 명
  }
  const [FilterDatas, setFilterDatas] = useState(orFilterData)
  const [ApplyFilterDatas, setApplyFilterDatas] = useState(orFilterData)
  const handleFilterDatas = (type) => (event) => {
    let datas = FilterDatas
    if (type === "contractDate") {
      if (event) {
        setFilterDatas({ ...datas, [type]: dayjs(event).format("YYYY-MM-DD") })
      } else {
        setFilterDatas({ ...datas, [type]: null })
      }
    } else if (type === "writingnow" || type === "cover") {
      setFilterDatas({ ...datas, [type]: !datas[type] })
    } else {
      setFilterDatas({ ...datas, [type]: event.target.value })
    }
  }

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  useLayoutEffect(() => {
    // 탭 접근 권한 체크
    if (myData.name !== "") {
      if (!myData.accessTabs) {
        return setInfoAlert_Acess({
          dialog: true,
          title: "접근 권한 필요",
          content: "해당 탭에 접근하기 위한 권한이 없습니다.",
          CanBtnText: "확인",
        })
      }
    }
    if (myData.accessTabs?.indexOf("wri") === -1) {
      return setInfoAlert_Acess({
        dialog: true,
        title: "접근 권한 필요",
        content: "해당 탭에 접근하기 위한 권한이 없습니다.",
        CanBtnText: "확인",
      })
    } else settings_Call()
  }, [myData])
  const settings_ref = useRef(null)
  const settings_Call = () => {
    settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        if (el.type === "department") {
          el.list.unshift("전체")
        }
        let slice_index = el.list.findIndex((el) => el === "-")
        if (slice_index > -1) {
          el.list.splice(slice_index, 1)
          settings[el.type] = el.list
        } else {
          settings[el.type] = el.list
        }
      })
      settings_ref.current = settings
    })
  }

  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  //filter-end
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("calories")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(1)
  const [searchPage, setsearchPage] = useState(1)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchRowsPerPage, setsearchRowsPerPage] = useState(10)
  const [age, setAge] = useState("")
  const [team, setTeam] = useState(5)
  const [totalpage, settotalpage] = useState(10)
  const [searchTotalpage, setsearchTotalpage] = useState(10)
  const [rowlength, setrowlength] = useState(0)
  const [contractsDatas, setcontractsDatas] = useState([])
  const [contractsCountFilter, setcontractsCountFilter] = useState({})
  const paperSorting = (array) => {
    let array_copy = [...array]
    array_copy.sort((a, b) => {
      var dateA = a[0].file.id
      var dateB = b[0].file.id
      return dateA < dateB ? 1 : -1
    })
    return array_copy
  }
  const contractTypeShow = (contractStatus) => {
    if (changeFilter === "웹툰") {
      return contractStatus.webtoon
    } else if (changeFilter === "영상") {
      return contractStatus.video
    } else if (changeFilter === "게임") {
      return contractStatus.game
    } else if (changeFilter === "제작") {
      // 추후 개발 필요!
      return contractStatus.created
    } else if (changeFilter === "기타") {
      return contractStatus.etc
    }
  }
  useEffect(() => {
    let rowsData = []
    contractsDatas.forEach((element, index) => {
      let contractStatus = {
        agent: "",
        contract: "",
        nobless: "",
        premium: "",
        mixnobl: "",
        ebook: "",
        webtoon: "",
        video: "",
        game: "",
        created: "",
        etc: "",
        bullseye: "",
        integrated: "",
        coversub: "",
        seoninse: "",
      }
      let etcStatus = {
        gray: "",
        red: "",
        orange: "",
        green: "",
      }
      let ebookStatus = {
        gray: "",
        red: "",
        orange: "",
        green: "",
        bullseye: false,
      }

      let etcdupCheck = element.contract_documents.filter((e) => {
        return e.contractPaper[0][0].selectData[1].value === "미분류 계약(기타)"
      })

      if (etcdupCheck.length > 1) {
        if (changeFilter === "기타") {
          contractStatus.bullseye = "etc"
        }
        etcStatus.bullseye = true
      }
      const ebookList = []
      if (changeFilter === "웹툰") {
        ebookList.push("웹툰계약")
      } else if (changeFilter === "영상") {
        ebookList.push("영상화 계약")
      } else if (changeFilter === "게임") {
        ebookList.push("게임화 계약")
      } else if (changeFilter === "제작") {
        ebookList.push("제작작가 계약")
      } else if (changeFilter === "기타") {
        ebookList.push("미분류 계약(기타)")
      }
      if (element.contract_documents.length > 0) {
        element.contract_documents
          .map((x) => x.contractPaper)
          .forEach((paper, paperIndex) => {
            if ("미분류 계약(기타)".includes(paperSorting(paper)[0][0].selectData[1].value)) {
              if (
                etcStatus[
                  betweenDayColor2(
                    dayjs(new Date()).format("YYYYMMDD"),
                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                  )
                ] === ""
              ) {
                etcStatus[
                  betweenDayColor2(
                    dayjs(new Date()).format("YYYYMMDD"),
                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                  )
                ] = 0
              }
              etcStatus[
                betweenDayColor2(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
              ] += 1
            }
            if (paperSorting(paper)[0][0].selectData[1].value.indexOf("전자책 계약") > -1) {
              if (
                ebookStatus[
                  betweenDayColor2(
                    dayjs(new Date()).format("YYYYMMDD"),
                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                  )
                ] === ""
              ) {
                ebookStatus[
                  betweenDayColor2(
                    dayjs(new Date()).format("YYYYMMDD"),
                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                  )
                ] = 0
              }
              ebookStatus[
                betweenDayColor2(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
              ] += 1
            }
            if (paperSorting(paper)[0][0].selectData[1].value === "저작물 에이전트") {
              if (!contractStatus.agent) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.agent = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (
              paperSorting(paper)[0][0].selectData[1].value === "독점적 이용 허락서(전속)"
            ) {
              if (!contractStatus.contract) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.contract = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "통합연재 계약(원계약)") {
              if (!contractStatus.integrated) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.integrated = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "통합) 표지 부속합의") {
              if (!contractStatus.coversub) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.coversub = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "통합) 선인세 부속합의") {
              if (!contractStatus.seoninse) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.seoninse = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "노블레스 계약") {
              if (!contractStatus.nobless) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.nobless = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "프리미엄 계약") {
              if (!contractStatus.premium) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.premium = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (
              paperSorting(paper)[0][0].selectData[1].value === "노블레스 + 프리미엄 계약"
            ) {
              if (!contractStatus.mixnobl) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.mixnobl = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (
              paperSorting(paper)[0][0].selectData[1].value === "전자책 계약" ||
              paperSorting(paper)[0][0].selectData[1].value === "전자책 계약 선인세용" ||
              paperSorting(paper)[0][0].selectData[1].value === "통합) 전자책 부속합의"
            ) {
              if (!contractStatus.ebook) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.ebook = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                  created_at: paperSorting(paper)[0][1].created_at,
                }
              } else {
                // 최근에 등록한 계약으로 변경
                if (
                  new Date(contractStatus.ebook.created_at) <
                  new Date(paperSorting(paper)[0][1].created_at)
                ) {
                  let left_day = betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                  )
                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                  contractStatus.ebook = {
                    start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                    end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                    ContractType: paperSorting(paper)[0][0].ContractType,
                    day: betweenDay(
                      dayjs(new Date()).format("YYYYMMDD"),
                      end_date.format("YYYYMMDD")
                    ),
                    color: betweenDayColor(
                      dayjs(new Date()).format("YYYYMMDD"),
                      end_date.format("YYYYMMDD")
                    ),
                    value: paperSorting(paper)[0][3].value,
                    end_date: end_date.format("YY.MM.DD"),
                    created_at: paperSorting(paper)[0][1].created_at,
                  }
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "웹툰계약") {
              if (!contractStatus.webtoon) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.webtoon = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "영상화 계약") {
              if (!contractStatus.video) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.video = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "게임화 계약") {
              if (!contractStatus.game) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.game = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "제작작가 계약") {
              if (!contractStatus.created) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.created = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            } else if (paperSorting(paper)[0][0].selectData[1].value === "미분류 계약(기타)") {
              if (!contractStatus.etc) {
                let left_day = betweenDay(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                )
                let end_date = dayjs(paperSorting(paper)[0][1].value2)

                contractStatus.etc = {
                  start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                  end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                  ContractType: paperSorting(paper)[0][0].ContractType,
                  day: betweenDay(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  color: betweenDayColor(
                    dayjs(new Date()).format("YYYYMMDD"),
                    end_date.format("YYYYMMDD")
                  ),
                  value: paperSorting(paper)[0][3].value,
                  end_date: end_date.format("YY.MM.DD"),
                }
              }
            }

            if (paperIndex === element.contract_documents.map((x) => x.contractPaper).length - 1) {
              rowsData.push(
                createData(
                  element.id,
                  element.title,
                  element.genre,
                  element.bookcode,
                  element.writer.joaraname,
                  element.writer.ebookname,
                  element.writer.name,
                  contractStatus.agent,
                  contractStatus.contract,
                  contractStatus.nobless,
                  contractStatus.premium,
                  contractStatus.mixnobl,
                  contractStatus.ebook,
                  contractStatus.webtoon,
                  contractStatus.video,
                  contractStatus.game,
                  contractStatus.integrated,
                  contractStatus.coversub,
                  contractStatus.seoninse,
                  contractStatus.created,
                  element.cover,
                  element.ecover,
                  element.joaraStatus,
                  element.writer,
                  contractStatus.bullseye,
                  etcStatus.bullseye ? etcStatus : contractStatus.etc,
                  contractStatus,
                  ebookStatus,
                  element.rating,
                  element.publicDate,
                  element.user?.id,
                  element.contract_documents
                )
              )
            }
          })
      } else {
        rowsData.push(
          createData(
            element.id,
            element.title,
            element.genre,
            element.bookcode,
            element.writer.joaraname,
            element.writer.ebookname,
            element.writer.name,
            contractStatus.agent,
            contractStatus.contract,
            contractStatus.nobless,
            contractStatus.premium,
            contractStatus.mixnobl,
            contractStatus.ebook,
            contractStatus.webtoon,
            contractStatus.video,
            contractStatus.game,
            contractStatus.integrated,
            contractStatus.coversub,
            contractStatus.seoninse,
            contractStatus.created,
            element.cover,
            element.ecover,
            element.joaraStatus,
            element.writer,
            contractStatus.bullseye,
            etcStatus.bullseye ? etcStatus : contractStatus.etc,
            contractStatus,
            ebookStatus,
            element.rating,
            element.publicDate,
            element.user?.id,
            element.contract_documents
          )
        )
      }
      if (contractsDatas.length - 1 === index) {
        setrows(rowsData)
      }
    })
  }, [changeFilter])

  useEffect(async () => {
    await client
      .query({
        query: contractsCount,
        variables: {
          status: "공개",
          limit: 10000000,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setrowlength(res.data.contractsCount)
      })
  }, [contractsCountFilter])
  const [contractsSearchVariables, setcontractsSearchVariables] = useState({
    sort: "created_at:desc",
    start: 0,
    limit: rowsPerPage,
    status: "공개",
  })
  const [contractsVariables, setcontractsVariables] = useState({
    sort: "created_at:desc",
    start: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    status: "공개",
  })
  const [FilterSearchActive, setFilterSearchActive] = useState(false)

  const { data: contractsSearch } = useQuery(contracts, {
    fetchPolicy: "network-only",
    variables: contractsSearchVariables,
  })
  const { data: contractsFirst } = useQuery(contracts, {
    fetchPolicy: "network-only",
    variables: contractsVariables,
    refetchQueries: [contractsCount],
  })

  useEffect(() => {
    if (myData.accessTabs?.indexOf("wri") !== -1) {
      if (contractsFirst && !FilterSearchActive) {
        setcontractsDatas(contractsFirst.contracts)
        dispatch(loading_Apply(true))
        if (contractsFirst.contracts.length > 0) {
          let elArray = []
          let ok = 0
          contractsFirst.contracts.forEach((el, elIndex) => {
            if (ok === 0) {
              elArray.push(el)
            }
            if (elIndex === contractsFirst.contracts.length - 1) {
              const uniqueArr = elArray.filter((element, index) => {
                return elArray.indexOf(element) === index
              })
              let rowsData = []

              uniqueArr.forEach((element, index) => {
                let contractStatus = {
                  agent: "",
                  contract: "",
                  nobless: "",
                  premium: "",
                  mixnobl: "",
                  ebook: "",
                  webtoon: "",
                  video: "",
                  game: "",
                  created: "",
                  etc: "",
                  bullseye: "",
                  integrated: "",
                  coversub: "",
                  seoninse: "",
                }
                let etcStatus = {
                  gray: "",
                  red: "",
                  orange: "",
                  green: "",
                  bullseye: false,
                }
                let ebookStatus = {
                  gray: "",
                  red: "",
                  orange: "",
                  green: "",
                  bullseye: false,
                }
                let etcdupCheck = element.contract_documents.filter((e) => {
                  return e.contractPaper[0][0].selectData[1].value === "미분류 계약(기타)"
                })

                let ebookdupCheck = element.contract_documents.filter((e) => {
                  return e.contractPaper[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                })

                if (etcdupCheck.length > 1) {
                  if (changeFilter === "기타") {
                    contractStatus.bullseye = "etc"
                  }

                  etcStatus.bullseye = true
                }
                if (ebookdupCheck.length > 1) {
                  if (changeFilter === "이북") {
                    contractStatus.bullseye = "ebookStatus"
                  }

                  ebookStatus.bullseye = true
                }
                const ebookList = []
                if (changeFilter === "이북") {
                  ebookList.push("전자책 계약")
                  ebookList.push("전자책 계약 선인세용")
                } else if (changeFilter === "웹툰") {
                  ebookList.push("웹툰계약")
                } else if (changeFilter === "영상") {
                  ebookList.push("영상화 계약")
                } else if (changeFilter === "게임") {
                  ebookList.push("게임화 계약")
                } else if (changeFilter === "제작") {
                  ebookList.push("제작작가 계약")
                } else if (changeFilter === "기타") {
                  ebookList.push("미분류 계약(기타)")
                }
                if (element.contract_documents.length > 0) {
                  element.contract_documents
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (
                        "미분류 계약(기타)".includes(paperSorting(paper)[0][0].selectData[1].value)
                      ) {
                        if (
                          etcStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          etcStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        etcStatus[
                          betweenDayColor2(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (
                        paperSorting(paper)[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                      ) {
                        if (
                          ebookStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          ebookStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        ebookStatus[
                          betweenDayColor2(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (paperSorting(paper)[0][0].selectData[1].value === "저작물 에이전트") {
                        if (!contractStatus.agent) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.agent = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "독점적 이용 허락서(전속)"
                      ) {
                        if (!contractStatus.contract) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.contract = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합연재 계약(원계약)"
                      ) {
                        if (!contractStatus.integrated) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.integrated = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 표지 부속합의"
                      ) {
                        if (!contractStatus.coversub) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.coversub = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 선인세 부속합의"
                      ) {
                        if (!contractStatus.seoninse) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.seoninse = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "노블레스 계약"
                      ) {
                        if (!contractStatus.nobless) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.nobless = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "프리미엄 계약"
                      ) {
                        if (!contractStatus.premium) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.premium = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "노블레스 + 프리미엄 계약"
                      ) {
                        if (!contractStatus.mixnobl) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.mixnobl = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "전자책 계약" ||
                        paperSorting(paper)[0][0].selectData[1].value === "전자책 계약 선인세용" ||
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 전자책 부속합의"
                      ) {
                        if (!contractStatus.ebook) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.ebook = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                            created_at: paperSorting(paper)[0][1].created_at,
                          }
                        } else {
                          // 최근에 등록한 계약으로 변경
                          if (
                            new Date(contractStatus.ebook.created_at) <
                            new Date(paperSorting(paper)[0][1].created_at)
                          ) {
                            let left_day = betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                            let end_date = dayjs(paperSorting(paper)[0][1].value2)

                            contractStatus.ebook = {
                              start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                              end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                              ContractType: paperSorting(paper)[0][0].ContractType,
                              day: betweenDay(
                                dayjs(new Date()).format("YYYYMMDD"),
                                end_date.format("YYYYMMDD")
                              ),
                              color: betweenDayColor(
                                dayjs(new Date()).format("YYYYMMDD"),
                                end_date.format("YYYYMMDD")
                              ),
                              value: paperSorting(paper)[0][3].value,
                              end_date: end_date.format("YY.MM.DD"),
                              created_at: paperSorting(paper)[0][1].created_at,
                            }
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "웹툰계약") {
                        if (!contractStatus.webtoon) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.webtoon = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "영상화 계약") {
                        if (!contractStatus.video) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.video = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "게임화 계약") {
                        if (!contractStatus.game) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.game = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "제작작가 계약"
                      ) {
                        if (!contractStatus.created) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.created = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "미분류 계약(기타)"
                      ) {
                        if (!contractStatus.etc) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.etc = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      }

                      if (
                        paperIndex ===
                        element.contract_documents.map((x) => x.contractPaper).length - 1
                      ) {
                        rowsData.push(
                          createData(
                            element.id,
                            element.title,
                            element.genre,
                            element.bookcode,
                            element.writer.joaraname,
                            element.writer.ebookname,
                            element.writer.name,
                            contractStatus.agent,
                            contractStatus.contract,
                            contractStatus.nobless,
                            contractStatus.premium,
                            contractStatus.mixnobl,
                            contractStatus.ebook,
                            contractStatus.webtoon,
                            contractStatus.video,
                            contractStatus.game,
                            contractStatus.integrated,
                            contractStatus.coversub,
                            contractStatus.seoninse,
                            contractStatus.created,
                            element.cover,
                            element.ecover,
                            element.joaraStatus,
                            element.writer,
                            contractStatus.bullseye,
                            etcStatus.bullseye ? etcStatus : contractStatus.etc,
                            contractStatus,
                            ebookStatus,
                            element.rating,
                            element.publicDate,
                            element.user?.id,
                            element.contract_documents
                          )
                        )
                      }
                    })
                } else {
                  rowsData.push(
                    createData(
                      element.id,
                      element.title,
                      element.genre,
                      element.bookcode,
                      element.writer.joaraname,
                      element.writer.ebookname,
                      element.writer.name,
                      contractStatus.agent,
                      contractStatus.contract,
                      contractStatus.nobless,
                      contractStatus.premium,
                      contractStatus.mixnobl,
                      contractStatus.ebook,
                      contractStatus.webtoon,
                      contractStatus.video,
                      contractStatus.game,
                      contractStatus.integrated,
                      contractStatus.coversub,
                      contractStatus.seoninse,
                      contractStatus.created,
                      element.cover,
                      element.ecover,
                      element.joaraStatus,
                      element.writer,
                      contractStatus.bullseye,
                      etcStatus.bullseye ? etcStatus : contractStatus.etc,
                      contractStatus,
                      ebookStatus,
                      element.rating,
                      element.publicDate,
                      element.user?.id,
                      element.contract_documents
                    )
                  )
                }
                if (uniqueArr.length - 1 === index) {
                  if (rowsData.length > rowsPerPage) {
                    let rows = []
                    rowsData.forEach((row, rowIndex) => {
                      if (rowIndex < rowsPerPage) {
                        rows.push(row)
                      }
                    })
                    setTimeout(() => {
                      dispatch(loading_Apply(false))
                    }, 1000)
                    setrows(rows)
                    setSelected(rows)
                  } else {
                    setTimeout(() => {
                      dispatch(loading_Apply(false))
                    }, 1000)
                    setrows(rowsData)
                    setSelected(rowsData)
                  }
                }
              })
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      }
    }
  }, [contractsFirst, contractsVariables])

  const [contracts_download_Active, {}] = useLazyQuery(contracts, {
    fetchPolicy: "network-only",
  })

  const call_all_contracts = async () => {
    dispatch(loading_Apply(true))
    let stop = false
    let rows = []
    for (let n = 0; n < n + 1; n++) {
      let input = {
        sort: "created_at:desc",
        start: n * 200,
        limit: 200,
        status: "공개",
      }
      if (eventExcel === "현재 페이지") {
        input.start = FilterSearchActive
          ? (searchPage - 1) * FilterSearchActive
          : (page - 1) * rowsPerPage
        input.limit = FilterSearchActive ? searchRowsPerPage : rowsPerPage
        stop = true
      }

      if (FilterDatas.writingnow) {
        input.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        input.cover = "있음"
      }
      if (FilterDatas.bookcode !== "") {
        input.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        input.title = FilterDatas.title
      }
      if (genreChipData.length > 0) {
        input.genre = genreChipData
      }
      handleSearchedFilter()
      let rowsData = []

      await client
        .query({
          query: contracts,
          variables: input,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.contracts.length !== 200) {
            stop = true
          }
          if (myData.accessTabs?.indexOf("wri") !== -1) {
            if (res.data) {
              dispatch(loading_Apply(true))
              if (res.data.contracts.length > 0) {
                if (FilterSearchActive) {
                  let elArray = []
                  let ok = 0
                  res.data.contracts.forEach((el, elIndex) => {
                    if (
                      FilterDatas.ebookname !== "" ||
                      FilterDatas.name !== "" ||
                      FilterDatas.joaraname !== ""
                    ) {
                      if (FilterDatas.name !== "") {
                        if (el.writer.name.indexOf(FilterDatas.name) > -1) {
                          elArray.push(el)
                        }
                      }
                      if (FilterDatas.joaraname !== "") {
                        if (el.writer.joaraname.indexOf(FilterDatas.joaraname) > -1) {
                          elArray.push(el)
                        }
                      }
                      if (FilterDatas.ebookname !== "") {
                        if (el.writer.ebookname.indexOf(FilterDatas.ebookname) > -1) {
                          elArray.push(el)
                        }
                      }
                    } else {
                      if (FilterDatas.contractDate) {
                        ok += 1
                        if (el.contract_documents.length > 0) {
                          el.contract_documents
                            .map((x) => x.contractPaper)
                            .forEach((paper, paperIndex) => {
                              if (
                                dayjs(paper[paper.length - 1][1].value1).format("YYYY-MM-DD") ===
                                FilterDatas.contractDate
                              ) {
                                elArray.push(el)
                              }
                            })
                        }
                      }
                      if (FilterDatas.contractDateAccessDay && filterChipData.length > 0) {
                        ok += 1
                        let filterChipDataSet = []
                        filterChipData.forEach((el) => {
                          if (el === chipsList[0]) {
                            filterChipDataSet.push("통합연재 계약(원계약)")
                          } else if (el === chipsList[1]) {
                            filterChipDataSet.push("통합) 표지 부속합의")
                          } else if (el === chipsList[2]) {
                            filterChipDataSet.push("통합) 선인세 부속합의")
                          } else if (el === chipsList[3]) {
                            filterChipDataSet.push("저작물 에이전트")
                          } else if (el === chipsList[4]) {
                            filterChipDataSet.push("독점적 이용 허락서(전속)")
                          } else if (el === chipsList[5]) {
                            filterChipDataSet.push("노블레스 계약")
                          } else if (el === chipsList[6]) {
                            filterChipDataSet.push("프리미엄 계약")
                          } else if (el === chipsList[7]) {
                            filterChipDataSet.push("노블레스 + 프리미엄 계약")
                          } else if (el === chipsList[8]) {
                            filterChipDataSet.push("부속계약")
                          } else if (el === chipsList[9]) {
                            filterChipDataSet.push("전자책 계약")
                            filterChipDataSet.push("전자책 계약 선인세용")
                          } else if (el === chipsList[10]) {
                            filterChipDataSet.push("웹툰계약")
                          } else if (el === chipsList[11]) {
                            filterChipDataSet.push("게임화 계약")
                          } else if (el === chipsList[12]) {
                            filterChipDataSet.push("영상화 계약")
                          } else if (el === chipsList[13]) {
                            filterChipDataSet.push("제작작가 계약")
                          } else if (el === chipsList[14]) {
                            filterChipDataSet.push("기타 2차 저작물 계약")
                          } else if (el === chipsList[15]) {
                            filterChipDataSet.push("미분류 계약(기타)")
                          }
                        })
                        if (el.contract_documents.length > 0) {
                          el.contract_documents
                            .map((x) => x.contractPaper)
                            .forEach((paper, paperIndex) => {
                              if (FilterDatas.contractDateAccessDay === contractList[0]) {
                                if (
                                  betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  ) >= 365
                                ) {
                                  if (filterChipDataSet.indexOf("부속계약") > -1) {
                                    if (paperSorting(paper)[0][0].subContract.length > 0) {
                                      if (filterChipDataSet.length > 1) {
                                        if (
                                          filterChipDataSet.includes(
                                            paperSorting(paper)[0][0].selectData[1].value
                                          )
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        elArray.push(el)
                                      }
                                    }
                                  } else {
                                    if (
                                      filterChipDataSet.includes(
                                        paperSorting(paper)[0][0].selectData[1].value
                                      )
                                    ) {
                                      elArray.push(el)
                                    }
                                  }
                                }
                              } else if (FilterDatas.contractDateAccessDay === contractList[1]) {
                                if (
                                  betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  ) >= 89 &&
                                  betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  ) < 365
                                ) {
                                  if (filterChipDataSet.indexOf("부속계약") > -1) {
                                    if (paperSorting(paper)[0][0].subContract.length > 0) {
                                      if (filterChipDataSet.length > 1) {
                                        if (
                                          filterChipDataSet.includes(
                                            paperSorting(paper)[0][0].selectData[1].value
                                          )
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        elArray.push(el)
                                      }
                                    }
                                  } else {
                                    if (
                                      filterChipDataSet.includes(
                                        paperSorting(paper)[0][0].selectData[1].value
                                      )
                                    ) {
                                      elArray.push(el)
                                    }
                                  }
                                }
                              } else if (FilterDatas.contractDateAccessDay === contractList[2]) {
                                if (
                                  betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  ) > 0 &&
                                  betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  ) < 90
                                ) {
                                  if (filterChipDataSet.indexOf("부속계약") > -1) {
                                    if (paperSorting(paper)[0][0].subContract.length > 0) {
                                      if (filterChipDataSet.length > 1) {
                                        if (
                                          filterChipDataSet.includes(
                                            paperSorting(paper)[0][0].selectData[1].value
                                          )
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        elArray.push(el)
                                      }
                                    }
                                  } else {
                                    if (
                                      filterChipDataSet.includes(
                                        paperSorting(paper)[0][0].selectData[1].value
                                      )
                                    ) {
                                      elArray.push(el)
                                    }
                                  }
                                }
                              } else if (FilterDatas.contractDateAccessDay === contractList[3]) {
                                if (new Date(paperSorting(paper)[0][1].value2) < new Date()) {
                                  if (filterChipDataSet.indexOf("부속계약") > -1) {
                                    if (paperSorting(paper)[0][0].subContract.length > 0) {
                                      if (filterChipDataSet.length > 1) {
                                        if (
                                          filterChipDataSet.includes(
                                            paperSorting(paper)[0][0].selectData[1].value
                                          )
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        elArray.push(el)
                                      }
                                    }
                                  } else {
                                    if (
                                      filterChipDataSet.includes(
                                        paperSorting(paper)[0][0].selectData[1].value
                                      )
                                    ) {
                                      elArray.push(el)
                                    }
                                  }
                                }
                              }
                            })
                        }
                      } else {
                        if (FilterDatas.contractDateAccessDay) {
                          ok += 1
                          if (el.contract_documents.length > 0) {
                            el.contract_documents
                              .map((x) => x.contractPaper)
                              .forEach((paper, paperIndex) => {
                                if (FilterDatas.contractDateAccessDay === contractList[0]) {
                                  if (
                                    betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    ) >= 365
                                  ) {
                                    elArray.push(el)
                                  }
                                } else if (FilterDatas.contractDateAccessDay === contractList[1]) {
                                  if (
                                    betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    ) >= 89 &&
                                    betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    ) < 365
                                  ) {
                                    elArray.push(el)
                                  }
                                } else if (FilterDatas.contractDateAccessDay === contractList[2]) {
                                  if (
                                    betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    ) > 0 &&
                                    betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    ) < 90
                                  ) {
                                    elArray.push(el)
                                  }
                                } else if (FilterDatas.contractDateAccessDay === contractList[3]) {
                                  if (new Date(paperSorting(paper)[0][1].value2) < new Date()) {
                                    elArray.push(el)
                                  }
                                }
                              })
                          }
                        }
                        if (filterChipData.length > 0) {
                          ok += 1
                          let filterChipDataSet = []
                          filterChipData.forEach((el) => {
                            if (el === chipsList[0]) {
                              filterChipDataSet.push("통합연재 계약(원계약)")
                            } else if (el === chipsList[1]) {
                              filterChipDataSet.push("통합) 표지 부속합의")
                            } else if (el === chipsList[2]) {
                              filterChipDataSet.push("통합) 선인세 부속합의")
                            } else if (el === chipsList[3]) {
                              filterChipDataSet.push("저작물 에이전트")
                            } else if (el === chipsList[4]) {
                              filterChipDataSet.push("독점적 이용 허락서(전속)")
                            } else if (el === chipsList[5]) {
                              filterChipDataSet.push("노블레스 계약")
                            } else if (el === chipsList[6]) {
                              filterChipDataSet.push("프리미엄 계약")
                            } else if (el === chipsList[7]) {
                              filterChipDataSet.push("노블레스 + 프리미엄 계약")
                            } else if (el === chipsList[8]) {
                              filterChipDataSet.push("부속계약")
                            } else if (el === chipsList[9]) {
                              filterChipDataSet.push("전자책 계약")
                              filterChipDataSet.push("전자책 계약 선인세용")
                            } else if (el === chipsList[10]) {
                              filterChipDataSet.push("웹툰계약")
                            } else if (el === chipsList[11]) {
                              filterChipDataSet.push("게임화 계약")
                            } else if (el === chipsList[12]) {
                              filterChipDataSet.push("영상화 계약")
                            } else if (el === chipsList[13]) {
                              filterChipDataSet.push("제작작가 계약")
                            } else if (el === chipsList[14]) {
                              filterChipDataSet.push("기타 2차 저작물 계약")
                            } else if (el === chipsList[15]) {
                              filterChipDataSet.push("미분류 계약(기타)")
                            }
                          })
                          if (el.contract_documents.length > 0) {
                            // el.contract_documents
                            //   .map((x) => x.contractPaper)
                            el.contract_documents.forEach((contract, contractIndex) => {
                              let paper = contract.contractPaper
                              if (filterChipDataSet.indexOf("부속계약") > -1) {
                                if (paperSorting(paper)[0][0].subContract.length > 0) {
                                  if (filterChipDataSet.length > 1) {
                                    if (
                                      filterChipDataSet.includes(
                                        paperSorting(paper)[0][0].selectData[1].value
                                      )
                                    ) {
                                      if (
                                        FilterDatas.department !== "" &&
                                        FilterDatas.department !== "전체"
                                      ) {
                                        if (
                                          FilterDatas.department !== "" &&
                                          FilterDatas.department !== "전체" &&
                                          FilterDatas.managerName !== ""
                                        ) {
                                          if (
                                            contract.users_permissions_user.name ===
                                              FilterDatas.managerName &&
                                            contract.users_permissions_user.department ===
                                              FilterDatas.department
                                          ) {
                                            elArray.push(el)
                                          }
                                        } else {
                                          if (
                                            contract.users_permissions_user.department ===
                                            FilterDatas.department
                                          ) {
                                            elArray.push(el)
                                          }
                                        }
                                      } else if (FilterDatas.managerName !== "") {
                                        if (
                                          contract.users_permissions_user.name ===
                                          FilterDatas.managerName
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        elArray.push(el)
                                      }
                                    }
                                  } else {
                                    if (
                                      FilterDatas.department !== "" &&
                                      FilterDatas.department !== "전체"
                                    ) {
                                      if (
                                        FilterDatas.department !== "" &&
                                        FilterDatas.department !== "전체" &&
                                        FilterDatas.managerName !== ""
                                      ) {
                                        if (
                                          contract.users_permissions_user.name ===
                                            FilterDatas.managerName &&
                                          contract.users_permissions_user.department ===
                                            FilterDatas.department
                                        ) {
                                          elArray.push(el)
                                        }
                                      } else {
                                        if (
                                          contract.users_permissions_user.department ===
                                          FilterDatas.department
                                        ) {
                                          elArray.push(el)
                                        }
                                      }
                                    } else if (FilterDatas.managerName !== "") {
                                      if (
                                        contract.users_permissions_user.name ===
                                        FilterDatas.managerName
                                      ) {
                                        elArray.push(el)
                                      }
                                    } else {
                                      elArray.push(el)
                                    }
                                  }
                                }
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  if (
                                    FilterDatas.department !== "" &&
                                    FilterDatas.department !== "전체"
                                  ) {
                                    if (
                                      FilterDatas.department !== "" &&
                                      FilterDatas.department !== "전체" &&
                                      FilterDatas.managerName !== ""
                                    ) {
                                      el.writer.users.forEach((user) => {
                                        if (
                                          user.department === FilterDatas.department &&
                                          user.name === FilterDatas.managerName
                                        ) {
                                          elArray.push(el)
                                        }
                                      })
                                    } else {
                                      el.writer.users.forEach((user) => {
                                        if (user.department === FilterDatas.department) {
                                          elArray.push(el)
                                        }
                                      })
                                    }
                                  } else if (FilterDatas.managerName !== "") {
                                    el.writer.users.forEach((user) => {
                                      if (user.name === FilterDatas.managerName) {
                                        elArray.push(el)
                                      }
                                    })
                                  } else {
                                    elArray.push(el)
                                  }
                                }
                              } else {
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  if (
                                    FilterDatas.department !== "" &&
                                    FilterDatas.department !== "전체"
                                  ) {
                                    if (
                                      FilterDatas.department !== "" &&
                                      FilterDatas.department !== "전체" &&
                                      FilterDatas.managerName !== ""
                                    ) {
                                      if (
                                        contract.users_permissions_user.name ===
                                          FilterDatas.managerName &&
                                        contract.users_permissions_user.department ===
                                          FilterDatas.department
                                      ) {
                                        elArray.push(el)
                                      }
                                    } else {
                                      if (
                                        contract.users_permissions_user.department ===
                                        FilterDatas.department
                                      ) {
                                        elArray.push(el)
                                      }
                                    }
                                  } else if (FilterDatas.managerName !== "") {
                                    if (
                                      contract.users_permissions_user.name ===
                                      FilterDatas.managerName
                                    ) {
                                      elArray.push(el)
                                    }
                                  } else {
                                    elArray.push(el)
                                  }
                                }
                              }
                            })
                          }
                        } else {
                          if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체" &&
                              FilterDatas.managerName !== ""
                            ) {
                              ok += 1
                              el.contract_documents.forEach((paper) => {
                                if (
                                  paper.users_permissions_user.name === FilterDatas.managerName &&
                                  paper.users_permissions_user.department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                              })
                            } else {
                              ok += 1
                              el.contract_documents.forEach((paper) => {
                                if (
                                  paper.users_permissions_user.department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                              })
                            }
                          } else if (FilterDatas.managerName !== "") {
                            ok += 1
                            el.contract_documents.forEach((paper) => {
                              if (paper.users_permissions_user.name === FilterDatas.managerName) {
                                elArray.push(el)
                              }
                            })
                          }
                        }
                      }
                      if (ok === 0) {
                        elArray.push(el)
                      }
                    }

                    if (elIndex === res.data.contracts.length - 1) {
                      const uniqueArr = elArray.filter((element, index) => {
                        return elArray.indexOf(element) === index
                      })
                      let rowsData = []
                      if (uniqueArr.length === 0) {
                        setrows_hide([])
                        dispatch(loading_Apply(false))
                      }

                      uniqueArr.forEach((element, index) => {
                        let contractStatus = {
                          agent: "",
                          contract: "",
                          nobless: "",
                          premium: "",
                          mixnobl: "",
                          ebook: "",
                          webtoon: "",
                          video: "",
                          game: "",
                          created: "",
                          etc: "",
                          bullseye: "",
                          integrated: "",
                          coversub: "",
                          seoninse: "",
                        }
                        let etcStatus = {
                          gray: "",
                          red: "",
                          orange: "",
                          green: "",
                          bullseye: false,
                        }
                        let ebookStatus = {
                          gray: "",
                          red: "",
                          orange: "",
                          green: "",
                          bullseye: false,
                        }
                        let etcdupCheck = element.contract_documents.filter((e) => {
                          return e.contractPaper[0][0].selectData[1].value === "미분류 계약(기타)"
                        })
                        let ebookdupCheck = element.contract_documents.filter((e) => {
                          return (
                            e.contractPaper[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                          )
                        })
                        if (etcdupCheck.length > 1) {
                          if (changeFilter === "기타") {
                            contractStatus.bullseye = "etc"
                          }
                          etcStatus.bullseye = true
                        }
                        if (ebookdupCheck.length > 1) {
                          if (changeFilter === "이북") {
                            contractStatus.bullseye = "ebookStatus"
                          }

                          ebookStatus.bullseye = true
                        }
                        const ebookList = []
                        if (changeFilter === "이북") {
                          ebookList.push("전자책 계약")
                          ebookList.push("전자책 계약 선인세용")
                        } else if (changeFilter === "웹툰") {
                          ebookList.push("웹툰계약")
                        } else if (changeFilter === "영상") {
                          ebookList.push("영상화 계약")
                        } else if (changeFilter === "게임") {
                          ebookList.push("게임화 계약")
                        } else if (changeFilter === "제작") {
                          ebookList.push("제작작가 계약")
                        } else if (changeFilter === "기타") {
                          ebookList.push("미분류 계약(기타)")
                        }
                        if (element.contract_documents.length > 0) {
                          element.contract_documents
                            .map((x) => x.contractPaper)
                            .forEach((paper, paperIndex) => {
                              if (
                                "미분류 계약(기타)".includes(
                                  paperSorting(paper)[0][0].selectData[1].value
                                )
                              ) {
                                if (
                                  etcStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] === ""
                                ) {
                                  etcStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] = 0
                                }
                                etcStatus[
                                  betweenDayColor2(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                ] += 1
                              }
                              if (
                                paperSorting(paper)[0][0].selectData[1].value.indexOf(
                                  "전자책 계약"
                                ) > -1
                              ) {
                                if (
                                  ebookStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] === ""
                                ) {
                                  ebookStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] = 0
                                }
                                ebookStatus[
                                  betweenDayColor2(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                ] += 1
                              }
                              if (
                                paperSorting(paper)[0][0].selectData[1].value === "저작물 에이전트"
                              ) {
                                if (!contractStatus.agent) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.agent = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "독점적 이용 허락서(전속)"
                              ) {
                                if (!contractStatus.contract) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.contract = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합연재 계약(원계약)"
                              ) {
                                if (!contractStatus.integrated) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.integrated = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합) 표지 부속합의"
                              ) {
                                if (!contractStatus.coversub) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.coversub = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합) 선인세 부속합의"
                              ) {
                                if (!contractStatus.seoninse) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.seoninse = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "노블레스 계약"
                              ) {
                                if (!contractStatus.nobless) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.nobless = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "프리미엄 계약"
                              ) {
                                if (!contractStatus.premium) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.premium = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "노블레스 + 프리미엄 계약"
                              ) {
                                if (!contractStatus.mixnobl) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.mixnobl = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "전자책 계약" ||
                                paperSorting(paper)[0][0].selectData[1].value ===
                                  "전자책 계약 선인세용" ||
                                paperSorting(paper)[0][0].selectData[1].value ===
                                  "통합) 전자책 부속합의"
                              ) {
                                if (!contractStatus.ebook) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.ebook = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                    created_at: paperSorting(paper)[0][1].created_at,
                                  }
                                } else {
                                  // 최근에 등록한 계약으로 변경
                                  if (
                                    new Date(contractStatus.ebook.created_at) <
                                    new Date(paperSorting(paper)[0][1].created_at)
                                  ) {
                                    let left_day = betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                    let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                    contractStatus.ebook = {
                                      start: dayjs(paperSorting(paper)[0][1].value1).format(
                                        "YYYY-MM-DD"
                                      ),
                                      end: dayjs(paperSorting(paper)[0][1].value2).format(
                                        "YYYY-MM-DD"
                                      ),
                                      ContractType: paperSorting(paper)[0][0].ContractType,
                                      day: betweenDay(
                                        dayjs(new Date()).format("YYYYMMDD"),
                                        end_date.format("YYYYMMDD")
                                      ),
                                      color: betweenDayColor(
                                        dayjs(new Date()).format("YYYYMMDD"),
                                        end_date.format("YYYYMMDD")
                                      ),
                                      value: paperSorting(paper)[0][3].value,
                                      end_date: end_date.format("YY.MM.DD"),
                                      created_at: paperSorting(paper)[0][1].created_at,
                                    }
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "웹툰계약"
                              ) {
                                if (!contractStatus.webtoon) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.webtoon = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "영상화 계약"
                              ) {
                                if (!contractStatus.video) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.video = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "게임화 계약"
                              ) {
                                if (!contractStatus.game) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.game = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "제작작가 계약"
                              ) {
                                if (!contractStatus.created) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.created = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "미분류 계약(기타)"
                              ) {
                                if (!contractStatus.etc) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.etc = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              }

                              if (
                                paperIndex ===
                                element.contract_documents.map((x) => x.contractPaper).length - 1
                              ) {
                                rowsData.push(
                                  createData(
                                    element.id,
                                    element.title,
                                    element.genre,
                                    element.bookcode,
                                    element.writer.joaraname,
                                    element.writer.ebookname,
                                    element.writer.name,
                                    contractStatus.agent,
                                    contractStatus.contract,
                                    contractStatus.nobless,
                                    contractStatus.premium,
                                    contractStatus.mixnobl,
                                    contractStatus.ebook,
                                    contractStatus.webtoon,
                                    contractStatus.video,
                                    contractStatus.game,
                                    contractStatus.integrated,
                                    contractStatus.coversub,
                                    contractStatus.seoninse,
                                    contractStatus.created,
                                    element.cover,
                                    element.ecover,
                                    element.joaraStatus,
                                    element.writer,
                                    contractStatus.bullseye,
                                    etcStatus.bullseye ? etcStatus : contractStatus.etc,
                                    contractStatus,
                                    ebookStatus,
                                    element.rating,
                                    element.publicDate,
                                    element.user?.id,
                                    element.contract_documents
                                  )
                                )
                              }
                            })
                        } else {
                          rowsData.push(
                            createData(
                              element.id,
                              element.title,
                              element.genre,
                              element.bookcode,
                              element.writer.joaraname,
                              element.writer.ebookname,
                              element.writer.name,
                              contractStatus.agent,
                              contractStatus.contract,
                              contractStatus.nobless,
                              contractStatus.premium,
                              contractStatus.mixnobl,
                              contractStatus.ebook,
                              contractStatus.webtoon,
                              contractStatus.video,
                              contractStatus.game,
                              contractStatus.integrated,
                              contractStatus.coversub,
                              contractStatus.seoninse,
                              contractStatus.created,
                              element.cover,
                              element.ecover,
                              element.joaraStatus,
                              element.writer,
                              contractStatus.bullseye,
                              etcStatus.bullseye ? etcStatus : contractStatus.etc,
                              contractStatus,
                              ebookStatus,
                              element.rating,
                              element.publicDate,
                              element.user?.id,
                              element.contract_documents
                            )
                          )
                        }
                        if (uniqueArr.length - 1 === index) {
                          rowsData.forEach((row, rowIndex) => {
                            rows.push(row)
                          })
                        }
                      })
                    }
                  })
                } else {
                  let elArray = []
                  let ok = 0
                  res.data.contracts.forEach((el, elIndex) => {
                    if (ok === 0) {
                      elArray.push(el)
                    }
                    if (elIndex === res.data.contracts.length - 1) {
                      const uniqueArr = elArray.filter((element, index) => {
                        return elArray.indexOf(element) === index
                      })
                      let rowsData = []

                      uniqueArr.forEach((element, index) => {
                        let contractStatus = {
                          agent: "",
                          contract: "",
                          nobless: "",
                          premium: "",
                          mixnobl: "",
                          ebook: "",
                          webtoon: "",
                          video: "",
                          game: "",
                          created: "",
                          etc: "",
                          bullseye: "",
                          integrated: "",
                          coversub: "",
                          seoninse: "",
                        }
                        let etcStatus = {
                          gray: "",
                          red: "",
                          orange: "",
                          green: "",
                          bullseye: false,
                        }
                        let ebookStatus = {
                          gray: "",
                          red: "",
                          orange: "",
                          green: "",
                          bullseye: false,
                        }
                        let etcdupCheck = element.contract_documents.filter((e) => {
                          return e.contractPaper[0][0].selectData[1].value === "미분류 계약(기타)"
                        })

                        let ebookdupCheck = element.contract_documents.filter((e) => {
                          return (
                            e.contractPaper[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                          )
                        })

                        if (etcdupCheck.length > 1) {
                          if (changeFilter === "기타") {
                            contractStatus.bullseye = "etc"
                          }

                          etcStatus.bullseye = true
                        }
                        if (ebookdupCheck.length > 1) {
                          if (changeFilter === "이북") {
                            contractStatus.bullseye = "ebookStatus"
                          }

                          ebookStatus.bullseye = true
                        }
                        const ebookList = []
                        if (changeFilter === "이북") {
                          ebookList.push("전자책 계약")
                          ebookList.push("전자책 계약 선인세용")
                        } else if (changeFilter === "웹툰") {
                          ebookList.push("웹툰계약")
                        } else if (changeFilter === "영상") {
                          ebookList.push("영상화 계약")
                        } else if (changeFilter === "게임") {
                          ebookList.push("게임화 계약")
                        } else if (changeFilter === "제작") {
                          ebookList.push("제작작가 계약")
                        } else if (changeFilter === "기타") {
                          ebookList.push("미분류 계약(기타)")
                        }
                        if (element.contract_documents.length > 0) {
                          element.contract_documents
                            .map((x) => x.contractPaper)
                            .forEach((paper, paperIndex) => {
                              if (
                                "미분류 계약(기타)".includes(
                                  paperSorting(paper)[0][0].selectData[1].value
                                )
                              ) {
                                if (
                                  etcStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] === ""
                                ) {
                                  etcStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] = 0
                                }
                                etcStatus[
                                  betweenDayColor2(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                ] += 1
                              }
                              if (
                                paperSorting(paper)[0][0].selectData[1].value.indexOf(
                                  "전자책 계약"
                                ) > -1
                              ) {
                                if (
                                  ebookStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] === ""
                                ) {
                                  ebookStatus[
                                    betweenDayColor2(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                  ] = 0
                                }
                                ebookStatus[
                                  betweenDayColor2(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                ] += 1
                              }
                              if (
                                paperSorting(paper)[0][0].selectData[1].value === "저작물 에이전트"
                              ) {
                                if (!contractStatus.agent) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.agent = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "독점적 이용 허락서(전속)"
                              ) {
                                if (!contractStatus.contract) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.contract = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합연재 계약(원계약)"
                              ) {
                                if (!contractStatus.integrated) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.integrated = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합) 표지 부속합의"
                              ) {
                                if (!contractStatus.coversub) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.coversub = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "통합) 선인세 부속합의"
                              ) {
                                if (!contractStatus.seoninse) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.seoninse = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "노블레스 계약"
                              ) {
                                if (!contractStatus.nobless) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.nobless = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "프리미엄 계약"
                              ) {
                                if (!contractStatus.premium) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.premium = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "노블레스 + 프리미엄 계약"
                              ) {
                                if (!contractStatus.mixnobl) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.mixnobl = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "전자책 계약" ||
                                paperSorting(paper)[0][0].selectData[1].value ===
                                  "전자책 계약 선인세용" ||
                                paperSorting(paper)[0][0].selectData[1].value ===
                                  "통합) 전자책 부속합의"
                              ) {
                                if (!contractStatus.ebook) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.ebook = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                    created_at: paperSorting(paper)[0][1].created_at,
                                  }
                                } else {
                                  // 최근에 등록한 계약으로 변경
                                  if (
                                    new Date(contractStatus.ebook.created_at) <
                                    new Date(paperSorting(paper)[0][1].created_at)
                                  ) {
                                    let left_day = betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                    )
                                    let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                    contractStatus.ebook = {
                                      start: dayjs(paperSorting(paper)[0][1].value1).format(
                                        "YYYY-MM-DD"
                                      ),
                                      end: dayjs(paperSorting(paper)[0][1].value2).format(
                                        "YYYY-MM-DD"
                                      ),
                                      ContractType: paperSorting(paper)[0][0].ContractType,
                                      day: betweenDay(
                                        dayjs(new Date()).format("YYYYMMDD"),
                                        end_date.format("YYYYMMDD")
                                      ),
                                      color: betweenDayColor(
                                        dayjs(new Date()).format("YYYYMMDD"),
                                        end_date.format("YYYYMMDD")
                                      ),
                                      value: paperSorting(paper)[0][3].value,
                                      end_date: end_date.format("YY.MM.DD"),
                                      created_at: paperSorting(paper)[0][1].created_at,
                                    }
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "웹툰계약"
                              ) {
                                if (!contractStatus.webtoon) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.webtoon = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "영상화 계약"
                              ) {
                                if (!contractStatus.video) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.video = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "게임화 계약"
                              ) {
                                if (!contractStatus.game) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.game = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value === "제작작가 계약"
                              ) {
                                if (!contractStatus.created) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.created = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              } else if (
                                paperSorting(paper)[0][0].selectData[1].value ===
                                "미분류 계약(기타)"
                              ) {
                                if (!contractStatus.etc) {
                                  let left_day = betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                  )
                                  let end_date = dayjs(paperSorting(paper)[0][1].value2)

                                  contractStatus.etc = {
                                    start: dayjs(paperSorting(paper)[0][1].value1).format(
                                      "YYYY-MM-DD"
                                    ),
                                    end: dayjs(paperSorting(paper)[0][1].value2).format(
                                      "YYYY-MM-DD"
                                    ),
                                    ContractType: paperSorting(paper)[0][0].ContractType,
                                    day: betweenDay(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    color: betweenDayColor(
                                      dayjs(new Date()).format("YYYYMMDD"),
                                      end_date.format("YYYYMMDD")
                                    ),
                                    value: paperSorting(paper)[0][3].value,
                                    end_date: end_date.format("YY.MM.DD"),
                                  }
                                }
                              }

                              if (
                                paperIndex ===
                                element.contract_documents.map((x) => x.contractPaper).length - 1
                              ) {
                                rowsData.push(
                                  createData(
                                    element.id,
                                    element.title,
                                    element.genre,
                                    element.bookcode,
                                    element.writer.joaraname,
                                    element.writer.ebookname,
                                    element.writer.name,
                                    contractStatus.agent,
                                    contractStatus.contract,
                                    contractStatus.nobless,
                                    contractStatus.premium,
                                    contractStatus.mixnobl,
                                    contractStatus.ebook,
                                    contractStatus.webtoon,
                                    contractStatus.video,
                                    contractStatus.game,
                                    contractStatus.integrated,
                                    contractStatus.coversub,
                                    contractStatus.seoninse,
                                    contractStatus.created,
                                    element.cover,
                                    element.ecover,
                                    element.joaraStatus,
                                    element.writer,
                                    contractStatus.bullseye,
                                    etcStatus.bullseye ? etcStatus : contractStatus.etc,
                                    contractStatus,
                                    ebookStatus,
                                    element.rating,
                                    element.publicDate,
                                    element.user?.id,
                                    element.contract_documents
                                  )
                                )
                              }
                            })
                        } else {
                          rowsData.push(
                            createData(
                              element.id,
                              element.title,
                              element.genre,
                              element.bookcode,
                              element.writer.joaraname,
                              element.writer.ebookname,
                              element.writer.name,
                              contractStatus.agent,
                              contractStatus.contract,
                              contractStatus.nobless,
                              contractStatus.premium,
                              contractStatus.mixnobl,
                              contractStatus.ebook,
                              contractStatus.webtoon,
                              contractStatus.video,
                              contractStatus.game,
                              contractStatus.integrated,
                              contractStatus.coversub,
                              contractStatus.seoninse,
                              contractStatus.created,
                              element.cover,
                              element.ecover,
                              element.joaraStatus,
                              element.writer,
                              contractStatus.bullseye,
                              etcStatus.bullseye ? etcStatus : contractStatus.etc,
                              contractStatus,
                              ebookStatus,
                              element.rating,
                              element.publicDate,
                              element.user?.id,
                              element.contract_documents
                            )
                          )
                        }
                        if (uniqueArr.length - 1 === index) {
                          rowsData.forEach((row, rowIndex) => {
                            rows.push(row)
                          })
                        }
                      })
                    }
                  })
                }
              }
            }
          }
        })
      if (stop) {
        setTimeout(() => {
          let excel_list = JSON.parse(JSON.stringify(rows))
          setrows_hide(excel_list)
          dispatch(loading_Apply(false))

          // if (eventExcel === "현재 페이지") {
          //   setrows_hide(
          //     excel_list.slice(
          //       FilterSearchActive
          //         ? (searchPage - 1) * FilterSearchActive
          //         : (page - 1) * rowsPerPage,
          //       FilterSearchActive ? searchRowsPerPage : rowsPerPage
          //     )
          //   )
          // } else {
          //   setrows_hide(excel_list)
          // }
        }, 1000)
        break
      }
    }
  }
  useEffect(() => {
    if (myData.accessTabs?.indexOf("wri") !== -1) {
      if (contractsSearch && FilterSearchActive) {
        dispatch(loading_Apply(true))
        if (contractsSearch.contracts.length > 0) {
          let elArray = []
          let ok = 0
          contractsSearch.contracts.forEach((el, elIndex) => {
            if (
              FilterDatas.ebookname !== "" ||
              FilterDatas.name !== "" ||
              FilterDatas.joaraname !== ""
            ) {
              if (FilterDatas.name !== "") {
                if (el.writer.name.indexOf(FilterDatas.name) > -1) {
                  elArray.push(el)
                }
              }
              if (FilterDatas.joaraname !== "") {
                if (el.writer.joaraname.indexOf(FilterDatas.joaraname) > -1) {
                  elArray.push(el)
                }
              }
              if (FilterDatas.ebookname !== "") {
                if (el.writer.ebookname.indexOf(FilterDatas.ebookname) > -1) {
                  elArray.push(el)
                }
              }
            } else {
              if (FilterDatas.contractDate) {
                ok += 1
                if (el.contract_documents.length > 0) {
                  el.contract_documents
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (
                        dayjs(paper[paper.length - 1][1].value1).format("YYYY-MM-DD") ===
                        FilterDatas.contractDate
                      ) {
                        elArray.push(el)
                      }
                    })
                }
              }
              if (FilterDatas.contractDateAccessDay && filterChipData.length > 0) {
                ok += 1
                let filterChipDataSet = []
                filterChipData.forEach((el) => {
                  if (el === chipsList[0]) {
                    filterChipDataSet.push("통합연재 계약(원계약)")
                  } else if (el === chipsList[1]) {
                    filterChipDataSet.push("통합) 표지 부속합의")
                  } else if (el === chipsList[2]) {
                    filterChipDataSet.push("통합) 선인세 부속합의")
                  } else if (el === chipsList[3]) {
                    filterChipDataSet.push("저작물 에이전트")
                  } else if (el === chipsList[4]) {
                    filterChipDataSet.push("독점적 이용 허락서(전속)")
                  } else if (el === chipsList[5]) {
                    filterChipDataSet.push("노블레스 계약")
                  } else if (el === chipsList[6]) {
                    filterChipDataSet.push("프리미엄 계약")
                  } else if (el === chipsList[7]) {
                    filterChipDataSet.push("노블레스 + 프리미엄 계약")
                  } else if (el === chipsList[8]) {
                    filterChipDataSet.push("부속계약")
                  } else if (el === chipsList[9]) {
                    filterChipDataSet.push("전자책 계약")
                    filterChipDataSet.push("전자책 계약 선인세용")
                  } else if (el === chipsList[10]) {
                    filterChipDataSet.push("웹툰계약")
                  } else if (el === chipsList[11]) {
                    filterChipDataSet.push("게임화 계약")
                  } else if (el === chipsList[12]) {
                    filterChipDataSet.push("영상화 계약")
                  } else if (el === chipsList[13]) {
                    filterChipDataSet.push("제작작가 계약")
                  } else if (el === chipsList[14]) {
                    filterChipDataSet.push("기타 2차 저작물 계약")
                  } else if (el === chipsList[15]) {
                    filterChipDataSet.push("미분류 계약(기타)")
                  }
                })
                if (el.contract_documents.length > 0) {
                  el.contract_documents
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (FilterDatas.contractDateAccessDay === contractList[0]) {
                        if (
                          betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          ) >= 365
                        ) {
                          if (filterChipDataSet.indexOf("부속계약") > -1) {
                            if (paperSorting(paper)[0][0].subContract.length > 0) {
                              if (filterChipDataSet.length > 1) {
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                elArray.push(el)
                              }
                            }
                          } else {
                            if (
                              filterChipDataSet.includes(
                                paperSorting(paper)[0][0].selectData[1].value
                              )
                            ) {
                              elArray.push(el)
                            }
                          }
                        }
                      } else if (FilterDatas.contractDateAccessDay === contractList[1]) {
                        if (
                          betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          ) >= 89 &&
                          betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          ) < 365
                        ) {
                          if (filterChipDataSet.indexOf("부속계약") > -1) {
                            if (paperSorting(paper)[0][0].subContract.length > 0) {
                              if (filterChipDataSet.length > 1) {
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                elArray.push(el)
                              }
                            }
                          } else {
                            if (
                              filterChipDataSet.includes(
                                paperSorting(paper)[0][0].selectData[1].value
                              )
                            ) {
                              elArray.push(el)
                            }
                          }
                        }
                      } else if (FilterDatas.contractDateAccessDay === contractList[2]) {
                        if (
                          betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          ) > 0 &&
                          betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          ) < 90
                        ) {
                          if (filterChipDataSet.indexOf("부속계약") > -1) {
                            if (paperSorting(paper)[0][0].subContract.length > 0) {
                              if (filterChipDataSet.length > 1) {
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                elArray.push(el)
                              }
                            }
                          } else {
                            if (
                              filterChipDataSet.includes(
                                paperSorting(paper)[0][0].selectData[1].value
                              )
                            ) {
                              elArray.push(el)
                            }
                          }
                        }
                      } else if (FilterDatas.contractDateAccessDay === contractList[3]) {
                        if (new Date(paperSorting(paper)[0][1].value2) < new Date()) {
                          if (filterChipDataSet.indexOf("부속계약") > -1) {
                            if (paperSorting(paper)[0][0].subContract.length > 0) {
                              if (filterChipDataSet.length > 1) {
                                if (
                                  filterChipDataSet.includes(
                                    paperSorting(paper)[0][0].selectData[1].value
                                  )
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                elArray.push(el)
                              }
                            }
                          } else {
                            if (
                              filterChipDataSet.includes(
                                paperSorting(paper)[0][0].selectData[1].value
                              )
                            ) {
                              elArray.push(el)
                            }
                          }
                        }
                      }
                    })
                }
              } else {
                if (FilterDatas.contractDateAccessDay) {
                  ok += 1
                  if (el.contract_documents.length > 0) {
                    el.contract_documents
                      .map((x) => x.contractPaper)
                      .forEach((paper, paperIndex) => {
                        if (FilterDatas.contractDateAccessDay === contractList[0]) {
                          if (
                            betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            ) >= 365
                          ) {
                            elArray.push(el)
                          }
                        } else if (FilterDatas.contractDateAccessDay === contractList[1]) {
                          if (
                            betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            ) >= 89 &&
                            betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            ) < 365
                          ) {
                            elArray.push(el)
                          }
                        } else if (FilterDatas.contractDateAccessDay === contractList[2]) {
                          if (
                            betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            ) > 0 &&
                            betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            ) < 90
                          ) {
                            elArray.push(el)
                          }
                        } else if (FilterDatas.contractDateAccessDay === contractList[3]) {
                          if (new Date(paperSorting(paper)[0][1].value2) < new Date()) {
                            elArray.push(el)
                          }
                        }
                      })
                  }
                }
                if (filterChipData.length > 0) {
                  ok += 1
                  let filterChipDataSet = []
                  filterChipData.forEach((el) => {
                    if (el === chipsList[0]) {
                      filterChipDataSet.push("통합연재 계약(원계약)")
                    } else if (el === chipsList[1]) {
                      filterChipDataSet.push("통합) 표지 부속합의")
                    } else if (el === chipsList[2]) {
                      filterChipDataSet.push("통합) 선인세 부속합의")
                    } else if (el === chipsList[3]) {
                      filterChipDataSet.push("저작물 에이전트")
                    } else if (el === chipsList[4]) {
                      filterChipDataSet.push("독점적 이용 허락서(전속)")
                    } else if (el === chipsList[5]) {
                      filterChipDataSet.push("노블레스 계약")
                    } else if (el === chipsList[6]) {
                      filterChipDataSet.push("프리미엄 계약")
                    } else if (el === chipsList[7]) {
                      filterChipDataSet.push("노블레스 + 프리미엄 계약")
                    } else if (el === chipsList[8]) {
                      filterChipDataSet.push("부속계약")
                    } else if (el === chipsList[9]) {
                      filterChipDataSet.push("전자책 계약")
                      filterChipDataSet.push("전자책 계약 선인세용")
                    } else if (el === chipsList[10]) {
                      filterChipDataSet.push("웹툰계약")
                    } else if (el === chipsList[11]) {
                      filterChipDataSet.push("게임화 계약")
                    } else if (el === chipsList[12]) {
                      filterChipDataSet.push("영상화 계약")
                    } else if (el === chipsList[13]) {
                      filterChipDataSet.push("제작작가 계약")
                    } else if (el === chipsList[14]) {
                      filterChipDataSet.push("기타 2차 저작물 계약")
                    } else if (el === chipsList[15]) {
                      filterChipDataSet.push("미분류 계약(기타)")
                    }
                  })
                  if (el.contract_documents.length > 0) {
                    // el.contract_documents
                    //   .map((x) => x.contractPaper)
                    el.contract_documents.forEach((contract, contractIndex) => {
                      let paper = contract.contractPaper
                      if (filterChipDataSet.indexOf("부속계약") > -1) {
                        if (paperSorting(paper)[0][0].subContract.length > 0) {
                          if (filterChipDataSet.length > 1) {
                            if (
                              filterChipDataSet.includes(
                                paperSorting(paper)[0][0].selectData[1].value
                              )
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체"
                              ) {
                                if (
                                  FilterDatas.department !== "" &&
                                  FilterDatas.department !== "전체" &&
                                  FilterDatas.managerName !== ""
                                ) {
                                  if (
                                    contract.users_permissions_user.name ===
                                      FilterDatas.managerName &&
                                    contract.users_permissions_user.department ===
                                      FilterDatas.department
                                  ) {
                                    elArray.push(el)
                                  }
                                } else {
                                  if (
                                    contract.users_permissions_user.department ===
                                    FilterDatas.department
                                  ) {
                                    elArray.push(el)
                                  }
                                }
                              } else if (FilterDatas.managerName !== "") {
                                if (
                                  contract.users_permissions_user.name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                elArray.push(el)
                              }
                            }
                          } else {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체"
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체" &&
                                FilterDatas.managerName !== ""
                              ) {
                                if (
                                  contract.users_permissions_user.name ===
                                    FilterDatas.managerName &&
                                  contract.users_permissions_user.department ===
                                    FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                              } else {
                                if (
                                  contract.users_permissions_user.department ===
                                  FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                              }
                            } else if (FilterDatas.managerName !== "") {
                              if (
                                contract.users_permissions_user.name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                            } else {
                              elArray.push(el)
                            }
                          }
                        }
                        if (
                          filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[1].value)
                        ) {
                          if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체" &&
                              FilterDatas.managerName !== ""
                            ) {
                              el.writer.users.forEach((user) => {
                                if (
                                  user.department === FilterDatas.department &&
                                  user.name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                              })
                            } else {
                              el.writer.users.forEach((user) => {
                                if (user.department === FilterDatas.department) {
                                  elArray.push(el)
                                }
                              })
                            }
                          } else if (FilterDatas.managerName !== "") {
                            el.writer.users.forEach((user) => {
                              if (user.name === FilterDatas.managerName) {
                                elArray.push(el)
                              }
                            })
                          } else {
                            elArray.push(el)
                          }
                        }
                      } else {
                        if (
                          filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[1].value)
                        ) {
                          if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체" &&
                              FilterDatas.managerName !== ""
                            ) {
                              if (
                                contract.users_permissions_user.name === FilterDatas.managerName &&
                                contract.users_permissions_user.department ===
                                  FilterDatas.department
                              ) {
                                elArray.push(el)
                              }
                            } else {
                              if (
                                contract.users_permissions_user.department ===
                                FilterDatas.department
                              ) {
                                elArray.push(el)
                              }
                            }
                          } else if (FilterDatas.managerName !== "") {
                            if (contract.users_permissions_user.name === FilterDatas.managerName) {
                              elArray.push(el)
                            }
                          } else {
                            elArray.push(el)
                          }
                        }
                      }
                    })
                  }
                } else {
                  if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                    if (
                      FilterDatas.department !== "" &&
                      FilterDatas.department !== "전체" &&
                      FilterDatas.managerName !== ""
                    ) {
                      ok += 1
                      el.contract_documents.forEach((paper) => {
                        if (
                          paper.users_permissions_user.name === FilterDatas.managerName &&
                          paper.users_permissions_user.department === FilterDatas.department
                        ) {
                          elArray.push(el)
                        }
                      })
                    } else {
                      ok += 1
                      el.contract_documents.forEach((paper) => {
                        if (paper.users_permissions_user.department === FilterDatas.department) {
                          elArray.push(el)
                        }
                      })
                    }
                  } else if (FilterDatas.managerName !== "") {
                    ok += 1
                    el.contract_documents.forEach((paper) => {
                      if (paper.users_permissions_user.name === FilterDatas.managerName) {
                        elArray.push(el)
                      }
                    })
                  }
                }
              }
              if (ok === 0) {
                elArray.push(el)
              }
            }

            if (elIndex === contractsSearch.contracts.length - 1) {
              const uniqueArr = elArray.filter((element, index) => {
                return elArray.indexOf(element) === index
              })
              setcontractsDatas(uniqueArr)
              let rowsData = []
              if (uniqueArr.length === 0) {
                setrows([])
                setTimeout(() => {
                  dispatch(loading_Apply(false))
                }, 1000)
                setrowlength(0)
              } else {
                setrowlength(uniqueArr.length)
              }

              uniqueArr.forEach((element, index) => {
                let contractStatus = {
                  agent: "",
                  contract: "",
                  nobless: "",
                  premium: "",
                  mixnobl: "",
                  ebook: "",
                  webtoon: "",
                  video: "",
                  game: "",
                  created: "",
                  etc: "",
                  bullseye: "",
                  integrated: "",
                  coversub: "",
                  seoninse: "",
                }
                let etcStatus = {
                  gray: "",
                  red: "",
                  orange: "",
                  green: "",
                  bullseye: false,
                }
                let ebookStatus = {
                  gray: "",
                  red: "",
                  orange: "",
                  green: "",
                  bullseye: false,
                }
                let etcdupCheck = element.contract_documents.filter((e) => {
                  return e.contractPaper[0][0].selectData[1].value === "미분류 계약(기타)"
                })
                let ebookdupCheck = element.contract_documents.filter((e) => {
                  return e.contractPaper[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                })
                if (etcdupCheck.length > 1) {
                  if (changeFilter === "기타") {
                    contractStatus.bullseye = "etc"
                  }
                  etcStatus.bullseye = true
                }
                if (ebookdupCheck.length > 1) {
                  if (changeFilter === "이북") {
                    contractStatus.bullseye = "ebookStatus"
                  }

                  ebookStatus.bullseye = true
                }
                const ebookList = []
                if (changeFilter === "이북") {
                  ebookList.push("전자책 계약")
                  ebookList.push("전자책 계약 선인세용")
                } else if (changeFilter === "웹툰") {
                  ebookList.push("웹툰계약")
                } else if (changeFilter === "영상") {
                  ebookList.push("영상화 계약")
                } else if (changeFilter === "게임") {
                  ebookList.push("게임화 계약")
                } else if (changeFilter === "제작") {
                  ebookList.push("제작작가 계약")
                } else if (changeFilter === "기타") {
                  ebookList.push("미분류 계약(기타)")
                }
                if (element.contract_documents.length > 0) {
                  element.contract_documents
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (
                        "미분류 계약(기타)".includes(paperSorting(paper)[0][0].selectData[1].value)
                      ) {
                        if (
                          etcStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          etcStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        etcStatus[
                          betweenDayColor2(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (
                        paperSorting(paper)[0][0].selectData[1].value.indexOf("전자책 계약") > -1
                      ) {
                        if (
                          ebookStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          ebookStatus[
                            betweenDayColor2(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        ebookStatus[
                          betweenDayColor2(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (paperSorting(paper)[0][0].selectData[1].value === "저작물 에이전트") {
                        if (!contractStatus.agent) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.agent = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "독점적 이용 허락서(전속)"
                      ) {
                        if (!contractStatus.contract) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.contract = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합연재 계약(원계약)"
                      ) {
                        if (!contractStatus.integrated) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.integrated = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 표지 부속합의"
                      ) {
                        if (!contractStatus.coversub) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.coversub = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 선인세 부속합의"
                      ) {
                        if (!contractStatus.seoninse) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.seoninse = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "노블레스 계약"
                      ) {
                        if (!contractStatus.nobless) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.nobless = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "프리미엄 계약"
                      ) {
                        if (!contractStatus.premium) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.premium = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "노블레스 + 프리미엄 계약"
                      ) {
                        if (!contractStatus.mixnobl) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.mixnobl = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "전자책 계약" ||
                        paperSorting(paper)[0][0].selectData[1].value === "전자책 계약 선인세용" ||
                        paperSorting(paper)[0][0].selectData[1].value === "통합) 전자책 부속합의"
                      ) {
                        if (!contractStatus.ebook) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.ebook = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                            created_at: paperSorting(paper)[0][1].created_at,
                          }
                        } else {
                          // 최근에 등록한 계약으로 변경
                          if (
                            new Date(contractStatus.ebook.created_at) <
                            new Date(paperSorting(paper)[0][1].created_at)
                          ) {
                            let left_day = betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                            let end_date = dayjs(paperSorting(paper)[0][1].value2)

                            contractStatus.ebook = {
                              start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                              end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                              ContractType: paperSorting(paper)[0][0].ContractType,
                              day: betweenDay(
                                dayjs(new Date()).format("YYYYMMDD"),
                                end_date.format("YYYYMMDD")
                              ),
                              color: betweenDayColor(
                                dayjs(new Date()).format("YYYYMMDD"),
                                end_date.format("YYYYMMDD")
                              ),
                              value: paperSorting(paper)[0][3].value,
                              end_date: end_date.format("YY.MM.DD"),
                              created_at: paperSorting(paper)[0][1].created_at,
                            }
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "웹툰계약") {
                        if (!contractStatus.webtoon) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.webtoon = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "영상화 계약") {
                        if (!contractStatus.video) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.video = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (paperSorting(paper)[0][0].selectData[1].value === "게임화 계약") {
                        if (!contractStatus.game) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.game = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "제작작가 계약"
                      ) {
                        if (!contractStatus.created) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.created = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      } else if (
                        paperSorting(paper)[0][0].selectData[1].value === "미분류 계약(기타)"
                      ) {
                        if (!contractStatus.etc) {
                          let left_day = betweenDay(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                          let end_date = dayjs(paperSorting(paper)[0][1].value2)

                          contractStatus.etc = {
                            start: dayjs(paperSorting(paper)[0][1].value1).format("YYYY-MM-DD"),
                            end: dayjs(paperSorting(paper)[0][1].value2).format("YYYY-MM-DD"),
                            ContractType: paperSorting(paper)[0][0].ContractType,
                            day: betweenDay(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            color: betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              end_date.format("YYYYMMDD")
                            ),
                            value: paperSorting(paper)[0][3].value,
                            end_date: end_date.format("YY.MM.DD"),
                          }
                        }
                      }

                      if (
                        paperIndex ===
                        element.contract_documents.map((x) => x.contractPaper).length - 1
                      ) {
                        rowsData.push(
                          createData(
                            element.id,
                            element.title,
                            element.genre,
                            element.bookcode,
                            element.writer.joaraname,
                            element.writer.ebookname,
                            element.writer.name,
                            contractStatus.agent,
                            contractStatus.contract,
                            contractStatus.nobless,
                            contractStatus.premium,
                            contractStatus.mixnobl,
                            contractStatus.ebook,
                            contractStatus.webtoon,
                            contractStatus.video,
                            contractStatus.game,
                            contractStatus.integrated,
                            contractStatus.coversub,
                            contractStatus.seoninse,
                            contractStatus.created,
                            element.cover,
                            element.ecover,
                            element.joaraStatus,
                            element.writer,
                            contractStatus.bullseye,
                            etcStatus.bullseye ? etcStatus : contractStatus.etc,
                            contractStatus,
                            ebookStatus,
                            element.rating,
                            element.publicDate,
                            element.user?.id,
                            element.contract_documents
                          )
                        )
                      }
                    })
                } else {
                  rowsData.push(
                    createData(
                      element.id,
                      element.title,
                      element.genre,
                      element.bookcode,
                      element.writer.joaraname,
                      element.writer.ebookname,
                      element.writer.name,
                      contractStatus.agent,
                      contractStatus.contract,
                      contractStatus.nobless,
                      contractStatus.premium,
                      contractStatus.mixnobl,
                      contractStatus.ebook,
                      contractStatus.webtoon,
                      contractStatus.video,
                      contractStatus.game,
                      contractStatus.integrated,
                      contractStatus.coversub,
                      contractStatus.seoninse,
                      contractStatus.created,
                      element.cover,
                      element.ecover,
                      element.joaraStatus,
                      element.writer,
                      contractStatus.bullseye,
                      etcStatus.bullseye ? etcStatus : contractStatus.etc,
                      contractStatus,
                      ebookStatus,
                      element.rating,
                      element.publicDate,
                      element.user?.id,
                      element.contract_documents
                    )
                  )
                }
                if (uniqueArr.length - 1 === index) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                  }, 1000)
                  setrows(rowsData)
                  setSelected(rowsData)
                }
              })
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      }
    }
  }, [contractsSearch, contractsSearchVariables])
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return 0
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return "light2"
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "error"
    } else {
      return "light2"
    }
  }
  const betweenDayColor2 = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return "gray"
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 365) {
      return "green"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 365 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "orange"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "red"
    } else {
      return "gray"
    }
  }

  useEffect(() => {
    if (myData.accessTabs?.indexOf("wri") !== -1) {
      dispatch(loading_Apply(true))
      localStorage.removeItem("writer")
      localStorage.removeItem("company")
      localStorage.removeItem("event")
      localStorage.removeItem("outsourcing")
      let CountData = {
        status: "공개",
        limit: 10000000,
      }
      if (FilterDatas.writingnow) {
        CountData.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        CountData.cover = "있음"
      }
      if (genreChipData.length > 0) {
        CountData.genre = genreChipData
      }
      if (FilterDatas.bookcode !== "") {
        CountData.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        CountData.title = FilterDatas.title
      }
      setcontractsCountFilter(CountData)
      let data = {
        sort: "created_at:desc",
        start: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        // ecover: FilterDatas.ecover ? "있음" : "없음",
        status: "공개",
      }
      if (FilterDatas.writingnow) {
        data.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        data.cover = "있음"
      }
      if (FilterDatas.bookcode !== "") {
        data.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        data.title = FilterDatas.title
      }
      if (genreChipData.length > 0) {
        data.genre = genreChipData
      }

      setcontractsVariables(data)
    }
  }, [page, rowsPerPage])
  useEffect(() => {
    setPage(1)
  }, [rowsPerPage])
  useEffect(() => {
    setsearchPage(1)
  }, [searchRowsPerPage])
  useEffect(() => {
    setsearchTotalpage(Math.ceil(rowlength / searchRowsPerPage))
    settotalpage(Math.ceil(rowlength / rowsPerPage))
  })
  const [contractDocumentsActive] = useLazyQuery(contractDocuments, {
    fetchPolicy: "network-only",
  })
  const handleDetail = (data, tab) => {
    if (myData.role.name === "bronze") {
      dispatch(loading_Apply(true))
      contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
        let ok = 0
        if (res.data.contractDocuments.length === 0) {
          setInfoAlert(InfoAlertSet)
          const InfoAlertSet1 = {
            dialog: true,
            title: "권한없음",
            content: "열람권한이 없습니다.",
            CanBtnText: "확인",
          }
          setInfoAlert(InfoAlertSet1)
          dispatch(loading_Apply(false))
        } else {
          res.data.contractDocuments.forEach((el, index) => {
            el.referrer?.forEach((el2) => {
              if (el2.id === myData.id) {
                ok += 1
              }
            })
            if (index === res.data.contractDocuments.length - 1) {
              dispatch(loading_Apply(false))

              if (ok > 0) {
                settabActive(0)
                if (tab) {
                  settabActive(tab)
                  setTimeout(() => {
                    settabActive(0)
                  }, 1000)
                }
                settype("detail")
                data.writername = data.name
                seteditData(data)
                seteditId(data.writer.id)
                setwriterDialog(true)
              } else {
                setInfoAlert(InfoAlertSet)
                const InfoAlertSet1 = {
                  dialog: true,
                  title: "권한없음",
                  content: "열람권한이 없습니다.",
                  CanBtnText: "확인",
                }
                setInfoAlert(InfoAlertSet1)
              }
            }
          })
        }
      })
    } else if (myData.role.name === "silver") {
      if (data.user === myData.id) {
        settabActive(0)
        if (tab) {
          settabActive(tab)
          setTimeout(() => {
            settabActive(0)
          }, 1000)
        }
        settype("detail")
        data.writername = data.name
        seteditData(data)
        seteditId(data.writer.id)
        setwriterDialog(true)
      } else {
        contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
          let ok = 0
          if (res.data.contractDocuments.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
          } else {
            res.data.contractDocuments.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.contractDocuments.length - 1) {
                if (ok > 0) {
                  settabActive(0)
                  if (tab) {
                    settabActive(tab)
                    setTimeout(() => {
                      settabActive(0)
                    }, 1000)
                  }
                  settype("detail")
                  data.writername = data.name
                  seteditData(data)
                  seteditId(data.writer.id)
                  setwriterDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      }
    } else {
      settabActive(0)
      if (tab) {
        settabActive(tab)
        setTimeout(() => {
          settabActive(0)
        }, 1000)
      }
      settype("detail")
      data.writername = data.name
      seteditData(data)
      seteditId(data.writer.id)
      setwriterDialog(true)
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = () => {
    if (rows.length === selected.length) {
      setSelected([])
    } else {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
    }
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }
  const excelExportActive = async () => {
    await call_all_contracts()
  }

  useEffect(() => {
    console.log(rows_hide)
    if (rows_hide.length > 0) {
      document.getElementById("excelExport").click()
      createDonwloadLogActive({
        variables: {
          userId: String(myData.id),
          userName: myData.name,
          userPosition: myData.position,
          userDepartment: myData.department,
          userEmail: myData.email,
          donwloadType: "excel",
          downloadList:
            eventExcel === "전체 내역" ? [{ "받은 데이터 개수": rows_hide.length }] : rows_hide,
          downloadDate: new Date(),
        },
      })
    }
  }, [rows_hide])

  const isSelected = (name) => selected.indexOf(name) !== -1

  const handleRowsPerPageChange = (event) => {
    if (FilterSearchActive) {
      setsearchRowsPerPage(event.target.value)
    } else {
      setRowsPerPage(event.target.value)
    }
  }
  const handlePageSearchChange = (event, value) => {
    setsearchPage(value)
  }
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const iconActive = () => {
    setopenFilter(false)
  }
  const openFilterInput = () => {
    setopenFilter(true)
  }
  const filterSave = () => {
    localStorage.setItem("contract", JSON.stringify(FilterDatas))
    setInfoAlert({
      dialog: true,
      title: "검색 필터 저장",
      content: "입력하신 검색 필터가 저장되었습니다.",
      CanBtnText: "확인",
    })
  }
  const filterClose = () => {
    setopenFilter(false)
    setloading(true)
    if (localStorage.getItem("contract")) {
      setFilterDatas(JSON.parse(localStorage.getItem("contract")))
    } else {
      setFilterDatas(orFilterData)
    }
  }
  const openGenreFilterDialog = () => {
    setopenGenreFilter(!openGenreFilter)
  }
  const resetSearch = () => {
    setopenFilter(true)
    setloading(false)
  }
  const genreSave = () => {
    let showText = ""
    if (genreChipData.length > 0) {
      if (genreChipData.length === 1) {
        showText = String(genreChipData[0])
      } else {
        showText = String(genreChipData[0]) + " 외 " + String(genreChipData.length - 1)
      }
    }
    let datas = FilterDatas
    setFilterDatas({ ...datas, genre: showText })
    setopenGenreFilter(false)
  }
  const [loading, setloading] = useState(false)

  const filterSearch = () => {
    setloading(false)
    setopenFilter(false)
    setFilterSearchActive(true)
    // setPage(1)
    dispatch(loading_Apply(true))
    setloading(true)
    setsearchPage(1)
    let data = {
      sort: "created_at:desc",
      start: 0,
      limit: 999999999,
      status: "공개",
    }
    if (FilterDatas.writingnow) {
      data.joaraStatus = "연재중"
    }
    if (FilterDatas.cover) {
      data.cover = "있음"
    }
    if (FilterDatas.bookcode !== "") {
      data.bookcode = FilterDatas.bookcode
    }
    if (FilterDatas.title !== "") {
      data.title = FilterDatas.title
    }
    if (genreChipData.length > 0) {
      data.genre = genreChipData
    }
    setcontractsSearchVariables(data)
    localStorage.setItem("contract", JSON.stringify(FilterDatas))
    handleSearchedFilter()
  }
  const filterChip = (x) => {
    let array = [...filterChipData]
    if (array.indexOf(x) === -1) {
      array.push(x)
    } else {
      array.splice(array.indexOf(x), 1)
    }
    setfilterChipData(array)
  }
  const genreChip = (x) => {
    let array = [...genreChipData]
    if (array.indexOf(x) === -1) {
      array.push(x)
    } else {
      array.splice(array.indexOf(x), 1)
    }
    setgenreChipData(array)
  }
  const TableReload = () => {
    if (!FilterSearchActive) {
      let CountData = {
        status: "공개",
        limit: 10000000,
      }
      if (FilterDatas.writingnow) {
        CountData.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        CountData.cover = "있음"
      }
      if (genreChipData.length > 0) {
        CountData.genre = genreChipData
      }
      if (FilterDatas.bookcode !== "") {
        CountData.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        CountData.title = FilterDatas.title
      }
      setcontractsCountFilter(CountData)
      let data = {
        sort: "created_at:desc",
        start: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        // ecover: FilterDatas.ecover ? "있음" : "없음",
        status: "공개",
      }
      if (FilterDatas.writingnow) {
        data.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        data.cover = "있음"
      }
      if (FilterDatas.bookcode !== "") {
        data.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        data.title = FilterDatas.title
      }
      if (genreChipData.length > 0) {
        data.genre = genreChipData
      }
      setcontractsVariables(data)
    } else {
      let data = {
        sort: "created_at:desc",
        start: 0,
        limit: 999999999,
        status: "공개",
      }
      if (FilterDatas.writingnow) {
        data.joaraStatus = "연재중"
      }
      if (FilterDatas.cover) {
        data.cover = "있음"
      }
      if (FilterDatas.bookcode !== "") {
        data.bookcode = FilterDatas.bookcode
      }
      if (FilterDatas.title !== "") {
        data.title = FilterDatas.title
      }
      if (genreChipData.length > 0) {
        data.genre = genreChipData
      }
      setcontractsSearchVariables(data)
      handleSearchedFilter()
    }
  }
  const FilterReload = () => {
    setgenreChipData([])
    setfilterChipData([])
    setFilterDatas(orFilterData)
  }
  const chipsList = [
    "통합", // 0
    "표지", // 1
    "선인세", // 2
    "에이전트", //3
    "전속계약", //4
    "노블레스", //5
    "프리미엄", //6
    "노블X프리", //7
    "부속계약", //8
    "이북", //9
    "웹툰화", //10
    "게임화", //11
    "영상화", //12
    "제작작가", //13
    "기타 2차저작물", //14
    "기타", //15
  ]
  const chipsListSelects = chipsList.map((x) => {
    return (
      <Chip
        sx={{
          mr: 2,
          height: 40,
          mt: 1,
          border: filterChipData.indexOf(x) > -1 ? "1px solid #5C38FF" : "none",
          backgroundColor: filterChipData.indexOf(x) > -1 ? "#FAF8FF" : "#F1F1F5",
          fontWeight: filterChipData.indexOf(x) > -1 ? 900 : 500,
        }}
        color={filterChipData.indexOf(x) > -1 ? "primary" : "secondary"}
        variant="outlined"
        className="filter-chips"
        key={x}
        label={x}
        style={{
          color: filterChipData.indexOf(x) > -1 ? "" : "#9AA0A6",
        }}
        onClick={() => filterChip(x)}
      ></Chip>
    )
  })
  const genreChipsList =
    settings_ref && settings_ref.current
      ? settings_ref.current.genre
      : [
          "로맨스",
          "판타지",
          "로판",
          "현판",
          "무협",
          "퓨전/현대물",
          "게임",
          "미스터리",
          "역사물/대체역사",
          "패러디",
          "스포츠",
          "BL",
          "라이트노벨",
          "SF",
          "팬픽",
          "GL",
        ]
  const genreChipsListSelects = genreChipsList.map((x, index) => {
    return (
      <Grid
        xs={3}
        item
        key={x}
        sx={{
          pr: index % 4 === 3 ? 0 : 1.5,
        }}
      >
        <Chip
          sx={{
            height: 40,
            width: "100%",
            p: 0,
            mt: 1,
            border: genreChipData.indexOf(x) > -1 ? "1px solid #5C38FF" : "none",
            backgroundColor: genreChipData.indexOf(x) > -1 ? "#FAF8FF" : "#F1F1F5",
            fontWeight: genreChipData.indexOf(x) > -1 ? 900 : 500,
          }}
          color={genreChipData.indexOf(x) > -1 ? "primary" : "secondary"}
          variant="outlined"
          className="oneSelect-filter-chips"
          label={x}
          onClick={() => genreChip(x)}
        ></Chip>
      </Grid>
    )
  })
  const contractList = ["1년이상 남음", "3개월 이상~1년미만", "3개월 미만(종료 전)", "종료된 계약"]
  const contractListSelects = contractList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const workList = ["작품"]
  const workListSelects = workList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const padingList = [10, 20, 50, 100]
  const padingListSelects = padingList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const teamList = settings_ref.current
    ? settings_ref.current.department
    : [
        "전체",
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ]
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [searchedFilter, setsearchedFilter] = useState(orFilterData)
  const handleSearchedFilter = () => {
    let datas = FilterDatas
    datas.filterChipData = filterChipData.join()
    setsearchedFilter(datas)
  }

  const authroiztionData = (type, data, row) => {
    if (type === "name") {
      if (data.length === 2) {
        if (
          row.user === myData.id ||
          row.contract_documents.filter((x) =>
            x.referrer ? x.referrer.map((x) => x.id).indexOf(myData.id) > -1 : false
          ).length > 0
        ) {
          return data
        } else return data.substring(0, 1) + "*"
      } else if (data.length === 3) {
        if (
          row.user === myData.id ||
          row.contract_documents.filter((x) =>
            x.referrer ? x.referrer.map((x) => x.id).indexOf(myData.id) > -1 : false
          ).length > 0
        ) {
          return data
        } else return data.substring(0, 1) + "**"
      } else {
        if (data.length > 1) {
          let star = "*".repeat(data.length - 2)
          if (
            row.user === myData.id ||
            row.contract_documents.filter((x) =>
              x.referrer ? x.referrer.map((x) => x.id).indexOf(myData.id) > -1 : false
            ).length > 0
          ) {
            return data
          } else return data.substring(0, 2) + star
        } else {
          return data
        }
      }
    }
  }
  function SearchedField() {
    let datas = searchedFilter
    const [searchedData, setsearchedData] = useState(true)
    useEffect(() => {
      setsearchedData(Object.values(datas).every((x) => x === false || x === "" || x === null))
    }, [searchedFilter])
    let stringData = ""
    for (const key in datas) {
      let value
      let keyValue
      if (datas[key] === "" || datas[key] === null) {
        value = "-"
      } else if (datas[key] === true) {
        value = "Y"
      } else if (datas[key] === false) {
        value = "N"
      } else {
        value = datas[key]
      }
      if (key === "department") {
        keyValue = "담당부서"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "title") {
        keyValue = "작품명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "genre") {
        keyValue = "장르"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "bookcode") {
        keyValue = "북코드"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "cover") {
        keyValue = "표지"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "contractDateAccessDay") {
        keyValue = "계약유효일"
        stringData = stringData + `${keyValue}:${value}, `
        // } else if (key === "contractDate") {
        //   keyValue = "최초계약일"
        //   stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "writingnow") {
        keyValue = "조아라 연재 작품"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "ebookname") {
        keyValue = "이북 필명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "managerName") {
        keyValue = "담당자 명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "filterChipData") {
        keyValue = "필수 계약"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "name") {
        keyValue = "작가 본명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "joaraname") {
        keyValue = "조아라 필명"
        stringData = stringData + `${keyValue}:${value}, `
      }
    }
    const filterCloseBtn = () => {
      setFilterDatas(orFilterData)
      setsearchedFilter(orFilterData)
      setsearchedData(true)
      setFilterSearchActive(false)
      let CountData = {
        status: "공개",
        limit: 10000000,
      }
      setcontractsCountFilter(CountData)
      setPage(1)
      setcontractsVariables({
        sort: "created_at:desc",
        start: 0,
        limit: rowsPerPage,
        status: "공개",
      })
      setloading(false)
    }
    let stringShowData = stringData.substring(0, stringData.length - 2)
    return (
      <SuiBox>
        {FilterSearchActive ? (
          <SuiBox
            p={2}
            mr={0}
            ml={1.5}
            mt={4}
            display="flex"
            style={{ alignItems: "center", width: "fit-content" }}
            className="filter-data-box"
          >
            <div>
              <SuiBox display="flex" style={{ alignItems: "center" }}>
                <SuiTypography
                  style={{ fontSize: "16px", color: "#535353" }}
                  verticalAlign="middle"
                >
                  검색내용
                </SuiTypography>
                <IconButton
                  color="text2"
                  size="small"
                  onClick={() => filterCloseBtn()}
                  aria-label="close"
                  sx={{
                    p: 0,
                    ml: "auto",
                  }}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiTypography style={{ fontSize: "16px", color: "#707070" }} verticalAlign="middle">
                {stringShowData}
              </SuiTypography>
            </div>
          </SuiBox>
        ) : (
          <SuiBox mt={12} style={{ alignItems: "center", width: "66%" }}></SuiBox>
        )}
      </SuiBox>
    )
  }
  return (
    <DashboardLayout className="main-dashboard">
      <WriterDialog
        open={writerDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={editData}
        tabActive={tabActive}
      />
      <Box sx={{ width: "100%" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1201 }}
          open={openFilter}
          onClick={filterClose}
        ></Backdrop>
        <SuiBox display="flex" justifyContent="start" height="100%">
          <SuiBox
            sx={{
              width: openFilter ? 800 : 400,
              height: 40,
              zIndex: 1202,
              ml: 1.5,
            }}
            className={
              openFilter
                ? "filter-right-icon-filter-open filter-overlay-radius"
                : "filter-right-icon"
            }
            onClick={() => {
              if (!openFilter) {
                openFilterInput()
              }
            }}
          >
            <SuiInput
              icon={
                openFilter
                  ? { component: "close", direction: "right" }
                  : { component: "search", direction: "right" }
              }
              iconActive={iconActive}
              type="text"
              placeholder="작품명을 입력해주세요"
              value={FilterDatas.title}
              onClick={openFilterInput}
              onChange={handleFilterDatas("title")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  filterSearch()
                }
              }}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          sx={{
            display: openFilter ? "" : "none",
            position: "absolute",
            zIndex: 5,
            backgroundColor: "#ffffff",
            p: 2,
            width: 800,
            border: 1,
            boxShadow: 2,
            borderColor: "#d2d6da",
            borderRadius: "0 0 10px 10px",
            zIndex: 1202,
            ml: 1.5,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  상세검색
                </SuiTypography>
                <SuiBox
                  ml={0.5}
                  mt={0.5}
                  component="img"
                  src="/reload.png"
                  alt="Soft UI Logo"
                  height="36px"
                  className="cursor"
                  onClick={() => FilterReload()}
                />
                <SuiButton
                  sx={{ fontSize: 14, width: 10, minWidth: 25, height: 20, ml: "auto", p: 0 }}
                  variant="text"
                  color="info2"
                  size="small"
                  onClick={() => filterSave()}
                >
                  저장
                </SuiButton>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  담당자 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.department === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen1(!FiltersOpen1)}
                  >
                    <InputLabel className="smallLabel">부서</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen1(!FiltersOpen1)}
                        />
                      )}
                      open={FiltersOpen1}
                      label="담당부서"
                      value={FilterDatas.department}
                      onChange={handleFilterDatas("department")}
                      className="setting-border-radius-small"
                    >
                      {teamListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="담당자 명"
                    value={FilterDatas.managerName}
                    onChange={handleFilterDatas("managerName")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  작가 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <SuiInput
                    type="text"
                    placeholder="작가 본명"
                    value={FilterDatas.name}
                    onChange={handleFilterDatas("name")}
                  />
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="조아라 필명"
                    value={FilterDatas.joaraname}
                    onChange={handleFilterDatas("joaraname")}
                  />
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="이북 필명"
                    value={FilterDatas.ebookname}
                    onChange={handleFilterDatas("ebookname")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  작품 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }} className="SuiInput-right-icon">
                  <SuiInput
                    icon={{ component: <KeyboardArrowDownIcon />, direction: "right" }}
                    type="text"
                    placeholder="장르 선택"
                    value={FilterDatas.genre}
                    onClick={openGenreFilterDialog}
                  />
                  <SuiBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      display: openGenreFilter ? "" : "none",
                      position: "absolute",
                      zIndex: 5,
                      backgroundColor: "#ffffff",
                      p: 2,
                      top: "240px",
                      width: 432,
                      border: 1,
                      boxShadow: 2,
                      borderColor: "#d2d6da",
                      borderRadius: "0 0 10px 10px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <SuiBox display="flex" alignItems="center">
                          <SuiTypography
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            color="primary"
                            onClick={() => setgenreChipData(genreChipsList)}
                          >
                            전체선택
                          </SuiTypography>
                          <SuiBox
                            ml={0.5}
                            pt={0.5}
                            component="img"
                            src="/reload.png"
                            alt="Soft UI Logo"
                            height="36px"
                            className="cursor"
                            onClick={() => setgenreChipData([])}
                          />
                          <SuiButton
                            sx={{
                              width: 10,
                              minWidth: 25,
                              height: 20,
                              ml: "auto",
                              p: 0,
                              fontSize: 14,
                            }}
                            variant="text"
                            color="info2"
                            size="small"
                            onClick={() => genreSave()}
                          >
                            완료
                          </SuiButton>
                        </SuiBox>
                      </Grid>
                      <Grid item xs={12} sx={{}}>
                        <SuiBox
                          display="flex"
                          justifyContent="start"
                          height="100%"
                          alignItems="center"
                        >
                          <Grid container>{genreChipsListSelects}</Grid>
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>
                <SuiBox sx={{ width: 128, height: 40, mx: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="북코드 입력"
                    value={FilterDatas.bookcode}
                    onChange={handleFilterDatas("bookcode")}
                  />
                </SuiBox>
                <FormControlLabel
                  label="조아라 연재 작품"
                  labelPlacement="start"
                  className="filter-checkbox"
                  control={
                    <Checkbox
                      checked={FilterDatas.writingnow}
                      icon={<AiOutlineCheckCircle />}
                      checkedIcon={<AiFillCheckCircle />}
                      onChange={handleFilterDatas("writingnow")}
                    />
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label="표지"
                  labelPlacement="start"
                  className="filter-checkbox"
                  control={
                    <Checkbox
                      checked={FilterDatas.cover}
                      icon={<AiOutlineCheckCircle />}
                      checkedIcon={<AiFillCheckCircle />}
                      onChange={handleFilterDatas("cover")}
                    />
                  }
                  sx={{ display: "none" }}
                ></FormControlLabel>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  계약 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.contractDateAccessDay === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <InputLabel className="smallLabel">계약 유효일</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      label="계약 유효일"
                      value={FilterDatas.contractDateAccessDay}
                      onChange={handleFilterDatas("contractDateAccessDay")}
                      className="setting-border-radius-small"
                    >
                      {contractListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                {/* <SuiBox sx={{ width: 128, height: 40, ml: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                    <DatePicker
                      inputFormat={"yyyy-MM-dd"}
                      mask={"____-__-__"}
                      label="최초 계약일"
                      value={FilterDatas.contractDate}
                      onChange={handleFilterDatas("contractDate")}
                      renderInput={(params) => (
                        <TextField className="data-picker-textfiled" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </SuiBox> */}
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  필수 계약
                </SuiTypography>
                <SuiBox sx={{ width: 600, ml: 3 }}>{chipsListSelects}</SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiButton
                  variant="text"
                  color="text2"
                  sx={{
                    ml: "auto",
                    mr: 2,
                    height: 40,
                    border: 1,
                    width: 93,
                    borderColor: "#707070",
                    fontSize: 14,
                  }}
                  onClick={() => filterClose()}
                >
                  취소
                </SuiButton>
                <SuiButton
                  color="primary"
                  sx={{ fontSize: 14, height: 40 }}
                  onClick={() => filterSearch()}
                >
                  이대로 검색하기
                </SuiButton>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <Paper sx={{ width: "100%", boxShadow: 0, marginTop: "-10px" }} className="main-layout">
          <SearchedField />
          <SuiBox ml="" pl={0} pb={2} mx={0} display="flex" style={{ alignItems: "center" }}>
            <SuiBox
              mt={0.5}
              component="img"
              src="/reload.png"
              alt="Soft UI Logo"
              height="48px"
              className="cursor"
              onClick={() => TableReload()}
            />
            <SuiTypography
              style={{ fontSize: "16px", fontWeight: "medium" }}
              verticalAlign="middle"
            >
              총 작품 수:&nbsp;
            </SuiTypography>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "bold", mr: "auto" }}>
              {rowlength} 건
            </SuiTypography>
            {myData.role.name !== "bronze" && myData.role.name !== "silver" ? (
              <>
                <SuiBox width="120px" height="43px" ml="auto" mr="-11px">
                  <FormControl
                    fullWidth
                    onClick={() => setFiltersOpen10(!FiltersOpen10)}
                    sx={{
                      "> div": {
                        minHeight: "30px",
                        border: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen10(!FiltersOpen10)}
                        />
                      )}
                      open={FiltersOpen10}
                      value={eventExcel}
                      onChange={(e) => {
                        seteventExcel(e.target.value)
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="전체 내역">전체 내역</MenuItem>
                      <MenuItem value="현재 페이지">현재 페이지</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>

                <SuiBox
                  sx={{ height: "61px", marginTop: "5px", zIndex: 2 }}
                  component="img"
                  src="/excel.png"
                  alt="Soft UI Logo"
                  height="52px"
                  className="cursor"
                  onClick={excelExportActive}
                />
              </>
            ) : (
              <SuiBox ml="auto" height="52px" className="cursor" />
            )}
            <SuiBox width="70px" height="44px">
              <FormControl
                fullWidth
                sx={{ "> div": { border: "none" } }}
                onClick={() => setFiltersOpen4(!FiltersOpen4)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen4(!FiltersOpen4)}
                    />
                  )}
                  open={FiltersOpen4}
                  value={FilterSearchActive ? searchRowsPerPage : rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {padingListSelects}
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
          <div style={{ display: "none" }}>
            <ReactHTMLTableToExcel
              id="excelExport"
              className="download-table-xls-button"
              table="table-to-xls-hidden"
              filename={`작품 리스트 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`}
              sheet="tablexls"
              buttonText={"Download as XLS"}
            />
          </div>

          <TableContainer sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
                changeFilter2={setchangeFilter2}
                changeFilter3={setchangeFilter3}
              />
              <TableBody>
                {stableSort(
                  FilterSearchActive
                    ? rows.slice(
                        (searchPage - 1) * searchRowsPerPage,
                        (searchPage - 1) * searchRowsPerPage + searchRowsPerPage
                      )
                    : rows,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                    >
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {String(row.id).padStart(5, "0")}
                      </TableCell>
                      {/* 테이블에 전체 열 삭제 작업 완료 20221211 - 박경실 */}
                      {row.title.length < 20 ? (
                        <TableCell
                          id={labelId}
                          className="td-Setting td-fontSetting"
                          align="center"
                          onClick={() => handleDetail(row, 1)}
                        >
                          {row.title}
                        </TableCell>
                      ) : (
                        <Tooltip
                          arrow={false}
                          color="white"
                          classes={{ popper: "tooltip-light" }}
                          title={
                            <SuiBox>
                              <SuiTypography
                                style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                                verticalAlign="middle"
                              >
                                {row.title}
                              </SuiTypography>
                            </SuiBox>
                          }
                        >
                          <TableCell
                            id={labelId}
                            onClick={() => handleDetail(row, 1)}
                            align="center"
                            className="td-Setting td-fontSetting"
                          >
                            {row.title}
                          </TableCell>
                        </Tooltip>
                      )}

                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.bookcode}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.bookname}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.joaraname}
                      </TableCell>

                      <TableCell
                        onClick={() => handleDetail(row)}
                        className="td-Setting td-fontSetting"
                        align="center"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {myData.role.name !== "bronze" && myData.role.name !== "silver"
                          ? row.name
                          : authroiztionData("name", row.name, row)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {changeFilter3 === "통합" ? (
                          <IconCircle
                            ContractType={row.integrated.ContractType}
                            color={row.integrated.color}
                            day={row.integrated.day}
                            value={row.integrated.value}
                            end_date={row.integrated.end_date}
                          />
                        ) : changeFilter3 === "표지" ? (
                          <IconCircle
                            ContractType={row.coversub.ContractType}
                            color={row.coversub.color}
                            day={row.coversub.day}
                            value={row.coversub.value}
                            end_date={row.coversub.end_date}
                          />
                        ) : (
                          <IconCircle
                            ContractType={row.seoninse.ContractType}
                            color={row.seoninse.color}
                            day={row.seoninse.day}
                            value={row.seoninse.value}
                            end_date={row.seoninse.end_date}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        <IconCircle
                          ContractType={row.ebook.ContractType}
                          color={row.ebook.color}
                          day={row.ebook.day}
                          value={row.ebook.value}
                          end_date={row.ebook.end_date}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        <IconCircle
                          ContractType={row.premium.ContractType}
                          color={row.premium.color}
                          day={row.premium.day}
                          value={row.premium.value}
                          end_date={row.premium.end_date}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        <IconCircle
                          ContractType={row.mixnobl.ContractType}
                          color={row.mixnobl.color}
                          day={row.mixnobl.day}
                          value={row.mixnobl.value}
                          end_date={row.mixnobl.end_date}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        <IconCircle
                          ContractType={row.nobless.ContractType}
                          color={row.nobless.color}
                          day={row.nobless.day}
                          value={row.nobless.value}
                          end_date={row.nobless.end_date}
                        />
                      </TableCell>

                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {changeFilter2 === "에이전트" ? (
                          <IconCircle
                            ContractType={row.agent.ContractType}
                            color={row.agent.color}
                            day={row.agent.day}
                            value={row.agent.value}
                            end_date={row.agent.end_date}
                          />
                        ) : (
                          <IconCircle
                            ContractType={row.contract.ContractType}
                            color={row.contract.color}
                            day={row.contract.day}
                            value={row.contract.value}
                            end_date={row.contract.end_date}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.bullseye ? (
                          <Tooltip
                            arrow={false}
                            color="white"
                            classes={{ popper: "tooltip-light" }}
                            title={
                              <SuiBox>
                                <SuiTypography
                                  style={{ fontSize: "14px", color: "#9AA0A6" }}
                                  verticalAlign="middle"
                                >
                                  계약 현황
                                </SuiTypography>
                                <DataOfNobless_gray stat={row[row.bullseye].gray} />
                                {row[row.bullseye].gray === "" ? (
                                  <span
                                    style={{
                                      display: "none",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      display: row[row.bullseye].red === "" ? "none" : "",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                )}
                                <DataOfNobless_red stat={row[row.bullseye].red} />
                                {row[row.bullseye].gray === "" && row[row.bullseye].red === "" ? (
                                  <span
                                    style={{
                                      display: "none",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      display: row[row.bullseye].orange === "" ? "none" : "",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                )}
                                <DataOfNobless_orange stat={row[row.bullseye].orange} />
                                {row[row.bullseye].gray === "" &&
                                row[row.bullseye].red === "" &&
                                row[row.bullseye].orange === "" ? (
                                  <span
                                    style={{
                                      display: "none",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      display: row[row.bullseye].green === "" ? "none" : "",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;/&nbsp;
                                  </span>
                                )}
                                <DataOfNobless_green stat={row[row.bullseye].green} />
                              </SuiBox>
                            }
                          >
                            <SuiBox
                              component="img"
                              src={bullseye}
                              // display="block"
                              left={0}
                              sx={{
                                verticalAlign: "middle",
                                width: 20,
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <IconCircle
                            ContractType={contractTypeShow(row.contractStatus).ContractType}
                            color={
                              // localStorage.getItem("miniSidenav") === "false"
                              //   ? row.ebook.color
                              //   :
                              contractTypeShow(row.contractStatus).color
                            }
                            day={
                              // localStorage.getItem("miniSidenav") === "false"
                              //   ? row.ebook.day
                              //   :
                              contractTypeShow(row.contractStatus).day
                            }
                            value={
                              // localStorage.getItem("miniSidenav") === "false"
                              //   ? row.ebook.value
                              //   :
                              contractTypeShow(row.contractStatus).value
                            }
                            end_date={contractTypeShow(row.contractStatus).end_date}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting2"
                        sx={{
                          borderLeft: "1px solid #F1F1F5",
                          color: "#535353",
                          fontSize: 14,
                        }}
                      >
                        {row.rating}
                      </TableCell>
                      {/* <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting2"
                        sx={{
                          color: "#535353",
                          fontSize: 14,
                        }}
                      >
                        {row.publicDate}
                      </TableCell> */}
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting2"
                        sx={{
                          color: "#535353",
                          fontSize: 14,
                        }}
                      >
                        {row.sign}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                        style={{
                          color: "#535353",
                          fontSize: 14,
                        }}
                      >
                        {row.serialization}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {/* 액셀에 출력되는 테이블 excel-export-table */}
            <Table
              style={{ display: "none" }}
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls-hidden"
            >
              {/* 총 수 row  - start */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>
                      <span>총 작품 수</span>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>
                      <span>{rows_hide.length}</span>
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* 총 수 row  - end */}
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
                showchangeFilter={changeFilter}
                changeFilter2={setchangeFilter2}
                changeFilter3={setchangeFilter3}
                excelTable={true}
              />
              <TableBody>
                {stableSort(rows_hide, getComparator(order, orderBy))
                  // .filter((x) => selected.indexOf(x.id) > -1)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          onClick={() => handleDetail(row)}
                          id={labelId}
                          className="td-Setting td-fontSetting"
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {String(row.id).padStart(5, "0")} &nbsp;
                        </TableCell>
                        <TableCell
                          id={labelId}
                          className="td-Setting td-fontSetting"
                          onClick={() => handleDetail(row, 1)}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.type}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.bookcode}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.bookname}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.joaraname}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          style={{ fontSize: "14px", borderRight: "1px solid #F1F1F5" }}
                        >
                          {myData.role.name !== "bronze" && myData.role.name !== "silver"
                            ? row.name
                            : authroiztionData("name", row.name, row)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.integrated.end_date ? (
                            <SuiTypography
                              color={row.integrated.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.integrated.end_date}(${
                                row.integrated.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.integrated.day <= 0
                                ? `계약종료`
                                : `${row.integrated.day}일 남음 (${row.integrated.value})`}
                              {`-계약시작일:${row.integrated.start}/계약종료일:${row.integrated.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.coversub.end_date ? (
                            <SuiTypography
                              color={row.coversub.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.coversub.end_date}(${
                                row.coversub.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.coversub.day <= 0
                                ? `계약종료`
                                : `${row.coversub.day}일 남음 (${row.coversub.value})`}
                              {`-계약시작일:${row.coversub.start}/계약종료일:${row.coversub.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.seoninse.end_date ? (
                            <SuiTypography
                              color={row.seoninse.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.seoninse.end_date}(${
                                row.seoninse.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.seoninse.day <= 0
                                ? `계약종료`
                                : `${row.seoninse.day}일 남음 (${row.seoninse.value})`}
                              {`-계약시작일:${row.seoninse.start}/계약종료일:${row.seoninse.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.ebook.end_date ? (
                            <SuiTypography
                              color={row.ebook.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.ebook.end_date}(${
                                row.ebook.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.ebook.day <= 0
                                ? `계약종료`
                                : `${row.ebook.day}일 남음 (${row.ebook.value})`}
                              {`-계약시작일:${row.ebook.start}/계약종료일:${row.ebook.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.premium.end_date ? (
                            <SuiTypography
                              color={row.premium.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.premium.end_date}(${
                                row.premium.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.premium.day <= 0
                                ? `계약종료`
                                : `${row.premium.day}일 남음 (${row.premium.value})`}
                              {`-계약시작일:${row.premium.start}/계약종료일:${row.premium.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.mixnobl.end_date ? (
                            <SuiTypography
                              color={row.mixnobl.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.mixnobl.end_date}(${
                                row.mixnobl.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.mixnobl.day <= 0
                                ? `계약종료`
                                : `${row.mixnobl.day}일 남음 (${row.mixnobl.value})`}
                              {`-계약시작일:${row.mixnobl.start}/계약종료일:${row.mixnobl.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.nobless.end_date ? (
                            <SuiTypography
                              color={row.nobless.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.nobless.end_date}(${
                                row.nobless.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.nobless.day <= 0
                                ? `계약종료`
                                : `${row.nobless.day}일 남음 (${row.nobless.value})`}
                              {`-계약시작일:${row.nobless.start}/계약종료일:${row.nobless.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.agent.end_date ? (
                            <SuiTypography
                              color={row.agent.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.agent.end_date}(${
                                row.agent.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.agent.day <= 0
                                ? `계약종료`
                                : `${row.agent.day}일 남음 (${row.agent.value})`}
                              {`-계약시작일:${row.agent.start}/계약종료일:${row.agent.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.contract.end_date ? (
                            <SuiTypography
                              color={row.contract.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contract.end_date}(${
                                row.contract.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contract.day <= 0
                                ? `계약종료`
                                : `${row.contract.day}일 남음 (${row.contract.value})`}
                              {`-계약시작일:${row.contract.start}/계약종료일:${row.contract.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.contractStatus.webtoon.end_date ? (
                            <SuiTypography
                              color={row.contractStatus.webtoon.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contractStatus.webtoon.end_date}(${
                                row.contractStatus.webtoon.value === "자동갱신"
                                  ? "갱신일"
                                  : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contractStatus.webtoon.day <= 0
                                ? `계약종료`
                                : `${row.contractStatus.webtoon.day}일 남음 (${row.contractStatus.webtoon.value})`}
                              {`-계약시작일:${row.contractStatus.webtoon.start}/계약종료일:${row.contractStatus.webtoon.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.contractStatus.video.end_date ? (
                            <SuiTypography
                              color={row.contractStatus.video.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contractStatus.video.end_date}(${
                                row.contractStatus.video.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contractStatus.video.day <= 0
                                ? `계약종료`
                                : `${row.contractStatus.video.day}일 남음 (${row.contractStatus.video.value})`}
                              {`-계약시작일:${row.contractStatus.video.start}/계약종료일:${row.contractStatus.video.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.contractStatus.game.end_date ? (
                            <SuiTypography
                              color={row.contractStatus.game.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contractStatus.game.end_date}(${
                                row.contractStatus.game.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contractStatus.game.day <= 0
                                ? `계약종료`
                                : `${row.contractStatus.game.day}일 남음 (${row.contractStatus.game.value})`}
                              {`-계약시작일:${row.contractStatus.game.start}/계약종료일:${row.contractStatus.game.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.contractStatus.created.end_date ? (
                            <SuiTypography
                              color={row.contractStatus.created.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contractStatus.created.end_date}(${
                                row.contractStatus.created.value === "자동갱신"
                                  ? "갱신일"
                                  : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contractStatus.created.day <= 0
                                ? `계약종료`
                                : `${row.contractStatus.created.day}일 남음 (${row.contractStatus.created.value})`}
                              {`-계약시작일:${row.contractStatus.created.start}/계약종료일:${row.contractStatus.created.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.bullseye ? (
                            <SuiTypography>
                              <DataOfNobless_gray stat={row[row.bullseye].gray} />
                              {row[row.bullseye].gray === "" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    display: row[row.bullseye].red === "" ? "none" : "",
                                    color: "black",
                                  }}
                                >
                                  &nbsp;/&nbsp;
                                </span>
                              )}
                              <DataOfNobless_red stat={row[row.bullseye].red} />
                              {row[row.bullseye].red === "" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    display: row[row.bullseye].orange === "" ? "none" : "",
                                    color: "black",
                                  }}
                                >
                                  &nbsp;/&nbsp;
                                </span>
                              )}
                              <DataOfNobless_orange stat={row[row.bullseye].orange} />
                              {row[row.bullseye].orange === "" || row[row.bullseye].green === "" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    display: row[row.bullseye].green === "" ? "none" : "",
                                    color: "black",
                                  }}
                                >
                                  &nbsp;/&nbsp;
                                </span>
                              )}
                              <DataOfNobless_green stat={row[row.bullseye].green} />
                            </SuiTypography>
                          ) : row.contractStatus.etc ? (
                            <SuiTypography
                              color={row.contractStatus.etc.color}
                              sx={{
                                fontSize: 14,
                              }}
                            >
                              {`${row.contractStatus.etc.end_date}(${
                                row.contractStatus.etc.value === "자동갱신" ? "갱신일" : "종료일"
                              })`}{" "}
                              /{" "}
                              {row.contractStatus.etc.day <= 0
                                ? `계약종료`
                                : `${row.contractStatus.etc.day}일 남음 (${row.contractStatus.etc.value})`}
                              {`-계약시작일:${row.contractStatus.etc.start}/계약종료일:${row.contractStatus.etc.end}`}
                            </SuiTypography>
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting2"
                          sx={{
                            borderLeft: "1px solid #F1F1F5",
                            color: "#535353",
                            fontSize: 14,
                          }}
                        >
                          {row.rating}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting2"
                          sx={{
                            color: "#535353",
                            fontSize: 14,
                          }}
                        >
                          {row.publicDate}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting2"
                          sx={{
                            color: "#535353",
                            fontSize: 14,
                          }}
                        >
                          {row.sign}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                          style={{
                            color: "#535353",
                            fontSize: 14,
                          }}
                        >
                          {row.serialization}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            {/* 액셀에 출력되는 테이블 excel-export-table 여기까지 */}
          </TableContainer>
          {FilterSearchActive ? (
            <SuiBox
              pt={3.75}
              pb={0}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: searchTotalpage === 0 || searchTotalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom
                page={searchPage}
                totalPage={searchTotalpage}
                changePage={handlePageSearchChange}
              />
              {/* <Pagination
                page={searchPage}
                count={searchTotalpage}
                color="primary"
                onChange={handlePageSearchChange}
                boundaryCount={3}
                siblingCount={0}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          ) : (
            <SuiBox
              pt={3.75}
              pb={0}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: totalpage === 0 || totalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom page={page} totalPage={totalpage} changePage={handlePageChange} />

              {/* <Pagination
                page={page}
                count={totalpage}
                color="primary"
                onChange={handlePageChange}
                boundaryCount={3}
                siblingCount={0}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          )}
          {FilterSearchActive ? (
            loading ? (
              <SuiBox
                py={2}
                mt="80px"
                display="flex"
                justifyContent="center"
                textAlign="center"
                sx={{ display: totalpage === 0 ? "" : "none" }}
              >
                <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }} color="black">
                  검색 결과가 없습니다.
                  <SuiTypography
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      textDecoration: "underline",
                      padding: "20px 0",
                    }}
                    color="info2"
                    onClick={() => resetSearch()}
                  >
                    다시 검색하기
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            ) : (
              <SuiBox py={2} ml={2} display="flex" sx={{ display: totalpage === 0 ? "" : "none" }}>
                <SuiTypography
                  style={{ cursor: "pointer", fontSize: "10px", textDecoration: "underline" }}
                  color="info2"
                  onClick={() => resetSearch()}
                >
                  <CircularProgress style={{ width: 20, height: 20 }} />
                </SuiTypography>
              </SuiBox>
            )
          ) : (
            ""
          )}
        </Paper>
      </Box>
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <SuiDialog
        openDialog={InfoAlert_Acess.dialog}
        closeDialog={() => {
          setInfoAlert(InfoAlertSet)
          navigate("/MyPage")
        }}
        MainTitle={InfoAlert_Acess.title}
        Content={InfoAlert_Acess.content}
        CanBtnText={InfoAlert_Acess.CanBtnText}
      />
    </DashboardLayout>
  )
}
