import React, { useEffect, useState, useCallback, useRef, useLayoutEffect, useMemo } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"

import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Dialog from "@mui/material/Dialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MobileStepper from "@mui/material/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"

import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiDialog from "components/SuiDialog"
import GetFileIcon from "components/getFileIcon"

import { BiCalendar, BiRecycle, BiImages } from "react-icons/bi"
import { BsFillArrowUpLeftSquareFill, BsFillCircleFill } from "react-icons/bs"
import {
  AiFillCloseCircle,
  AiOutlinePlus,
  AiOutlineUnorderedList,
  AiOutlineFile,
} from "react-icons/ai"
import { CgUserAdd } from "react-icons/cg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { FiSearch, FiMoreHorizontal, FiSend } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { BiUpload, BiImageAdd } from "react-icons/bi"
import { IoMdCloseCircle } from "react-icons/io"
import { MdRefresh } from "react-icons/md"
import { RiDeleteBinLine } from "react-icons/ri"
import { MdClose } from "react-icons/md"
import { FaThLarge, FaTh, FaRegFlushed } from "react-icons/fa"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { FiTrash2 } from "react-icons/fi"
import DaumPostcode from "react-daum-postcode"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import "dayjs/locale/ko"
import PaginationCustom from "components/PaginationCustom"
import * as XLSX from "xlsx"
import SingleReceiverDialog from "./singleReceiverDialog"
import SendEmail from "./sendEmail"
import ImportEmail from "./importEmail"
import DeleteDialog from "./deleteDialog"
import SendHistory from "./sendHistory"
import ImgUpload from "./imgUpload"
import PreviewDialog from "./previewDialog"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  createEventResultEmail,
  createRequest,
  updateEvent,
  updateRequest,
  createEventRecevier,
  updateEventResultEmail,
  updateEventRecevier,
} from "apollo/mutation"
import { bookCodeContracts, events, eventResultEmails, eventReceviers } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"

// Quill
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
// import ImageResize from "quill-image-resize-module-react"
// Quill.register("modules/imageResize", ImageResize)

import { ConstructionOutlined, Preview } from "@mui/icons-material"

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    changeFilter,
    showchangeFilter,
    type,
    select_type,
    all_select_click,
  } = props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "번호",
      width: 45,
    },
    {
      id: "real_name",
      numeric: false,
      disablePadding: false,
      label: "본명",
      width: 85,
    },
    {
      id: "ebook",
      numeric: false,
      disablePadding: false,
      label: "이북 필명",
      width: 85,
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처 ",
      width: 126,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
      width: 156,
    },
    {
      id: "book_code",
      numeric: false,
      disablePadding: false,
      label: "요청 메일",
      width: 101,
    },
    {
      id: "agree_status",
      numeric: false,
      disablePadding: false,
      label: "승인 여부",
      width: 101,
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "작가 담당자",
      width: 80,
    },
    {
      id: "img",
      numeric: false,
      disablePadding: false,
      label: "이미지 첨부",
      width: 101,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "결과 메일",
      width: 113,
    },
    {
      id: "select",
      numeric: false,
      disablePadding: false,
      label: "선택",
      width: 83,
    },
  ]
  const allClock = () => {
    all_select_click(select_type)
  }
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell, index) =>
          type === "detail" ? (
            index < 10 && (
              <TableCell
                sx={{
                  maxWidth: headCell.width,
                  px: 1,
                  fontSize: "14px",
                  borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
                }}
                key={index + "p3zx"}
                align="center"
              >
                {headCell.id === "select" ? (
                  <span
                    style={{ color: "#0C65FF", textDecoration: "underline", cursor: "pointer" }}
                    onClick={allClock}
                  >
                    {headCell.label}
                  </span>
                ) : (
                  <span>{headCell.label}</span>
                )}
              </TableCell>
            )
          ) : (
            <TableCell
              sx={{
                maxWidth: headCell.width,
                px: 1,
                fontSize: "14px",
                borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
              }}
              key={index + "p3"}
              align="center"
            >
              {headCell.id === "select" ? (
                <span
                  style={{ color: "#0C65FF", textDecoration: "underline", cursor: "pointer" }}
                  onClick={allClock}
                >
                  {headCell.label}
                </span>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
  type: PropTypes.string,
  all_select_click: PropTypes.func,
  select_type: PropTypes.object,
}
function resultSent(props) {
  dayjs.locale("ko")

  const CryptoJS = require("crypto-js")
  const dispatch = useDispatch()
  const pathUrl = process.env.REACT_APP_CUSTOMER_URL
  const imgPathUrl = process.env.REACT_APP_BACKEND_URL
  const { openDialog, closeDialog, editId, type, edit, index, saveExit } = props
  const [type_data, set_type_data] = useState("detail")
  const { myData } = useSelector((state) => state.store)
  const [snackState, setsSnackState] = React.useState(false)
  const [DetailData, setDetailData] = useState({
    history: [],
    requests: [],
  })
  const [ChangeRequests, setChangeRequests] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)
  const [create_show, set_create_show] = useState(false) // 수정시 새로생성버튼
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [open_result_dialog, set_open_result_dialog] = useState(false)
  const [open_preview_dialog, set_open_preview_dialog] = useState(false)
  const [preview_content, set_preview_content] = useState({})
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [openDeleteInfo_Dialog, setopenDeleteInfo_Dialog] = useState(false)
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [createContractShow, setcreateContractShow] = useState(false)
  const [all_event_data, set_all_event_data] = useState({
    // 이벤트 모든정보
    manager: {
      userName: "",
      userPosition: "",
      userProfile: "",
      userDepartment: "",
    },
    history: [],
    requests: [],
  })
  const quillRef = useRef(null)
  const client = useApolloClient()
  const { data: EventData } = useQuery(events, {
    fetchPolicy: "network-only",
    variables: {
      id: [editId],
    },

    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [events_email_receiver, set_events_email_receiver] = useState("이북 필명") // 이벤트 대상자 value
  const [filters_open2, set_filters_open2] = useState(false) // 이벤트 대상자 bool
  let event_data_set = [
    // 이벤트 초기 셋팅
    {
      value: "[조아라]<작품명>",
      maxLength: 255,
      dropdown: false,
      all_send: false,
      event_id: "",
      send_email_id: "",
    },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "typography",
      title: "연관된 이메일:",
      maxLength: 100,
      xs: 4,
      changed: false,
    },
    {
      value: "publishing@joara.com",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      maxLength: 100,
      xs: 3,
      changed: false,
      title: "발송 이메일 주소:",
    },
    {
      value: "조아라",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      maxLength: 100,
      xs: 3,
      changed: false,
      title: "발송인:",
    },
    // 이벤트 대상자
    {
      data: [
        // {
        //   real_name: "박우순",
        //   ebook: "까만콩커피",
        //   phone: "01028771802",
        //   title: "미친 황제 아론 엘리엇",
        //   book_code: "1591944",
        //   reward: "무료 회수 20",
        //   status: "미발송",
        //   agree_status: "미확인",
        //   agree_filter_open: false,
        //   select: false,
        // },
      ],
    },
    // 이벤트 내용
    {
      value: `<p><strong>${
        events_email_receiver ? events_email_receiver : "작가 본명"
      }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>발송인 명 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
        all_event_data.start
      ).format("MM/DD(ddd)")}</strong>부터 <strong>${
        all_event_data.flatform
      }</strong>에서 진행된 <strong>${
        all_event_data.title
      }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
        all_event_data.title
      }</strong></p><p>- 이벤트 기간 : <strong>${all_event_data.start} ~ ${
        all_event_data.end
      }</strong></p><p>- 이벤트 내용 :</p><p><strong>${
        all_event_data.content
      }</strong></p>&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p>조아라 <strong>발송인 명</strong> 드림</p>`,
      change: true,
      img: [],
      value_copy: `<p><strong>${
        events_email_receiver ? events_email_receiver : "작가 본명"
      }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>발송인 명 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
        all_event_data.start
      ).format("MM/DD(ddd)")}</strong>부터 <strong>${
        all_event_data.flatform
      }</strong>에서 진행된 <strong>${
        all_event_data.title
      }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
        all_event_data.title
      }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
        all_event_data.end
      }</strong></p><p>- 이벤트 내용:</p><p><strong>${
        all_event_data.content
      }</strong></p>&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p>조아라 <strong>발송인 명</strong> 드림</p>`,
    },
    {
      data: [],
    },
  ]
  const [event_default, set_event_default] = useState(event_data_set) // 이벤트 안내메일 생성
  const [event_data, set_event_data] = useState(event_data_set) // 이벤트 안내메일 생성

  useEffect(() => {
    setTimeout(async () => {
      if (openDialog) {
        await client
          .query({
            query: events,
            variables: {
              id: [editId],
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let EventData = res.data
            set_all_event_data(EventData.events[0])
            let event_content_data = [...event_data]
            let replace_content = EventData.events[0]?.content.replaceAll("\n", "<br/>")

            event_content_data[0].value = `[조아라]<작품명>${EventData.events[0].flatform} ${EventData.events[0].title} 안내`
            event_content_data[0].event_id = EventData.events[0].id
            event_content_data[5].value = `<p><strong>${
              events_email_receiver ? events_email_receiver : "작가 본명"
            }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
              EventData.events[0].start
            ).format("MM/DD(ddd)")}</strong>부터 <strong>${
              EventData.events[0].flatform
            }</strong>에서 진행된 <strong>${
              EventData.events[0].title
            }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
              EventData.events[0].title
            }</strong></p><p>- 이벤트 기간: <strong>${EventData.events[0].start} ~ ${
              EventData.events[0].end
            }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
            event_content_data[5].value_copy = `<p><strong>${
              events_email_receiver ? events_email_receiver : "작가 본명"
            }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
              EventData.events[0].start
            ).format("MM/DD(ddd)")}</strong>부터 <strong>${
              EventData.events[0].flatform
            }</strong>에서 진행된 <strong>${
              EventData.events[0].title
            }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
              EventData.events[0].title
            }</strong></p><p>- 이벤트 기간: <strong>${EventData.events[0].start} ~ ${
              EventData.events[0].end
            }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

            set_event_data(event_content_data)
            set_event_default(event_content_data)
          })
        set_type_data(type)
        startSetting(editId)
      }
    }, 500)
  }, [openDialog])

  const [event_data_list, set_event_data_list] = useState([]) // 이벤트 안내메일 리스트
  const [sort_events, set_sort_events] = useState("최신순") // 정렬 value
  const [filters_open1, set_filters_open1] = useState(false) // 최신순, 오래된순 정렬 bool
  const [events_email_rows_page, set_events_email_rows_page] = useState(10) // 이벤트 대상자 테이블 rowsperpage value
  const [filters_open3, set_filters_open3] = useState(false) // 이벤트 대상자 rowsperpage bool
  const [event_table_page, set_event_table_page] = useState(1) // 이메일 대상자 테이블 현재 페이지
  const [event_table_total_page, set_event_table_total_page] = useState(1) //        tnwjd이메일 대상자 테이블 토탈페이지
  const settings_ref = useRef(null)
  const [excel_file_name, set_excel_file_name] = useState(false) // 엑셀 업로드 파일 이름
  const [open_info_dialog, set_open_info_dialog] = useState(false) // 엑셀 업로드 에러 다이어그램
  const [dialog_title, set_dialog_title] = useState(false) // 엑셀 업로드 다이어그램 content
  const [dialog_content, set_dialog_content] = useState(false) // 엑셀 업로드 다이어그램 content
  const [open_single_receiver_dialog, set_open_single_receiver_dialog] = useState(false) // 이메일 대상자 개별 등록 다이어로그 오픈
  const [single_receiver_list, set_single_receiver_list] = useState([]) // 이메일 대상자 개별 등록 리스트
  const [open_send_email_dialog, set_open_send_email_dialog] = useState(false) // 이메일 전체 발송 다이어로그 오픈
  const [open_send_history_dialog, set_open_send_history_dialog] = useState(false) // 이메일 전체 발송 다이어로그 오픈
  const [send_history_list, set_send_history_list] = useState([]) // 이메일 대상자 테이블에 있는 리스트
  const [send_email_receiver_list, set_send_email_receiver_list] = useState([]) // 이메일 전체발송 발송이후 저장될 리스트
  const [send_email_list, set_send_email_list] = useState([]) // 이메일 대상자 테이블에 있는 리스트
  const [event_single_index, set_event_single_index] = useState(-1) // 이벤트 안내메일 리스트에서 개별 이메일 대상자를 선택할때 어떤 안내메일인지 알기위한 인덱스
  const [step_next, set_step_next] = useState(false) // 이벤트 안내메일 리스트에서 개별 이메일 대상자를 선택할때 어떤 안내메일인지 알기위한 인덱스
  const [email_type, set_email_type] = useState(-1) // 이메일 발송이 전체인지 개별인지
  const [file_open, set_file_open] = useState({
    dialog: false,
    editBool: false,
    editData: {},
  }) // 엑셀 업로드 파일 이름
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY

  const startSetting = async (valueId, sort) => {
    dispatch(loading_Apply(true))
    // 진입시 데이터 불러오기
    let event_email_list = []
    await client
      .query({
        query: eventResultEmails,
        variables: {
          eventId: String(valueId),
          sort: sort ? sort : "created_at:desc",
        },
        fetchPolicy: "network-only",
      })
      .then(async (res2) => {
        let result = res2.data.eventResultEmails
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            let img_eventResultEmails_lists = []
            if (result[i].emailImages.length > 0) {
              for (let h = 0; h < result[i].emailImages.length; h++) {
                img_eventResultEmails_lists.push({
                  file: [
                    {
                      type: "picUpload",
                      title: "이미지 업로드:",
                      xs: 12,
                      file: { name: result[i].emailImages[h].name },
                      changed: false,
                      id: result[i].emailImages[h].id,
                      url: result[i].emailImages[h].url,
                      name: result[i].emailImages[h].name,
                      size: {
                        inputType: "text",
                        value1: result[i].emailImages[h].width,
                        value2: result[i].emailImages[h].height,
                        error_message: "",
                        error: false,
                        type: "textArea",
                        title: "이미지 사이즈:",
                        placeholder1: "가로",
                        placeholder2: "세로",
                        maxLength: 255,
                        xs: 12,
                      },
                    },
                    {
                      value: result[i].emailImages[h].title,
                      inputType: "text",
                      error_message: "",
                      error: false,
                      type: "textFiled",
                      title: "이미지 제목:",
                      maxLength: 30,
                      xs: 12,
                    },
                    {
                      inputType: "text",
                      value: result[i].emailImages[h].link,
                      error_message: "",
                      error: false,
                      type: "textFiled",
                      title: "이미지 연결 링크:",
                      maxLength: 255,
                      xs: 12,
                    },
                    {
                      inputType: "text",
                      value: result[i].emailImages[h].description,
                      error_message: "",
                      error: false,
                      type: "textArea",
                      title: "이미지 설명:",
                      maxLength: 255,
                      xs: 12,
                    },
                  ],
                })
              }
            }
            await client
              .query({
                query: eventReceviers,
                variables: {
                  eventEmailId: String(result[i].id),
                  mailType: "result",
                },
                fetchPolicy: "network-only",
              })
              .then((res) => {
                let resData = res.data.eventReceviers

                let response = []
                for (let j = 0; j < resData.length; j++) {
                  let img_lists = []
                  if (resData[j].lists) {
                    for (let p = 0; p < resData[j].lists.length; p++) {
                      img_lists.push({
                        file: [
                          {
                            type: "picUpload",
                            title: "이미지 업로드:",
                            xs: 12,
                            file: { name: resData[j].lists[p].name },
                            changed: false,
                            id: resData[j].lists[p].id,
                            url: resData[j].lists[p].url,
                            name: resData[j].lists[p].name,
                            size: {
                              inputType: "text",
                              value1: resData[j].lists[p].width,
                              value2: resData[j].lists[p].height,
                              error_message: "",
                              error: false,
                              type: "textArea",
                              title: "이미지 사이즈:",
                              placeholder1: "가로",
                              placeholder2: "세로",
                              maxLength: 255,
                              xs: 12,
                            },
                          },
                          {
                            value: resData[j].lists[p].title,
                            inputType: "text",
                            error_message: "",
                            error: false,
                            type: "textFiled",
                            title: "이미지 제목:",
                            maxLength: 30,
                            xs: 12,
                          },
                          {
                            inputType: "text",
                            value: resData[j].lists[p].link,
                            error_message: "",
                            error: false,
                            type: "textFiled",
                            title: "이미지 연결 링크:",
                            maxLength: 255,
                            xs: 12,
                          },
                          {
                            inputType: "text",
                            value: resData[j].lists[p].description,
                            error_message: "",
                            error: false,
                            type: "textArea",
                            title: "이미지 설명:",
                            maxLength: 255,
                            xs: 12,
                          },
                        ],
                      })
                    }
                  }

                  response.push({
                    receiverId: resData[j].id,
                    real_name: resData[j].name,
                    ebook: resData[j].ebook,
                    re_email: resData[j].email,
                    joara_name: resData[j].joaraName,
                    genre: resData[j].genre,
                    writerId: resData[j].writerId,
                    phone: resData[j].phone,
                    title: resData[j].title,
                    book_code: resData[j].bookCode,
                    reward: resData[j].rewarod,
                    reward_change: false,
                    manager: resData[j].manager,
                    manager_bool: false,
                    send_status: resData[j].status,
                    send_result_status: resData[j].result_status,
                    code: resData[j].code,
                    agree_status:
                      resData[j].access === "unidentified"
                        ? "미확인"
                        : resData[j].access === "access"
                        ? "승인"
                        : "거절",
                    agree_status_change: false,
                    agree_filter_open: false,
                    select: false,
                    img: img_lists,
                    excel: false,
                  })
                }

                let first_setting = [
                  {
                    value: result[i].title,
                    maxLength: 255,
                    dropdown: false,
                    all_send: result[i].allSend,
                    event_id: result[i].eventId,
                    send_email_id: result[i].id,
                  },
                  {
                    value: result[i].emailTitle,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "typography",
                    title: "연관된 이메일:",
                    maxLength: 100,
                    xs: 4,
                    changed: false,
                  },
                  {
                    value: result[i].senderEmail,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    maxLength: 100,
                    xs: 3,
                    changed: false,
                    title: "발송 이메일 주소:",
                  },
                  {
                    value: result[i].senderName,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    maxLength: 100,
                    xs: 3,
                    changed: false,
                    title: "발송인:",
                  },
                  // 이벤트 대상자
                  {
                    data: response,
                  },
                  // 이벤트 내용
                  {
                    value: JSON.parse(JSON.stringify(result[i].content)),
                    value_copy: JSON.parse(JSON.stringify(result[i].content)),
                    change: true,
                    img: img_eventResultEmails_lists,
                  },
                  //발송 내역
                  {
                    data: result[i].history ? result[i].history : [],
                    first: result[i].history
                      ? result[i].history[result[i].history.length - 1]?.created_at
                      : "",
                  },
                ]
                let img_s =
                  first_setting[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
                  String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                let img_e = first_setting[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>")
                first_setting[5].value = first_setting[5].value.replace(
                  first_setting[5].value.substring(img_s, img_e),
                  `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                )
                let replace_str = ""
                if (first_setting[5].img && first_setting[5].img.length > 0) {
                  for (let j = 0; j < first_setting[5].img.length; j++) {
                    if (first_setting[5].img[j].file[2].value) {
                      replace_str =
                        replace_str +
                        `<p>[${first_setting[5].img[j].file[1].value}]</p><p><a href="${
                          first_setting[5].img[j].file[2].value
                        }" rel="noopener noreferrer"  target="_blank"><img referrerpolicy="no-referrer" src="${
                          imgPathUrl + first_setting[5].img[j].file[0].url
                        }" width="${first_setting[5].img[j].file[0].size.value1}px" height="${
                          first_setting[5].img[j].file[0].size.value2
                        }px"></a></p><p><br></p>`
                    } else {
                      replace_str =
                        replace_str +
                        `<p>[${
                          first_setting[5].img[j].file[1].value
                        }]</p><p><img referrerpolicy="no-referrer" src="${
                          imgPathUrl + first_setting[5].img[j].file[0].url
                        }" width="${first_setting[5].img[j].file[0].size.value1}px" height="${
                          first_setting[5].img[j].file[0].size.value2
                        }px"></p><p><br></p>`
                    }
                  }
                }
                first_setting[5].value = first_setting[5].value.replaceAll(
                  `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                  replace_str +
                    `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                )

                event_email_list.push({
                  data: JSON.parse(JSON.stringify(first_setting)),
                  temporary_storage: true,
                  name_filter: result[i].nameType ? result[i].nameType : "작가 본명",
                  name_filter_bool: false,
                  table_rows: 10,
                  table_rows_bool: false,
                  table_total_page: Math.ceil(response.length / 10),
                  table_current_page: 1,
                  excel_name: result[i].file?.name,
                })
              })
            if (result.length - 1 === i) {
              let li = event_email_list.filter((x) => x.data[6].first)
              li.sort(function (a, b) {
                return new Date(a.data[6].first) - new Date(b.data[6].first)
              })
              for (let i = 0; i < li.length; i++) {
                event_email_list[
                  event_email_list.findIndex(
                    (x) => x.data[0].send_email_id === li[i].data[0].send_email_id
                  )
                ].num = i + 1
              }
              set_event_data_list(event_email_list)
              dispatch(loading_Apply(false))
            }
          }
        } else {
          set_event_data_list([])
          dispatch(loading_Apply(false))
        }
      })
  }
  const all_select_click = (data) => {
    if (data.type === "add") {
      let change = [...event_data]
      if (
        change[4].data.length === change[4].data.filter((x) => x.select).length
        // 전체 선택되어있을때 전체 체크 해제
      ) {
        change[4].data.map((x) => (x.select = false))
      } else {
        change[4].data.map((x) => (x.select = true))
      }
      set_event_data(change)
    } else {
      let change = [...event_data_list]
      if (
        change[data.index].data[4].data.length ===
        change[data.index].data[4].data.filter((x) => x.select).length
        // 전체 선택되어있을때 전체 체크 해제
      ) {
        change[data.index].data[4].data.map((x) => (x.select = false))
      } else {
        change[data.index].data[4].data.map((x) => (x.select = true))
      }
      set_event_data_list(change)
    }
  }
  const imageHandler = () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", ".image/png, image/jpeg, image/jpg")
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (file.size > 500000) {
        return alert("500kb 이하의 png, jpeg ,jpg 파일만 업로드 가능합니다.")
      }
      let data = new FormData()
      data.append("files", file)
      let imgUrl
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
        imgUrl = process.env.REACT_APP_BACKEND_URL + res.data[0].url
      })
      const editor = quillRef.current.getEditor()
      const range = editor.getSelection()
      editor.insertEmbed(range.index, "image", imgUrl)
      editor.setSelection(range.index + 1)
    }
  }
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [["bold", { color: [] }, "italic", "link", "image", "size"]],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: imageHandler,
        },
      },
      // imageResize: {
      //   parchment: Quill.import("parchment"),
      //   modules: ["Resize", "DisplaySize", "Toolbar"],
      // },
    }
  }, [])
  const formats = ["bold", "color", "italic", "link", "image", "alt", "width", "height", "style"]
  const quillChange = (e, type, index2) => {
    if (type === "add") {
      let email_content = [...event_data]
      email_content[5].value = e
      set_event_data(email_content)
    } else {
      let email_content = [...event_data_list]
      email_content[index2].data[5].value = e
      set_event_data_list(email_content)
    }
  }
  const handleMenu = () => {
    setAnchorEl(null)
  }
  const sendHistory = (event_index) => {
    set_send_history_list(event_data_list[event_index])
    set_open_send_history_dialog(true)
  }
  // 메일 발송 내역 다이어로그 닫기
  const openSendHistoryCloseDialog = () => {
    set_open_send_history_dialog(false)
  }
  // 개별 이메일 대상자 다이어로그 닫기
  const singleReceiverCloseDialog = () => {
    set_open_single_receiver_dialog(false)
  }
  // 메일 발송 다이어로그 닫기
  const openSendEmailCloseDialog = () => {
    set_open_send_email_dialog(false)
  }
  // 이메일 대상자 보낸후 데이터 받아오기
  const sendEmailReceiverListFunc = (data) => {
    if (data === "update") {
      startSetting(all_event_data.id)
      set_type_data("detail")
      dispatch(editStatus_Apply(false))
      eventDataReset()
    }
  }
  // 전체 발송 클릭시
  const sendAllEmail = (currentType, event_index) => {
    if (currentType === "create") {
      if (event_data[0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`연관 이메일을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }

      let arr_name_filter = [...event_data]
      arr_name_filter[0]["name_filter"] = events_email_receiver
      set_send_email_list(arr_name_filter)
    } else {
      if (event_data_list[event_index].data[0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`연관된 이메일를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[2].value === "") {
        event_data_list[event_index].data("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }

      set_event_single_index(event_index)
      set_send_email_list(event_data_list[event_index].data)
    }
    set_email_type("all")
    set_open_send_email_dialog(true)
  }
  // 공통 이미지 업로드 닫기
  const fileCloseDialog = () => {
    let file_upload = { ...file_open }
    file_upload.editBool = false
    file_upload.dialog = false
    set_file_open(file_upload)
  }
  // 추가일 경우 공통 이미지 업로드 후 가져오기
  const fileSaveDialogCreateMain = async (data) => {
    let file_event_data = [...event_data]
    let file_upload = { ...file_open }
    if (!data[0].id) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    } else if (data[0].id && data[0].file.size) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    }
    if (file_upload.editBool) {
      file_event_data[5].img[file_upload.editData.img_index].file = data
    } else {
      file_event_data[5].img.push({ file: data })
    }
    let img_s =
      file_event_data[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
      String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
    let img_e = file_event_data[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>")
    file_event_data[5].value = file_event_data[5].value.replace(
      file_event_data[5].value.substring(img_s, img_e),
      `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
    )
    let replace_str = ""
    if (file_event_data[5].img && file_event_data[5].img.length > 0) {
      for (let j = 0; j < file_event_data[5].img.length; j++) {
        if (file_event_data[5].img[j].file[2].value) {
          replace_str =
            replace_str +
            `<p>[${file_event_data[5].img[j].file[1].value}]</p><p><a href="${
              file_event_data[5].img[j].file[2].value
            }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
              imgPathUrl + file_event_data[5].img[j].file[0].url
            }" width="${file_event_data[5].img[j].file[0].size.value1}px" height="${
              file_event_data[5].img[j].file[0].size.value2
            }px"></a></p><p><br></p>`
        } else {
          replace_str =
            replace_str +
            `<p>[${
              file_event_data[5].img[j].file[1].value
            }]</p><p><img referrerpolicy="no-referrer" src="${
              imgPathUrl + file_event_data[5].img[j].file[0].url
            }" width="${file_event_data[5].img[j].file[0].size.value1}px" height="${
              file_event_data[5].img[j].file[0].size.value2
            }px"></p><p><br></p>`
        }
      }
    }
    file_event_data[5].value = file_event_data[5].value.replaceAll(
      `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
      replace_str + `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
    )

    file_upload.dialog = false
    file_upload.editBool = false
    set_event_data(file_event_data)
    set_file_open(file_upload)
  }
  // 추가일경우 개별
  const fileSaveDialogCreateSingle = async (data) => {
    let file_upload = { ...file_open }
    let file_event_data = [...event_data]
    if (!data[0].id) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    } else if (data[0].id && data[0].file.size) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    }
    if (file_upload.editBool) {
      file_event_data[4].data[file_upload.editData.current_index].img[
        file_upload.editData.img_index
      ].file = data
    } else {
      file_event_data[4].data[file_upload.editData.current_index].img.push({ file: data })
    }
    file_upload.dialog = false
    file_upload.editBool = false
    set_event_data(file_event_data)
    set_file_open(file_upload)
  }
  // 기존에 있던 결과보고 메일 메인
  const fileSaveDialogDetailMain = async (data) => {
    let file_event_data = [...event_data_list]
    let file_upload = { ...file_open }
    if (!data[0].id) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    } else if (data[0].id && data[0].file.size) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    }
    if (file_upload.editBool) {
      file_event_data[file_upload.editData.event_index].data[5].img[
        file_upload.editData.img_index
      ].file = data
    } else {
      file_event_data[file_upload.editData.event_index].data[5].img.push({ file: data })
    }
    let img_s =
      file_event_data[file_upload.editData.event_index].data[5].value.indexOf(
        "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
      ) + String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
    let img_e = file_event_data[file_upload.editData.event_index].data[5].value.indexOf(
      "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
    )
    file_event_data[file_upload.editData.event_index].data[5].value = file_event_data[
      file_upload.editData.event_index
    ].data[5].value.replace(
      file_event_data[file_upload.editData.event_index].data[5].value.substring(img_s, img_e),
      `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
    )
    let replace_str = ""
    if (
      file_event_data[file_upload.editData.event_index].data[5].img &&
      file_event_data[file_upload.editData.event_index].data[5].img.length > 0
    ) {
      for (
        let j = 0;
        j < file_event_data[file_upload.editData.event_index].data[5].img.length;
        j++
      ) {
        if (file_event_data[file_upload.editData.event_index].data[5].img[j].file[2].value) {
          replace_str =
            replace_str +
            `<p>[${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[1].value
            }]</p><p><a href="${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[2].value
            }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
              imgPathUrl +
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].url
            }" width="${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].size.value1
            }px" height="${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].size.value2
            }px"></a></p><p><br></p>`
        } else {
          replace_str =
            replace_str +
            `<p>[${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[1].value
            }]</p><p><img referrerpolicy="no-referrer" src="${
              imgPathUrl +
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].url
            }" width="${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].size.value1
            }px" height="${
              file_event_data[file_upload.editData.event_index].data[5].img[j].file[0].size.value2
            }px"></p><p><br></p>`
        }
      }
    }
    file_event_data[file_upload.editData.event_index].data[5].value = file_event_data[
      file_upload.editData.event_index
    ].data[5].value.replaceAll(
      `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
      replace_str + `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
    )

    file_upload.editBool = false
    file_upload.dialog = false
    set_event_data(file_event_data)
    set_file_open(file_upload)
  }
  // 기존에 있던 결과보고 메일 개별
  const fileSaveDialogDetailSingle = async (data) => {
    let file_event_data = [...event_data_list]
    let file_upload = { ...file_open }
    if (!data[0].id) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    } else if (data[0].id && data[0].file.size) {
      let file_data = new FormData()
      file_data.append("files", data[0].file)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", file_data).then((res) => {
        data[0].id = res.data[0].id
        data[0].name = res.data[0].name
        data[0].url = res.data[0].url
      })
    }
    if (file_upload.editBool) {
      file_event_data[file_upload.editData.event_index].data[4].data[
        file_upload.editData.current_index
      ].img[file_upload.editData.img_index].file = data
    } else {
      file_event_data[file_upload.editData.event_index].data[4].data[
        file_upload.editData.current_index
      ].img.push({ file: data })
    }
    file_upload.editBool = false
    file_upload.dialog = false
    set_event_data_list(file_event_data)
    set_file_open(file_upload)
  }
  const imgUpdateClick = (type, img_index, row_index, event_index, img, list, data) => {
    let file_upload = { ...file_open }
    if (type === "detail_single") {
      file_upload.editBool = true
      file_upload.editData = {
        type: "single",
        current: "detail",
        current_index: row_index,
        img_index: img_index,
        event_index: event_index,
        img: img,
        readOnly:
          type_data === "detail"
            ? true
            : list.data[4].data[row_index].send_result_status === "완료"
            ? true
            : false,
        imgOnly: type_data === "detail" ? true : false,
        data: data,
      }
      file_upload.dialog = true
    } else if (type === "detail_main") {
      file_upload.editBool = true
      file_upload.editData = {
        type: "main",
        current: "detail",
        current_index: -1,
        img_index: img_index,
        event_index: event_index,
        img: img,
        readOnly:
          type_data === "detail"
            ? true
            : list.data[4].data.filter((x) => x.send_result_status !== "미발송").length > 0
            ? true
            : false,
        imgOnly: type_data === "detail" ? true : false,
      }
      file_upload.dialog = true
    } else if (type === "add_single") {
      file_upload.editBool = true
      file_upload.editData = {
        type: "single",
        current: "add",
        current_index: row_index,
        img_index: img_index,
        event_index: -1,
        img: img,
        readOnly: type_data === "detail" ? true : false,
        imgOnly: type_data === "detail" ? true : false,
        data: list,
      }
      file_upload.dialog = true
    } else if (type === "add_main") {
      file_upload.editBool = true
      file_upload.editData = {
        type: "main",
        current: "add",
        current_index: -1,
        img_index: img_index,
        event_index: -1,
        img: img,
        readOnly: type_data === "detail" ? true : false,
        imgOnly: type_data === "detail" ? true : false,
      }
      file_upload.dialog = true
    }
    set_file_open(file_upload)
  }
  const event_update_open = Boolean(anchorEl)
  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
  }
  // 기존에 있던 결과보고 연관 이메일 변경
  const changeEmail = (type, event_index) => {
    if (type === "add") {
      set_open_result_dialog(true)
    } else {
      set_event_single_index(event_index)
      set_step_next(true)
      set_open_result_dialog(true)
    }
  }
  // 안내메일 새롭게 추가
  const addEvent = () => {
    if (create_show) {
      eventDataReset()
    } else {
      set_event_single_index(-1)
      set_step_next(false)
      set_open_result_dialog(true)
    }
  }
  // 개별 이메일 내용 미리보기
  const openPreviewCloseDialog = () => {
    set_open_preview_dialog(false)
  }
  // 메일 정보 불러오기 그냥 닫기
  const closeDialog_close = () => {
    set_open_result_dialog(false)
  }
  // 메일 정보 불러오기에서 연관메일 불러오기
  const closeDialog_import = (data) => {
    console.log(data)
    set_open_result_dialog(false)
    if (event_single_index > -1) {
      if (data.length > 1) {
        let arr_event_data = [...event_data_list]
        arr_event_data[
          event_single_index
        ].data[0].value = `[조아라]<작품명>${all_event_data.flatform} ${all_event_data.title} 안내`
        arr_event_data[event_single_index].data[1].value = "다중 선택"
        arr_event_data[event_single_index].data[2].value = "publishing@joara.com"
        arr_event_data[event_single_index].data[3].value = "조아라"
        arr_event_data[event_single_index].data[4]["deleteData"] =
          arr_event_data[event_single_index].data[4].data
        let email_list = []
        for (let i = 0; i < data.length; i++) {
          email_list = email_list.concat(data[i].data[4].data)
        }
        arr_event_data[event_single_index].data[4].data = email_list
        set_event_data_list(arr_event_data)
      } else {
        data = data[0]
        let arr_event_data = [...event_data_list]
        let str_num = ""
        if (data.num === "no") str_num = ""
        else if (String(data.num).length === 1) str_num = `[00${data.num}] `
        else if (String(data.num).length === 2) str_num = `[0${data.num}] `
        else str_num = `[${data.num}] `
        arr_event_data[
          event_single_index
        ].data[0].value = `[조아라]<작품명>${all_event_data.flatform} ${all_event_data.title} 안내`
        arr_event_data[event_single_index].data[1].value = str_num + data.data[0].value
        arr_event_data[event_single_index].data[2].value = data.data[1].value
        arr_event_data[event_single_index].data[3].value = "조아라"
        arr_event_data[event_single_index].data[4]["deleteData"] =
          arr_event_data[event_single_index].data[4].data

        arr_event_data[event_single_index].data[4].data = data.data[4].data
        set_event_data_list(arr_event_data)
      }
    } else {
      if (data.length > 1) {
        let arr_event_data = [...event_data]
        arr_event_data[0].value = `[조아라]<작품명>${all_event_data.flatform} ${all_event_data.title} 안내`
        arr_event_data[1].value = "다중 선택"
        arr_event_data[2].value = "publishing@joara.com"
        arr_event_data[3].value = "조아라"
        let email_list = []
        for (let i = 0; i < data.length; i++) {
          email_list = email_list.concat(data[i].data[4].data)
        }
        arr_event_data[4].data = email_list

        set_event_data(arr_event_data)
        if (create_show === false) {
          set_create_show(!create_show)
          eventDropDown(event_data[0].dropdown, "add")
        }
      } else {
        data = data[0]
        let arr_event_data = [...event_data]
        let str_num = ""
        if (data.num === "no") str_num = ""
        else if (String(data.num).length === 1) str_num = `[00${data.num}] `
        else if (String(data.num).length === 2) str_num = `[0${data.num}] `
        else str_num = `[${data.num}] `
        arr_event_data[0].value = `[조아라]<작품명>${all_event_data.flatform} ${all_event_data.title} 안내`
        arr_event_data[1].value = str_num + data.data[0].value
        arr_event_data[2].value = data.data[1].value
        arr_event_data[3].value = "조아라"
        arr_event_data[4].data = data.data[4].data

        set_event_data(arr_event_data)
        if (create_show === false) {
          set_create_show(!create_show)
          eventDropDown(event_data[0].dropdown, "add")
        }
      }
    }
  }
  // 메일 정보 불러오기에서 신규 메일일경우
  const closeDialog_create = () => {
    set_open_result_dialog(false)
    let arr_event_data = [...event_data]
    arr_event_data[0].value = `[조아라]<작품명>${all_event_data.flatform} ${all_event_data.title} 안내`
    arr_event_data[1].value = `없음`
    // arr_event_data[1].value = `[등록 순서] [조아라] <작품명> ${all_event_data.title}`
    arr_event_data[3].value = "조아라"
    arr_event_data[4].data = []
    set_event_data(arr_event_data)
    if (create_show === false) {
      set_create_show(!create_show)
      eventDropDown(event_data[0].dropdown, "add")
    }
  }
  // 안내메일 추가 취소
  const eventDataReset = () => {
    set_excel_file_name("")
    set_create_show(false)
    let replace_content = all_event_data.content.replaceAll("\n", "<br/>")

    event_data_set[5].value = `<p><strong>${
      events_email_receiver ? events_email_receiver : "작가 본명"
    }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
      all_event_data.start
    ).format("MM/DD(ddd)")}</strong>부터 <strong>${
      all_event_data.flatform
    }</strong>에서 진행된 <strong>${
      all_event_data.title
    }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
      all_event_data.title
    }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
      all_event_data.end
    }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
    event_data_set[5].value_copy = `<p><strong>${
      events_email_receiver ? events_email_receiver : "작가 본명"
    }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
      all_event_data.start
    ).format("MM/DD(ddd)")}</strong>부터 <strong>${
      all_event_data.flatform
    }</strong>에서 진행된 <strong>${
      all_event_data.title
    }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
      all_event_data.title
    }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
      all_event_data.end
    }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

    set_event_data(event_data_set)
  }
  const eventDropDown = (bool, type, index2) => {
    if (type === "add") {
      let arr_event_data = [...event_data]
      arr_event_data[0].dropdown = !bool
      set_event_data(arr_event_data)
    } else {
      let arr_event_list = [...event_data_list]
      arr_event_list[index2].data[0].dropdown = !bool
      set_event_data_list(arr_event_list)
    }
  }

  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })

  //이벤트 삭제 신청
  const Delete_All = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      suggestRole: "이벤트 삭제신청",
      profile: myData.profile,
      requestExplain: requestExplainData,
      title: all_event_data.title,
      eventId: String(all_event_data.id),
    }
    if (all_event_data.history.length > 0) {
      request.first_history = all_event_data.history[all_event_data.history.length - 1]
    }
    createRequestActive({
      variables: {
        status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
        content: request,
        type: "Event",
        event: all_event_data.id,
        requestId: all_event_data.id,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      const editHistory = [...all_event_data.history]
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content:
          "<strong>" + all_event_data.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "waiting",
          history: editHistory,
        },
      })
      dispatch(loading_Apply(false))
      setDelete_Dialog(false)
    })
  }

  const [updateEventActive] = useMutation(updateEvent, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 수정 오류 발생")
    },
    refetchQueries: [events],
  })

  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [events],
  })

  const deleteSelect = () => {
    if (myData.role.name === "silver") {
      if (all_event_data.users_permissions_user.id !== myData.id) {
        const InfoAlertSet1 = {
          dialog: true,
          title: "권한없음",
          content: "열람권한이 없습니다.",
          CanBtnText: "확인",
        }
        setInfoAlert(InfoAlertSet1)
        return
      }
    }
    if (all_event_data.requests.length > 0) {
      if (all_event_data.requests[0].status.indexOf("심사중") > -1) {
        setopenDeleteInfo_Dialog(true)
      } else {
        setDelete_Dialog_type("create")
        setDelete_Content(`${all_event_data.title}`)
        setDelete_Dialog(true)
        setrequestExplainData("")
      }
    } else {
      setDelete_Dialog_type("create")
      setDelete_Content(`${all_event_data.title}`)
      setDelete_Dialog(true)
      setrequestExplainData("")
    }
    setAnchorEl(null)
  }
  const openRequest = (data) => {
    setDelete_Dialog(true)
    if (data.status.indexOf("심사중") > -1) {
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.content.requestExplain)
      setDelete_Dialog_type("edit")
    } else {
      if (!data.content.read) {
        let content = JSON.parse(JSON.stringify(data.content))
        content.read = true
        updateRequestActive({
          variables: {
            id: data.id,
            content: content,
          },
        })
      }
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.declining)
      setDelete_Dialog_type("delete")
    }
  }
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  //삭제신청 취소 작업
  const delete_cancle_active = () => {
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제신청 취소 완료"
      data.content = "신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"
      setdelete_cancle_alert(data)
      setcreateContractShow(true)
      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "active",
        },
      })
    })
  }
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
  }

  // 이벤트 대상자 txt필드 입력
  const handleInput = (index, maxLength, type, index2) => (e) => {
    // 생성시 입력
    if (type === "add") {
      let arr_event_data = [...event_data]
      if (index === 0) {
        arr_event_data[0].value = e.target.value
      } else if (index === 1) {
        arr_event_data[1].value = e.target.value
      } else if (index === 2) {
        arr_event_data[2].value = e.target.value
      } else if (index === 3) {
        let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
        arr_event_data[3].value = e.target.value
        arr_event_data[5].value = `<p><strong>${
          events_email_receiver ? events_email_receiver : "작가 본명"
        }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
          all_event_data.start
        ).format("MM/DD(ddd)")}</strong>부터 <strong>${
          all_event_data.flatform
        }</strong>에서 진행된 <strong>${
          all_event_data.title
        }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
          all_event_data.title
        }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
          all_event_data.end
        }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

        let img_s =
          arr_event_data[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
          String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
        let img_e = arr_event_data[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>")
        arr_event_data[5].value = arr_event_data[5].value.replace(
          arr_event_data[5].value.substring(img_s, img_e),
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
        )
        let replace_str = ""
        if (arr_event_data[5].img && arr_event_data[5].img.length > 0) {
          for (let j = 0; j < arr_event_data[5].img.length; j++) {
            if (arr_event_data[5].img[j].file[2].value) {
              replace_str =
                replace_str +
                `<p>[${arr_event_data[5].img[j].file[1].value}]</p><p><a href="${
                  arr_event_data[5].img[j].file[2].value
                }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + arr_event_data[5].img[j].file[0].url
                }" width="${arr_event_data[5].img[j].file[0].size.value1}px" height="${
                  arr_event_data[5].img[j].file[0].size.value2
                }px"></a></p><p><br></p>`
            } else {
              replace_str =
                replace_str +
                `<p>[${
                  arr_event_data[5].img[j].file[1].value
                }]</p><p><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + arr_event_data[5].img[j].file[0].url
                }" width="${arr_event_data[5].img[j].file[0].size.value1}px" height="${
                  arr_event_data[5].img[j].file[0].size.value2
                }px"></p><p><br></p>`
            }
          }
        }
        arr_event_data[5].value = arr_event_data[5].value.replaceAll(
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
          replace_str +
            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
        )
      }
      set_event_data(arr_event_data)
    } else {
      let arr_event_list = [...event_data_list]
      let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
      if (index === 0) {
        arr_event_list[index2].data[0].value = e.target.value
      } else if (index === 1) {
        arr_event_list[index2].data[1].value = e.target.value
      } else if (index === 2) {
        arr_event_list[index2].data[2].value = e.target.value
      } else if (index === 3) {
        arr_event_list[index2].data[3].value = e.target.value
        arr_event_list[index2].data[5].value = `<p><strong>${
          arr_event_list[index2].name_filter ? arr_event_list[index2].name_filter : "작가 본명"
        }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
          all_event_data.start
        ).format("MM/DD(ddd)")}</strong>부터 <strong>${
          all_event_data.flatform
        }</strong>에서 진행된 <strong>${
          all_event_data.title
        }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
          all_event_data.title
        }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
          all_event_data.end
        }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

        let img_s =
          arr_event_list[index2].data[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
          String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
        let img_e = arr_event_list[index2].data[5].value.indexOf(
          "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
        )
        arr_event_list[index2].data[5].value = arr_event_list[index2].data[5].value.replace(
          arr_event_list[index2].data[5].value.substring(img_s, img_e),
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
        )
        let replace_str = ""
        if (arr_event_list[index2].data[5].img && arr_event_list[index2].data[5].img.length > 0) {
          for (let j = 0; j < arr_event_list[index2].data[5].img.length; j++) {
            if (arr_event_list[index2].data[5].img[j].file[2].value) {
              replace_str =
                replace_str +
                `<p>[${arr_event_list[index2].data[5].img[j].file[1].value}]</p><p><a href="${
                  arr_event_list[index2].data[5].img[j].file[2].value
                }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + arr_event_list[index2].data[5].img[j].file[0].url
                }" width="${arr_event_list[index2].data[5].img[j].file[0].size.value1}px" height="${
                  arr_event_list[index2].data[5].img[j].file[0].size.value2
                }px"></a></p><p><br></p>`
            } else {
              replace_str =
                replace_str +
                `<p>[${
                  arr_event_list[index2].data[5].img[j].file[1].value
                }]</p><p><img referrerpolicy="no-referrer" src="${
                  imgPathUrl + arr_event_list[index2].data[5].img[j].file[0].url
                }" width="${arr_event_list[index2].data[5].img[j].file[0].size.value1}px" height="${
                  arr_event_list[index2].data[5].img[j].file[0].size.value2
                }px"></p><p><br></p>`
            }
          }
        }
        arr_event_list[index2].data[5].value = arr_event_list[index2].data[5].value.replaceAll(
          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
          replace_str +
            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
        )
      }
      set_event_data_list(arr_event_list)
    }
  }
  // 이메일 대상자 테이블 체크박스
  const handleCheckBox = (type, index, index2) => (e) => {
    // 이메일 대상자 생성시
    if (type === "add") {
      let arr_event_data = [...event_data]
      arr_event_data[4].data[index].select = e.target.checked
      set_event_data(arr_event_data)
    } else {
      let arr_event_data = [...event_data_list]
      arr_event_data[index2].data[4].data[index].select = e.target.checked
      set_event_data_list(arr_event_data)
    }
  }
  // 발송메일 생성
  const handleDatePickerChange = (index, type, index2) => (e) => {
    // if (type === "add") {
    //   let arr_event_data = [...event_data]
    //   let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
    //   arr_event_data[3].value = e
    //   arr_event_data[5].value = `<p><strong>${
    //     events_email_receiver ? events_email_receiver : "작가 본명"
    //   }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>${
    //     event_data[3].value ? event_data[3].value : "발송인 명"
    //   } </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
    //     all_event_data?.title
    //   }</strong>에</p><p><strong>작품 명</strong> 작품의 이벤트 진행을 위해 승인 요청드립니다.</p><p><br></p><p>- 이벤트명 :<strong> ${
    //     all_event_data?.title
    //   }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
    //     all_event_data?.end
    //   }</strong></p><p>- 리워드 :<strong> 리워드 직접 입력</strong></p><p>- 이벤트 내용 :</p><p><br></p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이벤트 내용을 함께 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${dayjs(
    //     e
    //   ).format(
    //     "YYYY.MM.DD(ddd) hh:mm"
    //   )}</strong>까지 답변 부탁드립니다</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
    //     pathUrl + "/notice/" + "코드_code"
    //   }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204);">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>남은 하루도 좋은 하루 보내시길 바랍니다</p><p><br></p><p>조아라 <strong>${
    //     event_data[3].value ? event_data[3].value : "발송인 명"
    //   }</strong> 드림</p>`
    //   set_event_data(arr_event_data)
    // } else {
    //   let arr_event_list = [...event_data_list]
    //   let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
    //   arr_event_list[index2].data[3].value = e
    //   arr_event_list[index2].data[5].value = `<p><strong>${
    //     arr_event_list[index2].data[0].name_filter
    //       ? arr_event_list[index2].data[0].name_filter
    //       : "작가 본명"
    //   }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>${
    //     arr_event_list[index2].data[3].value ? arr_event_list[index2].data[3].value : "발송인 명"
    //   } </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
    //     all_event_data?.title
    //   }</strong>에</p><p><strong>작품 명</strong> 작품의 이벤트 진행을 위해 승인 요청드립니다.</p><p><br></p><p>- 이벤트명 :<strong> ${
    //     all_event_data?.title
    //   }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
    //     all_event_data?.end
    //   }</strong></p><p>- 리워드 :<strong> 리워드 직접 입력</strong></p><p>- 이벤트 내용 :</p><p><br></p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이벤트 내용을 함께 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${dayjs(
    //     e
    //   ).format(
    //     "YYYY.MM.DD(ddd) hh:mm"
    //   )}</strong>까지 답변 부탁드립니다</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
    //     pathUrl + "/notice/" + "코드_code"
    //   }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204);">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>남은 하루도 좋은 하루 보내시길 바랍니다</p><p><br></p><p>조아라 <strong>${
    //     arr_event_list[index2].data[3].value ? arr_event_list[index2].data[3].value : "발송인 명"
    //   }</strong> 드림</p>`
    //   set_event_data_list(arr_event_list)
    // }
  }
  // 페이지 네이션 클릭시
  const eventTablePage = (type, evnet_index) => (e, el) => {
    if (type === "add") {
      let arr_email_receiver = [...event_data]
      set_event_table_total_page(
        Math.ceil(arr_email_receiver[4].data.length / events_email_rows_page)
      )
      set_event_table_page(el)
    } else {
      let arr_event_data = [...event_data_list]
      arr_event_data[evnet_index].table_current_page = el
      set_event_data_list(arr_event_data)
    }
  }
  // 엑셀 업로드 클릭
  const excelImport = (index) => {
    if (index > -1) {
      document.getElementById(`event_excel_import_${index}`).value = ""
      document.getElementById(`event_excel_import_${index}`).click()
      set_event_single_index(index)
    } else {
      document.getElementById(`event_excel_import`).value = ""
      document.getElementById(`event_excel_import`).click()
      set_event_single_index(-1)
    }
  }

  // 엑셀 파일 읽기
  const readExcel = (e) => {
    let input = e.target
    let reader = new FileReader()
    if (
      input.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      set_dialog_title("엑셀 업로드 오류")
      set_dialog_content(`xlsx파일이 아닙니다. 확인해주세요.`)
      set_open_info_dialog(true)
      return
    }

    reader.onload = function () {
      let data = reader.result
      let workBook = XLSX.read(data, { type: "binary" })
      workBook.SheetNames.forEach(function (sheetName) {
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName])
        let arr_excel = JSON.parse(JSON.stringify(rows))
        // 중복 북코드 체크
        let num_check = 0
        for (let i = 0; i < arr_excel.length; i++) {
          let arr_if = arr_excel.filter((x) => x["북코드"] === arr_excel[i]["북코드"])
          if (arr_if.length > 1) {
            num_check++
          }
        }
        if (num_check === 0) {
          callContract(arr_excel, input)
        } else {
          if (event_single_index === -1) {
            set_excel_file_name("")
          } else {
            let arr_email_receiver_list = [...event_data_list]
            arr_email_receiver_list[event_single_index]["excel_name"] = ""
            set_event_data_list(arr_email_receiver_list)
          }
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 중복됩니다. 확인해주세요.`)
          set_open_info_dialog(true)
          return
        }
      })
    }
    reader.readAsBinaryString(input.files[0])
  }
  // 엑셀파일 bookcode로 불러오기
  const callContract = async (excel_data, input) => {
    if (event_single_index === -1) {
      let arr_email_receiver = [...event_data]
      set_excel_file_name(input.files[0].name)

      arr_email_receiver[4].data = arr_email_receiver[4].data.filter((x) => x.excel === false)
      dispatch(loading_Apply(true))
      let arr_bookcode = []
      // 엑셀데이터 북코드 스트링으로 리스트 만들기
      for (let i = 0; i < excel_data.length; i++) {
        arr_bookcode.push(String(excel_data[i]["북코드"]))
      }
      // 북코드에 따라 작품 호출
      await client
        .query({
          query: bookCodeContracts,
          fetchPolicy: "network-only",
          variables: {
            bookcode: arr_bookcode,
          },
        })
        .then(({ data }) => {
          // 잘못된 북코드가 포함되어있을때
          if (arr_bookcode.length !== data.contracts.length) {
            set_excel_file_name("")
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            return
          }
          if (data.contracts.length > 0) {
            // 북코드에 맞는 작품이 있을경우에만
            for (let i = 0; i < data.contracts.length; i++) {
              // 엑셀데이터에서 bookcode에 맞는 인덱스찾기
              let num_bookcode_index = excel_data.findIndex(
                (x) => String(x["북코드"]) === data.contracts[i].bookcode
              )
              let re_phone
              re_phone = CryptoJS.AES.decrypt(data.contracts[i].writer.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let re_email
              re_email = CryptoJS.AES.decrypt(data.contracts[i].writer.email, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let obj_contract_data = {
                id: data.contracts[i].id,
                re_email: re_email,
                genre: data.contracts[i].genre,
                real_name: data.contracts[i].writer.name,
                ebook: data.contracts[i].writer.ebookname,
                joara_name: data.contracts[i].writer.joaraname,
                phone: re_phone,
                title: data.contracts[i].title,
                book_code: data.contracts[i].bookcode,
                reward: excel_data[num_bookcode_index]["개별 리워드"],
                reward_bool: false, // 리워드 인풋박스로 변경여부
                manager: excel_data[num_bookcode_index]["작가 담당자"],
                manager_bool: false,
                send_result_status: "미발송",
                send_status: "미발송",
                agree_status: "미확인",
                agree_filter_open: false,
                select: false,
                single_select: false,
                excel: true, // 엑셀업로드로 추가한경우
                writer: data.contracts[i].writer,
                img: [],
              }
              if (
                arr_email_receiver[4].data.findIndex((x) => x.id === data.contracts[i].id) === -1
              ) {
                arr_email_receiver[4].data.push(obj_contract_data)
              }
            }

            set_event_table_page(1)
            set_event_table_total_page(
              Math.ceil(arr_email_receiver[4].data.length / events_email_rows_page)
            )
            set_event_data(arr_email_receiver)
            dispatch(loading_Apply(false))
          } else {
            set_excel_file_name("")
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            return
          }
        })
        .catch((err) => {
          set_excel_file_name("")
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        })
    } else {
      let arr_email_receiver_list = [...event_data_list]
      arr_email_receiver_list[event_single_index]["excel_name"] = input.files[0].name

      let arr_email_receiver = arr_email_receiver_list[event_single_index].data
      arr_email_receiver[4].data = arr_email_receiver[4].data.filter((x) => x.excel === false)
      let arr_contract = []
      // dispatch(loading_Apply(true))
      let arr_bookcode = []
      // 엑셀데이터 북코드 스트링으로 리스트 만들기
      for (let i = 0; i < excel_data.length; i++) {
        arr_bookcode.push(String(excel_data[i]["북코드"]))
      }
      // 북코드에 따라 작품 호출
      await client
        .query({
          query: bookCodeContracts,
          fetchPolicy: "network-only",
          variables: {
            bookcode: arr_bookcode,
          },
        })
        .then(({ data }) => {
          // 잘못된 북코드가 포함되어있을때
          if (arr_bookcode.length !== data.contracts.length) {
            arr_email_receiver_list[event_single_index]["excel_name"] = ""
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            set_event_data_list(arr_email_receiver_list)
            return
          }
          if (data.contracts.length > 0) {
            // 북코드에 맞는 작품이 있을경우에만
            for (let i = 0; i < data.contracts.length; i++) {
              // 엑셀데이터에서 bookcode에 맞는 인덱스찾기
              let num_bookcode_index = excel_data.findIndex(
                (x) => String(x["북코드"]) === data.contracts[i].bookcode
              )
              let re_phone
              re_phone = CryptoJS.AES.decrypt(data.contracts[i].writer.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let re_email
              re_email = CryptoJS.AES.decrypt(data.contracts[i].writer.email, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let obj_contract_data = {
                id: data.contracts[i].id,
                re_email: re_email,
                real_name: data.contracts[i].writer.name,
                ebook: data.contracts[i].writer.ebookname,
                joara_name: data.contracts[i].writer.joaraname,
                phone: re_phone,
                title: data.contracts[i].title,
                book_code: data.contracts[i].bookcode,
                reward: excel_data[num_bookcode_index]["개별 리워드"],
                reward_bool: false, // 리워드 인풋박스로 변경여부
                manager: excel_data[num_bookcode_index]["작가 담당자"],
                manager_bool: false,
                send_result_status: "미발송",
                send_status: "미발송",
                agree_status: "미확인",
                agree_filter_open: false,
                select: false,
                single_select: false,
                excel: true, // 엑셀업로드로 추가한경우
                writer: data.contracts[i].writer,
                img: [],
              }
              if (
                arr_email_receiver[4].data.findIndex((x) => x.id === data.contracts[i].id) === -1
              ) {
                arr_email_receiver[4].data.push(obj_contract_data)
              }
              if (data.contracts.length - 1 === i) {
                arr_email_receiver_list[event_single_index].table_current_page = 1
                arr_email_receiver_list[event_single_index].table_total_page = Math.ceil(
                  arr_email_receiver[4].data.length / events_email_rows_page
                )
                set_event_data_list(arr_email_receiver_list)

                // dispatch(loading_Apply(false))
              }
            }
          } else {
            arr_email_receiver_list[event_single_index]["excel_name"] = ""
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            set_event_data_list(arr_email_receiver_list)
            return
          }
        })
        .catch((err) => {
          arr_email_receiver_list[event_single_index]["excel_name"] = ""
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          set_event_data_list(arr_email_receiver_list)
          return
        })
    }
  }
  // 엑셀 업로드 파일 x버튼
  const excelCloseClick = (type, index) => {
    if (type === "add") {
      let arr_email_receiver = [...event_data]
      arr_email_receiver[4].data = arr_email_receiver[4].data.filter((x) => x.excel === false)
      set_excel_file_name("")
      set_event_data(arr_email_receiver)
    } else {
      let arr_email_receiver = [...event_data_list]
      arr_email_receiver[event_single_index].data[4].data = arr_email_receiver[
        event_single_index
      ].data[4].data.filter((x) => x.excel === false)
      arr_email_receiver[event_single_index].excel_name = ""
      set_event_data_list(arr_email_receiver)
    }
  }
  const imgCloseClick = (type, index, index2, event_index, list) => {
    // type > 추가 or 기존에거 수정, index > 몇번째 이미지인지, index2 > 테이블에서 몇번째 대상자인지, event_index > 몇번째 결과보고 이메일인지
    if (type_data !== "detail") {
      if (type === "add") {
        if (index2 > -1) {
          let arr_email_receiver = [...event_data]
          arr_email_receiver[4].data[index2].img.splice(index, 1)
          set_event_data(arr_email_receiver)
        } else {
          let arr_email_receiver = [...event_data]
          arr_email_receiver[5].img.splice(index, 1)

          let img_s =
            arr_email_receiver[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
            String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
          let img_e = arr_email_receiver[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>")
          arr_email_receiver[5].value = arr_email_receiver[5].value.replace(
            arr_email_receiver[5].value.substring(img_s, img_e),
            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
          )
          let replace_str = ""
          if (arr_email_receiver[5].img && arr_email_receiver[5].img.length > 0) {
            for (let j = 0; j < arr_email_receiver[5].img.length; j++) {
              if (arr_email_receiver[5].img[j].file[2].value) {
                replace_str =
                  replace_str +
                  `<p>[${arr_email_receiver[5].img[j].file[1].value}]</p><p><a href="${
                    arr_email_receiver[5].img[j].file[2].value
                  }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                    imgPathUrl + arr_email_receiver[5].img[j].file[0].url
                  }" width="${arr_email_receiver[5].img[j].file[0].size.value1}px" height="${
                    arr_email_receiver[5].img[j].file[0].size.value2
                  }px"></a></p><p><br></p>`
              } else {
                replace_str =
                  replace_str +
                  `<p>[${
                    arr_email_receiver[5].img[j].file[1].value
                  }]</p><p><img referrerpolicy="no-referrer" src="${
                    imgPathUrl + arr_email_receiver[5].img[j].file[0].url
                  }" width="${arr_email_receiver[5].img[j].file[0].size.value1}px" height="${
                    arr_email_receiver[5].img[j].file[0].size.value2
                  }px"></p><p><br></p>`
              }
            }
          }
          arr_email_receiver[5].value = arr_email_receiver[5].value.replaceAll(
            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
            replace_str +
              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
          )
          set_event_data(arr_email_receiver)
        }
      } else {
        if (index2 > -1) {
          if (list.data[4].data[index2].send_result_status !== "완료") {
            let arr_email_receiver = [...event_data_list]
            arr_email_receiver[event_index].data[4].data[index2].img.splice(index, 1)
            set_event_data_list(arr_email_receiver)
          }
        } else {
          if (list.data[4].data.filter((x) => x.send_result_status !== "미발송").length === 0) {
            let arr_email_receiver = [...event_data_list]
            arr_email_receiver[event_index].data[5].img.splice(index, 1)

            let img_s =
              arr_email_receiver[event_index].data[5].value.indexOf(
                "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
              ) + String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
            let img_e = arr_email_receiver[event_index].data[5].value.indexOf(
              "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
            )
            arr_email_receiver[event_index].data[5].value = arr_email_receiver[
              event_index
            ].data[5].value.replace(
              arr_email_receiver[event_index].data[5].value.substring(img_s, img_e),
              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
            )
            let replace_str = ""
            if (
              arr_email_receiver[event_index].data[5].img &&
              arr_email_receiver[event_index].data[5].img.length > 0
            ) {
              for (let j = 0; j < arr_email_receiver[event_index].data[5].img.length; j++) {
                if (arr_email_receiver[event_index].data[5].img[j].file[2].value) {
                  replace_str =
                    replace_str +
                    `<p>[${
                      arr_email_receiver[event_index].data[5].img[j].file[1].value
                    }]</p><p><a href="${
                      arr_email_receiver[event_index].data[5].img[j].file[2].value
                    }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                      imgPathUrl + arr_email_receiver[event_index].data[5].img[j].file[0].url
                    }" width="${
                      arr_email_receiver[event_index].data[5].img[j].file[0].size.value1
                    }px" height="${
                      arr_email_receiver[event_index].data[5].img[j].file[0].size.value2
                    }px"></a></p><p><br></p>`
                } else {
                  replace_str =
                    replace_str +
                    `<p>[${
                      arr_email_receiver[event_index].data[5].img[j].file[1].value
                    }]</p><p><img referrerpolicy="no-referrer" src="${
                      imgPathUrl + arr_email_receiver[event_index].data[5].img[j].file[0].url
                    }" width="${
                      arr_email_receiver[event_index].data[5].img[j].file[0].size.value1
                    }px" height="${
                      arr_email_receiver[event_index].data[5].img[j].file[0].size.value2
                    }px"></p><p><br></p>`
                }
              }
            }
            arr_email_receiver[event_index].data[5].value = arr_email_receiver[
              event_index
            ].data[5].value.replaceAll(
              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
              replace_str +
                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
            )
            set_event_data_list(arr_email_receiver)
          }
        }
      }
    }
  }

  // 개별 이메일 대상자
  const openSingleDialog = (index) => {
    if (index > -1) {
      set_event_single_index(index)
    }
    set_open_single_receiver_dialog(true)
  }
  // 개별 이메일 대상자 추가시 중복제외
  const receiverList = (arr) => {
    if (event_single_index === -1) {
      let table_list = [...event_data]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].book_code !== "") {
          if (table_list[4].data.findIndex((x) => x.book_code === arr[i].book_code) === -1) {
            table_list[4].data.push(arr[i])
          }
        } else {
          if (
            table_list[4].data.findIndex(
              (x) =>
                x.title === arr[i].title && x.ebook === arr[i].ebook && x.genre === arr[i].genre
            ) === -1
          ) {
            table_list[4].data.push(arr[i])
          }
        }
      }
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].book_code !== "") {
          if (
            table_list[event_single_index].data[4].data.findIndex(
              (x) => x.book_code === arr[i].book_code
            ) === -1
          ) {
            table_list[event_single_index].data[4].data.push(arr[i])
          }
        } else {
          if (
            table_list[event_single_index].data[4].data.findIndex(
              (x) =>
                x.title === arr[i].title && x.ebook === arr[i].ebook && x.genre === arr[i].genre
            ) === -1
          ) {
            table_list[event_single_index].data[4].data.push(arr[i])
          }
        }
      }
      set_event_data_list(table_list)
      set_event_single_index(-1)
    }
  }
  const deleteEvent = async (event_index) => {
    dispatch(loading_Apply(true))
    await client
      .mutate({
        mutation: updateEventResultEmail,
        variables: {
          id: event_data_list[event_index].data[0].send_email_id,
          eventId: null,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        for (let i = 0; i < event_data_list[event_index].data[4].data.length; i++) {
          let receiver = event_data_list[event_index].data[4].data[i]
          await client.mutate({
            mutation: updateEventRecevier,
            variables: {
              id: receiver.receiverId,
              eventEmailId: null,
            },
            fetchPolicy: "network-only",
          })
          if (event_data_list[event_index].data[4].data.length - 1 === i) {
            await updateEventHistory("<strong>결과 보고 정보</strong>를 삭제하였습니다.")
            dispatch(loading_Apply(false))
            startSetting(all_event_data.id)
          }
        }
      })
  }
  const clickManager = (type, index, index2) => (e) => {
    e.preventDefault()
    if (type === "add") {
      // input 박스 열기
      let table_list = [...event_data]
      table_list[4].data.map((x, idx) => {
        if (index === idx) {
          x.manager_bool = true
        } else {
          x.manager_bool = false
        }
      })
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data.map((x, idx) => {
        if (index === idx) {
          x.manager_bool = true
        } else {
          x.manager_bool = false
        }
      })

      set_event_data_list(table_list)
    }
  }
  // manager 변경
  const handleManagerInput = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      if (e.target.value.length < 13) {
        table_list[4].data[index].manager = e.target.value
      }
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      if (e.target.value.length < 13) {
        table_list[index2].data[4].data[index].manager = e.target.value
      }
      set_event_data_list(table_list)
    }
  }
  // 입력후 엔터 눌렀을때 input 끄기
  const handleManagerEnter = (type, index, index2) => (e) => {
    if (type === "add") {
      if (e.key === "Enter") {
        let table_list = [...event_data]
        table_list[4].data[index].manager_bool = false
        set_event_data(table_list)
      }
    } else {
      if (e.key === "Enter") {
        let table_list = [...event_data_list]
        table_list[index2].data[4].data[index].manager_bool = false
        set_event_data_list(table_list)
      }
    }
  }
  // 입력후 blur 됬을때 input 끄기
  const handleManagerBlur = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      table_list[4].data[index].manager_bool = false
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data[index].manager_bool = false
      set_event_data_list(table_list)
    }
  }
  // 이메일 대상자 선택된 대상 메일보내기
  const singleSend = (type, event_index) => {
    if (type === "add") {
      if (event_data[0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`연관된 이메일를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }

      let arr_name_filter = [...event_data]
      arr_name_filter[0]["name_filter"] = events_email_receiver
      set_send_email_list(arr_name_filter)
    } else {
      set_event_single_index(event_index)
      set_send_email_list(event_data_list[event_index].data)
    }
    set_email_type("single")
    set_open_send_email_dialog(true)
  }

  // 이메일 대상자 테이블 새로고침
  const reflash = async (type, event_index) => {
    if (type === "add") {
      let select_list = [...event_data]
      let response = []
      if (select_list[0].send_email_id) {
        dispatch(loading_Apply(true))
        await client
          .query({
            query: eventReceviers,
            variables: {
              eventEmailId: String(select_list[0].send_email_id),
              mailType: "result",
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let resData = res.data.eventReceviers

            for (let j = 0; j < resData.length; j++) {
              let img_lists = []
              if (resData[j].lists) {
                for (let p = 0; p < resData[j].lists.length; p++) {
                  img_lists.push({
                    file: [
                      {
                        type: "picUpload",
                        title: "이미지 업로드:",
                        xs: 12,
                        file: { name: resData[j].lists[p].name },
                        changed: false,
                        id: resData[j].lists[p].id,
                        url: resData[j].lists[p].url,
                        name: resData[j].lists[p].name,
                        size: {
                          inputType: "text",
                          value1: resData[j].lists[p].width,
                          value2: resData[j].lists[p].height,
                          error_message: "",
                          error: false,
                          type: "textArea",
                          title: "이미지 사이즈:",
                          placeholder1: "가로",
                          placeholder2: "세로",
                          maxLength: 255,
                          xs: 12,
                        },
                      },
                      {
                        value: resData[j].lists[p].title,
                        inputType: "text",
                        error_message: "",
                        error: false,
                        type: "textFiled",
                        title: "이미지 제목:",
                        maxLength: 30,
                        xs: 12,
                      },
                      {
                        inputType: "text",
                        value: resData[j].lists[p].link,
                        error_message: "",
                        error: false,
                        type: "textFiled",
                        title: "이미지 연결 링크:",
                        maxLength: 255,
                        xs: 12,
                      },
                      {
                        inputType: "text",
                        value: resData[j].lists[p].description,
                        error_message: "",
                        error: false,
                        type: "textArea",
                        title: "이미지 설명:",
                        maxLength: 255,
                        xs: 12,
                      },
                    ],
                  })
                }
              }
              response.push({
                receiverId: resData[j].id,
                real_name: resData[j].name,
                ebook: resData[j].ebook,
                re_email: resData[j].email,
                joara_name: resData[j].joaraName,
                genre: resData[j].genre,
                code: resData[j].code,
                writerId: resData[j].writerId,
                phone: resData[j].phone,
                title: resData[j].title,
                book_code: resData[j].bookCode,
                reward: resData[j].rewarod,
                reward_change: false,
                manager: resData[j].manager,
                manager_bool: false,
                send_result_status: resData[j].result_status,
                send_status: resData[j].status,
                agree_status:
                  resData[j].access === "unidentified"
                    ? "미확인"
                    : resData[j].access === "access"
                    ? "승인"
                    : "거절",
                agree_status_change: false,
                agree_filter_open: false,
                select: false,
                img: img_lists,
              })
            }
            select_list[4].data = response
            dispatch(loading_Apply(false))
          })
      }
      set_event_data(select_list)
    } else {
      let select_list = [...event_data_list]
      let response = []
      dispatch(loading_Apply(true))
      await client
        .query({
          query: eventReceviers,
          variables: {
            eventEmailId: String(select_list[event_index].data[0].send_email_id),
            mailType: "result",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let resData = res.data.eventReceviers

          for (let j = 0; j < resData.length; j++) {
            let img_lists = []
            if (resData[j].lists) {
              for (let p = 0; p < resData[j].lists.length; p++) {
                img_lists.push({
                  file: [
                    {
                      type: "picUpload",
                      title: "이미지 업로드:",
                      xs: 12,
                      file: { name: resData[j].lists[p].name },
                      changed: false,
                      id: resData[j].lists[p].id,
                      url: resData[j].lists[p].url,
                      name: resData[j].lists[p].name,
                      size: {
                        inputType: "text",
                        value1: resData[j].lists[p].width,
                        value2: resData[j].lists[p].height,
                        error_message: "",
                        error: false,
                        type: "textArea",
                        title: "이미지 사이즈:",
                        placeholder1: "가로",
                        placeholder2: "세로",
                        maxLength: 255,
                        xs: 12,
                      },
                    },
                    {
                      value: resData[j].lists[p].title,
                      inputType: "text",
                      error_message: "",
                      error: false,
                      type: "textFiled",
                      title: "이미지 제목:",
                      maxLength: 30,
                      xs: 12,
                    },
                    {
                      inputType: "text",
                      value: resData[j].lists[p].link,
                      error_message: "",
                      error: false,
                      type: "textFiled",
                      title: "이미지 연결 링크:",
                      maxLength: 255,
                      xs: 12,
                    },
                    {
                      inputType: "text",
                      value: resData[j].lists[p].description,
                      error_message: "",
                      error: false,
                      type: "textArea",
                      title: "이미지 설명:",
                      maxLength: 255,
                      xs: 12,
                    },
                  ],
                })
              }
            }
            response.push({
              receiverId: resData[j].id,
              real_name: resData[j].name,
              ebook: resData[j].ebook,
              re_email: resData[j].email,
              joara_name: resData[j].joaraName,
              genre: resData[j].genre,
              code: resData[j].code,
              writerId: resData[j].writerId,
              phone: resData[j].phone,
              title: resData[j].title,
              book_code: resData[j].bookCode,
              reward: resData[j].rewarod,
              reward_change: false,
              manager: resData[j].manager,
              manager_bool: false,
              send_result_status: resData[j].result_status,
              send_status: resData[j].status,
              agree_status:
                resData[j].access === "unidentified"
                  ? "미확인"
                  : resData[j].access === "access"
                  ? "승인"
                  : "거절",
              agree_status_change: false,
              agree_filter_open: false,
              select: false,
              img: img_lists,
            })
          }
          select_list[event_index].data[4].data = response
          dispatch(loading_Apply(false))
        })
      set_event_data_list(select_list)
    }
  }
  // 이메일 대상자 선택된 대상 삭제
  const deleteSender = (type, event_index) => {
    if (type === "add") {
      let select_list = [...event_data]
      select_list[4].data = select_list[4].data.filter((x) => !x.select)
      set_event_data(select_list)
    } else {
      let select_list = [...event_data_list]
      select_list[event_index].data[4].deleteData = select_list[event_index].data[4].data.filter(
        (x) => x.select
      )
      select_list[event_index].data[4].data = select_list[event_index].data[4].data.filter(
        (x) => !x.select
      )
      set_event_data_list(select_list)
    }
  }
  // 안내발송 작업후 이벤트 히스토리 수정
  const updateEventHistory = async (value) => {
    let event_history = JSON.parse(JSON.stringify(all_event_data))

    event_history.history.unshift({
      profile: myData.profile ? myData.profile.url : null,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      content: `${value}`,
      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
    })

    await client.mutate({
      mutation: updateEvent,
      variables: {
        id: event_history.id,
        history: event_history.history,
      },
      fetchPolicy: "network-only",
    })
  }
  // 이벤트 안내 메일 생성후 임시저장
  const createEvent = async (save_type) => {
    if (event_data[0].value === "[조아라]<작품명>") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`안내 메일명을 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    if (event_data[1].value === "") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`연관된 이메일를 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    if (event_data[2].value === "") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`발송 이메일 주소를 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    if (event_data[3].value === "") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`발송인을 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    dispatch(loading_Apply(true))
    let img_list = {
      id: [],
      data: [],
    }
    if (event_data[5].img.length > 0) {
      for (let i = 0; i < event_data[5].img.length; i++) {
        img_list.id.push(event_data[5].img[i].file[0].id)
        img_list.data.push({
          id: event_data[5].img[i].file[0].id,
          url: event_data[5].img[i].file[0].url,
          name: event_data[5].img[i].file[0].name,
          title: event_data[5].img[i].file[1].value,
          link: event_data[5].img[i].file[2].value,
          description: event_data[5].img[i].file[3].value,
          width: event_data[5].img[i].file[0].size.value1,
          height: event_data[5].img[i].file[0].size.value2,
        })
      }
    }
    await client
      .mutate({
        mutation: createEventResultEmail,
        variables: {
          nameType: events_email_receiver,
          eventId: String(all_event_data.id),
          title: event_data[0].value,
          emailTitle: event_data[1].value,
          senderEmail: event_data[2].value,
          senderName: event_data[3].value,
          content: event_data[5].value,
          emailImage: img_list.id,
          emailImages: img_list.data,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        if (event_data[4].data.length > 0) {
          let receiver_list = event_data[4].data
          for (let i = 0; i < receiver_list.length; i++) {
            let img_table_list = {
              id: [],
              data: [],
            }
            if (receiver_list[i].img && receiver_list[i].img.length > 0) {
              for (let o = 0; o < receiver_list[i].img.length; o++) {
                img_table_list.id.push(receiver_list[i].img[o].file[0].id)
                img_table_list.data.push({
                  id: receiver_list[i].img[o].file[0].id,
                  url: receiver_list[i].img[o].file[0].url,
                  name: receiver_list[i].img[o].file[0].name,
                  title: receiver_list[i].img[o].file[1].value,
                  link: receiver_list[i].img[o].file[2].value,
                  description: receiver_list[i].img[o].file[3].value,
                  width: receiver_list[i].img[o].file[0].size.value1,
                  height: receiver_list[i].img[o].file[0].size.value2,
                })
              }
            }
            await client.mutate({
              mutation: createEventRecevier,
              variables: {
                eventId: String(all_event_data.id),
                genre: receiver_list[i].genre,
                email: receiver_list[i].re_email,
                eventEmailId: res.data.createEventResultEmail.eventResultEmail.id,
                joaraName: receiver_list[i].joara_name,
                writerId: String(receiver_list[i].writerId),
                name: receiver_list[i].real_name,
                ebook: receiver_list[i].ebook,
                phone: receiver_list[i].phone,
                title: receiver_list[i].title,
                bookCode: receiver_list[i].book_code,
                rewarod: receiver_list[i].reward,
                manager: receiver_list[i].manager,
                result_status: receiver_list[i].send_result_status,
                status: receiver_list[i].send_status,
                access:
                  receiver_list[i].agree_status === "미확인"
                    ? "unidentified"
                    : receiver_list[i].agree_status === "승인"
                    ? "access"
                    : "reject",
                agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                code: new Date().getTime().toString(36),
                mailType: "result",
                list: img_table_list.id,
                lists: img_table_list.data,
              },
              fetchPolicy: "network-only",
            })
            if (save_type !== "all" && receiver_list.length - 1 === i) {
              await updateEventHistory("<strong>결과 보고 정보</strong>를 등록하였습니다.")
              eventDataReset()
              startSetting(all_event_data.id)
              set_type_data("detail")
              dispatch(editStatus_Apply(false))
              dispatch(loading_Apply(false))
            }
          }
        } else {
          if (save_type !== "all") {
            await updateEventHistory("<strong>결과 보고 정보</strong>를 등록하였습니다.")
            eventDataReset()
            startSetting(all_event_data.id)
            set_type_data("detail")
            dispatch(editStatus_Apply(false))
            dispatch(loading_Apply(false))
          }
        }
      })
  }
  // 이벤트 안내 메일 디테일 임시저장
  const createEventDetailSave = async (event_index, save_type) => {
    if (!save_type) {
      if (event_data_list[event_index].data[0].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (
        event_data_list[event_index].data[0].value === "[조아라]<작품명>" ||
        event_data_list[event_index].data[0].value === "[조아라]<작품명>"
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (event_data_list[event_index].data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`연관된 이메일을 선택해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (event_data_list[event_index].data[2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (event_data_list[event_index].data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
    }
    dispatch(loading_Apply(true))
    let update_event = event_data_list[event_index]

    let img_list = {
      id: [],
      data: [],
    }
    if (update_event.data[5].img.length > 0) {
      for (let i = 0; i < update_event.data[5].img.length; i++) {
        img_list.id.push(update_event.data[5].img[i].file[0].id)
        img_list.data.push({
          id: update_event.data[5].img[i].file[0].id,
          url: update_event.data[5].img[i].file[0].url,
          name: update_event.data[5].img[i].file[0].name,
          title: update_event.data[5].img[i].file[1].value,
          link: update_event.data[5].img[i].file[2].value,
          description: update_event.data[5].img[i].file[3].value,
          width: update_event.data[5].img[i].file[0].size.value1,
          height: update_event.data[5].img[i].file[0].size.value2,
        })
      }
    }
    await client
      .mutate({
        mutation: updateEventResultEmail,
        variables: {
          id: update_event.data[0].send_email_id,
          nameType: update_event.name_filter,
          eventId: update_event.data[0].event_id,
          title: update_event.data[0].value,
          emailTitle: update_event.data[1].value,
          senderEmail: update_event.data[2].value,
          senderName: update_event.data[3].value,
          content: update_event.data[5].value,
          emailImage: img_list.id,
          emailImages: img_list.data,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        if (update_event.data[4].data.length > 0) {
          let receiver_list = update_event.data[4].data
          for (let i = 0; i < receiver_list.length; i++) {
            let img_table_list = {
              id: [],
              data: [],
            }
            if (receiver_list[i].img.length > 0) {
              for (let o = 0; o < receiver_list[i].img.length; o++) {
                img_table_list.id.push(receiver_list[i].img[o].file[0].id)
                img_table_list.data.push({
                  id: receiver_list[i].img[o].file[0].id,
                  url: receiver_list[i].img[o].file[0].url,
                  name: receiver_list[i].img[o].file[0].name,
                  title: receiver_list[i].img[o].file[1].value,
                  link: receiver_list[i].img[o].file[2].value,
                  description: receiver_list[i].img[o].file[3].value,
                  width: receiver_list[i].img[o].file[0].size.value1,
                  height: receiver_list[i].img[o].file[0].size.value2,
                })
              }
            }
            if (!receiver_list[i].receiverId) {
              await client.mutate({
                mutation: createEventRecevier,
                variables: {
                  eventId: String(all_event_data.id),
                  eventEmailId: res.data.updateEventResultEmail.eventResultEmail.id,
                  email: receiver_list[i].re_email,
                  name: receiver_list[i].real_name,
                  genre: receiver_list[i].genre,
                  ebook: receiver_list[i].ebook,
                  phone: receiver_list[i].phone,
                  joaraName: receiver_list[i].joara_name,
                  writerId: String(receiver_list[i].writerId),
                  title: receiver_list[i].title,
                  bookCode: receiver_list[i].book_code,
                  rewarod: receiver_list[i].reward,
                  manager: receiver_list[i].manager,
                  result_status: receiver_list[i].send_result_status,
                  status: receiver_list[i].send_status,
                  access:
                    receiver_list[i].agree_status === "미확인"
                      ? "unidentified"
                      : receiver_list[i].agree_status === "승인"
                      ? "access"
                      : "reject",
                  agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                  code: new Date().getTime().toString(36),
                  mailType: "result",
                  list: img_table_list.id,
                  lists: img_table_list.data,
                },
                fetchPolicy: "network-only",
              })
            } else if (receiver_list[i].receiverId) {
              await client.mutate({
                mutation: updateEventRecevier,
                variables: {
                  id: receiver_list[i].receiverId,
                  rewarod: receiver_list[i].reward,
                  manager: receiver_list[i].manager,
                  access:
                    receiver_list[i].agree_status === "미확인"
                      ? "unidentified"
                      : receiver_list[i].agree_status === "승인"
                      ? "access"
                      : "reject",
                  agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                  list: img_table_list.id,
                  lists: img_table_list.data,
                },
                fetchPolicy: "network-only",
              })
            }
            if (receiver_list.length - 1 === i) {
              if (update_event.data[4].deleteData && update_event.data[4].deleteData.length > 0) {
                for (let k = 0; k < update_event.data[4].deleteData.length; k++) {
                  await client.mutate({
                    mutation: updateEventRecevier,
                    variables: {
                      id: update_event.data[4].deleteData[k].receiverId,
                      eventEmailId: null,
                    },
                    fetchPolicy: "network-only",
                  })
                }
              }
            }
            if (save_type !== "all" && receiver_list.length - 1 === i) {
              await updateEventHistory("<strong>결과 보고 정보</strong>를 수정하였습니다.")
              eventDataReset()
              startSetting(all_event_data.id)
              set_type_data("detail")
              dispatch(editStatus_Apply(false))
              dispatch(loading_Apply(false))
            }
          }
        } else {
          if (update_event.data[4].deleteData && update_event.data[4].deleteData.length > 0) {
            for (let k = 0; k < update_event.data[4].deleteData.length; k++) {
              await client.mutate({
                mutation: updateEventRecevier,
                variables: {
                  id: update_event.data[4].deleteData[k].receiverId,
                  eventEmailId: null,
                },
                fetchPolicy: "network-only",
              })
            }
          }
          if (save_type !== "all") {
            await updateEventHistory("<strong>결과 보고 정보</strong>를 수정하였습니다.")
            eventDataReset()
            startSetting(all_event_data.id)
            set_type_data("detail")
            dispatch(editStatus_Apply(false))
            dispatch(loading_Apply(false))
          }
        }
      })
  }
  const handleSave = async (e) => {
    if (create_show) {
      await createEvent("all")
    }
    if (event_data_list.length === 0) {
      await updateEventHistory("<strong>결과 보고 정보</strong>를 수정하였습니다.")
      eventDataReset()
      await startSetting(all_event_data.id)
      set_type_data("detail")
      dispatch(editStatus_Apply(false))
      dispatch(loading_Apply(false))
    } else {
      for (let i = 0; i < event_data_list.length; i++) {
        if (event_data_list[i].data[0].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 안내 메일명을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (
          event_data_list[i].data[0].value === "[조아라]<작품명>" ||
          event_data_list[i].data[0].value === "[조아라]<작품명>"
        ) {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 안내 메일명을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (event_data_list[i].data[1].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 연관된 이메일을 선택해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (event_data_list[i].data[2].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 발송 이메일 주소를 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (event_data_list[i].data[3].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 발송인을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
      }
      for (let i = 0; i < event_data_list.length; i++) {
        await createEventDetailSave(i, "all")
        if (event_data_list.length - 1 === i) {
          await updateEventHistory("<strong>결과 보고 정보</strong>를 수정하였습니다.")
          eventDataReset()
          await startSetting(all_event_data.id)
          set_type_data("detail")
          if (e.target.value === "exit") {
            saveExit()
          } else {
            if (document.getElementById("saveEditBtn")) {
              document.getElementById("saveEditBtn").value = ""
            }
          }
          dispatch(editStatus_Apply(false))
          dispatch(loading_Apply(false))
        }
      }
    }
  }

  const InputTypeEl = (data, index, type, index2, list) => {
    if (index < 4) {
      if (type_data === "detail") {
        if (data.type === "monthPicker") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1,
                  mb: 1,
                  color: "#535353",
                }}
              >
                <SuiBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: "#535353",
                  }}
                >
                  <IconButton color="text2" sx={{ mb: 0, mx: 1 }}>
                    <BiCalendar />
                  </IconButton>

                  <div style={{ margin: "0 auto" }}>
                    {dayjs(data.value).format("YYYY-MM-DD  | a  HH:mm")}
                  </div>
                </SuiBox>
              </Card>
            </SuiBox>
          )
        } else {
          return (
            <SuiBox sx={{ width: "100%" }}>
              {data.value?.length < 25 ? (
                <Card
                  sx={{
                    display: "block",
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    px: 2,
                    color: "#535353",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data.value.indexOf("[조아라]") > -1 ? (
                    <>
                      <span
                        style={{
                          color:
                            data.value.split("[조아라]")[0] === "[등록 순서] " ? "" : "#0C65FF",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {data.value.split("[조아라]")[0]}
                      </span>
                      {"[조아라] " + data.value.split("[조아라]")[1]}
                    </>
                  ) : (
                    <>{data.value}</>
                  )}
                </Card>
              ) : (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {data.value.indexOf("[조아라]") > -1 ? (
                          <>
                            <span
                              style={{
                                color:
                                  data.value.split("[조아라]")[0] === "[등록 순서] "
                                    ? ""
                                    : "#0C65FF",
                                marginRight: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {data.value.split("[조아라]")[0]}
                            </span>
                            {"[조아라] " + data.value.split("[조아라]")[1]}
                          </>
                        ) : (
                          <>{data.value}</>
                        )}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <Card
                    sx={{
                      display: "block",
                      width: "100%",
                      height: 57,
                      backgroundColor: "#F7F9FB",
                      boxShadow: 0,
                      textAlign: "center",
                      fontSize: 18,
                      fontWeight: 700,
                      pt: 1.8,
                      mb: 1,
                      px: 2,
                      color: "#535353",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data.value.indexOf("[조아라]") > -1 ? (
                      <>
                        <span
                          style={{
                            color:
                              data.value.split("[조아라]")[0] === "[등록 순서] " ? "" : "#0C65FF",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {data.value.split("[조아라]")[0]}
                        </span>
                        {"[조아라] " + data.value.split("[조아라]")[1]}
                      </>
                    ) : (
                      <>{data.value}</>
                    )}
                  </Card>
                </Tooltip>
              )}
            </SuiBox>
          )
        }
      } else if (type_data === "create" || type_data === "update") {
        if (data.type === "monthPicker") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                <DateTimePicker
                  readOnly={
                    list?.data[4]?.data.filter((x) => x.send_result_status !== "미발송").length
                  }
                  className={
                    list?.data[4]?.data.filter((x) => x.send_result_status !== "미발송").length
                      ? "event_email_datepicker event_email_datepicker_disabled "
                      : "event_email_datepicker"
                  }
                  inputFormat="YYYY-MM-DD  | A  HH:mm"
                  mask="____-__-__  | _  __:__"
                  value={data.value}
                  disableMaskedInput={true}
                  onChange={handleDatePickerChange(index, type, index2)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </SuiBox>
          )
        } else if (data.type === "textFiled") {
          return (
            <SuiBox className={"textFiled-Writer"}>
              <SuiInput
                error={data.error}
                success={data.success}
                helperText={data.error_message}
                type={data.inputType}
                placeholder=""
                value={data.value}
                onChange={
                  list?.data[4]?.data.filter((x) => x.send_result_status !== "미발송").length
                    ? ""
                    : handleInput(index, data.maxLength, type, index2)
                }
              />
            </SuiBox>
          )
        } else if (data.type === "typography") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              {data.value?.length < 25 ? (
                <Card
                  sx={{
                    display: "block",
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    px: 2,
                    color: "#535353",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data.value.indexOf("[조아라]") > -1 ? (
                    <>
                      <span
                        style={{
                          color:
                            data.value.split("[조아라]")[0] === "[등록 순서] " ? "" : "#0C65FF",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {data.value.split("[조아라]")[0]}
                      </span>
                      {"[조아라] " + data.value.split("[조아라]")[1]}
                    </>
                  ) : (
                    <>{data.value}</>
                  )}
                </Card>
              ) : (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {data.value.indexOf("[조아라]") > -1 ? (
                          <>
                            <span
                              style={{
                                color:
                                  data.value.split("[조아라]")[0] === "[등록 순서] "
                                    ? ""
                                    : "#0C65FF",
                                marginRight: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {data.value.split("[조아라]")[0]}
                            </span>
                            {"[조아라] " + data.value.split("[조아라]")[1]}
                          </>
                        ) : (
                          <>{data.value}</>
                        )}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <Card
                    sx={{
                      display: "block",
                      width: "100%",
                      height: 57,
                      backgroundColor: "#F7F9FB",
                      boxShadow: 0,
                      textAlign: "center",
                      fontSize: 18,
                      fontWeight: 700,
                      pt: 1.8,
                      mb: 1,
                      px: 2,
                      color: "#535353",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data.value.indexOf("[조아라]") > -1 ? (
                      <>
                        <span
                          style={{
                            color:
                              data.value.split("[조아라]")[0] === "[등록 순서] " ? "" : "#0C65FF",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {data.value.split("[조아라]")[0]}
                        </span>
                        {"[조아라] " + data.value.split("[조아라]")[1]}
                      </>
                    ) : (
                      <>{data.value}</>
                    )}
                  </Card>
                </Tooltip>
              )}
            </SuiBox>
          )
        }
      }
    }
  }
  const Content = () => {
    return (
      <div style={{ paddingBottom: "60px" }}>
        {type_data === "create" || type_data === "update" ? (
          <SuiButton
            sx={{
              mt: 7.5,
              minHeight: 67,
              fontSize: 18,
              fontWeight: 700,
              boxShadow: 3,
              width: "100%",
            }}
            className="outlined-bg-white"
            variant="outlined"
            color="primary"
            onClick={() => addEvent()}
          >
            발송 메일 생성하기
          </SuiButton>
        ) : (
          ""
        )}
        <SuiBox sx={{ width: 100, ml: "auto", mt: 4 }}>
          <FormControl
            sx={{ minHeight: 30, width: "100%" }}
            className={
              sort_events === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => set_filters_open1(!filters_open1)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => set_filters_open1(!filters_open1)}
                />
              )}
              open={filters_open1}
              value={sort_events}
              onChange={(e) => {
                set_sort_events(e.target.value)
                if (e.target.value === "최신순") {
                  startSetting(all_event_data.id)
                } else {
                  startSetting(all_event_data.id, "created_at:Asc")
                }
              }}
              className="setting-border-radius"
            >
              <MenuItem value="최신순">최신순</MenuItem>
              <MenuItem value="오래된 순">오래된 순</MenuItem>
            </Select>
          </FormControl>
        </SuiBox>
        {create_show && (type_data === "create" || type_data === "update") ? (
          <Card sx={{ mt: 2, boxShadow: 0, px: 4, pt: 1, pb: 3 }}>
            <SuiBox display="flex" alignItems="center">
              <input
                className="addEvent"
                type="text"
                placeholder="안내메일 명을 입력해주세요."
                value={event_data[0].value}
                onChange={handleInput(0, event_data[0].maxLength, "add")}
              ></input>
              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  color="success"
                  variant="text"
                  sx={{ fontSize: "18px", fontWeight: 400 }}
                  onClick={() => createEvent()}
                >
                  임시저장
                </SuiButton>
                <SuiButton
                  color="subTitle"
                  variant="text"
                  sx={{ fontSize: "18px", fontWeight: 400 }}
                  onClick={eventDataReset}
                >
                  취소
                </SuiButton>
                <SuiButton
                  className="only-text-btn"
                  sx={{ fontSize: "18px", fontWeight: 400, pr: 0 }}
                  color="info2"
                  variant="text"
                  disabled={
                    event_data[0].all_send
                      ? true
                      : event_data[4].data.length === 0
                      ? true
                      : event_data[4].data.filter((x) => x.select === false).length > 0
                      ? true
                      : false
                  }
                  onClick={() => sendAllEmail("create")}
                >
                  전체발송
                </SuiButton>
              </SuiBox>
            </SuiBox>
            <Divider sx={{ mb: 4, mt: 1 }} />

            <SuiBox>
              <SuiTypography
                fontSize="16px"
                fontWeight="bold"
                color="dark"
                sx={{ textAlign: "left" }}
              >
                작품 정보
              </SuiTypography>
              <Grid container alignItems="center">
                {event_data.map((row, index) => {
                  if (index > 0 && index !== 8) {
                    return (
                      <Grid item xs={row.xs} key={index + "][1"} sx={{ pr: 1.5 }}>
                        <SuiBox>
                          <SuiBox display="flex">
                            <SuiTypography
                              fontSize="15px"
                              color="info"
                              sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                            >
                              {row.title}
                            </SuiTypography>
                            {type_data !== "detail" && index === 1 && (
                              <span
                                style={{
                                  marginLeft: "auto",
                                  padding: "16px 8px 8px 0",
                                  fontSize: "14px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => changeEmail("add")}
                              >
                                변경
                              </span>
                            )}
                          </SuiBox>
                          {InputTypeEl(row, index, "add")}
                        </SuiBox>
                      </Grid>
                    )
                  }
                })}
                <Grid item xs={12} sx={{ pr: 1.5 }}>
                  <SuiBox>
                    <SuiBox display="flex">
                      <SuiTypography
                        fontSize="15px"
                        color="info"
                        sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                      >
                        메인 이미지 첨부
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox display="flex" justifyContent="start">
                      <SuiButton
                        sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32 }}
                        style={{ padding: "0 0 0 3px" }}
                        color="primary"
                        onClick={() => {
                          let file_upload = { ...file_open }
                          file_upload.editBool = false
                          file_upload.editData = {
                            type: "main",
                            current: "add",
                          }
                          file_upload.dialog = true
                          set_file_open(file_upload)
                        }}
                      >
                        <BiImageAdd ePlus size="25" />
                      </SuiButton>
                      {event_data[5].img.length > 0 &&
                        event_data[5].img.map((img, index) => {
                          return (
                            <SuiBox
                              display="flex"
                              alignItems="center"
                              sx={{
                                px: 1,
                                backgroundColor: "#f7f9fb",
                                borderRadius: "5px",
                                "&::-webkit-scrollbar": {
                                  display: "block",
                                },
                              }}
                              key={index + ";b;"}
                            >
                              <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                                {GetFileIcon(
                                  img.file[0].file.name.split(".")[
                                    img.file[0].file.name.split(".").length - 1
                                  ]
                                )}
                              </IconButton>
                              <SuiTypography
                                sx={{ fontSize: "12px", cursor: "pointer" }}
                                onClick={() => imgUpdateClick("add_main", index, "-1", "-1", img)}
                              >
                                {img.file[0].file.name.length > 8
                                  ? img.file[0].file.name.slice(0, 8) +
                                    "..." +
                                    img.file[0].file.name.slice(
                                      img.file[0].file.name.length - 3,
                                      img.file[0].file.name.length
                                    )
                                  : img.file[0].file.name}
                              </SuiTypography>
                              <IoMdCloseCircle
                                style={{
                                  marginLeft: "5px",
                                  cursor: type_data !== "detail" ? "pointer" : "",
                                }}
                                onClick={() => imgCloseClick("add", index, -1)}
                              ></IoMdCloseCircle>
                            </SuiBox>
                          )
                        })}
                    </SuiBox>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
            <Divider sx={{ mb: "30px", mt: "30px" }} />
            <SuiBox>
              <SuiBox display="flex" alignItems="center" sx={{ mb: 0 }}>
                <SuiTypography
                  fontSize="16px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left" }}
                >
                  이메일 대상자 ({event_data[4] ? event_data[4].data.length : "0"})
                </SuiTypography>
                <SuiBox sx={{ ml: "auto" }}>
                  <IconButton onClick={() => eventDropDown(event_data[0].dropdown, "add")}>
                    {event_data[0].dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <SuiBox
              sx={{
                boxShadow: 0,
                display: event_data[0].dropdown ? "" : "none",
              }}
            >
              <SuiBox display="flex" alignItems="center">
                <SuiBox sx={{ width: 150 }}>
                  <FormControl
                    sx={{
                      minHeight: 25,
                      width: "100%",
                      "> .MuiOutlinedInput-root": {
                        backgroundColor: type_data === "detail" ? "#F7F9FB !important" : "",
                      },
                    }}
                    className={
                      sort_events === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => set_filters_open2(!filters_open2)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => set_filters_open2(!filters_open2)}
                        />
                      )}
                      open={filters_open2}
                      value={events_email_receiver}
                      onChange={(e) => {
                        set_events_email_receiver(e.target.value)
                        let change = [...event_data]
                        let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
                        change[5].value = `<p><strong>${
                          e.target.value
                        }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
                          all_event_data.start
                        ).format("MM/DD(ddd)")}</strong>부터 <strong>${
                          all_event_data.flatform
                        }</strong>에서 진행된 <strong>${
                          all_event_data.title
                        }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
                          all_event_data.title
                        }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
                          all_event_data.end
                        }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

                        let img_s =
                          change[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
                          String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                        let img_e = change[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>")
                        change[5].value = change[5].value.replace(
                          change[5].value.substring(img_s, img_e),
                          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                        )
                        let replace_str = ""
                        if (change[5].img && change[5].img.length > 0) {
                          for (let j = 0; j < change[5].img.length; j++) {
                            if (change[5].img[j].file[2].value) {
                              replace_str =
                                replace_str +
                                `<p>[${change[5].img[j].file[1].value}]</p><p><a href="${
                                  change[5].img[j].file[2].value
                                }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                  imgPathUrl + change[5].img[j].file[0].url
                                }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                  change[5].img[j].file[0].size.value2
                                }px"></a></p><p><br></p>`
                            } else {
                              replace_str =
                                replace_str +
                                `<p>[${
                                  change[5].img[j].file[1].value
                                }]</p><p><img referrerpolicy="no-referrer" src="${
                                  imgPathUrl + change[5].img[j].file[0].url
                                }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                  change[5].img[j].file[0].size.value2
                                }px"></p><p><br></p>`
                            }
                          }
                        }
                        change[5].value = change[5].value.replaceAll(
                          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                          replace_str +
                            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                        )
                        set_event_data(change)
                      }}
                      className="setting-border-radius"
                    >
                      <MenuItem value="작가 본명">작가 본명</MenuItem>
                      <MenuItem value="이북 필명">이북 필명</MenuItem>
                      <MenuItem value="조아라 필명">조아라 필명</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>

                {type_data !== "update" && type_data !== "create" ? (
                  ""
                ) : (
                  <>
                    <SuiBox display="flex" justifyContent="start">
                      <SuiButton
                        sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32, p: 1, ml: 2 }}
                        color="primary"
                        onClick={() => openSingleDialog()}
                      >
                        <AiOutlinePlus size="20" />
                      </SuiButton>
                    </SuiBox>
                    <SuiBox>
                      <SuiButton
                        sx={{
                          minHeight: 32,
                          height: 32,
                          width: 180,
                          minWidth: 180,
                          p: 1,
                          ml: 2,
                          border: "1px dashed rgba(92, 56, 255, 0.5) !important",
                        }}
                        color="primary"
                        variant="outlined"
                        onClick={() => excelImport(-1)}
                      >
                        북코드 업로드
                        <BiUpload size="20" style={{ marginLeft: "5px" }} />
                      </SuiButton>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="event_excel_import"
                        onChange={readExcel}
                      />
                    </SuiBox>
                    {excel_file_name && (
                      <SuiBox
                        display="flex"
                        alignItems="center"
                        sx={{ ml: 2, px: 1, backgroundColor: "#f7f9fb", borderRadius: "5px" }}
                      >
                        <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                          {GetFileIcon("xlsx")}
                        </IconButton>
                        <SuiTypography sx={{ fontSize: "12px" }}>{excel_file_name}</SuiTypography>
                        <IoMdCloseCircle
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => excelCloseClick("add")}
                        ></IoMdCloseCircle>
                      </SuiBox>
                    )}
                  </>
                )}
              </SuiBox>
              {event_data[4].data.length > 0 && (
                <>
                  <SuiBox display="flex" alignItems="center" sx={{ mt: 3, mb: 1 }}>
                    <SuiBox display="flex" alignItems="center">
                      <SuiTypography
                        fontSize="14px"
                        sx={{ textAlign: "left", color: "#707070", width: 130 }}
                      >
                        전체:
                      </SuiTypography>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="dark"
                        sx={{ textAlign: "left" }}
                      >
                        {event_data[4].data.length}명
                      </SuiTypography>
                    </SuiBox>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ mx: 2, height: 20 }}
                    />
                    <SuiBox display="flex" alignItems="center">
                      <SuiTypography
                        fontSize="14px"
                        sx={{ textAlign: "left", color: "#707070", width: 130 }}
                      >
                        발송:
                      </SuiTypography>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="success"
                        sx={{ textAlign: "left" }}
                      >
                        {event_data[4].data.filter((x) => x.result_status === "완료").length}명
                      </SuiTypography>
                    </SuiBox>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ mx: 2, height: 20 }}
                    />
                    <SuiBox display="flex" alignItems="center">
                      <SuiTypography
                        fontSize="14px"
                        sx={{ textAlign: "left", color: "#707070", width: 130 }}
                      >
                        미발송:
                      </SuiTypography>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="dark"
                        sx={{ textAlign: "left" }}
                      >
                        {event_data[4].data.filter((x) => x.result_status === "미발송").length}명
                      </SuiTypography>
                    </SuiBox>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ mx: 2, height: 20 }}
                    />
                    <SuiBox display="flex" alignItems="center">
                      <SuiTypography
                        fontSize="14px"
                        sx={{ textAlign: "left", color: "#707070", width: 130 }}
                      >
                        발송실패:
                      </SuiTypography>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="error"
                        sx={{ textAlign: "left" }}
                      >
                        {event_data[4].data.filter((x) => x.result_status === "실패").length}명
                      </SuiTypography>
                    </SuiBox>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ mx: 2, height: 20 }}
                    />
                    <SuiBox display="flex" alignItems="center" sx={{ ml: "auto" }}>
                      <SuiButton
                        sx={{
                          minHeight: 32,
                          height: 32,
                          width: 32,
                          minWidth: 32,
                          p: 1,
                          ml: 2,
                          borderRadius: 30,
                        }}
                        color="trash"
                        disabled={event_data[4].data.filter((x) => x.select).length === 0}
                        onClick={() => {
                          deleteSender("add")
                        }}
                      >
                        <FiTrash2 size="20" />
                      </SuiButton>
                      <SuiButton
                        sx={{
                          minHeight: 32,
                          height: 32,
                          width: 32,
                          minWidth: 32,
                          p: 1,
                          ml: 3,
                          borderRadius: 30,
                        }}
                        color="send"
                        disabled={event_data[4].data.filter((x) => x.select).length === 0}
                        style={{ paddingTop: "10px" }}
                        onClick={() => {
                          singleSend("add")
                        }}
                      >
                        <FiSend size="20" />
                      </SuiButton>
                      <SuiButton
                        sx={{
                          minHeight: 32,
                          height: 32,
                          width: 32,
                          minWidth: 32,
                          p: 1,
                          ml: 3,
                        }}
                        variant="outlined"
                        style={{ color: "#000000", border: "1.5px solid #c4c4c4" }}
                        onClick={() => {
                          reflash("add")
                        }}
                      >
                        <MdRefresh size="25" />
                      </SuiButton>
                    </SuiBox>
                    {/* <SuiBox sx={{ width: 70, ml: 3 }}>
                      <FormControl
                        sx={{ minHeight: 25, width: "100%" }}
                        className={
                          sort_events === ""
                            ? "setting-selectbox select-none-active"
                            : "setting-selectbox"
                        }
                        onClick={() => set_filters_open3(!filters_open3)}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              color="black"
                              className="select-icon cursor"
                              onClick={() => set_filters_open3(!filters_open3)}
                            />
                          )}
                          open={filters_open3}
                          value={events_email_rows_page}
                          onChange={(e) => {
                            let arr_email_receiver = [...event_data]
                            set_events_email_rows_page(e.target.value)
                            set_event_table_page(1)
                            set_event_table_total_page(
                              Math.ceil(arr_email_receiver[4].data.length / e.target.value)
                            )
                          }}
                          className="setting-border-radius"
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </SuiBox> */}
                  </SuiBox>

                  <TableContainer
                    sx={{
                      borderTop: "1px solid #F1F1F5",
                      borderRadius: 0,
                      boxShadow: 0,
                      marginRight: "40px",
                      mt: 2,
                      maxHeight: 770,
                      "&::-webkit-scrollbar": {
                        display: "block !important",
                      },
                    }}
                  >
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                      <EnhancedTableHead
                        all_select_click={all_select_click}
                        select_type={{ type: "add", index }}
                      />
                      <TableBody>
                        {event_data[4].data
                          // .slice(
                          //   (event_table_page - 1) * events_email_rows_page,
                          //   (event_table_page - 1) * events_email_rows_page + events_email_rows_page
                          // )
                          .map((data, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                key={index + "p12444"}
                                sx={{ backgroundColor: data.select ? "#FAF8FF" : "" }}
                              >
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {(event_table_page - 1) * events_email_rows_page + index + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.real_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.ebook}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.phone}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.title.length > 16 ? (
                                    <Tooltip
                                      arrow={false}
                                      color="white"
                                      classes={{ popper: "tooltip-light" }}
                                      title={
                                        <SuiBox>
                                          <SuiTypography
                                            style={{
                                              fontSize: "14px",
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            verticalAlign="middle"
                                          >
                                            {data.title}
                                          </SuiTypography>
                                        </SuiBox>
                                      }
                                    >
                                      {data.title.slice(0, 16) + "..."}
                                    </Tooltip>
                                  ) : (
                                    data.title
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    color:
                                      data.send_status === "완료"
                                        ? "#00C160 !important"
                                        : data.send_status === "실패"
                                        ? "red !important"
                                        : "",
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.send_status === "완료" ? "완료" : data.send_status}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    color:
                                      data.agree_status === "승인"
                                        ? "#00C160 !important"
                                        : data.agree_status === "거절"
                                        ? "red !important"
                                        : "",
                                    borderRight: "1px solid #F1F1F5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    set_preview_content({
                                      list: event_data,
                                      index: index,
                                      type: "add",
                                    })
                                    set_open_preview_dialog(true)
                                  }}
                                >
                                  {data.agree_status}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    padding: "5px !important",
                                  }}
                                >
                                  {/* {data.manager_bool ? ( */}
                                  <SuiBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      "> div": {
                                        maxWidth: "100px",
                                      },
                                    }}
                                  >
                                    <SuiInput
                                      placeholder=""
                                      value={data.manager}
                                      onChange={handleManagerInput("add", index)}
                                      onKeyPress={handleManagerEnter("add", index)}
                                      onBlur={handleManagerBlur("add", index)}
                                    />
                                  </SuiBox>
                                  {/* ) : (
                                    <SuiBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      onClick={clickManager("add", index)}
                                      sx={{ height: 30, width: "100%" }}
                                    >
                                      {data.manager && data.manager.length > 10
                                        ? data.manager.slice(0, 10) + "..."
                                        : data.manager}
                                    </SuiBox>
                                  )} */}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    overflow: "auto",
                                    "&::-webkit-scrollbar": {
                                      display: "block !important",
                                    },
                                    cursor: "pointer",
                                  }}
                                >
                                  <SuiBox display="flex" alignItems="center">
                                    {data.img && data.img.length > 0 ? (
                                      data.img.map((img, index2) => {
                                        return (
                                          <SuiBox display="flex" alignItems="center">
                                            <SuiBox
                                              display="flex"
                                              alignItems="center"
                                              sx={{
                                                mr: 2,
                                                px: 1,
                                                backgroundColor: "#f7f9fb",
                                                borderRadius: "5px",
                                              }}
                                              key={index + ";:11;"}
                                            >
                                              <IconButton
                                                sx={{ mr: 1.5, p: 0 }}
                                                className="cursorDefalut"
                                              >
                                                {GetFileIcon(
                                                  img.file[0].file.name.split(".")[
                                                    img.file[0].file.name.split(".").length - 1
                                                  ]
                                                )}
                                              </IconButton>
                                              <SuiTypography
                                                sx={{ fontSize: "12px", cursor: "pointer" }}
                                                onClick={() =>
                                                  imgUpdateClick(
                                                    "add_single",
                                                    index2,
                                                    index,
                                                    "-1",
                                                    img,
                                                    data
                                                  )
                                                }
                                              >
                                                {img.file[0].file.name.length > 5
                                                  ? img.file[0].file.name.slice(0, 5) +
                                                    "..." +
                                                    img.file[0].file.name.slice(
                                                      img.file[0].file.name.length - 3,
                                                      img.file[0].file.name.length
                                                    )
                                                  : img.file[0].file.name}
                                              </SuiTypography>
                                              <IoMdCloseCircle
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: type_data !== "detail" ? "pointer" : "",
                                                }}
                                                onClick={() => imgCloseClick("add", index2, index)}
                                              ></IoMdCloseCircle>
                                            </SuiBox>
                                            {data.img.length - 1 === index2 && (
                                              <SuiButton
                                                sx={{
                                                  minHeight: 32,
                                                  height: 32,
                                                  width: 32,
                                                  minWidth: 32,
                                                }}
                                                style={{ padding: "0 0 0 3px", marginRight: "5px" }}
                                                color="primary"
                                                onClick={() => {
                                                  let file_upload = { ...file_open }
                                                  file_upload.editBool = false
                                                  file_upload.editData = {
                                                    type: "single",
                                                    current: "add",
                                                    current_index: index,
                                                    data: data,
                                                  }
                                                  file_upload.dialog = true
                                                  set_file_open(file_upload)
                                                }}
                                              >
                                                <BiImageAdd ePlus size="25" />
                                              </SuiButton>
                                            )}
                                          </SuiBox>
                                        )
                                      })
                                    ) : type_data !== "detail" ? (
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                          color: "blue",
                                          cursor: "pointer",
                                          margin: "auto",
                                        }}
                                        onClick={() => {
                                          let file_upload = { ...file_open }
                                          file_upload.editBool = false
                                          file_upload.editData = {
                                            type: "single",
                                            current: "add",
                                            current_index: index,
                                            data: data,
                                          }
                                          file_upload.dialog = true
                                          set_file_open(file_upload)
                                        }}
                                      >
                                        첨부하기
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </SuiBox>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                    color:
                                      data.send_result_status === "완료"
                                        ? "#00C160 !important"
                                        : data.send_result_status === "실패"
                                        ? "red !important"
                                        : "",
                                    cursor: "pointer",
                                  }}
                                >
                                  {data.send_result_status === "완료"
                                    ? "완료"
                                    : data.send_result_status}
                                </TableCell>
                                <TableCell align="center" className="td-Setting td-fontSetting">
                                  <Checkbox
                                    checked={data.select}
                                    onChange={handleCheckBox("add", index)}
                                    color="send"
                                    label=""
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <Divider sx={{ mb: "30px", mt: "30px" }} />
              <SuiBox>
                <SuiBox display="flex" alignItems="center" sx={{ mt: 3 }}>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#84a2b1" }}>
                    이메일 내용:
                  </SuiTypography>
                  <SuiBox sx={{ ml: "auto", mb: "10px" }}>
                    {event_data[5].change ? (
                      <SuiButton
                        color="update"
                        variant="contained"
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                          padding: "0px 10px",
                          color: "#E67DAF",
                          mb: "10px",
                          height: "30px",
                          minHeight: "30px",
                        }}
                        onClick={() => {
                          let change = [...event_data]
                          change[5].change = false
                          set_event_data(change)
                        }}
                      >
                        수정하기
                      </SuiButton>
                    ) : (
                      <>
                        <SuiButton
                          color="subTitle"
                          variant="text"
                          sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                          onClick={() => {
                            let change = [...event_data]
                            change[5].change = true
                            let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
                            change[5].value = `<p><strong>${
                              events_email_receiver ? events_email_receiver : "작가 본명"
                            }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
                              all_event_data.start
                            ).format("MM/DD(ddd)")}</strong>부터 <strong>${
                              all_event_data.flatform
                            }</strong>에서 진행된 <strong>${
                              all_event_data.title
                            }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
                              all_event_data.title
                            }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
                              all_event_data.end
                            }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

                            let img_s =
                              change[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
                              String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                            let img_e = change[5].value.indexOf(
                              "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                            )
                            change[5].value = change[5].value.replace(
                              change[5].value.substring(img_s, img_e),
                              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                            )

                            let replace_str = ""
                            if (change[5].img && change[5].img.length > 0) {
                              for (let j = 0; j < change[5].img.length; j++) {
                                if (change[5].img[j].file[2].value) {
                                  replace_str =
                                    replace_str +
                                    `<p>[${change[5].img[j].file[1].value}]</p><p><a href="${
                                      change[5].img[j].file[2].value
                                    }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                      imgPathUrl + change[5].img[j].file[0].url
                                    }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                      change[5].img[j].file[0].size.value2
                                    }px"></a></p><p><br></p>`
                                } else {
                                  replace_str =
                                    replace_str +
                                    `<p>[${
                                      change[5].img[j].file[1].value
                                    }]</p><p><img referrerpolicy="no-referrer" src="${
                                      imgPathUrl + change[5].img[j].file[0].url
                                    }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                      change[5].img[j].file[0].size.value2
                                    }px"></p><p><br></p>`
                                }
                              }
                            }
                            change[5].value = change[5].value.replaceAll(
                              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                              replace_str +
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                            )
                            set_event_data(change)
                          }}
                        >
                          취소
                        </SuiButton>
                        <SuiButton
                          color="search"
                          variant="text"
                          sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                          onClick={() => {
                            let change = [...event_data]
                            change[5].change = true
                            let img_s =
                              change[5].value.indexOf("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>") +
                              String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                            let img_e = change[5].value.indexOf(
                              "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                            )
                            change[5].value = change[5].value.replace(
                              change[5].value.substring(img_s, img_e),
                              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                            )

                            let replace_str = ""
                            if (change[5].img && change[5].img.length > 0) {
                              for (let j = 0; j < change[5].img.length; j++) {
                                if (change[5].img[j].file[2].value) {
                                  replace_str =
                                    replace_str +
                                    `<p>[${change[5].img[j].file[1].value}]</p><p><a href="${
                                      change[5].img[j].file[2].value
                                    }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                      imgPathUrl + change[5].img[j].file[0].url
                                    }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                      change[5].img[j].file[0].size.value2
                                    }px"></a></p><p><br></p>`
                                } else {
                                  replace_str =
                                    replace_str +
                                    `<p>[${
                                      change[5].img[j].file[1].value
                                    }]</p><p><img referrerpolicy="no-referrer" src="${
                                      imgPathUrl + change[5].img[j].file[0].url
                                    }" width="${change[5].img[j].file[0].size.value1}px" height="${
                                      change[5].img[j].file[0].size.value2
                                    }px"></p><p><br></p>`
                                }
                              }
                            }
                            change[5].value = change[5].value.replaceAll(
                              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                              replace_str +
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                            )
                            set_event_data(change)
                          }}
                        >
                          저장
                        </SuiButton>
                      </>
                    )}
                  </SuiBox>
                </SuiBox>
                <SuiBox display="flex" alignItems="center" justifyContent="end" sx={{ mb: "30px" }}>
                  <SuiTypography
                    color="error"
                    variant="text"
                    sx={{ fontSize: "15px", fontWeight: 400, pl: "12px" }}
                  >
                    '수정하기' 사용시 자동입력 양식이 삭제될 수 있습니다.
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox>
                {event_data[5].change ? (
                  <div
                    style={{
                      backgroundColor: "#f7f9fb",
                      fontSize: "14px",
                      textAlign: "start",
                      padding: "12px",
                      borderRadius: "15px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: event_data[5].value.replaceAll(
                        `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                        ""
                      ),
                    }}
                  ></div>
                ) : (
                  <ReactQuill
                    readOnly={event_data[5].change}
                    className="Quill-layout color_position"
                    modules={modules}
                    theme="snow"
                    value={event_data[5].value}
                    formats={formats}
                    onChange={(e) => {
                      quillChange(e, "add")
                    }}
                    ref={quillRef}
                  />
                )}
              </SuiBox>
            </SuiBox>
          </Card>
        ) : (
          ""
        )}
        {event_data_list.map((list, event_index) => {
          return (
            <Card sx={{ mt: "16px", boxShadow: 0, px: 4, pt: 1, pb: 3 }}>
              <SuiBox display="flex" alignItems="center">
                {list.data[4].data.filter(
                  (x) => x.send_result_status && x.send_result_status !== "미발송"
                ).length > 0 && (
                  <span
                    style={{
                      color: "#0C65FF",
                      marginRight: "5px",
                      lineHeight: "48px",
                      fontWeight: "bold",
                    }}
                  >
                    {String(list.num).length === 1
                      ? `[00${list.num}]`
                      : String(list.num).length === 2
                      ? `[0${list.num}]`
                      : `[${list.num}]`}
                  </span>
                )}
                <input
                  className="addEvent"
                  type="text"
                  placeholder="결과보고 명을 입력해주세요."
                  readOnly={
                    list.data[4].data.filter((x) => x.send_result_status !== "미발송").length ||
                    type_data === "detail"
                  }
                  value={list.data[0].value}
                  onChange={handleInput(0, list.data[0].maxLength, "detail", event_index)}
                ></input>
                <SuiBox sx={{ ml: "auto" }}>
                  {type_data !== "detail" && (
                    <>
                      {list.data[4].data.filter((x) => x.send_result_status !== "미발송").length ===
                      0 ? (
                        <>
                          <SuiButton
                            color="success"
                            variant="text"
                            sx={{ fontSize: "18px", fontWeight: 400 }}
                            onClick={() => createEventDetailSave(event_index)}
                          >
                            임시저장
                          </SuiButton>
                          <SuiButton
                            color="subTitle"
                            variant="text"
                            sx={{ fontSize: "18px", fontWeight: 400 }}
                            onClick={() => deleteEvent(event_index)}
                          >
                            취소
                          </SuiButton>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {list.data[4].data.filter((x) => x.send_result_status !== "미발송").length !==
                    0 && (
                    <SuiButton
                      color="green2"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        width: 80,
                        p: 0,
                      }}
                      onClick={() => sendHistory(event_index)}
                    >
                      발송 내역
                    </SuiButton>
                  )}

                  {type_data !== "detail" && !list.num && (
                    <SuiButton
                      className="only-text-btn"
                      sx={{ fontSize: "18px", fontWeight: 400, pr: 0 }}
                      color="info2"
                      variant="text"
                      disabled={
                        list.data[4].data.length === 0
                          ? true
                          : list.data[0].all_send
                          ? true
                          : list.data[4].data.filter((x) => x.select === false).length > 0
                          ? true
                          : false
                      }
                      onClick={() => sendAllEmail("update", event_index)}
                    >
                      전체발송
                    </SuiButton>
                  )}
                </SuiBox>
              </SuiBox>
              <Divider sx={{ mb: 4, mt: 1 }} />

              <SuiBox>
                <SuiTypography
                  fontSize="16px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left" }}
                >
                  작품 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  {list.data.map((row, index) => {
                    if (index > 0 && index !== 8) {
                      return (
                        <Grid item xs={row.xs} key={index + "]zz"} sx={{ pr: 1.5 }}>
                          <SuiBox>
                            <SuiBox display="flex">
                              <SuiTypography
                                fontSize="15px"
                                color="info"
                                sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                              >
                                {row.title}
                              </SuiTypography>
                              {type_data !== "detail" &&
                                list.data[4].data.filter((x) => x.send_result_status !== "미발송")
                                  .length === 0 &&
                                index === 1 && (
                                  <span
                                    style={{
                                      marginLeft: "auto",
                                      padding: "16px 8px 8px 0",
                                      fontSize: "14px",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => changeEmail("detail", event_index)}
                                  >
                                    변경
                                  </span>
                                )}
                            </SuiBox>
                            {InputTypeEl(row, index, "detail", event_index, list)}
                          </SuiBox>
                        </Grid>
                      )
                    }
                  })}
                  <Grid item xs={12} sx={{ pr: 1.5 }}>
                    <SuiBox>
                      <SuiBox display="flex">
                        <SuiTypography
                          fontSize="15px"
                          color="info"
                          sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                        >
                          메인 이미지 첨부
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox display="flex" justifyContent="start">
                        {type_data !== "detail" &&
                          list.data[4].data.filter((x) => x.send_result_status !== "미발송")
                            .length === 0 && (
                            <SuiButton
                              sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32 }}
                              style={{ padding: "0 0 0 3px" }}
                              color="primary"
                              onClick={() => {
                                let file_upload = { ...file_open }
                                file_upload.editBool = false
                                file_upload.editData = {
                                  type: "main",
                                  current: "detail",
                                  event_index: event_index,
                                }
                                file_upload.dialog = true
                                set_file_open(file_upload)
                              }}
                            >
                              <BiImageAdd ePlus size="25" />
                            </SuiButton>
                          )}
                        {list.data[5].img.length > 0 &&
                          list.data[5].img.map((img, index) => {
                            return (
                              <SuiBox
                                display="flex"
                                alignItems="center"
                                sx={{
                                  px: 1,
                                  backgroundColor: "#f7f9fb",
                                  borderRadius: "5px",
                                  "&::-webkit-scrollbar": {
                                    display: "block",
                                  },
                                }}
                                key={index + ";as;"}
                              >
                                <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                                  {GetFileIcon(
                                    img.file[0].file.name.split(".")[
                                      img.file[0].file.name.split(".").length - 1
                                    ]
                                  )}
                                </IconButton>
                                <SuiTypography
                                  sx={{ fontSize: "12px", cursor: "pointer" }}
                                  onClick={() =>
                                    imgUpdateClick(
                                      "detail_main",
                                      index,
                                      "-1",
                                      event_index,
                                      img,
                                      list,
                                      ""
                                    )
                                  }
                                >
                                  {img.file[0].file.name.length > 8
                                    ? img.file[0].file.name.slice(0, 8) +
                                      "..." +
                                      img.file[0].file.name.slice(
                                        img.file[0].file.name.length - 3,
                                        img.file[0].file.name.length
                                      )
                                    : img.file[0].file.name}
                                </SuiTypography>
                                {type_data !== "detail" && (
                                  <IoMdCloseCircle
                                    style={{
                                      marginLeft: "5px",
                                      cursor:
                                        type_data === "detail"
                                          ? ""
                                          : list.data[4].data.filter(
                                              (x) => x.send_result_status !== "미발송"
                                            ).length === 0
                                          ? "pointer"
                                          : "",
                                    }}
                                    onClick={() =>
                                      imgCloseClick("detail", index, -1, event_index, list)
                                    }
                                  ></IoMdCloseCircle>
                                )}
                              </SuiBox>
                            )
                          })}
                      </SuiBox>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
              <Divider sx={{ mb: "30px", mt: "30px" }} />
              <SuiBox>
                <SuiBox display="flex" alignItems="center" sx={{ mb: 0 }}>
                  <SuiTypography
                    fontSize="16px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left" }}
                  >
                    이메일 대상자 ({list.data[4] ? list.data[4].data.length : "0"})
                  </SuiTypography>
                  <SuiBox sx={{ ml: "auto" }}>
                    <IconButton
                      onClick={() => eventDropDown(list.data[0].dropdown, "detail", event_index)}
                    >
                      {list.data[0].dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
              <SuiBox
                sx={{
                  boxShadow: 0,
                  display: list.data[0].dropdown ? "" : "none",
                }}
              >
                <SuiBox display="flex" alignItems="center">
                  <SuiBox sx={{ width: 150 }}>
                    <FormControl
                      sx={{
                        minHeight: 25,
                        width: "100%",
                        "> .MuiOutlinedInput-root": {
                          backgroundColor: type_data === "detail" ? "#F7F9FB !important" : "",
                        },
                      }}
                      className={
                        sort_events === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      readOnly={type_data !== "detail"}
                      onClick={() => {
                        if (type_data !== "detail") {
                          let change = [...event_data_list]
                          change[event_index].name_filter_bool =
                            !change[event_index].name_filter_bool
                          set_event_data_list(change)
                        }
                      }}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            readOnly={type_data !== "detail"}
                            className="select-icon cursor"
                            onClick={() => {
                              if (type_data !== "detail") {
                                let change = [...event_data_list]
                                change[event_index].name_filter_bool =
                                  !change[event_index].name_filter_bool
                                set_event_data_list(change)
                              }
                            }}
                          />
                        )}
                        open={list.name_filter_bool}
                        value={list.name_filter}
                        onChange={(e) => {
                          let change = [...event_data_list]
                          change[event_index].name_filter = e.target.value
                          let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
                          change[event_index].data[5].value = `<p><strong>${
                            e.target.value
                          }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
                            all_event_data.start
                          ).format("MM/DD(ddd)")}</strong>부터 <strong>${
                            all_event_data.flatform
                          }</strong>에서 진행된 <strong>${
                            all_event_data.title
                          }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
                            all_event_data.title
                          }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
                            all_event_data.end
                          }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`

                          let img_s =
                            change[event_index].data[5].value.indexOf(
                              "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                            ) + String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                          let img_e = change[event_index].data[5].value.indexOf(
                            "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                          )
                          change[event_index].data[5].value = change[
                            event_index
                          ].data[5].value.replace(
                            change[event_index].data[5].value.substring(img_s, img_e),
                            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                          )
                          let replace_str = ""
                          if (
                            change[event_index].data[5].img &&
                            change[event_index].data[5].img.length > 0
                          ) {
                            for (let j = 0; j < change[event_index].data[5].img.length; j++) {
                              if (change[event_index].data[5].img[j].file[2].value) {
                                replace_str =
                                  replace_str +
                                  `<p>[${
                                    change[event_index].data[5].img[j].file[1].value
                                  }]</p><p><a href="${
                                    change[event_index].data[5].img[j].file[2].value
                                  }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                    imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                  }" width="${
                                    change[event_index].data[5].img[j].file[0].size.value1
                                  }px" height="${
                                    change[event_index].data[5].img[j].file[0].size.value2
                                  }px"></a></p><p><br></p>`
                              } else {
                                replace_str =
                                  replace_str +
                                  `<p>[${
                                    change[event_index].data[5].img[j].file[1].value
                                  }]</p><p><img referrerpolicy="no-referrer" src="${
                                    imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                  }" width="${
                                    change[event_index].data[5].img[j].file[0].size.value1
                                  }px" height="${
                                    change[event_index].data[5].img[j].file[0].size.value2
                                  }px"></p><p><br></p>`
                              }
                            }
                          }
                          change[event_index].data[5].value = change[
                            event_index
                          ].data[5].value.replaceAll(
                            `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                            replace_str +
                              `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                          )
                          set_event_data_list(change)
                        }}
                        className="setting-border-radius"
                      >
                        <MenuItem value="작가 본명">작가 본명</MenuItem>
                        <MenuItem value="이북 필명">이북 필명</MenuItem>
                        <MenuItem value="조아라 필명">조아라 필명</MenuItem>
                      </Select>
                    </FormControl>
                  </SuiBox>

                  {type_data !== "update" && type_data !== "create" ? (
                    ""
                  ) : (
                    <>
                      <SuiBox display="flex" justifyContent="start">
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            p: 1,
                            ml: 2,
                            display:
                              list.data[4].data.filter((x) => x.send_result_status !== "미발송")
                                .length > 0
                                ? "none"
                                : "",
                          }}
                          color="primary"
                          onClick={() => openSingleDialog(event_index)}
                        >
                          <AiOutlinePlus size="20" />
                        </SuiButton>
                      </SuiBox>
                      <SuiBox>
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 180,
                            minWidth: 180,
                            p: 1,
                            ml: 2,
                            border: "1px dashed rgba(92, 56, 255, 0.5) !important",
                            display:
                              list.data[4].data.filter((x) => x.send_result_status !== "미발송")
                                .length > 0
                                ? "none"
                                : "",
                          }}
                          color="primary"
                          variant="outlined"
                          onClick={() => excelImport(event_index)}
                        >
                          북코드 업로드
                          <BiUpload size="20" style={{ marginLeft: "5px" }} />
                        </SuiButton>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id={`event_excel_import_${event_index}`}
                          onChange={readExcel}
                        />
                      </SuiBox>
                      {list.excel_name && (
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          sx={{ ml: 2, px: 1, backgroundColor: "#f7f9fb", borderRadius: "5px" }}
                        >
                          <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                            {GetFileIcon("xlsx")}
                          </IconButton>
                          <SuiTypography sx={{ fontSize: "12px" }}>{list.excel_name}</SuiTypography>

                          <IoMdCloseCircle
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => excelCloseClick("detail", event_index)}
                          ></IoMdCloseCircle>
                        </SuiBox>
                      )}
                    </>
                  )}
                </SuiBox>
                {list.data[4].data.length > 0 && (
                  <>
                    <SuiBox display="flex" alignItems="center" sx={{ mt: 3, mb: 1 }}>
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{ textAlign: "left", color: "#707070", width: 130 }}
                        >
                          전체:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="dark"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.length}명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{ textAlign: "left", color: "#707070", width: 130 }}
                        >
                          발송:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="success"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.filter((x) => x.send_result_status === "완료").length}
                          명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{ textAlign: "left", color: "#707070", width: 130 }}
                        >
                          미발송:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="dark"
                          sx={{ textAlign: "left" }}
                        >
                          {
                            list.data[4].data.filter((x) => x.send_result_status === "미발송")
                              .length
                          }
                          명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{ textAlign: "left", color: "#707070", width: 130 }}
                        >
                          발송실패:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="error"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.filter((x) => x.send_result_status === "실패").length}
                          명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center" sx={{ ml: "auto" }}>
                        {type_data !== "detail" && (
                          <>
                            <SuiButton
                              sx={{
                                minHeight: 32,
                                height: 32,
                                width: 32,
                                minWidth: 32,
                                p: 1,
                                ml: 2,
                                borderRadius: 30,
                              }}
                              color="trash"
                              disabled={
                                list.data[4].data.filter((x) => x.select).length === 0
                                  ? true
                                  : list.data[4].data.filter(
                                      (x) => x.select && x.send_result_status === "완료"
                                    ).length > 0
                              }
                              onClick={() => deleteSender("detail", event_index)}
                            >
                              <FiTrash2 size="20" />
                            </SuiButton>
                            <SuiButton
                              sx={{
                                minHeight: 32,
                                height: 32,
                                width: 32,
                                minWidth: 32,
                                p: 1,
                                ml: 3,
                                borderRadius: 30,
                              }}
                              color="send"
                              disabled={
                                list.data[4].data.filter((x) => x.select).length === 0
                                  ? true
                                  : false
                              }
                              style={{ paddingTop: "10px" }}
                              onClick={() => singleSend("detail", event_index)}
                            >
                              <FiSend size="20" />
                            </SuiButton>
                          </>
                        )}
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            p: 1,
                            ml: 3,
                          }}
                          variant="outlined"
                          style={{ color: "#000000", border: "1.5px solid #c4c4c4" }}
                          onClick={() => reflash("detail", event_index)}
                        >
                          <MdRefresh size="25" />
                        </SuiButton>
                      </SuiBox>
                      {/* <SuiBox sx={{ width: 70, ml: 3 }}>
                        <FormControl
                          sx={{ minHeight: 25, width: "100%" }}
                          className={
                            sort_events === ""
                              ? "setting-selectbox select-none-active"
                              : "setting-selectbox"
                          }
                          onClick={() => {
                            let change = [...event_data_list]
                            change[event_index].table_rows_bool =
                              !change[event_index].table_rows_bool
                            set_event_data_list(change)
                          }}
                        >
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                fontSize="small"
                                color="black"
                                className="select-icon cursor"
                                onClick={() => {
                                  let change = [...event_data_list]
                                  change[event_index].table_rows_bool =
                                    !change[event_index].table_rows_bool
                                  set_event_data_list(change)
                                }}
                              />
                            )}
                            open={list.table_rows_bool}
                            value={list.table_rows}
                            onChange={(e) => {
                              let change = [...event_data_list]
                              change[event_index].table_rows = e.target.value
                              change[event_index].table_current_page = 1
                              change[event_index].table_total_page = Math.ceil(
                                change[event_index].data[4].data.length / e.target.value
                              )

                              set_event_data_list(change)
                            }}
                            className="setting-border-radius"
                          >
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                          </Select>
                        </FormControl>
                      </SuiBox> */}
                    </SuiBox>

                    <TableContainer
                      sx={{
                        borderTop: "1px solid #F1F1F5",
                        borderRadius: 0,
                        boxShadow: 0,
                        marginRight: "40px",
                        mt: 2,
                        maxHeight: 770,
                        "&::-webkit-scrollbar": {
                          display: "block !important",
                        },
                      }}
                    >
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                        <EnhancedTableHead
                          all_select_click={all_select_click}
                          select_type={{ type: "detail", index: event_index }}
                          type={type_data}
                        />
                        <TableBody>
                          {list.data[4].data
                            // .slice(
                            //   (list.table_current_page - 1) * list.table_rows,
                            //   (list.table_current_page - 1) * list.table_rows + list.table_rows
                            // )
                            .map((data, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  key={index + "p17q11"}
                                  sx={{ backgroundColor: data.select ? "#FAF8FF" : "" }}
                                >
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      cursor: "pointer",
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {(list.table_current_page - 1) * list.table_rows + index + 1}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.real_name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.ebook}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.phone}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.title.length > 16 ? (
                                      <Tooltip
                                        arrow={false}
                                        color="white"
                                        classes={{ popper: "tooltip-light" }}
                                        title={
                                          <SuiBox>
                                            <SuiTypography
                                              style={{
                                                fontSize: "14px",
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                              verticalAlign="middle"
                                            >
                                              {data.title}
                                            </SuiTypography>
                                          </SuiBox>
                                        }
                                      >
                                        {data.title.slice(0, 16) + "..."}
                                      </Tooltip>
                                    ) : (
                                      data.title
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      color:
                                        data.send_status === "완료"
                                          ? "#00C160 !important"
                                          : data.send_status === "실패"
                                          ? "red !important"
                                          : "",
                                      borderRight: "1px solid #F1F1F5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.send_status === "완료" ? "완료" : data.send_status}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      color:
                                        data.agree_status === "승인"
                                          ? "#00C160 !important"
                                          : data.agree_status === "거절"
                                          ? "red !important"
                                          : "",
                                      borderRight: "1px solid #F1F1F5",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      set_preview_content({
                                        list: list,
                                        index: index,
                                      })
                                      set_open_preview_dialog(true)
                                    }}
                                  >
                                    {data.agree_status}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      padding: "5px !important",
                                    }}
                                  >
                                    {/* &&
                                     list.data[4].data.filter((x) => x.send_status !== "미발송")
                                       .length === 0  */}
                                    {type_data !== "detail" ? (
                                      <>
                                        {/* {data.manager_bool ? ( */}
                                        <SuiBox
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          sx={{
                                            "> div": {
                                              maxWidth: "100px",
                                            },
                                          }}
                                        >
                                          <SuiInput
                                            placeholder=""
                                            value={data.manager}
                                            onChange={handleManagerInput(
                                              "detail",
                                              index,
                                              event_index
                                            )}
                                            onKeyPress={handleManagerEnter(
                                              "detail",
                                              index,
                                              event_index
                                            )}
                                            onBlur={handleManagerBlur("detail", index, event_index)}
                                          />
                                        </SuiBox>
                                        {/* ) : (
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            onClick={clickManager("detail", index, event_index)}
                                            sx={{ height: 30, width: "100%" }}
                                          >
                                            {data.manager && data.manager.length > 10
                                              ? data.manager.slice(0, 10) + "..."
                                              : data.manager}
                                          </SuiBox>
                                        )} */}
                                      </>
                                    ) : (
                                      <SuiBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: 30, width: "100%" }}
                                      >
                                        {data.manager}
                                      </SuiBox>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      overflow: "auto",
                                      "&::-webkit-scrollbar": {
                                        display: "block !important",
                                      },
                                    }}
                                  >
                                    <SuiBox display="flex" alignItems="center">
                                      {data.img && data.img.length > 0 ? (
                                        data.img.map((img, index2) => {
                                          return (
                                            <SuiBox display="flex" alignItems="center">
                                              <SuiBox
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                  mr: 2,
                                                  px: 1,
                                                  backgroundColor: "#f7f9fb",
                                                  borderRadius: "5px",
                                                }}
                                                key={index + ";:1;44"}
                                              >
                                                <IconButton
                                                  sx={{ mr: 1.5, p: 0 }}
                                                  className="cursorDefalut"
                                                >
                                                  {GetFileIcon(
                                                    img.file[0].file.name.split(".")[
                                                      img.file[0].file.name.split(".").length - 1
                                                    ]
                                                  )}
                                                </IconButton>
                                                <SuiTypography
                                                  sx={{ fontSize: "12px", cursor: "pointer" }}
                                                  onClick={() =>
                                                    imgUpdateClick(
                                                      "detail_single",
                                                      index2,
                                                      index,
                                                      event_index,
                                                      img,
                                                      list,
                                                      data
                                                    )
                                                  }
                                                >
                                                  {img.file[0].file.name.length > 5
                                                    ? img.file[0].file.name.slice(0, 5) +
                                                      "..." +
                                                      img.file[0].file.name.slice(
                                                        img.file[0].file.name.length - 3,
                                                        img.file[0].file.name.length
                                                      )
                                                    : img.file[0].file.name}
                                                </SuiTypography>
                                                {type_data !== "detail" && (
                                                  <IoMdCloseCircle
                                                    style={{
                                                      marginLeft: "5px",
                                                      cursor:
                                                        type_data === "detail"
                                                          ? ""
                                                          : data.send_result_status !== "완료"
                                                          ? "pointer"
                                                          : "",
                                                    }}
                                                    onClick={() =>
                                                      imgCloseClick(
                                                        "detail",
                                                        index2,
                                                        index,
                                                        event_index,
                                                        list
                                                      )
                                                    }
                                                  ></IoMdCloseCircle>
                                                )}
                                              </SuiBox>
                                              {type_data !== "detail" &&
                                                data.send_result_status !== "완료" &&
                                                data.img.length - 1 === index2 && (
                                                  <SuiButton
                                                    sx={{
                                                      minHeight: 32,
                                                      height: 32,
                                                      width: 32,
                                                      minWidth: 32,
                                                    }}
                                                    style={{
                                                      padding: "0 0 0 3px",
                                                      marginRight: "5px",
                                                    }}
                                                    color="primary"
                                                    onClick={() => {
                                                      let file_upload = { ...file_open }
                                                      file_upload.editBool = false
                                                      file_upload.editData = {
                                                        type: "single",
                                                        current: "detail",
                                                        event_index: event_index,
                                                        current_index: index,
                                                        data: data,
                                                      }
                                                      file_upload.dialog = true
                                                      set_file_open(file_upload)
                                                    }}
                                                  >
                                                    <BiImageAdd ePlus size="25" />
                                                  </SuiButton>
                                                )}
                                            </SuiBox>
                                          )
                                        })
                                      ) : type_data !== "detail" ? (
                                        <span
                                          style={{
                                            textDecoration: "underline",
                                            color:
                                              type_data !== "detail" &&
                                              data.send_result_status !== "완료"
                                                ? "blue"
                                                : "gray",
                                            cursor:
                                              type_data !== "detail" &&
                                              data.send_result_status !== "완료"
                                                ? "pointer"
                                                : "",
                                            margin: "auto",
                                          }}
                                          onClick={() => {
                                            if (
                                              type_data !== "detail" &&
                                              data.send_result_status !== "완료"
                                            ) {
                                              let file_upload = { ...file_open }
                                              file_upload.editBool = false
                                              file_upload.editData = {
                                                type: "single",
                                                current: "detail",
                                                current_index: index,
                                                event_index: event_index,
                                                data: data,
                                              }
                                              file_upload.dialog = true
                                              set_file_open(file_upload)
                                            }
                                          }}
                                        >
                                          {data.send_result_status !== "완료" ? "첨부하기" : ""}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </SuiBox>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      color:
                                        data.send_result_status === "완료"
                                          ? "#00C160 !important"
                                          : data.send_result_status === "실패"
                                          ? "red !important"
                                          : "",
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.send_result_status === "완료"
                                      ? "완료"
                                      : data.send_result_status}
                                  </TableCell>
                                  {type_data !== "detail" && (
                                    <TableCell align="center" className="td-Setting td-fontSetting">
                                      <Checkbox
                                        checked={data.select}
                                        onChange={handleCheckBox("detail", index, event_index)}
                                        color="send"
                                        label=""
                                      />
                                    </TableCell>
                                  )}
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <SuiBox mt={2} display="flex" justifyContent="center">
                      <PaginationCustom
                        page={list.table_current_page}
                        totalPage={list.table_total_page}
                        changePage={eventTablePage("detail", event_index)}
                      />
                    </SuiBox> */}
                  </>
                )}
                <Divider sx={{ mb: "30px", mt: "30px" }} />
                <SuiBox>
                  <SuiBox display="flex" alignItems="center" sx={{ mt: 3 }}>
                    <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#84a2b1" }}>
                      이메일 내용:
                    </SuiTypography>
                    <SuiBox sx={{ ml: "auto", mb: "10px" }}>
                      {list.data[5].change ? (
                        <SuiButton
                          color="update"
                          variant="contained"
                          sx={{
                            fontSize: "15px",
                            fontWeight: 400,
                            padding: "0px 10px",
                            color: "#E67DAF",
                            mb: "10px",
                            height: "30px",
                            minHeight: "30px",
                          }}
                          disabled={
                            type_data === "detail"
                              ? true
                              : list?.data[4]?.data.filter((x) => x.send_result_status !== "미발송")
                                  .length !== 0
                          }
                          onClick={() => {
                            if (
                              list?.data[4]?.data.filter((x) => x.send_result_status !== "미발송")
                                .length === 0
                            ) {
                              let change = [...event_data_list]
                              change[event_index].data[5].change = false
                              set_event_data_list(change)
                            }
                          }}
                        >
                          수정하기
                        </SuiButton>
                      ) : (
                        <>
                          <SuiButton
                            color="subTitle"
                            variant="text"
                            sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                            onClick={(e) => {
                              let change = [...event_data_list]
                              let replace_content = all_event_data?.content.replaceAll(
                                "\n",
                                "<br/>"
                              )
                              change[event_index].data[5].change = true
                              change[event_index].data[5].value = `<p><strong>${
                                change[event_index].name_filter
                                  ? change[event_index].name_filter
                                  : "작가 본명"
                              }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${dayjs(
                                all_event_data.start
                              ).format("MM/DD(ddd)")}</strong>부터 <strong>${
                                all_event_data.flatform
                              }</strong>에서 진행된 <strong>${
                                all_event_data.title
                              }</strong>에</p><p>작가님의 <strong>작품 명</strong> 작품이 포함되어 안내드립니다. :)</p><p><br></p><p>- 이벤트명 :<strong> ${
                                all_event_data.title
                              }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
                                all_event_data.end
                              }</strong></p><p>- 이벤트 내용:</p><p><strong>${replace_content}</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;</p><strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p>그럼 작가님, 이후에도 진행되는 이벤트가 있으면 안내해드리겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
                              let img_s =
                                change[event_index].data[5].value.indexOf(
                                  "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                                ) + String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                              let img_e = change[event_index].data[5].value.indexOf(
                                "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                              )
                              change[event_index].data[5].value = change[
                                event_index
                              ].data[5].value.replace(
                                change[event_index].data[5].value.substring(img_s, img_e),
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                              )
                              let replace_str = ""
                              if (
                                change[event_index].data[5].img &&
                                change[event_index].data[5].img.length > 0
                              ) {
                                for (let j = 0; j < change[event_index].data[5].img.length; j++) {
                                  if (change[event_index].data[5].img[j].file[2].value) {
                                    replace_str =
                                      replace_str +
                                      `<p>[${
                                        change[event_index].data[5].img[j].file[1].value
                                      }]</p><p><a href="${
                                        change[event_index].data[5].img[j].file[2].value
                                      }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                        imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                      }" width="${
                                        change[event_index].data[5].img[j].file[0].size.value1
                                      }px" height="${
                                        change[event_index].data[5].img[j].file[0].size.value2
                                      }px"></a></p><p><br></p>`
                                  } else {
                                    replace_str =
                                      replace_str +
                                      `<p>[${
                                        change[event_index].data[5].img[j].file[1].value
                                      }]</p><p><img referrerpolicy="no-referrer" src="${
                                        imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                      }" width="${
                                        change[event_index].data[5].img[j].file[0].size.value1
                                      }px" height="${
                                        change[event_index].data[5].img[j].file[0].size.value2
                                      }px"></p><p><br></p>`
                                  }
                                }
                              }
                              change[event_index].data[5].value = change[
                                event_index
                              ].data[5].value.replaceAll(
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                                replace_str +
                                  `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                              )
                              set_event_data_list(change)
                            }}
                          >
                            취소
                          </SuiButton>
                          <SuiButton
                            color="search"
                            variant="text"
                            sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                            onClick={() => {
                              let change = [...event_data_list]
                              change[event_index].data[5].change = true
                              let img_s =
                                change[event_index].data[5].value.indexOf(
                                  "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                                ) + String("<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>").length
                              let img_e = change[event_index].data[5].value.indexOf(
                                "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>"
                              )
                              change[event_index].data[5].value = change[
                                event_index
                              ].data[5].value.replace(
                                change[event_index].data[5].value.substring(img_s, img_e),
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                              )
                              let replace_str = ""
                              if (
                                change[event_index].data[5].img &&
                                change[event_index].data[5].img.length > 0
                              ) {
                                for (let j = 0; j < change[event_index].data[5].img.length; j++) {
                                  if (change[event_index].data[5].img[j].file[2].value) {
                                    replace_str =
                                      replace_str +
                                      `<p>[${
                                        change[event_index].data[5].img[j].file[1].value
                                      }]</p><p><a href="${
                                        change[event_index].data[5].img[j].file[2].value
                                      }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                                        imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                      }" width="${
                                        change[event_index].data[5].img[j].file[0].size.value1
                                      }px" height="${
                                        change[event_index].data[5].img[j].file[0].size.value2
                                      }px"></a></p><p><br></p>`
                                  } else {
                                    replace_str =
                                      replace_str +
                                      `<p>[${
                                        change[event_index].data[5].img[j].file[1].value
                                      }]</p><p><img referrerpolicy="no-referrer" src="${
                                        imgPathUrl + change[event_index].data[5].img[j].file[0].url
                                      }" width="${
                                        change[event_index].data[5].img[j].file[0].size.value1
                                      }px" height="${
                                        change[event_index].data[5].img[j].file[0].size.value2
                                      }px"></p><p><br></p>`
                                  }
                                }
                              }
                              change[event_index].data[5].value = change[
                                event_index
                              ].data[5].value.replaceAll(
                                `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                                replace_str +
                                  `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`
                              )
                              set_event_data_list(change)
                            }}
                          >
                            저장
                          </SuiButton>
                        </>
                      )}
                    </SuiBox>
                  </SuiBox>
                  <SuiBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    sx={{ mb: "30px" }}
                  >
                    <SuiTypography
                      color="error"
                      variant="text"
                      sx={{ fontSize: "15px", fontWeight: 400, pl: "12px" }}
                    >
                      '수정하기' 사용시 자동입력 양식이 삭제될 수 있습니다.
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiBox>
                  {list.data[5].change ? (
                    <div
                      style={{
                        backgroundColor: "#f7f9fb",
                        fontSize: "14px",
                        textAlign: "start",
                        padding: "12px",
                        borderRadius: "15px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: list.data[5].value.replaceAll(
                          `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
                          ""
                        ),
                      }}
                    ></div>
                  ) : (
                    <ReactQuill
                      readOnly={list.data[5].change}
                      className="Quill-layout color_position"
                      modules={modules}
                      theme="snow"
                      value={list.data[5].value}
                      formats={formats}
                      onChange={(e) => {
                        quillChange(e, "detail", event_index)
                      }}
                      ref={quillRef}
                    />
                  )}
                </SuiBox>
              </SuiBox>
            </Card>
          )
        })}
        {event_data_list.length === 0 && type_data !== "create" && type_data !== "update" ? (
          <SuiTypography
            fontSize="18px"
            fontWeight="regular"
            color="text2"
            sx={{ textAlign: "left" }}
          >
            메일 생성 이력이 없습니다.
          </SuiTypography>
        ) : null}
      </div>
    )
  }
  const authCheck = () => {
    if (type_data !== "create") {
      if (
        myData.role.name === "Authenticated" ||
        myData.role.name === "gold" ||
        myData.role.name === "silver"
      ) {
        return true
      }
    }
  }
  const startEdit = () => {
    dispatch(loading_Apply(true))
    set_type_data("update")
    dispatch(editStatus_Apply(true))
    dispatch(loading_Apply(false))
  }

  const cancelReset = () => {
    set_type_data("detail")
    startSetting(all_event_data.id)
    eventDataReset()
    dispatch(editStatus_Apply(false))
  }

  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="1320px"
        maxWidth="1320"
        alignItems="center"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState}
          message="저장되었습니다"
          onClose={() => setsSnackState(false)}
        />

        {type_data === "detail" ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {all_event_data.requests.length > 0 &&
            all_event_data.requests[0].status.indexOf("회원 취소") === -1 ? (
              <SuiTypography
                onClick={() => {
                  if (
                    all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)
                      .length === 0
                  )
                    openRequest(all_event_data.requests[0])
                  else
                    setdelete_cancle_alert({
                      id: all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)[0]
                        .id,
                      open: true,
                      title: "삭제신청 취소",
                      content: "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?",
                      twoBtn: true,
                      CanBtnText: "취소",
                    })
                }}
                fontSize="16px"
                fontWeight="bold"
                color={
                  all_event_data.requests[0].status.indexOf("심사중") > -1 ? "error" : "secondary"
                }
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    all_event_data.requests[0].content.id === myData.id &&
                    !all_event_data.requests[0].content.read
                      ? ""
                      : "none",
                }}
              >
                {all_event_data.requests[0].status.replace("심사중", "")}
              </SuiTypography>
            ) : (
              ""
            )}
            {ChangeRequests.length > 0 ? (
              <SuiTypography
                onClick={() => setchagne_cancel_dailog(true)}
                fontSize="16px"
                fontWeight="bold"
                color={"error"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    ChangeRequests[0].arrayContent.users.map((x) => x.id).indexOf(myData.id) > -1 ||
                    ChangeRequests[0].users_permissions_user.id === myData.id
                      ? ""
                      : "none",
                }}
              >
                이관 신청{dayjs(ChangeRequests[0].updated_at).format("YY.MM.DD HH:mm")}
              </SuiTypography>
            ) : (
              ""
            )}
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleClick(e, index)}
              sx={{ color: "#000000" }}
            >
              <FiMoreHorizontal />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={event_update_open}
              onClose={handleMenu}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                },
              }}
            >
              <MenuItem
                disabled={
                  all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1).length >
                    0 || ChangeRequests.length > 0
                }
                onClick={(e) => deleteSelect()}
                style={{ color: "red" }}
              >
                삭제신청
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}

        {type_data === "detail" ? (
          <SuiButton
            color="info2"
            sx={{ ml: "auto", width: 100, position: "absolute", fontSize: 14 }}
            onClick={() => startEdit()}
          >
            수정
          </SuiButton>
        ) : (
          <>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#707070",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => cancelReset()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="info2"
              sx={{
                width: 100,
                fontSize: 14,
                visibility: type_data === "detail" ? "hidden" : "initial",
                display: type_data === "detail" ? "none" : "",
              }}
              onClick={(e) => handleSave(e)}
              id="saveEditBtn"
            >
              저장하기
            </SuiButton>
          </>
        )}
      </SuiBox>
    )
  }

  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      // {...other}
    >
      {openDialog && (
        <SuiBox
          display="flex"
          justifyContent="center"
          bgColor="#F1F1F5"
          minHeight="85vh"
          px={14}
          sx={{ pb: "40px" }}
        >
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                이벤트 결과 메일 관리
              </SuiTypography>
              <SuiTypography
                color="subTitle"
                sx={{ mt: 2, fontWeight: 500, fontSize: 18 }}
                variant="h6"
                component="div"
              >
                이벤트 결과 보고 메일을 생성하고 발송합니다.
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
        </SuiBox>
      )}
      {openDialog && authCheck() ? FooterForm() : ""}
      <SuiDialog
        openDialog={open_info_dialog}
        closeDialog={() => set_open_info_dialog(false)}
        MainTitle={dialog_title}
        Content={dialog_content}
        CanBtnText={"확인"}
      />
      <SingleReceiverDialog
        openDialog={open_single_receiver_dialog}
        closeDialog={singleReceiverCloseDialog}
        receiver={receiverList}
      ></SingleReceiverDialog>
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
        Content={Delete_Content}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"삭제신청"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => Delete_All()}
        type={Delete_Dialog_type}
      />
      <SuiDialog
        openDialog={delete_cancle_alert.open}
        className={"admin_setting_dialog"}
        MainTitle={delete_cancle_alert.title}
        Content={delete_cancle_alert.content}
        CanBtnText={delete_cancle_alert.CanBtnText}
        AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
        CreBtnColor={"error"}
        CreBtnText={"취소하기"}
        closeDialog={() => delete_cancle_alert_close()}
        Active={() => delete_cancle_active()}
      />
      <SendEmail
        openDialog={open_send_email_dialog}
        closeDialog={openSendEmailCloseDialog}
        emailContent={send_email_list}
        emailType={email_type}
        receiver={sendEmailReceiverListFunc}
        resultEmail={"result"}
      ></SendEmail>
      <SendHistory
        openDialog={open_send_history_dialog}
        closeDialog={openSendHistoryCloseDialog}
        emailContent={send_history_list}
      ></SendHistory>
      <ImportEmail
        openDialog={open_result_dialog}
        closeDialog={closeDialog_close}
        closeDialog_import={closeDialog_import}
        closeDialog_create={closeDialog_create}
        editId={editId}
        stepNext={step_next}
      ></ImportEmail>
      <ImgUpload
        openDialog={file_open.dialog}
        closeDialog={fileCloseDialog}
        saveDialogCreateMain={fileSaveDialogCreateMain} // 추가일경우 메인
        saveDialogCreateSingle={fileSaveDialogCreateSingle} // 추가일경우 개별
        saveDialogDetailMain={fileSaveDialogDetailMain} // 기존에 있던 결과보고 메일 메인
        saveDialogDetailSingle={fileSaveDialogDetailSingle} // 기존에 있던 결과보고 메일 개별
        edit={file_open.editBool}
        editData={file_open.editData}
      />
      <PreviewDialog
        openDialog={open_preview_dialog}
        closeDialog={openPreviewCloseDialog}
        emailContent={preview_content}
      ></PreviewDialog>
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
    </div>
  )
}
resultSent.defaultProps = {
  edit: false,
  editId: null,
  type: "create",
}
resultSent.propTypes = {
  edit: PropTypes.bool,
  type: PropTypes.string,
  saveExit: PropTypes.func,
}
export default resultSent
