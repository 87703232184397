import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Slide from "@mui/material/Slide"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"

import SuiDialog from "components/SuiDialog"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import DetailInfo from "./detailInfo"
import InformationSent from "./informationSent"
import ResultSent from "./resultSent"

import { BiUserPin, BiClipboard } from "react-icons/bi"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { RiBook2Line } from "react-icons/ri"
import { MdClose } from "react-icons/md"

//API
import { useQuery, useMutation } from "@apollo/client"
import {
  createEvent,
  createEventSignificant,
  createEventAttachment,
  createRecent,
  createEventExposure,
} from "apollo/mutation"
import { me, events } from "apollo/query"

//Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../../reducers/store"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function EventDialog({ open, closeDialog, editId, editData, type }) {
  const dispatch = useDispatch()
  const { myData } = useSelector((state) => state.store)
  const { editStatus } = useSelector((state) => state.store)
  const [tab, setTab] = useState(0)
  const [input_lists, set_input_lists] = useState([])
  const [input_detail_lists, set_input_detail_lists] = useState([])
  const [input_event_content, set_input_event_content] = useState()
  const [AttachmentDatas, setAttachmentDatas] = useState([])
  const [SignificantsDatas, setSignificantsDatas] = useState([])
  const [ExposureDatas, setExposureDatas] = useState([])
  const [typeData, settypeData] = useState(type)
  const [editIdData, seteditIdData] = useState(editId)
  const [editDataSet, seteditDataSet] = useState(editData)
  const [detail_info_open, set_detail_info_open] = useState(true)
  const [information_sent_open, set_information_sent_open] = useState(false)
  const [result_sent_open, set_result_sent_open] = useState(false)
  const [openDialog, setopenDialog] = useState(open)
  const [all_event_data, set_all_event_data] = useState({}) // 이벤트 모든정보
  const [dialogContent, setDialogContent] = useState("올바른 개인 상세 정보를 입력해 주세요.")
  const { data: EventData } = useQuery(events, {
    fetchPolicy: "network-only",
    variables: {
      id: [editId],
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  useEffect(async () => {
    if (open && EventData) {
      await set_all_event_data(EventData.events[0])
    }
  }, [open, EventData])
  useEffect(() => {
    dispatch(loading_Apply(true))
    setTimeout(() => {}, 2000)
    settypeData(type)
    seteditIdData(editId)
    seteditDataSet(editData)
    dispatch(loading_Apply(false))
  }, [openDialog])
  const eventSave = () => {
    setopenSave_Dialog(false)
    for (let i = 0; i < input_lists.length; i++) {
      if (i !== 4 && input_lists[i].value === "") {
        let sliceContent = input_lists[i].title.slice(0, -1)
        setDialogContent(`${sliceContent}의 정보를 정확히 입력해주세요`)
        setopenInfo_Dialog(true)
        return
      }
    }
    for (let i = 0; i < input_detail_lists.length; i++) {
      if (i === 4) {
        if (input_detail_lists[4].value.length === 0) {
          setDialogContent(`출판사의 정보를 정확히 입력해주세요.`)
          setopenInfo_Dialog(true)
          dispatch(loading_Apply(false))
          return
        }
      } else {
        if (input_detail_lists[i].value === "") {
          let sliceContent = input_detail_lists[i].title.slice(0, -1)
          setDialogContent(`${sliceContent}의 정보를 정확히 입력해주세요`)
          setopenInfo_Dialog(true)
          return
        }
      }
    }

    if (input_event_content === "") {
      setDialogContent(`이벤트 내용의 정보를 정확히 입력해주세요`)
      setopenInfo_Dialog(true)
      return
    }
    const firstHistory = [
      {
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "최초 등록",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      },
    ]
    dispatch(loading_Apply(true))
    let dt = new Date()
    let profileurl = ""
    if (myData.profile) {
      profileurl = myData.profile.url
    }
    let creater = {
      userName: myData.name,
      userPosition: myData.position,
      userDepartment: myData.department,
      userProfile: profileurl,
      updated_at: dt,
    }
    let publisher_data = ""
    for (let i = 0; i < input_detail_lists[4].value.length; i++) {
      if (i === 0) {
        publisher_data = input_detail_lists[4].value[i] + "_"
      } else {
        publisher_data = publisher_data + input_detail_lists[4].value[i] + "_"
      }
    }
    createEventsActive({
      variables: {
        title: input_lists[0].value,
        flatform: input_lists[1].value,
        start: dayjs(input_lists[2].value).format("YYYY-MM-DD"),
        end: dayjs(input_lists[3].value).format("YYYY-MM-DD"),
        status:
          input_lists[5].value === "진행중"
            ? "ing"
            : input_lists[5].value === "완료"
            ? "complete"
            : input_lists[5].value === "취소"
            ? "cancel"
            : "expected",
        serial: input_detail_lists[0].value,
        type: input_detail_lists[1].value,
        newOne: input_detail_lists[2].value,
        reward: input_detail_lists[3].value,
        publisher: publisher_data,
        history: firstHistory,
        content: input_event_content,
        manager: creater,
        users_permissions_user: myData.id,
      },
    }).then((resEvent) => {
      createRecentActive({
        variables: {
          users_permissions_user: myData.id,
          title: "이벤트 정보 등록",
          type: "Event",
          srcId: resEvent.data.createEvent.event.id,
          name: input_lists[0].value,
        },
      })
      for (var i = 0; i < SignificantsDatas.length; i++) {
        if (!SignificantsDatas[i].id) {
          createEventSignificantActive({
            variables: {
              content: SignificantsDatas[i].content,
              userName: SignificantsDatas[i].userName,
              userPosition: SignificantsDatas[i].userPosition,
              userProfile: SignificantsDatas[i].userProfile,
              userDepartment: SignificantsDatas[i].userDepartment,
              eventId: resEvent.data.createEvent.event.id,
              userID: String(SignificantsDatas[i].userID),
            },
          })
        }
      }
      console.log(SignificantsDatas)
      console.log(ExposureDatas)
      for (var i = 0; i < ExposureDatas.length; i++) {
        if (!ExposureDatas[i].id) {
          createEventExposureActive({
            variables: {
              content: ExposureDatas[i].content,
              userName: ExposureDatas[i].userName,
              userPosition: ExposureDatas[i].userPosition,
              userProfile: ExposureDatas[i].userProfile,
              userDepartment: ExposureDatas[i].userDepartment,
              eventId: resEvent.data.createEvent.event.id,
              userID: String(ExposureDatas[i].userID),
            },
          })
        }
      }

      if (AttachmentDatas.length > 0) {
        AttachmentDatas.map((x) => x.data).forEach((element, index) => {
          let data = new FormData()
          data.append("files", element[0].file)
          axios
            .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
            .then((res) => {
              createEventAttachmentActive({
                variables: {
                  file: [res.data[0].id],
                  title: element[1].value,
                  type: element[1].itemsValue,
                  // explain: element[3].value,
                  event: resEvent.data.createEvent.event.id,
                  eventId: resEvent.data.createEvent.event.id,
                  maker: myData.email,
                  users_permissions_user: myData.id,
                  position: myData.position,
                },
              })
              if (AttachmentDatas.length - 1 === index) {
                setTimeout(() => {
                  dispatch(loading_Apply(false))
                  set_all_event_data({ title: resEvent.data.createEvent.event.title })
                  seteditDataSet({ title: resEvent.data.createEvent.event.title })
                  seteditIdData(resEvent.data.createEvent.event.id)
                  settypeData("detail")
                }, 1000)
              }
            })
            .catch(() => dispatch(loading_Apply(false)))
        })
      } else {
        dispatch(loading_Apply(false))
        set_all_event_data({ title: resEvent.data.createEvent.event.title })
        seteditDataSet({ title: resEvent.data.createEvent.event.title })
        seteditIdData(resEvent.data.createEvent.event.id)
        settypeData("detail")
      }
    })
  }
  const [createRecentActive] = useMutation(createRecent, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [me],
  })
  const [createEventsActive] = useMutation(createEvent, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 생성 오류 발생")
    },
    refetchQueries: [events, me],
  })
  const [createEventSignificantActive] = useMutation(createEventSignificant, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createEventExposureActive] = useMutation(createEventExposure, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createEventAttachmentActive] = useMutation(createEventAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 생성 오류 발생")
    },
    refetchQueries: [events],
  })
  useEffect(() => {
    const homeRef = document.getElementById("top-event")
    if (homeRef) {
      setTimeout(() => {
        homeRef.scrollIntoView({ behavior: "smooth" })
      }, 300)
    }
  }, [tab])
  const [snackState, setsSnackState] = useState(false)
  const handleChange = (event, newValue) => {
    if (editStatus) {
      setsSnackState(true)
    } else {
      setTab(newValue)
      if (newValue === 0) {
        set_information_sent_open(false)
        settypeData("detail")
        set_detail_info_open(true)
        set_result_sent_open(false)
      } else if (newValue === 1) {
        set_detail_info_open(false)
        settypeData("detail")
        set_information_sent_open(true)
        set_result_sent_open(false)
      } else if (newValue === 2) {
        set_detail_info_open(false)
        settypeData("detail")
        set_information_sent_open(false)
        set_result_sent_open(true)
      }
    }
  }
  useEffect(() => {
    dispatch(loading_Apply(true))
    setTimeout(() => {}, 2000)
    settypeData(type)
    seteditIdData(editId)
    seteditDataSet(editData)
    dispatch(loading_Apply(false))
  }, [openDialog])

  const handleClose = () => {
    setTab(0)
    closeDialog()
    setopenDialog(false)
    set_detail_info_open(true)
    set_information_sent_open(false)
    setopenCancle_Dialog(false)
    setopenLeave_Dialog(false)
  }
  const detailinfoclose = () => {
    set_detail_info_open(false)
    setTab(1)
    set_information_sent_open(true)
  }
  const detailinfoclose2 = () => {
    set_detail_info_open(false)
    setTab(2)
    set_result_sent_open(true)
  }
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openSave_Dialog, setopenSave_Dialog] = useState(false)
  const [openCancle_Dialog, setopenCancle_Dialog] = useState(false)
  const [openLeave_Dialog, setopenLeave_Dialog] = useState(false)
  useEffect(() => {
    setopenDialog(open)
    if (editStatus) {
      dispatch(editStatus_Apply(false))
    }
  }, [open])
  const leaveCheck = () => {
    if (editStatus) {
      setopenLeave_Dialog(true)
    } else {
      handleClose()
    }
  }
  const saveExit = () => {
    document.getElementById("saveEditBtn").value = "exit"
    document.getElementById("saveEditBtn").click()
    setopenLeave_Dialog(false)
    handleClose()
  }
  const saveExit_info = () => {
    handleClose()
    setopenLeave_Dialog(false)
  }

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      sx={{
        maxWidth: 1320,
        margin: "0 auto",
        marginTop: "136px",
        // "> div ::-webkit-scrollbar": {
        //   display: "none",
        // },
      }}
    >
      <AppBar
        sx={{
          maxWidth: 1320,
          margin: "0 auto",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar>
          <SuiTypography
            sx={{ ml: 2, flex: 1, fontWeight: 700, color: "dark", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            {typeData === "detail" ? all_event_data?.title + " [E]" : "이벤트 등록"}
          </SuiTypography>
          {typeData === "detail" ? (
            ""
          ) : (
            <SuiButton
              sx={{
                fontWeight: 700,
                width: 20,
                minWidth: 60,
                height: 20,
                ml: "auto",
                p: 0,
                mr: 2,
                fontSize: 16,
              }}
              color="text2"
              variant="text"
              size="large"
              onClick={() => setopenCancle_Dialog(true)}
            >
              취소
            </SuiButton>
          )}
          {typeData === "detail" ? (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => leaveCheck()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          ) : (
            <SuiButton
              sx={{
                fontWeight: 700,
                fontSize: 16,
                width: 20,
                minWidth: 70,
                height: 20,
                ml: "auto",
                p: 0,
              }}
              variant="text"
              color="info2"
              size="large"
              onClick={() => setopenSave_Dialog(true)}
              // onClick={() => writerSave()}
            >
              등록하기
            </SuiButton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className="dialog-menu-tabs"
        id="top-event"
      >
        <SuiBox
          sx={{
            maxWidth: 1320,
            margin: "0 auto",
            position: "fixed",
            top: 64,
            backgroundColor: "#ffffff",
            width: "100%",
            borderTop: 1,
            borderBottom: 1,
            borderColor: "#CED4D7",
            zIndex: 10,
          }}
        >
          <Tabs value={tab} onChange={handleChange} centered>
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiUserPin />}
              iconPosition="start"
              label="기본 정보"
            />
            <Tab
              className={typeData === "create" ? "tab-disabled" : ""}
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<RiBook2Line />}
              iconPosition="start"
              label="안내 발송"
            />
            <Tab
              className={typeData === "create" ? "tab-disabled" : ""}
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiClipboard />}
              iconPosition="start"
              label="결과 보고"
            />
          </Tabs>
        </SuiBox>
        {detail_info_open && (
          <DetailInfo
            openDialog={detail_info_open}
            closeDialog={detailinfoclose}
            closeDialog2={detailinfoclose2}
            editId={editIdData}
            type={typeData}
            index={0}
            inputListsExport={set_input_lists}
            inputDetailListsExport={set_input_detail_lists}
            inputEventContentExport={set_input_event_content}
            AttachmentDatasExport={setAttachmentDatas}
            SignificantsDatasExport={setSignificantsDatas}
            ExposureDatasExport={setExposureDatas}
            saveExit={saveExit_info}
          ></DetailInfo>
        )}
        {information_sent_open && (
          <InformationSent
            index={1}
            openDialog={information_sent_open}
            editId={editIdData}
            type={typeData}
            saveExit={saveExit_info}
          ></InformationSent>
        )}
        {result_sent_open && (
          <ResultSent
            index={2}
            openDialog={result_sent_open}
            editId={editIdData}
            type={typeData}
            saveExit={saveExit_info}
          ></ResultSent>
        )}
      </Box>
      <SuiDialog
        openDialog={openInfo_Dialog}
        closeDialog={() => setopenInfo_Dialog(false)}
        MainTitle={"정보 입력 오류"}
        Content={dialogContent}
        CanBtnText={"확인"}
      />
      <SuiDialog
        openDialog={openSave_Dialog}
        closeDialog={() => setopenSave_Dialog(false)}
        MainTitle={"등록하기"}
        Content={"정보 등록 후에는 삭제하실 수 없습니다. 이대로 등록하시겠습니까?"}
        CanBtnText={"취소"}
        CreBtnText={"등록하기"}
        CreBtnColor="info2"
        AlertType="twoBtn"
        Active={() => eventSave()}
      />
      <SuiDialog
        openDialog={openCancle_Dialog}
        closeDialog={() => setopenCancle_Dialog(false)}
        MainTitle={"등록 취소하기"}
        Content={"작성하신 내용을 저장하지 않고 나가시겠습니까?"}
        CanBtnText={"취소"}
        CreBtnText={"나가기"}
        CreBtnColor="error"
        AlertType="twoBtn"
        Active={() => handleClose()}
      />
      <SuiDialog
        openDialog={openLeave_Dialog}
        closeDialog={() => setopenLeave_Dialog(false)}
        MainTitle={"저장하고 나가기"}
        Content={"정보 변경 또는 추가 후 저장하지 않은 내역이 있습니다. 저장하고 나가시겠습니까?"}
        CanBtnText={"저장안함"}
        CreBtnText={"저장함"}
        exitBtnText={"취소"}
        CreBtnColor="info2"
        AlertType="threeBtn"
        Active={() => saveExit()}
        ActiveLeave={() => handleClose()}
      />
      <Snackbar
        className="snackBar-center"
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackState}
        message="저장하기' 또는 '취소'버튼을 클릭해야 이동할 수 있습니다"
        onClose={() => setsSnackState(false)}
      />
    </Dialog>
  )
}

EventDialog.defaultProps = {
  open: false,
  editData: { writername: "" },
  tabActive: 0,
}

EventDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
  editId: PropTypes.string,
  editData: PropTypes.object,
  tabActive: PropTypes.number,
}

export default EventDialog
