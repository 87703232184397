import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useApolloClient } from "@apollo/client"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import dayjs from "dayjs"
import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { AiOutlineSearch } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"

import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { bookCodeContracts } from "apollo/query"
import {} from "apollo/mutation"

function sendHistory({ openDialog, closeDialog, emailContent }) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [status_data, set_status_data] = useState({})
  const [status_data_list, set_status_data_list] = useState([])

  useEffect(() => {
    if (openDialog) {
      set_status_data_list(emailContent.data[6].data)
      let obj = {
        email: 0,
        sms: 0,
      }
      let history_list = emailContent.data[6].data
      for (let i = 0; i < history_list.length; i++) {
        obj.email = Number(obj.email) + Number(history_list[i].email.success)
        obj.sms = Number(obj.sms) + Number(history_list[i].sms.success)
        if (history_list.length - 1 === i) {
          set_status_data(obj)
        }
      }
    }
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
  }
  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ mx: 4, mt: 4, width: 500 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              발송 내역
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              이메일 & 문자 발송 내역을 확인합니다.
            </SuiTypography>
          </SuiBox>

          <SuiBox sx={{ mb: 2 }}>
            <SuiTypography style={{ fontSize: "14px", fontWeight: "medium", color: "#84a2b1" }}>
              전체 발송 횟수:
            </SuiTypography>
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 60,
              backgroundColor: "#f7f9fb",
              borderRadius: "10px",
              mb: 5,
            }}
          >
            <SuiTypography
              style={{ fontSize: "16px", fontWeight: "medium", fontWeight: "bold" }}
              color="subTitle"
            >
              이메일: {status_data.email}건 · 문자: {status_data.sms}건
            </SuiTypography>
          </SuiBox>
          <SuiBox sx={{ mb: 2 }}>
            <SuiTypography style={{ fontSize: "14px", fontWeight: "medium", color: "#84a2b1" }}>
              발송 일자:
            </SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              height: 200,
              backgroundColor: "#f7f9fb",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            {status_data_list &&
              status_data_list.map((list, index) => {
                return (
                  <SuiBox key={index + ",1"} sx={{ p: 3, borderBottom: "1px dashed #e3e6e8" }}>
                    <SuiTypography
                      style={{ fontSize: "16px", fontWeight: "medium", fontWeight: "bold" }}
                      color="subTitle"
                    >
                      발송완료 ({dayjs(list.created_at).format("YY.MM.DD HH:mm:ss")})
                    </SuiTypography>
                    <SuiTypography
                      style={{ fontSize: "16px", fontWeight: "medium" }}
                      color="subTitle"
                    >
                      {list.email.success && list.sms.success
                        ? `메일 (발송: ${list.email.success}건, 실패: ${list.email.fail}건) · 문자 (발송: ${list.sms.success}건, 실패: ${list.sms.fail}건)`
                        : list.email.success && !list.sms.success
                        ? `메일 (발송: ${list.email.success}건, 실패: ${list.email.fail}건)`
                        : `문자 (발송: ${list.sms.success}건, 실패: ${list.sms.fail}건)`}
                    </SuiTypography>
                  </SuiBox>
                )
              })}
          </SuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
        <SuiBox display="flex" height="100%" sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}>
          <SuiButton
            color="success2"
            sx={{
              ml: "auto",
              mr: 1,
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              width: 100,
              fontSize: 14,
              mb: 1,
            }}
            onClick={() => {
              handleClose()
            }}
          >
            확인
          </SuiButton>
        </SuiBox>
      </Grid>
    </Dialog>
  )
}
sendHistory.defaultProps = {
  openDialog: false,
}

sendHistory.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  emailContent: PropTypes.array,
}
export default sendHistory
