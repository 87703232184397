function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme
  const { active, transparentSidenav } = ownerState

  const { dark, white, text, transparent } = palette
  const { xxl } = boxShadows
  const { borderRadius } = borders
  const { pxToRem } = functions

  return {
    background: active && transparentSidenav ? "#5C38FF" : transparent,
    color: active ? dark.main : text.main,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
    margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && transparentSidenav ? xxl : "none",
    [breakpoints.up("xl")]: {
      boxShadow: () => {
        if (active) {
          return transparentSidenav ? xxl : "none"
        }

        return "none"
      },
      transition: transitions.create("box-shadow", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
  }
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme
  const { active, transparentSidenav, color } = ownerState

  const { white, info, light, gradients } = palette
  const { md } = boxShadows
  const { borderRadius } = borders
  const { pxToRem } = functions

  return {
    background: () => {
      if (active) {
        return color === "default" ? info.main : palette[color].main
      }

      return light.main
    },
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      background: () => {
        let background

        if (!active) {
          // background = transparentSidenav ? white.main : light.main
          background = "transparent"
        } else if (color === "default") {
          // background = info.main
          background = "transparent"
        } else if (color === "warning") {
          // background = gradients.warning.main
          background = "transparent"
        } else {
          // background = palette[color].main
          background = "transparent"
        }

        return background
      },
    },

    "& svg, svg g": {
      fill: active ? white.main : "#9AA0A6",
      // "#9AA0A6" gradients.dark.state
    },
  }
}

function collapseIconBox2(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme
  const { active, color } = ownerState

  const { white, info, light, gradients } = palette
  const { pxToRem } = functions

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      background: () => {
        let background

        if (!active) {
          // background = transparentSidenav ? white.main : light.main
          background = "transparent"
        } else if (color === "default") {
          // background = info.main
          background = "transparent"
        } else if (color === "warning") {
          // background = gradients.warning.main
          background = "transparent"
        } else {
          // background = palette[color].main
          background = "transparent"
        }

        return background
      },
    },

    "& svg, svg g": {
      fill: active ? white.main : "#9AA0A6",
    },
  }
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
})

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions, palette } = theme
  const { dark, white, text, transparent } = palette
  const { miniSidenav, transparentSidenav, active } = ownerState

  const { fontWeightBold } = typography
  const { pxToRem } = functions

  return {
    // marginLeft: pxToRem(12.8),
    color: active ? white.main : "#707070",

    [breakpoints.up("xl")]: {
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 0,
    },
  }
}

function collapseTextIcon(theme, ownerState) {
  const { typography, transitions, breakpoints, functions, palette } = theme
  const { dark, white, text, transparent } = palette
  const { miniSidenav, transparentSidenav, active } = ownerState

  const { fontWeightBold } = typography
  const { pxToRem } = functions

  return {
    // marginLeft: pxToRem(12.8),
    color: active ? white.main : "#9AA0A6",

    [breakpoints.up("xl")]: {
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: fontWeightBold,
      fontSize: 18,
      lineHeight: 0,
    },
  }
}

export {
  collapseItem,
  collapseIconBox,
  collapseIconBox2,
  collapseIcon,
  collapseText,
  collapseTextIcon,
}
