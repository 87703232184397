import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Card from "@mui/material/Card"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"

import { useLazyQuery } from "@apollo/client"
import { contracts } from "apollo/query"
import { FiSearch } from "react-icons/fi"
import { AiFillCloseCircle } from "react-icons/ai"

//아이콘
import { MdClose } from "react-icons/md"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function referrerDialog({ openDialog, closeDialog, selectContarctActive, selectData }) {
  const dispatch = useDispatch()
  const [usersData, setUsersData] = useState([])
  const [usersFilterData, setUsersFilterData] = useState([])
  const [open, setOpen] = useState(openDialog)
  const [searchName, setSearchName] = useState("")
  const [searchActive, setsearchActive] = useState(false)
  const [expanded, setexpanded] = React.useState([])
  const [contarctDatas, setContarctDatas] = useState([])
  const [selectContarct, setSelectContarct] = useState([])
  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])
  const [contractsActive] = useLazyQuery(contracts, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
  })
  const handleDelete = (e) => {
    const array = [...selectContarct]
    const index = array.map((x) => x.id).indexOf(e.target.value)
    array.splice(index, 1)
    setSelectContarct(array)
  }
  const handleCheckBox = (x, e) => {
    const array = [...selectContarct]
    if (e.target.checked) {
      array.push({
        id: x.id,
        title: x.title,
        genre: x.genre,
        name: x.writer.name,
        joaraname: x.writer.joaraname,
        selectData: selectData,
        updated_at: x.updated_at,
      })
    } else {
      const index = array.map((x) => x.id).indexOf(x.id)
      array.splice(index, 1)
    }
    setSelectContarct(array)
  }
  const handleCloseBtn = () => {
    selectContarctActive([])
    setSelectContarct([])
    closeDialog()
    setOpen(false)
  }
  const handleClose = () => {
    selectContarctActive(selectContarct)
    closeDialog()
    setOpen(false)
  }
  const handleSearchName = (event) => {
    setSearchName(event.target.value)
  }
  const userSearch = () => {
    if (searchName !== "") {
      dispatch(loading_Apply(true))
      setsearchActive(true)
      contractsActive({
        variables: {
          title: searchName,
        },
      }).then((res) => {
        dispatch(loading_Apply(false))
        setContarctDatas(res.data.contracts)
      })
    } else {
      setsearchActive(false)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchName !== "") {
        userSearch()
      } else {
        setsearchActive(false)
      }
    }
  }
  const contractNumber = (id) => {
    var ids = String(id)
    return ids.padStart(5, "0")
  }
  const handleChange = (event, nodes) => {
    setexpanded(nodes)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Card sx={{ p: 5, width: 480 }}>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox display="flex" alignItems="center" justifyContent="end" sx={{ pt: 1 }}>
              <IconButton
                edge="start"
                color="subTitle"
                size="small"
                onClick={() => handleCloseBtn()}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                <MdClose />
              </IconButton>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                검색결과
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                작품명을 선택하고, 작업이력 내 작품으로 등록합니다.
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12}>
            <SuiBox display="flex" fullWidth>
              <SuiBox fullWidth sx={{ mr: 2, width: "100%" }}>
                <SuiInput
                  type="text"
                  placeholder="작품을 검색해주세요."
                  value={searchName}
                  onChange={handleSearchName}
                  onKeyPress={handleKeyPress}
                />
              </SuiBox>
              <SuiButton
                sx={{ height: 40, width: 40, minWidth: 40, p: 1 }}
                color="info2"
                onClick={() => userSearch()}
              >
                <FiSearch size="30" />
              </SuiButton>
            </SuiBox>
            <Stack direction="row" spacing={1} sx={{ pt: "12px", overflow: "auto" }}>
              {selectContarct.map((x, index) => {
                return (
                  <Chip
                    key={index}
                    label={contractNumber(x.id)}
                    onDelete={handleDelete}
                    deleteIcon={<AiFillCloseCircle color="#535353" />}
                  />
                )
              })}
            </Stack>
          </Grid>
          {contarctDatas.length === 0 ? (
            <Grid item xs={12} sx={{ pt: "12px" }}>
              <Divider color="#CED4D7" sx={{ mr: 1, mb: 2 }} />
              <SuiTypography
                sx={{
                  minHeight: "230px",
                  maxHeight: "230px",
                }}
                style={{
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                작품 검색내역이 없습니다.
              </SuiTypography>
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ pt: "12px" }}>
              <Divider color="#CED4D7" sx={{ mr: 1 }} />
              <SuiBox
                sx={{
                  minHeight: "230px",
                  maxHeight: "230px",
                  overflowY: "auto",
                }}
              >
                {contarctDatas.map((x, index) => {
                  return (
                    <SuiBox display="flex" alignItems="center" key={index}>
                      <SuiTypography
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#000000",
                          width: "300px",
                        }}
                      >
                        {x.title}
                      </SuiTypography>
                      <SuiTypography
                        style={{
                          fontSize: "14px",
                          width: "40px",
                          fontWeight: "bold",
                          color: "#668B9E",
                        }}
                      >
                        {contractNumber(x.id)}
                      </SuiTypography>
                      <Checkbox
                        checked={selectContarct.map((x) => x.id).indexOf(x.id) > -1}
                        onChange={(e) => handleCheckBox(x, e)}
                        sx={{ ml: 2 }}
                        color="primary"
                      />
                    </SuiBox>
                  )
                })}
              </SuiBox>
            </Grid>
          )}

          <Grid item xs={12}>
            <SuiBox
              display="flex"
              justifyContent="start"
              height="100%"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <SuiButton
                color="subTitle"
                variant="text"
                sx={{
                  ml: "auto",
                  fontSize: 14,
                  width: 100,
                  border: 1,
                  borderColor: "#707070",
                  height: 40,
                  fontSize: 14,
                  color: "#707070",
                }}
                onClick={() => handleCloseBtn()}
              >
                취소
              </SuiButton>
              <SuiButton
                color="primary"
                sx={{ ml: "8px", fontSize: 14, height: 40, width: 100 }}
                onClick={() => handleClose()}
                disabled={selectContarct.length === 0}
              >
                선택완료
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  )
}

referrerDialog.defaultProps = {
  openDialog: false,
}

referrerDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  selectContarctActive: PropTypes.func,
  selectData: PropTypes.string,
}
export default referrerDialog
