import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import Tooltip from "@mui/material/Tooltip"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import GetFileIcon from "components/getFileIcon"
import enLocale from "date-fns/locale/en-US"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"

import { FileUploader } from "react-drag-drop-files"
import ContarctDialog from "../ContarctDialog"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"
import { loading_Apply } from "reducers/store"

function DragDrop({ files }) {
  let message = "드래그해서 파일을 업로드 해주세요."
  const [Message, setMessage] = useState(message)
  const handleChange = (file) => {
    files({ name: "" })
    files(file)
    setMessage("업로드 되었습니다.")
  }
  const onTypeError = () => {
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }
  const onSizeError = () => {
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      hoverTitle="pdf, excel, ppt, word, png, jpeg 파일만 업로드 됩니다."
      label="드레그해서 파일을 업로드 해주세요."
      maxSize="200"
      classes="width100 fileDragDropContract"
      types={[
        "pdf",
        "png",
        "jpeg",
        "jpg",
        "ppt",
        "pptx",
        "docs",
        "xlsx",
        "docx",
        "xlsm",
        "xls",
        "xlsb",
        "xltx",
        "xltm",
        "xml",
      ]}
      accept=".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
      onSizeError={onSizeError}
      onTypeError={onTypeError}
    >
      <Card
        sx={{
          p: 2,
          width: "100%",
          height: 90,
          boxShadow: 0,
          border: 1,
          backgroundColor: "#FAF8FF",
          borderColor: "#EEEEEE",
        }}
      >
        <SuiBox
          sx={{
            mt: 0,
            textAlign: "center",
          }}
        >
          <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
            <BiUpload />
          </IconButton>
          <SuiTypography
            color={
              Message === "200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다."
                ? "error"
                : "secondary"
            }
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {Message}
          </SuiTypography>
        </SuiBox>
      </Card>
    </FileUploader>
  )
}
DragDrop.defaultProps = {}

DragDrop.propTypes = {
  files: PropTypes.func,
}

function ContractDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  edit,
  editData,
  contract_documents_data,
  contract_documents_data_update,
  contract_documents,
  type,
  selectDataProps,
  titleData,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [createStep, setcreateStep] = useState(0)
  const [selectStep, setselectStep] = useState(0)
  const [selectData, setselectData] = useState([{ value: "" }, { value: "" }, { value: "" }])
  const [DragDropFile, setDragDropFile] = useState({ name: "" })
  const { myData } = useSelector((state) => state.store)
  const [selectContarctActive, setSelectContarctActive] = useState([])
  const InputListsSet = [
    {
      type: "picUpload",
      title: "계약서 업로드:",
      xs: 6,
      file: {},
      changed: false,
      dropdown1: false,
      dropdown2: false,
      memo: [],
      memoSotring: "최신순",
      subContractSotring: "최신순",
      subContract: [],
      activeStep: 0,
      requests: [],
    },
    {
      value: [],
      value1: null,
      value2: null,
      value3: "",
      type: "datePicker",
      title: "계약일:",
      title1: "계약갱신/종료일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    {
      title: "업로드 파일:",
      type: "fileList",
      xs: 6,
    },
    {
      inputType: "text",
      value: "자동갱신",
      items: ["자동갱신", "해지시 종료"],
      type: "selectBox",
      title: "자동 갱신 계약 여부:",
      xs: 6,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  const [selectDatas, setselectDatas] = useState([])
  useEffect(() => {
    setOpen(openDialog)
    if (editData.length > 0) {
      if (editData[0].selectData.length > 0) {
        setselectDatas(editData[0].selectData[1].value)
      }
    }
  }, [openDialog])
  useEffect(() => {
    const array = [...InputLists]
    array[1].value = selectContarctActive
    setInputLists(array)
  }, [selectContarctActive])
  useEffect(() => {
    if (edit) {
      setcreateStep(2)
      setselectData(editData[0].selectData)
      setselectStep(0)
      setDragDropFile(editData[0].file)
      InputListsSet[0].file = editData[0].file
      InputListsSet[0].memo = editData[0].memo
      InputListsSet[0].subContract = editData[0].subContract
      InputListsSet[0].requests = editData[0].requests
      InputListsSet[1].value3 = editData[1].value3
      InputListsSet[1].value = editData[1].value
      InputListsSet[1].value1 = editData[1].value1
      InputListsSet[1].value2 = editData[1].value2
      InputListsSet[3].value = editData[3].value
      setInputLists(JSON.parse(JSON.stringify(InputListsSet)))
    }
  }, [edit])

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const handleClose = () => {
    setcreateStep(0)
    setselectStep(0)
    setselectData([{ value: "" }, { value: "" }, { value: "" }])
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    closeDialog()
    setOpen(false)
  }
  const handleClose2 = () => {
    setcreateStep(0)
    setselectStep(0)
    setselectData([{ value: "" }, { value: "" }, { value: "" }])
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    closeDialog2()
    setOpen(false)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].value = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const handleRatio = (index) => (e) => {
    let array = [...InputLists]
    array[index].value3 = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const handleFilterDatas = (index, num) => (e) => {
    let array = [...InputLists]
    if (e) {
      if (e.toString() === "Invalid Date") {
        array[index][`error${num}`] = true
      } else {
        array[index][`error${num}`] = false
      }
    }
    if (num === 1) {
      array[index].value1 = e
    } else if (num === 2) {
      array[index].value2 = e
    }
    array[0].changed = true
    setInputLists(array)
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: String(myData.id),
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }

  const fileText = (data) => {
    if (data.length > 40) {
      let result = data.slice(0, 30) + "..." + data.slice(-7)

      return result
    } else {
      return data
    }
  }
  const imgupload = () => {
    document.getElementsByClassName("fileDragDropContract")[0].click()
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const fileListShow = () => {
    const fileName = DragDropFile?.name
    const fileType = fileName.split(".")[fileName.split(".").length - 1]
    if (DragDropFile.name) {
      return (
        <SuiBox display="flex" alignItems="start" sx={{ height: "auto" }}>
          <IconButton sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }} className="cursorDefalut">
            {GetFileIcon(fileType)}
            {/* <GrDocumentPdf className="pdfColor" /> */}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="dark"
            sx={{ pt: 0.8, pb: 0.3, cursor: DragDropFile.url ? "pointer" : "default" }}
            onClick={() => DownloadPdf(DragDropFile)}
          >
            {fileText(DragDropFile.name)}
          </SuiTypography>
          {DragDropFile.url ? (
            <div style={{ width: "150px" }}>
              <SuiButton
                sx={{
                  backgroundColor: "#EEEEEE",
                  ml: "auto",
                  minHeight: 24,
                  height: 24,
                  width: 24,
                  minWidth: 24,
                  p: 0,
                  ml: 1,
                  boxShadow: 0,
                }}
                onClick={() => showPdf(DragDropFile)}
              >
                <FiSearch size="15" />
              </SuiButton>
              {/* <SuiButton
                color="info"
                sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                onClick={() => DownloadPdf(DragDropFile)}
              >
                다운로드
              </SuiButton> */}
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else {
      return (
        <SuiBox
          justifyContent="center"
          display="flex"
          alignItems="start"
          sx={{ height: "auto", pt: 4 }}
        ></SuiBox>
      )
    }
  }
  const [contarctDialog, setContarctDialog] = useState(false)
  const openContarctDialog = () => {
    setContarctDialog(true)
  }
  const contarctDialogclose = () => {
    setContarctDialog(false)
  }
  const contractNumber = (id) => {
    let returnData = ""
    if (id) {
      if (id.length === 1) {
        var ids = String(id[0].id)
        returnData += ids.padStart(5, "0")
      } else {
        id.map((x) => x.id).forEach((el) => {
          var ids = String(el)
          returnData += ids.padStart(5, "0") + ","
        })
      }
    }
    return returnData
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "picUpload") {
      return (
        <SuiBox sx={{ mb: "105px" }}>
          <SuiBox display="flex" alignItems="center">
            <DragDrop files={setDragDropFile} />
          </SuiBox>
          <SuiBox
            justifyContent="center"
            display="flex"
            alignItems="start"
            sx={{ height: "auto", mt: 2.5 }}
          >
            <SuiButton
              sx={{ height: 30, px: 2 }}
              color="info2"
              size="small"
              onClick={() => imgupload()}
            >
              파일선택
            </SuiButton>
          </SuiBox>
          <SuiTypography fontSize="15px" fontWeight="regular" color="info" sx={{ pt: 2, pb: 1 }}>
            업로드 파일:
          </SuiTypography>
          <SuiBox>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return <SuiBox>{/* <SuiBox>{fileListShow()}</SuiBox> */}</SuiBox>
    } else if (data.type === "datePicker") {
      let array = [...InputLists]
      return (
        <SuiBox>
          <SuiBox display="flex" sx={{ mt: "15px" }}>
            <SuiTypography
              fontSize="15px"
              fontWeight="regular"
              color="info"
              sx={{ pt: 2, pb: 1, mr: "auto" }}
            >
              작품연결:
            </SuiTypography>
            <SuiTypography
              fontSize="14px"
              fontWeight="bold"
              color="info2"
              className="cursor"
              sx={{
                textAlign: "right",
                pt: 2,
                ml: "auto",
                pb: 1,
                textDecoration: "underline",
              }}
              onClick={() => openContarctDialog()}
            >
              작품검색
            </SuiTypography>
          </SuiBox>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              pt: 1.8,
              mb: 1,
              px: 2,
            }}
          >
            <SuiBox display="flex" width="100%">
              {contractNumber(data.value).length > 36 ? (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {contractNumber(data.value)}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <SuiTypography
                    sx={{
                      fontSize: 18,
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {contractNumber(data.value).substring(0, 36)}...
                  </SuiTypography>
                </Tooltip>
              ) : (
                <SuiTypography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {contractNumber(data.value)}
                </SuiTypography>
              )}
            </SuiBox>
          </Card>
          <SuiBox display="flex" sx={{ mt: "15px" }}>
            <SuiTypography
              fontSize="15px"
              fontWeight="regular"
              color="info"
              sx={{ pt: 2, pb: 1, mr: "auto" }}
            >
              정산비율:
            </SuiTypography>
          </SuiBox>{" "}
          <SuiBox fullWidth sx={{ mr: 2, width: "100%" }}>
            <SuiInput
              type="text"
              placeholder="조아라:유통사"
              value={data.value3}
              onChange={handleRatio(index)}
            />
          </SuiBox>
          <SuiTypography
            fontSize="15px"
            fontWeight="regular"
            color="info"
            sx={{ pt: 2, pb: 1, mr: "auto" }}
          >
            {data.title}
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
              <DatePicker
                className="contract-date2"
                inputFormat={"YYYY-MM-DD"}
                value={data.value1}
                onChange={handleFilterDatas(index, 1)}
                renderInput={(params) => <TextField className="contract-date" {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
          <SuiTypography
            fontSize="15px"
            fontWeight="regular"
            color="info"
            sx={{ pt: 2, pb: 1, mr: "auto" }}
          >
            {data.title1}
          </SuiTypography>
          <SuiBox sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
              <DatePicker
                className="contract-date2"
                inputFormat={"YYYY-MM-DD"}
                value={data.value2 === "2999-01-01T00:00:00.000Z" ? "yyyy-MM-dd" : data.value2}
                minDate={new Date(data.value1)}
                onChange={handleFilterDatas(index, 2)}
                renderInput={(params) => <TextField className="contract-date" {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox>
          <FormControl
            fullWidth
            className={
              data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => setFiltersOpen1(!FiltersOpen1)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => setFiltersOpen1(!FiltersOpen1)}
                />
              )}
              open={FiltersOpen1}
              value={data.value}
              onChange={handleSelectChange(index)}
              className="setting-border-radius"
            >
              {data.items.map((x) => {
                return (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    }
  }
  const selectDataControll = (data) => {
    if (selectDatas.indexOf(data) === -1) {
      let array = [...selectData]
      array[createStep].value = data
      setselectData(array)
    } else {
      if (createStep !== 1) {
        let array = [...selectData]
        array[createStep].value = data
        setselectData(array)
      }
    }
  }
  const backStep = () => {
    if (createStep === 1) {
      setcreateStep(createStep - 1)
      setselectData([{ value: "" }, { value: "" }, { value: "" }])
      setselectStep(0)
    } else if (createStep === 2) {
      setcreateStep(createStep - 1)
      setselectStep(cards[0].items[selectStep].indexOf(selectData[0].value))
    }
  }
  const nextStep = () => {
    if (createStep < 1) {
      setcreateStep(createStep + 1)
      setselectStep(cards[0].items[0].indexOf(selectData[createStep].value))
    } else {
      setcreateStep(createStep + 1)
      setselectStep(0)
    }
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "inherit" }}
      >
        <SuiBox>
          {row.title !== "계약일:" && row.title !== "업로드 파일:" ? (
            <SuiTypography fontSize="15px" fontWeight="regular" color="info" sx={{ pt: 2, pb: 1 }}>
              {row.title}
            </SuiTypography>
          ) : (
            ""
          )}
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const title = () => {
    if (createStep === 0) {
      return "계약 유형 선택"
    } else if (createStep === 1) {
      if (selectStep === 0) {
        return "이북"
      } else if (selectStep === 1) {
        return "2차 저작물 제작/유통 계약"
      } else if (selectStep === 2) {
        return "기타 계약"
      }
    } else if (createStep === 2) {
      if (edit) {
        return "계약서 정보 수정"
      } else {
        return "계약서 정보 등록"
      }
    }
  }
  const subTitle = () => {
    if (createStep === 0) {
      return "계약 유형을 선택하고 다음 단계로 넘어갑니다."
    } else if (createStep === 1) {
      if (selectStep === 0) {
        return "이북 계약 방식을 선택하고 다음 단계로 넘어갑니다."
      } else if (selectStep === 1) {
        return "2차 저작물 제작/유통 계약 방식을 선택하고 다음 단계로 넘어갑니다."
      } else if (selectStep === 2) {
        return "기타 계약 방식을 선택하고 다음 단계로 넘어갑니다."
      }
    } else if (createStep === 2) {
      return "계약 정보를 등록하고, 계약서 파일을 업로드합니다."
    }
  }
  const [wrong_date, setwrong_date] = useState(false)
  const createContractDc = () => {
    if (InputLists[1].error1 || InputLists[1].error2) {
      return setwrong_date(true)
    }
    InputLists[0].file = DragDropFile

    if (edit) {
      if (!DragDropFile.url) {
        InputLists[0].changed = true
      }
    }
    InputLists[0].selectData = selectData
    // if (InputLists[3].value === "자동갱신") {
    //   const x = [...InputLists]
    //   x[1].value2 = "2999-01-01T00:00:00.000Z"
    //   setInputLists(x)
    // }

    if (type !== "create") {
      contract_documents_data_update(InputLists)
    } else {
      contract_documents_data(InputLists)
    }
    handleClose2()
  }
  const cards = [
    {
      items: [["이북", "2차 저작물 제작/유통 계약", "기타 계약"]],
    },
    {
      items: [
        ["이북 유통 계약", "이북 특별 계약"],
        ["(외부) IP 제작 계약", "(내부) IP 유통 계약"],
        ["이북 미분류(기타)", "IP 미분류(기타)"],
      ],
    },
    {
      items: [[]],
    },
  ]
  const createCardList = cards[createStep].items[selectStep].map((row, index) => {
    return (
      <Card
        sx={{
          p: 2,
          mb: 2,
          boxShadow: 0,
          border: 1,
          height: 60,
          backgroundColor:
            selectData[createStep].value === row
              ? "#FAF8FF"
              : selectDatas.indexOf(row) > -1 && createStep === 1
              ? "#CDD2D3"
              : "",
          borderColor:
            selectData[createStep].value === row
              ? "#733DF3"
              : selectDatas.indexOf(row) > -1 && createStep === 1
              ? "#CDD2D3"
              : "#EEEEEE",
          borderRadius: "10px",
        }}
        key={index}
        className={selectDatas.indexOf(row) > -1 && createStep === 1 ? "" : "cursor"}
        onClick={() => selectDataControll(row)}
      >
        <SuiBox display="flex" justifyContent="center">
          <SuiTypography
            color={
              selectData[createStep].value === row
                ? "primary"
                : selectDatas.indexOf(row) > -1 && createStep === 1
                ? "white"
                : "secondary"
            }
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            {row}
          </SuiTypography>
        </SuiBox>
      </Card>
    )
  })
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: createStep === 2 ? 820 : 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              {title(createStep)}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              {subTitle(createStep)}
            </SuiTypography>
          </SuiBox>
          <SuiBox sx={{ px: 3 }}>
            {createStep < 2 ? (
              createCardList
            ) : (
              <Grid container alignItems="center">
                {listsShow}
              </Grid>
            )}
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}
          >
            {createStep !== 0 ? (
              <SuiButton
                color={createStep > 0 ? "subTitle" : "secondary"}
                variant="text"
                onClick={() => (createStep > 0 ? backStep() : "")}
                className="back-btn"
                style={{ cursor: createStep > 0 ? "pointer" : "default" }}
                sx={{ mr: "auto", fontSize: 16 }}
              >
                뒤로
              </SuiButton>
            ) : (
              ""
            )}

            {createStep === 2 ? (
              <SuiButton
                color="subTitle"
                variant="text"
                sx={{
                  ml: "auto",
                  mr: 1,
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  width: 100,
                  fontSize: 14,
                  mb: 1,
                  border: 1,
                  borderColor: "#707070",
                }}
                onClick={() => handleClose()}
              >
                취소
              </SuiButton>
            ) : (
              ""
            )}
            {createStep < 2 ? (
              <SuiButton
                color="primary"
                onClick={() => nextStep()}
                sx={{
                  ml: createStep !== 0 ? "" : "auto",
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  fontSize: 14,
                }}
                disabled={selectData[createStep].value === ""}
              >
                다음으로
              </SuiButton>
            ) : (
              <SuiButton
                color="primary"
                onClick={() => createContractDc()}
                sx={{ px: 2, py: 0.5, height: 40, minHeight: 40, width: 100, fontSize: 14, mb: 1 }}
                disabled={
                  DragDropFile.name === "" ||
                  InputLists[1].value1 === null ||
                  (InputLists[3].value === "자동갱신" ? false : InputLists[1].value2 === null) ||
                  (InputLists[3].value === "자동갱신"
                    ? false
                    : new Date(InputLists[1].value1) > new Date(InputLists[1].value2))
                }
              >
                {edit ? "수정" : "완료"}
              </SuiButton>
            )}
          </SuiBox>
        </Grid>
      </Grid>
      <SuiDialog
        openDialog={wrong_date}
        closeDialog={() => setwrong_date(false)}
        MainTitle={"정보 입력 오류"}
        Content={"계약일 또는 계약 종료일을 다시 확인해 주세요."}
        CanBtnText={"닫기"}
      />
      <ContarctDialog
        openDialog={contarctDialog}
        closeDialog={contarctDialogclose}
        selectContarctActive={setSelectContarctActive}
        selectData={selectDatas}
        startContract={InputLists[2].value1}
        title={titleData}
      ></ContarctDialog>
    </Dialog>
  )
}

ContractDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: [],
  type: "create",
  contract_documents: [],
}

ContractDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.array,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  contract_documents_data: PropTypes.func,
  contract_documents_data_update: PropTypes.func,
  type: PropTypes.string,
  contract_documents: PropTypes.array,
  selectDataProps: PropTypes.func,
  titleData: PropTypes.string,
}
export default ContractDialog
