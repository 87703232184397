import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import Checkbox from "@mui/material/Checkbox"

import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { AiOutlineSearch } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"

import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { bookCodeContracts } from "apollo/query"
import {} from "apollo/mutation"

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "book_code",
      numeric: false,
      disablePadding: false,
      label: "북코드",
    },
    {
      id: "ebook",
      numeric: false,
      disablePadding: false,
      label: "이북 필명",
    },

    {
      id: "joara_name",
      numeric: false,
      disablePadding: false,
      label: "조아라 필명 ",
    },
    {
      id: "real_name",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },

    {
      id: "single_select",
      numeric: false,
      disablePadding: false,
      label: "선택",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell, index) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
              py: 0,
              height: "40px",
              maxHeight: "40px",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <span>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}

function singleReceiverDialog({ openDialog, closeDialog, receiver }) {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [seach_input, set_seach_input] = useState("") // 검색창 입력값
  const [filters_open1, set_filters_open1] = useState(false) // 검색창 셀렉트
  const [search_select_value, set_search_select_value] = useState("작품 명") // 검색창 셀렉트 value
  const [contract_list, set_contract_list] = useState([]) // 선택된 값들
  const [table_list, set_table_list] = useState([
    // 검색 테이블
    // {
    //   real_name: "박우순",
    //   ebook: "까만콩커피",
    //   phone: "01028771802",
    //   title: "미친 황제 아론 엘리엇",
    //   joara_name: "미친 황제 아론 엘리엇",
    //   book_code: "1591944",
    //   reward: "무료 회수 20",
    //   status: "미발송",
    //   agree_status: "미확인",
    //   agree_filter_open: false,
    //   select: false,
    // },
  ])

  const handleClose = () => {
    set_seach_input("")
    set_contract_list([])
    set_table_list([])
    closeDialog()
  }
  const handleCheckBox = (index) => (e) => {
    let arr_table_list = [...table_list]
    let arr_select_list = [...contract_list]
    arr_table_list[index].single_select = e.target.checked
    if (e.target.checked) {
      arr_select_list.unshift(arr_table_list[index])
      set_contract_list(arr_select_list)
    } else {
      let num_remove_data_index = arr_select_list.findIndex(
        (x) => x.id === arr_table_list[index].id
      )
      arr_select_list.splice(num_remove_data_index, 1)
      set_contract_list(arr_select_list)
    }
    set_table_list(arr_table_list)
  }
  const handleInput = (e) => {
    set_seach_input(e.target.value)
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && seach_input) {
      clickSearch()
    }
  }
  const handleSave = () => {
    receiver(contract_list)
    handleClose()
  }
  const selectCloseClick = (data, index) => (e) => {
    // 셀렉트 리스트
    let arr_select_list = [...contract_list]
    arr_select_list.splice(index, 1)
    set_contract_list(arr_select_list)
    // 테이블 리스트 수정
    let arr_table_list = [...table_list]
    let num_close_index = table_list.findIndex((x) => x.id === data.id)
    arr_table_list[num_close_index].single_select = false
    set_table_list(arr_table_list)
  }
  const clickSearch = async () => {
    dispatch(loading_Apply(true))
    let input = {}
    if (search_select_value === "작품 명") {
      input.title = seach_input
    } else if (search_select_value === "이북 필명") {
      input.ebookname = seach_input
    } else if (search_select_value === "조아라 필명") {
      input.joaraname = seach_input
    }
    await client
      .query({
        query: bookCodeContracts,
        fetchPolicy: "network-only",
        variables: input,
      })
      .then((res) => {
        let arr_search_list = []
        if (res.data.contracts.length > 0) {
          for (let i = 0; i < res.data.contracts.length; i++) {
            let re_phone
            re_phone = CryptoJS.AES.decrypt(res.data.contracts[i].writer.phone, secretKey)
              .toString(CryptoJS.enc.Utf8)
              .replace(/\"/g, "")
            let re_email
            re_email = CryptoJS.AES.decrypt(res.data.contracts[i].writer.email, secretKey)
              .toString(CryptoJS.enc.Utf8)
              .replace(/\"/g, "")
            let obj_contract_data = {
              id: res.data.contracts[i].id,
              genre: res.data.contracts[i].genre,
              re_email: re_email,
              real_name: res.data.contracts[i].writer.name,
              ebook: res.data.contracts[i].writer.ebookname,
              joara_name: res.data.contracts[i].writer.joaraname,
              writerId: res.data.contracts[i].writer.id,
              phone: re_phone,
              title: res.data.contracts[i].title,
              book_code: res.data.contracts[i].bookcode,
              reward: "이벤트 내용 참고",
              reward_bool: false, // 리워드 인풋박스로 변경여부
              manager: "",
              manager_bool: false, // 작가 담당자 인풋박스로 변경여부
              send_result_status: "미발송",
              send_status: "미발송",
              agree_status: "미확인",
              agree_filter_open: false,
              select: false, // 이메일 대상자에서 개별전송, 삭제 클릭시 필요
              single_select:
                // 개별 선택 checkbox 선택여부
                contract_list.findIndex((x) => x.id === res.data.contracts[i].id) > -1
                  ? true
                  : false,
              excel: false, // 엑셀업로드로 추가한경우 ture
              writer: res.data.contracts[i].writer,
              img: [],
            }
            arr_search_list.push(obj_contract_data)
          }
          set_table_list(arr_search_list)
          dispatch(loading_Apply(false))
        } else {
          set_table_list(arr_search_list)
          dispatch(loading_Apply(false))
        }
      })
  }
  const selectAll = () => {
    set_table_list(contract_list)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        "> div ::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 900 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <MdClose style={{ width: "24px", height: "24px" }} />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              이메일 대상자 추가
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              이벤트 추천 작품명을 검색하고, 이메일 대상자를 추가합니다.
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" sx={{ px: 3 }}>
            <SuiBox sx={{ width: 200, ml: "auto", mb: "5px", mr: "5px" }}>
              <FormControl
                sx={{ minHeight: 30, width: "100%" }}
                className={"setting-selectbox"}
                onClick={() => set_filters_open1(!filters_open1)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => set_filters_open1(!filters_open1)}
                    />
                  )}
                  open={filters_open1}
                  value={search_select_value}
                  onChange={(e) => set_search_select_value(e.target.value)}
                  className="setting-border-radius"
                >
                  <MenuItem value="작품 명">작품 명</MenuItem>
                  <MenuItem value="이북 필명">이북 필명</MenuItem>
                  <MenuItem value="조아라 필명">조아라 필명</MenuItem>
                </Select>
              </FormControl>
            </SuiBox>
            <SuiInput
              value={seach_input}
              onChange={handleInput}
              onKeyPress={handleKeyPress}
              placeholder={`${search_select_value}을 입력해주세요.`}
            ></SuiInput>
            <SuiButton
              sx={{ minHeight: 40, height: 40, width: 40, minWidth: 40, p: 1, ml: 1, mb: 1 }}
              color="search"
              disabled={seach_input === ""}
              onClick={clickSearch}
            >
              <AiOutlineSearch size="20" />
            </SuiButton>
          </SuiBox>
          {contract_list.length > 0 ? (
            <SuiBox display="flex" alignItems="center" sx={{ px: 3, mt: 1, height: "54px" }}>
              <SuiBox
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#e1eff6",
                  borderRadius: "50%",
                  px: 1,
                  width: 43,
                  height: 43,
                }}
              >
                <SuiTypography
                  style={{ fontSize: "16px", fontWeight: "medium", cursor: "pointer" }}
                  color="subTitle"
                  onClick={selectAll}
                >
                  전체
                </SuiTypography>
              </SuiBox>
              <SuiBox
                display="-webkit-box"
                alignItems="center"
                sx={{ overflow: "auto", width: "770px" }}
              >
                {contract_list.map((data, index) => {
                  return (
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        backgroundColor: "#f7f9fb",
                        my: 1,
                        px: 1,
                        ml: 2,
                        borderRadius: "10px%",
                      }}
                      key={index + "p5"}
                    >
                      <SuiTypography
                        style={{ fontSize: "16px", fontWeight: "medium" }}
                        color="subTitle"
                      >
                        {data.title.length > 8 ? data.title.substring(0, 8) + " ..." : data.title}
                      </SuiTypography>
                      <IoMdCloseCircle
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={selectCloseClick(data, index)}
                      ></IoMdCloseCircle>
                    </SuiBox>
                  )
                })}
              </SuiBox>
            </SuiBox>
          ) : (
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="start"
              sx={{ my: 1, px: 3, height: "54px" }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                대상 없음
              </SuiTypography>
            </SuiBox>
          )}
          <SuiBox sx={{ px: 3 }}>
            <TableContainer
              sx={{
                borderTop: "1px solid #F1F1F5",
                borderRadius: 0,
                boxShadow: 0,
                marginRight: "40px",
                mt: 2,
                minHeight: table_list.length === 0 ? 60 : 480,
                maxHeight: table_list.length === 0 ? 60 : 480,
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead />
                <TableBody>
                  {table_list.map((data, index) => {
                    return (
                      <TableRow hover role="checkbox" sx={{ cursor: "pointer" }} key={index + "p7"}>
                        <TableCell align="center" className="td-send-Setting td-fontSetting ">
                          {data.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {data.book_code}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {data.ebook}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {data.joara_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {data.real_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            "> span": {
                              padding: "0px",
                            },
                          }}
                        >
                          <Checkbox
                            checked={data.single_select}
                            onChange={handleCheckBox(index)}
                            color="primary"
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {table_list.length === 0 && (
              <SuiBox
                py={2}
                mt="60px"
                sx={{ height: 360 }}
                display="flex"
                justifyContent="center"
                textAlign="center"
              >
                <SuiTypography style={{ fontSize: "16px" }}>
                  검색하신 내용이 없습니다.
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mt: "20px", pl: 2, pr: 3 }}
          >
            <SuiButton
              color="primary"
              variant="contained"
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                mb: 1,
                border: 1,
              }}
              disabled={contract_list && contract_list.length === 0}
              onClick={handleSave}
            >
              완료
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}
singleReceiverDialog.defaultProps = {
  openDialog: false,
}

singleReceiverDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  receiver: PropTypes.func,
}
export default singleReceiverDialog
