import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"

import { MdClose } from "react-icons/md"

function HistoryDialog({ openDialog, closeDialog, selectDataProps, nextStep, selectDataEdit }) {
  const [open, setOpen] = useState(openDialog)
  const [selectData, setselectData] = useState("")
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  useEffect(() => {
    setOpen(openDialog)
    if (selectDataEdit) setselectData(selectDataEdit)
    else setselectData("")
  }, [openDialog])
  const selectDataControll = (row) => {
    setselectData(row)
    selectDataProps(row)
  }
  const cards = ["외주 일러스트 계약", "외주 편집계약", "기타 계약"]
  const createCardList = cards.map((row, index) => {
    return (
      <Card
        sx={{
          p: 2,
          mb: 2,
          boxShadow: 0,
          border: 1,
          height: 60,
          backgroundColor: selectData === row ? "#FAF8FF" : selectData === row ? "#CDD2D3" : "",
          borderColor: selectData === row ? "#733DF3" : selectData === row ? "#CDD2D3" : "#EEEEEE",
          borderRadius: "10px",
        }}
        key={index}
        className={selectData === row ? "" : "cursor"}
        onClick={() => selectDataControll(row)}
      >
        <SuiBox display="flex" justifyContent="center">
          <SuiTypography
            color={selectData === row ? "primary" : selectData === row ? "white" : "secondary"}
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            {row}
          </SuiTypography>
        </SuiBox>
      </Card>
    )
  })
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              작업 유형 선택
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              작업 유형을 선택하고 다음 단계로 넘어갑니다.
            </SuiTypography>
          </SuiBox>
          <SuiBox sx={{ px: 3 }}>{createCardList}</SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="end"
            height="100%"
            alignItems="center"
            sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}
          >
            <SuiButton
              color="primary"
              onClick={() => nextStep()}
              sx={{ px: 2, py: 0.5, height: 40, minHeight: 40, fontSize: 14 }}
              disabled={!selectData}
            >
              다음으로
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}

HistoryDialog.defaultProps = {
  openDialog: false,
}

HistoryDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  selectDataProps: PropTypes.func,
  nextStep: PropTypes.func,
  selectDataEdit: PropTypes.string,
}
export default HistoryDialog
