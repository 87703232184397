import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import dayjs from "dayjs"
import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { AiOutlineSearch } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"

// img
import gifImg from "assets/images/Spinner-2s-157px.gif"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"

import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { bookCodeContracts, events } from "apollo/query"
import {
  createEventEmail,
  updateEventEmail,
  createEventResultEmail,
  updateEventResultEmail,
  createSendEmailEvent,
  createEventRecevier,
  updateEventRecevier,
  createSendSm,
  updateEvent,
} from "apollo/mutation"

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "receiver",
      numeric: false,
      disablePadding: false,
      label: "받는 사람(이메일)",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "제목",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "발송시간",
    },
    {
      id: "send_status",
      numeric: false,
      disablePadding: false,
      label: "발송상태",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell, index) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              // borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
              py: 0,
              height: "40px",
              maxHeight: "40px",
              backgroundColor: "#ffffff",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <span>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}
function EnhancedTableHead2(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "receiver",
      numeric: false,
      disablePadding: false,
      label: "받는 사람(이북 필명)",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "발송시간",
    },
    {
      id: "send_status",
      numeric: false,
      disablePadding: false,
      label: "발송상태",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell, index) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              // borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
              py: 0,
              height: "40px",
              maxHeight: "40px",
              backgroundColor: "#ffffff",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <span>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead2.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead2.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}

function sendEmail({
  openDialog,
  closeDialog,
  emailContent,
  receiver,
  emailType,
  resultEmail,
  eventData,
}) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [spinner, set_spinner] = useState(false) // 에러 다이어로그 오픈여부
  const [open_info_dialog, set_open_info_dialog] = useState(false) // 에러 다이어로그 오픈여부
  const [open_title, set_open_title] = useState("") // 에러 다이어로그 제목
  const [open_content, set_open_content] = useState("") // 에러 다이어로그 내용
  const [sent_result, set_sent_result] = useState(false)
  const [checkbox_value, set_checkbox_value] = useState(false)
  const [step, set_step] = useState(0)
  const [send_type, set_send_type] = useState("")
  const [SMS_content, set_SMS_content] = useState(
    "[이벤트 협의] 작가님, 안녕하세요! 조아라입니다. 이벤트 협의 요청드릴 사항이 있어 메일 보내드렸습니다. 확인 부탁드립니다."
  )
  const { myData } = useSelector((state) => state.store)
  const [table_list, set_table_list] = useState([]) // 이메일 보낼 사람들
  const [all_email_data, set_all_email_data] = useState([]) // 이메일 모든 정보들
  const [all_event, set_all_event] = useState({
    history: [],
  })
  const imgPathUrl = process.env.REACT_APP_BACKEND_URL
  // {
  //   real_name: "이원회",
  //   re_email: "doggodi303@naver.com",
  //   title: "미친 황제 아론 엘리엇",
  //   created_at: "",
  //   send_status: "완료",
  // },

  useEffect(async () => {
    if (openDialog) {
      if (resultEmail) {
        set_SMS_content(
          "[이벤트 결과] 작가님, 안녕하세요! 조아라입니다. 이벤트 결과 안내드리고자 메일 보내드렸습니다. 확인 부탁드립니다."
        )
      } else {
        set_SMS_content(
          "[이벤트 협의] 작가님, 안녕하세요! 조아라입니다. 이벤트 협의 요청드릴 사항이 있어 메일 보내드렸습니다. 확인 부탁드립니다."
        )
      }
      set_sent_result(false)
      // let copy_data = [...emailContent]
      let copy_data = JSON.parse(JSON.stringify(emailContent))
      if (copy_data[0].value.indexOf("[조아라]<작품명>") > -1) {
        copy_data[0].value = copy_data[0].value.split("[조아라]<작품명>")[1].trim()
      } else {
        copy_data[0].value = copy_data[0].value
      }
      if (emailType === "single") {
        set_step(0)
        copy_data[4].createData = copy_data[4].data.filter((x) => !x.select)
        copy_data[4].data = copy_data[4].data.filter((x) => x.select)
      } else {
        if (resultEmail) {
          copy_data[4].data.map((x) => (x.send_result_status = "미발송"))
        } else {
          copy_data[4].data.map((x) => (x.send_status = "미발송"))
        }
      }
      copy_data[4].data.map((x) => (x.send_sms_status = "미발송"))

      set_checkbox_value(false)
      set_all_email_data(copy_data)
      event_call(copy_data[0].event_id)

      set_table_list(copy_data[4].data)
    }
  }, [openDialog])
  const event_call = async (value) => {
    await client
      .query({
        query: events,
        variables: {
          id: value,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        if (res.data.events.length > 0) {
          set_all_event(res.data.events[0])
        }
      })
  }
  const nextStep = () => {
    if (send_type === "") {
      set_open_title("개별 전송 에러")
      set_open_content("라디오 버튼을 선택해주세요")
      set_open_info_dialog(true)
      return
    }
    if (resultEmail) {
      if (send_type === "email") {
        if (table_list.filter((x) => x.send_result_status === "완료").length > 0) {
          set_open_title("개별 이메일 전송 에러")
          set_open_content("이미 메일을 보낸 대상자가 포함되어 있습니다.")
          set_open_info_dialog(true)
          return
        }
      } else {
        // if (table_list.filter((x) => x.send_result_status !== "완료").length > 0) {
        //   set_open_title("개별 SMS 전송 에러")
        //   set_open_content("메일을 보내지 않은 대상자가 포함되어 있습니다.")
        //   set_open_info_dialog(true)
        //   return
        // }
      }
    } else {
      if (send_type === "email") {
        if (table_list.filter((x) => x.send_status === "완료").length > 0) {
          set_open_title("개별 이메일 전송 에러")
          set_open_content("이미 메일을 보낸 대상자가 포함되어 있습니다.")
          set_open_info_dialog(true)
          return
        }
        if (!all_email_data[3].value) {
          set_open_title("이메일 발송 오류")
          set_open_content(`이벤트 승인 마감일을 입력해주세요`)
          set_open_info_dialog(true)
          return
        }
        if (
          all_email_data[3].value &&
          dayjs(eventData.start).format("YY-MM-DD") <=
            dayjs(all_email_data[3].value).format("YY-MM-DD")
        ) {
          set_open_title("이메일 발송 오류")
          set_open_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
          set_open_info_dialog(true)
          return
        }
      } else {
        // if (table_list.filter((x) => x.send_status !== "완료").length > 0) {
        //   set_open_title("개별 SMS 전송 에러")
        //   set_open_content("메일을 보내지 않는 대상자가 포함되어 있습니다.")
        //   set_open_info_dialog(true)
        //   return
        // }
      }
    }
    if (send_type === "sms") {
      set_SMS_content("")
    }
    set_step(1)
  }
  const handleClose = () => {
    if (sent_result) {
      receiver("update")
    } else {
      receiver("cancle")
    }
    closeDialog()
  }
  const handleSaveClose = () => {
    receiver("update")
    closeDialog()
  }
  const eventEmailCreate = async () => {
    await client
      .mutate({
        mutation: createEventEmail,
        variables: {
          nameType: all_email_data[0].name_filter,
          eventId: String(all_email_data[0].event_id),
          title: `[조아라] <작품명> ` + all_email_data[0].value,
          senderEmail: all_email_data[1].value,
          senderName: all_email_data[2].value,
          endDate: all_email_data[3].value ? all_email_data[3].value : null,
          content: all_email_data[5].value,
          history: [],
          allSend: emailType === "all" ? true : false,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        await updateEventHistory("<strong>안내 발송 정보</strong>를 등록하였습니다.")
        let arr_res = [...all_email_data]
        arr_res[0].send_email_id = res.data.createEventEmail.eventEmail.id
        set_all_email_data(arr_res)
      })
  }
  const eventResultEmailCreate = async () => {
    let img_list = {
      id: [],
      data: [],
    }
    if (all_email_data[5].img.length > 0) {
      for (let i = 0; i < all_email_data[5].img.length; i++) {
        img_list.id.push(all_email_data[5].img[i].file[0].id)
        img_list.data.push({
          id: all_email_data[5].img[i].file[0].id,
          url: all_email_data[5].img[i].file[0].url,
          name: all_email_data[5].img[i].file[0].name,
          title: all_email_data[5].img[i].file[1].value,
          link: all_email_data[5].img[i].file[2].value,
          description: all_email_data[5].img[i].file[3].value,
          width: all_email_data[5].img[i].file[0].size.value1,
          height: all_email_data[5].img[i].file[0].size.value2,
        })
      }
    }
    await client
      .mutate({
        mutation: createEventResultEmail,
        variables: {
          nameType: all_email_data[0].name_filter,
          eventId: String(all_email_data[0].event_id),
          title: `[조아라] <작품명> ` + all_email_data[0].value,
          emailTitle: all_email_data[1].value,
          senderEmail: all_email_data[2].value,
          senderName: all_email_data[3].value,
          content: all_email_data[5].value,
          history: [],
          allSend: emailType === "all" ? true : false,
          emailImage: img_list.id,
          emailImages: img_list.data,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        await updateEventHistory("<strong>결과 보고 정보</strong>를 등록하였습니다.")
        let arr_res = [...all_email_data]
        arr_res[0].send_email_id = res.data.createEventResultEmail.eventResultEmail.id
        set_all_email_data(arr_res)
      })
  }
  const getByteLengthOfString = (s, b, i, c) => {
    for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
    return b
  }

  const smsHandleSave = async () => {
    let send_list = [...table_list]
    if (getByteLengthOfString(SMS_content) > 3000) {
      set_open_title("sms 전송 에러")
      set_open_content("3000byte 미만이여합니다. (한글 3byte, 영어 1byte)")
      set_open_info_dialog(true)
      return
    }
    set_spinner(true)
    for (let i = 0; i < send_list.length; i++) {
      await client
        .mutate({
          mutation: createSendSm,
          variables: {
            phone: send_list[i].phone,
            eventId: String(all_email_data[0].event_id),
            content: SMS_content,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          send_list[i].send_sms_status =
            res.data.createSendSm.sendSm.send_result.split("_")[0] === "성공" ? "완료" : "실패"
          send_list[i].created_at = res.data.createSendSm.sendSm.created_at
          set_table_list(send_list)
        })
      if (send_list.length - 1 === i) {
        let arr_res = [...all_email_data]
        arr_res[6].data.unshift({
          created_at: new Date(),
          email: {
            success: "",
            fail: "",
          },
          sms: {
            success: send_list.filter((x) => x.send_sms_status === "완료").length,
            fail: send_list.filter((x) => x.send_sms_status === "실패").length,
          },
        })
        if (resultEmail) {
          await client
            .mutate({
              mutation: updateEventResultEmail,
              variables: {
                id: arr_res[0].send_email_id,
                history: arr_res[6].data,
              },
              fetchPolicy: "network-only",
            })
            .then(async (res) => {
              set_all_email_data(arr_res)
              set_sent_result(true)
            })
        } else {
          await client
            .mutate({
              mutation: updateEventEmail,
              variables: {
                id: arr_res[0].send_email_id,
                history: arr_res[6].data,
              },
              fetchPolicy: "network-only",
            })
            .then(async (res) => {
              set_all_email_data(arr_res)
              set_sent_result(true)
            })
        }
        set_spinner(false)
      }
    }
  }
  const updateEventHistory = async (value) => {
    let event_history = { ...all_event }
    let li = []
    li = JSON.parse(JSON.stringify(event_history.history))
    li.unshift({
      profile: myData.profile ? myData.profile.url : null,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      content: `${value}`,
      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
    })

    client.mutate({
      mutation: updateEvent,
      variables: {
        id: event_history.id,
        history: li,
      },
      fetchPolicy: "network-only",
    })
  }
  const handleSave = async () => {
    let send_list = [...table_list]
    if (getByteLengthOfString(SMS_content) > 3000) {
      set_open_title("sms 전송 에러")
      set_open_content("3000byte 미만이여합니다. (한글 3byte, 영어 1byte)")
      set_open_info_dialog(true)
      return
    }
    // 안내메일이 없을떄 이메일 생성후 메일발송
    if (!all_email_data[0].send_email_id) {
      if (resultEmail) {
        await eventResultEmailCreate()
      } else {
        await eventEmailCreate()
      }
    }
    set_spinner(true)
    for (let i = 0; i < send_list.length; i++) {
      let current_code = new Date().getTime().toString(36)
      let body_replace = JSON.parse(JSON.stringify(all_email_data[5].value))
      body_replace = body_replace.replaceAll("작가 본명", send_list[i].real_name)
      body_replace = body_replace.replaceAll("이북 필명", send_list[i].ebook)
      body_replace = body_replace.replaceAll("조아라 필명", send_list[i].joara_name)
      // body_replace = body_replace.replaceAll(
      //   "발송인 명",
      //   resultEmail ? all_email_data[3].value : all_email_data[2].value
      // )
      body_replace = body_replace.replaceAll("작품 명", send_list[i].title)
      body_replace = body_replace.replaceAll(
        "이벤트 내용 참고",
        send_list[i].reward ? send_list[i].reward : "이벤트 내용 참고"
      )
      body_replace = body_replace.replaceAll(
        "코드_code",
        send_list[i].code ? send_list[i].code : current_code
      )
      let replace_str = ""
      if (send_list[i].img && send_list[i].img.length > 0) {
        for (let j = 0; j < send_list[i].img.length; j++) {
          if (send_list[i].img[j].file[2].value) {
            replace_str =
              replace_str +
              `<p><br></p><p>[${send_list[i].img[j].file[1].value}]</p><p><a href="${
                send_list[i].img[j].file[2].value
              }" rel="noopener noreferrer" target="_blank"><img referrerpolicy="no-referrer" src="${
                imgPathUrl + send_list[i].img[j].file[0].url
              }" width="${send_list[i].img[j].file[0].size.value1}" height="${
                send_list[i].img[j].file[0].size.value2
              }"></a></p><p><br></p>`
          } else {
            replace_str =
              replace_str +
              `<p><br></p><p>[${
                send_list[i].img[j].file[1].value
              }]</p><p><img referrerpolicy="no-referrer" src="${
                imgPathUrl + send_list[i].img[j].file[0].url
              }" width="${send_list[i].img[j].file[0].size.value1}" height="${
                send_list[i].img[j].file[0].size.value2
              }"></p><p><br></p>`
          }
        }
      }

      body_replace = body_replace.replaceAll(
        `<strong style="color: rgb(255, 0, 0, 0.87);">[이미지 영역 삭제 금지]</strong>`,
        replace_str
      )
      if (checkbox_value) {
        await client
          .mutate({
            mutation: createSendSm,
            variables: {
              phone: send_list[i].phone,
              eventId: String(all_email_data[0].event_id),
              content: SMS_content,
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            send_list[i].send_sms_status =
              res.data.createSendSm.sendSm.send_result.split("_")[0] === "성공" ? "완료" : "실패"
            send_list[i].created_at = res.data.createSendSm.sendSm.created_at
          })
      }
      await client
        .mutate({
          mutation: createSendEmailEvent,
          variables: {
            eventId: String(all_email_data[0].event_id),
            subject: `[조아라] < ${send_list[i].title} > ${all_email_data[0].value}`,
            body: `<!DOCTYPE html><html lang="ko"><body>${body_replace}</body></html>`,
            sender: resultEmail ? all_email_data[2].value : all_email_data[1].value,
            sender_name: resultEmail ? all_email_data[3].value : all_email_data[2].value,
            receiver: [{ name: send_list[i].real_name, email: send_list[i].re_email.trim() }],
            events_email: String(all_email_data[0].send_email_id),
          },
          fetchPolicy: "network-only",
        })
        .then(async (res) => {
          if (resultEmail) {
            send_list[i].send_result_status =
              res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                ? "완료"
                : "실패"
          } else {
            send_list[i].send_status =
              res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                ? "완료"
                : "실패"
          }
          send_list[i].created_at = res.data.createSendEmailEvent.sendEmailEvent.created_at
          set_table_list(send_list)

          let img_table_list = {
            id: [],
            data: [],
          }
          if (send_list[i].img && send_list[i].img.length > 0) {
            for (let o = 0; o < send_list[i].img.length; o++) {
              img_table_list.id.push(send_list[i].img[o].file[0].id)
              img_table_list.data.push({
                id: send_list[i].img[o].file[0].id,
                url: send_list[i].img[o].file[0].url,
                name: send_list[i].img[o].file[0].name,
                title: send_list[i].img[o].file[1].value,
                link: send_list[i].img[o].file[2].value,
                description: send_list[i].img[o].file[3].value,
                width: send_list[i].img[o].file[0].size.value1,
                height: send_list[i].img[o].file[0].size.value2,
              })
            }
          }

          if (send_list[i].receiverId) {
            let input = {
              id: send_list[i].receiverId,
              rewarod: send_list[i].reward,
              manager: send_list[i].manager,
              access:
                send_list[i].agree_status === "미확인"
                  ? "unidentified"
                  : send_list[i].agree_status === "승인"
                  ? "access"
                  : "reject",
              agree_date: send_list[i].agree_status !== "미확인" ? new Date() : null,
              list: img_table_list.id,
              lists: img_table_list.data,
            }
            if (resultEmail) {
              input["result_status"] =
                res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                  ? "완료"
                  : "실패"
              input["status"] = send_list[i].status ? send_list[i].status : "미발송"
            } else {
              input["status"] =
                res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                  ? "완료"
                  : "실패"
            }
            await client.mutate({
              mutation: updateEventRecevier,
              variables: input,
              fetchPolicy: "network-only",
            })
          } else {
            let input = {
              eventId: String(all_email_data[0].event_id),
              genre: send_list[i].genre,
              email: send_list[i].re_email,
              eventEmailId: String(all_email_data[0].send_email_id),
              joaraName: send_list[i].joara_name,
              writerId: String(send_list[i].writerId),
              name: send_list[i].real_name,
              ebook: send_list[i].ebook,
              phone: send_list[i].phone,
              title: send_list[i].title,
              bookCode: send_list[i].book_code,
              rewarod: send_list[i].reward,
              manager: send_list[i].manager,
              access:
                send_list[i].agree_status === "미확인"
                  ? "unidentified"
                  : send_list[i].agree_status === "승인"
                  ? "access"
                  : "reject",
              agree_date: send_list[i].agree_status !== "미확인" ? new Date() : null,
              code: current_code,
              mailType: resultEmail ? "result" : "info",
              list: img_table_list.id,
              lists: img_table_list.data,
            }
            if (resultEmail) {
              input["result_status"] =
                res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                  ? "완료"
                  : "실패"
              input["status"] = send_list[i].status ? send_list[i].status : "미발송"
            } else {
              input["status"] =
                res.data.createSendEmailEvent.sendEmailEvent.send_result.split("_")[1] === "성공"
                  ? "완료"
                  : "실패"
            }
            await client.mutate({
              mutation: createEventRecevier,
              variables: input,
              fetchPolicy: "network-only",
            })
          }
        })
      if (send_list.length - 1 === i) {
        if (all_email_data[4].deleteData && all_email_data[4].deleteData.length > 0) {
          for (let k = 0; k < all_email_data[4].deleteData.length; k++) {
            await client.mutate({
              mutation: updateEventRecevier,
              variables: {
                id: all_email_data[4].deleteData[k].receiverId,
                eventEmailId: null,
              },
              fetchPolicy: "network-only",
            })
          }
        }
        if (all_email_data[4].createData && all_email_data[4].createData.length > 0) {
          for (let k = 0; k < all_email_data[4].createData.length; k++) {
            if (!all_email_data[4].createData[k].receiverId) {
              let img_table_list = {
                id: [],
                data: [],
              }
              if (all_email_data[4].createData.img && all_email_data[4].createData.img.length > 0) {
                for (let o = 0; o < all_email_data[4].createData.img.length; o++) {
                  img_table_list.id.push(all_email_data[4].createData.img[o].file[0].id)
                  img_table_list.data.push({
                    id: all_email_data[4].createData.img[o].file[0].id,
                    url: all_email_data[4].createData.img[o].file[0].url,
                    name: all_email_data[4].createData.img[o].file[0].name,
                    title: all_email_data[4].createData.img[o].file[1].value,
                    link: all_email_data[4].createData.img[o].file[2].value,
                    description: all_email_data[4].createData.img[o].file[3].value,
                    width: all_email_data[4].img[o].file[0].size.value1,
                    height: all_email_data[4].img[o].file[0].size.value2,
                  })
                }
              }
              let input = {
                eventId: String(all_email_data[0].event_id),
                genre: all_email_data[4].createData[k].genre,
                email: all_email_data[4].createData[k].re_email,
                eventEmailId: String(all_email_data[0].send_email_id),
                joaraName: all_email_data[4].createData[k].joara_name,
                writerId: String(all_email_data[4].createData[k].writerId),
                name: all_email_data[4].createData[k].real_name,
                ebook: all_email_data[4].createData[k].ebook,
                phone: all_email_data[4].createData[k].phone,
                title: all_email_data[4].createData[k].title,
                bookCode: all_email_data[4].createData[k].book_code,
                rewarod: all_email_data[4].createData[k].reward,
                manager: all_email_data[4].createData[k].manager,
                access:
                  all_email_data[4].createData[k].agree_status === "미확인"
                    ? "unidentified"
                    : all_email_data[4].createData[k].agree_status === "승인"
                    ? "access"
                    : "reject",
                code: current_code,
                mailType: resultEmail ? "result" : "info",
                list: img_table_list.id,
                lists: img_table_list.data,
              }
              if (resultEmail) {
                input["result_status"] = "미발송"
                input["status"] = "미발송"
              } else {
                input["status"] = "미발송"
              }
              await client.mutate({
                mutation: createEventRecevier,
                variables: input,
                fetchPolicy: "network-only",
              })
            }
          }
        }
        let arr_res = [...all_email_data]

        if (resultEmail) {
          arr_res[6].data.unshift({
            created_at: new Date(),
            email: {
              success: send_list.filter((x) => x.send_result_status === "완료").length,
              fail: send_list.filter((x) => x.send_result_status === "실패").length,
            },
            sms: {
              success: checkbox_value
                ? send_list.filter((x) => x.send_sms_status === "완료").length
                : "",
              fail: checkbox_value
                ? send_list.filter((x) => x.send_sms_status === "실패").length
                : "",
            },
          })
          let img_list = {
            id: [],
            data: [],
          }
          if (arr_res[5].img.length > 0) {
            for (let i = 0; i < arr_res[5].img.length; i++) {
              img_list.id.push(arr_res[5].img[i].file[0].id)
              img_list.data.push({
                id: arr_res[5].img[i].file[0].id,
                url: arr_res[5].img[i].file[0].url,
                name: arr_res[5].img[i].file[0].name,
                title: arr_res[5].img[i].file[1].value,
                link: arr_res[5].img[i].file[2].value,
                description: arr_res[5].img[i].file[3].value,
                width: arr_res[5].img[i].file[0].size.value1,
                height: arr_res[5].img[i].file[0].size.value2,
              })
            }
          }
          await client
            .mutate({
              mutation: updateEventResultEmail,
              variables: {
                id: arr_res[0].send_email_id,
                history: arr_res[6].data,
                allSend: emailType === "all" ? true : false,
                title: arr_res[0].value,
                emailTitle: arr_res[1].value,
                senderEmail: arr_res[2].value,
                senderName: arr_res[3].value,
                content: arr_res[5].value,
                emailImage: img_list.id,
                emailImages: img_list.data,
              },
              fetchPolicy: "network-only",
            })
            .then(async (res) => {
              set_all_email_data(arr_res)
              set_sent_result(true)
            })
        } else {
          arr_res[6].data.unshift({
            created_at: new Date(),
            email: {
              success: send_list.filter((x) => x.send_status === "완료").length,
              fail: send_list.filter((x) => x.send_status === "실패").length,
            },
            sms: {
              success: checkbox_value
                ? send_list.filter((x) => x.send_sms_status === "완료").length
                : "",
              fail: checkbox_value
                ? send_list.filter((x) => x.send_sms_status === "실패").length
                : "",
            },
          })
          await client
            .mutate({
              mutation: updateEventEmail,
              variables: {
                id: arr_res[0].send_email_id,
                history: arr_res[6].data,
                nameType: arr_res[0].name_filter,
                allSend: emailType === "all" ? true : false,
                title: arr_res[0].value,
                senderEmail: arr_res[1].value,
                senderName: arr_res[2].value,
                endDate: arr_res[3].value ? arr_res[3].value : null,
                content: arr_res[5].value,
              },
              fetchPolicy: "network-only",
            })
            .then(async (res) => {
              set_all_email_data(arr_res)
              set_sent_result(true)
            })
        }
        set_spinner(false)
      }
    }
  }

  return emailType === "single" ? (
    step === 0 ? (
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <Grid container sx={{ ml: 4, mt: 4, width: 500 }}>
          <Grid item xs={12}>
            <SuiBox display="flex" alignItems="center" justifyContent="start">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                선택 발송하기
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <RadioGroup
                sx={{ gap: 3, mt: 4, marginLeft: "10px !important" }}
                defaultValue=""
                name="controlled-radio-buttons-group"
                size="small"
              >
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label={
                    <SuiTypography variant="body1" style={{ fontSize: 18 }}>
                      이메일 보내기
                    </SuiTypography>
                  }
                  onChange={(e) => set_send_type(e.target.value)}
                />
                <FormControlLabel
                  value="sms"
                  control={<Radio />}
                  label={
                    <SuiTypography variant="body1" style={{ fontSize: 18 }}>
                      SMS 보내기
                    </SuiTypography>
                  }
                  onChange={(e) => set_send_type(e.target.value)}
                />
              </RadioGroup>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
          <SuiBox display="flex" height="100%" sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#707070",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="primary"
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                mb: 1,
              }}
              onClick={() => {
                nextStep()
              }}
            >
              다음으로
            </SuiButton>
          </SuiBox>
        </Grid>
        <SuiDialog
          openDialog={open_info_dialog}
          closeDialog={() => set_open_info_dialog(false)}
          MainTitle={open_title}
          Content={open_content}
          CanBtnText={"확인"}
        />
      </Dialog>
    ) : send_type === "sms" ? (
      // 선택발송 SMS일경우
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 900 }}>
          <Grid item xs={12}>
            <SuiBox display="flex" alignItems="center" justifyContent="end">
              <IconButton
                edge="start"
                color="inherit"
                size="small"
                onClick={handleClose}
                aria-label="close"
              >
                <MdClose style={{ width: "24px", height: "24px" }} />
              </IconButton>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                SMS 발송하기
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                보낼 메세지를 입력하고 작가들에게 SMS를 발송합니다.
              </SuiTypography>
            </SuiBox>

            <SuiBox sx={{ px: 3 }}>
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  보낼 메세지:
                </SuiTypography>
                <SuiTypography
                  style={{
                    fontSize: "15px",
                    color: "#0C65FF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  sx={{ ml: "auto" }}
                  onClick={() =>
                    set_SMS_content(
                      resultEmail
                        ? "[이벤트 결과] 작가님, 안녕하세요! 조아라입니다. 이벤트 결과 안내드리고자 메일 보내드렸습니다. 확인 부탁드립니다."
                        : "[이벤트 협의] 작가님, 안녕하세요! 조아라입니다. 이벤트 협의 요청드릴 사항이 있어 메일 보내드렸습니다. 확인 부탁드립니다."
                    )
                  }
                >
                  불러오기
                </SuiTypography>
              </SuiBox>
              <SuiBox sx={{ my: 2 }}>
                {sent_result === false ? (
                  <TextField
                    multiline
                    rows={2}
                    sx={{
                      width: 840,
                      height: 60,
                      "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                        padding: "0.5rem 0.75rem",
                      },
                    }}
                    variant="outlined"
                    placeholder={
                      resultEmail
                        ? "[이벤트 결과] 작가님, 안녕하세요! 조아라입니다. 이벤트 결과 안내드리고자 메일 보내드렸습니다. 확인 부탁드립니다."
                        : "[이벤트 협의] 작가님, 안녕하세요! 조아라입니다. 이벤트 협의 요청드릴 사항이 있어 메일 보내드렸습니다. 확인 부탁드립니다."
                    }
                    value={SMS_content}
                    onChange={(e) => {
                      set_SMS_content(e.target.value)
                    }}
                  />
                ) : (
                  <TextField
                    multiline
                    rows={2}
                    sx={{
                      width: 840,
                      height: 60,
                      "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                        padding: "0.5rem 0.75rem",
                      },
                    }}
                    variant="outlined"
                    value={SMS_content}
                    onChange={(e) => {
                      set_SMS_content(e.target.value)
                    }}
                    readOnly
                  />
                )}
              </SuiBox>
            </SuiBox>
            <SuiBox sx={{ px: 3 }}>
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  발송 상태:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  sx={{ textAlign: "left", color: "#707070", ml: 0.5 }}
                >
                  (
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  전체:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="success"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "완료").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 미발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "미발송").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송실패:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="error"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "실패").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  )
                </SuiTypography>
              </SuiBox>

              <TableContainer
                sx={{
                  borderTop: "1px solid #F1F1F5",
                  borderRadius: 0,
                  boxShadow: 0,
                  marginRight: "40px",
                  mt: 2,
                  minHeight: 400,
                  maxHeight: 400,
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 700 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead2 />
                  <TableBody>
                    {table_list.map((data, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                          key={index + "p7"}
                        >
                          <TableCell align="center" className="td-send-Setting td-fontSetting">
                            {data.real_name} ({data.ebook})
                          </TableCell>
                          <TableCell align="center" className="td-send-Setting td-fontSetting">
                            {data.phone.indexOf("-") > -1
                              ? data.phone
                              : data.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}
                          </TableCell>
                          <TableCell align="center" className="td-send-Setting td-fontSetting">
                            {data.created_at
                              ? dayjs(data.created_at).format("YY/MM/DD HH:mm:ss")
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="td-send-Setting td-fontSetting"
                            sx={{
                              color:
                                data.send_sms_status === "완료"
                                  ? "#42d089 !important"
                                  : data.send_sms_status === "실패"
                                  ? "red !important"
                                  : "",
                            }}
                          >
                            {data.send_sms_status === "미발송" ? "대기" : data.send_sms_status}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
            {sent_result === false ? (
              <>
                <SuiBox display="flex" height="100%" alignItems="end" sx={{ pr: 3, mb: 3 }}>
                  <SuiButton
                    color="subTitle"
                    variant="text"
                    sx={{
                      width: 100,
                      pl: 0,
                      height: 40,
                      mr: 2,
                      fontSize: 14,
                    }}
                    onClick={() => set_step(0)}
                  >
                    뒤로
                  </SuiButton>
                </SuiBox>
                <SuiBox display="flex" height="100%" alignItems="end" sx={{ pl: 2, pr: 3, mb: 3 }}>
                  <SuiButton
                    disabled={!SMS_content}
                    color="primary"
                    sx={{
                      ml: "auto",
                      mr: 1,
                      px: 2,
                      py: 0.5,
                      height: 40,
                      minHeight: 40,
                      width: 100,
                      fontSize: 14,
                      mb: 1,
                    }}
                    onClick={smsHandleSave}
                  >
                    {spinner ? <img height={24} width={24} src={gifImg} /> : "발송하기"}
                  </SuiButton>
                </SuiBox>
                <SuiDialog
                  openDialog={open_info_dialog}
                  closeDialog={() => set_open_info_dialog(false)}
                  MainTitle={open_title}
                  Content={open_content}
                  CanBtnText={"확인"}
                />
              </>
            ) : (
              <>
                <SuiBox sx={{ pl: 2 }}>
                  <SuiBox display="flex" alignItems="end">
                    <SuiTypography
                      style={{ fontSize: "15px", fontWeight: "bold", color: "#0C65FF" }}
                    >
                      발송 완료 ({dayjs(new Date()).add(5, "s").format("YY.MM.DD HH:mm:ss")})
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="end">
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                      문자 (발송: {table_list.filter((x) => x.send_sms_status === "완료").length}
                      건, 실패:{table_list.filter((x) => x.send_sms_status === "실패").length}건)
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiBox display="flex" height="100%" alignItems="center" sx={{ pl: 2, pr: 3 }}>
                  <SuiButton
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#0C65FF !important",
                      ml: "auto",
                      mr: 1,
                      px: 2,
                      py: 0.5,
                      height: 40,
                      minHeight: 40,
                      width: 100,
                      fontSize: 14,
                      mb: 1,
                    }}
                    onClick={handleSaveClose}
                  >
                    확인
                  </SuiButton>
                </SuiBox>
              </>
            )}
          </Grid>
        </Grid>
      </Dialog>
    ) : (
      // 선택발송 email일 경우
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 900 }}>
          <Grid item xs={12}>
            <SuiBox display="flex" alignItems="center" justifyContent="end">
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <MdClose />
              </IconButton>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                이메일 발송하기
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 4 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                {resultEmail
                  ? "작가들에게 이벤트 결과 보고 메일을 발송합니다."
                  : "작가들에게 이벤트 승인 요청 메일을 발송합니다."}
              </SuiTypography>
            </SuiBox>

            <SuiBox sx={{ px: 3 }}>
              {resultEmail ? (
                <SuiBox display="flex" alignItems="center">
                  <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                    발송 상태:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    sx={{ textAlign: "left", color: "#707070", ml: 0.5 }}
                  >
                    (
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    전체:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 발송:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="success"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_result_status === "완료").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 미발송:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_result_status === "미발송").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 발송실패:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="error"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_result_status === "실패").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    )
                  </SuiTypography>
                </SuiBox>
              ) : (
                <SuiBox display="flex" alignItems="center">
                  <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                    발송 상태:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    sx={{ textAlign: "left", color: "#707070", ml: 0.5 }}
                  >
                    (
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    전체:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 발송:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="success"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_status === "완료").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 미발송:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_status === "미발송").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    / 발송실패:
                  </SuiTypography>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    color="error"
                    sx={{ textAlign: "left", ml: 1 }}
                  >
                    {table_list.filter((x) => x.send_status === "실패").length}명
                  </SuiTypography>
                  <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                    )
                  </SuiTypography>
                </SuiBox>
              )}
              <TableContainer
                sx={{
                  borderTop: "1px solid #F1F1F5",
                  borderRadius: 0,
                  boxShadow: 0,
                  marginRight: "40px",
                  mt: 2,
                  minHeight: 480,
                  maxHeight: 480,
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 700 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {table_list.map((data, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          sx={{
                            cursor: "pointer",
                            height: 40,
                            maxHeight: 40,
                          }}
                          key={index + "p7"}
                        >
                          <TableCell align="center" className="td-send-Setting td-fontSetting">
                            {data.real_name} ({data.re_email})
                          </TableCell>
                          <TableCell align="center" className="td-send-Setting td-fontSetting">
                            {`[조아라] <${data.title}> ${all_email_data[0].value}`}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="td-send-Setting td-fontSetting"
                            // sx={{
                            //   borderRight: "1px solid #F1F1F5",
                            // }}
                          >
                            {data.created_at
                              ? dayjs(data.created_at).format("YY/MM/DD HH:mm:ss")
                              : "-"}
                          </TableCell>
                          {resultEmail ? (
                            <TableCell
                              align="center"
                              className="td-send-Setting td-fontSetting"
                              sx={{
                                color:
                                  data.send_result_status === "완료"
                                    ? "#00C160 !important"
                                    : data.send_result_status === "실패"
                                    ? "red !important"
                                    : "",
                              }}
                            >
                              {data.send_result_status === "미발송"
                                ? "대기"
                                : data.send_result_status}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              className="td-send-Setting td-fontSetting"
                              sx={{
                                color:
                                  data.send_status === "완료"
                                    ? "#42d089 !important"
                                    : data.send_status === "실패"
                                    ? "red !important"
                                    : "",
                              }}
                            >
                              {data.send_status === "미발송" ? "대기" : data.send_status}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
            {sent_result === false ? (
              <>
                <SuiBox sx={{ pl: 2 }}>
                  <SuiBox display="flex" alignItems="center">
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                      SMS 동시 발송:
                    </SuiTypography>
                    <Checkbox
                      checked={checkbox_value}
                      onChange={(e) => {
                        set_checkbox_value(e.target.checked)
                      }}
                      color="primary"
                      label=""
                    />
                  </SuiBox>
                  {checkbox_value ? (
                    <SuiBox
                      sx={{
                        backgroundColor: "#faf8ff",
                      }}
                    >
                      <TextField
                        multiline
                        rows={2}
                        sx={{
                          width: 400,
                          height: 60,
                          "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                            padding: "0.5rem 0.75rem",
                          },
                        }}
                        variant="outlined"
                        value={SMS_content}
                        onChange={(e) => set_SMS_content(e.target.value)}
                      />
                    </SuiBox>
                  ) : (
                    <SuiBox
                      sx={
                        {
                          // border: "1px solid #dddddd",
                          // p: 1,
                          // borderRadius: "10px",
                          // backgroundColor: "#faf8ff",
                          // opacity: 0.3,
                        }
                      }
                    >
                      <TextField
                        multiline
                        rows={2}
                        sx={{
                          width: 400,
                          height: 60,
                          "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                            padding: "0.5rem 0.75rem",
                          },
                          "> div": {
                            opacity: 0.5,
                          },
                        }}
                        variant="outlined"
                        value={SMS_content}
                        readOnly
                      />
                    </SuiBox>
                  )}
                </SuiBox>
                <SuiBox
                  display="flex"
                  height="100%"
                  alignItems="end"
                  sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}
                >
                  <SuiButton
                    color="primary"
                    sx={{
                      ml: "auto",
                      mr: 1,
                      px: 2,
                      py: 0.5,
                      height: 40,
                      minHeight: 40,
                      width: 100,
                      fontSize: 14,
                      mb: 1,
                    }}
                    onClick={handleSave}
                  >
                    {spinner ? <img height={24} width={24} src={gifImg} /> : "발송하기"}
                  </SuiButton>
                </SuiBox>
              </>
            ) : (
              <>
                <SuiBox sx={{ pl: 2 }}>
                  <SuiBox display="flex" alignItems="end">
                    <SuiTypography
                      style={{ fontSize: "15px", fontWeight: "bold", color: "#0C65FF" }}
                    >
                      발송 완료 ({dayjs(new Date()).add(5, "s").format("YY.MM.DD HH:mm:ss")})
                    </SuiTypography>
                  </SuiBox>
                  {resultEmail ? (
                    <SuiBox display="flex" alignItems="end">
                      <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                        메일 (발송:
                        {table_list.filter((x) => x.send_result_status === "완료").length}
                        건, 실패:{table_list.filter((x) => x.send_result_status === "실패").length}
                        건)
                      </SuiTypography>
                      <span style={{ lineHeight: "24px", margin: "0px 2px" }}>·</span>
                      <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                        문자 (발송: {table_list.filter((x) => x.send_sms_status === "완료").length}
                        건, 실패:{table_list.filter((x) => x.send_sms_status === "실패").length}건)
                      </SuiTypography>
                    </SuiBox>
                  ) : (
                    <SuiBox display="flex" alignItems="end">
                      <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                        메일 (발송: {table_list.filter((x) => x.send_status === "완료").length}
                        건, 실패:{table_list.filter((x) => x.send_status === "실패").length}건)
                      </SuiTypography>
                      <span style={{ lineHeight: "24px", margin: "0px 2px" }}>·</span>
                      <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                        문자 (발송: {table_list.filter((x) => x.send_sms_status === "완료").length}
                        건, 실패:{table_list.filter((x) => x.send_sms_status === "실패").length}건)
                      </SuiTypography>
                    </SuiBox>
                  )}
                </SuiBox>
                <SuiBox display="flex" height="100%" alignItems="center" sx={{ pl: 2, pr: 3 }}>
                  <SuiButton
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#0C65FF !important",
                      ml: "auto",
                      mr: 1,
                      px: 2,
                      py: 0.5,
                      height: 40,
                      minHeight: 40,
                      width: 100,
                      fontSize: 14,
                      mb: 1,
                    }}
                    onClick={handleSaveClose}
                  >
                    확인
                  </SuiButton>
                </SuiBox>
              </>
            )}
          </Grid>
        </Grid>
        <SuiDialog
          openDialog={open_info_dialog}
          closeDialog={() => set_open_info_dialog(false)}
          MainTitle={open_title}
          Content={open_content}
          CanBtnText={"확인"}
        />
      </Dialog>
    )
  ) : (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 900 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              이메일 발송하기
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              {resultEmail
                ? "작가들에게 이벤트 결과 보고 메일을 발송합니다."
                : "작가들에게 이벤트 승인 요청 메일을 발송합니다."}
            </SuiTypography>
          </SuiBox>

          <SuiBox sx={{ px: 3 }}>
            {resultEmail ? (
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  발송 상태:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  sx={{ textAlign: "left", color: "#707070", ml: 0.5 }}
                >
                  (
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  전체:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="success"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_result_status === "완료").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 미발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_result_status === "미발송").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송실패:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="error"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_result_status === "실패").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  )
                </SuiTypography>
              </SuiBox>
            ) : (
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  발송 상태:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  sx={{ textAlign: "left", color: "#707070", ml: 0.5 }}
                >
                  (
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  전체:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="success"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "완료").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 미발송:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "미발송").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  / 발송실패:
                </SuiTypography>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="error"
                  sx={{ textAlign: "left", ml: 1 }}
                >
                  {table_list.filter((x) => x.send_status === "실패").length}명
                </SuiTypography>
                <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#707070" }}>
                  )
                </SuiTypography>
              </SuiBox>
            )}

            <TableContainer
              sx={{
                borderTop: "1px solid #F1F1F5",
                borderRadius: 0,
                boxShadow: 0,
                marginRight: "40px",
                mt: 2,
                minHeight: 480,
                maxHeight: 480,
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead />
                <TableBody>
                  {table_list.map((data, index) => {
                    return (
                      <TableRow hover role="checkbox" sx={{ cursor: "pointer" }} key={index + "p7"}>
                        <TableCell align="center" className="td-send-Setting td-fontSetting">
                          {data.real_name} ({data.re_email})
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {`[조아라] <${data.title}> ${all_email_data[0].value}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="td-send-Setting td-fontSetting"
                          sx={{
                            borderRight: "1px solid #F1F1F5",
                          }}
                        >
                          {data.created_at
                            ? dayjs(data.created_at).format("YY/MM/DD HH:mm:ss")
                            : "-"}
                        </TableCell>
                        {resultEmail ? (
                          <TableCell
                            align="center"
                            className="td-send-Setting td-fontSetting"
                            sx={{
                              color:
                                data.send_result_status === "완료"
                                  ? "#42d089 !important"
                                  : data.send_result_status === "실패"
                                  ? "red !important"
                                  : "",
                            }}
                          >
                            {data.send_result_status === "미발송"
                              ? "대기"
                              : data.send_result_status}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            className="td-send-Setting td-fontSetting"
                            sx={{
                              color:
                                data.send_status === "완료"
                                  ? "#42d089 !important"
                                  : data.send_status === "실패"
                                  ? "red !important"
                                  : "",
                            }}
                          >
                            {data.send_status === "미발송" ? "대기" : data.send_status}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </SuiBox>
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
          {sent_result === false ? (
            <>
              <SuiBox sx={{ pl: 2 }}>
                <SuiBox display="flex" alignItems="center">
                  <SuiTypography style={{ fontSize: "15px", fontWeight: "bold" }}>
                    SMS 동시 발송:
                  </SuiTypography>
                  <Checkbox
                    checked={checkbox_value}
                    onChange={(e) => {
                      set_checkbox_value(e.target.checked)
                    }}
                    color="primary"
                    label=""
                  />
                </SuiBox>
                {checkbox_value ? (
                  <SuiBox
                    sx={
                      {
                        // border: "1px solid #dddddd",
                        // p: 1,
                        // borderRadius: "10px",
                        // backgroundColor: "#faf8ff",
                      }
                    }
                  >
                    <TextField
                      multiline
                      rows={2}
                      sx={{
                        width: 400,
                        height: 60,
                        "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                          padding: "0.5rem 0.75rem",
                        },
                      }}
                      variant="outlined"
                      value={SMS_content}
                      onChange={(e) => set_SMS_content(e.target.value)}
                    />
                  </SuiBox>
                ) : (
                  <SuiBox
                    sx={
                      {
                        // border: "1px solid #dddddd",
                        // p: 1,
                        // borderRadius: "10px",
                        // backgroundColor: "#faf8ff",
                        // opacity: 0.3,
                      }
                    }
                  >
                    <TextField
                      multiline
                      rows={2}
                      sx={{
                        width: 400,
                        height: 60,
                        "> .css-rx7853-MuiInputBase-root-MuiOutlinedInput-root": {
                          padding: "0.5rem 0.75rem",
                        },
                      }}
                      variant="outlined"
                      value={SMS_content}
                      readOnly
                    />
                  </SuiBox>
                )}
              </SuiBox>
              <SuiBox
                display="flex"
                height="100%"
                alignItems="end"
                sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}
              >
                <SuiButton
                  color="primary"
                  sx={{
                    ml: "auto",
                    mr: 1,
                    px: 2,
                    py: 0.5,
                    height: 40,
                    minHeight: 40,
                    width: 100,
                    fontSize: 14,
                    mb: 1,
                  }}
                  onClick={handleSave}
                >
                  {spinner ? <img height={24} width={24} src={gifImg} /> : "발송하기"}
                </SuiButton>
              </SuiBox>
            </>
          ) : (
            <>
              <SuiBox sx={{ pl: 2 }}>
                <SuiBox display="flex" alignItems="end">
                  <SuiTypography style={{ fontSize: "15px", fontWeight: "bold", color: "#0C65FF" }}>
                    발송 완료 ({dayjs(new Date()).add(5, "s").format("YY.MM.DD HH:mm:ss")})
                  </SuiTypography>
                </SuiBox>
                {resultEmail ? (
                  <SuiBox display="flex" alignItems="end">
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                      메일 (발송: {table_list.filter((x) => x.send_result_status === "완료").length}
                      건, 실패:{table_list.filter((x) => x.send_result_status === "실패").length}건)
                    </SuiTypography>
                    <span style={{ lineHeight: "24px", margin: "0px 2px" }}>·</span>
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                      문자 (발송: {table_list.filter((x) => x.send_sms_status === "완료").length}
                      건, 실패:{table_list.filter((x) => x.send_sms_status === "실패").length}건)
                    </SuiTypography>
                  </SuiBox>
                ) : (
                  <SuiBox display="flex" alignItems="end">
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                      메일 (발송: {table_list.filter((x) => x.send_status === "완료").length}
                      건, 실패:{table_list.filter((x) => x.send_status === "실패").length}건)
                    </SuiTypography>
                    <span style={{ lineHeight: "24px", margin: "0px 2px" }}>·</span>
                    <SuiTypography style={{ fontSize: "15px", fontWeight: "lighter" }}>
                      문자 (발송: {table_list.filter((x) => x.send_sms_status === "완료").length}
                      건, 실패:{table_list.filter((x) => x.send_sms_status === "실패").length}건)
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
              <SuiBox display="flex" height="100%" alignItems="center" sx={{ pl: 2, pr: 3 }}>
                <SuiButton
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#0C65FF !important",
                    ml: "auto",
                    mr: 1,
                    px: 2,
                    py: 0.5,
                    height: 40,
                    minHeight: 40,
                    width: 100,
                    fontSize: 14,
                    mb: 1,
                  }}
                  onClick={handleSaveClose}
                >
                  확인
                </SuiButton>
              </SuiBox>
            </>
          )}
        </Grid>
      </Grid>
      <SuiDialog
        openDialog={open_info_dialog}
        closeDialog={() => set_open_info_dialog(false)}
        MainTitle={open_title}
        Content={open_content}
        CanBtnText={"확인"}
      />
    </Dialog>
  )
}
sendEmail.defaultProps = {
  openDialog: false,
}

sendEmail.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  emailContent: PropTypes.array,
  receiver: PropTypes.func,
  emailType: PropTypes.string,
  resultEmail: PropTypes.string,
  eventData: PropTypes.object,
}
export default sendEmail
