import { useState, useEffect } from "react"

// react-router-dom components
import axios from "axios"
import { useNavigate } from "react-router-dom"

// @mui material components
import Checkbox from "@mui/material/Checkbox"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout"

import { useMutation } from "@apollo/client"
import { login, createLoginLog } from "apollo/mutation"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { useDispatch } from "react-redux"
import { loading_Apply } from "../../../reducers/store"

const SignIn = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loading_Apply(false))
  }, [])

  const navigate = useNavigate()
  const [username, setUsername] = useState(
    localStorage.getItem("joara_id") ? localStorage.getItem("joara_id") : ""
  )
  const [password, setPassword] = useState("")
  const [team, setTeam] = useState(
    localStorage.getItem("joara_team") ? localStorage.getItem("joara_team") : ""
  )

  const [username_error, setUsername_error] = useState(false)
  const [password_error, setPassword_error] = useState(false)
  const [team_error, setTeam_error] = useState(false)
  const [username_error_message, setUsername_error_message] = useState("")
  const [password_error_message, setPassword_error_message] = useState("")
  const [team_error_message, setTeam_error_message] = useState("")
  const [openSelect1, setopenSelect1] = useState(false)
  const handleTeamChange = (event) => {
    setTeam(event.target.value)
  }
  const [rememberMe, setRememberMe] = useState(localStorage.getItem("joara_id") ? true : false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe)
  const [createLoginLogActive] = useMutation(createLoginLog, {
    onError(err) {},
  })
  const [loginActive, { data }] = useMutation(login, {
    variables: {
      identifier: username,
      password: password,
    },
    onError(err) {
      dispatch(loading_Apply(false))
      setUsername_error(true)
      setUsername_error_message("*이메일이 올바르지 않습니다.")
      setPassword_error(true)
      setPassword_error_message("*비밀번호가 올바르지 않습니다.")
    },
  })
  useEffect(() => {
    if (data) {
      if (rememberMe) {
        localStorage.setItem("joara_id", username)
        localStorage.setItem("joara_team", team)
      } else {
        localStorage.removeItem("joara_id")
        localStorage.removeItem("joara_team")
      }
      dispatch(loading_Apply(false))
      sessionStorage.setItem("joara_token", data.login.jwt)
      navigate("/MyPage")
    }
  }, [data])
  const teamList = [
    "경영지원실",
    "콘텐츠사업본부",
    "전략기획팀",
    "플랫폼사업팀",
    "IP사업팀",
    "인터랙션디자인팀",
    "서비스개발팀",
    "서비스운영팀",
  ]
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const handleUsername = (e) => {
    setUsername(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e) {
      if (e.key === "Enter") {
        loginCheck()
      }
    }
  }
  const loginCheck = () => {
    setUsername_error(false)
    setUsername_error_message("")
    setPassword_error(false)
    setPassword_error_message("")
    setTeam_error(false)
    setTeam_error_message("")
    // if (team == "") {
    //   setTeam_error(true)
    //   return setTeam_error_message("*부서를 선택해 주세요.")
    // } else
    if (username == "") {
      setUsername_error(true)
      return setUsername_error_message("*이메일을 입력해주세요.")
    } else if (password == "") {
      setPassword_error(true)
      return setPassword_error_message("*비밀번호를 입력해주세요.")
    } else {
      dispatch(loading_Apply(true))
      sessionStorage.removeItem("joara_token")
      axios.get(process.env.REACT_APP_IP_URL).then((res) => {
        createLoginLogActive({
          variables: {
            username: username,
            useremail: username,
            userip: res.data.ip,
          },
        })
      })
      loginActive()
    }
  }
  return (
    <CoverLayout p={3} path="/">
      <SuiBox component="form" role="form">
        <SuiBox mb={5}>
          <SuiBox display="flex" justifyContent="center" mb={2}>
            <SuiTypography
              component="label"
              color="dark"
              fontWeight="bold"
              className="letter-0-8px"
            >
              로그인
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center">
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              className="letter-0-8px"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              조아라 IP 매니저에 접속합니다.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {/* <SuiBox mb={2}>
          <FormControl
            fullWidth
            error={team_error}
            className={team === "" ? "setting-selectbox select-none-active" : "setting-selectbox"}
            onClick={() => setopenSelect1(!openSelect1)}
          >
            <InputLabel>부서 선택</InputLabel>
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => setopenSelect1(!openSelect1)}
                />
              )}
              open={openSelect1}
              value={team}
              label="부서선택"
              onChange={handleTeamChange}
              sx={{}}
            >
              {teamListSelects}
            </Select>
            <FormHelperText className="component-error-text">{team_error_message}</FormHelperText>
          </FormControl>
        </SuiBox> */}
        <SuiBox mb={2}>
          <SuiBox ml={0.5}>
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              회원번호 또는 이메일 :
            </SuiTypography>
          </SuiBox>
          <SuiInput
            error={username_error}
            helperText={username_error_message}
            type="email"
            placeholder=""
            value={username}
            onChange={handleUsername}
            onKeyPress={handleKeyPress}
          />
        </SuiBox>
        <SuiBox mb={3}>
          <SuiBox ml={0.5}>
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              비밀번호 :
            </SuiTypography>
          </SuiBox>
          <SuiInput
            error={password_error}
            helperText={password_error_message}
            type="password"
            placeholder=""
            value={password}
            onChange={handlePassword}
            onKeyPress={handleKeyPress}
          />
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          sx={{
            position: "relative",
            top: 0,
            left: "-12px",
          }}
        >
          <Checkbox
            // sx={{ pl: 0 }}
            checked={rememberMe}
            onChange={handleSetRememberMe}
            color="primary"
          />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
            className="letter-0-8px"
            color="secondary"
          >
            &nbsp;&nbsp;회원번호 또는 이메일 저장
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={3} mb={1}>
          <SuiButton
            color="primary"
            sx={{
              fontSize: 16,
            }}
            fullWidth
            onClick={() => loginCheck()}
          >
            로그인
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  )
}

export default SignIn
