import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiDialog from "components/SuiDialog"

import { MdClose } from "react-icons/md"

// API
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { userContract, users } from "apollo/query"
import { createRequest, createAlert, createSendEmail } from "apollo/mutation"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function WithdrawlDialog({ openDialog, closeDialog, setWithdrawlDate }) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const { myData } = useSelector((state) => state.store)

  const [infos, setinfos] = useState("")
  const [open, setOpen] = useState(openDialog)
  const [withdrawlReason, setwithdrawlReason] = useState("")
  const [usersDatas, setusersDatas] = useState([])

  useEffect(() => {
    setOpen(openDialog)
    setwithdrawlReason("")
    if (openDialog) {
      client
        .query({
          query: users,
          variables: {
            role: 1,
            status: "근무",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          setusersDatas(res.data.users)
        })
    }
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
  }

  const [userContractActive] = useLazyQuery(userContract, {
    fetchPolicy: "network-only",
  })

  const [createRequestActive] = useMutation(createRequest, {
    fetchPolicy: "no-cache",
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })

  const check_withdrawlAble = () => {
    let newWithdrawSet = {
      dialog: false,
      title: "",
      content: "",
      CanBtnText: "완료",
    }

    dispatch(loading_Apply(true))
    userContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: myData.id,
      },
    }).then((dataContract) => {
      if (
        dataContract.data.user.contract_documents.length === 0 &&
        dataContract.data.user.company_contracts.length === 0
      ) {
        newWithdrawSet.dialog = true
        newWithdrawSet.title = "탈퇴 신청 완료"
        newWithdrawSet.content = `탈퇴 신청(${dayjs(new Date()).format(
          "YY.MM.DD"
        )})이 완료되었습니다. 관리자 승인시 탈퇴 처리됩니다.`
        newWithdrawSet.CanBtnText = "확인"

        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          suggestRole: "",
          requestExplain: withdrawlReason,
        }
        createRequestActive({
          variables: {
            status: "탈퇴신청",
            type: "withdrawl",
            content: request,
            users_permissions_user: myData.id,
          },
        }).then(() => {
          myData.requests.push({
            status: "탈퇴신청",
            created_at: new Date(),
            read: false,
          })
          setWithdrawlDate(`탈퇴 신청 심사중 ${dayjs(new Date()).format("(YYYY.MM.DD HH:mm)")}`)
          usersDatas.forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[0].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "회원 탈퇴 신청",
                    type: "witihdrawl",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      title: myData.name,
                      suggestRole: "",
                      declining: withdrawlReason,
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "4",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      suggestRole: "",
                      declining: withdrawlReason,
                    },
                  },
                })
              }
            }
          })
        })
      } else {
        newWithdrawSet.dialog = true
        newWithdrawSet.title = "탈퇴 신청 불가"
        newWithdrawSet.content =
          "담당 계약 건이 있어 탈퇴 신청을 할 수 없습니다. 먼저 계약 담당자 변경을 진행해주세요."
        newWithdrawSet.CanBtnText = "확인"
      }
      setwithdralData(newWithdrawSet)
      dispatch(loading_Apply(false))
    })
  }
  const handleInput = () => (e) => {
    setwithdrawlReason(e.target.value)
  }

  const withdralDataSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "완료",
  }

  const [withdralData, setwithdralData] = useState(withdralDataSet)
  const close_registerd = () => {
    closeDialog()
    setwithdralData({
      dialog: false,
      title: "",
      content: "",
      CanBtnText: "",
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 400 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                탈퇴 신청
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                신청 사유를 작성하고, 탈퇴 신청을 합니다
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, my: 5 }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              탈퇴 사유:
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              multiline
              disabled={false}
              rows={4}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              placeholder="300자 이내"
              value={withdrawlReason}
              onChange={(e) => {
                if (e.target.value.length <= 300) setwithdrawlReason(e.target.value)
              }}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              variant="outlined"
              color="subTitle"
              onClick={() => handleClose()}
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                border: 1,
                borderColor: "#707070",
              }}
            >
              취소
            </SuiButton>
            <SuiButton
              color="error"
              onClick={() => check_withdrawlAble()}
              sx={{
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              탈퇴 신청
            </SuiButton>
          </SuiBox>
        </Grid>
        <SuiDialog
          openDialog={withdralData.dialog}
          closeDialog={() => close_registerd()}
          MainTitle={withdralData.title}
          Content={withdralData.content}
          CanBtnText={withdralData.CanBtnText}
        />
      </Grid>
    </Dialog>
  )
}

WithdrawlDialog.defaultProps = {
  openDialog: false,
}

WithdrawlDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  setWithdrawlDate: PropTypes.func,
}
export default WithdrawlDialog
