import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import ContractInfosDialog from "../ContractInfosDialog"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

import { MdOutlineDoubleArrow } from "react-icons/md"

// API
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { updateWriter, createChangeRequest, createSendEmail, createAlert } from "apollo/mutation"
import { users_list, requests, users, me, settings } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, myData_Apply } from "../../../../reducers/store"
import axios from "axios"

function changeData_card({ usersDatas }) {
  const { myData } = useSelector((state) => state.store)
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [openAccess_Dialog, setopenAccess_Dialog] = useState(false)
  const [workList2, setworkList2] = useState([])
  const [ContractInfosDialogOpenBool, setContractInfosDialogOpenBool] = useState(false)
  const [ContractInfosData, setContractInfosData] = useState([])
  const [changeCount, setchangeCount] = useState(0)
  const [changeList, setchangeList] = useState({
    id: null,
    department: "경영지원실",
    name: "",
    reason: "퇴사로 인한 계약 이관",
  })

  const usersActive = (val) => {
    client
      .query({
        query: users_list,
        variables: {
          sort: "created_at:desc",
          start: 0,
          department: val,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        dispatch(loading_Apply(false))
        setworkList2(
          res.data.users
            .filter((y) => y.id !== myData.id)
            .map((x) => x.name + " " + x.position + " " + x.id)
        )
      })
  }
  const [meQuery] = useLazyQuery(me, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let _me = data.me
      for (let i = 0; i < _me.writers.length; i++) {
        let _contratcts = _me.contracts.filter((x) => x.writer === _me.writers[i].id)
        let _contract_documents
        for (let j = 0; j < _contratcts.length; j++) {
          _contract_documents = _me.contract_documents.filter(
            (x) => x.contract === _contratcts[j].id
          )
          _contratcts[j]["contract_documents"] = _contract_documents
          for (let k = 0; k < _contratcts[j]["contract_documents"].length; k++) {
            _contratcts[j]["contract_documents"][k].users_permissions_user = {
              id: _contratcts[j]["contract_documents"][k].users_permissions_user,
            }
          }
        }
        _me.writers[i]["contracts"] = _contratcts
      }

      for (let i = 0; i < _me.companies.length; i++) {
        let _company_contracts = _me.company_contracts.filter(
          (x) => x.company === _me.companies[i].id
        )
        _me.companies[i]["company_contracts"] = _company_contracts
        for (let j = 0; j < _me.companies[i]["company_contracts"].length; j++) {
          _me.companies[i]["company_contracts"][j].users_permissions_user = {
            id: _me.companies[i]["company_contracts"][j].users_permissions_user,
          }
        }
      }
      for (let i = 0; i < _me.outsourcings.length; i++) {
        let _outsourcing_contracts = _me.outsourcing_contracts.filter(
          (x) => x.outsourcing === _me.outsourcings[i].id
        )
        _me.outsourcings[i]["outsourcing_contracts"] = _outsourcing_contracts
        for (let j = 0; j < _me.outsourcings[i]["outsourcing_contracts"].length; j++) {
          _me.outsourcings[i]["outsourcing_contracts"][j].users_permissions_user = {
            id: _me.outsourcings[i]["outsourcing_contracts"][j].users_permissions_user,
          }
        }
      }
      dispatch(loading_Apply(false))
      dispatch(myData_Apply(_me))
    },
  })
  const {} = useQuery(me, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let _me = data.me
      for (let i = 0; i < _me.writers.length; i++) {
        let _contratcts = _me.contracts.filter((x) => x.writer === _me.writers[i].id)
        let _contract_documents
        for (let j = 0; j < _contratcts.length; j++) {
          _contract_documents = _me.contract_documents.filter(
            (x) => x.contract === _contratcts[j].id
          )
          _contratcts[j]["contract_documents"] = _contract_documents
          for (let k = 0; k < _contratcts[j]["contract_documents"].length; k++) {
            _contratcts[j]["contract_documents"][k].users_permissions_user = {
              id: _contratcts[j]["contract_documents"][k].users_permissions_user,
            }
          }
        }
        _me.writers[i]["contracts"] = _contratcts
      }

      for (let i = 0; i < _me.companies.length; i++) {
        let _company_contracts = _me.company_contracts.filter(
          (x) => x.company === _me.companies[i].id
        )
        _me.companies[i]["company_contracts"] = _company_contracts
        for (let j = 0; j < _me.companies[i]["company_contracts"].length; j++) {
          _me.companies[i]["company_contracts"][j].users_permissions_user = {
            id: _me.companies[i]["company_contracts"][j].users_permissions_user,
          }
        }
      }
      for (let i = 0; i < _me.outsourcings.length; i++) {
        let _outsourcing_contracts = _me.outsourcing_contracts.filter(
          (x) => x.outsourcing === _me.outsourcings[i].id
        )
        _me.outsourcings[i]["outsourcing_contracts"] = _outsourcing_contracts
        for (let j = 0; j < _me.outsourcings[i]["outsourcing_contracts"].length; j++) {
          _me.outsourcings[i]["outsourcing_contracts"][j].users_permissions_user = {
            id: _me.outsourcings[i]["outsourcing_contracts"][j].users_permissions_user,
          }
        }
      }

      dispatch(myData_Apply(_me))
    },
  })
  useEffect(() => {
    let array = []
    myData.companies
      .map((x) => x.company_contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.users_permissions_user.id === myData.id) {
              array.push(paper.contractPaper)
            }
          })
      })
    myData.outsourcings
      .map((x) => x.outsourcing_contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.users_permissions_user.id === myData.id) {
              array.push(paper.contractPaper)
            }
          })
      })
    myData.writers
      .filter((y) => y.contracts && y.contracts.length > 0)
      .map((x) => x.contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.contract_documents.length > 0) {
              paper.contract_documents.forEach((contract) => {
                if (contract.users_permissions_user) {
                  if (contract.users_permissions_user.id === myData.id) {
                    array.push(contract.contractPaper)
                  }
                }
              })
            }
          })
      })
    dispatch(loading_Apply(true))
    settings_Call()
    setchangeCount(array.length)
  }, [myData])
  const infoDialog = () => {
    setContractInfosDialogOpenBool(true)
    let array = []
    myData.companies
      .map((x) => x.company_contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.users_permissions_user.id === myData.id) {
              array.push(paper.contractPaper)
            }
          })
      })
    myData.outsourcings
      .map((x) => x.outsourcing_contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.users_permissions_user.id === myData.id) {
              array.push(paper.contractPaper)
            }
          })
      })
    myData.writers
      .filter((y) => y.contracts.length > 0)
      .map((x) => x.contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            if (paper.contract_documents.length > 0) {
              paper.contract_documents.forEach((contract) => {
                if (contract.users_permissions_user.id === myData.id) {
                  array.push(contract.contractPaper)
                }
              })
            }
          })
      })
    setContractInfosData(array)
  }
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const handleChange = (e, index) => {
    const changeListData = { ...changeList }
    if (index === 1) {
      changeListData.department = e.target.value
    } else if (index === 2) {
      changeListData.id = e.target.value.split(" ")[2]
      changeListData.name = e.target.value
    } else if (index === 3) {
      changeListData.reason = e.target.value
    }
    setchangeList(changeListData)
  }
  const change_data = () => {
    dispatch(loading_Apply(true))
    const content = {
      request: {
        id: myData.id,
        department: myData.department,
        name: myData.name,
        position: myData.position,
        profile: myData.profile ? myData.profile.url : null,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
        contractLenght: changeCount,
      },
      companiesIds: [],
      writersIds: [],
      outsourcingsIds: [],
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
      contractsIds: [],
      targetName: ["계약 일괄 변경"],
    }
    myData.companies.forEach((element) => {
      content.companiesIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        company_contracts: element.company_contracts.filter(
          (x) => x.users_permissions_user.id === myData.id
        ),
      })
    })
    myData.outsourcings.forEach((element) => {
      content.outsourcingsIds.push({
        id: element.id,
        user: element.user,
        outsourcing_contracts: element.outsourcing_contracts.filter(
          (x) => x.users_permissions_user.id === myData.id
        ),
      })
    })
    myData.writers.forEach((element) => {
      content.writersIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        contracts: element.contracts,
      })
    })
    const body = {
      type: "Writer / Company",
      content: content,
      users_permissions_user: myData.id,
      status: "신청",
    }
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then((res) => {
        usersDatas
          .filter((x) => x.role.id === 1)
          .forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[1].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "계약 담당자 변경 신청",
                    type: "Change",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      title: myData.name,
                      name: myData.name,
                      count: changeCount,
                      targetName: "계약 일괄 변경",
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "3",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      count: changeCount,
                    },
                  },
                })
              }
            }
          })
        meQuery()
        setopenAccess_Dialog(false)
        dispatch(loading_Apply(false))
      })
  }
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const settings_ref = useRef(null)
  const settings_Call = () => {
    settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        settings[el.type] = el.list
      })
      settings_ref.current = settings
      dispatch(loading_Apply(false))
    })
  }
  const teamList = settings_ref.current
    ? settings_ref.current.department
    : [
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ]

  const workListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const workListSelects2 = workList2.map((x, index) => {
    return (
      <MenuItem value={x} key={index}>
        {x.split(" ")[0] + " " + x.split(" ")[1]}
      </MenuItem>
    )
  })
  const workList3 = ["퇴사로 인한 계약 이관", "업무 변경으로 인한 이관"]
  const workListSelects3 = workList3.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const reload = () => {
    dispatch(loading_Apply(true))
    meQuery()
  }
  const paperSorting = (array) => {
    array.sort((a, b) => {
      var dateA = a[0].file.id
      var dateB = b[0].file.id
      return dateA < dateB ? 1 : -1
    })
    return array
  }
  const changeCountShow = (data) => {
    let count = 0
    data.content.companiesIds.forEach((element) => {
      element.company_contracts.forEach((contract) => {
        if (paperSorting(contract.contractPaper)[0][0].user) {
          if (paperSorting(contract.contractPaper)[0][0].user.id === myData.id) {
            count += 1
          }
        }
      })
    })
    data.content.writersIds.forEach((element) => {
      element.contracts.forEach((contract) => {
        contract.contract_documents.forEach((document) => {
          if (myData.id === document.users_permissions_user.id) {
            count += 1
          }
        })
      })
    })
    return count
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  return (
    <Card sx={{ my: 5, boxShadow: 0 }} className="bg-transparent">
      <SuiBox display="flex" justifyContent="center">
        <Card
          sx={{
            p: 4,
            mx: 2,
            border: 1,
            borderColor: "#CDD2D3",
            boxShadow: 1,
            width: 400,
          }}
        >
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{ textAlign: "center" }}
          >
            현재 담당자 정보
          </SuiTypography>
          <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap" }}>
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                  mb: 2,
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                    담당부서
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {myData.department}
                  </SuiTypography>
                </SuiBox>
              </Card>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                    담당자
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {myData.name}
                  </SuiTypography>
                </SuiBox>
              </Card>
            </SuiBox>
            <Card
              sx={{
                mt: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                width: "100%",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 136 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 4, color: "#535353" }}>
                  이관
                  <br />
                  계약
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 6, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{
                    mr: "auto",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#535353",
                  }}
                  onClick={() => infoDialog()}
                >
                  {changeCount}건
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
        </Card>
        <IconButton color="info2" sx={{ mx: 0 }}>
          <MdOutlineDoubleArrow size="30" />
        </IconButton>
        <Card
          sx={{
            p: 4,
            mx: 2,
            border: 1,
            borderColor: "#CDD2D3",
            boxShadow: 1,
            width: 400,
          }}
        >
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{ textAlign: "center" }}
          >
            이관 대상 담당자 정보
          </SuiTypography>
          <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap" }}>
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  pl: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#e9ecef",
                  mb: 2,
                  borderRadius: "1rem 0.7rem 0.7rem 1rem",
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                  >
                    담당부서
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                  />
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.department === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen1(!FiltersOpen1)}
                          />
                        )}
                        open={FiltersOpen1}
                        value={changeList.department}
                        onChange={(e) => {
                          dispatch(loading_Apply(true))
                          usersActive(e.target.value)
                          handleChange(e, 1)
                        }}
                        className="change-select-radius"
                      >
                        {workListSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                </SuiBox>
              </Card>
              <Card
                sx={{
                  pl: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#e9ecef",
                  borderRadius: "1rem 0.7rem 0.7rem 1rem",
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ ml: 2, whiteSpace: "nowrap", color: "#535353" }}
                  >
                    담당자
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                  />
                  <SuiBox sx={{ width: "100%", height: "auto" }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        changeList.name === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen2(!FiltersOpen2)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen2(!FiltersOpen2)}
                          />
                        )}
                        open={FiltersOpen2}
                        value={changeList.name}
                        onChange={(e) => handleChange(e, 2)}
                        className="change-select-radius"
                      >
                        {workListSelects2}
                      </Select>
                    </FormControl>
                  </SuiBox>
                </SuiBox>
              </Card>
            </SuiBox>
            <Card
              sx={{
                mt: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#e9ecef",
                width: "100%",
                borderRadius: "1rem 0.7rem 0.7rem 1rem",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "auto" }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 4, whiteSpace: "nowrap", color: "#535353" }}
                >
                  이관사유
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                />
                <SuiBox sx={{ width: "100%", height: "auto" }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      changeList.reason === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      value={changeList.reason}
                      onChange={(e) => handleChange(e, 3)}
                      className="change-select-radius"
                    >
                      {workListSelects3}
                    </Select>
                  </FormControl>
                </SuiBox>
              </SuiBox>
            </Card>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox py={5} px={13.4} display="flex" justifyContent="end">
        <SuiButton
          color="primary"
          onClick={() => setopenAccess_Dialog(true)}
          sx={{ width: 100, fontSize: 14 }}
          disabled={
            myData.change_requests
              .filter((z) => z.content)
              .filter((y) => y.content.companiesIds.length > 0 || y.content.writersIds.length > 0)
              .map((x) => x.content.status)
              .indexOf("신청") > -1 ||
            changeCount === 0 ||
            changeList.name === ""
          }
        >
          신청하기
        </SuiButton>
      </SuiBox>

      <SuiBox display="flex" justifyContent="center" px={13.4}>
        <Divider sx={{ borderColor: "#CDD2D3", width: "100%" }} />
      </SuiBox>
      <SuiTypography
        sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
        variant="h6"
        component="div"
      >
        변경 신청 결과
      </SuiTypography>
      <SuiBox display="flex" alignItems="center" justifyContent="end" px={13.4}>
        <SuiBox
          p={0}
          component="img"
          src="/reload.png"
          alt="Soft UI Logo"
          height="56px"
          className="cursor"
          onClick={() => reload()}
        />
      </SuiBox>
      {myData.change_requests.length === 0 ? (
        <SuiTypography
          sx={{ fontWeight: 400, color: "#707070", fontSize: 18, textAlign: "left", px: 13.4 }}
          component="div"
        >
          신청 내역이 없습니다.
        </SuiTypography>
      ) : (
        [...myData.change_requests]
          .filter((x) => x.content)
          .reverse()
          .map((data, index) => {
            return (
              <Card sx={{ my: 2, boxShadow: 0, p: 2, mx: 13.4 }} key={index}>
                <SuiTypography
                  sx={{ fontWeight: 700, color: "dark", fontSize: 18, textAlign: "left" }}
                  component="div"
                >
                  담당자 변경 {data.content.status}
                </SuiTypography>
                <SuiTypography
                  sx={{ fontWeight: 400, color: "#9AA0A6", fontSize: 16, textAlign: "left" }}
                  component="div"
                >
                  {dayjs(data.content.created_at).format("YYYY.MM.DD HH:mm")}
                </SuiTypography>
                {data.content.status === "거절" ? (
                  <SuiTypography
                    sx={{
                      fontWeight: 400,
                      color: "#707070",
                      fontSize: 18,
                      textAlign: "left",
                      mt: 1,
                    }}
                    component="div"
                  >
                    신청하신
                    {data.type
                      ? data.content.apply.contractLenght
                        ? data.content.apply.contractLenght
                        : "1"
                      : changeCountShow(data)}
                    건의 계약 담당자 변경 {data.content.status}되었습니다.
                  </SuiTypography>
                ) : (
                  <SuiTypography
                    sx={{
                      fontWeight: 400,
                      color: "#707070",
                      fontSize: 18,
                      textAlign: "left",
                      mt: 1,
                    }}
                    component="div"
                  >
                    {data.type
                      ? data.content.apply.contractLenght
                        ? data.content.apply.contractLenght
                        : "1"
                      : changeCountShow(data)}
                    건의 계약이 {" '"}
                    {data.content.apply.department}
                    {"' "}의{" '"}
                    {data.content.apply.name + " " + data.content.apply.position}
                    {"' "} 변경 {data.content.status}되었습니다.
                  </SuiTypography>
                )}

                {data.content.reject !== "" ? (
                  <SuiTypography
                    color="error"
                    sx={{ fontWeight: 400, fontSize: 18, textAlign: "left" }}
                    component="div"
                  >
                    거절 사유 : {data.content.reject}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </Card>
            )
          })
      )}
      <SuiDialog
        openDialog={openAccess_Dialog}
        closeDialog={() => setopenAccess_Dialog(false)}
        MainTitle={"담당자 변경 신청"}
        Content={"담당자 변경 신청시, 다시 되돌리실 수 없습니다. 그래도 진행하시겠습니까?"}
        Active={() => change_data()}
        CanBtnText={"취소"}
        CreBtnText={"변경신청"}
        AlertType={"twoBtn"}
        CreBtnColor={"primary"}
      />
      <ContractInfosDialog
        openDialog={ContractInfosDialogOpenBool}
        closeDialog={() => setContractInfosDialogOpenBool(false)}
        ContarctData={ContractInfosData}
      />
    </Card>
  )
}
changeData_card.defaultProps = {
  usersDatas: [],
}

changeData_card.propTypes = {
  usersDatas: PropTypes.array,
}

export default changeData_card
