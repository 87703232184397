import React, { useEffect, useState, useCallback, useRef, useLayoutEffect, useMemo } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"

import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Dialog from "@mui/material/Dialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MobileStepper from "@mui/material/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"

import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiDialog from "components/SuiDialog"
import GetFileIcon from "components/getFileIcon"

import { BiCalendar, BiRecycle, BiImages } from "react-icons/bi"
import { BsFillArrowUpLeftSquareFill, BsFillCircleFill } from "react-icons/bs"
import {
  AiFillCloseCircle,
  AiOutlinePlus,
  AiOutlineUnorderedList,
  AiOutlineFile,
} from "react-icons/ai"
import { CgUserAdd } from "react-icons/cg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { FiSearch, FiMoreHorizontal, FiSend } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { BiUpload } from "react-icons/bi"
import { IoMdCloseCircle } from "react-icons/io"
import { MdRefresh } from "react-icons/md"
import { RiDeleteBinLine } from "react-icons/ri"
import { MdClose } from "react-icons/md"
import { FaThLarge, FaTh, FaRegFlushed, FaCheck, FaRegCheckSquare } from "react-icons/fa"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { FiTrash2 } from "react-icons/fi"
import DaumPostcode from "react-daum-postcode"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import dayjs from "dayjs"
import "dayjs/locale/ko"

import PaginationCustom from "components/PaginationCustom"
import * as XLSX from "xlsx"
import SingleReceiverDialog from "./singleReceiverDialog"
import SendEmail from "./sendEmail"
import DeleteDialog from "./deleteDialog"
import SendHistory from "./sendHistory"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  createEventEmail,
  createRequest,
  updateEvent,
  updateRequest,
  createEventRecevier,
  updateEventEmail,
  updateEventRecevier,
} from "apollo/mutation"
import { bookCodeContracts, events, eventEmails, eventReceviers } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"

// Quill
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

function EnhancedTableHead(props) {
  dayjs.locale("ko")
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    changeFilter,
    showchangeFilter,
    type,
    select_type,
    all_select_click,
  } = props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "번호",
      width: 45,
    },
    {
      id: "real_name",
      numeric: false,
      disablePadding: false,
      label: "본명",
      width: 85,
    },
    {
      id: "ebook",
      numeric: false,
      disablePadding: false,
      label: "이북 필명",
      width: 85,
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처 ",
      width: 126,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
      width: 156,
    },
    // {
    //   id: "book_code",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "북코드",
    //   width: 101,
    // },
    {
      id: "reward",
      numeric: false,
      disablePadding: false,
      label: "개별 리워드",
      width: 128,
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "작가 담당자",
      width: 80,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "발송상태",
      width: 73,
    },
    {
      id: "agree_status",
      numeric: false,
      disablePadding: false,
      label: "승인여부",
      width: 113,
    },
    {
      id: "select",
      numeric: false,
      disablePadding: false,
      label: "선택",
      width: 73,
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  const allClock = () => {
    all_select_click(select_type)
  }
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell, index) =>
          type === "detail" ? (
            index < 9 && (
              <TableCell
                sx={{
                  maxWidth: headCell.width,
                  px: 1,
                  fontSize: "14px",
                  borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
                }}
                key={index + "p3asda"}
                align="center"
              >
                {headCell.id === "select" ? (
                  <span
                    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                    onClick={allClock}
                  >
                    {headCell.label}
                  </span>
                ) : (
                  <span>{headCell.label}</span>
                )}
              </TableCell>
            )
          ) : (
            <TableCell
              sx={{
                maxWidth: headCell.width,
                px: 1,
                fontSize: "14px",
                borderLeft: index === 0 ? "" : "1px solid #F1F1F5",
              }}
              key={index + "p3"}
              align="center"
            >
              {headCell.id === "select" ? (
                <span
                  style={{ color: "#0C65FF", textDecoration: "underline", cursor: "pointer" }}
                  onClick={allClock}
                >
                  {headCell.label}
                </span>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
  type: PropTypes.string,
  all_select_click: PropTypes.func,
  select_type: PropTypes.object,
}
function informationSent(props) {
  dayjs.locale("ko")
  const CryptoJS = require("crypto-js")
  const dispatch = useDispatch()
  const pathUrl = process.env.REACT_APP_CUSTOMER_URL

  const { openDialog, closeDialog, editId, type, edit, index, saveExit } = props
  const [type_data, set_type_data] = useState("detail")
  const { myData } = useSelector((state) => state.store)
  const [snackState, setsSnackState] = React.useState(false)
  const [DetailData, setDetailData] = useState({
    history: [],
    requests: [],
  })
  const [ChangeRequests, setChangeRequests] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)
  const [create_show, set_create_show] = useState(false) // 수정시 새로생성버튼
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [date_open, set_date_open] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [openDeleteInfo_Dialog, setopenDeleteInfo_Dialog] = useState(false)
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [createContractShow, setcreateContractShow] = useState(false)
  const [all_event_data, set_all_event_data] = useState({
    // 이벤트 모든정보
    manager: {
      userName: "",
      userPosition: "",
      userProfile: "",
      userDepartment: "",
    },
    history: [],
    requests: [],
  })
  const quillRef = useRef(null)
  const client = useApolloClient()

  const [events_email_receiver, set_events_email_receiver] = useState("이북 필명") // 이벤트 대상자 value
  const [filters_open2, set_filters_open2] = useState(false) // 이벤트 대상자 bool
  const [event_data_set, set_event_data_set] = useState([
    // 이벤트 초기 셋팅
    {
      value: "[조아라]<작품명>",
      maxLength: 255,
      dropdown: false,
      all_send: false,
      event_id: "",
      send_email_id: "",
      fliters_open: false,
      event_writer_name_type: "이북 필명",
      excel_name: "",
      access_select_value_open: false,
      access_select_value: "승인여부 상태 변경",
    },
    {
      value: "publishing@joara.com",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "발송 이메일 주소:",
      maxLength: 100,
      xs: 3,
      changed: false,
    },
    {
      value: "조아라",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      maxLength: 100,
      xs: 2,
      changed: false,
      title: "발송인:",
    },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "monthPicker",
      maxLength: 100,
      xs: 4,
      changed: false,
      title: "이벤트 승인 마감 일:",
    },
    // 이벤트 대상자
    {
      data: [
        // {
        //   real_name: "박우순",
        //   ebook: "까만콩커피",
        //   phone: "01028771802",
        //   title: "미친 황제 아론 엘리엇",
        //   book_code: "1591944",
        //   reward: "무료 회수 20",
        //   status: "미발송",
        //   agree_status: "미확인",
        //   agree_filter_open: false,
        //   select: false,
        // },
      ],
    },
    // 이벤트 내용
    {
      value: `<p><strong>${
        events_email_receiver ? events_email_receiver : "작가 본명"
      }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
        all_event_data.flatform
      }</strong>&nbsp;<strong>${
        all_event_data.title
      }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
        all_event_data.title
      }</strong></p><p>- 이벤트 기간: <strong>${all_event_data.start} ~ ${
        all_event_data.end
      }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${
        all_event_data?.content
      }</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p >이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de">0000.00.00(-) 00:00</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
        pathUrl + "/notice/" + "코드_code"
      }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`,
      change: true,
    },
    {
      data: [],
    },
  ])
  const [event_default, set_event_default] = useState([]) // 이벤트 안내 메일 기본 정보
  const [event_data, set_event_data] = useState([]) // 이벤트 안내메일 생성
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  useEffect(() => {
    setTimeout(async () => {
      if (openDialog) {
        await client
          .query({
            query: events,
            variables: {
              id: [editId],
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let EventData = res.data
            set_all_event_data(EventData.events[0])
            let event_content_data = [...event_data_set]
            let replace_content = EventData.events[0]?.content.replaceAll("\n", "<br/>")

            event_content_data[0].value = `[조아라]<작품명>${EventData.events[0].flatform} ${EventData.events[0].title} 승인 요청`
            event_content_data[0].event_id = EventData.events[0].id
            event_content_data[2].value = "조아라"
            event_content_data[5].value = `<p><strong>${
              events_email_receiver ? events_email_receiver : "작가 본명"
            }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p>
<strong>${EventData.events[0].flatform}</strong>&nbsp;<strong>${
              EventData.events[0].title
            }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
              EventData.events[0].title
            }</strong></p><p>- 이벤트 기간: <strong>${EventData.events[0].start} ~ ${
              EventData.events[0].end
            }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p >이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de">0000.00.00(-) 00:00</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
              pathUrl + "/notice/" + "코드_code"
            }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
            // set_event_data(event_content_data)
            // set_event_default(event_content_data)
          })
        set_type_data(type)
        startSetting(editId)
      }
    }, 500)
  }, [openDialog])

  const [event_data_list, set_event_data_list] = useState([]) // 이벤트 안내메일 리스트
  const [sort_events, set_sort_events] = useState("최신순") // 정렬 value
  const [filters_open1, set_filters_open1] = useState(false) // 최신순, 오래된순 정렬 bool
  const [events_email_rows_page, set_events_email_rows_page] = useState(10) // 이벤트 대상자 테이블 rowsperpage value
  const [filters_open3, set_filters_open3] = useState(false) // 이벤트 대상자 rowsperpage bool
  const [event_table_page, set_event_table_page] = useState(1) // 이메일 대상자 테이블 현재 페이지
  const [event_table_total_page, set_event_table_total_page] = useState(1) // 이메일 대상자 테이블 토탈페이지
  const settings_ref = useRef(null)
  const [excel_file_name, set_excel_file_name] = useState(false) // 엑셀 업로드 파일 이름
  const [open_info_dialog, set_open_info_dialog] = useState(false) // 엑셀 업로드 에러 다이어그램
  const [dialog_title, set_dialog_title] = useState(false) // 엑셀 업로드 다이어그램 content
  const [dialog_content, set_dialog_content] = useState(false) // 엑셀 업로드 다이어그램 content
  const [open_single_receiver_dialog, set_open_single_receiver_dialog] = useState(false) // 이메일 대상자 개별 등록 다이어로그 오픈
  const [single_receiver_list, set_single_receiver_list] = useState([]) // 이메일 대상자 개별 등록 리스트
  const [open_send_email_dialog, set_open_send_email_dialog] = useState(false) // 이메일 전체 발송 다이어로그 오픈
  const [open_send_history_dialog, set_open_send_history_dialog] = useState(false) // 이메일 전체 발송 다이어로그 오픈
  const [send_history_list, set_send_history_list] = useState([]) // 이메일 대상자 테이블에 있는 리스트
  const [send_email_receiver_list, set_send_email_receiver_list] = useState([]) // 이메일 전체발송 발송이후 저장될 리스트
  const [send_email_list, set_send_email_list] = useState([]) // 이메일 대상자 테이블에 있는 리스트
  const [event_type, set_event_type] = useState("") // 이벤트 안내메일 리스트에서 새로 추가한 정보인지 기존에 있던 정보인지
  const [event_single_index, set_event_single_index] = useState(-1) // 이벤트 안내메일 리스트에서 개별 이메일 대상자를 선택할때 어떤 안내메일인지 알기위한 인덱스
  const [email_type, set_email_type] = useState(-1) // 이메일 발송이 전체인지 개별인지
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [filters_open4, set_filters_open4] = useState(false) // 승인 여부 상태변경 셀렉트
  const [access_select_value, set_access_select_value] = useState("승인여부 상태 변경") // 승인여부 셀렉트 value

  const startSetting = async (valueId, sort) => {
    dispatch(loading_Apply(true))
    // 진입시 데이터 불러오기
    let event_email_list = []
    await client
      .query({
        query: eventEmails,
        variables: {
          eventId: String(valueId),
          sort: sort ? sort : "created_at:desc",
        },
        fetchPolicy: "network-only",
      })
      .then(async (res2) => {
        let result = res2.data.eventEmails
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            await client
              .query({
                query: eventReceviers,
                variables: {
                  eventEmailId: String(result[i].id),
                  mailType: "info",
                },
                fetchPolicy: "network-only",
              })
              .then((res) => {
                let resData = res.data.eventReceviers
                let response = []
                for (let j = 0; j < resData.length; j++) {
                  response.push({
                    receiverId: resData[j].id,
                    real_name: resData[j].name,
                    ebook: resData[j].ebook,
                    re_email: resData[j].email,
                    joara_name: resData[j].joaraName,
                    genre: resData[j].genre,
                    writerId: resData[j].writerId,
                    phone: resData[j].phone,
                    title: resData[j].title,
                    book_code: resData[j].bookCode,
                    reward: resData[j].rewarod,
                    reward_change: false,
                    manager: resData[j].manager,
                    send_status: resData[j].status,
                    code: resData[j].code,
                    agree_status:
                      resData[j].access === "unidentified"
                        ? "미확인"
                        : resData[j].access === "access"
                        ? "승인"
                        : "거절",
                    agree_status_change: false,
                    agree_filter_open: false,
                    select: false,
                    excel: false,
                  })
                }
                let first_setting = [
                  {
                    value: result[i].title,
                    maxLength: 255,
                    dropdown: false,
                    all_send: result[i].allSend,
                    event_id: result[i].eventId,
                    send_email_id: result[i].id,
                  },
                  {
                    value: result[i].senderEmail,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    title: "발송 이메일 주소:",
                    maxLength: 100,
                    xs: 3,
                    changed: false,
                  },
                  {
                    value: result[i].senderName,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    maxLength: 100,
                    xs: 2,
                    changed: false,
                    title: "발송인:",
                  },
                  {
                    value: result[i].endDate ? result[i].endDate : null,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "monthPicker",
                    maxLength: 100,
                    xs: 4,
                    changed: false,
                    title: "이벤트 승인 마감일:",
                  },
                  // 이벤트 대상자
                  {
                    data: response,
                    access_select_value: "승인여부 상태 변경",
                    access_select_bool: false,
                  },
                  // 이벤트 내용
                  {
                    value: result[i].content,
                    change: true,
                  },
                  //발송 내역
                  {
                    data: result[i].history ? result[i].history : [],
                    first: result[i].history
                      ? result[i].history[result[i].history.length - 1]?.created_at
                      : "",
                  },
                ]
                event_email_list.push({
                  data: JSON.parse(JSON.stringify(first_setting)),
                  temporary_storage: true,
                  name_filter: result[i].nameType ? result[i].nameType : "이북 필명",
                  name_filter_bool: false,
                  table_rows: 10,
                  table_rows_bool: false,
                  table_total_page: Math.ceil(response.length / 10),
                  table_current_page: 1,
                  excel_name: result[i].file?.name,
                })
              })
            if (result.length - 1 === i) {
              let li = event_email_list.filter((x) => x.data[6].first)
              li.sort(function (a, b) {
                return new Date(a.data[6].first) - new Date(b.data[6].first)
              })
              for (let i = 0; i < li.length; i++) {
                event_email_list[
                  event_email_list.findIndex(
                    (x) => x.data[0].send_email_id === li[i].data[0].send_email_id
                  )
                ].num = i + 1
              }
              set_event_data_list(event_email_list)
              dispatch(loading_Apply(false))
            }
          }
        } else {
          set_event_data_list([])
          dispatch(loading_Apply(false))
        }
      })
  }

  const all_select_click = (data) => {
    if (data.type === "add") {
      let change = [...event_data]
      if (
        change[data.index][4].data.length ===
        change[data.index][4].data.filter((x) => x.select).length
        // 전체 선택되어있을때 전체 체크 해제
      ) {
        change[data.index][4].data.map((x) => (x.select = false))
      } else {
        change[data.index][4].data.map((x) => (x.select = true))
      }
      set_event_data(change)
    } else {
      let change = [...event_data_list]
      if (
        change[data.index].data[4].data.length ===
        change[data.index].data[4].data.filter((x) => x.select).length
        // 전체 선택되어있을때 전체 체크 해제
      ) {
        change[data.index].data[4].data.map((x) => (x.select = false))
      } else {
        change[data.index].data[4].data.map((x) => (x.select = true))
      }
      set_event_data_list(change)
    }
  }
  const imageHandler = () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", ".image/png, image/jpeg, image/jpg")
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (file.size > 500000) {
        return alert("500kb 이하의 png, jpeg ,jpg 파일만 업로드 가능합니다.")
      }
      let data = new FormData()
      data.append("files", file)
      let imgUrl
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
        imgUrl = process.env.REACT_APP_BACKEND_URL + res.data[0].url
      })
      const editor = quillRef.current.getEditor()
      const range = editor.getSelection()
      editor.insertEmbed(range.index, "image", imgUrl)
      editor.setSelection(range.index + 1)
    }
  }
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [["bold", { color: [] }, "italic", "link", "image"]],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: imageHandler,
        },
      },
    }
  }, [])
  const formats = ["bold", "color", "italic", "link", "image"]
  const quillChange = (type, index2) => (e) => {
    if (type === "add") {
      let email_content = [...event_data]
      email_content[index2][5].value = e
      set_event_data(email_content)
    } else {
      let email_content = [...event_data_list]
      email_content[index2].data[5].value = e
      set_event_data_list(email_content)
    }
  }

  const handleMenu = () => {
    setAnchorEl(null)
  }
  const sendHistory = (event_index) => {
    set_send_history_list(event_data_list[event_index])
    set_open_send_history_dialog(true)
  }
  // 메일 발송 내역 다이어로그 닫기
  const openSendHistoryCloseDialog = () => {
    set_open_send_history_dialog(false)
  }
  // 개별 이메일 대상자 다이어로그 닫기
  const singleReceiverCloseDialog = () => {
    set_open_single_receiver_dialog(false)
  }
  // 메일 발송 다이어로그 닫기
  const openSendEmailCloseDialog = () => {
    set_open_send_email_dialog(false)
  }
  // 이메일 대상자 보낸후 데이터 받아오기
  const sendEmailReceiverListFunc = (data) => {
    if (data === "update") {
      startSetting(all_event_data.id)
      set_type_data("detail")
      dispatch(editStatus_Apply(false))
      eventDataReset()
    }
  }
  // 전체 발송 클릭시
  const sendAllEmail = (currentType, event_index) => {
    if (currentType === "create") {
      if (event_data[event_index][0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[event_index][1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[event_index][2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[event_index][3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (dayjs(event_data[event_index][3].value).format("YYYY.MM.DD (a) HH:mm").length !== 21) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 잘못 입력했습니다.`)
        set_open_info_dialog(true)
        return
      }
      if (
        dayjs(all_event_data.start).format("YY-MM-DD") <=
        dayjs(event_data[event_index][3].value).format("YY-MM-DD")
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[event_index][4].data.filter((x) => x.reward === "").length > 0) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이메일 대상자 개별 리워드를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      set_send_email_list(event_data[event_index])
    } else {
      if (event_data_list[event_index].data[0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[2].value === "") {
        event_data_list[event_index].data("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (
        dayjs(event_data_list[event_index].data[3].value).format("YYYY.MM.DD (a) HH:mm").length !==
        21
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 잘못 입력했습니다.`)
        set_open_info_dialog(true)
        return
      }
      if (
        dayjs(all_event_data.start).format("YY-MM-DD") <=
        dayjs(event_data_list[event_index].data[3].value).format("YY-MM-DD")
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data_list[event_index].data[4].data.filter((x) => x.reward === "").length > 0) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이메일 대상자 개별 리워드를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      set_event_single_index(event_index)
      set_send_email_list(event_data_list[event_index].data)
    }
    set_email_type("all")
    set_open_send_email_dialog(true)
  }

  const event_update_open = Boolean(anchorEl)
  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
  }
  // 안내메일 새롭게 추가
  const addEvent = () => {
    let arr_event_data = [...event_data]
    arr_event_data.unshift(JSON.parse(JSON.stringify(event_data_set)))
    set_event_data(arr_event_data)
  }
  // 안내메일 추가 취소
  const eventDataReset = () => {
    set_event_data([])
  }
  const cancelEvent = (index) => {
    let arr_event_data = [...event_data]
    if (arr_event_data[index][0].id) {
      dispatch(loading_Apply(true))
      client
        .mutate({
          mutation: updateEventEmail,
          variables: {
            id: arr_event_data[index][0].id,
            eventId: null,
          },
          fetchPolicy: "network-only",
        })
        .then(async (res) => {
          await updateEventHistory("<strong>안내 발송 정보</strong>를 삭제하였습니다.")
          dispatch(loading_Apply(false))
        })
    } else {
      arr_event_data.splice(index, 1)
      set_event_data(arr_event_data)
    }
  }

  const eventDropDown = (bool, type, index2) => {
    if (type === "add") {
      let arr_event_data = [...event_data]
      arr_event_data[index2][0].dropdown = !bool
      set_event_data(arr_event_data)
    } else {
      let arr_event_list = [...event_data_list]
      arr_event_list[index2].data[0].dropdown = !bool
      set_event_data_list(arr_event_list)
    }
  }

  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })

  //이벤트 삭제 신청
  const Delete_All = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      suggestRole: "이벤트 삭제신청",
      profile: myData.profile,
      requestExplain: requestExplainData,
      title: all_event_data.title,
      eventId: all_event_data.id,
    }
    if (all_event_data.history.length > 0) {
      request.first_history = all_event_data.history[all_event_data.history.length - 1]
    }
    createRequestActive({
      variables: {
        status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
        content: request,
        type: "Event",
        event: all_event_data.id,
        requestId: all_event_data.id,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      const editHistory = [...all_event_data.history]
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content:
          "<strong>" + all_event_data.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "waiting",
          history: editHistory,
        },
      })
      dispatch(loading_Apply(false))
      setDelete_Dialog(false)
    })
  }

  const [updateEventActive] = useMutation(updateEvent, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 수정 오류 발생")
    },
    refetchQueries: [events],
  })

  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [events],
  })

  const deleteSelect = () => {
    if (myData.role.name === "silver") {
      if (all_event_data.users_permissions_user.id !== myData.id) {
        const InfoAlertSet1 = {
          dialog: true,
          title: "권한없음",
          content: "열람권한이 없습니다.",
          CanBtnText: "확인",
        }
        setInfoAlert(InfoAlertSet1)
        return
      }
    }
    if (all_event_data.requests.length > 0) {
      if (all_event_data.requests[0].status.indexOf("심사중") > -1) {
        setopenDeleteInfo_Dialog(true)
      } else {
        setDelete_Dialog_type("create")
        setDelete_Content(`${all_event_data.title}`)
        setDelete_Dialog(true)
        setrequestExplainData("")
      }
    } else {
      setDelete_Dialog_type("create")
      setDelete_Content(`${all_event_data.title}`)
      setDelete_Dialog(true)
      setrequestExplainData("")
    }
    setAnchorEl(null)
  }
  const openRequest = (data) => {
    setDelete_Dialog(true)
    if (data.status.indexOf("심사중") > -1) {
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.content.requestExplain)
      setDelete_Dialog_type("edit")
    } else {
      if (!data.content.read) {
        let content = JSON.parse(JSON.stringify(data.content))
        content.read = true
        updateRequestActive({
          variables: {
            id: data.id,
            content: content,
          },
        })
      }
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.declining)
      setDelete_Dialog_type("delete")
    }
  }
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  //삭제신청 취소 작업
  const delete_cancle_active = () => {
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제신청 취소 완료"
      data.content = "신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"
      setdelete_cancle_alert(data)
      setcreateContractShow(true)
      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "active",
        },
      })
    })
  }
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
  }

  // 이벤트 대상자 txt필드 입력
  const handleInput = (index, maxLength, type, index2) => (e) => {
    // 생성시 입력
    if (type === "add") {
      let arr_event_data = [...event_data]
      if (index === 0) {
        arr_event_data[index2][0].value = e.target.value
      } else if (index === 1) {
        arr_event_data[index2][1].value = e.target.value
      } else if (index === 2) {
        let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
        arr_event_data[index2][2].value = e.target.value
        arr_event_data[index2][5].value = `<p><strong>${
          events_email_receiver ? events_email_receiver : "작가 본명"
        }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자</strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
          all_event_data?.flatform
        }</strong>&nbsp;<strong>${
          all_event_data?.title
        }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
          all_event_data?.title
        }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
          all_event_data?.end
        }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de">${
          arr_event_data[index2][3].value
            ? dayjs(arr_event_data[index2][3].value).format("YYYY.MM.DD(ddd) HH:mm")
            : "0000.00.00(-) 00:00"
        }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
          pathUrl + "/notice/" + "코드_code"
        }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
      }
      set_event_data(arr_event_data)
    } else {
      let arr_event_list = [...event_data_list]
      if (index === 0) {
        arr_event_list[index2].data[0].value = e.target.value
      } else if (index === 1) {
        arr_event_list[index2].data[1].value = e.target.value
      } else if (index === 2) {
        let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
        arr_event_list[index2].data[2].value = e.target.value
        arr_event_list[index2].data[5].value = `<p><strong>${
          arr_event_list[index2].name_filter ? arr_event_list[index2].name_filter : "작가 본명"
        }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
          all_event_data?.flatform
        }</strong>&nbsp;<strong>${
          all_event_data?.title
        }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
          all_event_data?.title
        }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
          all_event_data?.end
        }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de">${
          arr_event_list[index2].data[3].value
            ? dayjs(arr_event_list[index2].data[3].value).format("YYYY.MM.DD(ddd) HH:mm")
            : "0000.00.00(-) 00:00"
        }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
          pathUrl + "/notice/" + "코드_code"
        }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
      }
      set_event_data_list(arr_event_list)
    }
  }
  // 이메일 대상자 테이블 체크박스
  const handleCheckBox = (type, index, index2) => (e) => {
    // 이메일 대상자 생성시
    if (type === "add") {
      let arr_event_data = [...event_data]
      arr_event_data[index2][4].data[index].select = e.target.checked
      set_event_data(arr_event_data)
    } else {
      let arr_event_data = [...event_data_list]
      arr_event_data[index2].data[4].data[index].select = e.target.checked
      set_event_data_list(arr_event_data)
    }
  }
  // 발송메일 생성
  const handleDatePickerChange = (index, type, index2) => (e) => {
    if (type === "add") {
      let arr_event_data = [...event_data]
      let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
      arr_event_data[index2][3].value = e
      arr_event_data[index2][5].value = `<p><strong>${
        events_email_receiver ? events_email_receiver : "작가 본명"
      }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
        all_event_data?.flatform
      }</strong>&nbsp;<strong>${
        all_event_data?.title
      }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
        all_event_data?.title
      }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
        all_event_data?.end
      }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
        e ? dayjs(e).format("YYYY.MM.DD(ddd) HH:mm") : "0000.00.00(-) 00:00"
      }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
        pathUrl + "/notice/" + "코드_code"
      }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
      set_event_data(arr_event_data)
    } else {
      let arr_event_list = [...event_data_list]
      let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
      arr_event_list[index2].data[3].value = e
      arr_event_list[index2].data[5].value = `<p><strong>${
        arr_event_list[index2].name_filter ? arr_event_list[index2].name_filter : "작가 본명"
      }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
        all_event_data?.flatform
      }</strong>&nbsp;<strong>${
        all_event_data?.title
      }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
        all_event_data?.title
      }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
        all_event_data?.end
      }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
        e ? dayjs(e).format("YYYY.MM.DD(ddd) HH:mm") : "0000.00.00(-) 00:00"
      }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
        pathUrl + "/notice/" + "코드_code"
      }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
      set_event_data_list(arr_event_list)
    }
  }
  const handleCloseDatePicker = (index, type, index2) => (e) => {}
  // 페이지 네이션 클릭시
  const eventTablePage = (type, evnet_index) => (e, el) => {
    if (type === "add") {
      let arr_email_receiver = [...event_data]
      set_event_table_total_page(
        Math.ceil(arr_email_receiver[4].data.length / events_email_rows_page)
      )
      set_event_table_page(el)
    } else {
      let arr_event_data = [...event_data_list]
      arr_event_data[evnet_index].table_current_page = el
      set_event_data_list(arr_event_data)
    }
  }
  // 엑셀 업로드 클릭
  const excelImport = (index, type) => {
    if (type === "add") {
      document.getElementById(`event_excel_import_${index}_add`).value = ""
      document.getElementById(`event_excel_import_${index}_add`).click()
      set_event_single_index(index)
      set_event_type("add")
    } else {
      document.getElementById(`event_excel_import_${index}`).value = ""
      document.getElementById(`event_excel_import_${index}`).click()
      set_event_single_index(index)
    }
  }

  // 엑셀 파일 읽기
  const readExcel = (e) => {
    let input = e.target
    let reader = new FileReader()
    if (
      input.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      set_dialog_title("엑셀 업로드 오류")
      set_dialog_content(`xlsx파일이 아닙니다. 확인해주세요.`)
      set_open_info_dialog(true)
      return
    }

    reader.onload = function () {
      let data = reader.result
      let workBook = XLSX.read(data, { type: "binary" })
      workBook.SheetNames.forEach(function (sheetName) {
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName])
        let arr_excel = JSON.parse(JSON.stringify(rows))
        // 중복 북코드 체크
        let num_check = 0
        for (let i = 0; i < arr_excel.length; i++) {
          let arr_if = arr_excel.filter((x) => x["북코드"] === arr_excel[i]["북코드"])
          if (arr_if.length > 1) {
            num_check++
          }
        }
        if (num_check === 0) {
          callContract(arr_excel, input)
        } else {
          if (event_type === "add") {
            let arr_email_receiver_list = [...event_data]
            arr_email_receiver_list[event_single_index][0]["excel_name"] = ""
            set_event_data(arr_email_receiver_list)
          } else {
            let arr_email_receiver_list = [...event_data_list]
            arr_email_receiver_list[event_single_index]["excel_name"] = ""
            set_event_data_list(arr_email_receiver_list)
          }
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 중복됩니다. 확인해주세요.`)
          set_open_info_dialog(true)
          return
        }
      })
    }
    reader.readAsBinaryString(input.files[0])
  }
  // 엑셀파일 bookcode로 불러오기
  const callContract = async (excel_data, input) => {
    if (event_type === "add") {
      let arr_email_receiver = [...event_data]
      arr_email_receiver[event_single_index][0]["excel_name"] = input.files[0].name
      arr_email_receiver[event_single_index][4].data = arr_email_receiver[
        event_single_index
      ][4].data.filter((x) => x.excel === false)
      dispatch(loading_Apply(true))
      let arr_bookcode = []
      // 엑셀데이터 북코드 스트링으로 리스트 만들기
      for (let i = 0; i < excel_data.length; i++) {
        arr_bookcode.push(String(excel_data[i]["북코드"]))
      }
      // 북코드에 따라 작품 호출
      await client
        .query({
          query: bookCodeContracts,
          fetchPolicy: "network-only",
          variables: {
            bookcode: arr_bookcode,
          },
        })
        .then(({ data }) => {
          if (data.contracts.length > 0) {
            // 잘못된 북코드가 포함되어있을때
            if (arr_bookcode.length !== data.contracts.length) {
              set_excel_file_name("")
              set_dialog_title("엑셀 업로드 오류")
              set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
              set_open_info_dialog(true)
              dispatch(loading_Apply(false))
              return
            }
            // 북코드에 맞는 작품이 있을경우에만
            let contracts_list = []
            for (let i = 0; i < data.contracts.length; i++) {
              // 엑셀데이터에서 bookcode에 맞는 인덱스찾기
              let num_bookcode_index = excel_data.findIndex(
                (x) => String(x["북코드"]) === data.contracts[i].bookcode
              )

              let re_phone
              re_phone = CryptoJS.AES.decrypt(data.contracts[i].writer.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let re_email
              re_email = CryptoJS.AES.decrypt(data.contracts[i].writer.email, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let obj_contract_data = {
                id: data.contracts[i].id,
                re_email: re_email,
                genre: data.contracts[i].genre,
                real_name: data.contracts[i].writer.name,
                ebook: data.contracts[i].writer.ebookname,
                joara_name: data.contracts[i].writer.joaraname,
                phone: re_phone,
                title: data.contracts[i].title,
                book_code: data.contracts[i].bookcode,
                reward: excel_data[num_bookcode_index]["개별 리워드"]
                  ? excel_data[num_bookcode_index]["개별 리워드"]
                  : "이벤트 내용 참고",
                reward_bool: false, // 리워드 인풋박스로 변경여부
                manager: excel_data[num_bookcode_index]["작가 담당자"]
                  ? excel_data[num_bookcode_index]["작가 담당자"]
                  : "",
                manager_bool: false, // 작가 담당자 인풋박스로 변경여부
                send_status: "미발송",
                agree_status: "미확인",
                agree_filter_open: false,
                select: false,
                single_select: false,
                excel: true, // 엑셀업로드로 추가한경우
                writer: data.contracts[0].writer,
              }

              contracts_list.push(obj_contract_data)
              if (data.contracts.length - 1 === i) {
                let new_arr = arr_email_receiver[event_single_index][4].data.concat(contracts_list)
                arr_email_receiver[event_single_index][4].data = new_arr
                console.log(arr_email_receiver)
                set_event_data(arr_email_receiver)
                dispatch(loading_Apply(false))
              }
            }
          } else {
            set_excel_file_name("")
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            return
          }
        })
        .catch((err) => {
          set_excel_file_name("")
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        })
    } else {
      let arr_email_receiver_list = [...event_data_list]
      arr_email_receiver_list[event_single_index]["excel_name"] = input.files[0].name
      let arr_email_receiver = arr_email_receiver_list[event_single_index].data
      console.log(arr_email_receiver[4].data)
      arr_email_receiver[4].data = arr_email_receiver[4].data.filter((x) => x.excel === false)
      console.log(arr_email_receiver[4].data)
      let arr_contract = []
      dispatch(loading_Apply(true))
      let arr_bookcode = []
      // 엑셀데이터 북코드 스트링으로 리스트 만들기
      for (let i = 0; i < excel_data.length; i++) {
        arr_bookcode.push(String(excel_data[i]["북코드"]))
      }
      // 북코드에 따라 작품 호출
      await client
        .query({
          query: bookCodeContracts,
          fetchPolicy: "network-only",
          variables: {
            bookcode: arr_bookcode,
          },
        })
        .then(({ data }) => {
          if (data.contracts.length > 0) {
            // 잘못된 북코드가 포함되어있을때
            if (arr_bookcode.length !== data.contracts.length) {
              arr_email_receiver_list[event_single_index]["excel_name"] = ""
              set_dialog_title("엑셀 업로드 오류")
              set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
              set_open_info_dialog(true)
              dispatch(loading_Apply(false))
              set_event_data_list(arr_email_receiver_list)
              return
            }
            // 북코드에 맞는 작품이 있을경우에만
            for (let i = 0; i < data.contracts.length; i++) {
              // 엑셀데이터에서 bookcode에 맞는 인덱스찾기
              let num_bookcode_index = excel_data.findIndex(
                (x) => String(x["북코드"]) === data.contracts[i].bookcode
              )
              let re_phone
              re_phone = CryptoJS.AES.decrypt(data.contracts[i].writer.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let re_email
              re_email = CryptoJS.AES.decrypt(data.contracts[i].writer.email, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
              let obj_contract_data = {
                id: data.contracts[i].id,
                re_email: re_email,
                real_name: data.contracts[i].writer.name,
                ebook: data.contracts[i].writer.ebookname,
                joara_name: data.contracts[i].writer.joaraname,
                phone: re_phone,
                title: data.contracts[i].title,
                book_code: data.contracts[i].bookcode,
                reward: excel_data[num_bookcode_index]["개별 리워드"]
                  ? excel_data[num_bookcode_index]["개별 리워드"]
                  : "이벤트 내용 참고",
                reward_bool: false, // 리워드 인풋박스로 변경여부
                manager: excel_data[num_bookcode_index]["작가 담당자"]
                  ? excel_data[num_bookcode_index]["작가 담당자"]
                  : "",
                manager_bool: false, // 작가 담당자 인풋박스로 변경여부
                send_status: "미발송",
                agree_status: "미확인",
                agree_filter_open: false,
                select: false,
                single_select: false,
                excel: true, // 엑셀업로드로 추가한경우
                writer: data.contracts[i].writer,
              }
              if (
                arr_email_receiver[4].data.findIndex((x) => x.id === data.contracts[i].id) === -1
              ) {
                arr_email_receiver[4].data.push(obj_contract_data)
              }
              if (data.contracts.length - 1 === i) {
                arr_email_receiver_list[event_single_index].table_current_page = 1
                arr_email_receiver_list[event_single_index].table_total_page = Math.ceil(
                  arr_email_receiver[4].data.length / events_email_rows_page
                )
                set_event_data_list(arr_email_receiver_list)

                dispatch(loading_Apply(false))
              }
            }
          } else {
            arr_email_receiver_list[event_single_index]["excel_name"] = ""
            set_dialog_title("엑셀 업로드 오류")
            set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
            set_open_info_dialog(true)
            dispatch(loading_Apply(false))
            set_event_data_list(arr_email_receiver_list)
            return
          }
        })
        .catch((err) => {
          arr_email_receiver_list[event_single_index]["excel_name"] = ""
          set_dialog_title("엑셀 업로드 오류")
          set_dialog_content(`북코드가 존재하지 않습니다. 확인해주세요.`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          set_event_data_list(arr_email_receiver_list)
          return
        })
    }
  }
  // 엑셀 업로드 파일 x버튼
  const excelCloseClick = (type, index) => {
    if (type === "add") {
      let arr_email_receiver = [...event_data]
      arr_email_receiver[index][4].data = arr_email_receiver[index][4].data.filter(
        (x) => x.excel === false
      )
      arr_email_receiver[index][0].excel_name = ""
      set_event_data(arr_email_receiver)
    } else {
      let arr_email_receiver = [...event_data_list]
      arr_email_receiver[event_single_index].data[4].data = arr_email_receiver[
        event_single_index
      ].data[4].data.filter((x) => x.excel === false)
      arr_email_receiver[event_single_index].excel_name = ""
      set_event_data_list(arr_email_receiver)
    }
  }
  // 개별 이메일 대상자
  const openSingleDialog = (index, type) => {
    set_event_type(type)
    set_event_single_index(index)
    set_open_single_receiver_dialog(true)
  }
  // 개별 이메일 대상자 추가시 중복제외
  const receiverList = (arr) => {
    if (event_type === "add") {
      let table_list = [...event_data]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].book_code !== "") {
          if (
            table_list[event_single_index][4].data.findIndex(
              (x) => x.book_code === arr[i].book_code
            ) === -1
          ) {
            table_list[event_single_index][4].data.push(arr[i])
          }
        } else {
          if (
            table_list[event_single_index][4].data.findIndex(
              (x) =>
                x.title === arr[i].title && x.ebook === arr[i].ebook && x.genre === arr[i].genre
            ) === -1
          ) {
            table_list[event_single_index][4].data.push(arr[i])
          }
        }
      }
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].book_code !== "") {
          if (
            table_list[event_single_index].data[4].data.findIndex(
              (x) => x.book_code === arr[i].book_code
            ) === -1
          ) {
            table_list[event_single_index].data[4].data.push(arr[i])
          }
        } else {
          if (
            table_list[event_single_index].data[4].data.findIndex(
              (x) =>
                x.title === arr[i].title && x.ebook === arr[i].ebook && x.genre === arr[i].genre
            ) === -1
          ) {
            table_list[event_single_index].data[4].data.push(arr[i])
          }
        }
      }
      set_event_data_list(table_list)
      set_event_single_index(-1)
    }
  }
  const deleteEvent = async (event_index) => {
    dispatch(loading_Apply(true))
    await client
      .mutate({
        mutation: updateEventEmail,
        variables: {
          id: event_data_list[event_index].data[0].send_email_id,
          eventId: null,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        await updateEventHistory("<strong>안내 발송 정보</strong>를 삭제하였습니다.")
        dispatch(loading_Apply(false))
        startSetting(all_event_data.id)
      })
  }
  // 테이블에서 리워드 클릭시 input 변경
  const clickReward = (type, index, index2) => (e) => {
    e.preventDefault()
    if (type === "add") {
      // input 박스 열기
      let table_list = [...event_data]
      table_list[index2][4].data.map((x, idx) => {
        if (index === idx) {
          x.reward_bool = true
        } else {
          x.reward_bool = false
        }
      })
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data.map((x, idx) => {
        if (index === idx) {
          x.reward_bool = true
        } else {
          x.reward_bool = false
        }
      })

      set_event_data_list(table_list)
    }
  }
  const clickManager = (type, index, index2) => (e) => {
    e.preventDefault()
    if (type === "add") {
      // input 박스 열기
      let table_list = [...event_data]
      table_list[4].data.map((x, idx) => {
        if (index === idx) {
          x.manager_bool = true
        } else {
          x.manager_bool = false
        }
      })
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data.map((x, idx) => {
        if (index === idx) {
          x.manager_bool = true
        } else {
          x.manager_bool = false
        }
      })

      set_event_data_list(table_list)
    }
  }
  // reward 변경
  const handleRewardInput = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      if (e.target.value.length < 13) {
        table_list[index2][4].data[index].reward = e.target.value
      }
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      if (e.target.value.length < 13) {
        table_list[index2].data[4].data[index].reward = e.target.value
        table_list[index2].data[4].data[index].reward_change = true
      }
      set_event_data_list(table_list)
    }
  }
  // 입력후 엔터 눌렀을때 input 끄기
  const handleRewardEnter = (type, index, index2) => (e) => {
    if (type === "add") {
      if (e.key === "Enter") {
        let table_list = [...event_data]
        table_list[index2][4].data[index].reward_bool = false
        set_event_data(table_list)
      }
    } else {
      if (e.key === "Enter") {
        let table_list = [...event_data_list]
        table_list[index2].data[4].data[index].reward_bool = false
        set_event_data_list(table_list)
      }
    }
  }
  // 입력후 blur 됬을때 input 끄기
  const handleRewardBlur = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      table_list[index2][4].data[index].reward_bool = false
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data[index].reward_bool = false
      set_event_data_list(table_list)
    }
  }
  // manager 변경
  const handleManagerInput = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      if (e.target.value.length < 13) {
        table_list[index2][4].data[index].manager = e.target.value
      }
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      if (e.target.value.length < 13) {
        table_list[index2].data[4].data[index].manager = e.target.value
        table_list[index2].data[4].data[index].reward_change = true
      }
      set_event_data_list(table_list)
    }
  }
  // 입력후 엔터 눌렀을때 input 끄기
  const handleManagerEnter = (type, index, index2) => (e) => {
    if (type === "add") {
      if (e.key === "Enter") {
        let table_list = [...event_data]
        table_list[index2][4].data[index].manager_bool = false
        set_event_data(table_list)
      }
    } else {
      if (e.key === "Enter") {
        let table_list = [...event_data_list]
        table_list[index2].data[4].data[index].manager_bool = false
        set_event_data_list(table_list)
      }
    }
  }
  // 입력후 blur 됬을때 input 끄기
  const handleManagerBlur = (type, index, index2) => (e) => {
    if (type === "add") {
      let table_list = [...event_data]
      table_list[index2][4].data[index].manager_bool = false
      set_event_data(table_list)
    } else {
      let table_list = [...event_data_list]
      table_list[index2].data[4].data[index].manager_bool = false
      set_event_data_list(table_list)
    }
  }

  // 이메일 대상자 선택된 대상 메일보내기
  const singleSend = (type, event_index) => {
    if (type === "add") {
      if (event_data[0].value === "[조아라]<작품명>") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (event_data[3].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 입력해주세요`)
        set_open_info_dialog(true)
        return
      }
      if (
        event_data[3].value &&
        dayjs(all_event_data.start).format("YY-MM-DD") <=
          dayjs(event_data[3].value).format("YY-MM-DD")
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(editStatus_Apply(false))
        return
      }
      if (event_data[4].data.filter((x) => x.select && !x.reward).length > 0) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`이메일 대상자 개별 리워드를 입력해주세요`)
        set_open_info_dialog(true)
        return
      }

      set_event_single_index(event_index)
      set_send_email_list(arr_name_filter[event_index])
    } else {
      set_event_single_index(event_index)
      set_send_email_list(event_data_list[event_index].data)
    }
    0
    set_email_type("single")
    set_open_send_email_dialog(true)
  }

  // 이메일 대상자 테이블 새로고침
  const reflash = async (type, event_index) => {
    if (type === "add") {
      let select_list = [...event_data]
      let response = []
      if (select_list[event_index][0].send_email_id) {
        dispatch(loading_Apply(true))
        await client
          .query({
            query: eventReceviers,
            variables: {
              eventEmailId: String(select_list[0][event_index].send_email_id),
              mailType: "info",
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let resData = res.data.eventReceviers

            for (let j = 0; j < resData.length; j++) {
              response.push({
                receiverId: resData[j].id,
                real_name: resData[j].name,
                ebook: resData[j].ebook,
                re_email: resData[j].email,
                joara_name: resData[j].joaraName,
                genre: resData[j].genre,
                code: resData[j].code,
                writerId: resData[j].writerId,
                phone: resData[j].phone,
                title: resData[j].title,
                book_code: resData[j].bookCode,
                reward: resData[j].rewarod,
                reward_change: false,
                manager: resData[j].manager,
                send_status: resData[j].status,
                agree_status:
                  resData[j].access === "unidentified"
                    ? "미확인"
                    : resData[j].access === "access"
                    ? "승인"
                    : "거절",
                agree_status_change: false,
                agree_filter_open: false,
                select: false,
              })
            }
            select_list[event_index][4].data = response
            dispatch(loading_Apply(false))
          })
      }
      set_event_data(select_list)
    } else {
      let select_list = [...event_data_list]
      let response = []
      dispatch(loading_Apply(true))
      await client
        .query({
          query: eventReceviers,
          variables: {
            eventEmailId: String(select_list[event_index].data[0].send_email_id),
            mailType: "info",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let resData = res.data.eventReceviers

          for (let j = 0; j < resData.length; j++) {
            response.push({
              receiverId: resData[j].id,
              real_name: resData[j].name,
              ebook: resData[j].ebook,
              re_email: resData[j].email,
              joara_name: resData[j].joaraName,
              genre: resData[j].genre,
              code: resData[j].code,
              writerId: resData[j].writerId,
              phone: resData[j].phone,
              title: resData[j].title,
              book_code: resData[j].bookCode,
              reward: resData[j].rewarod,
              reward_change: false,
              manager: resData[j].manager,
              send_status: resData[j].status,
              agree_status:
                resData[j].access === "unidentified"
                  ? "미확인"
                  : resData[j].access === "access"
                  ? "승인"
                  : "거절",
              agree_status_change: false,
              agree_filter_open: false,
              select: false,
            })
          }
          select_list[event_index].data[4].data = response
          dispatch(loading_Apply(false))
        })
      set_event_data_list(select_list)
    }
  }
  // 이메일 대상자 선택된 대상 삭제
  const deleteSender = (type, event_index) => {
    if (type === "add") {
      let select_list = [...event_data]
      select_list[event_index][4].data = select_list[event_index][4].data.filter((x) => !x.select)
      set_event_data(select_list)
    } else {
      let select_list = [...event_data_list]
      select_list[event_index].data[4].deleteData = select_list[event_index].data[4].data.filter(
        (x) => x.select
      )
      select_list[event_index].data[4].data = select_list[event_index].data[4].data.filter(
        (x) => !x.select
      )
      set_event_data_list(select_list)
    }
  }
  // 안내발송 작업후 이벤트 히스토리 수정
  const updateEventHistory = async (value) => {
    let event_history = JSON.parse(JSON.stringify(all_event_data))
    event_history.history.unshift({
      profile: myData.profile ? myData.profile.url : null,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      content: `${value}`,
      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
    })

    await client.mutate({
      mutation: updateEvent,
      variables: {
        id: event_history.id,
        history: event_history.history,
      },
      fetchPolicy: "network-only",
    })
  }
  // 이벤트 안내 메일 생성후 임시저장
  const createEvent = async (save_type, index) => {
    if (event_data[index][0].value === "[조아라]<작품명>") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`안내 메일명을 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    if (event_data[index][1].value === "") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`발송 이메일 주소를 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    if (event_data[index][2].value === "") {
      set_dialog_title("이메일 발송 오류")
      set_dialog_content(`발송인을 입력해주세요`)
      set_open_info_dialog(true)
      return
    }
    // if (event_data[3].value === "") {
    //   set_dialog_title("이메일 발송 오류")
    //   set_dialog_content(`이벤트 승인 마감일을 입력해주세요`)
    //   set_open_info_dialog(true)
    //   return
    // }
    // if (dayjs(event_data[3].value).format("YYYY.MM.DD (a) hh:mm").length !== 21) {
    //   set_dialog_title("이메일 발송 오류")
    //   set_dialog_content(`이벤트 승인 마감일을 잘못 입력했습니다.`)
    //   set_open_info_dialog(true)
    //   return
    // }
    // if (
    //   event_data[3].value &&
    //   dayjs(all_event_data.start).format("YY-MM-DD") <=
    //     dayjs(event_data[3].value).format("YY-MM-DD")
    // ) {
    //   set_dialog_title("이메일 발송 오류")
    //   set_dialog_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
    //   set_open_info_dialog(true)
    //   return
    // }
    dispatch(loading_Apply(true))
    await client
      .mutate({
        mutation: createEventEmail,
        variables: {
          nameType: event_data[index][0].event_writer_name_type,
          eventId: all_event_data.id,
          title: event_data[index][0].value,
          senderEmail: event_data[index][1].value,
          senderName: event_data[index][2].value,
          endDate: event_data[index][3].value ? event_data[index][3].value : null,
          content: event_data[index][5].value,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        if (event_data[index][4].data.length > 0) {
          let receiver_list = event_data[index][4].data
          for (let i = 0; i < receiver_list.length; i++) {
            await client.mutate({
              mutation: createEventRecevier,
              variables: {
                eventId: String(all_event_data.id),
                genre: receiver_list[i].genre,
                email: receiver_list[i].re_email,
                eventEmailId: res.data.createEventEmail.eventEmail.id,
                joaraName: receiver_list[i].joara_name,
                writerId: String(receiver_list[i].writerId),
                name: receiver_list[i].real_name,
                ebook: receiver_list[i].ebook,
                phone: receiver_list[i].phone,
                title: receiver_list[i].title,
                bookCode: receiver_list[i].book_code,
                manager: receiver_list[i].manager,
                rewarod: receiver_list[i].reward,
                status: receiver_list[i].send_status,
                access:
                  receiver_list[i].agree_status === "미확인"
                    ? "unidentified"
                    : receiver_list[i].agree_status === "승인"
                    ? "access"
                    : "reject",
                agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                code: new Date().getTime().toString(36),
                mailType: "info",
              },
              fetchPolicy: "network-only",
            })
            if (receiver_list.length - 1 === i) {
              let arr_event_data = [...event_data]
              arr_event_data[index][0].id = res.data.createEventEmail.eventEmail.id
              set_event_data(arr_event_data)
              await updateEventHistory("<strong>안내 발송 정보</strong>를 등록하였습니다.")
              // eventDataReset()
              // await startSetting(all_event_data.id)
              // set_type_data("detail")
              dispatch(editStatus_Apply(false))
              dispatch(loading_Apply(false))
            }
          }
        } else {
          let arr_event_data = [...event_data]
          arr_event_data[index][0].id = res.data.createEventEmail.eventEmail.id
          set_event_data(arr_event_data)
          await updateEventHistory("<strong>안내 발송 정보</strong>를 등록하였습니다.")
          // eventDataReset()
          // await startSetting(all_event_data.id)
          // set_type_data("detail")
          dispatch(editStatus_Apply(false))
          dispatch(loading_Apply(false))
        }
      })
  }
  // 이벤트 안내 메일 디테일 임시저장
  const createEventDetailSave = async (event_index, save_type) => {
    if (!save_type) {
      if (event_data_list[event_index].data[0].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (
        event_data_list[event_index].data[0].value === "[조아라]<작품명>" ||
        event_data_list[event_index].data[0].value === "[조아라]<작품명>"
      ) {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`안내 메일명을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (event_data_list[event_index].data[1].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송 이메일 주소를 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      if (event_data_list[event_index].data[2].value === "") {
        set_dialog_title("이메일 발송 오류")
        set_dialog_content(`발송인을 입력해주세요`)
        set_open_info_dialog(true)
        dispatch(loading_Apply(false))
        return
      }
      // if (event_data_list[event_index].data[3].value === "") {
      //   set_dialog_title("이메일 발송 오류")
      //   set_dialog_content(`이벤트 승인 마감일을 입력해주세요`)
      //   set_open_info_dialog(true)
      //   dispatch(loading_Apply(false))
      //   return
      // }
      // if (
      //   dayjs(event_data_list[event_index].data[3].value).format("YYYY.MM.DD (a) hh:mm").length !==
      //   21
      // ) {
      //   set_dialog_title("이메일 발송 오류")
      //   set_dialog_content(`이벤트 승인 마감일을 잘못 입력했습니다.`)
      //   set_open_info_dialog(true)
      //   dispatch(loading_Apply(false))
      //   return
      // }
      // if (
      //   !event_data_list[event_index].data[3].value &&
      //   dayjs(all_event_data.start).format("YY-MM-DD") <=
      //     dayjs(event_data_list[event_index].data[3].value).format("YY-MM-DD")
      // ) {
      //   set_dialog_title("이메일 발송 오류")
      //   set_dialog_content(`이벤트 승인 마감일을 이벤트 시작전 날짜로 입력해주세요`)
      //   set_open_info_dialog(true)
      //   dispatch(editStatus_Apply(false))
      //   return
      // }
      // if (event_data_list[event_index].data[4].data.filter((x) => x.reward === "").length > 0) {
      //   set_dialog_title("이메일 발송 오류")
      //   set_dialog_content(`이메일 대상자 개별 리워드를 입력해주세요`)
      //   set_open_info_dialog(true)
      //   dispatch(loading_Apply(false))
      //   return
      // }
    }
    dispatch(loading_Apply(true))
    let update_event = event_data_list[event_index]
    await client
      .mutate({
        mutation: updateEventEmail,
        variables: {
          id: update_event.data[0].send_email_id,
          nameType: update_event.name_filter,
          eventId: update_event.data[0].event_id,
          title: update_event.data[0].value,
          senderEmail: update_event.data[1].value,
          senderName: update_event.data[2].value,
          endDate: update_event.data[3].value ? update_event.data[3].value : null,
          content: update_event.data[5].value,
        },
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        if (update_event.data[4].data.length > 0) {
          let receiver_list = update_event.data[4].data
          for (let i = 0; i < receiver_list.length; i++) {
            if (!receiver_list[i].receiverId) {
              await client.mutate({
                mutation: createEventRecevier,
                variables: {
                  eventId: String(all_event_data.id),
                  eventEmailId: res.data.updateEventEmail.eventEmail.id,
                  email: receiver_list[i].re_email,
                  name: receiver_list[i].real_name,
                  genre: receiver_list[i].genre,
                  ebook: receiver_list[i].ebook,
                  phone: receiver_list[i].phone,
                  joaraName: receiver_list[i].joara_name,
                  writerId: String(receiver_list[i].writerId),
                  title: receiver_list[i].title,
                  bookCode: receiver_list[i].book_code,
                  rewarod: receiver_list[i].reward,
                  manager: receiver_list[i].manager,
                  status: receiver_list[i].send_status,
                  access:
                    receiver_list[i].agree_status === "미확인"
                      ? "unidentified"
                      : receiver_list[i].agree_status === "승인"
                      ? "access"
                      : "reject",
                  agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                  code: new Date().getTime().toString(36),
                  mailType: "info",
                },
                fetchPolicy: "network-only",
              })
            } else if (
              receiver_list[i].receiverId &&
              (receiver_list[i].reward_change || receiver_list[i].agree_status_change)
            ) {
              await client.mutate({
                mutation: updateEventRecevier,
                variables: {
                  id: receiver_list[i].receiverId,
                  rewarod: receiver_list[i].reward,
                  manager: receiver_list[i].manager,
                  access:
                    receiver_list[i].agree_status === "미확인"
                      ? "unidentified"
                      : receiver_list[i].agree_status === "승인"
                      ? "access"
                      : "reject",
                  agree_date: receiver_list[i].agree_status !== "미확인" ? new Date() : null,
                },
                fetchPolicy: "network-only",
              })
            }
            if (receiver_list.length - 1 === i) {
              if (update_event.data[4].deleteData && update_event.data[4].deleteData.length > 0) {
                for (let k = 0; k < update_event.data[4].deleteData.length; k++) {
                  await client.mutate({
                    mutation: updateEventRecevier,
                    variables: {
                      id: update_event.data[4].deleteData[k].receiverId,
                      eventEmailId: null,
                    },
                    fetchPolicy: "network-only",
                  })
                }
              }
            }
            if (save_type !== "all" && receiver_list.length - 1 === i) {
              await updateEventHistory("<strong>안내 발송 정보</strong>를 수정하였습니다.")
              eventDataReset()
              await startSetting(all_event_data.id)
              set_type_data("detail")
              dispatch(editStatus_Apply(false))
              dispatch(loading_Apply(false))
            }
          }
        } else {
          if (update_event.data[4].deleteData && update_event.data[4].deleteData.length > 0) {
            for (let k = 0; k < update_event.data[4].deleteData.length; k++) {
              await client.mutate({
                mutation: updateEventRecevier,
                variables: {
                  id: update_event.data[4].deleteData[k].receiverId,
                  eventEmailId: null,
                },
                fetchPolicy: "network-only",
              })
            }
          }
          if (save_type !== "all") {
            await updateEventHistory("<strong>안내 발송 정보</strong>를 수정하였습니다.")
            eventDataReset()
            await startSetting(all_event_data.id)
            set_type_data("detail")
            dispatch(editStatus_Apply(false))
            dispatch(loading_Apply(false))
          }
        }
      })
  }
  const allAccess = useCallback((e, idx, type) => {
    if (type === "add") {
      let event_add_data = [...event_data]
      event_add_data[idx][0].access_select_value = e.target.value
      event_add_data[idx][4].data.forEach((x) => {
        if (x.select) {
          x.agree_status = e.target.value
          x.agree_status_change = true
        }
      })

      set_event_data(event_add_data)
    } else {
      let lists = [...event_data_list]
      lists[idx].data[4].access_select_value = e.target.value
      lists[idx].data[4].data.forEach((x) => {
        if (x.select) {
          x.agree_status = e.target.value
          x.agree_status_change = true
        }
      })
      set_event_data_list(lists)
    }
  })

  const handleSave = async (e) => {
    if (event_data.length > 0) {
      let reverse_event = event_data.reverse()
      for (let i = 0; i < reverse_event.length; i++) {
        console.log(reverse_event[i][0].id)
        if (!reverse_event[i][0].id) {
          await createEvent("", i)
        }
      }
    }
    if (event_data_list.length === 0) {
      await updateEventHistory("<strong>안내 발송 정보</strong>를 수정하였습니다.")
      eventDataReset()
      await startSetting(all_event_data.id)
      set_type_data("detail")
      dispatch(editStatus_Apply(false))
      dispatch(loading_Apply(false))
    } else {
      for (let i = 0; i < event_data_list.length; i++) {
        if (event_data_list[i].data[0].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 안내 메일명을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (
          event_data_list[i].data[0].value === "[조아라]<작품명>" ||
          event_data_list[i].data[0].value === "[조아라]<작품명>"
        ) {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 안내 메일명을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (event_data_list[i].data[1].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 발송 이메일 주소를 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
        if (event_data_list[i].data[2].value === "") {
          set_dialog_title("이메일 발송 오류")
          set_dialog_content(`${i + 1}번째의 발송인을 입력해주세요`)
          set_open_info_dialog(true)
          dispatch(loading_Apply(false))
          return
        }
      }
      for (let i = 0; i < event_data_list.length; i++) {
        await createEventDetailSave(i, "all")
        if (event_data_list.length - 1 === i) {
          await updateEventHistory("<strong>안내 발송 정보</strong>를 수정하였습니다.")
          eventDataReset()
          await startSetting(all_event_data.id)
          set_type_data("detail")
          if (e.target.value === "exit") {
            saveExit()
          } else {
            if (document.getElementById("saveEditBtn")) {
              document.getElementById("saveEditBtn").value = ""
            }
          }
          dispatch(editStatus_Apply(false))
          dispatch(loading_Apply(false))
        }
      }
    }
  }

  const InputTypeEl = (data, index, type, index2, list) => {
    if (index < 4) {
      if (type_data === "detail") {
        if (data.type === "monthPicker") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1,
                  mb: 1,
                  color: "#535353",
                }}
              >
                <SuiBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: "#535353",
                  }}
                >
                  <IconButton color="text2" sx={{ mb: 0, mx: 1 }}>
                    <BiCalendar />
                  </IconButton>

                  <div style={{ margin: "0 auto" }}>
                    {data.value
                      ? dayjs(data.value).format(
                          "YYYY.MM.DD  \u00a0\u00a0\u00a0 |\u00a0\u00a0\u00a0 a  \u00a0\u00a0 HH:mm"
                        )
                      : ""}
                  </div>
                </SuiBox>
              </Card>
            </SuiBox>
          )
        } else {
          return (
            <SuiBox sx={{ width: "100%" }}>
              {data.value?.length < 30 ? (
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {data.value}
                </Card>
              ) : (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {data.value}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: 57,
                      backgroundColor: "#F7F9FB",
                      boxShadow: 0,
                      textAlign: "center",
                      fontSize: 18,
                      fontWeight: 700,
                      pt: 1.8,
                      mb: 1,
                      color: "#535353",
                    }}
                  >
                    {data.value}
                  </Card>
                </Tooltip>
              )}
            </SuiBox>
          )
        }
      } else if (type_data === "create" || type_data === "update") {
        if (data.type === "monthPicker") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                <DateTimePicker
                  views={["day", "hours"]}
                  readOnly={list?.data[4]?.data.filter((x) => x.send_status !== "미발송").length}
                  className={
                    list?.data[4]?.data.filter((x) => x.send_status !== "미발송").length
                      ? "event_email_datepicker event_email_datepicker_disabled "
                      : "event_email_datepicker"
                  }
                  inputFormat="YYYY.MM.DD   |   a  HH:mm"
                  mask="____-__-__    |   _  __:__"
                  label={"날짜 선택 | -- -- : --"}
                  value={data.value}
                  disableMaskedInput={true}
                  onChange={handleDatePickerChange(index, type, index2)}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear", "cancel"],
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </SuiBox>
          )
        } else if (data.type === "textFiled") {
          return (
            <SuiBox className={"textFiled-Writer"}>
              <SuiInput
                error={data.error}
                success={data.success}
                helperText={data.error_message}
                type={data.inputType}
                placeholder=""
                value={data.value}
                onChange={
                  list?.data[4]?.data.filter((x) => x.send_status !== "미발송").length
                    ? ""
                    : handleInput(index, data.maxLength, type, index2)
                }
              />
            </SuiBox>
          )
        }
      }
    }
  }
  const Content = () => {
    return (
      <div style={{ paddingBottom: "60px" }}>
        {type_data === "create" || type_data === "update" ? (
          <SuiButton
            sx={{
              mt: 7.5,
              minHeight: 67,
              fontSize: 18,
              fontWeight: 700,
              boxShadow: 3,
              width: "100%",
            }}
            className="outlined-bg-white"
            variant="outlined"
            color="primary"
            onClick={() => addEvent()}
          >
            발송 메일 생성하기
          </SuiButton>
        ) : (
          ""
        )}
        <SuiBox sx={{ width: 100, ml: "auto", mt: 4 }}>
          <FormControl
            sx={{ minHeight: 30, width: "100%" }}
            className={
              sort_events === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => set_filters_open1(!filters_open1)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => set_filters_open1(!filters_open1)}
                />
              )}
              open={filters_open1}
              value={sort_events}
              onChange={(e) => {
                set_sort_events(e.target.value)
                if (e.target.value === "최신순") {
                  startSetting(all_event_data.id)
                } else {
                  startSetting(all_event_data.id, "created_at:Asc")
                }
              }}
              className="setting-border-radius"
            >
              <MenuItem value="최신순">최신순</MenuItem>
              <MenuItem value="오래된 순">오래된 순</MenuItem>
            </Select>
          </FormControl>
        </SuiBox>
        {(type_data === "create" || type_data === "update") &&
          event_data.map((add_event_data, add_index) => {
            return (
              <Card
                sx={{ mt: 2, boxShadow: 0, px: 4, pt: 1, mb: 4, pb: 3 }}
                key={add_index + "[[[;"}
              >
                <SuiBox display="flex" alignItems="center">
                  <input
                    className="addEvent"
                    type="text"
                    placeholder="안내메일 명을 입력해주세요."
                    value={add_event_data[0].value}
                    onChange={handleInput(0, add_event_data[0].maxLength, "add", add_index)}
                  ></input>
                  <SuiBox sx={{ ml: "auto" }}>
                    <SuiButton
                      color="success"
                      variant="text"
                      sx={{ fontSize: "18px", fontWeight: 400 }}
                      onClick={() => createEvent("", add_index)}
                    >
                      임시저장
                    </SuiButton>
                    <SuiButton
                      color="subTitle"
                      variant="text"
                      sx={{ fontSize: "18px", fontWeight: 400 }}
                      onClick={() => cancelEvent(add_index)}
                    >
                      취소
                    </SuiButton>
                    <SuiButton
                      className="only-text-btn"
                      sx={{ fontSize: "18px", fontWeight: 400, pr: 0 }}
                      color="info2"
                      variant="text"
                      disabled={
                        add_event_data[0].all_send
                          ? true
                          : add_event_data[4].data.length === 0
                          ? true
                          : add_event_data[4].data.filter((x) => x.select === false).length > 0
                          ? true
                          : add_event_data[4].data.filter((x) => x.reward === "").length > 0
                      }
                      onClick={() => sendAllEmail("create", add_index)}
                    >
                      전체발송
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
                <Divider sx={{ mb: 4, mt: 1 }} />

                <SuiBox>
                  <SuiTypography
                    fontSize="16px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left" }}
                  >
                    작품 정보
                  </SuiTypography>
                  <Grid container alignItems="center">
                    {add_event_data.map((row, index) => {
                      if (index > 0 && index !== 8) {
                        return (
                          <Grid item xs={row.xs} key={index + "]["} sx={{ pr: 1.5 }}>
                            <SuiBox>
                              <SuiBox display="flex">
                                <SuiTypography
                                  fontSize="15px"
                                  color="info"
                                  sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                                >
                                  {row.title}
                                </SuiTypography>
                              </SuiBox>
                              {InputTypeEl(row, index, "add", add_index)}
                            </SuiBox>
                          </Grid>
                        )
                      }
                    })}
                  </Grid>
                </SuiBox>
                <Divider sx={{ mb: "30px", mt: "30px" }} />
                <SuiBox>
                  <SuiBox display="flex" alignItems="center" sx={{ mb: "16px" }}>
                    <SuiTypography
                      fontSize="16px"
                      fontWeight="bold"
                      color="dark"
                      sx={{ textAlign: "left" }}
                    >
                      이메일 대상자 ({add_event_data[4] ? add_event_data[4].data.length : "0"})
                    </SuiTypography>
                    <SuiBox sx={{ ml: "auto" }}>
                      <IconButton
                        onClick={() => eventDropDown(add_event_data[0].dropdown, "add", add_index)}
                      >
                        {add_event_data[0].dropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
                <SuiBox
                  sx={{
                    boxShadow: 0,
                    display: add_event_data[0].dropdown ? "" : "none",
                  }}
                >
                  <SuiBox display="flex" alignItems="center">
                    <SuiBox sx={{ width: 150 }}>
                      <FormControl
                        sx={{ minHeight: 25, width: "100%" }}
                        className={
                          sort_events === ""
                            ? "setting-selectbox select-none-active"
                            : "setting-selectbox"
                        }
                        onClick={() => {
                          let change = [...event_data]
                          change[add_index][0].fliters_open = !change[add_index][0].fliters_open
                          set_event_data(change)
                        }}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              color="black"
                              className="select-icon cursor"
                              onClick={() => {
                                let change = [...event_data]
                                change[add_index][0].fliters_open =
                                  !change[add_index][0].fliters_open
                                set_event_data(change)
                              }}
                            />
                          )}
                          open={add_event_data[0].fliters_open}
                          value={add_event_data[0].event_writer_name_type}
                          onChange={(e) => {
                            let change = [...event_data]
                            change[add_index][0].event_writer_name_type = e.target.value
                            let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
                            change[add_index][5].value = `<p><strong>${
                              e.target.value
                            }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
                              all_event_data?.flatform
                            }</strong>&nbsp;<strong>${
                              all_event_data?.title
                            }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
                              all_event_data?.title
                            }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
                              all_event_data?.end
                            }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
                              add_event_data[3].value
                                ? dayjs(add_event_data[3].value).format("YYYY.MM.DD(ddd) HH:mm")
                                : "0000.00.00(-) 00:00"
                            }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
                              pathUrl + "/notice/" + "코드_code"
                            }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
                            set_event_data(change)
                          }}
                          className="setting-border-radius"
                        >
                          <MenuItem value="작가 본명">작가 본명</MenuItem>
                          <MenuItem value="이북 필명">이북 필명</MenuItem>
                          <MenuItem value="조아라 필명">조아라 필명</MenuItem>
                        </Select>
                      </FormControl>
                    </SuiBox>

                    {type_data !== "update" && type_data !== "create" ? (
                      ""
                    ) : (
                      <>
                        <SuiBox display="flex" justifyContent="start">
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              ml: 2,
                            }}
                            color="primary"
                            onClick={() => openSingleDialog(add_index, "add")}
                          >
                            <AiOutlinePlus size="20" />
                          </SuiButton>
                        </SuiBox>
                        <SuiBox>
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 180,
                              minWidth: 180,
                              p: 1,
                              ml: 2,
                              border: "1px dashed rgba(92, 56, 255, 0.5) !important",
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={() => excelImport(add_index, "add")}
                          >
                            북코드 / 리워드 업로드
                            <BiUpload size="20" style={{ marginLeft: "5px" }} />
                          </SuiButton>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`event_excel_import_${add_index}_add`}
                            onChange={readExcel}
                          />
                        </SuiBox>
                        {add_event_data[0].excel_name && (
                          <SuiBox
                            display="flex"
                            alignItems="center"
                            sx={{ ml: 2, px: 1, backgroundColor: "#f7f9fb", borderRadius: "5px" }}
                          >
                            <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                              {GetFileIcon("xlsx")}
                            </IconButton>
                            <SuiTypography sx={{ fontSize: "12px" }}>
                              {add_event_data[0].excel_name}
                            </SuiTypography>
                            <IoMdCloseCircle
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => excelCloseClick("add", add_index)}
                            ></IoMdCloseCircle>
                          </SuiBox>
                        )}
                      </>
                    )}
                  </SuiBox>
                  {add_event_data[4].data.length > 0 && (
                    <>
                      <SuiBox display="flex" alignItems="center" sx={{ mt: 3, mb: 1 }}>
                        <SuiBox display="flex" alignItems="center">
                          <SuiTypography
                            fontSize="14px"
                            sx={{ textAlign: "left", color: "#bbbbbb", width: 120 }}
                          >
                            전체:
                          </SuiTypography>
                          <SuiTypography
                            fontSize="14px"
                            fontWeight="bold"
                            color="dark"
                            sx={{ textAlign: "left" }}
                          >
                            {add_event_data[4].data.length}명
                          </SuiTypography>
                        </SuiBox>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ mx: 2, height: 20 }}
                        />
                        <SuiBox display="flex" alignItems="center">
                          <SuiTypography
                            fontSize="14px"
                            sx={{ textAlign: "left", color: "#bbbbbb", width: 120 }}
                          >
                            발송:
                          </SuiTypography>
                          <SuiTypography
                            fontSize="14px"
                            fontWeight="bold"
                            color="success"
                            sx={{ textAlign: "left" }}
                          >
                            {add_event_data[4].data.filter((x) => x.send_status === "완료").length}
                            명
                          </SuiTypography>
                        </SuiBox>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ mx: 2, height: 20 }}
                        />
                        <SuiBox display="flex" alignItems="center">
                          <SuiTypography
                            fontSize="14px"
                            sx={{ textAlign: "left", color: "#bbbbbb", width: 120 }}
                          >
                            미발송:
                          </SuiTypography>
                          <SuiTypography
                            fontSize="14px"
                            fontWeight="bold"
                            color="dark"
                            sx={{ textAlign: "left" }}
                          >
                            {
                              add_event_data[4].data.filter((x) => x.send_status === "미발송")
                                .length
                            }
                            명
                          </SuiTypography>
                        </SuiBox>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ mx: 2, height: 20 }}
                        />
                        <SuiBox display="flex" alignItems="center">
                          <SuiTypography
                            fontSize="14px"
                            sx={{ textAlign: "left", color: "#bbbbbb", width: 120 }}
                          >
                            발송실패:
                          </SuiTypography>
                          <SuiTypography
                            fontSize="14px"
                            fontWeight="bold"
                            color="error"
                            sx={{ textAlign: "left" }}
                          >
                            {add_event_data[4].data.filter((x) => x.send_status === "실패").length}
                            명
                          </SuiTypography>
                        </SuiBox>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ mx: 2, height: 20 }}
                        />
                        <SuiBox display="flex" alignItems="center" sx={{ ml: "auto" }}>
                          <SuiBox sx={{ width: 165, ml: "auto", mr: "5px" }}>
                            <FormControl
                              sx={{ minHeight: 30, width: "100%" }}
                              className={"setting-selectbox"}
                              disabled={add_event_data[4].data.filter((x) => x.select).length === 0}
                              onClick={() => {
                                if (add_event_data[4].data.filter((x) => x.select).length !== 0) {
                                  let change = [...event_data]
                                  change[add_index][0].access_select_value_open =
                                    !change[add_index][0].access_select_value_open
                                  set_event_data(change)
                                }
                              }}
                            >
                              <Select
                                // sx={{
                                //   backgroundColor:
                                //     event_data[4].data.filter((x) => x.select).length === 0
                                //       ? ""
                                //       : "#5c38ff !important",
                                //   "> div": {
                                //     fontSize: "12px",
                                //     color:
                                //       event_data[4].data.filter((x) => x.select).length === 0
                                //         ? ""
                                //         : "#ffffff !important",
                                //   },
                                // }}
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    fontSize="small"
                                    color={
                                      add_event_data[4].data.filter((x) => x.select).length === 0
                                        ? "disabled"
                                        : ""
                                    }
                                    className={
                                      add_event_data[4].data.filter((x) => x.select).length === 0
                                        ? "select-icon"
                                        : "select-icon cursor"
                                    }
                                    onClick={() => {
                                      if (
                                        add_event_data[4].data.filter((x) => x.select).length !== 0
                                      ) {
                                        let change = [...event_data]
                                        change[add_index][0].access_select_value_open =
                                          !change[add_index][0].access_select_value_open
                                        set_event_data(change)
                                      }
                                    }}
                                  />
                                )}
                                open={add_event_data[0].access_select_value_open}
                                value={add_event_data[0].access_select_value}
                                onChange={(e) => allAccess(e, add_index, "add")}
                                className="setting-border-radius"
                              >
                                <MenuItem sx={{ display: "none" }} value="승인여부 상태 변경">
                                  승인여부 상태 변경
                                </MenuItem>
                                <MenuItem value="미확인">미확인</MenuItem>
                                <MenuItem value="승인">승인</MenuItem>
                                <MenuItem value="거절">거절</MenuItem>
                              </Select>
                            </FormControl>
                          </SuiBox>
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              ml: 2,
                              borderRadius: 30,
                            }}
                            color="trash"
                            disabled={add_event_data[4].data.filter((x) => x.select).length === 0}
                            onClick={() => {
                              deleteSender("add", add_index)
                            }}
                          >
                            <FiTrash2 size="20" />
                          </SuiButton>
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              ml: 3,
                              borderRadius: 30,
                            }}
                            color="send"
                            disabled={
                              add_event_data[4].data.filter((x) => x.select).length === 0
                                ? true
                                : add_event_data[4].data.filter((x) => x.select && !x.reward)
                                    .length > 0
                            }
                            style={{ paddingTop: "10px" }}
                            onClick={() => {
                              singleSend("add", add_index)
                            }}
                          >
                            <FiSend size="20" />
                          </SuiButton>
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              ml: 3,
                            }}
                            variant="outlined"
                            style={{ color: "#000000", border: "1.5px solid #c4c4c4" }}
                            onClick={() => {
                              reflash("add", add_index)
                            }}
                          >
                            <MdRefresh size="25" />
                          </SuiButton>
                        </SuiBox>
                        {/* <SuiBox sx={{ width: 70, ml: 3 }}>
                      <FormControl
                        sx={{ minHeight: 25, width: "100%" }}
                        className={
                          sort_events === ""
                            ? "setting-selectbox select-none-active"
                            : "setting-selectbox"
                        }
                        onClick={() => set_filters_open3(!filters_open3)}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              color="black"
                              className="select-icon cursor"
                              onClick={() => set_filters_open3(!filters_open3)}
                            />
                          )}
                          open={filters_open3}
                          value={events_email_rows_page}
                          onChange={(e) => {
                            let arr_email_receiver = [...event_data]
                            set_events_email_rows_page(e.target.value)
                            set_event_table_page(1)
                            set_event_table_total_page(
                              Math.ceil(arr_email_receiver[4].data.length / e.target.value)
                            )
                          }}
                          className="setting-border-radius"
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </SuiBox> */}
                      </SuiBox>

                      <TableContainer
                        sx={{
                          borderTop: "1px solid #F1F1F5",
                          borderRadius: 0,
                          boxShadow: 0,
                          marginRight: "40px",
                          mt: 2,
                          maxHeight: 770,
                          "&::-webkit-scrollbar": {
                            display: "block !important",
                          },
                        }}
                      >
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                          <EnhancedTableHead
                            all_select_click={all_select_click}
                            select_type={{ type: "add", index: add_index }}
                          />
                          <TableBody>
                            {add_event_data[4].data
                              // .slice(
                              //   (event_table_page - 1) * events_email_rows_page,
                              //   (event_table_page - 1) * events_email_rows_page + events_email_rows_page
                              // )
                              .map((data, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    key={index + "p15"}
                                    sx={{ backgroundColor: data.select ? "#FAF8FF" : "" }}
                                  >
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {(event_table_page - 1) * events_email_rows_page + index + 1}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {data.real_name}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {data.ebook}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {data.phone}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {data.title.length > 16 ? (
                                        <Tooltip
                                          arrow={false}
                                          color="white"
                                          classes={{ popper: "tooltip-light" }}
                                          title={
                                            <SuiBox>
                                              <SuiTypography
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                  fontWeight: "bold",
                                                }}
                                                verticalAlign="middle"
                                              >
                                                {data.title}
                                              </SuiTypography>
                                            </SuiBox>
                                          }
                                        >
                                          {data.title.slice(0, 16) + "..."}
                                        </Tooltip>
                                      ) : (
                                        data.title
                                      )}
                                    </TableCell>
                                    {/* <TableCell
                                  align="center"
                                  className="td-Setting td-fontSetting"
                                  sx={{
                                    borderRight: "1px solid #F1F1F5",
                                  }}
                                >
                                  {data.book_code}
                                </TableCell> */}
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                        padding: "5px !important",
                                      }}
                                    >
                                      {data.reward_bool ? (
                                        <SuiBox
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          sx={{
                                            "> div": {
                                              maxWidth: "100px",
                                            },
                                          }}
                                        >
                                          <SuiInput
                                            placeholder=""
                                            value={data.reward}
                                            onChange={handleRewardInput("add", index, add_index)}
                                            onKeyPress={handleRewardEnter("add", index, add_index)}
                                            onBlur={handleRewardBlur("add", index, add_index)}
                                          />
                                        </SuiBox>
                                      ) : (
                                        <SuiBox
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          onClick={clickReward("add", index, add_index)}
                                          sx={{ height: 30, width: "100%" }}
                                        >
                                          {data.reward && data.reward.length > 10
                                            ? data.reward.slice(0, 10) + "..."
                                            : data.reward}
                                        </SuiBox>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                        padding: "5px !important",
                                      }}
                                    >
                                      {/* {data.manager_bool ? ( */}
                                      <SuiBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                          "> div": {
                                            maxWidth: "100px",
                                          },
                                        }}
                                      >
                                        <SuiInput
                                          placeholder=""
                                          value={data.manager}
                                          onChange={handleManagerInput("add", index, add_index)}
                                          onKeyPress={handleManagerEnter("add", index, add_index)}
                                          onBlur={handleManagerBlur("add", index, add_index)}
                                        />
                                      </SuiBox>
                                      {/* ) : (
                                    <SuiBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      onClick={clickManager("add", index)}
                                      sx={{ height: 30, width: "100%" }}
                                    >
                                      {data.manager && data.manager.length > 10
                                        ? data.manager.slice(0, 10) + "..."
                                        : data.manager}
                                    </SuiBox>
                                  )} */}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        color:
                                          data.send_status === "완료" ? "#00C160 !important" : "",
                                        borderRight: "1px solid #F1F1F5",
                                      }}
                                    >
                                      {data.send_status}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="td-Setting td-fontSetting"
                                      sx={{
                                        borderRight: "1px solid #F1F1F5",
                                        padding: "5px !important",
                                      }}
                                    >
                                      <div>
                                        <FormControl
                                          sx={{ minHeight: 30, minWidth: 85, width: "100%" }}
                                          className={
                                            sort_events === ""
                                              ? "setting-selectbox select-none-active"
                                              : "setting-selectbox"
                                          }
                                          onClick={() => {
                                            let arr_event_data = [...event_data]
                                            arr_event_data[add_index][4].data[
                                              index
                                            ].agree_filter_open =
                                              !arr_event_data[add_index][4].data[index]
                                                .agree_filter_open
                                            set_event_data(arr_event_data)
                                          }}
                                        >
                                          <Select
                                            IconComponent={() => (
                                              <KeyboardArrowDownIcon
                                                fontSize="small"
                                                color="black"
                                                className="select-icon cursor"
                                                onClick={() => {
                                                  let arr_event_data = [...event_data]
                                                  arr_event_data[add_index][4].data[
                                                    index
                                                  ].agree_filter_open =
                                                    !arr_event_data[add_index][4].data[index]
                                                      .agree_filter_open
                                                  set_event_data(arr_event_data)
                                                }}
                                              />
                                            )}
                                            open={data.agree_filter_open}
                                            value={data.agree_status}
                                            onChange={(e) => {
                                              let arr_event_data = [...event_data]
                                              arr_event_data[add_index][4].data[
                                                index
                                              ].agree_status = e.target.value
                                              set_event_data(arr_event_data)
                                            }}
                                            className="setting-border-radius"
                                          >
                                            <MenuItem value="미확인">미확인</MenuItem>
                                            <MenuItem value="승인">승인</MenuItem>
                                            <MenuItem value="거절">거절</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </TableCell>
                                    <TableCell align="center" className="td-Setting td-fontSetting">
                                      <Checkbox
                                        checked={data.select}
                                        onChange={handleCheckBox("add", index, add_index)}
                                        color="send"
                                        label=""
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <SuiBox mt={2} display="flex" justifyContent="center">
                    <PaginationCustom
                      page={event_table_page}
                      totalPage={event_table_total_page}
                      changePage={eventTablePage("add")}
                    />
                  </SuiBox> */}
                    </>
                  )}
                  <Divider sx={{ mb: "30px", mt: "30px" }} />
                  <SuiBox>
                    <SuiBox display="flex" alignItems="center" sx={{ mt: 3 }}>
                      <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#84a2b1" }}>
                        이메일 내용:
                      </SuiTypography>
                      <SuiBox sx={{ ml: "auto", mb: "10px" }}>
                        {add_event_data[5].change ? (
                          <SuiButton
                            color="update"
                            variant="contained"
                            sx={{
                              fontSize: "15px",
                              fontWeight: 400,
                              padding: "0px 10px",
                              color: "#E67DAF",
                              mb: "10px",
                              height: "30px",
                              minHeight: "30px",
                            }}
                            onClick={() => {
                              let change = [...event_data]
                              change[add_index][5].change = false
                              set_event_data(change)
                            }}
                          >
                            수정하기
                          </SuiButton>
                        ) : (
                          <>
                            <SuiButton
                              color="subTitle"
                              variant="text"
                              sx={{
                                fontSize: "15px",
                                fontWeight: 400,
                                pr: 0,
                                justifyContent: "end",
                              }}
                              onClick={() => {
                                let change = [...event_data]
                                let replace_content = all_event_data?.content.replaceAll(
                                  "\n",
                                  "<br/>"
                                )
                                change[add_index][5].change = true
                                change[add_index][5].value = `<p><strong>${
                                  events_email_receiver ? events_email_receiver : "작가 본명"
                                }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
                                  all_event_data?.flatform
                                }</strong>&nbsp;<strong>${
                                  all_event_data?.title
                                }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
                                  all_event_data?.title
                                }</strong></p><p>- 이벤트 기간: <strong>${
                                  all_event_data?.start
                                } ~ ${
                                  all_event_data?.end
                                }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
                                  add_event_data[3].value
                                    ? dayjs(add_event_data[3].value).format("YYYY.MM.DD(ddd) HH:mm")
                                    : "0000.00.00(-) 00:00"
                                }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
                                  pathUrl + "/notice/" + "코드_code"
                                }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
                                set_event_data(change)
                              }}
                            >
                              취소
                            </SuiButton>
                            <SuiButton
                              color="search"
                              variant="text"
                              sx={{
                                fontSize: "15px",
                                fontWeight: 400,
                                pr: 0,
                                justifyContent: "end",
                              }}
                              onClick={() => {
                                let change = [...event_data]
                                change[add_index][5].change = true
                                set_event_data(change)
                              }}
                            >
                              저장
                            </SuiButton>
                          </>
                        )}
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      sx={{ mb: "30px" }}
                    >
                      <SuiTypography
                        color="error"
                        variant="text"
                        sx={{ fontSize: "15px", fontWeight: 400, pl: "12px" }}
                      >
                        '수정하기' 사용시 자동입력 양식이 삭제될 수 있습니다.
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox>
                    {add_event_data[5].change ? (
                      <div
                        style={{
                          backgroundColor: "#f7f9fb",
                          fontSize: "14px",
                          textAlign: "start",
                          padding: "12px",
                          borderRadius: "15px",
                        }}
                        dangerouslySetInnerHTML={{ __html: add_event_data[5].value }}
                      ></div>
                    ) : (
                      <ReactQuill
                        readOnly={add_event_data[5].change}
                        className="Quill-layout color_position"
                        modules={modules}
                        theme="snow"
                        value={add_event_data[5].value}
                        formats={formats}
                        onChange={quillChange("add", add_index)}
                        ref={quillRef}
                      />
                    )}
                  </SuiBox>
                </SuiBox>
              </Card>
            )
          })}
        {event_data_list.map((list, event_index) => {
          return (
            <Card sx={{ mt: 2, boxShadow: 0, px: 4, pt: 1, pb: 3 }}>
              <SuiBox display="flex" alignItems="center">
                {list.data[4].data.filter((x) => x.send_status !== "미발송").length > 0 && (
                  <span
                    style={{
                      color: "#0C65FF",
                      marginRight: "5px",
                      lineHeight: "48px",
                      fontWeight: "bold",
                    }}
                  >
                    {String(list.num).length === 1
                      ? `[00${list.num}]`
                      : String(list.num).length === 2
                      ? `[0${list.num}]`
                      : `[${list.num}]`}
                  </span>
                )}
                <input
                  className="addEvent"
                  type="text"
                  placeholder="안내메일 명을 입력해주세요."
                  readOnly={
                    list.data[4].data.filter((x) => x.send_status !== "미발송").length ||
                    type_data === "detail"
                  }
                  value={list.data[0].value}
                  onChange={handleInput(0, list.data[0].maxLength, "detail", event_index)}
                ></input>
                <SuiBox sx={{ ml: "auto" }}>
                  {type_data !== "detail" && (
                    <>
                      {list.data[4].data.filter((x) => x.send_status !== "미발송").length === 0 ? (
                        <>
                          <SuiButton
                            color="success"
                            variant="text"
                            sx={{ fontSize: "18px", fontWeight: 400 }}
                            onClick={() => createEventDetailSave(event_index)}
                          >
                            임시저장
                          </SuiButton>
                          <SuiButton
                            color="subTitle"
                            variant="text"
                            sx={{ fontSize: "18px", fontWeight: 400 }}
                            onClick={() => deleteEvent(event_index)}
                          >
                            취소
                          </SuiButton>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {list.data[4].data.filter((x) => x.send_status !== "미발송").length !== 0 && (
                    <SuiButton
                      color="green2"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        width: 80,
                        p: 0,
                      }}
                      onClick={() => sendHistory(event_index)}
                    >
                      발송 내역
                    </SuiButton>
                  )}

                  {type_data !== "detail" && !list.num && (
                    <SuiButton
                      className="only-text-btn"
                      sx={{ fontSize: "18px", fontWeight: 400, pr: 0 }}
                      color="info2"
                      variant="text"
                      disabled={
                        list.data[4].data.length === 0
                          ? true
                          : list.data[4].data.filter((x) => x.select === false).length > 0
                          ? true
                          : list.data[4].data.filter((x) => x.reward === "").length > 0
                      }
                      onClick={() => sendAllEmail("update", event_index)}
                    >
                      전체발송
                    </SuiButton>
                  )}
                </SuiBox>
              </SuiBox>
              <Divider sx={{ mb: 4, mt: 1 }} />

              <SuiBox>
                <SuiTypography
                  fontSize="16px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ textAlign: "left" }}
                >
                  작품 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  {list.data.map((row, index) => {
                    if (index > 0 && index !== 8) {
                      return (
                        <Grid item xs={row.xs} key={index + "]"} sx={{ pr: 1.5 }}>
                          <SuiBox>
                            <SuiBox display="flex">
                              <SuiTypography
                                fontSize="15px"
                                color="info"
                                sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
                              >
                                {row.title}
                              </SuiTypography>
                            </SuiBox>
                            {InputTypeEl(row, index, "detail", event_index, list)}
                          </SuiBox>
                        </Grid>
                      )
                    }
                  })}
                </Grid>
              </SuiBox>
              <Divider sx={{ mb: "30px", mt: "30px" }} />
              <SuiBox>
                <SuiBox display="flex" alignItems="center" sx={{ mb: "16px" }}>
                  <SuiTypography
                    fontSize="16px"
                    fontWeight="bold"
                    color="dark"
                    sx={{ textAlign: "left" }}
                  >
                    이메일 대상자 ({list.data[4] ? list.data[4].data.length : "0"})
                  </SuiTypography>
                  <SuiBox sx={{ ml: "auto" }}>
                    <IconButton
                      onClick={() => eventDropDown(list.data[0].dropdown, "detail", event_index)}
                    >
                      {list.data[0].dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
              <SuiBox
                sx={{
                  boxShadow: 0,
                  display: list.data[0].dropdown ? "" : "none",
                }}
              >
                <SuiBox display="flex" alignItems="center">
                  <SuiBox sx={{ width: 150 }}>
                    <FormControl
                      sx={{
                        minHeight: 25,
                        width: "100%",
                        "> .MuiOutlinedInput-root": {
                          backgroundColor: type_data === "detail" ? "#F7F9FB !important" : "",
                        },
                      }}
                      className={
                        sort_events === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      readOnly={type_data !== "detail"}
                      onClick={() => {
                        if (type_data !== "detail") {
                          let change = [...event_data_list]
                          change[event_index].name_filter_bool =
                            !change[event_index].name_filter_bool
                          set_event_data_list(change)
                        }
                      }}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            readOnly={type_data !== "detail"}
                            className="select-icon cursor"
                            onClick={() => {
                              if (type_data !== "detail") {
                                let change = [...event_data_list]
                                change[event_index].name_filter_bool =
                                  !change[event_index].name_filter_bool
                                set_event_data_list(change)
                              }
                            }}
                          />
                        )}
                        open={list.name_filter_bool}
                        value={list.name_filter}
                        onChange={(e) => {
                          let change = [...event_data_list]
                          let replace_content = all_event_data?.content.replaceAll("\n", "<br/>")
                          change[event_index].name_filter = e.target.value
                          change[event_index].data[5].value = `<p><strong>${
                            e.target.value
                          }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
                            all_event_data?.flatform
                          }</strong>&nbsp;<strong>${
                            all_event_data?.title
                          }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
                            all_event_data?.title
                          }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
                            all_event_data?.end
                          }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
                            change[event_index].data[3].value
                              ? dayjs(change[event_index].data[3].value).format(
                                  "YYYY.MM.DD(ddd) HH:mm"
                                )
                              : "0000.00.00(-) 00:00"
                          }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
                            pathUrl + "/notice/" + "코드_code"
                          }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
                          set_event_data_list(change)
                        }}
                        className="setting-border-radius"
                      >
                        <MenuItem value="작가 본명">작가 본명</MenuItem>
                        <MenuItem value="이북 필명">이북 필명</MenuItem>
                        <MenuItem value="조아라 필명">조아라 필명</MenuItem>
                      </Select>
                    </FormControl>
                  </SuiBox>

                  {type_data !== "update" && type_data !== "create" ? (
                    ""
                  ) : (
                    <>
                      <SuiBox display="flex" justifyContent="start">
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            p: 1,
                            ml: 2,
                            display:
                              list.data[4].data.filter((x) => x.send_status !== "미발송").length > 0
                                ? "none"
                                : "",
                          }}
                          color="primary"
                          onClick={() => openSingleDialog(event_index)}
                        >
                          <AiOutlinePlus size="20" />
                        </SuiButton>
                      </SuiBox>
                      <SuiBox>
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 180,
                            minWidth: 180,
                            p: 1,
                            ml: 2,
                            border: "1px dashed rgba(92, 56, 255, 0.5) !important",
                            display:
                              list.data[4].data.filter((x) => x.send_status !== "미발송").length > 0
                                ? "none"
                                : "",
                          }}
                          color="primary"
                          variant="outlined"
                          onClick={() => excelImport(event_index)}
                        >
                          북코드 / 리워드 업로드
                          <BiUpload size="20" style={{ marginLeft: "5px" }} />
                        </SuiButton>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id={`event_excel_import_${event_index}`}
                          onChange={readExcel}
                        />
                      </SuiBox>
                      {list.excel_name && (
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          sx={{ ml: 2, px: 1, backgroundColor: "#f7f9fb", borderRadius: "5px" }}
                        >
                          <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                            {GetFileIcon("xlsx")}
                          </IconButton>
                          <SuiTypography sx={{ fontSize: "12px" }}>{list.excel_name}</SuiTypography>
                          <IoMdCloseCircle
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => excelCloseClick("detail", event_index)}
                          ></IoMdCloseCircle>
                        </SuiBox>
                      )}
                    </>
                  )}
                </SuiBox>
                {list.data[4].data.length > 0 && (
                  <>
                    <SuiBox display="flex" alignItems="center" sx={{ mt: 3, mb: 1 }}>
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{
                            textAlign: "left",
                            color: type_data === "detail" ? "#707070" : "#bbbbbb",
                            width: 120,
                          }}
                        >
                          전체:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="dark"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.length}명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{
                            textAlign: "left",
                            color: type_data === "detail" ? "#707070" : "#bbbbbb",
                            width: 120,
                          }}
                        >
                          발송:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="success"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.filter((x) => x.send_status === "완료").length}명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{
                            textAlign: "left",
                            color: type_data === "detail" ? "#707070" : "#bbbbbb",
                            width: 120,
                          }}
                        >
                          미발송:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="dark"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.filter((x) => x.send_status === "미발송").length}명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography
                          fontSize="14px"
                          sx={{
                            textAlign: "left",
                            color: type_data === "detail" ? "#707070" : "#bbbbbb",
                            width: 120,
                          }}
                        >
                          발송실패:
                        </SuiTypography>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="error"
                          sx={{ textAlign: "left" }}
                        >
                          {list.data[4].data.filter((x) => x.send_status === "실패").length}명
                        </SuiTypography>
                      </SuiBox>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ mx: 2, height: 20 }}
                      />
                      <SuiBox display="flex" alignItems="center" sx={{ ml: "auto" }}>
                        {type_data !== "detail" && (
                          <>
                            <SuiBox sx={{ width: 165, mr: "5px" }}>
                              <FormControl
                                sx={{ minHeight: 30, width: "100%" }}
                                className={"setting-selectbox"}
                                disabled={list.data[4].data.filter((x) => x.select).length === 0}
                                onClick={() => {
                                  if (list.data[4].data.filter((x) => x.select).length !== 0) {
                                    let lists = [...event_data_list]
                                    lists[event_index].data[4].access_select_bool =
                                      !lists[event_index].data[4].access_select_bool
                                    set_event_data_list(lists)
                                  }
                                }}
                              >
                                <Select
                                  // sx={{
                                  //   backgroundColor:
                                  //     list.data[4].data.filter((x) => x.select).length === 0
                                  //       ? ""
                                  //       : "#5c38ff !important",

                                  //   "> div": {
                                  //     fontSize: "12px",
                                  //     color:
                                  //       list.data[4].data.filter((x) => x.select).length === 0
                                  //         ? ""
                                  //         : "#ffffff !important",
                                  //   },
                                  // }}
                                  IconComponent={() => (
                                    <KeyboardArrowDownIcon
                                      fontSize="small"
                                      color={
                                        list.data[4].data.filter((x) => x.select).length === 0
                                          ? "disabled"
                                          : ""
                                      }
                                      className={
                                        list.data[4].data.filter((x) => x.select).length === 0
                                          ? "select-icon"
                                          : "select-icon cursor"
                                      }
                                      onClick={() => {
                                        if (
                                          list.data[4].data.filter((x) => x.select).length !== 0
                                        ) {
                                          let lists = [...event_data_list]
                                          lists[event_index].data[4].access_select_bool =
                                            !lists[event_index].data[4].access_select_bool
                                          set_event_data_list(lists)
                                        }
                                      }}
                                    />
                                  )}
                                  open={list.data[4].access_select_bool}
                                  value={list.data[4].access_select_value}
                                  onChange={(e) => allAccess(e, event_index)}
                                  className="setting-border-radius"
                                >
                                  <MenuItem sx={{ display: "none" }} value="승인여부 상태 변경">
                                    승인여부 상태 변경
                                  </MenuItem>
                                  <MenuItem value="미확인">미확인</MenuItem>
                                  <MenuItem value="승인">승인</MenuItem>
                                  <MenuItem value="거절">거절</MenuItem>
                                </Select>
                              </FormControl>
                            </SuiBox>
                            <SuiButton
                              sx={{
                                minHeight: 32,
                                height: 32,
                                width: 32,
                                minWidth: 32,
                                p: 1,
                                ml: 2,
                                borderRadius: 30,
                              }}
                              color="trash"
                              disabled={
                                list.data[4].data.filter((x) => x.select).length === 0
                                  ? true
                                  : list.data[4].data.filter(
                                      (x) => x.select && x.send_status === "완료"
                                    ).length > 0
                              }
                              onClick={() => deleteSender("detail", event_index)}
                            >
                              <FiTrash2 size="20" />
                            </SuiButton>
                            <SuiButton
                              sx={{
                                minHeight: 32,
                                height: 32,
                                width: 32,
                                minWidth: 32,
                                p: 1,
                                ml: 3,
                                borderRadius: 30,
                              }}
                              color="send"
                              disabled={
                                list.data[4].data.filter((x) => x.select).length === 0
                                  ? true
                                  : list.data[4].data.filter((x) => x.select && !x.reward).length >
                                    0
                              }
                              style={{ paddingTop: "10px" }}
                              onClick={() => singleSend("detail", event_index)}
                            >
                              <FiSend size="20" />
                            </SuiButton>
                          </>
                        )}
                        <SuiButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            p: 1,
                            ml: 3,
                          }}
                          variant="outlined"
                          style={{ color: "#000000", border: "1.5px solid #c4c4c4" }}
                          onClick={() => reflash("detail", event_index)}
                        >
                          <MdRefresh size="25" />
                        </SuiButton>
                      </SuiBox>
                      {/* <SuiBox sx={{ width: 70, ml: 3 }}>
                        <FormControl
                          sx={{ minHeight: 25, width: "100%" }}
                          className={
                            sort_events === ""
                              ? "setting-selectbox select-none-active"
                              : "setting-selectbox"
                          }
                          onClick={() => {
                            let change = [...event_data_list]
                            change[event_index].table_rows_bool =
                              !change[event_index].table_rows_bool
                            set_event_data_list(change)
                          }}
                        >
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                fontSize="small"
                                color="black"
                                className="select-icon cursor"
                                onClick={() => {
                                  let change = [...event_data_list]
                                  change[event_index].table_rows_bool =
                                    !change[event_index].table_rows_bool
                                  set_event_data_list(change)
                                }}
                              />
                            )}
                            open={list.table_rows_bool}
                            value={list.table_rows}
                            onChange={(e) => {
                              let change = [...event_data_list]
                              change[event_index].table_rows = e.target.value
                              change[event_index].table_current_page = 1
                              change[event_index].table_total_page = Math.ceil(
                                change[event_index].data[4].data.length / e.target.value
                              )

                              set_event_data_list(change)
                            }}
                            className="setting-border-radius"
                          >
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                          </Select>
                        </FormControl>
                      </SuiBox> */}
                    </SuiBox>

                    <TableContainer
                      sx={{
                        borderTop: "1px solid #F1F1F5",
                        borderRadius: 0,
                        boxShadow: 0,
                        marginRight: "40px",
                        mt: 2,
                        maxHeight: 770,
                        "&::-webkit-scrollbar": {
                          display: "block !important",
                        },
                      }}
                    >
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                        <EnhancedTableHead
                          all_select_click={all_select_click}
                          select_type={{ type: "detail", index: event_index }}
                          type={type_data}
                        />
                        <TableBody>
                          {list.data[4].data
                            // .slice(
                            //   (list.table_current_page - 1) * list.table_rows,
                            //   (list.table_current_page - 1) * list.table_rows + list.table_rows
                            // )
                            .map((data, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  key={index + "p1"}
                                  sx={{ backgroundColor: data.select ? "#FAF8FF" : "" }}
                                >
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {(list.table_current_page - 1) * list.table_rows + index + 1}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.real_name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.ebook}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.title.length > 16 ? (
                                      <Tooltip
                                        arrow={false}
                                        color="white"
                                        classes={{ popper: "tooltip-light" }}
                                        title={
                                          <SuiBox>
                                            <SuiTypography
                                              style={{
                                                fontSize: "14px",
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                              verticalAlign="middle"
                                            >
                                              {data.title}
                                            </SuiTypography>
                                          </SuiBox>
                                        }
                                      >
                                        {data.title.slice(0, 16) + "..."}
                                      </Tooltip>
                                    ) : (
                                      data.title
                                    )}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.book_code}
                                  </TableCell> */}
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      padding: "5px !important",
                                    }}
                                  >
                                    {/* &&
                                     list.data[4].data.filter((x) => x.send_status !== "미발송")
                                       .length === 0  */}
                                    {type_data !== "detail" ? (
                                      <>
                                        {data.reward_bool ? (
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                          >
                                            <SuiInput
                                              placeholder=""
                                              value={data.reward}
                                              onChange={handleRewardInput(
                                                "detail",
                                                index,
                                                event_index
                                              )}
                                              onKeyPress={handleRewardEnter(
                                                "detail",
                                                index,
                                                event_index
                                              )}
                                              onBlur={handleRewardBlur(
                                                "detail",
                                                index,
                                                event_index
                                              )}
                                            />
                                          </SuiBox>
                                        ) : (
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            onClick={clickReward("detail", index, event_index)}
                                            sx={{ height: 30, width: "100%" }}
                                          >
                                            {data.reward && data.reward.length > 10
                                              ? data.reward.slice(0, 10) + "..."
                                              : data.reward}
                                          </SuiBox>
                                        )}
                                      </>
                                    ) : (
                                      <SuiBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: 30, width: "100%" }}
                                      >
                                        {data.reward}
                                      </SuiBox>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      padding: "5px !important",
                                    }}
                                  >
                                    {/* &&
                                     list.data[4].data.filter((x) => x.send_status !== "미발송")
                                       .length === 0  */}
                                    {type_data !== "detail" ? (
                                      <>
                                        {/* {data.manager_bool ? ( */}
                                        <SuiBox
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          sx={{
                                            "> div": {
                                              maxWidth: "100px",
                                            },
                                          }}
                                        >
                                          <SuiInput
                                            placeholder=""
                                            value={data.manager}
                                            onChange={handleManagerInput(
                                              "detail",
                                              index,
                                              event_index
                                            )}
                                            onKeyPress={handleManagerEnter(
                                              "detail",
                                              index,
                                              event_index
                                            )}
                                            onBlur={handleManagerBlur("detail", index, event_index)}
                                          />
                                        </SuiBox>
                                        {/* ) : (
                                          <SuiBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            onClick={clickManager("detail", index, event_index)}
                                            sx={{ height: 30, width: "100%" }}
                                          >
                                            {data.manager && data.manager.length > 10
                                              ? data.manager.slice(0, 10) + "..."
                                              : data.manager}
                                          </SuiBox>
                                        )} */}
                                      </>
                                    ) : (
                                      <SuiBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: 30, width: "100%" }}
                                      >
                                        {data.manager}
                                      </SuiBox>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      color:
                                        data.send_status === "완료" ? "#00C160 !important" : "",
                                      borderRight: "1px solid #F1F1F5",
                                    }}
                                  >
                                    {data.send_status}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    sx={{
                                      borderRight: "1px solid #F1F1F5",
                                      padding: "5px !important",
                                    }}
                                  >
                                    {type_data !== "detail" ? (
                                      <div>
                                        <FormControl
                                          sx={{ minHeight: 30, minWidth: 85, width: "100%" }}
                                          className={
                                            sort_events === ""
                                              ? "setting-selectbox select-none-active"
                                              : "setting-selectbox"
                                          }
                                          readOnly={type_data !== "detail"}
                                          onClick={() => {
                                            if (type_data !== "detail") {
                                              let arr_event_data = [...event_data_list]
                                              arr_event_data[event_index].data[4].data[
                                                index
                                              ].agree_filter_open =
                                                !arr_event_data[event_index].data[4].data[index]
                                                  .agree_filter_open
                                              set_event_data_list(arr_event_data)
                                            }
                                          }}
                                        >
                                          <Select
                                            IconComponent={() => (
                                              <KeyboardArrowDownIcon
                                                fontSize="small"
                                                color="black"
                                                className="select-icon cursor"
                                                readOnly={type_data !== "detail"}
                                                onClick={() => {
                                                  if (type_data !== "detail") {
                                                    let arr_event_data = [...event_data_list]
                                                    arr_event_data[event_index].data[4].data[
                                                      index
                                                    ].agree_filter_open =
                                                      !arr_event_data[event_index].data[4].data[
                                                        index
                                                      ].agree_filter_open
                                                    set_event_data_list(arr_event_data)
                                                  }
                                                }}
                                              />
                                            )}
                                            open={data.agree_filter_open}
                                            value={data.agree_status}
                                            onChange={(e) => {
                                              let arr_event_data = [...event_data_list]
                                              arr_event_data[event_index].data[4].data[
                                                index
                                              ].agree_status = e.target.value
                                              arr_event_data[event_index].data[4].data[
                                                index
                                              ].agree_status_change = true

                                              set_event_data_list(arr_event_data)
                                            }}
                                            className="setting-border-radius"
                                          >
                                            <MenuItem value="미확인">미확인</MenuItem>
                                            <MenuItem value="승인">승인</MenuItem>
                                            <MenuItem value="거절">거절</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                    ) : data.agree_status === "미확인" ? (
                                      <SuiBox>{data.agree_status}</SuiBox>
                                    ) : (
                                      <SuiBox
                                        sx={{
                                          color:
                                            data.agree_status === "승인"
                                              ? "#00C160 !important"
                                              : "#FF7B89 !important",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(`/noticeDetail/${data.code}`)}
                                      >
                                        {data.agree_status}
                                      </SuiBox>
                                    )}
                                  </TableCell>
                                  {type_data !== "detail" && (
                                    <TableCell align="center" className="td-Setting td-fontSetting">
                                      <Checkbox
                                        checked={data.select}
                                        onChange={handleCheckBox("detail", index, event_index)}
                                        color="send"
                                        label=""
                                      />
                                    </TableCell>
                                  )}
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <SuiBox mt={2} display="flex" justifyContent="center">
                      <PaginationCustom
                        page={list.table_current_page}
                        totalPage={list.table_total_page}
                        changePage={eventTablePage("detail", event_index)}
                      />
                    </SuiBox> */}
                  </>
                )}
                <Divider sx={{ mb: "30px", mt: "30px" }} />
                <SuiBox>
                  <SuiBox display="flex" alignItems="center" sx={{ mt: 3 }}>
                    <SuiTypography fontSize="14px" sx={{ textAlign: "left", color: "#84a2b1" }}>
                      이메일 내용:
                    </SuiTypography>
                    <SuiBox sx={{ ml: "auto", mb: "10px" }}>
                      {list.data[5].change ? (
                        <SuiButton
                          color="update"
                          variant="contained"
                          sx={{
                            fontSize: "15px",
                            fontWeight: 400,
                            padding: "0px 10px",
                            color: "#E67DAF",
                            mb: "10px",
                            height: "30px",
                            minHeight: "30px",
                          }}
                          disabled={
                            type_data === "detail"
                              ? true
                              : list?.data[4]?.data.filter((x) => x.send_status !== "미발송")
                                  .length !== 0
                          }
                          onClick={() => {
                            if (
                              list?.data[4]?.data.filter((x) => x.send_status !== "미발송")
                                .length === 0
                            ) {
                              let change = [...event_data_list]
                              change[event_index].data[5].change = false
                              set_event_data_list(change)
                            }
                          }}
                        >
                          수정하기
                        </SuiButton>
                      ) : (
                        <>
                          <SuiButton
                            color="subTitle"
                            variant="text"
                            sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                            onClick={() => {
                              let change = [...event_data_list]
                              let replace_content = all_event_data?.content.replaceAll(
                                "\n",
                                "<br/>"
                              )
                              change[event_index].data[5].change = true
                              change[event_index].data[5].value = `<p><strong>${
                                events_email_receiver ? events_email_receiver : "작가 본명"
                              }</strong> 작가님 안녕하세요.</p><p>조아라 <strong>담당자 </strong>입니다</p><p>&nbsp;&nbsp;&nbsp;</p><p><strong>${
                                all_event_data?.flatform
                              }</strong>&nbsp;<strong>${
                                all_event_data?.title
                              }</strong> 진행을 위해</p><p><strong>작품 명</strong> 작품의 참여 승인을 요청드립니다.</p><p><br></p><p>승인 필요사항</p><p>- 이벤트명 :<strong> ${
                                all_event_data?.title
                              }</strong></p><p>- 이벤트 기간: <strong>${all_event_data?.start} ~ ${
                                all_event_data?.end
                              }</strong></p><p>- 리워드 :<strong> 이벤트 내용 참고</strong></p><p>- 이벤트 내용 :</p><p><strong>${replace_content}</strong></p>&nbsp;&nbsp;&nbsp;</p><br><p>이와 같이 이벤트 내용을 전달드리오니</p><p>아래의 연결 링크를 통해 승인 여부를 <strong style="color: #ff0000de";>${
                                change[event_index].data[3].value
                                  ? dayjs(change[event_index].data[3].value).format(
                                      "YYYY.MM.DD(ddd) HH:mm"
                                    )
                                  : "0000.00.00(-) 00:00"
                              }</strong>까지 답변하여 주시길 부탁드립니다.</p><p>&nbsp;&nbsp;&nbsp;</p><p><a href="${
                                pathUrl + "/notice/" + "코드_code"
                              }" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 102, 204); text-decoration: underline !important;">승인 비승인 통보하기</a></p><p>&nbsp;&nbsp;&nbsp;</p><p>감사합니다.</p><p><br></p><p><strong>조아라 담당자 드림</strong></p>`
                              set_event_data_list(change)
                            }}
                          >
                            취소
                          </SuiButton>
                          <SuiButton
                            color="search"
                            variant="text"
                            sx={{ fontSize: "15px", fontWeight: 400, pr: 0, justifyContent: "end" }}
                            onClick={() => {
                              let change = [...event_data_list]
                              change[event_index].data[5].change = true
                              set_event_data_list(change)
                            }}
                          >
                            저장
                          </SuiButton>
                        </>
                      )}
                    </SuiBox>
                  </SuiBox>
                  <SuiBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    sx={{ mb: "30px" }}
                  >
                    <SuiTypography
                      color="error"
                      variant="text"
                      sx={{ fontSize: "15px", fontWeight: 400, pl: "12px" }}
                    >
                      '수정하기' 사용시 자동입력 양식이 삭제될 수 있습니다.
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiBox>
                  {list.data[5].change ? (
                    <div
                      style={{
                        backgroundColor: "#f7f9fb",
                        fontSize: "14px",
                        textAlign: "start",
                        padding: "12px",
                        borderRadius: "15px",
                      }}
                      dangerouslySetInnerHTML={{ __html: list.data[5].value }}
                    ></div>
                  ) : (
                    <ReactQuill
                      readOnly={list.data[5].change}
                      className="Quill-layout color_position"
                      modules={modules}
                      theme="snow"
                      value={list.data[5].value}
                      formats={formats}
                      onChange={quillChange("detail", event_index)}
                      ref={quillRef}
                    />
                  )}
                </SuiBox>
              </SuiBox>
            </Card>
          )
        })}
        {event_data_list.length === 0 && type_data !== "create" && type_data !== "update" ? (
          <SuiTypography
            fontSize="18px"
            fontWeight="regular"
            color="text2"
            sx={{ textAlign: "left" }}
          >
            메일 생성 이력이 없습니다.
          </SuiTypography>
        ) : null}
      </div>
    )
  }
  const authCheck = () => {
    if (type_data !== "create") {
      if (
        myData.role.name === "Authenticated" ||
        myData.role.name === "gold" ||
        myData.role.name === "silver"
      ) {
        return true
      }
    }
  }
  const startEdit = () => {
    dispatch(loading_Apply(true))
    set_type_data("update")
    dispatch(editStatus_Apply(true))
    dispatch(loading_Apply(false))
  }

  const cancelReset = () => {
    set_type_data("detail")
    startSetting(all_event_data.id)
    eventDataReset()
    dispatch(editStatus_Apply(false))
  }

  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="1320px"
        maxWidth="1320"
        alignItems="center"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState}
          message="저장되었습니다"
          onClose={() => setsSnackState(false)}
        />

        {type_data === "detail" ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {all_event_data.requests.length > 0 &&
            all_event_data.requests[0].status.indexOf("회원 취소") === -1 ? (
              <SuiTypography
                onClick={() => {
                  if (
                    all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)
                      .length === 0
                  )
                    openRequest(all_event_data.requests[0])
                  else
                    setdelete_cancle_alert({
                      id: all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)[0]
                        .id,
                      open: true,
                      title: "삭제신청 취소",
                      content: "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?",
                      twoBtn: true,
                      CanBtnText: "취소",
                    })
                }}
                fontSize="16px"
                fontWeight="bold"
                color={
                  all_event_data.requests[0].status.indexOf("심사중") > -1 ? "error" : "secondary"
                }
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    all_event_data.requests[0].content.id === myData.id &&
                    !all_event_data.requests[0].content.read
                      ? ""
                      : "none",
                }}
              >
                {all_event_data.requests[0].status.replace("심사중", "")}
              </SuiTypography>
            ) : (
              ""
            )}
            {ChangeRequests.length > 0 ? (
              <SuiTypography
                onClick={() => setchagne_cancel_dailog(true)}
                fontSize="16px"
                fontWeight="bold"
                color={"error"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    ChangeRequests[0].arrayContent.users.map((x) => x.id).indexOf(myData.id) > -1 ||
                    ChangeRequests[0].users_permissions_user.id === myData.id
                      ? ""
                      : "none",
                }}
              >
                이관 신청{dayjs(ChangeRequests[0].updated_at).format("YY.MM.DD HH:mm")}
              </SuiTypography>
            ) : (
              ""
            )}
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleClick(e, index)}
              sx={{ color: "#000000" }}
            >
              <FiMoreHorizontal />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={event_update_open}
              onClose={handleMenu}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                },
              }}
            >
              <MenuItem
                disabled={
                  all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1).length >
                    0 || ChangeRequests.length > 0
                }
                onClick={(e) => deleteSelect()}
                style={{ color: "red" }}
              >
                삭제신청
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}

        {type_data === "detail" ? (
          <SuiButton
            color="info2"
            sx={{ ml: "auto", width: 100, position: "absolute", fontSize: 14 }}
            onClick={() => startEdit()}
          >
            수정
          </SuiButton>
        ) : (
          <>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#707070",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => cancelReset()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="info2"
              sx={{
                width: 100,
                fontSize: 14,
                visibility: type_data === "detail" ? "hidden" : "initial",
                display: type_data === "detail" ? "none" : "",
              }}
              onClick={(e) => handleSave(e)}
              id="saveEditBtn"
            >
              저장하기
            </SuiButton>
          </>
        )}
      </SuiBox>
    )
  }

  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      // {...other}
    >
      {openDialog && (
        <SuiBox
          display="flex"
          justifyContent="center"
          bgColor="#F1F1F5"
          minHeight="85vh"
          px={14}
          sx={{ pb: "40px" }}
        >
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                이벤트 안내 메일 관리
              </SuiTypography>
              <SuiTypography
                color="subTitle"
                sx={{ mt: 2, fontWeight: 500, fontSize: 18 }}
                variant="h6"
                component="div"
              >
                이벤트 진행을 위한 승인 요청 메일을 생성하고 발송합니다.
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
        </SuiBox>
      )}
      {openDialog && authCheck() ? FooterForm() : ""}
      <SuiDialog
        openDialog={open_info_dialog}
        closeDialog={() => set_open_info_dialog(false)}
        MainTitle={dialog_title}
        Content={dialog_content}
        CanBtnText={"확인"}
      />
      <SingleReceiverDialog
        openDialog={open_single_receiver_dialog}
        closeDialog={singleReceiverCloseDialog}
        receiver={receiverList}
      ></SingleReceiverDialog>
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
        Content={Delete_Content}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"삭제신청"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => Delete_All()}
        type={Delete_Dialog_type}
      />
      <SuiDialog
        openDialog={delete_cancle_alert.open}
        className={"admin_setting_dialog"}
        MainTitle={delete_cancle_alert.title}
        Content={delete_cancle_alert.content}
        CanBtnText={delete_cancle_alert.CanBtnText}
        AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
        CreBtnColor={"error"}
        CreBtnText={"취소하기"}
        closeDialog={() => delete_cancle_alert_close()}
        Active={() => delete_cancle_active()}
      />
      <SendEmail
        openDialog={open_send_email_dialog}
        closeDialog={openSendEmailCloseDialog}
        emailContent={send_email_list}
        emailType={email_type}
        receiver={sendEmailReceiverListFunc}
        eventData={all_event_data}
      ></SendEmail>
      <SendHistory
        openDialog={open_send_history_dialog}
        closeDialog={openSendHistoryCloseDialog}
        emailContent={send_history_list}
      ></SendHistory>
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
    </div>
  )
}
informationSent.defaultProps = {
  edit: false,
  editId: null,
  type: "create",
}
informationSent.propTypes = {
  edit: PropTypes.bool,
  type: PropTypes.string,
  saveExit: PropTypes.func,
}
export default informationSent
