// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors"

// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem"

const { transparent } = colors

export default {
  styleOverrides: {
    root: {
      margin: `${pxToRem(48)} 0`,
      padding: `0 ${pxToRem(12)}`,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
}
