import { useEffect } from "react"

// react-router-dom components
import { useLocation } from "react-router-dom"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"

// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "context"

function PageLayout({ className, background, children }) {
  const [, dispatch] = useSoftUIController()
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, "page")
  }, [pathname])

  return (
    <SuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      className={className}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </SuiBox>
  )
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  className: "",
  background: "default",
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  className: PropTypes.string,
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node,
}

export default PageLayout
