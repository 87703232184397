import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiDialog from "components/SuiDialog"

import DeleteDialog from "../deleteDialog"

import { MdClose } from "react-icons/md"

// API
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { userContract, users, user, request, roleCountUser, users_list } from "apollo/query"
import {
  createRequest,
  updateRequest,
  createAlert,
  createSendEmail,
  updateUserMu,
} from "apollo/mutation"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function WithdrawlDialog({ openDialog, closeDialog, withdrawlRow, updateUser }) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const { myData } = useSelector((state) => state.store)

  const [infos, setinfos] = useState("")
  const [open, setOpen] = useState(openDialog)
  const [withdrawlReason, setwithdrawlReason] = useState("")
  const [requsetInfo, setrequsetInfo] = useState({})

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    if (withdrawlRow.id) {
      dispatch(loading_Apply(true))
      requestActive({
        fetchPolicy: "network-only",
        variables: {
          id: withdrawlRow.id,
        },
      }).then((requestData) => {
        setrequsetInfo(requestData.data.request)
        dispatch(loading_Apply(false))
      })
    }
  }, [withdrawlRow])

  const handleClose = () => {
    closeDialog()
  }

  const [requestActive] = useLazyQuery(request, {
    fetchPolicy: "network-only",
    onError(err) {},
  })

  const [userContractActive] = useLazyQuery(userContract, {
    fetchPolicy: "network-only",
    onError(err) {},
  })

  const [createRequestActive] = useMutation(createRequest, {
    fetchPolicy: "no-cache",
    onError(err) {},
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [users_list],
  })

  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const [userActive] = useLazyQuery(user, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
    refetchQueries: [users_list],
  })
  const [updateUserActive] = useMutation(updateUserMu, {
    onError(err) {},
    refetchQueries: [users_list, roleCountUser],
  })

  const refuse_Delete = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: withdrawlRow.id,
        status: "탈퇴거절",
        declining: requestExplainData,
      },
    }).then(() => {
      handleClose()
      dispatch(loading_Apply(false))
      userActive({
        variables: {
          id: requsetInfo.content.id,
        },
      }).then(({ data }) => {
        // updateUser(data)
        if (data.user.nomalAlertSetting) {
          if (data.user.nomalAlertSetting[2].value) {
            createAlertActive({
              variables: {
                users_permissions_user: String(requsetInfo.content.id),
                title: "회원 탈퇴 신청 거절",
                type: "levelUp",
                userid: String(requsetInfo.content.id),
                content: {
                  position: myData.position,
                  department: myData.department,
                  title: myData.name,
                  id: myData.id,
                  name: myData.name,
                  suggestRole: requsetInfo.content.suggestRole,
                  profile: myData.profile,
                  declining: requestExplainData,
                },
              },
            })
          }
        }
      })
    })
  }

  const approve_Delete = () => {
    dispatch(loading_Apply(true))
    userContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: requsetInfo.content.id,
      },
    }).then((dataContract) => {
      if (
        dataContract.data.user.contract_documents.length === 0 &&
        dataContract.data.user.company_contracts.length === 0
      ) {
        updateRequestActive({
          variables: {
            id: withdrawlRow.id,
            status: "탈퇴승인",
          },
        }).then(() => {
          let data = {
            id: requsetInfo.content.id,
            status: "퇴사",
            blocked: true,
          }
          updateUserActive({
            variables: data,
          }).then(() => {
            let newWithdrawSet = {
              dialog: true,
              title: "탈퇴 승인 완료",
              content: "탈퇴 요청을 승인하였습니다.",
              CanBtnText: "확인",
            }

            setwithdralData(newWithdrawSet)
            dispatch(loading_Apply(false))
          })
        })
      } else {
        let newWithdrawSet = {
          dialog: true,
          title: "탈퇴 승인 불가",
          content:
            "담당 계약 건이 있어 탈퇴 승인을 할 수 없습니다. 먼저 계약 담당자 변경을 진행해주세요.",
          CanBtnText: "확인",
        }
        dispatch(loading_Apply(false))

        setwithdralData(newWithdrawSet)
      }
    })
  }

  const check_withdrawlAble = () => {
    let newWithdrawSet = {
      dialog: false,
      title: "",
      content: "",
      CanBtnText: "완료",
    }

    dispatch(loading_Apply(true))
    userContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: myData.id,
      },
    }).then((dataContract) => {
      if (
        dataContract.data.user.contract_documents.length === 0 &&
        dataContract.data.user.company_contracts.length === 0
      ) {
        newWithdrawSet.dialog = true
        newWithdrawSet.title = "탈퇴 신청 완료"
        newWithdrawSet.content = `탈퇴 신청(${dayjs(new Date()).format(
          "YY.MM.DD"
        )})이 완료되었습니다. 관리자 승인시 탈퇴 처리됩니다.`
        newWithdrawSet.CanBtnText = "확인"

        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          suggestRole: "",
          requestExplain: withdrawlReason,
        }
        createRequestActive({
          variables: {
            status: "탈퇴신청",
            type: "withdrawl",
            content: request,
            users_permissions_user: myData.id,
          },
        }).then(() => {
          myData.requests.push({ status: "탈퇴신청" })
          client
            .query({
              query: users_list,
              variables: {
                role: 1,
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              res.data.users.forEach((user) => {
                if (user.adminAlertSetting) {
                  if (user.adminAlertSetting[0].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(user.id),
                        title: "회원 탈퇴 신청",
                        type: "witihdrawl",
                        userid: String(user.id),
                        content: {
                          id: myData.id,
                          profile: myData.profile,
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          title: myData.name,
                          suggestRole: "",
                          declining: withdrawlReason,
                        },
                      },
                    })
                  }
                  if (user.adminAlertSetting[3].value) {
                    EmailSendActive({
                      variables: {
                        usermail: user.email,
                        type: "4",
                        content: {
                          id: myData.id,
                          profile: myData.profile,
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          suggestRole: "",
                          declining: withdrawlReason,
                        },
                      },
                    })
                  }
                }
              })
            })
        })
      } else {
        newWithdrawSet.dialog = true
        newWithdrawSet.title = "탈퇴 신청 불가"
        newWithdrawSet.content =
          "담당 계약 건이 있어 탈퇴 신청을 할 수 없습니다. 먼저 계약 담당자 변경을 진행해주세요."
        newWithdrawSet.CanBtnText = "확인"
      }
      setwithdralData(newWithdrawSet)
      dispatch(loading_Apply(false))
    })
  }
  const handleInput = () => (e) => {
    setwithdrawlReason(e.target.value)
  }

  const withdralDataSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "완료",
  }

  const [withdralData, setwithdralData] = useState(withdralDataSet)
  const close_registerd = () => {
    closeDialog()
    setwithdralData({
      dialog: false,
      title: "",
      content: "",
      CanBtnText: "",
    })
  }

  const [deny_Dialog, setdeny_Dialog] = useState(false)
  const [deny_Dialog_type, setdeny_Dialog_type] = useState("create")
  const [requestExplainData, setrequestExplainData] = useState("")

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 400 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                탈퇴 신청
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography
                style={{ fontSize: "16px", fontWeight: "medium", textAlign: "center" }}
                color="subTitle"
              >
                <strong>
                  {requsetInfo.content
                    ? requsetInfo.content.name + " " + requsetInfo.content.position
                    : ""}
                </strong>
                가 탈퇴 신청을 하였습니다. <br />
                승인하시겠습니까?
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, my: 5 }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              탈퇴 사유:
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              multiline
              disabled
              rows={4}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              value={requsetInfo.content ? requsetInfo.content.requestExplain : ""}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              color="subTitle"
              variant="text"
              onClick={() => handleClose()}
              sx={{
                minWidth: 0,
                px: 0,
                height: 40,
                minHeight: 40,
                fontSize: 14,
              }}
            >
              취소
            </SuiButton>
            <SuiButton
              color="error"
              onClick={() => setdeny_Dialog(true)}
              sx={{
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 93,
                ml: "auto",
                mr: 2,
              }}
            >
              거절
            </SuiButton>
            <SuiButton
              color="info2"
              onClick={() => approve_Delete()}
              sx={{
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 93,
              }}
            >
              승인
            </SuiButton>
          </SuiBox>
        </Grid>
        <SuiDialog
          openDialog={withdralData.dialog}
          closeDialog={() => close_registerd()}
          MainTitle={withdralData.title}
          Content={withdralData.content}
          CanBtnText={withdralData.CanBtnText}
        />
        <DeleteDialog
          openDialog={deny_Dialog}
          closeDialog={() => setdeny_Dialog(false)}
          MainTitle={"회원 탈퇴 신청 거절"}
          Content={"거절 사유를 작성하고, 회원 탈퇴 신청을 미승인합니다."}
          CanBtnText={"취소"}
          CreBtnText={"거절"}
          CreBtnColor={"error"}
          AlertType={"twoBtn"}
          requestExplainData={setrequestExplainData}
          requestExplainShowData={requestExplainData}
          Active={() => refuse_Delete()}
          type={deny_Dialog_type}
        />
      </Grid>
    </Dialog>
  )
}

WithdrawlDialog.defaultProps = {
  openDialog: false,
}

WithdrawlDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  withdrawlRow: PropTypes.object,
  updateUser: PropTypes.func,
}
export default WithdrawlDialog
