import React, { useEffect, useState } from "react"
import axios from "axios"
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Grid from "@mui/material/Grid"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Avatar from "@mui/material/Avatar"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Card from "@mui/material/Card"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"

import SuiDialog from "components/SuiDialog"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"

import ChangeData_card from "./components/changeData_card"
import WithdrawlDialog from "./components/WithdrawlDialog"
import RejectDialog from "./components/rejectDialog"

import { MdKeyboardArrowLeft } from "react-icons/md"
import { AiOutlineUser, AiOutlineCloseCircle } from "react-icons/ai"
import { MdClose } from "react-icons/md"
import { BiUserPin, BiBellMinus } from "react-icons/bi"
import { CgArrowsExchangeAlt } from "react-icons/cg"
// API
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import {
  updateUserMu,
  updateRequest,
  createRequest,
  createSendEmail,
  createAlert,
} from "apollo/mutation"
import { me, users, users_list } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../reducers/store"

function TabPanel(props) {
  const client = useApolloClient()
  const [PFurl, setPFurl] = useState("")
  const [PFid, setPFid] = useState(null)
  const [changeRole, setchangeRole] = useState("")
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [NomalSetting, setNomalSetting] = useState([
    { value: false },
    { value: false },
    { value: false },
    { value: false },
  ])
  const [AdminSetting, setAdminSetting] = useState([
    { value: false },
    { value: false },
    { value: false },
    { value: false },
  ])
  const [openrequestDialog, setopenrequestDialog] = useState(false)

  const NomalSettingChange = (index) => {
    let array = [...NomalSetting]
    array[index].value = !array[index].value
    setNomalSetting(array)
  }
  const CancelNomalSetting = () => {
    if (myData.nomalAlertSetting) {
      setNomalSetting(JSON.parse(JSON.stringify(myData.nomalAlertSetting)))
    }
  }
  const disableCancle = () => {
    let active = true
    NomalSetting.forEach((el, index) => {
      if (myData.nomalAlertSetting) {
        if (myData.nomalAlertSetting[index].value !== el.value) {
          active = false
        }
      } else {
        active = true
      }
    })

    return active
  }

  const disableCancleAdmin = () => {
    let active = true
    AdminSetting.forEach((el, index) => {
      if (myData.adminAlertSetting) {
        if (myData.adminAlertSetting[index].value !== el.value) {
          active = false
        }
      } else {
        active = true
      }
    })

    return active
  }
  const SaveNomalSetting = () => {
    let array = [...NomalSetting]
    dispatch(loading_Apply(true))
    updateUserActive({
      variables: {
        id: myData.id,
        nomalAlertSetting: array,
      },
    }).then(() => {
      dispatch(loading_Apply(false))
      setInfoAlert({
        dialog: true,
        title: "일반 환경 설정",
        content: "설정이 저장되었습니다.",
        CanBtnText: "확인",
      })
    })
  }
  const AdminSettingChange = (index) => {
    let array = [...AdminSetting]
    array[index].value = !array[index].value
    setAdminSetting(array)
  }
  const CancelAdminSetting = () => {
    if (myData.adminAlertSetting) {
      setAdminSetting(JSON.parse(JSON.stringify(myData.adminAlertSetting)))
    }
  }
  const SaveAdminSetting = () => {
    let array = [...AdminSetting]
    dispatch(loading_Apply(true))
    updateUserActive({
      variables: {
        id: myData.id,
        adminAlertSetting: array,
      },
    }).then(() => {
      dispatch(loading_Apply(false))
      setInfoAlert({
        dialog: true,
        title: "관리자 환경 설정",
        content: "설정이 저장되었습니다.",
        CanBtnText: "확인",
      })
    })
  }
  const { children, value, index, ...other } = props
  const subText = () => {
    if (value === 0) {
      return "사용자의 기본 정보를 확인하고, 비밀번호를 변경하실 수 있습니다."
    } else if (value === 1) {
      return "사용자의 계약 담당 건을 일괄적으로 변경 신청합니다."
    } else if (value === 2) {
      return "수신할 푸시 알림을 선택하세요."
    }
  }
  useEffect(async () => {
    if (value === 1) {
      dispatch(loading_Apply(true))
      await client
        .query({
          query: users_list,
          variables: {
            status: "근무",
            role: 1,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          setusersDatas(res.data.users)
          dispatch(loading_Apply(false))
        })
    }
  }, [value])

  const { myData } = useSelector((state) => state.store)
  const InputListsSet = [
    { type: "pic", title: "프로필 사진:", xs: 4 },
    {
      file: null,
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이름:",
      maxLength: 100,
      changed: false,
      xs: 4,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "비밀번호:",
      placeholder: "특수문자 포함 10자리 이상",
      maxLength: 255,
      changed: false,
      xs: 4,
    },
    {
      items: [
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ],
      value: "경영지원실",
      type: "selectBox",
      title: "부서:",
      xs: 4,
      changed: false,
    },
    {
      items: ["대표이사", "본부장", "실장", "팀장", "파트장", "프로", "PD"],
      value: "프로",
      type: "selectBox",
      title: "직위:",
      xs: 4,
      changed: false,
    },
    {
      items: ["슈퍼어드민", "골드", "실버", "브론즈"],
      value: "브론즈",
      type: "selectBox",
      title: "회원등급:",
      xs: 4,
      changed: false,
    },
    {
      items: ["근무", "휴직"],
      value: "근무",
      type: "selectBox",
      title: "근무상태:",
      xs: 3,
      changed: false,
    },
    {
      inputType: "email",
      value: "",
      error_message: "",
      success: false,
      error: false,
      type: "textFiled",
      title: "이메일:",
      maxLength: 255,
      xs: 5,
      changed: false,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "연락처:",
      maxLength: 13,
      changed: false,
      xs: 4,
      changed: false,
    },
  ]
  const dispatch = useDispatch()
  const [requestExplain, setrequestExplain] = useState("")
  const [InputLists, setInputLists] = useState(InputListsSet)
  const [password, setpassword] = useState("")
  let roleList = ["슈퍼어드민", "골드", "실버", "브론즈"]
  useEffect(() => {
    if (myData.id) {
      let array = [...InputLists]
      array[1].value = myData.name
      array[2].value = "**********"
      array[3].value = myData.department
      array[4].value = myData.position
      array[5].value = roleText(myData.role.name)
      array[6].value = myData.status
      array[7].value = myData.email
      array[8].value = myData.phone
      setInputLists(array)
      if (myData.profile) {
        setPFid(myData.profile.id)
        setPFurl(process.env.REACT_APP_BACKEND_URL + myData.profile.url)
      }
      if (myData.nomalAlertSetting) {
        setNomalSetting(JSON.parse(JSON.stringify(myData.nomalAlertSetting)))
      }
      if (myData.adminAlertSetting) {
        setAdminSetting(JSON.parse(JSON.stringify(myData.adminAlertSetting)))
      }
    }
    let withdrawl_requset = myData.requests.filter((x) => {
      return x.status === "탈퇴신청" && x.read === false
    })

    let withdrawl_deny = myData.requests.filter((x) => {
      return x.status === "탈퇴거절" && x.read === false
    })

    if (withdrawl_requset.length > 0) {
      setWithdrawlDate(
        `탈퇴 신청 심사중 ${dayjs(withdrawl_requset[0].created_at).format("(YYYY.MM.DD HH:mm)")}`
      )
    }
    if (withdrawl_deny.length > 0) {
      setWithdrawlDate(
        `탈퇴 신청 거절 ${dayjs(withdrawl_deny[0].updated_at).format("(YYYY.MM.DD HH:mm)")}`
      )
    }

    // myData.requests
  }, [myData])
  const roleText = (value) => {
    if (value === "Authenticated") {
      return "슈퍼어드민"
    } else if (value === "gold") {
      return "골드"
    } else if (value === "silver") {
      return "실버"
    } else if (value === "bronze") {
      return "브론즈"
    } else {
      return "오류"
    }
  }
  const handleInput = (index, maxLength) => (e) => {
    let array = [...InputLists]
    let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
      if (index === 2 && !rePassword.test(e.target.value)) {
        array[index].error = true
        array[index].error_message = "10자리 이상(영문+특수)"
      } else if (index === 8) {
        array[index].value = array[index].value
          .replace(/[^0-9]/, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
        array[index].error = false
        array[index].error_message = ""
      } else {
        array[index].error = false
        array[index].error_message = ""
      }
    }
    setInputLists(array)
  }
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [usersDatas, setusersDatas] = useState([])
  const [createRequestActive] = useMutation(createRequest, {})
  const [updateRequestActive] = useMutation(updateRequest, {})
  const [updateUserActive] = useMutation(updateUserMu, {
    refetchQueries: [me],
  })
  const fileUploadActive = (file) => {
    let data = new FormData()
    data.append("files", file)
    axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
      setPFid(res.data[0].id)
      setPFurl(process.env.REACT_APP_BACKEND_URL + res.data[0].url)
    })
  }
  const handlePFChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].file = e.target.files[0]
    array[index].changedData = true
    fileUploadActive(e.target.files[0])
    setInputLists(array)
  }
  const PFImgShow = () => {
    if (PFurl === "") {
      return (
        <IconButton
          disabled
          color="dark"
          className="profile-defalut-icon"
          size="large"
          sx={{ mr: 2 }}
        >
          <AiOutlineUser />
        </IconButton>
      )
    } else {
      return (
        <span>
          <Avatar alt="Profile Img" src={PFurl} sx={{ width: 52, height: 52, mr: 2 }} />
        </span>
      )
    }
  }
  const imgupload = () => {
    document.getElementById("user-img").click()
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox sx={{ width: "100%" }}>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 700,
              pt: 1.8,
              mb: 1,
              display: data.changed ? "none" : "",
            }}
          >
            {data.value}
          </Card>
          {(index === 2 || index === 8) && data.changed ? (
            <div className="profile-input">
              <SuiInput
                error={data.error}
                success={data.success}
                helperText={data.error_message}
                type={data.inputType}
                placeholder={data.placeholder ? data.placeholder : ""}
                value={data.value}
                onChange={handleInput(index, data.maxLength)}
              />
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox sx={{ width: "100%" }}>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 700,
              pt: 1.8,
              mb: 1,
            }}
          >
            {data.value}
          </Card>
        </SuiBox>
      )
    } else if (data.type === "pic") {
      return (
        <SuiBox display="flex" alignItems="center">
          {PFImgShow()}
          <SuiButton sx={{ height: 30 }} color="info2" size="small" onClick={() => imgupload()}>
            변경
          </SuiButton>
          <input
            onChange={handlePFChange(index)}
            type="file"
            id="user-img"
            accept="image/*"
            style={{ display: "none" }}
          ></input>
        </SuiBox>
      )
    }
  }
  const roleSetting = (value) => {
    if (value === "슈퍼어드민") {
      return 1
    } else if (value === "골드") {
      return 3
    } else if (value === "실버") {
      return 4
    } else if (value === "브론즈") {
      return 5
    }
  }
  const editChangedCancel = (index) => {
    let array = [...InputLists]
    if (index === 2) {
      array[index].changed = false
      array[index].value = "********"
      setInputLists(array)
    } else if (index === 8) {
      array[index].changed = false
      array[index].value = myData.phone
      setInputLists(array)
    }
  }
  const editChangedSave = (index) => {
    let array = [...InputLists]
    let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
    if (index === 2) {
      if (!rePassword.test(array[index].value)) {
        array[index].error = true
        array[index].error_message = "10자리 이상(영문+특수)"
      } else {
        setpassword(array[index].value)
        array[index].changed = false
        array[index].changedData = true
        setInputLists(array)
      }
    } else if (index === 8) {
      array[index].changed = false
      array[index].changedData = true
      setInputLists(array)
    }
  }
  const editChanged = (index) => {
    if (myData.requests.map((x) => x.status).indexOf("공개") === -1) {
      let array = [...InputLists]
      if (index === 2) {
        array[index].changed = true
        array[index].value = ""
        setInputLists(array)
      } else if (index === 5) {
        setopenrequestDialog(true)
      } else if (index === 8) {
        array[index].changed = true
        setInputLists(array)
      }
    }
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 5 }}>
        <SuiBox sx={{ pb: 4 }}>
          <SuiBox display="flex">
            <SuiTypography
              fontSize="15px"
              sx={{ pb: 1, textAlign: "left", fontWeight: 400, color: "#668B9E" }}
            >
              {row.title}
            </SuiTypography>
            {row.changed ? (
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color="error"
                className="cursor"
                sx={{
                  textAlign: "right",
                  ml: "auto",
                  pb: 1,
                  textDecoration: "underline",
                  display: index === 2 || index === 8 ? "" : "none",
                }}
                onClick={() => editChangedCancel(index)}
              >
                취소
              </SuiTypography>
            ) : (
              ""
            )}
            {row.changed ? (
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color="info2"
                className="cursor"
                sx={{
                  textAlign: "right",
                  ml: 2,
                  pb: 1,
                  textDecoration: "underline",
                  display: index === 2 || index === 8 ? "" : "none",
                }}
                onClick={() => editChangedSave(index)}
              >
                저장
              </SuiTypography>
            ) : (
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color={
                  myData.requests.map((x) => x.status).indexOf("공개") > -1 && index === 5
                    ? "text2"
                    : "info2"
                }
                className={
                  myData.requests.map((x) => x.status).indexOf("공개") > -1 ? "" : "cursor"
                }
                sx={{
                  textAlign: "right",
                  ml: "auto",
                  pb: 1,
                  textDecoration: "underline",
                  display: index === 2 || index === 5 || index === 8 ? "" : "none",
                }}
                onClick={() => editChanged(index)}
              >
                {index === 5
                  ? myData.requests.map((x) => x.status).indexOf("공개") > -1
                    ? "심사중"
                    : "변경신청"
                  : "변경"}
              </SuiTypography>
            )}
          </SuiBox>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })

  const CancelProfile = () => {
    setpassword("")
    let array = [...InputLists]
    array[0].changedData = false
    array[2].changed = false
    array[2].changedData = false
    array[2].value = "**********"
    array[8].changed = false
    array[8].changedData = false
    array[8].value = myData.phone
    if (myData.profile) {
      setPFid(myData.profile.id)
      setPFurl(process.env.REACT_APP_BACKEND_URL + myData.profile.url)
    } else {
      setPFid(null)
      setPFurl("")
    }
    setInputLists(array)
  }
  const SaveProfile = () => {
    dispatch(loading_Apply(true))
    let data = {
      id: myData.id,
      profile: PFid,
      phone: InputLists[8].value,
    }
    if (password !== "") {
      data.password = password
    }
    updateUserActive({
      variables: data,
    }).then(() => {
      let array = [...InputLists]
      array[2].changed = false
      array[8].changed = false
      array[0].changedData = false
      array[2].changedData = false
      array[8].changedData = false
      setInputLists(array)
      dispatch(loading_Apply(false))
      setInfoAlert({
        dialog: true,
        title: "프로필",
        content: "프로필 설정이 저장되었습니다.",
        CanBtnText: "확인",
      })
    })
  }
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const handleOut = () => {}
  const createLevelUpRequest = () => {
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      profile: myData.profile,
      suggestRole: changeRole,
      requestExplain: requestExplain,
    }
    createRequestActive({
      variables: {
        status: "공개",
        type: "levelUp",
        content: request,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      dispatch(loading_Apply(false))
      setInfoAlert({
        dialog: true,
        title: "회원 등급 변경 신청 완료",
        content: "회원 등급 변경 신청이 완료되었습니다. 관리자 승인시, 회원 등급이 변경됩니다.",
        CanBtnText: "확인",
      })
      myData.requests.push({ status: "공개" })
      client
        .query({
          query: users,
          variables: {
            role: 1,
            status: "근무",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          res.data.users.forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[0].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "회원 등급 변경 신청",
                    type: "levelUp",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      suggestRole: changeRole,
                      declining: requestExplain,
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "2",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      suggestRole: changeRole,
                      declining: requestExplain,
                    },
                  },
                })
              }
            }
          })
          setopenrequestDialog(false)
        })
    })
  }

  useEffect(() => {
    if (!InputLists[2].changedData && !InputLists[8].changedData && !InputLists[0].changedData) {
      dispatch(editStatus_Apply(true))
    } else {
      dispatch(editStatus_Apply(false))
    }
  }, [InputLists])

  const [FiltersOpen1, setFiltersOpen1] = useState(false)

  const [open_WithdrawlDialog, setopen_WithdrawlDialog] = useState(false)
  const close_WithdrawlDialog = () => {
    setopen_WithdrawlDialog(false)
  }
  const withdrawDialog_setData = () => {
    setopen_WithdrawlDialog(true)
    // setwithdrawl_row(row[0])
  }
  const open_denyReason = () => {
    setreject_Dialog(true)
    let withdrawl_deny = myData.requests.filter((x) => {
      return x.status === "탈퇴거절" && x.read === false
    })

    setrequestExplain_reject(withdrawl_deny[0].declining)

    updateRequestActive({
      variables: {
        id: withdrawl_deny[0].id,
        read: true,
      },
    }).then(() => {
      withdrawl_deny[0].read = true
    })
  }
  const [WithdrawlDate, setWithdrawlDate] = useState("")
  const [reject_Dialog, setreject_Dialog] = useState(false)

  const [requestExplain_reject, setrequestExplain_reject] = useState("")

  const Content = () => {
    if (value === 0) {
      return (
        <SuiBox sx={{ backgroundColor: "#ffffff", p: 4, borderRadius: 4, boxShadow: 1, mb: 12 }}>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          <Divider sx={{ my: 4 }} />
          <SuiBox sx={{ p: 0 }} display="flex">
            {/* <SuiButton
              color="error"
              variant="text"
              sx={{ height: 40, mr: "auto" }}
              onClick={() => handleOut()}
            >
              탈퇴신청
            </SuiButton> */}
            {myData.requests.filter((x) => {
              return (x.status === "탈퇴신청" || x.status === "탈퇴거절") && x.read === false
            }).length > 0 ? (
              <SuiBox display="flex">
                <SuiTypography
                  sx={{
                    fontSize: 14,
                    px: 0,
                    alignSelf: "center",
                    color: "#BFC4C7",
                  }}
                >
                  탈퇴신청
                </SuiTypography>
                <SuiTypography
                  sx={{
                    fontSize: 14,
                    ml: 2,
                    alignSelf: "center",
                    color:
                      myData.requests.filter((x) => {
                        return x.status === "탈퇴거절" && x.read === false
                      }).length > 0
                        ? "#9AA0A6"
                        : "#FF7B89",
                    textDecoration:
                      myData.requests.filter((x) => {
                        return x.status === "탈퇴거절" && x.read === false
                      }).length > 0
                        ? "underline"
                        : "",
                    cursor:
                      myData.requests.filter((x) => {
                        return x.status === "탈퇴거절" && x.read === false
                      }).length > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    myData.requests.filter((x) => {
                      return x.status === "탈퇴거절" && x.read === false
                    }).length > 0
                      ? open_denyReason()
                      : ""
                  }}
                >
                  {WithdrawlDate}
                </SuiTypography>
              </SuiBox>
            ) : (
              <SuiButton
                variant="text"
                color="error"
                sx={{
                  height: 40,
                  minHeight: 40,
                  fontSize: 14,
                  px: 0,
                }}
                onClick={() => withdrawDialog_setData()}
              >
                탈퇴신청
              </SuiButton>
            )}

            <SuiButton
              color="subTitle"
              variant="text"
              disableRipple={true}
              disableFocusRipple
              sx={{
                mr: 2,
                width: 97.38,
                border: 1,
                borderColor:
                  !InputLists[2].changedData &&
                  !InputLists[8].changedData &&
                  !InputLists[0].changedData
                    ? "#EEEEEE"
                    : "#707070",
                height: 40,
                ml: "auto",
                fontSize: 14,
                color:
                  !InputLists[2].changedData &&
                  !InputLists[8].changedData &&
                  !InputLists[0].changedData
                    ? "#A4A4A4 !important"
                    : "",
                cursor:
                  !InputLists[2].changedData &&
                  !InputLists[8].changedData &&
                  !InputLists[0].changedData
                    ? "default"
                    : "",
              }}
              onClick={() =>
                !InputLists[2].changedData &&
                !InputLists[8].changedData &&
                !InputLists[0].changedData
                  ? ""
                  : CancelProfile()
              }
            >
              취소
            </SuiButton>
            <SuiButton
              disabled={
                !InputLists[2].changedData &&
                !InputLists[8].changedData &&
                !InputLists[0].changedData
              }
              color="primary"
              onClick={() => SaveProfile()}
              id="profileSave"
              sx={{
                height: 40,
                fontSize: 14,
              }}
            >
              저장하기
            </SuiButton>
          </SuiBox>
          <Dialog
            open={openrequestDialog}
            onClose={() => setopenrequestDialog(false)}
            fullWidth
            maxWidth="xs"
          >
            <SuiBox sx={{ width: 444, p: 4 }}>
              {/* <IconButton
                edge="start"
                color="text2"
                size="small"
                onClick={() => setopenrequestDialog(false)}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                <MdClose />
              </IconButton> */}
              <SuiBox display="flex" justifyContent="center" sx={{ mb: 4 }}>
                <SuiTypography fontSize="20px" fontWeight="bold" color="dark">
                  {" "}
                  회원 등급 변경 신청
                </SuiTypography>
              </SuiBox>
              <SuiBox display="flex" justifyContent="center">
                <Card
                  sx={{
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  <SuiBox display="flex" sx={{ height: 60 }} alignItems="center">
                    <SuiTypography
                      fontWeight="bold"
                      component="div"
                      sx={{
                        width: "100px",
                        color: "#535353",
                        fontSize: 18,
                      }}
                    >
                      현재등급
                    </SuiTypography>
                    <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE" }} />
                    <SuiTypography
                      fontWeight="bold"
                      sx={{
                        ml: "auto",
                        mr: "auto",
                        textAlign: "center",
                        color: "#535353",
                        fontSize: 18,
                      }}
                    >
                      {roleText(myData.role.name)}
                    </SuiTypography>
                  </SuiBox>
                </Card>
              </SuiBox>
              <SuiBox display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Card
                  sx={{
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    borderRadius: "8px",
                    textAlign: "center",
                    border: 1,
                    borderColor: "#EEEEEE",
                  }}
                >
                  <SuiBox display="flex" sx={{ height: 60 }} alignItems="center">
                    <SuiTypography
                      fontWeight="bold"
                      component="div"
                      sx={{
                        width: "100px",
                        color: "#535353",
                        fontSize: 18,
                      }}
                    >
                      신청등급
                    </SuiTypography>
                    <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE" }} />
                    <SuiBox sx={{ width: 280 }}>
                      <FormControl
                        fullWidth
                        className={
                          changeRole === ""
                            ? "profile-select setting-selectbox select-none-active"
                            : "profile-select setting-selectbox"
                        }
                        onClick={() => setFiltersOpen1(!FiltersOpen1)}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              color="black"
                              className="select-icon cursor"
                              onClick={() => setFiltersOpen1(!FiltersOpen1)}
                            />
                          )}
                          open={FiltersOpen1}
                          value={changeRole}
                          onChange={(e) => setchangeRole(e.target.value)}
                          className="setting-border-radius"
                        >
                          {roleList
                            .filter((x) => x !== roleText(myData.role.name))
                            .map((x) => {
                              return (
                                <MenuItem value={x} key={x}>
                                  {x}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </SuiBox>
              <SuiTypography
                fontWeight="bold"
                component="div"
                color="secondary"
                sx={{
                  fontSize: 15,
                  mt: 2,
                  fontWeight: 400,
                  mb: 1.4,
                  color: "#668B9E",
                }}
              >
                신청 사유 :
              </SuiTypography>
              <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
                <TextField
                  multiline
                  rows={4}
                  sx={{ width: "100%" }}
                  className="Fileupload-textArea"
                  variant="outlined"
                  placeholder="300자 이내"
                  value={requestExplain}
                  onChange={(e) => {
                    if (e.target.value.length <= 300) setrequestExplain(e.target.value)
                  }}
                />
              </SuiBox>
              <SuiBox
                display="flex"
                justifyContent="start"
                width="100%"
                alignItems="center"
                sx={{ mt: 4 }}
              >
                <SuiButton
                  color="subTitle"
                  variant="text"
                  sx={{
                    width: 100,
                    border: 1,
                    borderColor: "#707070",
                    height: 40,
                    fontSize: 14,
                    ml: "auto",
                    mr: 2,
                  }}
                  onClick={() => setopenrequestDialog(false)}
                >
                  취소
                </SuiButton>
                <SuiButton
                  color="primary"
                  onClick={() => createLevelUpRequest()}
                  sx={{ px: 2, py: 0.5, height: 40, minHeight: 40, fontSize: 14, width: 100 }}
                  disabled={requestExplain === "" || changeRole === ""}
                >
                  신청하기
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Dialog>
        </SuiBox>
      )
    } else if (value === 1) {
      return (
        <div>
          <ChangeData_card usersDatas={usersDatas} />
        </div>
      )
    } else if (value === 2) {
      return (
        <div>
          <SuiBox
            sx={{
              backgroundColor: "#ffffff",
              p: 4,
              borderRadius: 4,
              boxShadow: 1,
              ml: "auto",
              mr: "auto",
              mb: "16px",
              width: 800,
            }}
          >
            <SuiTypography
              sx={{ fontSize: 20, textAlign: "left", letterSpacing: -0.5 }}
              fontWeight="bold"
            >
              일반 환경 설정
            </SuiTypography>
            <SuiBox display="flex" alignItems="center" sx={{ mt: 4 }}>
              <Switch
                color="info2"
                className={NomalSetting[0].value ? "" : "MuiSwitch-track-none"}
                checked={NomalSetting[0].value}
                onChange={() => NomalSettingChange(0)}
              />
              <SuiBox sx={{ ml: 2 }}>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                  fontWeight="medium"
                >
                  내가 등록한 작가 정보
                </SuiTypography>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                  fontWeight="medium"
                >
                  내가 등록한 작가 정보의 변경이나 새로운 계약 정보 추가, 메모 추가, 회의록 추가에
                  대한 알림 수신
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
              <Switch
                color="info2"
                className={NomalSetting[1].value ? "" : "MuiSwitch-track-none"}
                checked={NomalSetting[1].value}
                onChange={() => NomalSettingChange(1)}
              />
              <SuiBox sx={{ ml: 2 }}>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                  fontWeight="medium"
                  color="dark"
                >
                  내가 등록한 유통사 정보
                </SuiTypography>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                  fontWeight="medium"
                  color="secondary"
                >
                  내가 등록한 유통사 정보의 변경이나 새로운 계약 정보 추가, 메모 추가, 회의록 추가에
                  대한 알림 수신
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
              <Switch
                color="info2"
                className={NomalSetting[2].value ? "" : "MuiSwitch-track-none"}
                checked={NomalSetting[2].value}
                onChange={() => NomalSettingChange(2)}
              />
              <SuiBox sx={{ ml: 2 }}>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                  fontWeight="medium"
                  color="dark"
                >
                  변경 신청 알림
                </SuiTypography>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                  fontWeight="medium"
                  color="secondary"
                >
                  회원 등급, 계약 이관 신청 등의 결과에 대한 알림 수신
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
              <Switch
                color="info2"
                className={NomalSetting[3].value ? "" : "MuiSwitch-track-none"}
                checked={NomalSetting[3].value}
                onChange={() => NomalSettingChange(3)}
              />
              <SuiBox sx={{ ml: 2 }}>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                  fontWeight="medium"
                  color="dark"
                >
                  신규 정보 등록(참조자 설정시)
                </SuiTypography>
                <SuiTypography
                  sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                  fontWeight="medium"
                  color="secondary"
                >
                  작가 및 유통사 신규 등록시 알림 수신
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox sx={{ p: 0, mt: 6 }} display="flex">
              <SuiButton
                disableElevation={true}
                disableFocusRipple={true}
                disableRipple={true}
                sx={{
                  ml: "auto",
                  mr: 2,
                  width: 97.38,
                  border: 1,
                  borderColor: "#707070",
                  color: "#707070",
                  height: 40,
                  fontSize: 14,
                  boxShadow: 0,
                  boxShadow: disableCancle() ? "none !important" : "",
                }}
                color="subTitle"
                variant="text"
                onClick={() => (disableCancle() ? "" : CancelNomalSetting())}
              >
                취소
              </SuiButton>

              <SuiButton
                color="info2"
                onClick={() => SaveNomalSetting()}
                sx={{
                  fontSize: 14,
                  height: 40,
                }}
              >
                저장하기
              </SuiButton>
            </SuiBox>
          </SuiBox>
          {myData.role.name === "Authenticated" ? (
            <SuiBox
              sx={{
                backgroundColor: "#ffffff",
                p: 4,
                borderRadius: 4,
                boxShadow: 1,
                ml: "auto",
                mr: "auto",
                mb: 12,
                width: 800,
              }}
            >
              <SuiTypography
                sx={{ fontSize: 20, textAlign: "left", letterSpacing: -0.5 }}
                fontWeight="bold"
              >
                관리자 환경 설정
              </SuiTypography>
              <SuiBox display="flex" alignItems="center" sx={{ mt: 4 }}>
                <Switch
                  color="info2"
                  className={AdminSetting[0].value ? "" : "MuiSwitch-track-none"}
                  checked={AdminSetting[0].value}
                  onChange={() => AdminSettingChange(0)}
                />
                <SuiBox sx={{ ml: 2 }}>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                    fontWeight="medium"
                    color="dark"
                  >
                    회원 등급 변경 신청
                  </SuiTypography>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                    fontWeight="medium"
                    color="secondary"
                  >
                    사용자의 회원 등급 변경 신청시 알림 수신
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
                <Switch
                  color="info2"
                  className={AdminSetting[1].value ? "" : "MuiSwitch-track-none"}
                  checked={AdminSetting[1].value}
                  onChange={() => AdminSettingChange(1)}
                />
                <SuiBox sx={{ ml: 2 }}>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                    fontWeight="medium"
                    color="dark"
                  >
                    계약 이관 신청
                  </SuiTypography>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                    fontWeight="medium"
                    color="secondary"
                  >
                    개별 계약 이관 신청, 일괄 계약 담당자 변경 신청시 알림 수신
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
                <Switch
                  color="info2"
                  className={AdminSetting[2].value ? "" : "MuiSwitch-track-none"}
                  checked={AdminSetting[2].value}
                  onChange={() => AdminSettingChange(2)}
                />
                <SuiBox sx={{ ml: 2 }}>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                    fontWeight="medium"
                    color="dark"
                  >
                    데이터 삭제 신청
                  </SuiTypography>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                    fontWeight="medium"
                    color="secondary"
                  >
                    작가, 유통사 데이터 삭제 신청시 알림 수신
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiTypography
                sx={{ fontSize: 20, mt: 4, textAlign: "left", letterSpacing: -0.5 }}
                fontWeight="bold"
              >
                이메일 알림
              </SuiTypography>
              <SuiBox display="flex" alignItems="center" sx={{ mt: 2 }}>
                <Switch
                  color="info2"
                  className={AdminSetting[3].value ? "" : "MuiSwitch-track-none"}
                  checked={AdminSetting[3].value}
                  onChange={() => AdminSettingChange(3)}
                />
                <SuiBox sx={{ ml: 2 }}>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#668B9E" }}
                    fontWeight="medium"
                    color="dark"
                  >
                    알림을 이메일로 받기
                  </SuiTypography>
                  <SuiTypography
                    sx={{ fontSize: 16, textAlign: "left", letterSpacing: -0.5, color: "#535353" }}
                    fontWeight="medium"
                    color="secondary"
                  >
                    회원 등급 변경 신청, 계약 이관 신청 발생시{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>{myData.email}</span>{" "}
                    (으)로 전송됩니다.
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox sx={{ p: 0, mt: 6 }} display="flex">
                <SuiButton
                  sx={{
                    color: "#707070",
                    ml: "auto",
                    mr: 2,
                    width: 97.38,
                    border: 1,
                    borderColor: "#707070",

                    height: 40,
                    fontSize: 14,
                    boxShadow: 0,
                    boxShadow: disableCancleAdmin() ? "none !important" : "",
                  }}
                  variant="text"
                  color="subTitle"
                  onClick={() => (disableCancleAdmin() ? "" : CancelAdminSetting())}
                >
                  취소
                </SuiButton>
                <SuiButton
                  color="info2"
                  onClick={() => SaveAdminSetting()}
                  sx={{
                    height: 40,
                    fontSize: 14,
                  }}
                >
                  저장하기
                </SuiButton>
              </SuiBox>
            </SuiBox>
          ) : (
            ""
          )}
        </div>
      )
    }
  }
  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // {...other}
    >
      {value === index && (
        <SuiBox display="flex" justifyContent="center" bgColor="#F1F1F5" minHeight="85vh" px={14}>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                {children}
              </SuiTypography>
              <SuiTypography
                sx={{ mt: 2, fontWeight: 500, fontSize: 18, mb: "40px" }}
                color="subTitle"
                variant="h6"
                component="div"
              >
                {subText()}
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
        </SuiBox>
      )}
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <WithdrawlDialog
        openDialog={open_WithdrawlDialog}
        closeDialog={close_WithdrawlDialog}
        setWithdrawlDate={setWithdrawlDate}
      />
      <RejectDialog
        openDialog={reject_Dialog}
        closeDialog={() => setreject_Dialog(false)}
        MainTitle={"회원 탈퇴 거절"}
        Content={"회원 탈퇴 신청이 반려되었습니다. <br /> 아래의 거절 사유를 확인해보세요."}
        CanBtnText={"확인"}
        CreBtnColor={"info2"}
        AlertType={"oneBtn"}
        requestExplainShowData={requestExplain_reject}
        type={"detail"}
      />
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function SettingDialog({ open, closeDialog }) {
  const [tab, setTab] = useState(0)
  const handleChange = (event, newValue) => {
    setTab(newValue)
  }
  useEffect(() => {
    const homeRef = document.getElementById("top-setting")
    if (homeRef) {
      setTimeout(() => {
        homeRef.scrollIntoView({ behavior: "smooth" })
      }, 300)
    }
  }, [tab])
  const [openDialog, setopenDialog] = useState(open)
  const handleClose = () => {
    setopenDialog(false)
    closeDialog()
    setopenLeave_Dialog(false)
  }

  useEffect(() => {
    setopenDialog(open)
  }, [open])
  const { editStatus } = useSelector((state) => state.store)
  const leaveCheck = () => {
    if (editStatus) {
      handleClose()
    } else {
      setopenLeave_Dialog(true)
    }
  }

  const [openLeave_Dialog, setopenLeave_Dialog] = useState(false)

  const saveExit = async () => {
    await document.getElementById("profileSave").click()
    handleClose()
    setopenLeave_Dialog(false)
  }
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      sx={{ maxWidth: 1320, margin: "0 auto", marginTop: "136px" }}
    >
      <AppBar
        sx={{
          maxWidth: 1320,
          margin: "0 auto",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar>
          <SuiTypography
            sx={{ ml: 2, flex: 1, fontWeight: 700, color: "dark", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            설정
          </SuiTypography>
          <IconButton edge="start" color="inherit" onClick={() => leaveCheck()} aria-label="close">
            <MdClose />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className="dialog-menu-tabs"
        id="top-setting"
      >
        <SuiBox
          sx={{
            maxWidth: 1320,
            margin: "0 auto",
            position: "fixed",
            top: 64,
            backgroundColor: "#ffffff",
            width: "100%",
            borderTop: 1,
            borderBottom: 1,
            borderColor: "#CED4D7",
            zIndex: 10,
          }}
          fullWidth
        >
          <Tabs value={tab} onChange={handleChange} centered>
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiUserPin />}
              iconPosition="start"
              label="회원정보"
            />
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<CgArrowsExchangeAlt />}
              iconPosition="start"
              label="담당자 변경"
            />
            <Tab
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiBellMinus />}
              iconPosition="start"
              label="알림 설정"
            />
          </Tabs>
        </SuiBox>
        <TabPanel value={tab} index={0}>
          프로필
        </TabPanel>
        <TabPanel value={tab} index={1}>
          계약 담당자 변경 신청
        </TabPanel>
        <TabPanel value={tab} index={2}>
          알림
        </TabPanel>
      </Box>
      <SuiDialog
        openDialog={openLeave_Dialog}
        closeDialog={() => setopenLeave_Dialog(false)}
        MainTitle={"저장하고 나가기"}
        Content={"정보 변경 또는 추가 후 저장하지 않은 내역이 있습니다. 저장하고 나가시겠습니까?"}
        CanBtnText={"저장안함"}
        CreBtnText={"저장함"}
        exitBtnText={"취소"}
        CreBtnColor="info2"
        AlertType="threeBtn"
        Active={() => saveExit()}
        ActiveLeave={() => handleClose()}
      />
    </Dialog>
  )
}

SettingDialog.defaultProps = {
  open: false,
}

SettingDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
}

export default SettingDialog
