import React, { useEffect, useState } from "react"
import axios from "axios"
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Avatar from "@mui/material/Avatar"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Card from "@mui/material/Card"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"

import SuiDialog from "components/SuiDialog"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"

import NoticeDialog from "./components/NoticeDialog"
import NoticeDetailDialog from "./components/NoticeDetailDialog"

import { MdKeyboardArrowLeft } from "react-icons/md"
import { AiOutlineUser, AiOutlineCloseCircle } from "react-icons/ai"
import { MdClose } from "react-icons/md"
import { BiUserPin, BiBellMinus } from "react-icons/bi"
import { CgArrowsExchangeAlt } from "react-icons/cg"
import { FiSearch, FiMoreHorizontal } from "react-icons/fi"

import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import GetFileIcon from "components/getFileIcon"
// API
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import { createDonwloadLog, updateNotice, createNotice } from "apollo/mutation"
import { me, users, notices } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../reducers/store"

function TabPanel(props) {
  const client = useApolloClient()
  const [PFurl, setPFurl] = useState("")
  const [PFid, setPFid] = useState(null)
  const [changeRole, setchangeRole] = useState("")
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)

  const { children, value, index, ...other } = props
  const subText = () => {
    if (value === 0) {
      return "업데이트 소식 및 공지사항을 작성합니다."
    }
  }

  const { myData } = useSelector((state) => state.store)

  const dispatch = useDispatch()

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })

  const [noticesActive] = useLazyQuery(notices, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  useEffect(() => {
    dispatch(loading_Apply(true))
    notice_call()
  }, [])

  const [updateNoticeActive] = useMutation(updateNotice, {
    onError(err) {
      alert("수정 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createNoticeActive] = useMutation(createNotice, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [noticeDatas_origin, setnoticeDatas_origin] = useState([]) // 공지사항 백업 데이터
  const [noticeDatas, setnoticeDatas] = useState([]) // 공지사항 표현 데이터

  const notice_call = async () => {
    await client
      .query({
        query: notices,
        variables: { show: true },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.notices))
        if (FIlterSortingNotice === "최신순") {
          data.sort((a, b) => {
            var dateA = new Date(a.created_at).getTime()
            var dateB = new Date(b.created_at).getTime()

            return dateA < dateB ? 1 : -1
          })
        } else if (FIlterSortingNotice === "오래된 순") {
          data.sort((a, b) => {
            var dateA = new Date(a.created_at).getTime()
            var dateB = new Date(b.created_at).getTime()
            return dateA > dateB ? 1 : -1
          })
        }
        setnoticeDatas_origin(JSON.parse(JSON.stringify(data)))
        setnoticeDatas(data)
        dispatch(loading_Apply(false))
      })
      .catch((err) => {
        dispatch(loading_Apply(false))
      })
  }

  const PFImgShow = () => {
    if (PFurl === "") {
      return (
        <IconButton
          disabled
          color="dark"
          className="profile-defalut-icon"
          size="large"
          sx={{ mr: 2 }}
        >
          <AiOutlineUser />
        </IconButton>
      )
    } else {
      return (
        <span>
          <Avatar alt="Profile Img" src={PFurl} sx={{ width: 52, height: 52, mr: 2 }} />
        </span>
      )
    }
  }

  const [typeData, settypeData] = useState("detail")
  const authCheck = () => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else {
      return false
    }
  }
  const startEdit = () => {
    settypeData("update")
    dispatch(editStatus_Apply(false))
  }
  const resetEdit = () => {
    settypeData("detail")
    let origin = JSON.parse(JSON.stringify(noticeDatas_origin))
    setnoticeDatas(origin)
    dispatch(editStatus_Apply(true))
  }
  const update_Notice = async () => {
    dispatch(loading_Apply(true))
    for await (const el of noticeDatas) {
      if (el.added) {
        let new_data = { ...el }
        // 추가
        if (el.file) {
          // 사진 업로드
          let data = new FormData()
          data.append("files", el.file)
          await axios
            .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
            .then(async (res) => {
              new_data.file = res.data[0].id
              // create notice - 사진 업로드
              await createNoticeActive({
                variables: new_data,
              })
                .then(() => {})
                .catch((err) => {})
            })
            .catch((err) => {})
        } else {
          // create notice - 사진 x
          await createNoticeActive({
            variables: new_data,
          })
            .then(() => {})
            .catch((err) => {})
        }
      } else if (el.changed) {
        // 수정
        let new_data = { ...el }
        if (el.file && el.file.url) {
          // 사진 그대로
          new_data.file = el.file.id
          await updateNoticeActive({
            variables: new_data,
          })
            .then(() => {})
            .catch((err) => {})
        } else {
          if (el.file) {
            // 사진 수정
            let data = new FormData()
            data.append("files", el.file)
            await axios
              .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
              .then(async (res) => {
                new_data.file = res.data[0].id
                // update notice - 사진 업로드
                await updateNoticeActive({
                  variables: new_data,
                })
                  .then(() => {})
                  .catch((err) => {})
              })
              .catch((err) => {})
          } else {
            // 사진 삭제
            await updateNoticeActive({
              variables: new_data,
            })
              .then(() => {})
              .catch((err) => {})
          }
        }
      }
    }
    await notice_call()
    dispatch(editStatus_Apply(true))
    settypeData("detail")
  }

  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="100%"
        maxWidth="1320px"
        alignItems="center"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        {typeData === "detail" ? (
          <SuiButton color="info2" sx={{ width: 100, fontSize: 14 }} onClick={() => startEdit()}>
            수정
          </SuiButton>
        ) : (
          <SuiButton
            color="subTitle"
            variant="text"
            sx={{ width: 100, border: 1, borderColor: "#707070", height: 40, mr: 2, fontSize: 14 }}
            onClick={() => resetEdit()}
          >
            취소
          </SuiButton>
        )}
        {value === 0 && (
          <SuiButton
            color="info2"
            // disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => update_Notice()}
            id="saveEditBtn_not"
          >
            저장하기
          </SuiButton>
        )}
      </SuiBox>
    )
  }

  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)
  const [AnchorElRowData, setAnchorElRowData] = useState([[{}, { created_at: "" }]])
  const open = Boolean(anchorEl)

  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
    if (row) {
      setAnchorElRowData(row)
    }
  }
  const handleMenu = () => {
    setAnchorEl(null)
  }
  const NoticeDatasShow = noticeDatas.map((data, index) => {
    let fileName
    let fileType
    if (data.file) {
      fileName = data.file?.name
      fileType = fileName.split(".")[fileName.split(".").length - 1]
    }
    return data.show ? (
      <Grid item xs={6} key={index}>
        <Card
          className={typeData === "detail" ? "cursor" : ""}
          sx={{
            border: 1,
            borderColor: "#CED4D7",
            boxShadow: 0,
            minWidth: 491,
            p: 2.5,
            mb: 2,
            mr: index % 2 === 0 ? 2 : 0,
            textAlign: "left",
            height: typeData === "detail" ? "160px" : "165px",
          }}
        >
          <SuiBox
            display="flex"
            sx={{ alignItems: "center", marginBottom: "6px" }}
            onClick={() => DetailMeetingOpen(data)}
          >
            <Chip
              className={`${data.type}_chip`}
              label={{ notice: "공지사항", update: "업데이트", etc: "기타" }[data.type]}
              sx={{
                borderRadius: "8px",
              }}
            />
            <SuiTypography
              fontSize="18px"
              fontWeight="bold"
              color="dark"
              sx={{ ml: 1.5, mr: "auto" }}
            >
              {data.title}
            </SuiTypography>
            {typeData !== "update" && typeData !== "create" ? (
              ""
            ) : (
              <div>
                {/* {myData.id === data.data[1].makerId ? ( */}
                <div>
                  <IconButton
                    aria-label="more"
                    id={`long-button`}
                    aria-controls="long-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, index)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    sx={{ display: index === anchorElIndex ? "" : "none" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenu}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                      },
                    }}
                  >
                    <MenuItem onClick={(e) => MettingOption(data, e, index)}>수정</MenuItem>
                    <MenuItem onClick={(e) => MettingOption(data, e, index)}>삭제</MenuItem>
                  </Menu>
                </div>
                {/* ) : (
                  ""
                )} */}
              </div>
            )}
          </SuiBox>
          <SuiTypography fontSize="15px" color="subTitle" onClick={() => DetailMeetingOpen(data)}>
            {data.department} {data.name} {data.position} ·
            {dayjs(data.created_at).format("YYYY.MM.DD. HH:mm")}
          </SuiTypography>
          <SuiTypography
            fontSize="16px"
            sx={{ py: 2.5, color: "#535353" }}
            onClick={() => DetailMeetingOpen(data)}
          >
            <span
              className="ql-snow textOverFlow"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></span>
          </SuiTypography>
          <SuiBox>
            {/* {data.file.map((file, index) => { */}
            {/* return ( */}
            {data.file ? (
              <SuiBox
                justifyContent="start"
                display="flex"
                alignItems="start"
                sx={{ height: "auto", pt: 0 }}
              >
                <IconButton sx={{ mr: 1, p: 0, pt: 0.8 }} className="cursorDefalut">
                  {GetFileIcon(fileType)}
                </IconButton>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{
                    pt: 0.8,
                    pb: 1,
                    mr: 0.5,
                    cursor: "pointer",
                    cursor: data.file.url ? "pointer" : "default",
                  }}
                  onClick={() => DownloadPdf(data.file)}
                >
                  {data.file.name.length > 20
                    ? data.file.name.substring(0, 20) + "..."
                    : data.file.name}
                </SuiTypography>
                {data.file.url ? (
                  <div style={{ width: "107px" }}>
                    <SuiButton
                      sx={{
                        backgroundColor: "#EEEEEE",
                        ml: "auto",
                        minHeight: 24,
                        height: 24,
                        width: 24,
                        minWidth: 24,
                        p: 0,
                        ml: 1,
                        boxShadow: 0,
                      }}
                      onClick={() => showPdf(data.file)}
                    >
                      <FiSearch size="15" />
                    </SuiButton>
                    {/* <SuiButton
                      color="info"
                      sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                      onClick={() => DownloadPdf(data.file)}
                    >
                      다운로드
                    </SuiButton> */}
                  </div>
                ) : (
                  ""
                )}
              </SuiBox>
            ) : null}
            {/* )
            })} */}
          </SuiBox>
        </Card>
      </Grid>
    ) : null
  })

  const [FIlterSortingNotice, setFIlterSortingNotice] = useState("최신순")

  const change_FIlterSortingNotice = (value) => {
    let data = [...noticeDatas]
    let data_origin = [...noticeDatas_origin]

    setFIlterSortingNotice(value)

    if (value === "최신순") {
      data.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
      data_origin.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
    } else if (value === "오래된 순") {
      data.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
      data_origin.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
    }
    setnoticeDatas(data)
    setnoticeDatas_origin(data_origin)
  }
  const [FiltersOpen7, setFiltersOpen7] = useState(false)

  //  =============== 공지사항 alert  start =========
  const [ContractList, setContractList] = useState([])
  const [NoticeData, setNoticeData] = useState([])

  const [NoticeDialogBool, setNoticeDialogBool] = useState(false)
  const [editNoticeBool, seteditNoticeBool] = useState(false)
  const [add_index, setadd_index] = useState(0)
  const [editNoticeData, seteditNoticeData] = useState({})
  const [NoticeDetailDialogBool, setNoticeDetailDialogBool] = useState(false)
  const [DetailNoticeData, setDetailNoticeData] = useState({
    name: "",
    position: "",
    department: "",
    created_at: "",
    title: "",
    type: "",
    content: "",
    file: { name: " ", url: " " },
  })
  const addMettingDialog = () => {
    let addIndex = noticeDatas.filter((el) => el.added).length
    setadd_index(addIndex)
    seteditNoticeBool(false)
    setNoticeDialogBool(true)
  }

  const DetailMeetingOpen = (data) => {
    if (typeData === "detail") {
      setDetailNoticeData(data)
      setNoticeDetailDialogBool(true)
    }
  }
  const MettingOption = (data, e, index) => {
    // setContractList(ContractDatas)
    if (e.target.innerText === "수정") {
      seteditNoticeBool(true)
      setNoticeDialogBool(true)
      seteditNoticeData(data)
    } else if (e.target.innerText === "삭제") {
      setopenNoticeDelete_Dialog(true)
      setNoticeDelete_index(index)
    }
    setAnchorEl(null)
  }

  const DownloadPdf = (file) => {
    if (!file.url) {
      return
    }
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const [openNoticeDelete_Dialog, setopenNoticeDelete_Dialog] = useState(false)
  const [NoticeDelete_index, setNoticeDelete_index] = useState(false)
  const meetings_close = () => {
    setopenNoticeDelete_Dialog(false)
  }
  const Delete_MeettingData = () => {
    setopenNoticeDelete_Dialog(false)
    let data = [...noticeDatas]
    data[NoticeDelete_index].show = false
    data[NoticeDelete_index]["changed"] = true
    setnoticeDatas(data)
  }
  const closeDialog = () => {
    setNoticeDialogBool(false)
    setNoticeDetailDialogBool(false)
  }

  const closeDialog2 = () => {
    setNoticeDialogBool(false)
    setNoticeDetailDialogBool(false)
  }

  const addNotice = (new_data) => {
    let data = [...noticeDatas]
    data.unshift(new_data)
    setnoticeDatas(data)
  }

  const editNotice = (edit_data) => {
    let data = [...noticeDatas]
    let edit_index = data.findIndex((el) => el.id === edit_data.id)
    if (edit_data.id && edit_index > -1) {
      data[edit_index].title = edit_data.title
      data[edit_index].name = edit_data.name
      data[edit_index].position = edit_data.position
      data[edit_index].department = edit_data.department
      data[edit_index].type = edit_data.type
      data[edit_index].content = edit_data.content
      data[edit_index].file = edit_data.file
      data[edit_index]["changed"] = edit_data.changed
    } else if (edit_data.added) {
      let added_edit_index = data.findIndex((el) => el.added === edit_data.added)
      if (added_edit_index > -1) {
        data[added_edit_index].title = edit_data.title
        data[added_edit_index].name = edit_data.name
        data[added_edit_index].position = edit_data.position
        data[added_edit_index].department = edit_data.department
        data[added_edit_index].type = edit_data.type
        data[added_edit_index].content = edit_data.content
        data[added_edit_index].file = edit_data.file
      }
    }
    setnoticeDatas(data)
  }
  //  =============== 공지사항 alert  end =========

  const Content = () => {
    if (value === 0) {
      return (
        <div>
          <NoticeDialog
            openDialog={NoticeDialogBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            NoticeDataAdd={addNotice}
            NoticeDataEdit={editNotice}
            edit={editNoticeBool}
            editData={editNoticeData}
            addIndex={add_index}
          />
          <NoticeDetailDialog
            openDialog={NoticeDetailDialogBool}
            closeDialog={closeDialog}
            detailData={DetailNoticeData}
          />
          <SuiDialog
            openDialog={openNoticeDelete_Dialog}
            closeDialog={() => meetings_close()}
            MainTitle={"공지사항 삭제하기"}
            Content={"등록하신 공지사항을 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_MeettingData()}
          />

          {typeData === "create" || typeData === "update" ? (
            <SuiButton
              sx={{
                mt: 7.5,
                minHeight: 67,
                fontSize: 18,
                fontWeight: 700,
                boxShadow: 3,
                width: "100%",
              }}
              className="outlined-bg-white"
              variant="outlined"
              color="primary"
              onClick={() => addMettingDialog()}
            >
              공지사항 추가하기
            </SuiButton>
          ) : (
            ""
          )}
          <SuiBox sx={{ width: 100, ml: "auto", mt: 4 }}>
            <FormControl
              sx={{ minHeight: 30, width: "100%" }}
              className={
                FIlterSortingNotice === ""
                  ? "setting-selectbox select-none-active"
                  : "setting-selectbox"
              }
              onClick={() => setFiltersOpen7(!FiltersOpen7)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen7(!FiltersOpen7)}
                  />
                )}
                open={FiltersOpen7}
                value={FIlterSortingNotice}
                onChange={(e) => change_FIlterSortingNotice(e.target.value)}
                className="setting-border-radius"
              >
                <MenuItem value="최신순">최신순</MenuItem>
                <MenuItem value="오래된 순">오래된 순</MenuItem>
              </Select>
            </FormControl>
          </SuiBox>
          <SuiBox sx={{ mt: 4, mb: 12 }}>
            <Grid container>{NoticeDatasShow}</Grid>
            {noticeDatas.length === 0 ? (
              <SuiTypography
                fontSize="14px"
                fontWeight="regular"
                color="text2"
                sx={{ textAlign: "left" }}
              >
                아직 등록된 공지사항이 없습니다.
              </SuiTypography>
            ) : null}
          </SuiBox>
        </div>
      )
    }
  }
  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // {...other}
    >
      {value === index && (
        <SuiBox display="flex" justifyContent="center" bgColor="#F1F1F5" minHeight="85vh" px={14}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                {children}
              </SuiTypography>
              <SuiTypography
                sx={{ mt: 2, fontWeight: 500, fontSize: 18 }}
                color="subTitle"
                variant="h6"
                component="div"
              >
                {subText()}
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
        </SuiBox>
      )}
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />

      {authCheck() ? FooterForm() : ""}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Notice_Dialog({ open, closeDialog }) {
  const [tab, setTab] = useState(0)
  const { myData } = useSelector((state) => state.store)
  const dispatch = useDispatch()

  const [openDialog, setopenDialog] = useState(open)
  const handleClose = () => {
    setopenDialog(false)
    closeDialog()
    setopenLeave_Dialog(false)
  }
  useEffect(() => {
    setopenDialog(open)
    dispatch(editStatus_Apply(true))
  }, [open])
  const { editStatus } = useSelector((state) => state.store)
  const leaveCheck = () => {
    if (editStatus) {
      handleClose()
    } else {
      setopenLeave_Dialog(true)
    }
  }

  const [openLeave_Dialog, setopenLeave_Dialog] = useState(false)

  const saveExit = async () => {
    await document.getElementById("saveEditBtn_not").click()
    handleClose()
    setopenLeave_Dialog(false)
  }

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      sx={{ maxWidth: 1320, margin: "0 auto", marginTop: "64px" }}
    >
      <AppBar
        sx={{
          maxWidth: 1320,
          margin: "0 auto",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar>
          <SuiTypography
            sx={{ ml: 2, flex: 1, fontWeight: 700, color: "dark", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            info
          </SuiTypography>
          <IconButton edge="start" color="inherit" onClick={() => leaveCheck()} aria-label="close">
            <MdClose />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className="dialog-menu-tabs"
        id="top-setting"
      >
        <TabPanel value={tab} index={0}>
          공지사항
        </TabPanel>
      </Box>
      <SuiDialog
        openDialog={openLeave_Dialog}
        closeDialog={() => setopenLeave_Dialog(false)}
        MainTitle={"저장하고 나가기"}
        Content={"정보 변경 또는 추가 후 저장하지 않은 내역이 있습니다. 저장하고 나가시겠습니까?"}
        CanBtnText={"저장안함"}
        CreBtnText={"저장함"}
        exitBtnText={"취소"}
        CreBtnColor="info2"
        AlertType="threeBtn"
        Active={() => saveExit()}
        ActiveLeave={() => handleClose()}
      />
    </Dialog>
  )
}

Notice_Dialog.defaultProps = {
  open: false,
}

Notice_Dialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
}

export default Notice_Dialog
