import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import SuiButton from "components/SuiButton"
import SuiBox from "components/SuiBox"

//아이콘
import FirstPageIcon from "@mui/icons-material/FirstPage"
import LastPageIcon from "@mui/icons-material/LastPage"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

const PaginationCustom = ({ totalPage, page, changePage }) => {
  useEffect(() => {
    setdefault_range(0)
    list_set()
  }, [totalPage])
  const [default_list, setdefault_list] = useState([])
  const [default_range, setdefault_range] = useState(0)

  const list_set = () => {
    let list = []
    for (let index = 1; index <= totalPage; index++) {
      list.push(index)
    }
    setdefault_list(list)
  }
  const click_arrow = (type) => {
    if (type === "next") {
      if (totalPage < (default_range + 1) * 10 + 1) {
        changePage("", totalPage)
        if (Math.floor(totalPage / 10) === totalPage / 10) {
          setdefault_range(Math.floor(totalPage / 10) - 1)
        } else {
          setdefault_range(Math.floor(totalPage / 10))
        }
      } else {
        changePage("", (default_range + 1) * 10 + 1)
        setdefault_range(default_range + 1)
      }
    } else if (type === "end") {
      changePage("", totalPage)
      if (Math.floor(totalPage / 10) === totalPage / 10) {
        setdefault_range(Math.floor(totalPage / 10) - 1)
      } else {
        setdefault_range(Math.floor(totalPage / 10))
      }
    } else if (type === "prev") {
      if (page < 21) {
        changePage("", 1)
        setdefault_range(0)
      } else {
        changePage("", (default_range - 1) * 10 + 1)
        setdefault_range(default_range - 1)
      }
    } else if (type === "first") {
      changePage("", 1)
      setdefault_range(0)
    }
  }
  return (
    <SuiBox>
      <FirstPageIcon
        className={page === 1 ? "custom_pagination_arrow_hide" : "cursor"}
        sx={{ verticalAlign: "middle", mx: 0.5 }}
        onClick={() => {
          if (page !== 1) {
            click_arrow("first")
          }
        }}
      />
      <KeyboardArrowLeftIcon
        className={page === 1 ? "custom_pagination_arrow_hide" : "cursor"}
        sx={{ verticalAlign: "middle", mx: 1.3 }}
        onClick={() => {
          if (page !== 1) {
            click_arrow("prev")
          }
        }}
      />
      {default_list.map((el, index) => {
        return el <= (default_range + 1) * 10 && el > default_range * 10 ? (
          <SuiButton
            className={"custom_pagination_item" + (page === el ? "_active" : "")}
            key={index}
            sx={{ ml: "auto", fontSize: 14 }}
            onClick={(e) => changePage(e, el)}
          >
            {el}
          </SuiButton>
        ) : null
      })}
      <KeyboardArrowRightIcon
        className={page === totalPage ? "custom_pagination_arrow_hide" : "cursor"}
        sx={{ verticalAlign: "middle", mx: 1.3 }}
        onClick={() => {
          if (page !== totalPage) {
            click_arrow("next")
          }
        }}
      />
      <LastPageIcon
        className={page === totalPage ? "custom_pagination_arrow_hide" : "cursor"}
        sx={{ verticalAlign: "middle", mx: 0.5 }}
        onClick={() => {
          if (page !== totalPage) {
            click_arrow("end")
          }
        }}
      />
    </SuiBox>
  )
}

PaginationCustom.defaultProps = {
  totalPage: 0,
  page: 0,
}

PaginationCustom.propTypes = {
  totalPage: PropTypes.number,
  page: PropTypes.number,
  changePage: PropTypes.func,
}
export default PaginationCustom
