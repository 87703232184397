import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Dialog from "@mui/material/Dialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MobileStepper from "@mui/material/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import Snackbar from "@mui/material/Snackbar"
import { FaThLarge, FaTh } from "react-icons/fa"
import ReferrerCheck from "../referrerCheck"

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import FileUploadDialog from "../FileUploadDialog"
import ReferrerDialog from "../referrerDialog"
import ContractDialog from "../ContractDialog"
import HistoryCreateDialog from "../HistoryCreateDialog"
import HistoryDialog from "../HistoryDialog"
import ContractRenewalDialog from "../ContractRenewalDialog"
import ContractSubDialog from "../ContractSubDialog"
import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiDialog from "components/SuiDialog"
import DeleteDialog from "../deleteDialog"
import MettingDeleteDialog from "../MettingDeleteDialog"
import ContractChangeInfoDialog from "../ContractChangeInfoDialog"
import ContractMangerInfoDialog from "../ContractMangerInfoDialog"
import ContractChangeDialog from "../ContractChangeDialog"
import ContractInfoDialog from "../ContractInfoDialog"
import SubContractInfoDialog from "../SubContractInfoDialog"
import ContractDeleteDialog from "../ContractdeleteDialog"
import DeleteDenyDialog from "../DeleteDenyDialog"
import ChangeDialog from "../ChangeDialog"

import { BiCalendar, BiRecycle, BiImages } from "react-icons/bi"
import { BsFillCircleFill } from "react-icons/bs"
import { AiOutlinePlus, AiOutlineUnorderedList, AiFillCloseCircle } from "react-icons/ai"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { FiSearch, FiMoreHorizontal } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import GetFileIcon from "components/getFileIcon"
import { AiOutlineFile } from "react-icons/ai"
import { RiDeleteBinLine } from "react-icons/ri"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { MdClose, MdOutlineLocationSearching } from "react-icons/md"
import { CgUserAdd } from "react-icons/cg"
import DaumPostcode from "react-daum-postcode"
import dayjs from "dayjs"

// API
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import {
  updateOutsourcing,
  createOutsourcingAttachment,
  updateOutsourcingAttachment,
  createOutsourcingHistory,
  updateOutsourcingHistory,
  createoutsourcingContract,
  updateoutsourcingContract,
  createRequest,
  updateRequest,
  createRecent,
  createAlert,
  createChangeRequest,
  createOutsourcingSignificant,
  updateOutsourcingSignificant,
  createDonwloadLog,
  createSendEmail,
  updateChangeRequest,
} from "apollo/mutation"
import {
  outsourcing,
  users,
  changeRequests,
  me,
  users_list,
  outsourcingContract,
  OutsourcingSignificants,
  settings,
} from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../../../reducers/store"

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "No",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "구분",
    },
    {
      id: "work",
      numeric: false,
      disablePadding: false,
      label: "작업 내역",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "시작일",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "완료일",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "단가(원)",
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "특이사항",
    },
    {
      id: "file",
      numeric: false,
      disablePadding: false,
      label: "파일 형식",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "파일 형식" || headCell.label === "작업 내역"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}

function EnhancedTableHead2(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "계약유형",
    },
    {
      id: "work",
      numeric: false,
      disablePadding: false,
      label: "계약 담당자",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "계약 일자",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "계약 종료일",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "subCount",
      numeric: false,
      disablePadding: false,
      label: "부속계약(수)",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "계약 일자" || headCell.label === "부속계약(수)"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead2.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead2.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}
function EnhancedTableHead3(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, changeFilter, showchangeFilter } =
    props
  const headCells = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "버전",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "유형",
    },
    {
      id: "work",
      numeric: false,
      disablePadding: false,
      label: "내용",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "등록자",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "등록일",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "형식",
    },
  ]
  const [headCelldata, setheadCelldata] = useState(headCells)
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "내용" ||
                headCell.label === "등록자" ||
                headCell.label === "형식"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead3.defaultProps = {
  showchangeFilter: {},
}

EnhancedTableHead3.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
}

function TabPanel(props) {
  const handleStepChange = (step, ContractIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].activeStep = step
    setContractDatas(array)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalpage, settotalpage] = useState(10)
  const [rows, setrows] = useState([])
  const [chagne_cancel_dailog, setchagne_cancel_dailog] = useState(false)
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [rowlength, setrowlength] = useState(0)
  const [FilesSorting, setFilesSorting] = useState("최신순")
  const [FilesSortingHistory, setFilesSortingHistory] = useState("넓게보기")
  const [FilesSortingImage, setFilesSortingImage] = useState("이미지")
  const [FilesSortingMetting, setFilesSortingMetting] = useState("최신순")
  const [FilesSortingContract, setFilesSortingContract] = useState("최신순")
  const [SubContractSorting, setSubContractSorting] = useState("최신순")
  const [TeamSelect, setTeamSelect] = useState("콘텐츠사업본부")
  const [addressOpen, setaddressOpen] = useState(false)
  const [referrerIds, setreferrerIds] = useState([])
  const [ContarctChangeData, setContarctChangeData] = useState([{ managerLog: [] }])
  const [ContractChangeDialogOpenBool, setContractChangeDialogOpenBool] = useState(false)
  const [createContractShow, setcreateContractShow] = useState(false)
  const [deleteContractIds, setdeleteContractIds] = useState([])
  const [ContractChangeDialogInfoOpenBool, setContractChangeDialogInfoOpenBool] = useState(false)
  const [ContarctChangeInfoData, setContarctChangeInfoData] = useState([])
  const { myData } = useSelector((state) => state.store)
  const [selectContarct, setselectContarct] = useState([])
  const [selectDataEdit, setselectDataEdit] = useState("")
  const [ReferrerCheckDialog, setReferrerCheckDialog] = useState(false)
  const [ReferrerCheckData, setReferrerCheckData] = useState([])
  const selectDataProps = (row) => {
    setselectDataEdit(row)
  }
  const {
    children,
    value,
    index,
    inputListsExport,
    referrerIdsExport,
    AttachmentDatasExport,
    MeetingDatasExport,
    SignificantsDatasExport,
    editId,
    type,
    edit,
  } = props
  const [typeData, settypeData] = useState(type)
  useEffect(() => {
    settypeData(type)
  }, [type])
  useEffect(() => {
    const data = [...ContractDatas]
    if (selectContarct.length > 0) {
      data.forEach((el2, index) => {
        if (selectContarct.length > 0) {
          if (selectContarct[0][0].id) {
            if (el2[0][0].id === selectContarct[0][0].id) {
              el2[0][0].referrer = referrerIds
              el2[0][0].changed = true
              el2[0][0].changed = true
            }
          } else {
            if (selectContarct[0][1].created_at === el2[0][1].created_at) {
              el2[0][0].referrer = referrerIds
              el2[0][0].changed = true
              el2[0][0].changed = true
            }
          }
        }

        if (index === data.length - 1) {
          setContractDatas(
            data.sort((a, b) => {
              var dateA = new Date(a[0][1].created_at).getTime()
              var dateB = new Date(b[0][1].created_at).getTime()
              return dateA < dateB ? 1 : -1
            })
          )
          // setselectContarct([])
        }
      })
    }
  }, [referrerIds])
  const addFilesBtn = () => {
    seteditBool(false)
    setFileUploadDialogBool(true)
  }

  useLayoutEffect(() => {
    settings_Call()
  }, [])
  const settings_ref = useRef(null)
  const settings_Call = () => {
    settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        let slice_index = el.list.findIndex((el) => el === "-")
        if (slice_index > -1) {
          el.list.splice(slice_index, 1)
          settings[el.type] = el.list
        } else {
          settings[el.type] = el.list
        }
      })
      settings_ref.current = settings
    })
  }

  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const settings_return = (type, items) => {
    let list = []
    if (type) {
      if (settings_ref.current) {
        let slice_index = settings_ref.current[type].findIndex((el) => el === "-")
        if (slice_index > -1) {
          list = settings_ref.current[type].splice(slice_index, 1)
        } else {
          list = settings_ref.current[type]
        }
      } else {
        list = items
      }
    } else {
      list = items
    }
    return list
  }
  let InputListsSet = [
    // 0
    {
      file: null,
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "selectBox",
      setting_type: "outType",
      items: ["일러스트", "편집", "기타"],
      title: "구분:",
      maxLength: 100,
      xs: 2,
    },
    // 1
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "본명(회사명):",
      maxLength: 100,
      xs: 2,
    },
    // 2
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "닉네임:",
      maxLength: 100,
      xs: 2,
    },
    // 3
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "장르:",
      maxLength: 100,
      xs: 2,
    },
    // 4
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "대표 작업작품 명:",
      maxLength: 12,
      xs: 2,
    },
    // 5
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "selectBox",
      setting_type: "outContract",
      items: ["의뢰 가능", "계약 중", "계약해지"],
      title: "계약상태:",
      xs: 2,
    },
    // 6
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "주민등록번호(사업자번호):",
      maxLength: 50,
      xs: 3,
    },
    // 7
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이메일:",
      maxLength: 100,
      xs: 3,
      copy: true,
    },
    // 8
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "연락처:",
      maxLength: 13,
      xs: 3,
    },
    // 9
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "address",
      title: "주소:",
      maxLength: 100,
      xs: 3,
      copy: true,
    },
    // 10
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "rangeNumber",
      title: "단가 범위(세전, 원):",
      maxLength: 100,
      xs: 2,
    },
    // 11
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "rangeNumber",
      title: "",
      maxLength: 100,
      xs: 2,
    },
    // 12
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "예금주:",
      maxLength: 100,
      xs: 2,
    },
    // 13
    {
      items: [
        "KB국민은행",
        "우리은행",
        "신한은행",
        "하나은행",
        "SC제일은행",
        "한국씨티은행",
        "기업은행",
        "케이뱅크",
        "카카오뱅크",
        "토스뱅크",
        "농협",
        "수협",
        "신협",
        "산림조합",
        "상호저축은행",
        "새마을금고",
        "BNK부산은행",
        "BNK경남은행",
        "광주은행",
        "DGB대구은행",
        "전북은행",
        "제주은행",
      ],
      itemsValue: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "bankCount",
      setting_type: "bank",
      title: "계좌번호:",
      maxLength: 14,
      xs: 6,
      copy: true,
    },
  ]
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY

  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [Delete_Contarct_Active, setDelete_Contarct_Active] = useState(false)
  const [originSignificants, setoriginSignificants] = useState([])
  const [Significants, setSignificants] = useState([])
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createRecentActive] = useMutation(createRecent, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [me, outsourcing],
  })
  const [createOutsourcingSignificantActive] = useMutation(createOutsourcingSignificant, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateOutsourcingSignificantActive] = useMutation(updateOutsourcingSignificant, {
    onError(err) {
      alert("수정 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })

  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (secondDateObj.getTime() - firstDateObj.getTime() <= 0) {
      return "light2"
    }
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "error"
    } else {
      return "light2"
    }
  }
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const updateContarctDropDown = (type, bool, ContractIndex) => {
    let array = [...ContractDatas]
    if (type === 0) {
      array[ContractIndex][0][0].dropdown1 = !bool
    } else if (type === 1) {
      array[ContractIndex][0][0].dropdown2 = !bool
    }
    setContractDatas(array)
  }
  const copyData = async (row, index) => {
    if (index === 13) {
      await navigator.clipboard.writeText(row.itemsValue + "/" + row.value)
    } else {
      await navigator.clipboard.writeText(row.value)
    }
    snackOpenClick2()
  }
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [outsourcing],
  })
  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [outsourcing],
  })
  const client = useApolloClient()
  const Delete_All = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      profile: myData.profile,
      suggestRole: "외주계약 삭제신청",
      requestExplain: requestExplainData,
      title: DetailData.nickname,
      outsourcingId: DetailData.id,
    }
    if (DetailData.histroy.length > 0) {
      request.first_history = DetailData.histroy[DetailData.histroy.length - 1]
    }
    createRequestActive({
      variables: {
        status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
        content: request,
        type: "Outsourcing",
        outsourcing: DetailData.id,
        requestId: DetailData.id,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      client
        .query({
          query: users_list,
          variables: {
            status: "근무",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          res.data.users
            .filter((x) => x.role.id === 1)
            .forEach((user) => {
              if (user.adminAlertSetting) {
                if (user.adminAlertSetting[2].value) {
                  createAlertActive({
                    variables: {
                      users_permissions_user: String(user.id),
                      title: "외주계약 삭제 신청",
                      type: "Delete",
                      userid: String(user.id),
                      content: {
                        id: myData.id,
                        position: myData.position,
                        department: myData.department,
                        name: myData.name,
                        profile: myData.profile,
                        DetailData: DetailData,
                        targetName: DetailData.nickname,
                      },
                    },
                  })
                }
              }
            })

          const editHistory = [...DetailData.histroy]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>" + DetailData.nickname + " 社 </strong> 정보 삭제를 신청하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          updateOutsourcingActive({
            variables: {
              id: DetailData.id,
              histroy: editHistory,
            },
          })

          dispatch(loading_Apply(false))
          setDelete_Dialog(false)
        })
    })
  }
  const ContractDataReset = () => {
    setcreateContractShow(!createContractShow)
  }
  const handleInputContracts = (index, type, maxLength) => (e) => {
    let array = [...ContractDatas]
    if (maxLength >= e.target.value.length) {
      array[index][type].value = e.target.value
    }
    array[index][1].changed = true
    setContractData(array)
  }
  const handleInputContract = (index, maxLength) => (e) => {
    let array = [...ContractData]
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
    }
    setContractData(array)
  }
  const editSubContract = (data, row, rowIndex, contract) => {
    setContarctSubData(row)
    setContractSubDialogOpenBool(true)
    setContractSubDialogEdit(true)
    setContractSubDialogEditData(data)
    setContractSubDialogRowIndex(rowIndex)
    setContarctSubParentData(contract)
  }
  const handleInput = (index, maxLength, index2, type) => (e) => {
    if (value === 0) {
      let array = [...InputLists]
      if (maxLength >= e.target.value.length) {
        array[index].value = e.target.value
        if (typeData === "update") {
          array[index].changed = true
        }
        if (index === 6) {
          let num_special = /[0-9!~@#$%^&*-_-+=(){}.,/]$/
          let num_test = array[index].value.match(num_special)
          if (!num_test) {
            array[index].value = array[index].value.substring(0, array[index].value.length - 1)
          }
          array[index].error = false
          array[index].error_message = ""
        } else if (index === 8) {
          let num_special = /[0-9!~@#$%^&*-_-+=(){}.,/]$/
          let num_test = array[index].value.match(num_special)
          if (!num_test) {
            array[index].value = array[index].value.substring(0, array[index].value.length - 1)
          }

          // array[index].value = array[index].value
          //   .replace(/[^0-9]/, "")
          //   .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
          array[index].error = false
          array[index].error_message = ""
        } else if (index === 13) {
          array[index].value = array[index].value.replace(/[^0-9]/, "")
          array[index].error = false
          array[index].error_message = ""
          if (array[index].itemsValue === "") {
            array[index].error = true
            array[index].error_message = "은행을 선택하여 주십시오."
          }
          if (array[index].value === "") {
            array[index].error = false
            array[index].error_message = ""
          }
        } else if (index === 10) {
          array[index].value = array[index].value.replace(/[^\d]+/g, "")
          array[index].value = array[index].value.replace(/[^0-9]/, "")
          array[index].value = array[index].value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
        } else if (index === 11) {
          array[index].value = array[index].value.replace(/[^\d]+/g, "")
          array[index].value = array[index].value.replace(/[^0-9]/, "")
          array[index].value = array[index].value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
        } else {
          array[index].error = false
          array[index].error_message = ""
        }
      }

      let reitemValue
      let reValue
      let recompanynumber
      let rephone
      let readdress
      if (DetailData.account && DetailData.account.length >= 44) {
        reitemValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
        reValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else if (DetailData.account) {
        reitemValue = DetailData.account.split("/")[0]
        reValue = DetailData.account.split("/")[1]
      }
      if (DetailData.bizNumber) {
        recompanynumber = CryptoJS.AES.decrypt(DetailData.bizNumber, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        recompanynumber = DetailData.bizNumber
      }

      if (DetailData.phone && DetailData.phone.length >= 14) {
        rephone = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        rephone = DetailData.phone
      }

      if (DetailData.address && DetailData.address.length >= 44) {
        readdress = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        readdress = DetailData.address
      }

      if (ischeck === false) {
        if (
          array[0].value === DetailData.type &&
          array[1].value === DetailData.name &&
          array[2].value === DetailData.nickname &&
          array[3].value === DetailData.genre &&
          array[4].value === DetailData.mainTitle &&
          array[5].value === DetailData.contractStatus &&
          array[6].value === recompanynumber &&
          array[7].value === DetailData.email &&
          array[8].value === rephone &&
          array[9].value === readdress &&
          array[10].value.replace(/[^\d]+/g, "") === String(DetailData.minPrice) &&
          array[11].value.replace(/[^\d]+/g, "") === String(DetailData.maxPrice) &&
          array[12].value === DetailData.accountName &&
          array[13].itemsValue === reitemValue &&
          array[13].value === reValue
        ) {
          setcreateContractShow(false)
        } else {
          setcreateContractShow(true)
        }
      }

      setInputLists(array)
    } else if (value === 1) {
      if (type === "update") {
        let array = [...ContractDatas]
        if (maxLength >= e.target.value.length) {
          array[index2][index].value = e.target.value
          if (typeData === "update") {
            array[index2][index].changed = true
          }
        }
        setContractDatas(array)
      } else {
        let array = [...ContractData]
        if (maxLength >= e.target.value.length) {
          array[index].value = e.target.value
          if (typeData === "update") {
            array[index].changed = true
          }
        }
        setContractData(array)
      }
    }
  }
  const handleSelectChange = (index, index2, type) => (e) => {
    if (value === 0) {
      let array = [...InputLists]
      if (array[index].type === "bankCount") {
        array[index].itemsValue = e.target.value
        array[index].error = false
        array[index].error_message = ""
        if (typeData === "update") {
          array[index].changed = true
        }
      } else {
        array[index].value = e.target.value
        if (typeData === "update") {
          array[index].changed = true
        }
      }
      let reitemValue
      let reValue
      let rebiznumber
      let rephone
      let readdress

      if (DetailData.account && DetailData.account.length >= 44) {
        reitemValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
        reValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else if (DetailData.account) {
        reitemValue = DetailData.account.split("/")[0]
        reValue = DetailData.account.split("/")[1]
      }

      if (DetailData.biznumber && DetailData.biznumber.length >= 44) {
        rebiznumber = CryptoJS.AES.decrypt(DetailData.biznumber, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        rebiznumber = DetailData.biznumber
      }

      if (DetailData.phone && DetailData.phone.length >= 14) {
        rephone = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        rephone = DetailData.phone
      }

      if (DetailData.address && DetailData.address.length >= 44) {
        readdress = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      } else {
        readdress = DetailData.address
      }

      if (ischeck === false) {
        if (
          array[0].value === DetailData.type &&
          array[1].value === DetailData.name &&
          array[2].value === DetailData.nickname &&
          array[3].value === DetailData.genre &&
          array[4].value === DetailData.mainTitle &&
          array[5].value === DetailData.contractStatus &&
          array[6].value === rebiznumber &&
          array[7].value === DetailData.email &&
          array[8].value === rephone &&
          array[9].value === readdress &&
          array[10].value.replace(/[^\d]+/g, "") === String(DetailData.minPrice) &&
          array[11].value.replace(/[^\d]+/g, "") === String(DetailData.maxPrice) &&
          array[12].value === DetailData.accountName &&
          array[13].itemsValue === reitemValue &&
          array[13].value === reValue
        ) {
          setcreateContractShow(false)
        } else {
          setcreateContractShow(true)
        }
      }

      setInputLists(array)
    } else if (value === 1) {
      if (type === "update") {
        let array = [...ContractDatas]
        array[index2][index].value = e.target.value
        if (typeData === "update") {
          array[index2][1].changed = true
        }
        setContractDatas(array)
      } else {
        let array = [...ContractData]
        array[index].value = e.target.value
        if (typeData === "update") {
          array[index].changed = true
        }
        setContractData(array)
      }
    }
  }
  const handlereferrerIdsDelete = (user) => {
    if (user.id) {
      setreferrerIds([...referrerIds.filter((y) => y.id !== user.id)])
    } else {
      setreferrerIds([...referrerIds.filter((y) => y.department !== user.name)])
    }

    setcreateContractShow(true)
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false)
  const [FiltersOpen7, setFiltersOpen7] = useState(false)
  const [FiltersOpen8, setFiltersOpen8] = useState(false)
  const [FiltersOpen9, setFiltersOpen9] = useState(false)
  const [FiltersOpen10, setFiltersOpen10] = useState(false)
  const [historySplice, sethistorySplice] = useState(10)
  const [historySplice2, sethistorySplice2] = useState(5)
  const [historySplice3, sethistorySplice3] = useState(10)
  const [FiltersOpenIndex, setFiltersOpenIndex] = useState("")
  const [FiltersOpenIndex1, setFiltersOpenIndex1] = useState("")
  const filterOpenClick = (row, type) => {
    if (type === "subContract") {
      setFiltersOpenIndex(row[0][0].id)
      setFiltersOpen5(!FiltersOpen5)
    } else if (type === "memo") {
      setFiltersOpenIndex1(row[0][0].id)
      setFiltersOpen6(!FiltersOpen6)
    }
  }
  useEffect(() => {
    if (!FiltersOpen5) {
      setFiltersOpenIndex("")
    }
  }, [FiltersOpen5])

  const InputTypeEl = (data, index, index2, type) => {
    if (typeData === "detail") {
      return (
        <SuiBox sx={{ width: "100%", mt: !data.title ? "22.75px" : "" }}>
          {data.value?.length < 30 ? (
            index === 4 && data.value.length > 10 ? (
              <Tooltip
                arrow={false}
                color="white"
                classes={{ popper: "tooltip-light" }}
                title={
                  <SuiBox>
                    <SuiTypography
                      style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                      verticalAlign="middle"
                    >
                      {data.value}
                    </SuiTypography>
                  </SuiBox>
                }
              >
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {data.value.substring(0, 10) + " ..."}
                </Card>
              </Tooltip>
            ) : (index === 0 || index === 9) && data.value.length > 12 ? (
              <Tooltip
                arrow={false}
                color="white"
                classes={{ popper: "tooltip-light" }}
                title={
                  <SuiBox>
                    <SuiTypography
                      style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                      verticalAlign="middle"
                    >
                      {data.value}
                    </SuiTypography>
                  </SuiBox>
                }
              >
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {data.value.substring(0, 12) + " ..."}
                </Card>
              </Tooltip>
            ) : data.title !== "" ? (
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {index === 13
                  ? data.itemsValue + " / " + data.value
                  : index === 6 && data.value.length > 30
                  ? data.value.substring(0, 30) + " ..."
                  : data.value}
              </Card>
            ) : (
              <SuiBox display="flex" alignItems="center">
                <SuiBox sx={{ mb: 1, mr: 2 }}>~</SuiBox>
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {index === 13
                    ? data.itemsValue + " / " + data.value
                    : index === 6 && data.value.length > 30
                    ? data.value.substring(0, 30) + " ..."
                    : data.value}
                </Card>
              </SuiBox>
            )
          ) : (
            <Tooltip
              arrow={false}
              color="white"
              classes={{ popper: "tooltip-light" }}
              title={
                <SuiBox>
                  <SuiTypography
                    style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                    verticalAlign="middle"
                  >
                    {data.value}
                  </SuiTypography>
                </SuiBox>
              }
            >
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {index === 13
                  ? data.itemsValue + " / " + data.value
                  : index === 6 && data.value.length > 30
                  ? data.value.substring(0, 30) + " ..."
                  : data.value}
              </Card>
            </Tooltip>
          )}
        </SuiBox>
      )
    } else if (typeData === "create" || typeData === "update") {
      if (data.type === "textFiled") {
        return (
          <SuiBox className={value === 1 ? "textFiled-Writer" : ""}>
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength, index2, type)}
            />
          </SuiBox>
        )
      } else if (data.type === "address") {
        return (
          <SuiBox>
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength)}
            />
            <Dialog open={addressOpen} maxWidth="lg">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setaddressOpen(false)}
                aria-label="close"
                sx={{ alignSelf: "self-end", padding: "15px 10px 15px" }}
              >
                <MdClose />
              </IconButton>
              <SuiBox sx={{ width: 480 }}>
                <DaumPostcode onComplete={handleComplete} {...props} />
              </SuiBox>
            </Dialog>
          </SuiBox>
        )
      } else if (data.type === "bankCount") {
        return (
          <SuiBox display="flex">
            <SuiBox sx={{ width: 200 }}>
              <FormControl
                fullWidth
                sx={{ minHeight: 30 }}
                className={
                  data.itemsValue === ""
                    ? "setting-selectbox select-none-active"
                    : "setting-selectbox backCout"
                }
                onClick={() => setFiltersOpen1(!FiltersOpen1)}
              >
                <InputLabel>은행선택</InputLabel>
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    />
                  )}
                  open={FiltersOpen1}
                  value={data.itemsValue}
                  error={data.error}
                  success={data.success}
                  // helperText={data.error_message}
                  onChange={handleSelectChange(index)}
                  className="setting-border-radius-right"
                >
                  {settings_return(data.setting_type, data.items).map((x) => {
                    return (
                      <MenuItem value={x} key={x}>
                        {x}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </SuiBox>
            <SuiInput
              className="backCount-left-input"
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength)}
            />
          </SuiBox>
        )
      } else if (data.type === "rangeNumber" && index === 10) {
        return (
          <SuiBox
            className={value === 1 ? "textFiled-Writer" : ""}
            display="flex"
            alignItems="center"
          >
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder="단가 최소값"
              value={data.value}
              onChange={handleInput(index, data.maxLength, index2, type, 1)}
            />
            <SuiBox sx={{ ml: 1, mb: 1 }}>~</SuiBox>
          </SuiBox>
        )
      } else if (data.type === "rangeNumber" && index === 11) {
        return (
          <SuiBox
            className={value === 1 ? "textFiled-Writer" : ""}
            sx={{ mt: "22.75px" }}
            display="flex"
            alignItems="center"
          >
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder="단가 최대값"
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index, data.maxLength, index2, type, 2)}
            />
          </SuiBox>
        )
      } else if (data.type === "selectBox" && index === 0) {
        return (
          <SuiBox sx={{ marginBottom: "6px" }}>
            <FormControl
              fullWidth
              className={
                data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
              }
              onClick={() => setFiltersOpen2(!FiltersOpen2)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen2(!FiltersOpen2)}
                  />
                )}
                open={FiltersOpen2}
                value={data.value}
                onChange={handleSelectChange(index, index2, type)}
                className="setting-border-radius"
              >
                {data.items.map((x) => {
                  return (
                    <MenuItem value={x} key={x}>
                      {x}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </SuiBox>
        )
      } else if (data.type === "selectBox" && index === 5) {
        return (
          <SuiBox sx={{ marginBottom: "6px" }}>
            <FormControl
              fullWidth
              className={
                data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
              }
              onClick={() => setFiltersOpen8(!FiltersOpen8)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen8(!FiltersOpen8)}
                  />
                )}
                open={FiltersOpen8}
                value={data.value}
                onChange={handleSelectChange(index, index2, type)}
                className="setting-border-radius"
              >
                {settings_return(data.setting_type, data.items).map((x) => {
                  return (
                    <MenuItem value={x} key={x}>
                      {x}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </SuiBox>
        )
      }
    }
  }
  const [InputLists, setInputLists] = useState(InputListsSet)
  const [ContractData, setContractData] = useState([])
  const [contract_documents_data, setcontract_documents_data] = useState([])
  const [contract_documents_data_update, setcontract_documents_data_update] = useState([])
  const [contract_documents_activeStep, setcontract_documents_activeStep] = useState(0)
  const [contract_documents_data_index, setcontract_documents_data_index] = useState(0)
  useEffect(() => {
    let array = [...ContractDatas]
    if (contract_documents_data.length > 0) {
      array.unshift([contract_documents_data])
    }
    setContractDatas(array)
  }, [contract_documents_data])
  const [ContractDatas, setContractDatas] = useState([])
  useEffect(() => {
    let array = [...ContractDatas]
    if (contract_documents_data_update.length > 0) {
      let DocDatas = [...array[contract_documents_data_index]]
      if (!ContractDialogEdit) {
        DocDatas.unshift([contract_documents_data_update])
      } else {
        if (DocDatas[contract_documents_activeStep][0].id) {
          contract_documents_data_update[0].id = DocDatas[contract_documents_activeStep][0].id
        }
        contract_documents_data_update[0].user = DocDatas[contract_documents_activeStep][0].user
        contract_documents_data_update[0].ContractType =
          DocDatas[contract_documents_activeStep][0].ContractType
        contract_documents_data_update[0].changed = true
        contract_documents_data_update[0].memo = DocDatas[contract_documents_activeStep][0].memo
        contract_documents_data_update[0].subContract =
          DocDatas[contract_documents_activeStep][0].subContract
        contract_documents_data_update[0].managerLog =
          DocDatas[contract_documents_activeStep][0].managerLog
        DocDatas[contract_documents_activeStep] = contract_documents_data_update
      }
      array[contract_documents_data_index][0][0].changed = true
      array[contract_documents_data_index] = DocDatas

      // 계약서 수정시 부속계약서 수정(상위 계약서랑 동일 일경우)
      let find_index = []
      let first_contract = ""
      if (DocDatas[0][0].subContract.length === 1) {
        // 최초계약 하나 일경우
        find_index.push(0)
        first_contract = true
      } else {
        // 갱신계약이 있을경우
        first_contract = false
        for (let i = 0; i < DocDatas[0][0].subContract.length; i++) {
          let element = DocDatas[0][0].subContract[i]
          if (element[0].ContractType.split("_")[1] === String(DocDatas.length - 1)) {
            find_index.push(i)
          }
        }
      }

      if (!first_contract) {
        for (let i = 0; i < find_index.length; i++) {
          let idx = find_index[i]
          if (DocDatas[0][0].subContract[idx][1].value === "상위 계약과 동일") {
            DocDatas[0][0].subContract[idx][1].value1 = DocDatas[0][1].value1
            DocDatas[0][0].subContract[idx][1].value2 = DocDatas[0][1].value2
          }
        }
      } else if (first_contract) {
        DocDatas[0][0].subContract[0][1].value1 = DocDatas[0][1].value1
        DocDatas[0][0].subContract[0][1].value2 = DocDatas[0][1].value2
      }
    }
    setContractDatas(array)
  }, [contract_documents_data_update])
  const handleComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }
    let array = [...InputLists]
    array[9].value = fullAddress
    setInputLists(array)
    setaddressOpen(false)
  }

  const fileText = (data) => {
    if (data.length > 40) {
      let result = data.slice(0, 30) + "..." + data.slice(-7)

      return result
    } else {
      return data
    }
  }

  const rowCheckContract = (rows, row) => {
    if (rows.length === 1) {
      return false
    } else {
      if (rows.length - 1 === Number(row[0].ContractType.split("_")[1])) {
        return false
      } else {
        return true
      }
    }
  }
  const InputListShow = InputLists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
        <SuiBox>
          <SuiBox display="flex">
            <SuiTypography
              fontSize="14px"
              fontWeight="bold"
              color="info"
              sx={{ textAlign: "left", pt: 2, pb: 1 }}
            >
              {row.title}
            </SuiTypography>
            {typeData === "detail" ? (
              row.copy ? (
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="info2"
                  className="cursor"
                  sx={{
                    textAlign: "right",
                    pt: 2,
                    ml: "auto",
                    pb: 1,
                    mr: "14px",
                    textDecoration: "underline",
                  }}
                  onClick={() => copyData(row, index)}
                >
                  복사
                </SuiTypography>
              ) : (
                ""
              )
            ) : (
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color="info2"
                className="cursor"
                sx={{
                  textAlign: "right",
                  pt: 2,
                  ml: "auto",
                  pb: 1,
                  textDecoration: "underline",
                  display: row.type === "address" ? "" : "none",
                }}
                onClick={() => setaddressOpen(true)}
              >
                주소 검색
              </SuiTypography>
            )}
          </SuiBox>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)
  const [AnchorElRowData, setAnchorElRowData] = useState([[{}, { created_at: "" }]])
  const [openDelete_Dialog, setopenDelete_Dialog] = useState(false)
  const [openSignificantDelete_Dialog, setopenSignificantDelete_Dialog] = useState(false)
  const [openMeettingDelete_Dialog, setopenMeettingDelete_Dialog] = useState(false)
  const [editBool, seteditBool] = useState(false)
  const [editData, seteditData] = useState([])
  const [editMettingBool, seteditMettingBool] = useState(false)
  const [editMettingData, seteditMettingData] = useState([])
  const [deleteMettings, setdeleteMettings] = useState(false)
  const [DetailMettingData, setDetailMettingData] = useState({
    data: [
      {
        value: "",
        inputType: "text",
        error_message: "",
        error: false,
        type: "textFiled",
        title: "제목",
        maxLength: 100,
        xs: 8,
        changed: false,
      },
      {
        items: [],
        value: "",
        error_message: "",
        error: false,
        type: "selectBox",
        title: "관련계약",
        maxLength: 100,
        xs: 4,
        name: "",
        position: "",
        department: "",
        created_at: new Date(),
      },
      {
        type: "fileList",
        value: "",
        title: "내용",
        xs: 8,
        files: [],
      },
      {
        inputType: "text",
        value: "",
        items: ["작업이력", "인터뷰", "인수인계", "기타"],
        type: "category",
        title: "카테고리",
        xs: 4,
      },
    ],
  })
  const [openContractDelete_Dialog, setopenContractDelete_Dialog] = useState(false)
  const [deleteContractData, setdeleteContractData] = useState([])
  const [deleteMettingData, setdeleteMettingData] = useState([])
  const [deleteData, setdeleteData] = useState([])
  const [deleteDatas, setdeleteDatas] = useState([])
  const [DetailData, setDetailData] = useState({
    histroy: [],
    manager: [],
    requests: [],
    users: [],
    referrer: [],
  })
  const open = Boolean(anchorEl)
  let InputListsSetData = [
    { type: "picUpload", title: "파일 업로드:", xs: 6, file: {}, changed: false },
    {
      items: ["회사소개서", "통장사본", "사업자등록증", "포트폴리오", "신분증", "기타"],
      itemsValue: "회사소개서",
      value: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "유형:",
      maxLength: 30,
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      version: "최신",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "fileList",
      title: "",
      maxLength: 255,
      xs: 6,
    },
    // {
    //   inputType: "text",
    //   value: "",
    //   error_message: "",
    //   error: false,
    //   type: "textArea",
    //   title: "설명:",
    //   maxLength: 255,
    //   xs: 6,
    // },
  ]
  let historiesInputListsSet = [
    // 0
    {
      type: "picUpload",
      title: "파일 업로드:",
      xs: 6,
      file: {},
      changed: false,
      dropdown1: false,
      dropdown2: false,
      memo: [],
      memoSotring: "최신순",
      subContractSotring: "최신순",
      subContract: [],
      activeStep: 0,
      requests: [],
      selectDataProps: "",
    },
    // 1
    {
      value: [],
      type: "contract",
      title: "작품명:",
      value1: "표지",
      value2: "",
      items: ["표지", "타이포", "교정교열", "삽화", "콘텐츠", "배너", "기타"],
      title1: "구분:",
      title2: "단가(원):",
      xs: 6,
    },
    // 2
    {
      title: "업로드 파일:",
      type: "fileList",
      xs: 6,
    },
    // 3
    {
      value1: null,
      value2: null,
      type: "datePicker",
      title: "작업 시작일:",
      title1: "작업 완료일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    // 4
    {
      xs: 6,
    },
    // 5
    {
      inputType: "text",
      value: "",
      type: "textArea",
      title: "특이사항:",
      maxLength: 50,
      xs: 6,
    },
  ]
  const [changeRequestsActive] = useLazyQuery(changeRequests, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const { data } = useQuery(outsourcing, {
    fetchPolicy: "network-only",
    variables: {
      id: editId,
    },
    onError() {
      dispatch(loading_Apply(false))
    },
  })

  useEffect(() => {
    dispatch(loading_Apply(true))
    if (data) {
      firstChangeRequestsActive(data.outsourcing.id)
      setDetailData(data.outsourcing)
      let array = [...InputLists]
      array[0].value = data.outsourcing.type
      array[1].value = data.outsourcing.name
      array[2].value = data.outsourcing.nickname
      array[3].value = data.outsourcing.genre
      array[4].value = data.outsourcing.mainTitle
      array[5].value = data.outsourcing.contractStatus
      if (data.outsourcing.bizNumber.length < 44) {
        array[6].value = data.outsourcing.bizNumber
      } else {
        array[6].value = CryptoJS.AES.decrypt(data.outsourcing.bizNumber, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      array[7].value = data.outsourcing.email
      if (data.outsourcing.phone.length < 14) {
        array[8].value = data.outsourcing.phone
      } else {
        array[8].value = CryptoJS.AES.decrypt(data.outsourcing.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      if (data.outsourcing.address.length < 44) {
        array[9].value = data.outsourcing.address
      } else {
        array[9].value = CryptoJS.AES.decrypt(data.outsourcing.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      array[10].value = String(data.outsourcing.minPrice).replace(
        /(\d)(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      )
      array[11].value = String(data.outsourcing.maxPrice).replace(
        /(\d)(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      )
      array[12].value = data.outsourcing.accountName
      if (
        CryptoJS.AES.decrypt(data.outsourcing.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .indexOf("/") >= 0
      ) {
        array[13].itemsValue = CryptoJS.AES.decrypt(data.outsourcing.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
        array[13].value = CryptoJS.AES.decrypt(data.outsourcing.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else {
        array[13].itemsValue = ""
        array[13].value = ""
      }
      let arrayAttachments = []
      data.outsourcing.outsourcing_attachments.forEach((element) => {
        InputListsSetData[0].file = element.file[0]
        InputListsSetData[1].id = element.id
        InputListsSetData[1].value = element.title
        InputListsSetData[1].itemsValue = element.type
        InputListsSetData[1].name = element.users_permissions_user.name
        InputListsSetData[1].position = element.users_permissions_user.position
        InputListsSetData[1].department = element.users_permissions_user.department
        InputListsSetData[1].created_at = element.created_at
        // InputListsSetData[3].value = element.explain
        arrayAttachments.push({ data: JSON.parse(JSON.stringify(InputListsSetData)) })
      })
      setAttachmentDatas(
        arrayAttachments.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      let arrayhistories = []
      data.outsourcing.outsourcing_histories.forEach((element) => {
        historiesInputListsSet[0].selectDataProps = element.workType
        element.file.type = element.file.mime
        historiesInputListsSet[0].requests = element.requests
        historiesInputListsSet[0].file = element.file
        historiesInputListsSet[0].id = element.id
        historiesInputListsSet[1].created_at = element.created_at
        historiesInputListsSet[1].value = element.pieces
        historiesInputListsSet[1].value1 = element.type
        historiesInputListsSet[1].value2 = String(element.price)
        historiesInputListsSet[3].value1 = element.start
        historiesInputListsSet[3].value2 = element.end
        historiesInputListsSet[5].value = element.comment
        arrayhistories.push({ data: JSON.parse(JSON.stringify(historiesInputListsSet)) })
      })
      setMeetingDatas(
        arrayhistories.sort((a, b) => {
          var dateA = new Date(a.data[3].value2).getTime()
          var dateB = new Date(b.data[3].value2).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      let arrayContracts = []
      data.outsourcing.outsourcing_contracts.forEach((element) => {
        let ContractSet = element.contractPaper
        element.contractPaper.forEach((paper, paperIndex) => {
          ContractSet[0][0].oriReferrer = element.referrer
          ContractSet[0][0].id = element.id
          ContractSet[0][1].created_at = element.created_at
          ContractSet[0][0].subContract = element.subContract
          ContractSet[0][0].memo = element.memo
          ContractSet[0][0].managerLog = element.managerLog
          ContractSet[0][0].user = element.users_permissions_user
          ContractSet[0][0].changed = false
          ContractSet[0][0].requests = element.requests
        })
        arrayContracts.push(JSON.parse(JSON.stringify(ContractSet)))
      })
      setContractDatas(
        arrayContracts.sort((a, b) => {
          var dateA = new Date(a[0][1].created_at).getTime()
          var dateB = new Date(b[0][1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setInputLists(array)
      const x = JSON.parse(JSON.stringify(data.outsourcing.outsourcing_significants))
      setoriginSignificants(x)
      setSignificants(data.outsourcing.outsourcing_significants)
    }
    setTimeout(() => {
      dispatch(loading_Apply(false))
    }, 2000)
  }, [data])
  const [ChangeRequests, setChangeRequests] = useState([])
  const firstChangeRequestsActive = (id) => {
    changeRequestsActive({
      variables: {
        outsourcingId: id,
        status: "신청",
      },
    })
      .then((res) => {
        setChangeRequests(res.data.changeRequests)
      })
      .catch((err) => {})
  }
  const moreMetting = () => {}
  const Delete_ContractData = () => {
    let array = [...ContractDatas]
    let objectData = [...deleteContractIds]
    if (deleteContractData[0].id) {
      setdeleteContractIds([...objectData, deleteContractData[0]])
      array[contract_documents_data_index][0][0].changed = true
    }
    array.splice(contract_documents_data_index, 1)
    setDelete_Contarct_Active(true)
    setContractDatas(array)
    setopenContractDelete_Dialog(false)
    setcreateContractShow(true)
  }
  const [deleted_histories, setdeleted_histories] = useState([])
  const Delete_MeettingData = () => {
    let array = [...MeetingDatas]
    let deleted_array = [...deleted_histories]
    if (
      DetailData.outsourcing_histories.filter((x) => x.id === deleteMettingData[1].id).length > 0
    ) {
      deleted_array.push(deleteMettingData)
      setdeleted_histories(deleted_array)
    }
    setMeetingDatas(array.filter((x) => x.data[0].value !== deleteMettingData[0].value))
    setdeleteMettings(true)
    setopenMeettingDelete_Dialog(false)
    setcreateContractShow(true)
  }
  const [deleted_attachment, setdeleted_attachment] = useState([])
  const Delete_AttachmentData = () => {
    let array = [...AttachmentDatas]
    let deleted_array = [...deleted_attachment]
    if (DetailData.outsourcing_attachments.filter((x) => x.id === deleteData[1].id).length > 0) {
      deleted_array.push(deleteData)
      setdeleted_attachment(deleted_array)
    }
    setAttachmentDatas(array.filter((x) => x.data[1].value !== deleteData[1].value))
    const deleteArrays = [...deleteDatas]
    deleteArrays.push(deleteData)
    setdeleteDatas(deleteArrays)
    setopenDelete_Dialog(false)
    setcreateContractShow(true)
  }
  const chagneInfo = (rowData) => {
    dispatch(loading_Apply(true))
    changeRequestsActive({
      fetchPolicy: "network-only",
      variables: {
        type: "Outsourcing",
      },
    }).then(() => {
      changeRequestsActive({
        fetchPolicy: "network-only",
        variables: {
          type: "Outsourcing",
        },
      }).then((res) => {
        let Datas = []
        if (res.data.changeRequests.length > 0) {
          res.data.changeRequests.forEach((element, index) => {
            if (element.contractId === rowData[0].id) {
              Datas.push(element)
            } else if (element.type === "Writer / outsourcing") {
              element.content.companiesIds.forEach((contract) => {
                contract.outsourcing_contracts.forEach((document) => {
                  if (document.id === rowData[0].id) {
                    Datas.push(element)
                  }
                })
              })
            }
            if (index === res.data.changeRequests.length - 1) {
              setContarctChangeInfoData(Datas)
            }
          })
        } else {
          setContarctChangeInfoData([])
        }
        dispatch(loading_Apply(false))
        setContractChangeDialogInfoOpenBool(true)
      })
    })
  }
  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
    if (row) {
      setAnchorElRowData(row)
    }
  }
  const hisotyEdit = (data) => {
    setHistoryEdit(true)
    setTimeout(() => {
      setMeetingDialogBool(true)
    }, 500)
    setHistoryEditData(data)
    setselectDataEdit(data[0].selectDataProps)
  }
  const handleMenu = () => {
    setAnchorEl(null)
  }
  const createContractPaperBtn = () => {
    setContractDialogOpenBool(true)
    setContractType("create")
  }

  const [openContract_deleteDialog, setopenContract_deleteDialog] = useState(false)
  const [openMettingDeleteDialog, setopenMettingDeleteDialog] = useState(false)
  const [Contract_deleteData, setContract_deleteData] = useState([])
  const [MettingDeleteData, setMettingDeleteData] = useState([])
  // const [delete_ContractData, setdelete_ContractData] = useState([])
  // const deleteRequest = (data) => {}

  const ContractOption = (data, e, type, ContractIndex, activeStep, row) => {
    setContractType(type)
    setcontract_documents_activeStep(activeStep)
    setcontract_documents_data_index(ContractIndex)
    if (e.target.innerText === "수정") {
      setContractDialogEdit(false)
      setTimeout(() => {
        setContractDialogEdit(true)
      }, 200)
      setContractDialogOpenBool(true)
      setContractDialogEditData(data)
    } else if (e.target.innerText === "삭제신청") {
      setopenContract_deleteDialog(true)
      setContract_deleteData(row)
      setdeleteContractData(data)
    } else if (e.target.innerText === "계약이관") {
      data.DetailData = DetailData
      data.targetName = DetailData.nickname
      data.row = row
      setContractChangeDialogOpenBool(true)
      setContarctChangeData(data)
    } else if (e.target.innerText === "갱신계약") {
      setContarctRenewalType("renewal")
      setContractRenewalDialogOpenBool(true)
      setContarctRenewalData(data)
    } else if (e.target.innerText === "종료통고") {
      setContarctRenewalType("ContractEnd")
      setContractRenewalDialogOpenBool(true)
      setContarctRenewalData(data)
    }
    setAnchorEl(null)
  }

  const MettingOption = (data, e, index) => {
    setContractList(ContractDatas)
    if (e.target.innerText === "수정") {
      seteditMettingBool(false)
      setTimeout(() => {
        seteditMettingBool(true)
      }, 200)
      setMeetingDialogBool(true)
      seteditMettingData(data)
    } else if (e.target.innerText === "삭제") {
      setdeleteMettingData(data)
      setopenMeettingDelete_Dialog(true)
    }
    setAnchorEl(null)
  }
  const handleClose = (data, e, index) => {
    if (e.target.innerText === "수정") {
      seteditBool(false)
      setTimeout(() => {
        seteditBool(true)
      }, 200)
      seteditData(data)
      setFileUploadDialogBool(true)
    } else if (e.target.innerText === "삭제") {
      setdeleteData(data)
      setopenDelete_Dialog(true)
    }
    setAnchorEl(null)
  }
  const updateContractDialogopen = (ContractIndex) => {
    setContractType("update")
    setcontract_documents_data_index(ContractIndex)
    setContractDialogOpenBool(true)
    setContractDialogEdit(false)
    setContractDialogEditData([])
  }
  const paperSorting2 = (array) => {
    array = array[array.length - 1]

    return array
  }
  const FindingFirst = (Datas) => {
    let FirstDate
    let returnData

    Datas.forEach((contract) => {
      if (contract.length > 0) {
        if (!FirstDate) {
          FirstDate = paperSorting2(contract)[0].id
          returnData = paperSorting2(contract)
        }
        if (FirstDate > paperSorting2(contract)[0].id) {
          FirstDate = paperSorting2(contract)[0].id
          returnData = paperSorting2(contract)
        }
      }
    })
    if (returnData) {
      return returnData
    } else {
      return null
    }
  }
  const ITEM_HEIGHT = 48
  const subText = () => {
    if (value === 0) {
      return "외주 계약자의 기본 정보를 등록하고, 확인하실 수 있습니다."
    } else if (value === 1) {
      return "외주계약자와 계약한 계약정보와 계약서 등을 등록합니다."
    } else if (value === 2) {
      return "외주 계약자가 조아라와 작업한 결과물을 등록합니다."
    }
  }

  const [openContractInfoDialog, setopenContractInfoDialog] = useState(false)
  const [openSubContractInfoDialog, setopenSubContractInfoDialog] = useState(false)
  const [openNoContract_dialog, setopenNoContract_dialog] = useState(false)

  const [outsourcingContractActive] = useLazyQuery(outsourcingContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [InfoData, setInfoData] = useState([])
  const [SubInfoData, setSubInfoData] = useState([])

  const closeDialogInfo = () => {
    setopenContractInfoDialog(false)
    setopenSubContractInfoDialog(false)
  }

  const openInfoDialog = (contractPaper) => {
    setInfoData(contractPaper)
    setopenContractInfoDialog(true)
  }

  const clickContract = (row, type, title) => {
    dispatch(loading_Apply(true))
    outsourcingContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: row.contractId,
      },
    }).then(({ data }) => {
      if (data.outsourcingContract.outsourcing === null) {
        dispatch(loading_Apply(false))
        setopenNoContract_dialog(true)
      } else {
        outsourcingContractActive({
          fetchPolicy: "network-only",
          variables: {
            id: row.contractId,
          },
        }).then(({ data }) => {
          let contractData = { ...JSON.parse(JSON.stringify(data)) }
          dispatch(loading_Apply(false))
          if (type === "renewal") {
            contractData.outsourcingContract.contractPaper[
              contractData.outsourcingContract.contractPaper.length - 1 - row.renewalIndex
            ][0].managerLog.unshift(contractData.outsourcingContract.users_permissions_user)

            setSubInfoData(
              contractData.outsourcingContract.contractPaper[
                contractData.outsourcingContract.contractPaper.length - 1 - row.renewalIndex
              ]
            )
            setopenSubContractInfoDialog(true)
          } else {
            if (row.renewalIndex) {
              let array = [
                ...JSON.parse(
                  JSON.stringify(
                    contractData.outsourcingContract.contractPaper[
                      contractData.outsourcingContract.contractPaper.length - row.renewalIndex
                    ]
                  )
                ),
              ]
              array[0].ContractType = title
              array[0].managerLog.unshift(contractData.outsourcingContract.users_permissions_user)
              setSubInfoData(array)
              setopenSubContractInfoDialog(true)
            } else {
              if (row.contractLength) {
                contractData.outsourcingContract.contractPaper[
                  contractData.outsourcingContract.contractPaper.length - row.contractLength
                ].push(contractData)
                openInfoDialog(
                  contractData.outsourcingContract.contractPaper[
                    contractData.outsourcingContract.contractPaper.length - row.contractLength
                  ]
                )
              } else {
                contractData.outsourcingContract.contractPaper[0].push(contractData)
                openInfoDialog(contractData.outsourcingContract.contractPaper[0])
              }
            }
          }
        })
      }
    })
  }
  const authCheckContract = (row, type) => {
    if (typeData !== "create") {
      if (myData.role.name === "Authenticated") return true
      else if (myData.role.name === "gold") {
        if (row[0][0].user) {
          if (type) {
            if (row[0][0].user.id === myData.id) return true
          } else return true
        } else {
          return false
        }
      } else if (row[0][0].user) {
        if (row[0][0].user.id === myData.id) {
          return true
        } else {
          if (type) return false
          else {
            if (myData.role.name === "silver") {
              if (row[0][0].user.id === myData.id) return true
              else if (row[0][0].referrer) {
                if (row[0][0].referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
                else return false
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else {
        return false
      }
    }
  }
  const clickSubContract = (row) => {
    if (row.subDataSet) {
      let subData = [...JSON.parse(JSON.stringify(row.subDataSet))]
      outsourcingContractActive({
        fetchPolicy: "network-only",
        variables: {
          id: row.contractId,
        },
      }).then(({ data }) => {
        dispatch(loading_Apply(false))
        if (data.outsourcingContract.outsourcing !== null) {
          subData[0].managerLog.unshift(data.outsourcingContract.users_permissions_user)
        }
        setSubInfoData(subData)
        setopenSubContractInfoDialog(true)
      })
    } else {
      clickContract(row, "renewal")
    }
  }
  const downloadCheck = (row) => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else if (myData.role.name === "silver") {
      if (row[0].user) {
        if (row[0].user.id === myData.id) return true
        else if (row[0].referrer) {
          if (row[0].referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
          else return false
        } else {
          return false
        }
      } else {
        return false
      }
    } else if (myData.role.name === "bronze") {
      if (row[0].referrer) {
        if (row[0].referrer.map((x) => x.id).indexOf(myData.id) > -1) return true
        else return false
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const comma = (price) => {
    let str = String(price)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
  }

  const showTable = (type) => {
    if (type === 1) {
      let rever = 0
      MeetingDatas.map((x) => x.data).forEach((el) => {
        rever += Number(el[1].value2)
      })
      return rever / MeetingDatas.length
    } else {
      let rever = 0
      MeetingDatas.map((x) => x.data).forEach((el) => {
        rever += Number(el[1].value2)
      })
      return rever
    }
  }
  const historyContent = (row) => {
    let strongLength = row.content.match(/<strong[^>]*>([^<]+)<\/strong>/g)
    let firstStrong
    let secondStrong
    let hisotryType
    if (row.type.indexOf("contract") > -1) {
      if (strongLength) {
        firstStrong = strongLength[0].match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
        if (strongLength[1]) {
          secondStrong = strongLength[1].match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
        }
      }
      if (row.type === "contract") {
        hisotryType = row.content.slice(-10)
      } else {
        let startIndex = row.content.lastIndexOf("</strong>")
        hisotryType = row.content.substring(startIndex + 9)
      }
    }

    return row.type.indexOf("contract") > -1 ? (
      <span>
        <strong
          className="history-content-strong"
          onClick={() => clickContract(row, "", firstStrong)}
        >
          {firstStrong}
        </strong>
        {secondStrong ? "의" : ""}
        {secondStrong ? (
          <strong className="history-content-strong" onClick={() => clickSubContract(row)}>
            {" "}
            {secondStrong}
          </strong>
        ) : (
          ""
        )}
        {hisotryType}
      </span>
    ) : (
      ""
    )
  }
  const [InputValue, setInputValue] = useState("")
  const inputValue = (e) => {
    setInputValue(e.target.value)
  }
  const [ischeck, setischeck] = useState(false)
  const onClickSignificant = () => {
    let dt = new Date()
    let profileurl = ""
    if (myData.profile) {
      profileurl = myData.profile.url
    }

    setSignificants([
      ...Significants,
      {
        content: InputValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        outsourcing: DetailData.id,
        outsourcingId: myData.id,
        updated_at: dt,
      },
    ])

    const tag = document.getElementById("significantInputValue")
    tag.value = ""
    setInputValue("")
    setischeck(true)
    setcreateContractShow(true)
  }
  const [FilterSearchActive, setFilterSearchActive] = useState(false)
  const [openDeleteJudging_Dialog, setopenDeleteJudging_Dialog] = useState(false)
  const [DeleteJudging_Content, setDeleteJudging_Content] = useState("")
  const [openChangeJudging_Dialog, setopenChangeJudging_Dialog] = useState(false)
  const [ChangeJudging_Content, setChangeJudging_Content] = useState("")
  const [open_DenyReasonDialog, setopen_DenyReasonDialog] = useState(false)
  const [denyReason, setdenyReason] = useState("")
  const close_denyReason = () => {
    setopen_DenyReasonDialog(false)
  }
  const openChangeDialog = (row) => {
    const date = DetailData.change_requests.filter(
      (x) => x.arrayContent.companiesIds.indexOf(row[0][0].id) > -1
    ).created_at
    let content = "현재 계약서 이관 신청 건(" + dayjs(date).format("YY.MM.DD") + ")을 심사중입니다."
    setChangeJudging_Content(content)
    setopenChangeJudging_Dialog(true)
  }
  const openDeleteDialog = (row) => {
    let notRead = row[0][0].requests.filter((e) => {
      return e.read === false
    })
    if (notRead.length === 0) {
      return false
    } else {
      notRead.sort((a, b) => {
        var a = a.id
        var b = b.id
        return a < b ? 1 : -1
      })

      if (notRead[0].status.indexOf("심사중") > -1) {
        let content =
          "현재 계약서 삭제 신청 건(" +
          dayjs(notRead[0].created_at).format("YY.MM.DD") +
          ")을 심사중입니다."
        setDeleteJudging_Content(content)
        setopenDeleteJudging_Dialog(true)
      } else if (notRead[0].status.indexOf("거절") > -1) {
        updateRequestActive({
          variables: {
            id: notRead[0].id,
            read: true,
          },
        }).then(() => {
          let readId = row[0][0].requests.findIndex((e) => e.id === notRead[0].id)
          row[0][0].requests[readId].read = true
          setopen_DenyReasonDialog(true)
          setdenyReason(notRead[0].declining)
        })
      }
    }
  }
  const backStep = () => {
    setHistoryDialogOpenBool(true)
    setMeetingDialogBool(false)
  }
  const handleDelete = (x, row) => {
    const data = [...ContractDatas]
    if (row.length > 0) {
      data.forEach((el, index) => {
        if (el[0][0].referrer) {
          if (el[0][0].referrer.length > 0) {
            el[0][0].changed = true
            const index = el[0][0].referrer.map((x) => x.id).indexOf(x.id)
            el[0][0].referrer.splice(index, 1)
          }
        }
        if (index === data.length - 1) {
          setContractDatas(data)
          setcreateContractShow(true)
          // setselectContarct([])
        }
      })
    }
  }
  const [MaxHistoryList, setMaxHistoryList] = useState(4)
  const showChangeRequest = (row) => {
    if (DetailData.change_requests.length > 0) {
      if (
        DetailData.change_requests.filter(
          (x) => x.arrayContent.companiesIds.indexOf(row[0][0].id) > -1
        ).length > 0
      )
        return true
      else return false
    } else return false
  }
  const showDeleteRequest = (row) => {
    let notRead = row[0][0].requests.filter((e) => {
      return e.read === false
    })
    if (notRead.length === 0) {
      return false
    } else {
      notRead.sort((a, b) => {
        var a = a.id
        var b = b.id
        return a < b ? 1 : -1
      })

      if (notRead[0].status.indexOf("심사중") > -1) {
        return "삭제신청"
      } else if (notRead[0].status.indexOf("거절") > -1) {
        return "승인거절"
      }
    }
  }
  const AttachmentDatasShow_type2 = () => {
    return AttachmentDatas.length === 0 ? (
      <TableContainer
        sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0, marginRight: "40px" }}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead3
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowlength}
          />
        </Table>
        <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
          <SuiTypography fontSize="18px" color="dark" fontWeight="400">
            등록된 내역이 없습니다.
          </SuiTypography>
        </SuiBox>
      </TableContainer>
    ) : (
      <SuiBox
        sx={{
          width: "100%",
          paddingRight: "40px",
        }}
      >
        <TableContainer
          sx={{
            borderTop: "1px solid #F1F1F5",
            borderRadius: 0,
            boxShadow: 0,
            marginRight: "40px",
          }}
        >
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead3
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowlength}
            />
            <TableBody>
              {stableSort(
                AttachmentDatas.slice(0, historySplice2),
                getComparator(order, orderBy)
              ).map((data, index) => {
                const fileName = data.data[0].file?.name
                const fileType = fileName.split(".")[fileName.split(".").length - 1]
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ cursor: "pointer" }}
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiTypography
                        className="td-Setting td-fontSetting"
                        sx={{
                          fontWeight: "bold",
                          // backgroundColor: firstItemCheck(data) ? "#FBE8F1" : "#EEEEEE",
                          color: firstItemCheck(data) ? "#E67DAF !important" : "#A4A4A4 !important",
                          // borderRadius: "8px",
                        }}
                      >
                        {firstItemCheck(data) ? "최신" : "구버전"}
                      </SuiTypography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {data.data[1].itemsValue}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{
                        borderRight: "1px solid #F1F1F5",
                        minWidth: "400px",
                        width: "400px",
                      }}
                    >
                      {data.data[1].value}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      {data.data[1].name} {data.data[1].position}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiBox display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                          sx={{ mr: 1.5, p: 0 }}
                          className="cursorDefalut"
                          onClick={() => DownloadPdf(data.data[0].file)}
                        >
                          {GetFileIcon(fileType)}
                        </IconButton>
                        <SuiButton
                          sx={{
                            backgroundColor: "#EEEEEE",
                            minHeight: 24,
                            height: 24,
                            width: 24,
                            minWidth: 24,
                            p: 0,
                            ml: 1,
                            boxShadow: 0,
                            display: data.data[0].file.url ? "" : "none",
                          }}
                          onClick={() => showPdf(data.data[0].file)}
                        >
                          <FiSearch size="15" />
                        </SuiButton>

                        {typeData !== "update" && typeData !== "create" ? (
                          ""
                        ) : (
                          <div>
                            <IconButton
                              aria-label="more"
                              id={`long-button`}
                              aria-controls="long-menu"
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                              sx={{ color: "#000000" }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              sx={{ display: index === anchorElIndex ? "" : "none" }}
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleMenu}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                },
                              }}
                            >
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                수정
                              </MenuItem>
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                삭제
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </SuiBox>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {Math.ceil(historySplice2 / 5) < Math.ceil(AttachmentDatas.length / 5) ? (
          <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px" }}>
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => {
                sethistorySplice2(historySplice2 + 5)
              }}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }
  const allDownloadBtn = () => {
    document.getElementById("excelExport-history").click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        donwloadType: "excel",
        downloadList: MeetingDatas,
        downloadDate: new Date(),
      },
    })
  }
  const Content = () => {
    if (value === 0) {
      return (
        <div>
          <FileUploadDialog
            openDialog={FileUploadDialogBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            AttachmentData={setAttachmentData}
            edit={editBool}
            editData={editData}
          />

          <Card sx={{ mt: "40px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  개인 상세 정보
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <Grid container alignItems="center">
                  {InputListShow}
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>
          <Card sx={{ my: "16px", boxShadow: 0 }}>
            <SuiBox
              pl={5}
              pt={5}
              pb={2}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  첨부 파일
                </SuiTypography>
              </SuiBox>
              <SuiBox display="flex" sx={{ my: 1, pr: 5 }}>
                {typeData !== "update" && typeData !== "create" ? (
                  ""
                ) : (
                  <SuiButton
                    sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32, p: 1 }}
                    color="primary"
                    onClick={() => addFilesBtn()}
                  >
                    <AiOutlinePlus size="20" />
                  </SuiButton>
                )}
                <SuiBox sx={{ width: 100, ml: "auto", mb: "20px" }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilesSorting === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      value={FilesSorting}
                      onChange={(e) => setFilesSorting(e.target.value)}
                      className="setting-border-radius"
                    >
                      <MenuItem value="최신순">최신순</MenuItem>
                      <MenuItem value="오래된 순">오래된 순</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>
              </SuiBox>
              <SuiBox display="flex" sx={{ my: 1, width: "100%", overflowX: "auto", pb: 3 }}>
                {AttachmentDatasShow_type2()}
              </SuiBox>
            </SuiBox>
          </Card>
          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              pr={3}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  특이사항 기록
                </SuiTypography>
              </SuiBox>
              {(typeData === "update" || typeData === "create") && (
                <SuiBox sx={{ mb: 2 }}>
                  <SuiBox display="flex" sx={{ mt: 3, mr: 4 }}>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                      style={{ backgroundColor: "transparent" }}
                      src={
                        myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""
                      }
                    />
                    <SuiInput
                      type="textfield"
                      // className="significantInput"
                      id="significantInputValue"
                      sx={{ ml: 1, height: 100, mr: 2 }}
                      placeholder="내용을 입력해 주세요."
                      onChange={inputValue}
                    />
                  </SuiBox>

                  <SuiBox sx={{ textAlign: "end", mt: 3, mr: 3.5 }}>
                    <SuiButton
                      color="info2"
                      sx={{
                        width: 100,
                        fontSize: 14,
                      }}
                      disabled={InputValue.length > 0 ? false : true}
                      onClick={onClickSignificant}
                    >
                      등록
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
              {significant()}
            </SuiBox>
          </Card>
          <Card sx={{ mt: "16px", boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  계약 정보
                </SuiTypography>
                <Grid container alignItems="center">
                  <Grid item xs={3} sx={{ pr: 2 }}>
                    <SuiBox display="flex" sx={{ mt: 1 }}>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="info"
                        sx={{ pt: 2, pb: 1 }}
                      >
                        계약일자:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                      <Card
                        sx={{
                          width: "100%",
                          height: 57,
                          backgroundColor: "#F7F9FB",
                          boxShadow: 0,
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: 700,
                          pt: 0.8,
                        }}
                      >
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          sx={{
                            color: "#535353",
                          }}
                        >
                          <IconButton color="text2" sx={{ mb: 0, mx: 1 }}>
                            <BiCalendar />
                          </IconButton>
                          {FindingFirst(ContractDatas) ? (
                            <div style={{ margin: "0 auto" }}>
                              {dayjs(FindingFirst(ContractDatas)[1].value1).format("YYYY.MM.DD")}
                            </div>
                          ) : (
                            <div style={{ margin: "0 auto" }}>자동 입력 사용</div>
                          )}
                        </SuiBox>
                      </Card>
                    </SuiBox>
                  </Grid>
                  {ContractDatas.length > 0 ? (
                    <Grid item xs={2} sx={{ pr: 2 }}>
                      <SuiBox display="flex" sx={{ mt: 1 }}>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="info"
                          sx={{ pt: 2, pb: 1 }}
                        >
                          계약 정보:
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 0.8,
                            px: 2.5,
                          }}
                        >
                          <SuiBox
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: 40, color: "#535353" }}
                          >
                            {dayjs(FindingFirst(ContractDatas)[1].value2) > dayjs(new Date()) &&
                            FindingFirst(ContractDatas)[0].ContractType !== "계약 종료" ? (
                              dayjs(FindingFirst(ContractDatas)[1].value2).format("YYYYMMDD") ===
                              "29990101" ? (
                                ""
                              ) : (
                                <strong>
                                  {betweenDay(
                                    dayjs(new Date()).format("YYYYMMDD"),
                                    dayjs(FindingFirst(ContractDatas)[1].value2).format("YYYYMMDD")
                                  )}
                                </strong>
                              )
                            ) : (
                              <span style={{ minWidth: "68px" }}>계약 종료</span>
                            )}

                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                minWidth:
                                  dayjs(FindingFirst(ContractDatas)[1].value2) >
                                    dayjs(new Date()) &&
                                  FindingFirst(ContractDatas)[0].ContractType !== "계약 종료"
                                    ? "41px"
                                    : "8px",
                              }}
                            >
                              {dayjs(FindingFirst(ContractDatas)[1].value2) > dayjs(new Date()) &&
                              FindingFirst(ContractDatas)[0].ContractType !== "계약 종료" ? (
                                dayjs(FindingFirst(ContractDatas)[1].value2).format("YYYYMMDD") ===
                                "29990101" ? (
                                  <strong>무기한</strong>
                                ) : (
                                  "일 남음"
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {FindingFirst(ContractDatas)[3].value === "자동갱신" ? (
                              <IconButton
                                color={betweenDayColor(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(FindingFirst(ContractDatas)[1].value2).format("YYYYMMDD")
                                )}
                                sx={{ pr: 0 }}
                                className="cursorDefalut"
                              >
                                <BiRecycle />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </SuiBox>
                        </Card>
                      </SuiBox>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {ContractDatas.length > 0 ? (
                    <Grid item xs={5} sx={{ pr: 2 }}>
                      <SuiBox display="flex" sx={{ mt: 1 }}>
                        <SuiTypography
                          fontSize="14px"
                          fontWeight="bold"
                          color="info"
                          sx={{ pt: 2, pb: 1 }}
                        >
                          등록자:
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 0.8,
                            px: 2.5,
                          }}
                        >
                          <SuiBox
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: 40, color: "#535353" }}
                          >
                            {FindingFirst(ContractDatas)[0].user.department}
                            <Divider
                              orientation="vertical"
                              sx={{
                                borderColor: "#EEEEEE",
                                mx: FindingFirst(ContractDatas)[0].user.name.length > 3 ? 2 : 3,
                              }}
                            />
                            {FindingFirst(ContractDatas)[0].user.name}
                            {FindingFirst(ContractDatas)[0].user.position}
                          </SuiBox>
                        </Card>
                      </SuiBox>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={2}>
                    <SuiBox display="flex" sx={{ mt: 1 }}>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="info"
                        sx={{ pt: 2, pb: 1 }}
                      >
                        작업 수:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                      <Card
                        sx={{
                          width: "100%",
                          height: 57,
                          backgroundColor: "#F7F9FB",
                          boxShadow: 0,
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: 700,
                          pt: 0.8,
                          px: 2.5,
                        }}
                      >
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: 40, color: "#535353" }}
                        >
                          {MeetingDatas.length}건
                        </SuiBox>
                      </Card>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>

          <Card sx={{ mt: "16px", mb: 12, boxShadow: 0 }}>
            <SuiBox p={5} pr={3} display="flex" flexDirection="column" justifyContent="start">
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography mb="8px" fontSize="18px" fontWeight="bold" color="dark">
                  히스토리
                </SuiTypography>
                {DetailData.histroy ? (
                  <SuiBox className="history-container">
                    {typeData === "create" ? (
                      <SuiTypography fontSize="18px" fontWeight="medium" color="text2">
                        변경 내용 저장시 히스토리에 기록됩니다.
                      </SuiTypography>
                    ) : (
                      DetailData.histroy.map((row, index) => {
                        return index <= MaxHistoryList ? (
                          <Card
                            key={index}
                            sx={{
                              width: "100%",
                              height: 72,
                              backgroundColor: "#F7F9FB",
                              boxShadow: 0,
                              textAlign: "center",
                              fontSize: 18,
                              fontWeight: 700,
                              p: 2,
                              my: 2,
                            }}
                          >
                            <SuiBox display="flex" alignItems="center" justifyContent="center">
                              <Avatar
                                sx={{
                                  width: 40,
                                  height: 40,
                                }}
                                style={{ backgroundColor: "transparent" }}
                                alt="History"
                                src={
                                  row.profile ? process.env.REACT_APP_BACKEND_URL + row.profile : ""
                                }
                              />
                              <SuiTypography
                                ml="14px"
                                fontSize="16px"
                                fontWeight="medium"
                                sx={{ color: "#0C65FF" }}
                              >
                                {row.department} {row.name} {row.position}
                              </SuiTypography>
                              <SuiTypography
                                ml="16px"
                                fontSize="16px"
                                fontWeight="medium"
                                color="text2"
                                sx={{ width: "60%", textAlign: "left" }}
                              >
                                {row.type && row.type.indexOf("contract") > -1 ? (
                                  historyContent(row)
                                ) : (
                                  <span dangerouslySetInnerHTML={{ __html: row.content }}></span>
                                )}
                              </SuiTypography>
                              <SuiTypography
                                mr="4px"
                                ml="auto"
                                fontSize="16px"
                                fontWeight="medium"
                                color="text2"
                              >
                                {row.date}
                              </SuiTypography>
                            </SuiBox>
                          </Card>
                        ) : (
                          ""
                        )
                      })
                    )}
                    {MaxHistoryList < DetailData.histroy.length - 1 ? (
                      <SuiBox
                        sx={{ pl: 3, mt: "40px" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SuiButton
                          color="info2"
                          variant="text"
                          sx={{
                            width: 100,
                            border: 1,
                            borderColor: "#0C65FF",
                            height: 40,
                            mr: 2,
                            fontSize: 14,
                          }}
                          onClick={() => setMaxHistoryList(MaxHistoryList + 5)}
                        >
                          더보기
                        </SuiButton>
                      </SuiBox>
                    ) : (
                      ""
                    )}
                  </SuiBox>
                ) : (
                  ""
                )}
              </SuiBox>
            </SuiBox>
            {openContractInfoDialog ? (
              <ContractInfoDialog
                openDialog={openContractInfoDialog}
                closeDialog={closeDialogInfo}
                infoData={InfoData}
              />
            ) : (
              ""
            )}
            {openSubContractInfoDialog ? (
              <SubContractInfoDialog
                openDialog={openSubContractInfoDialog}
                closeDialog={closeDialogInfo}
                infoData={SubInfoData}
              />
            ) : (
              ""
            )}
            <SuiDialog
              openDialog={openNoContract_dialog}
              closeDialog={() => setopenNoContract_dialog(false)}
              MainTitle={"삭제된 계약 알림"}
              Content={"담당자의 요청에 의해 이미 삭제된 계약 건입니다."}
              CanBtnText={"확인"}
            />
          </Card>
          <ContractMangerInfoDialog
            openDialog={openMangerDialog}
            closeDialog={closeDialog}
            ContarctData={MangerContractData()}
          />
        </div>
      )
    } else if (value === 1) {
      return (
        <div>
          <ReferrerCheck
            openDialog={ReferrerCheckDialog}
            closeDialog={() => setReferrerCheckDialog(false)}
            ContarctData={ReferrerCheckData}
          ></ReferrerCheck>
          <ReferrerDialog
            openDialog={referrerDialog}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            referrerIds={setreferrerIds}
            referrerIdsUpdate={referrerIds}
            department_list={settings_ref.current}
          />
          <ContractChangeInfoDialog
            openDialog={ContractChangeDialogInfoOpenBool}
            closeDialog={closeDialog}
            ContarctData={ContarctChangeInfoData}
          />
          <ContractSubDialog
            openDialog={ContractSubDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            edit={ContractSubDialogEdit}
            editData={ContractSubDialogEditData}
            ContarctData={ContarctSubData}
            ContarctSubParentData={ContarctSubParentData}
            subData={setContarctSubDataApply}
          />
          <ContractChangeDialog
            openDialog={ContractChangeDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            ContarctData={ContarctChangeData}
          />
          <ContractRenewalDialog
            openDialog={ContractRenewalDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            edit={ContractRenewalDialogEdit}
            editData={ContractRenewalDialogEditData}
            ContarctData={ContarctRenewalData}
            type={ContarctRenewalType}
            renewalData={setrenewalData}
            renewalEndData={setrenewalEndData}
            ContarctDatas={ContractDatas[contract_documents_data_index]}
          />
          <ContractDialog
            openDialog={ContractDialogOpenBool}
            closeDialog={closeDialog}
            closeDialog2={closeDialog2}
            contract_documents_data={setcontract_documents_data}
            contract_documents_data_update={setcontract_documents_data_update}
            edit={ContractDialogEdit}
            editData={ContractDialogEditData}
            type={ContractType}
            contract_documents={ContractDatas}
          />

          <ContractDeleteDialog
            openDialog={openContract_deleteDialog}
            closeDialog={closeDialog}
            ContractDeleteData={Contract_deleteData}
            // deleteContractData={delete_ContractData}
            DetailData={DetailData}
            // deleteRequest={deleteRequest}
            setcreateContractShow={setcreateContractShow}
          />
          <DeleteDenyDialog
            openDialog={open_DenyReasonDialog}
            closeDialog={close_denyReason}
            denyReason={denyReason}
          />

          {typeData === "create" || typeData === "update" ? (
            <SuiButton
              sx={{
                minHeight: 67,
                fontSize: 18,
                fontWeight: 700,
                boxShadow: 3,
                mb: "20px",
                mt: "20px",
              }}
              className="outlined-bg-white"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => addContract()}
            >
              계약 정보 추가하기
            </SuiButton>
          ) : (
            ""
          )}
          <SuiBox display="flex" justifyContent="end" sx={{ mb: "16px" }}>
            <SuiBox display="flex" alignItems="center" sx={{ ml: "auto", mr: "20px" }}>
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                onClick={() => setFilesSortingHistory("좁게보기")}
                className={FilesSortingHistory !== "넓게보기" ? "image-icon active" : "image-icon"}
              >
                <FaTh />
              </IconButton>
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                onClick={() => setFilesSortingHistory("넓게보기")}
                className={FilesSortingHistory === "넓게보기" ? "image-icon active" : "image-icon"}
              >
                <FaThLarge />
              </IconButton>
            </SuiBox>
            <SuiBox sx={{ width: 100 }}>
              <FormControl
                fullWidth
                sx={{ minHeight: 30 }}
                className={
                  FilesSortingContract === ""
                    ? "setting-selectbox select-none-active"
                    : "setting-selectbox"
                }
                onClick={() => setFiltersOpen4(!FiltersOpen4)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen4(!FiltersOpen4)}
                    />
                  )}
                  open={FiltersOpen4}
                  value={FilesSortingContract}
                  onChange={(e) => setFilesSortingContract(e.target.value)}
                  className="setting-border-radius"
                >
                  <MenuItem value="최신순">최신순</MenuItem>
                  <MenuItem value="오래된 순">오래된 순</MenuItem>
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
          {ContractDatas.length > 0 ? (
            ""
          ) : (
            <SuiBox sx={{ mt: 2 }}>
              <SuiTypography
                fontSize={"18px"}
                fontWeight="medium"
                color="text2"
                sx={{ textAlign: "left" }}
              >
                등록된 계약 정보가 없습니다.
              </SuiTypography>
            </SuiBox>
          )}
          <Grid container alignItems="start" sx={{ mb: 12 }}>
            {ContractDatas.map((row, ContractIndex) => {
              return (
                <Grid
                  item
                  xs={FilesSortingHistory !== "넓게보기" ? 3 : 6}
                  sx={{
                    pr: FilesSortingHistory !== "넓게보기" ? 2 : ContractIndex % 2 === 0 ? 2 : 0,
                    mb: 2,
                  }}
                  key={ContractIndex}
                >
                  <Card
                    sx={{
                      boxShadow: "0px 3px 6px transparent",
                      border: "1px solid rgba(0, 0, 0, 0.16)",
                      py: 2,
                      backgroundColor: dayjs(row[0][1].value2) < dayjs(new Date()) ? "#F7F9FB" : "",
                    }}
                  >
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        px: 2,
                      }}
                    >
                      <SuiTypography
                        fontSize="16px"
                        fontWeight="medium"
                        color="dark"
                        sx={{
                          py:
                            row[0][3].value === "자동갱신"
                              ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                row[0][0].ContractType !== "계약 종료"
                                ? ""
                                : "6px"
                              : "6px",
                        }}
                      >
                        {row[0][0].selectData[0].value}
                      </SuiTypography>
                      <SuiTypography
                        fontSize="16px"
                        fontWeight="medium"
                        sx={{
                          py:
                            row[0][3].value === "자동갱신"
                              ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                row[0][0].ContractType !== "계약 종료"
                                ? ""
                                : "6px"
                              : "6px",
                          display: showDeleteRequest(row) ? "" : "none",
                          ml: 2,
                          textDecoration: "underline",
                          color: showDeleteRequest(row) === "삭제신청" ? "#FF4D60" : "#9AA0A6",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          showDeleteRequest(row) === "삭제신청"
                            ? open_cancle_alert("delete", row)
                            : openDeleteDialog(row)
                        }
                      >
                        {showDeleteRequest(row)}
                      </SuiTypography>
                      <SuiTypography
                        fontSize="16px"
                        fontWeight="medium"
                        sx={{
                          py:
                            row[0][3].value === "자동갱신"
                              ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                row[0][0].ContractType !== "계약 종료"
                                ? ""
                                : "6px"
                              : "6px",
                          display: showChangeRequest(row) ? "" : "none",
                          ml: 2,
                          textDecoration: "underline",
                          color: "#FF4D60",
                          cursor: "pointer",
                        }}
                        onClick={() => open_cancle_alert("change", row)}
                      >
                        이관신청
                      </SuiTypography>
                      <SuiBox
                        sx={{
                          ml: "auto",
                          py:
                            row[0][3].value === "자동갱신"
                              ? dayjs(row[0][1].value2) > dayjs(new Date()) &&
                                row[0][0].ContractType !== "계약 종료"
                                ? ""
                                : "6px"
                              : "6px",
                        }}
                      >
                        {FilesSortingHistory !== "넓게보기" ? (
                          ""
                        ) : (
                          <SuiTypography fontSize="16px" fontWeight="medium" color="dark">
                            {dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                              <strong>
                                {betweenDay(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(row[0][1].value2).format("YYYYMMDD")
                                )}
                              </strong>
                            ) : (
                              "계약 종료"
                            )}
                            <span style={{ fontSize: 14, fontWeight: 400 }}>
                              {dayjs(row[0][1].value2) > dayjs(new Date()) &&
                              dayjs(row[0][1].value2).format("YYYY.MM.DD") !== "2999.01.01"
                                ? "일 남음"
                                : ""}
                            </span>
                          </SuiTypography>
                        )}
                      </SuiBox>
                      {row[0][3].value === "자동갱신" && row[0][0].ContractType !== "계약 종료" ? (
                        <IconButton
                          color={betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(row[0][1].value2).format("YYYYMMDD")
                          )}
                          sx={{ display: "", pr: 0 }}
                          className="cursorDefalut"
                        >
                          <BiRecycle />
                        </IconButton>
                      ) : row[0][3].value === "-" &&
                        row[0][0].ContractType === "계약 종료" &&
                        dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                        <IconButton
                          color={betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(row[0][1].value2).format("YYYYMMDD")
                          )}
                          className="cursorDefalut"
                          sx={{ pr: 0 }}
                        >
                          <BsFillCircleFill size={10} />
                        </IconButton>
                      ) : row[0][3].value === "해지시 종료" &&
                        dayjs(row[0][1].value2) > dayjs(new Date()) ? (
                        <IconButton
                          color={betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(row[0][1].value2).format("YYYYMMDD")
                          )}
                          className="cursorDefalut"
                          sx={{ pr: 0 }}
                        >
                          <BsFillCircleFill size={10} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      {row[0][3].value !== "자동갱신" &&
                      dayjs(row[0][1].value2) < dayjs(new Date()) ? (
                        <IconButton
                          sx={{
                            color: "#b7b7b7",
                          }}
                          className="cursorDefalut"
                        >
                          <BsFillCircleFill size={10} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      {typeData === "update" ? (
                        !showDeleteRequest(row) &&
                        !showChangeRequest(row) &&
                        (authCheckContract(row) || myData.role.name === "Authenticated") ? (
                          <IconButton
                            aria-label="more"
                            id={`long-button`}
                            aria-controls="long-menu"
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            color="dark"
                            sx={{
                              color: !row[0][0].id ? "#CDD2D3" : "",
                              pr: 0,
                              // display: row[0][0].ContractType === "계약 종료" ? "none" : "",
                              cursor: !row[0][0].id ? "default" : "",
                            }}
                            onClick={(e) =>
                              !row[0][0].id ? "" : handleClick(e, ContractIndex, row)
                            }
                          >
                            <FiMoreHorizontal />
                          </IconButton>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <Menu
                        sx={{
                          display:
                            ContractIndex === anchorElIndex &&
                            row[0][1].created_at === AnchorElRowData[0][1].created_at
                              ? // &&
                                // row[0][0].ContractType !== "계약 종료"
                                ""
                              : "none",
                        }}
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                          },
                        }}
                      >
                        {row[row[0][0].activeStep][0].id && authCheckContract(row, "no") ? (
                          <MenuItem
                            onClick={(e) =>
                              ContractOption(
                                row[row[0][0].activeStep],
                                e,
                                "update",
                                ContractIndex,
                                row[0][0].activeStep,
                                row
                              )
                            }
                          >
                            계약이관
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {row[row[0][0].activeStep][0].id ? (
                          <MenuItem
                            onClick={(e) =>
                              ContractOption(
                                row[row[0][0].activeStep],
                                e,
                                "update",
                                ContractIndex,
                                row[0][0].activeStep
                              )
                            }
                            sx={{
                              backgroundColor: "#ffffff !important",
                              opacity: row[0][0].ContractType === "계약 종료" ? "1 !important" : "",
                              color:
                                row[0][0].ContractType === "계약 종료" ? "#CED4D7 !important" : "",
                            }}
                            disabled={row[0][0].ContractType === "계약 종료" ? true : false}
                          >
                            갱신계약
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {row[row[0][0].activeStep][0].id ? (
                          <MenuItem
                            onClick={(e) =>
                              ContractOption(
                                row[row[0][0].activeStep],
                                e,
                                "update",
                                ContractIndex,
                                row[0][0].activeStep
                              )
                            }
                            sx={{
                              backgroundColor: "#ffffff !important",
                              opacity: row[0][0].ContractType === "계약 종료" ? "1 !important" : "",
                              color:
                                row[0][0].ContractType === "계약 종료" ? "#CED4D7 !important" : "",
                            }}
                            disabled={row[0][0].ContractType === "계약 종료" ? true : false}
                          >
                            종료통고
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {/* <MenuItem
                          onClick={(e) =>
                            ContractOption(
                              row[row[0][0].activeStep],
                              e,
                              "update",
                              ContractIndex,
                              row[0][0].activeStep
                            )
                          }
                        >
                          수정하기
                        </MenuItem> */}
                        {authCheckContract(row, "my") ? (
                          <MenuItem
                            disabled={showDeleteRequest(row) ? true : false}
                            onClick={(e) =>
                              ContractOption(
                                row[row[0][0].activeStep],
                                e,
                                "update",
                                ContractIndex,
                                row[0][0].activeStep,
                                row
                              )
                            }
                          >
                            삭제신청
                          </MenuItem>
                        ) : (
                          ""
                        )}
                      </Menu>
                    </SuiBox>
                    <Card sx={{ boxShadow: 0, mx: 2, p: 2, backgroundColor: "#F7F9FB" }}>
                      <SwipeableViews
                        axis="x"
                        index={row[row[0][0].activeStep][0].activeStep}
                        onChangeIndex={(e) => handleStepChange(e, ContractIndex)}
                        enableMouseEvents
                      >
                        {row.map((rowData, rowIndex) => {
                          const fileName = rowData[0].file?.name
                          const fileType = fileName.split(".")[fileName.split(".").length - 1]
                          return (
                            <SuiBox key={rowIndex}>
                              <SuiBox display="flex" alignItems="center">
                                <SuiTypography
                                  fontSize="14px"
                                  fontWeight="medium"
                                  color="dark"
                                  sx={{ textAlign: "left", mb: 1 }}
                                >
                                  {rowData[0].ContractType
                                    ? rowData[0].ContractType === "계약 종료"
                                      ? rowData[0].ContractType + " 통고서"
                                      : rowData[0].ContractType + "_" + (row.length - rowIndex - 1)
                                    : "최초 계약"}
                                </SuiTypography>
                                {typeData === "update" && rowIndex === 0 ? (
                                  authCheckContract(row) ? (
                                    <SuiTypography
                                      fontSize="14px"
                                      fontWeight="medium"
                                      color="info2"
                                      sx={{ ml: "auto", mb: 1, mr: 1, cursor: "pointer" }}
                                      onClick={(e) =>
                                        ContractOption(
                                          row[row[0][0].activeStep],
                                          e,
                                          "update",
                                          ContractIndex,
                                          row[0][0].activeStep
                                        )
                                      }
                                    >
                                      수정
                                    </SuiTypography>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </SuiBox>
                              <table className="contract-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        계약일
                                      </SuiTypography>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        계약 종료일
                                      </SuiTypography>
                                    </td>
                                    {FilesSortingHistory !== "넓게보기" ? (
                                      ""
                                    ) : (
                                      <td>
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color="text2"
                                        >
                                          갱신 / 해지
                                        </SuiTypography>
                                      </td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        {dayjs(rowData[1].value1).format("YYYY.MM.DD")}
                                      </SuiTypography>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        {dayjs(rowData[1].value2).format("YYYY.MM.DD")}
                                      </SuiTypography>
                                    </td>
                                    {FilesSortingHistory !== "넓게보기" ? (
                                      ""
                                    ) : (
                                      <td>
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color={
                                            rowData[3].value === "자동갱신" ? "success" : "error"
                                          }
                                        >
                                          {rowData[0].ContractType === "계약 종료"
                                            ? "-"
                                            : rowData[3].value}
                                        </SuiTypography>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              {FilesSortingHistory !== "넓게보기" ? (
                                <SuiTypography
                                  fontSize="13px"
                                  fontWeight="medium"
                                  color="info2"
                                  sx={{ textAlign: "left", mt: 2 }}
                                >
                                  계약 담당: <br />
                                  {row[0][0].user
                                    ? row[0][0].user.department
                                    : myData.department}{" "}
                                  {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                  {row[0][0].user ? row[0][0].user.position : myData.position}
                                </SuiTypography>
                              ) : (
                                <SuiTypography
                                  fontSize="13px"
                                  fontWeight="medium"
                                  color="info2"
                                  sx={{ textAlign: "left", mt: 2 }}
                                >
                                  계약 담당:{" "}
                                  {row[0][0].user ? row[0][0].user.department : myData.department} /{" "}
                                  {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                  {row[0][0].user ? row[0][0].user.position : myData.position}
                                  <SuiButton
                                    color="info"
                                    sx={{
                                      minHeight: 20,
                                      height: 20,
                                      ml: 2,
                                      px: 0,
                                      boxShadow: 0,
                                    }}
                                    onClick={() => chagneInfo(rowData)}
                                  >
                                    변경이력
                                  </SuiButton>
                                </SuiTypography>
                              )}
                              <Divider sx={{ my: 2 }} />
                              {FilesSortingHistory !== "넓게보기" ? (
                                <SuiBox display="flex" alignItems="center">
                                  <SuiTypography
                                    fontSize="13px"
                                    fontWeight="medium"
                                    color="dark"
                                    sx={{ textAlign: "left" }}
                                  >
                                    계약서 원본
                                  </SuiTypography>
                                  <SuiButton
                                    sx={{
                                      backgroundColor: "#EEEEEE",
                                      minHeight: 24,
                                      height: 24,
                                      width: 24,
                                      minWidth: 24,
                                      p: 0,
                                      ml: 1,
                                      boxShadow: 0,
                                      display: rowData[0].file.url ? "" : "none",
                                    }}
                                    onClick={() => showPdf(rowData[0].file)}
                                  >
                                    <FiSearch size="15" />
                                  </SuiButton>
                                  {typeData === "update" && authCheckContract(row) ? (
                                    <SuiBox display="flex" alignItems="center" sx={{ mx: "16px" }}>
                                      <SuiButton
                                        className="referrerIcon"
                                        sx={{
                                          backgroundColor: "#0C65FF",
                                          minHeight: 32,
                                          height: 32,
                                          width: 32,
                                          minWidth: 32,
                                          p: 0,
                                          boxShadow: 0,
                                        }}
                                        onClick={() => {
                                          setreferrerDialog(true)
                                          setselectContarct(row)
                                          if (row[0][0].referrer) {
                                            setreferrerIds(row[0][0].referrer)
                                          }
                                        }}
                                      >
                                        <CgUserAdd size="20" color="white" />
                                      </SuiButton>
                                    </SuiBox>
                                  ) : FilesSortingHistory === "넓게보기" ? (
                                    <SuiButton
                                      className="referrerIcon"
                                      sx={{
                                        mx: "16px",
                                        backgroundColor: "#0C65FF",
                                        minHeight: 32,
                                        height: 32,
                                        width: 100,
                                        p: 0,
                                        boxShadow: 0,
                                        color: "white !important",
                                        display: row[0][0].referrer
                                          ? row[0][0].referrer.length === 0
                                            ? "none"
                                            : "block"
                                          : "none",
                                      }}
                                      onClick={() => {
                                        setReferrerCheckDialog(true)
                                        if (row[0][0].referrer)
                                          setReferrerCheckData(row[0][0].referrer)
                                        else setReferrerCheckData([])
                                      }}
                                    >
                                      참조자 확인
                                    </SuiButton>
                                  ) : (
                                    ""
                                  )}
                                </SuiBox>
                              ) : (
                                <SuiBox>
                                  <SuiTypography
                                    fontSize="13px"
                                    fontWeight="medium"
                                    color="dark"
                                    sx={{ textAlign: "left" }}
                                  >
                                    계약서 원본
                                  </SuiTypography>
                                  <SuiBox
                                    display="flex"
                                    alignItems="center"
                                    sx={{ mt: 1, overflowX: "hidden" }}
                                  >
                                    <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                                      {GetFileIcon(fileType)}
                                    </IconButton>
                                    <SuiTypography
                                      fontSize="14px"
                                      fontWeight="medium"
                                      color="dark"
                                      sx={{
                                        mr: "4px",
                                        textAlign: "left",
                                        cursor: rowData[0].file.url ? "pointer" : "default",
                                      }}
                                    >
                                      {rowData[0].file ? fileText(rowData[0].file.name) : ""}
                                    </SuiTypography>
                                    {downloadCheck(rowData) ? (
                                      <SuiButton
                                        sx={{
                                          backgroundColor: "#EEEEEE",
                                          minHeight: 24,
                                          height: 24,
                                          width: 24,
                                          minWidth: 24,
                                          p: 0,
                                          ml: 1,
                                          boxShadow: 0,
                                          display: rowData[0].file.url ? "" : "none",
                                        }}
                                        onClick={() => showPdf(rowData[0].file)}
                                      >
                                        <FiSearch size="15" />
                                      </SuiButton>
                                    ) : (
                                      ""
                                    )}

                                    {/* {downloadCheck(rowData) ? (
                                      <SuiButton
                                        color="info"
                                        sx={{
                                          minHeight: 20,
                                          height: 20,
                                          ml: 1,
                                          pt: 1.8,
                                          px: 0,
                                          boxShadow: 0,
                                          display: rowData[0].file.url ? "" : "none",
                                        }}
                                        onClick={() => DownloadPdf(rowData[0].file)}
                                      >
                                        다운로드
                                      </SuiButton>
                                    ) : (
                                      ""
                                    )} */}
                                  </SuiBox>
                                </SuiBox>
                              )}
                            </SuiBox>
                          )
                        })}
                      </SwipeableViews>
                    </Card>
                    <MobileStepper
                      steps={row.length}
                      position="static"
                      activeStep={row[0][0].activeStep}
                      sx={{
                        background: "transparent",
                        m: "0 auto",
                      }}
                    />
                    {typeData === "update" &&
                    FilesSortingHistory === "넓게보기" &&
                    authCheckContract(row) ? (
                      <SuiBox display="flex" alignItems="center" sx={{ mx: "16px" }}>
                        <SuiButton
                          className="referrerIcon"
                          sx={{
                            backgroundColor: "#0C65FF",
                            minHeight: 32,
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            p: 0,
                            boxShadow: 0,
                          }}
                          onClick={() => {
                            setreferrerDialog(true)
                            setselectContarct(row)
                            if (row[0][0].referrer) {
                              setreferrerIds(row[0][0].referrer)
                            }
                          }}
                        >
                          <CgUserAdd size="20" color="white" />
                        </SuiButton>
                        <Stack direction="row" spacing={1} sx={{ ml: 2, overflow: "auto" }}>
                          {row[0][0].referrer
                            ? row[0][0].referrer.map((x, index) => {
                                return (
                                  <Chip
                                    key={index}
                                    label={x.name}
                                    onDelete={(e) => handleDelete(x, row)}
                                    deleteIcon={<AiFillCloseCircle color="#535353" />}
                                  />
                                )
                              })
                            : ""}
                        </Stack>
                      </SuiBox>
                    ) : FilesSortingHistory === "넓게보기" ? (
                      <SuiButton
                        className="referrerIcon"
                        sx={{
                          mx: "16px",
                          backgroundColor: "#0C65FF",
                          minHeight: 32,
                          height: 32,
                          width: 100,
                          p: 0,
                          boxShadow: 0,
                          color: "white !important",
                          display: row[0][0].referrer
                            ? row[0][0].referrer.length === 0
                              ? "none"
                              : "block"
                            : "none",
                        }}
                        onClick={() => {
                          setReferrerCheckDialog(true)
                          if (row[0][0].referrer) setReferrerCheckData(row[0][0].referrer)
                          else setReferrerCheckData([])
                        }}
                      >
                        참조자 확인
                      </SuiButton>
                    ) : (
                      ""
                    )}
                    <Divider sx={{ my: 2 }} />
                    <SuiBox display="flex" alignItems="center">
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="medium"
                        color={row[0][0].dropdown1 ? "primary" : "info"}
                        sx={{ textAlign: "left", mx: 3 }}
                        className="cursor"
                        onClick={() =>
                          updateContarctDropDown(0, row[0][0].dropdown1, ContractIndex)
                        }
                      >
                        부속계약서 ({row[0][0].subContract.length})
                      </SuiTypography>
                      <IconButton
                        color={row[0][0].dropdown1 ? "primary" : "info"}
                        sx={{ ml: "auto" }}
                        onClick={() =>
                          updateContarctDropDown(0, row[0][0].dropdown1, ContractIndex)
                        }
                      >
                        {row[0][0].dropdown1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </SuiBox>
                    <Divider
                      sx={{
                        mb: row[0][0].dropdown1 ? 0 : 2,
                        mt: 2,
                      }}
                    />
                    <SuiBox
                      sx={{
                        display: row[0][0].dropdown1 ? "" : "none",
                        p: 2,
                        backgroundColor: "#F1F1F5",
                        borderTop: 1,
                        borderBottom: 1,
                        borderColor: "#CED4D7",
                        minHeight: 160,
                      }}
                    >
                      <SuiBox display="flex">
                        {typeData === "update" && row[0][0].id && authCheckContract(row) ? (
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              mr: "auto",
                            }}
                            color="primary"
                            onClick={() =>
                              addContractSubDialogBtn(row[0], ContractIndex, index, row)
                            }
                          >
                            <AiOutlinePlus size="20" />
                          </SuiButton>
                        ) : (
                          ""
                        )}
                        <SuiBox sx={{ width: 100, ml: "auto" }}>
                          <FormControl
                            fullWidth
                            sx={{ minHeight: 30 }}
                            className={
                              row[0][0].subContractSotring === ""
                                ? "setting-selectbox select-none-active"
                                : "setting-selectbox"
                            }
                            onClick={() => filterOpenClick(row, "subContract")}
                          >
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  fontSize="small"
                                  color="black"
                                  className="select-icon cursor"
                                  onClick={() => filterOpenClick(row, "subContract")}
                                />
                              )}
                              open={FiltersOpen5 && FiltersOpenIndex === row[0][0].id}
                              value={row[0][0].subContractSotring}
                              onChange={(e) =>
                                SubContractSortingActive(e.target.value, ContractIndex)
                              }
                              className="setting-border-radius"
                            >
                              <MenuItem value="최신순">최신순</MenuItem>
                              <MenuItem value="오래된 순">오래된 순</MenuItem>
                            </Select>
                          </FormControl>
                        </SuiBox>
                      </SuiBox>
                      {row[0][0].subContract.length === 0 ? (
                        <SuiBox sx={{ mt: 2 }}>
                          <SuiTypography
                            fontSize={FilesSortingHistory !== "넓게보기" ? "14px" : "18px"}
                            fontWeight="medium"
                            color="text2"
                            sx={{ textAlign: "left" }}
                          >
                            등록된 부속계약서가 없습니다.
                          </SuiTypography>
                        </SuiBox>
                      ) : (
                        row[0][0].subContract.map((rowData, rowIndex) => {
                          const fileName = rowData[0].file?.name
                          const fileType = fileName.split(".")[fileName.split(".").length - 1]
                          return (
                            <SuiBox
                              key={rowIndex}
                              sx={{
                                backgroundColor: "#ffffff",
                                mt: 1,
                                p: 2,
                                borderRadius: "1rem",
                              }}
                            >
                              <SuiBox display="flex" alignItems="center">
                                <SuiTypography
                                  fontSize="14px"
                                  fontWeight="medium"
                                  color="dark"
                                  sx={{ textAlign: "left", mb: 1 }}
                                >
                                  {rowData[0].ContractType ? rowData[0].ContractType : "부속 계약"}
                                </SuiTypography>

                                {dayjs(row[0][1].value2) < dayjs(new Date()) ||
                                row[0][0].ContractType === "계약 종료" ? (
                                  <SuiTypography
                                    fontSize="14px"
                                    fontWeight="medium"
                                    color="text2"
                                    sx={{ ml: "auto", mb: 1, mr: 1, cursor: "pointer" }}
                                  >
                                    계약종료
                                  </SuiTypography>
                                ) : typeData === "update" && authCheckContract(row) ? (
                                  rowCheckContract(row, rowData) ? (
                                    <SuiTypography
                                      fontSize="14px"
                                      fontWeight="medium"
                                      color="text2"
                                      sx={{ ml: "auto", mb: 1, mr: 1 }}
                                    >
                                      계약종료
                                    </SuiTypography>
                                  ) : (
                                    <SuiTypography
                                      fontSize="14px"
                                      fontWeight="medium"
                                      color="info2"
                                      sx={{ ml: "auto", mb: 1, mr: 1, cursor: "pointer" }}
                                      onClick={() =>
                                        editSubContract(rowData, row[0], rowIndex, row)
                                      }
                                    >
                                      수정
                                    </SuiTypography>
                                  )
                                ) : (
                                  ""
                                )}
                              </SuiBox>
                              <table className="contract-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        계약일
                                      </SuiTypography>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        계약 종료일
                                      </SuiTypography>
                                    </td>
                                    {FilesSortingHistory !== "넓게보기" ? (
                                      ""
                                    ) : (
                                      <td>
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color="text2"
                                        >
                                          갱신 / 해지
                                        </SuiTypography>
                                      </td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        {dayjs(rowData[1].value1).format("YYYY.MM.DD")}
                                      </SuiTypography>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        fontSize="13px"
                                        fontWeight="medium"
                                        color="text2"
                                      >
                                        {dayjs(rowData[1].value2).format("YYYY.MM.DD")}
                                      </SuiTypography>
                                    </td>
                                    {FilesSortingHistory !== "넓게보기" ? (
                                      ""
                                    ) : (
                                      <td>
                                        <SuiTypography
                                          fontSize="13px"
                                          fontWeight="medium"
                                          color={
                                            rowData[3].value === "자동갱신" ? "success" : "error"
                                          }
                                        >
                                          {rowData[3].value}
                                        </SuiTypography>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              {FilesSortingHistory !== "넓게보기" ? (
                                ""
                              ) : (
                                <SuiTypography
                                  fontSize="13px"
                                  fontWeight="medium"
                                  color="info2"
                                  sx={{ textAlign: "left", mt: 2 }}
                                >
                                  계약 담당:{" "}
                                  {row[0][0].user ? row[0][0].user.department : myData.department} /{" "}
                                  {row[0][0].user ? row[0][0].user.name : myData.name}{" "}
                                  {row[0][0].user ? row[0][0].user.position : myData.position}
                                  {/* <SuiButton
                                        color="info"
                                        sx={{
                                          minHeight: 20,
                                          height: 20,
                                          ml: 2,
                                          px: 0,
                                        }}
                                      >
                                        변경이력
                                      </SuiButton> */}
                                </SuiTypography>
                              )}
                              {FilesSortingHistory !== "넓게보기" ? "" : <Divider sx={{ my: 2 }} />}
                              {FilesSortingHistory !== "넓게보기" ? (
                                <SuiBox display="flex" alignItems="center" sx={{ mt: 1 }}>
                                  <SuiTypography
                                    fontSize="13px"
                                    fontWeight="medium"
                                    color="dark"
                                    sx={{ textAlign: "left" }}
                                  >
                                    계약서 원본
                                  </SuiTypography>
                                  <SuiButton
                                    sx={{
                                      backgroundColor: "#EEEEEE",
                                      minHeight: 24,
                                      height: 24,
                                      width: 24,
                                      minWidth: 24,
                                      p: 0,
                                      ml: 1,
                                      boxShadow: 0,
                                      display: rowData[0].file.url ? "" : "none",
                                    }}
                                    onClick={() => showPdf(rowData[0].file)}
                                  >
                                    <FiSearch size="15" />
                                  </SuiButton>
                                </SuiBox>
                              ) : (
                                <SuiBox>
                                  <SuiTypography
                                    fontSize="13px"
                                    fontWeight="medium"
                                    color="dark"
                                    sx={{ textAlign: "left" }}
                                  >
                                    계약서 원본
                                  </SuiTypography>
                                  <SuiBox
                                    display="flex"
                                    alignItems="center"
                                    sx={{ mt: 1, overflowX: "hidden" }}
                                  >
                                    <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
                                      {GetFileIcon(fileType)}
                                    </IconButton>
                                    <SuiTypography
                                      fontSize="14px"
                                      fontWeight="medium"
                                      color="dark"
                                      sx={{
                                        mr: "4px",
                                        textAlign: "left",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {rowData[0].file ? fileText(rowData[0].file.name) : ""}
                                    </SuiTypography>
                                    {downloadCheck(rowData) ? (
                                      <SuiButton
                                        sx={{
                                          backgroundColor: "#EEEEEE",
                                          minHeight: 24,
                                          height: 24,
                                          width: 24,
                                          minWidth: 24,
                                          p: 0,
                                          ml: 1,
                                          boxShadow: 0,
                                          display: rowData[0].file.url ? "" : "none",
                                        }}
                                        onClick={() => showPdf(rowData[0].file)}
                                      >
                                        <FiSearch size="15" />
                                      </SuiButton>
                                    ) : (
                                      ""
                                    )}

                                    {/* {downloadCheck(rowData) ? (
                                      <SuiButton
                                        color="info"
                                        sx={{
                                          minHeight: 20,
                                          height: 20,
                                          ml: 1,
                                          pt: 1.8,
                                          px: 0,
                                          boxShadow: 0,
                                          display: rowData[0].file.url ? "" : "none",
                                        }}
                                        onClick={() => DownloadPdf(rowData[0].file)}
                                      >
                                        다운로드
                                      </SuiButton>
                                    ) : (
                                      ""
                                    )} */}
                                  </SuiBox>
                                </SuiBox>
                              )}
                            </SuiBox>
                          )
                        })
                      )}
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        pt: row[0][0].dropdown1 ? 2 : 0,
                        pb: row[0][0].dropdown2 ? 2 : 0,
                      }}
                    >
                      <SuiTypography
                        fontSize="18px"
                        fontWeight="medium"
                        color={row[0][0].dropdown2 ? "primary" : "info"}
                        sx={{ textAlign: "left", mx: 3 }}
                        className="cursor"
                        onClick={() =>
                          updateContarctDropDown(1, row[0][0].dropdown2, ContractIndex)
                        }
                      >
                        메모 ({row[0][0].memo.length})
                      </SuiTypography>
                      <IconButton
                        color={row[0][0].dropdown2 ? "primary" : "info"}
                        sx={{ ml: "auto" }}
                        onClick={() =>
                          updateContarctDropDown(1, row[0][0].dropdown2, ContractIndex)
                        }
                      >
                        {row[0][0].dropdown2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </SuiBox>
                    <SuiBox
                      sx={{
                        display: row[0][0].dropdown2 ? "" : "none",
                        p: 2,
                        backgroundColor: "#F1F1F5",
                        borderTop: 1,
                        borderBottom: 1,
                        borderColor: "#CED4D7",
                        minHeight: 160,
                      }}
                    >
                      <SuiBox display="flex">
                        {typeData === "update" && row[0][0].id && authCheckContract(row) ? (
                          <SuiButton
                            sx={{
                              minHeight: 32,
                              height: 32,
                              width: 32,
                              minWidth: 32,
                              p: 1,
                              mr: "auto",
                            }}
                            color="primary"
                            onClick={() => addMemo(ContractIndex)}
                          >
                            <AiOutlinePlus size="20" />
                          </SuiButton>
                        ) : (
                          ""
                        )}
                        <SuiBox sx={{ width: 100, ml: "auto" }}>
                          <FormControl
                            fullWidth
                            sx={{ minHeight: 30 }}
                            className={
                              row[0][0].memoSotring === ""
                                ? "setting-selectbox select-none-active"
                                : "setting-selectbox"
                            }
                            onClick={() => filterOpenClick(row, "memo")}
                          >
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  fontSize="small"
                                  color="black"
                                  className="select-icon cursor"
                                  onClick={() => filterOpenClick(row, "memo")}
                                />
                              )}
                              open={FiltersOpen6 && FiltersOpenIndex1 === row[0][0].id}
                              value={row[0][0].memoSotring}
                              onChange={(e) =>
                                MemoContractSortingActive(e.target.value, ContractIndex)
                              }
                              className="setting-border-radius"
                            >
                              <MenuItem value="최신순">최신순</MenuItem>
                              <MenuItem value="오래된 순">오래된 순</MenuItem>
                            </Select>
                          </FormControl>
                        </SuiBox>
                      </SuiBox>
                      {row[0][0].memo.length === 0 ? (
                        <SuiBox sx={{ mt: 1 }}>
                          <SuiTypography
                            fontSize={FilesSortingHistory !== "넓게보기" ? "14px" : "18px"}
                            fontWeight="medium"
                            color="text2"
                            sx={{ textAlign: "left" }}
                          >
                            등록된 메모가 없습니다.
                          </SuiTypography>
                        </SuiBox>
                      ) : (
                        row[0][0].memo.map((memoData, memoIndex) => {
                          return memoData.type === "create" ? (
                            <SuiBox
                              key={memoIndex}
                              sx={{
                                backgroundColor: "#ffffff",
                                mt: 1,
                                py: 2,
                                borderRadius: "0.5rem",
                                border: 1,
                                borderColor: "#CED4D7",
                              }}
                            >
                              <SuiBox display="flex" alignItems="center" sx={{ px: 2 }}>
                                <SuiTypography
                                  fontSize="18px"
                                  fontWeight="medium"
                                  color="dark"
                                  sx={{ mr: "auto" }}
                                >
                                  메모하기
                                </SuiTypography>
                                {typeData === "update" && authCheckContract(row) ? (
                                  <IconButton
                                    sx={{ mr: 1, p: 0 }}
                                    color="error"
                                    onClick={() => deleteMemo(ContractIndex, memoIndex)}
                                  >
                                    <RiDeleteBinLine />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                                {typeData === "update" ? (
                                  <SuiButton
                                    color={
                                      memoData.title === "" || memoData.content === ""
                                        ? "white"
                                        : "info2"
                                    }
                                    variant="outlined"
                                    sx={{
                                      minHeight: 20,
                                      height: 20,
                                      ml: 1,
                                      px: 0,
                                    }}
                                    disabled={memoData.title === "" || memoData.content === ""}
                                    onClick={() => pushMemo(ContractIndex, memoIndex)}
                                  >
                                    완료
                                  </SuiButton>
                                ) : (
                                  ""
                                )}
                              </SuiBox>
                              <Divider sx={{ my: 1 }} />
                              <SuiBox sx={{ px: 2 }}>
                                <SuiInput
                                  type="text"
                                  placeholder="제목"
                                  value={memoData.title}
                                  onChange={(e) =>
                                    memoInput(ContractIndex, "title", e.target.value, memoIndex)
                                  }
                                />
                              </SuiBox>
                              <SuiBox sx={{ px: 2 }}>
                                <TextField
                                  multiline
                                  rows={8}
                                  sx={{ width: "100%" }}
                                  className="Fileupload-textArea"
                                  variant="outlined"
                                  placeholder="내용을 입력해주세요."
                                  value={memoData.content}
                                  onChange={(e) =>
                                    memoInput(ContractIndex, "content", e.target.value, memoIndex)
                                  }
                                />
                              </SuiBox>
                            </SuiBox>
                          ) : (
                            <SuiBox
                              key={memoIndex}
                              sx={{
                                backgroundColor: "#ffffff",
                                mt: 1,
                                py: 2,
                                borderRadius: "0.5rem",
                                border: 1,
                                borderColor: "#CED4D7",
                              }}
                            >
                              <SuiBox display="flex" alignItems="center" sx={{ px: 2 }}>
                                <SuiBox sx={{ mr: "auto" }}>
                                  <SuiTypography
                                    fontSize="16px"
                                    fontWeight="medium"
                                    color="dark"
                                    sx={{ textAlign: "left" }}
                                  >
                                    {memoData.title}
                                  </SuiTypography>
                                  <SuiTypography
                                    fontSize="14px"
                                    fontWeight="medium"
                                    color="secondary"
                                    sx={{ textAlign: "left" }}
                                  >
                                    {memoData.department} · {memoData.name}&nbsp;{memoData.position}{" "}
                                    ·{dayjs(memoData.created_at).format("YY.MM.DD")}
                                  </SuiTypography>
                                </SuiBox>
                                <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                                  {typeData === "update" && authCheckContract(row) ? (
                                    <IconButton
                                      sx={{ p: 0 }}
                                      color="error"
                                      onClick={() => deleteMemo(ContractIndex, memoIndex)}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </SuiBox>
                              <Divider sx={{ my: 1 }} />
                              <SuiBox sx={{ px: 2 }}>
                                <SuiTypography
                                  fontSize="14px"
                                  fontWeight="medium"
                                  color="text2"
                                  sx={{ textAlign: "left", whiteSpace: "pre-line" }}
                                >
                                  {memoData.content}
                                </SuiTypography>
                              </SuiBox>
                            </SuiBox>
                          )
                        })
                      )}
                    </SuiBox>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </div>
      )
    } else if (value === 2) {
      return (
        <div>
          <HistoryCreateDialog
            MeetingData={setMeetingData}
            openDialog={MeetingDialogBool}
            closeDialog={closeDialog}
            backStep={backStep}
            selectDataProps={selectDataEdit}
            edit={HistoryEdit}
            editData={HistoryEditData}
            typeData={typeData}
          />
          {typeData === "create" || typeData === "update" ? (
            <SuiButton
              sx={{
                minHeight: 67,
                fontSize: 18,
                fontWeight: 700,
                boxShadow: 3,
                mb: "20px",
                mt: "20px",
              }}
              className="outlined-bg-white"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => addMettingDialog()}
            >
              작업 이력 추가하기
            </SuiButton>
          ) : (
            ""
          )}
          <SuiBox display="flex" justifyContent="end">
            <IconButton
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => setFilesSortingImage("이미지")}
              className={FilesSortingImage === "이미지" ? "image-icon active" : "image-icon"}
            >
              <BiImages />
            </IconButton>
            <IconButton
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => setFilesSortingImage("리스트")}
              className={FilesSortingImage !== "이미지" ? "image-icon active" : "image-icon"}
            >
              <AiOutlineUnorderedList />
            </IconButton>
          </SuiBox>
          <SuiBox sx={{ mt: "16px", mb: 12 }}>
            {FilesSortingImage === "이미지" ? (
              <Grid container>
                {MeetingDatas.length === 0 ? (
                  <SuiBox sx={{ mt: 2 }}>
                    <SuiTypography
                      fontSize={"18px"}
                      fontWeight="medium"
                      color="text2"
                      sx={{ textAlign: "left" }}
                    >
                      등록된 작업 이력이 없습니다.
                    </SuiTypography>
                  </SuiBox>
                ) : (
                  MeetingDatasShow
                )}
              </Grid>
            ) : (
              <SuiBox>
                <SuiBox className="outsourcing-history-backCard">
                  <SuiBox sx={{ textAlign: "start" }}>
                    <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                      작업 이력 정보
                    </SuiTypography>
                  </SuiBox>
                  <Grid container alignItems="center">
                    <Grid item xs={3} sx={{ pr: 1.5 }}>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="info"
                        sx={{ textAlign: "left", pt: 2, pb: 1 }}
                      >
                        단가 평균:
                      </SuiTypography>
                      <SuiBox sx={{ width: "100%" }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 1.8,
                            mb: 1,
                            color: "#535353",
                          }}
                        >
                          {showTable(1) ? comma(showTable(1)) : 0}원
                        </Card>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={3} sx={{ pr: 1.5 }}>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="info"
                        sx={{ textAlign: "left", pt: 2, pb: 1 }}
                      >
                        총 작업 수:
                      </SuiTypography>
                      <SuiBox sx={{ width: "100%" }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 1.8,
                            mb: 1,
                            color: "#535353",
                          }}
                        >
                          {MeetingDatas.length}건
                        </Card>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={3} sx={{ pr: 1.5 }}>
                      <SuiTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="info"
                        sx={{ textAlign: "left", pt: 2, pb: 1 }}
                      >
                        총 지급액:
                      </SuiTypography>
                      <SuiBox sx={{ width: "100%" }}>
                        <Card
                          sx={{
                            width: "100%",
                            height: 57,
                            backgroundColor: "#F7F9FB",
                            boxShadow: 0,
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 700,
                            pt: 1.8,
                            mb: 1,
                            color: "#535353",
                          }}
                        >
                          {showTable(2) ? comma(showTable(2)) : 0}원
                        </Card>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={3}>
                      <SuiButton
                        sx={{
                          mt: "38px;",
                          p: 1,
                          width: "100%",
                          backgroundColor: "#02723B",
                          color: "#ffffff !important",
                          height: "57px",
                          fontSize: "18px",
                          borderRadius: "1rem",
                        }}
                        color="excel"
                        onClick={() => allDownloadBtn()}
                      >
                        전체 내역 다운로드
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
                <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="excelExport-history"
                    className="download-table-xls-button"
                    table="table-to-xls-history"
                    filename={`작업이력 리스트 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`}
                    sheet="tablexls"
                    buttonText={"Download as XLS"}
                  />
                </div>
                <SuiBox
                  className="outsourcing-history-backCard"
                  sx={{
                    mt: "16px",
                  }}
                >
                  <SuiBox sx={{ textAlign: "start" }}>
                    <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                      작업 이력 리스트
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ width: 100, ml: "auto", mt: 4, mb: "35px" }}>
                    <FormControl
                      sx={{ minHeight: 30, width: "100%" }}
                      className={
                        FilesSortingMetting === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen7(!FiltersOpen7)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="black"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen7(!FiltersOpen7)}
                          />
                        )}
                        open={FiltersOpen7}
                        value={FilesSortingMetting}
                        onChange={(e) => setFilesSortingMetting(e.target.value)}
                        className="setting-border-radius"
                      >
                        <MenuItem value="최신순">최신순</MenuItem>
                        <MenuItem value="오래된 순">오래된 순</MenuItem>
                      </Select>
                    </FormControl>
                  </SuiBox>
                  {MeetingDatas.length === 0 ? (
                    <TableContainer
                      sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
                    >
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={rowlength}
                        />
                      </Table>
                      <SuiBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ my: 2 }}
                      >
                        <SuiTypography fontSize="18px" color="dark" fontWeight="400">
                          등록된 내역이 없습니다.
                        </SuiTypography>
                      </SuiBox>
                    </TableContainer>
                  ) : (
                    <SuiBox>
                      <TableContainer
                        sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
                      >
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowlength}
                          />
                          <TableBody>
                            {stableSort(
                              MeetingDatas.map((x) => x.data).slice(0, historySplice),
                              getComparator(order, orderBy)
                            ).map((row, index) => {
                              const fileName = row[0].file.name
                              const fileType = fileName.split(".")[fileName.split(".").length - 1]
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  sx={{ cursor: "pointer" }}
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    style={{ borderRight: "1px solid #F1F1F5" }}
                                  >
                                    {row[1].value1}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {row[1].value.map((x) => x.title).join(",")}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {dayjs(row[3].value1).format("YY.MM.DD")}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {dayjs(row[3].value2).format("YY.MM.DD")}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                  >
                                    {row[1].value2}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => hisotyEdit(row)}
                                    align="center"
                                    className="td-Setting td-fontSetting"
                                    style={{ borderRight: "1px solid #F1F1F5" }}
                                  >
                                    {row[5].value}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    <IconButton
                                      sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }}
                                      className="cursorDefalut"
                                    >
                                      {GetFileIcon(fileType)}
                                    </IconButton>
                                    {typeData !== "detail" ? (
                                      <IconButton
                                        aria-label="more"
                                        id={`long-button`}
                                        aria-controls="long-menu"
                                        aria-expanded={open ? "true" : undefined}
                                        aria-haspopup="true"
                                        color="subTitle"
                                        onClick={(e) => handleClick(e, index)}
                                      >
                                        <FiMoreHorizontal />
                                      </IconButton>
                                    ) : (
                                      ""
                                    )}
                                    <Menu
                                      sx={{ display: index === anchorElIndex ? "" : "none" }}
                                      id="long-menu"
                                      MenuListProps={{
                                        "aria-labelledby": "long-button",
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleMenu}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                        },
                                      }}
                                    >
                                      <MenuItem onClick={() => deleteHistoryRequest(row)}>
                                        삭제신청
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {Math.ceil(historySplice / 10) < Math.ceil(MeetingDatas.length / 10) ? (
                        <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px" }}>
                          <SuiButton
                            color="info2"
                            variant="text"
                            sx={{
                              width: 100,
                              border: 1,
                              borderColor: "#0C65FF",
                              height: 40,
                              mr: 2,
                              fontSize: 14,
                            }}
                            onClick={() => {
                              sethistorySplice(historySplice + 10)
                            }}
                          >
                            더보기
                          </SuiButton>
                        </SuiBox>
                      ) : (
                        ""
                      )}
                      <TableContainer sx={{ display: "none" }}>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={"medium"}
                          id="table-to-xls-history"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "14px",
                                }}
                                align="center"
                              >
                                <TableSortLabel>
                                  <span>단가평균</span>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "14px",
                                }}
                                align="center"
                              >
                                <TableSortLabel>
                                  <span>총 작업 수</span>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "14px",
                                }}
                                align="center"
                              >
                                <TableSortLabel>
                                  <span>총 지급액 </span>
                                </TableSortLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow
                              hover
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                              tabIndex={-1}
                            >
                              <TableCell align="center" className="td-Setting td-fontSetting">
                                {showTable(1) ? comma(showTable(1)) : 0}원
                              </TableCell>
                              <TableCell align="center" className="td-Setting td-fontSetting">
                                {MeetingDatas.length}건
                              </TableCell>{" "}
                              <TableCell align="center" className="td-Setting td-fontSetting">
                                {showTable(2) ? comma(showTable(2)) : 0}원
                              </TableCell>
                            </TableRow>
                          </TableBody>

                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowlength}
                          />
                          <TableBody>
                            {stableSort(
                              MeetingDatas.map((x) => x.data),
                              getComparator(order, orderBy)
                            ).map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  sx={{ cursor: "pointer" }}
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[1].value1}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[1].value.map((x) => x.title).join(",")}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[3].value1}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[3].value2}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[1].value2}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {row[5].value}
                                  </TableCell>
                                  <TableCell align="center" className="td-Setting td-fontSetting">
                                    {process.env.REACT_APP_BACKEND_URL + row[0].file.name}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </SuiBox>
                  )}
                </SuiBox>
              </SuiBox>
            )}
          </SuiBox>
          <HistoryDialog
            openDialog={HistoryDialogOpenBool}
            closeDialog={closeDialog}
            nextStep={nextStep}
            selectDataProps={selectDataProps}
            selectDataEdit={selectDataEdit}
          />
        </div>
      )
    }
  }
  const [FileUploadDialogBool, setFileUploadDialogBool] = useState(false)
  const [MeetingDialogBool, setMeetingDialogBool] = useState(false)
  const [HistoryEdit, setHistoryEdit] = useState(false)
  const [HistoryEditData, setHistoryEditData] = useState([])
  const [ContractDialogOpenBool, setContractDialogOpenBool] = useState(false)
  const [HistoryDialogOpenBool, setHistoryDialogOpenBool] = useState(false)
  const [ContractRenewalDialogOpenBool, setContractRenewalDialogOpenBool] = useState(false)
  const [ContractSubDialogOpenBool, setContractSubDialogOpenBool] = useState(false)
  const [ContarctRenewalData, setContarctRenewalData] = useState([])
  const [ContarctSubData, setContarctSubData] = useState([])
  const [ContarctSubParentData, setContarctSubParentData] = useState([])
  const [ContarctSubDataApply, setContarctSubDataApply] = useState([])
  const [ContarctSubDataIndexs, setContarctSubDataIndexs] = useState({ index: 0, ContractIndex: 0 })
  const [renewalData, setrenewalData] = useState([])
  const [renewalEndData, setrenewalEndData] = useState([])
  const [ContarctRenewalType, setContarctRenewalType] = useState("renewal")
  const [ContractRenewalDialogEdit, setContractRenewalDialogEdit] = useState(false)
  const [ContractRenewalDialogEditData, setContractRenewalDialogEditData] = useState([])
  const [ContractSubDialogEdit, setContractSubDialogEdit] = useState(false)
  const [ContractSubDialogEditData, setContractSubDialogEditData] = useState([])
  const [ContractSubDialogRowIndex, setContractSubDialogRowIndex] = useState(0)
  const [ContractDialogEdit, setContractDialogEdit] = useState(false)
  const [ContractType, setContractType] = useState("create")
  const [HistoryType, setHistoryType] = useState("create")
  const [ContractDialogEditData, setContractDialogEditData] = useState([])
  const [AttachmentData, setAttachmentData] = useState([])
  const [AttachmentDatas, setAttachmentDatas] = useState([])
  const [MeetingData, setMeetingData] = useState([])
  const [MeetingDatas, setMeetingDatas] = useState([])
  const [ContractList, setContractList] = useState([])
  const [referrerDialog, setreferrerDialog] = useState(false)
  const [subContractAction, setsubContractAction] = useState([])
  const [AddRenewal, setAddRenewal] = useState([])
  useEffect(() => {
    if (ContarctSubDataApply.length > 0) {
      let array = [...ContractDatas]
      let SubDataSet = [...JSON.parse(JSON.stringify(ContarctSubDataApply))]
      SubDataSet[0].ContractType = ContarctSubDataApply[0].value + "_부속 계약"
      SubDataSet[1].value1 = ContarctSubDataApply[3].value1
      SubDataSet[1].value2 = ContarctSubDataApply[3].value2
      SubDataSet[3].value = ContarctSubDataApply[5].value
      SubDataSet[0].file = ContarctSubDataApply[0].file
      SubDataSet[0].managerLog = [
        {
          email: myData.email,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          users_permissions_user: myData.id,
          created_at: new Date(),
        },
      ]
      if (ContractSubDialogEdit) {
        array[ContarctSubDataIndexs.ContractIndex][0][0].subContract[ContractSubDialogRowIndex] =
          SubDataSet
      } else {
        array[ContarctSubDataIndexs.ContractIndex][0][0].subContract.push(SubDataSet)
      }
      array[ContarctSubDataIndexs.ContractIndex][0][0].changed = true
      setContractDatas(array)
      AddsubContractHistory(ContractSubDialogEdit, ContarctSubDataApply, SubDataSet)
    }
  }, [ContarctSubDataApply])
  useEffect(() => {
    if (ContarctRenewalData.length > 0) {
      let array = [...ContractDatas]
      let RenewalDataSet = [...JSON.parse(JSON.stringify(ContarctRenewalData))]
      RenewalDataSet[0].changed = true
      RenewalDataSet[0].ContractType = "갱신 계약"
      RenewalDataSet[0].ContractTypeValue = renewalData[1].value
      RenewalDataSet[0].file = renewalData[0].file
      if (renewalData[1].value === "직접입력") {
        RenewalDataSet[1].value1 = renewalData[3].value1
        RenewalDataSet[1].value2 = renewalData[3].value2
      } else {
        RenewalDataSet[1].value1 = renewalData[3].value1
        RenewalDataSet[1].value2 = dayjs(renewalData[3].value1).add(1, "years")
      }
      RenewalDataSet[3].value = renewalData[5].value
      array[contract_documents_data_index][0][0].changed = true
      array[contract_documents_data_index].unshift(RenewalDataSet)
      setContractDatas(array)
      AddsetAddRenewal(renewalData)
    }
  }, [renewalData])
  const AddsetAddRenewal = (renewalData) => {
    let array = [...AddRenewal]
    array.push({
      parentContractId: ContractDatas[contract_documents_data_index][0].id,
      profile: myData.profile ? myData.profile.url : null,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      content:
        "<strong>" +
        renewalData[0].value +
        "</strong>의 <strong>갱신 계약</strong>을 등록하였습니다.",
      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      type: "contract",
      renewalIndex: ContractDatas[contract_documents_data_index].length - 1,
    })
    setAddRenewal(array)
  }
  const AddsubContractHistory = (edit, subData, SubDataSet) => {
    let array = [...subContractAction]
    let content
    if (edit) {
      content =
        "<strong>" +
        subData[0].parentContract +
        "</strong>의 <strong>부속 계약</strong>을 수정하였습니다."
    } else {
      content =
        "<strong>" +
        subData[0].parentContract +
        "</strong>의 <strong>부속 계약</strong>을 등록하였습니다."
    }
    let sameMessage = array.findIndex((element) => element.content === content)
    if (sameMessage !== -1) {
      array.splice(sameMessage, 1, {
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: content,
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        subDataSet: SubDataSet,
        type: "contract",
      })
    } else {
      array.push({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: content,
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        subDataSet: SubDataSet,
        type: "contract",
      })
    }
    setsubContractAction(array)
  }
  const deleteHistoryRequest = (data) => {
    setopenMettingDeleteDialog(true)
    setMettingDeleteData(data)
  }
  const [deleteHistoryRequest_calcel_dialog, setdeleteHistoryRequest_calcel_dialog] =
    useState(false)
  const [deleteHistoryRequest_calcel_dialog_info, setdeleteHistoryRequest_calcel_dialog_info] =
    useState(false)
  const [cancelData, setcancelData] = useState([])
  const deleteHistoryRequest_cancel = (data) => {
    setcancelData(data[0].requests[0])
    setdeleteHistoryRequest_calcel_dialog(true)
  }
  const setdeleteHistoryRequest_calcel_active = () => {
    updateRequestActive({
      variables: {
        id: cancelData.id,
        status: `삭제 신청 신청취소(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
      },
    }).then(() => {
      setcreateContractShow(true)
      setdeleteHistoryRequest_calcel_dialog(false)
      setdeleteHistoryRequest_calcel_dialog_info(true)
    })
  }
  useEffect(() => {
    if (ContarctRenewalData.length > 0) {
      let array = [...ContractDatas]
      let RenewalDataSet = [...JSON.parse(JSON.stringify(ContarctRenewalData))]
      RenewalDataSet[0].changed = true
      RenewalDataSet[0].ContractType = "계약 종료"
      RenewalDataSet[0].ContractTypeValue = renewalEndData[0].value
      RenewalDataSet[0].file = renewalEndData[0].file
      RenewalDataSet[1].value1 = renewalEndData[1].value
      RenewalDataSet[1].value2 = renewalEndData[3].value
      // RenewalDataSet[3].value = renewalEndData[5].value
      array[contract_documents_data_index][0][0].changed = true
      array[contract_documents_data_index].unshift(RenewalDataSet)
      setContractDatas(array)
    }
  }, [renewalEndData])
  const memoInput = (ContractIndex, type, e, memoIndex) => {
    let array = [...ContractDatas]
    if (type === "title") {
      array[ContractIndex][0][0].memo[memoIndex].title = e
    } else {
      array[ContractIndex][0][0].memo[memoIndex].content = e
    }
    setContractDatas(array)
  }
  const addMemo = (ContractIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].memo.unshift({
      title: "",
      content: "",
      name: myData.name,
      position: myData.position,
      department: myData.department,
      type: "create",
      created_at: new Date(),
    })
    setContractDatas(array)
  }
  const pushMemo = (ContractIndex, memoIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].memo[memoIndex].type = "detail"
    array[ContractIndex][0][0].changed = true
    setcreateContractShow(true)
    setContractDatas(array)
  }
  const deleteMemo = (ContractIndex, memoIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].memo.splice(memoIndex, 1)
    array[ContractIndex][0][0].changed = true
    setcreateContractShow(true)
    setContractDatas(array)
  }
  const addContractSubDialogBtn = (row, ContractIndex, index, contract) => {
    setContractSubDialogEdit(false)
    setContarctSubData(row)
    setContarctSubDataIndexs({
      index: index,
      ContractIndex: ContractIndex,
    })
    setContractSubDialogOpenBool(true)
    setContarctSubParentData(contract)
  }

  const cancelReset = (value) => {
    if (value === 0) {
      let array = [...InputLists]
      array[0].value = DetailData.type
      array[1].value = DetailData.name
      array[2].value = DetailData.nickname
      array[3].value = DetailData.genre
      array[4].value = DetailData.mainTitle
      array[5].value = DetailData.contractStatus

      if (DetailData.bizNumber.length < 44) {
        array[6].value = DetailData.bizNumber
      } else {
        array[6].value = CryptoJS.AES.decrypt(DetailData.bizNumber, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      array[7].value = DetailData.email
      if (DetailData.phone.length < 14) {
        array[8].value = DetailData.phone
      } else {
        array[8].value = CryptoJS.AES.decrypt(DetailData.phone, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      if (DetailData.address.length < 44) {
        array[9].value = DetailData.address
      } else {
        array[9].value = CryptoJS.AES.decrypt(DetailData.address, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
      }
      array[10].value = String(DetailData.minPrice).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
      array[11].value = String(DetailData.maxPrice).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
      array[12].value = DetailData.accountName
      if (
        CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .indexOf("/") >= 0
      ) {
        array[13].itemsValue = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[0]
        array[13].value = CryptoJS.AES.decrypt(DetailData.account, secretKey)
          .toString(CryptoJS.enc.Utf8)
          .replace(/\"/g, "")
          .split("/")[1]
      } else {
        array[13].itemsValue = ""
        array[13].value = ""
      }

      setstepidx(0)
      setidx("")
      settest("")
      const x = JSON.parse(JSON.stringify(originSignificants))
      setSignificants(x)
      setUpdateData([])

      let arrayAttachments = []
      DetailData.outsourcing_attachments.forEach((element) => {
        InputListsSetData[0].file = element.file[0]
        InputListsSetData[1].id = element.id
        InputListsSetData[1].value = element.title
        InputListsSetData[1].itemsValue = element.type
        InputListsSetData[1].name = element.users_permissions_user.name
        InputListsSetData[1].position = element.users_permissions_user.position
        InputListsSetData[1].department = element.users_permissions_user.department
        InputListsSetData[1].created_at = element.created_at
        // InputListsSetData[3].value = element.explain
        arrayAttachments.push({ data: JSON.parse(JSON.stringify(InputListsSetData)) })
      })
      setAttachmentDatas(
        arrayAttachments.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setischeck(false)
    } else if (value === 1) {
      let arrayContracts = []
      DetailData.outsourcing_contracts.forEach((element) => {
        let ContractSet = element.contractPaper
        element.contractPaper.forEach((paper, paperIndex) => {
          ContractSet[paperIndex][0].id = element.id
          ContractSet[paperIndex][1].created_at = element.created_at
          ContractSet[0][0].subContract = element.subContract
          ContractSet[0][0].memo = element.memo
          ContractSet[0][0].managerLog = element.managerLog
        })
        arrayContracts.push(JSON.parse(JSON.stringify(ContractSet)))
      })
      setContractDatas(
        arrayContracts.sort((a, b) => {
          var dateA = new Date(a[0][1].created_at).getTime()
          var dateB = new Date(b[0][1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
      setsubContractAction([])
      setAddRenewal([])
    } else if (value === 2) {
      let arrayhistories = []
      DetailData.outsourcing_histories.forEach((element) => {
        historiesInputListsSet[0].selectDataProps = element.workType
        element.file.type = element.file.mime
        historiesInputListsSet[0].file = element.file
        historiesInputListsSet[0].requests = element.requests
        historiesInputListsSet[0].id = element.id
        historiesInputListsSet[1].created_at = element.created_at
        historiesInputListsSet[1].value = element.pieces
        historiesInputListsSet[1].value1 = element.type
        historiesInputListsSet[1].value2 = String(element.price)
        historiesInputListsSet[3].value1 = element.start
        historiesInputListsSet[3].value2 = element.end
        historiesInputListsSet[5].value = element.comment
        arrayhistories.push({ data: JSON.parse(JSON.stringify(historiesInputListsSet)) })
      })

      setMeetingDatas(
        arrayhistories.sort((a, b) => {
          var dateA = new Date(a.data[3].value2).getTime()
          var dateB = new Date(b.data[3].value2).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    }
    settypeData("detail")
    dispatch(editStatus_Apply(false))
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }

  const firstItemCheck = (attachData) => {
    let valueData = AttachmentDatas.map((x) => x.data[1])
    let afterCheck = valueData.findIndex(
      (e) =>
        dayjs(e.created_at).isAfter(dayjs(attachData.data[1].created_at)) &&
        e.itemsValue === attachData.data[1].itemsValue
    )
    if (afterCheck === -1) {
      return true
      return true
    } else {
      return false
    }
  }
  const AttachmentDatasShow = AttachmentDatas.map((data, index) => {
    const fileName = data.data[0].file?.name
    const fileType = fileName.split(".")[fileName.split(".").length - 1]
    return (
      <Card
        key={index}
        sx={{
          border: 1,
          borderColor: "#CDD2D3",
          boxShadow: 0,
          minWidth: 491,
          p: 2.5,
          mr: AttachmentDatas.length - 1 === index ? 0 : 2,
          textAlign: "left",
        }}
      >
        <SuiBox display="flex" sx={{ alignItems: "center", mb: "6px" }}>
          <Chip
            label={firstItemCheck(data) ? "최신" : "구버전"}
            sx={{
              backgroundColor: firstItemCheck(data) ? "#FBE8F1" : "#EEEEEE",
              color: firstItemCheck(data) ? "#E67DAF" : "#A4A4A4",
              borderRadius: "8px",
            }}
          />
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{
              ml: 1.5,
              mr: "auto",
            }}
          >
            {data.data[1].itemsValue}
          </SuiTypography>
          {typeData !== "update" && typeData !== "create" ? (
            ""
          ) : (
            <div>
              <IconButton
                aria-label="more"
                id={`long-button`}
                aria-controls="long-menu"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, index)}
                sx={{ color: "#000000" }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                sx={{ display: index === anchorElIndex ? "" : "none" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                  },
                }}
              >
                <MenuItem onClick={(e) => handleClose(data.data, e, index)}>수정</MenuItem>
                <MenuItem onClick={(e) => handleClose(data.data, e, index)}>삭제</MenuItem>
              </Menu>
            </div>
          )}
        </SuiBox>
        <SuiTypography fontSize="15px" color="subTitle">
          {data.data[1].department} {data.data[1].name} {data.data[1].position} ·
          {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
        </SuiTypography>
        <SuiTypography fontSize="16px" sx={{ color: "#535353", py: 2.5 }}>
          {data.data[1].value}
        </SuiTypography>
        <SuiBox display="flex" alignItems="center">
          <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="medium"
            color="dark"
            sx={{ mr: 0.5, cursor: "pointer" }}
            onClick={() => DownloadPdf(data.data[0].file)}
          >
            {data.data[0].file
              ? data.data[0].file.name.length > 20
                ? data.data[0].file.name.substring(0, 20) + "..."
                : data.data[0].file.name
              : ""}
          </SuiTypography>
          {downloadCheck(data.data) ? (
            <SuiButton
              sx={{
                backgroundColor: "#EEEEEE",
                minHeight: 24,
                height: 24,
                width: 24,
                minWidth: 24,
                p: 0,
                ml: 1,
                boxShadow: 0,
                display: data.data[0].file.url ? "" : "none",
              }}
              onClick={() => showPdf(data.data[0].file)}
            >
              <FiSearch size="15" />
            </SuiButton>
          ) : (
            ""
          )}
          {/* {downloadCheck(data.data) ? (
            <SuiButton
              color="info"
              sx={{
                minHeight: 20,
                height: 20,
                ml: 1,
                pt: 1.8,
                px: 0,
                boxShadow: 0,
                display: data.data[0].file.url ? "" : "none",
              }}
              onClick={() => DownloadPdf(data.data[0].file)}
            >
              다운로드
            </SuiButton>
          ) : (
            ""
          )} */}
        </SuiBox>
      </Card>
    )
  })
  const fileReader = (file) => {
    if (file.id) {
      return process.env.REACT_APP_BACKEND_URL + file.url
    } else {
      return window.URL.createObjectURL(file)
    }
  }

  function GetFileIcon2(data) {
    const fileName = data.data[0].file?.name
    const fileType = fileName.split(".")[fileName.split(".").length - 1]

    switch (fileType) {
      case "png": {
        return <img width="100%" src="/images/PNG.png" />
      }
      case "jpg": {
        return <img width="100%" src="/images/JPG.png" />
      }
      case "jpeg": {
        return <img width="100%" src="/images/JPG.png" />
      }
      case "pdf": {
        return <img width="100%" src="/images/PDF.png" />
      }
      case "xls": {
        return <img width="100%" src="/images/XSL.png" />
      }
      case "xlsx": {
        return <img width="100%" src="/images/XSL.png" />
      }
      case "ppt": {
        return <img width="100%" src="/images/PPT.png" />
      }
      case "pptx": {
        return <img width="100%" src="/images/PPT.png" />
      }
      case "doc": {
        return <img width="100%" src="/images/DOC.png" />
      }
      case "docs": {
        return <img width="100%" src="/images/DOC.png" />
      }
      case "docx": {
        return <img width="100%" src="/images/DOC.png" />
      }
    }
  }

  const MeetingDatasShow = MeetingDatas.map((data, index) => {
    return (
      <Grid item xs={3} key={index}>
        <Card
          className={"cursor"}
          sx={{
            border: 1,
            borderColor: "#CED4D7",
            boxShadow: 0,
            mb: 2,
            mr: (index + 1) % 4 === 0 ? 0 : 2,
            textAlign: "left",
            height: "auto",
          }}
        >
          {typeData !== "detail" ? (
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              className="outsourcing-history-menu"
              color="white"
              // sx={{ display: authOnlyClientCheck() ? "" : "none" }}
              onClick={(e) => handleClick(e, index)}
            >
              <FiMoreHorizontal />
            </IconButton>
          ) : (
            ""
          )}
          <Menu
            sx={{ display: index === anchorElIndex ? "" : "none" }}
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
            <MenuItem onClick={() => deleteHistoryRequest(data.data)}>삭제신청</MenuItem>
          </Menu>

          {data.data[0].file.type.indexOf("image") > -1 ? (
            <img
              onClick={() => hisotyEdit(data.data)}
              className="outsourcing-history-img"
              src={fileReader(data.data[0].file)}
            ></img>
          ) : GetFileIcon2(data) ? (
            <div
              className="outsourcing-history-img"
              onClick={() => hisotyEdit(data.data)}
              style={{ padding: "30%" }}
            >
              {GetFileIcon2(data)}
            </div>
          ) : (
            <AiOutlineFile
              onClick={() => hisotyEdit(data.data)}
              className="outsourcing-history-img icon"
              sx={{ color: "red" }}
            />
          )}

          <SuiBox
            sx={{
              padding: "14px",
            }}
          >
            <SuiTypography
              onClick={() => hisotyEdit(data.data)}
              fontSize="16px"
              sx={{
                color: "#535353",
                height: "40px",
                overflow: "auto",
                lineHeight: "18px",
              }}
            >
              {data.data[1].value.length === 0
                ? ""
                : data.data[1].value.map((x) => x.title).join(",")}
            </SuiTypography>
            <SuiBox display="flex">
              <SuiTypography
                fontSize="13px"
                sx={{ color: "#9AA0A6" }}
                onClick={() => hisotyEdit(data.data)}
              >
                {dayjs(data.data[3].value1).format("YY.MM.DD")} ~{" "}
                {dayjs(data.data[3].value2).format("YY.MM.DD")}
              </SuiTypography>
              {data.data[0].requests.length > 0 ? (
                data.data[0].requests[0].status.indexOf("신청취소") === -1 &&
                data.data[0].requests[0].status.indexOf("거절") === -1 ? (
                  <SuiTypography
                    fontSize="13px"
                    color="error"
                    sx={{ ml: "auto", textDecoration: "underline" }}
                    onClick={(e) => handleClick(e, index)}
                  >
                    삭제신청
                  </SuiTypography>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </SuiBox>
          </SuiBox>
          {data.data[0].requests.length > 0 ? (
            data.data[0].requests[0].status.indexOf("신청취소") === -1 &&
            data.data[0].requests[0].status.indexOf("거절") === -1 ? (
              <Menu
                sx={{ display: index === anchorElIndex ? "" : "none" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                  },
                }}
              >
                <MenuItem onClick={() => deleteHistoryRequest_cancel(data.data)}>
                  삭제신청 취소
                </MenuItem>
              </Menu>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Card>
      </Grid>
    )
  })
  const dispatch = useDispatch()
  const [usersDatas, setusersDatas] = useState([])

  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("외주계약수정 오류 발생")
    },
    refetchQueries: [outsourcing],
  })
  const [updateOutsourcingAttachmentActive] = useMutation(updateOutsourcingAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 수정시 오류 발생")
    },
  })
  const [createOutsourcingAttachmentActive] = useMutation(createOutsourcingAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 생성 오류 발생")
    },
  })
  const [createOutsourcingHistoryActive] = useMutation(createOutsourcingHistory, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("작업이력 생성시 오류 발생")
    },
  })
  const [updateOutsourcingHistoryActive] = useMutation(updateOutsourcingHistory, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("작업이력 수정시 오류 발생")
    },
  })
  const [createoutsourcingContractActive] = useMutation(createoutsourcingContract, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("외주계약계약 계약서 생성시 오류 발생")
    },
  })
  const [updateoutsourcingContractActive] = useMutation(updateoutsourcingContract, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openDeleteInfo_Dialog, setopenDeleteInfo_Dialog] = useState(false)
  const openRequest = (data) => {
    if (data.status.indexOf("심사중") > -1) {
      // setDelete_Dialog(true)
      // setDelete_Content(
      //   `<strong>'${DetailData.nickname} 외주계약'</strong>의 정보 삭제 신청을 진행합니다.`
      // )
      // setrequestExplainData(data.content.requestExplain)
      // setDelete_Dialog_type("edit")
      let alert_data = { ...delete_cancle_alert }
      alert_data.open = true
      alert_data.title = "삭제신청 취소"
      alert_data.content = "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?"
      alert_data.twoBtn = true
      alert_data.id = data.id
      alert_data.CanBtnText = "돌아가기"
      setdelete_cancle_alert(alert_data)
    } else {
      setDelete_Dialog(true)
      if (!data.content.read) {
        const content = data.content
        content.read = true
        updateRequestActive({
          variables: {
            id: data.id,
            content: content,
          },
        })
      }
      setDelete_Content(`${DetailData.nickname}`)
      setrequestExplainData(data.declining)
      setDelete_Dialog_type("delete")
    }
  }
  const updateOutsourcingActiveBtn = async () => {
    if (value === 0) {
      const editHistory = [...DetailData.histroy]
      let significantAdded = 0
      for (var i = 0; i < Significants.length; i++) {
        if (!Significants[i].id) {
          significantAdded++
          createOutsourcingSignificantActive({
            variables: {
              content: Significants[i].content,
              userName: Significants[i].userName,
              userPosition: Significants[i].userPosition,
              userProfile: Significants[i].userProfile,
              userDepartment: Significants[i].userDepartment,
              outsourcingId: Significants[i].outsourcingId,
              outsourcing: Significants[i].outsourcing,
            },
          })
        }
      }
      if (UpdateData.length > 0) {
        for (var i = 0; i < UpdateData.length; i++) {
          if (UpdateData[i].id) {
            updateOutsourcingSignificantActive({
              variables: {
                id: UpdateData[i].id,
                content: UpdateData[i].content,
              },
            })
          }
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 수정하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
        setUpdateData([])
      }

      if (DeleteId.length > 0) {
        for (var i = 0; i < DeleteId.length; i++) {
          if (DeleteId[i] !== undefined) {
            updateOutsourcingSignificantActive({
              variables: {
                id: DeleteId[i],
                outsourcing: null,
              },
            })
          }
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 삭제하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
        setDeleteId([])
      }

      let ok = 0
      InputLists.forEach((element) => {
        if (element.title === "닉네임:" && element.value === "") {
          ok += 1
        }
        // if (element.title === "계좌번호:" && element.itemsValue !== "") {
        //   if (element.value === "") {
        //     ok += 1
        //   }
        // }
        // if (element.title === "계좌번호:" && element.value !== "") {
        //   if (element.itemsValue === "") {
        //     ok += 1
        //   }
        // }
      })
      let changeFiled = []
      InputLists.forEach((element, index) => {
        if (element.changed) {
          if (index === 11) {
            changeFiled.push("단가 범위(세전, 원)-최대")
          } else if (index === 10) {
            changeFiled.push("단가 범위(세전, 원)-최소")
          } else {
            changeFiled.push(element.title.replace(":", ""))
          }
        }
      })
      let addAttachmentChanged = false
      let changed_attachment = []
      AttachmentDatas.forEach((element) => {
        if (!element.data[1].id) {
          addAttachmentChanged = true
        } else {
          let idfilterd = DetailData.outsourcing_attachments.filter((el) => {
            return el.id === element.data[1].id
          })
          if (idfilterd[0].type !== element.data[1].itemsValue) {
            changed_attachment.push(idfilterd[0])
          } else if (idfilterd[0].title !== element.data[1].value) {
            changed_attachment.push(idfilterd[0])
          } else if (!element.data[0].file.id) {
            changed_attachment.push(idfilterd[0])
          }
        }
      })

      if (significantAdded > 0) {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>특이사항</strong>을 등록하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      if (changed_attachment.length > 0) {
        changed_attachment.forEach((el) => {
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>첨부파일 " + el.type + "</strong>을(를) 수정하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
        })
      }

      if (deleted_attachment.length > 0) {
        deleted_attachment.forEach((del) => {
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: "<strong>첨부파일 " + del[1].itemsValue + "</strong>을(를) 삭제하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
        })
      }

      if (changeFiled.length > 0) {
        let sideText
        if (changeFiled.join(", ").endsWith("일") || changeFiled.join(", ").endsWith("명")) {
          sideText = "을 "
        } else {
          sideText = "를 "
        }
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>" + changeFiled.join(", ") + "</strong>" + sideText + "수정하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      if (addAttachmentChanged) {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>첨부파일</strong>을 등록하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      }
      const editManager = [...DetailData.manager]
      if (ok === 0) {
        dispatch(loading_Apply(true))
        let variablesData = {
          id: DetailData.id,

          type: InputLists[0].value,
          name: InputLists[1].value,
          nickname: InputLists[2].value,
          genre: InputLists[3].value,
          mainTitle: InputLists[4].value,
          contractStatus: InputLists[5].value,
          bizNumber: CryptoJS.AES.encrypt(
            JSON.stringify(InputLists[6].value),
            secretKey
          ).toString(),
          email: InputLists[7].value,
          phone: CryptoJS.AES.encrypt(JSON.stringify(InputLists[8].value), secretKey).toString(),
          address: CryptoJS.AES.encrypt(JSON.stringify(InputLists[9].value), secretKey).toString(),
          minPrice: Number(InputLists[10].value.replace(/[^\d]+/g, "")),
          maxPrice: Number(InputLists[11].value.replace(/[^\d]+/g, "")),
          accountName: InputLists[12].value,
          account: CryptoJS.AES.encrypt(
            JSON.stringify(InputLists[13].itemsValue + "/" + InputLists[13].value),
            secretKey
          ).toString(),
          histroy: editHistory,
          manager: editManager,
        }

        if (deleteDatas.length > 0) {
          variablesData.outsourcing_attachments = AttachmentDatas.map((x) => x.data[1].id).filter(
            (x) => !deleteDatas.map((x) => x[1].id).includes(x)
          )
        }
        updateOutsourcingActive({
          variables: variablesData,
        }).then((resCompany) => {
          createRecentActive({
            variables: {
              users_permissions_user: myData.id,
              title: "외주계약 정보 수정",
              type: "Outsourcing",
              srcId: DetailData.id,
              name: InputLists[1].value,
            },
          })
          if (AttachmentDatas.length > 0) {
            AttachmentDatas.map((x) => x.data).forEach((element, index) => {
              if (element[0].changed) {
                if (element[1].id) {
                  if (element[0].file.url) {
                    updateOutsourcingAttachmentActive({
                      variables: {
                        id: element[1].id,
                        title: element[1].value,
                        type: element[1].itemsValue,
                        // explain: element[3].value,
                        outsourcing: resCompany.data.updateOutsourcing.outsourcing.id,
                        outsourcingId: resCompany.data.updateOutsourcing.outsourcing.id,
                        maker: myData.email,
                        users_permissions_user: myData.id,
                        position: myData.position,
                      },
                    })
                  } else {
                    let data = new FormData()
                    data.append("files", element[0].file)
                    axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
                      updateOutsourcingAttachmentActive({
                        variables: {
                          id: element[1].id,
                          file: [res.data[0].id],
                          title: element[1].value,
                          type: element[1].itemsValue,
                          // explain: element[3].value,
                          outsourcing: resCompany.data.updateOutsourcing.outsourcing.id,
                          outsourcingId: resCompany.data.updateOutsourcing.outsourcing.id,
                          maker: myData.email,
                          users_permissions_user: myData.id,
                          position: myData.position,
                        },
                      })
                    })
                  }
                } else {
                  let data = new FormData()
                  data.append("files", element[0].file)
                  axios
                    .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                    .then((res) => {
                      createOutsourcingAttachmentActive({
                        variables: {
                          file: [res.data[0].id],
                          title: element[1].value,
                          type: element[1].itemsValue,
                          // explain: element[3].value,
                          outsourcing: resCompany.data.updateOutsourcing.outsourcing.id,
                          outsourcingId: resCompany.data.updateOutsourcing.outsourcing.id,
                          maker: myData.email,
                          users_permissions_user: myData.id,
                          position: myData.position,
                        },
                      })
                    })
                    .catch(() => dispatch(loading_Apply(false)))
                }
              }
              if (AttachmentDatas.length - 1 === index) {
                dispatch(loading_Apply(false))
                snackOpenClick()
                settypeData("detail")
              }
            })
          } else {
            settypeData("detail")
            dispatch(loading_Apply(false))
            snackOpenClick()
          }
        })
      } else {
        setopenInfo_Dialog(true)
      }
      dispatch(loading_Apply(false))
    } else if (value === 1) {
      dispatch(loading_Apply(true))
      if (Delete_Contarct_Active) {
        await updateOutsourcingActive({
          variables: {
            id: DetailData.id,
            outsourcing_contracts: ContractDatas.filter((x) => x[0][0].id).map((x) => x[0][0].id),
          },
        })
      } else {
        // dispatch(loading_Apply(false))
        settypeData("detail")
      }
      const editHistory = [...DetailData.histroy]
      ContractDatas.forEach(async (element, ContractIndex) => {
        element.forEach(async (setData, setDataIndex) => {
          let addedMemo = setData[0].memo.filter((memos) => memos.type !== "create")
          setData[0].dropdown1 = false
          setData[0].dropdown2 = false
          if (element[0][0].changed && setDataIndex === 0) {
            if (element[0][0].id) {
              if (!setData[0].file.url) {
                let fileData = new FormData()
                fileData.append("files", setData[0].file)
                await axios
                  .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                  .then(async (res) => {
                    setData[0].file = res.data[0]
                    await updateoutsourcingContractActive({
                      variables: {
                        id: setData[0].id,
                        memo: addedMemo,
                        subContract: setData[0].subContract,
                        name: myData.name,
                        position: myData.position,
                        department: myData.department,
                        managerLog: [
                          ...setData[0].managerLog,
                          {
                            email: myData.email,
                            name: myData.name,
                            position: myData.position,
                            department: myData.department,
                            users_permissions_user: myData.id,
                            created_at: new Date(),
                          },
                        ],
                        referrer: element[0][0].referrer ? element[0][0].referrer : [],
                        contractPaper: element,
                        // users_permissions_user: myData.id,
                      },
                    }).then((newContract) => {
                      if (element[0][0].referrer) {
                        element[0][0].referrer.forEach((referrer) => {
                          if (
                            element[0][0].oriReferrer.map((x) => x.id).indexOf(referrer.id) === -1
                          ) {
                            createAlertActive({
                              variables: {
                                users_permissions_user: String(referrer.id),
                                title: "참조자 등록안내",
                                type: "Outsourcing",
                                userid: String(referrer.id),
                                content: {
                                  id: myData.id,
                                  position: myData.position,
                                  department: myData.department,
                                  name: myData.name,
                                  title: element[0][0].selectData[0].value,
                                  profile: myData.profile,
                                  targetName: DetailData.nickname,
                                },
                              },
                            })
                          }
                        })
                      }
                      if (
                        myData.id !== setData[0].user.id &&
                        myData.role.name === "Authenticated"
                      ) {
                        const content = {
                          request: {
                            id: myData.id,
                            department: myData.department,
                            name: myData.name,
                            position: myData.position,
                            profile: myData.profile ? myData.profile.url : null,
                          },
                          apply: {
                            id: myData.id,
                            department: myData.department,
                            name: myData.name,
                            position: myData.position,
                          },
                          companiesIds: [],
                          writersIds: [],
                          outsourcingId: {
                            id: setData[0].id,
                            user: setData[0].user.id,
                            outsourcingId: DetailData.id,
                            users: DetailData.user,
                          },
                          contractsIds: [],
                          writersContractId: {},
                          reject: "",
                          reason: "Authenticated",
                          created_at: new Date(),
                          status: "승인",
                        }
                        const body = {
                          type: "Outsourcing",
                          status: "승인",
                          contractId: setData[0].id,
                          content: content,
                          users_permissions_user: myData.id,
                        }
                        axios.post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
                          headers: {
                            Authorization: "Bearer " + sessionStorage.getItem("joara_token"),
                          },
                        })
                      }
                      let Renewaled = AddRenewal.filter((renew) => {
                        return renew.parentContractId === element[0].id
                      })
                      if (Renewaled.length > 0) {
                        Renewaled.forEach((renewal) => {
                          renewal.contractId = setData[0].id
                          editHistory.unshift(renewal)
                        })
                      } else {
                        editHistory.unshift({
                          profile: myData.profile ? myData.profile.url : null,
                          name: myData.name,
                          position: myData.position,
                          department: myData.department,
                          content:
                            "<strong>" +
                            element[0][0].selectData[0].value +
                            "</strong>을 수정하였습니다.",
                          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                          contractId: setData[0].id,
                          type: "contract",
                          contractLength: element.length,
                        })
                      }
                      updateOutsourcingActive({
                        variables: {
                          id: DetailData.id,
                          histroy: editHistory,
                          user: DetailData.user.id,
                        },
                      })
                    })
                  })
              } else {
                if (setData[0].subContract.length === 0) {
                  await updateoutsourcingContractActive({
                    variables: {
                      id: setData[0].id,
                      memo: addedMemo,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      managerLog: [
                        ...setData[0].managerLog,
                        {
                          email: myData.email,
                          name: myData.name,
                          position: myData.position,
                          department: myData.department,
                          users_permissions_user: myData.id,
                          created_at: new Date(),
                        },
                      ],
                      referrer: element[0][0].referrer ? element[0][0].referrer : [],
                      contractPaper: element,
                      // users_permissions_user: myData.id,
                    },
                  }).then((newContract) => {
                    if (element[0][0].referrer) {
                      element[0][0].referrer.forEach((referrer) => {
                        if (
                          element[0][0].oriReferrer.map((x) => x.id).indexOf(referrer.id) === -1
                        ) {
                          createAlertActive({
                            variables: {
                              users_permissions_user: String(referrer.id),
                              title: "참조자 등록안내",
                              type: "Outsourcing",
                              userid: String(referrer.id),
                              content: {
                                id: myData.id,
                                position: myData.position,
                                department: myData.department,
                                name: myData.name,
                                title: element[0][0].selectData[0].value,
                                profile: myData.profile,
                                targetName: DetailData.nickname,
                              },
                            },
                          })
                        }
                      })
                    }
                    let usersArray = []
                    if (DetailData.users) {
                      usersArray = DetailData.users.map((x) => x.id)
                      if (usersArray.indexOf(myData.id) === -1) {
                        usersArray.push(myData.id)
                      }
                    }
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content:
                        "<strong>" +
                        element[0][0].selectData[0].value +
                        "</strong>을 수정하였습니다.",
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      contractId: setData[0].id,
                      type: "contract",
                      contractLength: element.length,
                    })
                    updateOutsourcingActive({
                      variables: {
                        id: DetailData.id,
                        histroy: editHistory,
                        user: DetailData.user.id,
                      },
                    })
                  })
                } else {
                  await setData[0].subContract.forEach(async (subCont, subContractIndex) => {
                    if (!subCont[0].file.url) {
                      let fileData = new FormData()
                      fileData.append("files", subCont[0].file)
                      await axios
                        .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                        .then(async (res) => {
                          subCont[0].file = res.data[0]
                        })
                    }
                    if (setData[0].subContract.length - 1 === subContractIndex) {
                      updateoutsourcingContractActive({
                        variables: {
                          id: setData[0].id,
                          memo: addedMemo,
                          name: myData.name,
                          position: myData.position,
                          department: myData.department,
                          managerLog: [
                            ...setData[0].managerLog,
                            {
                              email: myData.email,
                              name: myData.name,
                              position: myData.position,
                              department: myData.department,
                              users_permissions_user: myData.id,
                              created_at: new Date(),
                            },
                          ],
                          referrer: element[0][0].referrer ? element[0][0].referrer : [],
                          // users_permissions_user: myData.id,
                          contractPaper: element,
                          subContract: setData[0].subContract,
                        },
                      }).then((newContract) => {
                        if (element[0][0].referrer) {
                          element[0][0].referrer.forEach((referrer) => {
                            if (
                              element[0][0].oriReferrer.map((x) => x.id).indexOf(referrer.id) === -1
                            ) {
                              createAlertActive({
                                variables: {
                                  users_permissions_user: String(referrer.id),
                                  title: "참조자 등록안내",
                                  type: "Outsourcing",
                                  userid: String(referrer.id),
                                  content: {
                                    id: myData.id,
                                    position: myData.position,
                                    department: myData.department,
                                    name: myData.name,
                                    title: element[0][0].selectData[0].value,
                                    profile: myData.profile,
                                    targetName: DetailData.nickname,
                                  },
                                },
                              })
                            }
                          })
                        }
                        let usersArray = []
                        if (DetailData.users) {
                          usersArray = DetailData.users.map((x) => x.id)
                          if (usersArray.indexOf(myData.id) === -1) {
                            usersArray.push(myData.id)
                          }
                        }
                        if (subContractAction.length > 0) {
                          subContractAction.forEach((subAction, subIndex) => {
                            subAction.contractId = setData[0].id
                            editHistory.unshift(subAction)
                            subContractAction.splice(subIndex, 1)
                          })
                        } else {
                          editHistory.unshift({
                            profile: myData.profile ? myData.profile.url : null,
                            name: myData.name,
                            position: myData.position,
                            department: myData.department,
                            content:
                              "<strong>" +
                              element[0][0].selectData[0].value +
                              "</strong>을 수정하였습니다.",
                            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                            contractId: setData[0].id,
                            type: "contract",
                            contractLength: element.length,
                          })
                        }
                        updateOutsourcingActive({
                          variables: {
                            id: DetailData.id,
                            histroy: editHistory,
                            user: DetailData.user.id,
                          },
                        })
                      })
                    }
                  })
                }
              }
            } else {
              let fileData = new FormData()
              fileData.append("files", setData[0].file)
              await axios
                .post(process.env.REACT_APP_BACKEND_URL + "/upload", fileData)
                .then((res) => {
                  setData[0].file = res.data[0]
                })
              await createoutsourcingContractActive({
                variables: {
                  type: "Outsourcing",
                  status: "공개",
                  memo: [],
                  subContract: [],
                  outsourcing: DetailData.id,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  users_permissions_user: myData.id,
                  managerLog: [
                    {
                      email: myData.email,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      users_permissions_user: myData.id,
                      created_at: new Date(),
                    },
                  ],
                  referrer: element[0][0].referrer ? element[0][0].referrer : [],
                  contractPaper: element,
                },
              }).then((datas) => {
                let usersArray = []
                if (DetailData.users) {
                  usersArray = DetailData.users.map((x) => x.id)
                  if (usersArray.indexOf(myData.id) === -1) {
                    usersArray.push(myData.id)
                  }
                }
                editHistory.unshift({
                  profile: myData.profile ? myData.profile.url : null,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  content:
                    "<strong>" + element[0][0].selectData[0].value + "</strong>을 등록하였습니다.",
                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  contractId: datas.data.createOutsourcingContract.outsourcingContract.id,
                  type: "contract",
                })
                updateOutsourcingActive({
                  variables: {
                    id: DetailData.id,
                    histroy: editHistory,
                    user: DetailData.user.id,
                  },
                })
              })
            }
          }
        })
        if (ContractIndex === ContractDatas.length - 1) {
          // dispatch(loading_Apply(false))
          snackOpenClick()
          ContractDataReset()
          settypeData("detail")
          if (DetailData.users) {
            DetailData.users.forEach((user) => {
              if (user.id !== myData.id) {
                if (user.nomalAlertSetting) {
                  if (user.nomalAlertSetting[1].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(user.id),
                        title: "외주계약 계약정보 작성",
                        type: "Outsourcing",
                        userid: String(user.id),
                        content: {
                          id: myData.id,
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          title: InputLists[0].value,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              }
            })
          }
          createRecentActive({
            variables: {
              users_permissions_user: myData.id,
              title: "외주계약 계약정보 작성",
              type: "Outsourcing",
              srcId: DetailData.id,
              name: DetailData.nickname,
            },
          })
        }
      })
      setsubContractAction([])
      setAddRenewal([])
    } else if (value === 2) {
      const editHistory = [...DetailData.histroy]
      if (MeetingDatas.length > 0) {
        dispatch(loading_Apply(true))
        MeetingDatas.forEach(async (el, index) => {
          if (el.data[0].id) {
            if (!el.data[0].file.url) {
              let data = new FormData()
              data.append("files", el.data[0].file)
              await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
                const piecesDataset = []
                for (let index = 0; index < el.data[1].value.length; index++) {
                  el.data[1].value[index].name = DetailData.nickname
                  el.data[1].value[index].updated_at = el.data[3].value2
                  piecesDataset.push(el.data[1].value[index])
                }
                updateOutsourcingHistoryActive({
                  variables: {
                    id: el.data[0].id,
                    type: el.data[1].value1,
                    pieces: piecesDataset,
                    workType: el.data[0].selectDataProps,
                    price: Number(el.data[1].value2),
                    start: el.data[3].value1,
                    end: el.data[3].value2,
                    comment: el.data[5].value,
                    file: res.data[0].id,
                    status: "공개",
                    outsourcing: editId,
                  },
                }).then((res) => {
                  dispatch(loading_Apply(false))
                  snackOpenClick()
                  settypeData("detail")
                  editHistory.unshift({
                    profile: myData.profile ? myData.profile.url : null,
                    name: myData.name,
                    position: myData.position,
                    department: myData.department,
                    content:
                      "<strong>" +
                      el.data[1].value.map((x) => x.title).join(",") +
                      "</strong> 작품이력을 수정하였습니다. ",
                    date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                    type: "outsourcing",
                    contractId: null,
                  })
                  updateOutsourcingActive({
                    variables: {
                      id: DetailData.id,
                      histroy: editHistory,
                    },
                  })
                })
              })
            } else {
              const piecesDataset = []
              for (let index = 0; index < el.data[1].value.length; index++) {
                el.data[1].value[index].name = DetailData.nickname
                el.data[1].value[index].updated_at = el.data[3].value2
                piecesDataset.push(el.data[1].value[index])
              }
              updateOutsourcingHistoryActive({
                variables: {
                  id: el.data[0].id,
                  type: el.data[1].value1,
                  pieces: piecesDataset,
                  workType: el.data[0].selectDataProps,
                  price: Number(el.data[1].value2),
                  start: el.data[3].value1,
                  end: el.data[3].value2,
                  comment: el.data[5].value,
                  status: "공개",
                  outsourcing: editId,
                },
              }).then((res) => {
                dispatch(loading_Apply(false))
                snackOpenClick()
                settypeData("detail")
                editHistory.unshift({
                  profile: myData.profile ? myData.profile.url : null,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  content:
                    "<strong>" +
                    el.data[1].value.map((x) => x.title).join(",") +
                    "</strong> 작품이력을 수정하였습니다. ",
                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  type: "outsourcing",
                  contractId: null,
                })
                updateOutsourcingActive({
                  variables: {
                    id: DetailData.id,
                    histroy: editHistory,
                  },
                })
              })
            }
          } else {
            let data = new FormData()
            data.append("files", el.data[0].file)
            await axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
              const piecesDataset = []
              for (let index = 0; index < el.data[1].value.length; index++) {
                el.data[1].value[index].name = DetailData.nickname
                el.data[1].value[index].updated_at = el.data[3].value2
                piecesDataset.push(el.data[1].value[index])
              }
              createOutsourcingHistoryActive({
                variables: {
                  type: el.data[1].value1,
                  pieces: piecesDataset,
                  workType: el.data[0].selectDataProps,
                  price: Number(el.data[1].value2),
                  start: el.data[3].value1,
                  end: el.data[3].value2,
                  comment: el.data[5].value,
                  file: res.data[0].id,
                  status: "공개",
                  outsourcing: editId,
                },
              }).then((res) => {
                dispatch(loading_Apply(false))
                snackOpenClick()
                settypeData("detail")
                editHistory.unshift({
                  profile: myData.profile ? myData.profile.url : null,
                  name: myData.name,
                  position: myData.position,
                  department: myData.department,
                  content:
                    "<strong>" +
                    el.data[1].value.map((x) => x.title).join(",") +
                    "</strong> 작품이력을 등록하였습니다. ",
                  date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  type: "outsourcing",
                  contractId: null,
                })
                updateOutsourcingActive({
                  variables: {
                    id: DetailData.id,
                    histroy: editHistory,
                  },
                })
              })
            })
          }
        })
      } else {
        if (deleted_meetings.length > 0) {
          deleted_meetings.forEach((del) => {
            let sideText
            if (del[3].value.endsWith("록")) {
              sideText = "을 "
            } else {
              sideText = "를 "
            }
            if (del[1].contract_document !== null) {
              let z = del[1].contract_document.contractPaper[0][0].selectData[0].value
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content:
                  "<strong>" + z + "</strong>의 " + del[3].value + sideText + "삭제하였습니다. ",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-meeting",
                contractId: del[1].contract_document.id,
              })
            } else {
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: del[3].value + sideText + "삭제하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "outsourcing",
                contractId: null,
              })
            }
          })
          updateOutsourcingActive({
            variables: {
              id: DetailData.id,
              company_meetings: [],
              history: editHistory,
            },
          })
        }
        settypeData("detail")
        dispatch(loading_Apply(false))
        // snackOpenClick()
      }
    }
    dispatch(loading_Apply(false))
    dispatch(editStatus_Apply(false))
  }
  useEffect(() => {
    let array = [...AttachmentDatas]
    if (FilesSorting === "최신순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSorting === "오래된 순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSorting])
  const authCheck = () => {
    if (typeData !== "create") {
      if (
        myData.role.name === "Authenticated" ||
        myData.role.name === "gold" ||
        myData.role.name === "silver"
      ) {
        return true
      } else if (DetailData.referrer.length > 0) {
        if (DetailData.referrer.map((x) => x.id).indexOf(myData.id) > -1) {
          return true
        } else {
          if (DetailData.manager.length > 0) {
            if (
              DetailData.manager[0].name === myData.name &&
              DetailData.manager[0].department === myData.department
            ) {
              return true
            } else {
              if (DetailData.users.length > 0) {
                if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            }
          } else {
            if (DetailData.user.length > 0) {
              if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else if (DetailData.manager.length > 0) {
        if (
          DetailData.manager[0].name === myData.name &&
          DetailData.manager[0].department === myData.department
        ) {
          return true
        } else {
          if (DetailData.referrer.map((x) => x.id).indexOf(myData.id) > -1) {
            return true
          } else {
            if (DetailData.user) {
              if (DetailData.user.id === myData.id) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      } else {
        if (DetailData.users.length > 0) {
          if (DetailData.users.map((x) => x.id).indexOf(myData.id) > -1) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    }
  }
  const authOnlyClientCheck = () => {
    if (myData.role.name === "Authenticated" || myData.role.name === "gold") {
      return true
    } else if (DetailData.user) {
      if (DetailData.user.id === myData.id) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const deleteSelect = () => {
    if (DetailData.requests.length > 0) {
      if (DetailData.requests[0].status.indexOf("심사중") > -1) {
        setopenDeleteInfo_Dialog(true)
      } else {
        setDelete_Dialog_type("create")
        setDelete_Content(`${DetailData.nickname}`)
        setDelete_Dialog(true)
        setrequestExplainData("")
      }
    } else {
      setDelete_Dialog_type("create")
      setDelete_Content(`${DetailData.nickname}`)
      setDelete_Dialog(true)
      setrequestExplainData("")
    }
    setAnchorEl(null)
  }

  const [snackState, setsSnackState] = React.useState(false)
  const [snackState2, setsSnackState2] = React.useState(false)
  const snackOpenClick = () => {
    setsSnackState(true)
  }
  const snackOpenClick2 = () => {
    setsSnackState2(true)
  }
  const open_change_dialog = () => {
    setchange_content(DetailData.nickname)
    client
      .query({
        query: users_list,
        variables: {
          status: "근무",
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setusersDatas(res.data.users)
        setopen_ChagneDialog(true)
      })
  }
  const startEdit = () => {
    let array = [...InputLists]
    array.forEach((el) => {
      el.error = false
      el.error_message = ""
    })

    settypeData("update")
    dispatch(editStatus_Apply(true))
    setcreateContractShow(false)
    setsubContractAction([])
    setAddRenewal([])
    setdeleted_histories([])
    setdeleted_attachment([])
  }

  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="1320px"
        maxWidth="1320"
        alignItems="center"
        height="72px"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState}
          message="저장되었습니다"
          onClose={() => setsSnackState(false)}
        />
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState2}
          message="복사되었습니다"
          onClose={() => setsSnackState2(false)}
        />
        {typeData === "detail" ? (
          <SuiButton
            color="info2"
            sx={{ ml: "auto", width: 100, position: "absolute", fontSize: 14 }}
            onClick={() => startEdit()}
          >
            수정
          </SuiButton>
        ) : (
          <SuiButton
            color="subTitle"
            variant="text"
            sx={{ width: 100, border: 1, borderColor: "#707070", height: 40, mr: 2, fontSize: 14 }}
            onClick={() => cancelReset(value)}
          >
            취소
          </SuiButton>
        )}
        {typeData === "detail" ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {DetailData.requests.length > 0 ? (
              <SuiTypography
                onClick={() => {
                  if (
                    DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length === 0
                  )
                    openRequest(DetailData.requests[0])
                  else
                    setdelete_cancle_alert({
                      id: DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1)[0].id,
                      open: true,
                      title: "삭제신청 취소",
                      content: "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?",
                      twoBtn: true,
                      CanBtnText: "취소",
                    })
                }}
                fontSize="16px"
                fontWeight="bold"
                color={DetailData.requests[0].status.indexOf("심사중") > -1 ? "error" : "secondary"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    DetailData.requests[0].content.id === myData.id &&
                    !DetailData.requests[0].content.read
                      ? DetailData.requests[0].status.indexOf("취소") > -1
                        ? "none"
                        : ""
                      : "none",
                }}
              >
                {DetailData.requests[0].status.replace("심사중", "")}
              </SuiTypography>
            ) : (
              ""
            )}
            {ChangeRequests.length > 0 ? (
              <SuiTypography
                onClick={() => setchagne_cancel_dailog(true)}
                fontSize="16px"
                fontWeight="bold"
                color={"error"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display: ChangeRequests[0].users_permissions_user.id === myData.id ? "" : "none",
                }}
              >
                이관 신청{dayjs(ChangeRequests[0].updated_at).format("YY.MM.DD HH:mm")}
              </SuiTypography>
            ) : (
              ""
            )}
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              color="dark"
              sx={{ display: authOnlyClientCheck() ? "" : "none" }}
              onClick={(e) => handleClick(e, index)}
            >
              <FiMoreHorizontal />
            </IconButton>
            <Menu
              sx={{ display: index === anchorElIndex ? "" : "none" }}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              <MenuItem
                disabled={
                  ChangeRequests.length > 0 ||
                  DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length > 0
                }
                onClick={() => open_change_dialog()}
              >
                이관신청
              </MenuItem>
              <MenuItem
                disabled={
                  DetailData.requests.filter((x) => x.status.indexOf("심사중") > -1).length > 0 ||
                  ChangeRequests.length > 0
                }
                onClick={(e) => deleteSelect()}
              >
                삭제신청
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}
        {value === 0 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateOutsourcingActiveBtn()}
            id="saveEditBtn_out"
          >
            저장하기
          </SuiButton>
        )}
        {value === 1 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateOutsourcingActiveBtn()}
            id="saveEditBtn_out"
          >
            저장하기
          </SuiButton>
        )}
        {value === 2 && (
          <SuiButton
            color="info2"
            disabled={!createContractShow}
            sx={{
              width: 100,
              fontSize: 14,
              visibility: typeData === "detail" ? "hidden" : "initial",
              display: typeData === "detail" ? "none" : "",
            }}
            onClick={() => updateOutsourcingActiveBtn()}
            id="saveEditBtn_out"
          >
            저장하기
          </SuiButton>
        )}
      </SuiBox>
    )
  }
  useEffect(() => {
    let array = [...MeetingDatas]
    if (FilesSortingMetting === "최신순") {
      setMeetingDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[3].value2).getTime()
          var dateB = new Date(b.data[3].value2).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSortingMetting === "오래된 순") {
      setMeetingDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[3].value2).getTime()
          var dateB = new Date(b.data[3].value2).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSortingMetting])
  useEffect(() => {
    let array = [...ContractDatas]
    if (FilesSortingContract === "최신순") {
      setContractDatas(
        array.sort((a, b) => {
          var dateA = new Date(a[0][1].created_at).getTime()
          var dateB = new Date(b[0][1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSortingContract === "오래된 순") {
      setContractDatas(
        array.sort((a, b) => {
          var dateA = new Date(a[0][1].created_at).getTime()
          var dateB = new Date(b[0][1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSortingContract])
  const MemoContractSortingActive = (e, ContractIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].memoSotring = e
    if (e === "최신순") {
      array[ContractIndex][0][0].memo.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
    } else if (e === "오래된 순") {
      array[ContractIndex][0][0].memo.sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
    }
    setContractDatas(array)
  }
  const SubContractSortingActive = (e, ContractIndex) => {
    let array = [...ContractDatas]
    array[ContractIndex][0][0].subContractSotring = e
    if (e === "최신순") {
      array[ContractIndex][0][0].subContract.sort((a, b) => {
        var dateA = new Date(a[3].created_at).getTime()
        var dateB = new Date(b[3].created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
    } else if (e === "오래된 순") {
      array[ContractIndex][0][0].subContract.sort((a, b) => {
        var dateA = new Date(a[3].created_at).getTime()
        var dateB = new Date(b[3].created_at).getTime()
        return dateA > dateB ? 1 : -1
      })
    }
    setContractDatas(array)
  }

  useEffect(() => {
    if (AttachmentData.length > 0) {
      let array = [...AttachmentDatas]
      array.unshift({ data: AttachmentData })
      setAttachmentDatas(array)
    }
  }, [AttachmentData])
  useEffect(() => {
    if (MeetingData.length > 0) {
      let array = [...MeetingDatas]
      if (HistoryEdit) {
        array.forEach((el, index) => {
          if (MeetingData[0].id) {
            if (MeetingData[0].id === el.data[0].id) el.data = MeetingData
          } else {
            if (MeetingData[0].file.name === el.data[0].file.name) el.data = MeetingData
          }
        })
      } else {
        array.unshift({ data: MeetingData })
      }
      setMeetingDatas(array)
      setHistoryEdit(false)
      setTimeout(() => {
        setcreateContractShow(true)
        setMeetingData([])
      }, 500)
    }
  }, [MeetingData])
  const nextStep = () => {
    setHistoryDialogOpenBool(false)
    setMeetingDialogBool(true)
  }
  const closeDialog = () => {
    setopenMettingDeleteDialog(false)
    setFileUploadDialogBool(false)
    setreferrerDialog(false)
    setMeetingDialogBool(false)
    setContractRenewalDialogOpenBool(false)
    setContractDialogOpenBool(false)
    setContractSubDialogOpenBool(false)
    setContractChangeDialogOpenBool(false)
    setContractChangeDialogInfoOpenBool(false)
    setopenContract_deleteDialog(false)
    setopenMangerDialog(false)
    setHistoryDialogOpenBool(false)
  }
  const closeDialog2 = () => {
    setFileUploadDialogBool(false)
    setreferrerDialog(false)
    setMeetingDialogBool(false)
    setContractRenewalDialogOpenBool(false)
    setContractDialogOpenBool(false)
    setContractSubDialogOpenBool(false)
    setContractChangeDialogOpenBool(false)
    setContractChangeDialogInfoOpenBool(false)
    setischeck(true)
    setcreateContractShow(true)
  }
  const addMettingDialog = () => {
    setselectDataEdit("")
    setHistoryEdit(false)
    setHistoryEditData([])
    setHistoryDialogOpenBool(true)
  }
  const addContract = () => {
    setContractDialogOpenBool(true)
    setContractType("create")
    setContractDialogEdit(false)
  }
  const teamList = [
    "경영지원실",
    "콘텐츠사업본부",
    "전략기획팀",
    "플랫폼사업팀",
    "IP사업팀",
    "인터랙션디자인팀",
    "서비스개발팀",
    "서비스운영팀",
  ]
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  let lists = ["최초 정보 등록:", "계약 담당자:", "담당 계약:"]
  const [editTeamSelect, seteditTeamSelect] = useState("")
  const [editMangerSelect, seteditMangerSelect] = useState("")
  // if (DetailData.users.length > 0) {
  //   if (ContractDatas.length > 0) {
  //     let ContractListSet = []
  //     ContractDatas.forEach((datas) => {
  //       if (datas[0][0].user) {
  //         ContractListSet.push({
  //           name: datas[0][0].user.name,
  //           position: datas[0][0].user.position,
  //           department: datas[0][0].user.department,
  //           value: datas[0][0].selectData[0].value,
  //           value1: datas[0][1].value1,
  //           value2: datas[0][1].value2,
  //           value3: datas[0][3].value,
  //         })
  //       } else {
  //         ContractListSet.push({
  //           name: datas[0][1].name,
  //           position: datas[0][1].position,
  //           department: datas[0][1].department,
  //           value: datas[0][0].selectData[0].value,
  //           value1: datas[0][1].value1,
  //           value2: datas[0][1].value2,
  //           value3: datas[0][3].value,
  //         })
  //       }
  //     })
  //     // lists[2] = "진행 계약 정보: " + ContractListSet.length + "건"
  //     DetailData.ContractList = ContractListSet
  //   }
  // }
  useEffect(() => {
    if (DetailData.ContractList) {
      if (DetailData.ContractList.map((x) => x.department)[0]) {
        async function seteditTeamSelectFnc() {
          const data = await DetailData.ContractList.map((x) => x.department)[0]
          seteditTeamSelect(data)
          const data2 = await DetailData.ContractList.filter((x) => x.department === data).map(
            (x) => x.name
          )[0]
          seteditMangerSelect(data2)
        }
        seteditTeamSelectFnc()
      }
    }
  }, [DetailData])
  const changeTeamSelect = (e) => {
    seteditTeamSelect(e.target.value)
    setTimeout(() => {
      seteditMangerSelect(
        DetailData.ContractList.filter((x) => x.department !== editTeamSelect).map((x) => x.name)[0]
      )
    }, 500)
  }
  const mangaers = () => {
    let test = []
    if (DetailData) {
      DetailData.ContractList.forEach((x) => {
        if (test.findIndex((e) => e.name === x.name) === -1) {
          test.push(x)
        }
      })
    }
    return test
  }
  const MangerContractData = () => {
    let test = []
    if (DetailData.ContractList) {
      DetailData.ContractList.forEach((x) => {
        if (x.name === editMangerSelect) {
          test.push(x)
        }
      })
    }
    return test
  }
  const magerdisabled = () => {
    if (DetailData.ContractList !== undefined) {
      setFiltersOpen9(!FiltersOpen9)
    }
  }
  const [openMangerDialog, setopenMangerDialog] = useState(false)
  const openMangerInfoDialog = () => {
    setopenMangerDialog(true)
  }
  const listsShow = lists.map((row, index) => {
    return (
      <SuiBox sx={{ textAlign: "start", width: index === 2 ? "15%" : "25%", mr: 2 }} key={index}>
        <SuiTypography
          fontSize="14px"
          fontWeight="bold"
          color="info"
          sx={{ pt: 2, pb: 1, ml: index === 1 ? 2 : 0 }}
          display={
            typeData === "create"
              ? ""
              : index === 2
              ? DetailData.ContractList === undefined
                ? "none"
                : DetailData.ContractList && !DetailData.ContractList[0]
                ? "none"
                : ""
              : ""
          }
        >
          {row}
        </SuiTypography>
        {index === 0 ? (
          <SuiBox sx={{ width: "100%", ml: "auto" }}>
            {DetailData.manager.length > 0 ? (
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  color: "#535353",
                }}
              >
                {DetailData.manager[0].name} {DetailData.manager[0].position}
              </Card>
            ) : (
              ""
            )}
          </SuiBox>
        ) : index === 1 ? (
          <SuiBox display="flex">
            <div className="verticalLine"></div>
            <SuiBox sx={{ width: "100%", ml: "auto" }}>
              <FormControl
                fullWidth
                sx={{ minHeight: 40 }}
                className={
                  DetailData.ContractList === undefined
                    ? "showData-selectbox setting-selectbox-manger svg-disabled"
                    : DetailData.ContractList && !DetailData.ContractList[0]
                    ? "showData-selectbox setting-selectbox-manger svg-disabled"
                    : "showData-selectbox setting-selectbox-manger "
                }
                disabled={DetailData.ContractList === undefined}
                onClick={() => {
                  magerdisabled()
                }}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      sx={{ color: "#535353", textAlign: "center", fontSize: 18, fontWeight: 700 }}
                      className="select-icon cursor"
                      onClick={() => {
                        magerdisabled()
                      }}
                    />
                  )}
                  open={FiltersOpen9}
                  value={editMangerSelect}
                  onChange={(e) => seteditMangerSelect(e.target.value)}
                  className="setting-border-radius"
                >
                  {DetailData.ContractList &&
                    mangaers().map((x, index) => {
                      return (
                        <MenuItem value={x.name} key={index}>
                          {x.name} {x.position}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
        ) : index === 2 && MangerContractData().length > 0 ? (
          <SuiBox sx={{ width: "100%", ml: "auto" }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                textAlign: "center",
                boxShadow: 0,
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                color: "#535353",
              }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#0C65FF",
                  textAlign: "center",
                }}
                onClick={() => openMangerInfoDialog()}
              >
                {MangerContractData().length}건
              </SuiTypography>
            </Card>
          </SuiBox>
        ) : (
          ""
        )}
        <SuiBox sx={{ width: "100%", ml: "auto", display: edit && index === 0 ? "" : "none" }}>
          <FormControl
            fullWidth
            sx={{ minHeight: 40 }}
            className={
              TeamSelect === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => setFiltersOpen10(!FiltersOpen10)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => setFiltersOpen10(!FiltersOpen10)}
                />
              )}
              open={FiltersOpen10}
              value={TeamSelect}
              onChange={(e) => setTeamSelect(e.target.value)}
              className="setting-border-radius"
            >
              {teamListSelects}
            </Select>
          </FormControl>
        </SuiBox>
        <SuiButton
          sx={{
            minHeight: 32,
            height: 32,
            width: 32,
            minWidth: 32,
            p: 1,
            display: edit && index === 1 ? "" : "none",
          }}
          color="primary"
          onClick={() => addFilesBtn()}
        >
          <AiOutlinePlus size="20" />
        </SuiButton>
      </SuiBox>
    )
  })
  const [test, settest] = useState("")
  const [idx, setidx] = useState("")
  const [stepidx, setstepidx] = useState(0)
  const [updateinputvalue, setupdateinputvalue] = useState("")
  const updateInputValue = (data, e, index) => {
    setupdateinputvalue(e.target.value)
  }
  const eidt_Significant = (content, id, e, index) => {
    setstepidx(1)
    setidx(index)
    setupdateinputvalue(content)
    settest(content)
  }
  const eidt_finish_Significant = (row, id, event, i) => {
    let updateSignificants = Significants
    for (var i = 0; i < updateSignificants.length; i++) {
      if (updateSignificants[i] === row) {
        updateSignificants[i].content = updateinputvalue
        if (row.id) {
          setUpdateData([...UpdateData, row])
        }
      }
    }

    setcreateContractShow(true)
    setstepidx(0)
    setidx("")
    settest("")
  }
  const updateCancle = () => {
    setstepidx(0)
  }
  const [DeleteId, setDeleteId] = useState([])
  const [UpdateData, setUpdateData] = useState([])
  const [DeletecurrentId, setDeletecurrentId] = useState("")
  const [Deleterow, setDeleterow] = useState([])
  const closeSignificant_dialog = () => {
    if (DeletecurrentId) {
      setDeleteId([...DeleteId.filter((y) => y.id === DeletecurrentId)])
    }
    setDeletecurrentId("")
    setopenSignificantDelete_Dialog(false)
  }
  const Delete_SignificantData = () => {
    setSignificants([...Significants.filter((y) => y !== Deleterow)])
    setcreateContractShow(true)
    setopenSignificantDelete_Dialog(false)
  }
  const delete_Significant = (id, row) => {
    setopenSignificantDelete_Dialog(true)

    setDeletecurrentId(id)
    if (DeleteId.indexOf(id) === -1 && id) {
      setDeleteId([...DeleteId, id])
    }
    setDeleterow(row)

    setstepidx(0)
    setidx("")
    settest("")
  }
  const [MaxSignificant, setMaxSignificant] = useState(4)
  const significant = () => {
    let data = Significants
    data.sort(function (a, b) {
      return new Date(b.updated_at) - new Date(a.updated_at)
    })

    return (
      <SuiBox
        // display="flex"
        alignItems="center"
        sx={{
          mt: 2,
        }}
        className="significants-container"
      >
        {data.map((row, index) => {
          let dt = new Date(row.updated_at)
          return index <= MaxSignificant ? (
            <SuiBox
              key={index}
              display="flex"
              sx={{
                mb: 3,
              }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  mt: 0.5,
                }}
                style={{ backgroundColor: "transparent" }}
                src={row.userProfile ? process.env.REACT_APP_BACKEND_URL + row.userProfile : ""}
              />

              <Card
                key={index}
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "start",
                  fontSize: 18,
                  fontWeight: 700,
                  p: 2,
                  ml: 1,
                }}
              >
                <SuiBox display="flex">
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="light"
                    sx={{ color: "#535353", width: "100%" }}
                  >
                    <span style={{ color: "#0C65FF" }}>
                      {row.userDepartment} {row.userName} {row.userPosition}
                    </span>
                    •{" "}
                    {dt.toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                    })}
                  </SuiTypography>
                  {typeData === "update" && row.outsourcingId === myData.id && stepidx === 0 && (
                    <>
                      <SuiButton
                        className="only-text-btn"
                        sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                        color="info2"
                        variant="text"
                        onClick={(event) => {
                          eidt_Significant(row.content, row.id, event, index)
                        }}
                      >
                        수정
                      </SuiButton>
                      <SuiButton
                        className="only-text-btn"
                        sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                        color="error"
                        variant="text"
                        onClick={() => {
                          delete_Significant(row.id, row)
                        }}
                      >
                        삭제
                      </SuiButton>
                    </>
                  )}
                  {typeData === "update" &&
                    row.outsourcingId === myData.id &&
                    stepidx === 1 &&
                    idx === index && (
                      <>
                        <SuiButton
                          className="only-text-btn"
                          sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                          color="info2"
                          variant="text"
                          onClick={(event) => {
                            eidt_finish_Significant(row, row.id, event, index)
                          }}
                        >
                          완료
                        </SuiButton>
                        <SuiButton
                          className="only-text-btn"
                          sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                          color="error"
                          variant="text"
                          onClick={() => {
                            updateCancle()
                          }}
                        >
                          취소
                        </SuiButton>
                      </>
                    )}
                </SuiBox>
                {row.outsourcingId === myData.id && stepidx === 1 && idx === index ? (
                  <SuiInput
                    type="textfield"
                    className="significantInput"
                    id={`${index}`}
                    sx={{ mt: 1, height: 100 }}
                    placeholder="내용을 입력해 주세요."
                    defaultValue={test}
                    onChange={(e) => updateInputValue(index, e, index)}
                  />
                ) : (
                  <SuiTypography fontSize="16px" fontWeight="medium" sx={{ color: "#535353" }}>
                    {row.content}
                  </SuiTypography>
                )}
              </Card>
            </SuiBox>
          ) : (
            ""
          )
        })}
        {MaxSignificant < data.length - 1 ? (
          <SuiBox
            sx={{ pl: 3, mt: "40px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => setMaxSignificant(MaxSignificant + 5)}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }
  const referrerData = () => {
    let list = {
      경영지원실: 0,
      콘텐츠사업본부: 0,
      전략기획팀: 0,
      플랫폼사업팀: 0,
      IP사업팀: 0,
      인터랙션디자인팀: 0,
      서비스개발팀: 0,
      서비스운영팀: 0,
    }
    let list2 = {
      경영지원실: 0,
      콘텐츠사업본부: 0,
      전략기획팀: 0,
      플랫폼사업팀: 0,
      IP사업팀: 0,
      인터랙션디자인팀: 0,
      서비스개발팀: 0,
      서비스운영팀: 0,
    }

    for (var i = 0; i < usersDatas.length; i++) {
      if ("콘텐츠사업본부" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["콘텐츠사업본부"] = parseInt(list["콘텐츠사업본부"]) + 1
      } else if ("전략기획팀" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["전략기획팀"] = parseInt(list["전략기획팀"]) + 1
      } else if ("IP사업팀" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["IP사업팀"] = parseInt(list["IP사업팀"]) + 1
      } else if (
        "인터랙션디자인팀" === usersDatas[i].department &&
        usersDatas[i].id !== myData.id
      ) {
        list["인터랙션디자인팀"] = parseInt(list["인터랙션디자인팀"]) + 1
      } else if ("서비스개발팀" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["서비스개발팀"] = parseInt(list["서비스개발팀"]) + 1
      } else if ("경영지원실" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["경영지원실"] = parseInt(list["경영지원실"]) + 1
      } else if ("플랫폼사업팀" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["플랫폼사업팀"] = parseInt(list["플랫폼사업팀"]) + 1
      } else if ("서비스운영팀" === usersDatas[i].department && usersDatas[i].id !== myData.id) {
        list["서비스운영팀"] = parseInt(list["서비스운영팀"]) + 1
      }
    }
    list[myData.department] = parseInt(list[myData.department]) + 1
    for (var i = 0; i < referrerIds.length; i++) {
      if ("콘텐츠사업본부" === referrerIds[i].department) {
        list2["콘텐츠사업본부"] = parseInt(list2["콘텐츠사업본부"]) + 1
      } else if ("전략기획팀" === referrerIds[i].department) {
        list2["전략기획팀"] = parseInt(list2["전략기획팀"]) + 1
      } else if ("IP사업팀" === referrerIds[i].department) {
        list2["IP사업팀"] = parseInt(list2["IP사업팀"]) + 1
      } else if ("인터랙션디자인팀" === referrerIds[i].department) {
        list2["인터랙션디자인팀"] = parseInt(list2["인터랙션디자인팀"]) + 1
      } else if ("서비스개발팀" === referrerIds[i].department) {
        list2["서비스개발팀"] = parseInt(list2["서비스개발팀"]) + 1
      } else if ("경영지원실" === referrerIds[i].department) {
        list2["경영지원실"] = parseInt(list2["경영지원실"]) + 1
      } else if ("서비스운영팀" === referrerIds[i].department) {
        list2["서비스운영팀"] = parseInt(list2["서비스운영팀"]) + 1
      } else if ("플랫폼사업팀" === referrerIds[i].department) {
        list2["플랫폼사업팀"] = parseInt(list2["플랫폼사업팀"]) + 1
      }
    }

    let Rereferrer = JSON.parse(JSON.stringify(referrerIds))
    if (list["콘텐츠사업본부"] === list2["콘텐츠사업본부"] && list2["콘텐츠사업본부"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "콘텐츠사업본부")
      let x = {
        name: "콘텐츠사업본부",
      }
      Rereferrer.push(x)
    }

    if (list["전략기획팀"] === list2["전략기획팀"] && list2["전략기획팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "전략기획팀")
      let x = {
        name: "전략기획팀",
      }
      Rereferrer.push(x)
    }
    if (list["IP사업팀"] === list2["IP사업팀"] && list2["IP사업팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "IP사업팀")
      let x = {
        name: "IP사업팀",
      }
      Rereferrer.push(x)
    }
    if (list["인터랙션디자인팀"] === list2["인터랙션디자인팀"] && list2["인터랙션디자인팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "인터랙션디자인팀")
      let x = {
        name: "인터랙션디자인팀",
      }
      Rereferrer.push(x)
    }

    if (list["서비스개발팀"] === list2["서비스개발팀"] && list2["서비스개발팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "서비스개발팀")
      let x = {
        name: "서비스개발팀",
      }
      Rereferrer.push(x)
    }
    if (list["경영지원실"] === list2["경영지원실"] && list2["경영지원실"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "경영지원실")
      let x = {
        name: "경영지원실",
      }
      Rereferrer.push(x)
    }
    if (list["서비스운영팀"] === list2["서비스운영팀"] && list2["서비스운영팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "서비스운영팀")
      let x = {
        name: "서비스운영팀",
      }
      Rereferrer.push(x)
    }
    if (list["플랫폼사업팀"] === list2["플랫폼사업팀"] && list2["플랫폼사업팀"] !== 0) {
      Rereferrer = Rereferrer.filter((x) => x.department !== "플랫폼사업팀")
      let x = {
        name: "플랫폼사업팀",
      }
      Rereferrer.push(x)
    }
    return Rereferrer
  }
  const ListCard = () => {
    return (
      <SuiBox>
        <SuiBox display="flex">{listsShow}</SuiBox>
        <SuiBox display="flex" sx={{ mt: 1 }}>
          <SuiTypography fontSize="14px" fontWeight="bold" color="info" sx={{ pt: 2, pb: 1 }}>
            참조자:
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" sx={{ mt: 1 }}>
          {typeData !== "update" && typeData !== "create" ? (
            ""
          ) : (
            <SuiButton
              sx={{
                minHeight: 32,
                height: 32,
                width: 32,
                minWidth: 32,
                p: 1,
                mr: referrerIds.length === 0 ? 0 : 1,
              }}
              color="primary"
              onClick={() => setreferrerDialog(true)}
            >
              <AiOutlinePlus size="20" />
            </SuiButton>
          )}
          <Stack display="block" direction="row" spacing={1} sx={{ textAlign: "left" }}>
            {referrerData().map((row, index) => {
              return typeData !== "update" && typeData !== "create" ? (
                <Chip
                  sx={{
                    backgroundColor: "#F7F9FB",
                    height: 32,
                  }}
                  style={{ margin: "0px 5px 5px 0px" }}
                  key={index}
                  label={row.name}
                />
              ) : (
                <Chip
                  sx={{
                    backgroundColor: "#F7F9FB",
                    height: 32,
                  }}
                  key={index}
                  label={row.name}
                  style={{ margin: "0px 5px 5px 0px" }}
                  onDelete={() => handlereferrerIdsDelete(row)}
                  deleteIcon={<AiOutlineCloseCircle className="blackColor" />}
                />
              )
            })}
          </Stack>
        </SuiBox>
      </SuiBox>
    )
  }
  // dataExport
  useEffect(() => {
    SignificantsDatasExport(Significants)
  }, [Significants])
  useEffect(() => {
    AttachmentDatasExport(AttachmentDatas)
  }, [AttachmentDatas])
  useEffect(() => {
    MeetingDatasExport(MeetingDatas)
  }, [MeetingDatas])
  useEffect(() => {
    referrerIdsExport(referrerIds)
  }, [referrerIds])
  useEffect(() => {
    inputListsExport(InputLists)
  }, [InputLists])
  const [alert_Dialog, setalert_Dialog] = useState({
    open: false,
    title: "",
    content: "",
  })
  const alertDialog_close = () => {
    let data = { ...alert_Dialog }
    data.open = false
    setalert_Dialog(data)
  }
  const [updateChangeRequestActive] = useMutation(updateChangeRequest, {
    onError() {
      alert("이관 신청 취소가 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const chagne_cancel_dailog_Active = () => {
    dispatch(loading_Apply(true))
    updateChangeRequestActive({
      variables: {
        id: ChangeRequests[0].id,
        status: "취소",
      },
    }).then((res) => {
      setchagne_cancel_dailog(false)
      let data = { ...alert_Dialog }
      data.open = true
      data.title = "이관 신청 취소 완료"
      data.content = `신청이 취소되었습니다.`
      setalert_Dialog(data)
      firstChangeRequestsActive(DetailData.id)
      dispatch(loading_Apply(false))
    })
  }
  const [open_ChagneDialog, setopen_ChagneDialog] = useState(false)
  const [change_data, setchange_data] = useState([])
  const [change_content, setchange_content] = useState("")
  const close_ChagneDialog = () => {
    setopen_ChagneDialog(false)
  }
  const change_active = (changeList) => {
    dispatch(loading_Apply(true))
    const content = {
      request: {
        id: myData.id,
        department: myData.department,
        name: myData.name,
        position: myData.position,
        profile: myData.profile ? myData.profile.url : null,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
      },
      companiesIds: [],
      writersIds: [],
      contractsIds: [],
      outsourcingsIds: [DetailData.id],
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
      type: "my_outsourcing",
      name: "",
      users: DetailData.user,
      contract_document: DetailData.contracts,
      targets_name: [DetailData.nickname],
      targets: [DetailData],
    }
    const body = {
      type: `my_outsourcing Change`,
      arrayContent: content,
      users_permissions_user: myData.id,
      status: "신청",
      outsourcingId: DetailData.id,
    }
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then((res) => {
        usersDatas
          .filter((x) => x.role.id === 1)
          .forEach((user, user_idex) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[1].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "담당자 변경 신청",
                    type: "Change",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      title: myData.name,
                      name: myData.name,
                      targetName: DetailData.nickname,
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "3",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                    },
                  },
                })
              }
            }
            if (user_idex === usersDatas.length - 1) {
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "이관 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 이관이 완료됩니다.`
              setalert_Dialog(data)
              firstChangeRequestsActive(DetailData.id)
              dispatch(loading_Apply(false))
            }
          })
        dispatch(loading_Apply(false))
      })

    setopen_ChagneDialog(false)
  }

  const open_cancle_alert = (type, row) => {
    let request = {}
    if (typeData === "detail") {
      // 수정 버튼 클릭 전  - 기존 확인 팝업 생성
      if (type === "change") {
        return openChangeDialog(row)
      } else {
        return openDeleteDialog(row)
      }
    }
    if (type === "change") {
      // 이관 신청 취소하기
      if (DetailData.change_requests.length > 0) {
        if (
          DetailData.change_requests.filter(
            (x) => x.arrayContent.companiesIds.indexOf(row[0][0].id) > -1
          ).length > 0
        )
          request = DetailData.change_requests.filter(
            (x) => x.arrayContent.companiesIds.indexOf(row[0][0].id) > -1
          )[0]
      }

      // 신청인이 아닐 시 기존의 신청 확인 팝업
      if (
        request.arrayContent.request.id !== myData.id &&
        myData.role.name !== "Authenticated" &&
        myData.role.name !== "gold" &&
        myData.role.name !== "silver"
      ) {
        return openChangeDialog(row)
      }

      let data = { ...change_cancle_alert }
      data.open = true
      data.title = "이관신청 취소"
      data.content = "관리자에게 이관 신청 중입니다. 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.data = request
      data.CanBtnText = "돌아가기"
      setchange_cancle_alert(data)
    } else if (type === "delete") {
      let notRead = row[0][0].requests.filter((e) => {
        return e.read === false
      })
      if (notRead.length === 0) {
        return false
      } else {
        notRead.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })

        if (notRead[0].status.indexOf("심사중") > -1) {
          request = notRead[0]
        }
      }

      // 신청인이 아닐 시 기존의 신청 확인 팝업
      if (
        !request.content ||
        (request.content.id !== myData.id &&
          myData.role.name !== "Authenticated" &&
          myData.role.name !== "gold" &&
          myData.role.name !== "silver")
      ) {
        return openChangeDialog(row)
      }

      let data = { ...delete_cancle_alert }
      data.open = true
      data.title = "삭제신청 취소"
      data.content = "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.CanBtnText = "돌아가기"
      setdelete_cancle_alert(data)
    }
  }
  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
  }
  const delete_cancle_active = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제신청 취소 완료"
      data.content = "신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"

      setdelete_cancle_alert(data)
      setcreateContractShow(true)

      updateOutsourcingActive({
        variables: {
          id: DetailData.id,
        },
      })

      dispatch(loading_Apply(false))
    })
  }
  // 이관신청 취소 alert
  const [change_cancle_alert, setchange_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
    data: {},
  })
  const change_cancle_alert_close = () => {
    let data = { ...change_cancle_alert }
    data.open = false
    setchange_cancle_alert(data)
  }
  const change_cancle_active = () => {
    const content = { ...change_cancle_alert.data.arrayContent }
    content["read"] = true
    content["status"] = `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`
    content["decisionDate"] = new Date()
    const body = {
      arrayContent: content,
      status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
      decisionDate: new Date(),
    }
    dispatch(loading_Apply(true))
    axios
      .put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + change_cancle_alert.id, body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then(() => {
        let data = { ...change_cancle_alert }
        data.title = "이관신청 취소 완료"
        data.content = "신청이 취소되었습니다."
        data.twoBtn = false
        data.CanBtnText = "확인"

        setchange_cancle_alert(data)
        setcreateContractShow(true)

        firstChangeRequestsActive(DetailData.id)

        updateOutsourcingActive({
          variables: {
            id: DetailData.id,
          },
        })

        dispatch(loading_Apply(false))
      })
  }
  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // {...other}
    >
      {value === index && (
        <SuiBox display="flex" justifyContent="center" bgColor="#F1F1F5" minHeight="85vh" px={14}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                {children}
              </SuiTypography>
              <SuiTypography
                sx={{ mt: 2, fontWeight: 500, fontSize: 18, mb: "40px" }}
                color="subTitle"
                variant="h6"
                component="div"
              >
                {subText()}
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
          <DeleteDialog
            openDialog={Delete_Dialog}
            closeDialog={() => setDelete_Dialog(false)}
            MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
            Content={Delete_Content}
            CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
            CreBtnText={"삭제신청"}
            CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
            AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
            requestExplainData={setrequestExplainData}
            requestExplainShowData={requestExplainData}
            Active={() => Delete_All()}
            type={Delete_Dialog_type}
          />
          <MettingDeleteDialog
            openDialog={openMettingDeleteDialog}
            closeDialog={() => {
              closeDialog()
              setcreateContractShow(true)
            }}
            ContractDeleteData={MettingDeleteData}
          />
          <SuiDialog
            openDialog={openMeettingDelete_Dialog}
            closeDialog={() => setopenMeettingDelete_Dialog(false)}
            MainTitle={"작업이력 삭제하기"}
            Content={"등록하신 작업이력을 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_MeettingData()}
          />
          <SuiDialog
            openDialog={deleteHistoryRequest_calcel_dialog}
            closeDialog={() => setdeleteHistoryRequest_calcel_dialog(false)}
            MainTitle={"삭제 신청 취소"}
            Content={`관리자에게 삭제 신청 중입니다.\n취소하시겠습니까?`}
            CanBtnText={"돌아가기"}
            CreBtnText={"취소하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => setdeleteHistoryRequest_calcel_active()}
          />
          <SuiDialog
            openDialog={deleteHistoryRequest_calcel_dialog_info}
            closeDialog={() => setdeleteHistoryRequest_calcel_dialog_info(false)}
            MainTitle={"삭제 신청 취소 완료"}
            Content={"신청이 취소되었습니다."}
            CreBtnText={"확인"}
            CreBtnColor="info2"
            AlertType="oneBtn"
            Active={() => setdeleteHistoryRequest_calcel_dialog_info(false)}
          />
          <SuiDialog
            openDialog={openContractDelete_Dialog}
            closeDialog={() => setopenContractDelete_Dialog(false)}
            MainTitle={"계약서 삭제하기"}
            Content={"등록하신 계약서를 삭제하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제하기"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_ContractData()}
          />
          <SuiDialog
            openDialog={openDelete_Dialog}
            closeDialog={() => setopenDelete_Dialog(false)}
            MainTitle={"첨부 파일 삭제"}
            Content={"첨부파일을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_AttachmentData()}
          />
          <SuiDialog
            openDialog={openSignificantDelete_Dialog}
            closeDialog={() => closeSignificant_dialog()}
            MainTitle={"특이사항 삭제"}
            Content={"특이사항을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_SignificantData()}
          />
          <SuiDialog
            openDialog={openInfo_Dialog}
            closeDialog={() => setopenInfo_Dialog(false)}
            MainTitle={"정보 입력 오류"}
            Content={"올바른 외주계약 상세 정보를 입력해 주세요."}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openDeleteInfo_Dialog}
            closeDialog={() => setopenDeleteInfo_Dialog(false)}
            MainTitle={"외주계약 정보 삭제 심사중"}
            Content={"외주계약 정보 삭제 심사중 입니다. <br>심사가 끝날때 까지 기다려 주세요."}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openDeleteJudging_Dialog}
            closeDialog={() => setopenDeleteJudging_Dialog(false)}
            MainTitle={"삭제 신청 심사중"}
            Content={DeleteJudging_Content}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openChangeJudging_Dialog}
            closeDialog={() => setopenChangeJudging_Dialog(false)}
            MainTitle={"이관 신청 심사중"}
            Content={ChangeJudging_Content}
            CanBtnText={"확인"}
          />
          <ChangeDialog
            openDialog={open_ChagneDialog}
            closeDialog={close_ChagneDialog}
            change_data={change_data}
            content={change_content}
            usersDatas={usersDatas}
            change_active={change_active}
          />
          <SuiDialog
            openDialog={alert_Dialog.open}
            className={"admin_setting_dialog"}
            MainTitle={alert_Dialog.title}
            Content={alert_Dialog.content}
            CanBtnText={"닫기"}
            CreBtnColor="info2"
            AlertType="oneBtn"
            closeDialog={() => alertDialog_close()}
          />
          <SuiDialog
            openDialog={chagne_cancel_dailog}
            closeDialog={() => setchagne_cancel_dailog(false)}
            MainTitle={"이관 신청 취소"}
            Content={`관리자에게 이관 신청 중입니다.
            취소하시겠습니까?`}
            CanBtnText={"돌아가기"}
            CreBtnText={"취소하기"}
            CreBtnColor="primary"
            AlertType="twoBtn"
            Active={() => chagne_cancel_dailog_Active()}
          />
          <SuiDialog
            openDialog={delete_cancle_alert.open}
            className={"admin_setting_dialog"}
            MainTitle={delete_cancle_alert.title}
            Content={delete_cancle_alert.content}
            CanBtnText={delete_cancle_alert.CanBtnText}
            AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
            CreBtnColor={"error"}
            CreBtnText={"취소하기"}
            closeDialog={() => delete_cancle_alert_close()}
            Active={() => delete_cancle_active()}
          />
          <SuiDialog
            openDialog={change_cancle_alert.open}
            className={"admin_setting_dialog"}
            MainTitle={change_cancle_alert.title}
            Content={change_cancle_alert.content}
            CanBtnText={change_cancle_alert.CanBtnText}
            AlertType={change_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
            CreBtnColor={"error"}
            CreBtnText={"취소하기"}
            closeDialog={() => change_cancle_alert_close()}
            Active={() => change_cancle_active()}
          />
        </SuiBox>
      )}
      {authCheck() ? FooterForm() : ""}
    </div>
  )
}
TabPanel.defaultProps = {
  edit: false,
  editId: null,
  type: "create",
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  edit: PropTypes.bool,
  type: PropTypes.string,
  inputListsExport: PropTypes.func,
  referrerIdsExport: PropTypes.func,
  AttachmentDatasExport: PropTypes.func,
  MeetingDatasExport: PropTypes.func,
  SignificantsDatasExport: PropTypes.func,
  inputListsImport: PropTypes.array,
  editId: PropTypes.string,
}
export default TabPanel
