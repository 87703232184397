import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"

import { MdClose } from "react-icons/md"

function ContractChangeTitleInfoDialog({ openDialog, closeDialog, ContarctData }) {
  const [open, setOpen] = useState(openDialog)
  const [TitleChange, setTitleChange] = useState([])
  const [TitleChangeNum, setTitleChangeNum] = useState("")
  useEffect(() => {
    setOpen(openDialog)
    if (ContarctData.length > 0) {
      let info = []
      ContarctData.forEach((el, index) => {
        let data = {}
        data.date = el.date
        data.titleName = el.backUpcontent
        info.push(data)

        // let data = {}
        // if (el.content.indexOf("작품명") > -1) {
        //   const e = el.content.split("<strong>")
        //   const str = e[1].split("</strong>")
        //   data.titleName = str[0]
        //   data.date = el.date
        //   info.push(data)
        // }
      })
      setTitleChangeNum(info.length)
      let test = ""
      info.forEach((e) => {
        test = test + e.titleName + "_" + e.date + "\n"
      })
      setTitleChange(test)
    } else {
      let test = "변경이력 없음"
      setTitleChangeNum(0)
      setTitleChange(test)
    }
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const stepActive = () => {
    closeDialog()
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                작품명 변경 이력
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                작품명 변경 이력을 확인하실 수 있습니다.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4 }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              변경 건 수 :
            </SuiTypography>
          </SuiBox>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              mb: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: 2 }}>
                {TitleChangeNum}회
              </SuiTypography>
            </SuiBox>
          </Card>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              변경 이력:
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              disabled
              multiline
              rows={8}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              value={TitleChange}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              color="info2"
              onClick={() => stepActive()}
              sx={{
                ml: "auto",
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              확인
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ContractChangeTitleInfoDialog.defaultProps = {
  openDialog: false,
}

ContractChangeTitleInfoDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  ContarctData: PropTypes.array,
}
export default ContractChangeTitleInfoDialog
