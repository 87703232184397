import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"

import { MdClose } from "react-icons/md"

function DeleteDenyDialog({ openDialog, closeDialog, denyReason }) {
  const [infos, setinfos] = useState("")
  const [open, setOpen] = useState(openDialog)
  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const stepActive = () => {
    closeDialog()
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 400 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                계약서 삭제 심사 거절
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ mt: 1, mb: 0, px: 4 }}
              justifyContent="center"
            >
              <SuiTypography
                style={{ fontSize: "16px", fontWeight: "medium", textAlign: "center" }}
                color="subTitle"
              >
                계약서 삭제 신청 심사가 거절되었습니다.
                <br />
                아래의 거절 사유를 확인해보세요.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4 }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              거절 사유 :
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              disabled
              multiline
              rows={8}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              value={denyReason}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              color="info2"
              onClick={() => stepActive()}
              sx={{
                ml: "auto",
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              확인
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DeleteDenyDialog.defaultProps = {
  openDialog: false,
}

DeleteDenyDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  denyReason: PropTypes.string,
}
export default DeleteDenyDialog
