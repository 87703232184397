import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Avatar from "@mui/material/Avatar"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Tooltip from "@mui/material/Tooltip"

import SettingDialog from "layouts/setting"
import SuiDialog from "components/SuiDialog"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import RejectDialog from "./rejectDialog"

// 다이어로그
import WriterDialog from "../writer"
import DistributorDialog from "../distributor"
import OutsourcingDialog from "../outsourcing"
import EventDialog from "../eventsManagement/items/event"

// 아이콘
import { MdKeyboardArrowLeft, MdArrowForwardIos } from "react-icons/md"
import { FiBell, FiSearch } from "react-icons/fi"
import { GoChevronDown, GoChevronUp } from "react-icons/go"
import { CgProfile } from "react-icons/cg"
import { MdClose } from "react-icons/md"
import { RiBook2Line } from "react-icons/ri"
import { BiCalendarEvent } from "react-icons/bi"
import { BiBuildings, BiUserPin, BiPencil, BiCalendarCheck } from "react-icons/bi"
import bxTrash from "assets/images/small-logos/bx-trash.svg"

// API
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import {
  me,
  writers,
  alerts,
  contractsSearch,
  companies,
  recents,
  users,
  users_need,
  outsourcings,
  contractDocuments,
  companyContracts,
  events,
  outsourcingContracts,
} from "apollo/query"
import {
  createRequest,
  updateRequest,
  updateAlert,
  createSendEmail,
  createAlert,
  updateUserMu,
} from "apollo/mutation"

// Reducers
import { useSelector, useDispatch } from "react-redux"
import { myData_Apply, loading_Apply } from "../../reducers/store"

export default function PrimarySearchAppBar(loadingRef) {
  const navigate = useNavigate()
  const [ProfileMenuOpen, setProfileMenuOpen] = useState(false)
  const [AlertMenuOpen, setAlertMenuOpen] = useState(false)
  const [openCreateList, setopenCreateList] = useState(false)
  const [createStep, setcreateStep] = useState(0)
  const [firstStep, setfirstStep] = useState("")
  const [firstStep_set, setfirstStep_set] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchActive, setsearchActive] = useState(false)
  const [writerDialog, setwriterDialog] = useState(false)
  const [distributorDialog, setDistributorDialog] = useState(false)
  const [openDeleteAlert_Dialog, setopenDeleteAlert_Dialog] = useState(false)
  const [editId, seteditId] = useState(null)
  const [editData, seteditData] = useState({ writername: "" })
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [editDistributorData, seteditDistributorData] = useState({ writername: "" })
  const [typeDistributor, settypeDistributor] = useState("create")
  const [tabActive, settabActive] = useState(0)
  const [type, settype] = useState("create")
  const [writerList, setwriterList] = useState([])
  const [requestExplain, setrequestExplain] = useState("")
  const dispatch = useDispatch()
  const [changeRole, setchangeRole] = useState("")
  const [openrequestDialog, setopenrequestDialog] = useState(false)
  const [settingDialog, setSettingDialog] = useState(false)
  const [reject_Dialog, setreject_Dialog] = useState(false)
  const [requestExplainData, setrequestExplainData] = useState("")
  const [outsourcingDialog, setoutsourcingDialog] = useState(false)
  const [editOutsourcingId, seteditOutsourcingId] = useState(null)
  const [editOutsourcingData, seteditOutsourcingData] = useState({ writername: "" })
  const [typeOutsourcing, settypeOutsourcing] = useState("create")
  const [edit_event_id, set_edit_event_id] = useState(null)
  const [edit_event_data, set_edit_event_data] = useState({ writersName: "" })
  const [type_event, set_type_event] = useState("create")
  const [eventDialog, seteventDialog] = useState(false)

  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  let roleList = ["슈퍼어드민", "골드", "실버", "브론즈"]
  const [AlertDatas, setAlertDatas] = useState([])
  const [alertsStart, setalertsStart] = useState(0)
  const [Recents, setRecents] = useState([])
  const { myData } = useSelector((state) => state.store)
  const client = useApolloClient()
  const {} = useQuery(me, {
    fetchPolicy: "no-cache",
    onError(err) {
      navigate("/")
      sessionStorage.removeItem("joara_token")
    },
    onCompleted: (data) => {
      dispatch(myData_Apply(data.me))
    },
  })
  useEffect(() => {
    if (myData.requests.length > 0) {
      if (myData.requests.filter((x) => !x.read && x.type === "levelUp")[0]) {
        if (myData.requests.filter((x) => !x.read && x.type === "levelUp")[0].status === "승인") {
          updateRequestActive({
            fetchPolicy: "no-cache",
            variables: {
              id: myData.requests.filter((x) => !x.read && x.type === "levelUp")[0].id,
              read: true,
            },
          }).then(() => {
            setInfoAlert({
              dialog: true,
              title: "회원 등급 변경 신청 승인",
              content: "신청하신 회원 등급 변경이 완료되었습니다.",
              CanBtnText: "확인",
            })
          })
        } else if (
          myData.requests.filter((x) => !x.read && x.type === "levelUp")[0].status === "거절"
        ) {
          updateRequestActive({
            fetchPolicy: "no-cache",
            variables: {
              id: myData.requests.filter((x) => !x.read && x.type === "levelUp")[0].id,
              read: true,
            },
          }).then(() => {
            setrequestExplainData(
              myData.requests.filter((x) => !x.read && x.type === "levelUp")[0].declining
            )
            setreject_Dialog(true)
          })
        }
      }
    }
  }, [myData])
  const {} = useQuery(recents, {
    fetchPolicy: "no-cache",
    variables: {
      users_permissions_user: myData.id,
    },

    onCompleted: (data) => {
      setRecents(data.recents)
    },
  })
  const [recentsActive] = useLazyQuery(recents, {
    fetchPolicy: "no-cache",
    variables: {
      users_permissions_user: myData.id,
    },

    onCompleted: (data) => {
      setRecents(data.recents)
    },
  })
  const {} = useQuery(alerts, {
    variables: {
      limit: 100,
      start: alertsStart,
      users_permissions_user: myData.id,
      show: true,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setAlertDatas(data.alerts)
    },
  })

  const [alertsActive] = useLazyQuery(alerts, {})
  const [updateUserActive] = useMutation(updateUserMu, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const logOut = () => {
    navigate("/")
    sessionStorage.removeItem("joara_token")
    dispatch(
      myData_Apply({
        id: "",
        username: "",
        email: "",
        department: "",
        position: "",
        phone: "",
        status: "",
        name: "",
        profile: {
          url: "",
        },
        writers: [],
        companies: [],
        role: {
          name: "",
        },
        requests: [],
      })
    )
  }
  const to_myPage = () => {
    navigate("/MyPage")
  }
  const editProfileBtn = () => {
    setSettingDialog(true)
  }
  const [updateAlertActive] = useMutation(updateAlert, {
    fetchPolicy: "no-cache",
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    fetchPolicy: "no-cache",
  })
  const [createRequestActive] = useMutation(createRequest, {
    fetchPolicy: "no-cache",
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [outsourcingContractsActive] = useLazyQuery(outsourcingContracts, {
    fetchPolicy: "network-only",
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  const AlertOpen = () => {
    let array = [...AlertDatas]
    array.forEach((element) => {
      if (element.id) {
        if (!element.read) {
          updateAlertActive({
            variables: {
              id: element.id,
              read: true,
            },
          })
        }
      }
      element.read = true
    })
    setAlertDatas(array)
    setAlertMenuOpen(!AlertMenuOpen)
  }
  const createLevelUpRequest = () => {
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      profile: myData.profile,
      suggestRole: changeRole,
      requestExplain: requestExplain,
    }
    createRequestActive({
      variables: {
        status: "공개",
        type: "levelUp",
        content: request,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      dispatch(loading_Apply(false))
      setInfoAlert({
        dialog: true,
        title: "회원 등급 변경 신청 완료",
        content: "회원 등급 변경 신청이 완료되었습니다. 관리자 승인시, 회원 등급이 변경됩니다.",
        CanBtnText: "확인",
      })
      myData.requests.push({ status: "공개" })
      client
        .query({
          query: users_need,
          variables: {
            role: 1,
            status: "근무",
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          res.data.users.forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[0].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "회원 등급 변경 신청",
                    type: "levelUp",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      title: myData.name,
                      suggestRole: changeRole,
                      declining: requestExplain,
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "2",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      suggestRole: changeRole,
                      declining: requestExplain,
                    },
                  },
                })
              }
            }
          })
          setopenrequestDialog(false)
        })
    })
  }
  const recentOpen = (row) => {
    if (row.type === "Writer") {
      setwriterDialog(true)
      seteditId(row.srcId)
      let data = {
        writername: row.name,
      }
      seteditData(data)
      settype("detail")
    } else {
      setDistributorDialog(true)
      seteditDistributorId(row.srcId)
      let data = {
        title: row.name,
      }
      seteditDistributorData(data)
      settypeDistributor("detail")
    }
  }
  const roleText = (value) => {
    if (value === "Authenticated") {
      return "슈퍼어드민"
    } else if (value === "gold") {
      return "골드"
    } else if (value === "silver") {
      return "실버"
    } else if (value === "bronze") {
      return "브론즈"
    } else {
      return "오류"
    }
  }
  const backstep = () => {
    if (firstStep_set) {
      setfirstStep("작품 등록")
      setcreateStep(0)
      setfirstStep_set(false)
    } else {
      setsearchNameApply("")
      setwriterList([])
      setcreateStep(createStep - 1)
    }
  }
  const nextStepFirst = () => {
    setSearchName("")
    setwriterList([])
    setfirstStep_set(false)
    if (firstStep === cards[0].title && createStep === 1) {
      setfirstStep_set(true)
      setfirstStep("작가 등록")
      setcreateStep(1)
    } else {
      setcreateStep(1)
      setwriterList([])
      setsearchNameApply("")
    }
    setsearchActive(false)
  }
  const handleProfileMenuOpen = () => {
    setProfileMenuOpen(!ProfileMenuOpen)
  }

  const handlecloseClick = () => {
    setopenCreateList(false)
    setsearchNameApply("")
    setwriterList([])
    setcreateStep(0)
    setfirstStep("")
  }
  const addRef = useRef(null)
  const alertRef = useRef(null)
  const alertMenuRef = useRef(null)
  const profileRef = useRef(null)
  const profileMenuRef = useRef(null)

  function clickEvent(event) {
    if (loadingRef.loadingRef.current && loadingRef.loadingRef.current.contains(event.target)) {
      return
    }
    if (addRef.current && !addRef.current.contains(event.target)) {
      if (openCreateList) {
        setopenCreateList(false)
      }
    }
    if (
      alertRef.current &&
      !alertRef.current.contains(event.target) &&
      alertMenuRef.current &&
      !alertMenuRef.current.contains(event.target)
    ) {
      if (AlertMenuOpen) {
        setAlertMenuOpen(false)
      }
    }
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      if (ProfileMenuOpen) {
        setProfileMenuOpen(false)
      }
    }
  }
  const FilterReload = () => {
    recentsActive()
  }
  function outSideClick() {
    document.addEventListener("mousedown", clickEvent)
    return () => {
      document.addEventListener("mousedown", clickEvent)
    }
  }

  const contract_count = () => {
    let count = 0
    if (myData.contract_documents && myData.contract_documents.length > 0) {
      myData.contract_documents.forEach((el, index) => {
        if (el.contract && el.contract.writer) {
          count++
        }
      })
    }
    return count
  }

  const rejectTitle = (title) => {
    if (title === "회원 등급 변경 거절") return "등급변경신청"
    else if (title === "계약 이관 거절") {
      return "이관신청"
    } else if (title === "회원 탈퇴 신청 거절") {
      return "탈퇴신청"
    } else if (title.indexOf("삭제 거절") > -1) {
      return "삭제신청"
    }
  }
  const showTitle = (title) => {
    if (title === "회원 등급 변경 신청") return "변경신청"
    else if (title.indexOf("삭제 신청") > -1) {
      return "삭제신청"
    } else if (title.indexOf("등록") > -1) {
      return "등록완료"
    } else if (title.indexOf("계약 담당자 변경 신청") > -1) {
      return "계약 이관신청"
    } else if (title.indexOf("담당자 변경 신청") > -1) {
      return "데이터 이관 신청"
    } else if (title.indexOf("이관 신청") > -1) {
      return "계약 이관신청"
    } else {
      return title
    }
  }
  outSideClick()
  const renderAlert = (
    <SuiBox
      display="flex"
      alignItems="center"
      sx={{
        display: AlertMenuOpen ? "" : "none",
        position: "absolute",
        zIndex: 10,
        backgroundColor: "#ffffff",
        left: myData.role.name !== "bronze" ? "-25px" : "-125px",
        mt: 0.7,
        width: 300,
        boxShadow: 2,
        borderRadius: 4,
      }}
      ref={alertMenuRef}
    >
      <Grid container sx={{ maxHeight: 770, overflowY: "auto" }}>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 53, position: "relative" }}
          >
            <SuiTypography style={{ fontSize: "16px", fontWeight: "bold" }}>
              알림 내역
            </SuiTypography>
            <SuiBox
              component="img"
              src={bxTrash}
              left={0}
              sx={{
                verticalAlign: "middle",
                cursor: "pointer",
                position: "absolute",
                top: "22%",
                left: "87%",
              }}
              onClick={() => setopenDeleteAlert_Dialog(true)}
            />
          </SuiBox>
          <Divider sx={{ borderColor: "#EEEEEE" }} />
        </Grid>
        <SuiBox sx={{ maxHeight: 716, overflow: "scroll" }}>
          {AlertDatas.length > 0 ? (
            AlertDatas.map((data, index) => {
              return (
                <Grid item xs={12} sx={{ px: 2, py: 1 }} key={index}>
                  <SuiBox display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: data.content.profile ? "#ffffff" : "#ffffff",
                        boxShadow: 1,
                      }}
                      src={
                        data.content.profile
                          ? process.env.REACT_APP_BACKEND_URL + data.content.profile.url
                          : "test"
                      }
                    />
                    <SuiBox sx={{ ml: 2 }}>
                      {data.title.indexOf("등록안내") > -1 ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[{data.title}]</strong>

                          {`'${data.content.name + " " + data.content.position}'님이 회원님을 '${
                            data.content.targetName
                          }'님의 '${data.content.title}'의 참조자로 등록했습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "작가 계약 이관 신청" ||
                      data.title === "외주계약 이관 신청" ||
                      data.title === "유통사 계약 이관 신청" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          <strong style={{ marginRight: "6px" }}>[계약 이관신청]</strong>{" "}
                          {data.content.name}
                          {data.content.position}
                          님이 {"'"}
                          {data.content.selectData}
                          {"'"}님의 {"'"}
                          {data.content.targetName}
                          {"'"}을 이관신청했습니다.
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title.indexOf("등록_") > -1 ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[{showTitle(data.title)}]</strong>

                          {`'${data.content.targetName}'님을 ${data.title.replace(
                            " 등록_",
                            ""
                          )}로 등록했습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "계약 담당자 변경 신청" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>
                            [{showTitle(data.title)}]
                          </strong>{" "}
                          {data.content.name}
                          {data.content.position}
                          님이 {"'"}
                          {`${data.content.count}'건의 데이터를 이관신청했습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "담당자 변경 신청" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>
                            [{showTitle(data.title)}]
                          </strong>{" "}
                          {data.content.name}
                          {data.content.position}
                          님이 {"'"}
                          {data.content.targetName}
                          {"'"}님의 데이터를 이관신청했습니다.
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "계약삭제 신청 승인" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          <span>
                            <strong style={{ marginRight: "6px" }}>[신청승인]</strong>관리자가
                            회원님이 신청한 {data.content.targetName}
                            {"'"}님의 {"'"}
                            {data.content.selectData}
                            {"'"}를 삭제신청을 승인하였습니다.
                          </span>
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "삭제 신청 승인" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          <span>
                            <strong style={{ marginRight: "6px" }}>[신청승인]</strong>관리자가
                            회원님이 신청한 {"'"}
                            {data.content.targetName}
                            {"'"}를 삭제신청을 승인하였습니다.
                          </span>
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.type === "Delete" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {data.content.selectData ? (
                            <span>
                              <strong style={{ marginRight: "6px" }}>
                                [{showTitle(data.title)}]
                              </strong>{" "}
                              {data.content.name}
                              {data.content.position}
                              님이 {"'"}
                              {data.content.targetName}
                              {"'"}님의 {"'"}
                              {data.content.selectData}
                              {"'"}를 삭제신청했습니다.
                            </span>
                          ) : (
                            <span>
                              <strong style={{ marginRight: "6px" }}>
                                [{showTitle(data.title)}]
                              </strong>{" "}
                              {data.content.name}
                              {data.content.position}
                              님이 {"'"}
                              {data.content.targetName}
                              {"'"}를 삭제신청했습니다.
                            </span>
                          )}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "회원 등급 변경 신청" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>
                            [{showTitle(data.title)}]
                          </strong>{" "}
                          {data.content.name}
                          {data.content.position}
                          님이 회원 등급 변경을 신청했습니다.
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "회원 등급 변경 승인" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[신청승인]</strong> 관리자가
                          등급변경 신청을 승인하였습니다
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "회원 탈퇴 신청" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[탈퇴신청]</strong>
                          {data.content.name} {data.content.position}
                          님이 회원 탈퇴를 신청했습니다.
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title.indexOf("거절") > -1 ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[신청거절]</strong>
                          관리자가 {rejectTitle(data.title)}을 거절하였습니다. 거절사유를
                          확인해주세요.
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.type === "info" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[이관안내]</strong>
                          {data.content.name} {data.content.position}
                          님이 신청한 {"'"}
                          {data.content.count === 1
                            ? `'${
                                data.content.targetName
                                  ? data.content.targetName[0]
                                  : data.content.content.targetName
                              }'님의 ${
                                data.content.selectData && data.content.content.selectData
                                  ? `'${
                                      data.content.selectData
                                        ? data.content.selectData[0]
                                        : data.content.content.selectData
                                    }'을`
                                  : "데이터를"
                              } 이관하였습니다. 마이페이지에서 확인해주세요`
                            : `${data.content.count}'건을 이관하였습니다. 마이페이지에서 확인해주세요.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "계약 이관 승인" && data.type !== "info" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[신청승인]</strong>
                          {data.content.name} {data.content.position}
                          님이 신청한 {"'"}
                          {data.content.count === 1
                            ? `'${
                                data.content.targetName
                                  ? data.content.targetName[0]
                                  : data.content.content.targetName
                              }'님의 '${
                                data.content.selectData
                                  ? data.content.selectData[0]
                                  : data.content.content.selectData
                              }'의 이관신청을 승인하였습니다.`
                            : `${data.content.count}'건의 계약 이관을 승인하였습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title === "담당자 이관 승인" && data.type !== "info" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          color="subTitle"
                        >
                          {/* <span dangerouslySetInnerHTML={{ __html: Content }}></span> */}
                          <strong style={{ marginRight: "6px" }}>[신청승인]</strong>
                          {data.content.name} {data.content.position}
                          님이 신청하신 {"'"}
                          {data.content.count === 1
                            ? `${data.content.targetName}'의 담당자 변경을 승인하였습니다.`
                            : `${data.content.count}'건의 담당자 변경을 승인하였습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      {data.title !== "작가 계약 이관 신청" &&
                      data.title !== "외주계약 이관 신청" &&
                      data.title !== "유통사 계약 이관 신청" &&
                      data.title !== "담당자 이관 승인" &&
                      data.title !== "삭제 신청 승인" &&
                      data.title !== "계약삭제 신청 승인" &&
                      data.title !== "계약 이관 승인" &&
                      data.type !== "info" &&
                      data.title.indexOf("거절") === -1 &&
                      data.title !== "회원 탈퇴 신청" &&
                      data.title !== "회원 등급 변경 신청" &&
                      data.title !== "담당자 변경 신청" &&
                      data.title !== "계약 담당자 변경 신청" &&
                      data.title.indexOf("등록_") === -1 &&
                      data.title.indexOf("등록안내") === -1 &&
                      data.title !== "회원 등급 변경 승인" &&
                      data.type !== "Delete" ? (
                        <SuiTypography
                          style={{ fontSize: "12px", fontWeight: "Regular" }}
                          sx={{ color: "#535353" }}
                        >
                          <strong style={{ marginRight: "6px" }}>[{showTitle(data.title)}]</strong>
                          {data.content.name} {data.content.position}
                          님이{" "}
                          {data.title === "회원 등급 변경 신청" ||
                          data.title === "회원 등급 변경 승인" ||
                          data.title === "회원 등급 변경 거절"
                            ? data.content.suggestRole
                            : data.title === "회원 탈퇴 신청 거절"
                            ? "회원 탈퇴 신청을 거절"
                            : data.title.indexOf("회원 탈퇴") > -1 && data.content.id === myData.id
                            ? ""
                            : data.content.title}{" "}
                          {data.title === "회원 탈퇴 신청 거절"
                            ? "하였습니다."
                            : `${data.title}을 하였습니다.`}
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                      <SuiTypography
                        style={{ fontSize: "10px", fontWeight: "Regular" }}
                        color="text2"
                      >
                        {dayjs(data.created_at).format("YY.MM.DD HH:mm")}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Grid>
              )
            })
          ) : (
            <SuiTypography
              style={{ fontSize: "14px", fontWeight: "Regular", padding: "16px 0px 0px 16px" }}
              color="text2"
            >
              알림 내역이 없습니다.
            </SuiTypography>
          )}
        </SuiBox>
      </Grid>
    </SuiBox>
  )
  const menuId = "profile-menu"
  // 프로필 클릭 시 나오는 메뉴
  const renderMenu = (
    <SuiBox
      display="flex"
      alignItems="center"
      sx={{
        display: ProfileMenuOpen ? "" : "none",
        position: "absolute",
        zIndex: 10,
        backgroundColor: "#ffffff",
        left: myData.role.name !== "bronze" ? "-25px" : "-125px",
        mt: 0.7,
        width: 300,
        boxShadow: 2,
        borderRadius: 4,
      }}
      ref={profileMenuRef}
    >
      <Grid container>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            className="profile-top"
            sx={{ width: "100%", p: 2 }}
          >
            <SuiTypography style={{ fontSize: "12px", fontWeight: "medium" }} color="white">
              {myData.role ? roleText(myData.role.name) : ""}
            </SuiTypography>
            <SuiTypography
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "medium",
              }}
              ml="auto"
              color="white"
              onClick={() =>
                myData.requests.map((x) => x.status).indexOf("공개") > -1
                  ? ""
                  : setopenrequestDialog(true)
              }
            >
              {myData.requests.map((x) => x.status).indexOf("공개") > -1 ? "심사중" : "등업신청"}
            </SuiTypography>
            {/* <IconButton
              sx={{ position: "absolute", right: "8px", marginTop: "21px" }}
              color="white"
              onClick={editProfileBtn}
            >
              <BiPencil />
            </IconButton> */}
            <Avatar
              sx={{
                width: 50,
                height: 50,
                position: "absolute",
                left: "43%",
                top: "55px",
                border: "2px solid white",
                backgroundColor: myData.profile ? "#ffffff" : "",
                boxShadow: 2,
              }}
              alt="Profile"
              src={myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox sx={{ textAlign: "center", mt: 6 }}>
            <SuiBox>
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                color="dark"
              >
                {myData.department}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "medium",
                }}
                color="dark"
              >
                {myData.name} {myData.position}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" sx={{ mt: 3.3 }}>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 35,
                border: 1,
                borderColor: "#F1F1F5",
                mr: 1,
                borderRadius: "10px 10px 0 0",
                pt: 0.6,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                color="dark"
              >
                작가
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 35,
                border: 1,
                borderColor: "#F1F1F5",
                mr: 1,
                borderRadius: "10px 10px 0 0",
                pt: 0.6,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                color="dark"
              >
                작품
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 35,
                border: 1,
                borderColor: "#F1F1F5",
                mr: 1,
                borderRadius: "10px 10px 0 0",
                pt: 0.6,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                color="dark"
              >
                유통사
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 35,
                border: 1,
                borderColor: "#F1F1F5",
                borderRadius: "10px 10px 0 0",
                pt: 0.6,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                color="dark"
              >
                외주계약
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center">
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 55,
                border: 1,
                borderColor: "#F1F1F5",
                mr: 1,
                borderRadius: "0 0 10px 10px",
                borderTop: "none",
                pt: 1,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                color="text2"
              >
                {myData.writers ? myData.writers.filter((el) => el.status === "공개").length : 0}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 55,
                mr: 1,
                border: 1,
                borderColor: "#F1F1F5",
                borderRadius: "0 0 10px 10px",
                borderTop: "none",
                pt: 1,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                color="text2"
              >
                {/* {contract_count()} */}
                {myData.contracts
                  ? myData.contracts.filter((el) => el.status === "공개").length
                  : 0}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 55,
                mr: 1,
                border: 1,
                borderColor: "#F1F1F5",
                borderRadius: "0 0 10px 10px",
                borderTop: "none",
                pt: 1,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                color="text2"
              >
                {myData.companies
                  ? myData.companies.filter((el) => el.status === "공개").length
                  : 0}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                textAlign: "center",
                width: 62,
                height: 55,
                border: 1,
                borderColor: "#F1F1F5",
                borderRadius: "0 0 10px 10px",
                borderTop: "none",
                pt: 1,
              }}
            >
              <SuiTypography
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                color="text2"
              >
                {myData.outsourcings
                  ? myData.outsourcings.filter((el) => el.status === "공개").length
                  : 0}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          {/* <SuiBox sx={{ pt: 2, pb: 1, pl: 2, pr: 1, mt: 1 }} display="flex" alignItems="center">
            <SuiTypography
              style={{
                fontSize: "14px",
                fontWeight: "medium",
              }}
              color="dark"
            >
              최근 업무 현황
            </SuiTypography>
            <SuiBox
              ml={"auto"}
              mt={0.5}
              component="img"
              src="/reload.png"
              alt="Soft UI Logo"
              height="36px"
              className="cursor"
              onClick={() => FilterReload()}
            />
          </SuiBox> */}
          {/* {Recents.map((row, index) => {
            return (
              <SuiBox
                key={index}
                display="flex"
                justifyContent="start"
                sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "#F1F1F5", cursor: "pointer" }}
                onClick={() => recentOpen(row)}
              >
                {row.title.indexOf("유통사") > -1 && row.name.length > 4 ? (
                  <Tooltip
                    arrow={false}
                    color="white"
                    classes={{ popper: "tooltip-light" }}
                    title={
                      <SuiBox>
                        <SuiTypography
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          color="dark"
                        >
                          {row.name}
                        </SuiTypography>
                      </SuiBox>
                    }
                  >
                    <SuiTypography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      color="dark"
                    >
                      {row.name.substring(0, 4) + " ..."}
                    </SuiTypography>
                  </Tooltip>
                ) : (
                  <SuiTypography
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                    color="dark"
                  >
                    {row.name}
                  </SuiTypography>
                )}
                <SuiTypography
                  style={{
                    fontSize: "14px",
                    fontWeight: "regular",
                  }}
                  color="dark"
                >
                  &nbsp;{row.title}
                </SuiTypography>
                <SuiTypography
                  style={{
                    fontSize: "14px",
                    fontWeight: "regular",
                  }}
                  ml="auto"
                  color="subTitle"
                >
                  {dayjs(row.created_at).format("YY.MM.DD")}
                  <IconButton sx={{ pt: 0, pb: 0.2, pr: 0 }}>
                    <MdArrowForwardIos size="12" />
                  </IconButton>
                </SuiTypography>
              </SuiBox>
            )
          })} */}
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="block"
            justifyContent="center"
            height="100%"
            alignItems="center"
            sx={{ p: 2, mt: 1 }}
          >
            <SuiButton
              color={"subTitle"}
              variant="outlined"
              onClick={() => to_myPage()}
              sx={{
                color: "#707070",
                border: "1px solid #CDD2D3 !important",
                fontSize: 14,
                width: "100%",
                height: 40,
                minHeight: 40,
                fontWeight: 700,
              }}
            >
              마이 페이지
            </SuiButton>
            <SuiButton
              color={"subTitle"}
              variant="outlined"
              onClick={editProfileBtn}
              sx={{
                color: "#707070",
                border: "1px solid #CDD2D3 !important;",
                mt: 1,
                fontSize: 14,
                width: "100%",
                height: 40,
                minHeight: 40,
                fontWeight: 700,
              }}
            >
              회원정보 변경
            </SuiButton>
            <SuiButton
              color="primary"
              onClick={() => logOut()}
              sx={{
                mt: 3,
                fontSize: 14,
                width: "100%",
                height: 40,
                minHeight: 40,
                fontWeight: 700,
              }}
            >
              로그아웃
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  )

  const mobileMenuId = "primary-search-account-menu-mobile"

  const cards = [
    {
      title: "작품 등록",
      content: "작가를 검색하고, 계약 작품을 추가합니다.",
    },
    {
      title: "작가 등록",
      content: "새로 계약한 작가 정보를 등록합니다.",
    },
    {
      title: "유통사 등록",
      content: "새로 계약한 유통사 정보를 등록합니다.",
    },
    {
      title: "외주계약 등록",
      content: "새로 계약한 외주계약 정보를 등록합니다.",
    },
    {
      title: "이벤트 등록",
      content: "새로운 이벤트 정보를 등록합니다.",
    },
  ]
  const IconControll = (title) => {
    if (title === cards[0].title) {
      return <RiBook2Line />
    } else if (title === cards[1].title) {
      return <BiUserPin />
    } else if (title === cards[2].title) {
      return <BiBuildings />
    } else if (title === cards[3].title) {
      return <BiCalendarCheck />
    } else if (title === cards[4].title) {
      return <BiCalendarEvent />
    }
  }
  const createCardList = cards.map((row, index) => {
    return (
      <Card
        sx={{
          p: 2,
          mb: 1,
          boxShadow: 0,
          border: 1,
          backgroundColor: firstStep === row.title ? "#FAF8FF" : "",
          borderColor: firstStep === row.title ? "#733DF3" : "#EEEEEE",
        }}
        key={row.title}
        className="cursor"
        onClick={() => setfirstStep(row.title)}
      >
        <SuiBox display="flex">
          <IconButton
            edge="start"
            color={firstStep === row.title ? "primary" : "secondary"}
            sx={{ mr: 1 }}
          >
            {IconControll(row.title)}
          </IconButton>
          <div style={{ paddingTop: "6px" }}>
            <SuiTypography
              color={firstStep === row.title ? "primary" : "secondary"}
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                lineHeight: "20px",
                color: firstStep === row.title ? "" : "#585858",
              }}
            >
              {row.title}
            </SuiTypography>
            <SuiTypography
              color={firstStep === row.title ? "primary" : "secondary"}
              style={{
                fontSize: "14px",
                fontWeight: "medium",
                color: firstStep === row.title ? "" : "#585858",
              }}
            >
              {row.content}
            </SuiTypography>
          </div>
        </SuiBox>
      </Card>
    )
  })
  const mainTitleText = () => {
    if (firstStep === cards[0].title) {
      return "작품 정보 확인"
    } else if (firstStep === cards[1].title) {
      return "작가 정보 확인"
    } else if (firstStep === cards[2].title) {
      return "유통사 정보 확인"
    } else if (firstStep === cards[3].title) {
      return "외주계약 정보 확인"
    } else if (firstStep === cards[4].title) {
      return "이벤트 정보 확인"
    }
  }
  const mainTitleSubText = () => {
    if (firstStep === cards[0].title) {
      return "먼저, 등록된 작품 정보가 있는지 확인합니다."
    } else if (firstStep === cards[1].title) {
      return "먼저, 등록된 작가 정보가 있는지 확인합니다."
    } else if (firstStep === cards[2].title) {
      return "먼저, 등록된 유통사 정보가 있는지 확인합니다."
    } else if (firstStep === cards[3].title) {
      return "먼저, 등록된 외주계약 정보가 있는지 확인합니다."
    } else if (firstStep === cards[4].title) {
      return "먼저, 등록된 이벤트 정보가 있는지 확인합니다."
    }
  }
  const createBtnText = () => {
    if (firstStep === cards[0].title) {
      return "다음으로"
    } else if (firstStep === cards[1].title) {
      if (firstStep_set) {
        return "신규 작가 추가하고 작품 등록"
      } else {
        return "신규 작가 등록"
      }
    } else if (firstStep === cards[2].title) {
      return "신규 유통사 등록"
    } else if (firstStep === cards[3].title) {
      return "신규 외주계약 등록"
    } else if (firstStep === cards[4].title) {
      return "신규 이벤트 등록"
    }
  }
  const createWriterBtn = () => {
    seteditId(null)
    settype("create")
    setwriterDialog(false)
    setDistributorDialog(false)
    setTimeout(() => {
      if (firstStep === cards[0].title) {
        setwriterDialog(true)
      } else if (firstStep === cards[1].title) {
        setwriterDialog(true)
      } else if (firstStep === cards[2].title) {
        setDistributorDialog(true)
      } else if (firstStep === cards[3].title) {
        setoutsourcingDialog(true)
      } else if (firstStep === cards[4].title) {
        seteventDialog(true)
      }
    }, 200)
  }
  const [searchNameApply, setsearchNameApply] = useState("")
  const [contractsTitleBtn, { data: contractsTitle }] = useLazyQuery(contractsSearch, {
    fetchPolicy: "no-cache",
    variables: {
      sort: "created_at:desc",
      title: searchNameApply,
    },
  })

  const [companiesActiveBtn, { data: companiesActive }] = useLazyQuery(companies, {
    fetchPolicy: "no-cache",
    variables: {
      sort: "created_at:desc",
      status: "공개",
      name: searchNameApply,
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [outsourcingsActiveBtn, { data: outsourcingsActive }] = useLazyQuery(outsourcings, {
    fetchPolicy: "no-cache",
    variables: {
      sort: "created_at:desc",
      status: "공개",
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [eventsActiveBtn, { data: eventsActive }] = useLazyQuery(events, {
    fetchPolicy: "no-cache",
    variables: {
      sort: "created_at:desc",
      title: searchNameApply,
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [writersNameBtn, { data: writersName }] = useLazyQuery(writers, {
    fetchPolicy: "no-cache",
    variables: {
      sort: "created_at:desc",
      status: "공개",
    },
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const writerSearch = () => {
    set_edit_event_id(null)
    set_type_event("create")
    setsearchNameApply(searchName)
    if (firstStep === cards[0].title) {
      contractsTitleBtn()
    } else if (firstStep === cards[1].title) {
      writersNameBtn()
      // writersjoaranameBtn()
      // writersebooknameBtn()
    } else if (firstStep === cards[2].title) {
      if (searchName !== "") {
        setwriterList([])
        companiesActiveBtn()
      }
    } else if (firstStep === cards[3].title) {
      if (searchName !== "") {
        setwriterList([])
        outsourcingsActiveBtn()
      }
    } else if (firstStep === cards[4].title) {
      if (searchName !== "") {
        setwriterList([])
        eventsActiveBtn()
      }
    }

    setsearchActive(true)
  }
  useEffect(() => {
    dispatch(loading_Apply(true))
    if (firstStep === cards[0].title) {
      if (contractsTitle) {
        setwriterList(contractsTitle.contracts)
        dispatch(loading_Apply(false))
      }
    } else if (firstStep === cards[1].title) {
      if (!searchNameApply) {
        dispatch(loading_Apply(false))
      }
      if (writersName && searchNameApply) {
        let array = []
        writersName.writers.forEach((element) => {
          if (element.name.indexOf(searchNameApply) > -1) {
            array.push(element)
          }
          if (element.joaraname.indexOf(searchNameApply) > -1) {
            array.push(element)
          }
          if (element.ebookname.indexOf(searchNameApply) > -1) {
            array.push(element)
          }
        })
        const uniqueArr = array.filter((element, index) => {
          return array.indexOf(element) === index
        })
        dispatch(loading_Apply(false))
        setwriterList(uniqueArr)
      }
    } else if (firstStep === cards[2].title) {
      if (companiesActive !== undefined && searchName) {
        dispatch(loading_Apply(false))

        setwriterList(companiesActive.companies)
      }
    } else if (firstStep === cards[3].title) {
      if (outsourcingsActive !== undefined && searchName) {
        dispatch(loading_Apply(false))
        let array = []
        outsourcingsActive.outsourcings.forEach((el) => {
          if (el.name.indexOf(searchNameApply) > -1) {
            array.push(el)
          } else if (el.nickname.indexOf(searchNameApply) > -1) {
            array.push(el)
          }
        })
        setwriterList(array)
      }
    } else if (firstStep === cards[4].title) {
      if (eventsActive !== undefined && searchName) {
        dispatch(loading_Apply(false))
        let array = []
        eventsActive.events.forEach((el) => {
          if (el.title.indexOf(searchNameApply) > -1 && el.state !== "delete") {
            array.push(el)
          }
        })
        setwriterList(array)
      }
    }
    setTimeout(() => {
      dispatch(loading_Apply(false))
    }, 3000)
  }, [
    searchNameApply,
    contractsTitle,
    writersName,
    companiesActive,
    outsourcingsActive,
    eventsActive,
  ])
  const searchText = () => {
    if (searchActive) {
      if (writerList.length === 0) {
        if (firstStep === cards[0].title) {
          //   return `이미 등록된 작품 정보가 없습니다.<br>
          // 하단에 <span class="point-text">[신규 작가 추가하고 작품 등록]</span> 을 눌러 작품 정보를 등록하실 수 있습니다.`
          return `검색결과 : 등록된 작품 정보 없음`
        } else if (firstStep === cards[1].title) {
          return `등록된 작가 정보가 없습니다.<br>
        하단에 <span class="point-text">[신규 작가 추가하고 작품 등록]</span> 을 눌러 작가 정보를 등록하실 수 있습니다.`
        } else if (firstStep === cards[2].title) {
          return `등록된 유통사 정보가 없습니다.<br>
        하단에 <span class="point-text">[신규 유통사 등록]</span> 을 눌러 유통사 정보를 등록하실 수 있습니다.`
        } else if (firstStep === cards[3].title) {
          return `등록된 외주계약 정보가 없습니다.<br>
        하단에 <span class="point-text">[신규 외주계약 등록]</span> 을 눌러 외주계약을 등록하실 수 있습니다.`
        } else if (firstStep === cards[4].title) {
          return `등록된 이벤트 정보가 없습니다.<br>
        하단에 <span class="point-text">[신규 이벤트 등록]</span> 을 눌러 이벤트를 등록하실 수 있습니다.`
        }
      } else if (writerList.length > 0) {
        if (firstStep === cards[0].title) {
          return `
        검색결과 : <strong>${writerList.length}</strong>개의 작품을 찾았습니다.`
        } else if (firstStep === cards[1].title) {
          return `
        검색결과 : <strong>${writerList.length}</strong>명의 작가를 찾았습니다.`
        } else if (firstStep === cards[2].title) {
          return `
        검색결과 : <strong>${writerList.length}</strong>개의 유통사를 찾았습니다.`
        } else if (firstStep === cards[3].title) {
          return `
        검색결과 : <strong>${writerList.length}</strong>명의 외주 계약자를 찾았습니다.`
        } else if (firstStep === cards[4].title) {
          return `
        검색결과 : <strong>${writerList.length}</strong>건의 이벤트를 찾았습니다.`
        }
      }
    } else {
      return "검색하신 정보가 없습니다."
    }
  }
  const [companyContractsActive] = useLazyQuery(companyContracts, {
    fetchPolicy: "network-only",
  })
  const [contractDocumentsActive] = useLazyQuery(contractDocuments, {
    fetchPolicy: "network-only",
  })
  const writerShow = (data) => {
    if (firstStep === cards[0].title) {
      if (myData.role.name === "bronze") {
        dispatch(loading_Apply(true))
        contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
          let ok = 0
          if (res.data.contractDocuments.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
            dispatch(loading_Apply(false))
          } else {
            res.data.contractDocuments.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.contractDocuments.length - 1) {
                dispatch(loading_Apply(false))

                if (ok > 0) {
                  data.writer.bookname = data.writer.ebookname
                  seteditId(data.writer.id)
                  seteditData(data.writer)
                  settabActive(1)
                  settype("detail")
                  setwriterDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      } else if (myData.role.name === "silver") {
        if (data.writer.users.indexOf(myData.id) > -1) {
          data.writer.bookname = data.writer.ebookname
          seteditId(data.writer.id)
          seteditData(data.writer)
          settabActive(1)
          settype("detail")
          setwriterDialog(true)
        } else {
          contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
            let ok = 0
            if (res.data.contractDocuments.length === 0) {
              setInfoAlert(InfoAlertSet)
              const InfoAlertSet1 = {
                dialog: true,
                title: "권한없음",
                content: "열람권한이 없습니다.",
                CanBtnText: "확인",
              }
              setInfoAlert(InfoAlertSet1)
            } else {
              res.data.contractDocuments.forEach((el, index) => {
                el.referrer?.forEach((el2) => {
                  if (el2.id === myData.id) {
                    ok += 1
                  }
                })
                if (index === res.data.contractDocuments.length - 1) {
                  if (ok > 0) {
                    data.writer.bookname = data.writer.ebookname
                    seteditId(data.writer.id)
                    seteditData(data.writer)
                    settabActive(1)
                    settype("detail")
                    setwriterDialog(true)
                  } else {
                    setInfoAlert(InfoAlertSet)
                    const InfoAlertSet1 = {
                      dialog: true,
                      title: "권한없음",
                      content: "열람권한이 없습니다.",
                      CanBtnText: "확인",
                    }
                    setInfoAlert(InfoAlertSet1)
                  }
                }
              })
            }
          })
        }
      } else {
        data.writer.bookname = data.writer.ebookname
        seteditId(data.writer.id)
        seteditData(data.writer)
        settabActive(1)
        settype("detail")
        setwriterDialog(true)
      }
    } else if (firstStep === cards[1].title) {
      if (myData.role.name === "bronze") {
        dispatch(loading_Apply(true))
        contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
          let ok = 0
          if (res.data.contractDocuments.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
            dispatch(loading_Apply(false))
          } else {
            res.data.contractDocuments.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.contractDocuments.length - 1) {
                dispatch(loading_Apply(false))

                if (ok > 0) {
                  data.bookname = data.ebookname
                  seteditId(data.id)
                  seteditData(data)
                  settype("detail")
                  setwriterDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      } else if (myData.role.name === "silver") {
        client
          .query({
            query: users_need,
            variables: {
              role: 1,
              status: "근무",
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let usersDataId = res.data.users.map((el) => el.id)
            if (usersDataId.indexOf(myData.id) > -1) {
              data.bookname = data.ebookname
              seteditId(data.id)
              seteditData(data)
              settype("detail")
              setwriterDialog(true)
            } else {
              contractDocumentsActive({ variables: { contract: data.id } }).then((res) => {
                let ok = 0
                if (res.data.contractDocuments.length === 0) {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                } else {
                  res.data.contractDocuments.forEach((el, index) => {
                    el.referrer?.forEach((el2) => {
                      if (el2.id === myData.id) {
                        ok += 1
                      }
                    })
                    if (index === res.data.contractDocuments.length - 1) {
                      if (ok > 0) {
                        data.bookname = data.ebookname
                        seteditId(data.id)
                        seteditData(data)
                        settype("detail")
                        setwriterDialog(true)
                      } else {
                        setInfoAlert(InfoAlertSet)
                        const InfoAlertSet1 = {
                          dialog: true,
                          title: "권한없음",
                          content: "열람권한이 없습니다.",
                          CanBtnText: "확인",
                        }
                        setInfoAlert(InfoAlertSet1)
                      }
                    }
                  })
                }
              })
            }
          })
      } else {
        data.bookname = data.ebookname
        seteditId(data.id)
        seteditData(data)
        settype("detail")
        setwriterDialog(true)
      }
    } else if (firstStep === cards[2].title) {
      if (myData.role.name === "bronze") {
        dispatch(loading_Apply(true))
        companyContractsActive({ variables: { company: data.id } }).then((res) => {
          let ok = 0
          if (res.data.companyContracts.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
            dispatch(loading_Apply(false))
          } else {
            res.data.companyContracts.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.companyContracts.length - 1) {
                dispatch(loading_Apply(false))

                if (ok > 0) {
                  seteditDistributorId(data.id)
                  data.title = data.name
                  seteditDistributorData(data)
                  settypeDistributor("detail")
                  setDistributorDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      } else if (myData.role.name === "silver") {
        client
          .query({
            query: users_need,
            variables: {
              role: 1,
              status: "근무",
            },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            if (res.data.users.indexOf(myData.id) > -1) {
              seteditDistributorId(data.id)
              data.title = data.name
              seteditDistributorData(data)
              settypeDistributor("detail")
              setDistributorDialog(true)
            } else {
              companyContractsActive({ variables: { company: data.id } }).then((res) => {
                let ok = 0
                if (res.data.companyContracts.length === 0) {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                } else {
                  res.data.companyContracts.forEach((el, index) => {
                    el.referrer?.forEach((el2) => {
                      if (el2.id === myData.id) {
                        ok += 1
                      }
                    })
                    if (index === res.data.companyContracts.length - 1) {
                      if (ok > 0) {
                        seteditDistributorId(data.id)
                        data.title = data.name
                        seteditDistributorData(data)
                        settypeDistributor("detail")
                        setDistributorDialog(true)
                      } else {
                        setInfoAlert(InfoAlertSet)
                        const InfoAlertSet1 = {
                          dialog: true,
                          title: "권한없음",
                          content: "열람권한이 없습니다.",
                          CanBtnText: "확인",
                        }
                        setInfoAlert(InfoAlertSet1)
                      }
                    }
                  })
                }
              })
            }
          })
      } else {
        seteditDistributorId(data.id)
        data.title = data.name
        seteditDistributorData(data)
        settypeDistributor("detail")
        setDistributorDialog(true)
      }
    } else if (firstStep === cards[3].title) {
      if (myData.role.name === "bronze") {
        dispatch(loading_Apply(true))
        outsourcingContractsActive({ variables: { outsourcing: data.id } }).then((res) => {
          let ok = 0
          if (res.data.outsourcingContracts.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
            dispatch(loading_Apply(false))
          } else {
            res.data.outsourcingContracts.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.outsourcingContracts.length - 1) {
                dispatch(loading_Apply(false))

                if (ok > 0) {
                  seteditOutsourcingId(data.id)
                  data.title = data.name
                  seteditOutsourcingData(data)
                  settypeOutsourcing("detail")
                  setoutsourcingDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      } else if (myData.role.name === "silver") {
        if (data.user === myData.id) {
          seteditOutsourcingId(data.id)
          data.title = data.name
          seteditOutsourcingData(data)
          settypeOutsourcing("detail")
          setoutsourcingDialog(true)
        } else {
          outsourcingContractsActive({ variables: { outsourcing: data.id } }).then((res) => {
            let ok = 0
            if (res.data.outsourcingContracts.length === 0) {
              setInfoAlert(InfoAlertSet)
              const InfoAlertSet1 = {
                dialog: true,
                title: "권한없음",
                content: "열람권한이 없습니다.",
                CanBtnText: "확인",
              }
              setInfoAlert(InfoAlertSet1)
            } else {
              res.data.outsourcingContracts.forEach((el, index) => {
                el.referrer?.forEach((el2) => {
                  if (el2.id === myData.id) {
                    ok += 1
                  }
                })
                if (index === res.data.outsourcingContracts.length - 1) {
                  if (ok > 0) {
                    seteditOutsourcingId(data.id)
                    data.title = data.name
                    seteditOutsourcingData(data)
                    settypeOutsourcing("detail")
                    setoutsourcingDialog(true)
                  } else {
                    setInfoAlert(InfoAlertSet)
                    const InfoAlertSet1 = {
                      dialog: true,
                      title: "권한없음",
                      content: "열람권한이 없습니다.",
                      CanBtnText: "확인",
                    }
                    setInfoAlert(InfoAlertSet1)
                  }
                }
              })
            }
          })
        }
      } else {
        seteditOutsourcingId(data.id)
        data.title = data.name
        seteditOutsourcingData(data)
        settypeOutsourcing("detail")
        setoutsourcingDialog(true)
      }
    } else if (firstStep === cards[4].title) {
      if (myData.role.name === "bronze") {
        dispatch(loading_Apply(true))
        outsourcingContractsActive({ variables: { outsourcing: data.id } }).then((res) => {
          let ok = 0
          if (res.data.outsourcingContracts.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
            dispatch(loading_Apply(false))
          } else {
            res.data.outsourcingContracts.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.outsourcingContracts.length - 1) {
                dispatch(loading_Apply(false))

                if (ok > 0) {
                  seteditOutsourcingId(data.id)
                  data.title = data.title
                  seteditOutsourcingData(data)
                  settypeOutsourcing("detail")
                  setoutsourcingDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      } else {
        set_edit_event_id(data.id)
        data.title = data.title
        set_edit_event_data(data)
        set_type_event("detail")
        seteventDialog(true)
      }
    }
  }
  const writerCard = writerList.map((row, index) => {
    return (
      <SuiBox
        display="flex"
        fullWidth
        key={index}
        sx={{
          py: 1,
          width: "100%",
          borderBottom: 1,
          borderColor: "#CED4D7",
          px: 1,
        }}
      >
        {firstStep === cards[0].title ? (
          <SuiTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.title}
          </SuiTypography>
        ) : (
          ""
        )}
        {firstStep === cards[1].title ? (
          <SuiTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.name}&nbsp;
          </SuiTypography>
        ) : (
          ""
        )}
        {firstStep === cards[1].title ? (
          <SuiTypography color="secondary" style={{ fontSize: "14px", fontWeight: "medium" }}>
            (P : {row.joaraname === "" ? "-" : row.joaraname} / E :{" "}
            {row.ebookname === "" ? "-" : row.ebookname})
          </SuiTypography>
        ) : (
          ""
        )}
        {firstStep === cards[2].title ? (
          <SuiTypography color="dark" style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.name}
          </SuiTypography>
        ) : (
          ""
        )}
        {firstStep === cards[3].title ? (
          <SuiTypography color="secondary" style={{ fontSize: "14px", fontWeight: "medium" }}>
            {row.name} (닉네임 : {row.nickname === "" ? "" : row.nickname})
          </SuiTypography>
        ) : (
          ""
        )}
        {firstStep === cards[4].title ? (
          <SuiTypography color="secondary" style={{ fontSize: "14px", fontWeight: "medium" }}>
            {`${row.title} (${row.start})`}
          </SuiTypography>
        ) : (
          ""
        )}
        <SuiTypography
          color="dark"
          className="cursor"
          sx={{ ml: "auto", fontSize: "14px", fontWeight: "bold", textDecoration: "underline" }}
          onClick={() => writerShow(row)}
        >
          내용보기
        </SuiTypography>
      </SuiBox>
    )
  })
  const handleSearchName = (event) => {
    setSearchName(event.target.value)
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchName !== "") {
        writerSearch()
      }
    }
  }
  const checkWriter = () => {
    return (
      <SuiBox fullWidth>
        <SuiBox display="flex" fullWidth>
          <SuiBox fullWidth sx={{ mr: 2, width: "100%" }}>
            <SuiInput
              type="text"
              placeholder={
                firstStep === cards[0].title
                  ? "작품명을 입력해주세요"
                  : firstStep === cards[1].title
                  ? "작가 본명 또는 필명을 입력해주세요"
                  : firstStep === cards[2].title
                  ? "유통사명을 입력해주세요"
                  : firstStep === cards[3].title
                  ? "외주계약자명 또는 닉네임을 입력해주세요"
                  : "이벤트명을 입력해주세요"
              }
              value={searchName}
              onChange={handleSearchName}
              onKeyPress={handleKeyPress}
            />
          </SuiBox>
          <SuiButton
            sx={{ height: 40, width: 40, minWidth: 40, p: 1 }}
            color="info2"
            disabled={searchName === ""}
            onClick={() => writerSearch()}
          >
            <FiSearch size="30" />
          </SuiButton>
        </SuiBox>
        <SuiBox fullWidth sx={{ height: 300, py: 2 }}>
          <SuiTypography style={{ color: "#6B6B6B", fontSize: "14px", fontWeight: "medium" }}>
            <span dangerouslySetInnerHTML={{ __html: searchText() }}></span>
          </SuiTypography>
          <SuiBox
            sx={{
              width: "100%",
              mt: 3,
              borderTop: writerList.length > 0 ? 1 : 0,
              borderColor: "#CED4D7",
              overflowY: "auto",
              height: 260,
            }}
          >
            {writerCard}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    )
  }
  const closeDialog = () => {
    setSettingDialog(false)
    setwriterDialog(false)
    seteventDialog(false)
    setDistributorDialog(false)
    setoutsourcingDialog(false)
  }

  const clickAdd = () => {
    setopenCreateList(!openCreateList)
    setcreateStep(0)
    setfirstStep("")
  }
  const deleteAllAlert = () => {
    dispatch(loading_Apply(true))
    let data = {
      id: myData.id,
      alerts: [],
    }
    updateUserActive({
      variables: data,
    }).then(() => {
      setAlertDatas([])
      dispatch(loading_Apply(false))
      setopenDeleteAlert_Dialog(false)
    })
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        marginTop: "13px",
        marginLeft: "auto",
        zIndex: 3,
        width: "max-content",
      }}
    >
      <DistributorDialog
        open={distributorDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={editDistributorData}
      />
      <WriterDialog
        open={writerDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={editData}
        tabActive={tabActive}
      />
      <OutsourcingDialog
        open={outsourcingDialog}
        closeDialog={closeDialog}
        editId={editOutsourcingId}
        type={typeOutsourcing}
        editData={editOutsourcingData}
      />
      <EventDialog
        open={eventDialog}
        closeDialog={closeDialog}
        type={type_event}
        editId={edit_event_id}
        editData={edit_event_data}
      />
      <SuiDialog
        openDialog={openDeleteAlert_Dialog}
        closeDialog={() => setopenDeleteAlert_Dialog(false)}
        MainTitle={"모든 알림 삭제"}
        Content={"모든 알림을 삭제하시겠습니까?"}
        Active={() => deleteAllAlert()}
        CanBtnText={"취소"}
        CreBtnText={"모두 삭제"}
        AlertType={"twoBtn"}
        CreBtnColor={"error"}
      />
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, px: 0 }}>
            <SuiBox mt={1} mb={1} ref={addRef} id="addBtn">
              {myData.role.name !== "bronze" ? (
                <SuiButton
                  color="primary"
                  fullWidth
                  onClick={() => clickAdd()}
                  sx={{ fontSize: 14, fontWeight: 400 }}
                >
                  +등록하기
                </SuiButton>
              ) : null}
              <SuiBox
                display="flex"
                alignItems="center"
                sx={{
                  display: openCreateList ? "" : "none",
                  position: "absolute",
                  zIndex: 10,
                  backgroundColor: "#ffffff",
                  px: 4,
                  pb: 6,
                  left: "-225px",
                  mt: 2,
                  width: 500,
                  boxShadow: 2,
                  borderRadius: 4,
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      sx={{ pt: 1, width: "100%", height: 40 }}
                    >
                      <IconButton
                        edge="start"
                        color="text2"
                        size="small"
                        onClick={() => handlecloseClick()}
                        aria-label="close"
                        sx={{
                          position: "absolute",
                          right: "10px",
                        }}
                      >
                        <MdClose />
                      </IconButton>
                    </SuiBox>
                    <SuiBox display="flex" alignItems="center" justifyContent="center">
                      <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {createStep === 1 ? mainTitleText() : "등록 유형 선택"}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ mt: 1, mb: 4 }}
                    >
                      <SuiTypography
                        style={{ fontSize: "16px", fontWeight: "medium" }}
                        color="subTitle"
                      >
                        {createStep === 1
                          ? mainTitleSubText()
                          : "정보 유형을 선택하고 다음 단계로 넘어갑니다."}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox>{createStep === 1 ? checkWriter() : createCardList}</SuiBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiBox
                      display="flex"
                      justifyContent="start"
                      height="100%"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      {createStep === 0 ? (
                        ""
                      ) : (
                        <SuiButton
                          color={createStep === 1 ? "subTitle" : "deactivated"}
                          variant="text"
                          onClick={() => (createStep === 1 ? backstep() : "")}
                          className="back-btn"
                          style={{ cursor: createStep === 1 ? "pointer" : "default" }}
                          sx={{ mr: "auto", fontSize: 16, justifyContent: "left" }}
                        >
                          뒤로
                        </SuiButton>
                      )}
                      {createStep > 0 &&
                      createBtnText() !== "다음으로" &&
                      myData.role.name === "bronze" ? null : (
                        <SuiButton
                          color="primary"
                          onClick={() =>
                            createStep === 0
                              ? nextStepFirst()
                              : firstStep === cards[0].title
                              ? nextStepFirst()
                              : createWriterBtn()
                          }
                          disabled={firstStep === "" || (!searchActive && createStep === 1)}
                          sx={{ fontSize: 14, ml: createStep === 0 ? "auto" : "" }}
                        >
                          {createStep === 1 ? createBtnText() : "다음으로"}
                        </SuiButton>
                      )}
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>
            {/* 알림 버튼 */}
            <IconButton
              // size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              className="ml-25 header-badge"
              onClick={() => AlertOpen()}
              ref={alertRef}
              id="alertBtn"
            >
              {AlertDatas.map((x) => x.read).indexOf(false) > -1 ? (
                <Badge overlap="circular" badgeContent="">
                  <FiBell />
                </Badge>
              ) : (
                <FiBell />
              )}
            </IconButton>
            <IconButton
              className="ml-10"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              ref={profileRef}
              id="profileBtn"
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: myData.profile ? "#ffffff" : "",
                  boxShadow: 1,
                  // color: "gray",
                }}
                style={{ backgroundColor: "transparent" }}
                alt="Profile"
                src={myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""}
              />
              {myData.role.name === "Authenticated" ? (
                <img
                  src="/myHeader.png"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                ></img>
              ) : (
                ""
              )}
            </IconButton>
            <IconButton
              size="small"
              edge="end"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {!ProfileMenuOpen ? (
                <GoChevronDown size="18px" color="#B2B2B2" />
              ) : (
                <GoChevronUp size="18px" color="#B2B2B2"></GoChevronUp>
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderAlert}
      <Dialog
        open={openrequestDialog}
        onClose={() => setopenrequestDialog(false)}
        fullWidth
        maxWidth="xs"
      >
        <SuiBox sx={{ width: 444, p: 4 }}>
          {/* <IconButton
            edge="start"
            color="text2"
            size="small"
            onClick={() => setopenrequestDialog(false)}
            aria-label="close"
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            <MdClose />
          </IconButton> */}
          <SuiBox display="flex" justifyContent="center" sx={{ mb: 4 }}>
            <SuiTypography fontSize="20px" fontWeight="bold" color="dark">
              {" "}
              회원 등급 변경 신청
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center">
            <Card
              sx={{
                width: "100%",
                height: 60,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <SuiBox display="flex" sx={{ height: 60 }} alignItems="center">
                <SuiTypography
                  fontWeight="bold"
                  component="div"
                  sx={{
                    width: "100px",
                    color: "#535353",
                    fontSize: 18,
                  }}
                >
                  현재등급
                </SuiTypography>
                <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE" }} />
                <SuiTypography
                  fontWeight="bold"
                  sx={{
                    ml: "auto",
                    mr: "auto",
                    textAlign: "center",
                    color: "#535353",
                    fontSize: 18,
                  }}
                >
                  {roleText(myData.role.name)}
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Card
              sx={{
                width: "100%",
                height: 60,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                borderRadius: "8px",
                textAlign: "center",
                border: 1,
                borderColor: "#EEEEEE",
              }}
            >
              <SuiBox display="flex" sx={{ height: 60 }} alignItems="center">
                <SuiTypography
                  fontWeight="bold"
                  component="div"
                  sx={{
                    width: "100px",
                    color: "#535353",
                    fontSize: 18,
                  }}
                >
                  신청등급
                </SuiTypography>
                <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE" }} />
                <SuiBox sx={{ width: 280 }}>
                  <FormControl
                    fullWidth
                    className={
                      changeRole === ""
                        ? "profile-select setting-selectbox select-none-active"
                        : "profile-select setting-selectbox"
                    }
                    onClick={() => setFiltersOpen1(!FiltersOpen1)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen1(!FiltersOpen1)}
                        />
                      )}
                      open={FiltersOpen1}
                      value={changeRole}
                      onChange={(e) => setchangeRole(e.target.value)}
                      className="setting-border-radius"
                    >
                      {roleList
                        .filter((x) => x !== roleText(myData.role.name))
                        .map((x) => {
                          return (
                            <MenuItem value={x} key={x}>
                              {x}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </SuiBox>
              </SuiBox>
            </Card>
          </SuiBox>
          <SuiTypography
            fontWeight="bold"
            component="div"
            sx={{
              fontSize: 15,
              mt: 2,
              fontWeight: 400,
              mb: 1.4,
              color: "#668B9E",
            }}
          >
            신청 사유 :
          </SuiTypography>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              multiline
              rows={4}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              placeholder="300자 이내"
              value={requestExplain}
              onChange={(e) => {
                if (e.target.value.length <= 300) setrequestExplain(e.target.value)
              }}
            />
          </SuiBox>
          <SuiBox
            display="flex"
            justifyContent="start"
            width="100%"
            alignItems="center"
            sx={{ mt: 4 }}
          >
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#707070",
                height: 40,
                fontSize: 14,
                ml: "auto",
                mr: 2,
              }}
              onClick={() => setopenrequestDialog(false)}
            >
              취소
            </SuiButton>
            <SuiButton
              color="primary"
              onClick={() => createLevelUpRequest()}
              sx={{ px: 2, py: 0.5, height: 40, minHeight: 40, fontSize: 14, width: 100 }}
              disabled={requestExplain === "" || changeRole === ""}
            >
              신청하기
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Dialog>
      <SettingDialog open={settingDialog} closeDialog={closeDialog} />
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <RejectDialog
        openDialog={reject_Dialog}
        closeDialog={() => setreject_Dialog(false)}
        MainTitle={"회원 등급 변경 거절"}
        Content={"회원 등급 변경 신청이 거절되었습니다."}
        CanBtnText={"확인"}
        CreBtnColor={"info2"}
        AlertType={"oneBtn"}
        requestExplainShowData={requestExplainData}
        type={"detail"}
      />
    </Box>
  )
}
