import * as React from "react"
import { useState, useEffect, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Divider from "@mui/material/Divider"
import Backdrop from "@mui/material/Backdrop"
import SuiInput from "components/SuiInput"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import SuiButton from "components/SuiButton"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"

import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

import { visuallyHidden } from "@mui/utils"
import Pagination from "@mui/material/Pagination"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import SuiBox from "components/SuiBox"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import SuiTypography from "components/SuiTypography"
import SuiDialog from "components/SuiDialog"
import enLocale from "date-fns/locale/en-US"
import PaginationCustom from "components/PaginationCustom"

import {
  AiFillCaretDown,
  AiOutlineCheck,
  AiFillCheckCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai"
import { MdClose } from "react-icons/md"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { BsFillCircleFill } from "react-icons/bs"
import { BiRecycle } from "react-icons/bi"
// Dialog
import OutsourcingDialog from "../outsourcing"

// API
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import { outsourcingContracts, outsourcings, outsourcingCount, settings } from "apollo/query"
import { createDonwloadLog } from "apollo/mutation"

import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../reducers/store"

function createData(
  id,
  type,
  contractStatus,
  name,
  nickname,
  minPrice,
  maxPrice,
  title,
  email,
  genre,
  workingLength,
  comment,
  phone,
  updated_at,
  user,
  outsourcing_contracts
) {
  return {
    id,
    type,
    contractStatus,
    name,
    nickname,
    minPrice,
    maxPrice,
    title,
    email,
    genre,
    workingLength,
    comment,
    phone,
    updated_at,
    user,
    outsourcing_contracts,
  }
}

const CryptoJS = require("crypto-js")
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function IconCircle(color) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  let colorCircle
  if (color.color !== "") {
    colorCircle = (
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        color="white"
        classes={{ popper: "tooltip-light" }}
        arrow={false}
        title={
          <SuiBox>
            <SuiTypography
              style={{ fontSize: "12px", color: "#000000", fontWeight: "normal" }}
              verticalAlign="middle"
            >
              계약 유효일
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}
              verticalAlign="middle"
            >
              {color.day === 0
                ? "계약종료"
                : color.value === "자동갱신"
                ? color.day + "일"
                : color.day + "일"}
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "12px", color: "#707070", fontWeight: "normal" }}
              verticalAlign="middle"
            >
              {`${color.value === "자동갱신" ? "갱신일" : "종료일"} : ${color.end_date}`}
            </SuiTypography>
          </SuiBox>
        }
      >
        {color.color ? (
          <IconButton color={color.color} sx={{ p: 0 }}>
            {color.value === "자동갱신" ? (
              <BiRecycle size={20} />
            ) : (
              <BsFillCircleFill size={10}></BsFillCircleFill>
            )}
          </IconButton>
        ) : (
          <div></div>
        )}
      </Tooltip>
    )
  } else {
    colorCircle = <></>
  }

  return colorCircle
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    changeFilter,
    showchangeFilter,
    excelTable,
  } = props
  const createSortHandler = (property) => (event) => {
    if (property !== "webtoon" && property !== "premium") {
      onRequestSort(event, property)
    }
  }
  const headerSelect = {
    webtoon: "특별 계약",
    premium: "콘텐츠 계약",
  }
  const EbookList = [
    { type: "웹툰", items: ["특별 계약"] },
    { type: "프리미엄", items: ["콘텐츠 계약"] },
  ]
  const [headerSelectVal, setheaderSelectVal] = useState(headerSelect)
  useLayoutEffect(() => {
    if (showchangeFilter.premium) {
      setheaderSelectVal(showchangeFilter)
    }
  }, [showchangeFilter])
  const headCells = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "구분",
    },
    {
      id: "bossName",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "bizNumber",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "닉네임",
    },
    {
      id: "contractDate1",
      numeric: false,
      disablePadding: false,
      label: "단가(최소)",
    },
    {
      id: "contractDate2",
      numeric: false,
      disablePadding: false,
      label: "단가(최대)",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "대표 작업작품명",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "이메일",
    },
    {
      id: "premium",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },
    {
      id: "webtoon",
      numeric: false,
      disablePadding: false,
      label: "작업 수",
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "최종작업일",
    },
  ]

  const headCellsLong = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "구분",
    },
    {
      id: "bossName",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "bizNumber",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "닉네임",
    },
    {
      id: "contractDate1",
      numeric: false,
      disablePadding: false,
      label: "단가(최소)",
    },
    {
      id: "contractDate2",
      numeric: false,
      disablePadding: false,
      label: "단가(최대)",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "대표 작업작품명",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "이메일",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처",
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "특이사항",
    },
    {
      id: "premium",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },
    {
      id: "webtoon",
      numeric: false,
      disablePadding: false,
      label: "작업 수",
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "최종작업일",
    },
  ]
  const headCellsExcel = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "No.",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "구분",
    },
    {
      id: "bossName",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "bizNumber",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "닉네임",
    },
    {
      id: "contractDate1",
      numeric: false,
      disablePadding: false,
      label: "단가(최소)",
    },
    {
      id: "contractDate2",
      numeric: false,
      disablePadding: false,
      label: "단가(최대)",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "대표 작업작품명",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "이메일",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처",
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "특이사항",
    },
    {
      id: "premium",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },
    {
      id: "webtoon",
      numeric: false,
      disablePadding: false,
      label: "작업 수",
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "최종작업일",
    },
  ]
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  useLayoutEffect(() => {
    if (excelTable) {
      setheadCelldata(headCellsExcel)
    } else {
      if (localStorage.getItem("miniSidenav") === "true") {
        setheadCelldata(headCells)
      } else if (localStorage.getItem("miniSidenav") === "false") {
        setheadCelldata(headCellsLong)
      }
    }
  }, [localStorage.getItem("miniSidenav")])

  const [headCelldata, setheadCelldata] = useState(headCells)
  const handlesetheadCelldata = (data) => {
    setheadCelldata(data)
  }
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderLeft:
                headCell.label === "대표 작업작품명" || headCell.label === "작업 수"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
  excelTable: false,
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,

  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
  excelTable: PropTypes.bool,
}

export default function EnhancedTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const client = useApolloClient()
  const { myData } = useSelector((state) => state.store)
  const [rows, setrows] = useState([])
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const [selectedData, setSelectedData] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [searchPage, setsearchPage] = useState(1)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalpage, settotalpage] = useState(10)
  const [searchRowsPerPage, setsearchRowsPerPage] = useState(10)
  const [searchTotalpage, setsearchTotalpage] = useState(10)
  const [outsourcingDialog, setoutsourcingDialog] = useState(false)
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [typeDistributor, settypeDistributor] = useState("create")
  const [editDistributorData, seteditDistributorData] = useState({ title: "" })
  const [rowlength, setrowlength] = useState(0)
  const [openFilter, setopenFilter] = useState(false)
  const [filterChipData, setfilterChipData] = useState([])
  const [changeFilter, setchangeFilter] = useState({
    premium: "콘텐츠 계약",
    webtoon: "특별 계약",
  })
  const [outsourcingsDatas, setoutsourcingsDatas] = useState([])
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false)
  const [outsourcingCountFilter, setoutsourcingCountFilter] = useState({
    status: "공개",
  })
  const [eventExcel, seteventExcel] = useState("현재 페이지")
  const [FiltersOpen10, setFiltersOpen10] = useState(false) // 엑셀 다운로드 형식

  // 다운로드 row
  const [rows_hide, setrows_hide] = useState([])

  useLayoutEffect(() => {
    setsearchTotalpage(Math.ceil(rowlength / searchRowsPerPage))
    settotalpage(Math.ceil(rowlength / rowsPerPage))
  })
  const paperSorting = (array) => {
    array.sort((a, b) => {
      var dateA = a[0].file.id
      var dateB = b[0].file.id
      return dateA < dateB ? 1 : -1
    })
    return array
  }

  useLayoutEffect(() => {
    // 탭 접근 권한 체크
    if (myData.name !== "") {
      if (!myData.accessTabs) {
        return setInfoAlert_Acess({
          dialog: true,
          title: "접근 권한 필요",
          content: "해당 탭에 접근하기 위한 권한이 없습니다.",
          CanBtnText: "확인",
        })
      }
    }
    if (myData.name !== "") {
      if (!myData.accessTabs) {
        return setInfoAlert_Acess({
          dialog: true,
          title: "접근 권한 필요",
          content: "해당 탭에 접근하기 위한 권한이 없습니다.",
          CanBtnText: "확인",
        })
      }
    }
    if (myData.accessTabs?.indexOf("out") === -1) {
      return setInfoAlert_Acess({
        dialog: true,
        title: "접근 권한 필요",
        content: "해당 탭에 접근하기 위한 권한이 없습니다.",
        CanBtnText: "확인",
      })
    } else settings_Call()
  }, [myData])
  const settings_ref = useRef(null)
  const settings_Call = () => {
    settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        if (el.type === "department") {
          el.list.unshift("전체")
        }

        let slice_index = el.list.findIndex((el) => el === "-")
        if (slice_index > -1) {
          el.list.splice(slice_index, 1)
          settings[el.type] = el.list
        } else {
          settings[el.type] = el.list
        }
      })
      settings_ref.current = settings
    })
  }

  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  useLayoutEffect(() => {
    let rowsData = []
    // outsourcingsDatas.forEach((element, index) => {
    //   let contractDate = ""
    //   if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
    //     let decryptbizNumber
    //     if (element.bizNumber.length < 44) {
    //       decryptbizNumber = element.bizNumber
    //     } else {
    //       decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }
    //     let decryptphone
    //     if (element.phone.length < 14) {
    //       decryptphone = element.phone
    //     } else {
    //       decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }
    //     let decryptaddress
    //     if (element.address.length < 44) {
    //       decryptaddress = element.address
    //     } else {
    //       decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }

    //     let contractStatus = {
    //       agent: "",
    //       contract: "",
    //       nobless: "",
    //       premium: {
    //         gray: "",
    //         red: "",
    //         orange: "",
    //         green: "",
    //       },
    //       mixnobl: "",
    //       ebook: { gray: "", red: "", orange: "", green: "" },
    //     }
    //     const premium = []
    //     if (changeFilter.premium === "콘텐츠 계약") {
    //       premium.push("콘텐츠 제공 계약")
    //     }
    //     // else if (changeFilter.premium === "프리미엄") {
    //     //   premium.push("프리미엄 계약")
    //     // } else if (changeFilter.premium === "노블X프리") {
    //     //   premium.push("노블레스 + 프리미엄 계약")
    //     // }
    //     const ebookList = []
    //     if (changeFilter.webtoon === "특별 계약") {
    //       ebookList.push("특별 계약")
    //       // ebookList.push("전자책 계약 선인세용")
    //     }
    //     // else if (changeFilter.webtoon === "웹툰") {
    //     //   ebookList.push("웹툰계약")
    //     // } else if (changeFilter.webtoon === "영상") {
    //     //   ebookList.push("영상화 계약")
    //     // } else if (changeFilter.webtoon === "게임") {
    //     //   ebookList.push("게임화 계약")
    //     // }
    //     element.outsourcing_contracts
    //       .map((x) => x.contractPaper)
    //       .forEach((paper, paperIndex) => {
    //         if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
    //           if (
    //             contractStatus.premium[
    //               betweenDayColor(
    //                 dayjs(new Date()).format("YYYYMMDD"),
    //                 dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //               )
    //             ] === ""
    //           ) {
    //             contractStatus.premium[
    //               betweenDayColor(
    //                 dayjs(new Date()).format("YYYYMMDD"),
    //                 dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //               )
    //             ] = 0
    //           }
    //           contractStatus.premium[
    //             betweenDayColor(
    //               dayjs(new Date()).format("YYYYMMDD"),
    //               dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //             )
    //           ] += 1
    //         } else if (ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)) {
    //           if (
    //             contractStatus.ebook[
    //               betweenDayColor(
    //                 dayjs(new Date()).format("YYYYMMDD"),
    //                 dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //               )
    //             ] === ""
    //           ) {
    //             contractStatus.ebook[
    //               betweenDayColor(
    //                 dayjs(new Date()).format("YYYYMMDD"),
    //                 dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //               )
    //             ] = 0
    //           }
    //           contractStatus.ebook[
    //             betweenDayColor(
    //               dayjs(new Date()).format("YYYYMMDD"),
    //               dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
    //             )
    //           ] += 1
    //         }
    //         if (element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 === paperIndex) {
    //           let mapdata = []
    //           element.outsourcing_contracts
    //             .map((x) => x.contractPaper)
    //             .forEach((el) => {
    //               mapdata.push(el.reverse()[0][1].value1)
    //             })
    //           mapdata.sort((a, b) => {
    //             var dateA = new Date(a)
    //             var dateB = new Date(b)
    //             return dateA > dateB ? 1 : -1
    //           })

    //           contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

    //           // contractDate = dayjs(
    //           //   element.outsourcing_contracts
    //           //     .map((x) => x.contractPaper)
    //           //     .reverse()[0]
    //           //     .reverse()[0][1].created_at
    //           // ).format("YY.MM.DD")
    //           rowsData.push(
    //             createData(
    //               element.id,
    //               element.name,
    //               element.companyid,
    //               element.joaraname,
    //               element.bossName,
    //               decryptbizNumber,
    //               decryptaddress,
    //               decryptphone,
    //               contractDate,
    //               contractStatus.premium,
    //               contractStatus.ebook,
    //               element.manager[0].department,
    //               element.manager[0].name,
    //               element.company_meetings.length
    //             )
    //           )
    //         }
    //       })
    //   } else {
    //     let decryptbizNumber
    //     if (element.bizNumber.length < 44) {
    //       decryptbizNumber = element.bizNumber
    //     } else {
    //       decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }
    //     let decryptphone
    //     if (element.phone.length < 14) {
    //       decryptphone = element.phone
    //     } else {
    //       decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }
    //     let decryptaddress
    //     if (element.address.length < 44) {
    //       decryptaddress = element.address
    //     } else {
    //       decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
    //         .toString(CryptoJS.enc.Utf8)
    //         .replace(/\"/g, "")
    //     }
    //     rowsData.push(
    //       createData(
    //         element.id,
    //         element.name,
    //         element.companyid,
    //         element.joaraname,
    //         element.bossName,
    //         decryptbizNumber,
    //         decryptaddress,
    //         decryptphone,
    //         "",
    //         "",
    //         "",
    //         element.manager[0].department,
    //         element.manager[0].name,
    //         element.company_meetings.length
    //       )
    //     )
    //   }
    //   if (index === outsourcingsDatas.length - 1) {
    //     setrows(rowsData)
    //   }
    // })
  }, [changeFilter])
  const { data: outsourcingCountActive } = useQuery(outsourcingCount, {
    fetchPolicy: "network-only",
    variables: outsourcingCountFilter,
  })
  useLayoutEffect(() => {
    if (outsourcingCountActive) {
      setrowlength(outsourcingCountActive.outsourcingCount)
    }
  }, [outsourcingCountActive, outsourcingCountFilter])
  const [outsourcingsSearchVariables, setoutsourcingsSearchVariables] = useState({ limit: 1 })
  const [outsourcingsVariables, setoutsourcingsVariables] = useState({
    sort: "created_at:desc",
    start: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    status: "공개",
  })
  const [FilterSearchActive, setFilterSearchActive] = useState(false)
  const [outsourcingContractsActive] = useLazyQuery(outsourcingContracts, {
    fetchPolicy: "network-only",
  })
  const [outsourcingsActiveLazy] = useLazyQuery(outsourcings, {
    fetchPolicy: "network-only",
    variables: outsourcingsVariables,
  })

  const [outsourcings_allActiveLazy] = useLazyQuery(outsourcings, {
    fetchPolicy: "network-only",
  })
  useLayoutEffect(() => {
    outsourcingsActiveLazy().then((res) => {})
  }, [])
  const { data: outsourcingsActive } = useQuery(outsourcings, {
    fetchPolicy: "network-only",
    variables: outsourcingsVariables,
  })
  const { data: outsourcingsSearchActive } = useQuery(outsourcings, {
    fetchPolicy: "network-only",
    variables: outsourcingsSearchVariables,
  })
  useLayoutEffect(() => {
    if (myData.accessTabs?.indexOf("out") !== -1) {
      if (outsourcingsActive && !FilterSearchActive) {
        dispatch(loading_Apply(true))
        let CountData = {
          status: "공개",
        }
        // if (FilterDatas.name !== "") {
        //   CountData.name = FilterDatas.name
        // }
        // if (FilterDatas.bossName !== "") {
        //   CountData.bossName = FilterDatas.bossName
        // }
        // if (FilterDatas.companyid !== "") {
        //   CountData.companyid = FilterDatas.companyid
        // }
        // if (FilterDatas.bizNumber !== "") {
        //   CountData.bizNumber = FilterDatas.bizNumber
        // }
        setoutsourcingCountFilter(CountData)
        setoutsourcingsDatas(outsourcingsActive.outsourcings)
        if (outsourcingsActive.outsourcings.length > 0) {
          let rowsData = []
          outsourcingsActive.outsourcings.forEach((element, index) => {
            let decryptbizNumber
            if (element.bizNumber.length < 44) {
              decryptbizNumber = element.bizNumber
            } else {
              decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }
            let decryptphone
            if (element.phone.length < 14) {
              decryptphone = element.phone
            } else {
              decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }
            let decryptaddress
            if (element.address.length < 44) {
              decryptaddress = element.address
            } else {
              decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }
            let contractDate = ""
            // if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
            // let contractStatus = {
            //   agent: "",
            //   contract: "",
            //   nobless: "",
            //   premium: {
            //     gray: "",
            //     red: "",
            //     orange: "",
            //     green: "",
            //   },
            //   mixnobl: "",
            //   ebook: { gray: "", red: "", orange: "", green: "" },
            // }
            // const premium = []
            // if (changeFilter.premium === "콘텐츠 계약") {
            //   premium.push("콘텐츠 제공 계약")
            // }
            // // else if (changeFilter.premium === "프리미엄") {
            // //   premium.push("프리미엄 계약")
            // // } else if (changeFilter.premium === "노블X프리") {
            // //   premium.push("노블레스 + 프리미엄 계약")
            // // }
            // const ebookList = []
            // if (changeFilter.webtoon === "특별 계약") {
            //   ebookList.push("특별 계약")
            //   // ebookList.push("전자책 계약 선인세용")
            // }
            // // else if (changeFilter.webtoon === "웹툰") {
            // //   ebookList.push("웹툰계약")
            // // } else if (changeFilter.webtoon === "영상") {
            // //   ebookList.push("영상화 계약")
            // // } else if (changeFilter.webtoon === "게임") {
            // //   ebookList.push("게임화 계약")
            // // }
            // element.outsourcing_contracts
            //   .map((x) => x.contractPaper)
            //   .forEach((paper, paperIndex) => {
            //     if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
            //       if (
            //         contractStatus.premium[
            //           betweenDayColor(
            //             dayjs(new Date()).format("YYYYMMDD"),
            //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //           )
            //         ] === ""
            //       ) {
            //         contractStatus.premium[
            //           betweenDayColor(
            //             dayjs(new Date()).format("YYYYMMDD"),
            //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //           )
            //         ] = 0
            //       }
            //       contractStatus.premium[
            //         betweenDayColor(
            //           dayjs(new Date()).format("YYYYMMDD"),
            //           dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //         )
            //       ] += 1
            //     } else if (ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)) {
            //       if (
            //         contractStatus.ebook[
            //           betweenDayColor(
            //             dayjs(new Date()).format("YYYYMMDD"),
            //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //           )
            //         ] === ""
            //       ) {
            //         contractStatus.ebook[
            //           betweenDayColor(
            //             dayjs(new Date()).format("YYYYMMDD"),
            //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //           )
            //         ] = 0
            //       }
            //       contractStatus.ebook[
            //         betweenDayColor(
            //           dayjs(new Date()).format("YYYYMMDD"),
            //           dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
            //         )
            //       ] += 1
            //     }
            //     if (
            //       element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
            //       paperIndex
            //     ) {
            //       let mapdata = []
            //       element.outsourcing_contracts
            //         .map((x) => x.contractPaper)
            //         .forEach((el) => {
            //           mapdata.push(el.reverse()[0][1].value1)
            //         })
            //       mapdata.sort((a, b) => {
            //         var dateA = new Date(a)
            //         var dateB = new Date(b)
            //         return dateA > dateB ? 1 : -1
            //       })

            //       contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

            //       // contractDate = dayjs(
            //       //   element.outsourcing_contracts
            //       //     .map((x) => x.contractPaper)
            //       //     .reverse()[0]
            //       //     .reverse()[0][1].created_at
            //       // ).format("YY.MM.DD")
            //       rowsData.push(
            //         createData(
            //           element.id,
            //           element.name,
            //           element.companyid,
            //           element.joaraname,
            //           element.bossName,
            //           decryptbizNumber,
            //           decryptaddress,
            //           decryptphone,
            //           contractDate,
            //           contractStatus.premium,
            //           contractStatus.ebook,
            //           element.manager[0].department,
            //           element.manager[0].name,
            //           element.company_meetings.length
            //         )
            //       )
            //     }
            //   })
            // } else {
            //   let decryptbizNumber
            //   if (element.bizNumber.length < 44) {
            //     decryptbizNumber = element.bizNumber
            //   } else {
            //     decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
            //       .toString(CryptoJS.enc.Utf8)
            //       .replace(/\"/g, "")
            //   }
            //   let decryptaddress
            //   if (element.address.length < 44) {
            //     decryptaddress = element.address
            //   } else {
            //     decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
            //       .toString(CryptoJS.enc.Utf8)
            //       .replace(/\"/g, "")
            //   }
            rowsData.push(
              createData(
                element.id,
                element.type,
                element.contractStatus,
                element.name,
                element.nickname,
                element.minPrice,
                element.maxPrice,
                element.mainTitle,
                element.email,
                element.genre,
                element.outsourcing_histories.length,
                element.outsourcing_significants.length > 0 ? element.outsourcing_significants : "",
                decryptphone,
                element.updated_at,
                element.user?.id,
                element.outsourcing_contracts
              )
            )
            // }
            if (index === outsourcingsActive.outsourcings.length - 1) {
              setTimeout(() => {
                dispatch(loading_Apply(false))
              }, 1000)
              setrows(rowsData)
              setSelected([])
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      }
    }
  }, [outsourcingsActive, outsourcingsVariables])
  useLayoutEffect(() => {
    if (myData.accessTabs?.indexOf("out") !== -1) {
      dispatch(loading_Apply(true))
      if (outsourcingsSearchActive && FilterSearchActive) {
        if (localStorage.getItem("outsourcing")) {
          setFilterDatas(JSON.parse(localStorage.getItem("outsourcing")))
        }
        let rowsData = []
        if (outsourcingsSearchActive.outsourcings.length > 0) {
          let elArray = []
          let ok = 0
          outsourcingsSearchActive.outsourcings.forEach((el, index) => {
            if (filterChipData.length > 0) {
              ok += 1
              let filterChipDataSet = []
              filterChipData.forEach((el) => {
                if (el === chipsList[0]) {
                  filterChipDataSet.push("콘텐츠 제공 계약")
                } else if (el === chipsList[1]) {
                  filterChipDataSet.push("특별 계약")
                }
                // else if (el === chipsList[2]) {
                //   filterChipDataSet.push("노블레스 계약")
                // } else if (el === chipsList[3]) {
                //   filterChipDataSet.push("프리미엄 계약")
                // } else if (el === chipsList[4]) {
                //   filterChipDataSet.push("노블레스 + 프리미엄 계약")
                // } else if (el === chipsList[5]) {
                //   filterChipDataSet.push("부속계약")
                // } else if (el === chipsList[6]) {
                //   filterChipDataSet.push("전자책 계약")
                //   filterChipDataSet.push("전자책 계약 선인세용")
                // } else if (el === chipsList[7]) {
                //   filterChipDataSet.push("웹툰계약")
                // } else if (el === chipsList[8]) {
                //   filterChipDataSet.push("영상화 계약")
                // } else if (el === chipsList[9]) {
                //   filterChipDataSet.push("게임화 계약")
                // }
              })
              if (el.outsourcing_contracts.length > 0) {
                el.outsourcing_contracts
                  .map((x) => x.contractPaper)
                  .forEach((paper, paperIndex) => {
                    if (filterChipDataSet.indexOf("부속계약") > -1) {
                      if (paperSorting(paper)[0][0].subContract.length > 0) {
                        if (filterChipDataSet.length > 1) {
                          if (
                            filterChipDataSet.includes(
                              paperSorting(paper)[0][0].selectData[0].value
                            )
                          ) {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체"
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체" &&
                                FilterDatas.managerName !== ""
                              ) {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department &&
                                  el.manager[0].name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (
                                //     user.department === FilterDatas.department &&
                                //     user.name === FilterDatas.managerName
                                //   ) {
                                //     elArray.push(el)
                                //   }
                                // })
                              } else {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (user.department === FilterDatas.department) {
                                //     elArray.push(el)
                                //   }
                                // })
                              }
                            } else if (FilterDatas.managerName !== "") {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.name === FilterDatas.managerName) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              elArray.push(el)
                            }
                          }
                        } else {
                          if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체" &&
                              FilterDatas.managerName !== ""
                            ) {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].department === FilterDatas.department &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (
                              //     user.department === FilterDatas.department &&
                              //     user.name === FilterDatas.managerName
                              //   ) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].department === FilterDatas.department
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.department === FilterDatas.department) {
                              //     elArray.push(el)
                              //   }
                              // })
                            }
                          } else if (FilterDatas.managerName !== "") {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.name === FilterDatas.managerName) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            elArray.push(el)
                          }
                        }
                      }
                      if (
                        filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                          if (
                            FilterDatas.department !== "" &&
                            FilterDatas.department !== "전체" &&
                            FilterDatas.managerName !== ""
                          ) {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (
                            //     user.department === FilterDatas.department &&
                            //     user.name === FilterDatas.managerName
                            //   ) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.department === FilterDatas.department) {
                            //     elArray.push(el)
                            //   }
                            // })
                          }
                        } else if (FilterDatas.managerName !== "") {
                          if (
                            el.manager.length > 0 &&
                            el.manager[0].name === FilterDatas.managerName
                          ) {
                            elArray.push(el)
                          }
                          // el.manager.forEach((user) => {
                          //   if (user.name === FilterDatas.managerName) {
                          //     elArray.push(el)
                          //   }
                          // })
                        } else {
                          elArray.push(el)
                        }
                      }
                    } else {
                      if (
                        filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                          if (
                            FilterDatas.department !== "" &&
                            FilterDatas.department !== "전체" &&
                            FilterDatas.managerName !== ""
                          ) {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (
                            //     user.department === FilterDatas.department &&
                            //     user.name === FilterDatas.managerName
                            //   ) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.department === FilterDatas.department) {
                            //     elArray.push(el)
                            //   }
                            // })
                          }
                        } else if (FilterDatas.managerName !== "") {
                          if (
                            el.manager.length > 0 &&
                            el.manager[0].name === FilterDatas.managerName
                          ) {
                            elArray.push(el)
                          }
                          // el.manager.forEach((user) => {
                          //   if (user.name === FilterDatas.managerName) {
                          //     elArray.push(el)
                          //   }
                          // })
                        } else {
                          elArray.push(el)
                        }
                      }
                    }
                  })
              }
            } else {
              if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                if (
                  FilterDatas.department !== "" &&
                  FilterDatas.department !== "전체" &&
                  FilterDatas.managerName !== ""
                ) {
                  ok += 1
                  if (
                    el.manager.length > 0 &&
                    el.manager[0].department === FilterDatas.department &&
                    el.manager[0].name === FilterDatas.managerName
                  ) {
                    elArray.push(el)
                  }
                  // el.manager.forEach((user) => {
                  //   if (
                  //     user.department === FilterDatas.department &&
                  //     user.name === FilterDatas.managerName
                  //   ) {
                  //     elArray.push(el)
                  //   }
                  // })
                } else {
                  ok += 1
                  if (
                    el.manager.length > 0 &&
                    el.manager[0].department === FilterDatas.department
                  ) {
                    elArray.push(el)
                  }
                  // el.manager.forEach((user) => {
                  //   if (user.department === FilterDatas.department) {
                  //     elArray.push(el)
                  //   }
                  // })
                }
              } else if (FilterDatas.managerName !== "") {
                ok += 1
                if (el.manager.length > 0 && el.manager[0].name === FilterDatas.managerName) {
                  elArray.push(el)
                }
                // el.manager.forEach((user) => {
                //   if (user.name === FilterDatas.managerName) {
                //     elArray.push(el)
                //   }
                // })
              } else {
                elArray.push(el)
              }
            }
            if (ok === 0) {
              elArray.push(el)
            }
            if (index === outsourcingsSearchActive.outsourcings.length - 1) {
              const uniqueArr = elArray.filter((element, index) => {
                return elArray.indexOf(element) === index
              })
              setoutsourcingsDatas(uniqueArr)
              if (uniqueArr.length === 0) {
                setrows([])
                setrowlength(0)
                setTimeout(() => {
                  dispatch(loading_Apply(false))
                }, 1000)
              } else {
                setrowlength(uniqueArr.length)
              }
              uniqueArr.forEach((element, uniqueIndex) => {
                let contractDate = ""
                if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
                  let decryptbizNumber
                  if (element.bizNumber.length < 44) {
                    decryptbizNumber = element.bizNumber
                  } else {
                    decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptphone
                  if (element.phone.length < 14) {
                    decryptphone = element.phone
                  } else {
                    decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptaddress
                  if (element.address.length < 44) {
                    decryptaddress = element.address
                  } else {
                    decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }

                  let contractStatus = {
                    agent: "",
                    contract: "",
                    nobless: "",
                    premium: {
                      gray: "",
                      red: "",
                      orange: "",
                      green: "",
                    },
                    mixnobl: "",
                    ebook: { gray: "", red: "", orange: "", green: "" },
                  }
                  const premium = []
                  if (changeFilter.premium === "콘텐츠 계약") {
                    premium.push("콘텐츠 제공 계약")
                  }
                  // else if (changeFilter.premium === "프리미엄") {
                  //   premium.push("프리미엄 계약")
                  // } else if (changeFilter.premium === "노블X프리") {
                  //   premium.push("노블레스 + 프리미엄 계약")
                  // }
                  const ebookList = []
                  if (changeFilter.webtoon === "특별 계약") {
                    ebookList.push("특별 계약")
                    // ebookList.push("전자책 계약 선인세용")
                  }
                  // else if (changeFilter.webtoon === "웹툰") {
                  //   ebookList.push("웹툰계약")
                  // } else if (changeFilter.webtoon === "영상") {
                  //   ebookList.push("영상화 계약")
                  // } else if (changeFilter.webtoon === "게임") {
                  //   ebookList.push("게임화 계약")
                  // }
                  element.outsourcing_contracts
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                        if (
                          contractStatus.premium[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          contractStatus.premium[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        contractStatus.premium[
                          betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      } else if (
                        ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (
                          contractStatus.ebook[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          contractStatus.ebook[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        contractStatus.ebook[
                          betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (
                        element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
                        paperIndex
                      ) {
                        let mapdata = []
                        element.outsourcing_contracts
                          .map((x) => x.contractPaper)
                          .forEach((el) => {
                            mapdata.push(el.reverse()[0][1].value1)
                          })
                        mapdata.sort((a, b) => {
                          var dateA = new Date(a)
                          var dateB = new Date(b)
                          return dateA > dateB ? 1 : -1
                        })

                        contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

                        // contractDate = dayjs(
                        //   element.outsourcing_contracts
                        //     .map((x) => x.contractPaper)
                        //     .reverse()[0]
                        //     .reverse()[0][1].created_at
                        // ).format("YY.MM.DD")
                        rowsData.push(
                          createData(
                            element.id,
                            element.type,
                            element.contractStatus,
                            element.name,
                            element.nickname,
                            element.minPrice,
                            element.maxPrice,
                            element.mainTitle,
                            element.email,
                            element.genre,
                            element.outsourcing_histories.length,
                            element.outsourcing_significants.length > 0
                              ? element.outsourcing_significants
                              : "",
                            decryptphone,
                            element.updated_at,
                            element.user?.id,
                            element.outsourcing_contracts
                          )
                        )
                      }
                    })
                } else {
                  let decryptbizNumber
                  if (element.bizNumber.length < 44) {
                    decryptbizNumber = element.bizNumber
                  } else {
                    decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptphone
                  if (element.phone.length < 14) {
                    decryptphone = element.phone
                  } else {
                    decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptaddress
                  if (element.address.length < 44) {
                    decryptaddress = element.address
                  } else {
                    decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }

                  rowsData.push(
                    createData(
                      element.id,
                      element.type,
                      element.contractStatus,
                      element.name,
                      element.nickname,
                      element.minPrice,
                      element.maxPrice,
                      element.mainTitle,
                      element.email,
                      element.genre,
                      element.outsourcing_histories.length,
                      element.outsourcing_significants.length > 0
                        ? element.outsourcing_significants
                        : "",
                      decryptphone,
                      element.updated_at,
                      element.user?.id,
                      element.outsourcing_contracts
                    )
                  )
                }
                if (uniqueIndex === uniqueArr.length - 1) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                  }, 1000)
                  setrows(rowsData)
                  setSelected(rowsData)
                }
              })
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      }
    }
  }, [outsourcingsSearchActive, outsourcingsSearchVariables])

  const all_outsourcings_call = async () => {
    let data = {
      sort: "created_at:desc",
      status: "공개",
      start: 0,
      limit: 9999,
    }

    if (eventExcel === "현재 페이지") {
      data.start = FilterSearchActive
        ? (searchPage - 1) * FilterSearchActive
        : (page - 1) * rowsPerPage
      data.limit = FilterSearchActive ? searchRowsPerPage : rowsPerPage
    }
    if (FilterDatas.type !== "") {
      data.type = FilterDatas.type
    }

    if (FilterDatas.nickname !== "") {
      data.nickname = FilterDatas.nickname
    }
    if (FilterDatas.name !== "") {
      data.name = FilterDatas.name
    }
    if (FilterDatas.contractStatus !== "") {
      data.contractStatus = FilterDatas.contractStatus
    }
    if (FilterDatas.genre !== "") {
      data.genre = FilterDatas.genre
    }
    if (FilterDatas.maxPrice) {
      data.maxPrice = FilterDatas.maxPrice.replace(/,/g, "")
    }
    // 다운로드 테이블 row 세팅 - 모든 외주계약 호출
    await client
      .query({
        query: outsourcings,
        variables: data,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        if (myData.accessTabs?.indexOf("out") !== -1) {
          if (!FilterSearchActive) {
            dispatch(loading_Apply(true))
            let CountData = {
              status: "공개",
            }
            // if (FilterDatas.name !== "") {
            //   CountData.name = FilterDatas.name
            // }
            // if (FilterDatas.bossName !== "") {
            //   CountData.bossName = FilterDatas.bossName
            // }
            // if (FilterDatas.companyid !== "") {
            //   CountData.companyid = FilterDatas.companyid
            // }
            // if (FilterDatas.bizNumber !== "") {
            //   CountData.bizNumber = FilterDatas.bizNumber
            // }
            if (res.data.outsourcings.length > 0) {
              let rowsData = []
              res.data.outsourcings.forEach((element, index) => {
                let decryptbizNumber
                if (element.bizNumber.length < 44) {
                  decryptbizNumber = element.bizNumber
                } else {
                  decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                    .toString(CryptoJS.enc.Utf8)
                    .replace(/\"/g, "")
                }
                let decryptphone
                if (element.phone.length < 14) {
                  decryptphone = element.phone
                } else {
                  decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                    .toString(CryptoJS.enc.Utf8)
                    .replace(/\"/g, "")
                }
                let decryptaddress
                if (element.address.length < 44) {
                  decryptaddress = element.address
                } else {
                  decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                    .toString(CryptoJS.enc.Utf8)
                    .replace(/\"/g, "")
                }
                let contractDate = ""
                // if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
                // let contractStatus = {
                //   agent: "",
                //   contract: "",
                //   nobless: "",
                //   premium: {
                //     gray: "",
                //     red: "",
                //     orange: "",
                //     green: "",
                //   },
                //   mixnobl: "",
                //   ebook: { gray: "", red: "", orange: "", green: "" },
                // }
                // const premium = []
                // if (changeFilter.premium === "콘텐츠 계약") {
                //   premium.push("콘텐츠 제공 계약")
                // }
                // // else if (changeFilter.premium === "프리미엄") {
                // //   premium.push("프리미엄 계약")
                // // } else if (changeFilter.premium === "노블X프리") {
                // //   premium.push("노블레스 + 프리미엄 계약")
                // // }
                // const ebookList = []
                // if (changeFilter.webtoon === "특별 계약") {
                //   ebookList.push("특별 계약")
                //   // ebookList.push("전자책 계약 선인세용")
                // }
                // // else if (changeFilter.webtoon === "웹툰") {
                // //   ebookList.push("웹툰계약")
                // // } else if (changeFilter.webtoon === "영상") {
                // //   ebookList.push("영상화 계약")
                // // } else if (changeFilter.webtoon === "게임") {
                // //   ebookList.push("게임화 계약")
                // // }
                // element.outsourcing_contracts
                //   .map((x) => x.contractPaper)
                //   .forEach((paper, paperIndex) => {
                //     if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                //       if (
                //         contractStatus.premium[
                //           betweenDayColor(
                //             dayjs(new Date()).format("YYYYMMDD"),
                //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //           )
                //         ] === ""
                //       ) {
                //         contractStatus.premium[
                //           betweenDayColor(
                //             dayjs(new Date()).format("YYYYMMDD"),
                //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //           )
                //         ] = 0
                //       }
                //       contractStatus.premium[
                //         betweenDayColor(
                //           dayjs(new Date()).format("YYYYMMDD"),
                //           dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //         )
                //       ] += 1
                //     } else if (ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                //       if (
                //         contractStatus.ebook[
                //           betweenDayColor(
                //             dayjs(new Date()).format("YYYYMMDD"),
                //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //           )
                //         ] === ""
                //       ) {
                //         contractStatus.ebook[
                //           betweenDayColor(
                //             dayjs(new Date()).format("YYYYMMDD"),
                //             dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //           )
                //         ] = 0
                //       }
                //       contractStatus.ebook[
                //         betweenDayColor(
                //           dayjs(new Date()).format("YYYYMMDD"),
                //           dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                //         )
                //       ] += 1
                //     }
                //     if (
                //       element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
                //       paperIndex
                //     ) {
                //       let mapdata = []
                //       element.outsourcing_contracts
                //         .map((x) => x.contractPaper)
                //         .forEach((el) => {
                //           mapdata.push(el.reverse()[0][1].value1)
                //         })
                //       mapdata.sort((a, b) => {
                //         var dateA = new Date(a)
                //         var dateB = new Date(b)
                //         return dateA > dateB ? 1 : -1
                //       })

                //       contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

                //       // contractDate = dayjs(
                //       //   element.outsourcing_contracts
                //       //     .map((x) => x.contractPaper)
                //       //     .reverse()[0]
                //       //     .reverse()[0][1].created_at
                //       // ).format("YY.MM.DD")
                //       rowsData.push(
                //         createData(
                //           element.id,
                //           element.name,
                //           element.companyid,
                //           element.joaraname,
                //           element.bossName,
                //           decryptbizNumber,
                //           decryptaddress,
                //           decryptphone,
                //           contractDate,
                //           contractStatus.premium,
                //           contractStatus.ebook,
                //           element.manager[0].department,
                //           element.manager[0].name,
                //           element.company_meetings.length
                //         )
                //       )
                //     }
                //   })
                // } else {
                //   let decryptbizNumber
                //   if (element.bizNumber.length < 44) {
                //     decryptbizNumber = element.bizNumber
                //   } else {
                //     decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                //       .toString(CryptoJS.enc.Utf8)
                //       .replace(/\"/g, "")
                //   }
                //   let decryptaddress
                //   if (element.address.length < 44) {
                //     decryptaddress = element.address
                //   } else {
                //     decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                //       .toString(CryptoJS.enc.Utf8)
                //       .replace(/\"/g, "")
                //   }
                element.outsourcing_significants
                rowsData.push(
                  createData(
                    element.id,
                    element.type,
                    element.contractStatus,
                    element.name,
                    element.nickname,
                    element.minPrice,
                    element.maxPrice,
                    element.mainTitle,
                    element.email,
                    element.genre,
                    element.outsourcing_histories.length,
                    element.outsourcing_significants.length > 0
                      ? element.outsourcing_significants
                      : "",
                    decryptphone,
                    element.updated_at,
                    element.user?.id,
                    element.outsourcing_contracts
                  )
                )
                // }
                if (index === res.data.outsourcings.length - 1) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                  }, 1000)
                  if (eventExcel === "현재 페이지") {
                    setrows_hide(rowsData.slice(0, rowsPerPage))
                  } else {
                    setrows_hide(rowsData)
                  }
                }
              })
            } else {
              setTimeout(() => {
                dispatch(loading_Apply(false))
              }, 1000)
              setrowlength(0)
              setrows_hide([])
            }
          } else {
            if (res.data.outsourcings.length > 0) {
              let rowsData = []

              let elArray = []
              let ok = 0
              res.data.outsourcings.forEach((el, index) => {
                if (filterChipData.length > 0) {
                  ok += 1
                  let filterChipDataSet = []
                  filterChipData.forEach((el) => {
                    if (el === chipsList[0]) {
                      filterChipDataSet.push("콘텐츠 제공 계약")
                    } else if (el === chipsList[1]) {
                      filterChipDataSet.push("특별 계약")
                    }
                    // else if (el === chipsList[2]) {
                    //   filterChipDataSet.push("노블레스 계약")
                    // } else if (el === chipsList[3]) {
                    //   filterChipDataSet.push("프리미엄 계약")
                    // } else if (el === chipsList[4]) {
                    //   filterChipDataSet.push("노블레스 + 프리미엄 계약")
                    // } else if (el === chipsList[5]) {
                    //   filterChipDataSet.push("부속계약")
                    // } else if (el === chipsList[6]) {
                    //   filterChipDataSet.push("전자책 계약")
                    //   filterChipDataSet.push("전자책 계약 선인세용")
                    // } else if (el === chipsList[7]) {
                    //   filterChipDataSet.push("웹툰계약")
                    // } else if (el === chipsList[8]) {
                    //   filterChipDataSet.push("영상화 계약")
                    // } else if (el === chipsList[9]) {
                    //   filterChipDataSet.push("게임화 계약")
                    // }
                  })
                  if (el.outsourcing_contracts.length > 0) {
                    el.outsourcing_contracts
                      .map((x) => x.contractPaper)
                      .forEach((paper, paperIndex) => {
                        if (filterChipDataSet.indexOf("부속계약") > -1) {
                          if (paperSorting(paper)[0][0].subContract.length > 0) {
                            if (filterChipDataSet.length > 1) {
                              if (
                                filterChipDataSet.includes(
                                  paperSorting(paper)[0][0].selectData[0].value
                                )
                              ) {
                                if (
                                  FilterDatas.department !== "" &&
                                  FilterDatas.department !== "전체"
                                ) {
                                  if (
                                    FilterDatas.department !== "" &&
                                    FilterDatas.department !== "전체" &&
                                    FilterDatas.managerName !== ""
                                  ) {
                                    if (
                                      el.manager.length > 0 &&
                                      el.manager[0].department === FilterDatas.department &&
                                      el.manager[0].name === FilterDatas.managerName
                                    ) {
                                      elArray.push(el)
                                    }
                                    // el.manager.forEach((user) => {
                                    //   if (
                                    //     user.department === FilterDatas.department &&
                                    //     user.name === FilterDatas.managerName
                                    //   ) {
                                    //     elArray.push(el)
                                    //   }
                                    // })
                                  } else {
                                    if (
                                      el.manager.length > 0 &&
                                      el.manager[0].department === FilterDatas.department
                                    ) {
                                      elArray.push(el)
                                    }
                                    // el.manager.forEach((user) => {
                                    //   if (user.department === FilterDatas.department) {
                                    //     elArray.push(el)
                                    //   }
                                    // })
                                  }
                                } else if (FilterDatas.managerName !== "") {
                                  if (
                                    el.manager.length > 0 &&
                                    el.manager[0].name === FilterDatas.managerName
                                  ) {
                                    elArray.push(el)
                                  }
                                  // el.manager.forEach((user) => {
                                  //   if (user.name === FilterDatas.managerName) {
                                  //     elArray.push(el)
                                  //   }
                                  // })
                                } else {
                                  elArray.push(el)
                                }
                              }
                            } else {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체"
                              ) {
                                if (
                                  FilterDatas.department !== "" &&
                                  FilterDatas.department !== "전체" &&
                                  FilterDatas.managerName !== ""
                                ) {
                                  if (
                                    el.manager.length > 0 &&
                                    el.manager[0].department === FilterDatas.department &&
                                    el.manager[0].name === FilterDatas.managerName
                                  ) {
                                    elArray.push(el)
                                  }
                                  // el.manager.forEach((user) => {
                                  //   if (
                                  //     user.department === FilterDatas.department &&
                                  //     user.name === FilterDatas.managerName
                                  //   ) {
                                  //     elArray.push(el)
                                  //   }
                                  // })
                                } else {
                                  if (
                                    el.manager.length > 0 &&
                                    el.manager[0].department === FilterDatas.department
                                  ) {
                                    elArray.push(el)
                                  }
                                  // el.manager.forEach((user) => {
                                  //   if (user.department === FilterDatas.department) {
                                  //     elArray.push(el)
                                  //   }
                                  // })
                                }
                              } else if (FilterDatas.managerName !== "") {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (user.name === FilterDatas.managerName) {
                                //     elArray.push(el)
                                //   }
                                // })
                              } else {
                                elArray.push(el)
                              }
                            }
                          }
                          if (
                            filterChipDataSet.includes(
                              paperSorting(paper)[0][0].selectData[0].value
                            )
                          ) {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체"
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체" &&
                                FilterDatas.managerName !== ""
                              ) {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department &&
                                  el.manager[0].name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (
                                //     user.department === FilterDatas.department &&
                                //     user.name === FilterDatas.managerName
                                //   ) {
                                //     elArray.push(el)
                                //   }
                                // })
                              } else {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (user.department === FilterDatas.department) {
                                //     elArray.push(el)
                                //   }
                                // })
                              }
                            } else if (FilterDatas.managerName !== "") {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.name === FilterDatas.managerName) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              elArray.push(el)
                            }
                          }
                        } else {
                          if (
                            filterChipDataSet.includes(
                              paperSorting(paper)[0][0].selectData[0].value
                            )
                          ) {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체"
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체" &&
                                FilterDatas.managerName !== ""
                              ) {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department &&
                                  el.manager[0].name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (
                                //     user.department === FilterDatas.department &&
                                //     user.name === FilterDatas.managerName
                                //   ) {
                                //     elArray.push(el)
                                //   }
                                // })
                              } else {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (user.department === FilterDatas.department) {
                                //     elArray.push(el)
                                //   }
                                // })
                              }
                            } else if (FilterDatas.managerName !== "") {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.name === FilterDatas.managerName) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              elArray.push(el)
                            }
                          }
                        }
                      })
                  }
                } else {
                  if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                    if (
                      FilterDatas.department !== "" &&
                      FilterDatas.department !== "전체" &&
                      FilterDatas.managerName !== ""
                    ) {
                      ok += 1
                      if (
                        el.manager.length > 0 &&
                        el.manager[0].department === FilterDatas.department &&
                        el.manager[0].name === FilterDatas.managerName
                      ) {
                        elArray.push(el)
                      }
                      // el.manager.forEach((user) => {
                      //   if (
                      //     user.department === FilterDatas.department &&
                      //     user.name === FilterDatas.managerName
                      //   ) {
                      //     elArray.push(el)
                      //   }
                      // })
                    } else {
                      ok += 1
                      if (
                        el.manager.length > 0 &&
                        el.manager[0].department === FilterDatas.department
                      ) {
                        elArray.push(el)
                      }
                      // el.manager.forEach((user) => {
                      //   if (user.department === FilterDatas.department) {
                      //     elArray.push(el)
                      //   }
                      // })
                    }
                  } else if (FilterDatas.managerName !== "") {
                    ok += 1
                    if (el.manager.length > 0 && el.manager[0].name === FilterDatas.managerName) {
                      elArray.push(el)
                    }
                    // el.manager.forEach((user) => {
                    //   if (user.name === FilterDatas.managerName) {
                    //     elArray.push(el)
                    //   }
                    // })
                  } else {
                    elArray.push(el)
                  }
                }
                if (ok === 0) {
                  elArray.push(el)
                }
                if (index === res.data.outsourcings.length - 1) {
                  const uniqueArr = elArray.filter((element, index) => {
                    return elArray.indexOf(element) === index
                  })
                  console.log(uniqueArr)
                  uniqueArr.forEach((element, uniqueIndex) => {
                    let contractDate = ""
                    if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
                      let decryptbizNumber
                      if (element.bizNumber.length < 44) {
                        decryptbizNumber = element.bizNumber
                      } else {
                        decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }
                      let decryptphone
                      if (element.phone.length < 14) {
                        decryptphone = element.phone
                      } else {
                        decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }
                      let decryptaddress
                      if (element.address.length < 44) {
                        decryptaddress = element.address
                      } else {
                        decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }

                      let contractStatus = {
                        agent: "",
                        contract: "",
                        nobless: "",
                        premium: {
                          gray: "",
                          red: "",
                          orange: "",
                          green: "",
                        },
                        mixnobl: "",
                        ebook: { gray: "", red: "", orange: "", green: "" },
                      }
                      const premium = []
                      if (changeFilter.premium === "콘텐츠 계약") {
                        premium.push("콘텐츠 제공 계약")
                      }
                      // else if (changeFilter.premium === "프리미엄") {
                      //   premium.push("프리미엄 계약")
                      // } else if (changeFilter.premium === "노블X프리") {
                      //   premium.push("노블레스 + 프리미엄 계약")
                      // }
                      const ebookList = []
                      if (changeFilter.webtoon === "특별 계약") {
                        ebookList.push("특별 계약")
                        // ebookList.push("전자책 계약 선인세용")
                      }
                      // else if (changeFilter.webtoon === "웹툰") {
                      //   ebookList.push("웹툰계약")
                      // } else if (changeFilter.webtoon === "영상") {
                      //   ebookList.push("영상화 계약")
                      // } else if (changeFilter.webtoon === "게임") {
                      //   ebookList.push("게임화 계약")
                      // }
                      element.outsourcing_contracts
                        .map((x) => x.contractPaper)
                        .forEach((paper, paperIndex) => {
                          if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                            if (
                              contractStatus.premium[
                                betweenDayColor(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                )
                              ] === ""
                            ) {
                              contractStatus.premium[
                                betweenDayColor(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                )
                              ] = 0
                            }
                            contractStatus.premium[
                              betweenDayColor(
                                dayjs(new Date()).format("YYYYMMDD"),
                                dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                              )
                            ] += 1
                          } else if (
                            ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)
                          ) {
                            if (
                              contractStatus.ebook[
                                betweenDayColor(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                )
                              ] === ""
                            ) {
                              contractStatus.ebook[
                                betweenDayColor(
                                  dayjs(new Date()).format("YYYYMMDD"),
                                  dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                                )
                              ] = 0
                            }
                            contractStatus.ebook[
                              betweenDayColor(
                                dayjs(new Date()).format("YYYYMMDD"),
                                dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                              )
                            ] += 1
                          }
                          if (
                            element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
                            paperIndex
                          ) {
                            let mapdata = []
                            element.outsourcing_contracts
                              .map((x) => x.contractPaper)
                              .forEach((el) => {
                                mapdata.push(el.reverse()[0][1].value1)
                              })
                            mapdata.sort((a, b) => {
                              var dateA = new Date(a)
                              var dateB = new Date(b)
                              return dateA > dateB ? 1 : -1
                            })

                            contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

                            // contractDate = dayjs(
                            //   element.outsourcing_contracts
                            //     .map((x) => x.contractPaper)
                            //     .reverse()[0]
                            //     .reverse()[0][1].created_at
                            // ).format("YY.MM.DD")
                            rowsData.push(
                              createData(
                                element.id,
                                element.type,
                                element.contractStatus,
                                element.name,
                                element.nickname,
                                element.minPrice,
                                element.maxPrice,
                                element.mainTitle,
                                element.email,
                                element.genre,
                                element.outsourcing_histories.length,
                                element.outsourcing_significants.length > 0
                                  ? element.outsourcing_significants
                                  : "",
                                decryptphone,
                                element.updated_at,
                                element.user?.id,
                                element.outsourcing_contracts
                              )
                            )
                          }
                        })
                    } else {
                      let decryptbizNumber
                      if (element.bizNumber.length < 44) {
                        decryptbizNumber = element.bizNumber
                      } else {
                        decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }
                      let decryptphone
                      if (element.phone.length < 14) {
                        decryptphone = element.phone
                      } else {
                        decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }
                      let decryptaddress
                      if (element.address.length < 44) {
                        decryptaddress = element.address
                      } else {
                        decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                          .toString(CryptoJS.enc.Utf8)
                          .replace(/\"/g, "")
                      }

                      rowsData.push(
                        createData(
                          element.id,
                          element.type,
                          element.contractStatus,
                          element.name,
                          element.nickname,
                          element.minPrice,
                          element.maxPrice,
                          element.mainTitle,
                          element.email,
                          element.genre,
                          element.outsourcing_histories.length,
                          element.outsourcing_significants.length > 0
                            ? element.outsourcing_significants
                            : "",
                          decryptphone,
                          element.updated_at,
                          element.user?.id,
                          element.outsourcing_contracts
                        )
                      )
                    }
                    if (uniqueIndex === uniqueArr.length - 1) {
                      setTimeout(() => {
                        setrows_hide(rowsData)
                        dispatch(loading_Apply(false))
                      }, 1000)
                    }
                  })
                }
              })
            } else {
              setTimeout(() => {
                setrows_hide([])
                dispatch(loading_Apply(false))
              }, 1000)
            }
          }
        }
      })
  }

  let orFilterData = {
    department: "", // 부서
    managerName: "", // 담당자 명
    type: "", // 구분
    nickname: "", // 닉네임
    name: "", // 본명
    contractStatus: "", // 계약상태
    genre: "", // 장르
    maxPrice: "", // 단가 최대값
  }
  const [FilterDatas, setFilterDatas] = useState(orFilterData)
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return 0
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return "gray"
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "green"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "orange"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "red"
    } else {
      return "gray"
    }
  }
  useLayoutEffect(() => {
    localStorage.removeItem("writer")
    localStorage.removeItem("contract")
    localStorage.removeItem("event")
    localStorage.removeItem("company")
    let CountData = {
      status: "공개",
    }
    // if (FilterDatas.name !== "") {
    //   CountData.name = FilterDatas.name
    // }
    // if (FilterDatas.bossName !== "") {
    //   CountData.bossName = FilterDatas.bossName
    // }
    // if (FilterDatas.companyid !== "") {
    //   CountData.companyid = FilterDatas.companyid
    // }
    // if (FilterDatas.bizNumber !== "") {
    //   CountData.bizNumber = FilterDatas.bizNumber
    // }
    setoutsourcingCountFilter(CountData)
    let data = {
      sort: "created_at:desc",
      start: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      status: "공개",
    }
    // if (FilterDatas.name !== "") {
    //   data.name = FilterDatas.name
    // }
    // if (FilterDatas.bossName !== "") {
    //   data.bossName = FilterDatas.bossName
    // }
    // if (FilterDatas.companyid !== "") {
    //   data.companyid = FilterDatas.companyid
    // }
    // if (FilterDatas.bizNumber !== "") {
    //   data.bizNumber = FilterDatas.bizNumber
    // }
    setoutsourcingsVariables(data)
    localStorage.setItem("outsourcing", JSON.stringify(FilterDatas))
    handleSearchedFilter()
  }, [page, rowsPerPage])
  const [loading, setloading] = useState(false)
  const filterSearch = () => {
    setloading(false)
    setopenFilter(false)
    // setPage(1)
    setsearchPage(1)
    // setRowsPerPage(10)
    // setsearchRowsPerPage(10)
    setFilterSearchActive(true)
    let data = {
      sort: "created_at:desc",
      status: "공개",
    }

    if (FilterDatas.type !== "") {
      data.type = FilterDatas.type
    }

    if (FilterDatas.nickname !== "") {
      data.nickname = FilterDatas.nickname
    }
    if (FilterDatas.name !== "") {
      data.name = FilterDatas.name
    }
    if (FilterDatas.contractStatus !== "") {
      data.contractStatus = FilterDatas.contractStatus
    }
    if (FilterDatas.genre !== "") {
      data.genre = FilterDatas.genre
    }
    if (FilterDatas.maxPrice) {
      data.maxPrice = FilterDatas.maxPrice.replace(/,/g, "")
    }

    setoutsourcingsSearchVariables(data)
    localStorage.setItem("outsourcing", JSON.stringify(FilterDatas))
    handleSearchedFilter()
    setTimeout(() => {
      setloading(true)
    }, 2000)
  }
  const handleFilterDatas = (type) => (event) => {
    let datas = FilterDatas
    if (type === "birth") {
      setFilterDatas({ ...datas, [type]: dayjs(event).format("YYYY-MM-DD") })
    } else {
      setFilterDatas({ ...datas, [type]: event.target.value })
    }
  }
  const filterChip = (x) => {
    let array = [...filterChipData]
    if (array.indexOf(x) === -1) {
      array.push(x)
    } else {
      array.splice(array.indexOf(x), 1)
    }
    setfilterChipData(array)
  }
  const openFilterInput = () => {
    setopenFilter(true)
  }
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [InfoAlert_Acess, setInfoAlert_Acess] = useState(InfoAlertSet)
  const filterSave = () => {
    localStorage.setItem("outsourcing", JSON.stringify(FilterDatas))
    setInfoAlert({
      dialog: true,
      title: "검색 필터 저장",
      content: "입력하신 검색 필터가 저장되었습니다.",
      CanBtnText: "확인",
    })
  }
  const filterClose = () => {
    setloading(true)
    setopenFilter(false)
    if (localStorage.getItem("outsourcing")) {
      setFilterDatas(JSON.parse(localStorage.getItem("outsourcing")))
    } else {
      setFilterDatas(orFilterData)
    }
  }

  const resetSearch = () => {
    setopenFilter(true)
    setloading(false)
  }
  const handleDetail = (data) => {
    if (myData.role.name === "bronze") {
      dispatch(loading_Apply(true))
      outsourcingContractsActive({ variables: { outsourcing: data.id } }).then((res) => {
        let ok = 0
        if (res.data.outsourcingContracts.length === 0) {
          setInfoAlert(InfoAlertSet)
          const InfoAlertSet1 = {
            dialog: true,
            title: "권한없음",
            content: "열람권한이 없습니다.",
            CanBtnText: "확인",
          }
          setInfoAlert(InfoAlertSet1)
          dispatch(loading_Apply(false))
        } else {
          res.data.outsourcingContracts.forEach((el, index) => {
            el.referrer?.forEach((el2) => {
              if (el2.id === myData.id) {
                ok += 1
              }
            })
            if (index === res.data.outsourcingContracts.length - 1) {
              dispatch(loading_Apply(false))

              if (ok > 0) {
                settypeDistributor("detail")
                seteditDistributorId(data.id)
                seteditDistributorData(data)
                setoutsourcingDialog(true)
              } else {
                setInfoAlert(InfoAlertSet)
                const InfoAlertSet1 = {
                  dialog: true,
                  title: "권한없음",
                  content: "열람권한이 없습니다.",
                  CanBtnText: "확인",
                }
                setInfoAlert(InfoAlertSet1)
              }
            }
          })
        }
      })
    } else if (myData.role.name === "silver") {
      if (data.user === myData.id) {
        seteditDistributorId(data.id)
        seteditDistributorData(data)
        settypeDistributor("detail")
        setoutsourcingDialog(true)
      } else {
        outsourcingContractsActive({ variables: { outsourcing: data.id } }).then((res) => {
          let ok = 0
          if (res.data.outsourcingContracts.length === 0) {
            setInfoAlert(InfoAlertSet)
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
          } else {
            res.data.outsourcingContracts.forEach((el, index) => {
              el.referrer?.forEach((el2) => {
                if (el2.id === myData.id) {
                  ok += 1
                }
              })
              if (index === res.data.outsourcingContracts.length - 1) {
                if (ok > 0) {
                  settypeDistributor("detail")
                  seteditDistributorId(data.id)
                  seteditDistributorData(data)
                  setoutsourcingDialog(true)
                } else {
                  setInfoAlert(InfoAlertSet)
                  const InfoAlertSet1 = {
                    dialog: true,
                    title: "권한없음",
                    content: "열람권한이 없습니다.",
                    CanBtnText: "확인",
                  }
                  setInfoAlert(InfoAlertSet1)
                }
              }
            })
          }
        })
      }
    } else {
      setoutsourcingDialog(true)
      seteditDistributorId(data.id)
      seteditDistributorData(data)
      settypeDistributor("detail")
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const chipsList = ["콘텐츠 계약", "특별 계약"]
  const chipsListSelects = chipsList.map((x) => {
    return (
      <Chip
        sx={{
          mr: 2,
          height: 40,
          mt: 1,
          border: filterChipData.indexOf(x) > -1 ? "1px solid #5C38FF" : "none",
          backgroundColor: filterChipData.indexOf(x) > -1 ? "#FAF8FF" : "#F1F1F5",
          fontWeight: filterChipData.indexOf(x) > -1 ? 900 : 500,
        }}
        color={filterChipData.indexOf(x) > -1 ? "primary" : "secondary"}
        variant="outlined"
        className="filter-chips"
        key={x}
        label={x}
        onClick={() => filterChip(x)}
        style={{
          color: filterChipData.indexOf(x) > -1 ? "" : "#9AA0A6",
        }}
      ></Chip>
    )
  })
  const contractList = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ]
  const contractListSelects = contractList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id)

    let newSelected = []
    let newSelectedsData = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      newSelectedsData = newSelectedsData.concat(selectedData, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newSelectedsData = newSelectedsData.concat(selectedData.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSelectedsData = newSelectedsData.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      newSelectedsData = newSelectedsData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }
    setSelectedData(newSelectedsData)
    setSelected(newSelected)
  }
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const excelExportActive = () => {
    all_outsourcings_call()
  }

  useEffect(() => {
    if (rows_hide.length > 0) {
      document.getElementById("excelExport").click()
      createDonwloadLogActive({
        variables: {
          userId: String(myData.id),
          userName: myData.name,
          userPosition: myData.position,
          userDepartment: myData.department,
          userEmail: myData.email,
          donwloadType: "excel",
          downloadList:
            eventExcel === "전체 내역" ? [{ "받은 데이터 개수": rows_hide.length }] : rows_hide,
          downloadDate: new Date(),
        },
      })
    }
  }, [rows_hide])

  const isSelected = (name) => selected.indexOf(name) !== -1
  const padingList = [10, 20, 50, 100]
  const padingListSelects = padingList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const handleRowsPerPageChange = (event) => {
    if (FilterSearchActive) {
      setsearchRowsPerPage(event.target.value)
    } else {
      setRowsPerPage(event.target.value)
    }
  }
  const handlePageSearchChange = (event, value) => {
    // dispatch(loading_Apply(true))
    setsearchPage(value)
  }
  const handlePageChange = (event, value) => {
    dispatch(loading_Apply(true))
    setPage(value)
  }
  const TableReload = () => {
    if (myData.accessTabs?.indexOf("out") !== -1) {
      dispatch(loading_Apply(true))
      if (!FilterSearchActive) {
        setoutsourcingsDatas(outsourcingsActive.outsourcings)
        if (outsourcingsActive.outsourcings.length > 0) {
          let rowsData = []
          outsourcingsActive.outsourcings.forEach((element, index) => {
            let decryptbizNumber
            if (element.bizNumber.length < 44) {
              decryptbizNumber = element.bizNumber
            } else {
              decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }
            let decryptphone
            if (element.phone.length < 14) {
              decryptphone = element.phone
            } else {
              decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }
            let decryptaddress
            if (element.address.length < 44) {
              decryptaddress = element.address
            } else {
              decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                .toString(CryptoJS.enc.Utf8)
                .replace(/\"/g, "")
            }

            let contractDate = ""
            if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
              let contractStatus = {
                agent: "",
                contract: "",
                nobless: "",
                premium: {
                  gray: "",
                  red: "",
                  orange: "",
                  green: "",
                },
                mixnobl: "",
                ebook: { gray: "", red: "", orange: "", green: "" },
              }
              const premium = []
              if (changeFilter.premium === "콘텐츠 계약") {
                premium.push("콘텐츠 제공 계약")
              }
              // else if (changeFilter.premium === "프리미엄") {
              //   premium.push("프리미엄 계약")
              // } else if (changeFilter.premium === "노블X프리") {
              //   premium.push("노블레스 + 프리미엄 계약")
              // }
              const ebookList = []
              if (changeFilter.webtoon === "특별 계약") {
                ebookList.push("특별 계약")
                // ebookList.push("전자책 계약 선인세용")
              }
              // else if (changeFilter.webtoon === "웹툰") {
              //   ebookList.push("웹툰계약")
              // } else if (changeFilter.webtoon === "영상") {
              //   ebookList.push("영상화 계약")
              // } else if (changeFilter.webtoon === "게임") {
              //   ebookList.push("게임화 계약")
              // }
              element.outsourcing_contracts
                .map((x) => x.contractPaper)
                .forEach((paper, paperIndex) => {
                  if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                    if (
                      contractStatus.premium[
                        betweenDayColor(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                        )
                      ] === ""
                    ) {
                      contractStatus.premium[
                        betweenDayColor(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                        )
                      ] = 0
                    }
                    contractStatus.premium[
                      betweenDayColor(
                        dayjs(new Date()).format("YYYYMMDD"),
                        dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                      )
                    ] += 1
                  } else if (ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                    if (
                      contractStatus.ebook[
                        betweenDayColor(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                        )
                      ] === ""
                    ) {
                      contractStatus.ebook[
                        betweenDayColor(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                        )
                      ] = 0
                    }
                    contractStatus.ebook[
                      betweenDayColor(
                        dayjs(new Date()).format("YYYYMMDD"),
                        dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                      )
                    ] += 1
                  }
                  if (
                    element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
                    paperIndex
                  ) {
                    let mapdata = []
                    element.outsourcing_contracts
                      .map((x) => x.contractPaper)
                      .forEach((el) => {
                        mapdata.push(el.reverse()[0][1].value1)
                      })
                    mapdata.sort((a, b) => {
                      var dateA = new Date(a)
                      var dateB = new Date(b)
                      return dateA > dateB ? 1 : -1
                    })

                    contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

                    // contractDate = dayjs(
                    //   element.outsourcing_contracts
                    //     .map((x) => x.contractPaper)
                    //     .reverse()[0]
                    //     .reverse()[0][1].created_at
                    // ).format("YY.MM.DD")
                    rowsData.push(
                      createData(
                        element.id,
                        element.type,
                        element.contractStatus,
                        element.name,
                        element.nickname,
                        element.minPrice,
                        element.maxPrice,
                        element.mainTitle,
                        element.email,
                        element.genre,
                        element.outsourcing_histories.length,
                        element.outsourcing_significants.length > 0
                          ? element.outsourcing_significants
                          : "",
                        decryptphone,
                        element.updated_at,
                        element.user?.id,
                        element.outsourcing_contracts
                      )
                    )
                  }
                })
            } else {
              let decryptbizNumber
              if (element.bizNumber.length < 44) {
                decryptbizNumber = element.bizNumber
              } else {
                decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                  .toString(CryptoJS.enc.Utf8)
                  .replace(/\"/g, "")
              }
              let decryptphone
              if (element.phone.length < 14) {
                decryptphone = element.phone
              } else {
                decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                  .toString(CryptoJS.enc.Utf8)
                  .replace(/\"/g, "")
              }
              let decryptaddress
              if (element.address.length < 44) {
                decryptaddress = element.address
              } else {
                decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                  .toString(CryptoJS.enc.Utf8)
                  .replace(/\"/g, "")
              }

              rowsData.push(
                createData(
                  element.id,
                  element.type,
                  element.contractStatus,
                  element.name,
                  element.nickname,
                  element.minPrice,
                  element.maxPrice,
                  element.mainTitle,
                  element.email,
                  element.genre,
                  element.outsourcing_histories.length,
                  element.outsourcing_significants.length > 0
                    ? element.outsourcing_significants
                    : "",
                  decryptphone,
                  element.updated_at,
                  element.user?.id,
                  element.outsourcing_contracts
                )
              )
            }
            if (index === outsourcingsActive.outsourcings.length - 1) {
              setTimeout(() => {
                dispatch(loading_Apply(false))
              }, 1000)
              setrows(rowsData)
              setSelected(rowsData)
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      } else {
        let rowsData = []
        if (outsourcingsSearchActive.outsourcings.length > 0) {
          let elArray = []
          let ok = 0
          outsourcingsSearchActive.outsourcings.forEach((el, index) => {
            if (filterChipData.length > 0) {
              ok += 1
              let filterChipDataSet = []
              filterChipData.forEach((el) => {
                if (el === chipsList[0]) {
                  filterChipDataSet.push("콘텐츠 제공 계약")
                } else if (el === chipsList[1]) {
                  filterChipDataSet.push("특별 계약")
                }
                //  else if (el === chipsList[2]) {
                //   filterChipDataSet.push("노블레스 계약")
                // } else if (el === chipsList[3]) {
                //   filterChipDataSet.push("프리미엄 계약")
                // } else if (el === chipsList[4]) {
                //   filterChipDataSet.push("노블레스 + 프리미엄 계약")
                // } else if (el === chipsList[5]) {
                //   filterChipDataSet.push("부속계약")
                // } else if (el === chipsList[6]) {
                //   filterChipDataSet.push("전자책 계약")
                //   filterChipDataSet.push("전자책 계약 선인세용")
                // } else if (el === chipsList[7]) {
                //   filterChipDataSet.push("웹툰계약")
                // } else if (el === chipsList[8]) {
                //   filterChipDataSet.push("영상화 계약")
                // } else if (el === chipsList[9]) {
                //   filterChipDataSet.push("게임화 계약")
                // }
              })
              if (el.outsourcing_contracts.length > 0) {
                el.outsourcing_contracts
                  .map((x) => x.contractPaper)
                  .forEach((paper, paperIndex) => {
                    if (filterChipDataSet.indexOf("부속계약") > -1) {
                      if (paperSorting(paper)[0][0].subContract.length > 0) {
                        if (filterChipDataSet.length > 1) {
                          if (
                            filterChipDataSet.includes(
                              paperSorting(paper)[0][0].selectData[0].value
                            )
                          ) {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체"
                            ) {
                              if (
                                FilterDatas.department !== "" &&
                                FilterDatas.department !== "전체" &&
                                FilterDatas.managerName !== ""
                              ) {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department &&
                                  el.manager[0].name === FilterDatas.managerName
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (
                                //     user.department === FilterDatas.department &&
                                //     user.name === FilterDatas.managerName
                                //   ) {
                                //     elArray.push(el)
                                //   }
                                // })
                              } else {
                                if (
                                  el.manager.length > 0 &&
                                  el.manager[0].department === FilterDatas.department
                                ) {
                                  elArray.push(el)
                                }
                                // el.manager.forEach((user) => {
                                //   if (user.department === FilterDatas.department) {
                                //     elArray.push(el)
                                //   }
                                // })
                              }
                            } else if (FilterDatas.managerName !== "") {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.name === FilterDatas.managerName) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              elArray.push(el)
                            }
                          }
                        } else {
                          if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                            if (
                              FilterDatas.department !== "" &&
                              FilterDatas.department !== "전체" &&
                              FilterDatas.managerName !== ""
                            ) {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].department === FilterDatas.department &&
                                el.manager[0].name === FilterDatas.managerName
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (
                              //     user.department === FilterDatas.department &&
                              //     user.name === FilterDatas.managerName
                              //   ) {
                              //     elArray.push(el)
                              //   }
                              // })
                            } else {
                              if (
                                el.manager.length > 0 &&
                                el.manager[0].department === FilterDatas.department
                              ) {
                                elArray.push(el)
                              }
                              // el.manager.forEach((user) => {
                              //   if (user.department === FilterDatas.department) {
                              //     elArray.push(el)
                              //   }
                              // })
                            }
                          } else if (FilterDatas.managerName !== "") {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.name === FilterDatas.managerName) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            elArray.push(el)
                          }
                        }
                      }
                      if (
                        filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                          if (
                            FilterDatas.department !== "" &&
                            FilterDatas.department !== "전체" &&
                            FilterDatas.managerName !== ""
                          ) {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (
                            //     user.department === FilterDatas.department &&
                            //     user.name === FilterDatas.managerName
                            //   ) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.department === FilterDatas.department) {
                            //     elArray.push(el)
                            //   }
                            // })
                          }
                        } else if (FilterDatas.managerName !== "") {
                          if (
                            el.manager.length > 0 &&
                            el.manager[0].name === FilterDatas.managerName
                          ) {
                            elArray.push(el)
                          }
                          // el.manager.forEach((user) => {
                          //   if (user.name === FilterDatas.managerName) {
                          //     elArray.push(el)
                          //   }
                          // })
                        } else {
                          elArray.push(el)
                        }
                      }
                    } else {
                      if (
                        filterChipDataSet.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                          if (
                            FilterDatas.department !== "" &&
                            FilterDatas.department !== "전체" &&
                            FilterDatas.managerName !== ""
                          ) {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department &&
                              el.manager[0].name === FilterDatas.managerName
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (
                            //     user.department === FilterDatas.department &&
                            //     user.name === FilterDatas.managerName
                            //   ) {
                            //     elArray.push(el)
                            //   }
                            // })
                          } else {
                            if (
                              el.manager.length > 0 &&
                              el.manager[0].department === FilterDatas.department
                            ) {
                              elArray.push(el)
                            }
                            // el.manager.forEach((user) => {
                            //   if (user.department === FilterDatas.department) {
                            //     elArray.push(el)
                            //   }
                            // })
                          }
                        } else if (FilterDatas.managerName !== "") {
                          if (
                            el.manager.length > 0 &&
                            el.manager[0].name === FilterDatas.managerName
                          ) {
                            elArray.push(el)
                          }
                          // el.manager.forEach((user) => {
                          //   if (user.name === FilterDatas.managerName) {
                          //     elArray.push(el)
                          //   }
                          // })
                        } else {
                          elArray.push(el)
                        }
                      }
                    }
                  })
              }
            } else {
              if (FilterDatas.department !== "" && FilterDatas.department !== "전체") {
                if (
                  FilterDatas.department !== "" &&
                  FilterDatas.department !== "전체" &&
                  FilterDatas.managerName !== ""
                ) {
                  ok += 1
                  if (
                    el.manager.length > 0 &&
                    el.manager[0].department === FilterDatas.department &&
                    el.manager[0].name === FilterDatas.managerName
                  ) {
                    elArray.push(el)
                  }
                  // el.manager.forEach((user) => {
                  //   if (
                  //     user.department === FilterDatas.department &&
                  //     user.name === FilterDatas.managerName
                  //   ) {
                  //     elArray.push(el)
                  //   }
                  // })
                } else {
                  ok += 1
                  if (
                    el.manager.length > 0 &&
                    el.manager[0].department === FilterDatas.department
                  ) {
                    elArray.push(el)
                  }
                  // el.manager.forEach((user) => {
                  //   if (user.department === FilterDatas.department) {
                  //     elArray.push(el)
                  //   }
                  // })
                }
              } else if (FilterDatas.managerName !== "") {
                ok += 1
                if (el.manager.length > 0 && el.manager[0].name === FilterDatas.managerName) {
                  elArray.push(el)
                }
                // el.manager.forEach((user) => {
                //   if (user.name === FilterDatas.managerName) {
                //     elArray.push(el)
                //   }
                // })
              } else {
                elArray.push(el)
              }
            }
            if (ok === 0) {
              elArray.push(el)
            }
            if (index === outsourcingsSearchActive.outsourcings.length - 1) {
              const uniqueArr = elArray.filter((element, index) => {
                return elArray.indexOf(element) === index
              })
              setoutsourcingsDatas(uniqueArr)
              if (uniqueArr.length === 0) {
                setrows([])
                setrowlength(0)
                setTimeout(() => {
                  dispatch(loading_Apply(false))
                }, 1000)
              } else {
                setrowlength(uniqueArr.length)
              }
              uniqueArr.forEach((element, uniqueIndex) => {
                let contractDate = ""
                if (element.outsourcing_contracts.map((x) => x.contractPaper).length > 0) {
                  let decryptbizNumber
                  if (element.bizNumber.length < 44) {
                    decryptbizNumber = element.bizNumber
                  } else {
                    decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptphone
                  if (element.phone.length < 14) {
                    decryptphone = element.phone
                  } else {
                    decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptaddress
                  if (element.address.length < 44) {
                    decryptaddress = element.address
                  } else {
                    decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }

                  let contractStatus = {
                    agent: "",
                    contract: "",
                    nobless: "",
                    premium: {
                      gray: "",
                      red: "",
                      orange: "",
                      green: "",
                    },
                    mixnobl: "",
                    ebook: { gray: "", red: "", orange: "", green: "" },
                  }
                  const premium = []
                  if (changeFilter.premium === "콘텐츠 계약") {
                    premium.push("콘텐츠 제공 계약")
                  }
                  // else if (changeFilter.premium === "프리미엄") {
                  //   premium.push("프리미엄 계약")
                  // } else if (changeFilter.premium === "노블X프리") {
                  //   premium.push("노블레스 + 프리미엄 계약")
                  // }
                  const ebookList = []
                  if (changeFilter.webtoon === "특별 계약") {
                    ebookList.push("특별 계약")
                    // ebookList.push("전자책 계약 선인세용")
                  }
                  // else if (changeFilter.webtoon === "웹툰") {
                  //   ebookList.push("웹툰계약")
                  // } else if (changeFilter.webtoon === "영상") {
                  //   ebookList.push("영상화 계약")
                  // } else if (changeFilter.webtoon === "게임") {
                  //   ebookList.push("게임화 계약")
                  // }
                  element.outsourcing_contracts
                    .map((x) => x.contractPaper)
                    .forEach((paper, paperIndex) => {
                      if (premium.includes(paperSorting(paper)[0][0].selectData[0].value)) {
                        if (
                          contractStatus.premium[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          contractStatus.premium[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        contractStatus.premium[
                          betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      } else if (
                        ebookList.includes(paperSorting(paper)[0][0].selectData[0].value)
                      ) {
                        if (
                          contractStatus.ebook[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] === ""
                        ) {
                          contractStatus.ebook[
                            betweenDayColor(
                              dayjs(new Date()).format("YYYYMMDD"),
                              dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                            )
                          ] = 0
                        }
                        contractStatus.ebook[
                          betweenDayColor(
                            dayjs(new Date()).format("YYYYMMDD"),
                            dayjs(paperSorting(paper)[0][1].value2).format("YYYYMMDD")
                          )
                        ] += 1
                      }
                      if (
                        element.outsourcing_contracts.map((x) => x.contractPaper).length - 1 ===
                        paperIndex
                      ) {
                        let mapdata = []
                        element.outsourcing_contracts
                          .map((x) => x.contractPaper)
                          .forEach((el) => {
                            mapdata.push(el.reverse()[0][1].value1)
                          })
                        mapdata.sort((a, b) => {
                          var dateA = new Date(a)
                          var dateB = new Date(b)
                          return dateA > dateB ? 1 : -1
                        })

                        contractDate = dayjs(mapdata[0]).format("YY.MM.DD")

                        // contractDate = dayjs(
                        //   element.outsourcing_contracts
                        //     .map((x) => x.contractPaper)
                        //     .reverse()[0]
                        //     .reverse()[0][1].created_at
                        // ).format("YY.MM.DD")
                        rowsData.push(
                          createData(
                            element.id,
                            element.type,
                            element.contractStatus,
                            element.name,
                            element.nickname,
                            element.minPrice,
                            element.maxPrice,
                            element.mainTitle,
                            element.email,
                            element.genre,
                            element.outsourcing_histories.length,
                            element.outsourcing_significants.length > 0
                              ? element.outsourcing_significants
                              : "",
                            decryptphone,
                            element.updated_at,
                            element.user?.id,
                            element.outsourcing_contracts
                          )
                        )
                      }
                    })
                } else {
                  let decryptbizNumber
                  if (element.bizNumber.length < 44) {
                    decryptbizNumber = element.bizNumber
                  } else {
                    decryptbizNumber = CryptoJS.AES.decrypt(element.bizNumber, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptphone
                  if (element.phone.length < 14) {
                    decryptphone = element.phone
                  } else {
                    decryptphone = CryptoJS.AES.decrypt(element.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }
                  let decryptaddress
                  if (element.address.length < 44) {
                    decryptaddress = element.address
                  } else {
                    decryptaddress = CryptoJS.AES.decrypt(element.address, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                  }

                  rowsData.push(
                    createData(
                      element.id,
                      element.type,
                      element.contractStatus,
                      element.name,
                      element.nickname,
                      element.minPrice,
                      element.maxPrice,
                      element.mainTitle,
                      element.email,
                      element.genre,
                      element.outsourcing_histories.length,
                      element.outsourcing_significants.length > 0
                        ? element.outsourcing_significants
                        : "",
                      decryptphone,
                      element.updated_at,
                      element.user?.id,
                      element.outsourcing_contracts
                    )
                  )
                }
                if (uniqueIndex === uniqueArr.length - 1) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                  }, 1000)
                  setrows(rowsData)
                  setSelected(rowsData)
                }
              })
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrowlength(0)
          setrows([])
          setSelected([])
        }
      }
    }
  }
  const FilterReload = () => {
    setfilterChipData([])
    setFilterDatas(orFilterData)
  }
  const teamList = settings_ref.current
    ? settings_ref.current.department
    : [
        "전체",
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ]
  const closeDialog = () => {
    setoutsourcingDialog(false)
  }
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const outTypeList = settings_ref.current
    ? settings_ref.current.outContract
    : ["의뢰가능", "의뢰 중", "계약해지"]

  const outTypeListSelects = outTypeList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const typeList = ["일러스트", "편집", "기타"]

  const typeListSelects = typeList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const [searchedFilter, setsearchedFilter] = useState(orFilterData)
  const handleSearchedFilter = () => {
    let datas = FilterDatas
    datas.filterChipData = filterChipData.join()
    setsearchedFilter(datas)
  }

  useLayoutEffect(() => {
    setminiSidenav(localStorage.getItem("miniSidenav"))
  }, [localStorage.getItem("miniSidenav")])

  const [miniSidenav, setminiSidenav] = useState(localStorage.getItem("miniSidenav"))
  const iconActive = () => {
    setopenFilter(false)
  }
  const checkNull = (str) => {
    if (typeof str == "undefined" || str == null || str == "") {
      return true
    } else {
      return false
    }
  }
  const authroiztionData = (type, data, row) => {
    if (type === "name") {
      let name = []

      data.split(",").forEach((el) => {
        if (el.length === 1) {
          name.push(el)
        } else if (el.length === 2) {
          name.push(el.substring(0, 1) + "*")
        } else if (el.length === 3) {
          name.push(el.substring(0, 1) + "**")
        } else {
          if (el) {
            let star = "*".repeat(el.length - 2)
            name.push(el.substring(0, 2) + star)
          }
        }
      })
      if (
        row.user === myData.id ||
        row.outsourcing_contracts.filter((x) => x.referrer.map((x) => x.id).indexOf(myData.id) > -1)
          .length > 0
      ) {
        return data
      } else {
        return name.join(", ")
      }
    } else if (type === "email") {
      let originStr = data
      let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
      let strLength

      if (checkNull(originStr) == true || checkNull(emailStr) == true) {
        return originStr
      } else {
        strLength = emailStr.toString().split("@")[0].length - 3

        // ex1) abcdefg12345@naver.com => ab**********@naver.com
        if (
          row.user === myData.id ||
          row.outsourcing_contracts.filter(
            (x) => x.referrer.map((x) => x.id).indexOf(myData.id) > -1
          ).length > 0
        ) {
          return data
        } else {
          return originStr.toString().replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*")
        }

        // ex2) abcdefg12345@naver.com => ab**********@nav******
        // return originStr
        //   .toString()
        //   .replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*")
        //   .replace(/.{6}$/, "******")
      }
    }
  }
  const createDialog = () => {
    setoutsourcingDialog(true)
    settypeDistributor("create")
  }
  function SearchedField() {
    let datas = searchedFilter
    const [searchedData, setsearchedData] = useState(true)
    useLayoutEffect(() => {
      setsearchedData(Object.values(datas).every((x) => x === false || x === "" || x === null))
    }, [searchedFilter])
    let stringData = ""
    for (const key in datas) {
      let value
      let keyValue
      if (datas[key] === "" || datas[key] === null) {
        value = "-"
      } else if (datas[key] === true) {
        value = "Y"
      } else if (datas[key] === false) {
        value = "N"
      } else {
        value = datas[key]
      }
      if (key === "department") {
        keyValue = "부서"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "managerName") {
        keyValue = "등록자 명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "type") {
        keyValue = "구분"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "nickname") {
        keyValue = "닉네임"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "name") {
        keyValue = "본명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "contractStatus") {
        keyValue = "계약상태"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "genre") {
        keyValue = "장르"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "maxPrice") {
        keyValue = "단가 최대값"
        stringData = stringData + `${keyValue}:${value}, `
      }
    }
    let stringShowData = stringData.substring(0, stringData.length - 2)
    const filterCloseBtn = () => {
      FilterReload()
      const orFilterDataSet = {
        department: "", // 부서
        managerName: "", // 담당자 명
        type: "", // 구분
        nickname: "", // 닉네임
        name: "", // 본명
        contractStatus: "", // 계약상태
        genre: "", // 장르
        maxPrice: "", // 단가 최대값
      }
      setFilterDatas(orFilterDataSet)
      setsearchedFilter(orFilterDataSet)
      setsearchedData(true)
      setFilterSearchActive(false)
      let CountData = {
        status: "공개",
      }
      setoutsourcingCountFilter(CountData)
      setPage(1)
      setoutsourcingsVariables({
        sort: "created_at:desc",
        start: 0,
        limit: rowsPerPage,
        status: "공개",
      })
      setloading(false)
    }
    return (
      <SuiBox>
        {FilterSearchActive ? (
          <SuiBox
            ml={1.5}
            p={2}
            mr={0}
            mt={4}
            display="flex"
            style={{ alignItems: "center", width: "fit-content" }}
            className="filter-data-box"
          >
            <div>
              <SuiBox display="flex" style={{ alignItems: "center" }}>
                <SuiTypography
                  style={{ fontSize: "16px", color: "#535353" }}
                  verticalAlign="middle"
                >
                  검색내용
                </SuiTypography>
                <IconButton
                  color="text2"
                  size="small"
                  onClick={() => filterCloseBtn()}
                  aria-label="close"
                  sx={{
                    p: 0,
                    ml: "auto",
                  }}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiTypography style={{ fontSize: "16px", color: "#707070" }} verticalAlign="middle">
                {stringShowData}
              </SuiTypography>
            </div>
          </SuiBox>
        ) : (
          <SuiBox mt={12} style={{ alignItems: "center", width: "66%" }}></SuiBox>
        )}
      </SuiBox>
    )
  }

  const comma = (price) => {
    let str = String(price)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
  }

  return (
    <DashboardLayout>
      <OutsourcingDialog
        open={outsourcingDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={editDistributorData}
      />
      <Box sx={{ width: "100%" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1201 }}
          open={openFilter}
          onClick={filterClose}
        ></Backdrop>
        <SuiBox display="flex" justifyContent="start" height="100%">
          <SuiBox
            sx={{
              width: openFilter ? 800 : 400,
              height: 40,
              zIndex: 1202,
              ml: 1.5,
            }}
            className={
              openFilter
                ? "filter-right-icon-filter-open filter-overlay-radius"
                : "filter-right-icon"
            }
            onClick={() => {
              if (!openFilter) {
                openFilterInput()
              }
            }}
          >
            <SuiInput
              icon={
                openFilter
                  ? { component: "close", direction: "right" }
                  : { component: "search", direction: "right" }
              }
              iconActive={iconActive}
              type="text"
              placeholder="닉네임을 입력해주세요"
              value={FilterDatas.nickname}
              onClick={openFilterInput}
              onChange={handleFilterDatas("nickname")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  filterSearch()
                }
              }}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          sx={{
            display: openFilter ? "" : "none",
            position: "absolute",
            zIndex: 5,
            backgroundColor: "#ffffff",
            p: 2,
            width: 800,
            border: 1,
            boxShadow: 2,
            borderColor: "#d2d6da",
            borderRadius: "0 0 10px 10px",
            zIndex: 1202,
            ml: 1.5,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  상세검색
                </SuiTypography>
                <SuiBox
                  ml={0.5}
                  mt={0.5}
                  component="img"
                  src="/reload.png"
                  alt="Soft UI Logo"
                  height="36px"
                  className="cursor"
                  onClick={() => FilterReload()}
                />
                <SuiButton
                  sx={{ fontSize: 14, width: 10, minWidth: 25, height: 20, ml: "auto", p: 0 }}
                  variant="text"
                  color="info2"
                  size="small"
                  onClick={() => filterSave()}
                >
                  저장
                </SuiButton>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  등록자 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.department === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <InputLabel className="smallLabel">부서</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      label="담당부서"
                      value={FilterDatas.department}
                      onChange={handleFilterDatas("department")}
                      className="setting-border-radius-small"
                    >
                      {teamListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="등록자 명"
                    value={FilterDatas.managerName}
                    onChange={handleFilterDatas("managerName")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  계약 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen6(!FiltersOpen6)}
                  >
                    <InputLabel className="smallLabel">구분</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen6(!FiltersOpen6)}
                        />
                      )}
                      open={FiltersOpen6}
                      label="담당부서"
                      value={FilterDatas.type}
                      onChange={handleFilterDatas("type")}
                      className="setting-border-radius-small"
                    >
                      {typeListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="닉네임"
                    value={FilterDatas.nickname}
                    onChange={handleFilterDatas("nickname")}
                  />
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="본명"
                    value={FilterDatas.name}
                    onChange={handleFilterDatas("name")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiBox sx={{ width: 192, height: 40, ml: 13 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.contractStatus === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen5(!FiltersOpen5)}
                  >
                    <InputLabel className="smallLabel">계약상태</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="black"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen5(!FiltersOpen5)}
                        />
                      )}
                      open={FiltersOpen5}
                      label="담당부서"
                      value={FilterDatas.contractStatus}
                      onChange={handleFilterDatas("contractStatus")}
                      className="setting-border-radius-small"
                    >
                      {outTypeListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <SuiInput
                    type="text"
                    placeholder="장르"
                    value={FilterDatas.genre}
                    onChange={handleFilterDatas("genre")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  단가 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="단가 최대값"
                    value={FilterDatas.maxPrice}
                    onChange={handleFilterDatas("maxPrice")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiButton
                  variant="text"
                  color="text2"
                  sx={{
                    ml: "auto",
                    mr: 2,
                    height: 40,
                    border: 1,
                    width: 93,
                    borderColor: "#707070",
                    fontSize: 14,
                  }}
                  onClick={() => filterClose()}
                >
                  취소
                </SuiButton>
                <SuiButton
                  color="primary"
                  sx={{ fontSize: 14, height: 40 }}
                  onClick={() => filterSearch()}
                >
                  이대로 검색하기
                </SuiButton>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <Paper sx={{ width: "100%", boxShadow: 0, marginTop: "-10px" }} className="main-layout">
          <SearchedField />
          {/* <SuiBox>
            <SuiButton
              sx={{ fontSize: 14, minWidth: 25, height: 20, ml: "auto", p: 0 }}
              color="info2"
              size="small"
              onClick={() => createDialog()}
            >
              등록(예비로 만들어둠)
            </SuiButton>
          </SuiBox> */}
          <SuiBox ml="" pl={0} pb={2} mx={0} display="flex" style={{ alignItems: "center" }}>
            <SuiBox
              mt={0.5}
              component="img"
              src="/reload.png"
              alt="Soft UI Logo"
              height="48px"
              className="cursor"
              onClick={() => TableReload()}
            />
            <SuiTypography
              style={{ fontSize: "16px", fontWeight: "medium" }}
              verticalAlign="middle"
            >
              총 계약 수:&nbsp;
            </SuiTypography>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "bold" }}>
              {rowlength} 건
            </SuiTypography>
            {myData.role.name !== "bronze" && myData.role.name !== "silver" ? (
              <>
                <SuiBox width="120px" height="44px" ml="auto" mr="-11px">
                  <FormControl
                    fullWidth
                    onClick={() => setFiltersOpen10(!FiltersOpen10)}
                    sx={{
                      "> div": {
                        minHeight: "30px",
                        border: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen10(!FiltersOpen10)}
                        />
                      )}
                      open={FiltersOpen10}
                      value={eventExcel}
                      onChange={(e) => seteventExcel(e.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="전체 내역">전체 내역</MenuItem>
                      <MenuItem value="현재 페이지">현재 페이지</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox
                  sx={{ height: "61px", marginTop: "5px", zIndex: 2 }}
                  component="img"
                  src="/excel.png"
                  alt="Soft UI Logo"
                  height="52px"
                  className="cursor"
                  onClick={excelExportActive}
                />
              </>
            ) : (
              <SuiBox ml="auto" height="52px" className="cursor" />
            )}
            <SuiBox width="70px" height="44px">
              <FormControl
                fullWidth
                onClick={() => setFiltersOpen4(!FiltersOpen4)}
                sx={{ "> div": { border: "none" } }}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen4(!FiltersOpen4)}
                    />
                  )}
                  open={FiltersOpen4}
                  value={FilterSearchActive ? searchRowsPerPage : rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {padingListSelects}
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
          <div style={{ display: "none" }}>
            <ReactHTMLTableToExcel
              id="excelExport"
              className="download-table-xls-button"
              table="table-to-xls-hidden"
              filename={`외주계약 리스트 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`}
              sheet="tablexls"
              buttonText={"Download as XLS"}
            />
          </div>

          <TableContainer sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
              />
              <TableBody>
                {stableSort(
                  FilterSearchActive
                    ? rows.slice(
                        (searchPage - 1) * searchRowsPerPage,
                        (searchPage - 1) * searchRowsPerPage + searchRowsPerPage
                      )
                    : rows,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.contractStatus}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {myData.role.name !== "bronze" && myData.role.name !== "silver"
                          ? row.name
                          : authroiztionData("name", row.name, row)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.nickname}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {comma(row.minPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {comma(row.maxPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {myData.role.name !== "bronze" && myData.role.name !== "silver"
                          ? row.email
                          : authroiztionData("email", row.email, row)}
                      </TableCell>
                      {miniSidenav === "false" ? (
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.phone}
                        </TableCell>
                      ) : null}
                      {miniSidenav === "false" ? (
                        <TableCell onClick={() => handleDetail(row)} align="center">
                          <Tooltip
                            arrow={false}
                            color="white"
                            className=""
                            classes={{ popper: "tooltip-light" }}
                            title={
                              <SuiBox>
                                <SuiTypography
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "pre-line",
                                  }}
                                  verticalAlign="middle"
                                >
                                  {row.comment
                                    ? row.comment.slice(0, 3).map((comment, index) => {
                                        return `[${row.comment.length - index}]_${
                                          comment.content
                                        }_${dayjs(comment.updated_at).format("YYYY.MM.DD")} \n`
                                      })
                                    : null}
                                </SuiTypography>
                              </SuiBox>
                            }
                          >
                            <SuiTypography
                              sx={{
                                padding: "0px !important",
                                margin: "0 auto",
                              }}
                              className="td-Setting td-fontSetting"
                            >
                              {row.comment ? row.comment[0].content : ""}
                            </SuiTypography>
                          </Tooltip>
                        </TableCell>
                      ) : null}
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.genre}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.workingLength}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {dayjs(row.updated_at).format("YY.MM.DD")}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {/* excel-export-table */}

            <Table
              style={{ display: "none" }}
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls-hidden"
            >
              {/* 총 수 row  - start */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>
                      <span>총 계약 수</span>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>
                      <span>{rows_hide.length}</span>
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* 총 수 row  - end */}
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
                showchangeFilter={changeFilter}
                excelTable={true}
              />
              <TableBody>
                {stableSort(rows_hide, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        onClick={() => handleDetail(row)}
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.contractStatus}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.nickname}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {comma(row.minPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {comma(row.maxPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.phone}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.comment
                          ? row.comment.slice(0, 3).map((comment, index) => {
                              return `[${row.comment.length - index}]_${comment.content}_${dayjs(
                                comment.updated_at
                              ).format("YYYY.MM.DD")} \n`
                            })
                          : null}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.genre}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.workingLength}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {dayjs(row.updated_at).format("YY.MM.DD")}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {/* 여기까지 */}
          </TableContainer>
          {FilterSearchActive ? (
            <SuiBox
              pt={3}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: searchTotalpage === 0 || searchTotalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom
                page={searchPage}
                totalPage={searchTotalpage}
                changePage={handlePageSearchChange}
              />
              {/* <Pagination
                page={searchPage}
                count={searchTotalpage}
                color="primary"
                onChange={handlePageSearchChange}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          ) : (
            <SuiBox
              pt={3}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: totalpage === 0 || totalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom page={page} totalPage={totalpage} changePage={handlePageChange} />
              {/* <Pagination
                page={page}
                count={totalpage}
                color="primary"
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          )}
          {FilterSearchActive ? (
            loading ? (
              <SuiBox
                py={2}
                mt="80px"
                display="flex"
                justifyContent="center"
                textAlign="center"
                sx={{ display: totalpage === 0 ? "" : "none" }}
              >
                <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }} color="black">
                  검색 결과가 없습니다.
                  <SuiTypography
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      textDecoration: "underline",
                      padding: "20px 0",
                    }}
                    color="info2"
                    onClick={() => resetSearch()}
                  >
                    다시 검색하기
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            ) : (
              <SuiBox py={2} ml={2} display="flex" sx={{ display: totalpage === 0 ? "" : "none" }}>
                <SuiTypography
                  style={{ cursor: "pointer", fontSize: "16px", textDecoration: "underline" }}
                  color="info2"
                  onClick={() => resetSearch()}
                >
                  <CircularProgress style={{ width: 20, height: 20 }} />
                </SuiTypography>
              </SuiBox>
            )
          ) : (
            ""
          )}
        </Paper>
      </Box>
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <SuiDialog
        openDialog={InfoAlert_Acess.dialog}
        closeDialog={() => {
          setInfoAlert(InfoAlertSet)
          navigate("/MyPage")
        }}
        MainTitle={InfoAlert_Acess.title}
        Content={InfoAlert_Acess.content}
        CanBtnText={InfoAlert_Acess.CanBtnText}
      />
    </DashboardLayout>
  )
}
