import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"

import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

import ContractInfosDialog from "../ContractInfosDialog"
import ContractInfosDialog2 from "../ContractInfosDialog2"
import DeleteDialog from "../deleteDialog"
import { MdOutlineDoubleArrow } from "react-icons/md"

// Dialog
import WriterDialog from "../../../writer"
import DistributorDialog from "../../../distributor"

// API
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import {
  updateCompany,
  updateWriter,
  updateChangeRequest,
  updateContract,
  updateCompanyContract,
  updateContractDocument,
  createAlert,
  updateOutsourcing,
  updateoutsourcingContract,
} from "apollo/mutation"
import {
  changeRequests,
  writersChangeList,
  companiesChangeList,
  companyContract,
  writerContract,
  user,
  writer,
  company,
  outsourcing,
} from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

import axios from "axios"

function changeData_card({ data, dataId, origin_data, reCallChangeData }) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [openAccess_Dialog, setopenAccess_Dialog] = useState(false)
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [distributorDialog, setdistributorDialog] = useState(false)
  const [writerDialog, setwriterDialog] = useState(false)
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [typeDistributor, settypeDistributor] = useState("detail")
  const [editId, seteditId] = useState(null)
  const [type, settype] = useState("detail")
  const [deleteDatas, setdeleteDatas] = useState({})
  const [ContractInfosDialogOpenBool, setContractInfosDialogOpenBool] = useState(false)
  const [ContractInfosData, setContractInfosData] = useState([])
  const [ContractInfosDataType, setContractInfosDataType] = useState("")
  const [ContractInfosDialogOpenBool2, setContractInfosDialogOpenBool2] = useState(false)
  const [ContractInfosData2, setContractInfosData2] = useState([])
  const { myData } = useSelector((state) => state.store)
  const [writerContractActive] = useLazyQuery(writerContract, {
    fetchPolicy: "network-only",
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [companyContractActive] = useLazyQuery(companyContract, {
    fetchPolicy: "network-only",
  })
  const [companiesChangeListActive] = useLazyQuery(companiesChangeList, {
    fetchPolicy: "network-only",
  })
  const [writersChangeListActive] = useLazyQuery(writersChangeList, {
    fetchPolicy: "network-only",
  })
  const openInfoDialog = (datas) => {
    // setContractInfosData(data)
    if (data.type === "Writer") {
      setContractInfosDataType("Writer")
      writerContractActive({
        fetchPolicy: "network-only",
        variables: {
          id: datas.writersContractId.id,
        },
      })
      writerContractActive({
        fetchPolicy: "network-only",
        variables: {
          id: datas.writersContractId.id,
        },
      }).then(({ data }) => {
        setContractInfosData(data.contractDocument.contractPaper)
        setContractInfosDialogOpenBool(true)
      })
    } else if (data.type === "Company") {
      setContractInfosDataType("Company")

      companyContractActive({
        variables: {
          id: data.companyContractId.id,
        },
      })
      companyContractActive({
        variables: {
          id: data.companyContractId.id,
        },
      }).then(({ data }) => {
        setContractInfosData(data.companyContract.contractPaper)
        setContractInfosDialogOpenBool(true)
      })
    } else if (data.type.indexOf("my") > -1) {
      if (data.targets && data.targets.length > 0) {
        setContractInfosData(data.targets)
      } else {
        setContractInfosData(data.contract_document)
      }
      setContractInfosDialogOpenBool(true)
      setContractInfosDataType(data.type)
    } else {
      let array = []
      data.companiesIds
        .map((x) => x.company_contracts)
        .forEach((el) => {
          if (el) {
            el.forEach((paper) => {
              array.push(paper.contractPaper)
            })
          }
        })
      data.outsourcingsIds
        .map((x) => x.outsourcing_contracts)
        .forEach((el) => {
          el &&
            el.forEach((paper) => {
              array.push(paper.contractPaper)
            })
        })
      data.writersIds
        .filter((y) => y.contracts && y.contracts.length > 0)
        .map((x) => x.contracts)
        .forEach((el) => {
          el.forEach((paper) => {
            if (paper.contract_documents.length > 0) {
              paper.contract_documents.forEach((contract) => {
                if (contract.users_permissions_user.id === data.request.id) {
                  array.push(contract.contractPaper)
                }
              })
            }
          })
        })
      setContractInfosData2(array)
      setContractInfosDialogOpenBool2(true)
    }
  }
  const changeCountSetting = (data) => {
    if (data.type.indexOf("my") > -1) {
      if (data.type.indexOf("contract") > -1) {
        // 마이페이지 - 작품 이관
        if (data.contract_document) {
          return data.contract_document.length
        } else {
          return 0
        }
      } else {
        // 마이페이지 - 작가,유통사,외주계약 이관
        if (data.targets_name) {
          return data.targets_name.length
        } else {
          return 0
        }
      }
    }
    let array = []
    data.companiesIds
      .map((x) => x.company_contracts)
      .forEach((el) => {
        el.forEach((paper) => {
          array.push(paper.contractPaper)
        })
      })
    data.outsourcingsIds
      .map((x) => x.outsourcing_contracts)
      .forEach((el) => {
        el &&
          el.forEach((paper) => {
            array.push(paper.contractPaper)
          })
      })
    data.writersIds
      .filter((y) => y.contracts && y.contracts.length > 0)
      .map((x) => x.contracts)
      .forEach((el) => {
        el.forEach((paper) => {
          if (paper.contract_documents.length > 0) {
            paper.contract_documents.forEach((contract) => {
              if (contract.users_permissions_user.id === data.request.id) {
                array.push(contract.contractPaper)
              }
            })
          }
        })
      })

    return array.length
  }
  const closeDialog = () => {
    setdistributorDialog(false)
    setwriterDialog(false)
  }
  const [updateContractDocumentActive] = useMutation(updateContractDocument, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateoutsourcingContractActive] = useMutation(updateoutsourcingContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyContractActive] = useMutation(updateCompanyContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateContractActive] = useMutation(updateContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateChangeRequestActive] = useMutation(updateChangeRequest, {
    fetchPolicy: "network-only",
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [changeRequests],
  })
  const [updateWriterActive] = useMutation(updateWriter, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyActive] = useMutation(updateCompany, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [userActive] = useLazyQuery(user, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
  })
  const [writerActive] = useLazyQuery(writer, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })

  const refuse_Change = () => {
    dispatch(loading_Apply(true))
    const content = { ...data }
    content["status"] = "거절"
    content["reject"] = requestExplainData
    // const body = {
    //   content: content,
    //   status: "거절",
    // }
    // console.log(content)
    // axios.put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + dataId, body, {
    //   headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
    // })
    updateChangeRequestActive({
      variables: {
        id: String(dataId),
        content: content.type.indexOf("my_") > -1 ? null : content,
        arrayContent: content.type.indexOf("my_") > -1 ? content : null,
        status: "거절",
      },
    }).then(() => {
      userActive({
        variables: {
          id: data.request.id,
        },
      }).then(({ data }) => {
        // 마이페이지 이관 - 작품
        if (content.type.indexOf("my_contract") > -1) {
          content.writersIds.forEach((writer, index) => {
            writerActive({
              variables: {
                id: writer,
              },
            }).then(({ data }) => {
              const editHistory = [...data.writer.history]
              let historyContent = "담당자 이관이 거절되었습니다."

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-change",
              })

              updateWriterActive({
                variables: {
                  id: writer,
                  history: editHistory,
                },
              })
            })
          })
        } else if (content.type.indexOf("my_writer") > -1) {
          // 마이페이지 이관 - 작가

          content.writersIds.forEach((writer, index) => {
            writerActive({
              variables: {
                id: writer,
              },
            }).then(({ data }) => {
              const editHistory = [...data.writer.history]
              let historyContent = "담당자 이관이 거절되었습니다."

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-change",
              })

              updateWriterActive({
                variables: {
                  id: writer,
                  history: editHistory,
                },
              })
            })
          })
        } else if (content.type.indexOf("my_distributor") > -1) {
          // 마이페이지 이관 - 유통사
          content.companiesIds.forEach((company, index) => {
            companyActive({
              variables: {
                id: company,
              },
            }).then(({ data }) => {
              const editHistory = [...data.company.history]

              let historyContent = "담당자 이관이 거절되었습니다."

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-change",
              })

              updateCompanyActive({
                variables: {
                  id: company,
                  history: editHistory,
                },
              })
            })
          })
        } else if (content.type.indexOf("my_outsourcing") > -1) {
          // 마이페이지 이관 - 외주계약
          content.outsourcingsIds.forEach((outsourcing, index) => {
            outsourcingActive({
              variables: {
                id: outsourcing,
              },
            }).then(({ data }) => {
              const editHistory = [...data.outsourcing.histroy]

              let historyContent = "담당자 이관이 거절되었습니다."

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-change",
              })

              updateOutsourcingActive({
                variables: {
                  id: outsourcing,
                  histroy: editHistory,
                },
              })
            })
          })
        }
        // 기존 거절
        else if (content.writersContractId) {
          writerActive({
            variables: {
              id: content.writersContractId.writerId,
            },
          }).then(({ data }) => {
            let contractIndex = data.writer.contracts.findIndex(
              (el) => el.contract_documents.findIndex((e) => e.id === content.contractId) !== -1
            )
            let titleIndex = data.writer.contracts[contractIndex].contract_documents.findIndex(
              (e) => e.id === content.contractId
            )

            let historyContent =
              "<strong>" +
              data.writer.contracts[contractIndex].contract_documents[titleIndex]
                .contractPaper[0][0].selectData[1].value +
              "</strong> 담당자 이관이 거절되었습니다."
            const editHistory = [...data.writer.history]
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-change",
              contractId: content.contractId,
            })

            updateWriterActive({
              variables: {
                id: content.writersContractId.writerId,
                history: editHistory,
              },
            })
          })
        }
        if (content.companyContractId) {
          companyActive({
            variables: {
              id: content.companyContractId.companyId,
            },
          }).then(({ data }) => {
            let titleIndex = data.company.company_contracts.findIndex(
              (e) => e.id === content.contractId
            )

            let historyContent =
              "<strong>" +
              data.company.company_contracts[titleIndex].contractPaper[0][0].selectData[0].value +
              "</strong> 담당자 이관이 거절되었습니다."
            const editHistory = [...data.company.history]
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-change",
              contractId: content.contractId,
            })

            updateCompanyActive({
              variables: {
                id: content.companyContractId.companyId,
                history: editHistory,
              },
            })
          })
        }

        if (data.user.nomalAlertSetting) {
          if (data.user.nomalAlertSetting[2].value) {
            createAlertActive({
              variables: {
                users_permissions_user: String(data.user.id),
                title: "계약 이관 거절",
                type: "change",
                userid: String(data.user.id),
                content: {
                  content: content,
                  position: myData.position,
                  department: myData.department,
                  name: myData.name,
                  requestExplainData: requestExplainData,
                  profile: myData.profile,
                },
              },
            })
          }
        }
      })
      setDelete_Dialog(false)
      // reCallChangeData()
      dispatch(loading_Apply(false))
    })
  }

  const access_Change = async () => {
    dispatch(loading_Apply(true))
    const content = { ...data }
    // content.status = "승인"
    // const body = {
    //   content: content,
    //   status: "승인",
    // }
    // axios.put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + dataId, body, {
    //   headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
    // })
    updateChangeRequestActive({
      variables: {
        id: String(dataId),
        // content: content.type.indexOf("my_") > -1 ? null : content,
        // arrayContent: content.type.indexOf("my_") > -1 ? content : null,
        status: "승인",
      },
    }).then(async () => {
      const content = { ...data }
      // 마이페이지 이관 - 작품
      if (data.type.indexOf("my_contract") > -1) {
        data.writersIds.forEach((writer, index) => {
          writerActive({
            variables: {
              id: writer,
            },
          }).then(({ data }) => {
            const editHistory = [...data.writer.history]
            let writer_contract = content.contract_document.filter((el) => el.writer === writer)
            writer_contract.forEach(async (el, idx) => {
              let historyContent =
                "<strong>" +
                el.contractPaper[0][0].selectData[1].value +
                "</strong> 담당자가 변경(" +
                content.request.name +
                " " +
                content.request.position +
                " → " +
                content.apply.name +
                " " +
                content.apply.position +
                ")"

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-change",
                contractId: el.id,
              })

              updateContractDocumentActive({
                variables: {
                  id: el.contract_id,
                  users_permissions_user: content.apply.id,
                },
              }).then(() => {
                if (idx === writer_contract.length - 1 && index === content.writersIds.length - 1) {
                  dispatch(loading_Apply(false))
                  setopenAccess_Dialog(false)
                }
              })
            })

            updateWriterActive({
              variables: {
                id: writer,
                history: editHistory,
              },
            })
          })
        })
      } else if (data.type.indexOf("my_writer") > -1) {
        // 마이페이지 이관 - 작가

        data.writersIds.forEach((writer, index) => {
          writerActive({
            variables: {
              id: writer,
            },
          }).then(({ data }) => {
            let new_users = data.writer.users
              .filter((el) => el.id !== content.request.id)
              .map((el) => el.id)
            new_users.unshift(content.apply.id)

            let new_manager = [
              {
                type: "manager",
                name: content.apply.name,
                position: content.apply.position,
                department: content.apply.department,
              },
            ]

            const editHistory = [...data.writer.history]
            let historyContent =
              "담당자 변경(" +
              content.request.name +
              " " +
              content.request.position +
              " → " +
              content.apply.name +
              " " +
              content.apply.position +
              ")"

            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "writer-change",
            })

            updateWriterActive({
              variables: {
                id: writer,
                history: editHistory,
                users: new_users,
                manager: new_manager,
              },
            }).then(() => {
              if (index === content.writersIds.length - 1) {
                dispatch(loading_Apply(false))
                setopenAccess_Dialog(false)
              }
            })
          })
        })
      } else if (data.type.indexOf("my_distributor") > -1) {
        // 마이페이지 이관 - 유통사
        data.companiesIds.forEach((company, index) => {
          companyActive({
            variables: {
              id: company,
            },
          }).then(({ data }) => {
            const editHistory = [...data.company.history]
            let historyContent =
              "담당자 변경(" +
              content.request.name +
              " " +
              content.request.position +
              " → " +
              content.apply.name +
              " " +
              content.apply.position +
              ")"

            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "distributor-change",
            })

            let new_users = data.company.users
              .filter((el) => el.id !== content.request.id)
              .map((el) => el.id)

            new_users.unshift(content.apply.id)

            let new_manager = [
              {
                type: "manager",
                name: content.apply.name,
                position: content.apply.position,
                department: content.apply.department,
              },
            ]

            updateCompanyActive({
              variables: {
                id: company,
                history: editHistory,
                users: new_users,
                manager: new_manager,
              },
            }).then(() => {
              if (index === content.companiesIds.length - 1) {
                dispatch(loading_Apply(false))
                setopenAccess_Dialog(false)
              }
            })
          })
        })
      } else if (data.type.indexOf("my_outsourcing") > -1) {
        // 마이페이지 이관 - 외주계약
        data.outsourcingsIds.forEach((outsourcing, index) => {
          outsourcingActive({
            variables: {
              id: outsourcing,
            },
          }).then(({ data }) => {
            const editHistory = [...data.outsourcing.histroy]

            let historyContent =
              "담당자 변경(" +
              content.request.name +
              " " +
              content.request.position +
              " → " +
              content.apply.name +
              " " +
              content.apply.position +
              ")"

            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "outsourcing-change",
            })

            updateOutsourcingActive({
              variables: {
                id: outsourcing,
                histroy: editHistory,
                user: content.apply.id,
              },
            }).then(() => {
              if (index === content.outsourcingsIds.length - 1) {
                dispatch(loading_Apply(false))
                setopenAccess_Dialog(false)
              }
            })
          })
        })
      }
      // 기존 이관
      else if (content.writersContractId) {
        writerActive({
          variables: {
            id: content.writersContractId.writerId,
          },
        }).then(({ data }) => {
          let contractIndex = data.writer.contracts.findIndex(
            (el) => el.contract_documents.findIndex((e) => e.id === content.contractId) !== -1
          )
          let titleIndex = data.writer.contracts[contractIndex].contract_documents.findIndex(
            (e) => e.id === content.contractId
          )

          let historyContent =
            "<strong>" +
            data.writer.contracts[contractIndex].contract_documents[titleIndex].contractPaper[0][0]
              .selectData[1].value +
            "</strong> 담당자가 변경(" +
            content.request.name +
            " " +
            content.request.position +
            " → " +
            content.apply.name +
            " " +
            content.apply.position +
            ")"
          const editHistory = [...data.writer.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: historyContent,
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
            type: "contract-change",
            contractId: content.contractId,
          })

          updateWriterActive({
            variables: {
              id: content.writersContractId.writerId,
              history: editHistory,
            },
          }).then(() => {
            updateContractDocumentActive({
              variables: {
                id: content.writersContractId.id,
                users_permissions_user: content.apply.id,
              },
            }).then(() => {
              dispatch(loading_Apply(false))
              setopenAccess_Dialog(false)
            })
          })
        })
      } else if (!content.change_type) {
        if (content.companyContractId) {
          companyActive({
            variables: {
              id: content.companyContractId.companyId,
            },
          }).then(({ data }) => {
            let contractIndex = data.company.company_contracts.findIndex(
              (el) => el.id === content.contractId
            )
            let historyContent =
              "<strong>" +
              data.company.company_contracts[contractIndex].contractPaper[0][0].selectData[0]
                .value +
              "</strong> 담당자가 변경(" +
              content.request.name +
              " " +
              content.request.position +
              " → " +
              content.apply.name +
              " " +
              content.apply.position +
              ")"
            const editHistory = [...data.company.history]
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-change",
              contractId: content.contractId,
            })
            updateCompanyActive({
              variables: {
                id: content.companyContractId.companyId,
                history: editHistory,
              },
            })
          })
        }

        // 작가, 작품
        if (content.writersIds.length > 0) {
          for (let i = 0; i < content.writersIds.length; i++) {
            let element = content.writersIds[i]
            const userIndex = element.users.indexOf(content.request.id)
            element.users.splice(userIndex, 1)
            element.users.push(content.apply.id)
            await client.mutate({
              mutation: updateWriter,
              variables: {
                id: element.id,
                users: element.users,
              },
              fetchPolicy: "network-only",
            })

            for (let j = 0; j < element.contracts.length; j++) {
              let contract = element.contracts[j]
              await client.mutate({
                mutation: updateContract,
                variables: {
                  id: contract.id,
                  user: content.apply.id,
                },
                fetchPolicy: "network-only",
              })
              contract.contract_documents.forEach(async (contract_doc) => {
                if (contract_doc.users_permissions_user.id === content.request.id) {
                  await client.mutate({
                    mutation: updateContractDocument,
                    variables: {
                      id: contract_doc.id,
                      users_permissions_user: content.apply.id,
                    },
                    fetchPolicy: "network-only",
                  })
                }
              })
            }
          }
        }
        // 유통사
        if (content.companiesIds.length > 0) {
          for (let i = 0; i < content.companiesIds.length; i++) {
            let element = content.companiesIds[i]
            const userIndex = element.users.indexOf(content.request.id)
            element.users.splice(userIndex, 1)
            element.users.push(content.apply.id)
            await client.mutate({
              mutation: updateCompany,
              variables: {
                id: element.id,
                users: element.users,
              },
              fetchPolicy: "network-only",
            })

            for (let j = 0; j < element.company_contracts.length; j++) {
              let contract = element.company_contracts[j]
              await client.mutate({
                mutation: updateCompanyContract,
                variables: {
                  id: contract.id,
                  users_permissions_user: content.apply.id,
                },
                fetchPolicy: "network-only",
              })
            }
          }
        }
        // 외주계약
        if (content.outsourcingsIds.length > 0) {
          for (let i = 0; i < content.outsourcingsIds.length; i++) {
            let element = content.outsourcingsIds[i]
            await client.mutate({
              mutation: updateOutsourcing,
              variables: {
                id: element.id,
                user: content.apply.id,
              },
              fetchPolicy: "network-only",
            })
            for (let j = 0; j < element.outsourcing_contracts.length; j++) {
              let contract = element.outsourcing_contracts[j]
              await client.mutate({
                mutation: updateoutsourcingContract,
                variables: {
                  id: contract.id,
                  users_permissions_user: content.apply.id,
                },
                fetchPolicy: "network-only",
              })
            }
            if (content.outsourcingsIds.length - 1 === i) {
              reCallChangeData()
              setopenAccess_Dialog(false)
              dispatch(loading_Apply(false))
            }
          }
        }
        if (content.writersContractId) {
          let usersArray = content.writersContractId.users.map((x) => x.id)
          usersArray.push(content.apply.id)
          updateWriterActive({
            variables: {
              id: content.writersContractId.writerId,
              users: usersArray,
            },
          }).then(() => {
            updateContractDocumentActive({
              variables: {
                id: content.writersContractId.id,
                users_permissions_user: content.apply.id,
              },
            }).then(() => {
              setopenAccess_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        } else {
          if (content.companyContractId) {
            let usersArray = content.companyContractId.users.map((x) => x.id)
            usersArray.push(content.apply.id)
            updateCompanyActive({
              variables: {
                id: content.companyContractId.companyId,
                users: usersArray,
              },
            }).then(() => {
              updateCompanyContractActive({
                variables: {
                  id: content.companyContractId.id,
                  users_permissions_user: content.apply.id,
                },
              }).then(() => {
                setopenAccess_Dialog(false)
                dispatch(loading_Apply(false))
              })
            })
          }
        }
      }
      if (content.change_type === "writer") {
        updateWriterActive({
          variables: {
            id: content.writersId,
            users: content.users,
          },
        }).then(() => {
          dispatch(loading_Apply(false))
          setopenAccess_Dialog(false)
        })
      } else if (content.change_type === "contract") {
        updateContractActive({
          variables: {
            id: content.contractsId,
            user: content.users,
          },
        }).then(() => {
          dispatch(loading_Apply(false))
          setopenAccess_Dialog(false)
        })
      } else if (content.change_type === "distributor") {
        updateCompanyActive({
          variables: {
            id: content.companiesId,
            user: content.users,
          },
        }).then(() => {
          dispatch(loading_Apply(false))
          setopenAccess_Dialog(false)
        })
      } else if (content.change_type === "outsourcing") {
        updateOutsourcingActive({
          variables: {
            id: content.outsourcingId,
            user: content.users,
          },
        }).then(() => {
          dispatch(loading_Apply(false))
          setopenAccess_Dialog(false)
        })
      }
      userActive({
        variables: {
          id: content.request.id,
        },
      }).then(({ data }) => {
        if (data.user.nomalAlertSetting) {
          if (data.user.nomalAlertSetting[2].value) {
            if (content.type.indexOf("my_") > -1) {
              // 마이 페이지 이관- 승인
              let count = 0

              if (content.type.indexOf("my_contract") > -1) {
                count = content.contractsIds?.length
              } else {
                count = content.targets_name?.length
              }
              createAlertActive({
                variables: {
                  users_permissions_user: String(content.request.id),
                  title: `${
                    content.type.indexOf("my_contract") > -1 ? "계약" : "담당자"
                  } 이관 승인`,
                  type: "change",
                  userid: String(content.request.id),
                  content: {
                    content: content,
                    position: content.request.position,
                    department: content.request.department,
                    name: content.request.name,
                    profile: content.request.profile,
                    targetName: content.targets_name,
                    selectData: content.targets_select,
                    count: count,
                  },
                },
              })
            } else {
              // 기존 이관- 승인
              createAlertActive({
                variables: {
                  users_permissions_user: String(content.request.id),
                  title: "계약 이관 승인",
                  type: "change",
                  userid: String(content.request.id),
                  content: {
                    content: content,
                    position: content.request.position,
                    department: content.request.department,
                    name: content.request.name,
                    profile: content.request.profile,
                    content: content,
                    targetName: content.targets_name,
                    selectData: content.targets_select,
                    count: 1,
                  },
                },
              })
            }
          }
        }
      })
      // [이관안내] '이관신청한 회원'님이 회원님에게 '이관신청한내용의위치'의 '이관신청한내용'을 이관하였습니다. 마이페이지에서 확인해주세요.
      userActive({
        variables: {
          id: content.apply.id,
        },
      }).then(({ data }) => {
        if (data.user.nomalAlertSetting) {
          if (data.user.nomalAlertSetting[2].value) {
            if (content.type.indexOf("my_") > -1) {
              // 마이 페이지 이관- 승인
              let count = 0

              if (content.type.indexOf("my_contract") > -1) {
                count = content.contractsIds?.length
              } else {
                count = content.targets_name?.length
              }
              createAlertActive({
                variables: {
                  users_permissions_user: String(content.apply.id),
                  title: `${
                    content.type.indexOf("my_contract") > -1 ? "계약" : "담당자"
                  } 이관 승인`,
                  type: "info",
                  userid: String(content.apply.id),
                  content: {
                    content: content,
                    position: content.request.position,
                    department: content.request.department,
                    name: content.request.name,
                    profile: content.request.profile,
                    targetName: content.targets_name,
                    selectData: content.targets_select,
                    count: count,
                  },
                },
              })
            } else {
              // 기존 이관- 승인
              createAlertActive({
                variables: {
                  users_permissions_user: String(content.apply.id),
                  title: "계약 이관 승인",
                  type: "info",
                  userid: String(content.apply.id),
                  content: {
                    content: content,
                    position: content.request.position,
                    department: content.request.department,
                    name: content.request.name,
                    profile: content.request.profile,
                    content: content,
                    targetName: content.targets_name,
                    selectData: content.targets_select,
                    count: 1,
                  },
                },
              })
            }
          }
        }
      })
    })
  }

  const [companyActive] = useLazyQuery(company, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [outsourcingActive] = useLazyQuery(outsourcing, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const setopenAccess_Dialog_Btn = (dialog_type) => {
    setrequestExplainData("")
    if (dialog_type === "refuse") {
      setDelete_Dialog(true)
    } else {
      setopenAccess_Dialog(true)
    }
  }
  return (
    <Card sx={{ mt: "16px", boxShadow: 0 }}>
      <div id="query-click"></div>
      <SuiBox p={5} display="flex" justifyContent="start">
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
          이관 신청_{dayjs(origin_data.created_at).format("YY.MM.DD HH:mm")}
        </SuiTypography>
        {data.status === "거절" ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="error" sx={{ ml: "auto" }}>
            승인거절_{dayjs(origin_data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : data.status.indexOf("취소") > -1 ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="secondary" sx={{ ml: "auto" }}>
            회원취소_{dayjs(origin_data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : null}
        {data.status === "승인" ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="info2" sx={{ ml: "auto" }}>
            승인완료_{dayjs(origin_data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : (
          ""
        )}
      </SuiBox>
      <SuiBox display="flex" justifyContent="center">
        <Card
          sx={{
            p: 4,
            mx: 2,
            border: 1,
            borderColor: "#CDD2D3",
            boxShadow: 0,
            width: 400,
          }}
        >
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{ textAlign: "center" }}
          >
            현재 담당자 정보
          </SuiTypography>
          <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap" }}>
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                  mb: 2,
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                    담당부서
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {data.request.department}
                  </SuiTypography>
                </SuiBox>
              </Card>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                    담당자
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353" }}
                  >
                    {data.request.name} {data.request.position}
                  </SuiTypography>
                </SuiBox>
              </Card>
            </SuiBox>
            <Card
              sx={{
                mt: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                width: "100%",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 136 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 4, color: "#535353" }}>
                  이관
                  <br />
                  {data.type &&
                  data.type.indexOf("my_") > -1 &&
                  data.type.indexOf("contract") === -1
                    ? "정보"
                    : "계약"}
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 6, mr: "auto" }}
                />

                {data.name ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{
                      mr: "auto",
                    }}
                  >
                    {data.name}
                    {
                      {
                        writer: " 작가님",
                        distributor: " 社",
                      }[data.change_type]
                    }
                  </SuiTypography>
                ) : (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{
                      mr: "auto",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#0C65FF",
                    }}
                    onClick={() => openInfoDialog(data)}
                  >
                    {data.type === "Writer / Company"
                      ? changeCountSetting(data) + "건"
                      : data.type.indexOf("my") === -1
                      ? "1건"
                      : changeCountSetting(data) + "건"}
                  </SuiTypography>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </Card>
        <IconButton color="info2" sx={{ mx: 0, cursor: "default" }}>
          <MdOutlineDoubleArrow size="30" />
        </IconButton>
        <Card
          sx={{
            p: 4,
            mx: 2,
            border: 1,
            borderColor: "#CDD2D3",
            boxShadow: 0,
            width: 400,
          }}
        >
          <SuiTypography
            fontSize="18px"
            fontWeight="bold"
            color="dark"
            sx={{ textAlign: "center" }}
          >
            이관 대상 담당자 정보
          </SuiTypography>
          <SuiBox display="flex" justifyContent="start" sx={{ mt: 4, flexFlow: "wrap" }}>
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                  mb: 2,
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ textAlign: "center", color: "#535353", pl: 1, pr: 1 }}
                  >
                    담당부서
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353", textAlign: "center" }}
                  >
                    {data.apply.department}
                  </SuiTypography>
                </SuiBox>
              </Card>
              <Card
                sx={{
                  px: 2,
                  border: 1,
                  borderColor: "#F1F1F5",
                  boxShadow: 0,
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: 60 }}
                >
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ ml: 2, color: "#535353", textAlign: "center" }}
                  >
                    담당자
                  </SuiTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                  />
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ mr: "auto", color: "#535353", textAlign: "center" }}
                  >
                    {data.apply.name} {data.apply.position}
                  </SuiTypography>
                </SuiBox>
              </Card>
            </SuiBox>
            <Card
              sx={{
                mt: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                width: "100%",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "auto" }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ ml: 4, whiteSpace: "nowrap", color: "#535353" }}
                >
                  이관사유
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 2, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{
                    mx: "auto",
                    ml: 0,
                    whiteSpace: "pre-line",
                    px: 2,
                    py: 1,
                    color: "#535353",
                  }}
                >
                  {data.reason}
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox p={5} display="flex" justifyContent="end">
        <SuiButton
          disabled={data.status.indexOf("신청") === -1}
          color="error"
          onClick={() => setopenAccess_Dialog_Btn("refuse")}
          sx={{ width: 100, mr: 2, fontSize: 14 }}
        >
          거절
        </SuiButton>
        <SuiButton
          disabled={data.status.indexOf("신청") === -1}
          color="primary"
          onClick={() => setopenAccess_Dialog_Btn("access")}
          sx={{ width: 100, fontSize: 14 }}
        >
          변경승인
        </SuiButton>
      </SuiBox>
      <SuiDialog
        openDialog={openAccess_Dialog}
        closeDialog={() => setopenAccess_Dialog(false)}
        MainTitle={"이관 신청 승인"}
        Content={"계약 이관 신청을 승인하시겠습니까?"}
        Active={() => access_Change()}
        CanBtnText={"취소"}
        CreBtnText={"변경승인"}
        AlertType={"twoBtn"}
        CreBtnColor={"primary"}
      />
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={"이관 신청 거절"}
        Content={"거절 사유를 작성하고, 계약 이관 신청을 미승인합니다."}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"승인거절"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => refuse_Change()}
        type={Delete_Dialog_type}
      />
      <WriterDialog open={writerDialog} closeDialog={closeDialog} editId={editId} type={type} />
      <DistributorDialog
        open={distributorDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
      />
      <ContractInfosDialog
        openDialog={ContractInfosDialogOpenBool}
        closeDialog={() => setContractInfosDialogOpenBool(false)}
        ContarctData={ContractInfosData}
        ContractInfosDataType={ContractInfosDataType}
      />
      <ContractInfosDialog2
        openDialog={ContractInfosDialogOpenBool2}
        closeDialog={() => setContractInfosDialogOpenBool2(false)}
        ContarctData={ContractInfosData2}
      />
    </Card>
  )
}
changeData_card.defaultProps = {
  data: {},
  dataId: null,
  origin_data: {},
}

changeData_card.propTypes = {
  data: PropTypes.object,
  dataId: PropTypes.string,
  origin_data: PropTypes.object,
}

export default changeData_card
