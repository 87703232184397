import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Slide from "@mui/material/Slide"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"

import SuiDialog from "components/SuiDialog"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import TabPanel from "./components/TabPanel"

import { BiUserPin, BiClipboard } from "react-icons/bi"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { RiBook2Line } from "react-icons/ri"
import { BiCalendarEvent } from "react-icons/bi"
import { MdClose } from "react-icons/md"

//API
import { useQuery, useMutation } from "@apollo/client"
import {
  createWriter,
  createAttachment,
  createMeeting,
  createRecent,
  createAlert,
  createWriterSignificant,
} from "apollo/mutation"
import { writer, writers, me } from "apollo/query"

//Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "../../reducers/store"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function WriterDialog({ open, closeDialog, editId, type, editData, tabActive }) {
  const dispatch = useDispatch()
  const { myData } = useSelector((state) => state.store)
  const { editStatus } = useSelector((state) => state.store)
  const [tab, setTab] = useState(tabActive ? tabActive : 0)
  const [InputLists, setInputLists] = useState([])
  const [referrerIds, setreferrerIds] = useState([])
  const [AttachmentDatas, setAttachmentDatas] = useState([])
  const [SignificantsDatas, setSignificantsDatas] = useState([])
  const [MeetingDatas, setMeetingDatas] = useState([])
  const [typeData, settypeData] = useState(type)
  const [editIdData, seteditIdData] = useState(editId)
  const [editDataSet, seteditDataSet] = useState(editData)
  useEffect(() => {
    if (tabActive) {
      setTimeout(() => {
        setTab(tabActive)
      }, 500)
    }
  }, [tabActive])
  useEffect(() => {
    const homeRef = document.getElementById("top-writer")
    if (homeRef) {
      setTimeout(() => {
        homeRef.scrollIntoView({ behavior: "smooth" })
      }, 300)
    }
  }, [tab])
  const [snackState, setsSnackState] = useState(false)
  const handleChange = (event, newValue) => {
    if (editStatus) {
      setsSnackState(true)
    } else {
      setTab(newValue)
    }
  }
  const [openDialog, setopenDialog] = useState(open)
  useEffect(() => {
    dispatch(loading_Apply(true))
    setTimeout(() => {}, 2000)
    seteditIdData(editId)
    settypeData(type)
    seteditDataSet(editData)
    dispatch(loading_Apply(false))
  }, [openDialog])
  const handleClose = () => {
    setTab(0)
    setopenDialog(false)
    closeDialog()
    setopenCancle_Dialog(false)
    setopenLeave_Dialog(false)
  }
  const [createWriterActive] = useMutation(createWriter, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("작가생성 오류 발생")
    },
    refetchQueries: [writers, me],
  })
  const [createAttachmentActive] = useMutation(createAttachment, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 생성 오류 발생")
    },
    refetchQueries: [writer],
  })
  const [createMeetingActive] = useMutation(createMeeting, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("회의록 생성시 오류 발생")
    },
  })
  const fileUploadActive = (file) => {
    let data = new FormData()
    data.append("files", file)
    axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
      setPFid(res.data[0].id)
      setPFurl(process.env.REACT_APP_BACKEND_URL + res.data[0].url)
    })
  }
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [createRecentActive] = useMutation(createRecent, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [me],
  })
  const [createWriterSignificantActive] = useMutation(createWriterSignificant, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const writerSave = () => {
    setopenSave_Dialog(false)
    let ok = 0
    InputLists.forEach((element) => {
      if (element.title === "이북 필명:" && element.value === "") {
        ok += 1
      }
      // if (element.title === "계좌번호:" && element.itemsValue !== "") {
      //   if (element.value === "") {
      //     ok += 1
      //   }
      // }
      // if (element.title === "계좌번호:" && element.value !== "") {
      //   if (element.itemsValue === "") {
      //     ok += 1
      //   }
      // }
      // if (element.title === "주민등록번호:" && element.value !== "") {
      //   if (element.value.indexOf("-") === -1) {
      //     ok += 1
      //   }
      //   if (element.error) {
      //     ok += 1
      //   }
      // }

      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (element.title === "이메일:" && element.value !== "") {
        if (!reEmail.test(element.value)) {
          ok += 1
        }
      }
    })
    const firstHistory = [
      {
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "최초 등록",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      },
    ]
    const firstManager = [
      {
        type: "manager",
        name: myData.name,
        position: myData.position,
        department: myData.department,
      },
    ]
    // let test =/[0-9~!@#$%^&*()-_:+={}]/

    if (ok === 0) {
      dispatch(loading_Apply(true))
      createWriterActive({
        variables: {
          name: InputLists[0].value,
          userid: InputLists[1].value,
          joaraname: InputLists[2].value,
          ebookname: InputLists[3].value,
          birth: CryptoJS.AES.encrypt(JSON.stringify(InputLists[4].value), secretKey).toString(),
          phone: CryptoJS.AES.encrypt(JSON.stringify(InputLists[5].value), secretKey).toString(),
          address: CryptoJS.AES.encrypt(JSON.stringify(InputLists[6].value), secretKey).toString(),
          email: CryptoJS.AES.encrypt(JSON.stringify(InputLists[8].value), secretKey).toString(),
          account: CryptoJS.AES.encrypt(
            JSON.stringify(InputLists[10].itemsValue + "/" + InputLists[10].value),
            secretKey
          ).toString(),
          users: [myData.id],
          referrer: referrerIds,
          history: firstHistory,
          manager: firstManager,
          accountName: InputLists[9].value,
          status: "공개",
          dal: InputLists[7].value
            ? String(InputLists[7].value)
            : String(
                (InputLists[4].value[2] === "0" ? "" : InputLists[4].value[2]) +
                  InputLists[4].value[3]
              ),
        },
      }).then((resWirter) => {
        createAlertActive({
          variables: {
            users_permissions_user: String(myData.id),
            title: "작가 등록_",
            type: "Writer",
            userid: String(myData.id),
            content: {
              id: myData.id,
              position: myData.position,
              department: myData.department,
              name: myData.name,
              title: InputLists[0].value,
              profile: myData.profile,
              targetName: InputLists[3].value,
            },
          },
        })
        referrerIds.forEach((referrer) => {
          if (referrer.nomalAlertSetting) {
            if (referrer.nomalAlertSetting[3].value) {
              createAlertActive({
                variables: {
                  users_permissions_user: String(referrer.id),
                  title: "작가 정보 참조자 등록",
                  type: "Writer",
                  userid: String(referrer.id),
                  content: {
                    id: myData.id,
                    position: myData.position,
                    department: myData.department,
                    name: myData.name,
                    title: InputLists[0].value,
                    profile: myData.profile,
                  },
                },
              })
            }
          }
        })
        createRecentActive({
          variables: {
            users_permissions_user: myData.id,
            title: "작가 정보 등록",
            type: "Writer",
            srcId: resWirter.data.createWriter.writer.id,
            name: InputLists[0].value,
          },
        })

        for (var i = 0; i < SignificantsDatas.length; i++) {
          if (!SignificantsDatas[i].id) {
            createWriterSignificantActive({
              variables: {
                content: SignificantsDatas[i].content,
                userName: SignificantsDatas[i].userName,
                userPosition: SignificantsDatas[i].userPosition,
                userProfile: SignificantsDatas[i].userProfile,
                userDepartment: SignificantsDatas[i].userDepartment,
                writer: resWirter.data.createWriter.writer.id,
                writerId: SignificantsDatas[i].writerId,
              },
            })
          }
        }

        if (AttachmentDatas.length > 0) {
          AttachmentDatas.map((x) => x.data).forEach((element, index) => {
            let data = new FormData()
            data.append("files", element[0].file)
            axios
              .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
              .then((res) => {
                createAttachmentActive({
                  variables: {
                    file: [res.data[0].id],
                    title: element[1].value,
                    type: element[1].itemsValue,
                    // explain: element[3].value,
                    writer: resWirter.data.createWriter.writer.id,
                    writerId: resWirter.data.createWriter.writer.id,
                    maker: myData.email,
                    users_permissions_user: myData.id,
                    position: myData.position,
                  },
                })
                if (AttachmentDatas.length - 1 === index) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                    settypeData("detail")
                    seteditDataSet({ bookname: resWirter.data.createWriter.writer.ebookname })
                    seteditIdData(resWirter.data.createWriter.writer.id)
                  }, 1000)
                }
              })
              .catch(() => dispatch(loading_Apply(false)))
          })
        } else {
          settypeData("detail")
          seteditDataSet({ bookname: resWirter.data.createWriter.writer.ebookname })
          seteditIdData(resWirter.data.createWriter.writer.id)
          dispatch(loading_Apply(false))
        }
      })
    } else {
      setopenInfo_Dialog(true)
    }
  }
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openSave_Dialog, setopenSave_Dialog] = useState(false)
  const [openCancle_Dialog, setopenCancle_Dialog] = useState(false)
  const [openLeave_Dialog, setopenLeave_Dialog] = useState(false)
  useEffect(() => {
    setopenDialog(open)
    if (editStatus) {
      dispatch(editStatus_Apply(false))
    }
  }, [open])

  const leaveCheck = () => {
    if (editStatus) {
      setopenLeave_Dialog(true)
    } else {
      handleClose()
    }
  }
  const saveExit = () => {
    document.getElementById("saveEditBtn").click()
    handleClose()
    setopenLeave_Dialog(false)
  }
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      sx={{ maxWidth: 1320, margin: "0 auto", marginTop: "136px" }}
    >
      <AppBar
        sx={{
          maxWidth: 1320,
          margin: "0 auto",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar>
          <SuiTypography
            sx={{ ml: 2, flex: 1, fontWeight: 700, color: "dark", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            {typeData === "detail" ? editDataSet.bookname + " 작가님" : "작가 등록"}
          </SuiTypography>
          {typeData === "detail" ? (
            ""
          ) : (
            <SuiButton
              sx={{
                fontWeight: 700,
                width: 20,
                minWidth: 60,
                height: 20,
                ml: "auto",
                p: 0,
                mr: 2,
                fontSize: 16,
              }}
              color="text2"
              variant="text"
              size="large"
              onClick={() => setopenCancle_Dialog(true)}
            >
              취소
            </SuiButton>
          )}
          {typeData === "detail" ? (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => leaveCheck()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          ) : (
            <SuiButton
              sx={{
                fontWeight: 700,
                fontSize: 16,
                width: 20,
                minWidth: 70,
                height: 20,
                ml: "auto",
                p: 0,
              }}
              variant="text"
              color="info2"
              size="large"
              onClick={() => setopenSave_Dialog(true)}
              // onClick={() => writerSave()}
            >
              등록하기
            </SuiButton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className="dialog-menu-tabs"
        id="top-writer"
      >
        <SuiBox
          sx={{
            maxWidth: 1320,
            margin: "0 auto",
            position: "fixed",
            top: 64,
            backgroundColor: "#ffffff",
            width: "100%",
            borderTop: 1,
            borderBottom: 1,
            borderColor: "#CED4D7",
            zIndex: 10,
          }}
          fullWidth
        >
          <Tabs value={tab} onChange={handleChange} centered>
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiUserPin />}
              iconPosition="start"
              label="기본 정보"
            />
            <Tab
              className={typeData === "create" ? "tab-disabled" : ""}
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<RiBook2Line />}
              iconPosition="start"
              label="계약 작품"
            />
            <Tab
              className={typeData === "create" ? "tab-disabled" : ""}
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiCalendarEvent />}
              iconPosition="start"
              label="이벤트 현황"
            />
            <Tab
              className={typeData === "create" ? "tab-disabled" : ""}
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiClipboard />}
              iconPosition="start"
              label="회의록"
            />
          </Tabs>
        </SuiBox>
        <TabPanel
          value={tab}
          index={0}
          editId={editIdData}
          type={typeData}
          inputListsExport={setInputLists}
          referrerIdsExport={setreferrerIds}
          AttachmentDatasExport={setAttachmentDatas}
          MeetingDatasExport={setMeetingDatas}
          SignificantsDatasExport={setSignificantsDatas}
        >
          작가 기본 정보
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          editId={editIdData}
          type={typeData}
          inputListsExport={setInputLists}
          referrerIdsExport={setreferrerIds}
          AttachmentDatasExport={setAttachmentDatas}
          MeetingDatasExport={setMeetingDatas}
          SignificantsDatasExport={setSignificantsDatas}
        >
          계약 작품 정보
        </TabPanel>
        <TabPanel
          value={tab}
          index={2}
          editId={editIdData}
          type={typeData}
          inputListsExport={setInputLists}
          referrerIdsExport={setreferrerIds}
          AttachmentDatasExport={setAttachmentDatas}
          MeetingDatasExport={setMeetingDatas}
          SignificantsDatasExport={setSignificantsDatas}
        >
          이벤트 참여 현황
        </TabPanel>
        <TabPanel
          value={tab}
          index={3}
          editId={editIdData}
          type={typeData}
          inputListsExport={setInputLists}
          referrerIdsExport={setreferrerIds}
          AttachmentDatasExport={setAttachmentDatas}
          MeetingDatasExport={setMeetingDatas}
          SignificantsDatasExport={setSignificantsDatas}
        >
          회의록
        </TabPanel>
      </Box>
      <SuiDialog
        openDialog={openInfo_Dialog}
        closeDialog={() => setopenInfo_Dialog(false)}
        MainTitle={"정보 입력 오류"}
        Content={"올바른 개인 상세 정보를 입력해 주세요."}
        CanBtnText={"확인"}
      />
      <SuiDialog
        openDialog={openSave_Dialog}
        closeDialog={() => setopenSave_Dialog(false)}
        MainTitle={"등록하기"}
        Content={"정보 등록 후에는 삭제하실 수 없습니다. 이대로 등록하시겠습니까?"}
        CanBtnText={"취소"}
        CreBtnText={"등록하기"}
        CreBtnColor="info2"
        AlertType="twoBtn"
        Active={() => writerSave()}
      />
      <SuiDialog
        openDialog={openCancle_Dialog}
        closeDialog={() => setopenCancle_Dialog(false)}
        MainTitle={"등록 취소하기"}
        Content={"작성하신 내용을 저장하지 않고 나가시겠습니까?"}
        CanBtnText={"취소"}
        CreBtnText={"나가기"}
        CreBtnColor="error"
        AlertType="twoBtn"
        Active={() => handleClose()}
      />
      <SuiDialog
        openDialog={openLeave_Dialog}
        closeDialog={() => setopenLeave_Dialog(false)}
        MainTitle={"저장하고 나가기"}
        Content={"정보 변경 또는 추가 후 저장하지 않은 내역이 있습니다. 저장하고 나가시겠습니까?"}
        CanBtnText={"저장안함"}
        CreBtnText={"저장함"}
        exitBtnText={"취소"}
        CreBtnColor="info2"
        AlertType="threeBtn"
        Active={() => saveExit()}
        ActiveLeave={() => handleClose()}
      />
      <Snackbar
        className="snackBar-center"
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackState}
        message="저장하기' 또는 '취소'버튼을 클릭해야 이동할 수 있습니다"
        onClose={() => setsSnackState(false)}
      />
    </Dialog>
  )
}

WriterDialog.defaultProps = {
  open: false,
  type: "create",
  editData: { writername: "" },
  tabActive: 0,
}

WriterDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
  editId: PropTypes.string,
  editData: PropTypes.object,
  tabActive: PropTypes.number,
}

export default WriterDialog
