import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"

import Divider from "@mui/material/Divider"
import SuiDialog from "components/SuiDialog"

import ContractInfoDialog from "../ContractInfoDialog"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"

import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { FiSearch } from "react-icons/fi"
import { MdClose } from "react-icons/md"
import GetFileIcon from "components/getFileIcon"

// API
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"
import { writerContract } from "apollo/query"
import { createDonwloadLog } from "apollo/mutation"
import { set } from "date-fns"
function MeetingDetailDialog({ openDialog, closeDialog, detailData }) {
  const [open, setOpen] = useState(openDialog)
  const [Files, setFiles] = useState([])
  const [openContractInfoDialog, setopenContractInfoDialog] = useState(false)
  const [openNoContract_dialog, setopenNoContract_dialog] = useState(false)
  const [InfoData, setInfoData] = useState([])
  const closeDialogInfo = () => {
    setopenContractInfoDialog(false)
  }
  const openInfoDialog = (userInfoData) => {
    const data = detailData.data[1].contract_document.contractPaper[0]
    data[4] = userInfoData
    setInfoData(data)
    setopenContractInfoDialog(true)
  }
  const [writerContractActive] = useLazyQuery(writerContract, {
    fetchPolicy: "network-only",
  })

  const clickContract = (id) => {
    writerContractActive({
      fetchPolicy: "network-only",
      variables: {
        id: detailData.data[1].contract_document.id,
      },
    }).then(({ data }) => {
      if (data.contractDocument.contract === null) {
        // dispatch(loading_Apply(false))
        setopenNoContract_dialog(true)
      } else {
        openInfoDialog(data)
      }
    })
  }

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])
  useEffect(() => {
    if (detailData.data) {
      if (detailData.data[2].files.length > 0) {
        let array = []
        detailData.data[2].files.forEach((element) => {
          array.push({ file: element.file })
        })
        setFiles(array)
      }
    }
  }, [detailData])
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const showPdf = (file) => {
    if (file.url) {
      var a = document.createElement("a")
      a.href = process.env.REACT_APP_BACKEND_URL + file.url
      a.target = "_blank"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
    }
  }

  const handleClose = () => {
    closeDialog()
    setFiles([])
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <SuiBox sx={{ p: 5, height: 700 }}>
          <SuiBox display="flex" sx={{ alignItems: "center" }}>
            <Chip
              label={detailData.data[3].value}
              sx={{
                backgroundColor:
                  detailData.data[3].value === "회의록"
                    ? "#FBE8F1"
                    : detailData.data[3].value === "인터뷰"
                    ? "#E1EFF6"
                    : detailData.data[3].value === "인수인계"
                    ? "#CBFFE5"
                    : "",
                color:
                  detailData.data[3].value === "회의록"
                    ? "#E67DAF"
                    : detailData.data[3].value === "인터뷰"
                    ? "#668B9E"
                    : detailData.data[3].value === "인수인계"
                    ? "#0E914F"
                    : "",
                borderRadius: "8px",
              }}
            />
            <SuiTypography
              fontSize="18px"
              fontWeight="bold"
              color="dark"
              sx={{ ml: 1.5, mr: "auto" }}
            >
              {detailData.data[0].value}
            </SuiTypography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiTypography fontSize="14px" color="secondary">
            {detailData.data[1].department} {detailData.data[1].name} {detailData.data[1].position}{" "}
            ·{dayjs(detailData.data[1].created_at).format("YYYY.MM.DD. Ahh:mm")}
          </SuiTypography>
          <SuiTypography
            fontSize="18px"
            color="dark"
            sx={{ py: 2.5, height: 400, overflowY: "auto" }}
          >
            <span
              className="ql-snow textOverFlow2"
              dangerouslySetInnerHTML={{ __html: detailData.data[2].value }}
            ></span>
          </SuiTypography>
          {detailData.data[3].value2 !== "전체 공개" ? <Divider sx={{ my: 2 }} /> : ""}
          {detailData.data[3].value2 !== "전체 공개" ? (
            <SuiBox display="flex">
              <SuiTypography fontSize="14px" color="secondary">
                관련계약:
              </SuiTypography>
              <SuiTypography
                fontSize="14px"
                color="info2"
                sx={{ ml: 1, textDecoration: "underline", cursor: "pointer" }}
                // onClick={() => openInfoDialog()}
                onClick={() => clickContract()}
              >
                {detailData.data[1].contract_document ? detailData.data[1].value : ""}
              </SuiTypography>
            </SuiBox>
          ) : (
            ""
          )}
          {detailData.data[3].value2 !== "전체 공개" ? (
            <SuiBox sx={{ mt: 1.3, height: 100, overflowY: "auto" }}>
              {detailData.data[2].files.map((file, index) => {
                const fileName = file.file?.name
                const fileType = fileName.split(".")[fileName.split(".").length - 1]
                return (
                  <SuiBox
                    key={index}
                    justifyContent="start"
                    display="flex"
                    alignItems="start"
                    sx={{ height: "auto", pt: 0 }}
                  >
                    <IconButton sx={{ mr: 1, p: 0, pt: 1 }} className="cursorDefalut">
                      {GetFileIcon(fileType)}
                    </IconButton>
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="bold"
                      color="dark"
                      sx={{ pt: 1, pb: 0, cursor: "pointer" }}
                      onClick={() => DownloadPdf(file.file)}
                    >
                      {file.file.name}
                    </SuiTypography>
                    {file.file.url ? (
                      <div style={{ width: "107px" }}>
                        <SuiButton
                          sx={{
                            backgroundColor: "#EEEEEE",
                            ml: "auto",
                            minHeight: 24,
                            height: 24,
                            width: 24,
                            minWidth: 24,
                            p: 0,
                            ml: 1,
                            boxShadow: 0,
                          }}
                          onClick={() => showPdf(file.file)}
                        >
                          <FiSearch size="15" />
                        </SuiButton>
                        {/* <SuiButton
                          color="info"
                          sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                          onClick={() => DownloadPdf(file.file)}
                        >
                          다운로드
                        </SuiButton> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </SuiBox>
                )
              })}
            </SuiBox>
          ) : (
            ""
          )}
        </SuiBox>
        {openContractInfoDialog ? (
          <ContractInfoDialog
            openDialog={openContractInfoDialog}
            closeDialog={closeDialogInfo}
            infoData={InfoData}
          />
        ) : (
          ""
        )}
        <SuiDialog
          openDialog={openNoContract_dialog}
          closeDialog={() => setopenNoContract_dialog(false)}
          MainTitle={"삭제된 계약 알림"}
          Content={"담당자의 요청에 의해 이미 삭제된 계약 건입니다."}
          CanBtnText={"확인"}
        />
      </Dialog>
    </div>
  )
}

MeetingDetailDialog.defaultProps = {
  openDialog: false,
  detailData: {
    data: [
      {
        value: "",
        inputType: "text",
        error_message: "",
        error: false,
        type: "textFiled",
        title: "제목",
        maxLength: 100,
        xs: 8,
        changed: false,
      },
      {
        items: [],
        value: "",
        error_message: "",
        error: false,
        type: "selectBox",
        title: "관련계약",
        maxLength: 100,
        xs: 4,
        name: "",
        position: "",
        department: "",
        created_at: new Date(),
      },
      {
        type: "fileList",
        value: "",
        title: "내용",
        xs: 8,
        files: [],
      },
      {
        inputType: "text",
        value: "",
        items: ["회의록", "인터뷰", "인수인계", "기타"],
        type: "category",
        title: "카테고리",
        xs: 4,
      },
    ],
  },
}

MeetingDetailDialog.propTypes = {
  openDialog: PropTypes.bool,
  detailData: PropTypes.object,
  closeDialog: PropTypes.func,
}
export default MeetingDetailDialog
