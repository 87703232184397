import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Avatar from "@mui/material/Avatar"
import Checkbox from "@mui/material/Checkbox"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import SuiDialog from "components/SuiDialog"

import { useMutation, useLazyQuery } from "@apollo/client"
import { users, roleCountUser, settings } from "apollo/query"
import { fileUpload, register, updateUserMu, resetPass } from "apollo/mutation"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { AiOutlineUser } from "react-icons/ai"

// Reducers
import { useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function CuDailog({ openDialog, closeDialog, Users, updateUser, editData, edit }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [UsersData, setUsersData] = useState([])
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openOut_Dialog, setopenOut_Dialog] = useState(false)
  const [openOut_Dialog_Content, setopenOut_Dialog_Content] = useState("")
  const [open_disable_delete, setopen_disable_delete] = useState(false)
  const [PFurl, setPFurl] = useState("")
  const [PFid, setPFid] = useState(null)
  const [department_list, setdepartment_list] = useState([])
  useEffect(() => {
    if (open) settings_Call()
  }, [open])

  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const settings_Call = () => {
    settingsActive({
      variables: {
        type: "department",
      },
    }).then((res) => {
      let list = res.data.settings[0].list
      let input_lists = [...InputLists]
      input_lists[3].items = list
      setInputLists(input_lists)
      setdepartment_list(list)
    })
  }

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const InputListsSet = [
    { type: "pic", title: "프로필 사진:", xs: 4 },
    {
      file: null,
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이름:",
      maxLength: 100,
      xs: 4,
    },
    {
      inputType: "password",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "비밀번호:",
      maxLength: 255,
      xs: 4,
    },
    {
      open: false,
      items: [],
      value: "",
      type: "selectBox",
      title: "부서:",
      xs: 4,
    },
    {
      open: false,
      items: ["대표이사", "본부장", "실장", "팀장", "파트장", "프로", "PD"],
      value: "프로",
      type: "selectBox",
      title: "직위:",
      xs: 4,
    },
    {
      open: false,
      items: ["슈퍼어드민", "골드", "실버", "브론즈"],
      value: "브론즈",
      type: "selectBox",
      title: "회원등급:",
      xs: 4,
    },
    {
      items: ["근무", "휴직"],
      value: "근무",
      type: "selectBox",
      title: "근무상태:",
      xs: 3,
    },
    {
      inputType: "email",
      value: "",
      error_message: "",
      success: false,
      error: false,
      type: "textFiled",
      title: "이메일:",
      maxLength: 255,
      xs: 5,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "연락처:",
      maxLength: 13,
      xs: 4,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  const roleText = (value) => {
    if (value === "Authenticated") {
      return "슈퍼어드민"
    } else if (value === "gold") {
      return "골드"
    } else if (value === "silver") {
      return "실버"
    } else if (value === "bronze") {
      return "브론즈"
    } else {
      return "오류"
    }
  }
  useEffect(() => {
    setUsersData(Users)
    if (edit) {
      if (editData.name) {
        if (editData.profile) {
          setPFid(editData.profile.id)
          setPFurl(process.env.REACT_APP_BACKEND_URL + editData.profile.url)
        }
        InputLists[1].value = editData.name
        InputLists[2].value = "**********"
        InputLists[3].value = editData.department
        InputLists[4].value = editData.position
        InputLists[5].value = roleText(editData.role.name)
        InputLists[6].value = editData.status
        InputLists[7].value = editData.email
        InputLists[8].value = editData.phone
        if (editData.accessTabs) {
          let access_data = [...accesses]
          access_data.forEach((el) => {
            if (editData.accessTabs.split(",").indexOf(el.value) > -1) {
              el.checked = true
            } else {
              el.checked = false
            }
          })
          setaccesses(access_data)
        }
      }
    }
  }, [editData, Users])
  const handleInput = (index, maxLength) => (e) => {
    let array = [...InputLists]
    let UsersSetting = edit ? Users.filter((x) => x.email !== editData.email) : Users
    let reEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
      if (index === 2 && !rePassword.test(e.target.value) && !edit) {
        array[index].error = true
        array[index].error_message = "10자리 이상(영문+특수)"
      } else if (index === 7 && !reEmail.test(e.target.value)) {
        array[index].error = true
        array[index].error_message = "이메일 형식이 아닙니다."
      } else if (index === 7 && UsersSetting.map((x) => x.email).indexOf(e.target.value) > -1) {
        array[index].error = true
        array[index].success = false
        array[index].error_message = "*사용할 수 없는 이메일입니다."
      } else if (index === 7 && UsersSetting.map((x) => x.email).indexOf(e.target.value) === -1) {
        array[index].error = false
        array[index].success = true
        array[index].error_message = "*사용가능한 이메일입니다."
      } else if (index === 8) {
        array[index].value = array[index].value
          .replace(/[^0-9]/, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
        array[index].error = false
        array[index].error_message = ""
      } else {
        array[index].error = false
        array[index].error_message = ""
      }
    }
    setInputLists(array)
  }
  const changeOpen = (open, index) => {
    let array = [...InputLists]
    array[index].open = open
    setInputLists(array)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].value = e.target.value
    setInputLists(array)
  }
  const [updateUserActive] = useMutation(updateUserMu, {
    onError(err) {},
  })

  const [resetPasswordActive] = useMutation(resetPass, {
    onError(err) {},
  })

  const [registerActive] = useMutation(register, {
    onError(err) {},
  })
  const fileUploadActive = (file) => {
    let data = new FormData()
    data.append("files", file)
    axios.post(process.env.REACT_APP_BACKEND_URL + "/upload", data).then((res) => {
      setPFid(res.data[0].id)
      setPFurl(process.env.REACT_APP_BACKEND_URL + res.data[0].url)
    })
  }
  const handlePFChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].file = e.target.files[0]
    fileUploadActive(e.target.files[0])
    setInputLists(array)
  }
  const PFImgShow = () => {
    if (PFurl === "") {
      return (
        <IconButton
          disabled
          color="dark"
          className="profile-defalut-icon"
          size="large"
          sx={{ mr: 2 }}
        >
          <AiOutlineUser />
        </IconButton>
      )
    } else {
      return (
        <span>
          <Avatar alt="Profile Img" src={PFurl} sx={{ width: 52, height: 52, mr: 2 }} />
        </span>
      )
    }
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const imgupload = () => {
    document.getElementById("user-img").click()
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiInput
          placeholder={data.inputType === "password" ? "특수문자 포함 10자리 이상" : ""}
          error={data.error}
          success={data.success}
          helperText={data.error_message}
          type={data.inputType}
          value={data.value}
          disabled={data.value === "**********"}
          onChange={handleInput(index, data.maxLength)}
        />
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox>
          <FormControl
            fullWidth
            className={
              data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => changeOpen(!data.open, index)}
          >
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  onClick={() => changeOpen(data.open, index)}
                />
              )}
              open={data.open}
              value={data.value}
              onChange={handleSelectChange(index)}
              className="setting-border-radius"
            >
              {data.items.map((x) => {
                return (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    } else if (data.type === "pic") {
      return (
        <SuiBox display="flex" alignItems="center">
          {PFImgShow()}
          <SuiButton
            sx={{ height: 30, backgroundColor: "#707070", color: "White" }}
            size="small"
            color="subTitle"
            onClick={() => imgupload()}
          >
            변경
          </SuiButton>
          <input
            onChange={handlePFChange(index)}
            type="file"
            id="user-img"
            accept="image/*"
            style={{ display: "none" }}
          ></input>
        </SuiBox>
      )
    }
  }
  const roleSetting = (value) => {
    if (value === "슈퍼어드민") {
      return 1
    } else if (value === "골드") {
      return 3
    } else if (value === "실버") {
      return 4
    } else if (value === "브론즈") {
      return 5
    }
  }
  const updateUserAct = (users) => {
    updateUser(users)
  }
  const CUcheck = () => {
    let ok = 0
    let array = [...InputLists]
    let UsersSetting = edit ? Users.filter((x) => x.email !== editData.email) : Users
    let reEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let rePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/
    array.forEach((el, index) => {
      if (el.type === "textFiled" && el.value === "") {
        el.error = true
        el.error_message = "필수값을 입력해 주세요."
        ok += 1
      } else if (index === 2 && !rePassword.test(el.value) && !edit) {
        el.error = true
        el.error_message = "10자리 이상(영문+특수)"
        ok += 1
      } else if (index === 7 && !reEmail.test(el.value)) {
        el.error = true
        el.error_message = "이메일 형식이 아닙니다."
        ok += 1
      } else if (index === 7 && UsersSetting.map((x) => x.email).indexOf(el.value) > -1) {
        el.error = true
        el.success = false
        el.error_message = "*사용할 수 없는 이메일입니다."
        ok += 1
      }
    })

    setInputLists(array)
    let access_seleced = accesses.filter((el) => el.checked)
    let access_value = ""
    if (access_seleced.length > 0) {
      access_value = access_seleced.map((el) => el.value).join(",")
    }
    let data = {
      name: InputLists[1].value,
      // password: InputLists[2].value,
      department: InputLists[3].value,
      position: InputLists[4].value,
      status: InputLists[6].value,
      role: roleSetting(InputLists[5].value),
      email: InputLists[7].value,
      username: InputLists[7].value,
      phone: InputLists[8].value,
      accessTabs: access_value,
    }
    if (!edit) {
      data.password = InputLists[2].value
    }
    if (PFid) {
      data.profile = PFid
    }
    if (ok === 0) {
      if (edit) {
        data.id = editData.id
        dispatch(loading_Apply(true))
        updateUserActive({
          variables: data,
        })
      } else {
        data.nomalAlertSetting = [
          { value: true },
          { value: true },
          { value: true },
          { value: true },
        ]
        data.adminAlertSetting = [
          { value: true },
          { value: true },
          { value: true },
          { value: false },
        ]
        registerActive({
          variables: data,
        })
      }
      dispatch(loading_Apply(false))
      document.getElementById("reset-table").click()
      setPFurl("")
      setInputLists(InputListsSet)
      setaccesses(accessDefault)
      setOpen(false)
      closeDialog()
    }
  }
  const handleClose = () => {
    closeDialog()
    setPFurl("")
    setInputLists(InputListsSet)
    setaccesses(accessDefault)
    setOpen(false)
    setopenInfo_Dialog(false)
  }
  const handleCloseOut = () => {
    setopenOut_Dialog(false)
  }
  const handleOut = () => {
    let companiesIds = []
    editData.companies.forEach((element) => {
      companiesIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        company_contracts: element.company_contracts.filter(
          (x) => x.users_permissions_user.id === editData.id
        ),
      })
    })
    let writersIds = []
    editData.writers.forEach((element) => {
      writersIds.push({
        id: element.id,
        users: element.users.map((x) => x.id),
        contracts: element.contracts,
      })
    })

    if (writersIds.length > 0 || companiesIds.length > 0 || editData.outsourcings.length > 0) {
      return setopen_disable_delete(true)
    }
    setopenOut_Dialog(true)
    setopenOut_Dialog_Content(
      "회원 탈퇴 처리를 하시겠습니까? \n 탈퇴처리 완료 시 정보 추가 및 수정이 불가능합니다."
    )
  }
  const OutActive = () => {
    let data = {
      id: editData.id,
      status: "퇴사",
      blocked: true,
    }
    updateUserActive({
      variables: data,
    })
    dispatch(loading_Apply(false))
    document.getElementById("reset-table").click()
    handleCloseOut()
    handleClose()
  }

  const [open_reset_password, setopen_reset_password] = useState(false)
  const reset_password = () => {
    dispatch(loading_Apply(true))
    resetPasswordActive({
      variables: {
        id: editData.id,
        password: "joara1234567!",
      },
    })
      .then((res) => {
        setopen_reset_password(false)
        dispatch(loading_Apply(false))
        if (!res.data.resetPass.ok) {
          alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.")
        }
      })
      .catch((err) => {
        setopen_reset_password(false)
        dispatch(loading_Apply(false))
        alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.")
      })
  }

  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 5 }}>
        <SuiBox sx={{ position: "relative" }}>
          <SuiTypography fontSize="15px" sx={{ pt: 2, pb: 1, fontWeight: 400, color: "#668B9E" }}>
            <span>{row.title}</span>

            {row.inputType === "password" && edit ? (
              <SuiButton
                color="secondary"
                variant="outlined"
                onClick={() => setopen_reset_password(true)}
                sx={{
                  height: 40,
                  fontSize: 14,
                  minHeight: 0,
                  position: "absolute",
                  right: 0,
                  top: "0.7rem",
                  height: "2rem",
                  padding: 0,
                }}
              >
                초기화
              </SuiButton>
            ) : null}
          </SuiTypography>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const accessDefault = [
    { text: "작품/작가", value: "wri", checked: false },
    { text: "유통사", value: "com", checked: false },
    { text: "외주계약", value: "out", checked: false },
    { text: "이벤트", value: "eve", checked: false },
  ]
  const [accesses, setaccesses] = useState(accessDefault)

  const accessShow = () => {
    const handleCheckBox = (access, index) => {
      let data = [...accesses]
      data[index].checked = !data[index].checked
      setaccesses(data)
    }
    return (
      <SuiBox sx={{ textAlign: "start", ml: "0.5rem" }} display="flex">
        <SuiTypography
          variant="div"
          fontWeight="regular"
          sx={{ mr: 1, mt: 1, fontSize: 14 }}
          className="letter-0-8px"
          color={"info2"}
        >
          권한:
        </SuiTypography>
        {accesses.map((access, index) => {
          return (
            <div key={index}>
              <Checkbox
                checked={access.checked}
                onChange={() => handleCheckBox(access, index)}
                sx={{ p: 0 }}
                color="primary"
              />
              <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={() => handleCheckBox(access, index)}
                sx={{ cursor: "pointer", userSelect: "none", mr: 1 }}
                className="letter-0-8px"
                color={"dark"}
              >
                {access.text}
              </SuiTypography>
            </div>
          )
        })}
      </SuiBox>
    )
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <SuiBox sx={{ p: 5 }}>
          <SuiBox sx={{ textAlign: "start" }}>
            <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
              {edit ? "회원 프로필 관리" : "신규 회원 등록"}
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <SuiBox sx={{ textAlign: "start" }}>{accessShow()}</SuiBox>
          <DialogActions sx={{ p: 0 }}>
            <SuiButton
              color="error"
              variant="text"
              sx={{ height: 40, display: edit ? "" : "none", mr: "auto", fontSize: 14 }}
              onClick={() => handleOut()}
            >
              탈퇴처리
            </SuiButton>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 97.38,
                border: 1,
                borderColor: "#707070",
                height: 40,
                fontSize: 14,
                color: "#707070",
              }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="info2"
              onClick={() => CUcheck()}
              sx={{
                height: 40,
                fontSize: 14,
              }}
            >
              저장하기
            </SuiButton>
          </DialogActions>
        </SuiBox>
        <SuiDialog
          openDialog={openInfo_Dialog}
          closeDialog={() => closeDialog()}
          MainTitle={"가입 완료"}
          Content={"회원가입이 완료되었습니다."}
          CanBtnText={"확인"}
        />
        <SuiDialog
          openDialog={openOut_Dialog}
          closeDialog={() => handleCloseOut()}
          MainTitle={"탈퇴처리 확인"}
          Content={openOut_Dialog_Content}
          Active={() => OutActive()}
          CanBtnText={"취소"}
          CreBtnText={"확인"}
          AlertType={"twoBtn"}
          CreBtnColor={"info2"}
        />
        <SuiDialog
          openDialog={open_disable_delete}
          closeDialog={() => setopen_disable_delete(false)}
          MainTitle={"탈퇴 처리 불가"}
          Content={
            "현재 담당하는 계약이 존재하므로 회원 탈퇴가 불가능합니다. \n 담당 계약 이관/삭제 후 회원탈퇴 처리가 가능합니다."
          }
          CanBtnText={"확인"}
        />

        <SuiDialog
          openDialog={open_reset_password}
          closeDialog={() => setopen_reset_password(false)}
          MainTitle={"비밀번호 초기화 확인"}
          Content={
            "회원의 비밀번호를 초기화 하시겠습니까? \n 확인 완료 시 최초 비밀번호로 초기화됩니다."
          }
          CanBtnText={"취소"}
          CreBtnText={"확인"}
          CreBtnColor="info2"
          AlertType={"twoBtn"}
          Active={() => reset_password()}
        />
      </Dialog>
    </div>
  )
}

CuDailog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: {},
  Users: [],
}

CuDailog.propTypes = {
  Users: PropTypes.array,
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.object,
  closeDialog: PropTypes.func,
  updateUser: PropTypes.func,
}
export default CuDailog
