import * as React from "react"
import { useState, useEffect, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Divider from "@mui/material/Divider"
import Backdrop from "@mui/material/Backdrop"
import SuiInput from "components/SuiInput"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import SuiButton from "components/SuiButton"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"

import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import InputAdornment from "@mui/material/InputAdornment"

import { visuallyHidden } from "@mui/utils"
import Pagination from "@mui/material/Pagination"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import SuiBox from "components/SuiBox"
import Select from "@mui/material/Select"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import SuiTypography from "components/SuiTypography"
import SuiDialog from "components/SuiDialog"
import enLocale from "date-fns/locale/en-US"
import PaginationCustom from "components/PaginationCustom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"

import {
  AiFillCaretDown,
  AiOutlineCheck,
  AiFillCheckCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai"
import { MdClose } from "react-icons/md"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { BsFillCircleFill } from "react-icons/bs"
import { BiRecycle } from "react-icons/bi"
// Dialog
import Event from "./items/event"
import EventWorksDialog from "./items/eventWorksDialog"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  settings,
  events,
  eventReceiversStatus,
  eventsConnection,
  eventReceiversStatusCount,
} from "apollo/query"
import { createDonwloadLog } from "apollo/mutation"

import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../reducers/store"

function createData(
  id,
  yearMonth,
  flatform,
  title,
  startEnd,
  status,
  serial,
  type,
  publisher,
  reward,
  sendCount,
  resultCount,
  mailSendWorks,
  all_recevier
) {
  return {
    id,
    yearMonth,
    flatform,
    title,
    startEnd,
    status,
    serial,
    type,
    publisher,
    reward,
    sendCount,
    resultCount,
    mailSendWorks,
    all_recevier,
  }
}
/****엑셀 다운로드용 데이터 생성 함수 
 @author 전현균 
 */
function createDataExcel(
  id,
  year,
  month,
  flatform,
  title,
  start,
  end,
  status,
  serial,
  type,
  publisher,
  reward,
  sendCount,
  resultCount,
  genre,
  workName,
  joaraName,
  ebookName,
  eachReward
) {
  return {
    id,
    year,
    month,
    flatform,
    title,
    start,
    end,
    status,
    serial,
    type,
    publisher,
    reward,
    sendCount,
    resultCount,
    genre,
    workName,
    joaraName,
    ebookName,
    eachReward,
  }
}
const CryptoJS = require("crypto-js")
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
function IconCircle(color) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  let colorCircle
  if (color.color !== "") {
    colorCircle = (
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        color="white"
        classes={{ popper: "tooltip-light" }}
        arrow={false}
        title={
          <SuiBox>
            <SuiTypography
              style={{ fontSize: "12px", color: "#000000", fontWeight: "normal" }}
              verticalAlign="middle"
            >
              계약 유효일
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "18px", color: "dark", fontWeight: "bold" }}
              verticalAlign="middle"
            >
              {color.day === 0
                ? "계약종료"
                : color.value === "자동갱신"
                ? color.day + "일"
                : color.day + "일"}
            </SuiTypography>
            <SuiTypography
              style={{ fontSize: "12px", color: "#707070", fontWeight: "normal" }}
              verticalAlign="middle"
            >
              {`${color.value === "자동갱신" ? "갱신일" : "종료일"} : ${color.end_date}`}
            </SuiTypography>
          </SuiBox>
        }
      >
        {color.color ? (
          <IconButton color={color.color} sx={{ p: 0 }}>
            {color.value === "자동갱신" ? (
              <BiRecycle size={20} />
            ) : (
              <BsFillCircleFill size={10}></BsFillCircleFill>
            )}
          </IconButton>
        ) : (
          <div></div>
        )}
      </Tooltip>
    )
  } else {
    colorCircle = <></>
  }

  return colorCircle
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    changeFilter,
    showchangeFilter,
    excelTable,
  } = props
  const createSortHandler = (property) => (event) => {
    if (property !== "webtoon" && property !== "premium") {
      onRequestSort(event, property)
    }
  }
  const headerSelect = {
    webtoon: "특별 계약",
    premium: "콘텐츠 계약",
  }
  const EbookList = [
    { type: "웹툰", items: ["특별 계약"] },
    { type: "프리미엄", items: ["콘텐츠 계약"] },
  ]
  const [headerSelectVal, setheaderSelectVal] = useState(headerSelect)
  useLayoutEffect(() => {
    if (showchangeFilter.premium) {
      setheaderSelectVal(showchangeFilter)
    }
  }, [showchangeFilter])
  const headCells = [
    {
      id: "yearMonth",
      numeric: false,
      disablePadding: false,
      label: "연도 / 월",
    },
    {
      id: "flatform",
      numeric: false,
      disablePadding: false,
      label: "플랫폼",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "이벤트 제목",
    },
    {
      id: "startEnd",
      numeric: false,
      disablePadding: false,
      label: "이벤트 시작 일 ~ 종료 일",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "진행 여부",
    },
    {
      id: "serial",
      numeric: false,
      disablePadding: false,
      label: "단행/연재",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "이벤트 종류",
    },
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "출판사",
    },
    {
      id: "sendCount",
      numeric: false,
      disablePadding: false,
      label: "e발송 / 승인 / 거절",
    },
    {
      id: "resultCount",
      numeric: false,
      disablePadding: false,
      label: "결과 보고",
    },
    {
      id: "mailSendWorks",
      numeric: false,
      disablePadding: false,
      label: "이벤트 참여 작품",
    },
  ]
  const headCellsLong = [
    {
      id: "yearMonth",
      numeric: false,
      disablePadding: false,
      label: "연도 / 월",
    },
    {
      id: "flatform",
      numeric: false,
      disablePadding: false,
      label: "플랫폼",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "이벤트 제목",
    },
    {
      id: "startEnd",
      numeric: false,
      disablePadding: false,
      label: "이벤트 시작 일 ~ 종료 일",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "진행 여부",
    },
    {
      id: "serial",
      numeric: false,
      disablePadding: false,
      label: "단행/연재",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "이벤트 종류",
    },
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "출판사",
    },
    {
      id: "sendCount",
      numeric: false,
      disablePadding: false,
      label: "e발송 / 승인 / 거절",
    },
    {
      id: "resultCount",
      numeric: false,
      disablePadding: false,
      label: "결과 보고",
    },
    {
      id: "mailSendWorks",
      numeric: false,
      disablePadding: false,
      label: "이벤트 참여 작품",
    },
  ]
  const headCellsExcel = [
    {
      id: "No",
      numeric: false,
      disablePadding: false,
      label: "No.",
    },

    {
      id: "year_excel",
      numeric: false,
      disablePadding: false,
      label: "연도",
    },
    {
      id: "month_excel",
      numeric: false,
      disablePadding: false,
      label: "월",
    },
    {
      id: "flatform",
      numeric: false,
      disablePadding: false,
      label: "플랫폼",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "이벤트 제목",
    },
    {
      id: "start_excel",
      numeric: false,
      disablePadding: false,
      label: "시작일",
    },
    {
      id: "end_excel",
      numeric: false,
      disablePadding: false,
      label: "종료일",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "진행 여부",
    },
    {
      id: "serial",
      numeric: false,
      disablePadding: false,
      label: "단행/연재",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "이벤트 종류",
    },
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "출판사",
    },
    {
      id: "reward",
      numeric: false,
      disablePadding: false,
      label: "리워드",
    },
    {
      id: "sendCount",
      numeric: false,
      disablePadding: false,
      label: "e발송 / 승인 / 거절",
    },
    {
      id: "resultCount",
      numeric: false,
      disablePadding: false,
      label: "결과 보고",
    },
    {
      id: "genre",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },
    {
      id: "workName",
      numeric: false,
      disablePadding: false,
      label: "참여 작품명",
    },
    {
      id: "joaraName",
      numeric: false,
      disablePadding: false,
      label: "J 필명",
    },
    {
      id: "ebookName",
      numeric: false,
      disablePadding: false,
      label: "E 필명",
    },
    {
      id: "eachReward",
      numeric: false,
      disablePadding: false,
      label: "개별 리워드",
    },
  ]
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  useLayoutEffect(() => {
    if (excelTable) {
      setheadCelldata(headCellsExcel)
    } else {
      if (localStorage.getItem("miniSidenav") === "true") {
        setheadCelldata(headCells)
      } else if (localStorage.getItem("miniSidenav") === "false") {
        setheadCelldata(headCellsLong)
      }
    }
  }, [localStorage.getItem("miniSidenav")])

  const [headCelldata, setheadCelldata] = useState(headCells)
  const handlesetheadCelldata = (data) => {
    setheadCelldata(data)
  }
  return (
    <TableHead>
      <TableRow>
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              borderRight:
                headCell.id === "yearMonth" ||
                headCell.id === "status" ||
                headCell.id === "reward" ||
                headCell.id === "resultCount"
                  ? "1px solid #F1F1F5"
                  : "",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {
  showchangeFilter: {},
  excelTable: false,
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  changeFilter: PropTypes.func,
  showchangeFilter: PropTypes.object,
  excelTable: PropTypes.bool,
}

export default function EnhancedTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { myData } = useSelector((state) => state.store)
  const [rows, setrows] = useState([])
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const [selectedData, setSelectedData] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [searchPage, setsearchPage] = useState(1)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalpage, settotalpage] = useState(10)
  const [searchRowsPerPage, setsearchRowsPerPage] = useState(10)
  const [searchTotalpage, setsearchTotalpage] = useState(10)
  const [event_dialog, set_event_dialog] = useState(false)
  const [edit_event_id, set_edit_event_id] = useState(null)
  const [type_event, set_type_event] = useState("create")
  const [editDistributorData, seteditDistributorData] = useState({ title: "" })
  const [rowlength, setrowlength] = useState(0)
  const [openFilter, setopenFilter] = useState(false)
  const [filterChipData, setfilterChipData] = useState([])
  const [changeFilter, setchangeFilter] = useState({
    premium: "콘텐츠 계약",
    webtoon: "특별 계약",
  })
  const [eventVariables, seteventVariables] = useState({
    state_ne: "delete",
    start_index: 0,
    limit: 10,
  })

  const [eventCountVariables, seteventCountVariables] = useState({
    state_ne: "delete",
    _or: [],
  })
  const [TitleFilterOpen, setTitleFilterOpen] = useState(false)
  const [inputFilterTitle, setInputFilterTitle] = useState("") // 필터 타이틀 input
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false) // 검색 필터 "플랫폼"
  const [FiltersOpen7, setFiltersOpen7] = useState(false) // 검색 필터 "이벤트 종류"
  const [FiltersOpen8, setFiltersOpen8] = useState(false) // 검색 필터 "단행/연재"
  const [FiltersOpen9, setFiltersOpen9] = useState(false) // 검색 필터 "기본 리워드"
  const [FiltersOpen10, setFiltersOpen10] = useState(false) // 엑셀 다운로드 형식
  const [FiltersDateOpen1, setFiltersDateOpen1] = useState(false) // 검색 필터 "이벤트 기간 년"
  const [FiltersDateOpen2, setFiltersDateOpen2] = useState(false) // 검색 필터 "이벤트 기간 월"
  const [outsourcingCountFilter, setoutsourcingCountFilter] = useState({})
  const [eventData, setEventData] = useState([])
  const [eventCancel, setEventCancel] = useState(0)
  const [eventExpected, setEventExpected] = useState(0)
  const [eventComplete, setEventComplete] = useState(0)
  const [eventCancelWork, setEventCancelWork] = useState(0) // 확정 종수
  const [eventExpectedWork, setEventExpectedWork] = useState(0) // 예정 종수
  const [eventCompleteWork, setEventCompleteWork] = useState(0) // 취소 종수
  const [department, setDepartment] = useState("")
  const [eventTitle, setEventTitle] = useState("플랫폼(전체)")
  const [managerName, setManagerName] = useState("")
  const [eventType, setEventType] = useState("이벤트 종류(전체)")
  const [eventSerial, setEventSerial] = useState("단행/연재(전체)")
  const [eventReward, seteventReward] = useState("기본 리워드(전체)")
  const [eventExcel, seteventExcel] = useState("현재 페이지")
  const [eventDateYear, setEventDateYear] = useState("-")
  const [eventDateMonth, setEventDateMonth] = useState("-")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [filterTitle, setFilterTitle] = useState("searchEvent")
  const workSearchValue = useRef("") // 작품명 검색 인풋 ref
  const [workSearchActive, setWorkSearchActive] = useState(false) // 작품명 검색 활성화 여부
  const [workSearchText, setWorkSearchText] = useState("") // 작품명 검색한 값 저장
  const [filters_open1, set_filters_open1] = useState(false) // 검색창 셀렉트
  const [search_select_value, set_search_select_value] = useState("작품 명") // 검색창 셀렉트 value

  const client = useApolloClient()

  const handleFilterTitle = (event) => {
    setFilterTitle(event.target.value)
  }
  const { data: get_events } = useQuery(events, {
    fetchPolicy: "network-only",
    variables: eventVariables,
  })
  const [eventFilterActive] = useLazyQuery(events, {
    fetchPolicy: "network-only",
  })

  const [eventCountActive] = useLazyQuery(eventsConnection, {
    fetchPolicy: "network-only",
  })

  const [eventReceiversStatusActive] = useLazyQuery(eventReceiversStatus, {
    variables: {
      result_status: "완료",
    },
    fetchPolicy: "network-only",
  })

  // 다운로드 row
  const [rows_hide, setrows_hide] = useState([])

  useLayoutEffect(() => {
    setsearchTotalpage(Math.ceil(rowlength / searchRowsPerPage))
    settotalpage(Math.ceil(rowlength / rowsPerPage))
  })
  const paperSorting = (array) => {
    array.sort((a, b) => {
      var dateA = a[0].file.id
      var dateB = b[0].file.id
      return dateA < dateB ? 1 : -1
    })
    return array
  }

  useLayoutEffect(() => {
    // 탭 접근 권한 체크
    if (myData.name !== "") {
      if (!myData.accessTabs) {
        return setInfoAlert_Acess({
          dialog: true,
          title: "접근 권한 필요",
          content: "해당 탭에 접근하기 위한 권한이 없습니다.",
          CanBtnText: "확인",
        })
      }
    }
    if (myData.name !== "") {
      if (!myData.accessTabs) {
        return setInfoAlert_Acess({
          dialog: true,
          title: "접근 권한 필요",
          content: "해당 탭에 접근하기 위한 권한이 없습니다.",
          CanBtnText: "확인",
        })
      }
    }
    if (myData.accessTabs?.indexOf("eve") === -1) {
      return setInfoAlert_Acess({
        dialog: true,
        title: "접근 권한 필요",
        content: "해당 탭에 접근하기 위한 권한이 없습니다.",
        CanBtnText: "확인",
      })
    } else settings_Call()
  }, [myData])
  const settings_ref = useRef(null)
  const settings_Call = () => {
    settingsActive().then((res) => {
      let settings = {}
      res.data.settings.forEach((el) => {
        let listCopy = [...el.list]
        if (el.type === "department") {
          listCopy.unshift("전체")
        }
        if (el.type === "eventReward") {
          listCopy.unshift("기본 리워드(전체)")
        }
        let slice_index = listCopy.findIndex((el) => el === "-")
        if (slice_index > -1) {
          listCopy.splice(slice_index, 1)
          settings[el.type] = listCopy
        } else {
          settings[el.type] = listCopy
        }
      })
      settings_ref.current = settings
    })
  }

  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [eventsActive] = useLazyQuery(events, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  useLayoutEffect(() => {
    // 테이블 상단 확정/ 예정 / 취소 숫자 세기
    // 검색 시에 검색한 필터들을 eventCountVariables에 담아서 저장하면 자동으로 실행
    client
      .query({
        query: eventsConnection,
        variables: eventCountVariables,
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        let confirmed = 0
        let cancel = 0
        let expected = 0

        for (let index = 0; index < res.data.eventsConnection.values.length; index++) {
          let events = res.data.eventsConnection.values[index]

          if (events.status === "cancel") {
            cancel++
          } else {
            if (dayjs(events.start).format("YYYY.MM.DD") > dayjs(new Date()).format("YYYY.MM.DD")) {
              expected++
            } else if (
              dayjs(events.start).format("YYYY.MM.DD") <= dayjs(new Date()).format("YYYY.MM.DD") &&
              dayjs(events.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              confirmed++
            } else if (
              dayjs(events.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              confirmed++
            }
          }
        }

        setrowlength(res.data.eventsConnection.aggregate.count)

        setEventComplete(confirmed)
        setEventCancel(cancel)
        setEventExpected(expected)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [eventCountVariables])

  useLayoutEffect(() => {
    if (myData.accessTabs?.indexOf("eve") !== -1) {
      localStorage.removeItem("contract")
      localStorage.removeItem("writer")
      localStorage.removeItem("company")
      localStorage.removeItem("outsourcing")

      if (get_events) {
        dispatch(loading_Apply(true))
        if (get_events.events.length > 0) {
          let rowsData = []

          let events_ids = get_events.events.map((el) => el.id)

          let receiver_variables = {
            ids: events_ids,
            _or: [
              {
                status: "완료",
              },
              {
                status: "미발송",
              },
              { mailType: "result", result_status_ne: "" },
            ],
          }
          client
            .query({
              query: eventReceiversStatus,
              variables: receiver_variables,
              fetchPolicy: "network-only",
            })
            .then((res) => {
              let receivers = res.data.eventReceviers
              let events_result_reciver = res.data.eventReceviers.filter(
                (x) => x.result_status === "완료"
              )
              get_events.events.forEach((el, index) => {
                let info_mail_count = "" // 안내 발송 / 승인 텍스트
                let all_recevier = receivers.filter((receiver) => receiver.eventId === el.id)
                let info_receiver = receivers.filter(
                  // 안내 발송 총 건수
                  (receiver) =>
                    receiver.eventId === el.id &&
                    receiver.status === "완료" &&
                    receiver.mailType === "info"
                )
                let info_receiver_access_sentOnly = receivers.filter(
                  // 안내 발송된것 중에서 승인된 건수
                  (receiver) =>
                    receiver.eventId === el.id &&
                    receiver.status === "완료" &&
                    receiver.mailType === "info" &&
                    receiver.access === "access"
                )
                let info_receiver_reject_sentOnly = receivers.filter(
                  // 안내 발송된것 중에서 거절된 건수
                  (receiver) =>
                    receiver.eventId === el.id &&
                    receiver.status === "완료" &&
                    receiver.mailType === "info" &&
                    receiver.access === "reject"
                )

                let info_receiver_access = receivers.filter(
                  // 안내 발송 중 발송+미발송 승인 건수
                  (receiver) =>
                    receiver.eventId === el.id &&
                    receiver.mailType === "info" &&
                    receiver.access === "access"
                )
                let info_receiver_complete_data = receivers.filter(
                  // 안내 발송(승인) || 결과보고(전송완료) 데이터
                  (receiver) =>
                    (receiver.eventId === el.id &&
                      receiver.mailType === "info" &&
                      receiver.access === "access") ||
                    (receiver.eventId === el.id && receiver.mailType === "result")
                )
                // 안내 발송 텍스트 세팅
                if (
                  info_receiver.length > 0 ||
                  info_receiver_access_sentOnly.length > 0 ||
                  info_receiver_reject_sentOnly.length > 0
                ) {
                  let total_info_count = info_receiver.length > 0 ? info_receiver.length : "-"
                  let access_info_count =
                    info_receiver_access_sentOnly.length > 0
                      ? info_receiver_access_sentOnly.length
                      : "-"
                  let reject_info_count =
                    info_receiver_reject_sentOnly.length > 0
                      ? info_receiver_reject_sentOnly.length
                      : "-"

                  info_mail_count = `${total_info_count} / ${access_info_count} / ${reject_info_count}`
                } else {
                  info_mail_count = "-"
                }

                let result_receiver = events_result_reciver.filter(
                  // 결과보고 발송 총 건수
                  (receiver) => receiver.eventId === el.id && receiver.mailType === "result"
                )

                // 안내발송 승인 수 / 결과보고 메일 수
                let result_mail_count = `${
                  info_receiver_access.length > 0 ? info_receiver_access.length : "-"
                } / ${result_receiver.length > 0 ? result_receiver.length : "-"}`

                let evnets_works_lists = info_receiver_complete_data.filter(
                  (v, i, a) => a.findIndex((v2) => v2.bookCode === v.bookCode) === i
                )

                let year_month
                year_month = dayjs(el.start).format("YY") + " / " + dayjs(el.start).format("MM")
                let start_end
                start_end =
                  dayjs(el.start).format("YYYY.MM.DD") + " ~ " + dayjs(el.end).format("YYYY.MM.DD")
                let status

                if (el.status === "cancel") {
                  status = "취소"
                } else {
                  if (
                    dayjs(el.start).format("YYYY.MM.DD") > dayjs(new Date()).format("YYYY.MM.DD")
                  ) {
                    status = "예정"
                  } else if (
                    dayjs(el.start).format("YYYY.MM.DD") <=
                      dayjs(new Date()).format("YYYY.MM.DD") &&
                    dayjs(el.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
                  ) {
                    status = "진행중"
                  } else if (
                    dayjs(el.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
                  ) {
                    status = "완료"
                  }
                }

                rowsData.push(
                  createData(
                    el.id,
                    year_month,
                    el.flatform,
                    el.title,
                    start_end,
                    status,
                    el.serial,
                    el.type,
                    el.publisher,
                    el.reward,
                    info_mail_count,
                    result_mail_count,
                    evnets_works_lists,
                    all_recevier
                  )
                )
                if (index === get_events.events.length - 1) {
                  setTimeout(() => {
                    dispatch(loading_Apply(false))
                  }, 1000)
                  setrows(rowsData)
                }
              })
            })
        } else {
          dispatch(loading_Apply(false))
        }
      }
    }
  }, [get_events])

  useLayoutEffect(() => {
    let rowsData = []
  }, [changeFilter])
  const [FilterSearchActive, setFilterSearchActive] = useState(false)
  let orFilterData = {
    department: "", // 담당부서
    managerName: "", // 담당자 명
    eventFlatform: "", // 이벤트 플랫폼
    eventType: "", // 이벤트 종류
    eventSerial: "", // 단행/연재
    eventDateYear: "", // 이벤트 기간 년
    eventDateMonth: "", // 이벤트 기간 월
    eventStart: "", // 이벤트 시작 일
    eventEnd: "", // 이벤트 종료 일
  }

  const [FilterDatas, setFilterDatas] = useState(orFilterData)
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return 0
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    if (new Date() > secondDateObj) {
      return "gray"
    }
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "green"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "orange"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "red"
    } else {
      return "gray"
    }
  }
  useLayoutEffect(() => {
    // let data = {
    //   start_index: (page - 1) * rowsPerPage,
    //   limit: rowsPerPage,
    //   state_ne: "delete",
    // }

    let data = { ...eventCountVariables }
    data["start_index"] = (page - 1) * rowsPerPage
    data["limit"] = rowsPerPage
    seteventVariables(data)
  }, [page])
  useEffect(() => {
    let data = { ...eventCountVariables }
    data["start_index"] = (page - 1) * rowsPerPage
    data["limit"] = rowsPerPage

    seteventVariables(data)
    setPage(1)
  }, [rowsPerPage])

  const [loading, setloading] = useState(false)
  // 필터 시작
  const filterSearch = async () => {
    let search_events = []
    if (search_events) {
      let variables = {
        state_ne: "delete",
        title: filterTitle === "searchEvent" && inputFilterTitle !== "" ? inputFilterTitle : "",
        flatform: eventTitle === "플랫폼(전체)" ? "" : eventTitle,
        type: eventType === "이벤트 종류(전체)" ? "" : eventType,
        serial: eventSerial === "단행/연재(전체)" ? "" : eventSerial,
        reward: eventReward === "기본 리워드(전체)" ? "" : eventReward,
        start: startDate ? startDate : dayjs("2000-01-01 00:00:00").format("YYYY-MM-DD"),
        end: endDate ? endDate : dayjs("2099-01-01 00:00:00").format("YYYY-MM-DD"),
        limit: rowsPerPage,
        _or: [],
      }
      if (department && department !== "전체") {
        variables["user_department"] = department
      }
      if (managerName) {
        variables["user_name"] = managerName
      }

      if (startDate) {
        variables.start = startDate
      } else {
        if (eventDateYear !== "-" && eventDateMonth !== "-") {
          variables.start = `${eventDateYear}-${eventDateMonth}-01`
          variables["start_lte"] = `${eventDateYear}-${eventDateMonth}-${new Date(
            eventDateYear,
            eventDateMonth,
            0
          ).getDate()}`
        } else if (eventDateYear !== "-") {
          variables.start = `${eventDateYear}-01-01`
          variables["start_lte"] = `${eventDateYear}-12-31`
        }
      }
      if (filterChipData.length > 0) {
        for (let i = 0; i < filterChipData.length; i++) {
          variables["_or"].push({ publisher_contains: filterChipData[i] + "_" })
        }
      }
      seteventCountVariables(variables)
      await client
        .query({
          query: events,
          variables: variables,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          search_events = res.data
        })

      let events_ids = search_events.events.map((el) => el.id)
      let receiver_variables = {
        ids: events_ids,
        _or: [
          {
            status: "완료",
          },
          {
            status: "미발송",
          },
          { mailType: "result", result_status_ne: "" },
        ],
      }
      let receivers = []
      let events_result_reciver = []
      await client
        .query({
          query: eventReceiversStatus,
          variables: receiver_variables,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          receivers = res.data.eventReceviers
          events_result_reciver = res.data.eventReceviers.filter((x) => x.result_status === "완료")
        })

      //
      dispatch(loading_Apply(true))

      if (search_events.events.length > 0) {
        let rowsData = []
        search_events.events.forEach((el, index) => {
          let info_mail_count = "" // 안내 발송 / 승인 텍스트
          let all_recevier = receivers.filter((receiver) => receiver.eventId === el.id)

          let info_receiver = receivers.filter(
            // 안내 발송 총 건수
            (receiver) =>
              receiver.eventId === el.id &&
              receiver.status === "완료" &&
              receiver.mailType === "info"
          )
          let info_receiver_access_sentOnly = receivers.filter(
            // 안내 발송된것 중에서 승인된 건수
            (receiver) =>
              receiver.eventId === el.id &&
              receiver.status === "완료" &&
              receiver.mailType === "info" &&
              receiver.access === "access"
          )
          let info_receiver_reject_sentOnly = receivers.filter(
            // 안내 발송된것 중에서 거절된 건수
            (receiver) =>
              receiver.eventId === el.id &&
              receiver.status === "완료" &&
              receiver.mailType === "info" &&
              receiver.access === "reject"
          )

          let info_receiver_access = receivers.filter(
            // 안내 발송 중 발송+미발송 승인 건수
            (receiver) =>
              receiver.eventId === el.id &&
              receiver.mailType === "info" &&
              receiver.access === "access"
          )
          let info_receiver_complete_data = receivers.filter(
            // 안내 발송(승인) && 결과보고(전송완료) 데이터
            (receiver) =>
              (receiver.eventId === el.id &&
                receiver.mailType === "info" &&
                receiver.access === "access") ||
              (receiver.eventId === el.id && receiver.mailType === "result")
          )
          // 안내 발송 텍스트 세팅
          if (
            info_receiver.length > 0 ||
            info_receiver_access_sentOnly.length > 0 ||
            info_receiver_reject_sentOnly.length > 0
          ) {
            let total_info_count = info_receiver.length > 0 ? info_receiver.length : "-"
            let access_info_count =
              info_receiver_access_sentOnly.length > 0 ? info_receiver_access_sentOnly.length : "-"
            let reject_info_count =
              info_receiver_reject_sentOnly.length > 0 ? info_receiver_reject_sentOnly.length : "-"

            info_mail_count = `${total_info_count} / ${access_info_count} / ${reject_info_count}`
          } else {
            info_mail_count = "-"
          }

          let result_receiver = events_result_reciver.filter(
            // 결과보고 발송 총 건수
            (receiver) => receiver.eventId === el.id && receiver.mailType === "result"
          )
          // 안내발송 승인 수 / 결과보고 메일 수
          let result_mail_count = `${
            info_receiver_access.length > 0 ? info_receiver_access.length : "-"
          } / ${result_receiver.length > 0 ? result_receiver.length : "-"}`

          let evnets_works_lists = info_receiver_complete_data.filter(
            (v, i, a) => a.findIndex((v2) => v2.bookCode === v.bookCode) === i
          )

          let year_month
          year_month = dayjs(el.start).format("YY") + " / " + dayjs(el.start).format("MM")
          let start_end
          start_end =
            dayjs(el.start).format("YYYY.MM.DD") + " ~ " + dayjs(el.end).format("YYYY.MM.DD")
          let status
          if (el.status === "cancel") {
            status = "취소"
          } else {
            if (dayjs(el.start).format("YYYY.MM.DD") > dayjs(new Date()).format("YYYY.MM.DD")) {
              status = "예정"
            } else if (
              dayjs(el.start).format("YYYY.MM.DD") <= dayjs(new Date()).format("YYYY.MM.DD") &&
              dayjs(el.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              status = "진행중"
            } else if (
              dayjs(el.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              status = "완료"
            }
          }

          rowsData.push(
            createData(
              el.id,
              year_month,
              el.flatform,
              el.title,
              start_end,
              status,
              el.serial,
              el.type,
              el.publisher,
              el.reward,
              info_mail_count,
              result_mail_count,
              evnets_works_lists,
              all_recevier
            )
          )
          if (index === search_events.events.length - 1) {
            setTimeout(() => {
              dispatch(loading_Apply(false))
            }, 1000)
            setrows(rowsData)
          }
        })
      } else {
        setTimeout(() => {
          dispatch(loading_Apply(false))
        }, 1000)
        setrows(search_events.events)
      }
    }
    setloading(false)
    setopenFilter(false)
    setPage(1)
    setsearchPage(1)
    setFilterSearchActive(true)
    let searchedItem = {
      department: department, // 담당부서
      managerName: managerName, // 담당자 명
      eventFlatform: eventTitle, // 이벤트 플랫폼
      eventType: eventType, // 이벤트 종류
      eventSerial: eventSerial, // 단행/연재
      eventDateYear: eventDateYear, // 이벤트 기간 년
      eventDateMonth: eventDateMonth, // 이벤트 기간 월
      eventStart: startDate, // 이벤트 시작 일
      eventEnd: endDate, // 이벤트 종료 일
    }
    setsearchedFilter(searchedItem)

    setTimeout(() => {
      setloading(true)
    }, 1000)
  }
  const handleFilterDate = (type) => (event) => {
    if (type === "year") {
      setEventDateYear(event.target.value)
    }
    if (type === "month") {
      setEventDateMonth(event.target.value)
    }
  }
  const handleFilterDatas = (type) => (event) => {
    // let datas = FilterDatas
    if (type === "department") {
      setDepartment(event.target.value)
    }
    if (type === "managerName") {
      setManagerName(event.target.value)
    }
    if (type === "eventFlatform") {
      setEventTitle(event.target.value)
    }
    if (type === "eventType") {
      setEventType(event.target.value)
    }
    if (type === "eventSerial") {
      setEventSerial(event.target.value)
    }
    if (type === "titleType") {
      setInputFilterTitle(event.target.value)
    }
    if (type === "eventReward") {
      seteventReward(event.target.value)
    }

    // if (type === "birth") {
    //   setFilterDatas({ ...datas, [type]: dayjs(event).format("YYYY-MM-DD") })
    // } else {
    //   setFilterDatas({ ...datas, [type]: event.target.value })
    // }
  }
  const filterChip = (x) => {
    let array = [...filterChipData]
    if (array.indexOf(x) === -1) {
      array.push(x)
    } else {
      array.splice(array.indexOf(x), 1)
    }
    setfilterChipData(array)
  }
  const openFilterInput = () => {
    setopenFilter(true)
  }
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [InfoAlert_Acess, setInfoAlert_Acess] = useState(InfoAlertSet)
  const [workDialog, setWorkDialog] = useState({ dialog: false, workLists: [] })

  const openWorksListsDialog = async (workLists, row) => {
    let event_detail_date
    await client
      .query({
        query: events,
        variables: { id: row.id },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        event_detail_date = res.data.events[0]
      })
    if (myData.role.name === "bronze") {
      const InfoAlertSet1 = {
        dialog: true,
        title: "권한없음",
        content: "열람권한이 없습니다.",
        CanBtnText: "확인",
      }
      setInfoAlert(InfoAlertSet1)
    } else {
      setWorkDialog({ dialog: true, workLists: workLists })
    }
  }
  const closeWorksListsDialog = () => {
    setWorkDialog({ dialog: false, workLists: [] })
  }
  const filterSave = () => {
    let local_filter = {
      inputFilterTitle: inputFilterTitle, // 이벤트 명
      department: department, // 담당부서
      managerName: managerName, // 담당자 명
      eventTitle: eventTitle, // 이벤트 플랫폼
      eventType: eventType, // 이벤트 종류
      eventSerial: eventSerial, // 단행/연재
      eventDateYear: eventDateYear, // 이벤트 기간 년
      eventDateMonth: eventDateMonth, // 이벤트 기간 월
      eventStart: startDate, // 이벤트 시작 일
      eventEnd: endDate, // 이벤트 종료 일
    }

    localStorage.setItem("event", JSON.stringify(local_filter))
    setInfoAlert({
      dialog: true,
      title: "검색 필터 저장",
      content: "입력하신 검색 필터가 저장되었습니다.",
      CanBtnText: "확인",
    })
  }
  const filterClose = () => {
    setloading(true)
    setopenFilter(false)

    if (localStorage.getItem("event")) {
      let local_filter = JSON.parse(localStorage.getItem("event"))
      setInputFilterTitle(local_filter.inputFilterTitle)
      setDepartment(local_filter.department)
      setManagerName(local_filter.managerName)
      setEventTitle(local_filter.eventTitle)
      setEventType(local_filter.eventType)
      setEventSerial(local_filter.eventSerial)
      setEventDateYear(local_filter.eventDateYear)
      setEventDateMonth(local_filter.eventDateMonth)
      setStartDate(local_filter.eventStart)
      setEndDate(local_filter.eventEnd)
    }
  }

  const resetSearch = () => {
    setopenFilter(true)
    setloading(false)
  }
  const handleDetail = async (data) => {
    let event_detail_date
    await client
      .query({
        query: events,
        variables: { id: data.id },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        event_detail_date = res.data.events[0]
      })
    if (myData.role.name === "bronze") {
      const InfoAlertSet1 = {
        dialog: true,
        title: "권한없음",
        content: "열람권한이 없습니다.",
        CanBtnText: "확인",
      }
      setInfoAlert(InfoAlertSet1)
    } else {
      set_edit_event_id(data.id)
      set_type_event("detail")
      set_event_dialog(true)
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const chipsList =
    settings_ref.current && settings_ref.current.eventPublisher
      ? settings_ref.current.eventPublisher
      : ["조아라", "휘슬북", "이색", "미드나잇"]
  const chipsListSelects = chipsList.map((x) => {
    return (
      <Chip
        sx={{
          mr: 2,
          height: 40,
          mt: 1,
          border: filterChipData.indexOf(x) > -1 ? "1px solid #5C38FF" : "none",
          backgroundColor: filterChipData.indexOf(x) > -1 ? "#FAF8FF" : "#F1F1F5",
          fontWeight: filterChipData.indexOf(x) > -1 ? 900 : 500,
        }}
        color={filterChipData.indexOf(x) > -1 ? "primary" : "secondary"}
        variant="outlined"
        className="filter-chips"
        key={x}
        label={x}
        onClick={() => filterChip(x)}
        style={{
          color: filterChipData.indexOf(x) > -1 ? "" : "#9AA0A6",
        }}
      ></Chip>
    )
  })

  const clearStartDate = ({ onCancel }) => {
    return (
      <div style={{ display: "flex", padding: "0 10px 10px 0" }}>
        <SuiButton
          color="primary"
          sx={{
            ml: "auto",
            minHeight: 0,
            minWidth: 0,
            fontSize: "12px",
            padding: "10px 14px",
          }}
          onClick={() => {
            setStartDate(null)
            onCancel()
          }}
        >
          초기화
        </SuiButton>
      </div>
    )
  }

  const clearEndDate = ({ onCancel }) => {
    return (
      <div style={{ display: "flex", padding: "0 10px 10px 0" }}>
        <SuiButton
          color="primary"
          sx={{
            ml: "auto",
            minHeight: 0,
            minWidth: 0,
            fontSize: "12px",
            padding: "10px 14px",
          }}
          onClick={() => {
            setEndDate(null)
            onCancel()
          }}
        >
          초기화
        </SuiButton>
      </div>
    )
  }

  const contractList = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ]
  const contractListSelects = contractList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id)

    let newSelected = []
    let newSelectedsData = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      newSelectedsData = newSelectedsData.concat(selectedData, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newSelectedsData = newSelectedsData.concat(selectedData.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSelectedsData = newSelectedsData.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      newSelectedsData = newSelectedsData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }
    setSelectedData(newSelectedsData)
    setSelected(newSelected)
  }
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [event_allActive] = useLazyQuery(events, {
    fetchPolicy: "network-only",
  })
  const all_event_call = () => {
    let variables = {
      state_ne: "delete",
    }

    if (eventExcel === "현재 페이지") {
      variables["start_index"] = (page - 1) * rowsPerPage
      variables["limit"] = rowsPerPage
    }
    if (FilterSearchActive) {
      //
      variables = {
        state_ne: "delete",
        title: filterTitle === "searchEvent" && inputFilterTitle !== "" ? inputFilterTitle : "",
        flatform: eventTitle === "플랫폼(전체)" ? "" : eventTitle,
        type: eventType === "이벤트 종류(전체)" ? "" : eventType,
        serial: eventSerial === "단행/연재(전체)" ? "" : eventSerial,
        reward: eventReward === "기본 리워드(전체)" ? "" : eventReward,
        start: startDate ? startDate : dayjs("2000-01-01 00:00:00").format("YYYY-MM-DD"),
        end: endDate ? endDate : dayjs("2099-01-01 00:00:00").format("YYYY-MM-DD"),
        _or: [],
      }
      if (eventExcel === "현재 페이지") {
        variables["start_index"] = (page - 1) * rowsPerPage
        variables["limit"] = rowsPerPage
      }
      if (department && department !== "전체") {
        variables["user_department"] = department
      }
      if (managerName) {
        variables["user_name"] = managerName
      }

      if (startDate) {
        variables.start = startDate
      } else {
        if (eventDateYear !== "-" && eventDateMonth !== "-") {
          variables.start = `${eventDateYear}-${eventDateMonth}-01`
          variables["start_lte"] = `${eventDateYear}-${eventDateMonth}-${new Date(
            eventDateYear,
            eventDateMonth,
            0
          ).getDate()}`
        } else if (eventDateYear !== "-") {
          variables.start = `${eventDateYear}-01-01`
          variables["start_lte"] = `${eventDateYear}-12-31`
        }
      }
    }
    if (filterChipData.length > 0) {
      for (let i = 0; i < filterChipData.length; i++) {
        variables["_or"].push({ publisher_contains: filterChipData[i] + "_" })
      }
    }

    let confirmed_work = 0
    let cancel_work = 0
    let expected_work = 0

    client
      .query({
        query: events,
        variables: variables,
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        dispatch(loading_Apply(true))
        if (res.data.events.length > 0) {
          let rowsData = []
          let event_data = [...res.data.events]
          let events_ids = event_data.map((el) => el.id)
          let receivers = []

          for (let i = 0; i < i + 1; i++) {
            let id_list = events_ids.slice(i * 50, (i + 1) * 50)
            if (id_list.length === 0) {
              break
            }
            let receiver_variables = {
              ids: id_list,
              _or: [
                {
                  status: "완료",
                },
                {
                  status: "미발송",
                },
                { mailType: "result", result_status_ne: "" },
              ],
            }

            await client
              .query({
                query: eventReceiversStatus,
                variables: receiver_variables,
                fetchPolicy: "network-only",
              })
              .then((res) => {
                receivers = receivers.concat(res.data.eventReceviers)
              })
          }

          let events_result_reciver = receivers.filter((x) => x.result_status === "완료")
          let rows_receiver = receivers.map((receive) => {
            // 엑셀 다운로드 테이블에 넣을 데이터 ( 이벤트 참여 작품이 엑셀에 다운로드 된다. )
            let receive_event = event_data.filter((event) => event.id === receive.eventId)
            return { ...receive, event: receive_event.length > 0 ? receive_event[0] : {} }
          })

          // 결과 메일을 기준으로 리스트 세팅
          let info_rows = rows_receiver.filter(
            (el) => el.mailType === "result" || (el.mailType === "info" && el.access === "access")
          )

          // 이벤트가 다르거나 북코드가 다른 경우 리스트에 추가 및 '종'의 숫자에 추가
          info_rows = info_rows.filter(
            // 1. 이벤트가 같고, 북코드가 다른 경우 -> 추가
            // 2. 이벤트가 같고, 북코드도 같은 경우 -> 제외
            // 3. 이벤트가 다르고, 북코드 다른 경우 -> 추가
            // 4. 이벤트가 다르고, 쿡코드 같은 경우 -> 추가
            (v, i, a) =>
              a.findIndex((v2) => v2.bookCode === v.bookCode && v2.event.id === v.event.id) === i
          )
          if (info_rows.length > 0) {
            info_rows.forEach((el, index) => {
              let info_mail_count = "" // 안내 발송 / 승인 텍스트

              let info_receiver = receivers.filter(
                // 안내 발송 총 건수
                (receiver) =>
                  receiver.eventId === el.event.id &&
                  receiver.status === "완료" &&
                  receiver.mailType === "info"
              )
              let info_receiver_access_sentOnly = receivers.filter(
                // 안내 발송된것 중에서 승인된 건수
                (receiver) =>
                  receiver.eventId === el.event.id &&
                  receiver.status === "완료" &&
                  receiver.mailType === "info" &&
                  receiver.access === "access"
              )
              let info_receiver_reject_sentOnly = receivers.filter(
                // 안내 발송된것 중에서 거절된 건수
                (receiver) =>
                  receiver.eventId === el.event.id &&
                  receiver.status === "완료" &&
                  receiver.mailType === "info" &&
                  receiver.access === "reject"
              )

              let info_receiver_access = receivers.filter(
                // 안내 발송 중 발송+미발송 승인 건수
                (receiver) =>
                  receiver.eventId === el.event.id &&
                  receiver.mailType === "info" &&
                  receiver.access === "access"
              )
              // 안내 발송 텍스트 세팅
              if (
                info_receiver.length > 0 ||
                info_receiver_access_sentOnly.length > 0 ||
                info_receiver_reject_sentOnly.length > 0
              ) {
                let total_info_count = info_receiver.length > 0 ? info_receiver.length : "-"
                let access_info_count =
                  info_receiver_access_sentOnly.length > 0
                    ? info_receiver_access_sentOnly.length
                    : "-"
                let reject_info_count =
                  info_receiver_reject_sentOnly.length > 0
                    ? info_receiver_reject_sentOnly.length
                    : "-"

                info_mail_count = `${total_info_count} / ${access_info_count} / ${reject_info_count}`
              } else {
                info_mail_count = "-"
              }

              let result_receiver = events_result_reciver.filter(
                // 결과보고 발송 총 건수
                (receiver) => receiver.eventId === el.event.id && receiver.mailType === "result"
              )

              // 안내발송 승인 수 / 결과보고 메일 수
              let result_mail_count = `${
                info_receiver_access.length > 0 ? info_receiver_access.length : "-"
              } / ${result_receiver.length > 0 ? result_receiver.length : "-"}`

              let year = dayjs(el.event.start).format("YYYY")
              let month = dayjs(el.event.start).format("MM")

              let start = dayjs(el.event.start).format("YYYY.MM.DD")
              let end = dayjs(el.event.end).format("YYYY.MM.DD")

              let status
              if (el.event.status === "cancel") {
                status = "취소"
                cancel_work++
              } else {
                if (
                  dayjs(el.event.start).format("YYYY.MM.DD") >
                  dayjs(new Date()).format("YYYY.MM.DD")
                ) {
                  status = "예정"
                  expected_work++
                } else if (
                  dayjs(el.event.start).format("YYYY.MM.DD") <=
                    dayjs(new Date()).format("YYYY.MM.DD") &&
                  dayjs(el.event.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
                ) {
                  status = "진행중"
                  confirmed_work++
                } else if (
                  dayjs(el.event.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
                ) {
                  status = "완료"
                  confirmed_work++
                }
              }
              rowsData.push(
                createDataExcel(
                  el.event.id,
                  year,
                  month,
                  el.event.flatform,
                  el.event.title,
                  start,
                  end,
                  status,
                  el.event.serial,
                  el.event.type,
                  el.event.publisher,
                  el.event.reward,
                  "\u00a0" + String(info_mail_count),
                  result_receiver.length > 0 ? "\u00a0" + String(result_mail_count) : "-",
                  el.genre,
                  el.title,
                  el.joaraName ? el.joaraName : "",
                  el.ebook ? el.ebook : "",
                  el.rewarod ? el.rewarod : ""
                )
              )
              if (index === info_rows.length - 1) {
                setTimeout(() => {
                  dispatch(loading_Apply(false))
                }, 1000)

                setEventCompleteWork(confirmed_work)
                setEventCancelWork(cancel_work)
                setEventExpectedWork(expected_work)
                setrows_hide(rowsData)
              }
            })
          } else {
            setEventCompleteWork(confirmed_work)
            setEventCancelWork(cancel_work)
            setEventExpectedWork(expected_work)
            setrows_hide([])
            setTimeout(() => {
              dispatch(loading_Apply(false))
            }, 1000)
          }
        } else {
          setEventCompleteWork(confirmed_work)
          setEventCancelWork(cancel_work)
          setEventExpectedWork(expected_work)
          setrows_hide([])
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
        }
      })
  }
  function excelExportActive() {
    all_event_call()
  }

  useEffect(() => {
    if (rows_hide.length > 0) {
      document.getElementById("excelExport").click()
      createDonwloadLogActive({
        variables: {
          userId: String(myData.id),
          userName: myData.name,
          userPosition: myData.position,
          userDepartment: myData.department,
          userEmail: myData.email,
          donwloadType: "excel",
          downloadList:
            eventExcel === "전체 내역" ? [{ "받은 데이터 개수": rows_hide.length }] : rows_hide,
          downloadDate: new Date(),
        },
      })
    }
  }, [rows_hide])

  const isSelected = (name) => selected.indexOf(name) !== -1
  const padingList = [10, 20, 50, 100]
  const padingListSelects = padingList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const handleRowsPerPageChange = (event) => {
    if (FilterSearchActive) {
      setRowsPerPage(event.target.value)
    } else {
      setRowsPerPage(event.target.value)
    }
  }
  const handlePageSearchChange = (event, value) => {
    // dispatch(loading_Apply(true))
    setsearchPage(value)
  }
  const handlePageChange = (event, value) => {
    dispatch(loading_Apply(true))
    setPage(value)
  }
  const TableReload = async () => {
    if (FilterSearchActive) {
      filterSearch()
    } else {
      dispatch(loading_Apply(true))
      let search_events = []
      if (search_events) {
        let variables = {
          state_ne: "delete",
          start_index: (page - 1) * rowsPerPage,
          limit: rowsPerPage,
        }
        if (filterChipData.length > 0) {
          variables["_or"] = []
          for (let i = 0; i < filterChipData.length; i++) {
            variables["_or"].push({ publisher_contains: filterChipData[i] + "_" })
          }
        }
        seteventCountVariables(variables)

        // Lazy쿼리 사용시 새로고침이 늦게 적용되는 현상이 있어서 변경 - 성시헌
        await client
          .query({
            query: events,
            variables: variables,
            fetchPolicy: "network-only",
          })
          .then((res) => {
            search_events = res.data
          })

        let events_ids = search_events.events.map((el) => el.id)
        let receiver_variables = {
          ids: events_ids,
          _or: [
            {
              status: "완료",
            },
            {
              status: "미발송",
            },
            { mailType: "result", result_status_ne: "" },
          ],
        }
        let receivers = []
        let events_result_reciver = []
        await client
          .query({
            query: eventReceiversStatus,
            variables: receiver_variables,
            fetchPolicy: "network-only",
          })
          .then((res) => {
            receivers = res.data.eventReceviers
            events_result_reciver = res.data.eventReceviers.filter(
              (x) => x.result_status === "완료"
            )
          })

        //
        if (search_events.events.length > 0) {
          let rowsData = []
          search_events.events.forEach((el, index) => {
            let info_mail_count = "" // 안내 발송 / 승인 텍스트
            let all_recevier = receivers.filter((receiver) => receiver.eventId === el.id)
            let info_receiver = receivers.filter(
              // 안내 발송 총 건수
              (receiver) =>
                receiver.eventId === el.id &&
                receiver.status === "완료" &&
                receiver.mailType === "info"
            )
            let info_receiver_access_sentOnly = receivers.filter(
              // 안내 발송된것 중에서 승인된 건수
              (receiver) =>
                receiver.eventId === el.id &&
                receiver.status === "완료" &&
                receiver.mailType === "info" &&
                receiver.access === "access"
            )
            let info_receiver_reject_sentOnly = receivers.filter(
              // 안내 발송된것 중에서 거절된 건수
              (receiver) =>
                receiver.eventId === el.id &&
                receiver.status === "완료" &&
                receiver.mailType === "info" &&
                receiver.access === "reject"
            )

            let info_receiver_access = receivers.filter(
              // 안내 발송 중 발송+미발송 승인 건수
              (receiver) =>
                receiver.eventId === el.id &&
                receiver.mailType === "info" &&
                receiver.access === "access"
            )
            let info_receiver_complete_data = receivers.filter(
              // 안내 발송(승인) && 결과보고(전송완료) 데이터
              (receiver) =>
                (receiver.eventId === el.id &&
                  receiver.mailType === "info" &&
                  receiver.access === "access") ||
                (receiver.eventId === el.id && receiver.mailType === "result")
            )
            // 안내 발송 텍스트 세팅
            if (
              info_receiver.length > 0 ||
              info_receiver_access_sentOnly.length > 0 ||
              info_receiver_reject_sentOnly.length > 0
            ) {
              let total_info_count = info_receiver.length > 0 ? info_receiver.length : "-"
              let access_info_count =
                info_receiver_access_sentOnly.length > 0
                  ? info_receiver_access_sentOnly.length
                  : "-"
              let reject_info_count =
                info_receiver_reject_sentOnly.length > 0
                  ? info_receiver_reject_sentOnly.length
                  : "-"

              info_mail_count = `${total_info_count} / ${access_info_count} / ${reject_info_count}`
            } else {
              info_mail_count = "-"
            }

            let result_receiver = events_result_reciver.filter(
              // 결과보고 발송 총 건수
              (receiver) => receiver.eventId === el.id && receiver.mailType === "result"
            )

            // 안내발송 승인 수 / 결과보고 메일 수
            let result_mail_count = `${
              info_receiver_access.length > 0 ? info_receiver_access.length : "-"
            } / ${result_receiver.length > 0 ? result_receiver.length : "-"}`

            let evnets_works_lists = info_receiver_complete_data.filter(
              (v, i, a) => a.findIndex((v2) => v2.bookCode === v.bookCode) === i
            )

            let year_month
            year_month = dayjs(el.start).format("YY") + " / " + dayjs(el.start).format("MM")
            let start_end
            start_end =
              dayjs(el.start).format("YYYY.MM.DD") + " ~ " + dayjs(el.end).format("YYYY.MM.DD")
            let status
            if (el.status === "cancel") {
              status = "취소"
            } else {
              if (dayjs(el.start).format("YYYY.MM.DD") > dayjs(new Date()).format("YYYY.MM.DD")) {
                status = "예정"
              } else if (
                dayjs(el.start).format("YYYY.MM.DD") <= dayjs(new Date()).format("YYYY.MM.DD") &&
                dayjs(el.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
              ) {
                status = "진행중"
              } else if (
                dayjs(el.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
              ) {
                status = "완료"
              }
            }

            rowsData.push(
              createData(
                el.id,
                year_month,
                el.flatform,
                el.title,
                start_end,
                status,
                el.serial,
                el.type,
                el.publisher,
                el.reward,
                info_mail_count,
                result_mail_count,
                evnets_works_lists,
                all_recevier
              )
            )
            if (index === search_events.events.length - 1) {
              setTimeout(() => {
                dispatch(loading_Apply(false))
              }, 1000)
              setrows(rowsData)
            }
          })
        } else {
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 1000)
          setrows(search_events.events)
        }
      }
      setsearchPage(1)
    }
  }
  const FilterReload = () => {
    setDepartment("")
    setManagerName("")
    setEventTitle("플랫폼(전체)")
    setEventType("이벤트 종류(전체)")
    setEventSerial("단행/연재(전체)")
    setEventDateYear("-")
    setEventDateMonth("-")
    setStartDate(null)
    setEndDate(null)
    setInputFilterTitle("")
    setfilterChipData([])
  }
  const teamList = settings_ref.current
    ? settings_ref.current.department
    : [
        "전체",
        "경영지원실",
        "콘텐츠사업본부",
        "전략기획팀",
        "플랫폼사업팀",
        "IP사업팀",
        "인터랙션디자인팀",
        "서비스개발팀",
        "서비스운영팀",
      ]
  const closeDialog = () => {
    TableReload()
    set_event_dialog(false)
  }
  const teamListSelects = teamList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })

  const outTypeList = settings_ref.current
    ? settings_ref.current.outContract
    : ["의뢰가능", "의뢰 중", "계약해지"]

  const outTypeListSelects = outTypeList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const typeList =
    settings_ref.current && settings_ref.current.eventFlatform
      ? settings_ref.current.eventFlatform.map((item) =>
          typeof item === "object" ? item.value : item
        )
      : [
          "플랫폼(전체)",
          "네이버",
          "리디북스",
          "카카오페이지",
          "알라딘",
          "교보문고",
          "에피루스",
          "북큐브",
          "미스터블루",
          "톡소다",
          "블라이스",
          "원스토어",
          "봄툰",
          "문피아",
          "조아라(프리미엄)",
        ]

  const typeListSelects = typeList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const eventTypeList =
    settings_ref.current && settings_ref.current.eventType
      ? settings_ref.current.eventType
      : ["이벤트 종류(전체)", "신작 단독", "신작 통합", "구작 단독", "구작 통합", "기타"]

  const eventTypeListSelects = eventTypeList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const eventSerialList = ["단행/연재(전체)", "단행", "연재"]
  const eventRewardList =
    settings_ref.current && settings_ref.current.eventReward
      ? settings_ref.current.eventReward
      : [
          "기본 리워드(전체)",
          "구매할인",
          "대여 할인",
          "단행 무료",
          "연재 무료",
          "재정가",
          "대여권",
          "기타",
        ]

  const eventSerialSelects = eventSerialList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const now = new Date()
  let years = ["-"]
  for (let y = 2030; y >= 2020; y -= 1) {
    years.push(y)
  }
  const eventYearSelects = years.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}년
      </MenuItem>
    )
  })
  let month = ["-"]
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString())
    } else {
      month.push(m.toString())
    }
  }
  const eventMonthSelects = month.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}월
      </MenuItem>
    )
  })
  const [searchedFilter, setsearchedFilter] = useState(orFilterData)
  const handleSearchedFilter = () => {
    let datas = FilterDatas
    datas.filterChipData = filterChipData.join()
    setsearchedFilter(datas)
  }

  useLayoutEffect(() => {
    setminiSidenav(localStorage.getItem("miniSidenav"))
  }, [localStorage.getItem("miniSidenav")])

  const [miniSidenav, setminiSidenav] = useState(localStorage.getItem("miniSidenav"))
  const iconActive = () => {
    setopenFilter(false)
  }

  /****작품명 검색 아이콘 클릭 이벤트
 @author 전현균 
 */
  const work_searchIcon_click = () => {
    // 실행되는 듯 보이도록 로딩 0.5초 세팅
    dispatch(loading_Apply(true))
    setTimeout(() => {
      dispatch(loading_Apply(false))
    }, 500)

    if (workSearchActive) {
      // 검색 취소
      workSearchValue.current.value = ""
      setWorkSearchText("")
      setWorkSearchActive(false)
    } else {
      // 검색 하기
      if (workSearchValue.current.value.trim().length > 0) {
        // 띄어쓰기만 있을 경우 외에만 검색 활성화
        setWorkSearchText(workSearchValue.current.value.trim())
        setWorkSearchActive(true)
      } else {
        // 띄어쓰기만 있을 경우 검색 비활성화
        workSearchValue.current.value = ""
        setWorkSearchText("")
        setWorkSearchActive(false)
      }
    }
  }

  /****작품명 검색이 활성화 되었을 때, 이벤트 참여 작품 내의 작품명이 해당하는 경우 true 리턴
 @param {object} row 테이블 행 데이터
 @author 전현균 
 @returns true / false 
 */
  const workSearchBg = (row) => {
    if (workSearchActive) {
      if (search_select_value === "작품 명") {
        if (row.mailSendWorks) {
          // 이벤트 참여작품 내의 작품명이 검색 키워드를 포함하는 경우 true 리턴
          let filterd_work = row.mailSendWorks.filter((el) =>
            el.title.toUpperCase().includes(workSearchText.toUpperCase())
          )
          if (filterd_work.length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else if (search_select_value === "작가 담당자(안내 발송)") {
        if (row.all_recevier) {
          let filterd_work = row.all_recevier.filter(
            (el) =>
              el.mailType === "info" &&
              el.manager &&
              el.manager.toUpperCase().includes(workSearchText.toUpperCase())
          )
          if (filterd_work.length > 0) {
            return true
          } else {
            return false
          }
        }
      } else if (search_select_value === "작가 담당자(결과 보고)") {
        if (row.all_recevier) {
          let filterd_work = row.all_recevier.filter(
            (el) =>
              el.mailType === "result" &&
              el.manager &&
              el.manager.toUpperCase().includes(workSearchText.toUpperCase())
          )
          if (filterd_work.length > 0) {
            return true
          } else {
            return false
          }
        }
      }
    } else {
      return false
    }
  }
  const checkNull = (str) => {
    if (typeof str == "undefined" || str == null || str == "") {
      return true
    } else {
      return false
    }
  }
  const authroiztionData = (type, data, row) => {
    if (type === "name") {
      let name = []

      data.split(",").forEach((el) => {
        if (el.length === 1) {
          name.push(el)
        } else if (el.length === 2) {
          name.push(el.substring(0, 1) + "*")
        } else if (el.length === 3) {
          name.push(el.substring(0, 1) + "**")
        } else {
          if (el) {
            let star = "*".repeat(el.length - 2)
            name.push(el.substring(0, 2) + star)
          }
        }
      })
      if (
        row.user === myData.id ||
        row.outsourcing_contracts.filter((x) => x.referrer.map((x) => x.id).indexOf(myData.id) > -1)
          .length > 0
      ) {
        return data
      } else {
        return name.join(", ")
      }
    } else if (type === "email") {
      let originStr = data
      let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
      let strLength

      if (checkNull(originStr) == true || checkNull(emailStr) == true) {
        return originStr
      } else {
        strLength = emailStr.toString().split("@")[0].length - 3

        // ex1) abcdefg12345@naver.com => ab**********@naver.com
        if (
          row.user === myData.id ||
          row.outsourcing_contracts.filter(
            (x) => x.referrer.map((x) => x.id).indexOf(myData.id) > -1
          ).length > 0
        ) {
          return data
        } else {
          return originStr.toString().replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*")
        }

        // ex2) abcdefg12345@naver.com => ab**********@nav******
        // return originStr
        //   .toString()
        //   .replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*")
        //   .replace(/.{6}$/, "******")
      }
    }
  }
  const createDialog = () => {
    set_event_dialog(true)
    set_type_event("create")
  }
  function SearchedField() {
    let datas = searchedFilter
    const [searchedData, setsearchedData] = useState(true)
    useLayoutEffect(() => {
      setsearchedData(Object.values(datas).every((x) => x === false || x === "" || x === null))
    }, [searchedFilter])
    let stringData = ""

    for (const key in datas) {
      let value
      let keyValue
      if (datas[key] === "" || datas[key] === null) {
        value = "-"
      } else if (datas[key] === true) {
        value = "Y"
      } else if (datas[key] === false) {
        value = "N"
      } else {
        value = datas[key]
      }
      if (key === "department") {
        keyValue = "부서"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "managerName") {
        keyValue = "담당자 명"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventFlatform") {
        keyValue = "플랫폼"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventType") {
        keyValue = "이벤트 종류"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventSerial") {
        keyValue = "단행/연재"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventDateYear") {
        keyValue = "연도"
        if (value !== "-") {
          value = value + "년"
        }
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventDateMonth") {
        keyValue = "월"
        if (value !== "-") {
          value = value + "월"
        }
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventStart") {
        keyValue = "이벤트 시작 일"
        stringData = stringData + `${keyValue}:${value}, `
      } else if (key === "eventEnd") {
        keyValue = "이벤트 종료 일"
        stringData = stringData + `${keyValue}:${value}, `
      }
    }
    let stringShowData = stringData.substring(0, stringData.length - 2)
    const filterCloseBtn = async () => {
      dispatch(loading_Apply(true))
      setfilterChipData([])
      // FilterReload()
      const orFilterDataSet = {
        department: "", // 담당부서
        managerName: "", // 담당자 명
        eventFlatform: "", // 이벤트 플랫폼
        eventType: "", // 이벤트 종류
        eventSerial: "", // 단행/연재
        eventDateYear: "", // 이벤트 기간 년
        eventDateMonth: "", // 이벤트 기간 월
        eventStart: "", // 이벤트 시작 일
        eventEnd: "", // 이벤트 종료 일
        // bossName: "", //  대표자
        // companyid: "", // 유통사 id
        // bizNumber: "", // 유통사 사업자 번호
        // department: "", // 등록자 부서
        // filterChipData: "", // 필수 계약
        // managerName: "", // 등록자 명
        // name: "", // 유통사 이름 명
        // name: "", // 작가 본명
        // userId: "", // 회원 ID
        // birth: null, // 생년월일
        // birthMonth: "", // 생년월
        // ebookname: "", // 이북 필명
        // joaraname: "", // 조아라 필명
      }
      setDepartment("")
      setManagerName("")
      setEventTitle("플랫폼(전체)")
      setEventType("이벤트 종류(전체)")
      setEventSerial("단행/연재(전체)")
      setInputFilterTitle("")
      setEventDateYear("-")
      setEventDateMonth("-")
      setStartDate("")
      setEndDate("")

      setFilterDatas(orFilterDataSet)
      setsearchedFilter(orFilterDataSet)
      setsearchedData(true)
      setFilterSearchActive(false)
      // let CountData = {
      //   status: "공개",
      // }
      // setoutsourcingCountFilter(CountData)
      setPage(1)
      // setoutsourcingsVariables({
      //   sort: "created_at:desc",
      //   start: 0,
      //   limit: rowsPerPage,
      //   status: "공개",
      // })

      let search_events = []
      let variables = {
        state_ne: "delete",
        start_index: 0,
        limit: rowsPerPage,
        _or: [],
      }

      seteventCountVariables(variables)

      await client
        .query({
          query: events,
          variables: variables,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          search_events = res.data

          if (search_events.events.length > 0) {
            let rowsData = []

            let events_ids = search_events.events.map((el) => el.id)
            let receiver_variables = {
              ids: events_ids,
              _or: [
                {
                  status: "완료",
                },
                {
                  status: "미발송",
                },
                { mailType: "result", result_status_ne: "" },
              ],
            }
            client
              .query({
                query: eventReceiversStatus,
                variables: receiver_variables,
                fetchPolicy: "network-only",
              })
              .then((res) => {
                let receivers = res.data.eventReceviers
                let events_result_reciver = res.data.eventReceviers.filter(
                  (x) => x.result_status === "완료"
                )
                search_events.events.forEach((el, index) => {
                  let info_mail_count = "" // 안내 발송 / 승인 텍스트
                  let all_recevier = receivers.filter((receiver) => receiver.eventId === el.id)
                  let info_receiver = receivers.filter(
                    // 안내 발송 총 건수
                    (receiver) =>
                      receiver.eventId === el.id &&
                      receiver.status === "완료" &&
                      receiver.mailType === "info"
                  )
                  let info_receiver_access_sentOnly = receivers.filter(
                    // 안내 발송된것 중에서 승인된 건수
                    (receiver) =>
                      receiver.eventId === el.id &&
                      receiver.status === "완료" &&
                      receiver.mailType === "info" &&
                      receiver.access === "access"
                  )
                  let info_receiver_reject_sentOnly = receivers.filter(
                    // 안내 발송된것 중에서 거절된 건수
                    (receiver) =>
                      receiver.eventId === el.id &&
                      receiver.status === "완료" &&
                      receiver.mailType === "info" &&
                      receiver.access === "reject"
                  )

                  let info_receiver_access = receivers.filter(
                    // 안내 발송 중 발송+미발송 승인 건수
                    (receiver) =>
                      receiver.eventId === el.id &&
                      receiver.mailType === "info" &&
                      receiver.access === "access"
                  )
                  let info_receiver_complete_data = receivers.filter(
                    // 안내 발송(승인) && 결과보고(전송완료) 데이터
                    (receiver) =>
                      (receiver.eventId === el.id &&
                        receiver.mailType === "info" &&
                        receiver.access === "access") ||
                      (receiver.eventId === el.id && receiver.mailType === "result")
                  )
                  // 안내 발송 텍스트 세팅
                  if (
                    info_receiver.length > 0 ||
                    info_receiver_access_sentOnly.length > 0 ||
                    info_receiver_reject_sentOnly.length > 0
                  ) {
                    let total_info_count = info_receiver.length > 0 ? info_receiver.length : "-"
                    let access_info_count =
                      info_receiver_access_sentOnly.length > 0
                        ? info_receiver_access_sentOnly.length
                        : "-"
                    let reject_info_count =
                      info_receiver_reject_sentOnly.length > 0
                        ? info_receiver_reject_sentOnly.length
                        : "-"

                    info_mail_count = `${total_info_count} / ${access_info_count} / ${reject_info_count}`
                  } else {
                    info_mail_count = "-"
                  }

                  let result_receiver = events_result_reciver.filter(
                    // 결과보고 발송 총 건수
                    (receiver) => receiver.eventId === el.id && receiver.mailType === "result"
                  )

                  // 안내발송 승인 수 / 결과보고 메일 수
                  let result_mail_count = `${
                    info_receiver_access.length > 0 ? info_receiver_access.length : "-"
                  } / ${result_receiver.length > 0 ? result_receiver.length : "-"}`

                  let evnets_works_lists = info_receiver_complete_data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.bookCode === v.bookCode) === i
                  )

                  let year_month
                  year_month = dayjs(el.start).format("YY") + " / " + dayjs(el.start).format("MM")
                  let start_end
                  start_end =
                    dayjs(el.start).format("YYYY.MM.DD") +
                    " ~ " +
                    dayjs(el.end).format("YYYY.MM.DD")
                  let status
                  if (el.status === "cancel") {
                    status = "취소"
                  } else {
                    if (
                      dayjs(el.start).format("YYYY.MM.DD") > dayjs(new Date()).format("YYYY.MM.DD")
                    ) {
                      status = "예정"
                    } else if (
                      dayjs(el.start).format("YYYY.MM.DD") <=
                        dayjs(new Date()).format("YYYY.MM.DD") &&
                      dayjs(el.end).format("YYYY.MM.DD") >= dayjs(new Date()).format("YYYY.MM.DD")
                    ) {
                      status = "진행중"
                    } else if (
                      dayjs(el.end).format("YYYY.MM.DD") < dayjs(new Date()).format("YYYY.MM.DD")
                    ) {
                      status = "완료"
                    }
                  }

                  rowsData.push(
                    createData(
                      el.id,
                      year_month,
                      el.flatform,
                      el.title,
                      start_end,
                      status,
                      el.serial,
                      el.type,
                      el.publisher,
                      el.reward,
                      info_mail_count,
                      result_mail_count,
                      evnets_works_lists,
                      all_recevier
                    )
                  )
                  if (index === search_events.events.length - 1) {
                    setTimeout(() => {
                      dispatch(loading_Apply(false))
                    }, 1000)
                    setrows(rowsData)
                  }
                })
              })
          } else {
            setTimeout(() => {
              dispatch(loading_Apply(false))
            }, 1000)
            setrows(search_events.events)
          }
        })
        .catch(() => {
          dispatch(loading_Apply(false))
        })
      // setEventData(search_events.events)

      setloading(false)
    }
    return (
      <SuiBox>
        {FilterSearchActive ? (
          <SuiBox
            ml={1.5}
            p={2}
            mr={0}
            mt={4}
            display="flex"
            style={{ alignItems: "center", width: "fit-content" }}
            className="filter-data-box"
          >
            <div>
              <SuiBox display="flex" style={{ alignItems: "center" }}>
                <SuiTypography
                  style={{ fontSize: "16px", color: "#535353" }}
                  verticalAlign="middle"
                >
                  검색 내용
                </SuiTypography>
                <IconButton
                  color="text2"
                  size="small"
                  onClick={() => filterCloseBtn()}
                  aria-label="close"
                  sx={{
                    p: 0,
                    ml: "auto",
                  }}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiTypography style={{ fontSize: "16px", color: "#707070" }} verticalAlign="middle">
                {stringShowData}
              </SuiTypography>
            </div>
          </SuiBox>
        ) : (
          <SuiBox mt={12} style={{ alignItems: "center", width: "66%" }}></SuiBox>
        )}
      </SuiBox>
    )
  }

  const comma = (price) => {
    let str = String(price)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
  }
  const handleDatePickerChange = (type) => (event) => {
    if (type === "start") {
      setStartDate(dayjs(event).format("YYYY-MM-DD"))
    }
    if (type === "end") {
      setEndDate(dayjs(event).format("YYYY-MM-DD"))
    }
  }

  return (
    <DashboardLayout>
      <Event
        open={event_dialog}
        closeDialog={closeDialog}
        editId={edit_event_id}
        type={type_event}
      />
      <Box sx={{ width: "100%" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1201 }}
          open={openFilter}
          onClick={filterClose}
        ></Backdrop>
        <SuiBox display="flex" justifyContent="start" height="100%" sx={{ ml: "12px" }}>
          {/* QA사항에서 삭제요청된 부분으로써 주석처리
                                              -성시헌
          */}
          {/* <SuiBox
            sx={{
              width: 120,
              height: 40,
              zIndex: 1202,
              alignItems: "center",
              border: "1px solid #d2d6da !important",
              padding: "8px 12px",
              background: "white",
            }}
            className={openFilter ? "filter-Open-border-radius" : "search-border-radius-small"}
          >
            <SuiTypography style={{ fontSize: "14px" }}>이벤트 명</SuiTypography>
          </SuiBox> */}
          <SuiBox
            type="titleType"
            sx={{
              width: openFilter ? 800 : 400,
              borderBottom: openFilter ? 1 : "",
              borderColor: openFilter ? "#d2d6da" : "",
              height: 40,
              zIndex: 1202,
            }}
            className={
              openFilter ? "filter-detail-open filter-overlay-radius" : "filter-right-icon"
            }
            onClick={() => {
              if (!openFilter) {
                openFilterInput()
              }
            }}
          >
            <SuiInput
              icon={
                openFilter
                  ? { component: "close", direction: "right" }
                  : { component: "search", direction: "right" }
              }
              iconActive={iconActive}
              type="text"
              placeholder={
                filterTitle === "searchEvent"
                  ? "이벤트 명을 입력해주세요."
                  : filterTitle === "searchTitle"
                  ? "이벤트 참여 작품 명을 입력해주세요."
                  : ""
              }
              value={inputFilterTitle}
              onClick={openFilterInput}
              onChange={handleFilterDatas("titleType")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  filterSearch()
                }
              }}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          sx={{
            display: openFilter ? "" : "none",
            position: "absolute",
            zIndex: 5,
            backgroundColor: "#ffffff",
            p: 2,
            width: 800,
            borderRight: 1,
            borderLeft: 1,
            borderBottom: 1,
            boxShadow: 2,
            borderColor: "#d2d6da",
            borderRadius: "0 0 10px 10px",
            zIndex: 1202,
            ml: 1.5,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <SuiBox display="flex" alignItems="center">
                <SuiTypography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  상세검색
                </SuiTypography>
                <SuiBox
                  ml={0.5}
                  mt={0.5}
                  component="img"
                  src="/reload.png"
                  alt="Soft UI Logo"
                  height="36px"
                  className="cursor"
                  onClick={() => FilterReload()}
                />
                <SuiButton
                  sx={{ fontSize: 14, width: 10, minWidth: 25, height: 20, ml: "auto", p: 0 }}
                  variant="text"
                  color="info2"
                  size="small"
                  onClick={() => filterSave()}
                >
                  저장
                </SuiButton>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  등록자 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      department === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen3(!FiltersOpen3)}
                  >
                    <InputLabel className="smallLabel">부서</InputLabel>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen3(!FiltersOpen3)}
                        />
                      )}
                      open={FiltersOpen3}
                      label="담당부서"
                      value={department}
                      onChange={handleFilterDatas("department")}
                      className="setting-border-radius-small"
                    >
                      {teamListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <SuiInput
                    type="text"
                    placeholder="담당자 명"
                    value={managerName}
                    onChange={handleFilterDatas("managerName")}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  이벤트 정보
                </SuiTypography>
                <SuiBox sx={{ width: 192, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen6(!FiltersOpen6)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen6(!FiltersOpen6)}
                        />
                      )}
                      open={FiltersOpen6}
                      value={eventTitle}
                      onChange={handleFilterDatas("eventFlatform")}
                      className="setting-border-radius-small"
                    >
                      {typeListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen7(!FiltersOpen7)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen7(!FiltersOpen7)}
                        />
                      )}
                      open={FiltersOpen7}
                      value={eventType}
                      onChange={handleFilterDatas("eventType")}
                      className="setting-border-radius-small"
                    >
                      {eventTypeListSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                  <SuiBox sx={{ width: 192, height: 40, ml: 2 }}>
                    <FormControl
                      fullWidth
                      sx={{ minHeight: 30 }}
                      className={
                        FilterDatas.type === ""
                          ? "setting-selectbox select-none-active"
                          : "setting-selectbox"
                      }
                      onClick={() => setFiltersOpen8(!FiltersOpen8)}
                    >
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            fontSize="small"
                            color="dark"
                            className="select-icon cursor"
                            onClick={() => setFiltersOpen8(!FiltersOpen8)}
                          />
                        )}
                        open={FiltersOpen8}
                        value={eventSerial}
                        onChange={handleFilterDatas("eventSerial")}
                        className="setting-border-radius-small"
                      >
                        {eventSerialSelects}
                      </Select>
                    </FormControl>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiBox sx={{ width: 192, height: 40, ml: 13 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersOpen9(!FiltersOpen9)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen9(!FiltersOpen9)}
                        />
                      )}
                      open={FiltersOpen9}
                      value={eventReward}
                      onChange={handleFilterDatas("eventReward")}
                      className="setting-border-radius-small"
                    >
                      {eventRewardList.map((x) => {
                        return (
                          <MenuItem value={x} key={x}>
                            {x}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </SuiBox>
              </SuiBox>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  이벤트 기간
                </SuiTypography>
                <SuiBox sx={{ width: 92, height: 40, ml: 3 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() => setFiltersDateOpen1(!FiltersDateOpen1)}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersDateOpen1(!FiltersDateOpen1)}
                        />
                      )}
                      open={FiltersDateOpen1}
                      value={eventDateYear}
                      onChange={handleFilterDate("year")}
                      className="setting-border-radius-small"
                    >
                      {eventYearSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox sx={{ width: 92, height: 40, ml: 1 }}>
                  <FormControl
                    fullWidth
                    sx={{ minHeight: 30 }}
                    disabled={eventDateYear === "-"}
                    className={
                      FilterDatas.type === ""
                        ? "setting-selectbox select-none-active"
                        : "setting-selectbox"
                    }
                    onClick={() =>
                      eventDateYear === "-" ? "" : setFiltersDateOpen2(!FiltersDateOpen2)
                    }
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          disabled={eventDateYear === "-"}
                          onClick={() =>
                            eventDateYear === "-" ? "" : setFiltersDateOpen2(!FiltersDateOpen2)
                          }
                        />
                      )}
                      open={FiltersDateOpen2}
                      value={eventDateMonth}
                      onChange={handleFilterDate("month")}
                      className="setting-border-radius-small"
                    >
                      {eventMonthSelects}
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox type="start" sx={{ width: 192, height: 40, ml: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter_datepicker"
                      inputFormat={"YYYY-MM-DD"}
                      minDate={eventDateMonth ? `${eventDateYear}-${eventDateMonth}-01` : ""}
                      maxDate={endDate ? endDate : ""}
                      components={{
                        ActionBar: clearStartDate,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "이벤트 시작 일",
                          }}
                        />
                      )}
                      value={startDate}
                      onChange={handleDatePickerChange("start")}
                    />
                  </LocalizationProvider>
                </SuiBox>
                <SuiBox type="end" sx={{ width: 192, height: 40, ml: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter_datepicker"
                      inputFormat={"YYYY-MM-DD"}
                      minDate={startDate ? startDate : ""}
                      components={{
                        ActionBar: clearEndDate,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "이벤트 종료 일",
                          }}
                        />
                      )}
                      value={endDate}
                      onChange={handleDatePickerChange("end")}
                    />
                  </LocalizationProvider>
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiTypography
                  style={{ fontSize: "14px", fontWeight: "medium" }}
                  className="width80"
                  color="subTitle"
                >
                  출판사
                </SuiTypography>
                <SuiBox sx={{ width: 620, ml: 3 }}>{chipsListSelects}</SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <SuiBox display="flex" justifyContent="start" height="100%" alignItems="center">
                <SuiButton
                  variant="text"
                  color="text2"
                  sx={{
                    ml: "auto",
                    mr: 2,
                    height: 40,
                    border: 1,
                    width: 93,
                    borderColor: "#707070",
                    fontSize: 14,
                  }}
                  onClick={() => filterClose()}
                >
                  취소
                </SuiButton>
                <SuiButton
                  color="primary"
                  sx={{ fontSize: 14, height: 40 }}
                  onClick={() => filterSearch()}
                >
                  이대로 검색하기
                </SuiButton>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <Paper sx={{ width: "100%", boxShadow: 0, marginTop: "-10px" }} className="main-layout">
          <SearchedField />
          {/* <SuiBox>
            <SuiButton
              sx={{ fontSize: 14, minWidth: 25, height: 20, ml: "auto", p: 0 }}
              color="info2"
              size="small"
              onClick={() => createDialog()}
            >
              등록(예비로 만들어둠)
            </SuiButton>
          </SuiBox> */}
          <SuiBox ml="" pl={0} pb={2} mx={0} display="flex" style={{ alignItems: "center" }}>
            <SuiBox
              mt={0.5}
              component="img"
              src="/reload.png"
              alt="Soft UI Logo"
              height="48px"
              className="cursor"
              onClick={() => TableReload()}
            />

            {FilterSearchActive ? (
              <div style={{ display: "flex" }}>
                <SuiTypography
                  style={{ paddingLeft: "20px", fontSize: "16px", fontWeight: "medium" }}
                  verticalAlign="middle"
                >
                  검색결과 :&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  verticalAlign="middle"
                >
                  {rowlength} 건이 검색되었습니다.
                </SuiTypography>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <SuiTypography
                  style={{ fontSize: "16px", fontWeight: "medium" }}
                  verticalAlign="middle"
                >
                  확정 :&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  verticalAlign="middle"
                >
                  {eventComplete}건&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{ paddingLeft: "20px", fontSize: "16px", fontWeight: "medium" }}
                  verticalAlign="middle"
                >
                  예정 :&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  verticalAlign="middle"
                >
                  {eventExpected}건&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{ paddingLeft: "20px", fontSize: "16px", fontWeight: "medium" }}
                  verticalAlign="middle"
                >
                  취소 :&nbsp;
                </SuiTypography>
                <SuiTypography
                  style={{ paddingLeft: "20px", fontSize: "16px", fontWeight: "bold" }}
                  verticalAlign="middle"
                >
                  {eventCancel}건&nbsp;
                </SuiTypography>
              </div>
            )}

            {/* 작품명 검색 영역 - start */}
            <SuiBox sx={{ width: 185, ml: "auto" }}>
              <FormControl
                sx={{
                  width: "100%",
                }}
                className={"setting-selectbox"}
                onClick={() => set_filters_open1(!filters_open1)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => set_filters_open1(!filters_open1)}
                    />
                  )}
                  open={filters_open1}
                  value={search_select_value}
                  onChange={(e) => set_search_select_value(e.target.value)}
                  className="setting-border-radius"
                  sx={{
                    borderTopRightRadius: "0px !important",
                    borderBottomRightRadius: "0px !important",
                  }}
                >
                  <MenuItem value="작품 명">작품 명</MenuItem>
                  <MenuItem value="작가 담당자(안내 발송)">작가 담당자(안내 발송)</MenuItem>
                  <MenuItem value="작가 담당자(결과 보고)">작가 담당자(결과 보고)</MenuItem>
                </Select>
              </FormControl>
            </SuiBox>
            <SuiBox
              type="titleType"
              sx={{
                width: 300,
                "> div > p": {
                  display: "none",
                },
                "> div > .css-1qirbm4": {
                  height: "40px",
                },
              }}
              className={"event_work_search left_no_radius"}
            >
              <SuiInput
                className="small_search"
                icon={
                  workSearchActive
                    ? { component: "close", direction: "right" }
                    : { component: "search", direction: "right" }
                }
                iconActive={() => work_searchIcon_click()}
                type="text"
                placeholder={
                  search_select_value === "작품 명"
                    ? `${search_select_value}을 입력해주세요.`
                    : `${search_select_value}를 입력해주세요.`
                }
                inputRef={workSearchValue}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    // 실행되는 듯 보이도록 로딩 0.5초 세팅
                    dispatch(loading_Apply(true))
                    setTimeout(() => {
                      dispatch(loading_Apply(false))
                    }, 500)

                    if (workSearchValue.current.value.trim().length > 0) {
                      // 띄어쓰기만 있을 경우 외에만 검색 활성화
                      setWorkSearchText(workSearchValue.current.value.trim())
                      setWorkSearchActive(true)
                    } else {
                      // 띄어쓰기만 있을 경우 검색 비활성화
                      workSearchValue.current.value = ""
                      setWorkSearchText("")
                      setWorkSearchActive(false)
                    }
                  }
                }}
              />
            </SuiBox>
            {/* 작품명 검색 영역 - end  */}

            {myData.role.name !== "bronze" ? (
              <>
                <SuiBox width="120px" height="43px" ml="20px" mr="-11px">
                  <FormControl
                    fullWidth
                    onClick={() => setFiltersOpen10(!FiltersOpen10)}
                    sx={{
                      "> div": {
                        minHeight: "30px",
                        border: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          color="dark"
                          className="select-icon cursor"
                          onClick={() => setFiltersOpen10(!FiltersOpen10)}
                        />
                      )}
                      open={FiltersOpen10}
                      value={eventExcel}
                      onChange={(e) => seteventExcel(e.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="전체 내역">전체 내역</MenuItem>
                      <MenuItem value="현재 페이지">현재 페이지</MenuItem>
                    </Select>
                  </FormControl>
                </SuiBox>
                <SuiBox
                  sx={{ height: "61px", marginTop: "5px", zIndex: 2 }}
                  component="img"
                  src="/excel.png"
                  alt="Soft UI Logo"
                  height="52px"
                  className="cursor"
                  onClick={excelExportActive}
                />
              </>
            ) : (
              <SuiBox mr={2} height="52px" className="cursor" />
            )}
            <SuiBox width="70px" height="44px">
              <FormControl
                sx={{ "> div": { border: "none" } }}
                fullWidth
                onClick={() => setFiltersOpen4(!FiltersOpen4)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="dark"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen4(!FiltersOpen4)}
                    />
                  )}
                  open={FiltersOpen4}
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {padingListSelects}
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
          <div style={{ display: "none" }}>
            <ReactHTMLTableToExcel
              id="excelExport"
              className="download-table-xls-button"
              table="table-to-xls-hidden"
              filename={`이벤트 관리 대시보드 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`}
              sheet="tablexls"
              buttonText={"Download as XLS"}
            />
          </div>

          <TableContainer sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{
                        cursor: "pointer",
                        background: workSearchBg(row) ? "rgb(92, 56, 255, 0.1)!important" : "",
                      }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.yearMonth}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.flatform}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.startEnd}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        sx={{
                          color:
                            row.status === "취소"
                              ? "#FF4D60 !important"
                              : row.status === "완료"
                              ? "#00C160 !important"
                              : "",
                          borderRight: "1px solid #F1F1F5",
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.serial}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.publisher &&
                        row.publisher.split("_").length > 1 &&
                        row.publisher.split("_")[1] !== "" ? (
                          <Tooltip
                            arrow={false}
                            color="white"
                            placement="right-start"
                            classes={{ popper: "tooltip-light" }}
                            title={
                              <SuiBox>
                                {row.publisher &&
                                  row.publisher.split("_").map((x) => {
                                    return (
                                      <SuiTypography
                                        style={{
                                          fontSize: "14px",
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                        verticalAlign="middle"
                                      >
                                        {x}
                                      </SuiTypography>
                                    )
                                  })}
                              </SuiBox>
                            }
                          >
                            {row.publisher.split("_")[0]} 외 {row.publisher.split("_").length - 1}
                          </Tooltip>
                        ) : row.publisher ? (
                          row.publisher.split("_")[0]
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.sendCount}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.resultCount}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (row.mailSendWorks && row.mailSendWorks.length > 0) {
                            openWorksListsDialog(row.mailSendWorks, row)
                          } else {
                            handleDetail(row)
                          }
                        }}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.mailSendWorks && row.mailSendWorks.length > 0 ? (
                          <SuiTypography className="event_works_lists">
                            {`${row.mailSendWorks.length}종`}
                          </SuiTypography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      {/* <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.contractStatus}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {myData.role.name !== "bronze" && myData.role.name !== "silver"
                          ? row.name
                          : authroiztionData("name", row.name, row)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.nickname}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {comma(row.minPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {comma(row.maxPrice)}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {myData.role.name !== "bronze" && myData.role.name !== "silver"
                          ? row.email
                          : authroiztionData("email", row.email, row)}
                      </TableCell>
                      {miniSidenav === "false" ? (
                        <TableCell
                          onClick={() => handleDetail(row)}
                          align="center"
                          className="td-Setting td-fontSetting"
                        >
                          {row.phone}
                        </TableCell>
                      ) : null}
                      {miniSidenav === "false" ? (
                        <TableCell onClick={() => handleDetail(row)} align="center">
                          <Tooltip
                            arrow={false}
                            color="white"
                            className=""
                            classes={{ popper: "tooltip-light" }}
                            title={
                              <SuiBox>
                                <SuiTypography
                                  style={{
                                    fontSize: "14px",
                                    color: "dark",
                                    fontWeight: "bold",
                                    whiteSpace: "pre-line",
                                  }}
                                  verticalAlign="middle"
                                >
                                  {row.comment
                                    ? row.comment.slice(0, 3).map((comment, index) => {
                                        return `[${row.comment.length - index}]_${
                                          comment.content
                                        }_${dayjs(comment.updated_at).format("YYYY.MM.DD")} \n`
                                      })
                                    : null}
                                </SuiTypography>
                              </SuiBox>
                            }
                          >
                            <SuiTypography
                              sx={{
                                padding: "0px !important",
                                margin: "0 auto",
                              }}
                              className="td-Setting td-fontSetting"
                            >
                              {row.comment ? row.comment[0].content : ""}
                            </SuiTypography>
                          </Tooltip>
                        </TableCell>
                      ) : null}
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.genre}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {row.workingLength}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row)}
                        align="center"
                        className="td-Setting td-fontSetting"
                      >
                        {dayjs(row.updated_at).format("YY.MM.DD")}
                      </TableCell> */}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {/* excel-export-table */}

            <Table
              style={{ display: "none" }}
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="table-to-xls-hidden"
            >
              {/* 총 수 */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span>총 수</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventComplete + eventExpected + eventCancel}건</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* 빈 행 */}
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {/* 확정 */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span>확정</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventComplete}건</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventCompleteWork}종</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* 예정 */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span>예정</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventExpected}건</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventExpectedWork}종</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* 취소 */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span>취소</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventCancel}건</span>
                  </TableCell>
                  <TableCell>
                    <span>{eventCancelWork}종</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* 빈 행 */}
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {/* 빈 행 */}
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowlength}
                changeFilter={setchangeFilter}
                showchangeFilter={changeFilter}
                excelTable={true}
              />
              <TableBody>
                {stableSort(rows_hide, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.year}
                      </TableCell>{" "}
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.month}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.flatform}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.title}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.start}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.end}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        sx={{
                          color:
                            row.status === "취소"
                              ? "#FF4D60 !important"
                              : row.status === "완료"
                              ? "#00C160 !important"
                              : "",
                          borderRight: "1px solid #F1F1F5",
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.serial}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.type}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.publisher}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.reward}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.sendCount}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.resultCount}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.genre}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.workName}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.joaraName}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.ebookName}
                      </TableCell>
                      <TableCell
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                        style={{ borderRight: "1px solid #F1F1F5" }}
                      >
                        {row.eachReward}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {/* 여기까지 */}
          </TableContainer>
          {FilterSearchActive ? (
            <SuiBox
              pt={3.75}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: totalpage === 0 || totalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom page={page} totalPage={totalpage} changePage={handlePageChange} />
              {/* <Pagination
                page={searchPage}
                count={searchTotalpage}
                color="primary"
                onChange={handlePageSearchChange}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          ) : (
            <SuiBox
              pt={3.75}
              mx={3}
              display="flex"
              justifyContent="center"
              sx={{ display: totalpage === 0 || totalpage === 1 ? "none" : "" }}
            >
              <PaginationCustom page={page} totalPage={totalpage} changePage={handlePageChange} />
              {/* <Pagination
                page={page}
                count={totalpage}
                color="primary"
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              /> */}
            </SuiBox>
          )}
          {FilterSearchActive ? (
            loading ? (
              <SuiBox
                py={2}
                mt="80px"
                display="flex"
                justifyContent="center"
                textAlign="center"
                sx={{ display: totalpage === 0 ? "" : "none" }}
              >
                <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }} color="dark">
                  검색 결과가 없습니다.
                  <SuiTypography
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      textDecoration: "underline",
                      padding: "20px 0",
                    }}
                    color="info2"
                    onClick={() => resetSearch()}
                  >
                    다시 검색하기
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            ) : (
              <SuiBox py={2} ml={2} display="flex" sx={{ display: totalpage === 0 ? "" : "none" }}>
                <SuiTypography
                  style={{ cursor: "pointer", fontSize: "16px", textDecoration: "underline" }}
                  color="info2"
                  onClick={() => resetSearch()}
                >
                  <CircularProgress style={{ width: 20, height: 20 }} />
                </SuiTypography>
              </SuiBox>
            )
          ) : (
            ""
          )}
        </Paper>
      </Box>
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <SuiDialog
        openDialog={InfoAlert_Acess.dialog}
        closeDialog={() => {
          setInfoAlert(InfoAlertSet)
          navigate("/MyPage")
        }}
        MainTitle={InfoAlert_Acess.title}
        Content={InfoAlert_Acess.content}
        CanBtnText={InfoAlert_Acess.CanBtnText}
      />
      <EventWorksDialog
        openDialog={workDialog.dialog}
        workLists={workDialog.workLists}
        closeDialog={() => closeWorksListsDialog()}
      />
    </DashboardLayout>
  )
}
