import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useApolloClient } from "@apollo/client"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Tooltip from "@mui/material/Tooltip"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import dayjs from "dayjs"
import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { AiOutlineSearch } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"

import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { events, eventEmails, eventReceviers, eventResultEmails } from "apollo/query"
import {
  createEventEmail,
  updateEventEmail,
  createEventResultEmail,
  updateEventResultEmail,
  createSendEmailEvent,
  createEventRecevier,
  updateEventRecevier,
  createSendSm,
  updateEvent,
} from "apollo/mutation"

function importEmail({
  openDialog,
  closeDialog,
  closeDialog_import,
  closeDialog_create,
  editId,
  stepNext,
}) {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const [open_info_dialog, set_open_info_dialog] = useState(false) // 에러 다이어로그 오픈여부
  const [open_title, set_open_title] = useState("") // 에러 다이어로그 제목
  const [open_content, set_open_content] = useState("") // 에러 다이어로그 내용
  const [step, set_step] = useState(0)
  const [import_type, set_import_type] = useState("import") // 신규 생성 or 안내메일 불러오기 라디오
  const [import_writer_type, set_import_writer_type] = useState("agree") // 승인한작가 or 메일 발송 전체작가 라디오
  const [info_email_list, set_info_email_list] = useState([]) // 불러올 안내메일 리스트들
  const [info_email, set_info_email] = useState([]) // 선택된 안내메일
  const [info_result_email, set_info_result_email] = useState([]) // 선택된 안내메일이 이미 선택된 안내메일인지 확인하기위해 결과메일 불러오기

  const { myData } = useSelector((state) => state.store)
  const [all_event, set_all_event] = useState({
    history: [],
  })
  const startSetting = async (valueId, sort) => {
    dispatch(loading_Apply(true))

    // 진입시 데이터 불러오기
    await client
      .query({
        query: eventResultEmails,
        variables: {
          eventId: String(valueId),
          sort: sort ? sort : "created_at:desc",
        },
        fetchPolicy: "network-only",
      })
      .then(async (res2) => {
        let result = res2.data.eventResultEmails
        set_info_result_email(result)
      })
    let event_email_list = []
    await client
      .query({
        query: eventEmails,
        variables: {
          eventId: String(valueId),
          sort: "created_at:Asc",
        },
        fetchPolicy: "network-only",
      })
      .then(async (res2) => {
        let result = res2.data.eventEmails
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            await client
              .query({
                query: eventReceviers,
                variables: {
                  eventEmailId: String(result[i].id),
                  mailType: "info",
                },
                fetchPolicy: "network-only",
              })
              .then((res) => {
                let resData = res.data.eventReceviers
                let response = []
                for (let j = 0; j < resData.length; j++) {
                  response.push({
                    receiverId: resData[j].id,
                    real_name: resData[j].name,
                    ebook: resData[j].ebook,
                    re_email: resData[j].email,
                    joaraName: resData[j].joaraName,
                    genre: resData[j].genre,
                    writerId: resData[j].writerId,
                    phone: resData[j].phone,
                    title: resData[j].title,
                    book_code: resData[j].bookCode,
                    reward: resData[j].rewarod,
                    reward_change: false,
                    manager: resData[j].manager,
                    manager_bool: false,
                    send_status: resData[j].status,
                    code: resData[j].code,
                    agree_status:
                      resData[j].access === "unidentified"
                        ? "미확인"
                        : resData[j].access === "access"
                        ? "승인"
                        : "거절",
                    agree_status_change: false,
                    agree_filter_open: false,
                    select: false,
                  })
                }
                let first_setting = [
                  {
                    value: result[i].title,
                    maxLength: 255,
                    dropdown: false,
                    all_send: result[i].allSend,
                    event_id: result[i].eventId,
                    send_email_id: result[i].id,
                  },
                  {
                    value: result[i].senderEmail,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    title: "발송 이메일 주소:",
                    maxLength: 100,
                    xs: 3,
                    changed: false,
                  },
                  {
                    value: result[i].senderName,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "textFiled",
                    maxLength: 100,
                    xs: 2,
                    changed: false,
                    title: "발송인:",
                  },
                  {
                    value: result[i].endDate ? result[i].endDate : null,
                    inputType: "text",
                    error_message: "",
                    error: false,
                    type: "monthPicker",
                    maxLength: 100,
                    xs: 4,
                    changed: false,
                    title: "이벤트 승인 마감일:",
                  },
                  // 이벤트 대상자
                  {
                    data: response,
                  },
                  // 이벤트 내용
                  {
                    value: result[i].content,
                    change: true,
                  },
                  //발송 내역
                  {
                    data: result[i].history ? result[i].history : [],
                    first: result[i].history
                      ? result[i].history[result[i].history.length - 1]?.created_at
                      : "",
                  },
                ]
                event_email_list.push({
                  data: JSON.parse(JSON.stringify(first_setting)),
                  temporary_storage: true,
                  name_filter: result[i].nameType ? result[i].nameType : "작가 본명",
                  name_filter_bool: false,
                  table_rows: 10,
                  table_rows_bool: false,
                  table_total_page: Math.ceil(response.length / 10),
                  table_current_page: 1,
                  excel_name: result[i].file?.name,
                })
              })
            if (result.length - 1 === i) {
              let li = []
              for (let k = 0; k < event_email_list.length; k++) {
                if (event_email_list[k].data[6].first) {
                  li.push(event_email_list[k])
                } else if (
                  event_email_list[k].data[4].data.filter((x) => x.agree_status === "승인").length >
                  0
                ) {
                  li.push(event_email_list[k])
                }
              }
              li.sort(function (a, b) {
                return new Date(a.data[6].first) - new Date(b.data[6].first)
              })
              let count = 0
              for (let i = 0; i < li.length; i++) {
                if (li[i].data[6].first) {
                  event_email_list[
                    event_email_list.findIndex(
                      (x) => x.data[0].send_email_id === li[i].data[0].send_email_id
                    )
                  ].num = count + 1
                  count++
                } else {
                  event_email_list[
                    event_email_list.findIndex(
                      (x) => x.data[0].send_email_id === li[i].data[0].send_email_id
                    )
                  ].num = "no"
                }
              }
              event_email_list = event_email_list.filter((x) => x.num)
              event_email_list.sort(function (a, b) {
                return b.num - a.num
              })
              set_info_email_list(event_email_list)
              dispatch(loading_Apply(false))
            }
          }
        } else {
          set_info_email_list([])
          dispatch(loading_Apply(false))
        }
      })
  }
  useEffect(async () => {
    if (openDialog) {
      set_import_type("import")
      set_import_writer_type("agree")
      if (stepNext) {
        startSetting(editId)
        set_step(1)
      } else {
        set_step(0)
      }
      set_info_email([])
    }
  }, [openDialog])

  const nextStep = async () => {
    if (import_type === "") {
      set_open_title("불러오기 오류")
      set_open_content("불러올 정보에 대한 라디오 버튼을 선택해주세요")
      set_open_info_dialog(true)
      return
    }
    if (import_type === "import") {
      startSetting(editId)
      set_step(1)
    } else {
      closeDialog_create()
    }
  }
  const handleClose = () => {
    closeDialog()
  }
  const selectCompelete = () => {
    if (import_writer_type === "") {
      set_open_title("불러오기 오류")
      set_open_content("불러올 작가에 대한 라디오 버튼을 선택해주세요")
      set_open_info_dialog(true)
      return
    }
    console.log(info_email)
    if (import_writer_type === "all") {
      // 전체 메일 발송 작가
      let arr_info_email = [...info_email]
      let arr_result = []
      for (let i = 0; i < arr_info_email.length; i++) {
        for (let j = 0; j < arr_info_email[i].data[4].data.length; j++) {
          arr_info_email[i].data[4].data[j].receiverId = ""
          arr_info_email[i].data[4].data[j].send_result_status = "미발송"
          arr_info_email[i].data[4].data[j].img = []
          arr_info_email[i].data[4].data[j].joara_name = arr_info_email[i].data[4].data[j].joaraName
        }
        arr_result.push(arr_info_email[i])
      }
      closeDialog_import(arr_result)
    } else {
      // 승인 작가 정보
      let arr_info_email = [...info_email]
      let arr_result = []
      for (let i = 0; i < arr_info_email.length; i++) {
        for (let j = 0; j < arr_info_email[i].data[4].data.length; j++) {
          arr_info_email[i].data[4].data[j].receiverId = ""
          arr_info_email[i].data[4].data[j].send_result_status = "미발송"
          arr_info_email[i].data[4].data[j].img = []
          arr_info_email[i].data[4].data[j].joara_name = arr_info_email[i].data[4].data[j].joaraName
        }
        arr_info_email[i].data[4].data = arr_info_email[i].data[4].data.filter(
          (x) => x.agree_status === "승인"
        )
        arr_result.push(arr_info_email[i])
      }
      console.log(arr_result)
      closeDialog_import(arr_result)
    }
  }
  const click_check_list = useCallback((list) => {
    let lists = [...info_email]
    if (lists.findIndex((x) => x.data[0].send_email_id === list.data[0].send_email_id) > -1) {
      lists.splice(
        lists.findIndex((x) => x.data[0].send_email_id === list.data[0].send_email_id),
        1
      )
    } else {
      lists.push(list)
    }

    set_info_email(lists)
  })

  return step === 0 ? (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ ml: 4, mt: 4, width: 500 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="start">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              메일 정보 불러오기
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <RadioGroup
              sx={{ gap: 3, mt: 4, ml: "10px" }}
              defaultValue="import"
              name="controlled-radio-buttons-group"
              size="small"
            >
              <FormControlLabel
                value="import"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 18 }}>
                    연관된 메일 정보 가져오기
                  </SuiTypography>
                }
                onChange={(e) => set_import_type(e.target.value)}
              />
              <FormControlLabel
                value="create"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 18 }}>
                    신규 메일 생성하기
                  </SuiTypography>
                }
                onChange={(e) => set_import_type(e.target.value)}
              />
            </RadioGroup>
          </SuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
        <SuiBox display="flex" height="100%" sx={{ mt: 4, pl: 2, pr: 3, mb: 3 }}>
          <SuiButton
            color="subTitle"
            variant="text"
            sx={{
              width: 100,
              border: 1,
              borderColor: "#707070",
              height: 40,
              mr: 2,
              fontSize: 14,
            }}
            onClick={() => handleClose()}
          >
            취소
          </SuiButton>
          <SuiButton
            color="primary"
            sx={{
              ml: "auto",
              mr: 1,
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              width: 100,
              fontSize: 14,
              mb: 1,
            }}
            onClick={() => {
              nextStep()
            }}
          >
            다음으로
          </SuiButton>
        </SuiBox>
      </Grid>
      <SuiDialog
        openDialog={open_info_dialog}
        closeDialog={() => set_open_info_dialog(false)}
        MainTitle={open_title}
        Content={open_content}
        CanBtnText={"확인"}
      />
    </Dialog>
  ) : (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ p: "32px", width: 500 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              연관된 메일 정보 선택
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              연관된 이메일 메일 정보를 선택합니다.
            </SuiTypography>
          </SuiBox>

          <SuiBox sx={{ mb: 2 }}>
            <SuiTypography style={{ fontSize: "14px", fontWeight: "medium", color: "#84a2b1" }}>
              불러올 정보 선택:
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <RadioGroup
              sx={{ gap: 3, mt: 2, mb: 2, ml: "12px" }}
              defaultValue="agree"
              row
              name="controlled-radio-buttons-group"
              size="small"
            >
              <FormControlLabel
                value="agree"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 14 }}>
                    이벤트 승인 작가 정보
                  </SuiTypography>
                }
                onChange={(e) => set_import_writer_type(e.target.value)}
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 14 }}>
                    전체 메일 발송 작가 정보
                  </SuiTypography>
                }
                onChange={(e) => set_import_writer_type(e.target.value)}
              />
            </RadioGroup>
          </SuiBox>
          <SuiBox sx={{ mb: 2 }}>
            <SuiTypography style={{ fontSize: "14px", fontWeight: "medium", color: "#84a2b1" }}>
              이메일 선택:
            </SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              width: "100%",
              height: 200,
              backgroundColor: "#f7f9fb",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            {info_email_list &&
              info_email_list
                // .filter((x) => {
                //   // 안내발송에서 연관된 이메일 선택되서 사용됬으면 보여주지 않기
                //   let str_num = ""
                //   if (String(x.num).length === 1) str_num = `[00${x.num}] `
                //   else if (String(x.num).length === 2) str_num = `[0${x.num}] `
                //   else str_num = `[${x.num}] `
                //   if (
                //     info_result_email.findIndex(
                //       (y) => y.emailTitle === `${str_num}${x.data[0].value}`
                //     ) > -1
                //   ) {
                //     return false
                //   } else {
                //     return true
                //   }
                // })
                .map((list, index) => {
                  return (
                    <SuiBox
                      key={index + ",1o"}
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: "1px dashed #e3e6e8",
                        cursor: "pointer",
                        backgroundColor:
                          info_email.length > 0 &&
                          info_email.findIndex(
                            (x) => x.data[0].send_email_id == list.data[0].send_email_id
                          ) > -1
                            ? "#e1eff6"
                            : "",
                      }}
                      onClick={() => click_check_list(list)}
                    >
                      <SuiTypography
                        style={{
                          fontSize: "16px",
                          fontWeight: "medium",
                          fontWeight: "bold",
                          wordBreak: "break-all",
                          height: "30px",
                        }}
                        color="subTitle"
                      >
                        <span
                          style={{
                            color: "#0C65FF",
                            marginRight: "5px",
                            lineHeight: "48px",
                            fontWeight: "bold",
                          }}
                        >
                          {list.num === "no"
                            ? ""
                            : String(list.num).length === 1
                            ? `[00${list.num}]`
                            : String(list.num).length === 2
                            ? `[0${list.num}]`
                            : `[${list.num}]`}
                        </span>
                        {list.data[0].value.length <= 30 ? (
                          <span style={{ color: "#000000" }}>{list.data[0].value}</span>
                        ) : (
                          <Tooltip
                            arrow={false}
                            color="white"
                            classes={{ popper: "tooltip-light" }}
                            title={
                              <SuiBox>
                                <SuiTypography
                                  style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                                  verticalAlign="middle"
                                >
                                  {list.data[0].value}
                                </SuiTypography>
                              </SuiBox>
                            }
                          >
                            <span style={{ color: "#000000" }}>
                              {`${list.data[0].value.slice(0, 30)}...`}
                            </span>
                          </Tooltip>
                        )}
                      </SuiTypography>
                      <span
                        style={{
                          color: "#707070",
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                      >
                        (최초 발송일:{" "}
                        {list.data[6].first
                          ? dayjs(list.data[6].first).format("YY.MM.DD HH:mm:ss")
                          : "-"}
                        )
                      </span>
                    </SuiBox>
                  )
                })}
          </SuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
        <SuiBox display="flex" height="100%" alignItems="end" sx={{ mt: 4, pr: 3, mb: 3 }}>
          {stepNext ? (
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                // border: 1,
                // borderColor: "#707070",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
          ) : (
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                height: 40,
                mr: 2,
                fontSize: 14,
                ml: "20px",
                justifyContent: "start",
              }}
              onClick={() => set_step(0)}
            >
              뒤로
            </SuiButton>
          )}
        </SuiBox>
        <SuiBox display="flex" height="100%" sx={{ mt: 4, pr: 3, mb: 3 }}>
          <SuiButton
            disabled={info_email.length === 0}
            color="primary"
            sx={{
              ml: "auto",
              mr: 1,
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              width: 100,
              fontSize: 14,
              mb: 1,
              border: 1,
            }}
            onClick={() => {
              selectCompelete()
            }}
          >
            선택완료
          </SuiButton>
        </SuiBox>
      </Grid>
      <SuiDialog
        openDialog={open_info_dialog}
        closeDialog={() => set_open_info_dialog(false)}
        MainTitle={open_title}
        Content={open_content}
        CanBtnText={"확인"}
      />
    </Dialog>
  )
}
importEmail.defaultProps = {
  openDialog: false,
}

importEmail.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  closeDialog_import: PropTypes.func,
  closeDialog_create: PropTypes.func,
}
export default importEmail
