import { gql } from "@apollo/client"

export const login = gql`
  mutation login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        username
      }
    }
  }
`
export const createSendEmail = gql`
  mutation createSendEmail($usermail: String, $type: String, $content: JSON) {
    createSendEmail(input: { data: { usermail: $usermail, type: $type, content: $content } }) {
      sendEmail {
        authcode
      }
    }
  }
`

// 단일 파일 업로드 - 파일 컨트롤
export const fileUpload = gql`
  mutation upload($ref: String, $field: String, $source: String, $file: Upload!) {
    upload(ref: $ref, field: $field, source: $source, file: $file) {
      id
      name
      width
      height
      formats
      size
      url
    }
  }
`
// 복수 파일 업로드 - 파일 컨트롤
export const multipleUpload = gql`
  mutation multipleUpload($ref: String, $field: String, $source: String, $files: [Upload]!) {
    multipleUpload(ref: $ref, field: $field, source: $source, files: $files) {
      id
      name
      width
      height
      formats
      size
      url
    }
  }
`

export const register = gql`
  mutation createUser(
    $username: String!
    $email: String!
    $password: String!
    $name: String
    $department: String!
    $position: String
    $phone: String
    $status: String
    $profile: ID
    $role: ID
    $nomalAlertSetting: JSON
    $adminAlertSetting: JSON
    $accessTabs: String
  ) {
    createUser(
      input: {
        data: {
          username: $username
          email: $email
          password: $password
          name: $name
          department: $department
          position: $position
          phone: $phone
          status: $status
          profile: $profile
          role: $role
          nomalAlertSetting: $nomalAlertSetting
          adminAlertSetting: $adminAlertSetting
          accessTabs: $accessTabs
        }
      }
    ) {
      user {
        id
        username
        email
        blocked
        role {
          id
          name
        }
        department
        position
        phone
        name
        status
        profile {
          name
          url
        }
      }
    }
  }
`

export const updateUserPassword = gql`
  mutation updateUserPassword($id: ID!, $password: String) {
    updateUser(input: { where: { id: $id }, data: { password: $password } }) {
      user {
        email
      }
    }
  }
`
export const updateUserMu = gql`
  mutation updateUser(
    $id: ID!
    $department: String
    $email: String
    $name: String
    $password: String
    $phone: String
    $position: String
    $role: ID
    $status: String
    $username: String
    $profile: ID
    $adminAlertSetting: JSON
    $nomalAlertSetting: JSON
    $blocked: Boolean
    $alerts: [ID]
    $accessTabs: String
  ) {
    updateUser(
      input: {
        where: { id: $id }
        data: {
          department: $department
          email: $email
          name: $name
          password: $password
          phone: $phone
          position: $position
          role: $role
          status: $status
          username: $username
          profile: $profile
          adminAlertSetting: $adminAlertSetting
          nomalAlertSetting: $nomalAlertSetting
          blocked: $blocked
          alerts: $alerts
          accessTabs: $accessTabs
        }
      }
    ) {
      user {
        id
        username
        email
        blocked
        role {
          id
          name
        }
        department
        position
        phone
        name
        status
        profile {
          name
          url
        }
      }
    }
  }
`
export const createLoginLog = gql`
  mutation createLoginLog($username: String, $useremail: String, $userip: String) {
    createLoginLog(
      input: { data: { username: $username, useremail: $useremail, userip: $userip } }
    ) {
      loginLog {
        id
      }
    }
  }
`
export const createWriter = gql`
  mutation createWriter(
    $joaraname: String
    $userid: String
    $ebookname: String
    $name: String
    $birth: String
    $phone: String
    $nobless: String
    $ebook: String
    $registerdate: String
    $memo: String
    $address: String
    $email: String
    $account: String
    $referrer: JSON
    $history: JSON
    $pieces: [ID]
    $users: [ID]
    $contracts: [ID]
    $attachments: [ID]
    $manager: JSON
    $status: String
    $dal: String
    $accountName: String
  ) {
    createWriter(
      input: {
        data: {
          accountName: $accountName
          dal: $dal
          joaraname: $joaraname
          userid: $userid
          ebookname: $ebookname
          name: $name
          birth: $birth
          phone: $phone
          nobless: $nobless
          ebook: $ebook
          registerdate: $registerdate
          memo: $memo
          address: $address
          email: $email
          account: $account
          pieces: $pieces
          users: $users
          contracts: $contracts
          attachments: $attachments
          referrer: $referrer
          history: $history
          manager: $manager
          status: $status
        }
      }
    ) {
      writer {
        id
        name
        ebookname
      }
    }
  }
`
export const updateWriter = gql`
  mutation updateWriter(
    $joaraname: String
    $userid: String
    $ebookname: String
    $name: String
    $birth: String
    $phone: String
    $nobless: String
    $ebook: String
    $registerdate: String
    $memo: String
    $address: String
    $email: String
    $account: String
    $referrer: JSON
    $pieces: [ID]
    $users: [ID]
    $contracts: [ID]
    $attachments: [ID]
    $id: ID!
    $history: JSON
    $manager: JSON
    $status: String
    $meetings: [ID]
    $dal: String
    $accountName: String
  ) {
    updateWriter(
      input: {
        where: { id: $id }
        data: {
          accountName: $accountName
          dal: $dal
          joaraname: $joaraname
          userid: $userid
          ebookname: $ebookname
          name: $name
          birth: $birth
          phone: $phone
          nobless: $nobless
          ebook: $ebook
          registerdate: $registerdate
          memo: $memo
          address: $address
          email: $email
          account: $account
          pieces: $pieces
          users: $users
          contracts: $contracts
          attachments: $attachments
          referrer: $referrer
          history: $history
          manager: $manager
          status: $status
          meetings: $meetings
        }
      }
    ) {
      writer {
        id
      }
    }
  }
`
export const createAttachment = gql`
  mutation createAttachment(
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $writer: ID
    $writerId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
  ) {
    createAttachment(
      input: {
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          writer: $writer
          writerId: $writerId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      attachment {
        id
      }
    }
  }
`
export const updateAttachment = gql`
  mutation updateAttachment(
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $writer: ID
    $writerId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
    $id: ID!
  ) {
    updateAttachment(
      input: {
        where: { id: $id }
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          writer: $writer
          writerId: $writerId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      attachment {
        id
      }
    }
  }
`
export const createMeeting = gql`
  mutation createMeeting(
    $type: String
    $relationId: String
    $title: String
    $content: String
    $category: String
    $file: [ID]
    $contract: String
    $users_permissions_user: ID
    $writer: ID
    $maker: String
    $status: String
    $contract_document: ID
    $open: Boolean
  ) {
    createMeeting(
      input: {
        data: {
          open: $open
          type: $type
          relationId: $relationId
          title: $title
          content: $content
          category: $category
          file: $file
          contract: $contract
          users_permissions_user: $users_permissions_user
          writer: $writer
          maker: $maker
          status: $status
          contract_document: $contract_document
        }
      }
    ) {
      meeting {
        id
        contract_document {
          contractPaper
        }
      }
    }
  }
`
export const updateMeeting = gql`
  mutation updateMeeting(
    $id: ID!
    $type: String
    $relationId: String
    $title: String
    $content: String
    $category: String
    $file: [ID]
    $contract: String
    $users_permissions_user: ID
    $writer: ID
    $maker: String
    $status: String
    $contract_document: ID
    $open: Boolean
  ) {
    updateMeeting(
      input: {
        where: { id: $id }
        data: {
          open: $open
          type: $type
          relationId: $relationId
          title: $title
          content: $content
          category: $category
          file: $file
          contract: $contract
          users_permissions_user: $users_permissions_user
          writer: $writer
          maker: $maker
          status: $status
          contract_document: $contract_document
        }
      }
    ) {
      meeting {
        id
      }
    }
  }
`
export const createContract = gql`
  mutation createContract(
    $title: String
    $status: String
    $user: ID
    $writer: ID
    $bookcode: String
    $genre: String
    $joaraStatus: String
    $cover: String
    $ecover: String
    $contract_documents: [ID]
    $name: String
    $position: String
    $department: String
    $rating: String
    $episodes: String
    $publicDate: String
  ) {
    createContract(
      input: {
        data: {
          rating: $rating
          episodes: $episodes
          publicDate: $publicDate
          title: $title
          status: $status
          user: $user
          writer: $writer
          bookcode: $bookcode
          genre: $genre
          joaraStatus: $joaraStatus
          cover: $cover
          ecover: $ecover
          contract_documents: $contract_documents
          name: $name
          position: $position
          department: $department
        }
      }
    ) {
      contract {
        id
      }
    }
  }
`
export const updateContract = gql`
  mutation updateContract(
    $id: ID!
    $title: String
    $status: String
    $user: ID
    $writer: ID
    $bookcode: String
    $genre: String
    $joaraStatus: String
    $cover: String
    $ecover: String
    $contract_documents: [ID]
    $name: String
    $position: String
    $department: String
    $rating: String
    $episodes: String
    $publicDate: String
  ) {
    updateContract(
      input: {
        where: { id: $id }
        data: {
          rating: $rating
          episodes: $episodes
          publicDate: $publicDate
          title: $title
          status: $status
          user: $user
          writer: $writer
          bookcode: $bookcode
          genre: $genre
          joaraStatus: $joaraStatus
          cover: $cover
          ecover: $ecover
          contract_documents: $contract_documents
          name: $name
          position: $position
          department: $department
        }
      }
    ) {
      contract {
        id
      }
    }
  }
`
export const createContractDocument = gql`
  mutation createContractDocument(
    $type: String
    $status: String
    $memo: JSON
    $subContract: JSON
    $contract: ID
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $referrer: JSON
  ) {
    createContractDocument(
      input: {
        data: {
          referrer: $referrer
          type: $type
          status: $status
          memo: $memo
          subContract: $subContract
          contract: $contract
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
        }
      }
    ) {
      contractDocument {
        id
      }
    }
  }
`
export const updateContractDocument = gql`
  mutation updateContractDocument(
    $id: ID!
    $type: String
    $status: String
    $memo: JSON
    $subContract: JSON
    $contract: ID
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $referrer: JSON
  ) {
    updateContractDocument(
      input: {
        where: { id: $id }
        data: {
          referrer: $referrer
          type: $type
          status: $status
          memo: $memo
          subContract: $subContract
          contract: $contract
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
        }
      }
    ) {
      contractDocument {
        id
      }
    }
  }
`
export const createRequest = gql`
  mutation createRequest(
    $decisionDate: DateTime
    $declining: String
    $status: String
    $fromToType: JSON
    $content: JSON
    $type: String
    $company: ID
    $writer: ID
    $event: ID
    $requestId: String
    $users_permissions_user: ID
    $contract_document: ID
    $company_contract: ID
    $outsourcing_history: ID
    $outsourcing: ID
    $outsourcing_contract: ID
    $arrayContent: JSON
  ) {
    createRequest(
      input: {
        data: {
          decisionDate: $decisionDate
          declining: $declining
          status: $status
          fromToType: $fromToType
          content: $content
          type: $type
          company: $company
          writer: $writer
          event: $event
          requestId: $requestId
          users_permissions_user: $users_permissions_user
          contract_document: $contract_document
          company_contract: $company_contract
          outsourcing_history: $outsourcing_history
          outsourcing: $outsourcing
          outsourcing_contract: $outsourcing_contract
          arrayContent: $arrayContent
        }
      }
    ) {
      request {
        id
      }
    }
  }
`

export const updateRequest = gql`
  mutation updateRequest(
    $decisionDate: DateTime
    $declining: String
    $status: String
    $fromToType: JSON
    $content: JSON
    $type: String
    $company: ID
    $writer: ID
    $requestId: String
    $read: Boolean
    $id: ID!
    $arrayContent: JSON
  ) {
    updateRequest(
      input: {
        where: { id: $id }
        data: {
          decisionDate: $decisionDate
          declining: $declining
          status: $status
          fromToType: $fromToType
          content: $content
          type: $type
          company: $company
          writer: $writer
          requestId: $requestId
          read: $read
          arrayContent: $arrayContent
        }
      }
    ) {
      request {
        id
      }
    }
  }
`
export const createAlert = gql`
  mutation createAlert(
    $show: Boolean
    $content: JSON
    $title: String
    $type: String
    $userid: String
    $users_permissions_user: ID
  ) {
    createAlert(
      input: {
        data: {
          show: $show
          content: $content
          title: $title
          type: $type
          userid: $userid
          users_permissions_user: $users_permissions_user
        }
      }
    ) {
      alert {
        id
      }
    }
  }
`
export const updateAlert = gql`
  mutation updateAlert($show: Boolean, $read: Boolean, $id: ID!) {
    updateAlert(input: { where: { id: $id }, data: { show: $show, read: $read } }) {
      alert {
        id
      }
    }
  }
`
export const createCompany = gql`
  mutation createCompany(
    $name: String
    $companyid: String
    $companynumber: String
    $address: String
    $phone: String
    $premium: String
    $webtoon: String
    $memo: String
    $account: String
    $url: String
    $users: [ID]
    $joaraname: String
    $company_attachments: [ID]
    $company_meetings: [ID]
    $manager: JSON
    $history: JSON
    $referrer: JSON
    $bossName: String
    $status: String
    $settlement: String
  ) {
    createCompany(
      input: {
        data: {
          settlement: $settlement
          name: $name
          companyid: $companyid
          companynumber: $companynumber
          address: $address
          phone: $phone
          premium: $premium
          webtoon: $webtoon
          memo: $memo
          account: $account
          url: $url
          users: $users
          company_attachments: $company_attachments
          company_meetings: $company_meetings
          manager: $manager
          history: $history
          referrer: $referrer
          bossName: $bossName
          joaraname: $joaraname
          status: $status
        }
      }
    ) {
      company {
        id
        name
      }
    }
  }
`

export const updateCompany = gql`
  mutation updateCompany(
    $id: ID!
    $name: String
    $companyid: String
    $companynumber: String
    $address: String
    $phone: String
    $premium: String
    $webtoon: String
    $memo: String
    $account: String
    $joaraname: String
    $url: String
    $users: [ID]
    $company_attachments: [ID]
    $company_meetings: [ID]
    $company_contracts: [ID]
    $manager: JSON
    $history: JSON
    $referrer: JSON
    $bossName: String
    $status: String
    $settlement: String
  ) {
    updateCompany(
      input: {
        where: { id: $id }
        data: {
          settlement: $settlement
          name: $name
          companyid: $companyid
          companynumber: $companynumber
          address: $address
          phone: $phone
          premium: $premium
          webtoon: $webtoon
          memo: $memo
          account: $account
          url: $url
          users: $users
          company_attachments: $company_attachments
          company_meetings: $company_meetings
          manager: $manager
          history: $history
          referrer: $referrer
          bossName: $bossName
          joaraname: $joaraname
          status: $status
          company_contracts: $company_contracts
        }
      }
    ) {
      company {
        id
      }
    }
  }
`
export const createCompanyAttachment = gql`
  mutation createCompanyAttachment(
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $company: ID
    $companyId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
  ) {
    createCompanyAttachment(
      input: {
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          company: $company
          companyId: $companyId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      companyAttachment {
        id
      }
    }
  }
`
export const updateCompanyAttachment = gql`
  mutation updateCompanyAttachment(
    $id: ID!
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $company: ID
    $companyId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
  ) {
    updateCompanyAttachment(
      input: {
        where: { id: $id }
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          company: $company
          companyId: $companyId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      companyAttachment {
        id
      }
    }
  }
`
export const createCompanyMeeting = gql`
  mutation createCompanyMeeting(
    $type: String
    $relationId: String
    $title: String
    $content: String
    $category: String
    $file: [ID]
    $contract: String
    $users_permissions_user: ID
    $company: ID
    $maker: String
    $status: String
    $company_contract: ID
    $open: Boolean
  ) {
    createCompanyMeeting(
      input: {
        data: {
          open: $open
          type: $type
          relationId: $relationId
          title: $title
          content: $content
          category: $category
          file: $file
          contract: $contract
          users_permissions_user: $users_permissions_user
          company: $company
          maker: $maker
          status: $status
          company_contract: $company_contract
        }
      }
    ) {
      companyMeeting {
        id
        company_contract {
          contractPaper
        }
      }
    }
  }
`
export const updateCompanyMeeting = gql`
  mutation updateCompanyMeeting(
    $id: ID!
    $type: String
    $relationId: String
    $title: String
    $content: String
    $category: String
    $file: [ID]
    $contract: String
    $users_permissions_user: ID
    $company: ID
    $maker: String
    $status: String
    $company_contract: ID
    $open: Boolean
  ) {
    updateCompanyMeeting(
      input: {
        where: { id: $id }
        data: {
          open: $open
          type: $type
          relationId: $relationId
          title: $title
          content: $content
          category: $category
          file: $file
          contract: $contract
          users_permissions_user: $users_permissions_user
          company: $company
          maker: $maker
          status: $status
          company_contract: $company_contract
        }
      }
    ) {
      companyMeeting {
        id
      }
    }
  }
`
export const createCompanyContract = gql`
  mutation createCompanyContract(
    $memo: JSON
    $subContract: JSON
    $type: String
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $status: String
    $company_meetings: [ID]
    $company: ID
    $ratio: String
    $referrer: JSON
    $pieces: JSON
  ) {
    createCompanyContract(
      input: {
        data: {
          memo: $memo
          subContract: $subContract
          type: $type
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
          status: $status
          company_meetings: $company_meetings
          company: $company
          ratio: $ratio
          referrer: $referrer
          pieces: $pieces
        }
      }
    ) {
      companyContract {
        id
      }
    }
  }
`

export const updateCompanyContract = gql`
  mutation updateCompanyContract(
    $memo: JSON
    $subContract: JSON
    $type: String
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $status: String
    $company_meetings: [ID]
    $company: ID
    $id: ID!
    $ratio: String
    $referrer: JSON
    $pieces: JSON
  ) {
    updateCompanyContract(
      input: {
        where: { id: $id }
        data: {
          memo: $memo
          subContract: $subContract
          type: $type
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
          status: $status
          company_meetings: $company_meetings
          company: $company
          ratio: $ratio
          referrer: $referrer
          pieces: $pieces
        }
      }
    ) {
      companyContract {
        id
      }
    }
  }
`
export const createChangeRequest = gql`
  mutation createChangeRequest(
    $content: JSON
    $users_permissions_user: ID
    $contractId: String
    $status: String
    $type: String
    $companiesId: String
    $writersId: String
  ) {
    createChangeRequest(
      input: {
        data: {
          content: $content
          users_permissions_user: $users_permissions_user
          contractId: $contractId
          writersId: $writersId
          companiesId: $companiesId
          status: $status
          type: $type
        }
      }
    ) {
      changeRequest {
        id
      }
    }
  }
`
export const updateChangeRequest = gql`
  mutation updateChangeRequest(
    $content: JSON
    $users_permissions_user: ID
    $id: ID!
    $arrayContent: JSON
    $status: String
  ) {
    updateChangeRequest(
      input: {
        where: { id: $id }
        data: {
          content: $content
          users_permissions_user: $users_permissions_user
          status: $status
          arrayContent: $arrayContent
        }
      }
    ) {
      changeRequest {
        id
      }
    }
  }
`
export const createRecent = gql`
  mutation createRecent(
    $users_permissions_user: ID
    $title: String
    $type: String
    $srcId: String
    $name: String
  ) {
    createRecent(
      input: {
        data: {
          users_permissions_user: $users_permissions_user
          title: $title
          type: $type
          srcId: $srcId
          name: $name
        }
      }
    ) {
      recent {
        id
      }
    }
  }
`

export const createWriterSignificant = gql`
  mutation createWriterSignificant(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $writer: ID
    $writerId: String
    $type: String
  ) {
    createWriterSignificant(
      input: {
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          writer: $writer
          writerId: $writerId
          type: $type
        }
      }
    ) {
      writerSignificant {
        id
      }
    }
  }
`

export const updateWriterSignificant = gql`
  mutation updateWriterSignificant($id: ID!, $content: String, $writer: ID) {
    updateWriterSignificant(
      input: { where: { id: $id }, data: { content: $content, writer: $writer } }
    ) {
      writerSignificant {
        id
      }
    }
  }
`
export const createCompanySignificant = gql`
  mutation createCompanySignificant(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $company: ID
    $companyId: String
  ) {
    createCompanySignificant(
      input: {
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          company: $company
          companyId: $companyId
        }
      }
    ) {
      companySignificant {
        id
      }
    }
  }
`

export const updateCompanySignificant = gql`
  mutation updateCompanySignificant($id: ID!, $content: String, $company: ID) {
    updateCompanySignificant(
      input: { where: { id: $id }, data: { content: $content, company: $company } }
    ) {
      companySignificant {
        id
      }
    }
  }
`

export const createDonwloadLog = gql`
  mutation createDonwloadLog(
    $userId: String
    $userName: String
    $userPosition: String
    $userDepartment: String
    $userEmail: String
    $donwloadType: String
    $downloadDate: DateTime
    $downloadList: JSON
  ) {
    createDonwloadLog(
      input: {
        data: {
          userId: $userId
          userName: $userName
          userPosition: $userPosition
          userDepartment: $userDepartment
          userEmail: $userEmail
          donwloadType: $donwloadType
          downloadDate: $downloadDate
          downloadList: $downloadList
        }
      }
    ) {
      donwloadLog {
        userId
        userName
        userPosition
        userDepartment
        userEmail
        donwloadType
        downloadList
        downloadDate
      }
    }
  }
`
export const createOutsourcing = gql`
  mutation (
    $name: String
    $nickname: String
    $type: String
    $genre: String
    $mainTitle: String
    $bizNumber: String
    $email: String
    $phone: String
    $address: String
    $minPrice: Long
    $account: String
    $accountName: String
    $histroy: JSON
    $outsourcing_contracts: [ID]
    $outsourcing_histories: [ID]
    $outsourcing_attachments: [ID]
    $contractStatus: String
    $maxPrice: Long
    $status: String
    $referrer: JSON
    $manager: JSON
    $user: ID
  ) {
    createOutsourcing(
      input: {
        data: {
          name: $name
          nickname: $nickname
          type: $type
          genre: $genre
          mainTitle: $mainTitle
          bizNumber: $bizNumber
          email: $email
          phone: $phone
          address: $address
          minPrice: $minPrice
          account: $account
          accountName: $accountName
          histroy: $histroy
          outsourcing_contracts: $outsourcing_contracts
          outsourcing_histories: $outsourcing_histories
          outsourcing_attachments: $outsourcing_attachments
          contractStatus: $contractStatus
          maxPrice: $maxPrice
          status: $status
          referrer: $referrer
          manager: $manager
          user: $user
        }
      }
    ) {
      outsourcing {
        id
        nickname
      }
    }
  }
`
export const updateOutsourcing = gql`
  mutation (
    $name: String
    $nickname: String
    $type: String
    $genre: String
    $mainTitle: String
    $bizNumber: String
    $email: String
    $phone: String
    $address: String
    $minPrice: Long
    $account: String
    $accountName: String
    $histroy: JSON
    $outsourcing_contracts: [ID]
    $outsourcing_histories: [ID]
    $outsourcing_attachments: [ID]
    $contractStatus: String
    $maxPrice: Long
    $status: String
    $referrer: JSON
    $manager: JSON
    $id: ID!
    $user: ID
  ) {
    updateOutsourcing(
      input: {
        where: { id: $id }
        data: {
          name: $name
          nickname: $nickname
          type: $type
          genre: $genre
          mainTitle: $mainTitle
          bizNumber: $bizNumber
          email: $email
          phone: $phone
          address: $address
          minPrice: $minPrice
          account: $account
          accountName: $accountName
          histroy: $histroy
          outsourcing_contracts: $outsourcing_contracts
          outsourcing_histories: $outsourcing_histories
          outsourcing_attachments: $outsourcing_attachments
          contractStatus: $contractStatus
          maxPrice: $maxPrice
          status: $status
          referrer: $referrer
          manager: $manager
          user: $user
        }
      }
    ) {
      outsourcing {
        id
      }
    }
  }
`
export const createOutsourcingAttachment = gql`
  mutation (
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $outsourcing: ID
    $outsourcingId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
  ) {
    createOutsourcingAttachment(
      input: {
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          outsourcing: $outsourcing
          outsourcingId: $outsourcingId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      outsourcingAttachment {
        id
      }
    }
  }
`
export const updateOutsourcingAttachment = gql`
  mutation (
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $outsourcing: ID
    $outsourcingId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
    $id: ID!
  ) {
    updateOutsourcingAttachment(
      input: {
        where: { id: $id }
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          outsourcing: $outsourcing
          outsourcingId: $outsourcingId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      outsourcingAttachment {
        id
      }
    }
  }
`
export const createoutsourcingContract = gql`
  mutation (
    $type: String
    $memo: JSON
    $subContract: JSON
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $status: String
    $outsourcing: ID
    $requests: [ID]
    $referrer: JSON
  ) {
    createOutsourcingContract(
      input: {
        data: {
          referrer: $referrer
          type: $type
          memo: $memo
          subContract: $subContract
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
          status: $status
          outsourcing: $outsourcing
          requests: $requests
        }
      }
    ) {
      outsourcingContract {
        id
      }
    }
  }
`
export const updateoutsourcingContract = gql`
  mutation (
    $type: String
    $memo: JSON
    $subContract: JSON
    $name: String
    $position: String
    $department: String
    $users_permissions_user: ID
    $managerLog: JSON
    $contractPaper: JSON
    $status: String
    $outsourcing: ID
    $requests: [ID]
    $id: ID!
    $referrer: JSON
  ) {
    updateOutsourcingContract(
      input: {
        where: { id: $id }
        data: {
          referrer: $referrer
          type: $type
          memo: $memo
          subContract: $subContract
          name: $name
          position: $position
          department: $department
          users_permissions_user: $users_permissions_user
          managerLog: $managerLog
          contractPaper: $contractPaper
          status: $status
          outsourcing: $outsourcing
          requests: $requests
        }
      }
    ) {
      outsourcingContract {
        id
      }
    }
  }
`
export const createOutsourcingHistory = gql`
  mutation (
    $type: String
    $pieces: JSON
    $workType: String
    $price: Long
    $start: Date
    $end: Date
    $comment: String
    $file: ID
    $status: String
    $outsourcing: ID
  ) {
    createOutsourcingHistory(
      input: {
        data: {
          type: $type
          pieces: $pieces
          workType: $workType
          price: $price
          start: $start
          end: $end
          comment: $comment
          file: $file
          status: $status
          outsourcing: $outsourcing
        }
      }
    ) {
      outsourcingHistory {
        id
      }
    }
  }
`
export const updateOutsourcingHistory = gql`
  mutation (
    $type: String
    $pieces: JSON
    $workType: String
    $price: Long
    $start: Date
    $end: Date
    $comment: String
    $file: ID
    $status: String
    $outsourcing: ID
    $id: ID!
  ) {
    updateOutsourcingHistory(
      input: {
        where: { id: $id }
        data: {
          type: $type
          pieces: $pieces
          workType: $workType
          price: $price
          start: $start
          end: $end
          comment: $comment
          file: $file
          status: $status
          outsourcing: $outsourcing
        }
      }
    ) {
      outsourcingHistory {
        id
      }
    }
  }
`
export const updateSetting = gql`
  mutation updateSetting($id: ID!, $list: JSON, $log: JSON) {
    updateSetting(input: { where: { id: $id }, data: { list: $list, log: $log } }) {
      setting {
        id
      }
    }
  }
`
export const createOutsourcingSignificant = gql`
  mutation createOutsourcingSignificant(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $outsourcing: ID
    $outsourcingId: String
  ) {
    createOutsourcingSignificant(
      input: {
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          outsourcing: $outsourcing
          outsourcingId: $outsourcingId
        }
      }
    ) {
      outsourcingSignificant {
        id
      }
    }
  }
`
export const updateOutsourcingSignificant = gql`
  mutation updateOutsourcingSignificant(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $outsourcing: ID
    $outsourcingId: String
    $id: ID!
  ) {
    updateOutsourcingSignificant(
      input: {
        where: { id: $id }
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          outsourcing: $outsourcing
          outsourcingId: $outsourcingId
        }
      }
    ) {
      outsourcingSignificant {
        id
      }
    }
  }
`
export const createNotice = gql`
  mutation createNotice(
    $title: String
    $content: String
    $file: ID
    $name: String
    $department: String
    $position: String
    $type: ENUM_NOTICE_TYPE
  ) {
    createNotice(
      input: {
        data: {
          title: $title
          content: $content
          file: $file
          name: $name
          department: $department
          position: $position
          type: $type
        }
      }
    ) {
      notice {
        id
      }
    }
  }
`
export const updateNotice = gql`
  mutation updateNotice(
    $id: ID!
    $title: String
    $content: String
    $file: ID
    $name: String
    $department: String
    $position: String
    $show: Boolean
    $type: ENUM_NOTICE_TYPE
  ) {
    updateNotice(
      input: {
        where: { id: $id }
        data: {
          title: $title
          content: $content
          file: $file
          name: $name
          department: $department
          position: $position
          show: $show
          type: $type
        }
      }
    ) {
      notice {
        id
      }
    }
  }
`
export const resetPass = gql`
  mutation resetPass($id: ID!, $password: String!) {
    resetPass(input: { id: $id, password: $password }) {
      ok
    }
  }
`

export const createEvent = gql`
  mutation createEvent(
    $title: String!
    $flatform: String!
    $start: Date
    $end: Date
    $status: ENUM_EVENT_STATUS
    $users_permissions_user: ID
    $serial: String
    $type: String
    $newOne: String
    $reward: String
    $publisher: String
    $content: String
    $manager: JSON
    $history: JSON
  ) {
    createEvent(
      input: {
        data: {
          title: $title
          flatform: $flatform
          start: $start
          end: $end
          status: $status
          serial: $serial
          users_permissions_user: $users_permissions_user
          type: $type
          newOne: $newOne
          reward: $reward
          publisher: $publisher
          content: $content
          manager: $manager
          history: $history
        }
      }
    ) {
      event {
        id
        title
      }
    }
  }
`
export const createEventSignificant = gql`
  mutation createEventSignificant(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $eventId: String
    $userID: String
  ) {
    createEventSignificant(
      input: {
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          eventId: $eventId
          userID: $userID
        }
      }
    ) {
      eventSignificant {
        id
      }
    }
  }
`
export const createEventExposure = gql`
  mutation createEventExposure(
    $content: String
    $userName: String
    $userDepartment: String
    $userPosition: String
    $userProfile: String
    $eventId: String
    $userID: String
  ) {
    createEventExposure(
      input: {
        data: {
          content: $content
          userName: $userName
          userDepartment: $userDepartment
          userPosition: $userPosition
          userProfile: $userProfile
          eventId: $eventId
          userID: $userID
        }
      }
    ) {
      eventExposure {
        id
      }
    }
  }
`
export const createEventAttachment = gql`
  mutation createEventAttachment(
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $event: ID
    $eventId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
  ) {
    createEventAttachment(
      input: {
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          event: $event
          eventId: $eventId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      eventAttachment {
        id
      }
    }
  }
`
export const updateEventAttachment = gql`
  mutation updateEventAttachment(
    $file: [ID]
    $title: String
    $type: String
    $explain: String
    $event: ID
    $eventId: String
    $version: String
    $status: String
    $maker: String
    $users_permissions_user: ID
    $position: String
    $id: ID!
  ) {
    updateEventAttachment(
      input: {
        where: { id: $id }
        data: {
          file: $file
          title: $title
          type: $type
          explain: $explain
          event: $event
          eventId: $eventId
          version: $version
          status: $status
          maker: $maker
          users_permissions_user: $users_permissions_user
          position: $position
        }
      }
    ) {
      eventAttachment {
        id
      }
    }
  }
`

export const updateEventSignificant = gql`
  mutation updateEventSignificant($id: ID!, $content: String, $eventId: String) {
    updateEventSignificant(
      input: { where: { id: $id }, data: { content: $content, eventId: $eventId } }
    ) {
      eventSignificant {
        id
      }
    }
  }
`
export const updateEventExposure = gql`
  mutation updateEventExposure($id: ID!, $content: String, $eventId: String) {
    updateEventExposure(
      input: { where: { id: $id }, data: { content: $content, eventId: $eventId } }
    ) {
      eventExposure {
        id
      }
    }
  }
`

export const updateEvent = gql`
  mutation updateEvent(
    $id: ID!
    $title: String
    $flatform: String
    $start: Date
    $end: Date
    $status: ENUM_EVENT_STATUS
    $users_permissions_user: ID
    $serial: String
    $type: String
    $newOne: String
    $reward: String
    $publisher: String
    $content: String
    $manager: JSON
    $history: JSON
    $state: ENUM_EVENT_STATE
  ) {
    updateEvent(
      input: {
        where: { id: $id }
        data: {
          title: $title
          flatform: $flatform
          start: $start
          end: $end
          status: $status
          serial: $serial
          users_permissions_user: $users_permissions_user
          type: $type
          newOne: $newOne
          reward: $reward
          publisher: $publisher
          content: $content
          manager: $manager
          history: $history
          state: $state
        }
      }
    ) {
      event {
        id
        start
        end
      }
    }
  }
`
export const createSendEmailEvent = gql`
  mutation createSendEmailEvent(
    $eventId: String
    $subject: String
    $body: String
    $sender: String
    $sender_name: String
    $receiver: JSON
    $events_email: String
  ) {
    createSendEmailEvent(
      input: {
        data: {
          eventId: $eventId
          subject: $subject
          body: $body
          sender: $sender
          sender_name: $sender_name
          receiver: $receiver
          events_email: $events_email
        }
      }
    ) {
      sendEmailEvent {
        id
        send_result
        created_at
      }
    }
  }
`
export const createEventEmail = gql`
  mutation createSendEmailEvent(
    $eventId: String
    $title: String
    $senderEmail: String
    $senderName: String
    $endDate: DateTime
    $content: String
    $nameType: String
    $allSend: Boolean
    $history: JSON
  ) {
    createEventEmail(
      input: {
        data: {
          eventId: $eventId
          title: $title
          senderEmail: $senderEmail
          senderName: $senderName
          endDate: $endDate
          content: $content
          nameType: $nameType
          allSend: $allSend
          history: $history
        }
      }
    ) {
      eventEmail {
        id
      }
    }
  }
`
export const createEventResultEmail = gql`
  mutation createSendEmailEvent(
    $eventId: String
    $title: String
    $senderEmail: String
    $senderName: String
    $emailTitle: String
    $content: String
    $nameType: String
    $allSend: Boolean
    $history: JSON
    $emailImage: [ID]
    $emailImages: JSON
  ) {
    createEventResultEmail(
      input: {
        data: {
          eventId: $eventId
          title: $title
          senderEmail: $senderEmail
          senderName: $senderName
          emailTitle: $emailTitle
          content: $content
          nameType: $nameType
          allSend: $allSend
          history: $history
          emailImage: $emailImage
          emailImages: $emailImages
        }
      }
    ) {
      eventResultEmail {
        id
      }
    }
  }
`

export const updateEventRecevier = gql`
  mutation updateEventRecevier(
    $id: ID!
    $access: ENUM_EVENTRECEVIER_ACCESS
    $comment: String
    $rewarod: String
    $manager: String
    $status: String
    $result_status: String
    $eventEmailId: String
    $list: [ID]
    $lists: JSON
    $agree_date: DateTime
  ) {
    updateEventRecevier(
      input: {
        where: { id: $id }
        data: {
          access: $access
          comment: $comment
          rewarod: $rewarod
          manager: $manager
          status: $status
          result_status: $result_status
          eventEmailId: $eventEmailId
          list: $list
          lists: $lists
          agree_date: $agree_date
        }
      }
    ) {
      eventRecevier {
        id
      }
    }
  }
`

export const updateEventEmail = gql`
  mutation updateEventEmail(
    $id: ID!
    $eventId: String
    $title: String
    $senderEmail: String
    $senderName: String
    $endDate: DateTime
    $content: String
    $nameType: String
    $history: JSON
    $allSend: Boolean
  ) {
    updateEventEmail(
      input: {
        where: { id: $id }
        data: {
          eventId: $eventId
          title: $title
          senderEmail: $senderEmail
          senderName: $senderName
          endDate: $endDate
          content: $content
          nameType: $nameType
          history: $history
          allSend: $allSend
        }
      }
    ) {
      eventEmail {
        id
      }
    }
  }
`
export const updateEventResultEmail = gql`
  mutation updateEventResultEmail(
    $id: ID!
    $eventId: String
    $title: String
    $senderEmail: String
    $senderName: String
    $emailTitle: String
    $content: String
    $nameType: String
    $history: JSON
    $allSend: Boolean
    $emailImage: [ID]
    $emailImages: JSON
  ) {
    updateEventResultEmail(
      input: {
        where: { id: $id }
        data: {
          eventId: $eventId
          title: $title
          senderEmail: $senderEmail
          senderName: $senderName
          emailTitle: $emailTitle
          content: $content
          nameType: $nameType
          history: $history
          allSend: $allSend
          emailImage: $emailImage
          emailImages: $emailImages
        }
      }
    ) {
      eventResultEmail {
        id
      }
    }
  }
`
export const createEventRecevier = gql`
  mutation createEventRecevier(
    $eventId: String
    $eventEmailId: String
    $name: String
    $ebook: String
    $phone: String
    $title: String
    $bookCode: String
    $rewarod: String
    $manager: String
    $status: String
    $result_status: String
    $access: ENUM_EVENTRECEVIER_ACCESS
    $code: String
    $mailType: String
    $writerId: String
    $joaraName: String
    $genre: String
    $email: String
    $list: [ID]
    $lists: JSON
  ) {
    createEventRecevier(
      input: {
        data: {
          eventId: $eventId
          eventEmailId: $eventEmailId
          name: $name
          ebook: $ebook
          phone: $phone
          title: $title
          bookCode: $bookCode
          rewarod: $rewarod
          manager: $manager
          status: $status
          result_status: $result_status
          access: $access
          code: $code
          mailType: $mailType
          writerId: $writerId
          joaraName: $joaraName
          genre: $genre
          email: $email
          list: $list
          lists: $lists
        }
      }
    ) {
      eventRecevier {
        id
      }
    }
  }
`

export const createSendSm = gql`
  mutation createSendSm($eventId: String, $phone: String, $content: String, $email: String) {
    createSendSm(
      input: { data: { eventId: $eventId, phone: $phone, content: $content, email: $email } }
    ) {
      sendSm {
        id
        send_result
        created_at
      }
    }
  }
`
