import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import TextField from "@mui/material/TextField"

import IconButton from "@mui/material/IconButton"
import { MdClose } from "react-icons/md"

function deleteDialog({
  openDialog,
  closeDialog,
  Active,
  CreBtnText,
  CanBtnText,
  AlertType,
  MainTitle,
  Content,
  CreBtnColor,
  requestExplainData,
  requestExplainShowData,
  type,
}) {
  const [open, setOpen] = useState(openDialog)
  const [requestExplain, setrequestExplain] = useState("")
  const [step, setstep] = useState(0)
  useEffect(() => {
    setOpen(openDialog)
    setstep(0)
    setrequestExplain(requestExplainShowData)
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const setrequestExplainDataExport = (e) => {
    requestExplainData(e)
    setrequestExplain(e)
  }
  const next_active = () => {
    if (step === 0) {
      setstep(1)
    } else {
      Active()
    }
  }
  const BtnControll = () => {
    if (AlertType !== "oneBtn") {
      return (
        <DialogActions sx={{ p: 0 }}>
          {step === 1 ? (
            <SuiButton
              color="subTitle"
              variant="text"
              className="back-btn"
              sx={{ justifyContent: "left", fontSize: 16, mr: "auto" }}
              onClick={() => setstep(0)}
            >
              {"뒤로"}
            </SuiButton>
          ) : null}
          <SuiButton
            color={CreBtnColor}
            disabled={requestExplain === ""}
            onClick={() => next_active()}
            sx={{
              fontSize: 14,
              width: 100,
            }}
          >
            {step === 0 ? "다음으로" : CreBtnText}
          </SuiButton>
        </DialogActions>
      )
    } else {
      return (
        <DialogActions>
          <SuiButton color="info2" sx={{ width: 93, height: 40 }} onClick={() => handleClose()}>
            {CanBtnText}
          </SuiButton>
        </DialogActions>
      )
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" className="alert-dialog-layout">
      <SuiBox sx={{ padding: "40px 32px 32px" }}>
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          edge="start"
          color="inherit"
          onClick={() => handleClose()}
          aria-label="close"
        >
          <MdClose />
        </IconButton>
        <SuiBox sx={{ textAlign: "center" }}>
          <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
            {MainTitle}
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ textAlign: "center", mt: 2 }}>
          <SuiTypography fontSize="16px" sx={{ color: "#707070" }}>
            신청 내용을 확인하고 사유를 작성합니다.
          </SuiTypography>
        </SuiBox>

        <SuiBox sx={{ mt: 5, mb: 3 }}>
          <SuiTypography
            fontSize="18px"
            fontWeight="medium"
            sx={{
              color: "#535353",
              textAlign: "center",
              backgroundColor: "#F7F9FB",
              border: "1px solid #F1F1F5",
              height: "60px",
              lineHeight: "60px",
              borderRadius: "10px",
            }}
          >
            {`신청내용 : `}
            <span style={{ color: "#0C65FF" }} dangerouslySetInnerHTML={{ __html: Content }}></span>
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ my: 1 }}>
          <SuiTypography
            fontSize="15px"
            color="info"
            sx={{ textAlign: "left", pt: 2, fontWeight: 400 }}
          >
            {type === "delete" ? "거절 사유 :" : "삭제 신청 사유 :"}
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="center"
          sx={{
            mb: 18,
          }}
        >
          <TextField
            disabled={type !== "create" || step === 1}
            multiline
            rows={4}
            sx={{ width: "100%" }}
            className="Fileupload-textArea"
            variant="outlined"
            placeholder="300자 이내"
            value={requestExplain}
            onChange={(e) => {
              if (e.target.value.length <= 300) setrequestExplainDataExport(e.target.value)
            }}
          />
        </SuiBox>

        {BtnControll()}
      </SuiBox>
    </Dialog>
  )
}

deleteDialog.defaultProps = {
  openDialog: false,
  AlertType: "oneBtn",
  CanBtnText: "확인",
  CreBtnText: "저장",
  CreBtnColor: "primary",
  MainTitle: "제목을 입력해주세요.",
  Content: "내용을 입력해주세요.",
  type: "create",
  requestExplainShowData: "",
}

deleteDialog.propTypes = {
  openDialog: PropTypes.bool,
  MainTitle: PropTypes.string,
  Content: PropTypes.string,
  AlertType: PropTypes.string,
  CanBtnText: PropTypes.string,
  CreBtnText: PropTypes.string,
  CreBtnColor: PropTypes.string,
  closeDialog: PropTypes.func,
  Active: PropTypes.func,
  requestExplainData: PropTypes.func,
  type: PropTypes.string,
  requestExplainShowData: PropTypes.string,
}

export default deleteDialog
