import { useState, useEffect, useMemo, useRef } from "react"

// react-router components
import { Route, Routes, useLocation } from "react-router-dom"

// @mui material components
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Icon from "@mui/material/Icon"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav"
import Configurator from "examples/Configurator"
import { useNavigate } from "react-router-dom"
// Soft UI Dashboard PRO React themes
import theme from "assets/theme"
import themeRTL from "assets/theme/theme-rtl"

// Css Import
import "assets/App.scss"

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"

// Soft UI Dashboard PRO React routes
import routes from "routes"

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context"

// Apollo
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
// import { createHttpLink } from "apollo-link-http"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"

import HeaderBar from "layouts/header"

// 로딩바
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { useSelector } from "react-redux"

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("joara_token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const CryptoJS = require("crypto-js")
import axios from "axios"
const { buildAxiosFetch } = require("@lifeomic/axios-fetch")

const decodeByAES56 = (key, data) => {
  const cipher = CryptoJS.AES.decrypt(data, key)
  return cipher.toString(CryptoJS.enc.Utf8)
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem("joara_token")
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }))
  // const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY.padEnd(32, " ")
  // for (let index = 0; index < Object.keys(operation.variables).length; index++) {
  //   if (
  //     typeof operation.variables[Object.keys(operation.variables)[index]] !== "boolean" &&
  //     typeof operation.variables[Object.keys(operation.variables)[index]] !== "number"
  //   ) {
  //     operation.variables[Object.keys(operation.variables)[index]] = encodeByAES56(
  //       secretKey,
  //       operation.variables[Object.keys(operation.variables)[index]].toString()
  //     )
  //   }
  // }
  const tableDatas = [
    // "companies",
    "contracts",
    // "outsourcings",
    // "writers",
    // "events",
    "settings",
    "alerts",
    "attachments",
    "changeRequests",
    "companyAttachments",
    "companyContracts",
    "companyMeetings",
    "companySignificants",
    "contractDocuments",
    "donwloadLogs",
    "eventAttachments",
    "eventEmails",
    "eventReceviers",
    "eventResultEmails",
    "eventSignificants",
    "loginLogs",
    "meetings",
    "notices",
    "outsourcingAttachments",
    "outsourcingContracts",
    "outsourcingHistories",
    "outsourcingSignificants",
    "pieces",
    "recents",
    "requests",
    "sendEmails",
    "sendEmailEvents",
    "sendEmails",
    "writerSignificants",
    "users",
  ]
  const tableData = ["me", "myContractsList"]
  return forward(operation).map((response) => {
    for (let i = 0; i < tableData.length; i++) {
      const el = tableData[i]
      if (response.data[el]) {
        const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY + response.data[el].created_at
        const decodeDatas = decodeByAES56(secretKey, response.data[el].secret)
        if (el === "myContractsList")
          return {
            data: {
              [el]: {
                id: "1",
                created_at: response.data[el].created_at,
                secret: "",
                list: JSON.parse(decodeDatas),
              },
            },
          }
        else return { data: { [el]: JSON.parse(decodeDatas) } }
      }
    }

    for (let i = 0; i < tableDatas.length; i++) {
      const el = tableDatas[i]
      if (response.data[el]) {
        if (response.data[el].length > 0) {
          const datas = []
          const secretKey =
            process.env.REACT_APP_CRYPTO_SECRET_KEY + response.data[el][0].created_at
          for (let index = 0; index < response.data[el].length; index++) {
            const el2 = response.data[el][index]
            const decodeDatas = decodeByAES56(secretKey, el2.secret)
            datas.push(JSON.parse(decodeDatas))
          }
          if (el === "users") {
            // console.log(response.data[el])
          }
          if (response.data[el + "Connection"]) {
            return {
              data: { [el]: datas, [el + "Connection"]: response.data[el + "Connection"] },
            }
          } else {
            return { data: { [el]: datas } }
          }
        } else return response
      } else if (response.data[el + "Connection"]) {
        return {
          data: { [el + "Connection"]: response.data[el + "Connection"] },
        }
      }
      if (tableDatas.length - 1 === i) {
        return response
      }
    }
  })
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  if (networkError) console.error(`[Network error]: ${networkError}`)
})
const client = new ApolloClient({
  link: authMiddleware.concat(
    createUploadLink({
      uri: process.env.REACT_APP_BACKEND_URL + "/graphql",
      fetch: buildAxiosFetch(axios, (config, input, init) => ({
        ...config,
        onUploadProgress: init.onUploadProgress,
      })),
    }),
    errorLink
  ),
  cache: new InMemoryCache(),
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: "no-cache",
  //   },
  //   query: {
  //     fetchPolicy: "no-cache",
  //   },
  //   mutate: {
  //     fetchPolicy: "no-cache",
  //   },
  // },
})

export default function App() {
  const navigate = useNavigate()
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const [rtlCache, setRtlCache] = useState(null)
  const { pathname } = useLocation()
  const { loading } = useSelector((state) => state.store)
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    })

    setRtlCache(cacheRtl)
  }, [])

  // Open sidenav when mouse enter on mini sidenavasd
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    if (pathname !== "/reset-password") {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      const token = sessionStorage.getItem("joara_token")
      if (!token) {
        if (pathname.includes("/notice") > 0 || pathname.includes("/reset") > 0) {
        } else {
          navigate("/")
        }
      }
    }
  }, [pathname])
  const [loadingOpen, setloadingOpen] = useState(false)
  useEffect(() => {
    if (typeof Boolean(loading)) {
      setloadingOpen(loading)
    }
  }, [loading])
  const loadingRef = useRef(null)
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />
      }
      return null
    })

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SuiBox>
  )
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: 9999 }}
          open={loadingOpen}
          // onClick={() => setloadingOpen(false)}
          ref={loadingRef}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {layout === "dashboard" &&
        sessionStorage.getItem("joara_token") &&
        (!pathname.includes("/notice") || !pathname.includes("/noticeDetail")) ? (
          <>
            <Sidenav
              color={sidenavColor}
              brand={process.env.PUBLIC_URL + "/images/logo_shape.png"}
              brandName={"JOARA\n IP MANAGER"}
              routes={routes}
            />
            <HeaderBar loadingRef={loadingRef} />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        ) : (
          ""
        )}
        {layout === "vr" && <Configurator />}
        <Routes>{getRoutes(routes)}</Routes>
      </ThemeProvider>
    </ApolloProvider>
  )
}
