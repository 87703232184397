import { forwardRef } from "react"

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// Custom styles for SuiAvatar
import SuiAvatarRoot from "components/SuiAvatar/SuiAvatarRoot"

// eslint-disable-next-line react/display-name
const SuiAvatar = forwardRef(({ bgColor, size, shadow, ...rest }, ref) => (
  <SuiAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
))

// Setting default values for the props of SuiAvatar
SuiAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
}

// Typechecking props for the SuiAvatar
SuiAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
}

export default SuiAvatar
