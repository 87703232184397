import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import SuiInput from "components/SuiInput"
import { visuallyHidden } from "@mui/utils"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import InputLabel from "@mui/material/InputLabel"
import PaginationCustom from "components/PaginationCustom"

import Role_Dialog from "../role_Dialog"
import CuDailog from "../create-edit-dialog"
import WithdrawlDialog from "../WithdrawlDialog"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import ContractInfosDialog3 from "../ContractInfosDialog3"

import { AiFillCaretDown } from "react-icons/ai"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

// API
import { useLazyQuery, useQuery, useApolloClient } from "@apollo/client"
import { users, requests, usersCount } from "apollo/query"

// Reducers
import { useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "회원번호",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "이름",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "부서",
  },
  {
    id: "joaraname",
    numeric: false,
    disablePadding: false,
    label: "직위",
  },
  {
    id: "bookname",
    numeric: false,
    disablePadding: false,
    label: "이메일",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "연락처",
  },
  {
    id: "agent",
    numeric: false,
    disablePadding: false,
    label: "근무",
  },
  {
    id: "contract",
    numeric: false,
    disablePadding: false,
    label: "회원등급",
  },
  {
    id: "nobless",
    numeric: false,
    disablePadding: false,
    label: "담당계약",
  },
  {
    id: "premium",
    numeric: false,
    disablePadding: false,
    label: "관리",
  },
]

function Icondown(headCell) {
  let Icondownarray

  if (headCell.staus.label === "장르" || headCell.staus.label === "이북") {
    Icondownarray = <AiFillCaretDown></AiFillCaretDown>
  } else {
    Icondownarray = <></>
  }
  return Icondownarray
}

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ borderTop: 1, borderBottom: 1, borderColor: "rgba(0, 0, 0, 0.12)" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontSize: 14, fontWeight: 700 }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>

              <Icondown staus={headCell}></Icondown>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
}

function Datatable({ tab }) {
  const client = useApolloClient()
  const [usersData, setUsersData] = useState([])
  const dispatch = useDispatch()
  const [searchName, setSearchName] = useState("")
  const [sorting, setSorting] = useState("최신순")
  const [pageing, setPageing] = useState(10)
  const [changeGradeCount, setchangeGradeCount] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("calories")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(pageing)
  const [sort, setSort] = useState("created_at:desc")
  const [userData, setUserData] = useState([])
  const [role_Dialog_oepn, setrole_Dialog_oepn] = useState(false)
  const [role_Dialog_data, setrole_Dialog_data] = useState({})
  const [pageCount, setpageCount] = useState(0)
  const [usersCountActive, {}] = useLazyQuery(usersCount, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [requestsActive, {}] = useLazyQuery(requests, {
    fetchPolicy: "network-only",

    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  useEffect(() => {
    client
      .query({
        query: requests,
        variables: {
          type: "levelUp",
          status: "공개",
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        setchangeGradeCount(data.requests.length)
      })
  }, [])
  const [variables, setvariables] = useState({
    sort: "id:desc",
    start: (page - 1) * pageing,
    limit: pageing,
  })
  const userSearch = async () => {
    let li = []
    dispatch(loading_Apply(true))
    for (let i = 0; i < pageing / 10; i++) {
      await client
        .query({
          query: users,
          variables: {
            sort: variables.sort,
            start: (page + i - 1) * 10,
            limit: 10,
          },
          fetchPolicy: "network-only",
        })
        .then(async ({ data }) => {
          if (data.users.length > 0) {
            await client
              .query({
                query: requests,
                variables: {
                  status: "공개",
                },
                fetchPolicy: "network-only",
              })
              .then((res) => {
                let userData = JSON.parse(JSON.stringify([...data.users]))
                userData.forEach((user, index) => {
                  user.changeRequest = {}
                  user.change_requests.forEach((request) => {
                    if (request.status === "신청") {
                      user.changeRequest = request
                    }
                  })
                  user.request = {}
                  res.data.requests.forEach((request) => {
                    if (user.id === request.users_permissions_user.id) {
                      user.request = request
                    }
                  })
                  if (index === userData.length - 1) {
                    li = [...li, ...userData]
                  }
                })
              })
          } else {
            li = [...li, ...data.users]
          }
        })
      if (pageing / 10 - 1 === i) {
        setUsersData(li)
        dispatch(loading_Apply(false))
      }
    }
  }
  useEffect(() => {
    userSearch()
    usersCountActive({
      variables: variables,
    }).then((res) => {
      setpageCount(res.data.userCount)
    })
  }, [variables])
  const pageingList = [10, 20, 50, 100]
  const pageingListSelects = pageingList.map((x) => {
    return (
      <MenuItem value={x} key={x}>
        {x}
      </MenuItem>
    )
  })
  const updateRoleDialog = (row) => {
    if (row.request.id && row.request.content.suggestRole !== roleText(row.role.name)) {
      setrole_Dialog_data(row.request)
      setrole_Dialog_oepn(true)
    }
  }
  const handleSorting = (event) => {
    dispatch(loading_Apply(true))
    const data = { ...variables }
    if (event.target.value === "최신순") {
      data.sort = "id:desc"
    } else {
      data.sort = "id:asc"
    }
    setSorting(event.target.value)
    setvariables(data)
  }
  const handlePageing = (event) => {
    dispatch(loading_Apply(true))
    setPage(1)
    const data = { ...variables }
    data.start = 0
    data.limit = event.target.value
    setvariables(data)
    setPageing(event.target.value)
  }
  const handlePage = (event, value) => {
    dispatch(loading_Apply(true))
    const data = { ...variables }
    data.start = (value - 1) * pageing
    setvariables(data)
    setPage(value)
  }
  const handleSearchName = (event) => {
    setSearchName(event.target.value)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }
  const roleText = (value) => {
    if (value === "Authenticated") {
      return "슈퍼어드민"
    } else if (value === "gold") {
      return "골드"
    } else if (value === "silver") {
      return "실버"
    } else if (value === "bronze") {
      return "브론즈"
    } else {
      return "오류"
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchName !== "") {
        dispatch(loading_Apply(true))
        setPage(1)
        const data = { ...variables }
        data.start = 0
        data.name = searchName
        setvariables(data)
      } else {
        dispatch(loading_Apply(true))
        setPage(1)
        const data = {
          sort: sorting === "최신순" ? "id:desc" : "id:asc",
          start: 0,
          limit: pageing,
        }
        setvariables(data)
      }
    }
  }
  const [CuDailogBool, setCuDailogBool] = useState(false)
  const [editData, seteditData] = useState({})
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const updateUser = (data) => {
    let array = [...userData]
    const index = array.map((x) => x.id).indexOf(data.user.id)
    array[index].request = {}
    setUserData(array)
  }
  const closeDialog = () => {
    setrole_Dialog_oepn(false)
    setCuDailogBool(false)
    seteditData({})
  }
  const handleEditDialog = (row) => {
    setCuDailogBool(true)
    seteditData(row)
  }

  const [open_WithdrawlDialog, setopen_WithdrawlDialog] = useState(false)
  const [withdrawl_row, setwithdrawl_row] = useState({})
  const close_WithdrawlDialog = () => {
    setopen_WithdrawlDialog(false)
  }
  const withdrawDialog_setData = (row) => {
    setopen_WithdrawlDialog(true)
    setwithdrawl_row(row[0])
  }

  const total_contract = (row) => {
    let array = []
    if (row.company_contracts && row.company_contracts.length > 0) {
      row.company_contracts.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          array.push(el.contractPaper)
        }
      })
    }
    if (row.contract_documents && row.contract_documents.length > 0) {
      row.contract_documents.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          array.push(el.contractPaper)
        }
      })
    }
    if (row.outsourcing_contracts && row.outsourcing_contracts.length > 0) {
      row.outsourcing_contracts.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          array.push(el.contractPaper)
        }
      })
    }
    return array.length
  }

  const change_request = (row) => {
    setContractInfosDialogOpenBool(true)

    let array = []
    if (row.company_contracts && row.company_contracts.length > 0) {
      row.company_contracts.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          el.contractPaper.dataType = "company"
          array.push(el.contractPaper)
        }
      })
    }
    if (row.contract_documents && row.contract_documents.length > 0) {
      row.contract_documents.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          el.contractPaper.dataType = "writer"
          array.push(el.contractPaper)
        }
      })
    }
    if (row.outsourcing_contracts && row.outsourcing_contracts.length > 0) {
      row.outsourcing_contracts.forEach((el) => {
        if (el.users_permissions_user === row.id && el.contractPaper.length > 0) {
          el.contractPaper.dataType = "outsourcing"
          array.push(el.contractPaper)
        }
      })
    }
    setContractInfosData(array)
    setContractInfos_user(row)
  }
  const reset = () => {
    dispatch(loading_Apply(true))
    setTimeout(() => {
      userSearch()
    }, 500)
  }
  const [ContractInfosDialogOpenBool, setContractInfosDialogOpenBool] = useState(false)
  const [ContractInfosData, setContractInfosData] = useState([])
  const [ContractInfos_user, setContractInfos_user] = useState({})

  return (
    <Box sx={{ width: "100%" }}>
      <Role_Dialog
        openDialog={role_Dialog_oepn}
        closeDialog={closeDialog}
        data={role_Dialog_data}
        updateUser={updateUser}
      />
      <CuDailog
        openDialog={CuDailogBool}
        closeDialog={closeDialog}
        Users={usersData}
        edit={true}
        editData={editData}
        updateUser={setUserData}
      />
      <WithdrawlDialog
        openDialog={open_WithdrawlDialog}
        closeDialog={close_WithdrawlDialog}
        withdrawlRow={withdrawl_row}
        updateUser={setUserData}
      />
      <ContractInfosDialog3
        openDialog={ContractInfosDialogOpenBool}
        closeDialog={() => setContractInfosDialogOpenBool(false)}
        ContarctData={ContractInfosData}
        userInfo={ContractInfos_user}
      />
      <Paper sx={{ width: "100%", mb: 2, boxShadow: 0 }}>
        <TableContainer sx={{ borderRadius: 0, boxShadow: 0, overflow: "hidden" }}>
          <SuiBox py={3} display="flex" justifyContent="start" height="100%">
            <SuiTypography fontSize="18px" fontWeight="medium" color="dark">
              현재 <span className="changeGradeCount-style-zero">{changeGradeCount}건</span>의 회원
              등급 변경 신청 건이 있습니다.
            </SuiTypography>
            <SuiBox sx={{ width: 200, mr: 2, ml: "auto" }} className="SuiInput-right-icon">
              <SuiInput
                icon={{ component: "search", direction: "right" }}
                type="text"
                placeholder="이름 입력"
                value={searchName}
                onChange={handleSearchName}
                onKeyPress={handleKeyPress}
              />
            </SuiBox>
            <SuiBox sx={{ width: 70 }}>
              <FormControl
                fullWidth
                sx={{ minHeight: 30 }}
                className={
                  pageing === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
                }
                onClick={() => setFiltersOpen1(!FiltersOpen1)}
              >
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen1(!FiltersOpen1)}
                    />
                  )}
                  open={FiltersOpen1}
                  value={pageing}
                  onChange={handlePageing}
                  className="setting-border-radius"
                >
                  {pageingListSelects}
                </Select>
              </FormControl>
            </SuiBox>
            <SuiBox sx={{ width: 100, ml: 2 }}>
              <FormControl
                fullWidth
                sx={{ minHeight: 30 }}
                className={
                  sorting === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
                }
                onClick={() => setFiltersOpen2(!FiltersOpen2)}
              >
                <InputLabel sx={{ display: sorting === "" ? "" : "none" }}>정렬 기준</InputLabel>
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      color="black"
                      className="select-icon cursor"
                      onClick={() => setFiltersOpen2(!FiltersOpen2)}
                    />
                  )}
                  open={FiltersOpen2}
                  value={sorting}
                  onChange={handleSorting}
                  className="setting-border-radius"
                >
                  <MenuItem value="최신순">최신순</MenuItem>
                  <MenuItem value="오래된 순">오래된 순</MenuItem>
                </Select>
              </FormControl>
            </SuiBox>
          </SuiBox>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={usersData.length}
            />
            <TableBody>
              {usersData.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.username}>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      J-{row.id}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.department}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.position}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: 13, color: row.status !== "퇴사" ? "" : "#CED4D7" }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 13,
                        color:
                          row.status !== "퇴사"
                            ? row.request.id &&
                              row.request.content.suggestRole !== roleText(row.role.name)
                              ? "#0C65FF"
                              : ""
                            : "#CED4D7",
                        textDecoration:
                          row.request.id &&
                          row.request.content.suggestRole !== roleText(row.role.name)
                            ? "underline"
                            : "",
                        cursor:
                          row.request.id &&
                          row.request.content.suggestRole !== roleText(row.role.name)
                            ? "pointer"
                            : "",
                      }}
                      onClick={() => updateRoleDialog(row)}
                    >
                      {roleText(row.role.name)}
                      <br></br>
                      {row.request.id && row.request.content.suggestRole !== roleText(row.role.name)
                        ? "등업신청"
                        : ""}
                    </TableCell>
                    {total_contract(row) > 0 ? (
                      <TableCell align="center">
                        <SuiTypography
                          style={{
                            fontSize: 13,
                            color: "#0C65FF",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => change_request(row)}
                        >
                          {total_contract(row)}
                        </SuiTypography>
                      </TableCell>
                    ) : (
                      <TableCell
                        align="center"
                        style={{
                          fontSize: 13,
                          color: "#CED4D7",
                        }}
                      >
                        -
                      </TableCell>
                    )}
                    {/* <TableCell
                      align="center"
                      style={{
                        fontSize: 13,
                        color: "#CED4D7",
                      }}
                    >
                      -
                    </TableCell> */}
                    <TableCell align="center" style={{ fontSize: 13 }}>
                      <span className="event-text" onClick={() => handleEditDialog(row)}>
                        수정
                      </span>
                      {row.requests.filter((x) => x.status.indexOf("탈퇴신청") > -1).length > 0 ? (
                        <SuiTypography
                          style={{
                            fontSize: 13,
                            color:
                              row.status !== "퇴사"
                                ? row.requests.filter((x) => x.status.indexOf("탈퇴신청") > -1)
                                    .length > 0
                                  ? "#FF7B89"
                                  : ""
                                : "#CED4D7",
                            textDecoration:
                              row.requests.filter((x) => x.status.indexOf("탈퇴신청") > -1).length >
                              0
                                ? "underline"
                                : "",
                            cursor:
                              row.requests.filter((x) => x.status.indexOf("탈퇴신청") > -1).length >
                              0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() =>
                            withdrawDialog_setData(
                              row.requests.filter((x) => x.status.indexOf("탈퇴신청") > -1)
                            )
                          }
                        >
                          탈퇴신청
                        </SuiTypography>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <SuiBox display="flex" justifyContent="center" pt={5}>
            {Math.ceil(pageCount / pageing) > 1 ? (
              <Stack spacing={2}>
                <PaginationCustom
                  page={page}
                  totalPage={Math.ceil(pageCount / pageing)}
                  changePage={handlePage}
                />

                {/* <Pagination
                  color="primary"
                  count={Math.ceil(userData.length / pageing)}
                  page={page}
                  onChange={handlePage}
                  showFirstButton
                  showLastButton
                /> */}
              </Stack>
            ) : (
              ""
            )}
          </SuiBox>
        </TableContainer>
      </Paper>
      <button style={{ display: "none" }} id="reset-table" onClick={() => reset()}></button>
    </Box>
  )
}
Datatable.defaultProps = {}

Datatable.propTypes = {
  tab: PropTypes.func,
}

export default Datatable
