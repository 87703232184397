import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"

import IconButton from "@mui/material/IconButton"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import Dialog from "@mui/material/Dialog"
import SuiDialog from "components/SuiDialog"

import DeleteDialog from "../deleteDialog"

import { MdClose } from "react-icons/md"
import { MdOutlineDoubleArrow } from "react-icons/md"

// API
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { updateRequest, updateUserMu, createAlert } from "apollo/mutation"
import { requests, users, user, users_list } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function role_Dialog({ openDialog, closeDialog, data, updateUser }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [openAccess_Dialog, setopenAccess_Dialog] = useState(false)
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [dialogData, setdialogData] = useState({})
  const { myData } = useSelector((state) => state.store)
  const roleText = (value) => {
    if (value === "Authenticated") {
      return "슈퍼어드민"
    } else if (value === "gold") {
      return "골드"
    } else if (value === "silver") {
      return "실버"
    } else if (value === "bronze") {
      return "브론즈"
    } else {
      return "오류"
    }
  }
  useEffect(() => {
    setOpen(openDialog)
    setdialogData(data)
  }, [openDialog])
  const roleSetting = (value) => {
    if (value === "슈퍼어드민") {
      return 1
    } else if (value === "골드") {
      return 3
    } else if (value === "실버") {
      return 4
    } else if (value === "브론즈") {
      return 5
    }
  }
  const [updateUserActive] = useMutation(updateUserMu, {
    onError(err) {},
    refetchQueries: [users_list],
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [users_list],
  })
  const [userActive] = useLazyQuery(user, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
    refetchQueries: [users_list],
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const Delete_Dialog_Btn = () => {
    setDelete_Dialog(true)
    setDelete_Dialog_type("create")
  }
  const refuse_Delete = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: dialogData.id,
        status: "거절",
        declining: requestExplainData,
      },
    }).then(() => {
      handleClose()
      dispatch(loading_Apply(false))
      userActive({
        variables: {
          id: dialogData.content.id,
        },
      }).then(({ data }) => {
        updateUser(data)
        if (data.user.nomalAlertSetting) {
          if (data.user.nomalAlertSetting[2].value) {
            createAlertActive({
              variables: {
                users_permissions_user: String(dialogData.content.id),
                title: "회원 등급 변경 거절",
                type: "levelUp",
                userid: String(dialogData.content.id),
                content: {
                  position: myData.position,
                  department: myData.department,
                  title: myData.name,
                  id: myData.id,
                  name: myData.name,
                  suggestRole: dialogData.content.suggestRole,
                  profile: myData.profile,
                  declining: requestExplainData,
                },
              },
            })
          }
        }
      })
    })
  }
  const access_Dialog = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: dialogData.id,
        status: "승인",
      },
    }).then(() => {
      updateUserActive({
        variables: {
          id: dialogData.content.id,
          role: roleSetting(dialogData.content.suggestRole),
        },
      }).then(() => {
        handleClose()
        dispatch(loading_Apply(false))
        userActive({
          variables: {
            id: dialogData.content.id,
          },
        }).then(({ data }) => {
          // updateUser()
          if (data.user.nomalAlertSetting) {
            if (data.user.nomalAlertSetting[2].value) {
              createAlertActive({
                variables: {
                  users_permissions_user: String(dialogData.content.id),
                  title: "회원 등급 변경 승인",
                  type: "levelUp",
                  userid: String(dialogData.content.id),
                  content: {
                    position: myData.position,
                    department: myData.department,
                    title: myData.name,
                    id: myData.id,
                    name: myData.name,
                    suggestRole: dialogData.content.suggestRole,
                    profile: myData.profile,
                  },
                },
              })
            }
          }
        })
      })
    })
  }
  const handleClose = () => {
    closeDialog()
    setDelete_Dialog(false)
    setopenAccess_Dialog(false)
    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      className="alert-dialog-layout-userChange"
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => handleClose()}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 0,
          margin: "10px",
        }}
      >
        <MdClose />
      </IconButton>
      <Card sx={{ my: 5, boxShadow: 0 }}>
        <SuiBox display="flex" justifyContent="center">
          <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
            회원 등급 변경 신청
          </SuiTypography>
        </SuiBox>
        <Card sx={{ mx: 5, border: 0, boxShadow: 0 }}>
          <SuiBox display="flex" justifyContent="start" sx={{ mt: 4 }}>
            <Card
              sx={{
                width: "50%",
                mr: 2,
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: 2 }}>
                  신청자
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                {dialogData.content ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    color="text2"
                    sx={{ mr: "auto" }}
                  >
                    {dialogData.content.department} {dialogData.content.name}{" "}
                    {dialogData.content.position}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </SuiBox>
            </Card>
            <Card
              sx={{
                width: "50%",
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: 2 }}>
                  신청일
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                {dialogData.content ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    color="text2"
                    sx={{ mr: "auto" }}
                  >
                    {dayjs(dialogData.created_at).format("YYYY.MM.DD")}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </SuiBox>
            </Card>
          </SuiBox>
          <SuiBox display="flex" justifyContent="start" sx={{ width: "100%", minWidth: 800 }}>
            <Card
              sx={{
                px: 2,
                width: "100%",
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", minHeight: 80, height: "auto" }}
              >
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  color="text2"
                  sx={{ ml: 4, whiteSpace: "nowrap" }}
                >
                  사유
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                {dialogData.content ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    color="text2"
                    sx={{ mr: "auto", whiteSpace: "pre-line", p: 3 }}
                  >
                    {dialogData.content.requestExplain}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </SuiBox>
            </Card>
          </SuiBox>
          <SuiBox
            display="flex"
            justifyContent="start"
            sx={{
              mt: 5,
            }}
          >
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                width: "50%",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: 4 }}>
                  등급
                </SuiTypography>
                <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 4 }} />
                {dialogData.content ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    color="text2"
                    sx={{ mx: "auto" }}
                  >
                    {roleText(dialogData.content.role)}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </SuiBox>
            </Card>
            <IconButton color="info2" sx={{ mx: 7.1 }}>
              <MdOutlineDoubleArrow size="30" />
            </IconButton>
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                width: "50%",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: 4 }}>
                  등급
                </SuiTypography>
                <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 4 }} />
                {dialogData.content ? (
                  <SuiTypography
                    fontSize="18px"
                    fontWeight="bold"
                    color="text2"
                    sx={{ mx: "auto" }}
                  >
                    {dialogData.content.suggestRole}
                  </SuiTypography>
                ) : (
                  ""
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </Card>
        <SuiBox p={5} display="flex" justifyContent="end">
          <SuiButton
            color="subTitle"
            variant="text"
            className="back-btn"
            sx={{ height: 40, mr: "auto", fontSize: 16 }}
            onClick={() => handleClose()}
          >
            취소
          </SuiButton>
          <SuiButton
            color="error"
            onClick={() => Delete_Dialog_Btn(true)}
            sx={{ width: 100, mr: 2 }}
          >
            거절
          </SuiButton>
          <SuiButton color="primary" onClick={() => setopenAccess_Dialog(true)} sx={{ width: 100 }}>
            변경 승인
          </SuiButton>
        </SuiBox>
      </Card>
      <SuiDialog
        openDialog={openAccess_Dialog}
        closeDialog={() => setopenAccess_Dialog(false)}
        MainTitle={"회원 등급 변경 신청 승인"}
        Content={"회원 등급 변경 신청을 승인하시겠습니까?"}
        Active={() => access_Dialog()}
        CanBtnText={"취소"}
        CreBtnText={"변경승인"}
        AlertType={"twoBtn"}
        CreBtnColor={"primary"}
      />
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={"회원 등급 변경 거절"}
        Content={"거절 사유를 작성하고, 회원 등급 변경을 미승인합니다."}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"승인거절"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => refuse_Delete()}
        type={Delete_Dialog_type}
      />
    </Dialog>
  )
}
role_Dialog.defaultProps = {
  data: {},
  openDialog: false,
}

role_Dialog.propTypes = {
  data: PropTypes.object,
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  updateUser: PropTypes.func,
}

export default role_Dialog
