import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"
import SuiDialog from "components/SuiDialog"
import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { IoIosCloseCircle } from "react-icons/io"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { FiSearch } from "react-icons/fi"
import GetFileIcon from "components/getFileIcon"

// Quill
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

// Redux
import { useSelector } from "react-redux"

// Api
import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"
import dayjs from "dayjs"

function MeetingDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  editData,
  edit,
  MeetingData,
  ContractList,
}) {
  const [open, setOpen] = useState(openDialog)
  const [Files, setFiles] = useState([])
  const { myData } = useSelector((state) => state.store)
  const InputListsSet = [
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "제목",
      maxLength: 100,
      xs: 8,
      changed: false,
    },
    {
      items: [],
      valueId: null,
      value: "",
      error_message: "",
      error: false,
      type: "selectBox",
      title: "관련계약",
      maxLength: 100,
      xs: 4,
      makerId: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
    },
    {
      type: "fileList",
      value: "",
      title: "내용",
      xs: 8,
      files: [],
    },
    {
      inputType: "text",
      value: "",
      value2: "",
      items2: ["전체 공개", "일부 공개"],
      items: ["회의록", "인터뷰", "인수인계", "기타"],
      type: "category",
      title: "",
      xs: 4,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  useEffect(() => {
    setOpen(openDialog)
    let array = [...InputLists]
    if (ContractList.length > 0) {
      array[1].items = []
      ContractList.forEach((datas, idx) => {
        datas.forEach((element, index) => {
          if (index === 0) {
            array[1].items.push({
              value: `[${
                element[0].selectData[1].value
                  ? element[0].selectData[1].value
                  : element[0].selectData[0].value
              }] ${
                element[0].pieces.length > 0
                  ? element[0].pieces.length === 1
                    ? element[0].pieces[0].title
                    : `${element[0].pieces[0].title} 외 ${element[0].pieces.length - 1}건`
                  : "계약작품 없음"
              }_${dayjs(element[1].value1).format("YYMMDD")}`,
              index: idx,
              id: element[0].id,
            })
          }
        })
      })
    }
    setInputLists(array)
  }, [openDialog])
  useEffect(() => {
    if (edit) {
      if (editData.length > 0) {
        if (editData[2].files.length > 0) {
          let array = []
          editData[2].files.forEach((element) => {
            array.push({ file: element.file })
          })
          setFiles(array)
        }
        if (ContractList.length > 0) {
          editData[1].items = []
          ContractList.forEach((datas, idx) => {
            datas.forEach((element, index) => {
              if (index === 0) {
                editData[1].items.push({
                  value: `[${
                    element[0].selectData[1].value
                      ? element[0].selectData[1].value
                      : element[0].selectData[0].value
                  }] ${
                    element[0].pieces.length > 0
                      ? element[0].pieces.length === 1
                        ? element[0].pieces[0].title
                        : `${element[0].pieces[0].title} 외 ${element[0].pieces.length - 1}건`
                      : "계약작품 없음"
                  }_${dayjs(element[1].value1).format("YYMMDD")}`,
                  index: idx,
                  id: element[0].id,
                })
              }
            })
          })
        }
        setInputLists(editData)
      }
    }
  }, [edit])
  useEffect(() => {
    if (!edit) {
      setInputLists(InputListsSet)
    }
  }, [edit])
  const [rejectDialog, setrejectDialog] = useState(false)
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })

  const [changeInput, setchangeInput] = useState("")
  let arrayFiles = [...Files]
  const imageHandler = () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute(
      "accept",
      ".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
    )
    input.click()
    input.onchange = () => {
      const file = input.files[0]

      if (file.size > 20000000) {
        return alert("200MB 이하의 pdf, excel, ppt, word, png, jpeg 파일만 업로드 가능합니다.")
      }
      arrayFiles[0] = { file: file }
      // arrayFiles.push({ file: file })
      setFiles(arrayFiles)
      setTimeout(() => {
        setchangeInput("on")
      }, 500)
      setTimeout(() => {
        setchangeInput("off")
      }, 1000)
    }
  }
  const DeleteFile = (file) => {
    let array = [...Files]
    setFiles(array.filter((x) => x.file.name !== file.name))
    arrayFiles = array.filter((x) => x.file.name !== file.name)
  }
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [["bold", "italic", "image"]],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: imageHandler,
        },
      },
    }
  }, [])
  const formats = ["bold", "italic", "image"]
  const quillChange = (e) => {
    if (!edit) {
      let array = [...InputLists]
      array[2].value = e
      array[0].changed = true
      setInputLists(array)
    } else {
      let array = [...editData]
      array[2].value = e
      array[0].changed = true
      setInputLists(array)
    }
  }
  const handleInput = (index, maxLength) => (e) => {
    let array = [...InputLists]
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
      array[0].changed = true
    }
    setInputLists(array)
  }
  const handleSelectChange = (index, data) => (e) => {
    let array = [...InputLists]
    array[index].value = e.target.value
    array[index].valueId = data.items.filter((x) => x.value === e.target.value)[0].id
    array[0].changed = true
    setInputLists(array)
  }
  const handleCheckBox2 = (index) => {
    let array = [...InputLists]
    if (index === 0) {
      array[3].value2 = "전체 공개"
    } else {
      array[3].value2 = "일부 공개"
    }
    setFiles([])
    array[1].value = ""
    array[0].changed = true
    setInputLists(array)
  }
  const handleCheckBox = (row, index) => {
    let array = [...InputLists]
    if (array[index].value === row) {
      array[index].value = ""
    } else {
      array[index].value = row
      array[0].changed = true
    }
    setInputLists(array)
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }

  const showPdf = (file) => {
    if (file.url) {
      var a = document.createElement("a")
      a.href = process.env.REACT_APP_BACKEND_URL + file.url
      a.target = "_blank"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
    }
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox>
          <SuiInput
            error={data.error}
            success={data.success}
            helperText={data.error_message}
            type={data.inputType}
            placeholder=""
            value={data.value}
            disabled={data.value === "**********"}
            onChange={handleInput(index, data.maxLength)}
          />
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return (
        <SuiBox sx={{ width: 200, mb: 0.7 }}>
          <FormControl
            fullWidth
            sx={{ minHeight: 30 }}
            className={
              data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
            }
            onClick={() => setFiltersOpen1(!FiltersOpen1)}
          >
            <Select
              sx={{
                backgroundColor: InputLists[3].value2 === "전체 공개" ? "#CDD2D3 !important" : "",
              }}
              disabled={InputLists[3].value2 === "전체 공개"}
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                  id="SelectValue"
                  onClick={() => {
                    if (InputLists[3].value2 !== "전체 공개") {
                      setFiltersOpen1(!FiltersOpen1)
                    }
                  }}
                />
              )}
              open={FiltersOpen1}
              value={data.value}
              onChange={handleSelectChange(index, data)}
              className="setting-border-radius"
            >
              {data.items.map((x) => {
                return (
                  <MenuItem value={x.value} key={x.index}>
                    {x.value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    } else if (data.type === "category") {
      return (
        <SuiBox sx={{ height: "auto", pt: 1.5, minHeight: 334 }}>
          <SuiTypography fontSize="14px" color="info">
            공개범위:
          </SuiTypography>
          {data.items2.map((x, index) => {
            return (
              <SuiBox display="flex" alignItems="center" sx={{ pb: 0.8 }} key={index}>
                <Checkbox
                  checked={x === data.value2}
                  onChange={() => handleCheckBox2(index)}
                  sx={{ p: 0, pr: 1 }}
                  color="primary"
                />
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={() => handleCheckBox2(index)}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                  className="letter-0-8px"
                  color={x === data.value2 ? "primary" : "dark"}
                >
                  {x}
                </SuiTypography>
              </SuiBox>
            )
          })}

          <SuiTypography fontSize="14px" color="info" sx={{ mt: "20px" }}>
            카테고리:
          </SuiTypography>
          {data.items.map((row, rowindex) => {
            return (
              <SuiBox display="flex" alignItems="center" key={rowindex} sx={{ pb: 0.8 }}>
                <Checkbox
                  checked={data.value === row}
                  onChange={() => handleCheckBox(row, index)}
                  sx={{ p: 0, pr: 1 }}
                  color="primary"
                />
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={() => handleCheckBox(row, index)}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                  className="letter-0-8px"
                  color={data.value === row ? "primary" : "dark"}
                >
                  {row}
                </SuiTypography>
              </SuiBox>
            )
          })}
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <input
            type="text"
            value={changeInput}
            style={{ display: "none" }}
            id="changeInputAct"
            onChange={(e) => setchangeInput(e.target.value)}
          ></input>
          <SuiBox
            justifyContent="center"
            display="flex"
            alignItems="start"
            sx={{ height: "auto", width: "100%" }}
          >
            <ReactQuill
              className="Quill-layout"
              modules={modules}
              theme="snow"
              value={data.value}
              formats={formats}
              onChange={quillChange}
            />
          </SuiBox>
          <SuiBox sx={{ mt: 1 }}>
            {Files.map((file, index) => {
              const fileName = file.file?.name
              const fileType = fileName.split(".")[fileName.split(".").length - 1]
              return (
                <SuiBox
                  key={index}
                  justifyContent="flex-start"
                  display="flex"
                  alignItems="center"
                  sx={{ height: "auto" }}
                >
                  <IconButton
                    sx={{ mr: 1, p: 0, pt: 0.6, width: 18, height: 18 }}
                    onClick={() => DeleteFile(file.file)}
                  >
                    <IoIosCloseCircle />
                  </IconButton>
                  <IconButton sx={{ mr: 1, p: 0 }} className="cursorDefalut">
                    {GetFileIcon(fileType)}
                  </IconButton>
                  <SuiTypography
                    fontSize="14px"
                    fontWeight="medium"
                    color="dark"
                    sx={{ pb: 0, cursor: "pointer" }}
                    onClick={() => DownloadPdf(file.file)}
                  >
                    {file.file.name}
                  </SuiTypography>
                  {file.file.url ? (
                    <div style={{ marginBottom: "2px", width: "150px" }}>
                      <SuiButton
                        sx={{
                          backgroundColor: "#EEEEEE",
                          ml: "auto",
                          minHeight: 24,
                          height: 24,
                          width: 24,
                          minWidth: 24,
                          p: 0,
                          ml: 2,
                          boxShadow: 0,
                        }}
                        onClick={() => showPdf(file.file)}
                      >
                        <FiSearch size="15" />
                      </SuiButton>
                      {/* <SuiButton
                        color="info"
                        sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                        onClick={() => DownloadPdf(file.file)}
                      >
                        다운로드
                      </SuiButton> */}
                    </div>
                  ) : (
                    ""
                  )}
                </SuiBox>
              )
            })}
          </SuiBox>
        </SuiBox>
      )
    }
  }

  const CUcheck = () => {
    if (InputLists[3].value2 === "전체 공개" && Files.length > 0) {
      return setrejectDialog(true)
    }
    InputLists[2].files = Files
    if (!edit) {
      MeetingData(InputLists)
    }

    setOpen(false)
    closeDialog2()
    setTimeout(() => {
      setFiles([])
      setInputLists(InputListsSet)
    }, 500)
  }
  const handleClose = () => {
    closeDialog()
    setFiles([])
    if (!edit) {
      setInputLists(InputListsSet)
    }
    setOpen(false)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "inherit" }}
      >
        <SuiBox>
          <SuiTypography
            fontSize="15px"
            sx={{ pt: index < 2 ? 2 : 0.5, pb: 0.5, color: "#668B9E !important;", fontWeight: 400 }}
          >
            {row.title}
          </SuiTypography>
          <button id="hihi" style={{ display: "none" }}></button>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <SuiBox sx={{ p: 5 }}>
          <SuiBox sx={{ textAlign: "start" }}>
            <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
              {edit ? "회의록 수정" : "회의록 추가"}
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          <Divider sx={{ mb: 4, mt: 2 }} />
          <DialogActions sx={{ p: 0 }}>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{ width: 97.38, border: 1, borderColor: "#707070", height: 40, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              disabled={InputLists[3].value === "" || InputLists[0].value === ""}
              sx={{ width: 97.38, height: 40, fontSize: 14 }}
              color="primary"
              onClick={() => CUcheck()}
            >
              {!edit ? "완료" : "수정"}
            </SuiButton>
          </DialogActions>
        </SuiBox>
        <SuiDialog
          openDialog={rejectDialog}
          closeDialog={() => setrejectDialog(false)}
          MainTitle={"첨부파일 불가"}
          Content={"공개범위가 전체 공개시 첨부파일이 불가 합니다."}
          CanBtnText={"확인"}
          CreBtnColor="primary"
          AlertType="oneBtn"
        />
      </Dialog>
    </div>
  )
}

MeetingDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: [],
  ContractList: [],
}

MeetingDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.array,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  MeetingData: PropTypes.func,
  ContractList: PropTypes.array,
}
export default MeetingDialog
