import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import axios from "axios"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import GetFileIcon from "components/getFileIcon"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import enLocale from "date-fns/locale/en-US"
import Tooltip from "@mui/material/Tooltip"

import ContarctDialog from "../ContarctDialog"

import { MdClose } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { BiUpload, BiCalendar } from "react-icons/bi"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { FileUploader } from "react-drag-drop-files"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { outsourcing, settings } from "apollo/query"
import {
  createDonwloadLog,
  createOutsourcingHistory,
  updateOutsourcingHistory,
} from "apollo/mutation"
import { loading_Apply } from "reducers/store"

function DragDrop({ files }) {
  let message = "드래그해서 파일을 업로드 해주세요."
  const [Message, setMessage] = useState(message)
  const handleChange = (file) => {
    files({ name: "" })
    files(file)
    setMessage("업로드 되었습니다.")
  }
  const onTypeError = () => {
    setMessage("200MB 이하의 파일만 업로드 가능합니다.")
  }
  const onSizeError = () => {
    setMessage("200MB 이하의 파일만 업로드 가능합니다.")
  }
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      hoverTitle="pdf, excel, ppt, word, png, jpeg 파일만 업로드 됩니다."
      label="드레그해서 파일을 업로드 해주세요."
      maxSize="200"
      classes="width100 fileDragDropContract"
      types={[
        "pdf",
        "png",
        "jpeg",
        "jpg",
        "ppt",
        "pptx",
        "docs",
        "xlsx",
        "docx",
        "xlsm",
        "xls",
        "xlsb",
        "xltx",
        "xltm",
        "xml",
      ]}
      accept=".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
      onSizeError={onSizeError}
      onTypeError={onTypeError}
    >
      <Card
        sx={{
          p: 2,
          width: "100%",
          height: 90,
          boxShadow: 0,
          border: 1,
          backgroundColor: "#FAF8FF",
          borderColor: "#EEEEEE",
        }}
      >
        <SuiBox
          sx={{
            mt: 0,
            textAlign: "center",
          }}
        >
          <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
            <BiUpload />
          </IconButton>
          <SuiTypography
            color={
              Message === "200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다."
                ? "error"
                : "secondary"
            }
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {Message}
          </SuiTypography>
        </SuiBox>
      </Card>
    </FileUploader>
  )
}
DragDrop.defaultProps = {}

DragDrop.propTypes = {
  files: PropTypes.func,
}

function ContractDialog({
  openDialog,
  closeDialog,
  edit,
  backStep,
  selectDataProps,
  MeetingData,
  editData,
  typeData,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [DragDropFile, setDragDropFile] = useState({ name: "" })
  const { myData } = useSelector((state) => state.store)
  const [contarctDialog, setContarctDialog] = useState(false)
  const [selectContarctActive, setSelectContarctActive] = useState([])
  const [outType_list, setoutType_list] = useState([])
  const InputListsSet = [
    // 0
    {
      type: "picUpload",
      title: "파일 업로드:",
      xs: 6,
      file: {},
      changed: false,
      dropdown1: false,
      dropdown2: false,
      memo: [],
      memoSotring: "최신순",
      subContractSotring: "최신순",
      subContract: [],
      requests: [],
      selectDataProps: "",
    },
    // 1
    {
      value: [],
      type: "contract",
      title: "작품명:",
      value1: "표지",
      value2: "",
      items:
        outType_list.length > 0
          ? outType_list
          : ["표지", "타이포", "교정교열", "삽화", "콘텐츠", "배너", "기타"],
      title1: "구분:",
      title2: "단가(원):",
      xs: 6,
    },
    // 2
    {
      title: "업로드 파일:",
      type: "fileList",
      xs: 6,
    },
    // 3
    {
      value1: null,
      value2: null,
      type: "datePicker",
      title: "작업 시작일:",
      title1: "작업 완료일:",
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      error1: false,
      error2: false,
    },
    // 4
    {
      xs: 6,
    },
    // 5
    {
      inputType: "text",
      value: "",
      type: "textArea",
      title: "특이사항:",
      maxLength: 50,
      xs: 6,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
    },
  })
  const [createOutsourcingHistoryActive] = useMutation(createOutsourcingHistory, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
    },
    refetchQueries: [outsourcing],
  })
  const [updateOutsourcingHistoryActive] = useMutation(updateOutsourcingHistory, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
    },
  })
  const [settingsActive] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  useEffect(() => {
    setOpen(openDialog)
    if (editData.length > 0) {
      setDragDropFile(editData[0].file)
      InputListsSet[0].id = editData[0].id
      InputListsSet[0].file = editData[0].file
      InputListsSet[0].requests = editData[0].requests
      InputListsSet[1].value = editData[1].value
      InputListsSet[1].value1 = editData[1].value1
      if (typeData === "detail")
        InputListsSet[1].value2 = editData[1].value2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
      else InputListsSet[1].value2 = editData[1].value2
      InputListsSet[3].value1 = editData[3].value1
      InputListsSet[3].value2 = editData[3].value2
      InputListsSet[5].value = editData[5].value
      setInputLists(JSON.parse(JSON.stringify(InputListsSet)))
    } else {
      setDragDropFile({ name: "" })
      setInputLists(InputListsSet)
    }
  }, [openDialog])
  useEffect(() => {
    const array = [...InputLists]
    array[1].value = selectContarctActive
    setInputLists(array)
  }, [selectContarctActive])
  useEffect(() => {
    if (editData.length > 0) {
      setDragDropFile(editData[0].file)
      InputListsSet[0].id = editData[0].id
      InputListsSet[0].file = editData[0].file
      InputListsSet[0].requests = editData[0].requests
      InputListsSet[1].value = editData[1].value
      InputListsSet[1].value1 = editData[1].value1
      InputListsSet[1].value2 = editData[1].value2
      InputListsSet[3].value1 = editData[3].value1
      InputListsSet[3].value2 = editData[3].value2
      InputListsSet[5].value = editData[5].value
      setInputLists(JSON.parse(JSON.stringify(InputListsSet)))
    } else {
      setDragDropFile({ name: "" })
      setInputLists(InputListsSet)
    }
  }, [editData, edit])
  useEffect(() => {
    settings_Call()
  }, [])
  const settings_Call = () => {
    settingsActive({
      variables: {
        type: "outType",
      },
    }).then((res) => {
      let list = res.data.settings[0].list
      setoutType_list(list)
    })
  }

  const priceInput = (e, index) => {
    const array = [...InputLists]

    array[index].value2 = e.target.value

    array[index].value2 = array[index].value2.replace(/[^\d]+/g, "")
    array[index].value2 = array[index].value2.replace(/[^0-9]/, "")
    array[index].value2 = array[index].value2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")

    setInputLists(array)
  }
  const handleTextAreaChange = (index) => (e) => {
    const array = [...InputLists]
    if (array[index].maxLength >= e.target.value.length) {
      array[index].value = e.target.value
    }
    setInputLists(array)
  }
  const handleClose = () => {
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    closeDialog()
    setOpen(false)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].value1 = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const handleFilterDatas = (index, num) => (e) => {
    let array = [...InputLists]
    if (e) {
      if (e.toString() === "Invalid Date") {
        array[index][`error${num}`] = true
      } else {
        array[index][`error${num}`] = false
      }
    }
    if (num === 1) {
      array[index].value1 = dayjs(e.$d).format("YYYY-MM-DD")
    } else if (num === 2) {
      array[index].value2 = dayjs(e.$d).format("YYYY-MM-DD")
    }
    array[0].changed = true

    setInputLists(array)
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: String(myData.id),
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const imgupload = () => {
    document.getElementsByClassName("fileDragDropContract")[0].click()
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const fileListShow = () => {
    if (DragDropFile.name) {
      const fileName = DragDropFile.name
      const fileType = fileName.split(".")[fileName.split(".").length - 1]
      return (
        <SuiBox display="flex" alignItems="center" sx={{ height: "auto" }}>
          <IconButton sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }} className="cursorDefalut">
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="dark"
            sx={{ pt: 0.8, pb: 0.3, cursor: DragDropFile.url ? "pointer" : "default" }}
            onClick={() => DownloadPdf(DragDropFile)}
          >
            {DragDropFile.name}
          </SuiTypography>
          {DragDropFile.url ? (
            <div style={{ width: "150px" }}>
              <SuiButton
                sx={{
                  backgroundColor: "#EEEEEE",
                  ml: "auto",
                  minHeight: 24,
                  height: 24,
                  width: 24,
                  minWidth: 24,
                  p: 0,
                  ml: 1,
                  boxShadow: 0,
                }}
                onClick={() => showPdf(DragDropFile)}
              >
                <FiSearch size="15" />
              </SuiButton>
              {/* <SuiButton
                color="info"
                sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                onClick={() => DownloadPdf(DragDropFile)}
              >
                다운로드
              </SuiButton> */}
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else {
      return (
        <SuiBox
          justifyContent="center"
          display="flex"
          alignItems="start"
          sx={{ height: "auto", pt: 4 }}
        ></SuiBox>
      )
    }
  }
  const contractNumber = (id) => {
    let returnData = ""
    if (id.length === 1) {
      var ids = String(id[0].id)
      returnData += ids.padStart(5, "0")
    } else {
      id.map((x) => x.id).forEach((el) => {
        var ids = String(el)
        returnData += ids.padStart(5, "0") + ","
      })
    }
    return returnData
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "picUpload") {
      return (
        <SuiBox>
          <SuiBox display="flex" alignItems="center">
            {typeData === "detail" ? "" : <DragDrop files={setDragDropFile} />}
          </SuiBox>
          <SuiBox
            justifyContent="center"
            display="flex"
            alignItems="start"
            sx={{ height: "auto", mt: 2.5 }}
          >
            {typeData === "detail" ? (
              ""
            ) : (
              <SuiButton
                sx={{ height: 30, px: 2 }}
                color="info2"
                size="small"
                onClick={() => imgupload()}
              >
                파일선택
              </SuiButton>
            )}
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "contract") {
      return (
        <SuiBox>
          <Card
            sx={{
              width: "100%",
              height: 57,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              pt: 1.8,
              mb: 1,
              px: 2,
            }}
          >
            <SuiBox display="flex" width="100%">
              {contractNumber(data.value).length > 36 ? (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {contractNumber(data.value)}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <SuiTypography
                    sx={{
                      fontSize: 18,
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {contractNumber(data.value).substring(0, 36)}...
                  </SuiTypography>
                </Tooltip>
              ) : (
                <SuiTypography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {contractNumber(data.value)}
                </SuiTypography>
              )}
            </SuiBox>
          </Card>
          <Grid container alignItems="center">
            <Grid item xs={6} sx={{ mb: "6px" }}>
              <SuiBox display="flex">
                <SuiTypography
                  fontSize="15px"
                  fontWeight="regular"
                  color="info"
                  sx={{ pt: 2, pb: 1 }}
                >
                  {data.title1}
                </SuiTypography>
              </SuiBox>
              {typeData === "detail" ? (
                <SuiBox sx={{ width: "165px", pt: 1 }} className="setting_disabled">
                  <SuiInput disabled={edit} value={data.value1} />
                </SuiBox>
              ) : (
                <FormControl
                  fullWidth
                  sx={{ width: "165px" }}
                  className={
                    data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
                  }
                  onClick={() => setFiltersOpen1(!FiltersOpen1)}
                >
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        color="black"
                        className="select-icon cursor"
                        onClick={() => setFiltersOpen1(!FiltersOpen1)}
                      />
                    )}
                    open={FiltersOpen1}
                    value={data.value1}
                    onChange={handleSelectChange(index)}
                    className="setting-border-radius"
                  >
                    {data.items.map((x) => {
                      return (
                        <MenuItem value={x} key={x}>
                          {x}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={6}>
              <SuiBox display="flex" sx={{ pl: 2 }}>
                <SuiTypography
                  fontSize="15px"
                  fontWeight="regular"
                  color="info"
                  sx={{ pt: 2, pb: 1 }}
                >
                  {data.title2}
                </SuiTypography>
              </SuiBox>
              <SuiBox sx={{ pl: 2 }} className={typeData === "detail" ? "setting_disabled" : ""}>
                <SuiInput
                  disabled={typeData === "detail"}
                  type="text"
                  value={data.value2}
                  onChange={(e) => priceInput(e, index)}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <SuiBox>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    } else if (data.type === "datePicker") {
      return (
        <SuiBox display="flex" alignItems="center">
          <SuiBox sx={{ width: "165px" }}>
            {typeData === "detail" ? (
              <SuiBox sx={{ width: "165px", pt: 1 }} className="setting_disabled">
                <SuiInput disabled={edit} value={data.value1} />
              </SuiBox>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
                <DatePicker
                  className="contract-date"
                  inputFormat={"YYYY-MM-DD"}
                  mask="____-__-__"
                  value={data.value1}
                  onChange={handleFilterDatas(index, 1)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
          </SuiBox>
          <SuiBox sx={{ width: "165px", ml: "32px" }}>
            {typeData === "detail" ? (
              <SuiBox sx={{ width: "165px", pt: 1 }} className="setting_disabled">
                <SuiInput disabled={edit} value={data.value2} />
              </SuiBox>
            ) : (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={enLocale}
                sx={{
                  backgroundColor: typeData === "detail" ? "gray" : "",
                }}
              >
                <DatePicker
                  className="contract-date"
                  inputFormat={"YYYY-MM-DD"}
                  mask="____-__-__"
                  value={data.value2}
                  minDate={new Date(data.value1)}
                  onChange={handleFilterDatas(index, 2)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "textArea") {
      return (
        <SuiBox display="flex" alignItems="center">
          <TextField
            disabled={typeData === "detail"}
            multiline
            rows={2}
            sx={{ width: "100%" }}
            className="Fileupload-textArea"
            variant="outlined"
            value={data.value}
            onChange={handleTextAreaChange(index)}
          />
        </SuiBox>
      )
    }
  }
  const openContarctDialog = () => {
    setContarctDialog(true)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{
          pl: index % 2 === 0 ? 0 : 2,
          pr: index % 2 === 0 ? 2 : 0,
        }}
      >
        <SuiBox>
          {index === 1 ? (
            <SuiBox display="flex" sx={{ mt: "15px", mb: "8px" }}>
              <SuiTypography fontSize="15px" fontWeight="regular" color="info" sx={{ mr: "auto" }}>
                {row.title}
              </SuiTypography>
              <SuiTypography
                fontSize="14px"
                fontWeight="bold"
                color="info2"
                className="cursor"
                sx={{
                  display: typeData === "detail" ? "none" : "",
                  textAlign: "right",
                  ml: "auto",
                  textDecoration: "underline",
                }}
                onClick={() => openContarctDialog()}
              >
                작품검색
              </SuiTypography>
            </SuiBox>
          ) : row.title1 ? (
            <SuiBox display="flex" sx={{ mt: "2px" }}>
              <SuiTypography
                fontSize="15px"
                fontWeight="regular"
                color="info"
                sx={{ pt: 2, pb: 1, width: "198px" }}
              >
                {row.title}
              </SuiTypography>
              <SuiTypography
                fontSize="15px"
                fontWeight="regular"
                color="info"
                sx={{ pt: 2, pb: 1 }}
              >
                {row.title1}
              </SuiTypography>
            </SuiBox>
          ) : (
            <SuiTypography fontSize="15px" fontWeight="regular" color="info" sx={{ pt: 2, pb: 1 }}>
              {row.title}
            </SuiTypography>
          )}
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })
  const [wrong_date, setwrong_date] = useState(false)
  const createContractDc = () => {
    InputLists[0].file = DragDropFile
    InputLists[0].selectDataProps = selectDataProps
    if (edit) {
      handleClose()
      // if (!DragDropFile.url) {
      //   InputLists[0].changed = true
      // }
    }

    let array = [...InputLists]

    array[1].value2 = String(array[1].value2).replace(/[^\d]+/g, "")
    array[1].value2 = Number(array[1].value2.replace(/[^0-9]/, ""))

    MeetingData(array)
    handleClose()
  }
  const contarctDialogclose = () => {
    setContarctDialog(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 820 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              {/* 작업이력 정보 {!typeData !== 'detail' ?"등록" : "수정"} */}
              작업이력 정보 등록
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              정보를 입력하고 파일을 업로드합니다.
            </SuiTypography>
          </SuiBox>
          <SuiBox sx={{ px: 3 }}>
            <Grid container alignItems="start">
              {listsShow}
            </Grid>
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ pl: 2, pr: 3, mb: 3, mt: "20px" }}
          >
            {typeData === "detail" ? (
              ""
            ) : (
              <SuiButton
                color={"subTitle"}
                variant="text"
                onClick={() => backStep()}
                className="back-btn"
                style={{ cursor: "pointer" }}
                sx={{ mr: "auto", fontSize: 16 }}
              >
                뒤로
              </SuiButton>
            )}
            {typeData === "detail" ? (
              ""
            ) : (
              <SuiButton
                color="subTitle"
                variant="outlined"
                onClick={() => handleClose()}
                sx={{
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  width: 100,
                  fontSize: 14,
                  mb: 1,
                  mr: "16px",
                }}
              >
                취소
              </SuiButton>
            )}

            <SuiButton
              color="info2"
              onClick={() => createContractDc()}
              sx={{
                ml: typeData === "detail" ? "auto" : "",
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                mb: 1,
              }}
              disabled={
                // InputLists[1].value.length === 0 ||
                // !InputLists[1].value2 ||
                !InputLists[3].value1 || !InputLists[3].value2 || !DragDropFile.name
              }
            >
              {typeData === "detail" ? "확인" : edit ? "수정" : "확인"}
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      <SuiDialog
        openDialog={wrong_date}
        closeDialog={() => setwrong_date(false)}
        MainTitle={"정보 입력 오류"}
        Content={"작업 시작일 또는 작업 종료일을 다시 확인해 주세요."}
        CanBtnText={"닫기"}
      />
      <ContarctDialog
        openDialog={contarctDialog}
        closeDialog={contarctDialogclose}
        selectContarctActive={setSelectContarctActive}
        selectData={selectDataProps}
      ></ContarctDialog>
    </Dialog>
  )
}

ContractDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: [],
}

ContractDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.array,
  backStep: PropTypes.func,
  selectDataProps: PropTypes.string,
  closeDialog: PropTypes.func,
  MeetingData: PropTypes.func,
  typeData: PropTypes.string,
}
export default ContractDialog
