import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes, { element, number } from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"
import TextField from "@mui/material/TextField"

import Grid from "@mui/material/Grid"

import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogActions from "@mui/material/DialogActions"

import SuiButton from "components/SuiButton"

import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"

// API
import { useQuery, useMutation } from "@apollo/client"
import { updateEventRecevier } from "apollo/mutation"
import { events, eventReceviers } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import "dayjs/locale/ko"

function noticeComfirm() {
  const dispatch = useDispatch()
  // 첫 실행시 데이터를 받아오는 코드
  const { code } = useParams()

  const [event_recevier_ID, set_event_recevier_ID] = useState("")
  const [event_recevier_data, set_event_recevier_data] = useState({})
  const [eventNoticeInfo, set_eventNoticeInfo] = useState({})
  if (code === "코드_code") {
    alert("미리보기에서는 접근할 수 없습니다")
    window.close()
  }

  const client = useApolloClient()

  useEffect(() => {
    startSetting()
  }, [])
  const startSetting = async () => {
    await client
      .query({
        query: eventReceviers,
        variables: {
          code: code,
        },
      })
      .then((res) => {
        set_eventNoticeInfo(res.data.eventReceviers[0])

        set_event_recevier_data(res.data.eventReceviers[0].id)
        set_event_recevier_ID(res.data.eventReceviers[0].id)
        let eventid = res.data.eventReceviers[0].eventId
        client
          .query({
            query: events,
            variables: {
              id: eventid,
            },
          })
          .then((res) => {
            set_all_event_data(res.data.events[0])
          })
      })
  }

  const [all_event_data, set_all_event_data] = useState({
    // 이벤트 모든정보
    manager: {
      userName: "",
      userPosition: "",
      userProfile: "",
      userDepartment: "",
    },
    history: [],
    requests: [],
  })
  const [selectedValue, setSelectedValue] = useState("진행 승인") // 라디오 밸류
  const [notify_content, set_notify_content_content] = useState("") // 이벤트 내용
  const [step, setstep] = useState(0) // 화면 단계 조정

  // 라디오 밸류 조정
  const handleChange = (e) => {
    setSelectedValue(e.target.value)
  }
  // 의견 담는 부분
  const handleNotifyContent = (e) => {
    set_notify_content_content(e.target.value)
  }

  //step이 0일때 의견 부분 구현
  const Content = () => {
    return (
      <div>
        <SuiBox ml={3.5} mt={7} sx={{ textAlign: "start" }}>
          <SuiTypography fontSize="17px" fontWeight="light" color="info">
            의견:
          </SuiTypography>
        </SuiBox>
        <SuiBox
          ml={3.5}
          mt={1}
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="start"
        >
          <div
            style={{
              backgroundColor: "#f7f9fb",
              fontSize: "12px",
              textAlign: "start",
              padding: "12px",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            {eventNoticeInfo.comment}
          </div>
        </SuiBox>

        {BtnControll()}
      </div>
    )
  }

  // 나가기
  const quit_active = () => {
    window.close()
  }

  //버튼
  const BtnControll = () => {
    return (
      <DialogActions style={{ width: "100%" }} sx={{ p: 0, ml: 3.5, mt: 3.5 }}>
        <SuiButton
          color="subTitle"
          variant="text"
          className="back-btn"
          sx={{ justifyContent: "left", fontSize: 16, mr: "auto" }}
          onClick={() => quit_active()}
        >
          나가기
        </SuiButton>
        <SuiBox>
          <SuiTypography
            color="primary"
            sx={{ fontSize: "15px", fontWeight: "bold", px: "12px", textAlign: "right" }}
          >
            제출완료
          </SuiTypography>
          <SuiTypography sx={{ fontSize: "15px", fontWeight: 400, px: "12px", color: "#707070" }}>
            {`[${dayjs(eventNoticeInfo.agree_date).format("YY.MM.DD HH:mm:ss")}]`}
          </SuiTypography>
        </SuiBox>
      </DialogActions>
    )
  }

  return (
    <SuiBox display="flex" justifyContent="center" minHeight="85vh" maxWidth="55vh">
      <Grid item xs={12} sx={{ width: "100%" }}>
        <div style={{ textAlign: "start" }}>
          <SuiTypography
            sx={{ ml: 3.5, mt: 3.5, flex: 1, fontWeight: "bold", color: "dark", fontSize: 20 }}
            variant="h6"
            component="div"
          >
            [이벤트 승인/비승인 통보]
          </SuiTypography>
          <SuiTypography
            color="subTitle"
            sx={{ ml: 3.5, mt: 2, fontWeight: "reguler", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            <span>
              <strong>{`<${all_event_data.title}>`}</strong>
              {`의 이벤트 진행을 승인하시겠습니까?`}
            </span>
          </SuiTypography>

          <RadioGroup
            sx={{ gap: 3, mt: 4, ml: 5 }}
            row
            name="controlled-radio-buttons-group "
            size="small"
          >
            <FormControlLabel
              value="진행 승인"
              control={<Radio checked={eventNoticeInfo.access === "access"} />}
              label={
                <SuiTypography
                  variant="body1"
                  style={{
                    fontSize: 14,
                    color: eventNoticeInfo.access !== "access" ? "#dde0e1" : "",
                  }}
                >
                  진행 승인
                </SuiTypography>
              }
            />
            <FormControlLabel
              value="진행 거절"
              control={<Radio checked={eventNoticeInfo.access !== "access"} />}
              label={
                <SuiTypography
                  variant="body1"
                  style={{
                    fontSize: 14,
                    color: eventNoticeInfo.access === "access" ? "#dde0e1" : "",
                  }}
                >
                  진행 거절
                </SuiTypography>
              }
            />
          </RadioGroup>
        </div>
        {Content()}
      </Grid>
    </SuiBox>
  )
}
export default noticeComfirm
