// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// react-router-dom components
import { useNavigate } from "react-router-dom"

// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout"

// Authentication layout components
import Footer from "layouts/authentication/components/Footer"

function CoverLayout({ path, color, header, title, description, image, top, children }) {
  const navigate = useNavigate()
  const reset_password = () => {
    navigate("/reset-password")
  }
  return (
    <PageLayout background="white" className="login-bg">
      <Grid
        container
        justifyContent="center"
        alignItems="end"
        sx={{
          minHeight: "80vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={5} lg={5} xl={5}>
          <SuiBox p={3}>
            <Card sx={{ p: 4, width: 480, minHeight: 520.3, m: "0 auto" }}>{children}</Card>
          </SuiBox>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <SuiTypography
            variant="body2"
            color="white"
            className="reset-password-text"
            onClick={() => reset_password()}
            sx={{
              width: 480,
              m: "0 auto",
            }}
          >
            {path === "/" ? "비밀번호를 분실하셨나요? 비밀번호 재설정" : ""}
          </SuiTypography>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  )
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  path: "",
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
}

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  top: PropTypes.number,
  children: PropTypes.node,
  path: PropTypes.string,
}

export default CoverLayout
