import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import { MdClose } from "react-icons/md"
import { loading_Apply } from "../../../../reducers/store"
import { createRequest } from "apollo/mutation"
import { outsourcing } from "apollo/query"
function MettingDeleteDialog({ openDialog, closeDialog, ContractDeleteData }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [delete_content, setdelete_content] = useState("")
  const [saveState, setsaveState] = useState(false)
  const [openAlert_Dialog, setopenAlert_Dialog] = useState(false)
  const { myData } = useSelector((state) => state.store)
  useEffect(() => {
    setOpen(openDialog)
    if (openDialog) {
      setdelete_content("")
      setsaveState(false)
    }
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
    setOpen(false)
    setopenAlert_Dialog(false)
  }
  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [outsourcing],
  })
  const nextStep = (step) => {
    if (step === 1) {
      setsaveState(true)
    } else {
      const request = {
        id: myData.id,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        role: myData.role.name,
        profile: myData.profile,
        suggestRole: "외주계약 작업이력 삭제신청",
        requestExplain: delete_content,
        title: ContractDeleteData[1].value.map((x) => x.title).join(","),
      }
      createRequestActive({
        variables: {
          status: `삭제 신청(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
          content: request,
          type: "Outsourcing",
          outsourcing_history: ContractDeleteData[0].id,
          requestId: ContractDeleteData[0].id,
          users_permissions_user: myData.id,
        },
      }).then(() => {
        setopenAlert_Dialog(true)
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <SuiDialog
        openDialog={openAlert_Dialog}
        closeDialog={() => handleClose()}
        MainTitle={"삭제 신청 완료"}
        Content={`신청되었습니다. (${dayjs(new Date()).format(
          "YY.MM.DD HH:mm"
        )}) \n관리자 승인 후 삭제가 완료됩니다.`}
        CreBtnText={"확인"}
        CreBtnColor="info2"
        AlertType="oneBtn"
        Active={() => handleClose()}
      />
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                삭제 신청
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                신청 정보를 확인하고 사유를 작성합니다.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, mt: 5, maxWidth: "-webkit-fill-available" }}>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" color="text2" sx={{ ml: "auto" }}>
                신청내용 :
              </SuiTypography>

              {ContractDeleteData.length > 0 ? (
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", ml: "4px", color: "#0C65FF" }}
                >
                  {ContractDeleteData[1].value.map((x) => x.title).join(",")}
                </SuiTypography>
              ) : (
                ""
              )}
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, mt: "40px", maxWidth: "-webkit-fill-available" }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", fontWeight: 400 }}>
              신청사유 :
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              disabled={saveState}
              multiline
              rows={6}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              placeholder="사유를 작성해주세요 (300자 이내)"
              value={delete_content}
              onChange={(e) => {
                if (e.target.value.length <= 300) setdelete_content(e.target.value)
              }}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: "20px", px: 4 }}
          >
            {saveState ? (
              <SuiButton
                color="subTitle"
                variant="text"
                onClick={() => setsaveState(false)}
                className="back-btn"
                sx={{ justifyContent: "left", fontSize: 16 }}
              >
                뒤로
              </SuiButton>
            ) : (
              ""
            )}
            {!saveState ? (
              <SuiButton
                color="info2"
                disabled={delete_content === ""}
                sx={{
                  ml: "auto",
                  mr: 1,
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  width: 100,
                  fontSize: 14,
                }}
                onClick={() => nextStep(1)}
              >
                다음으로
              </SuiButton>
            ) : (
              <SuiButton
                color="error"
                sx={{
                  ml: "auto",
                  mr: 1,
                  px: 2,
                  py: 0.5,
                  height: 40,
                  minHeight: 40,
                  width: 100,
                  fontSize: 14,
                }}
                onClick={() => nextStep(2)}
              >
                삭제신청
              </SuiButton>
            )}
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}

MettingDeleteDialog.defaultProps = {
  openDialog: false,
}

MettingDeleteDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  ContractDeleteData: PropTypes.array,
}
export default MettingDeleteDialog
