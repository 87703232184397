import { gql } from "@apollo/client"

// me data
export const me = gql`
  query {
    me {
      secret
      created_at
      id
      username
      email
      department
      position
      phone
      status
      name
      adminAlertSetting
      nomalAlertSetting
      accessTabs
      profile {
        id
        url
      }
      writers {
        id
        status
        users {
          id
        }
        contracts {
          id
          created_at
          updated_at
          title
          status
          user {
            id
            email
            name
            position
            department
            status
          }
          bookcode
          genre
          joaraStatus
          cover
          ecover
          name
          position
          department
          contract_documents(sort: "created_at:desc") {
            id
            created_at
            updated_at
            type
            status
            memo
            subContract
            name
            position
            department
            contractPaper
            managerLog
            users_permissions_user {
              id
              email
              name
              position
              department
              status
            }
          }
        }
        users {
          id
        }
      }
      companies {
        id
        status
        users {
          id
        }
        company_contracts(sort: "created_at:desc") {
          id
          created_at
          updated_at
          type
          status
          memo
          subContract
          name
          position
          department
          contractPaper
          managerLog
          referrer
          users_permissions_user {
            id
            email
            name
            position
            department
            status
          }
        }
      }
      requests {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        read
      }
      role {
        id
        name
      }
      change_requests {
        id
        type
        content
      }
      outsourcings {
        id
        status
        user {
          id
        }
        outsourcing_contracts(sort: "created_at:desc") {
          id
          created_at
          updated_at
          type
          status
          memo
          subContract
          name
          position
          department
          contractPaper
          managerLog
          referrer
          users_permissions_user {
            id
            email
            name
            position
            department
            status
          }
        }
      }
      contracts {
        id
      }
      contract_documents {
        id
        contract {
          id
          writer {
            id
          }
        }
      }
    }
  }
`

export const me_Request = gql`
  query {
    me {
      secret
      created_at
      id
      change_requests {
        id
        type
        content
        status
        arrayContent
      }
      requests {
        id
        status
        read
        declining
        content
        arrayContent
        requestId
        type
        writer {
          id
        }
        company {
          id
        }
        outsourcing {
          id
        }
      }
    }
  }
`
export const me_id = gql`
  query {
    me {
      secret
      created_at
      id
    }
  }
`
export const me_mypage = gql`
  query {
    me {
      secret
      created_at
      id
      username
      email
      department
      position
      name
      profile {
        id
        url
      }
      writers {
        id
        joaraname
        userid
        ebookname
        name
        birth
        phone
        history
        email
        status
        users {
          id
        }
        contracts {
          id
          created_at
          updated_at
          title
          status
          user {
            id
            email
            name
            position
            department
            status
          }
          bookcode
          genre
          joaraStatus
          cover
          ecover
          name
          position
          department
          contract_documents(sort: "created_at:desc") {
            id
            created_at
            updated_at
            type
            status
            memo
            subContract
            name
            position
            department
            contractPaper
            managerLog
            users_permissions_user {
              id
              email
              name
              position
              department
              status
            }
          }
        }
        requests(sort: "created_at:desc") {
          id
          status
          read
          declining
        }
      }
      companies {
        id
        name
        bossName
        history
        status
        companynumber
        users {
          id
        }
        company_contracts(sort: "created_at:desc") {
          id
          type
          status
          referrer
          subContract
          name
          position
          department
          contractPaper
          users_permissions_user {
            id
          }
        }
        requests(sort: "created_at:desc") {
          id
          status
          read
          declining
        }
      }
      requests {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        read
      }
      role {
        id
        name
      }
      outsourcings {
        id
        name
        status
        histroy
        nickname
        contractStatus
        type
        genre
        mainTitle
        maxPrice
        minPrice
        outsourcing_histories {
          id
        }
        requests(sort: "created_at:desc") {
          id
          status
          read
          declining
        }
      }
      contracts {
        id
        status
        user {
          id
        }
        title
        contract_documents(sort: "created_at:desc") {
          contractPaper
          subContract
          users_permissions_user {
            id
          }
        }

        writer {
          id
          ebookname
          userid
          joaraname
          name
        }
        genre
        bookcode
      }
      change_requests {
        id
        type
        content
      }
    }
  }
`
export const myContractsList = gql`
  query {
    myContractsList {
      secret
      created_at
      list
    }
  }
`
export const usersQuery = gql`
  query users(
    $start: Int
    $limit: Int
    $email: String
    $name: String
    $sort: String
    $department: String
    $status: String
  ) {
    users(
      sort: $sort
      start: $start
      limit: $limit
      where: { status: $status, email: $email, name_contains: $name, department: $department }
    ) {
      id
      secret
      created_at
      name
      position
      department
      profile {
        id
        url
        name
      }
      nomalAlertSetting
      adminAlertSetting
    }
  }
`
export const users_list = gql`
  query users(
    $start: Int
    $limit: Int
    $email: String
    $name: String
    $sort: String
    $department: String
    $status: String
    $role: ID
  ) {
    users(
      sort: $sort
      start: $start
      limit: $limit
      where: {
        status: $status
        email: $email
        name_contains: $name
        department: $department
        role: $role
      }
    ) {
      id
      secret
      created_at
      nomalAlertSetting
      adminAlertSetting
      username
      email
      department
      position
      phone
      name
      role {
        id
        name
      }
    }
  }
`
export const usersCount = gql`
  query ($email: String, $name: String, $department: String, $status: String, $role: ID) {
    userCount(
      where: { email: $email, name: $name, department: $department, status: $status, role: $role }
    )
  }
`
export const users = gql`
  query users(
    $start: Int
    $limit: Int
    $email: String
    $name: String
    $sort: String
    $department: String
    $status: String
    $role: ID
  ) {
    users(
      sort: $sort
      start: $start
      limit: $limit
      where: {
        status: $status
        email: $email
        name_contains: $name
        department: $department
        role: $role
      }
    ) {
      id
      secret
      nomalAlertSetting
      adminAlertSetting
      created_at
      updated_at
      username
      email
      blocked
      role {
        id
        name
      }
      change_requests {
        id
        created_at
        updated_at
        content
        users_permissions_user {
          id
          username
          email
          department
          position
        }
        status
        contractId
        type
      }
      requests {
        id
        status
        type
        content
      }
      department
      position
      phone
      name
      status
      profile {
        id
        name
        url
      }
      accessTabs
      writers {
        id
        users {
          id
        }
        contracts {
          id
          contract_documents(sort: "created_at:desc") {
            id
            contractPaper
            users_permissions_user {
              id
            }
          }
        }
      }
      companies {
        id
        users {
          id
        }
        company_contracts(sort: "created_at:desc") {
          id
          contractPaper
          users_permissions_user {
            id
          }
        }
      }
      outsourcings {
        id
        user {
          id
        }
        outsourcing_contracts(sort: "created_at:desc") {
          id
          contractPaper
          users_permissions_user {
            id
          }
        }
      }
    }
  }
`
export const users_need = gql`
  query users(
    $start: Int
    $limit: Int
    $email: String
    $name: String
    $sort: String
    $department: String
    $status: String
    $role: ID
  ) {
    users(
      sort: $sort
      start: $start
      limit: $limit
      where: {
        status: $status
        email: $email
        name_contains: $name
        department: $department
        role: $role
      }
    ) {
      id
      secret
      adminAlertSetting
      created_at
      updated_at
      username
      email
      blocked
      role {
        id
        name
      }
      department
      position
      phone
      name
      status
      accessTabs
    }
  }
`
export const user = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      secret
      created_at
      nomalAlertSetting
      adminAlertSetting
    }
  }
`
export const sendEmails = gql`
  query sendEmails($usermail: String, $limit: Int, $type: String) {
    sendEmails(
      where: { usermail: $usermail, type: $type }
      limit: $limit
      sort: "created_at:desc"
    ) {
      secret
      created_at
      authcode
    }
  }
`
export const writers = gql`
  query writers(
    $limit: Int
    $start: Int
    $name: String
    $ebookname: String
    $joaraname: String
    $userid: String
    $birth: String
    $status: String
  ) {
    writers(
      sort: "created_at:desc"
      limit: $limit
      start: $start
      where: {
        name_contains: $name
        joaraname_contains: $joaraname
        userid_contains: $userid
        birth_contains: $birth
        ebookname_contains: $ebookname
        status: $status
      }
    ) {
      id
      secret
      created_at
      updated_at
      joaraname
      userid
      ebookname
      name
      birth
      dal
      phone
      nobless
      ebook
      registerdate
      memo
      address
      email
      account
      history
      manager
      users {
        id
        email
        name
        position
        department
        status
      }
      referrer
      pieces {
        id
      }
      contracts {
        id
        created_at
        updated_at
        title
        status
        rating
        episodes
        publicDate
        joaraStatus
        contract_documents(sort: "created_at:desc") {
          contractPaper
          referrer
          managerLog
        }
        user {
          id
          email
          name
          position
          department
          status
        }
      }
      attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        writerId
        version
        status
        maker
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        position
        file {
          id
          name
          url
        }
      }
      meetings {
        id
      }
    }
  }
`

export const writer = gql`
  query writer($id: ID!) {
    writer(id: $id) {
      id
      dal
      requests(sort: "created_at:desc") {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        requestId
      }
      secret
      accountName
      created_at
      updated_at
      joaraname
      userid
      ebookname
      name
      birth
      phone
      nobless
      ebook
      registerdate
      memo
      address
      email
      history
      manager
      account
      users {
        id
        email
        name
        position
        department
        status
        profile {
          id
          url
          name
        }
        nomalAlertSetting
      }
      referrer
      pieces {
        id
        created_at
        updated_at
        title
        genre
        bookcode
        agent
        contract
        nobless
        premium
        noblepre
        ebook
        cover
        writingnow
        contractDate
      }
      contracts {
        id
        rating
        episodes
        publicDate
        created_at
        updated_at
        title
        status
        user {
          id
          email
          name
          position
          department
          status
        }
        bookcode
        genre
        joaraStatus
        cover
        ecover
        name
        position
        department
        contract_documents(sort: "created_at:desc") {
          id
          created_at
          updated_at
          type
          status
          memo
          subContract
          name
          position
          department
          contractPaper
          managerLog
          referrer
          users_permissions_user {
            id
            email
            name
            position
            department
            status
          }
          requests {
            id
            status
            read
            declining
            created_at
            content
          }
        }
      }
      attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        writerId
        version
        status
        maker
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        position
        file {
          id
          name
          url
        }
      }
      meetings {
        id
        open
        created_at
        updated_at
        type
        relationId
        title
        content
        category
        file {
          id
          name
          url
        }
        contract
        contract_document {
          id
          contract {
            title
          }
          created_at
          updated_at
          type
          status
          memo
          subContract
          name
          position
          department
          contractPaper
          managerLog
          referrer
        }
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        maker
        status
      }
      change_requests(where: { status: "신청" }) {
        id
        users_permissions_user {
          id
        }
        created_at
        arrayContent
      }
      writer_significants {
        id
        userName
        userDepartment
        userPosition
        writer {
          id
        }
        writerId
        userProfile
        content
        updated_at
        type
      }
    }
  }
`
export const writer_user_permission = gql`
  query writer($id: ID!) {
    writer(id: $id) {
      id
      secret
      created_at
      users {
        id
        username
        email
        role {
          id
          name
        }
        name
      }
    }
  }
`
export const requests = gql`
  query requests($type: String, $status: String, $type_in: JSON, $read: Boolean, $limit: Int) {
    requests(
      sort: "created_at:desc"
      limit: $limit
      where: { type: $type, status: $status, type_in: $type_in, read: $read }
    ) {
      id
      secret
      created_at
      updated_at
      decisionDate
      declining
      status
      fromToType
      content
      type
      company {
        id
      }
      writer {
        id
      }
      requestId
      users_permissions_user {
        id
      }
      arrayContent
    }
  }
`
export const request = gql`
  query request($id: ID!) {
    request(id: $id) {
      id
      secret
      created_at
      updated_at
      decisionDate
      declining
      status
      fromToType
      content
      type
      company {
        id
      }
      writer {
        id
      }
      users_permissions_user {
        id
      }
      requestId
      arrayContent
    }
  }
`
export const alerts = gql`
  query alerts(
    $userid: String
    $show: Boolean
    $start: Int
    $limit: Int
    $users_permissions_user: ID
  ) {
    alerts(
      where: { userid: $userid, show: $show, users_permissions_user: $users_permissions_user }
      sort: "created_at:desc"
      limit: $limit
      start: $start
    ) {
      id
      secret
      created_at
      updated_at
      show
      content
      title
      type
      userid
      read
      users_permissions_user {
        id
        name
        username
        email
        department
        position
      }
    }
  }
`
export const contractsSearch = gql`
  query contracts($title: String) {
    contracts(where: { title_contains: $title, writer_ne: null }, sort: "created_at:desc") {
      id
      title
      secret
      created_at
      writer {
        id
        created_at
        updated_at
        joaraname
        userid
        ebookname
        name
        birth
        phone
        nobless
        ebook
        registerdate
        memo
        address
        email
        history
        manager
        account
        users {
          id
          email
          name
          position
          department
          status
        }
        referrer
        pieces {
          id
          created_at
          updated_at
          title
          genre
          bookcode
          agent
          contract
          nobless
          premium
          noblepre
          ebook
          cover
          writingnow
          contractDate
        }
        contracts {
          id
          created_at
          updated_at
          title
          status
          user {
            id
            email
            name
            position
            department
            status
          }
          bookcode
          genre
          joaraStatus
          cover
          ecover
          name
          position
          department
          contract_documents(sort: "created_at:desc") {
            id
            created_at
            updated_at
            type
            status
            memo
            subContract
            name
            position
            department
            contractPaper
            managerLog
            users_permissions_user {
              id
              email
              name
              position
              department
              status
            }
          }
        }
        attachments {
          id
          created_at
          updated_at
          title
          type
          explain
          writerId
          version
          status
          maker
          users_permissions_user {
            id
            email
            name
            position
            department
            status
          }
          position
          file {
            id
            name
            url
          }
        }
        meetings {
          id
          created_at
          updated_at
          type
          relationId
          title
          content
          category
          file {
            id
            name
            url
          }
          contract
          contract_document {
            id
            created_at
            updated_at
            type
            status
            memo
            subContract
            name
            position
            department
            contractPaper
            managerLog
          }
          users_permissions_user {
            id
            email
            name
            position
            department
            status
          }
          maker
          status
        }
      }
    }
  }
`

export const search_bookcode_contracts = gql`
  query contracts {
    contracts(where: { status_ne: "삭제" }) {
      id
      secret
      created_at
      bookcode
    }
  }
`
export const contracts = gql`
  query contracts(
    $start: Int
    $limit: Int
    $title: String
    $bookcode: String
    $genre: JSON
    $joaraStatus: String
    $cover: String
    $ecover: String
    $status: String
    $writer: String
  ) {
    contracts(
      where: {
        title_contains: $title
        writer_ne: null
        bookcode_contains: $bookcode
        genre_in: $genre
        joaraStatus: $joaraStatus
        cover: $cover
        ecover: $ecover
        status: $status
        writer: $writer
      }
      sort: "created_at:desc"
      limit: $limit
      start: $start
    ) {
      id
      secret
      title
      created_at
      updated_at
      status
      rating
      episodes
      publicDate
      user {
        id
        email
        name
        position
        department
        status
      }
      writer {
        id
        name
        joaraname
        ebookname
        status
        users {
          department
          name
        }
      }
      contract_documents(sort: "created_at:desc") {
        contractPaper
        referrer
        users_permissions_user {
          name
          department
        }
      }
      bookcode
      genre
      joaraStatus
      cover
      ecover
      name
      position
      department
      rating
      episodes
      publicDate
    }
  }
`
export const bookCodeContracts = gql`
  query contracts(
    $start: Int
    $limit: Int
    $title: String
    $bookcode: [String]
    $genre: JSON
    $joaraStatus: String
    $cover: String
    $ecover: String
    $joaraname: String
    $ebookname: String
  ) {
    contracts(
      where: {
        title_contains: $title
        writer_ne: null
        writer: { ebookname_contains: $ebookname, joaraname_contains: $joaraname }
        bookcode: $bookcode
        genre_in: $genre
        joaraStatus: $joaraStatus
        cover: $cover
        ecover: $ecover
        status_ne: "삭제"
      }
      limit: $limit
      start: $start
    ) {
      id
      secret
      title
      created_at
      updated_at
      status
      rating
      episodes
      publicDate
      user {
        id
        email
        name
        position
        department
        status
      }
      writer {
        id
        name
        joaraname
        ebookname
        status
        phone
        email
        users {
          department
          name
        }
      }
      contract_documents(sort: "created_at:desc") {
        contractPaper
        referrer
        users_permissions_user {
          name
          department
        }
      }
      bookcode
      genre
      joaraStatus
      cover
      ecover
      name
      position
      department
      rating
      episodes
      publicDate
    }
  }
`
export const companies = gql`
  query companies(
    $limit: Int
    $start: Int
    $name: String
    $bossName: String
    $companyid: String
    $companynumber: String
    $status: String
  ) {
    companies(
      sort: "created_at:desc"
      limit: $limit
      start: $start
      where: {
        name_contains: $name
        bossName_contains: $bossName
        companyid_contains: $companyid
        companynumber_contains: $companynumber
        status: $status
      }
    ) {
      id
      secret
      settlement
      created_at
      updated_at
      name
      bossName
      companyid
      companynumber
      joaraname
      address
      phone
      premium
      webtoon
      memo
      account
      url
      users {
        id
        email
        name
        position
        department
        status
      }
      manager
      history
      referrer
      published_at
      company_attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        companyId
        version
        status
        maker
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        position
        file {
          id
          name
          url
        }
      }
      company_contracts(sort: "created_at:desc") {
        id
        created_at
        updated_at
        type
        status
        memo
        subContract
        name
        referrer
        position
        department
        contractPaper
        managerLog
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
      }
      company_meetings {
        id
      }
    }
  }
`

export const company = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      secret
      requests(sort: "created_at:desc") {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        requestId
      }
      settlement
      created_at
      updated_at
      name
      bossName
      companyid
      companynumber
      joaraname
      address
      phone
      premium
      webtoon
      memo
      account
      url
      users {
        id
        email
        name
        position
        department
        status
        profile {
          id
          url
          name
        }
        nomalAlertSetting
      }
      manager
      history
      referrer
      published_at
      change_requests(where: { status: "신청" }) {
        id
        users_permissions_user {
          id
        }
        created_at
        arrayContent
      }
      company_attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        companyId
        version
        status
        maker
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        position
        file {
          id
          name
          url
        }
      }
      company_meetings {
        id
        open
        created_at
        updated_at
        type
        relationId
        title
        content
        category
        file {
          id
          name
          url
        }
        contract
        company_contract {
          id
          company {
            id
          }
          pieces
          created_at
          updated_at
          type
          status
          memo
          subContract
          name
          position
          department
          contractPaper
          managerLog
          referrer
        }
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        maker
        status
      }
      company_contracts(sort: "created_at:desc") {
        id
        created_at
        updated_at
        type
        status
        memo
        subContract
        name
        position
        department
        contractPaper
        managerLog
        pieces
        referrer
        ratio
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        requests {
          id
          status
          read
          declining
          created_at
        }
      }
      company_significants {
        id
        userName
        userDepartment
        userPosition
        company {
          id
        }
        companyId
        userProfile
        content
        updated_at
      }
    }
  }
`
export const roleCountUser = gql`
  query roleCountUser {
    roleCountUser {
      count
    }
  }
`
export const changeRequests = gql`
  query changeRequests(
    $contractId: String
    $status: String
    $type: String
    $writersId: String
    $companiesId: String
    $outsourcingId: String
    $limit: Int
  ) {
    changeRequests(
      limit: $limit
      sort: "created_at:desc"
      where: {
        contractId: $contractId
        status: $status
        type_contains: $type
        writersId: $writersId
        companiesId: $companiesId
        outsourcingId: $outsourcingId
      }
    ) {
      id
      secret
      content
      writersId
      outsourcingId
      companiesId
      contractId
      users_permissions_user {
        id
      }
      status
      updated_at
      created_at
      type
      arrayContent
    }
  }
`
export const recents = gql`
  query recents($users_permissions_user: ID) {
    recents(
      limit: 5
      sort: "created_at:desc"
      where: { users_permissions_user: $users_permissions_user }
    ) {
      secret
      created_at
      id
      title
      type
      srcId
      name
      users_permissions_user {
        id
        email
        name
        position
        department
        status
      }
    }
  }
`
export const contractsCount = gql`
  query contractsCount(
    $title: String
    $bookcode: String
    $genre: JSON
    $joaraStatus: String
    $cover: String
    $ecover: String
    $status: String
    $limit: Int
  ) {
    contractsCount(
      where: {
        title_contains: $title
        writer_ne: null
        bookcode_contains: $bookcode
        genre_in: $genre
        joaraStatus: $joaraStatus
        cover: $cover
        ecover: $ecover
        status: $status
        limit: $limit
      }
    )
  }
`
export const writersCount = gql`
  query writersCount(
    $name: String
    $ebookname: String
    $joaraname: String
    $userid: String
    $birth: String
    $status: String
  ) {
    writersCount(
      where: {
        name_contains: $name
        ebookname_contains: $ebookname
        joaraname_contains: $joaraname
        userid_contains: $userid
        birth_contains: $birth
        status: $status
      }
    )
  }
`

export const companysCount = gql`
  query companysCount(
    $name: String
    $bossName: String
    $companyid: String
    $companynumber: String
    $status: String
  ) {
    companysCount(
      where: {
        name_contains: $name
        bossName_contains: $bossName
        companyid_contains: $companyid
        companynumber_contains: $companynumber
        status: $status
      }
    )
  }
`
export const companiesChangeList = gql`
  query companiesChangeList($ids: JSON) {
    companies(sort: "created_at:desc", where: { id_in: $ids }) {
      id
      secret
      created_at
      updated_at
      name
      bossName
      companyid
      companynumber
      joaraname
      address
      phone
      premium
      webtoon
      memo
      account
      url
      manager
      history
      referrer
      published_at
      company_contracts(sort: "created_at:desc") {
        id
        created_at
        updated_at
        type
        status
        memo
        subContract
        name
        referrer
        position
        department
        contractPaper
        managerLog
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
      }
    }
  }
`
export const writersChangeList = gql`
  query writersChangeList($ids: JSON) {
    writers(sort: "created_at:desc", where: { id_in: $ids }) {
      id
      secret
      created_at
      updated_at
      joaraname
      userid
      ebookname
      name
      birth
      phone
      nobless
      ebook
      registerdate
      memo
      address
      email
      account
      history
      manager
      referrer
      contracts {
        id
        created_at
        updated_at
        title
        status
        joaraStatus
        contract_documents(sort: "created_at:desc") {
          contractPaper
        }
      }
    }
  }
`
export const outsourcingContract = gql`
  query ($id: ID!) {
    outsourcingContract(id: $id) {
      id
      secret
      created_at
      updated_at
      memo
      subContract
      type
      name
      position
      department
      users_permissions_user {
        id
        name
        position
        department
      }
      managerLog
      contractPaper
      status
      outsourcing {
        id
      }
    }
  }
`
export const companyContract = gql`
  query companyContract($id: ID!) {
    companyContract(id: $id) {
      id
      secret
      created_at
      updated_at
      memo
      subContract
      type
      name
      position
      department
      users_permissions_user {
        id
        name
        position
        department
      }
      managerLog
      contractPaper
      status
      company {
        id
        users {
          id
        }
      }
    }
  }
`
export const writerContract = gql`
  query writerContract($id: ID!) {
    contractDocument(id: $id) {
      id
      secret
      created_at
      contractPaper
      contract {
        id
        title
      }
      users_permissions_user {
        id
        department
        name
        position
      }
    }
  }
`
export const userContract = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      secret
      created_at
      company_contracts {
        id
      }
      contract_documents {
        id
      }
    }
  }
`
export const writerSignificants = gql`
  query writerSignificants {
    writerSignificants {
      id
      secret
      content
      userName
      userDepartment
      userPosition
      userProfile
      writer {
        id
      }
      writerId
      updated_at
      created_at
    }
  }
`
export const companySignificants = gql`
  query companySignificants {
    companySignificants {
      id
      secret
      content
      userName
      userDepartment
      userPosition
      userProfile
      company {
        id
      }
      companyId
      updated_at
      created_at
    }
  }
`
export const outsourcingSignificants = gql`
  query outsourcingSignificants {
    outsourcingSignificants {
      id
      secret
      content
      userName
      userDepartment
      userPosition
      userProfile
      outsourcing {
        id
      }
      outsourcing
      updated_at
      created_at
    }
  }
`
export const outsourcings = gql`
  query outsourcings(
    $limit: Int
    $start: Int
    $status: String
    $nickname: String
    $name: String
    $genre: String
    $type: String
    $contractStatus: String
    $maxPrice: String
  ) {
    outsourcings(
      sort: "created_at:desc"
      limit: $limit
      start: $start
      where: {
        type: $type
        nickname_contains: $nickname
        name_contains: $name
        contractStatus: $contractStatus
        genre_contains: $genre
        maxPrice: $maxPrice
        status: $status
      }
    ) {
      id
      secret
      created_at
      updated_at
      name
      nickname
      type
      genre
      mainTitle
      bizNumber
      email
      phone
      address
      minPrice
      account
      accountName
      histroy
      contractStatus
      maxPrice
      status
      manager
      referrer
      outsourcing_contracts {
        id
        created_at
        updated_at
        type
        memo
        subContract
        name
        referrer
        position
        department
        users_permissions_user {
          id
        }
        managerLog
        contractPaper
        status
      }
      outsourcing_histories {
        id
        created_at
        updated_at
        type
        pieces
        workType
        price
        start
        end
        comment
        file {
          id
          url
          name
        }
        status
      }
      outsourcing_significants(sort: "id:desc") {
        id
        userName
        userDepartment
        userPosition
        outsourcingId
        userProfile
        content
        updated_at
      }
      user {
        id
      }
      outsourcing_attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        outsourcingId
        version
        status
        maker
        users_permissions_user {
          id
        }
        position
        file {
          name
          id
          url
        }
      }
    }
  }
`
export const outsourcingHistories = gql`
  query {
    outsourcingHistories(where: { status: "공개", outsourcing_ne: null }) {
      pieces
      secret
      created_at
    }
  }
`
export const outsourcing = gql`
  query ($id: ID!) {
    outsourcing(id: $id) {
      id
      secret
      created_at
      updated_at
      name
      nickname
      type
      genre
      mainTitle
      bizNumber
      email
      phone
      address
      minPrice
      account
      accountName
      histroy
      contractStatus
      maxPrice
      status
      manager
      referrer
      change_requests(where: { status: "신청" }) {
        id
        users_permissions_user {
          id
        }
        created_at
        arrayContent
      }
      requests(sort: "created_at:desc") {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        requestId
      }
      outsourcing_significants {
        id
        userName
        userDepartment
        userPosition
        outsourcingId
        userProfile
        content
        updated_at
      }
      outsourcing_contracts {
        id
        created_at
        updated_at
        type
        memo
        subContract
        name
        position
        department
        referrer
        users_permissions_user {
          id
          name
          position
          department
        }
        requests {
          id
          status
          read
          declining
          created_at
          content
        }
        managerLog
        contractPaper
        status
      }
      user {
        id
      }
      outsourcing_histories {
        id
        created_at
        updated_at
        type
        pieces
        workType
        price
        start
        end
        comment
        requests(sort: "created_at:desc") {
          id
          status
        }
        file {
          id
          url
          mime
          name
        }
        status
      }
      outsourcing_attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        outsourcingId
        version
        status
        maker
        users_permissions_user {
          id
          position
          department
          name
        }
        position
        file {
          name
          id
          url
        }
      }
    }
  }
`
export const outsourcingCount = gql`
  query outsourcingCount($status: String) {
    outsourcingCount(where: { status: $status })
  }
`
export const settings = gql`
  query settings($type: String, $typeList: JSON) {
    settings(where: { type: $type, type_in: $typeList }) {
      id
      secret
      created_at
      type
      list
      log
    }
  }
`
export const notices = gql`
  query notices($show: Boolean) {
    notices(where: { show: $show }) {
      id
      secret
      title
      content
      file {
        id
        name
        url
      }
      name
      department
      position
      type
      show
      created_at
    }
  }
`
export const outsourcingContracts = gql`
  query ($outsourcing: ID) {
    outsourcingContracts(where: { outsourcing: $outsourcing }) {
      referrer
      secret
      created_at
    }
  }
`
export const contractDocuments = gql`
  query ($contract: ID) {
    contractDocuments(where: { contract: $contract }) {
      referrer
      secret
      created_at
    }
  }
`
export const companyContracts = gql`
  query ($company: ID) {
    companyContracts(where: { company: $company }) {
      referrer
      secret
      created_at
    }
  }
`
export const companyContractsSearch = gql`
  query {
    companyContracts(where: { status: "공개", company_ne: null }) {
      pieces
      secret
      created_at
    }
  }
`
export const events = gql`
  query events(
    $id: [ID]
    $flatform: String
    $title: String
    $start: Date
    $start_lte: Date
    $end: Date
    $status: ENUM_EVENT_STATUS
    $serial: String
    $type: String
    $reward: String
    $sendCount: String
    $resultCount: String
    $newOne: String
    $event_attachments: JSON
    $content: String
    $manager: JSON
    $_or: JSON
    $history: JSON
    $limit: Int
    $start_index: Int
    $user_name: String
    $user_department: String
    $users_permissions_user: ID
    $state_ne: String
  ) {
    events(
      where: {
        id: $id
        flatform_contains: $flatform
        title_contains: $title
        start_gte: $start
        start_lte: $start_lte
        end_lte: $end
        status: $status
        serial_contains: $serial
        type_contains: $type
        reward_contains: $reward
        sendCount: $sendCount
        resultCount: $resultCount
        event_attachments: $event_attachments
        newOne: $newOne
        content: $content
        manager: $manager
        _or: $_or
        history: $history
        state_ne: $state_ne
        users_permissions_user: {
          name_contains: $user_name
          department: $user_department
          id: $users_permissions_user
        }
      }
      limit: $limit
      start: $start_index
      sort: "created_at:desc"
    ) {
      id
      secret
      created_at
      updated_at
      flatform
      publisher
      title
      start
      end
      status
      state
      serial
      type
      reward
      sendCount
      resultCount
      newOne
      content
      manager
      history
      event_attachments {
        id
        created_at
        updated_at
        title
        type
        explain
        eventId
        version
        status
        maker
        users_permissions_user {
          id
          email
          name
          position
          department
          status
        }
        position
        file {
          id
          name
          url
        }
      }
      users_permissions_user {
        id
        position
        department
        name
      }
      requests(sort: "created_at:desc") {
        id
        created_at
        updated_at
        decisionDate
        declining
        status
        content
        type
        requestId
        read
      }
      change_requests(where: { status: "신청" }) {
        id
        users_permissions_user {
          id
        }
        created_at
        arrayContent
      }
    }
  }
`

export const eventsConnection = gql`
  query eventsConnection(
    $flatform: String
    $title: String
    $start: Date
    $start_lte: Date
    $end: Date
    $status: ENUM_EVENT_STATUS
    $serial: String
    $type: String
    $reward: String
    $sendCount: String
    $resultCount: String
    $newOne: String
    $event_attachments: JSON
    $content: String
    $manager: JSON
    $history: JSON
    $_or: JSON
    $user_name: String
    $user_department: String
    $users_permissions_user: ID
    $state_ne: String
  ) {
    eventsConnection(
      where: {
        flatform_contains: $flatform
        title_contains: $title
        start_gte: $start
        start_lte: $start_lte
        end_lte: $end
        status: $status
        serial_contains: $serial
        type_contains: $type
        reward_contains: $reward
        sendCount: $sendCount
        resultCount: $resultCount
        event_attachments: $event_attachments
        newOne: $newOne
        _or: $_or
        content: $content
        manager: $manager
        history: $history
        state_ne: $state_ne
        users_permissions_user: {
          name_contains: $user_name
          department: $user_department
          id: $users_permissions_user
        }
      }
    ) {
      values {
        id
        start
        end
        status
      }
      aggregate {
        count
      }
    }
  }
`

export const eventSignificants = gql`
  query eventSignificants($eventId: String) {
    eventSignificants(where: { eventId: $eventId }) {
      id
      secret
      updated_at
      created_at
      content
      userName
      userDepartment
      userPosition
      eventId
      userProfile
      userID
    }
  }
`
export const eventExposures = gql`
  query eventExposures($eventId: String) {
    eventExposures(where: { eventId: $eventId }) {
      id
      updated_at
      created_at
      content
      userName
      userDepartment
      userPosition
      eventId
      userProfile
      userID
    }
  }
`
export const eventEmails = gql`
  query eventEmails($eventId: String, $sort: String) {
    eventEmails(sort: $sort, where: { eventId: $eventId }) {
      id
      secret
      created_at
      eventId
      title
      senderEmail
      senderName
      endDate
      content
      allSend
      nameType
      file {
        id
        name
      }
      history
    }
  }
`
export const eventResultEmails = gql`
  query eventResultEmails($eventId: String, $sort: String) {
    eventResultEmails(sort: $sort, where: { eventId: $eventId }) {
      id
      secret
      created_at
      eventId
      title
      senderEmail
      senderName
      emailTitle
      content
      allSend
      nameType
      file {
        id
        name
      }
      emailImage {
        id
        name
        url
      }
      emailImages
      history
    }
  }
`
export const eventReceviers = gql`
  query eventReceviers(
    $eventEmailId: String
    $code: String
    $writerId: String
    $mailType: String
    $bookCode: String
    $year_gte: Date
    $year_lte: Date
    $bookCode_in: JSON
    $result_status: String
    $access: String
    $_or: JSON
  ) {
    eventReceviers(
      where: {
        _or: $_or
        eventEmailId: $eventEmailId
        created_at_gte: $year_gte
        created_at_lte: $year_lte
        code: $code
        writerId: $writerId
        mailType: $mailType
        bookCode: $bookCode
        bookCode_in: $bookCode_in
        result_status: $result_status
        access: $access
        eventEmailId_null: false
      }
    ) {
      id
      secret
      created_at
      eventEmailId
      name
      ebook
      joaraName
      phone
      title
      bookCode
      rewarod
      status
      result_status
      access
      eventId
      genre
      email
      mailType
      writerId
      code
      lists
      comment
      manager
      agree_date
      list {
        id
        url
        name
      }
    }
  }
`
export const eventReceiversStatus = gql`
  query eventReceviers($ids: JSON, $status: String, $result_status: String, $_or: JSON) {
    eventReceviers(
      where: {
        eventId_in: $ids
        status: $status
        result_status: $result_status
        _or: $_or
        eventEmailId_null: false
      }
      sort: "id:desc"
    ) {
      id
      secret
      created_at
      eventId
      access
      mailType
      name
      ebook
      title
      bookCode
      joaraName
      writerId
      rewarod
      genre
      status
      result_status
      manager
    }
  }
`

export const eventReceiversStatusCount = gql`
  query eventReceviers($ids: JSON, $status: String, $mailType: String) {
    eventReceviers(
      where: { eventId_in: $ids, status: $status, mailType: $mailType }
      sort: "id:desc"
    ) {
      secret
      created_at
      eventId
      bookCode
    }
  }
`
export const eventEmailNoDel = gql`
  query eventEmails($id: ID) {
    eventEmails(where: { id: $id, eventId_null: false }) {
      id
      secret
      created_at
    }
  }
`
export const eventResultEmailNoDel = gql`
  query eventResultEmails($id: ID) {
    eventResultEmails(where: { id: $id, eventId_null: false }) {
      id
      secret
      created_at
    }
  }
`
