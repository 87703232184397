import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import TextField from "@mui/material/TextField"

function rejectDialog({
  openDialog,
  closeDialog,
  Active,
  CreBtnText,
  CanBtnText,
  AlertType,
  MainTitle,
  Content,
  CreBtnColor,
  requestExplainShowData,
  type,
}) {
  const [open, setOpen] = useState(openDialog)
  const [requestExplain, setrequestExplain] = useState("")
  useEffect(() => {
    setOpen(openDialog)
    setrequestExplain(requestExplainShowData)
  }, [openDialog])

  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const setrequestExplainDataExport = (e) => {
    requestExplainData(e)
    setrequestExplain(e)
  }
  const BtnControll = () => {
    if (AlertType !== "oneBtn") {
      return (
        <DialogActions sx={{ p: 0 }}>
          <SuiButton
            variant="text"
            color="text2"
            sx={{ mr: "auto", height: 40, pl: 0, minWidth: 48 }}
            onClick={() => handleClose()}
          >
            {CanBtnText}
          </SuiButton>
          <SuiButton color={CreBtnColor} onClick={() => Active()}>
            {CreBtnText}
          </SuiButton>
        </DialogActions>
      )
    } else {
      return (
        <DialogActions>
          <SuiButton color="info2" sx={{ width: 93, height: 40 }} onClick={() => handleClose()}>
            {CanBtnText}
          </SuiButton>
        </DialogActions>
      )
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" className="alert-dialog-layout-levelup">
      <SuiBox sx={{ p: 4 }}>
        <SuiBox sx={{ textAlign: "center" }}>
          <SuiTypography fontSize="20px" fontWeight="bold" color="dark">
            {MainTitle}
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ my: 3 }}>
          <SuiTypography
            fontSize="18px"
            fontWeight="medium"
            color="subTitle"
            sx={{ textAlign: "center" }}
          >
            <span dangerouslySetInnerHTML={{ __html: Content }}></span>
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ my: 1 }}>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="info"
            sx={{ textAlign: "left", pt: 2 }}
          >
            반려 사유 :
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="center"
          sx={{
            mb: 18,
            width: 320,
          }}
        >
          <TextField
            disabled={type !== "create"}
            multiline
            rows={4}
            sx={{ width: "100%" }}
            className="Fileupload-textArea"
            variant="outlined"
            placeholder="300자 이내"
            value={requestExplain}
            onChange={(e) => {
              if (e.target.value.length <= 300) setrequestExplainDataExport(e.target.value)
            }}
          />
        </SuiBox>
        {BtnControll()}
      </SuiBox>
    </Dialog>
  )
}

rejectDialog.defaultProps = {
  openDialog: false,
  AlertType: "oneBtn",
  CanBtnText: "확인",
  CreBtnText: "저장",
  CreBtnColor: "primary",
  MainTitle: "제목을 입력해주세요.",
  Content: "내용을 입력해주세요.",
  type: "create",
  requestExplainShowData: "",
}

rejectDialog.propTypes = {
  openDialog: PropTypes.bool,
  MainTitle: PropTypes.string,
  Content: PropTypes.string,
  AlertType: PropTypes.string,
  CanBtnText: PropTypes.string,
  CreBtnText: PropTypes.string,
  CreBtnColor: PropTypes.string,
  closeDialog: PropTypes.func,
  Active: PropTypes.func,
  requestExplainData: PropTypes.func,
  type: PropTypes.string,
  requestExplainShowData: PropTypes.string,
}

export default rejectDialog
