// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// @mui material components
import Collapse from "@mui/material/Collapse"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Icon from "@mui/material/Icon"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIconBox2,
  collapseIcon,
  collapseText,
  collapseTextIcon,
} from "examples/Sidenav/styles/sidenavCollapse"

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context"
import { bgcolor } from "@mui/system"

function SidenavCollapse({
  color,
  icon,
  arrow,
  name,
  children,
  active,
  noCollapse,
  open,
  ...rest
}) {
  const [controller] = useSoftUIController()
  const { miniSidenav, transparentSidenav } = controller

  return (
    <>
      <ListItem component="li">
        <SuiBox
          className={miniSidenav ? "leftNav-bt-mini" : "leftNav-bt"}
          {...rest}
          sx={(theme) => collapseItem(theme, { active, transparentSidenav })}
        >
          <SuiBox
            className="leftNav-decobox"
            bgColor="white"
            width="6px"
            height="40px"
            borderRadius="lg"
            shadow="md"
          />
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
            // sx={{ bgcolor: "transparent" }}
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />
          <ListItemIcon
            className="leftNav-arrow"
            style={miniSidenav ? { display: "none" } : {}}
            sx={(theme) => collapseIconBox2(theme, { active, transparentSidenav, color })}
            // sx={{ bgcolor: "transparent" }}
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              arrow
            )}
          </ListItemIcon>
        </SuiBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  )
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node,
  arrow: PropTypes.node,
  name: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
}

export default SidenavCollapse
