import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"

import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

import DeleteDialog from "../deleteDialog"
import ContractdeleteListDialog from "../ContractdeleteListDialog"

// Dialog
import WriterDialog from "../../../writer"
import DistributorDialog from "../../../distributor"
import OutsourcingDialog from "../../../outsourcing"

// API
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  updateRequest,
  updateCompany,
  updateWriter,
  updateContract,
  updateOutsourcing,
  updateContractDocument,
  updateCompanyContract,
  updateoutsourcingContract,
  createAlert,
} from "apollo/mutation"
import { requests, writer, company, outsourcing, user } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function deleteData_card({ data }) {
  const dispatch = useDispatch()
  const [openAccess_Dialog, setopenAccess_Dialog] = useState(false)
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [distributorDialog, setdistributorDialog] = useState(false)
  const [writerDialog, setwriterDialog] = useState(false)
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [typeDistributor, settypeDistributor] = useState("detail")
  const [outsourcingDialog, setoutsourcingDialog] = useState(false)
  const [editId, seteditId] = useState(null)
  const [type, settype] = useState("detail")
  const [deleteDatas, setdeleteDatas] = useState({})
  const { myData } = useSelector((state) => state.store)
  const [openContract_deleteDialog, setopenContract_deleteDialog] = useState(false)
  const closeDialog = () => {
    setdistributorDialog(false)
    setwriterDialog(false)
    setoutsourcingDialog(false)
  }
  const [updateContractActive] = useMutation(updateContract, {
    onError(err) {
      alert("계약 삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [writerActive] = useLazyQuery(writer, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [companyActive] = useLazyQuery(company, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [outsourcingActive] = useLazyQuery(outsourcing, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateWriterActive] = useMutation(updateWriter, {
    onError(err) {
      alert("작가 삭제시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [updateCompanyActive] = useMutation(updateCompany, {
    onError(err) {
      alert("유통사 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    onError(err) {
      alert("유통사 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [updateContractDocumentActive] = useMutation(updateContractDocument, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyContractActive] = useMutation(updateCompanyContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateOutsourcingContractActive] = useMutation(updateoutsourcingContract, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [userActive] = useLazyQuery(user, {
    fetchPolicy: "network-only",
    onError() {
      alert("유저 리스트를 불러오는 동안 오류가 발생하였습니다.")
    },
  })
  const refuse_Delete = () => {
    if (data.arrayContent) {
      dispatch(loading_Apply(true))
      if (data.arrayContent.writerId) {
        writerActive({
          variables: {
            id: data.arrayContent.writerId,
          },
        }).then((writerData) => {
          const editHistory = [...writerData.data.writer.history]
          data.arrayContent.contractData.forEach((contract_all) => {
            let historyContent =
              `<strong>${contract_all.title}</strong> 작품의 ` +
              "<strong>" +
              contract_all.contractPaper[0][0].selectData[1].value +
              "</strong> 삭제 신청을 거절하였습니다."
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-delete",
              contractId: contract_all.contract_id,
            })
          })

          updateWriterActive({
            variables: {
              id: data.arrayContent.writerId,
              history: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "작가 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      } else if (data.arrayContent.companyId) {
        companyActive({
          variables: {
            id: data.arrayContent.companyId,
          },
        }).then((companyData) => {
          const editHistory = [...companyData.data.company.history]
          data.arrayContent.contractData.forEach((contract_all) => {
            let historyContent =
              "<strong>" +
              contract_all.contractPaper[0][0].selectData[1].value +
              "</strong> 삭제 신청을 거절하였습니다."
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-delete",
              contractId: contract_all.id,
            })
          })

          updateCompanyActive({
            variables: {
              id: data.arrayContent.companyId,
              history: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "유통사 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      } else if (data.arrayContent.outsourcingId) {
        outsourcingActive({
          variables: {
            id: data.arrayContent.outsourcingId,
          },
        }).then((outsourcingData) => {
          const editHistory = [...outsourcingData.data.outsourcing.histroy]
          data.arrayContent.contractData.forEach((contract_all) => {
            let historyContent =
              "<strong>" +
              contract_all.contractPaper[0][0].selectData[0].value +
              "</strong> 삭제 신청을 거절하였습니다."
            editHistory.unshift({
              profile: myData.profile ? myData.profile.url : null,
              name: myData.name,
              position: myData.position,
              department: myData.department,
              content: historyContent,
              date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              type: "contract-delete",
              contractId: contract_all.id,
            })
          })
          updateOutsourcingActive({
            variables: {
              id: data.arrayContent.outsourcingId,
              histroy: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "외주계약 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      }
    } else {
      if (data.content.writerId) {
        writerActive({
          variables: {
            id: data.content.writerId,
          },
        }).then((writerData) => {
          let historyContent =
            `<strong>${data.content.contractTitle}</strong> 작품의 ` +
            "<strong>" +
            data.content.contractData[data.content.contractData.length - 1][0].selectData[1].value +
            "</strong> 삭제 신청을 거절하였습니다."
          const editHistory = [...writerData.data.writer.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: historyContent,
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
            type: "contract-delete",
            contractId: data.content.contractData[0][0].id,
          })

          updateWriterActive({
            variables: {
              id: data.content.writerId,
              history: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "작가 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      } else if (data.content.outsourcingId) {
        outsourcingActive({
          variables: {
            id: data.content.outsourcingId,
          },
        }).then((companyData) => {
          let historyContent =
            "<strong>" +
            data.content.contractData[data.content.contractData.length - 1][0].selectData[1].value +
            "</strong> 삭제 신청을 거절하였습니다."
          const editHistory = [...companyData.data.outsourcing.histroy]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: historyContent,
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
            type: "contract-delete",
            contractId: data.content.contractData[0][0].id,
          })

          updateOutsourcingActive({
            variables: {
              id: data.content.outsourcingId,
              history: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "외주 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      } else if (data.content.companyId) {
        companyActive({
          variables: {
            id: data.content.companyId,
          },
        }).then((companyData) => {
          let historyContent =
            "<strong>" +
            data.content.contractData[data.content.contractData.length - 1][0].selectData[0].value +
            "</strong> 삭제 신청을 거절하였습니다."
          const editHistory = [...companyData.data.company.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content: historyContent,
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
            type: "contract-delete",
            contractId: data.content.contractData[0][0].id,
          })

          updateCompanyActive({
            variables: {
              id: data.content.companyId,
              history: editHistory,
            },
          }).then(() => {
            updateRequestActive({
              variables: {
                id: data.id,
                status: "유통사 계약 삭제 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                declining: requestExplainData,
                decisionDate: new Date(),
              },
            }).then(() => {
              userActive({
                variables: {
                  id: data.content.id,
                },
              }).then(({ data }) => {
                if (data.user.nomalAlertSetting) {
                  if (data.user.nomalAlertSetting[2].value) {
                    createAlertActive({
                      variables: {
                        users_permissions_user: String(data.user.id),
                        title: "삭제 신청 거절",
                        type: "change",
                        userid: String(data.user.id),
                        content: {
                          position: myData.position,
                          department: myData.department,
                          name: myData.name,
                          profile: myData.profile,
                        },
                      },
                    })
                  }
                }
              })
              document.getElementById("deleteList-reset").click()
              setDelete_Dialog(false)
              dispatch(loading_Apply(false))
            })
          })
        })
      }
    }
  }

  const access_Delete = () => {
    dispatch(loading_Apply(true))

    if (data.arrayContent) {
      // 마이페이지 - 계약 승인
      if (data.arrayContent.writerId) {
        data.arrayContent.contractData.forEach((contract, index) => {
          updateContractDocumentActive({
            variables: {
              id: contract.contract_id,
              contract: null,
              users_permissions_user: null,
            },
          }).then(() => {
            if (index === data.arrayContent.contractData.length - 1) {
              if (data.arrayContent.writerId) {
                writerActive({
                  variables: {
                    id: data.arrayContent.writerId,
                  },
                }).then((writerData) => {
                  const editHistory = [...writerData.data.writer.history]
                  data.arrayContent.contractData.forEach((contract_all) => {
                    let historyContent =
                      `<strong>${contract_all.title}</strong> 작품의 ` +
                      "<strong>" +
                      contract_all.contractPaper[0][0].selectData[1].value +
                      "</strong> 삭제 신청을 승인하였습니다."
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content: historyContent,
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "contract-delete",
                      contractId: contract_all.contract_id,
                    })
                  })

                  updateWriterActive({
                    variables: {
                      id: data.arrayContent.writerId,
                      history: editHistory,
                    },
                  }).then(() => {
                    updateRequestActive({
                      variables: {
                        id: data.id,
                        status: "작가 계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      },
                    }).then(() => {
                      document.getElementById("deleteList-reset").click()
                      setopenAccess_Dialog(false)
                      dispatch(loading_Apply(false))
                    })
                  })
                })
              }
            }
          })
        })
      } else if (data.arrayContent.companyId) {
        data.arrayContent.contractData.forEach((contract, index) => {
          updateCompanyContractActive({
            variables: {
              id: contract.id,
              company: null,
              users_permissions_user: null,
            },
          }).then(() => {
            if (index === data.arrayContent.contractData.length - 1) {
              if (data.arrayContent.companyId) {
                companyActive({
                  variables: {
                    id: data.arrayContent.companyId,
                  },
                }).then((companyData) => {
                  const editHistory = [...companyData.data.company.history]
                  data.arrayContent.contractData.forEach((contract_all) => {
                    let historyContent =
                      "<strong>" +
                      contract_all.contractPaper[0][0].selectData[1].value +
                      "</strong> 삭제 신청을 승인하였습니다."
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content: historyContent,
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "contract-delete",
                      contractId: contract_all.id,
                    })
                  })

                  updateCompanyActive({
                    variables: {
                      id: data.arrayContent.companyId,
                      history: editHistory,
                    },
                  }).then(() => {
                    updateRequestActive({
                      variables: {
                        id: data.id,
                        status:
                          "유통사 계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      },
                    }).then(() => {
                      document.getElementById("deleteList-reset").click()
                      setopenAccess_Dialog(false)
                      dispatch(loading_Apply(false))
                    })
                  })
                })
              }
            }
          })
        })
      } else if (data.arrayContent.outsourcingId) {
        data.arrayContent.contractData.forEach((contract, index) => {
          updateOutsourcingContractActive({
            variables: {
              id: contract.id,
              outsourcing: null,
              users_permissions_user: null,
            },
          }).then(() => {
            if (index === data.arrayContent.contractData.length - 1) {
              if (data.arrayContent.outsourcingId) {
                outsourcingActive({
                  variables: {
                    id: data.arrayContent.outsourcingId,
                  },
                }).then((outsourcingData) => {
                  const editHistory = [...outsourcingData.data.outsourcing.histroy]
                  data.arrayContent.contractData.forEach((contract_all) => {
                    let historyContent =
                      "<strong>" +
                      contract_all.contractPaper[0][0].selectData[0].value +
                      "</strong> 삭제 신청을 승인하였습니다."
                    editHistory.unshift({
                      profile: myData.profile ? myData.profile.url : null,
                      name: myData.name,
                      position: myData.position,
                      department: myData.department,
                      content: historyContent,
                      date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      type: "contract-delete",
                      contractId: contract_all.id,
                    })
                  })

                  updateOutsourcingActive({
                    variables: {
                      id: data.arrayContent.outsourcingId,
                      histroy: editHistory,
                    },
                  }).then(() => {
                    updateRequestActive({
                      variables: {
                        id: data.id,
                        status: "외주계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                      },
                    }).then(() => {
                      userActive({
                        variables: {
                          id: data.arrayContent.id,
                        },
                      }).then((datas) => {
                        if (datas.data.user.nomalAlertSetting) {
                          if (datas.data.user.nomalAlertSetting[2].value) {
                            createAlertActive({
                              variables: {
                                users_permissions_user: String(datas.data.user.id),
                                title: "계약삭제 신청 승인",
                                type: "change",
                                userid: String(datas.data.user.id),
                                content: {
                                  position: myData.position,
                                  department: myData.department,
                                  name: myData.name,
                                  profile: myData.profile,
                                  content: data.arrayContent,
                                  targetName: data.content.title,
                                  selectData:
                                    data.content.contractData[
                                      data.content.contractData.length - 1
                                    ][0].selectData[0].value,
                                },
                              },
                            })
                          }
                        }
                      })
                      document.getElementById("deleteList-reset").click()
                      setopenAccess_Dialog(false)
                      dispatch(loading_Apply(false))
                    })
                  })
                })
              }
            }
          })
        })
      }
    } else {
      // 기존 - 계약 승인
      if (data.content.writerId) {
        updateContractDocumentActive({
          variables: {
            id: data.content.contractData[0][0].id,
            contract: null,
            users_permissions_user: null,
            // meetings: [],
          },
        }).then(() => {
          if (data.content.writerId) {
            writerActive({
              variables: {
                id: data.content.writerId,
              },
            }).then((writerData) => {
              let historyContent =
                `<strong>${data.content.contractTitle}</strong> 작품의 ` +
                "<strong>" +
                data.content.contractData[data.content.contractData.length - 1][0].selectData[1]
                  .value +
                "</strong> 삭제 신청을 승인하였습니다."
              const editHistory = [...writerData.data.writer.history]
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-delete",
                contractId: data.content.contractData[0][0].id,
              })

              updateWriterActive({
                variables: {
                  id: data.content.writerId,
                  history: editHistory,
                },
              }).then(() => {
                updateRequestActive({
                  variables: {
                    id: data.id,
                    status: "작가 계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  },
                }).then(() => {
                  userActive({
                    variables: {
                      id: data.content.id,
                    },
                  }).then((datas) => {
                    if (datas.data.user.nomalAlertSetting) {
                      if (datas.data.user.nomalAlertSetting[2].value) {
                        createAlertActive({
                          variables: {
                            users_permissions_user: String(datas.data.user.id),
                            title: "계약삭제 신청 승인",
                            type: "change",
                            userid: String(datas.data.user.id),
                            content: {
                              position: myData.position,
                              department: myData.department,
                              name: myData.name,
                              profile: myData.profile,
                              content: data.content,
                              targetName: data.content.title,
                              selectData:
                                data.content.contractData[data.content.contractData.length - 1][0]
                                  .selectData[1].value,
                            },
                          },
                        })
                      }
                    }
                  })
                  document.getElementById("deleteList-reset").click()
                  setopenAccess_Dialog(false)
                  dispatch(loading_Apply(false))
                })
              })
            })
          }
        })
      } else if (data.content.companyId) {
        updateCompanyContractActive({
          variables: {
            id: data.content.contractData[0][0].id,
            company: null,
            users_permissions_user: null,
            // meetings: [],
          },
        }).then(() => {
          if (data.content.companyId) {
            companyActive({
              variables: {
                id: data.content.companyId,
              },
            }).then((companyData) => {
              let historyContent =
                "<strong>" +
                data.content.contractData[data.content.contractData.length - 1][0].selectData[0]
                  .value +
                "</strong> 삭제 신청을 승인하였습니다."
              const editHistory = [...companyData.data.company.history]
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-delete",
                contractId: data.content.contractData[0][0].id,
              })

              updateCompanyActive({
                variables: {
                  id: data.content.companyId,
                  history: editHistory,
                },
              }).then(() => {
                updateRequestActive({
                  variables: {
                    id: data.id,
                    status: "유통사 계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  },
                }).then(() => {
                  userActive({
                    variables: {
                      id: data.content.id,
                    },
                  }).then((datas) => {
                    if (datas.data.user.nomalAlertSetting) {
                      if (datas.data.user.nomalAlertSetting[2].value) {
                        createAlertActive({
                          variables: {
                            users_permissions_user: String(datas.data.user.id),
                            title: "계약삭제 신청 승인",
                            type: "change",
                            userid: String(datas.data.user.id),
                            content: {
                              position: myData.position,
                              department: myData.department,
                              name: myData.name,
                              profile: myData.profile,
                              content: data.content,
                              targetName: data.content.title,
                              selectData:
                                data.content.contractData[data.content.contractData.length - 1][0]
                                  .selectData[0].value,
                            },
                          },
                        })
                      }
                    }
                  })
                  document.getElementById("deleteList-reset").click()
                  setopenAccess_Dialog(false)
                  dispatch(loading_Apply(false))
                })
              })
            })
          }
        })
      } else if (data.content.outsourcingId) {
        updateOutsourcingContractActive({
          variables: {
            id: data.content.contractData[0][0].id,
            outsourcing: null,
            users_permissions_user: null,
            // meetings: [],
          },
        }).then(() => {
          if (data.content.outsourcingId) {
            outsourcingActive({
              variables: {
                id: data.content.outsourcingId,
              },
            }).then((companyData) => {
              let historyContent =
                "<strong>" +
                data.content.contractData[data.content.contractData.length - 1][0].selectData[0]
                  .value +
                "</strong> 삭제 신청을 승인하였습니다."
              const editHistory = [...companyData.data.outsourcing.histroy]
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                type: "contract-delete",
                contractId: data.content.contractData[0][0].id,
              })

              updateOutsourcingActive({
                variables: {
                  id: data.content.outsourcingId,
                  history: editHistory,
                },
              }).then(() => {
                updateRequestActive({
                  variables: {
                    id: data.id,
                    status: "유통사 계약 삭제 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
                  },
                }).then(() => {
                  userActive({
                    variables: {
                      id: data.content.id,
                    },
                  }).then((dataUser) => {
                    if (dataUser.data.user.nomalAlertSetting) {
                      if (dataUser.data.user.nomalAlertSetting[2].value) {
                        createAlertActive({
                          variables: {
                            users_permissions_user: String(dataUser.data.user.id),
                            title: "계약삭제 신청 승인",
                            type: "change",
                            userid: String(dataUser.data.user.id),
                            content: {
                              position: myData.position,
                              department: myData.department,
                              name: myData.name,
                              profile: myData.profile,
                              content: data.content,
                              targetName: data.content.title,
                              selectData:
                                data.content.contractData[data.content.contractData.length - 1][0]
                                  .selectData[0].value,
                            },
                          },
                        })
                      }
                    }
                  })
                  document.getElementById("deleteList-reset").click()
                  setopenAccess_Dialog(false)
                  dispatch(loading_Apply(false))
                })
              })
            })
          }
        })
      }
    }
  }
  const setopenAccess_Dialog_Btn = (requestId, type, id, status, dialog_type) => {
    setdeleteDatas({
      requestId: requestId,
      type: type,
      id: id,
      status: status,
    })
    setrequestExplainData("")
    if (dialog_type === "refuse") {
      setDelete_Dialog(true)
    } else {
      setopenAccess_Dialog(true)
    }
  }

  const close_deleteList = () => {
    setopenContract_deleteDialog(false)
  }
  const contract_count = () => {
    let deleteCount = ""
    let contractCount = 0
    if (data.arrayContent) {
      contractCount += data.arrayContent.contractData.length

      if (data.type === "Outsourcing") {
        if (contractCount > 1) {
          deleteCount = `${
            data.arrayContent.contractData[0].contractPaper[0][0].selectData[0].value
          } 외 ${contractCount - 1}`
        } else if (contractCount < 1) {
          deleteCount = ""
        } else {
          deleteCount = data.arrayContent.contractData[0].contractPaper[0][0].selectData[0].value
        }
      } else {
        if (contractCount > 1) {
          deleteCount = `${
            data.arrayContent.contractData[0].contractPaper[0][0].selectData[1].value
          } 외 ${contractCount - 1}`
        } else if (contractCount < 1) {
          deleteCount = ""
        } else {
          deleteCount = data.arrayContent.contractData[0].contractPaper[0][0].selectData[1].value
        }
      }
    } else {
      contractCount +=
        data.content.contractData.length + data.content.contractData[0][0].subContract.length
      if (data.type === "Writer") {
        if (contractCount > 1) {
          deleteCount = `${
            data.content.contractData[data.content.contractData.length - 1][0].selectData[1].value
          } 외 ${contractCount - 1}`
        } else {
          deleteCount =
            data.content.contractData[data.content.contractData.length - 1][0].selectData[1].value
        }
      } else {
        if (contractCount > 1) {
          deleteCount = `${
            data.content.contractData[data.content.contractData.length - 1][0].selectData[0].value
          } 외 ${contractCount - 1}`
        } else {
          deleteCount =
            data.content.contractData[data.content.contractData.length - 1][0].selectData[0].value
        }
      }
    }
    return deleteCount
  }

  return (
    <Card sx={{ mt: "16px", boxShadow: 0 }}>
      <SuiBox p={5} display="flex" justifyContent="start">
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
          계약서 삭제 신청_{dayjs(data.created_at).format("YY.MM.DD HH:mm")}
        </SuiTypography>
        {data.declining ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="error" sx={{ ml: "auto" }}>
            승인거절_{dayjs(data.decisionDate).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : data.status.indexOf("취소") > -1 ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="secondary" sx={{ ml: "auto" }}>
            회원취소_{dayjs(data.decisionDate).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : data.status.indexOf("승인") > -1 ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="info2" sx={{ ml: "auto" }}>
            신청승인_{dayjs(data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : null}
      </SuiBox>
      <Card sx={{ p: 5, mx: 5, border: 1, borderColor: "#CDD2D3", boxShadow: 0 }}>
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
          신청자 정보
        </SuiTypography>
        <SuiBox display="flex" justifyContent="start" sx={{ mt: 4 }}>
          <SuiBox sx={{ width: "50%", mr: 1 }}>
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                  신청자
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {data.content.department} {data.content.name} {data.content.position}
                </SuiTypography>
              </SuiBox>
            </Card>
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                  신청일
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {dayjs(data.created_at).format("YY.MM.DD")}
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "50%",
              ml: 1,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 136 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                요청
                <br />
                사유
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ mr: "auto", color: "#535353" }}
              >
                {data.content.requestExplain}
              </SuiTypography>
            </SuiBox>
          </Card>
        </SuiBox>
        <SuiTypography
          fontSize="18px"
          fontWeight="bold"
          color="dark"
          sx={{ textAlign: "left", my: 4 }}
        >
          데이터 정보
        </SuiTypography>
        <SuiBox display="flex" justifyContent="start">
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "25%",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 0, color: "#535353" }}>
                유형
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 1.5 }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ mx: "auto", color: "#535353" }}
              >
                {
                  { Writer: "작가 계약", Company: "유통사 계약", Outsourcing: "외주계약 계약" }[
                    data.type
                  ]
                }
              </SuiTypography>
            </SuiBox>
          </Card>
          <Card
            sx={{
              ml: 2,
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "33%",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 0, color: "#535353" }}>
                {{ Writer: "작가명", Company: "유통사명", Outsourcing: "외주계약명" }[data.type]}
              </SuiTypography>
              <Divider
                orientation="vertical"
                sx={{ borderColor: "#EEEEEE", ml: 1.5, color: "#535353" }}
              />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{
                  mx: "auto",
                  color: "#535353",
                  // textDecoration: "underline",
                  // cursor: "pointer",
                  // color: "#0C65FF",
                }}
                // onClick={() => openDialog(data.requestId, data.type)}
              >
                {data.content.title}
              </SuiTypography>
            </SuiBox>
          </Card>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "45%",
              ml: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ ml: 0, color: "#535353", minWidth: "79px" }}
              >
                삭제신청 건
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 1.5 }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{
                  mx: "auto",
                  color: "#0C65FF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setopenContract_deleteDialog(true)
                }}
              >
                {contract_count()}
              </SuiTypography>
            </SuiBox>
          </Card>
        </SuiBox>
      </Card>
      <SuiBox p={5} display="flex" justifyContent="end">
        <SuiButton
          disabled={data.status.indexOf("심사중") === -1}
          color="error"
          onClick={() =>
            setopenAccess_Dialog_Btn(data.requestId, data.type, data.id, data.status, "refuse")
          }
          sx={{ width: 100, mr: 2, fontSize: 14 }}
        >
          거절
        </SuiButton>
        <SuiButton
          disabled={data.status.indexOf("심사중") === -1}
          color="primary"
          onClick={() =>
            setopenAccess_Dialog_Btn(data.requestId, data.type, data.id, data.status, "access")
          }
          sx={{ width: 100, fontSize: 14 }}
        >
          삭제승인
        </SuiButton>
      </SuiBox>
      <SuiDialog
        openDialog={openAccess_Dialog}
        closeDialog={() => setopenAccess_Dialog(false)}
        MainTitle={"삭제 신청 승인"}
        Content={"삭제 신청을 승인하시겠습니까?"}
        Active={() => access_Delete()}
        CanBtnText={"취소"}
        CreBtnText={"삭제승인"}
        AlertType={"twoBtn"}
        CreBtnColor={"primary"}
      />
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={"삭제 신청 거절"}
        Content={"거절 사유를 작성하고, 데이터 삭제 신청을 미승인합니다."}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"승인거절"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => refuse_Delete()}
        type={Delete_Dialog_type}
      />
      <WriterDialog
        open={writerDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={{ ebookname: data.content.title }}
      />
      <DistributorDialog
        open={distributorDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={{ title: data.content.title }}
      />
      <OutsourcingDialog
        open={outsourcingDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={{ nickname: data.content.title }}
      />
      <ContractdeleteListDialog
        openDialog={openContract_deleteDialog}
        closeDialog={close_deleteList}
        ContractDeleteData={data.content.contractData}
        writerName={data.content.title}
        requestTitle={data.content.title}
        contractTitle={data.content.contractTitle}
        datatype={data.type}
      />
    </Card>
  )
}
deleteData_card.defaultProps = {
  data: {},
}

deleteData_card.propTypes = {
  data: PropTypes.object,
}

export default deleteData_card
