import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useApolloClient } from "@apollo/client"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import SuiDialog from "components/SuiDialog"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { MdClose } from "react-icons/md"

import WriterDialog from "../../writer"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { writer_user_permission } from "apollo/query"

import { useSelector, useDispatch } from "react-redux"

function EventWorksDialog({ openDialog, workLists, closeDialog }) {
  // 작가 팝업 여는 데이터
  const [writerDialog, setwriterDialog] = useState(false)
  const [editId, seteditId] = useState(null)
  const [editData, seteditData] = useState({ writername: "" })
  const [type, settype] = useState("create")
  const { myData } = useSelector((state) => state.store)
  const [tabActive, settabActive] = useState(0)
  const client = useApolloClient()
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)

  const open_dialog = (work) => {
    // 실버등급은 내용보기시 본인작품이 아니면 열람 불가
    if (myData.role.name === "silver") {
      client
        .query({
          query: writer_user_permission,
          variables: {
            id: work.writerId,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let userData = res.data.writer.users
          let userId = userData.map((el) => el.id)
          if (userId.indexOf(myData.id) > -1) {
            // 작가 팝업 열기
            setwriterDialog(true)
            seteditId(work.writerId)
            let data = {
              writername: work.name,
              bookname: work.ebook,
            }

            seteditData(data)
            settype("detail")
            settabActive(0)
          } else {
            const InfoAlertSet1 = {
              dialog: true,
              title: "권한없음",
              content: "열람권한이 없습니다.",
              CanBtnText: "확인",
            }
            setInfoAlert(InfoAlertSet1)
          }
        })
    } else {
      // 작가 팝업 열기
      setwriterDialog(true)
      seteditId(work.writerId)
      let data = {
        writername: work.name,
        bookname: work.ebook,
      }

      seteditData(data)
      settype("detail")
      settabActive(0)
    }
  }

  const worksListsShow = () => {
    // 참여 작품 리스트 세팅
    let maped_workLists = workLists.map((element, idx) => {
      let ebook_text = element.ebook
      if (element.ebook && element.ebook.length > 3) {
        ebook_text = element.ebook.substring(0, 3) + "..."
      }
      let joaraName_text = element.joaraName
      if (element.joaraName && element.joaraName.length > 3) {
        joaraName_text = element.joaraName.substring(0, 3) + "..."
      }
      let title_text = element.title
      // if (element.title && element.title.length > 16) {
      //   title_text = element.title.substring(0, 16) + "..."
      // }
      return {
        ...element,
        ebook_text: ebook_text,
        joaraName_text: joaraName_text,
        title_text: title_text,
      }
    })

    return maped_workLists.map((work, idx) => (
      <SuiTypography
        key={idx}
        sx={{
          display: "flex",
          px: 2,
          height: "40px",
          alignItems: "center",
          borderTop: idx > 0 ? "1px solid rgba(224, 224, 224, 1)" : "",
        }}
      >
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
          {work.title_text.length > 16 ? (
            <Tooltip
              arrow={false}
              color="white"
              classes={{ popper: "tooltip-light" }}
              title={
                <SuiBox>
                  <SuiTypography
                    style={{
                      fontSize: "14px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    verticalAlign="middle"
                  >
                    {work.title_text}
                  </SuiTypography>
                </SuiBox>
              }
            >
              {work.title_text.slice(0, 16) + "..."}
            </Tooltip>
          ) : (
            work.title_text
          )}
        </span>
        <span style={{ fontSize: "14px", color: "#707070", marginLeft: "8px" }}>{`(P : ${
          work.joaraName_text ? work.joaraName_text : "-"
        } / E : ${work.ebook_text ? work.ebook_text : "-"})`}</span>
        <span
          style={{
            fontSize: "14px",
            color: "#0c65ff",
            fontWeight: "bold",
            marginLeft: "auto",
            display: "block",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            open_dialog(work)
          }}
        >
          내용보기
        </span>
      </SuiTypography>
    ))
  }
  return (
    <Dialog open={openDialog} onClose={closeDialog} maxWidth="lg">
      <SuiBox sx={{ padding: "40px 32px 32px", width: "480px" }}>
        {/* QA사항에서 삭제요청이 들어왔음으로 주석처리 - 성시헌 */}
        {/* <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          edge="start"
          color="inherit"
          onClick={() => closeDialog()}
          aria-label="close"
        >
          <MdClose />
        </IconButton> */}
        <SuiBox sx={{ textAlign: "center" }}>
          <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
            이벤트 참여 작품
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ textAlign: "center", mt: 2 }}>
          <SuiTypography fontSize="16px" sx={{ color: "#707070" }}>
            이벤트 참여 작품 목록을 확인합니다.
          </SuiTypography>
        </SuiBox>
        <SuiBox sx={{ mt: 5 }}>
          <SuiTypography fontSize="16px" sx={{ color: "#707070" }}>
            총 <span style={{ color: "#000000", fontWeight: "bold" }}>{workLists.length}</span>종
          </SuiTypography>
        </SuiBox>

        <SuiBox
          sx={{
            mt: 2,
            mb: 5,
            color: "#535353",
            backgroundColor: "#F7F9FB",
            border: "1px solid #F1F1F5",
            height: "250px",
            maxHeight: "250px",
            lineHeight: "60px",
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          {worksListsShow()}
        </SuiBox>

        <DialogActions sx={{ p: 0 }}>
          <SuiButton
            color="info2"
            sx={{
              ml: "auto",
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              width: 100,
              fontSize: 14,
            }}
            onClick={() => closeDialog()}
          >
            확인
          </SuiButton>
        </DialogActions>
      </SuiBox>

      {/* 작가 팝업 */}
      <WriterDialog
        open={writerDialog}
        closeDialog={() => setwriterDialog(false)}
        editId={editId}
        type={type}
        editData={editData}
        tabActive={tabActive}
      />
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
    </Dialog>
  )
}

EventWorksDialog.defaultProps = {
  openDialog: false,
  workLists: [],
}

EventWorksDialog.propTypes = {
  openDialog: PropTypes.bool,
  workLists: PropTypes.array,
  closeDialog: PropTypes.func,
}

export default EventWorksDialog
