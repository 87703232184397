import { useEffect, useState } from "react"

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// @mui material components
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import Icon from "@mui/material/Icon"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import AdminDialog from "layouts/admin"
import SettingDialog from "layouts/setting"
import NoticeDialog from "layouts/notice"

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse"
import SidenavCard from "examples/Sidenav/SidenavCard"
import { BiChevronLeft } from "react-icons/bi"

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot"
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav"

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context"
import { useSelector } from "react-redux"
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery"

import { useNavigate } from "react-router-dom"

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentSidenav } = controller
  const location = useLocation()
  const { pathname } = location
  const collapseName = pathname.split("/").slice(1)[0]
  const { myData } = useSelector((state) => state.store)
  const navigate = useNavigate()
  const goto = () => {
    navigate("/MyPage")
  }
  const closeSidenav_NotExtend = (key) => {
    if (collapseName === key && miniSidenav) {
      setMiniSidenav(dispatch, !miniSidenav)
      localStorage.setItem("miniSidenav", miniSidenav)
    }
  }
  const closeSidenav = (key) => {
    if (collapseName === key) {
      if (miniSidenav) {
        setMiniSidenav(dispatch, false)
      } else {
        setMiniSidenav(dispatch, true)
      }
      localStorage.setItem("miniSidenav", miniSidenav)
    }
  }
  const Item = ({ brandName }) => {
    return (
      <p>
        {brandName.split("\n").map((brand) => (
          <span key={brand}>
            <>
              {brand}
              <br />
            </>
          </span>
        ))}
      </p>
    )
  }
  const [adminDialog, setAdminDialog] = useState(false)
  const [settingDialog, setSettingDialog] = useState(false)
  const [noticeDialog, setNoticeDialog] = useState(false)

  const dialogsOpen = (key) => {
    if (key === "admin") {
      setAdminDialog(true)
    } else if (key === "setting") {
      setSettingDialog(true)
    } else if (key === "notice") {
      setNoticeDialog(true)
    }
  }
  const closeDialog = () => {
    setAdminDialog(false)
    setSettingDialog(false)
    setNoticeDialog(false)
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      let sideNavVal = false
      if (localStorage.getItem("miniSidenav")) {
        sideNavVal = localStorage.getItem("miniSidenav") === "false" ? true : false
      }
      setMiniSidenav(dispatch, sideNavVal)
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav)
  }, [dispatch, location])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const routes1 = routes.slice(3, 8)
  let routes2 = routes.slice(8, 11)
  if (myData.role.name !== "Authenticated") {
    routes2.splice(0, 1)
  }
  const arrow = routes1[0].arrow

  const renderRoutes = routes1.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue
    if (type === "collapse") {
      returnValue =
        name === "설정" || name === "도움말" ? (
          <SuiBox pt={2} my={2} mx={0} mt={20}>
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                color={color}
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          </SuiBox>
        ) : href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          // here to make some move
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={!miniSidenav ? name : ""}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
              arrow={
                !miniSidenav ? (
                  key === collapseName ? (
                    <BiChevronLeft size="25px" onClick={() => closeSidenav(key)} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              }
              onClick={() => closeSidenav_NotExtend(key)}
            />
          </NavLink>
        )
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SuiTypography>
      )
    } else if (type === "divider") {
      returnValue = <Divider key={key} />
    }

    return returnValue
  })
  const renderRoutes1 = routes2.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <Link key={key} onClick={() => dialogsOpen(key)}>
          <SidenavCollapse
            color={color}
            key={key}
            name={!miniSidenav ? name : ""}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      )
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SuiTypography>
      )
    } else if (type === "divider") {
      returnValue = <Divider key={key} />
    }

    return returnValue
  })

  return (
    <SidenavRoot
      className={miniSidenav ? "leftNav-bg-mini" : "leftNav-bg"}
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SuiBox pt={5} pb={5} px={miniSidenav ? 5 : 6} textAlign="center">
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => goto()}
        >
          {brand && !miniSidenav ? (
            <SuiBox mr={2} component="img" src={brand} alt="Soft UI Logo" width="1.5rem" />
          ) : (
            <SuiBox mr={2} component="img" src={brand} alt="Soft UI Logo" width="1.5rem" />
          )}
          <SuiBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SuiTypography
              sx={{ fontSize: 16, color: "#5C38FF", lineHeight: 1 }}
              variant="button"
              fontWeight="bold"
            >
              <Item brandName={brandName} />
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <List>{renderRoutes}</List>
      <SuiBox pt={2} my={2} mx={0} mt="auto">
        <AdminDialog open={adminDialog} closeDialog={closeDialog} />
        <SettingDialog open={settingDialog} closeDialog={closeDialog} />
        <NoticeDialog open={noticeDialog} closeDialog={closeDialog} />

        <List>{renderRoutes1}</List>
      </SuiBox>
    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
}

export default Sidenav
