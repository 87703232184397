import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"

import { MdClose } from "react-icons/md"
import { prototype } from "apollo-upload-client/public/ReactNativeFile"

function ContractDeleteListDialog({
  openDialog,
  closeDialog,
  ContractDeleteData,
  requestTitle,
  contractTitle,
  datatype,
}) {
  const [open, setOpen] = useState(openDialog)
  const [delete_count, setdelete_count] = useState("")
  useEffect(() => {
    setOpen(openDialog)
    if (ContractDeleteData.length > 0) {
      let contractCount = 0
      if (ContractDeleteData[0].contractPaper) {
        contractCount += ContractDeleteData.length
      } else {
        contractCount += ContractDeleteData.length + ContractDeleteData[0][0].subContract.length
      }

      setdelete_count(contractCount + "건")
    }
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const stepActive = () => {
    closeDialog()
    setOpen(false)
  }

  const contract_list = () => {
    let contractList = []
    ContractDeleteData.forEach((element, index) => {
      if (element.contractPaper) {
        let type

        if (datatype !== "Outsourcing") {
          type = element.contractPaper[0][0].selectData[1].value
        } else {
          type = element.contractPaper[0][0].selectData[0].value
        }

        contractList.push({
          type: type,
          contractDate: element.contractPaper[0][1].created_at,
          title: element.title,
        })
      } else {
        let type
        if (element[0].ContractType) {
          type = element[0].ContractType
        } else {
          if (datatype === "Writer") {
            type = element[0].selectData[1].value
          } else {
            type = element[0].selectData[0].value
          }
        }

        contractList.push({ type: type, contractDate: element[1].created_at })

        if (index === 0) {
          if (element[0].subContract.length > 0) {
            element[0].subContract.forEach((subContract) => {
              subContract[3].created_at
              contractList.push({ type: "부속 계약", contractDate: subContract[3].created_at })
            })
          }
        }
      }
    })
    contractList.sort((a, b) => {
      var dateA = new Date(a.contractDate).getTime()
      var dateB = new Date(b.contractDate).getTime()
      return dateA < dateB ? 1 : -1
    })

    const list = contractList.map((list, index) => {
      return (
        <SuiBox
          key={index}
          display="flex"
          alignItems="center"
          fontSize="16px"
          fontWeight="medium"
          sx={{ color: "#6B6B6B" }}
        >
          <span style={{ minWidth: "fit-content" }}>
            {`${list.title ? list.title : requestTitle}`}{" "}
            {datatype === "Writer" ? " 작가_" : " 社 "}
          </span>
          {datatype === "Writer" ? (
            <Tooltip
              arrow={false}
              color="white"
              classes={{ popper: "tooltip-light" }}
              title={
                <SuiTypography
                  style={{
                    fontSize: "14px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  verticalAlign="middle"
                >
                  <span className="first-contract">{`${
                    list.title ? list.title : contractTitle
                  }`}</span>
                </SuiTypography>
              }
            >
              <span className="first-contract">{`${list.title ? list.title : contractTitle}`}</span>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip
            arrow={false}
            color="white"
            classes={{ popper: "tooltip-light" }}
            title={
              <SuiTypography
                style={{
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "bold",
                }}
                verticalAlign="middle"
              >
                <span className="first-contract">{list.type}</span>
              </SuiTypography>
            }
          >
            <span className="first-contract">{`(${list.type})`}</span>
          </Tooltip>
          <span style={{ minWidth: "fit-content", color: "#9AA0A6", marginLeft: "8px" }}>
            {dayjs(list.contractDate).format("YYYY.MM.DD")}
          </span>
        </SuiBox>
      )
    })
    return list
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                삭제 신청 계약 확인
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                삭제 신청 계약을 확인합니다.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, mt: 5, maxWidth: "-webkit-fill-available" }}>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              삭제 신청 건:
            </SuiTypography>
          </SuiBox>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              mb: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                color="text2"
                sx={{ ml: "auto", mr: "auto" }}
              >
                {delete_count}
              </SuiTypography>
            </SuiBox>
          </Card>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              계약 정보 :
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <Card
              sx={{
                px: 2,
                py: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
                width: "100%",
                height: "138px",
                overflowY: "scroll",
              }}
            >
              {contract_list()}
            </Card>
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              color="info2"
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
              onClick={() => handleClose()}
            >
              확인
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ContractDeleteListDialog.defaultProps = {
  openDialog: false,
}

ContractDeleteListDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  ContractDeleteData: PropTypes.array,
  requestTitle: PropTypes.string,
  contractTitle: PropTypes.string,
  datatype: PropTypes.string,
}
export default ContractDeleteListDialog
