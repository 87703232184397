import { forwardRef } from "react"

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography"

// Custom styles for SuiProgress
import SuiProgressRoot from "components/SuiProgress/SuiProgressRoot"

// eslint-disable-next-line react/display-name
const SuiProgress = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
  <>
    {label && (
      <SuiTypography variant="button" fontWeight="medium" color="text">
        {value}%
      </SuiTypography>
    )}
    <SuiProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      ownerState={{ color, value, variant }}
    />
  </>
))

// Setting default values for the props of SuiProgress
SuiProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
}

// Typechecking props for the SuiProgress
SuiProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
}

export default SuiProgress
