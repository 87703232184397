import { useState, useEffect } from "react"

// react-router-dom components
import { useNavigate } from "react-router-dom"

import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout"

import { useMutation } from "@apollo/client"
import { createSendEmail } from "apollo/mutation"

// ICON
import { MdKeyboardArrowLeft } from "react-icons/md"

const ResetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [email_error, setEmail_error] = useState(false)
  const [email_error_message, setEmail_error_message] = useState("")

  const [openDialog, setOpenDialog] = useState(false)
  const [EmailSendActive] = useMutation(createSendEmail, {
    variables: {
      usermail: email,
      type: "1",
    },
    onError() {
      setEmail("")
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {
      setOpenDialog(true)
    },
  })
  const closeDialog = () => {
    setOpenDialog(false)
  }
  const handleemail = (e) => {
    setEmail(e.target.value)
  }
  const gotoLogin = () => {
    navigate("/")
  }

  const emailCheck = () => {
    setEmail_error(false)
    setEmail_error_message("")
    const emailSet =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (email == "") {
      setEmail_error(true)
      return setEmail_error_message("*이메일을 입력해주세요.")
    } else if (!emailSet.test(email)) {
      setEmail_error(true)
      return setEmail_error_message("*올바른 이메일을 형식이 아닙니다.")
    } else {
      setEmail("")
      EmailSendActive()
    }
  }
  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      p={3}
      path="/reset-password"
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={5}>
          <SuiBox display="flex" justifyContent="center" mb={2}>
            <SuiTypography
              component="label"
              color="dark"
              fontWeight="bold"
              className="letter-0-8px"
            >
              비밀번호 재설정
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center">
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
              className="letter-0-8px"
            >
              가입하신 이메일 주소로 비밀번호 재설정 메일을 보내드립니다.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox>
          <SuiBox ml={0.5}>
            <SuiTypography
              component="label"
              variant="caption"
              color="subTitle"
              fontWeight="bold"
              className="letter-0-8px"
              sx={{
                fontSize: 14,
              }}
            >
              이메일 :
            </SuiTypography>
          </SuiBox>
          <SuiInput
            error={email_error}
            helperText={email_error_message}
            type="email"
            placeholder=""
            value={email}
            onChange={handleemail}
          />
        </SuiBox>
        <SuiBox mt={3.5} mb={1}>
          <SuiButton
            color="primary"
            fullWidth
            sx={{
              fontSize: 16,
            }}
            onClick={() => emailCheck()}
          >
            이메일 보내기
          </SuiButton>
        </SuiBox>
        <SuiBox className="back-btn-layout">
          <SuiButton
            color="subTitle"
            variant="text"
            onClick={() => gotoLogin()}
            className="back-btn"
            sx={{ justifyContent: "left", fontSize: 16 }}
          >
            뒤로
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        MainTitle={"비밀번호 재설정 메일 전송"}
        Content={
          "가입하신 이메일 주소로 비밀번호 재설정 메일을 전송했습니다. 메일의 링크를 클릭하고 새 비밀번호를 설정하세요."
        }
        CanBtnText={"확인"}
        ActiveOneBtn={true}
        Active={() => gotoLogin()}
      />
    </CoverLayout>
  )
}

export default ResetPassword
