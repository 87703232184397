import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { useApolloClient } from "@apollo/client"

import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import { visuallyHidden } from "@mui/utils"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import Avatar from "@mui/material/Avatar"
import InputLabel from "@mui/material/InputLabel"
import Tooltip from "@mui/material/Tooltip"

import dayjs from "dayjs"
import {
  AiFillCaretDown,
  AiOutlineCheck,
  AiFillCheckCircle,
  AiOutlineCheckCircle,
  AiOutlineConsoleSql,
} from "react-icons/ai"
import { BiRecycle, BiTrash } from "react-icons/bi"
import { CgArrowsExchange } from "react-icons/cg"
import { MdClose } from "react-icons/md"
import IconButton from "@mui/material/IconButton"
import { BsFillCircleFill } from "react-icons/bs"

import bullseye from "assets/images/small-logos/bullseye.svg"

// Dialog
// 다이어로그
import WriterDialog from "../writer"
import DistributorDialog from "../distributor"
import OutsourcingDialog from "../outsourcing"
import DeleteDialog from "./deleteDialog"
import DeleteDenyDialog from "./DeleteDenyDialog"
import ChangeDialog from "./ChangeDialog"
import ChangeDenyDialog from "./ChangeDenyDialog"
import NoticeDetailDialog from "../notice/components/NoticeDetailDialog"
import EventWorksDialog from "../eventsManagement/items/eventWorksDialog"
import Event from "../eventsManagement/items/event"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  me_mypage,
  me_id,
  me_Request,
  users,
  myContractsList,
  notices,
  users_list,
  events,
  eventReceiversStatus,
} from "apollo/query"
import {
  updateCompany,
  updateWriter,
  createAlert,
  createRequest,
  updateRequest,
  updateContract,
  updateOutsourcing,
  updateEvent,
  createSendEmail,
} from "apollo/mutation"
import axios from "axios"

import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../reducers/store"
import { TrafficRounded } from "@mui/icons-material"

function createData(
  id,
  flatform,
  status,
  start,
  end,
  title,
  serial,
  type,
  reward,
  newOne,
  worksList,
  deleteStatus,
  history
) {
  return {
    id,
    flatform,
    status,
    start,
    end,
    title,
    serial,
    type,
    reward,
    newOne,
    worksList,
    deleteStatus,
    history,
  }
}

function EnhancedTableHead(props) {
  const { headCelldata, onSelectAllClick, order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCelldata[0].id !== "category" ? (
          <TableCell
            padding="checkbox"
            style={{ width: "60px", textAlign: "center", borderRight: "1px solid #F1F1F5" }}
          >
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: 16,
                color: "#53535",
              }}
              onClick={() => onSelectAllClick()}
            >
              전체
            </span>
            {/* select Box */}
          </TableCell>
        ) : null}
        {headCelldata.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: 150,
              px: 1,
              fontSize: "14px",
              width: "auto",
            }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="table-head-sortLabel"
            >
              <span>{headCell.label}</span>

              {/* <Icondown staus={headCell}></Icondown> */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.defaultProps = {}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  headCelldata: PropTypes.array,
}
export default function MyPage() {
  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  const { myData } = useSelector((state) => state.store)
  const dispatch = useDispatch()

  const [myContractsListActive] = useLazyQuery(myContractsList, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [noticesActive] = useLazyQuery(notices, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })

  const [myRequestActive] = useLazyQuery(me_Request, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [myIdActive] = useLazyQuery(me_id, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [myEventActive] = useLazyQuery(events, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [eventReceiversStatusActive] = useLazyQuery(eventReceiversStatus, {
    fetchPolicy: "network-only",
  })
  const [myPageActive] = useLazyQuery(me_mypage, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateWriterActive] = useMutation(updateWriter, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateEventActive] = useMutation(updateEvent, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateCompanyActive] = useMutation(updateCompany, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateContractActive] = useMutation(updateContract, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("계약작품 수정시 오류 발생")
    },
  })
  const [EmailSendActive] = useMutation(createSendEmail, {
    onError() {
      alert("이메일 전송이 실패하였습니다. 다시 시도해 주세요.")
    },
    onCompleted: () => {},
  })
  useEffect(() => {
    dispatch(loading_Apply(true))
    my_contract_list_call()
    notices_call()
    dispatch(loading_Apply(false))
  }, [])
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)
  const [event_reject, set_event_reject] = useState(false)
  const [edit_event_id, set_edit_event_id] = useState(null)
  const [type_event, set_type_event] = useState("create")
  const [event_dialog, set_event_dialog] = useState(false)
  const [workDialog, setWorkDialog] = useState({ dialog: false, workLists: [] })
  const client = useApolloClient()
  const openWorksListsDialog = (workLists) => {
    setWorkDialog({ dialog: true, workLists: workLists })
  }
  const closeWorksListsDialog = () => {
    setWorkDialog({ dialog: false, workLists: [] })
  }
  const handleDetail = (data) => {
    let event_detail_date
    myEventActive({ variables: { id: data.id } }).then((res) => {
      event_detail_date = res.data.events[0]
    })
    if (myData.role.name === "bronze") {
      const InfoAlertSet1 = {
        dialog: true,
        title: "권한없음",
        content: "열람권한이 없습니다.",
        CanBtnText: "확인",
      }
      setInfoAlert(InfoAlertSet1)
    } else {
      set_edit_event_id(data.id)
      set_type_event("detail")
      set_event_dialog(true)
    }
  }
  const getmyContractList = async () => {
    let my_id
    await myIdActive().then(async (res) => {
      my_id = res.data.me.id
      await client
        .query({
          query: events,
          variables: {
            users_permissions_user: my_id,
            state_ne: "delete",
          },
          fetchPolicy: "network-only",
        })
        .then(async (res_event) => {
          // myEventActive({ variables: { users_permissions_user: my_id, state_ne: "delete" } }).then(
          //   (res_event) => {
          let events_data = []
          events_data = res_event.data.events
          if (events_data.length > 0) {
            let rowsData = []
            let receivers = []
            let events_ids = events_data.map((el) => el.id)
            for (let i = 0; i < i + 1; i++) {
              let id_list = events_ids.slice(i * 50, (i + 1) * 50)
              if (id_list.length === 0) {
                break
              }
              let receiver_variables = {
                ids: id_list,
                _or: [
                  {
                    status: "완료",
                    access: "access",
                  },
                  {
                    status: "미발송",
                    access: "access",
                  },
                  { mailType: "result", result_status_ne: "" },
                ],
              }

              await client
                .query({
                  query: eventReceiversStatus,
                  variables: receiver_variables,
                  fetchPolicy: "network-only",
                })
                .then((res) => {
                  receivers = receivers.concat(res.data.eventReceviers)
                })
            }

            if (events_data) {
              events_data.forEach((el) => {
                let event_status
                if (el.status === "ing") {
                  event_status = "진행중"
                } else if (el.status === "expected") {
                  event_status = "예정"
                } else if (el.status === "cancel") {
                  event_status = "취소"
                } else if (el.status === "complete") {
                  event_status = "완료"
                } else {
                  event_status = "-"
                }
                let main_new
                if (el.newOne === "해당") {
                  main_new = "O"
                } else if (el.newOne === "해당 없음") {
                  main_new = "X"
                }
                let result_receiver = receivers.filter(
                  // 결과보고 발송 총 건수
                  (receiver) =>
                    receiver.eventId === el.id &&
                    (receiver.mailType === "result" ||
                      (receiver.mailType === "info" && receiver.access === "access"))
                )
                let events_works_lists = result_receiver.filter(
                  (v, i, a) => a.findIndex((v2) => v2.bookCode === v.bookCode) === i
                )
                rowsData.push(
                  createData(
                    el.id,
                    el.flatform,
                    event_status,
                    el.start,
                    el.end,
                    el.title,
                    el.serial,
                    el.type,
                    el.reward,
                    main_new,
                    events_works_lists,
                    el.requests,
                    ""
                  )
                )
              })
              setEvents_rows(rowsData)
              setEvents_searched_origin(rowsData)
            }
          }
        })
    })
  }
  const my_contract_list_call = async () => {
    dispatch(loading_Apply(true))
    let my_id
    await client
      .query({
        query: me_id,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        my_id = res.data.me.id
      })
    await client
      .query({
        query: myContractsList,
        fetchPolicy: "network-only",
      })
      .then(async (res) => {
        let list = JSON.parse(JSON.stringify(res.data.myContractsList.list))
        // 내가 등록한 작품 - start
        let filter_contract = []
        list.writerContract.forEach((el, index) => {
          if (el.contract && el.contract.writer) {
            let contract_type = {
              "프리미엄 계약": "프리미엄",
              "노블레스 계약": "노블레스",
              "노블레스 + 프리미엄 계약": "노블X프리",
              "전자책 계약": "전자책 계약",
              "전자책 계약 선인세용": "전자책 계약 선인세용",
              "웹툰화 계약": "웹툰화",
              "게임화 계약": "게임화",
              "영상화 계약": "영상화",
              "제작작가 계약": "제작작가",
              "기타 2차 저작물 계약": "기타 2차저작물",
              "저작물 에이전트": "저작물 에이전트",
              "독점적 이용 허락서(전속)": "독점적 이용 허락서(전속)",
              "미분류 계약(기타)": "미분류 계약(기타)",
            }[el.contractPaper[0][0].selectData[1].value]

            let contract_status = el.contractPaper[0][3].value

            let today = dayjs(new Date()).format("YYYY-MM-DD")
            let contract_date = el.contractPaper[0][1].value2

            // let writer = list.writer.filter((writer) => writer.id === el.contract.writer)

            // if (writer.length > 0) {
            let contract_item = {
              ...el.contract,
              contract_id: el.id,
              // history: writer[0].history,

              contract_type: contract_type,
              contract_status: contract_status,
              cotract_date: dayjs(contract_date).format("YY.MM.DD"),
              subcontract_num:
                el.subContract && el.subContract.length > 0 ? el.subContract.length : "",

              selected: false,
              contractPaper: el.contractPaper,
            }
            filter_contract.push(contract_item)
            // }
          }
        })
        // 내가 등록한 작품 - end // 내가 등록한 작가 - start
        let writer_filterd = []
        list.writer
          .filter((x) => x.users[x.users.length - 1].id === my_id)
          .forEach((el) => {
            if (el.contracts && el.contracts.length > 0) {
              let contract_filter = el.contracts

              let contract_count = Array.from(
                new Set(contract_filter.map((item) => item.id))
              ).length
              let contract_all = []
              el.contracts.forEach((contract) => {
                contract.contract_document.forEach((document) => {
                  if (document.status === "공개") {
                    contract_all.push(document)
                  }
                })
              })
              let wrtier_item = {
                ...el,
                contract_num: contract_count,
                contract_all_num: contract_all.length,
                selected: false,
                contract_document: contract_all,
              }

              if (wrtier_item.birth && wrtier_item.birth.length > 14) {
                let birth = CryptoJS.AES.decrypt(wrtier_item.birth, secretKey)
                  .toString(CryptoJS.enc.Utf8)
                  .replace(/\"/g, "")
                  .split("-")[0]
                if (!isNaN(birth)) {
                  let year = birth.substring(0, 2)
                  let month = birth.substring(2, 4)
                  let day = birth.substring(4, 6)
                  wrtier_item.birth = `${year}.${month}.${day}`
                } else {
                  wrtier_item.birth = birth
                }
              }

              wrtier_item.phone =
                wrtier_item.phone && wrtier_item.phone.length > 14
                  ? CryptoJS.AES.decrypt(wrtier_item.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                      .split("-")[0]
                  : wrtier_item.phone

              wrtier_item.email =
                wrtier_item.email && wrtier_item.email.length > 40
                  ? CryptoJS.AES.decrypt(wrtier_item.email, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                      .split("-")[0]
                  : wrtier_item.email

              writer_filterd.push(wrtier_item)
            } else {
              let wrtier_item = {
                ...el,
                contract_num: 0,
                contract_all_num: 0,
                selected: false,
                contract_document: [],
              }

              if (wrtier_item.birth && wrtier_item.birth.length > 14) {
                let birth = CryptoJS.AES.decrypt(wrtier_item.birth, secretKey)
                  .toString(CryptoJS.enc.Utf8)
                  .replace(/\"/g, "")
                  .split("-")[0]
                if (!isNaN(birth)) {
                  let year = birth.substring(0, 2)
                  let month = birth.substring(2, 4)
                  let day = birth.substring(4, 6)
                  wrtier_item.birth = `${year}.${month}.${day}`
                } else {
                  wrtier_item.birth = birth
                }
              }

              wrtier_item.phone =
                wrtier_item.phone && wrtier_item.phone.length > 14
                  ? CryptoJS.AES.decrypt(wrtier_item.phone, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                      .split("-")[0]
                  : wrtier_item.phone

              wrtier_item.email =
                wrtier_item.email && wrtier_item.email.length > 40
                  ? CryptoJS.AES.decrypt(wrtier_item.email, secretKey)
                      .toString(CryptoJS.enc.Utf8)
                      .replace(/\"/g, "")
                      .split("-")[0]
                  : wrtier_item.email

              writer_filterd.push(wrtier_item)
            }
          })

        // 내가 등록한 작가 - end

        // 내가 등록한 유통사 - start
        for (let i = 0; i < list.company.length; i++) {
          let el = list.company[i]
          el["contract_document"] = el.company_contracts ? el.company_contracts : []
          if (el.company_contracts.length > 0) {
            // let my_contracts = el.company_contracts.filter(
            //   (contract) => contract.users_permissions_user === Number(my_id)
            // )
            // el.company_contracts = my_contracts
            el["ebook_distribution"] = 0
            el["ebook_special"] = 0
            el["outside"] = 0
            el["domestic_distribution"] = 0
            el["etc_ebook"] = 0
            el["etc_ip"] = 0
            el["first_contract"] = ""
            if (el.company_contracts.length > 0) {
              el.company_contracts.forEach((contract) => {
                let contract_type = contract.contractPaper[0][0].selectData[1].value
                if (contract_type === "이북 유통 계약") {
                  el.ebook_distribution++
                } else if (contract_type === "이북 특별 계약") {
                  el.ebook_special++
                } else if (contract_type === "(외부) IP 제작 계약") {
                  el.outside++
                } else if (contract_type === "(내부) IP 유통 계약") {
                  el.domestic_distribution++
                } else if (contract_type === "이북 미분류(기타)") {
                  el.etc_ebook++
                } else if (contract_type === "IP 미분류(기타)") {
                  el.etc_ip++
                }
              })
              let first_date =
                el.company_contracts[0].contractPaper[
                  el.company_contracts[0].contractPaper.length - 1
                ][1].value1
              let first_date_format = dayjs(first_date).format("YY.MM.DD")
              el["first_contract"] = first_date_format
            }
            el.companynumber =
              el.companynumber && el.companynumber.length > 12
                ? CryptoJS.AES.decrypt(el.companynumber, secretKey)
                    .toString(CryptoJS.enc.Utf8)
                    .replace(/\"/g, "")
                : el.companynumber

            el["ratio"] = el.settlement ? el.settlement : ""
          }
          list.company[i] = el
        }
        let company_filterd = list.company
        // let company_filterd = list.company.filter((el) => el.company_contracts.length > 0)
        company_filterd.forEach((el) => {
          el.ebook_distribution = el.ebook_distribution ? el.ebook_distribution : ""
          el.ebook_special = el.ebook_special ? el.ebook_special : ""
          el.outside = el.outside ? el.outside : ""
          el.domestic_distribution = el.domestic_distribution ? el.domestic_distribution : ""
          el.etc_ebook = el.etc_ebook ? el.etc_ebook : ""
          el.etc_ip = el.etc_ip ? el.etc_ip : ""
        })

        // 내가 등록한 유통사 - end

        // 내가 등록한 외주계약 - start

        let outsourcing_filterd = []

        list.outsourcing.forEach((el) => {
          // let contract_document = el.outsourcing_contracts.filter(
          //   (contract) => contract.users_permissions_user === Number(my_id)
          // )
          let type_list = { illustration: "일러스트", edit: "편집", etc: "기타" }
          if (type_list[el.type]) {
            el.type = type_list[el.type]
          }

          let outsourcing_item = {
            ...el,
            selected: false,
            work_num: el.outsourcing_histories.length,
            contract_document: el.outsourcing_contracts,
          }
          outsourcing_filterd.push(outsourcing_item)
        })
        setoutsourcing_rows(outsourcing_filterd)

        await getmyContractList()

        // 참조자로 등록된 목록 - start
        let referrer_filterd = []
        list.referrer.forEach((el) => {
          let referrer = {
            category: { Wirter: "작품 현황", Outsourcing: "외주계약 관리", Company: "유통사 관리" }[
              el.type
            ],
            where: "",
            where_detail: "",
            type: "",
            asignner: `${el.users_permissions_user.name} ${el.users_permissions_user.position}`,
            id: "",
            tabpanel: "",
            name: "",
          }
          if (el.type === "Wirter") {
            referrer.id = el.contract.writer
            referrer.where = el.contract.ebookname
            referrer.name = `${el.name} ${el.position}`
            referrer["ebookname"] = el.contract.ebookname
            referrer.where_detail = el.contract.title

            let contract_type = {
              "프리미엄 계약": "프리미엄",
              "노블레스 계약": "노블레스",
              "노블레스 + 프리미엄 계약": "노블X프리",
              "전자책 계약": "전자책 계약",
              "전자책 계약 선인세용": "전자책 계약 선인세용",
              "웹툰화 계약": "웹툰화",
              "게임화 계약": "게임화",
              "영상화 계약": "영상화",
              "제작작가 계약": "제작작가",
              "기타 2차 저작물 계약": "기타 2차저작물",
              "저작물 에이전트": "저작물 에이전트",
              "독점적 이용 허락서(전속)": "독점적 이용 허락서(전속)",
              "미분류 계약(기타)": "미분류 계약(기타)",
            }[el.contractPaper[0][0].selectData[1].value]

            referrer.type = contract_type
              ? contract_type
              : el.contractPaper[0][0].selectData[1].value

            referrer.tabpanel = "writer"
          } else if (el.type === "Outsourcing") {
            referrer.id = el.outsourcing.id
            referrer.where = el.outsourcing.nickname
            referrer["nickname"] = el.outsourcing.nickname
            referrer.name = `${el.name} ${el.position}`
            referrer.where_detail = "-"

            let contract_type = {
              "외주 일러스트 계약": "일러스트",
              "외주 편집 계약": "편집",
              "기타 계약": "기타",
            }[el.contractPaper[0][0].selectData[0].value]

            referrer.type = contract_type
              ? contract_type
              : el.contractPaper[0][0].selectData[0].value
            referrer.tabpanel = "outsourcing"
          } else if (el.type === "Company") {
            referrer.id = el.company.id
            referrer.where = el.company.name
            referrer.name = el.company.name
            referrer.where_detail = "-"

            let contract_type = {
              "이북 유통 계약": "이북 유통",
              "이북 특별 계약": "이북 특별",
              "(외부) IP 제작 계약": "(외부) IP 제작",
              "(내부) IP 유통 계약": "(내부) IP 유통",
              "이북 미분류(기타)": "이북 미분류",
              "IP 미분류(기타)": "IP 미분류",
            }[el.contractPaper[0][0].selectData[1].value]

            referrer.type = contract_type
              ? contract_type
              : el.contractPaper[0][0].selectData[1].value
            referrer.tabpanel = "distributor"
          }
          referrer_filterd.push(referrer)
        })
        // 참조자로 등록된 목록 - end

        // 내가 등록한 외주계약 - end

        filter_contract.sort((a, b) => {
          let titleA = a.title
          let titleB = b.title

          let endDateA = a.contractPaper[0][1].value2
          let endDateB = b.contractPaper[0][1].value2

          let expiredA =
            dayjs(a.contractPaper[0][1].value2).format("YYYY-MM-DD") <
            dayjs(new Date()).format("YYYY-MM-DD")
              ? 0
              : 1
          let expiredB =
            dayjs(b.contractPaper[0][1].value2).format("YYYY-MM-DD") <
            dayjs(new Date()).format("YYYY-MM-DD")
              ? 0
              : 1

          if (titleA < titleB) {
            return -1
          } else if (titleA > titleB) {
            return 1
          } else {
            if (expiredA > expiredB) {
              return -1 // regular case
            } else if (expiredA < expiredB) {
              return 1
            } else {
              if (endDateA < endDateB) {
                return -1
              } else if (endDateA > endDateB) {
                return 1
              } else {
                return 0
              }
            }
          }
        })
        writer_filterd.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })
        company_filterd.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })
        outsourcing_filterd.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })
        referrer_filterd.sort((a, b) => {
          var a = a.id
          var b = b.id
          return a < b ? 1 : -1
        })
        setcontract_rows(filter_contract)
        setwriter_rows(writer_filterd)
        setcompany_rows(company_filterd)
        setoutsourcing_rows(outsourcing_filterd)
        setreferer_rows(referrer_filterd)

        let data = { ...my_page_data_all }
        data["contract_rows"] = filter_contract
        data["writer_rows"] = writer_filterd
        data["company_rows"] = company_filterd
        data["outsourcing_rows"] = outsourcing_filterd
        data["events_rows"] = events_rows
        data["referer_rows"] = referrer_filterd

        await myRequestActive()
          .then((res) => {
            let data_request = res.data.me

            data["change_requests"] = data_request.change_requests
            data["requests"] = data_request.requests
          })
          .catch((err) => {
            dispatch(loading_Apply(false))
          })
        setmy_page_data_all(data)
        dispatch(loading_Apply(false))
      })
      .catch((err) => {
        dispatch(loading_Apply(false))
      })
  }

  const notices_call = () => {
    noticesActive({ variables: { show: true } }).then((res) => {
      let data = res.data.notices
      data = [...data].sort((a, b) => {
        var dateA = new Date(a.created_at).getTime()
        var dateB = new Date(b.created_at).getTime()
        return dateA < dateB ? 1 : -1
      })
      setnotices_data(data)
    })
  }
  const [notices_data, setnotices_data] = useState([])
  const [usersDatas, setusersDatas] = useState([])
  const [my_page_data_all, setmy_page_data_all] = useState({
    department: "",
    position: "",
    name: "",
    role: { name: "" },
    change_requests: [],
    requests: [],
    contract_rows: [],
    writer_rows: [],
    company_rows: [],
    outsourcing_rows: [],
    events_rows: [],
  })

  const role_return = () => {
    let role_name = {
      Authenticated: "슈퍼어드민",
      authenticated: "슈퍼어드민",
      Gold: "골드",
      gold: "골드",
      Silver: "실버",
      silver: "실버",
      Bronze: "브론즈",
      bronze: "브론즈",
    }[myData.role.name]
    if (role_name) {
      return role_name
    } else {
      return ""
    }
  }
  // table_setting - start

  // headers -start

  const writer_headCells = [
    {
      id: "ebookname",
      numeric: false,
      disablePadding: false,
      label: "이북 필명",
    },

    {
      id: "joaraname",
      numeric: false,
      disablePadding: false,
      label: "조아라 필명",
    },
    {
      id: "userid",
      numeric: false,
      disablePadding: false,
      label: "회원ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "birth",
      numeric: false,
      disablePadding: false,
      label: "생년월일",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "연락처",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "이메일",
    },
    {
      id: "contract_num",
      numeric: false,
      disablePadding: false,
      label: "계약작품(수)",
    },
    {
      id: "contract_all_num",
      numeric: false,
      disablePadding: false,
      label: "계약 종(수)",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
    {
      id: "request_now",
      numeric: false,
      disablePadding: false,
      label: "신청현황",
    },
  ]
  const contract_headCells = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "작품명",
    },

    {
      id: "genre",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },
    {
      id: "bookcode",
      numeric: false,
      disablePadding: false,
      label: "북코드",
    },
    {
      id: "ebookname",
      numeric: false,
      disablePadding: false,
      label: "이북 필명",
    },
    {
      id: "joaraname",
      numeric: false,
      disablePadding: false,
      label: "조아라 필명",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "contract_type",
      numeric: false,
      disablePadding: false,
      label: "계약유형",
    },
    {
      id: "contract_status",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "cotract_date",
      numeric: false,
      disablePadding: false,
      label: "계약갱신/종료일",
    },
    {
      id: "subcontract_num",
      numeric: false,
      disablePadding: false,
      label: "부속계약(수)",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
    {
      id: "request_now",
      numeric: false,
      disablePadding: false,
      label: "신청현황",
    },
  ]
  const company_headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "업체명",
    },

    {
      id: "bossName",
      numeric: false,
      disablePadding: false,
      label: "대표자",
    },
    {
      id: "companynumber",
      numeric: false,
      disablePadding: false,
      label: "사업자등록번호",
    },
    {
      id: "ratio",
      numeric: false,
      disablePadding: false,
      label: "정산비율",
    },
    {
      id: "first_contract",
      numeric: false,
      disablePadding: false,
      label: "최초계약",
    },
    {
      id: "ebook_distribution",
      numeric: false,
      disablePadding: false,
      label: "이북 유통",
    },
    {
      id: "ebook_special",
      numeric: false,
      disablePadding: false,
      label: "이북 특별",
    },
    {
      id: "outside",
      numeric: false,
      disablePadding: false,
      label: "외부 제작",
    },

    {
      id: "domestic_distribution",
      numeric: false,
      disablePadding: false,
      label: "내부 유통",
    },
    {
      id: "etc_ebook",
      numeric: false,
      disablePadding: false,
      label: "기타 (이북)",
    },
    {
      id: "etc_ip",
      numeric: false,
      disablePadding: false,
      label: "기타 (IP)",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
    {
      id: "request_now",
      numeric: false,
      disablePadding: false,
      label: "신청현황",
    },
  ]
  const outsourcing_headCells = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "구분",
    },

    {
      id: "contractStatus",
      numeric: false,
      disablePadding: false,
      label: "계약상태",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "본명",
    },
    {
      id: "nickname",
      numeric: false,
      disablePadding: false,
      label: "닉네임",
    },
    {
      id: "minPrice",
      numeric: false,
      disablePadding: false,
      label: "단가(최소)",
    },
    {
      id: "maxPrice",
      numeric: false,
      disablePadding: false,
      label: "단가(최대)",
    },
    {
      id: "mainTitle",
      numeric: false,
      disablePadding: false,
      label: "대표 작업품명",
    },
    {
      id: "genre",
      numeric: false,
      disablePadding: false,
      label: "장르",
    },

    {
      id: "work_num",
      numeric: false,
      disablePadding: false,
      label: "작업수",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
    {
      id: "request_now",
      numeric: false,
      disablePadding: false,
      label: "신청현황",
    },
  ]
  const events_headCells = [
    {
      id: "flatform",
      numeric: false,
      disablePadding: false,
      label: "플랫폼",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "진행 상황",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: "시작 일",
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: "종료 일",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "이벤트 명",
    },
    {
      id: "serial",
      numeric: false,
      disablePadding: false,
      label: "단행/연재",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "이벤트 종류",
    },
    {
      id: "reward",
      numeric: false,
      disablePadding: false,
      label: "기본 리워드",
    },
    {
      id: "main_new",
      numeric: false,
      disablePadding: false,
      label: "메인/신작",
    },
    {
      id: "participate_work",
      numeric: false,
      disablePadding: false,
      label: "참여 작품",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
    {
      id: "request_now",
      numeric: false,
      disablePadding: false,
      label: "신청현황",
    },
  ]
  const referer_headCells = [
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "카테고리",
    },

    {
      id: "where",
      numeric: false,
      disablePadding: false,
      label: "등록위치",
    },
    {
      id: "where_detail",
      numeric: false,
      disablePadding: false,
      label: "등록위치 상세",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "등록유형",
    },
    {
      id: "asignner",
      numeric: false,
      disablePadding: false,
      label: "등록자",
    },
    {
      id: "page_move",
      numeric: false,
      disablePadding: false,
      label: "페이지로 이동",
    },
  ]
  // headers - end

  // 공지사항 - strat

  const info_type = (type) => {
    let convert = {
      notice: "공지사항",
      update: "업데이트",
      etc: "기타",
    }[type]

    return convert
  }

  const [NoticeDetailDialogBool, setNoticeDetailDialogBool] = useState(false)
  const [DetailNoticeData, setDetailNoticeData] = useState({
    name: "",
    position: "",
    department: "",
    created_at: "",
    title: "",
    type: "",
    content: "",
    file: { name: " ", url: " " },
  })

  const DetailMeetingOpen = (data) => {
    setDetailNoticeData(data)
    setNoticeDetailDialogBool(true)
  }
  const closeDialog_notice = () => {
    setNoticeDetailDialogBool(false)
  }

  // 공지사항 - end
  // 내가 등록한 작가 - start
  const [writer_head_cell, setwriter_head_cell] = useState(writer_headCells)
  const [writer_order, setwriter_Order] = useState("asc")
  const [writer_orderBy, setwriter_OrderBy] = useState("calories")
  const [writer_rows, setwriter_rows] = useState([])
  const [writer_selected, setwriter_Selected] = useState([])
  const [writer_maxAmount, setwriter_maxAmount] = useState(false)
  const [writer_filter, setwriter_filter] = useState("")
  const [writer_searched, setwriter_searched] = useState("")

  const handleRequestSort_writer = (event, property) => {
    const isAsc = writer_orderBy === property && writer_order === "asc"
    setwriter_Order(isAsc ? "desc" : "asc")
    setwriter_OrderBy(property)
  }
  const isSelected_writer = (name) => writer_selected.indexOf(name) !== -1

  // 내가 등록한 작가 - end

  // 내가 등록한 작품 - start

  const [contract_head_cell, setcontract_head_cell] = useState(contract_headCells)
  const [contract_order, setcontract_Order] = useState("asc")
  const [contract_orderBy, setcontract_OrderBy] = useState("calories")
  const [contract_rows, setcontract_rows] = useState([])
  const [contract_selected, setcontract_Selected] = useState([])
  const [contract_maxAmount, setcontract_maxAmount] = useState(false)
  const [contract_filter, setcontract_filter] = useState("")
  const [contract_searched, setcontract_searched] = useState("")

  const handleRequestSort_contract = (event, property) => {
    const isAsc = contract_orderBy === property && contract_order === "asc"
    setcontract_Order(isAsc ? "desc" : "asc")
    setcontract_OrderBy(property)
  }
  const isSelected_contract = (name) => contract_selected.indexOf(name) !== -1
  // 내가 등록한 작품 - end

  // 내가 등록한 유통사 - start

  const [company_head_cell, setcompany_head_cell] = useState(company_headCells)
  const [company_order, setcompany_Order] = useState("asc")
  const [company_orderBy, setcompany_OrderBy] = useState("calories")
  const [company_rows, setcompany_rows] = useState([])
  const [company_selected, setcompany_Selected] = useState([])
  const [company_maxAmount, setcompany_maxAmount] = useState(false)
  const [company_filter, setcompany_filter] = useState("")
  const [company_searched, setcompany_searched] = useState("")

  const handleRequestSort_company = (event, property) => {
    const isAsc = company_orderBy === property && company_order === "asc"
    setcompany_Order(isAsc ? "desc" : "asc")
    setcompany_OrderBy(property)
  }
  const isSelected_company = (name) => company_selected.indexOf(name) !== -1
  // 내가 등록한 유통사 - end

  // 내가 등록한 외주계약 - start

  const [outsourcing_head_cell, setoutsourcing_head_cell] = useState(outsourcing_headCells)
  const [outsourcing_order, setoutsourcing_Order] = useState("asc")
  const [outsourcing_orderBy, setoutsourcing_OrderBy] = useState("calories")
  const [outsourcing_rows, setoutsourcing_rows] = useState([])
  const [outsourcing_selected, setoutsourcing_Selected] = useState([])
  const [outsourcing_maxAmount, setoutsourcing_maxAmount] = useState(false)
  const [outsourcing_filter, setoutsourcing_filter] = useState("")
  const [outsourcing_searched, setoutsourcing_searched] = useState("")

  const handleRequestSort_outsourcing = (event, property) => {
    const isAsc = outsourcing_orderBy === property && outsourcing_order === "asc"
    setoutsourcing_Order(isAsc ? "desc" : "asc")
    setoutsourcing_OrderBy(property)
  }
  const isSelected_outsourcing = (name) => outsourcing_selected.indexOf(name) !== -1

  const comma = (price) => {
    let str = String(price)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
  }

  // 내가 등록한 외주계약 - end

  // 내가 등록한 이벤트 - start

  const [events_head_cell, setEvents_head_cell] = useState(events_headCells)
  const [events_rows, setEvents_rows] = useState([])
  const [events_searched, setEvents_searched] = useState("")
  const [events_searched_origin, setEvents_searched_origin] = useState([])
  const [events_filter_bool, setEvents_filter_bool] = useState(false)
  const [events_filter, setEvent_filter] = useState("")
  const [events_order, setEvent_order] = useState("asc")
  const [events_orderBy, setEvent_orderBy] = useState("calories")
  const [events_maxAmount, setEvents_maxAmount] = useState(false)

  const handleRequestSort_events = (event, property) => {
    const isAsc = events_orderBy === property && events_order === "asc"
    setEvent_order(isAsc ? "desc" : "asc")
    setEvent_orderBy(property)
  }

  // 내가 등록한 이벤트 - end

  // 참조자로 등록된 목록 - start

  const [referer_head_cell, setreferer_head_cell] = useState(referer_headCells)
  const [referer_order, setreferer_Order] = useState("asc")
  const [referer_orderBy, setreferer_OrderBy] = useState("calories")
  const [referer_rows, setreferer_rows] = useState([])
  const [referer_selected, setreferer_Selected] = useState([])
  const [referer_maxAmount, setreferer_maxAmount] = useState(false)
  const [referer_filter, setreferer_filter] = useState("")

  const handleRequestSort_referer = (event, property) => {
    const isAsc = referer_orderBy === property && referer_order === "asc"
    setreferer_Order(isAsc ? "desc" : "asc")
    setreferer_OrderBy(property)
  }
  const isSelected_referer = (name) => referer_selected.indexOf(name) !== -1
  // 참조자로 등록된 목록 - end

  const filter_change = (type, e) => {
    if (type === "writer") {
      setwriter_filter(e.target.value)
    } else if (type === "contract") {
      setcontract_filter(e.target.value)
    } else if (type === "company") {
      setcompany_filter(e.target.value)
    } else if (type === "outsourcing") {
      setoutsourcing_filter(e.target.value)
    } else if (type === "events") {
      setEvent_filter(e.target.value)
    } else if (type === "referer") {
      setreferer_filter(e.target.value)
    }
  }
  const handleKeyPress = (type, e) => {
    if (e) {
      if (e.key === "Enter") {
        let data = { ...my_page_data_all }
        if (type === "writer") {
          let filterd = data.writer_rows.filter((el) => el.ebookname.indexOf(writer_filter) > -1)
          setwriter_rows(filterd)
          setwriter_searched(`${writer_filter}`)
        } else if (type === "contract") {
          let filterd = data.contract_rows.filter((el) => el.title.indexOf(contract_filter) > -1)
          setcontract_rows(filterd)
          setcontract_searched(`${contract_filter}`)
        } else if (type === "company") {
          let filterd = data.company_rows.filter((el) => el.name.indexOf(company_filter) > -1)
          setcompany_rows(filterd)
          setcompany_searched(`${company_filter}`)
        } else if (type === "outsourcing") {
          let filterd = data.outsourcing_rows.filter(
            (el) => el.nickname.indexOf(outsourcing_filter) > -1
          )
          setoutsourcing_rows(filterd)
          setoutsourcing_searched(`${outsourcing_filter}`)
        } else if (type === "events") {
          if (events_filter.length === 0) {
            reset_search("events")
          } else {
            let event = [...events_searched_origin]
            let filtered = event.filter((el) => el.title.indexOf(events_filter) > -1)
            setEvents_rows(filtered)
            setEvents_searched(`${events_filter}`)

            setEvents_filter_bool(true)
          }
        } else if (type === "referer") {
          // let filterd = data.referer.filter(
          //   (el) => el.nickname.indexOf(referer_filter) > -1
          // )
          // setreferer_rows(filterd)
        }
      }
    }
  }
  const handleSelectAllClick = (type) => {
    if (type === "writer") {
      let data = [...writer_rows]
      let allcheck = data.filter((el) => el.selected)
      data.forEach((el) => {
        if (allcheck.length < writer_rows.length) {
          el.selected = true
        } else {
          el.selected = false
        }
      })
      setwriter_rows(data)
    } else if (type === "contract") {
      let data = [...contract_rows]
      let allcheck = data.filter((el) => el.selected)
      data.forEach((el) => {
        if (allcheck.length < contract_rows.length) {
          el.selected = true
        } else {
          el.selected = false
        }
      })
      setcontract_rows(data)
    } else if (type === "company") {
      let data = [...company_rows]
      let allcheck = data.filter((el) => el.selected)
      data.forEach((el) => {
        if (allcheck.length < company_rows.length) {
          el.selected = true
        } else {
          el.selected = false
        }
      })
      setcompany_rows(data)
    } else if (type === "outsourcing") {
      let data = [...outsourcing_rows]
      let allcheck = data.filter((el) => el.selected)
      data.forEach((el) => {
        if (allcheck.length < outsourcing_rows.length) {
          el.selected = true
        } else {
          el.selected = false
        }
      })
      setoutsourcing_rows(data)
    } else if (type === "events") {
      let data = [...events_rows]
      let allCheck = data.filter((el) => el.selected)
      data.forEach((el) => {
        if (allCheck.length < events_rows.length) {
          el.selected = true
        } else {
          el.selected = false
        }
      })
      setEvents_rows(data)
    } else if (type === "referer") {
      // let data = [...referer_rows]
      // let allcheck = data.filter((el) => el.selected)
      // data.forEach((el) => {
      //   if (allcheck.length < referer_rows.length) {
      //     el.selected = true
      //   } else {
      //     el.selected = false
      //   }
      // })
      // setreferer_rows(data)
    }
  }
  const handleSearchClick = (type, e) => {
    let data = { ...my_page_data_all }
    if (type === "writer") {
      let filterd = data.writer_rows.filter((el) => el.ebookname.indexOf(writer_filter) > -1)
      setwriter_rows(filterd)
      setwriter_searched(`${writer_filter}`)
    } else if (type === "contract") {
      let filterd = data.contract_rows.filter((el) => el.title.indexOf(contract_filter) > -1)
      setcontract_rows(filterd)
      setcontract_searched(`${contract_filter}`)
    } else if (type === "company") {
      let filterd = data.company_rows.filter((el) => el.name.indexOf(company_filter) > -1)
      setcompany_rows(filterd)
      setcompany_searched(`${company_filter}`)
    } else if (type === "outsourcing") {
      let filterd = data.outsourcing_rows.filter(
        (el) => el.nickname.indexOf(outsourcing_filter) > -1
      )
      setoutsourcing_rows(filterd)
      setoutsourcing_searched(`${outsourcing_filter}`)
    } else if (type === "events") {
      if (events_filter.length === 0) {
        reset_search("events")
      } else {
        let event = [...events_searched_origin]
        let filtered = event.filter((el) => el.title.indexOf(events_filter) > -1)
        setEvents_rows(filtered)
        setEvents_searched(`${events_filter}`)
        setEvents_filter_bool(true)
      }
    } else if (type === "referer") {
      // let filterd = data.referer.filter(
      //   (el) => el.nickname.indexOf(referer_filter) > -1
      // )
      // setreferer_rows(filterd)
    }
  }
  const handleClick = (type, index) => {
    if (type === "writer") {
      let data = [...writer_rows]
      data[index].selected = !data[index].selected
      setwriter_rows(data)
    } else if (type === "contract") {
      let data = [...contract_rows]
      data[index].selected = !data[index].selected
      setcontract_rows(data)
    } else if (type === "company") {
      let data = [...company_rows]
      data[index].selected = !data[index].selected
      setcompany_rows(data)
    } else if (type === "outsourcing") {
      let data = [...outsourcing_rows]
      data[index].selected = !data[index].selected
      setoutsourcing_rows(data)
    } else if (type === "events") {
      let data = [...events_rows]
      data[index].selected = !data[index].selected
      setEvents_rows(data)
    } else if (type === "referer") {
      // let data = [...referer_rows]
      // data[index].selected = !data[index].selected
      // setreferer_rows(data)
    }
  }
  const reset_search = (type) => {
    let data = { ...my_page_data_all }

    if (type === "writer") {
      let filterd = data.writer_rows.filter((el) => el.ebookname.indexOf("") > -1)
      setwriter_rows(filterd)
      setwriter_filter("")
      setwriter_searched("")
    } else if (type === "contract") {
      let filterd = data.contract_rows.filter((el) => el.title.indexOf("") > -1)
      setcontract_rows(filterd)
      setcontract_filter("")
      setcontract_searched("")
    } else if (type === "company") {
      let filterd = data.company_rows.filter((el) => el.name.indexOf("") > -1)
      setcompany_rows(filterd)
      setcompany_filter("")
      setcompany_searched("")
    } else if (type === "outsourcing") {
      let filterd = data.outsourcing_rows.filter((el) => el.nickname.indexOf("") > -1)
      setoutsourcing_rows(filterd)
      setoutsourcing_filter("")
      setoutsourcing_searched("")
    } else if (type === "events") {
      let filterd = data.events_rows.filter((el) => el.title.indexOf("") > -1)
      setEvents_rows(filterd)
      setEvent_filter("")
      setEvents_searched("")
      setEvents_filter_bool(false)
    }
  }
  const btn_disabled = (type) => {
    if (type === "writer") {
      let selected = writer_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_change_request("writer", el)) {
            if (find_change_request("writer", el).status === "신청") {
              able = true
            }
          }
        })

        return able
      } else {
        return true
      }
    } else if (type === "contract") {
      let selected = contract_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_change_request("contract", el)) {
            if (find_change_request("contract", el).status === "신청") {
              able = true
            }
          }
        })

        return able
      } else {
        return true
      }
    } else if (type === "distributor") {
      let selected = company_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_change_request("distributor", el)) {
            if (find_change_request("distributor", el).status === "신청") {
              able = true
            }
          }
        })

        return able
      } else {
        return true
      }
    } else if (type === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_change_request("outsourcing", el)) {
            if (find_change_request("outsourcing", el).status === "신청") {
              able = true
            }
          }
        })

        return able
      } else {
        return true
      }
    } else if (type === "events") {
      let selected = events_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_change_request("events", el)) {
            if (find_change_request("events", el).status === "신청") {
              able = true
            }
          }
        })

        return able
      } else {
        return true
      }
    }
  }

  const btn_disabled_delete = (type) => {
    if (type === "writer") {
      let selected = writer_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_request("writer", el)) {
            if (find_request("writer", el).status.indexOf("심사중") > -1) {
              able = true
            }
          }
        })
        return able
      } else {
        return true
      }
    } else if (type === "contract") {
      let selected = contract_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_request("contract", el)) {
            if (find_request("contract", el).status.indexOf("심사중") > -1) {
              able = true
            }
          }
        })
        return able
      } else {
        return true
      }
    } else if (type === "distributor") {
      let selected = company_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_request("distributor", el)) {
            if (find_request("distributor", el).status.indexOf("심사중") > -1) {
              able = true
            }
          }
        })
        return able
      } else {
        return true
      }
    } else if (type === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected)
      if (selected.length > 0) {
        let able = false
        selected.forEach((el) => {
          if (find_request("outsourcing", el)) {
            if (find_request("outsourcing", el).status.indexOf("심사중") > -1) {
              able = true
            }
          }
        })
        return able
      } else {
        return true
      }
    }
  }
  const open_change_dialog = (type) => {
    if (type === "writer") {
      let selected = writer_rows.filter((el) => el.selected)
      let content = `${selected[0].ebookname} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`
      let contract_list = []
      selected.forEach((el) => {
        el.contract_document.forEach((contract) => {
          contract_list.push(contract)
        })
      })
      setchange_data(contract_list)
      setopen_ChagneDialog(true)
      setchange_content(content)
      setchange_target(type)
    } else if (type === "contract") {
      let selected = contract_rows.filter((el) => el.selected)
      let content = `${selected[0].title} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      setchange_data(selected)
      setopen_ChagneDialog(true)
      setchange_content(content)
      setchange_target(type)
    } else if (type === "distributor") {
      let selected = company_rows.filter((el) => el.selected)
      let content = `${selected[0].name} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      let contract_list = []
      selected.forEach((el) => {
        el.company_contracts.forEach((contract) => {
          contract_list.push(contract)
        })
      })

      setchange_data(contract_list)
      setopen_ChagneDialog(true)
      setchange_content(content)
      setchange_target(type)
    } else if (type === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected)
      let content = `${selected[0].nickname} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`
      let contract_list = []
      selected.forEach((el) => {
        el.contract_document.forEach((contract) => {
          contract_list.push(contract)
        })
      })

      setchange_data(contract_list)
      setopen_ChagneDialog(true)
      setchange_content(content)
      setchange_target(type)
    }
  }
  const open_delete_dialog = (type) => {
    if (type === "writer") {
      let selected = writer_rows.filter((el) => el.selected)
      let content = `${selected[0].ebookname} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      setDelete_Dialog(true)
      setDelete_Content(content)
      setDelete_target(type)
      setrequestExplainData("")
    } else if (type === "contract") {
      let selected = contract_rows.filter((el) => el.selected)
      let content = `${selected[0].title} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      setDelete_Dialog(true)
      setDelete_Content(content)
      setDelete_target(type)
      setrequestExplainData("")
    } else if (type === "distributor") {
      let selected = company_rows.filter((el) => el.selected)
      let content = `${selected[0].name} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      setDelete_Dialog(true)
      setDelete_Content(content)
      setDelete_target(type)
      setrequestExplainData("")
    } else if (type === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected)
      let content = `${selected[0].nickname} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`

      setDelete_Dialog(true)
      setDelete_Content(content)
      setDelete_target(type)
      setrequestExplainData("")
    } else if (type === "events") {
      let selected = events_rows.filter((el) => el.selected)
      let content = `${selected[0].title} ${
        selected.length - 1 > 0 ? `외 ${selected.length - 1}` : ""
      }`
      setDelete_Dialog(true)
      setDelete_Content(content)
      setDelete_target(type)
      setrequestExplainData("")
    }
  }

  const find_change_request = (type, row) => {
    let change_requests = [...my_page_data_all.change_requests]
    if (type === "writer") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            (el.arrayContent?.writersIds?.indexOf(row.id) > -1 ||
              el.arrayContent?.writersIds?.indexOf(String(row.id)) > -1) &&
            el.arrayContent?.contractsIds.length === 0
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "contract") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.arrayContent?.contractsIds?.indexOf(row.contract_id) > -1 ||
            el.arrayContent?.contractsIds?.indexOf(String(row.contract_id)) > -1
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "distributor") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.arrayContent?.companiesIds?.indexOf(row.id) > -1 ||
            el.arrayContent?.companiesIds?.indexOf(String(row.id)) > -1
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "outsourcing") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.arrayContent?.outsourcingsIds?.indexOf(row.id) > -1 ||
            el.arrayContent?.outsourcingsIds?.indexOf(String(row.id)) > -1
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "events") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.arrayContent?.eventsIds?.indexOf(row.id) > -1 ||
            el.arrayContent?.eventsIds?.indexOf(String(row.id)) > -1
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    }
  }
  const find_request = (type, row) => {
    let change_requests = [...my_page_data_all.requests]
    if (type === "writer") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.type === "Writer" &&
            (el.arrayContent || el.content) &&
            el.writer &&
            el.writer.id === String(row.id)
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "contract") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.type === "Writer" &&
            el.arrayContent &&
            el.arrayContent.writerId === row.writer &&
            el.requestId === String(row.contract_id)
        )

      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "distributor") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.type === "Company" &&
            (el.arrayContent || el.content) &&
            el.company &&
            el.company.id === String(row.id)
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    } else if (type === "outsourcing") {
      let findIndex = change_requests
        .reverse()
        .findIndex(
          (el) =>
            el.type === "Outsourcing" &&
            (el.arrayContent || el.content) &&
            el.outsourcing &&
            el.outsourcing.id === String(row.id)
        )
      if (findIndex > -1) {
        return change_requests[findIndex]
      } else {
        return null
      }
    }
  }

  const open_cancle_alert = (type, request) => {
    if (type === "change") {
      let data = { ...change_cancle_alert }
      data.open = true
      data.title = "이관신청 취소"
      data.content = "관리자에게 이관 신청 중입니다. 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.data = request
      data.CanBtnText = "돌아가기"
      setchange_cancle_alert(data)
    } else if (type === "delete") {
      let data = { ...delete_cancle_alert }
      data.open = true
      data.title = "삭제 신청 취소"
      data.content = "삭제 신청하신 항목이 취소 처리됩니다. \n 취소하시겠습니까?"
      data.twoBtn = true
      data.id = request.id
      data.CanBtnText = "돌아가기"
      setdelete_cancle_alert(data)
    }
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (secondDateObj.getTime() - firstDateObj.getTime() <= 0) {
      return "light2"
    }
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) > 90
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) <= 90) {
      return "error"
    } else {
      return "light2"
    }
  }
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const tooltipIndex = useRef(null)

  const [openTool, setOpenTool] = useState(false)
  const handleCloseTool = () => {
    setOpenTool(false)
  }

  const handleOpenTool = () => {
    setOpenTool(true)
  }
  const ShowStateIcon = (data, index) => {
    if (data.length > 0) {
      return (
        <div onMouseEnter={() => (tooltipIndex.current = index)}>
          <Tooltip
            open={openTool}
            onClose={handleCloseTool}
            onOpen={handleOpenTool}
            color="white"
            classes={{ popper: "tooltip-light" }}
            arrow={false}
            title={
              index === tooltipIndex.current ? (
                <SuiBox>
                  <SuiTypography
                    style={{ fontSize: "12px", color: "#9AA0A6" }}
                    verticalAlign="middle"
                  >
                    계약 유효일
                  </SuiTypography>
                  <SuiTypography
                    style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}
                    verticalAlign="middle"
                  >
                    {dayjs(new Date()) > dayjs(data[1].value2)
                      ? "계약종료"
                      : betweenDay(
                          dayjs(new Date()).format("YYYYMMDD"),
                          dayjs(data[1].value2).format("YYYYMMDD")
                        ) + "일"}
                  </SuiTypography>
                  <SuiTypography
                    style={{ fontSize: "12px", color: "#707070", fontWeight: "normal" }}
                    verticalAlign="middle"
                  >
                    {`${data[3].value === "자동갱신" ? "갱신일" : "종료일"} : ${dayjs(
                      data[1].value2
                    ).format("YY.MM.DD")}`}
                  </SuiTypography>
                </SuiBox>
              ) : (
                ""
              )
            }
          >
            {betweenDayColor(
              dayjs(new Date()).format("YYYYMMDD"),
              dayjs(data[1].value2).format("YYYYMMDD")
            ) ? (
              <IconButton
                color={betweenDayColor(
                  dayjs(new Date()).format("YYYYMMDD"),
                  dayjs(data[1].value2).format("YYYYMMDD")
                )}
                sx={{ p: 0 }}
              >
                {data[3].value === "자동갱신" ? (
                  <BiRecycle size={20} />
                ) : (
                  <BsFillCircleFill size={10}></BsFillCircleFill>
                )}
              </IconButton>
            ) : (
              <div></div>
            )}
          </Tooltip>
        </div>
      )
    } else {
      return "-"
    }
  }

  // table_setting - end

  // 다이어로그 - start
  const [writerDialog, setwriterDialog] = useState(false)
  const [editId, seteditId] = useState(null)
  const [editData, seteditData] = useState({ writername: "" })
  const [type, settype] = useState("create")
  const [tabActive, settabActive] = useState(0)

  const [distributorDialog, setDistributorDialog] = useState(false)
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [editDistributorData, seteditDistributorData] = useState({ writername: "" })
  const [typeDistributor, settypeDistributor] = useState("create")

  const [outsourcingDialog, setoutsourcingDialog] = useState(false)
  const [editOutsourcingId, seteditOutsourcingId] = useState(null)
  const [editOutsourcingData, seteditOutsourcingData] = useState({ writername: "" })
  const [typeOutsourcing, settypeOutsourcing] = useState("create")

  const closeDialog = async () => {
    await my_contract_list_call()
    notices_call()
    setwriterDialog(false)
    setDistributorDialog(false)
    setoutsourcingDialog(false)
  }
  const closeWorksDialog = async () => {
    await my_contract_list_call()
    await notices_call()
    set_event_dialog(false)
    set_edit_event_id(null)
  }

  const open_dialog = (row, type) => {
    if (type === "writer") {
      setwriterDialog(true)
      seteditId(row.id)
      let data = {
        writername: row.ebookname,
        bookname: row.ebookname,
      }

      seteditData(data)
      settype("detail")
      settabActive(0)
    } else if (type === "distributor") {
      setDistributorDialog(true)
      seteditDistributorId(row.id)
      let data = {
        title: row.name,
      }
      seteditDistributorData(data)
      settypeDistributor("detail")
    } else if (type === "contract") {
      setwriterDialog(true)
      seteditId(String(row.writer))
      let data = {
        writername: row.ebookname,
        bookname: row.ebookname,
      }
      seteditData(data)
      settype("detail")
      settabActive(1)
    } else if (type === "outsourcing") {
      setoutsourcingDialog(true)
      seteditOutsourcingId(row.id)
      row.title = row.nickname
      seteditOutsourcingData(row)
      settypeOutsourcing("detail")
    }
  }

  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [Delete_target, setDelete_target] = useState("")

  const Delete_All = () => {
    dispatch(loading_Apply(true))

    if (Delete_target === "writer") {
      let selected = writer_rows.filter((el) => el.selected)
      selected.forEach((DetailData, index) => {
        let contract_list = []
        DetailData.contract_document.forEach((contract) => {
          contract_list.push(contract)
        })
        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          requestExplain: requestExplainData,
          title: DetailData.ebookname,
          writerId: DetailData.id,
          suggestRole: "작가 삭제신청",
        }
        if (DetailData.history.length > 0) {
          request.first_history = DetailData.history[DetailData.history.length - 1]
        }
        createRequestActive({
          variables: {
            status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
            content: request,
            type: "Writer",
            writer: DetailData.id,
            requestId: String(DetailData.id),
            users_permissions_user: myData.id,
          },
        }).then(() => {
          client
            .query({
              query: users_list,
              variables: {
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              setusersDatas(res.data.users)
              res.data.users
                .filter((x) => x.role.id === "1")
                .forEach((user) => {
                  if (user.adminAlertSetting) {
                    if (user.adminAlertSetting[2].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "작가 삭제 신청",
                          type: "Delete",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            profile: myData.profile,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            profile: myData.profile,
                            DetailData: DetailData,
                            targetName: DetailData.ebookname,
                          },
                        },
                      })
                    }
                  }
                })
              const editHistory = [...DetailData.history]
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content:
                  "<strong>" + DetailData.name + " 작가님 </strong> 정보 삭제를 신청하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              })

              updateWriterActive({
                variables: {
                  id: DetailData.id,
                  history: editHistory,
                },
              })
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "삭제 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 삭제가 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
              setDelete_Dialog(false)
            })
        })
      })
    } else if (Delete_target === "contract") {
      let selected = contract_rows.filter((el) => el.selected)
      selected.forEach((DetailData, index) => {
        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          requestExplain: requestExplainData,
          title: DetailData.ebookname,
          writerId: DetailData.writer,
          contractData: selected,
        }
        if (DetailData.history.length > 0) {
          request.first_history = DetailData.history[DetailData.history.length - 1]
        }
        createRequestActive({
          variables: {
            status: `작가 계약 삭제 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
            arrayContent: request,
            type: "Writer",
            requestId: String(DetailData.contract_id),
            users_permissions_user: myData.id,
          },
        }).then(() => {
          client
            .query({
              query: users_list,
              variables: {
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              setusersDatas(res.data.users)
              res.data.users
                .filter((x) => x.role.id === "1")
                .forEach((user) => {
                  if (user.adminAlertSetting) {
                    if (user.adminAlertSetting[2].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "작가 계약 삭제 신청",
                          type: "Delete",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            profile: myData.profile,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            profile: myData.profile,
                            DetailData: DetailData,
                            selectData: DetailData.contractPaper[0][0].selectData[1].value,
                            targetName: DetailData.ebookname,
                          },
                        },
                      })
                    }
                  }
                })

              const editHistory = [...DetailData.history]
              let historyContent =
                `<strong>${DetailData.title}</strong> 작품의 ` +
                "<strong>" +
                DetailData.contractPaper[0][0].selectData[1].value +
                "</strong> 삭제를 요청하였습니다."
              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: historyContent,
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              })
              updateWriterActive({
                variables: {
                  id: DetailData.writer,
                  history: editHistory,
                },
              })
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "삭제 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 삭제가 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
              setDelete_Dialog(false)
            })
        })
      })
    } else if (Delete_target === "distributor") {
      let selected = company_rows.filter((el) => el.selected)
      selected.forEach((DetailData, index) => {
        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          requestExplain: requestExplainData,
          title: DetailData.title,
          companyId: DetailData.id,
          suggestRole: "유통사 삭제신청",
        }
        if (DetailData.history.length > 0) {
          request.first_history = DetailData.history[DetailData.history.length - 1]
        }
        createRequestActive({
          variables: {
            status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
            content: request,
            type: "Company",
            company: DetailData.id,
            requestId: String(DetailData.id),
            users_permissions_user: myData.id,
          },
        }).then(() => {
          client
            .query({
              query: users_list,
              variables: {
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              setusersDatas(res.data.users)
              res.data.users
                .filter((x) => x.role.id === "1")
                .forEach((user) => {
                  if (user.adminAlertSetting) {
                    if (user.adminAlertSetting[2].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "유통사 삭제 신청",
                          type: "Delete",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            profile: myData.profile,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            profile: myData.profile,
                            DetailData: DetailData,
                            targetName: DetailData.title,
                          },
                        },
                      })
                    }
                  }
                })

              const editHistory = [...DetailData.history]

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content: "<strong>" + DetailData.name + " 社 </strong> 정보 삭제를 신청하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
              })
              updateCompanyActive({
                variables: {
                  id: DetailData.id,
                  history: editHistory,
                },
              })
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "삭제 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 삭제가 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
              setDelete_Dialog(false)
            })
        })
      })
    } else if (Delete_target === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected)
      selected.forEach((DetailData, index) => {
        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          requestExplain: requestExplainData,
          title: DetailData.nickname,
          outsourcingId: DetailData.id,
          suggestRole: "외주계약 삭제신청",
        }
        if (DetailData.histroy.length > 0) {
          request.first_history = DetailData.histroy[DetailData.histroy.length - 1]
        }
        createRequestActive({
          variables: {
            status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
            content: request,
            type: "Outsourcing",
            outsourcing: DetailData.id,
            requestId: String(DetailData.id),
            users_permissions_user: myData.id,
          },
        }).then(() => {
          client
            .query({
              query: users_list,
              variables: {
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              setusersDatas(res.data.users)
              res.data.users
                .filter((x) => x.role.id === "1")
                .forEach((user) => {
                  if (user.adminAlertSetting) {
                    if (user.adminAlertSetting[2].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "외주계약 삭제 신청",
                          type: "Delete",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            profile: myData.profile,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            profile: myData.profile,
                            DetailData: DetailData,
                            targetName: DetailData.nickname,
                          },
                        },
                      })
                    }
                  }
                })

              const editHistory = [...DetailData.histroy]

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content:
                  "<strong>" + DetailData.nickname + " 社 </strong> 정보 삭제를 신청하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                content:
                  "<strong>" + DetailData.nickname + " 社 </strong> 정보 삭제를 신청하였습니다.",
              })
              updateOutsourcingActive({
                variables: {
                  id: DetailData.id,
                  histroy: editHistory,
                },
              })
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "삭제 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 삭제가 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
              setDelete_Dialog(false)
            })
        })
      })
    } else if (Delete_target === "events") {
      let selected = events_rows.filter((el) => el.selected)
      selected.forEach((DetailData, index) => {
        const request = {
          id: myData.id,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          role: myData.role.name,
          profile: myData.profile,
          requestExplain: requestExplainData,
          title: DetailData.title,
          eventId: String(DetailData.id),
          suggestRole: "이벤트 삭제신청",
        }
        if (DetailData.history.length > 0) {
          request.first_history = DetailData.history[DetailData.history.length - 1]
        } else {
          request.first_history = {
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" + DetailData.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          }
        }
        createRequestActive({
          variables: {
            status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
            content: request,
            type: "Event",
            event: DetailData.id,
            requestId: String(DetailData.id),
            users_permissions_user: myData.id,
          },
        }).then(() => {
          client
            .query({
              query: users_list,
              variables: {
                status: "근무",
              },
              fetchPolicy: "network-only",
            })
            .then((res) => {
              setusersDatas(res.data.users)
              res.data.users
                .filter((x) => x.role.id === "1")
                .forEach((user) => {
                  if (user.adminAlertSetting) {
                    if (user.adminAlertSetting[2].value) {
                      createAlertActive({
                        variables: {
                          users_permissions_user: String(user.id),
                          title: "이벤트 삭제 신청",
                          type: "Delete",
                          userid: String(user.id),
                          content: {
                            id: myData.id,
                            profile: myData.profile,
                            position: myData.position,
                            department: myData.department,
                            name: myData.name,
                            profile: myData.profile,
                            DetailData: DetailData,
                            targetName: DetailData.title,
                          },
                        },
                      })
                    }
                  }
                })
              const editHistory = [...DetailData.history]

              editHistory.unshift({
                profile: myData.profile ? myData.profile.url : null,
                name: myData.name,
                position: myData.position,
                department: myData.department,
                content:
                  "<strong>" + DetailData.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
                date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
                content:
                  "<strong>" + DetailData.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
              })
              updateEventActive({
                variables: {
                  id: DetailData.id,
                  state: "waiting",
                  history: editHistory,
                },
              })
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "삭제 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD HH:mm"
              )})  \n 관리자 승인 후 삭제가 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
              setDelete_Dialog(false)
              my_contract_list_call()
            })
        })
      })
    }
  }

  const [alert_Dialog, setalert_Dialog] = useState({
    open: false,
    title: "",
    content: "",
  })
  const alertDialog_close = () => {
    let data = { ...alert_Dialog }
    data.open = false
    setalert_Dialog(data)
    my_contract_list_call()
  }
  const [alert_Dialog2, setalert_Dialog2] = useState({
    open: false,
    title: "",
    content: "",
  })
  const alertDialog_close2 = () => {
    let data = { ...alert_Dialog2 }
    data.open = false
    setalert_Dialog2(data)
  }

  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
    my_contract_list_call()
  }
  const delete_cancle_active = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제 신청 취소 완료"
      data.content = "삭제 신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"
      setdelete_cancle_alert(data)
      my_contract_list_call()
    })
  }
  // 이관신청 취소 alert
  const [change_cancle_alert, setchange_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
    data: {},
  })
  const change_cancle_alert_close = () => {
    let data = { ...change_cancle_alert }
    data.open = false
    setchange_cancle_alert(data)
    // my_contract_list_call()
  }
  const change_cancle_active = () => {
    dispatch(loading_Apply(true))
    const content = { ...change_cancle_alert.data.arrayContent }
    content["read"] = true
    content["status"] = `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`
    content["decisionDate"] = new Date()
    const body = {
      arrayContent: content,
      status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
      decisionDate: new Date(),
    }
    axios
      .put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + change_cancle_alert.id, body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then(() => {
        let data = { ...change_cancle_alert }
        data.title = "이관신청 취소 완료"
        data.content = "신청이 취소되었습니다."
        data.twoBtn = false
        data.CanBtnText = "확인"
        setchange_cancle_alert(data)
        my_contract_list_call()
      })
  }
  const [open_DenyReasonDialog, setopen_DenyReasonDialog] = useState(false)
  const [denyReason, setdenyReason] = useState("")
  const close_denyReason = async () => {
    if (event_reject) {
      await my_contract_list_call()
      set_event_reject(false)
    }
    setopen_DenyReasonDialog(false)
  }

  const [open_DenyReasonDialog2, setopen_DenyReasonDialog2] = useState(false)
  const [denyReason2, setdenyReason2] = useState("")
  const close_denyReason2 = () => {
    setopen_DenyReasonDialog2(false)
  }

  const openDeleteDialog = (type, request) => {
    if (type === "delete") {
      updateRequestActive({
        variables: {
          id: request.id,
          read: true,
        },
      }).then(() => {
        setopen_DenyReasonDialog(true)
        setdenyReason(request.declining)
        my_contract_list_call()
      })
    } else if (type === "change") {
      const content = { ...request.arrayContent }
      content["read"] = true
      const body = {
        arrayContent: content,
      }
      axios
        .put(process.env.REACT_APP_BACKEND_URL + "/change-requests/" + request.id, body, {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
        })
        .then(() => {
          my_contract_list_call()
        })

      setopen_DenyReasonDialog2(true)
      setdenyReason(request.arrayContent.reject)
    } else if (type === "reject") {
      set_event_reject(true)
      updateRequestActive({
        variables: {
          id: request.deleteStatus[0].id,
          read: true,
        },
      }).then(() => {
        setopen_DenyReasonDialog(true)
        setdenyReason(request.deleteStatus[0].declining)
        my_contract_list_call()
      })
    }
  }

  const [open_ChagneDialog, setopen_ChagneDialog] = useState(false)
  const [change_data, setchange_data] = useState([])
  const [change_content, setchange_content] = useState("")
  const [change_target, setchange_target] = useState("")
  const close_ChagneDialog = () => {
    setopen_ChagneDialog(false)
  }
  const change_active = (changeList) => {
    dispatch(loading_Apply(true))
    const content = {
      request: {
        id: myData.id,
        department: myData.department,
        name: myData.name,
        position: myData.position,
        profile: myData.profile ? myData.profile.url : null,
      },
      apply: {
        id: changeList.name.split(" ")[2],
        department: changeList.department,
        name: changeList.name.split(" ")[0],
        position: changeList.name.split(" ")[1],
      },
      companiesIds: [],
      writersIds: [],
      contractsIds: [],
      outsourcingsIds: [],
      eventsIds: [],
      reject: "",
      reason: changeList.reason,
      created_at: new Date(),
      status: "신청",
      type: "my_" + change_target,
      name: "",
      users: null,
      contract_document: change_data,
      targets_name: [],
      targets: [],
    }
    let count = 0
    if (change_target === "writer") {
      let selected = writer_rows.filter((el) => el.selected).map((el) => el.id)
      let selected_name = writer_rows.filter((el) => el.selected).map((el) => el.ebookname)
      let selected_all = writer_rows.filter((el) => el.selected)

      content.writersIds = selected
      count = selected.length
      content.targets_name = selected_name
      content.targets = selected_all
    } else if (change_target === "contract") {
      let selected = contract_rows.filter((el) => el.selected).map((el) => el.contract_id)
      let selected_writer = contract_rows.filter((el) => el.selected).map((el) => el.writer)
      let selected_name = contract_rows.filter((el) => el.selected).map((el) => el.ebookname)
      let selected_select = contract_rows
        .filter((el) => el.selected)
        .map((el) => el.contractPaper[0][0].selectData[1].value)
      content.targets_name = selected_name
      content.targets_select = selected_select
      content.writersIds = Array.from(new Set(selected_writer))
      content.contractsIds = selected
      count = selected.length
    } else if (change_target === "distributor") {
      let selected = company_rows.filter((el) => el.selected).map((el) => el.id)
      let selected_name = company_rows.filter((el) => el.selected).map((el) => el.name)
      let selected_all = company_rows.filter((el) => el.selected)

      content.companiesIds = selected
      count = selected.length
      content.targets_name = selected_name
      content.targets = selected_all
    } else if (change_target === "outsourcing") {
      let selected = outsourcing_rows.filter((el) => el.selected).map((el) => el.id)
      let selected_name = outsourcing_rows.filter((el) => el.selected).map((el) => el.nickname)
      let selected_all = outsourcing_rows.filter((el) => el.selected)
      content.outsourcingsIds = selected
      count = selected.length

      content.targets_name = selected_name
      content.targets = selected_all
    }
    const body = {
      type: `my_${change_target} Change`,
      arrayContent: content,
      users_permissions_user: myData.id,
      status: "신청",
    }
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/change-requests", body, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("joara_token") },
      })
      .then((res) => {
        usersDatas
          .filter((x) => x.role.id === "1")
          .forEach((user, user_idex) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[1].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "계약 담당자 변경 신청",
                    type: "Change",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      title: myData.name,
                      name: myData.name,
                      count: count,
                      targetName: content.targets_name[0],
                    },
                  },
                })
              }
              if (user.adminAlertSetting[3].value) {
                EmailSendActive({
                  variables: {
                    usermail: user.email,
                    type: "3",
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      DetailData: content.targets,
                    },
                  },
                })
              }
            }
            if (user_idex === usersDatas.filter((x) => x.role.id === "1").length - 1) {
              let data = { ...alert_Dialog }
              data.open = true
              data.title = "이관 신청 완료"
              data.content = `신청되었습니다. (${dayjs(new Date()).format(
                "YY.MM.DD"
              )})  \n 관리자 승인 후 이관이 완료됩니다.`
              setalert_Dialog(data)
              dispatch(loading_Apply(false))
            }
          })
        dispatch(loading_Apply(false))
      })

    setopen_ChagneDialog(false)
  }

  // 다이어로그 - end
  return (
    <DashboardLayout>
      <Event
        open={event_dialog}
        closeDialog={closeWorksDialog}
        editId={edit_event_id}
        type={type_event}
      />
      <Paper sx={{ width: "100%", mb: 2, pt: 14, px: 2, boxShadow: 0 }} className="main-layout">
        <SuiBox display="flex" style={{ alignItems: "center" }}>
          {/* 회원 정보 - start */}
          <SuiBox
            pr={1}
            mx={0}
            sx={{ width: "50%" }}
            display="block"
            style={{ alignItems: "center" }}
          >
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }} verticalAlign="middle">
              회원 정보
            </SuiTypography>
            <SuiBox
              display="flex"
              sx={{
                width: "100%",
                p: 2,
                mt: 2.5,
                backgroundColor: "#F7F9FB",
                border: "1px solid #EEEEEE",
                borderRadius: "10px",
                height: "110px",
              }}
              style={{ alignItems: "center" }}
            >
              <SuiBox display="flex">
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "#ffffff",
                    boxShadow: 1,
                  }}
                  src={myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""}
                />
                <SuiBox sx={{ alignSelf: "center", ml: 2 }}>
                  <SuiTypography style={{ fontSize: "14px" }} verticalAlign="middle">
                    {role_return()}
                  </SuiTypography>
                  <SuiTypography style={{ fontSize: "18px" }} verticalAlign="middle">
                    {`${myData.department} · ${myData.name} ${myData.position}`}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </SuiBox>
          {/* 회원 정보 - end */}
          {/* 공지사항 - start */}
          <SuiBox
            ml=""
            pl={1}
            mx={0}
            sx={{ width: "50%" }}
            display="block"
            style={{ alignItems: "center" }}
          >
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }} verticalAlign="middle">
              Info
            </SuiTypography>
            <SuiBox
              sx={{
                width: "100%",
                padding: "16px 16px 0px 16px",
                mt: 2.5,
                backgroundColor: "#F7F9FB",
                border: "1px solid #EEEEEE",
                borderRadius: "10px",
                height: "110px",
              }}
              style={{ alignItems: "center" }}
            >
              <SuiBox
                display="-webkit-box"
                sx={{
                  pb: "11px",
                  overflow: "auto",
                }}
                style={{ alignItems: "center" }}
              >
                {notices_data.length > 0 ? (
                  notices_data.map((notice, index) => {
                    return (
                      <SuiBox
                        key={`notice_${index}`}
                        sx={{
                          width: "300px",
                          maxHeight: "100%",
                          padding: "10px 16px 10px 16px",
                          mr: 2,
                          background: "white",
                          border: "1px solid #EEEEEE",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        display="block"
                        onClick={() => DetailMeetingOpen(notice)}
                      >
                        <SuiBox display="flex">
                          <SuiTypography
                            style={{
                              fontSize: "14px",
                              color: {
                                notice: "#FF4D60",
                                update: "#0C65FF",
                                etc: "#668B9E",
                              }[notice.type],
                            }}
                            verticalAlign="middle"
                          >
                            {info_type(notice.type)}
                          </SuiTypography>

                          <SuiTypography
                            style={{ marginLeft: "auto", fontSize: "12px", color: "#707070" }}
                            verticalAlign="middle"
                          >
                            {dayjs(notice.created_at).format("YY.MM.DD")}
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox display="block">
                          <SuiTypography
                            style={{ marginLeft: "auto", fontSize: "16px" }}
                            verticalAlign="middle"
                          >
                            {notice.title}
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    )
                  })
                ) : (
                  <SuiTypography
                    style={{
                      marginTop: "24px",
                      fontSize: "18px",
                      color: "#707070",
                      width: "100%",
                      textAlignLast: "center",
                    }}
                    verticalAlign="middle"
                  >
                    공지사항이 없습니다.
                  </SuiTypography>
                )}
              </SuiBox>
            </SuiBox>
          </SuiBox>

          <NoticeDetailDialog
            openDialog={NoticeDetailDialogBool}
            closeDialog={closeDialog_notice}
            detailData={DetailNoticeData}
          />

          {/* 공지사항 - end */}
        </SuiBox>
        {/* 테이블 1 - 내가 등록한 작가 */}

        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                내가 등록한 작가 ({writer_rows.length})
              </SuiTypography>
              <SuiBox
                sx={{
                  border: "1px dashed #BFC4C7",
                  borderRadius: 2.5,
                  ml: 3,
                  pl: 1.25,
                  minWidth: 400,
                  alignItems: "center",
                  display: writer_searched ? "flex" : "none",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiTypography
                  style={{ fontSize: "14px" }}
                  verticalAlign="middle"
                  sx={{ color: "#535353" }}
                >
                  검색 내용: {writer_searched}
                </SuiTypography>
                <IconButton
                  edge="start"
                  color="#707070"
                  aria-label="close"
                  sx={{ marginLeft: "auto" }}
                  onClick={() => reset_search("writer")}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="error"
                  size="small"
                  onClick={() => open_delete_dialog("writer")}
                  disabled={
                    btn_disabled("writer") ||
                    btn_disabled_delete("writer") ||
                    writer_rows.filter((el) => el.selected).length > 1
                  }
                >
                  <BiTrash size="20"></BiTrash>
                </SuiButton>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="info2"
                  size="small"
                  onClick={() => open_change_dialog("writer")}
                  disabled={btn_disabled("writer") || btn_disabled_delete("writer")}
                >
                  <CgArrowsExchange size="20"></CgArrowsExchange>
                </SuiButton>
              </SuiBox>
              <SuiBox sx={{ ml: 2, maxWidth: "400px" }} className="filter-right-icon">
                <SuiInput
                  icon={{ component: "search", direction: "right" }}
                  iconActive={() => handleSearchClick("writer")}
                  type="text"
                  placeholder="이북 필명을 입력해주세요."
                  value={writer_filter}
                  onChange={(e) => filter_change("writer", e)}
                  onKeyPress={(e) => handleKeyPress("writer", e)}
                />
              </SuiBox>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={writer_order}
                orderBy={writer_orderBy}
                onSelectAllClick={() => handleSelectAllClick("writer")}
                onRequestSort={handleRequestSort_writer}
                headCelldata={writer_head_cell}
              />
              <TableBody>
                {writer_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-writer-${index}`
                  let amount = 0
                  if (writer_maxAmount) {
                    amount = writer_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      <TableCell
                        onClick={() => handleClick("writer", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.ebookname}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.joaraname}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.userid}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.birth}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.phone}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.email}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.contract_num}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.contract_all_num}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        <SuiTypography
                          className="mypage_blue_underline"
                          style={{
                            fontSize: 13,
                          }}
                          onClick={() => open_dialog(row, "writer")}
                        >
                          상세보기
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {find_request("writer", row) ? (
                          find_request("writer", row).status.indexOf("심사중") > -1 ? (
                            <SuiTypography
                              className="mypage_red_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("delete", find_request("writer", row))
                              }
                            >
                              삭제신청
                            </SuiTypography>
                          ) : find_request("writer", row).status.indexOf("거절") > -1 &&
                            !find_request("writer", row).read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("delete", find_request("writer", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}

                        {find_change_request("writer", row) ? (
                          find_change_request("writer", row).status === "신청" ? (
                            <SuiTypography
                              className="mypage_blue_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("change", find_change_request("writer", row))
                              }
                            >
                              이관신청
                            </SuiTypography>
                          ) : find_change_request("writer", row).status === "거절" &&
                            !find_change_request("writer", row).arrayContent.read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("change", find_change_request("writer", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {writer_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {writer_rows.length > 10 ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setwriter_maxAmount(!writer_maxAmount)
                  }}
                >
                  {writer_maxAmount ? "숨기기" : "전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 1 - 내가 등록한 작가 - end */}

        {/* 테이블 2 - 내가 등록한 작품 */}

        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                내가 등록한 작품 ({contract_rows.length})
              </SuiTypography>
              <SuiBox
                sx={{
                  border: "1px dashed #BFC4C7",
                  borderRadius: 2.5,
                  ml: 3,
                  pl: 1.25,
                  minWidth: 400,
                  alignItems: "center",
                  display: contract_searched ? "flex" : "none",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiTypography
                  style={{ fontSize: "14px" }}
                  verticalAlign="middle"
                  sx={{ color: "#535353" }}
                >
                  검색 내용: {contract_searched}
                </SuiTypography>
                <IconButton
                  edge="start"
                  color="#707070"
                  aria-label="close"
                  sx={{ marginLeft: "auto" }}
                  onClick={() => reset_search("contract")}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="error"
                  size="small"
                  disabled={
                    btn_disabled("contract") ||
                    btn_disabled_delete("contract") ||
                    contract_rows.filter((el) => el.selected).length > 1
                  }
                  onClick={() => open_delete_dialog("contract")}
                >
                  <BiTrash size="20"></BiTrash>
                </SuiButton>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="info2"
                  size="small"
                  disabled={btn_disabled("contract") || btn_disabled_delete("contract")}
                  onClick={() => open_change_dialog("contract")}
                >
                  <CgArrowsExchange size="20"></CgArrowsExchange>
                </SuiButton>
              </SuiBox>
              <SuiBox sx={{ ml: 2, maxWidth: "400px" }} className="filter-right-icon">
                <SuiInput
                  icon={{ component: "search", direction: "right" }}
                  iconActive={() => handleSearchClick("contract")}
                  type="text"
                  placeholder="작품명을 입력해주세요."
                  value={contract_filter}
                  onChange={(e) => filter_change("contract", e)}
                  onKeyPress={(e) => handleKeyPress("contract", e)}
                />
              </SuiBox>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={contract_order}
                orderBy={contract_orderBy}
                onSelectAllClick={() => handleSelectAllClick("contract")}
                onRequestSort={handleRequestSort_contract}
                headCelldata={contract_head_cell}
              />
              <TableBody>
                {contract_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-contract-${index}`
                  let amount = 0
                  if (contract_maxAmount) {
                    amount = contract_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      <TableCell
                        onClick={() => handleClick("contract", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.title}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.genre}
                      </TableCell>

                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.bookcode}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.ebookname}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.joaraname}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.contractPaper.length > 0
                          ? ShowStateIcon(row.contractPaper[0], index)
                          : "-"}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.cotract_date}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.subcontract_num}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        <SuiTypography
                          className="mypage_blue_underline"
                          style={{
                            fontSize: 13,
                          }}
                          onClick={() => open_dialog(row, "contract")}
                        >
                          상세보기
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {find_request("contract", row) ? (
                          find_request("contract", row).status.indexOf("심사중") > -1 ? (
                            <SuiTypography
                              className="mypage_red_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("delete", find_request("contract", row))
                              }
                            >
                              삭제신청
                            </SuiTypography>
                          ) : find_request("contract", row).status.indexOf("거절") > -1 &&
                            !find_request("contract", row).read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("delete", find_request("contract", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}

                        {find_change_request("contract", row) ? (
                          find_change_request("contract", row).status === "신청" ? (
                            <SuiTypography
                              className="mypage_blue_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("change", find_change_request("contract", row))
                              }
                            >
                              이관신청
                            </SuiTypography>
                          ) : find_change_request("contract", row).status === "거절" &&
                            !find_change_request("contract", row).arrayContent.read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("change", find_change_request("contract", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {contract_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {contract_rows.length > 10 ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setcontract_maxAmount(!contract_maxAmount)
                  }}
                >
                  {contract_maxAmount ? "숨기기" : "전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 2 - 내가 등록한 작품 - end */}

        {/* 테이블 3 - 내가 등록한 유통사 - start*/}

        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                내가 등록한 유통사 ({company_rows.length})
              </SuiTypography>

              <SuiBox
                sx={{
                  border: "1px dashed #BFC4C7",
                  borderRadius: 2.5,
                  ml: 3,
                  pl: 1.25,
                  minWidth: 400,
                  alignItems: "center",
                  display: company_searched ? "flex" : "none",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiTypography
                  style={{ fontSize: "14px" }}
                  verticalAlign="middle"
                  sx={{ color: "#535353" }}
                >
                  검색 내용: {company_searched}
                </SuiTypography>
                <IconButton
                  edge="start"
                  color="#707070"
                  aria-label="close"
                  sx={{ marginLeft: "auto" }}
                  onClick={() => reset_search("company")}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="error"
                  size="small"
                  disabled={
                    btn_disabled("distributor") ||
                    btn_disabled_delete("distributor") ||
                    company_rows.filter((el) => el.selected).length > 1
                  }
                  onClick={() => open_delete_dialog("distributor")}
                >
                  <BiTrash size="20"></BiTrash>
                </SuiButton>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="info2"
                  size="small"
                  disabled={btn_disabled("distributor") || btn_disabled_delete("distributor")}
                  onClick={() => open_change_dialog("distributor")}
                >
                  <CgArrowsExchange size="20"></CgArrowsExchange>
                </SuiButton>
              </SuiBox>
              <SuiBox sx={{ ml: 2, maxWidth: "400px" }} className="filter-right-icon">
                <SuiInput
                  icon={{ component: "search", direction: "right" }}
                  iconActive={() => handleSearchClick("company")}
                  type="text"
                  placeholder="유통사명을 입력해주세요."
                  value={company_filter}
                  onChange={(e) => filter_change("company", e)}
                  onKeyPress={(e) => handleKeyPress("company", e)}
                />
              </SuiBox>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={company_order}
                orderBy={company_orderBy}
                onSelectAllClick={() => handleSelectAllClick("company")}
                onRequestSort={handleRequestSort_company}
                headCelldata={company_head_cell}
              />
              <TableBody>
                {company_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-company-${index}`
                  let amount = 0
                  if (company_maxAmount) {
                    amount = company_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      <TableCell
                        onClick={() => handleClick("company", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.bossName}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.companynumber}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.ratio}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.first_contract}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.ebook_distribution}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.ebook_special}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.outside}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.domestic_distribution}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.etc_ebook}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.etc_ip}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        <SuiTypography
                          className="mypage_blue_underline"
                          style={{
                            fontSize: 13,
                          }}
                          onClick={() => open_dialog(row, "distributor")}
                        >
                          상세보기
                        </SuiTypography>
                      </TableCell>

                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {find_request("distributor", row) ? (
                          find_request("distributor", row).status.indexOf("심사중") > -1 ? (
                            <SuiTypography
                              className="mypage_red_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("delete", find_request("distributor", row))
                              }
                            >
                              삭제신청
                            </SuiTypography>
                          ) : find_request("distributor", row).status.indexOf("거절") > -1 &&
                            !find_request("distributor", row).read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("delete", find_request("distributor", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}

                        {find_change_request("distributor", row) ? (
                          find_change_request("distributor", row).status === "신청" ? (
                            <SuiTypography
                              className="mypage_blue_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("change", find_change_request("distributor", row))
                              }
                            >
                              이관신청
                            </SuiTypography>
                          ) : find_change_request("distributor", row).status === "거절" &&
                            !find_change_request("distributor", row).arrayContent.read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("change", find_change_request("distributor", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {company_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {company_rows.length > 10 ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setcompany_maxAmount(!company_maxAmount)
                  }}
                >
                  {company_maxAmount ? "숨기기" : "전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 3 - 내가 등록한 유통사 - end */}

        {/* 테이블 4 - 내가 등록한 외주계약 - start */}

        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                내가 등록한 외주계약 ({outsourcing_rows.length})
              </SuiTypography>

              <SuiBox
                sx={{
                  border: "1px dashed #BFC4C7",
                  borderRadius: 2.5,
                  ml: 3,
                  pl: 1.25,
                  minWidth: 400,
                  alignItems: "center",
                  display: outsourcing_searched ? "flex" : "none",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiTypography
                  style={{ fontSize: "14px" }}
                  verticalAlign="middle"
                  sx={{ color: "#535353" }}
                >
                  검색 내용: {outsourcing_searched}
                </SuiTypography>
                <IconButton
                  edge="start"
                  color="#707070"
                  aria-label="close"
                  sx={{ marginLeft: "auto" }}
                  onClick={() => reset_search("outsourcing")}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>
              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="error"
                  size="small"
                  onClick={() => open_delete_dialog("outsourcing")}
                  disabled={
                    btn_disabled("outsourcing") ||
                    btn_disabled_delete("outsourcing") ||
                    outsourcing_rows.filter((el) => el.selected).length > 1
                  }
                >
                  <BiTrash size="20"></BiTrash>
                </SuiButton>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="info2"
                  size="small"
                  disabled={btn_disabled("outsourcing") || btn_disabled_delete("outsourcing")}
                  onClick={() => open_change_dialog("outsourcing")}
                >
                  <CgArrowsExchange size="20"></CgArrowsExchange>
                </SuiButton>
              </SuiBox>
              <SuiBox sx={{ ml: 2, maxWidth: "400px" }} className="filter-right-icon">
                <SuiInput
                  icon={{ component: "search", direction: "right" }}
                  iconActive={() => handleSearchClick("outsourcing")}
                  type="text"
                  placeholder="닉네임을 입력해주세요."
                  value={outsourcing_filter}
                  onChange={(e) => filter_change("outsourcing", e)}
                  onKeyPress={(e) => handleKeyPress("outsourcing", e)}
                />
              </SuiBox>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={outsourcing_order}
                orderBy={outsourcing_orderBy}
                onSelectAllClick={() => handleSelectAllClick("outsourcing")}
                onRequestSort={handleRequestSort_outsourcing}
                headCelldata={outsourcing_head_cell}
              />
              <TableBody>
                {outsourcing_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-outsourcing-${index}`
                  let amount = 0
                  if (outsourcing_maxAmount) {
                    amount = outsourcing_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      <TableCell
                        onClick={() => handleClick("outsourcing", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.type}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.contractStatus}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.nickname}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {comma(row.minPrice)}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {comma(row.maxPrice)}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.mainTitle}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.genre}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.work_num}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        <SuiTypography
                          className="mypage_blue_underline"
                          style={{
                            fontSize: 13,
                          }}
                          onClick={() => open_dialog(row, "outsourcing")}
                        >
                          상세보기
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {find_request("outsourcing", row) ? (
                          find_request("outsourcing", row).status.indexOf("심사중") > -1 ? (
                            <SuiTypography
                              className="mypage_red_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("delete", find_request("outsourcing", row))
                              }
                            >
                              삭제신청
                            </SuiTypography>
                          ) : find_request("outsourcing", row).status.indexOf("거절") > -1 &&
                            !find_request("outsourcing", row).read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("delete", find_request("outsourcing", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}
                        {find_change_request("outsourcing", row) ? (
                          find_change_request("outsourcing", row).status === "신청" ? (
                            <SuiTypography
                              className="mypage_blue_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                open_cancle_alert("change", find_change_request("outsourcing", row))
                              }
                            >
                              이관신청
                            </SuiTypography>
                          ) : find_change_request("outsourcing", row).status === "거절" &&
                            !find_change_request("outsourcing", row).arrayContent.read ? (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() =>
                                openDeleteDialog("change", find_change_request("outsourcing", row))
                              }
                            >
                              승인거절
                            </SuiTypography>
                          ) : null
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {outsourcing_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {outsourcing_rows.length > 10 ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setoutsourcing_maxAmount(!outsourcing_maxAmount)
                  }}
                >
                  {outsourcing_maxAmount ? "숨기기" : "전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 4 - 내가 등록한 외주계약 - end */}

        {/* 테이블 5 - 내가 등록한 이벤트 - start */}
        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                내가 등록한 이벤트 ({events_rows.length})
              </SuiTypography>

              <SuiBox
                sx={{
                  border: "1px dashed #BFC4C7",
                  borderRadius: 2.5,
                  ml: 3,
                  pl: 1.25,
                  minWidth: 400,
                  alignItems: "center",
                  display: events_filter_bool ? "flex" : "none",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <SuiTypography
                  style={{ fontSize: "14px" }}
                  verticalAlign="middle"
                  sx={{ color: "#535353" }}
                >
                  검색 내용: {events_searched}
                </SuiTypography>
                <IconButton
                  edge="start"
                  color="#707070"
                  aria-label="close"
                  sx={{ marginLeft: "auto" }}
                  onClick={() => reset_search("events")}
                >
                  <MdClose />
                </IconButton>
              </SuiBox>

              <SuiBox sx={{ ml: "auto" }}>
                <SuiButton
                  sx={{
                    minHeight: 32,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    p: 0,
                    ml: 2,
                    borderRadius: 30,
                  }}
                  color="error"
                  size="small"
                  onClick={() => open_delete_dialog("events")}
                  disabled={
                    btn_disabled("events") ||
                    btn_disabled_delete("events") ||
                    events_rows.filter((el) => el.selected).length > 1
                  }
                >
                  <BiTrash size="20"></BiTrash>
                </SuiButton>
              </SuiBox>
              <SuiBox sx={{ ml: 2, maxWidth: "400px" }} className="filter-right-icon">
                <SuiInput
                  icon={{ component: "search", direction: "right" }}
                  iconActive={(e) => handleSearchClick("events", e)}
                  type="text"
                  placeholder="이벤트 명을 입력해주세요."
                  value={events_filter}
                  onChange={(e) => filter_change("events", e)}
                  onKeyPress={(e) => handleKeyPress("events", e)}
                />
              </SuiBox>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={events_order}
                orderBy={events_orderBy}
                onSelectAllClick={() => handleSelectAllClick("events")}
                onRequestSort={handleRequestSort_events}
                headCelldata={events_headCells}
              />
              <TableBody>
                {events_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-events-${index}`
                  let amount = 0
                  if (events_maxAmount) {
                    amount = events_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      <TableCell
                        onClick={() => handleClick("events", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.flatform}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.status}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.start}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.end}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.title}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.serial}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.type}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.reward}
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.newOne}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (row.worksList && row.worksList.length > 0) {
                            openWorksListsDialog(row.worksList)
                          }
                        }}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        {row.worksList && row.worksList.length > 0 ? (
                          <SuiTypography className="event_works_lists">
                            {`${row.worksList.length}종`}
                          </SuiTypography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleDetail(row)
                        }}
                        align="center"
                        id={labelId}
                        className="td-Setting td-fontSetting"
                      >
                        <SuiTypography className="event_works_lists">상세보기</SuiTypography>
                      </TableCell>
                      <TableCell align="center" id={labelId} className="td-Setting td-fontSetting">
                        {row.deleteStatus.length > 0 &&
                        row.deleteStatus[0].status.includes("심사중") ? (
                          <SuiTypography
                            onClick={() => {
                              open_cancle_alert("delete", row.deleteStatus[0])
                            }}
                            className="event_delete_cancel"
                          >
                            삭제신청
                          </SuiTypography>
                        ) : (
                          row.deleteStatus.length > 0 &&
                          row.deleteStatus[0].status.includes("거절") > -1 &&
                          !row.deleteStatus[0].read && (
                            <SuiTypography
                              className="mypage_gray_underline"
                              style={{
                                fontSize: 13,
                              }}
                              onClick={() => {
                                openDeleteDialog("reject", row)
                              }}
                            >
                              승인거절
                            </SuiTypography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {events_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {events_rows.length > 10 && !events_maxAmount ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setEvents_maxAmount(!events_maxAmount)
                  }}
                >
                  {"전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 5 - 내가 등록한 이벤트 - end */}

        {/* 테이블 6 - 참조자로 등록된 목록 - start */}

        <SuiBox>
          <SuiBox mt={8} pl={0} mx={0} display="flex" style={{ alignItems: "center" }}>
            {/* 상단 필터 start */}
            <SuiBox display="contents">
              <SuiTypography
                style={{ fontSize: "20px", fontWeight: "bold" }}
                verticalAlign="middle"
              >
                참조자로 등록된 목록 ({referer_rows.length})
              </SuiTypography>
            </SuiBox>
            {/* 상단 필터 end */}
          </SuiBox>
          <TableContainer
            sx={{ mt: 6, borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0 }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
              id="table-to-xls"
            >
              <EnhancedTableHead
                order={referer_order}
                orderBy={referer_orderBy}
                onSelectAllClick={() => handleSelectAllClick("referer")}
                onRequestSort={handleRequestSort_referer}
                headCelldata={referer_head_cell}
              />
              <TableBody>
                {referer_rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-referer-${index}`
                  let amount = 0
                  if (referer_maxAmount) {
                    amount = referer_rows.length
                  } else {
                    amount = 10
                  }
                  return index < amount ? (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                      aria-checked={row.selected}
                      tabIndex={-1}
                      key={`${row.id}_${index}`}
                      selected={row.selected}
                    >
                      {/* <TableCell
                        onClick={() => handleClick("referer", index)}
                        padding="checkbox"
                        style={{ textAlign: "center", borderRight: "1px solid #F1F1F5" }}
                      >
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          checked={row.selected || false}
                          icon={<AiOutlineCheckCircle />}
                          checkedIcon={<AiFillCheckCircle />}
                        />
                      </TableCell> */}
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.category}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.where}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.where_detail}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.type}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        {row.asignner}
                      </TableCell>
                      <TableCell align="center" className="td-Setting td-fontSetting">
                        <SuiTypography
                          className="mypage_blue_underline"
                          style={{
                            fontSize: 13,
                          }}
                          onClick={() => open_dialog(row, row.tabpanel)}
                        >
                          상세보기
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  ) : null
                })}
              </TableBody>
            </Table>
            {referer_rows.length === 0 ? (
              <SuiBox
                display="block"
                sx={{
                  py: 2.5,
                  textAlign: "center",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <SuiTypography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  등록된 내역이 없습니다.
                </SuiTypography>
              </SuiBox>
            ) : null}
            {referer_rows.length > 10 ? (
              <SuiBox display="block" sx={{ mt: 5, textAlign: "center", p: 1 }}>
                <SuiButton
                  sx={{
                    fontSize: 14,
                    width: 80,
                    height: 40,
                    p: 0,
                    borderColor: "#0C65FF !important",
                    color: "#0C65FF !important",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setreferer_maxAmount(referer_maxAmount + 1)
                  }}
                >
                  {referer_maxAmount ? "숨기기" : "전체보기"}
                </SuiButton>
              </SuiBox>
            ) : null}
          </TableContainer>
        </SuiBox>
        {/* 테이블 5 - 참조자로 등록된 목록 - end */}
      </Paper>
      <DistributorDialog
        open={distributorDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={editDistributorData}
      />
      <WriterDialog
        open={writerDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={editData}
        tabActive={tabActive}
      />
      <OutsourcingDialog
        open={outsourcingDialog}
        closeDialog={closeDialog}
        editId={editOutsourcingId}
        type={typeOutsourcing}
        editData={editOutsourcingData}
      />
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
        Content={Delete_Content}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"삭제 요청"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => Delete_All()}
        type={Delete_Dialog_type}
      />

      <DeleteDenyDialog
        openDialog={open_DenyReasonDialog}
        closeDialog={close_denyReason}
        denyReason={denyReason}
      />

      <ChangeDenyDialog
        openDialog={open_DenyReasonDialog2}
        closeDialog={close_denyReason}
        denyReason={denyReason}
      />

      <ChangeDialog
        openDialog={open_ChagneDialog}
        closeDialog={close_ChagneDialog}
        change_data={change_data}
        content={change_content}
        usersDatas={usersDatas}
        change_active={change_active}
      />
      <SuiDialog
        openDialog={alert_Dialog.open}
        className={"admin_setting_dialog alert_line"}
        MainTitle={alert_Dialog.title}
        Content={alert_Dialog.content}
        CanBtnText={"확인"}
        CreBtnColor="info2"
        AlertType="oneBtn"
        closeDialog={() => alertDialog_close()}
      />
      <SuiDialog
        openDialog={alert_Dialog2.open}
        className={"admin_setting_dialog"}
        MainTitle={alert_Dialog2.title}
        Content={alert_Dialog2.content}
        CanBtnText={"확인"}
        CreBtnColor="info2"
        AlertType="oneBtn"
        closeDialog={() => alertDialog_close2()}
      />
      <SuiDialog
        openDialog={delete_cancle_alert.open}
        className={"admin_setting_dialog alert_line"}
        MainTitle={delete_cancle_alert.title}
        Content={delete_cancle_alert.content}
        CanBtnText={delete_cancle_alert.CanBtnText}
        AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
        CreBtnColor={"error"}
        CreBtnText={"취소하기"}
        closeDialog={() => delete_cancle_alert_close()}
        Active={() => delete_cancle_active()}
      />
      <SuiDialog
        openDialog={change_cancle_alert.open}
        className={"admin_setting_dialog"}
        MainTitle={change_cancle_alert.title}
        Content={change_cancle_alert.content}
        CanBtnText={change_cancle_alert.CanBtnText}
        AlertType={change_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
        CreBtnColor={"error"}
        CreBtnText={"취소하기"}
        closeDialog={() => change_cancle_alert_close()}
        Active={() => change_cancle_active()}
      />
      <SuiDialog
        openDialog={InfoAlert.dialog}
        closeDialog={() => setInfoAlert(InfoAlertSet)}
        MainTitle={InfoAlert.title}
        Content={InfoAlert.content}
        CanBtnText={InfoAlert.CanBtnText}
      />
      <EventWorksDialog
        openDialog={workDialog.dialog}
        workLists={workDialog.workLists}
        closeDialog={() => closeWorksListsDialog()}
      />
    </DashboardLayout>
  )
}
