import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

import ContractdeleteListDialog from "../ContractdeleteListDialog"

import { MdClose } from "react-icons/md"

// API
import { useMutation, useLazyQuery } from "@apollo/client"
import { createRequest, updateOutsourcing, createAlert } from "apollo/mutation"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"
import { users_list } from "apollo/query"

function ContractDeleteDialog({
  openDialog,
  closeDialog,
  ContractDeleteData,
  DetailData,
  setcreateContractShow,
}) {
  const [open, setOpen] = useState(openDialog)
  const [delete_count, setdelete_count] = useState("")
  const [delete_reason, setdelete_reason] = useState("")
  const [openContract_deleteListDialog, setopenContract_deleteListDialog] = useState(false)
  const [open_deleteSend, setopen_deleteSend] = useState(false)
  const [sendContent, setsendContent] = useState("")

  const dispatch = useDispatch()
  const { myData } = useSelector((state) => state.store)

  const [createRequestActive] = useMutation(createRequest, {
    fetchPolicy: "no-cache",
  })
  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    onError(err) {
      alert("작가 삭제시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  useEffect(() => {
    setOpen(openDialog)
    if (ContractDeleteData.length > 0) {
      let deleteCount = ""
      let contractCount = 0
      contractCount += ContractDeleteData.length + ContractDeleteData[0][0].subContract.length

      if (contractCount > 1) {
        deleteCount = `${
          ContractDeleteData[ContractDeleteData.length - 1][0].selectData[0].value
        } 외 ${contractCount - 1}`
      } else {
        deleteCount = ContractDeleteData[ContractDeleteData.length - 1][0].selectData[0].value
      }
      setdelete_count(deleteCount)
      setdelete_reason("")
    }
  }, [openDialog])
  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const [usersActive] = useLazyQuery(users_list, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const stepActive = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      profile: myData.profile,
      suggestRole: "",
      requestExplain: delete_reason,
      title: DetailData.name,
      outsourcingId: DetailData.id,
      contractData: ContractDeleteData,
      // contractTitle: deleteContractData[0].value,
    }
    if (DetailData.histroy.length > 0) {
      request.first_histroy = DetailData.histroy[DetailData.histroy.length - 1]
    }
    // setOpen(false)
    createRequestActive({
      variables: {
        status: "외주계약 삭제 심사중",
        type: "Outsourcing",
        content: request,
        users_permissions_user: myData.id,
        outsourcing_contract: ContractDeleteData[0][0].id,
      },
    }).then(() => {
      let histroyContent =
        "<strong>" +
        ContractDeleteData[ContractDeleteData.length - 1][0].selectData[0].value +
        "</strong> 삭제를 요청하였습니다."
      const edithistroy = [...DetailData.histroy]
      edithistroy.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: histroyContent,
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        type: "contract-delete",
        contractId: ContractDeleteData[0][0].id,
      })
      updateOutsourcingActive({
        variables: {
          id: DetailData.id,
          histroy: edithistroy,
        },
      }).then(() => {
        setopen_deleteSend(true)
        let content = `계약서 삭제 신청 (${dayjs(new Date()).format(
          "YY.MM.DD HH:mm"
        )})이 완료되었습니다. 관리자 승인시 계약 정보가 삭제됩니다.`
        ContractDeleteData[0][0].requests.push({
          status: "계약 삭제 신청 심사중",
          read: false,
          created_at: new Date(),
        })
        usersActive({
          role: 1,
          status: "근무",
        }).then((res) => {
          res.data.users.forEach((user) => {
            if (user.adminAlertSetting) {
              if (user.adminAlertSetting[2].value) {
                createAlertActive({
                  variables: {
                    users_permissions_user: String(user.id),
                    title: "외주계약 삭제 신청",
                    type: "Delete",
                    userid: String(user.id),
                    content: {
                      id: myData.id,
                      profile: myData.profile,
                      position: myData.position,
                      department: myData.department,
                      name: myData.name,
                      profile: myData.profile,
                      selectData:
                        ContractDeleteData[ContractDeleteData.length - 1][0].selectData[0].value,
                      DetailData: DetailData,
                      targetName: DetailData.nickname,
                    },
                  },
                })
              }
            }
          })
        })

        setsendContent(content)
        dispatch(loading_Apply(false))
        setcreateContractShow(true)
      })
    })
  }
  const open_contractList = () => {
    setopenContract_deleteListDialog(true)
  }
  const close_deleteList = () => {
    setopenContract_deleteListDialog(false)
  }
  const close_all = () => {
    setopen_deleteSend(false)
    closeDialog()
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Grid container sx={{ px: 1, pb: 4, pt: 1, width: 480 }}>
        <Grid item xs={12}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiBox display="flex" alignItems="center" justifyContent="center">
              <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
                계약서 삭제 신청
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1, mb: 0 }}
            >
              <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
                계약서 삭제 신청 사유를 작성합니다.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sx={{ mx: 4, mt: 5 }}>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              mb: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                color="text2"
                sx={{ ml: 2, mr: "auto" }}
              >
                삭제신청 건
              </SuiTypography>
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                color="text2"
                sx={{ ml: 2, mr: "auto", textDecoration: "underline", cursor: "pointer" }}
                onClick={() => open_contractList()}
              >
                {delete_count}
              </SuiTypography>
            </SuiBox>
          </Card>
          <SuiBox sx={{ my: 1 }}>
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2 }}>
              계약 정보 :
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="center" width="100%" alignItems="center">
            <TextField
              multiline
              rows={8}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              variant="outlined"
              placeholder="300자 이내"
              value={delete_reason}
              onChange={(e) => {
                if (e.target.value.length <= 300) setdelete_reason(e.target.value)
              }}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            justifyContent="start"
            height="100%"
            alignItems="center"
            sx={{ mb: 4, mt: 2, px: 4 }}
          >
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                ml: "auto",
                mr: 1,
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
                border: 1,
                borderColor: "#707070",
              }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="error"
              onClick={() => stepActive()}
              sx={{
                px: 2,
                py: 0.5,
                height: 40,
                minHeight: 40,
                width: 100,
                fontSize: 14,
              }}
            >
              삭제신청
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      <ContractdeleteListDialog
        openDialog={openContract_deleteListDialog}
        closeDialog={close_deleteList}
        ContractDeleteData={ContractDeleteData}
        DetailData={DetailData}
      />
      <SuiDialog
        openDialog={open_deleteSend}
        closeDialog={() => close_all()}
        MainTitle={"계약서 삭제 신청 완료"}
        Content={sendContent}
        CanBtnText={"확인"}
      />
    </Dialog>
  )
}

ContractDeleteDialog.defaultProps = {
  openDialog: false,
}

ContractDeleteDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  ContractDeleteData: PropTypes.array,
  DetailData: PropTypes.object,
  setcreateContractShow: PropTypes.func,
}
export default ContractDeleteDialog
