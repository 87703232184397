import { useEffect } from "react"

// react-router-dom components
import { useLocation } from "react-router-dom"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"

// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "context"

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav } = controller
  const { pathname } = useLocation()
  useEffect(() => {
    setLayout(dispatch, "dashboard")
  }, [pathname])

  return (
    <SuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        mt: -8,
        p: "22px 24px",
        backgroundColor: "#fff",
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(80) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </SuiBox>
  )
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node,
}

export default DashboardLayout
