import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import GetFileIcon from "components/getFileIcon"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { BiUpload } from "react-icons/bi"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { MdClose } from "react-icons/md"
import { IoMdCloseCircle } from "react-icons/io"

import { FileUploader } from "react-drag-drop-files"
// Redux
import { useSelector, useDispatch } from "react-redux"

import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"
import { loading_Apply } from "reducers/store"

function DragDrop({ files, readOnly }) {
  let message = "클릭 또는 드레그로 이미지 업로드"
  const [Message, setMessage] = useState(message)
  const [read_only, set_read_only] = useState(readOnly ? readOnly : false)
  const handleChange = (file) => {
    files({ name: "" })
    files(file)
    // setMessage("업로드 되었습니다.")
  }
  const onTypeError = () => {
    setMessage("200MB 이하의 jpg, png, jpeg파일만 업로드 가능합니다.")
  }
  const onSizeError = () => {
    setMessage("200MB 이하의 jpg, png, jpeg파일만 업로드 가능합니다.")
  }
  return (
    <>
      {read_only ? (
        <FileUploader
          disabled={true}
          handleChange={handleChange}
          name="file"
          hoverTitle="jpg, png, jpeg 파일만 업로드 됩니다."
          label="클릭 또는 드레그로 이미지 업로드"
          maxSize="200"
          classes="width100 fileDragDrop "
          types={["pdf", "png", "jpeg", "jpg"]}
          accept=" image/png, image/jpeg, image/jpg"
          onSizeError={onSizeError}
          onTypeError={onTypeError}
          style={{ border: "none" }}
        >
          <Card
            sx={{
              p: 2,
              width: "100%",
              boxShadow: 0,
              border: 1,
              backgroundColor: "#FAF8FF",
              borderColor: "#EEEEEE",
            }}
          >
            <SuiBox
              sx={{
                textAlign: "center",
                width: "200px",
              }}
            >
              <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
                <BiUpload />
              </IconButton>
              <SuiTypography
                color={
                  Message === "200MB 이하의 jpg, png, jpeg파일만 업로드 가능합니다."
                    ? "error"
                    : "secondary"
                }
                style={{ fontSize: "14px", color: "#707070" }}
              >
                {Message}
              </SuiTypography>
            </SuiBox>
          </Card>
        </FileUploader>
      ) : (
        <FileUploader
          handleChange={handleChange}
          name="file"
          hoverTitle="jpg, png, jpeg 파일만 업로드 됩니다."
          label="클릭 또는 드레그로 이미지 업로드"
          maxSize="200"
          classes="width100 fileDragDrop"
          types={["pdf", "png", "jpeg", "jpg"]}
          accept=" image/png, image/jpeg, image/jpg"
          onSizeError={onSizeError}
          onTypeError={onTypeError}
          style={{ border: "none" }}
        >
          <Card
            sx={{
              p: 2,
              width: "100%",
              boxShadow: 0,
              border: 1,
              backgroundColor: "#FAF8FF",
              borderColor: "#EEEEEE",
            }}
          >
            <SuiBox
              sx={{
                textAlign: "center",
                width: "200px",
              }}
            >
              <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
                <BiUpload />
              </IconButton>
              <SuiTypography
                color={
                  Message === "200MB 이하의 jpg, png, jpeg파일만 업로드 가능합니다."
                    ? "error"
                    : "secondary"
                }
                style={{ fontSize: "14px", color: "#707070" }}
              >
                {Message}
              </SuiTypography>
            </SuiBox>
          </Card>
        </FileUploader>
      )}
    </>
  )
}
DragDrop.defaultProps = {
  readOnly: false,
}

DragDrop.propTypes = {
  files: PropTypes.func,
  readOnly: PropTypes.bool,
}

function imgUpload({
  openDialog,
  closeDialog,
  editData,
  edit,
  saveDialogCreateMain,
  saveDialogCreateSingle,
  saveDialogDetailMain,
  saveDialogDetailSingle,
}) {
  const [open, setOpen] = useState(openDialog)
  const [DragDropFile, setDragDropFile] = useState({ name: "" })
  const { myData } = useSelector((state) => state.store)
  const dispatch = useDispatch()
  const [current_img, set_current_img] = useState(openDialog)
  const imgPathUrl = process.env.REACT_APP_BACKEND_URL
  useEffect(() => {
    setOpen(openDialog)
    if (editData.imgOnly) {
      set_current_img(
        `<p><img referrerpolicy="no-referrer" src="${
          imgPathUrl + editData.img.file[0].url
        }" width="${editData.img.file[0].size.value1}" height="${
          editData.img.file[0].size.value2
        }"></p>`
      )
    }
  }, [openDialog])

  const InputListsSet = [
    {
      type: "picUpload",
      title: "이미지 업로드:",
      xs: 12,
      file: {},
      changed: false,
      placeholder: "여기에 첨부하실 이미지의 제목을 작성해 주세요.",
      id: "",
      url: "",
      name: "",
      size: {
        inputType: "text",
        value1: "",
        value2: "",
        error_message: "",
        error: false,
        type: "textArea",
        title: "이미지 사이즈:",
        placeholder1: "가로",
        placeholder2: "세로",
        maxLength: 255,
        xs: 12,
      },
    },
    {
      value: "",
      inputType: "text",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이미지 제목:",
      placeholder: "여기에 첨부하실 이미지의 제목을 작성해 주세요.",
      maxLength: 30,
      xs: 12,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이미지 연결 링크:",
      placeholder: "이미지 연결 URL을 작성해 주세요.",
      maxLength: 255,
      xs: 12,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textArea",
      title: "이미지 설명:",
      placeholder: "이미지 설명 글을 작성해 주세요.",
      maxLength: 255,
      xs: 12,
    },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  useEffect(() => {
    if (edit) {
      setDragDropFile(editData.img.file[0].file)
      setInputLists(editData.img.file)
    } else {
      setDragDropFile({ name: "" })
      setInputLists(InputListsSet)
    }
  }, [edit])

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const handleInput = (index, maxLength, size, idx) => (e) => {
    let array = [...InputLists]
    if (size) {
      array[index].size[`value${idx}`] = e.target.value
      array[0].changed = true
    } else {
      if (maxLength >= e.target.value.length) {
        array[index].value = e.target.value
        array[0].changed = true
      }
    }
    setInputLists(array)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].itemsValue = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file
    a.target = "_blank"
    a.rel = "noopener noreferrer"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: String(myData.id),
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const imgupload = () => {
    document.getElementsByClassName("fileDragDrop")[0].click()
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const fileListShow = () => {
    if (DragDropFile.name) {
      const fileName = DragDropFile?.name
      const fileType = fileName.split(".")[fileName.split(".").length - 1]
      return (
        <SuiBox display="flex" alignItems="center">
          <SuiBox display="flex" alignItems="center" sx={{ height: 40 }}>
            <IconButton sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }} className="cursorDefalut">
              {GetFileIcon(fileType)}
              {/* <GrDocumentPdf className="pdfColor" /> */}
            </IconButton>
            <SuiTypography
              fontSize="14px"
              fontWeight="bold"
              color="dark"
              sx={{ pt: 0.8, pb: 0.3 }}
              // onClick={() => DownloadPdf(DragDropFile)}
            >
              {DragDropFile.name.slice(0, DragDropFile.name.length - 3).length > 10
                ? DragDropFile.name.slice(0, 10) +
                  "..." +
                  DragDropFile.name.slice(DragDropFile.name.length - 3, DragDropFile.name.length)
                : DragDropFile.name}
            </SuiTypography>
            <IoMdCloseCircle
              disabled={editData.readOnly}
              style={{
                marginLeft: "5px",
                cursor: editData.readOnly ? "" : "pointer",
                marginTop: "5px",
              }}
              onClick={() => (editData.readOnly ? "" : setDragDropFile({ name: "" }))}
            ></IoMdCloseCircle>
          </SuiBox>
          {editData.img?.file[0].url ? (
            <div style={{ width: "150px" }}>
              <SuiButton
                sx={{
                  backgroundColor: "#EEEEEE",
                  ml: "auto",
                  minHeight: 24,
                  height: 24,
                  width: 24,
                  minWidth: 24,
                  p: 0,
                  ml: 1,
                  boxShadow: 0,
                }}
                onClick={() => showPdf(editData.img.file[0].url)}
              >
                <FiSearch size="15" />
              </SuiButton>
              {/* <SuiButton
              color="info"
              sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
              onClick={() => DownloadPdf(DragDropFile)}
            >
              다운로드
            </SuiButton> */}
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else {
      return (
        <SuiBox
          justifyContent="center"
          display="flex"
          alignItems="start"
          sx={{ height: 40, pt: 4 }}
        ></SuiBox>
      )
    }
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox>
          <SuiInput
            disabled={editData.readOnly}
            error={data.error}
            success={data.success}
            helperText={data.error_message}
            type={data.inputType}
            placeholder={data.placeholder}
            value={data.value}
            onChange={handleInput(index, data.maxLength)}
          />
        </SuiBox>
      )
    } else if (data.type === "textArea") {
      return (
        <TextField
          disabled={editData.readOnly}
          multiline
          rows={3}
          sx={{ width: "100%" }}
          className="Fileupload-textArea"
          variant="outlined"
          placeholder={data.placeholder}
          value={data.value}
          onChange={handleInput(index, data.maxLength)}
        />
      )
    } else if (data.type === "picUpload") {
      return (
        <SuiBox>
          <SuiBox display="flex" alignItems="start">
            <SuiBox sx={{ height: "100px" }}>
              <DragDrop files={setDragDropFile} readOnly={editData.readOnly} />
            </SuiBox>
            <SuiBox>
              <SuiBox display="flex" alignItems="center" sx={{ pl: "10px" }}>
                <SuiInput
                  disabled={editData.readOnly}
                  error={data.size.error}
                  success={data.size.success}
                  helperText={data.size.error_message}
                  type={data.size.inputType}
                  placeholder={data.size.placeholder1}
                  value={data.size.value1}
                  onChange={handleInput(index, data.maxLength, "size", 1)}
                />
                <span
                  style={{
                    width: "60px",
                    textAlign: "center",
                    paddingBottom: "7px",
                    color: " #9d9f9f",
                  }}
                >
                  x
                </span>
                <SuiInput
                  disabled={editData.readOnly}
                  error={data.size.error}
                  success={data.size.success}
                  helperText={data.size.error_message}
                  type={data.size.inputType}
                  placeholder={data.size.placeholder2}
                  value={data.size.value2}
                  onChange={handleInput(index, data.maxLength, "size", 2)}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <SuiBox sx={{ width: "500px", overflow: "auto" }}>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <SuiBox justifyContent="center" display="flex" alignItems="start" sx={{ height: "auto" }}>
            <SuiButton
              sx={{ height: 30, px: 2 }}
              color="info2"
              size="small"
              onClick={() => imgupload()}
            >
              파일선택
            </SuiButton>
          </SuiBox>
          <SuiBox>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    }
  }
  useEffect(() => {
    let array = [...InputLists]
    array[0].changed = true
    setInputLists(array)
  }, [DragDropFile])
  const CUcheck = () => {
    InputLists[0].file = DragDropFile
    if (editData.current === "add" && editData.type === "main") {
      saveDialogCreateMain(InputLists)
    } else if (editData.current === "add" && editData.type === "single") {
      saveDialogCreateSingle(InputLists)
    } else if (editData.current === "detail" && editData.type === "main") {
      saveDialogDetailMain(InputLists)
    } else if (editData.current === "detail" && editData.type === "single") {
      saveDialogDetailSingle(InputLists)
    }
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    setOpen(false)
  }
  const handleClose = () => {
    closeDialog()
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    setOpen(false)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ px: 3, pt: 3 }}>
        <SuiBox>
          <SuiBox display="flex">
            <SuiTypography fontSize="15px" sx={{ pb: 0.5, fontWeight: 400, color: "#668B9E" }}>
              {(index === 0 || index === 1) && <span>*</span>}
              {row.title}
            </SuiTypography>
            {index === 0 && (
              <SuiTypography
                fontSize="15px"
                sx={{ ml: "30%", pb: 0.5, fontWeight: 400, color: "#668B9E" }}
              >
                {row.size.title}
              </SuiTypography>
            )}
          </SuiBox>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })

  return editData.imgOnly ? (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <div
          style={{
            backgroundColor: "#f7f9fb",
            fontSize: "12px",
            textAlign: "start",
            padding: "12px",
          }}
          dangerouslySetInnerHTML={{ __html: current_img }}
        ></div>
      </Dialog>
    </div>
  ) : (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <SuiBox sx={{ p: 2, width: 600 }}>
          <SuiBox display="flex" alignItems="center" justifyContent="end">
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <MdClose style={{ width: "24px", height: "24px" }} />
            </IconButton>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center">
            <SuiTypography style={{ fontSize: "20px", fontWeight: "bold" }}>
              이미지 첨부하기 :{" "}
              {editData.type === "main" ? "공통" : editData.data?.real_name + "님"}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 4 }}>
            <SuiTypography style={{ fontSize: "16px", fontWeight: "medium" }} color="subTitle">
              설명글과 함께 이메일에 첨부할 이미지를 추가합니다.
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          {/* <Divider sx={{ my: 4 }} /> */}
          <DialogActions sx={{ px: 3, py: 3, mt: 7 }}>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{ width: 97.38, border: 1, borderColor: "#707070", height: 40, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              disabled={
                editData.readOnly ? true : DragDropFile.name === "" || InputLists[1].value === ""
              }
              sx={{ width: 100, height: 40, fontSize: 14 }}
              color="primary"
              onClick={() => CUcheck()}
            >
              등록하기
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </div>
  )
}

imgUpload.defaultProps = {
  openDialog: false,
  edit: false,
  editData: {
    type: "main",
  },
}

imgUpload.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.object,
  closeDialog: PropTypes.func,
  saveDialogCreateMain: PropTypes.func,
  saveDialogCreateSingle: PropTypes.func,
  saveDialogDetailMain: PropTypes.func,
  saveDialogDetailSingle: PropTypes.func,
}
export default imgUpload
