import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes, { element, number } from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"

import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Dialog from "@mui/material/Dialog"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MobileStepper from "@mui/material/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"

import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiDialog from "components/SuiDialog"
import DeleteDialog from "./deleteDialog"
import notice from "../notice"
// import DeleteDenyDialog from "../DeleteDenyDialog"
// import ChangeDialog from "../ChangeDialog"
import GetFileIcon from "components/getFileIcon"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import enLocale from "date-fns/locale/en-US"

import { BiCalendar, BiRecycle, BiImages } from "react-icons/bi"
import { BsFillArrowUpLeftSquareFill, BsFillCircleFill } from "react-icons/bs"
import { AiFillCloseCircle, AiOutlinePlus, AiOutlineUnorderedList } from "react-icons/ai"
import { CgUserAdd } from "react-icons/cg"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { FiSearch, FiMoreHorizontal } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { RiDeleteBinLine } from "react-icons/ri"
import { MdClose } from "react-icons/md"
import { FaThLarge, FaTh } from "react-icons/fa"
import { AiOutlineCloseCircle } from "react-icons/ai"
import DaumPostcode from "react-daum-postcode"
import dayjs from "dayjs"

import FileUploadDialog from "./fileUploadDialog"

// API
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
  createDonwloadLog,
  updateEventSignificant,
  createEventSignificant,
  updateEvent,
  createEventAttachment,
  updateEventAttachment,
  createRequest,
  updateRequest,
  createRecent,
  createEventExposure,
  updateEventExposure,
} from "apollo/mutation"
import { events, eventSignificants, me, settings, eventExposures } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply, editStatus_Apply } from "reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"

function detailInfo(props) {
  const {
    openDialog,
    closeDialog,
    closeDialog2,
    inputListsExport,
    inputDetailListsExport,
    inputEventContentExport,
    referrerIdsExport,
    AttachmentDatasExport,
    MeetingDatasExport,
    SignificantsDatasExport,
    ExposureDatasExport,
    editId,
    type,
    edit,
    index,
    saveExit,
  } = props

  // 파일 다운로드 로그
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const { myData } = useSelector((state) => state.store)
  // 이벤트 기본정보
  let input_lists_set = [
    {
      file: null,
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "이벤트 명:",
      maxLength: 10,
      xs: 8,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "flatform",
      items: [
        "YES24",
        "교보문고",
        "네이버",
        "리디북스",
        "무툰",
        "문피아",
        "미스터블루",
        "봄툰",
        "북큐브",
        "블라이스",
        "알라딘",
        "에피루스",
        "원스토어",
        "조아라",
        "카카오페이지",
      ],
      title: "플랫폼:",
      xs: 4,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "monthPicker",
      setting_type: "start",
      title: "이벤트 시작 일:",
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "monthPicker",
      setting_type: "end",
      title: "이벤트 종료 일:",
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "dateLength",
      setting_type: "flatform",
      title: "이벤트 기간:",
      xs: 2,
    },
    {
      inputType: "text",
      value: "예정",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "status",
      items: ["예정", "진행중", "완료", "취소"],
      title: "이벤트 진행 여부:",
      xs: 4,
    },
  ]
  // 이벤트 상세정보
  let input_detail_lists_set = [
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "flatform",
      items: ["단행", "연재"],
      title: "단행/연재:",
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "type",
      items: ["신작 단독", "신작 통합", "구작 단독", "구작 통합", "기타"],
      title: "이벤트 종류:",
      xs: 3,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "start",
      items: ["해당", "해당 없음"],
      title: "메인/신작 타겟:",
      xs: 2,
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "end",
      items: ["구매 할인", "대여 할인", "단행 무료", "연재 무료", "재정가", "대여권", "기타"],
      title: "기본 리워드:",
      xs: 4,
    },
    {
      inputType: "text",
      value: [],
      error_message: "",
      error: false,
      open: false,
      type: "selectBox",
      setting_type: "publisher",
      items: ["조아라", "휘슬북", "이색", "미드나잇"],
      title: "출판사:",
      xs: 12,
    },
  ]
  let InputListsSetData = [
    { type: "picUpload", title: "파일 업로드:", xs: 6, file: {}, changed: false },
    {
      items: ["회사소개서", "통장사본", "사업자등록증", "포트폴리오", "신분증", "기타"],
      itemsValue: "회사소개서",
      value: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "유형:",
      maxLength: 30,
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      version: "최신",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "fileList",
      title: "",
      maxLength: 255,
      xs: 6,
    },
    // {
    //   inputType: "text",
    //   value: "",
    //   error_message: "",
    //   error: false,
    //   type: "textArea",
    //   title: "설명:",
    //   maxLength: 30,
    //   xs: 6,
    // },
  ]
  const dispatch = useDispatch()
  const [order, set_order] = React.useState("asc")
  const [order_by, set_order_by] = React.useState("calories")
  const [row_length, set_row_length] = useState(0)
  const handleRequestSort = (event, property) => {
    const isAsc = order_by === property && order === "asc"
    set_order(isAsc ? "desc" : "asc")
    set_order_by(property)
  }
  const [input_lists, set_input_lists] = useState(input_lists_set) // 기본정보
  const [input_detail_lists, set_input_detail_lists] = useState(input_detail_lists_set) // 상세정보
  const [input_event_content, set_input_event_content] = useState("") // 이벤트 내용
  const inputEventContentRef = useRef("") // textField 인풋렉을 없애기 위해 임시로 담는 이벤트 내용값
  const inputEvenExposureRef = useRef("") // textField 인풋렉을 없애기 위해 임시로 담는 이벤트 내용값
  const [edit_data, set_edit_data] = useState([]) // 파일업로드 수정시 다이어로그 오픈때  담길 데이터
  const [edit_bool, set_edit_bool] = useState(false) // 파일업로드 생성 or 수정/삭제 판단여부
  const [delete_data, set_delete_data] = useState([]) // 파일업로드삭제시  다이어로그 오픈때  담길 데이터
  const [delete_datas, set_delete_datas] = useState([]) // 삭제될 파일들 목록
  const [file_upload_dialog_bool, set_file_upload_dialog_bool] = useState(false) // 파일업로드 다이어로그 오픈여부
  const [open_delete_dialog, set_open_delete_dialog] = useState(false) // 삭제시 확인 다이어그램
  const [historySplice, sethistorySplice] = useState(5)
  const [FilesSorting, setFilesSorting] = useState("최신순") // 첨부파일 정렬 기분
  const [AttachmentData, setAttachmentData] = useState([]) // 파일 업로드 다이어로그에서 저장시 넘어오는부분
  const [AttachmentDatas, setAttachmentDatas] = useState([]) // 파일 업로드한 파일들 목록
  const [Significants, setSignificants] = useState([]) // 특이사항
  const [Exposures, setExposures] = useState([]) // 노출영역
  const [createContractShow, setcreateContractShow] = useState(false)
  const [all_event_data, set_all_event_data] = useState({
    // 이벤트 전체 데이터
    manager: {
      userName: "",
      userPosition: "",
      userProfile: "",
      userDepartment: "",
    },
    history: [],
    requests: [],
  })
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [Delete_Content, setDelete_Content] = useState("")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const [FiltersOpen2, setFiltersOpen2] = useState(false)
  const [FiltersOpen3, setFiltersOpen3] = useState(false)
  const [FiltersOpen4, setFiltersOpen4] = useState(false)
  const [FiltersOpen5, setFiltersOpen5] = useState(false)
  const [FiltersOpen6, setFiltersOpen6] = useState(false)
  const [typeData, settypeData] = useState(type)
  const client = useApolloClient()
  useEffect(() => {
    setTimeout(() => {
      callSettingData()
      settypeData(type)
      settingBasicInfo()
    }, 500)
  }, [openDialog])
  useEffect(() => {
    setTimeout(() => {
      settypeData(type)
      if (type === "detail") {
        settingBasicInfo()
      }
    }, 500)
  }, [type])

  const callSettingData = async () => {
    let variables = []
    await client
      .query({
        query: settings,
        variables: {
          typeList: ["eventFlatform", "eventType", "eventReward", "eventPublisher"],
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        let flatformData = res.data.settings[0] ? res.data.settings[0] : []
        let typeData = res.data.settings[1] ? res.data.settings[1] : []
        let rewardData = res.data.settings[2] ? res.data.settings[2] : []
        let publisherData = res.data.settings[3] ? res.data.settings[3] : []
        //플랫폼 items 추가 부분
        let newFlatformItems = flatformData.list
          .slice(1)
          .map((item) => (typeof item === "object" ? item.value.trim() : item.trim()))
        let flatformType = input_lists_set.find((item) => item.title === "플랫폼:")
        flatformType.items = Array.from(new Set([...flatformType.items, ...newFlatformItems]))
        // 이벤트 종류 items 추가 부분
        let newTypeItems = typeData.list.slice(1)
        let eventType = input_detail_lists_set.find((item) => item.title === "이벤트 종류:")
        eventType.items = [...new Set([...eventType.items, ...newTypeItems])]

        // 기본 리워드 items 추가 부분
        let newRewardItems = rewardData.list
        let rewardType = input_detail_lists_set.find((item) => item.title === "기본 리워드:")
        rewardType.items = [...new Set([...rewardType.items, ...newRewardItems])]

        // 출판사 items 추가 부분
        let newPublisherItems = publisherData.list
        let publisherType = input_detail_lists_set.find((item) => item.title === "출판사:")
        publisherType.items = [...new Set([...publisherType.items, ...newPublisherItems])]
      })
  }
  const settingBasicInfo = async () => {
    if (type === "detail") {
      dispatch(loading_Apply(true))
      setDetailEventContent(true)
      await client
        .query({
          query: eventSignificants,
          variables: {
            eventId: String(editId),
          },
          fetchPolicy: "network-only",
        })
        .then((sigRes) => {
          let eventSignificantData = sigRes.data
          setSignificants(eventSignificantData.eventSignificants)
        })
      await client
        .query({
          query: eventExposures,
          variables: {
            eventId: String(editId),
          },
          fetchPolicy: "network-only",
        })
        .then((sigRes) => {
          let eventExposureData = sigRes.data
          setExposures(eventExposureData.eventExposures)
        })

      //내용보기, 상세보기시에 데이터 불러와서 할당하는 코드
      await client
        .query({
          query: events,
          variables: {
            id: [editId],
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          let EventData = res.data
          // set_delete_data(EventData) // 확인 필요
          set_all_event_data(EventData.events[0])
          let array = [...input_lists]
          array[0].value = EventData.events[0].title
          array[1].value = EventData.events[0].flatform
          array[2].value = dayjs(EventData.events[0].start).format("YYYY-MM-DD")
          array[3].value = dayjs(EventData.events[0].end).format("YYYY-MM-DD")
          let status

          if (EventData.events[0].status === "cancel") {
            status = "취소"
          } else {
            if (
              dayjs(EventData.events[0].start).format("YYYY.MM.DD") >
              dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              status = "예정"
            } else if (
              dayjs(EventData.events[0].start).format("YYYY.MM.DD") <=
                dayjs(new Date()).format("YYYY.MM.DD") &&
              dayjs(EventData.events[0].end).format("YYYY.MM.DD") >=
                dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              status = "진행중"
            } else if (
              dayjs(EventData.events[0].end).format("YYYY.MM.DD") <
              dayjs(new Date()).format("YYYY.MM.DD")
            ) {
              status = "완료"
            }
          }
          array[5].value = status

          let detailArray = [...input_detail_lists]
          detailArray[0].value = EventData.events[0].serial
          detailArray[1].value = EventData.events[0].type
          detailArray[2].value = EventData.events[0].newOne
          detailArray[3].value = EventData.events[0].reward

          detailArray[4].value = EventData.events[0].publisher
            ? EventData.events[0].publisher.split("_").filter((x) => x !== "")
            : []

          let contentEvent = [...input_event_content]
          contentEvent = EventData.events[0].content
          inputEventContentRef.current = contentEvent
          set_input_event_content(contentEvent)
          let arrayAttachments = []
          EventData.events[0].event_attachments.forEach((element) => {
            InputListsSetData[0].file = element.file[0]
            InputListsSetData[1].id = element.id
            InputListsSetData[1].value = element.title
            InputListsSetData[1].itemsValue = element.type
            InputListsSetData[1].name = element.users_permissions_user.name
            InputListsSetData[1].position = element.users_permissions_user.position
            InputListsSetData[1].department = element.users_permissions_user.department
            InputListsSetData[1].created_at = element.created_at
            // InputListsSetData[3].value = element.explain
            arrayAttachments.push({ data: JSON.parse(JSON.stringify(InputListsSetData)) })
          })
          setAttachmentDatas(
            arrayAttachments.sort((a, b) => {
              var dateA = new Date(a.data[1].created_at).getTime()
              var dateB = new Date(b.data[1].created_at).getTime()
              return dateA < dateB ? 1 : -1
            })
          )
          setTimeout(() => {
            dispatch(loading_Apply(false))
          }, 2000)
        })
    }
  }

  //이벤트 삭제 신청
  const Delete_All = () => {
    dispatch(loading_Apply(true))
    const request = {
      id: myData.id,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      role: myData.role.name,
      suggestRole: "이벤트 삭제신청",
      profile: myData.profile,
      requestExplain: requestExplainData,
      title: all_event_data.title,

      eventId: all_event_data.id,
    }
    if (all_event_data.history.length > 0) {
      request.first_history = all_event_data.history[all_event_data.history.length - 1]
    }
    createRequestActive({
      variables: {
        status: `삭제 신청 심사중(${dayjs(new Date()).format("YY.MM.DD HH:mm")})`,
        content: request,
        type: "Event",
        event: all_event_data.id,
        requestId: all_event_data.id,
        users_permissions_user: myData.id,
      },
    }).then(() => {
      // usersDatas
      //   .filter((x) => x.role.id === 1)
      //   .forEach((user) => {
      //     if (user.adminAlertSetting) {
      //       if (user.adminAlertSetting[2].value) {
      //         createAlertActive({
      //           variables: {
      //             users_permissions_user: user.id,
      //             title: "작가 삭제 신청",
      //             type: "Delete",
      //             userid: user.id,
      //             content: {
      //               id: myData.id,
      //               profile: myData.profile,
      //               position: myData.position,
      //               department: myData.department,
      //               name: myData.name,
      //               profile: myData.profile,
      //               DetailData: DetailData,
      //               targetName: DetailData.ebookname,
      //             },
      //           },
      //         })
      //       }
      //     }
      //   })
      const editHistory = [...all_event_data.history]
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content:
          "<strong>" + all_event_data.title + " 이벤트 </strong> 정보 삭제를 신청하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "waiting",
          history: editHistory,
        },
      })
      dispatch(loading_Apply(false))
      setDelete_Dialog(false)
    })
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(0)

  const [InputValue, setInputValue] = useState("")
  const [InputExposureValue, setInputExposureValue] = useState("")
  const [openDeleteJudging_Dialog, setopenDeleteJudging_Dialog] = useState(false)
  const [openChangeJudging_Dialog, setopenChangeJudging_Dialog] = useState(false)
  const [DeleteJudging_Content, setDeleteJudging_Content] = useState("")
  const [ChangeJudging_Content, setChangeJudging_Content] = useState("")
  const [open_DenyReasonDialog, setopen_DenyReasonDialog] = useState(false)
  const [denyReason, setdenyReason] = useState("")
  const [ContractType, setContractType] = useState("create")
  const InfoAlertSet = {
    dialog: false,
    title: "",
    content: "",
    CanBtnText: "확인",
  }
  const [InfoAlert, setInfoAlert] = useState(InfoAlertSet)

  const [MeetingData, setMeetingData] = useState([])
  const [MeetingDatas, setMeetingDatas] = useState([])
  const [ContractList, setContractList] = useState([])
  const [selectContarct, setselectContarct] = useState([])
  const [usersDatas, setusersDatas] = useState([])
  const [openInfo_Dialog, setopenInfo_Dialog] = useState(false)
  const [openDeleteInfo_Dialog, setopenDeleteInfo_Dialog] = useState(false)
  const [openCantDelete_Dialog, setopenCantDelete_Dialog] = useState(false)
  const [CopyContractData, setCopyContractData] = useState([])
  const [snackState, setsSnackState] = React.useState(false)
  const [editTeamSelect, seteditTeamSelect] = useState("")
  const [editMangerSelect, seteditMangerSelect] = useState("")
  const [editMangerContractData, seteditMangerContractData] = useState([])
  const [MaxHistoryList, setMaxHistoryList] = useState(4)
  const [test, settest] = useState("")
  const [exposuretest, setexposuretest] = useState("")
  const [idx, setidx] = useState("")
  const [exposureidx, setexposureidx] = useState("")
  const [stepidx, setstepidx] = useState(0)
  const [exposurestepidx, setexposurestepidx] = useState(0)
  const [updateinputvalue, setupdateinputvalue] = useState("")
  const [updateinputexposurevalue, setupdateinputexposurevalue] = useState("")
  const [DeleteId, setDeleteId] = useState([])
  const [ExposureDeleteId, setExposureDeleteId] = useState([])
  const [UpdateData, setUpdateData] = useState([])
  const [ExposureUpdateData, setExposureUpdateData] = useState([])
  const [openContractInfoDialog, setopenContractInfoDialog] = useState(false)
  const [openNoContract_dialog, setopenNoContract_dialog] = useState(false)
  const [InfoData, setInfoData] = useState([])
  const [SubInfoData, setSubInfoData] = useState([])
  const [openSignificantDelete_Dialog, setopenSignificantDelete_Dialog] = useState(false)
  const [openExposureDelete_Dialog, setopenExposureDelete_Dialog] = useState(false) // 노출영역 삭제시 얼럿
  const [dialogContent, setDialogContent] = useState("올바른 개인 상세 정보를 입력해 주세요.")

  // dataExport
  const [alert_Dialog, setalert_Dialog] = useState({
    open: false,
    title: "",
    content: "",
  })
  // 입력한 데이터를 상위 컴포넌트로 넘겨주기
  useEffect(() => {
    inputListsExport(input_lists)
  }, [input_lists])
  useEffect(() => {
    inputDetailListsExport(input_detail_lists)
  }, [input_detail_lists])
  useEffect(() => {
    inputEventContentExport(input_event_content)
  }, [input_event_content])
  useEffect(() => {
    AttachmentDatasExport(AttachmentDatas)
  }, [AttachmentDatas])
  useEffect(() => {
    SignificantsDatasExport(SignificantsDatasExport)
  }, [SignificantsDatasExport])
  useEffect(() => {
    ExposureDatasExport(ExposureDatasExport)
  }, [ExposureDatasExport])

  //이벤트정보 업데이트
  const updateEventActiveBtn = async (e) => {
    const editHistory = [...all_event_data.history]
    dispatch(loading_Apply(true))
    //id가 있는 특이사항을 수정했을경우
    if (UpdateData.length > 0) {
      for (let i = 0; i < UpdateData.length; i++) {
        if (UpdateData[i].id) {
          updateEventSignificantActive({
            variables: {
              id: UpdateData[i].id,
              content: UpdateData[i].content,
            },
          })
        }
      }
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>특이사항</strong>을 수정하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      setUpdateData([])
    }
    //id가 있는 특이사항을 삭제했을경우
    if (DeleteId.length > 0) {
      for (let i = 0; i < DeleteId.length; i++) {
        if (DeleteId[i] !== undefined) {
          updateEventSignificantActive({
            variables: {
              id: DeleteId[i],
              eventId: null,
            },
          })
        }
      }
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>특이사항</strong>을 삭제하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      setDeleteId([])
    }
    //id가 있는 노출영역을 수정했을경우
    if (ExposureUpdateData.length > 0) {
      for (let i = 0; i < ExposureUpdateData.length; i++) {
        if (ExposureUpdateData[i].id) {
          updateEventExposureActive({
            variables: {
              id: ExposureUpdateData[i].id,
              content: ExposureUpdateData[i].content,
            },
          })
        }
      }
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>노출 영역</strong>을 수정하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      setExposureUpdateData([])
    }
    //id가 있는 노출영역을 삭제했을경우
    if (ExposureDeleteId.length > 0) {
      for (let i = 0; i < ExposureDeleteId.length; i++) {
        if (ExposureDeleteId[i] !== undefined) {
          updateEventExposureActive({
            variables: {
              id: ExposureDeleteId[i],
              eventId: null,
            },
          })
        }
      }
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>노출 영역</strong>을 삭제하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
      setExposureDeleteId([])
    }

    //기본 정보를 수정했을 시
    let changeFiled = []
    input_lists.forEach((element) => {
      if (element.changed) {
        changeFiled.push(element.title.replace(":", ""))
      }
      //수정 이후 히스토리에 남아있는 데이터 분기점 삭제
      element.changed = false
    })
    // 상세정보를 수정했을 시
    let changeDetailFiled = []
    input_detail_lists.forEach((element) => {
      if (element.changed) {
        changeDetailFiled.push(element.title.replace(":", ""))
      }
      //수정 이후 히스토리에 남아있는 데이터 분기점 삭제
      element.changed = false
    })

    //기본정보, 상세정보, 이벤트내용이 빈칸인지 확인
    for (let i = 0; i < input_lists.length; i++) {
      if (i !== 4 && input_lists[i].value === "") {
        let sliceContent = input_lists[i].title.slice(0, -1)
        setDialogContent(`${sliceContent}의 정보를 정확히 입력해주세요`)
        setopenInfo_Dialog(true)
        dispatch(loading_Apply(false))
        return
      }
    }
    for (let i = 0; i < input_detail_lists.length; i++) {
      if (i === 4) {
        if (input_detail_lists[4].value.length === 0) {
          setDialogContent(`출판사의 정보를 정확히 입력해주세요.`)
          setopenInfo_Dialog(true)
          dispatch(loading_Apply(false))
          return
        }
      } else {
        if (input_detail_lists[i].value === "") {
          let sliceContent = input_detail_lists[i].title.slice(0, -1)
          setDialogContent(`${sliceContent}의 정보를 정확히 입력해주세요`)
          setopenInfo_Dialog(true)
          dispatch(loading_Apply(false))
          return
        }
      }
    }

    if (input_event_content === "") {
      setDialogContent(`이벤트 내용의 정보를 정확히 입력해주세요`)
      setopenInfo_Dialog(true)
      dispatch(loading_Apply(false))
      return
    }

    //첨부파일 수정
    let addAttachmentChanged = false
    let changed_attachment = []
    AttachmentDatas.forEach((element) => {
      if (!element.data[1].id) {
        addAttachmentChanged = true
      } else {
        let idFilterd = all_event_data.event_attachments.filter((el) => {
          return el.id === element.data[1].id
        })
        if (idFilterd[0].type !== element.data[1].itemsValue) {
          changed_attachment.push(idFilterd[0])
        } else if (idFilterd[0].title !== element.data[1].value) {
          changed_attachment.push(idFilterd[0])
        } else if (!element.data[0].file.id) {
          changed_attachment.push(idFilterd[0])
        }
      }
    })

    if (changed_attachment.length > 0) {
      changed_attachment.forEach((el) => {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>첨부파일 " + el.type + "</strong>을(를) 수정하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      })
    }

    //첨부파일 삭제시
    if (delete_datas.length > 0) {
      delete_datas.forEach((del) => {
        editHistory.unshift({
          profile: myData.profile ? myData.profile.url : null,
          name: myData.name,
          position: myData.position,
          department: myData.department,
          content: "<strong>첨부파일 " + del[1].itemsValue + "</strong>을(를) 삭제하였습니다.",
          date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
        })
      })
    }

    //기본정보 변경시 히스토리에 삽입될 문구
    if (changeFiled.length > 0) {
      let sideText
      if (changeFiled.join(", ").endsWith("부")) {
        sideText = "를 "
      } else {
        sideText = "을 "
      }
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>" + changeFiled.join(", ") + "</strong>" + sideText + "수정하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }

    //상세정보 변경시 히스토리에 삽입될 문구
    if (changeDetailFiled.length > 0) {
      let sideText = "를"

      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content:
          "<strong>" + changeDetailFiled.join(", ") + "</strong>" + sideText + "수정하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }
    if (addAttachmentChanged) {
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>첨부파일</strong>을 등록하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }
    //이벤트 내용 변경시 히스토리에 삽입될 문구
    if (all_event_data.content !== input_event_content) {
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>이벤트 내용</strong>을 수정하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }

    //특이사항 추가시
    let significantAdded = 0
    for (let i = 0; i < Significants.length; i++) {
      if (!Significants[i].id) {
        significantAdded++
        await createEventSignificantActive({
          variables: {
            content: Significants[i].content,
            userName: Significants[i].userName,
            userPosition: Significants[i].userPosition,
            userProfile: Significants[i].userProfile,
            userDepartment: Significants[i].userDepartment,
            eventId: String(editId),
            userID: String(Significants[i].userID),
          },
        })
      }
    }
    if (significantAdded > 0) {
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>특이사항</strong>을 등록하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }
    //노출영역 추가시
    let exposuresAdded = 0
    for (let i = 0; i < Exposures.length; i++) {
      if (!Exposures[i].id) {
        exposuresAdded++
        await createEventExposureActive({
          variables: {
            content: Exposures[i].content,
            userName: Exposures[i].userName,
            userPosition: Exposures[i].userPosition,
            userProfile: Exposures[i].userProfile,
            userDepartment: Exposures[i].userDepartment,
            eventId: String(editId),
            userID: String(Exposures[i].userID),
          },
        })
      }
    }
    if (exposuresAdded > 0) {
      editHistory.unshift({
        profile: myData.profile ? myData.profile.url : null,
        name: myData.name,
        position: myData.position,
        department: myData.department,
        content: "<strong>노출 영역</strong>을 등록하였습니다.",
        date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
      })
    }
    let dt = new Date()
    const editManager = {
      userName: all_event_data.manager.userName,
      userPosition: all_event_data.manager.userPosition,
      userDepartment: all_event_data.manager.userDepartment,
      userProfile: all_event_data.userProfile,
      update_at: dt,
    }
    // dispatch(loading_Apply(true))
    let publisher_data = ""
    for (let i = 0; i < input_detail_lists[4].value.length; i++) {
      if (i === 0) {
        publisher_data = input_detail_lists[4].value[i] + "_"
      } else {
        publisher_data = publisher_data + input_detail_lists[4].value[i] + "_"
      }
    }
    let variablesData = {
      id: all_event_data.id,
      title: input_lists[0].value,
      flatform: input_lists[1].value,
      start: dayjs(input_lists[2].value).format("YYYY-MM-DD"),
      end: dayjs(input_lists[3].value).format("YYYY-MM-DD"),
      status:
        input_lists[5].value === "진행중"
          ? "ing"
          : input_lists[5].value === "완료"
          ? "complete"
          : input_lists[5].value === "취소"
          ? "cancel"
          : "expected",
      serial: input_detail_lists[0].value,
      type: input_detail_lists[1].value,
      newOne: input_detail_lists[2].value,
      reward: input_detail_lists[3].value,
      publisher: publisher_data,
      history: editHistory,
      content: input_event_content,
      manager: editManager,
      users_permissions_user: myData.id,
    }
    if (delete_datas.length > 0) {
      variablesData.attachments = AttachmentDatas.map((x) => x.data[1].id).filter(
        (x) => !delete_datas.map((x) => x[1].id).includes(x)
      )
    }
    await updateEventActive({
      variables: variablesData,
    }).then(async (resEvent) => {
      createRecentActive({
        variables: {
          users_permissions_user: myData.id,
          title: "이벤트 정보 수정",
          type: "Event",
          srcId: resEvent.data.updateEvent.event.id,
          name: input_lists[0].value,
        },
      })

      //파일삭제 API
      if (delete_datas.length > 0) {
        delete_datas.forEach(async (element, index) => {
          if (element[1].id) {
            await client
              .mutate({
                mutation: updateEventAttachment,
                variables: {
                  id: element[1].id,
                  event: null,
                  eventId: null,
                },
                fetchPolicy: "network-only",
              })
              .then(() => {
                // 삭제된 항목을 delete_datas에서 제거
                delete_datas.splice(index, 1)
              })
          }
        })
      }
      //파일수정, 등록 API
      if (AttachmentDatas.length > 0) {
        AttachmentDatas.map((x) => x.data).forEach(async (element, index) => {
          if (element[0].changed) {
            if (element[1].id) {
              if (element[0].file.url) {
                await client.mutate({
                  mutation: updateEventAttachment,
                  variables: {
                    id: element[1].id,
                    title: element[1].value,
                    type: element[1].itemsValue,
                    // explain: element[3].value,
                    event: resEvent.data.updateEvent.event.id,
                    eventId: resEvent.data.updateEvent.event.id,
                    maker: myData.email,
                    users_permissions_user: myData.id,
                    position: myData.position,
                  },
                  fetchPolicy: "network-only",
                })
              } else {
                let data = new FormData()
                data.append("files", element[0].file)
                await axios
                  .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                  .then((res) => {
                    client.mutate({
                      mutation: updateEventAttachment,
                      variables: {
                        id: element[1].id,
                        file: [res.data[0].id],
                        title: element[1].value,
                        type: element[1].itemsValue,
                        // explain: element[3].value,
                        event: resEvent.data.updateEvent.event.id,
                        eventId: resEvent.data.updateEvent.event.id,
                        maker: myData.email,
                        users_permissions_user: myData.id,
                        position: myData.position,
                      },
                      fetchPolicy: "network-only",
                    })
                  })
              }
            } else {
              let data = new FormData()
              data.append("files", element[0].file)
              await axios
                .post(process.env.REACT_APP_BACKEND_URL + "/upload", data)
                .then((res) => {
                  client.mutate({
                    mutation: createEventAttachment,
                    variables: {
                      file: [res.data[0].id],
                      title: element[1].value,
                      type: element[1].itemsValue,
                      // explain: element[3].value,
                      event: resEvent.data.updateEvent.event.id,
                      eventId: resEvent.data.updateEvent.event.id,
                      maker: myData.email,
                      users_permissions_user: myData.id,
                      position: myData.position,
                    },
                    fetchPolicy: "network-only",
                  })
                })
                .catch(() => dispatch(loading_Apply(false)))
            }
          }
          if (AttachmentDatas.length - 1 === index) {
            dispatch(editStatus_Apply(false))
            settypeData("detail")

            setDetailEventContent(true)
            snackOpenClick()
          }
        })
      } else {
        dispatch(editStatus_Apply(false))
        settypeData("detail")
        setDetailEventContent(true)
        snackOpenClick()
      }

      setTimeout(() => {
        settingBasicInfo()
        if (e.target.value === "exit") {
          saveExit()
        } else {
          if (document.getElementById("saveEditBtn")) {
            document.getElementById("saveEditBtn").value = ""
          }
        }
        dispatch(loading_Apply(false))
      }, 2000)
    })
  }
  const [createRecentActive] = useMutation(createRecent, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
    refetchQueries: [me],
  })
  const [updateEventSignificantActive] = useMutation(updateEventSignificant, {
    onError(err) {
      alert("수정 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createEventSignificantActive] = useMutation(createEventSignificant, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateEventExposureActive] = useMutation(updateEventExposure, {
    onError(err) {
      alert("수정 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [createEventExposureActive] = useMutation(createEventExposure, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateEventActive] = useMutation(updateEvent, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 수정 오류 발생")
    },
    refetchQueries: [events],
  })
  const [createEventAttachmentActive] = useMutation(createEventAttachment, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 생성 오류 발생")
    },
    refetchQueries: [events],
  })
  const [updateEventAttachmentActive] = useMutation(updateEventAttachment, {
    fetchPolicy: "network-only",
    onError(err) {
      dispatch(loading_Apply(false))
      alert("첨부파일 수정시 오류 발생")
    },
    refetchQueries: [events],
  })
  const [createRequestActive] = useMutation(createRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [events],
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("거절 메세지 읽음처리 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  // 이관신청 취소 alert
  const [change_cancle_alert, setchange_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
    data: {},
  })
  const [open_ChagneDialog, setopen_ChagneDialog] = useState(false)
  // 삭제신청 취소 alert
  const [delete_cancle_alert, setdelete_cancle_alert] = useState({
    open: false,
    title: "",
    content: "",
    twoBtn: true,
    id: null,
    CanBtnText: "",
  })

  //삭제신청 취소 작업
  const delete_cancle_active = () => {
    updateRequestActive({
      variables: {
        id: delete_cancle_alert.id,
        status: `회원 취소 ${dayjs(new Date()).format("YY.MM.DD HH:mm")}`,
        decisionDate: new Date(),
        read: true,
      },
    }).then(() => {
      let data = { ...delete_cancle_alert }
      data.title = "삭제신청 취소 완료"
      data.content = "신청이 취소되었습니다."
      data.twoBtn = false
      data.CanBtnText = "확인"

      setdelete_cancle_alert(data)
      setcreateContractShow(true)

      updateEventActive({
        variables: {
          id: all_event_data.id,
          state: "active",
        },
      })
    })
  }
  const delete_cancle_alert_close = () => {
    let data = { ...delete_cancle_alert }
    data.open = false
    setdelete_cancle_alert(data)
  }

  // 첨부파일 초기작업
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
  function descendingComparator(a, b, order_by) {
    if (b[order_by] < a[order_by]) {
      return -1
    }
    if (b[order_by] > a[order_by]) {
      return 1
    }
    return 0
  }
  function getComparator(order, order_by) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, order_by)
      : (a, b) => -descendingComparator(a, b, order_by)
  }
  function EnhancedTableHead(props) {
    const {
      order,
      order_by,
      numSelected,
      rowCount,
      onRequestSort,
      changeFilter,
      showchangeFilter,
    } = props
    const headCells = [
      {
        id: "No",
        numeric: false,
        disablePadding: false,
        label: "버전",
      },
      {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "유형",
      },
      {
        id: "work",
        numeric: false,
        disablePadding: false,
        label: "내용",
      },
      {
        id: "start",
        numeric: false,
        disablePadding: false,
        label: "등록자",
      },
      {
        id: "end",
        numeric: false,
        disablePadding: false,
        label: "등록일",
      },
      {
        id: "price",
        numeric: false,
        disablePadding: false,
        label: "형식",
      },
    ]
    const [headCelldata, setheadCelldata] = useState(headCells)
    return (
      <TableHead>
        <TableRow>
          {headCelldata.map((headCell) => (
            <TableCell
              sx={{
                maxWidth: 150,
                px: 1,
                fontSize: "14px",
                borderLeft:
                  headCell.label === "내용" ||
                  headCell.label === "등록자" ||
                  headCell.label === "형식"
                    ? "1px solid #F1F1F5"
                    : "",
              }}
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={order_by === headCell.id ? order : false}
            >
              <TableSortLabel
                active={order_by === headCell.id}
                direction={order_by === headCell.id ? order : "asc"}
                className="table-head-sortLabel"
              >
                <span>{headCell.label}</span>
                {order_by === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.defaultProps = {
    showchangeFilter: {},
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    order: PropTypes.oneOf(["asc", "desc"]),
    order_by: PropTypes.string,
    rowCount: PropTypes.number,
    changeFilter: PropTypes.func,
    showchangeFilter: PropTypes.object,
  }

  const settings_ref = useRef(null)
  const settings_return = (type, items) => {
    let list = []
    if (type) {
      if (settings_ref.current) {
        let slice_index = settings_ref.current[type].findIndex((el) => el === "-")
        if (slice_index > -1) {
          list = settings_ref.current[type].splice(slice_index, 1)
        } else {
          list = settings_ref.current[type]
        }
      } else {
        list = items
      }
    } else {
      list = items
    }
    return list
  }

  const CryptoJS = require("crypto-js")
  const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
  // 두 날짜 사이 남은 날에 따른 색깔변화
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (secondDateObj.getTime() - firstDateObj.getTime() <= 0) {
      return "light2"
    }
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "error"
    } else {
      return "light2"
    }
  }
  // 두 날짜 사이의 날짜
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(5, 7),
      firstDate.substring(8, 10)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(5, 7),
      secondDate.substring(8, 10)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }

  const handleInput = (index) => (e) => {
    let arr_normal_info = [...input_lists]
    if (index === 0) {
      // 이벤트 명
      arr_normal_info[0].value = e.target.value
      if (typeData === "update") {
        arr_normal_info[index].changed = true
      }
    }
    set_input_lists(arr_normal_info)
  }

  const handleDatePickerChange = (index) => (e) => {
    let arr_normal_info = [...input_lists]
    if (index === 2) {
      if (typeData === "update") {
        arr_normal_info[2].changed = true
      }
      // 이벤트 시작일
      arr_normal_info[2].value = e
      if (e && arr_normal_info[3].value) {
        // 이벤트 시작일를 선택했을때 종료일도 있으면 진행여부 자동판단
        if (new Date(dayjs(e).format("YYYY-MM-DD")) > new Date()) {
          // 이벤트 시작전
          arr_normal_info[5].value = "예정"
        } else if (
          new Date(dayjs(e).format("YYYY-MM-DD")) < new Date() &&
          new Date() < new Date(dayjs(arr_normal_info[3].value).format("YYYY-MM-DD"))
        ) {
          // 이벤트 진행중
          arr_normal_info[5].value = "진행중"
        } else if (new Date(dayjs(arr_normal_info[3].value).format("YYYY-MM-DD")) < new Date()) {
          // 이벤트 완료
          arr_normal_info[5].value = "완료"
        }
      }
    } else if (index === 3) {
      if (typeData === "update") {
        arr_normal_info[3].changed = true
      }
      // 이벤트 종료일
      arr_normal_info[3].value = e
      if (e && arr_normal_info[3].value) {
        // 이벤트 종료일를 선택했을때 시작일도 있으면 진행여부 자동판단
        if (new Date(dayjs(arr_normal_info[2].value).format("YYYY-MM-DD")) > new Date()) {
          // 이벤트 시작전
          arr_normal_info[5].value = "예정"
        } else if (
          new Date(dayjs(arr_normal_info[2].value).format("YYYY-MM-DD")) < new Date() &&
          new Date() < new Date(dayjs(e).format("YYYY-MM-DD"))
        ) {
          // 이벤트 진행중
          arr_normal_info[5].value = "진행중"
        } else if (new Date(dayjs(e).format("YYYY-MM-DD")) < new Date()) {
          // 이벤트 완료
          arr_normal_info[5].value = "완료"
        }
      }
    }
    set_input_lists(arr_normal_info)
  }
  const handleSelectChange = (index, type) => (e) => {
    if (type === "normal") {
      // 기본정보 수정
      let arr_normal_info = [...input_lists] // 기본정보
      if (index === 1) {
        // 플랫폼
        if (typeData === "update") {
          arr_normal_info[index].changed = true
        }
        arr_normal_info[1].value = e.target.value
      } else if (index === 5) {
        // 이벤트 진행여부
        if (e.target.value === "취소") {
          // 이벤트가 취소일경우만 변경 (나머지는 기간에 따라 자동변경)
          if (typeData === "update") {
            arr_normal_info[index].changed = true
          }
          arr_normal_info[5].value = e.target.value
        }
      }
      set_input_lists(arr_normal_info)
    } else if (type === "detail") {
      // 상세정보 수정
      let arr_detail_info = [...input_detail_lists] // 상세정보
      if (index === 0) {
        // 단행/연재
        if (typeData === "update") {
          arr_detail_info[index].changed = true
        }
        arr_detail_info[0].value = e.target.value
      } else if (index === 1) {
        // 이벤트 종류
        if (typeData === "update") {
          arr_detail_info[index].changed = true
        }
        arr_detail_info[1].value = e.target.value
      } else if (index === 2) {
        // 메인/신작 타켓
        if (typeData === "update") {
          arr_detail_info[index].changed = true
        }
        arr_detail_info[2].value = e.target.value
      } else if (index === 3) {
        // 기본 리워드
        if (typeData === "update") {
          arr_detail_info[index].changed = true
        }
        arr_detail_info[3].value = e.target.value
      }
      set_input_detail_lists(arr_detail_info)
    }
  }
  const handleClickPublisher = (idx, data) => {
    let arr_detail_info = [...input_detail_lists] // 상세정보

    // 출판사
    if (typeData === "update") {
      arr_detail_info[4].changed = true
    }
    if (arr_detail_info[4].value.length > 0) {
      if (arr_detail_info[4].value.findIndex((x) => x === data) > -1) {
        arr_detail_info[4].value.splice(
          arr_detail_info[4].value.findIndex((x) => x === data),
          1
        )
      } else {
        arr_detail_info[4].value.push(data)
      }
    } else {
      arr_detail_info[4].value.push(data)
    }
    console.log(arr_detail_info[4].value)

    set_input_detail_lists(arr_detail_info)
  }
  // 기본정보,상세정보 타입별 그리는 부분
  const InputTypeEl = (data, index, type) => {
    // 이벤트 상세화면 (등록 x)
    if (typeData === "detail") {
      //  달력일때
      if (data.type === "monthPicker") {
        if (data.setting_type === "start") {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {dayjs(input_lists[2].value).format("YYYY.MM.DD")}
              </Card>
            </SuiBox>
          )
        } else {
          return (
            <SuiBox sx={{ width: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  height: 57,
                  backgroundColor: "#F7F9FB",
                  boxShadow: 0,
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 700,
                  pt: 1.8,
                  mb: 1,
                  color: "#535353",
                }}
              >
                {dayjs(input_lists[3].value).format("YYYY.MM.DD")}
              </Card>
            </SuiBox>
          )
        }
      }
      //이벤트 기간
      else if (data.type === "dateLength") {
        return (
          <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
            <Card
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.8,
                mb: 1,
              }}
            >
              <SuiBox
                display="flex"
                alignItems="center"
                sx={{
                  color: "#535353",
                }}
              >
                {input_lists[2].value && input_lists[3].value ? (
                  <div style={{ margin: "0 auto" }}>
                    {betweenDay(
                      dayjs(input_lists[2].value).format("YYYY.MM.DD"),
                      dayjs(input_lists[3].value).format("YYYY.MM.DD")
                    )}
                    일
                  </div>
                ) : (
                  <div style={{ margin: "auto" }}>자동 입력</div>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        )
      }
      // 달력, 이벤트 기간 이외
      else {
        if (data.setting_type === "publisher") {
          return (
            <Card
              sx={{
                width: "100%",
                minHeight: 57,
                // backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 1.2,
                mb: 1,
                color: "#535353",
              }}
            >
              <SuiBox display="flex" sx={{ width: "100%", flexFlow: "wrap" }}>
                {input_detail_lists[4].value.length > 0 &&
                  input_detail_lists[4].value.map((x, index) => {
                    return <SuiBox className="publisher_chip">{x}</SuiBox>
                  })}
              </SuiBox>
            </Card>
          )
        } else {
          return (
            <SuiBox sx={{ width: "100%" }}>
              {data.value?.length < 30 ? (
                <Card
                  sx={{
                    width: "100%",
                    height: 57,
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 700,
                    pt: 1.8,
                    mb: 1,
                    color: "#535353",
                  }}
                >
                  {index === 10 ? data.itemsValue + " / " + data?.value : data?.value}
                </Card>
              ) : (
                <Tooltip
                  arrow={false}
                  color="white"
                  classes={{ popper: "tooltip-light" }}
                  title={
                    <SuiBox>
                      <SuiTypography
                        style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                        verticalAlign="middle"
                      >
                        {data.value}
                      </SuiTypography>
                    </SuiBox>
                  }
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: 57,
                      backgroundColor: "#F7F9FB",
                      boxShadow: 0,
                      textAlign: "center",
                      fontSize: 18,
                      fontWeight: 700,
                      pt: 1.8,
                      mb: 1,
                      color: "#535353",
                    }}
                  >
                    {index === 10
                      ? data.itemsValue + " / " + data.value
                      : index === 6 && data.value.length > 30
                      ? data.value.substring(0, 30) + " ..."
                      : data.value}
                  </Card>
                </Tooltip>
              )}
            </SuiBox>
          )
        }
      }
    }
    // 등록, 수정일 경우
    else if (typeData === "create" || typeData === "update") {
      // 달력
      if (data.type === "monthPicker") {
        return (
          <SuiBox sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="event_datepicker"
                inputFormat={"YYYY-MM-DD"}
                value={data.value}
                minDate={data.setting_type === "end" ? input_lists[2].value : ""} // 이벤트 종료일 선택일경우 maxdate 설정 (종료일보다 시작일이 작게)
                maxDate={data.setting_type === "start" ? input_lists[3].value : ""} // 이벤트 시작일 선택일경우 mindat e설정 (시작일보다 종료일이 크게)
                onChange={handleDatePickerChange(index)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </SuiBox>
        )
      }
      // 텍스트 필드
      else if (data.type === "textFiled") {
        return (
          <SuiBox className="error_detail_none">
            <SuiInput
              error={data.error}
              success={data.success}
              helperText={data.error_message}
              type={data.inputType}
              placeholder=""
              value={data.value}
              disabled={data.value === "**********"}
              onChange={handleInput(index)}
            />
          </SuiBox>
        )
      }
      // 셀렉트 박스
      else if (data.type === "selectBox") {
        return data.setting_type === "publisher" ? (
          <SuiBox display="flex">
            <SuiBox display="flex" sx={{ width: "100%", flexFlow: "wrap" }}>
              {settings_return(data.setting_type, data.items).length > 0 &&
                settings_return(data.setting_type, data.items).map((x) => {
                  return (
                    <SuiBox
                      className={
                        input_detail_lists[4].value.indexOf(x) > -1
                          ? "publisher_chip cursor"
                          : "publisher_chip_no cursor"
                      }
                      onClick={() => handleClickPublisher(index, x)}
                    >
                      {x}
                    </SuiBox>
                  )
                })}
            </SuiBox>
          </SuiBox>
        ) : (
          <SuiBox>
            <FormControl
              className={
                data.value === "" ? "setting-selectbox select-none-active" : "setting-selectbox"
              }
              sx={{ width: "100%" }}
              onClick={(e) => {
                if (type === "normal") {
                  let change = [...input_lists]
                  change[index].open = !data.open
                  set_input_lists(change)
                } else {
                  let change = [...input_detail_lists]
                  change[index].open = !data.open
                  set_input_detail_lists(change)
                }
              }}
            >
              <InputLabel id="demo-controlled-open-select-label">{data.placeholder}</InputLabel>
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                  />
                )}
                open={data.open}
                value={data.value}
                label=""
                onChange={handleSelectChange(index, type)}
                className="setting-border-radius"
              >
                {settings_return(data.setting_type, data.items).map((x) => {
                  return (
                    <MenuItem
                      value={x}
                      key={x}
                      className={
                        data.setting_type === "status" && x !== "취소" ? "menu_disabled" : ""
                      }
                      disabled={data.setting_type === "status" && x !== "취소"}
                    >
                      {x}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </SuiBox>
        )
      } else {
        return (
          <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
            <Card
              sx={{
                width: "100%",
                height: 40,
                backgroundColor: "#F7F9FB",
                boxShadow: 0,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                pt: 0.8,
              }}
            >
              <SuiBox
                display="flex"
                alignItems="center"
                sx={{
                  color: "#535353",
                }}
              >
                {input_lists[2].value && input_lists[3].value ? (
                  <div style={{ margin: "0 auto" }}>
                    {betweenDay(
                      dayjs(input_lists[2].value).format("YYYY.MM.DD"),
                      dayjs(input_lists[3].value).format("YYYY.MM.DD")
                    )}
                    일
                  </div>
                ) : (
                  <div style={{ margin: "auto" }}>자동 입력</div>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        )
      }
    }
  }

  // 기본정보
  const InputListShow = input_lists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
        <SuiBox>
          <SuiBox display="flex">
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
              {row.title}
            </SuiTypography>
          </SuiBox>
          {InputTypeEl(row, index, "normal")}
        </SuiBox>
      </Grid>
    )
  })
  // 상세정보
  const InputDetailListShow = input_detail_lists.map((row, index) => {
    return (
      <Grid item xs={row.xs} key={row.title} sx={{ pr: 1.5 }}>
        <SuiBox>
          <SuiBox display="flex">
            <SuiTypography fontSize="15px" color="info" sx={{ textAlign: "left", pt: 2, pb: 1 }}>
              {row.title}
            </SuiTypography>
          </SuiBox>
          {InputTypeEl(row, index, "detail")}
        </SuiBox>
      </Grid>
    )
  })

  // 이벤트 내용 변경
  const handleInputEventContent = (e) => {
    inputEventContentRef.current = e.target.value
  }

  // 이벤트 내용에서 focus를 잃으면 set_input_event_content 실행
  const handleBlur = () => {
    set_input_event_content(inputEventContentRef.current)
  }

  // 이벤트 첨부파일 다이어로그 열기
  const addFilesBtn = () => {
    set_edit_bool(false)
    set_file_upload_dialog_bool(true)
  }
  // 이벤트 첨부파일 다이어로그 닫기
  const fileCloseDialog = () => {
    set_file_upload_dialog_bool(false)
  }
  // 이벤트 첨부파일 다이어로그 닫기
  const fileCloseDialog2 = () => {
    set_file_upload_dialog_bool(false)
  }
  // 파일 첨부 다이어그램 저장시 테이블에 넣어주기
  useEffect(() => {
    if (AttachmentData.length > 0) {
      let array = [...AttachmentDatas]
      array.unshift({ data: AttachmentData })
      setAttachmentDatas(array)
    }
  }, [AttachmentData])
  const firstItemCheck = (attachData) => {
    let valueData = AttachmentDatas.map((x) => x.data[1])
    let afterCheck = valueData.findIndex(
      (e) =>
        dayjs(e.created_at).isAfter(dayjs(attachData.data[1].created_at)) &&
        e.itemsValue === attachData.data[1].itemsValue
    )
    if (afterCheck === -1) {
      return true
    } else {
      return false
    }
  }
  // 삭제 확인시 삭제될 파일들 넘겨주기
  const deleteAttachmentData = () => {
    let array = [...AttachmentDatas]
    setAttachmentDatas(array.filter((x) => x.data[1].value !== delete_data[1].value)) // 삭제될 파일 제외시키기
    const arr_delete_data = [...delete_datas]
    arr_delete_data.push(delete_data)
    console.log(arr_delete_data)
    set_delete_datas(arr_delete_data) // 삭제된 파일들 모아주기
    set_open_delete_dialog(false)
  }
  // 이벤트 첨부파일 다운로드
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    if (file.url) {
      a.click()
    }
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  // 이벤트 첨부파일 pdf 보기
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    a.rel = "noopener noreferrer"
    document.body.appendChild(a)
    a.click()
  }
  const historyContent = (row) => {
    return (
      <>
        <span
          style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
          onClick={() => closeDialog()}
        >
          안내 발송 정보
        </span>
        <span>{row.content.split("</strong>")[1]}</span>
      </>
    )
  }
  const historyContentResult = (row) => {
    return (
      <>
        <span
          style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
          onClick={() => closeDialog2()}
        >
          결과 보고 정보
        </span>
        <span>{row.content.split("</strong>")[1]}</span>
      </>
    )
  }
  const AttachmentDatasShow_type2 = () => {
    return AttachmentDatas.length === 0 ? (
      <TableContainer
        sx={{ borderTop: "1px solid #F1F1F5", borderRadius: 0, boxShadow: 0, marginRight: "40px" }}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead
            order={order}
            order_by={order_by}
            onRequestSort={handleRequestSort}
            rowCount={row_length}
          />
        </Table>
        <SuiBox display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
          <SuiTypography fontSize="18px" color="dark" fontWeight="400">
            등록된 내역이 없습니다.
          </SuiTypography>
        </SuiBox>
      </TableContainer>
    ) : (
      <SuiBox
        sx={{
          width: "100%",
          paddingRight: "40px",
        }}
      >
        <TableContainer
          sx={{
            borderTop: "1px solid #F1F1F5",
            borderRadius: 0,
            boxShadow: 0,
            marginRight: "40px",
          }}
        >
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              order={order}
              order_by={order_by}
              onRequestSort={handleRequestSort}
              rowCount={row_length}
            />
            <TableBody>
              {stableSort(
                AttachmentDatas.slice(0, historySplice),
                getComparator(order, order_by)
              ).map((data, index) => {
                const fileName = data.data[0].file?.name
                const fileType = fileName?.split(".")[fileName?.split(".").length - 1]
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiTypography
                        className="td-Setting td-fontSetting"
                        sx={{
                          fontWeight: "bold",
                          // backgroundColor: firstItemCheck(data) ? "#FBE8F1" : "#EEEEEE",
                          color: firstItemCheck(data) ? "#E67DAF !important" : "#A4A4A4 !important",
                          // borderRadius: "8px",
                        }}
                      >
                        {firstItemCheck(data) ? "최신" : "구버전"}
                      </SuiTypography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {data.data[1].itemsValue}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{
                        borderRight: "1px solid #F1F1F5",
                        minWidth: "400px",
                        width: "400px",
                      }}
                    >
                      {data.data[1].value}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      {data.data[1].name} {data.data[1].position}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td-Setting td-fontSetting"
                      style={{ borderRight: "1px solid #F1F1F5" }}
                    >
                      {dayjs(data.data[1].created_at).format("YYYY.MM.DD. HH:mm")}
                    </TableCell>
                    <TableCell align="center" className="td-Setting td-fontSetting">
                      <SuiBox display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                          sx={{ mr: 1.5, p: 0 }}
                          className="cursorDefalut"
                          onClick={() => DownloadPdf(data.data[0].file)}
                        >
                          {GetFileIcon(fileType)}
                        </IconButton>
                        <SuiButton
                          sx={{
                            backgroundColor: "#EEEEEE",
                            minHeight: 24,
                            height: 24,
                            width: 24,
                            minWidth: 24,
                            p: 0,
                            ml: 1,
                            boxShadow: 0,
                            display: data.data[0].file.url ? "" : "none",
                          }}
                          onClick={() => showPdf(data.data[0].file)}
                        >
                          <FiSearch size="15" />
                        </SuiButton>

                        {typeData !== "update" && typeData !== "create" ? (
                          ""
                        ) : (
                          <div>
                            <IconButton
                              aria-label="more"
                              id={`long-button`}
                              aria-controls="long-menu"
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                              sx={{ color: "#000000" }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              sx={{ display: index === anchorElIndex ? "" : "none" }}
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleMenu}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                },
                              }}
                            >
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                수정
                              </MenuItem>
                              <MenuItem onClick={(e) => handleClose(data.data, e, index)}>
                                삭제
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </SuiBox>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {Math.ceil(historySplice / 5) < Math.ceil(AttachmentDatas.length / 5) ? (
          <SuiBox display="flex" justifyContent="center" sx={{ mt: "40px" }}>
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => {
                sethistorySplice(historySplice + 5)
              }}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }

  //특이사항 기록
  const onClickSignificant = () => {
    let dt = new Date()
    let profileurl = ""
    if (myData.profile) {
      profileurl = myData.profile.url
    }
    setSignificants([
      ...Significants,
      {
        content: InputValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        userID: myData.id,
        updated_at: dt,
      },
    ])

    SignificantsDatasExport([
      ...Significants,
      {
        content: InputValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        userID: myData.id,
        updated_at: dt,
      },
    ])

    const tag = document.getElementById("significantInputValue")
    tag.value = ""
    setInputValue("")
  }
  //노출영역 기록
  const onClickExposure = () => {
    let dt = new Date()
    let profileurl = ""
    if (myData.profile) {
      profileurl = myData.profile.url
    }
    setExposures([
      ...Exposures,
      {
        content: InputExposureValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        userID: myData.id,
        updated_at: dt,
      },
    ])

    ExposureDatasExport([
      ...Exposures,
      {
        content: InputExposureValue,
        userName: myData.name,
        userDepartment: myData.department,
        userPosition: myData.position,
        userProfile: profileurl,
        userID: myData.id,
        updated_at: dt,
      },
    ])

    const tag = document.getElementById("exposureInputValue")
    tag.value = ""
    setInputExposureValue("")
  }
  const open = Boolean(anchorEl)

  const [ChangeRequests, setChangeRequests] = useState([])
  const firstChangeRequestsActive = (id) => {
    changeRequestsActive({
      variables: {
        writersId: id,
        status: "신청",
      },
    })
      .then((res) => {
        setChangeRequests(res.data.changeRequests)
      })
      .catch((err) => {})
  }
  const handleClick = (event, index, row) => {
    setAnchorElIndex(index)
    setAnchorEl(event.currentTarget)
    if (row) {
      setAnchorElRowData(row)
    }
  }
  const handleMenu = () => {
    setAnchorEl(null)
  }

  const handleClose = (data, e, index) => {
    if (e.target.innerText === "수정") {
      set_edit_data(data)
      set_edit_bool(false)
      setTimeout(() => {
        set_edit_bool(true)
      }, 200)
      set_file_upload_dialog_bool(true)
    } else if (e.target.innerText === "삭제") {
      set_delete_data(data)
      set_open_delete_dialog(true)
    }
    setAnchorEl(null)
  }
  const inputValue = (e) => {
    setInputValue(e.target.value)
  }
  const inputExposureValue = (e) => {
    setInputExposureValue(e.target.value)
    // inputEvenExposureRef.current = e.target.value
  }
  // const handleInputExposureeBlur = (e) => {
  //   setInputExposureValue(e.target.value)
  // }

  const [detailEventContent, setDetailEventContent] = useState(type === "detail")

  const Content = () => {
    return (
      <div>
        <FileUploadDialog
          openDialog={file_upload_dialog_bool}
          closeDialog={fileCloseDialog}
          closeDialog2={fileCloseDialog2}
          edit={edit_bool}
          editData={edit_data}
          AttachmentData={setAttachmentData}
        />
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox p={5} display="flex" flexDirection="column" justifyContent="start" height="100%">
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                기본 정보
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <Grid container alignItems="center">
                {InputListShow}
              </Grid>
              <Divider style={{ margin: "20px 0", border: "0.1px dashed #e1e4e5" }} />
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  상세 정보
                </SuiTypography>
              </SuiBox>
              <Grid container alignItems="center">
                {InputDetailListShow}
              </Grid>
            </SuiBox>
          </SuiBox>
        </Card>
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox p={5} display="flex" flexDirection="column" justifyContent="start" height="100%">
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                이벤트 내용
              </SuiTypography>
            </SuiBox>
            {!detailEventContent && (
              <SuiBox
                mt={3}
                display="flex"
                justifyContent="center"
                width="100%"
                alignItems="center"
              >
                <TextField
                  multiline
                  rows={8}
                  sx={{ width: "100%", border: "none" }}
                  variant="outlined"
                  placeholder="이벤트 내용을 간단하게 입력해주세요."
                  defaultValue={inputEventContentRef.current}
                  onChange={handleInputEventContent}
                  onBlur={handleBlur}
                />
              </SuiBox>
            )}
            {detailEventContent && (
              <Grid sx={{ pr: 1.5 }}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 500,
                    p: 2,
                    mt: 3,
                  }}
                >
                  {input_event_content
                    .split("\n")
                    .map((line, index) =>
                      line.length > 0 ? <div key={index}>{line}</div> : <br />
                    )}
                </Card>
              </Grid>
            )}

            <SuiBox display="flex" sx={{ my: 1, width: "100%", overflowX: "auto", pb: 3 }}></SuiBox>
          </SuiBox>
        </Card>
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox
            p={5}
            pr={3}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            height="100%"
          >
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                노출 영역
              </SuiTypography>
            </SuiBox>
            {typeData === "create" || typeData === "update" ? (
              <SuiBox sx={{ mb: 2 }}>
                <SuiBox display="flex" sx={{ mt: 3, mr: 4 }}>
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                    }}
                    style={{ backgroundColor: "transparent" }}
                    alt="History"
                    src={
                      myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""
                    }
                  />

                  <TextField
                    multiline
                    rows={3}
                    id="exposureInputValue"
                    sx={{ ml: 1, width: "100%", border: "none" }}
                    variant="outlined"
                    placeholder="이벤트 내용을 간단하게 입력해주세요."
                    // defaultValue={inputEvenExposureRef.current}
                    onChange={inputExposureValue}
                    // onBlur={handleInputExposureeBlur}
                  />
                  {/* <SuiInput
                    type="textfield"
                    // className="significantInput"
                    id="exposureInputValue"
                    sx={{ ml: 1, height: 100 }}
                    placeholder="내용을 입력해 주세요."
                    onChange={inputExposureValue}
                  /> */}
                </SuiBox>

                <SuiBox sx={{ textAlign: "end", mt: 3, mr: 3.5 }}>
                  <SuiButton
                    color="info2"
                    sx={{
                      width: 100,
                      fontSize: 14,
                    }}
                    disabled={InputExposureValue.length > 0 ? false : true}
                    onClick={onClickExposure}
                  >
                    등록
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            ) : null}
            {exposure()}
          </SuiBox>
        </Card>
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox
            pl={5}
            pt={5}
            pb={2}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            height="100%"
          >
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                첨부 파일
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex" sx={{ my: 1, pr: 5 }}>
              {typeData !== "update" && typeData !== "create" ? (
                ""
              ) : (
                <SuiButton
                  sx={{ minHeight: 32, height: 32, width: 32, minWidth: 32, p: 1 }}
                  color="primary"
                  onClick={() => addFilesBtn()}
                >
                  <AiOutlinePlus size="20" />
                </SuiButton>
              )}
              <SuiBox sx={{ width: 100, ml: "auto", mb: "20px" }}>
                <FormControl
                  sx={{ minHeight: 30, width: "100%" }}
                  className={
                    FilesSorting === ""
                      ? "setting-selectbox select-none-active"
                      : "setting-selectbox"
                  }
                  onClick={() => setFiltersOpen3(!FiltersOpen3)}
                >
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        color="black"
                        className="select-icon cursor"
                        onClick={() => setFiltersOpen3(!FiltersOpen3)}
                      />
                    )}
                    open={FiltersOpen3}
                    value={FilesSorting}
                    onChange={(e) => setFilesSorting(e.target.value)}
                    className="setting-border-radius"
                  >
                    <MenuItem value="최신순">최신순</MenuItem>
                    <MenuItem value="오래된 순">오래된 순</MenuItem>
                  </Select>
                </FormControl>
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" sx={{ my: 1, width: "100%", overflowX: "auto", pb: 3 }}>
              {AttachmentDatasShow_type2()}
            </SuiBox>
          </SuiBox>
        </Card>
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox
            p={5}
            pr={3}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            height="100%"
          >
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                특이사항 기록
              </SuiTypography>
            </SuiBox>
            {typeData === "create" || typeData === "update" ? (
              <SuiBox sx={{ mb: 2 }}>
                <SuiBox display="flex" sx={{ mt: 3, mr: 4 }}>
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                    }}
                    style={{ backgroundColor: "transparent" }}
                    alt="History"
                    src={
                      myData.profile ? process.env.REACT_APP_BACKEND_URL + myData.profile.url : ""
                    }
                  />
                  <SuiInput
                    type="textfield"
                    // className="significantInput"
                    id="significantInputValue"
                    sx={{ ml: 1, height: 100 }}
                    placeholder="내용을 입력해 주세요."
                    onChange={inputValue}
                  />
                </SuiBox>

                <SuiBox sx={{ textAlign: "end", mt: 3, mr: 3.5 }}>
                  <SuiButton
                    color="info2"
                    sx={{
                      width: 100,
                      fontSize: 14,
                    }}
                    disabled={InputValue.length > 0 ? false : true}
                    onClick={onClickSignificant}
                  >
                    등록
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            ) : null}
            {significant()}
          </SuiBox>
        </Card>
        <Card sx={{ my: "16px", boxShadow: 0 }}>
          <SuiBox p={5} display="flex" flexDirection="column" justifyContent="start" height="100%">
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                이벤트 담당자
              </SuiTypography>
              <Grid container alignItems="center">
                <Grid item sx={{ pr: 2, width: "248px" }}>
                  <SuiBox display="flex" sx={{ mt: 1 }}>
                    <SuiTypography
                      fontSize="15px"
                      color="info"
                      sx={{ pt: 2, pb: 1, fontWeight: 400 }}
                    >
                      등록일자:
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                    <Card
                      display="flex"
                      alignItems="center"
                      sx={{
                        width: "100%",
                        height: 57,
                        backgroundColor: "#F7F9FB",
                        boxShadow: 0,
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#535353",
                      }}
                    >
                      <SuiBox
                        display="flex"
                        alignItems="center"
                        sx={{
                          color: "#535353",
                          height: "100%",
                        }}
                      >
                        <IconButton color="text2" sx={{ mb: 0, mx: 1 }}>
                          <BiCalendar />
                        </IconButton>
                        <div style={{ margin: "0 auto" }}>
                          {typeData === "create"
                            ? "자동 입력 사용"
                            : dayjs(all_event_data.created_at).format("YYYY-MM-DD")}
                        </div>
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </Grid>
                {typeData !== "create" && (
                  <Grid item sx={{ width: "335px" }}>
                    <SuiBox display="flex" sx={{ mt: 1 }}>
                      <SuiTypography
                        fontSize="15px"
                        fontWeight="bold"
                        color="info"
                        sx={{ pt: 2, pb: 1, fontWeight: 400 }}
                      >
                        등록자:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ textAlign: "start", width: "100%", mr: 2 }}>
                      <Card
                        sx={{
                          width: "100%",
                          height: 57,
                          backgroundColor: "#F7F9FB",
                          boxShadow: 0,
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: 700,
                          px: 3,
                        }}
                      >
                        <SuiBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ color: "#535353", height: "100%" }}
                        >
                          <div style={{ margin: "0 auto" }}>
                            {all_event_data.manager?.userDepartment}
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{
                              borderColor: "#EEEEEE",
                              mx: 1.5,
                              height: "50%",
                            }}
                          />
                          <div style={{ margin: "0 auto" }}>
                            {all_event_data.manager?.userName}{" "}
                            {all_event_data.manager?.userPosition}
                          </div>
                        </SuiBox>
                      </Card>
                    </SuiBox>
                  </Grid>
                )}
              </Grid>
            </SuiBox>
          </SuiBox>
        </Card>

        <Card sx={{ my: "16px", mb: 12, boxShadow: 0 }}>
          <SuiBox p={5} pr={3} display="flex" flexDirection="column" justifyContent="start">
            <SuiBox sx={{ textAlign: "start" }}>
              <SuiTypography mb="24px" fontSize="18px" fontWeight="bold" color="dark">
                히스토리
              </SuiTypography>
              <SuiBox className="history-container">
                {typeData === "create" ? (
                  <SuiTypography fontSize="18px" fontWeight="medium" color="text2">
                    변경 내용 저장시 히스토리에 기록됩니다.
                  </SuiTypography>
                ) : (
                  all_event_data.history?.map((row, index) => {
                    return index <= MaxHistoryList ? (
                      <Card
                        key={index}
                        sx={{
                          width: "100%",
                          height: 72,
                          backgroundColor: "#F7F9FB",
                          boxShadow: 0,
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: 700,
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <SuiBox display="flex" alignItems="center" justifyContent="center">
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                            }}
                            style={{ backgroundColor: "transparent" }}
                            alt="History"
                            src={row.profile ? process.env.REACT_APP_BACKEND_URL + row.profile : ""}
                          />
                          <SuiTypography
                            ml="14px"
                            fontSize="16px"
                            fontWeight="medium"
                            sx={{ color: "#0C65FF" }}
                          >
                            {row.department} {row.name} {row.position}
                          </SuiTypography>
                          <SuiTypography
                            ml="16px"
                            fontSize="16px"
                            fontWeight="medium"
                            sx={{ color: "#535353", width: "60%", textAlign: "left" }}
                          >
                            {row.content.indexOf("안내 발송 정보") > -1 ? (
                              historyContent(row)
                            ) : row.content.indexOf("결과 보고 정보") > -1 ? (
                              historyContentResult(row)
                            ) : (
                              <span dangerouslySetInnerHTML={{ __html: row.content }}></span>
                            )}
                          </SuiTypography>
                          <SuiTypography
                            mr="4px"
                            ml="auto"
                            fontSize="16px"
                            fontWeight="medium"
                            color="text2"
                          >
                            {row.date}
                          </SuiTypography>
                        </SuiBox>
                      </Card>
                    ) : (
                      ""
                    )
                  })
                )}
                {MaxHistoryList < all_event_data.history?.length - 1 ? (
                  <SuiBox
                    sx={{ pl: 3, mt: "40px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SuiButton
                      color="info2"
                      variant="text"
                      sx={{
                        width: 100,
                        border: 1,
                        borderColor: "#0C65FF",
                        height: 40,
                        mr: 2,
                        fontSize: 14,
                      }}
                      onClick={() => setMaxHistoryList(MaxHistoryList + 5)}
                    >
                      더보기
                    </SuiButton>
                  </SuiBox>
                ) : (
                  ""
                )}
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Card>
      </div>
    )
  }

  const cancelReset = () => {
    let array = [...input_lists]
    array[0].value = all_event_data.title
    array[1].value = all_event_data.flatform
    array[2].value = dayjs(all_event_data.start).format("YYYY-MM-DD")
    array[3].value = dayjs(all_event_data.end).format("YYYY-MM-DD")
    array[5].value =
      all_event_data.status === "complete"
        ? "완료"
        : all_event_data.status === "ing"
        ? "진행중"
        : all_event_data.status === "cancel"
        ? "취소"
        : "예정"
    let detailArray = [...input_detail_lists]
    detailArray[0].value = all_event_data.serial
    detailArray[1].value = all_event_data.type
    detailArray[2].value = all_event_data.newOne
    detailArray[3].value = all_event_data.reward
    detailArray[4].value = all_event_data.publisher
      ? all_event_data.publisher.split("_").filter((x) => x !== "")
      : []
    let contentEvent = [...input_event_content]
    contentEvent = all_event_data.content
    inputEventContentRef.current = contentEvent
    set_input_event_content(contentEvent)
    if (all_event_data.event_attachments.length > 0) {
      let arrayAttachments = [...AttachmentDatas]
      all_event_data.event_attachments.forEach((element) => {
        InputListsSetData[0].file = element.file[0]
        InputListsSetData[1].id = element.id
        InputListsSetData[1].value = element.title
        InputListsSetData[1].itemsValue = element.type
        InputListsSetData[1].name = element.users_permissions_user.name
        InputListsSetData[1].position = element.users_permissions_user.position
        InputListsSetData[1].department = element.users_permissions_user.department
        InputListsSetData[1].created_at = element.created_at
        // InputListsSetData[3].value = element.explain
      })
      arrayAttachments = [{ data: JSON.parse(JSON.stringify(InputListsSetData)) }]
      setAttachmentDatas(
        arrayAttachments.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    }

    settypeData("detail")
    setDetailEventContent(true)
    dispatch(editStatus_Apply(false))
  }
  //파일 최신, 오래된순 배치
  useEffect(() => {
    let array = [...AttachmentDatas]
    if (FilesSorting === "최신순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA < dateB ? 1 : -1
        })
      )
    } else if (FilesSorting === "오래된 순") {
      setAttachmentDatas(
        array.sort((a, b) => {
          var dateA = new Date(a.data[1].created_at).getTime()
          var dateB = new Date(b.data[1].created_at).getTime()
          return dateA > dateB ? 1 : -1
        })
      )
    }
  }, [FilesSorting])
  const update_event = async () => {
    dispatch(editStatus_Apply(false))
  }
  const authCheck = () => {
    if (typeData !== "create") {
      if (
        myData.role.name === "Authenticated" ||
        myData.role.name === "gold" ||
        myData.role.name === "silver"
      ) {
        return true
      }
    }
  }

  //승인/비승인 임시코드
  const notice = (data) => {
    let queryString = "notice" + "/" + data
    window.open(queryString, "_blank")
  }

  const deleteSelect = () => {
    if (myData.role.name === "silver") {
      if (all_event_data.users_permissions_user.id !== myData.id) {
        const InfoAlertSet1 = {
          dialog: true,
          title: "권한없음",
          content: "열람권한이 없습니다.",
          CanBtnText: "확인",
        }
        setInfoAlert(InfoAlertSet1)
        return
      }
    }
    if (all_event_data.requests.length > 0) {
      if (all_event_data.requests[0].status.indexOf("심사중") > -1) {
        setopenDeleteInfo_Dialog(true)
      } else {
        setDelete_Dialog_type("create")
        setDelete_Content(`${all_event_data.title}`)
        setDelete_Dialog(true)
        setrequestExplainData("")
      }
    } else {
      setDelete_Dialog_type("create")
      setDelete_Content(`${all_event_data.title}`)
      setDelete_Dialog(true)
      setrequestExplainData("")
    }
    setAnchorEl(null)
  }
  const openRequest = (data) => {
    setDelete_Dialog(true)
    if (data.status.indexOf("심사중") > -1) {
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.content.requestExplain)
      setDelete_Dialog_type("edit")
    } else {
      if (!data.content.read) {
        let content = JSON.parse(JSON.stringify(data.content))
        content.read = true
        updateRequestActive({
          variables: {
            id: data.id,
            content: content,
          },
        })
      }
      setDelete_Content(`${all_event_data.title}`)
      setrequestExplainData(data.declining)
      setDelete_Dialog_type("delete")
    }
  }

  const snackOpenClick = () => {
    setsSnackState(true)
  }
  const startEdit = () => {
    dispatch(loading_Apply(true))
    let array = [...input_lists]
    array.forEach((el) => {
      el.error = false
      el.error_message = ""
      el.open = false
    })
    settypeData("update")
    setDetailEventContent(false)
    dispatch(editStatus_Apply(true))
    dispatch(loading_Apply(false))
  }

  const open_change_dialog = () => {
    setopen_ChagneDialog(true)
  }
  const FooterForm = () => {
    return (
      <SuiBox
        display="flex"
        justifyContent="center"
        width="1320px"
        maxWidth="1320"
        alignItems="center"
        sx={{
          py: 2,
          position: "fixed",
          bottom: 0,
          boxShadow: 2,
          backgroundColor: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <Snackbar
          className="snackBar-center"
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackState}
          message="저장되었습니다"
          onClose={() => setsSnackState(false)}
        />
        {typeData === "detail" ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {all_event_data.requests.length > 0 &&
            all_event_data.requests[0].status.indexOf("회원 취소") === -1 ? (
              <SuiTypography
                onClick={() => {
                  if (
                    all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)
                      .length === 0
                  )
                    openRequest(all_event_data.requests[0])
                  else
                    setdelete_cancle_alert({
                      id: all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1)[0]
                        .id,
                      open: true,
                      title: "삭제신청 취소",
                      content: "관리자에게 삭제 신청 중입니다. 취소하시겠습니까?",
                      twoBtn: true,
                      CanBtnText: "취소",
                    })
                }}
                fontSize="16px"
                fontWeight="bold"
                color={
                  all_event_data.requests[0].status.indexOf("심사중") > -1 ? "error" : "secondary"
                }
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    all_event_data.requests[0].content.id === myData.id &&
                    !all_event_data.requests[0].content.read
                      ? ""
                      : "none",
                }}
              >
                {all_event_data.requests[0].status.replace("심사중", "")}
              </SuiTypography>
            ) : (
              ""
            )}
            {ChangeRequests.length > 0 ? (
              <SuiTypography
                onClick={() => setchagne_cancel_dailog(true)}
                fontSize="16px"
                fontWeight="bold"
                color={"error"}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display:
                    ChangeRequests[0].arrayContent.users.map((x) => x.id).indexOf(myData.id) > -1 ||
                    ChangeRequests[0].users_permissions_user.id === myData.id
                      ? ""
                      : "none",
                }}
              >
                이관 신청{dayjs(ChangeRequests[0].updated_at).format("YY.MM.DD HH:mm")}
              </SuiTypography>
            ) : (
              ""
            )}
            <IconButton
              aria-label="more"
              id={`long-button`}
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleClick(e, index)}
              sx={{ color: "#000000" }}
            >
              <FiMoreHorizontal />
            </IconButton>
            <Menu
              sx={{ display: 0 === anchorElIndex ? "" : "none" }}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenu}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                },
              }}
            >
              <MenuItem
                disabled={
                  all_event_data.requests.filter((x) => x.status.indexOf("심사중") > -1).length >
                    0 || ChangeRequests.length > 0
                }
                onClick={(e) => deleteSelect()}
                style={{ color: "red" }}
              >
                삭제신청
              </MenuItem>
            </Menu>
          </div>
        ) : (
          ""
        )}
        {typeData === "detail" ? (
          <SuiButton
            color="info2"
            sx={{ ml: "auto", width: 100, position: "absolute", fontSize: 14 }}
            onClick={() => startEdit()}
          >
            수정
          </SuiButton>
        ) : (
          <>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#707070",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => cancelReset()}
            >
              취소
            </SuiButton>
            <SuiButton
              color="info2"
              sx={{
                width: 100,
                fontSize: 14,
                visibility: typeData === "detail" ? "hidden" : "initial",
                display: typeData === "detail" ? "none" : "",
              }}
              onClick={(e) => {
                updateEventActiveBtn(e)
              }}
              id="saveEditBtn"
            >
              저장하기
            </SuiButton>
          </>
        )}
      </SuiBox>
    )
  }

  const updateInputValue = (data, e, index) => {
    setupdateinputvalue(e.target.value)
  }
  const updateInputExposureValue = (data, e, index) => {
    setupdateinputexposurevalue(e.target.value)
  }
  const eidt_Significant = (content, id, e, index, row) => {
    setstepidx(1)
    setidx(index)
    setupdateinputvalue(content)
    settest(content)
  }
  const eidt_Exposure = (content, id, e, index, row) => {
    setexposurestepidx(1)
    setexposureidx(index)
    setupdateinputexposurevalue(content)
    setexposuretest(content)
  }
  const eidt_finish_Significant = (row, id, event, i) => {
    let updatedSignificants = Significants.map((significant) => {
      if (significant === row) {
        return {
          ...significant,
          content: updateinputvalue,
        }
      }
      return significant
    })

    if (row.id) {
      const updatedRow = {
        ...row,
        content: updateinputvalue, // UpdateData에 수정된 내용을 반영
      }

      setUpdateData((prevData) => [...prevData.filter((item) => item.id !== row.id), updatedRow])
    }

    setSignificants(updatedSignificants)
    setstepidx(0)
    setidx("")
    settest("")
  }
  const eidt_finish_Exposure = (row, id, event, i) => {
    let updatedSignificants = Exposures.map((exposure) => {
      if (exposure === row) {
        return {
          ...exposure,
          content: updateinputexposurevalue,
        }
      }
      return exposure
    })

    if (row.id) {
      const updatedRow = {
        ...row,
        content: updateinputexposurevalue, // UpdateData에 수정된 내용을 반영
      }

      setExposureUpdateData((prevData) => [
        ...prevData.filter((item) => item.id !== row.id),
        updatedRow,
      ])
    }

    setExposures(updatedSignificants)
    setexposurestepidx(0)
    setexposureidx("")
    setexposuretest("")
  }
  const updateCancle = () => {
    setstepidx(0)
  }
  const updateExposureCancle = () => {
    setexposurestepidx(0)
  }
  const [MaxExposure, setMaxExposure] = useState(4)
  const exposure = () => {
    let data = [...Exposures]
    data.length > 1 &&
      data.sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at)
      })
    return (
      <SuiBox
        // display="flex"
        alignItems="center"
        sx={{
          mt: 2,
        }}
        className="significants-container"
      >
        {data.length > 0 &&
          data.map((row, index) => {
            let dt = new Date(row.updated_at)
            return index <= MaxExposure ? (
              <SuiBox
                key={index}
                display="flex"
                sx={{
                  mb: 3,
                }}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                  style={{ backgroundColor: "transparent" }}
                  src={row.userProfile ? process.env.REACT_APP_BACKEND_URL + row.userProfile : ""}
                />

                <Card
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "start",
                    fontSize: 18,
                    fontWeight: 700,
                    p: 2,
                    ml: 1,
                  }}
                >
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="light"
                      sx={{ color: "#0C65FF", width: "100%" }}
                    >
                      {row.userDepartment} {row.userName} {row.userPosition} •{" "}
                      {dt.toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </SuiTypography>

                    {(typeData === "create" || typeData === "update") &&
                      (Number(row.userID) === myData.id || myData.role.name === "Authenticated") &&
                      exposurestepidx === 0 && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_Exposure(row.content, row.id, event, index, row)
                            }}
                          >
                            수정
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              delete_Exposure(row.id, row)
                            }}
                          >
                            삭제
                          </SuiButton>
                        </>
                      )}
                    {(typeData === "create" || typeData === "update") &&
                      Number(row.userID) === myData.id &&
                      exposurestepidx === 1 &&
                      exposureidx === index && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_finish_Exposure(row, row.id, event, index)
                            }}
                          >
                            완료
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              updateExposureCancle()
                            }}
                          >
                            취소
                          </SuiButton>
                        </>
                      )}
                  </SuiBox>
                  {Number(row.userID) === myData.id &&
                  exposurestepidx === 1 &&
                  exposureidx === index ? (
                    <TextField
                      multiline
                      rows={3}
                      type="textfield"
                      className="significantInput"
                      id={`${index}+`}
                      sx={{ mt: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      defaultValue={exposuretest}
                      onChange={(e) => updateInputExposureValue(index, e, index)}
                    />
                  ) : (
                    <SuiTypography
                      fontSize="16px"
                      fontWeight="medium"
                      sx={{ color: "#535353", whiteSpace: "pre-line" }}
                    >
                      {row.content
                        .split("\n")
                        .map((line, index) =>
                          line.length > 0 ? <div key={index}>{line}</div> : <br />
                        )}
                    </SuiTypography>
                  )}
                </Card>
              </SuiBox>
            ) : (
              ""
            )
          })}
        {MaxExposure < data.length - 1 ? (
          <SuiBox
            sx={{ pl: 3, mt: "40px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => setMaxExposure(MaxExposure + 5)}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }
  const [MaxSignificant, setMaxSignificant] = useState(4)
  const significant = () => {
    let data = [...Significants]
    data.length > 1 &&
      data.sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at)
      })
    return (
      <SuiBox
        // display="flex"
        alignItems="center"
        sx={{
          mt: 2,
        }}
        className="significants-container"
      >
        {data.length > 0 &&
          data.map((row, index) => {
            let dt = new Date(row.updated_at)
            return index <= MaxSignificant ? (
              <SuiBox
                key={index}
                display="flex"
                sx={{
                  mb: 3,
                }}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                  style={{ backgroundColor: "transparent" }}
                  src={row.userProfile ? process.env.REACT_APP_BACKEND_URL + row.userProfile : ""}
                />

                <Card
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F7F9FB",
                    boxShadow: 0,
                    textAlign: "start",
                    fontSize: 18,
                    fontWeight: 700,
                    p: 2,
                    ml: 1,
                  }}
                >
                  <SuiBox display="flex">
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="light"
                      sx={{ color: "#0C65FF", width: "100%" }}
                    >
                      {row.userDepartment} {row.userName} {row.userPosition} •{" "}
                      {dt.toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </SuiTypography>

                    {(typeData === "create" || typeData === "update") &&
                      (Number(row.userID) === myData.id || myData.role.name === "Authenticated") &&
                      stepidx === 0 && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_Significant(row.content, row.id, event, index, row)
                            }}
                          >
                            수정
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              delete_Significant(row.id, row)
                            }}
                          >
                            삭제
                          </SuiButton>
                        </>
                      )}
                    {(typeData === "create" || typeData === "update") &&
                      Number(row.userID) === myData.id &&
                      stepidx === 1 &&
                      idx === index && (
                        <>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="info2"
                            variant="text"
                            onClick={(event) => {
                              eidt_finish_Significant(row, row.id, event, index)
                            }}
                          >
                            완료
                          </SuiButton>
                          <SuiButton
                            className="only-text-btn"
                            sx={{ fontSize: "14px", fontWeight: 400, p: 0, pt: 0.3 }}
                            color="error"
                            variant="text"
                            onClick={() => {
                              updateCancle()
                            }}
                          >
                            취소
                          </SuiButton>
                        </>
                      )}
                  </SuiBox>
                  {Number(row.userID) === myData.id && stepidx === 1 && idx === index ? (
                    <SuiInput
                      type="textfield"
                      className="significantInput"
                      id={`${index}`}
                      sx={{ mt: 1, height: 100 }}
                      placeholder="내용을 입력해 주세요."
                      defaultValue={test}
                      onChange={(e) => updateInputValue(index, e, index)}
                    />
                  ) : (
                    <SuiTypography fontSize="16px" fontWeight="medium" sx={{ color: "#535353" }}>
                      {row.content}
                    </SuiTypography>
                  )}
                </Card>
              </SuiBox>
            ) : (
              ""
            )
          })}
        {MaxSignificant < data.length - 1 ? (
          <SuiBox
            sx={{ pl: 3, mt: "40px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                fontSize: 14,
              }}
              onClick={() => setMaxSignificant(MaxSignificant + 5)}
            >
              더보기
            </SuiButton>
          </SuiBox>
        ) : (
          ""
        )}
      </SuiBox>
    )
  }

  const alertDialog_close = () => {
    let data = { ...alert_Dialog }
    data.open = false
    setalert_Dialog(data)
  }
  //특이사항 삭제 함수들
  const [DeletecurrentId, setDeletecurrentId] = useState("")
  const [Deleterow, setDeleterow] = useState([])
  const closeSignificant_dialog = () => {
    if (DeletecurrentId) {
      setDeleteId([...DeleteId.filter((y) => y.id === DeletecurrentId)])
    }
    setDeletecurrentId("")
    setopenSignificantDelete_Dialog(false)
  }
  const Delete_SignificantData = () => {
    setSignificants([...Significants.filter((y) => y !== Deleterow)])
    setopenSignificantDelete_Dialog(false)
  }
  const delete_Significant = (id, row) => {
    setopenSignificantDelete_Dialog(true)

    setDeletecurrentId(id)
    if (DeleteId.indexOf(id) === -1 && id) {
      setDeleteId([...DeleteId, id])
    }
    setDeleterow(row)

    setstepidx(0)
    setidx("")
    settest("")
  }
  // 노출영역 삭제
  const [ExposureDeletecurrentId, setExposureDeletecurrentId] = useState("")
  const [ExposureDeleterow, setExposureDeleterow] = useState([])
  const closeExposure_dialog = () => {
    if (ExposureDeletecurrentId) {
      setExposureDeleteId([...ExposureDeleteId.filter((y) => y.id === ExposureDeletecurrentId)])
    }
    setExposureDeletecurrentId("")
    setopenExposureDelete_Dialog(false)
  }
  const Delete_ExposureData = () => {
    setExposures([...Exposures.filter((y) => y !== ExposureDeleterow)])
    setopenExposureDelete_Dialog(false)
  }
  const delete_Exposure = (id, row) => {
    setopenExposureDelete_Dialog(true)

    setExposureDeletecurrentId(id)
    if (ExposureDeleteId.indexOf(id) === -1 && id) {
      setExposureDeleteId([...ExposureDeleteId, id])
    }
    setExposureDeleterow(row)

    setexposurestepidx(0)
    setexposureidx("")
    setexposuretest("")
  }

  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      // {...other}
    >
      {openDialog && (
        <SuiBox
          display="flex"
          justifyContent="center"
          bgColor="#F1F1F5"
          minHeight="85vh"
          px={14}
          sx={{ overflowX: "auto" }}
        >
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                이벤트 기본 정보
              </SuiTypography>
              <SuiTypography
                color="subTitle"
                sx={{ mt: 2, fontWeight: 500, fontSize: 18, mb: "40px" }}
                variant="h6"
                component="div"
              >
                이벤트의 기본 정보를 등록하고, 확인하실 수 있습니다.
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
          <SuiDialog
            openDialog={open_delete_dialog}
            closeDialog={() => set_open_delete_dialog(false)}
            MainTitle={"첨부 파일 삭제"}
            Content={"첨부파일을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => deleteAttachmentData()}
          />
          <SuiDialog
            openDialog={openInfo_Dialog}
            closeDialog={() => setopenInfo_Dialog(false)}
            MainTitle={"정보 입력 오류"}
            Content={dialogContent}
            CanBtnText={"확인"}
          />
          <SuiDialog
            openDialog={openSignificantDelete_Dialog}
            closeDialog={() => closeSignificant_dialog()}
            MainTitle={"특이사항 삭제"}
            Content={"특이사항을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_SignificantData()}
          />
          <SuiDialog
            openDialog={openExposureDelete_Dialog}
            closeDialog={() => closeExposure_dialog()}
            MainTitle={"노출 영역 삭제"}
            Content={"노출영역을 삭제 하시겠습니까?"}
            CanBtnText={"취소"}
            CreBtnText={"삭제"}
            CreBtnColor="error"
            AlertType="twoBtn"
            Active={() => Delete_ExposureData()}
          />
          <DeleteDialog
            openDialog={Delete_Dialog}
            closeDialog={() => setDelete_Dialog(false)}
            MainTitle={Delete_Dialog_type === "delete" ? "데이터 삭제 심사 거절" : "삭제 신청"}
            Content={Delete_Content}
            CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
            CreBtnText={"삭제신청"}
            CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
            AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
            requestExplainData={setrequestExplainData}
            requestExplainShowData={requestExplainData}
            Active={() => Delete_All()}
            type={Delete_Dialog_type}
          />
          <SuiDialog
            openDialog={delete_cancle_alert.open}
            className={"admin_setting_dialog"}
            MainTitle={delete_cancle_alert.title}
            Content={delete_cancle_alert.content}
            CanBtnText={delete_cancle_alert.CanBtnText}
            AlertType={delete_cancle_alert.twoBtn ? "twoBtn" : "oneBtn"}
            CreBtnColor={"error"}
            CreBtnText={"취소하기"}
            closeDialog={() => delete_cancle_alert_close()}
            Active={() => delete_cancle_active()}
          />
          <SuiDialog
            openDialog={InfoAlert.dialog}
            closeDialog={() => setInfoAlert(InfoAlertSet)}
            MainTitle={InfoAlert.title}
            Content={InfoAlert.content}
            CanBtnText={InfoAlert.CanBtnText}
          />
        </SuiBox>
      )}
      {openDialog && authCheck() ? FooterForm() : ""}
    </div>
  )
}
detailInfo.defaultProps = {
  edit: false,
  editId: null,
  type: "create",
}
detailInfo.propTypes = {
  edit: PropTypes.bool,
  type: PropTypes.string,
  index: PropTypes.number,
  inputListsExport: PropTypes.func,
  inputDetailListsExport: PropTypes.func,
  inputEventContentExport: PropTypes.func,
  referrerIdsExport: PropTypes.func,
  AttachmentDatasExport: PropTypes.func,
  MeetingDatasExport: PropTypes.func,
  inputListsImport: PropTypes.array,
  editId: PropTypes.string,
  SignificantsDatasExport: PropTypes.func,
  ExposureDatasExport: PropTypes.func,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  saveExit: PropTypes.func,
}
export default detailInfo
