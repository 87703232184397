import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"

import Divider from "@mui/material/Divider"
import SuiDialog from "components/SuiDialog"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"

import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { FiSearch } from "react-icons/fi"
import { MdClose } from "react-icons/md"
import GetFileIcon from "components/getFileIcon"

// API
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"
import { writerContract } from "apollo/query"
import { createDonwloadLog } from "apollo/mutation"
import { set } from "date-fns"

// Redux
import { useSelector, useDispatch } from "react-redux"

function NoticeDetailDialog({ openDialog, closeDialog, detailData }) {
  const [open, setOpen] = useState(openDialog)
  const [Files, setFiles] = useState([])
  const [openNoContract_dialog, setopenNoContract_dialog] = useState(false)
  const [InfoData, setInfoData] = useState([])
  const { myData } = useSelector((state) => state.store)

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const showPdf = (file) => {
    if (file.url) {
      var a = document.createElement("a")
      a.href = process.env.REACT_APP_BACKEND_URL + file.url
      a.target = "_blank"
      a.rel = "noopener noreferrer"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
    }
  }

  const handleClose = () => {
    closeDialog()
    setFiles([])
    setOpen(false)
  }

  const fileName = detailData.file?.name
  const fileType = fileName?.split(".")[fileName?.split(".").length - 1]
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <SuiBox sx={{ p: 5, height: 700 }}>
          <SuiBox display="flex" sx={{ alignItems: "center" }}>
            <Chip
              className={`${detailData.type}_chip`}
              label={{ notice: "공지사항", update: "업데이트", etc: "기타" }[detailData.type]}
              sx={{
                borderRadius: "8px",
              }}
            />
            <SuiTypography
              fontSize="18px"
              fontWeight="bold"
              color="dark"
              sx={{ ml: 1.5, mr: "auto" }}
            >
              {detailData.title}
            </SuiTypography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
              sx={{ p: 0, top: "-8px", right: "7px" }}
            >
              <MdClose />
            </IconButton>
          </SuiBox>
          <SuiTypography fontSize="14px" color="secondary">
            {detailData.department} {detailData.name} {detailData.position} ·
            {dayjs(detailData.created_at).format("YYYY.MM.DD. Ahh:mm")}
          </SuiTypography>
          <SuiTypography
            fontSize="18px"
            color="dark"
            sx={{ py: 2.5, height: 400, overflowY: "auto" }}
          >
            <span
              className="ql-snow textOverFlow2"
              dangerouslySetInnerHTML={{ __html: detailData.content }}
            ></span>
          </SuiTypography>
          <Divider sx={{ my: 2 }} />

          <SuiBox sx={{ mt: 1.3, height: 100, overflowY: "auto" }}>
            {detailData.file && detailData.file.url ? (
              <SuiBox
                justifyContent="start"
                display="flex"
                alignItems="start"
                sx={{ height: "auto", pt: 0 }}
              >
                <IconButton sx={{ mr: 1, p: 0, pt: 1 }} className="cursorDefalut">
                  {GetFileIcon(fileType)}
                </IconButton>
                <SuiTypography
                  fontSize="14px"
                  fontWeight="bold"
                  color="dark"
                  sx={{ pt: 1, pb: 0, cursor: "pointer" }}
                  onClick={() => DownloadPdf(detailData.file)}
                >
                  {detailData.file.name}
                </SuiTypography>
                <div style={{ width: "107px" }}>
                  <SuiButton
                    sx={{
                      backgroundColor: "#EEEEEE",
                      ml: "auto",
                      minHeight: 24,
                      height: 24,
                      width: 24,
                      minWidth: 24,
                      p: 0,
                      ml: 1,
                      boxShadow: 0,
                    }}
                    onClick={() => showPdf(detailData.file)}
                  >
                    <FiSearch size="15" />
                  </SuiButton>
                  {/* <SuiButton
                    color="info"
                    sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                    onClick={() => DownloadPdf(detailData.file)}
                  >
                    다운로드
                  </SuiButton> */}
                </div>
              </SuiBox>
            ) : null}
          </SuiBox>
        </SuiBox>

        <SuiDialog
          openDialog={openNoContract_dialog}
          closeDialog={() => setopenNoContract_dialog(false)}
          MainTitle={"삭제된 계약 알림"}
          Content={"담당자의 요청에 의해 이미 삭제된 계약 건입니다."}
          CanBtnText={"확인"}
        />
      </Dialog>
    </div>
  )
}

NoticeDetailDialog.defaultProps = {
  openDialog: false,

  detailData: {
    name: "",
    position: "",
    department: "",
    created_at: "",
    title: "",
    type: "",
    content: "",
    file: { name: " ", url: " " },
  },
}

NoticeDetailDialog.propTypes = {
  openDialog: PropTypes.bool,
  detailData: PropTypes.object,
  closeDialog: PropTypes.func,
}
export default NoticeDetailDialog
