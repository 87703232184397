import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"

import IconButton from "@mui/material/IconButton"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"

import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import GetFileIcon from "components/getFileIcon"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { BiUpload } from "react-icons/bi"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"

import { FileUploader } from "react-drag-drop-files"
// Redux
import { useSelector, useDispatch } from "react-redux"

import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"
import { loading_Apply } from "reducers/store"

function DragDrop({ files }) {
  let message = "드래그해서 파일을 업로드 해주세요."
  const [Message, setMessage] = useState(message)
  const handleChange = (file) => {
    files({ name: "" })
    files(file)
    setMessage("업로드 되었습니다.")
  }
  const onTypeError = (err) => {
    console.log(err)
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }
  const onSizeError = () => {
    setMessage("200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다.")
  }
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      hoverTitle="pdf, excel, ppt, word, png, jpeg 파일만 업로드 됩니다."
      label="드레그해서 파일을 업로드 해주세요."
      maxSize="200"
      classes="width100 fileDragDrop"
      types={[
        "pdf",
        "png",
        "jpeg",
        "jpg",
        "ppt",
        "pptx",
        "docs",
        "docx",
        "xlsm",
        "xlsb",
        "xltx",
        "xltm",
        "xls",
        "XLSX",
        "csv",
        "pptm",
      ]}
      accept=".pdf, image/png, image/jpeg, image/jpg, .ppt, .pptx, .docx, .xlsx, .docx, .excel, .docs, .xlsm, .xls, .xltx, .xlsb, .xltm, .xml"
      onSizeError={onSizeError}
      onTypeError={onTypeError}
    >
      <Card
        sx={{
          p: 2,
          width: "100%",
          height: 180,
          boxShadow: 0,
          border: 1,
          backgroundColor: "#FAF8FF",
          borderColor: "#EEEEEE",
        }}
      >
        <SuiBox
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        >
          <IconButton edge="start" sx={{ color: "#0C65FF", ml: 0.3 }}>
            <BiUpload />
          </IconButton>
          <SuiTypography
            color={
              Message === "200MB 이하의 pdf, excel, ppt, word, png, jpeg파일만 업로드 가능합니다."
                ? "error"
                : "secondary"
            }
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {Message}
          </SuiTypography>
        </SuiBox>
      </Card>
    </FileUploader>
  )
}
DragDrop.defaultProps = {}

DragDrop.propTypes = {
  files: PropTypes.func,
}

function FileUploadDialog({
  openDialog,
  closeDialog,
  closeDialog2,
  editData,
  edit,
  AttachmentData,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(openDialog)
  const [DragDropFile, setDragDropFile] = useState({ name: "" })
  const { myData } = useSelector((state) => state.store)
  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const InputListsSet = [
    { type: "picUpload", title: "파일 업로드:", xs: 6, file: {}, changed: false },
    {
      items: ["이벤트 제안서", "가이드", "기타 참고 자료"],
      itemsValue: "이벤트 제안서",
      value: "",
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "textFiled",
      title: "유형:",
      maxLength: 30,
      xs: 6,
      name: myData.name,
      position: myData.position,
      department: myData.department,
      created_at: new Date(),
      version: "최신",
    },
    {
      inputType: "text",
      value: "",
      error_message: "",
      error: false,
      type: "fileList",
      title: "",
      maxLength: 255,
      xs: 6,
    },
    // {
    //   inputType: "text",
    //   value: "",
    //   error_message: "",
    //   error: false,
    //   type: "textArea",
    //   title: "설명:",
    //   maxLength: 255,
    //   xs: 6,
    // },
  ]
  const [InputLists, setInputLists] = useState(InputListsSet)
  useEffect(() => {
    if (edit) {
      if (editData.length > 0) {
        setDragDropFile(editData[0].file)
        setInputLists(editData)
      }
    }
  }, [edit])
  useEffect(() => {
    if (!edit) {
      setDragDropFile({ name: "" })
      setInputLists(InputListsSet)
    }
  }, [edit])

  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const handleInput = (index, maxLength) => (e) => {
    let array = [...InputLists]
    if (maxLength >= e.target.value.length) {
      array[index].value = e.target.value
      array[0].changed = true
    }
    setInputLists(array)
  }
  const handleSelectChange = (index) => (e) => {
    let array = [...InputLists]
    array[index].itemsValue = e.target.value
    array[0].changed = true
    setInputLists(array)
  }
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: String(myData.id),
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const imgupload = () => {
    document.getElementsByClassName("fileDragDrop")[0].click()
  }
  const [FiltersOpen1, setFiltersOpen1] = useState(false)
  const fileListShow = () => {
    if (DragDropFile.name) {
      const fileName = DragDropFile.name
      const fileType = fileName.split(".")[fileName.split(".").length - 1]
      return (
        <SuiBox display="flex" alignItems="start" sx={{ height: 40, pt: 4 }}>
          <IconButton sx={{ mr: 1, pt: 0.3, px: 0, pb: 0 }} className="cursorDefalut">
            {/* <GrDocumentPdf className="pdfColor" /> */}
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="dark"
            sx={{ pt: 0.8, pb: 0.3, cursor: "pointer" }}
            onClick={() => DownloadPdf(DragDropFile)}
          >
            {DragDropFile.name}
          </SuiTypography>
          {DragDropFile.url ? (
            <div style={{ width: "150px" }}>
              <SuiButton
                sx={{
                  backgroundColor: "#EEEEEE",
                  ml: "auto",
                  minHeight: 24,
                  height: 24,
                  width: 24,
                  minWidth: 24,
                  p: 0,
                  ml: 1,
                  boxShadow: 0,
                }}
                onClick={() => showPdf(DragDropFile)}
              >
                <FiSearch size="15" />
              </SuiButton>
              {/* <SuiButton
                color="info"
                sx={{ minHeight: 20, height: 20, ml: 1, pt: 1.8, px: 0, boxShadow: 0 }}
                onClick={() => DownloadPdf(DragDropFile)}
              >
                다운로드
              </SuiButton> */}
            </div>
          ) : (
            ""
          )}
        </SuiBox>
      )
    } else {
      return (
        <SuiBox
          justifyContent="center"
          display="flex"
          alignItems="start"
          sx={{ height: 40, pt: 4 }}
        ></SuiBox>
      )
    }
  }
  const InputTypeEl = (data, index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox sx={{ mb: 0 }}>
          <SuiBox sx={{ width: 200, mt: 0 }}>
            <FormControl
              fullWidth
              sx={{ minHeight: 30, mb: 2 }}
              className={
                data.itemsValue === ""
                  ? "setting-selectbox select-none-active"
                  : "setting-selectbox"
              }
              onClick={() => setFiltersOpen1(!FiltersOpen1)}
            >
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    color="black"
                    className="select-icon cursor"
                    onClick={() => setFiltersOpen1(!FiltersOpen1)}
                  />
                )}
                open={FiltersOpen1}
                value={data.itemsValue}
                onChange={handleSelectChange(index)}
                className="setting-border-radius"
              >
                {data.items.map((x) => {
                  return (
                    <MenuItem value={x} key={x}>
                      {x}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </SuiBox>
          <SuiBox>
            <SuiTypography
              fontSize="15px"
              sx={{ pt: 0, pb: 0.5, fontWeight: 400, color: "#668B9E" }}
            >
              설명:
            </SuiTypography>
            <TextField
              multiline
              rows={3}
              sx={{ width: "100%" }}
              className="Fileupload-textArea"
              placeholder="30자 이내"
              variant="outlined"
              value={data.value}
              onChange={handleInput(index, data.maxLength)}
            />
          </SuiBox>
        </SuiBox>
      )
    } else if (data.type === "textArea") {
      return (
        <TextField
          multiline
          rows={10}
          sx={{ width: "100%" }}
          className="Fileupload-textArea"
          variant="outlined"
          value={data.value}
          onChange={handleInput(index, data.maxLength)}
        />
      )
    } else if (data.type === "picUpload") {
      return (
        <SuiBox display="flex" alignItems="center">
          <DragDrop files={setDragDropFile} />
        </SuiBox>
      )
    } else if (data.type === "fileList") {
      return (
        <SuiBox>
          <SuiBox justifyContent="center" display="flex" alignItems="start" sx={{ height: "auto" }}>
            <SuiButton
              sx={{ height: 30, px: 2 }}
              color="info2"
              size="small"
              onClick={() => imgupload()}
            >
              파일선택
            </SuiButton>
          </SuiBox>
          <SuiBox>{fileListShow()}</SuiBox>
        </SuiBox>
      )
    }
  }
  useEffect(() => {
    let array = [...InputLists]
    array[0].changed = true
    setInputLists(array)
  }, [DragDropFile])
  const CUcheck = () => {
    let array = [...InputLists]
    array[0].file = DragDropFile
    if (!edit) {
      AttachmentData(array)
    }
    setOpen(false)
    closeDialog2()
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
  }
  const handleClose = () => {
    closeDialog()
    setDragDropFile({ name: "" })
    setInputLists(InputListsSet)
    setOpen(false)
  }
  const listsShow = InputLists.map((row, index) => {
    return (
      <Grid
        item
        xs={row.xs}
        key={row.title}
        sx={{ pl: index % 2 === 0 ? 0 : 2, pr: index % 2 === 0 ? 2 : 0, alignItems: "inherit" }}
      >
        <SuiBox>
          <SuiTypography fontSize="15px" sx={{ pt: 2, pb: 0.5, fontWeight: 400, color: "#668B9E" }}>
            {row.title}
          </SuiTypography>
          {InputTypeEl(row, index)}
        </SuiBox>
      </Grid>
    )
  })

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <SuiBox sx={{ p: 5, width: 840 }}>
          <SuiBox sx={{ textAlign: "start" }}>
            <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
              {edit ? "첨부파일 수정" : "첨부파일 추가"}
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <Grid container alignItems="center">
              {listsShow}
            </Grid>
          </SuiBox>
          {/* <Divider sx={{ my: 4 }} /> */}
          <DialogActions sx={{ p: 0, mt: 10 }}>
            <SuiButton
              color="subTitle"
              variant="text"
              sx={{ width: 97.38, border: 1, borderColor: "#707070", height: 40, fontSize: 14 }}
              onClick={() => handleClose()}
            >
              취소
            </SuiButton>
            <SuiButton
              disabled={DragDropFile.name === ""}
              sx={{ width: 97.38, height: 40, fontSize: 14 }}
              color="primary"
              onClick={() => CUcheck()}
            >
              {!edit ? "완료" : "수정"}
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </div>
  )
}

FileUploadDialog.defaultProps = {
  openDialog: false,
  edit: false,
  editData: [],
}

FileUploadDialog.propTypes = {
  openDialog: PropTypes.bool,
  edit: PropTypes.bool,
  editData: PropTypes.array,
  closeDialog: PropTypes.func,
  closeDialog2: PropTypes.func,
  AttachmentData: PropTypes.func,
}
export default FileUploadDialog
