import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react"
import PropTypes, { element, number } from "prop-types"
import axios from "axios"
import { useApolloClient } from "@apollo/client"
import TextField from "@mui/material/TextField"

import Grid from "@mui/material/Grid"

import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogActions from "@mui/material/DialogActions"

import SuiButton from "components/SuiButton"

import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import dayjs from "dayjs"

import FileUploadDialog from "./items/fileUploadDialog"

// API
import { useQuery, useMutation } from "@apollo/client"
import { updateEventRecevier } from "apollo/mutation"
import { events, eventReceviers } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "reducers/store"
import { mt } from "date-fns/locale"
import moment from "moment"
import { useParams } from "react-router-dom"
function Notice() {
  const dispatch = useDispatch()
  // 첫 실행시 데이터를 받아오는 코드
  const { code } = useParams()

  if (code === "코드_code") {
    alert("미리보기에서는 접근할 수 없습니다")
    window.close()
  } else {
    const { data: eventNoticeInfo, loading } = useQuery(eventReceviers, {
      fetchPolicy: "network-only",
      variables: {
        code: code,
      },
      onError(err) {
        dispatch(loading_Apply(false))
      },
    })

    const client = useApolloClient()

    useEffect(() => {
      startSetting()
    }, [loading, eventNoticeInfo])
    const startSetting = async () => {
      if (eventNoticeInfo) {
        if (
          eventNoticeInfo.eventReceviers[0].access === "access" ||
          eventNoticeInfo.eventReceviers[0].access === "reject"
        ) {
          alert("이미 이벤트 승인/비승인을 진행하셨습니다")
          window.close()
        } else {
          set_event_recevier_ID(eventNoticeInfo.eventReceviers[0].id)
          let eventid = eventNoticeInfo.eventReceviers[0].eventId
          await client
            .query({
              query: events,
              variables: {
                id: eventid,
              },
            })
            .then((res) => {
              set_all_event_data(res.data.events[0])
            })
        }
      }
    }
  }

  const [event_recevier_ID, set_event_recevier_ID] = useState("")
  const [all_event_data, set_all_event_data] = useState({
    // 이벤트 모든정보
    manager: {
      userName: "",
      userPosition: "",
      userProfile: "",
      userDepartment: "",
    },
    history: [],
    requests: [],
  })
  const [selectedValue, setSelectedValue] = useState("진행 승인") // 라디오 밸류
  const [notify_content, set_notify_content_content] = useState("") // 이벤트 내용
  const [step, setstep] = useState(0) // 화면 단계 조정

  // 라디오 밸류 조정
  const handleChange = (e) => {
    setSelectedValue(e.target.value)
  }
  // 의견 담는 부분
  const handleNotifyContent = (e) => {
    set_notify_content_content(e.target.value)
  }

  //step이 0일때 의견 부분 구현
  const Content = () => {
    return (
      <div>
        <SuiBox ml={3.5} mt={7} sx={{ textAlign: "start" }}>
          <SuiTypography fontSize="17px" fontWeight="light" color="info">
            의견:
          </SuiTypography>
        </SuiBox>
        <SuiBox
          ml={3.5}
          mt={1}
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="start"
        >
          <TextField
            multiline
            rows={8}
            sx={{ width: "100%" }}
            variant="outlined"
            placeholder="추가로 전달해주실 의견이 있으면 작성해주세요."
            value={notify_content}
            onChange={handleNotifyContent}
          />
        </SuiBox>

        {BtnControll()}
      </div>
    )
  }
  const [updateEventReceviersActive] = useMutation(updateEventRecevier, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 승인/비승인 진행 오류")
    },
  })
  // 의견제출
  const next_active = () => {
    dispatch(loading_Apply(true))
    setstep(1)
    setTimeout(() => {
      updateEventReceviersActive({
        variables: {
          id: event_recevier_ID,
          access:
            selectedValue === "진행 승인" ? "access" : "진행 거절" ? "reject" : "unidentified",
          comment: notify_content,
          agree_date: new Date(),
        },
      }).then(() => {
        dispatch(loading_Apply(false))
      })
    }, 500)
  }

  // 나가기
  const quit_active = () => {
    if (step === 0) {
      window.close()
    } else {
      window.close()
    }
  }

  //버튼
  const BtnControll = () => {
    return (
      <DialogActions style={{ width: "100%" }} sx={{ p: 0, ml: 3.5, mt: 3.5 }}>
        <SuiButton
          color={step === 0 ? "subTitle" : "error"}
          variant="text"
          className="back-btn"
          sx={{ justifyContent: "left", fontSize: 16, mr: "auto", mt: step === 0 ? "" : 10 }}
          onClick={() => quit_active()}
        >
          {step === 0 ? "나중에하기" : "나가기"}
        </SuiButton>
        {step === 0 ? (
          <SuiButton
            onClick={() => next_active()}
            variant="outlined"
            color="info2"
            sx={{
              fontSize: 14,
              width: 110,
            }}
          >
            {"의견제출"}
          </SuiButton>
        ) : (
          ""
        )}
      </DialogActions>
    )
  }

  return (
    <SuiBox display="flex" justifyContent="center" minHeight="85vh" maxWidth="55vh">
      {step === 0 ? (
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div style={{ textAlign: "start" }}>
            <SuiTypography
              sx={{ ml: 3.5, mt: 3.5, flex: 1, fontWeight: "bold", color: "dark", fontSize: 20 }}
              variant="h6"
              component="div"
            >
              [이벤트 승인/비승인 통보]
            </SuiTypography>
            <SuiTypography
              color="subTitle"
              sx={{ ml: 3.5, mt: 2, fontWeight: "reguler", fontSize: 18 }}
              variant="h6"
              component="div"
            >
              <span>
                <strong>{`<${all_event_data?.title}>`}</strong>
                {`의 이벤트 진행을 승인하시겠습니까?`}
              </span>
            </SuiTypography>

            <RadioGroup
              sx={{ gap: 3, mt: 4, ml: 5 }}
              defaultValue="진행 승인"
              row
              name="controlled-radio-buttons-group  "
              size="small"
            >
              <FormControlLabel
                value="진행 승인"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 14 }}>
                    진행 승인
                  </SuiTypography>
                }
                onChange={handleChange}
              />
              <FormControlLabel
                value="진행 거절"
                control={<Radio />}
                label={
                  <SuiTypography variant="body1" style={{ fontSize: 14 }}>
                    진행 거절
                  </SuiTypography>
                }
                onChange={handleChange}
              />
            </RadioGroup>
          </div>
          {Content()}
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ width: "100%" }}>
          <SuiTypography
            color="subTitle"
            sx={{ ml: 3.5, mt: 3, fontWeight: "bold", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            이벤트 진행 승인/비승인 통보가 완료되었습니다.
          </SuiTypography>

          <SuiTypography
            color="subTitle"
            sx={{ ml: 3.5, fontWeight: "reguler", fontSize: 15 }}
            variant="h6"
            component="div"
          >
            {`[${dayjs(new Date()).format("YY.MM.DD HH:mm:ss")}]`}
          </SuiTypography>

          <SuiTypography
            color="subTitle"
            sx={{ ml: 3.5, mt: 4, fontWeight: "bold", fontSize: 15 }}
            variant="h6"
            component="div"
          >
            감사합니다.
          </SuiTypography>
          {BtnControll()}
        </Grid>
      )}
    </SuiBox>
  )
}
export default Notice
