// @mui material components
import Grid from "@mui/material/Grid"

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function Footer() {
  return (
    <SuiBox component="footer" className="footer-copy">
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <SuiTypography variant="body2" color="white">
            © JOARA Corp. All rights reserved
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  )
}

export default Footer
