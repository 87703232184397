import React, { useEffect, useState, useRef } from "react"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Badge from "@mui/material/Badge"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

import CuDailog from "./components/create-edit-dialog"
import Datatable from "./components/datatable"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiInput from "components/SuiInput"
import SuiDialog from "components/SuiDialog"
import ChangeData_card from "./components/changeData_card"
import DeleteData_card from "./components/deleteData_card"
import DeleteContract_card from "./components/deleteContract_card"

import { MdClose } from "react-icons/md"
import { BiUserPin } from "react-icons/bi"
import { CgFolderRemove, CgArrowsExchangeAlt } from "react-icons/cg"
import TuneIcon from "@mui/icons-material/Tune"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { ColorResult, HsvaColor } from "@uiw/color-convert"
import Compact from "@uiw/react-color-compact"
// API
import { updateChangeRequest, updateSetting } from "apollo/mutation"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { users, roleCountUser, requests, changeRequests, settings } from "apollo/query"

import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../reducers/store"
import { position } from "stylis"

function TabPanel(props) {
  const dispatch = useDispatch()
  const [Users, setUsers] = useState([])
  const [listsCount, setlistsCount] = useState([0, 0, 0, 0, 0])
  const [delect_lists, setdelect_lists] = useState([])
  const [change_lists, setchange_lists] = useState([])
  const [delete_count, setdelete_count] = useState(0)
  const [change_count, setchange_count] = useState(0)
  const { children, value, index, delete_count_send, change_count_send, setTab } = props
  const [tabActive, setTabActive] = useState(0)
  const { myData } = useSelector((state) => state.store)

  useEffect(() => {
    setTab(tabActive)
  }, [tabActive])
  const [changeRequestsActive, {}] = useLazyQuery(changeRequests, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setchange_count(
        data.changeRequests.filter((x) => {
          if (x.content) {
            return x.content.status === "신청"
          } else if (x.arrayContent) {
            return x.arrayContent.status === "신청"
          }
        }).length
      )
      data.changeRequests.forEach((element, index) => {
        if (element.content) {
          element.content.type = element.type
          element.content.status = element.status
          element.content.contractId = element.contractId
        } else if (element.arrayContent) {
          element.arrayContent.type = element.type
          element.arrayContent.status = element.status
          element.arrayContent.contractId = element.contractId
        }
        if (index === data.changeRequests.length - 1) {
          setchange_lists(data.changeRequests)
        }
      })
      change_count_send(
        data.changeRequests.filter((x) => {
          if (x.content) {
            return x.content.status === "신청"
          } else if (x.arrayContent) {
            return x.arrayContent.status === "신청"
          }
        }).length
      )
    },
  })
  const [requestsActive, {}] = useLazyQuery(requests, {
    fetchPolicy: "network-only",
  })
  const [usersCount, setusersCount] = useState(0)
  const {} = useQuery(roleCountUser, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setlistsCount([
        data.roleCountUser.count.Authenticated +
          data.roleCountUser.count.gold +
          data.roleCountUser.count.silver +
          data.roleCountUser.count.bronze,
        data.roleCountUser.count.Authenticated,
        data.roleCountUser.count.gold,
        data.roleCountUser.count.silver,
        data.roleCountUser.count.bronze,
      ])
    },
  })

  const [settingsActive, {}] = useLazyQuery(settings, {
    fetchPolicy: "network-only",
  })
  const settings_call = () => {
    settingsActive().then((res) => {
      let data = res.data
      data.settings.forEach((el) => {
        el["value_list"] = ""
        el["type_text"] = {
          bank: "기본정보 - 은행",
          genre: "계약작품 - 장르",
          age: "계약작품 - 연령대",
          sign: "계약작품 - 표지여부",
          offer: "계약작품 - 제공여부",
          outContract: "외주계약 - 계약상태",
          outType: "외주계약 - 구분",
          department: "회원정보 - 부서",
          eventFlatform: "이벤트 - 플랫폼",
          eventType: "이벤트 - 종류",
          eventReward: "이벤트 - 기본 리워드",
          eventPublisher: "이벤트 - 출판사",
        }[el.type]

        let type_edit = ["-", "추가", "삭제", "수정"]
        let type_add = ["-", "추가"]

        el["type_items"] = {
          bank: type_edit,
          genre: type_edit,
          age: type_edit,
          sign: type_edit,
          offer: type_add,
          outContract: type_add,
          outType: type_add,
          department: type_add,
          eventFlatform: type_add,
          eventType: type_add,
          eventReward: type_add,
          eventPublisher: type_add,
        }[el.type]

        el["value_type_items"] = {
          bank: "-",
          genre: "-",
          age: "-",
          sign: "-",
          offer: "추가",
          outContract: "추가",
          outType: "추가",
          department: "추가",
          eventFlatform: "추가",
          eventType: "추가",
          eventReward: "추가",
          eventPublisher: "추가",
        }[el.type]
      })
      setsettingData(data.settings)
    })
  }
  const [updatesettingActive] = useMutation(updateSetting, {
    onError(err) {
      alert("설정 변경시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const [updateChangeRequestActive] = useMutation(updateChangeRequest, {
    onError(err) {
      alert("계약 담당자 변경 신청시 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const subText = () => {
    if (value === 0) {
      return "등록된 회원 등급을 변경하거나, 새로운 회원을 등록하실 수 있습니다."
    } else if (value === 1) {
      return "회원의 변경 신청 내역을 확인하고, 처리합니다."
    } else if (value === 2) {
      return "작가 및 유통사 삭제 신청 현황을 확인하고, 처리합니다."
    } else if (value === 3) {
      return "정보 입력 항목을 수정하거나 추가할 수 있습니다."
    }
  }
  const Content = () => {
    if (value === 0) {
      return (
        <div style={{ width: "100%", minWidth: "980px" }}>
          <SuiButton
            sx={{
              minHeight: 67,
              fontSize: 18,
              fontWeight: 700,
              boxShadow: 3,
              width: "100%",
            }}
            className="outlined-bg-white"
            variant="outlined"
            color="primary"
            onClick={() => addUserDialog()}
          >
            신규 회원 추가하기
          </SuiButton>
          <CuDailog
            openDialog={CuDailogBool}
            closeDialog={closeDialog}
            Users={Users}
            updateUser={setUsers}
          />
          <Card sx={{ mt: 5, boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  전체 회원 현황
                </SuiTypography>
              </SuiBox>
              {userListCard()}
            </SuiBox>
          </Card>
          <Card sx={{ mt: "20px", boxShadow: 0 }}>
            <SuiBox
              px={5}
              pt={5}
              pb={2}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  회원 정보
                </SuiTypography>
                <Datatable tab={setTabActive} />
              </SuiBox>
            </SuiBox>
          </Card>
        </div>
      )
    } else if (value === 1) {
      return (
        <div style={{ width: "980px" }}>
          <Card sx={{ mt: 5, boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  이관 신청 대기 현황
                </SuiTypography>
              </SuiBox>
              <SuiTypography
                fontSize="18px"
                fontWeight="medium"
                color="dark"
                sx={{
                  textAlign: "left",
                  mt: 2,
                }}
              >
                현재 <span className="changeGradeCount-style-zero">{change_count}건</span>의 이관
                신청 건이 있습니다.
              </SuiTypography>
            </SuiBox>
          </Card>
          {change_lists.map((data, index) => {
            return (
              <ChangeData_card
                data={data.content ? data.content : data.arrayContent}
                dataId={data.id}
                origin_data={data}
                reCallChangeData={changeRequestsActive}
                key={index}
              />
            )
          })}
          <SuiBox display="flex" justifyContent="center" sx={{ mt: "16px" }}>
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                mb: 4,
                fontSize: 14,
              }}
              onClick={() => {
                changeRequestsActive()
              }}
            >
              더보기
            </SuiButton>
          </SuiBox>
        </div>
      )
    } else if (value === 2) {
      return (
        <div style={{ width: "980px" }}>
          <button
            id="deleteList-reset"
            style={{ display: "none" }}
            onClick={() => requestsActiveBtn()}
          ></button>
          <Card sx={{ boxShadow: 0 }}>
            <SuiBox
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              height="100%"
            >
              <SuiBox sx={{ textAlign: "start" }}>
                <SuiTypography fontSize="18px" fontWeight="bold" color="dark">
                  삭제 신청 대기 현황
                </SuiTypography>
              </SuiBox>
              <SuiTypography
                fontSize="18px"
                fontWeight="medium"
                color="dark"
                sx={{
                  textAlign: "left",
                  mt: 2,
                }}
              >
                현재 <span className="changeGradeCount-style-zero">{delete_count}건</span>의 데이터
                삭제 신청 건이 있습니다.
              </SuiTypography>
            </SuiBox>
          </Card>
          {delect_lists.map((data, index) => {
            if (data.arrayContent) {
              data.content = data.arrayContent
            }
            return data.status.indexOf("계약 삭제") > -1 ? (
              <DeleteContract_card key={index} data={data}></DeleteContract_card>
            ) : (
              <DeleteData_card key={index} data={data} />
            )
          })}
          <SuiBox display="flex" justifyContent="center" sx={{ mt: "16px" }}>
            <SuiButton
              color="info2"
              variant="text"
              sx={{
                width: 100,
                border: 1,
                borderColor: "#0C65FF",
                height: 40,
                mr: 2,
                mb: 4,
                fontSize: 14,
              }}
              onClick={() => {
                requestsActive({
                  variables: { type_in: ["Writer", "Company", "Outsourcing", "Event"] },
                }).then((res) => {
                  setdelect_lists(res.data.requests)
                  let deleteCount = 0
                  res.data.requests.forEach((request, index) => {
                    if (request.status.indexOf("심사중") > -1) {
                      deleteCount += 1
                    }
                    if (index === res.data.requests.length - 1) {
                      setdelete_count(deleteCount)
                      delete_count_send(deleteCount)
                    }
                  })
                })
              }}
            >
              더보기
            </SuiButton>
          </SuiBox>
        </div>
      )
    } else if (value === 3) {
      return (
        <div style={{ width: "980px" }}>
          {settingData.map((setting, index) => {
            return (
              <Card
                key={index}
                sx={{ boxShadow: 0, p: 5, mb: "16px", width: "960px", ml: 1.5 }}
                style={{ overflow: index === 8 ? "visible" : "hidden" }}
              >
                <SuiBox display="flex" flexDirection="column" justifyContent="start" height="100%">
                  <SuiBox sx={{ textAlign: "start" }}>
                    <SuiTypography
                      fontSize="16px"
                      fontWeight="bold"
                      color="dark"
                      sx={{ textAlign: "left" }}
                    >
                      {setting.type_text}
                    </SuiTypography>
                  </SuiBox>
                  <Grid container alignItems="center" className={"setting_disabled"}>
                    {settingDataShow(setting, index)}
                  </Grid>
                </SuiBox>
              </Card>
            )
          })}
          <SuiDialog
            openDialog={openSave_Dialog.open}
            className={"admin_setting_dialog"}
            closeDialog={() => saveDialog_close()}
            MainTitle={openSave_Dialog.title}
            Content={openSave_Dialog.content}
            CanBtnText={"취소"}
            CreBtnText={"확인"}
            CreBtnColor="info2"
            AlertType="twoBtn"
            Active={() => saveDialog_active()}
          />
          <SuiDialog
            openDialog={alert_Dialog.open}
            className={"admin_setting_dialog"}
            MainTitle={alert_Dialog.title}
            Content={alert_Dialog.content}
            CanBtnText={"닫기"}
            CreBtnColor="info2"
            AlertType="oneBtn"
            closeDialog={() => alertDialog_close()}
          />
        </div>
      )
    }
  }

  const [settingData, setsettingData] = useState([])

  const [CuDailogBool, setCuDailogBool] = useState(false)
  const closeDialog = () => {
    setCuDailogBool(false)
  }
  const addUserDialog = () => {
    setCuDailogBool(true)
  }
  const [openSave_Dialog, setopenSave_Dialog] = useState({
    open: false,
    title: "",
    content: "",
    setting: {},
  })
  const [alert_Dialog, setalert_Dialog] = useState({
    open: false,
    title: "",
    content: "",
  })

  const saveDialog_open = (setting, setting_index) => {
    let dialog_data = { ...openSave_Dialog }
    let alert_dialog_data = { ...alert_Dialog }
    if (setting.value_type_items === "삭제") {
      if (!setting.value_list) {
        alert_dialog_data.open = true
        alert_dialog_data.title = "입력 필드 확인"
        alert_dialog_data.content = "기존 등록된 목록을 선택해주세요."
        return setalert_Dialog(alert_dialog_data)
      }
    } else if (setting.value_type_items === "수정") {
      if (!setting.value_list) {
        alert_dialog_data.open = true
        alert_dialog_data.title = "입력 필드 확인"
        alert_dialog_data.content = "기존 등록된 목록을 선택해주세요."
        return setalert_Dialog(alert_dialog_data)
      } else if (!input_Ref.current[setting_index].children[0].value) {
        alert_dialog_data.open = true
        alert_dialog_data.title = "입력 필드 확인"
        alert_dialog_data.content = "추가/수정 정보명을 입력해주세요."
        return setalert_Dialog(alert_dialog_data)
      }
    } else if (setting.value_type_items === "추가") {
      if (!input_Ref.current[setting_index].children[0].value) {
        alert_dialog_data.open = true
        alert_dialog_data.title = "입력 필드 확인"
        alert_dialog_data.content = "추가/수정 정보명을 입력해주세요."
        return setalert_Dialog(alert_dialog_data)
      }
    }

    // 계약작품 - 제공여부 ~ 이벤트 - 기본리워드까지
    // 이미 DB에 동일한 정보명이 존재한다면 return하는 유효성 검사
    if (
      4 <= setting_index &&
      setting_index !== 8 &&
      setting.list.includes(input_Ref.current[setting_index].children[0].value)
    ) {
      alert_dialog_data.open = true
      alert_dialog_data.title = "입력 필드 확인"
      alert_dialog_data.content = "이미 동일한 정보명이 존재합니다"
      return setalert_Dialog(alert_dialog_data)
    } else if (4 <= setting_index && setting_index === 8) {
      // 이벤트 - 플랫폼일시
      const values = setting.list.slice(1).map((item) => item.value)
      if (values.includes(input_Ref.current[setting_index].children[0].value)) {
        alert_dialog_data.open = true
        alert_dialog_data.title = "입력 필드 확인"
        alert_dialog_data.content = "이미 동일한 정보명이 존재합니다"
        return setalert_Dialog(alert_dialog_data)
      }
    }

    let content = "변경된 내용을 저장하시겠습니까? "

    if (setting.value_type_items !== "추가") {
      content =
        content +
        `\n 항목 ${setting.value_type_items} 시 기존 항목을 선택했던 데이터는 공란 처리됩니다.`
    }

    dialog_data.open = true
    dialog_data.title = `목록 ${setting.value_type_items} 확인`
    dialog_data.content = content
    dialog_data.setting = { ...setting }
    dialog_data.setting["value_new"] = input_Ref.current[setting_index].children[0].value
    dialog_data.setting["setting_index"] = setting_index
    //이벤트 플랫폼 저장시 색깔 코드 저장
    if (setting_index === 8) {
      //색깔을 지정안하고 저장시 기본색깔인 #ffffff로 저장
      if (input_Ref.current[8].colorValue === undefined) {
        input_Ref.current[8].colorValue = "#ffffff"
      }
      dialog_data.setting["value_new_color"] = input_Ref.current[8].colorValue
    }
    setopenSave_Dialog(dialog_data)
  }
  const saveDialog_close = () => {
    let dialog_data = { ...openSave_Dialog }
    dialog_data.open = false
    setopenSave_Dialog(dialog_data)
  }
  const alertDialog_close = () => {
    let alert_dialog_data = { ...alert_Dialog }
    alert_dialog_data.open = false
    setalert_Dialog(alert_dialog_data)
  }
  const saveDialog_active = () => {
    update_setting()
    let dialog_data = { ...openSave_Dialog }
    dialog_data.open = false
    setopenSave_Dialog(dialog_data)
  }

  const update_setting = () => {
    let dialog_data = { ...openSave_Dialog }
    let setting = { ...dialog_data.setting }

    if (setting.value_type_items === "추가") {
      if (setting.setting_index === 8) {
        let format = {
          value: setting.value_new,
          color: setting.value_new_color,
        }
        setting.list.push(format)
      } else {
        setting.list.push(setting.value_new)
      }
    } else if (setting.value_type_items === "수정") {
      let edit_index = setting.list.findIndex((el) => el === setting.value_list)
      setting.list.splice(edit_index, 1, setting.value_new)
    } else if (setting.value_type_items === "삭제") {
      let edit_index = setting.list.findIndex((el) => el === setting.value_list)
      setting.list.splice(edit_index, 1)
    }

    input_Ref.current[setting.setting_index].children[0].value = ""
    //이벤트 플랫폼저장시 기존 저장한 색깔코드 초기화
    if (setting.value_new_color || setting.value_new_color !== undefined) {
      input_Ref.current[8].colorValue = ""
    }

    setting.log.unshift({
      createDate: dayjs(new Date()).add(9, "hours").toISOString(),
      department: myData.department,
      name: myData.name,
      position: myData.position,
      list: setting.list,
    })
    //이벤트 플랫폼저장시 로그에 색깔코드 추가
    if (setting.value_new_color || setting.value_new_color !== undefined) {
      setting.log[0].color = setting.value_new_color
    }
    dispatch(loading_Apply(true))
    updatesettingActive({
      variables: {
        id: setting.id,
        log: setting.log,
        list: setting.list,
      },
    }).then(() => {
      settings_call()
      dispatch(loading_Apply(false))
    })
  }
  const settingDataSet = [
    {
      type: "selectBox",
      setting_type: "type_items",
      title: "유형 :",
      xs: "20%",
    },
    {
      type: "selectBox",
      setting_type: "list",
      title: "기존 등록된 목록 :",
      xs: "25%",
    },
    { type: "textFiled", setting_type: "new", title: "추가/수정 정보명 :", xs: "30%" },
    { type: "button", title: "", xs: "25%" },
  ]
  const settingDataShow = (setting, setting_index) => {
    return settingDataSet.map((row, index) => {
      return setting_index > 3 && setting_index !== 8 && index === 1 ? (
        ""
      ) : (
        <Grid
          item
          key={index}
          sx={{
            pr: 2,
            width: row.xs,
            alignSelf: index === settingDataSet.length - 1 ? "end" : "",
          }}
        >
          <SuiBox>
            <SuiBox display="flex">
              <SuiTypography
                fontSize="15px"
                color="info"
                sx={{ textAlign: "left", pt: 2, pb: 1, fontWeight: 500 }}
              >
                {setting_index > 3 && index === 2
                  ? setting_index > 3 && setting_index !== 8 && index === 3
                    ? ""
                    : "추가 정보명:"
                  : row.title}
              </SuiTypography>
            </SuiBox>
            {setting_index > 3 && setting_index !== 8 && index === 1
              ? ""
              : InputTypeEl(row, setting, setting_index)}
          </SuiBox>
        </Grid>
      )
    })
  }

  const handleSelectChange = (row, setting_index) => (e) => {
    let data = [...settingData]
    input_Ref.current[setting_index].children[0].value = ""
    if (row.setting_type === "type_items") {
      data[setting_index][`value_${row.setting_type}`] = e.target.value
      data[setting_index].value_list = ""
    } else if (row.setting_type === "list") {
      data[setting_index][`value_${row.setting_type}`] = e.target.value
    }
    setsettingData(data)
  }
  const reset_setting = (setting_index) => {
    let data = [...settingData]
    if (type_filterd(data[setting_index])) {
      data[setting_index].value_type_items = "추가"
    } else {
      data[setting_index].value_type_items = "-"
    }
    data[setting_index].value_list = ""
    if (setting_index === 8) {
      let whiteColor = {
        hex: "#ffffff",
      }
      handleColorChange(whiteColor)
      input_Ref.current[setting_index].colorValue = ""
    }
    input_Ref.current[setting_index].children[0].value = ""
    setsettingData(data)
  }
  const type_filterd = (setting) => {
    let only_add = {
      eventFlatform: true,
      eventType: true,
      offer: true,
      outContract: true,
      department: true,
      outType: true,
      eventReward: true,
      eventPublisher: true,
    }[setting.type]
    return only_add
  }

  const button_disabled = (setting, setting_index) => {
    let disabled = false
    if (setting.value_type_items === "-") {
      disabled = true
    } else if (setting_index > 3) {
      if (
        input_Ref.current[setting_index] &&
        input_Ref.current[setting_index].children[0].value.length === 0
      ) {
        disabled = true
      } else if (!input_Ref.current[setting_index]) {
        disabled = true
      }
    }
    return disabled
  }
  const requestsActiveBtn = () => {
    requestsActive({
      variables: { type_in: ["Writer", "Company", "Outsourcing", "Event"], limit: 20 },
    }).then((res) => {
      setdelect_lists(res.data.requests)
      let deleteCount = 0
      res.data.requests.forEach((request, index) => {
        if (request.status.indexOf("심사중") > -1) {
          deleteCount += 1
        }
        if (index === res.data.requests.length - 1) {
          setdelete_count(deleteCount)
          delete_count_send(deleteCount)
        }
      })
    })
  }
  useEffect(async () => {
    setTabActive(value)
    if (value === 1) {
      await changeRequestsActive({ variables: { limit: 20 } })
    } else if (value === 2) {
      requestsActiveBtn()
    } else if (value === 3) {
      settings_call()
    }
  }, [value])
  const input_Ref = useRef([])

  const [input_change, setinput_change] = useState(false)
  const [hex, setHex] = useState("#fff")
  const [compactVisible, setCompactVisible] = useState(false)
  //버튼클릭 시 colorPicker display 설정
  const showCompact = () => {
    if (compactVisible) {
      setCompactVisible(false)
    } else {
      setCompactVisible(true)
    }
  }
  const handleColorChange = (color) => {
    setHex(color.hex)
    input_Ref.current[8].colorValue = color.hex
  }
  /**설정변경 이벤트 카드 출력폼
  @param {object} data 데이터셋 오브젝트
  @param {number} setting 세팅데이터셋 넘버링
  @param {number} setting_index 세팅데이터셋 인덱스라벨
  @returns {object} SuiBox element if data.type == '"textFiled"
  @returns {object} SuiBox element 설정변경-특수1 => 이벤트 - 플랫폼 시에는 기존 등록된 목록이 들어가야하기때문에 따로 설정

  @author 성시헌
  */
  const InputTypeEl = (data, setting, setting_index) => {
    if (data.type === "textFiled") {
      return (
        <SuiBox className={setting_index === 8 ? "compactPickerBox" : ""}>
          <SuiInput
            className={
              (setting.value_type_items === "-" || setting.value_type_items === "삭제"
                ? "Mui-disabled "
                : "backwhite",
              setting_index == 8 ? "compactIndent" : "")
            }
            ref={(el) => {
              input_Ref.current[setting_index] = el
            }}
            onChange={() => setinput_change(!input_change)}
            disabled={setting.value_type_items === "-" || setting.value_type_items === "삭제"}
            style={{ position: "relative" }}
          />
          <div
            style={{ display: setting_index === 8 ? "block" : "none" }}
            className={setting_index === 8 ? "compactPicker" : ""}
          >
            <button style={{ backgroundColor: hex }} onClick={() => showCompact()}></button>
          </div>
          <div className="compactColorPicker">
            <Compact
              color={hex}
              onChange={handleColorChange}
              style={{ display: compactVisible && setting_index === 8 ? "block" : "none" }}
              onClick={() => showCompact(false)}
            />
          </div>
        </SuiBox>
      )
    } else if (data.type === "selectBox") {
      return setting_index > 3 && data.setting_type === "type_items" ? (
        <SuiBox sx={{ marginBottom: "6px" }}>
          <Card
            sx={{
              width: "160px",
              height: 40,
              backgroundColor: "#F7F9FB",
              boxShadow: 0,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 700,
              pt: "5.4px",
              color: "#535353",
            }}
          >
            추가
          </Card>
        </SuiBox>
      ) : setting_index > 3 && setting_index === 8 ? (
        //설정변경-특수1
        <SuiBox sx={{ marginBottom: "6px" }}>
          <FormControl fullWidth className={"setting-selectbox"}>
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                />
              )}
              value={setting[`value_${data.setting_type}`]}
              className="setting-border-radius"
              style={{ border: "none" }}
            >
              {setting[`${data.setting_type}`]
                .filter((_, index) => index !== 0)
                .map((item, index) => {
                  if (typeof item === "string") {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    )
                  } else {
                    return (
                      <MenuItem value={item.value} key={index}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: item.color,
                            marginRight: "5px",
                          }}
                        />
                        {item.value}
                      </MenuItem>
                    )
                  }
                })}
            </Select>
          </FormControl>
        </SuiBox>
      ) : (
        <SuiBox sx={{ marginBottom: "6px" }}>
          <FormControl fullWidth className={"setting-selectbox"}>
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  color="black"
                  className="select-icon cursor"
                />
              )}
              sx={{
                backgroundColor:
                  (data.setting_type === "list" &&
                    (setting.value_type_items === "-" || setting.value_type_items === "추가")) ||
                  (type_filterd(setting) && data.setting_type === "type_items")
                    ? "#CDD2D3 !important"
                    : "",
              }}
              disabled={
                (data.setting_type === "list" &&
                  (setting.value_type_items === "-" || setting.value_type_items === "추가")) ||
                (type_filterd(setting) && data.setting_type === "type_items")
              }
              value={setting[`value_${data.setting_type}`]}
              onChange={handleSelectChange(data, setting_index)}
              className="setting-border-radius"
            >
              {setting[`${data.setting_type}`].map((x) => {
                return (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </SuiBox>
      )
    } else if (data.type === "button") {
      return (
        <SuiBox sx={{ display: "flex", marginBottom: "6px" }}>
          <SuiButton
            sx={{
              px: 2,
              py: 0.5,
              height: 40,
              minHeight: 40,
              minWidth: 93,
              fontSize: 14,
            }}
            variant="outlined"
            color="subTitle"
            onClick={() => reset_setting(setting_index)}
          >
            초기화
          </SuiButton>
          <SuiButton
            color="info2"
            onClick={() => saveDialog_open(setting, setting_index)}
            sx={{
              px: 2,
              py: 0.5,
              ml: 2,
              height: 40,
              minHeight: 40,
              minWidth: 93,
              fontSize: 14,
              "&.Mui-disabled": {
                backgroundColor: "#CDD2D3 !important",
              },
            }}
            disabled={button_disabled(setting, setting_index)}
          >
            저장하기
          </SuiButton>
        </SuiBox>
      )
    }
  }

  const lists = ["전체 회원 수:", "슈퍼관리자:", "골드 등급:", "실버등급:", "브론즈 등급:"]
  const listsShow = lists.map((row, index) => {
    return (
      <SuiBox
        sx={{ textAlign: "start", width: index === 0 ? "30%" : "20%", mr: index === 0 ? 6 : 2 }}
        key={row}
      >
        <SuiTypography fontSize="15px" sx={{ pt: 2, pb: 1, fontWeight: 400, color: "#668B9E" }}>
          {row}
        </SuiTypography>
        <Card
          sx={{
            width: "100%",
            height: 57,
            backgroundColor: "#F7F9FB",
            boxShadow: 0,
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            pt: 1.8,
            color: "#535353",
          }}
        >
          {listsCount[index]}명
        </Card>
      </SuiBox>
    )
  })
  const userListCard = () => {
    return <SuiBox display="flex">{listsShow}</SuiBox>
  }
  return (
    <div
      // style={{ marginTop: 64 + 72 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // {...other}
    >
      {value === index && (
        <SuiBox
          display="flex"
          justifyContent="center"
          bgColor="#F1F1F5"
          minHeight="85vh"
          px={14}
          sx={{ pb: "60px" }}
        >
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <SuiTypography
                sx={{ mt: 7.5, flex: 1, fontWeight: 700, color: "dark", fontSize: 24 }}
                variant="h6"
                component="div"
              >
                {children}
              </SuiTypography>
              <SuiTypography
                sx={{ mt: 2, fontWeight: 500, fontSize: 18, mb: "40px" }}
                color="subTitle"
                variant="h6"
                component="div"
              >
                {subText()}
              </SuiTypography>
              {Content()}
            </div>
          </Grid>
        </SuiBox>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  change_count_send: PropTypes.func,
  delete_count_send: PropTypes.func,
  setTab: PropTypes.func,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function AdminDialog({ open, closeDialog }) {
  const dispatch = useDispatch()
  const [tab, setTab] = useState(0)
  const [delete_count, setdelete_count] = useState(0)
  const [change_count, setchange_count] = useState(0)
  const handleChange = (event, newValue) => {
    setTab(newValue)
  }
  const [openDialog, setopenDialog] = useState(open)
  const handleClose = () => {
    setopenDialog(false)
    closeDialog()
  }
  useEffect(() => {
    setopenDialog(open)
    setTab(0)
    if (open) {
      dispatch(loading_Apply(true))
    }
  }, [open])
  useEffect(() => {
    const homeRef = document.getElementById("top-admmin")
    if (homeRef) {
      setTimeout(() => {
        homeRef.scrollIntoView({ behavior: "smooth" })
      }, 300)
    }
  }, [tab])
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      sx={{ maxWidth: 1320, margin: "0 auto", marginTop: "136px" }}
    >
      <AppBar
        sx={{
          maxWidth: 1320,
          margin: "0 auto",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 10,
        }}
      >
        <Toolbar>
          <SuiTypography
            sx={{ ml: 2, flex: 1, fontWeight: 700, color: "dark", fontSize: 18 }}
            variant="h6"
            component="div"
          >
            관리자
          </SuiTypography>
          <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
            <MdClose />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className="dialog-menu-tabs"
        id="top-admmin"
      >
        <SuiBox
          sx={{
            maxWidth: 1320,
            margin: "0 auto",
            position: "fixed",
            top: 64,
            backgroundColor: "#ffffff",
            width: "100%",
            borderTop: 1,
            borderBottom: 1,
            borderColor: "#CED4D7",
            zIndex: 10,
          }}
        >
          <Tabs value={tab} onChange={handleChange} centered>
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<BiUserPin />}
              iconPosition="start"
              label="회원 관리"
            />
            <Tab
              sx={{
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<CgArrowsExchangeAlt />}
              iconPosition="start"
              label="이관 신청"
            />
            <Tab
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<CgFolderRemove />}
              iconPosition="start"
              label="삭제 신청"
            />
            <Tab
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: 20,
                width: 240,
              }}
              icon={<TuneIcon />}
              iconPosition="start"
              label="설정 변경"
            />
          </Tabs>
          {change_count > 0 ? <span className="baege-active-change"></span> : ""}
          {delete_count > 0 ? <span className="baege-active-delete"></span> : ""}
        </SuiBox>
        <TabPanel
          value={tab}
          index={0}
          setTab={setTab}
          delete_count_send={(e) => setdelete_count(e)}
          change_count_send={(e) => setchange_count(e)}
        >
          회원 관리
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          setTab={setTab}
          delete_count_send={(e) => setdelete_count(e)}
          change_count_send={(e) => setchange_count(e)}
        >
          이관 신청
        </TabPanel>
        <TabPanel
          value={tab}
          index={2}
          setTab={setTab}
          delete_count_send={(e) => setdelete_count(e)}
          change_count_send={(e) => setchange_count(e)}
        >
          삭제 신청
        </TabPanel>
        <TabPanel
          value={tab}
          index={3}
          setTab={setTab}
          delete_count_send={(e) => setdelete_count(e)}
          change_count_send={(e) => setchange_count(e)}
        >
          설정 변경
        </TabPanel>
      </Box>
    </Dialog>
  )
}

AdminDialog.defaultProps = {
  open: false,
}

AdminDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
}

export default AdminDialog
