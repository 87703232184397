import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"

import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiDialog from "components/SuiDialog"

import DeleteDialog from "../deleteDialog"

// Dialog
import WriterDialog from "../../../writer"
import DistributorDialog from "../../../distributor"
import OutsourcingDialog from "../../../outsourcing"
import EventDialog from "layouts/eventsManagement/items/event"

// API
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  updateRequest,
  updateCompany,
  updateWriter,
  updateContract,
  updateOutsourcing,
  createAlert,
  updateEvent,
} from "apollo/mutation"
import { requests, writer, company, outsourcing, events } from "apollo/query"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { loading_Apply } from "../../../../reducers/store"

function deleteData_card({ data }) {
  const dispatch = useDispatch()
  const [openAccess_Dialog, setopenAccess_Dialog] = useState(false)
  const [Delete_Dialog, setDelete_Dialog] = useState(false)
  const [Delete_Dialog_type, setDelete_Dialog_type] = useState("create")
  const [requestExplainData, setrequestExplainData] = useState("")
  const [distributorDialog, setdistributorDialog] = useState(false)
  const [outsourcingDialog, setoutsourcingDialog] = useState(false)
  const [eventDialog, seteventDialog] = useState(false)
  const [writerDialog, setwriterDialog] = useState(false)
  const [editDistributorId, seteditDistributorId] = useState(null)
  const [typeDistributor, settypeDistributor] = useState("detail")
  const [editId, seteditId] = useState(null)
  const [type, settype] = useState("detail")
  const [deleteDatas, setdeleteDatas] = useState({})
  const { myData } = useSelector((state) => state.store)
  const closeDialog = () => {
    setdistributorDialog(false)
    setwriterDialog(false)
    setoutsourcingDialog(false)
    seteventDialog(false)
  }
  const [updateContractActive] = useMutation(updateContract, {
    onError(err) {
      alert("계약 삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [writerActive] = useLazyQuery(writer, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [companyActive] = useLazyQuery(company, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [outsourcingActive] = useLazyQuery(outsourcing, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateRequestActive] = useMutation(updateRequest, {
    onError(err) {
      alert("삭제 신청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [updateWriterActive] = useMutation(updateWriter, {
    onError(err) {
      alert("작가 삭제시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [updateCompanyActive] = useMutation(updateCompany, {
    onError(err) {
      alert("유통사 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [createAlertActive] = useMutation(createAlert, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateOutsourcingActive] = useMutation(updateOutsourcing, {
    onError(err) {
      alert("유통사 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
    refetchQueries: [requests],
  })
  const [eventActive] = useLazyQuery(events, {
    onError(err) {
      dispatch(loading_Apply(false))
    },
  })
  const [updateEventActive] = useMutation(updateEvent, {
    onError(err) {
      dispatch(loading_Apply(false))
      alert("이벤트 수정 오류 발생")
    },
    refetchQueries: [events],
  })
  const refuse_Delete = () => {
    dispatch(loading_Apply(true))
    updateRequestActive({
      variables: {
        id: deleteDatas.id,
        decisionDate: new Date(),
        declining: requestExplainData,
        status: "삭제 신청 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
      },
    }).then(() => {
      data.status = "삭제 신청 거절" + dayjs(new Date()).format("YY.MM.DD HH:mm")
      data["decisionDate"] = new Date()
      data["declining"] = requestExplainData
      setDelete_Dialog(false)
      if (deleteDatas.type === "Writer") {
        writerActive({
          variables: {
            id: deleteDatas.requestId,
          },
        }).then(({ data }) => {
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "작가데이터 삭제 거절",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
              },
            },
          })
          const editHistory = [...data.writer.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" + data.writer.name + " 작가님 </strong> 정보 삭제 신청을 거절하였습니다",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          updateWriterActive({
            variables: {
              id: deleteDatas.requestId,
              history: editHistory,
            },
          })
        })
      } else if (deleteDatas.type === "Company") {
        companyActive({
          variables: {
            id: deleteDatas.requestId,
          },
        }).then(({ data }) => {
          const editHistory = [...data.company.history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" + data.company.name + " 社 </strong> 정보 삭제 신청을 거절하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "유통사데이터 삭제 거절",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
              },
            },
          })
          updateCompanyActive({
            variables: {
              id: deleteDatas.requestId,
              history: editHistory,
            },
          })
        })
      } else if (deleteDatas.type === "Outsourcing") {
        outsourcingActive({
          variables: {
            id: deleteDatas.requestId,
          },
        }).then(({ data }) => {
          const editHistory = [...data.outsourcing.histroy]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" +
              data.outsourcing.nickname +
              " 社 </strong> 정보 삭제 신청을 거절하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "외주계약데이터 삭제 거절",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
              },
            },
          })
          updateOutsourcingActive({
            variables: {
              id: deleteDatas.requestId,
              history: editHistory,
            },
          })
        })
      } else if (deleteDatas.type === "Event") {
        eventActive({
          variables: {
            id: deleteDatas.requestId,
          },
        }).then(({ data }) => {
          const editHistory = [...data.events[0].history]
          editHistory.unshift({
            profile: myData.profile ? myData.profile.url : null,
            name: myData.name,
            position: myData.position,
            department: myData.department,
            content:
              "<strong>" +
              data.events[0].title +
              " 이벤트 </strong> 정보 삭제 신청을 거절하였습니다.",
            date: dayjs(new Date()).format("YY.MM.DD HH:mm"),
          })
          // createAlertActive({
          //   variables: {
          //     users_permissions_user: deleteDatas.users_permissions_user.id,
          //     title: "유통사데이터 삭제 거절",
          //     type: "change",
          //     userid: deleteDatas.users_permissions_user.id,
          //     content: {
          //       position: myData.position,
          //       department: myData.department,
          //       name: myData.name,
          //       profile: myData.profile,
          //     },
          //   },
          // })
          updateEventActive({
            variables: {
              id: deleteDatas.requestId,
              state: "active",
              history: editHistory,
            },
          })
        })
      }
      dispatch(loading_Apply(false))
    })
  }
  const access_Delete = () => {
    dispatch(loading_Apply(true))

    updateRequestActive({
      variables: {
        id: deleteDatas.id,
        status: "삭제 신청 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm"),
      },
    }).then(() => {
      data.status = "삭제 신청 승인" + dayjs(new Date()).format("YY.MM.DD HH:mm")
      if (deleteDatas.type === "Writer") {
        updateWriterActive({
          variables: {
            id: deleteDatas.requestId,
            status: "삭제",
          },
        }).then(() => {
          writerActive({
            variables: {
              id: deleteDatas.requestId,
            },
          }).then(({ data }) => {
            data.writer.contracts.forEach((contract) => {
              updateContractActive({
                variables: {
                  id: contract.id,
                  status: "삭제",
                },
              })
            })
          })
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "삭제 신청 승인",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
                targetName: data.content.title,
              },
            },
          })
          setopenAccess_Dialog(false)
          dispatch(loading_Apply(false))
        })
      } else if (deleteDatas.type === "Company") {
        updateCompanyActive({
          variables: {
            id: deleteDatas.requestId,
            status: "삭제",
          },
        }).then(() => {
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "삭제 신청 승인",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
                targetName: data.content.title,
              },
            },
          })
          setopenAccess_Dialog(false)
          dispatch(loading_Apply(false))
        })
      } else if (deleteDatas.type === "Outsourcing") {
        updateOutsourcingActive({
          variables: {
            id: deleteDatas.requestId,
            status: "삭제",
          },
        }).then(() => {
          createAlertActive({
            variables: {
              users_permissions_user: String(deleteDatas.users_permissions_user.id),
              title: "삭제 신청 승인",
              type: "change",
              userid: String(deleteDatas.users_permissions_user.id),
              content: {
                position: myData.position,
                department: myData.department,
                name: myData.name,
                profile: myData.profile,
                targetName: data.content.title,
              },
            },
          })
          setopenAccess_Dialog(false)
          dispatch(loading_Apply(false))
        })
      } else if (deleteDatas.type === "Event") {
        updateEventActive({
          variables: {
            state: "delete",
            id: deleteDatas.requestId,
          },
        }).then(() => {
          // createAlertActive({
          //   variables: {
          //     users_permissions_user: deleteDatas.users_permissions_user.id,
          //     title: "삭제 신청 승인",
          //     type: "change",
          //     userid: deleteDatas.users_permissions_user.id,
          //     content: {
          //       position: myData.position,
          //       department: myData.department,
          //       name: myData.name,
          //       profile: myData.profile,
          //       targetName: data.content.title,
          //     },
          //   },
          // })
          setopenAccess_Dialog(false)
          dispatch(loading_Apply(false))
        })
      }
    })
  }
  const setopenAccess_Dialog_Btn = (
    requestId,
    type,
    id,
    status,
    dialog_type,
    users_permissions_user
  ) => {
    setdeleteDatas({
      requestId: requestId,
      type: type,
      id: id,
      status: status,
      users_permissions_user: users_permissions_user,
    })
    setrequestExplainData("")
    if (dialog_type === "refuse") {
      setDelete_Dialog(true)
    } else {
      setopenAccess_Dialog(true)
    }
  }
  const openDialog = (id, type) => {
    if (type === "Writer") {
      settype("detail")
      seteditId(id)
      setwriterDialog(true)
    } else if (type === "Company") {
      seteditDistributorId(id)
      settypeDistributor("detail")
      setdistributorDialog(true)
    } else if (type === "Outsourcing") {
      seteditDistributorId(id)
      settypeDistributor("detail")
      setoutsourcingDialog(true)
    } else if (type === "Event") {
      seteditId(id)
      settype("detail")
      seteventDialog(true)
    }
  }
  //관리자 삭제 이벤트명에서 8글자 이상일시 처리
  let contentTitle = ""
  if (data.content.title && data.content.title.length > 8) {
    contentTitle = data.content.title.substring(0, 8) + "...."
  } else {
    contentTitle = data.content.title
  }
  return (
    <Card sx={{ mt: "16px", boxShadow: 0 }}>
      <SuiBox p={5} display="flex" justifyContent="start">
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
          삭제 신청_{dayjs(data.created_at).format("YY.MM.DD HH:mm")}
        </SuiTypography>
        {data.declining ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="error" sx={{ ml: "auto" }}>
            승인거절_{dayjs(data.decisionDate).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : (
          ""
        )}
        {data.status.indexOf("승인") > -1 ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="info2" sx={{ ml: "auto" }}>
            신청승인_{dayjs(data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : null}
        {data.status.indexOf("취소") > -1 ? (
          <SuiTypography fontSize="18px" fontWeight="bold" color="secondary" sx={{ ml: "auto" }}>
            회원취소_{dayjs(data.updated_at).format("YY.MM.DD HH:mm")}
          </SuiTypography>
        ) : null}
      </SuiBox>
      <Card sx={{ p: 5, mx: 5, border: 1, borderColor: "#CDD2D3", boxShadow: 0 }}>
        <SuiTypography fontSize="18px" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
          신청자 정보
        </SuiTypography>
        <SuiBox display="flex" justifyContent="start" sx={{ mt: 4 }}>
          <SuiBox sx={{ width: "50%", mr: 1 }}>
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
                mb: 2,
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                  신청자
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {data.content.department} {data.content.name} {data.content.position}
                </SuiTypography>
              </SuiBox>
            </Card>
            <Card
              sx={{
                px: 2,
                border: 1,
                borderColor: "#F1F1F5",
                boxShadow: 0,
                backgroundColor: "#F7F9FB",
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 60 }}
              >
                <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                  신청일
                </SuiTypography>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }}
                />
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mr: "auto", color: "#535353" }}
                >
                  {dayjs(data.created_at).format("YY.MM.DD")}
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "50%",
              ml: 1,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 136 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 2, color: "#535353" }}>
                요청
                <br />
                사유
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 4, mr: "auto" }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ mr: "auto", color: "#535353" }}
              >
                {data.content.requestExplain}
              </SuiTypography>
            </SuiBox>
          </Card>
        </SuiBox>
        <SuiTypography
          fontSize="18px"
          fontWeight="bold"
          color="dark"
          sx={{ textAlign: "left", my: 4 }}
        >
          데이터 정보
        </SuiTypography>
        <SuiBox display="flex" justifyContent="start">
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "25%",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 0, color: "#535353" }}>
                유형
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 1.5 }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ mx: "auto", color: "#535353" }}
              >
                {data.type === "Writer"
                  ? "작가 정보"
                  : data.type === "Company"
                  ? "유통사 정보"
                  : data.type === "Event"
                  ? "이벤트 정보"
                  : "외주계약 정보"}
              </SuiTypography>
            </SuiBox>
          </Card>
          <Card
            sx={{
              ml: 2,
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "33%",
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography fontSize="18px" fontWeight="bold" sx={{ ml: 0, color: "#535353" }}>
                {data.type === "Writer"
                  ? "작가명"
                  : data.type === "Company"
                  ? "유통사명"
                  : data.type === "Event"
                  ? "이벤트"
                  : "외주계약명"}
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 1.5 }} />
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{
                  mx: "auto",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0C65FF",
                }}
                onClick={() => openDialog(data.requestId, data.type)}
              >
                {contentTitle}
              </SuiTypography>
            </SuiBox>
          </Card>
          <Card
            sx={{
              px: 2,
              border: 1,
              borderColor: "#F1F1F5",
              boxShadow: 0,
              backgroundColor: "#F7F9FB",
              width: "45%",
              ml: 2,
            }}
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 60 }}
            >
              <SuiTypography
                fontSize="18px"
                fontWeight="bold"
                sx={{ ml: 0, color: "#535353", minWidth: "60px" }}
              >
                최초등록
              </SuiTypography>
              <Divider orientation="vertical" sx={{ borderColor: "#EEEEEE", ml: 1.5 }} />
              {data.content.first_history ? (
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mx: "auto", color: "#535353" }}
                >
                  {data.content?.first_history.name} {data.content?.first_history.position}_
                  {data.content?.first_history.date}
                </SuiTypography>
              ) : (
                <SuiTypography
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ mx: "auto", color: "#535353" }}
                >
                  -
                </SuiTypography>
              )}
            </SuiBox>
          </Card>
        </SuiBox>
      </Card>
      <SuiBox p={5} display="flex" justifyContent="end">
        <SuiButton
          disabled={data.status.indexOf("심사중") === -1}
          color="error"
          onClick={() =>
            setopenAccess_Dialog_Btn(
              data.requestId,
              data.type,
              data.id,
              data.status,
              "refuse",
              data.users_permissions_user
            )
          }
          sx={{ width: 100, mr: 2, fontSize: 14 }}
        >
          거절
        </SuiButton>
        <SuiButton
          disabled={data.status.indexOf("심사중") === -1}
          color="primary"
          onClick={() =>
            setopenAccess_Dialog_Btn(
              data.requestId,
              data.type,
              data.id,
              data.status,
              "access",
              data.users_permissions_user
            )
          }
          sx={{ width: 100, fontSize: 14 }}
        >
          삭제승인
        </SuiButton>
      </SuiBox>
      <SuiDialog
        openDialog={openAccess_Dialog}
        closeDialog={() => setopenAccess_Dialog(false)}
        MainTitle={"삭제 신청 승인"}
        Content={"삭제 신청을 승인하시겠습니까?"}
        Active={() => access_Delete()}
        CanBtnText={"취소"}
        CreBtnText={"삭제승인"}
        AlertType={"twoBtn"}
        CreBtnColor={"primary"}
      />
      <DeleteDialog
        openDialog={Delete_Dialog}
        closeDialog={() => setDelete_Dialog(false)}
        MainTitle={"삭제 신청 거절"}
        Content={"거절 사유를 작성하고, 데이터 삭제 신청을 미승인합니다."}
        CanBtnText={Delete_Dialog_type === "create" ? "취소" : "확인"}
        CreBtnText={"승인거절"}
        CreBtnColor={Delete_Dialog_type === "create" ? "error" : "info2"}
        AlertType={Delete_Dialog_type === "create" ? "twoBtn" : "oneBtn"}
        requestExplainData={setrequestExplainData}
        requestExplainShowData={requestExplainData}
        Active={() => refuse_Delete()}
        type={Delete_Dialog_type}
      />
      <WriterDialog
        open={writerDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={{ bookname: data.content.title }}
      />
      <DistributorDialog
        open={distributorDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={{ title: data.content.title }}
      />
      <OutsourcingDialog
        open={outsourcingDialog}
        closeDialog={closeDialog}
        editId={editDistributorId}
        type={typeDistributor}
        editData={{ nickname: data.content.title }}
      />
      <EventDialog
        open={eventDialog}
        closeDialog={closeDialog}
        editId={editId}
        type={type}
        editData={{ title: data.content.title }}
      />
    </Card>
  )
}
deleteData_card.defaultProps = {
  data: {},
}

deleteData_card.propTypes = {
  data: PropTypes.object,
}

export default deleteData_card
