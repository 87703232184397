export const MYDATA_APPLY = "myData/MYDATA_APPLY"
export const LOADING_APPLY = "loading/LOADING_APPLY"
export const EDITSTATUS_APPLY = "editStatus/LOADING_APPLY"

export const myData_Apply = (myData) => ({ type: MYDATA_APPLY, myData })
export const loading_Apply = (loading) => ({ type: LOADING_APPLY, loading })
export const editStatus_Apply = (editStatus) => ({ type: EDITSTATUS_APPLY, editStatus })

const initialState = {
  myData: {
    id: "",
    username: "",
    email: "",
    department: "",
    position: "",
    phone: "",
    status: "",
    name: "",
    profile: {
      url: "",
    },
    writers: [],
    companies: [],
    role: {
      name: "",
    },
    requests: [],
  },
  loading: false,
  editStatus: false,
}

export default function store(state = initialState, action) {
  switch (action.type) {
    case MYDATA_APPLY:
      return {
        ...state,
        myData: { ...action.myData },
      }
    case LOADING_APPLY:
      return {
        ...state,
        loading: action.loading,
      }
    case EDITSTATUS_APPLY:
      return {
        ...state,
        editStatus: action.editStatus,
      }
    default:
      return state
  }
}
