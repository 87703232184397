import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import Dialog from "@mui/material/Dialog"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import SuiButton from "components/SuiButton"
import dayjs from "dayjs"
import { FiSearch } from "react-icons/fi"
import { GrDocumentPdf } from "react-icons/gr"
import { AiOutlineFile } from "react-icons/ai"
import { BiRecycle } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import GetFileIcon from "components/getFileIcon"

import { useSelector } from "react-redux"

import { useQuery, useMutation } from "@apollo/client"
import { createDonwloadLog } from "apollo/mutation"

function ContractInfoDialog({ openDialog, closeDialog, infoData }) {
  const [open, setOpen] = useState(openDialog)
  const { myData } = useSelector((state) => state.store)
  // useEffect(() => {
  // }, [openDialog])
  const [infoDatas, setinfoDatas] = useState(infoData)
  useEffect(() => {
    setinfoDatas(infoDatas)
    setOpen(openDialog)
  }, [infoDatas])

  const handleClose = () => {
    closeDialog()
    setOpen(false)
  }
  const [createDonwloadLogActive] = useMutation(createDonwloadLog, {
    onError(err) {
      alert("생성 요청시 오류가 발생 하였습니다.")
      dispatch(loading_Apply(false))
    },
  })
  const showPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.target = "_blank"
    a.rel = "noopener noreferrer"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
  }
  const DownloadPdf = (file) => {
    var a = document.createElement("a")
    a.href = process.env.REACT_APP_BACKEND_URL + file.url
    a.download = file.name
    a.target = "_blank"
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    createDonwloadLogActive({
      variables: {
        userId: myData.id,
        userName: myData.name,
        userPosition: myData.position,
        userDepartment: myData.department,
        userEmail: myData.email,
        downloadList: file,
        downloadDate: new Date(),
      },
    })
  }
  const betweenDayColor = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 181) {
      return "success"
    } else if (
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 181 &&
      Math.floor(betweenTime / (1000 * 60 * 60 * 24)) >= 91
    ) {
      return "warning"
    } else if (Math.floor(betweenTime / (1000 * 60 * 60 * 24)) < 91) {
      return "error"
    } else {
      return "light2"
    }
  }
  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(
      firstDate.substring(0, 4),
      firstDate.substring(4, 6) - 1,
      firstDate.substring(6, 8)
    )
    var secondDateObj = new Date(
      secondDate.substring(0, 4),
      secondDate.substring(4, 6) - 1,
      secondDate.substring(6, 8)
    )
    var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime())
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24))
  }
  const fileName = infoDatas[0].file?.name
  const fileType = fileName.split(".")[fileName.split(".").length - 1]
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <SuiBox
        sx={{
          width: 500,
          backgroundColor: "#ffffff",
          p: 2,
          mb: 2,
        }}
      >
        <SuiBox display="flex" alignItems="center" sx={{ mb: 4 }}>
          <SuiTypography
            fontSize="18px"
            fontWeight="medium"
            color="dark"
            sx={{ textAlign: "left", mr: "auto" }}
          >
            {infoDatas[0].selectData[1].value}
          </SuiTypography>
          <SuiBox
            sx={{
              ml: "auto",
              py: infoDatas[3].value === "자동갱신" ? "" : "6px",
            }}
          >
            <SuiTypography fontSize="16px" fontWeight="medium" color="dark">
              {dayjs(infoDatas[1].value2) > dayjs(new Date()) ? (
                dayjs(infoDatas[1].value2).format("YYYY.MM.DD") === "2999.01.01" ? (
                  <span>무기한</span>
                ) : (
                  <strong>
                    {betweenDay(
                      dayjs(new Date()).format("YYYYMMDD"),
                      dayjs(infoDatas[1].value2).format("YYYYMMDD")
                    )}
                  </strong>
                )
              ) : (
                "계약 종료"
              )}
              {dayjs(infoDatas[1].value2) > dayjs(new Date())
                ? dayjs(infoDatas[1].value2).format("YYYY.MM.DD") === "2999.01.01"
                  ? ""
                  : "일 남음"
                : ""}
            </SuiTypography>
          </SuiBox>
          {infoDatas[3].value === "자동갱신" ? (
            <IconButton
              color={betweenDayColor(
                dayjs(new Date()).format("YYYYMMDD"),
                dayjs(infoDatas[1].value2).format("YYYYMMDD")
              )}
              className="cursorDefalut"
            >
              <BiRecycle />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            edge="start"
            color="inherit"
            size="small"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <MdClose />
          </IconButton>
        </SuiBox>
        <table className="contract-table">
          <tbody>
            <tr>
              <td>
                <SuiTypography fontSize="13px" fontWeight="medium" color="text2">
                  계약일
                </SuiTypography>
              </td>
              <td>
                <SuiTypography fontSize="13px" fontWeight="medium" color="text2">
                  계약 종료일
                </SuiTypography>
              </td>
              <td>
                <SuiTypography fontSize="13px" fontWeight="medium" color="text2">
                  갱신 / 해지
                </SuiTypography>
              </td>
            </tr>
            <tr>
              <td>
                <SuiTypography fontSize="13px" fontWeight="medium" color="text2">
                  {dayjs(infoDatas[1].value1).format("YYYY.MM.DD")}
                </SuiTypography>
              </td>
              <td>
                <SuiTypography fontSize="13px" fontWeight="medium" color="text2">
                  {dayjs(infoDatas[1].value2).format("YYYY.MM.DD") === "2999.01.01"
                    ? "-"
                    : dayjs(infoDatas[1].value2).format("YYYY.MM.DD")}
                </SuiTypography>
              </td>
              <td>
                <SuiTypography
                  fontSize="13px"
                  fontWeight="medium"
                  color={infoDatas[3].value === "자동갱신" ? "success" : "error"}
                >
                  {infoDatas[3].value}
                </SuiTypography>
              </td>
            </tr>
          </tbody>
        </table>
        <SuiTypography
          fontSize="13px"
          fontWeight="medium"
          color="info2"
          sx={{ textAlign: "left", mt: 2 }}
        >
          계약 진행:
          {infoDatas[4] && infoDatas[4].contractDocument
            ? infoDatas[4].contractDocument.users_permissions_user.department
            : myData.department}{" "}
          /{" "}
          {infoDatas[4] && infoDatas[4].contractDocument
            ? infoDatas[4].contractDocument.users_permissions_user.name
            : myData.name}{" "}
          {infoDatas[4] && infoDatas[4].contractDocument
            ? infoDatas[4].contractDocument.users_permissions_user.position
            : myData.position}
        </SuiTypography>
        <SuiTypography
          fontSize="13px"
          fontWeight="medium"
          color="dark"
          sx={{ textAlign: "left", mt: 4 }}
        >
          계약서 원본
        </SuiTypography>
        <SuiBox display="flex" alignItems="center" sx={{ mt: 1 }}>
          <IconButton sx={{ mr: 1.5, p: 0 }} className="cursorDefalut">
            {GetFileIcon(fileType)}
          </IconButton>
          <SuiTypography
            fontSize="14px"
            fontWeight="medium"
            color="dark"
            sx={{ mr: 2, textAlign: "left", cursor: "pointer" }}
          >
            {infoDatas[0].file ? infoDatas[0].file.name : ""}
          </SuiTypography>
          {/* <SuiButton
            sx={{
              backgroundColor: "#EEEEEE",
              minHeight: 24,
              height: 24,
              width: 24,
              minWidth: 24,
              p: 0,
              ml: 1,
              boxShadow: 0,
              display: infoDatas[0].file.url ? "" : "none",
            }}
            onClick={() => showPdf(infoDatas[0].file)}
          >
            <FiSearch size="15" />
          </SuiButton>
          <SuiButton
            color="info"
            sx={{
              minHeight: 20,
              height: 20,
              ml: 1,
              pt: 1.8,
              px: 0,
              boxShadow: 0,
              display: infoDatas[0].file.url ? "" : "none",
            }}
            onClick={() => DownloadPdf(infoDatas[0].file)}
          >
            다운로드
          </SuiButton> */}
        </SuiBox>
      </SuiBox>
    </Dialog>
  )
}

ContractInfoDialog.defaultProps = {
  openDialog: false,
}

ContractInfoDialog.propTypes = {
  openDialog: PropTypes.bool,
  infoData: PropTypes.array,
  closeDialog: PropTypes.func,
}
export default ContractInfoDialog
